import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import Marker from '@editorjs/marker';
import Quote from '@editorjs/quote';
import Delimiter from '@editorjs/delimiter';
import ImageTool from '@editorjs/image';
import NestedList from '@editorjs/nested-list';
import Alert from 'editorjs-alert';
import Header from 'editorjs-header-with-alignment';
import Paragraph from 'editorjs-paragraph-with-alignment';
import InlineImage from 'editorjs-inline-image';
// import Tooltip from 'editorjs-tooltip';

export const EDITOR_TOOLS = {
  header: {
    class: Header,
    config: {
      placeholder: 'Enter a header',
      levels: [2, 3, 4, 5],
      defaultLevel: 3,
    },
  },
  list: {
    class: NestedList,
    inlineToolbar: true,
  },
  paragraph: {
    class: Paragraph,
  },
  embed: {
    class: Embed,
    config: {
      services: {
        youtube: true,
        twitter: true,
        coub: true,
        imgur: true
      }
    }
  },
  table: {
    class: Table,
    // inlineToolbar: true,
    config: {
      rows: 2,
      cols: 3,
    },
  },

  Marker: {
    class: Marker,
    shortcut: 'CMD+SHIFT+M',
  },
  quote: {
    class: Quote,
    inlineToolbar: true,
    shortcut: 'CMD+SHIFT+O',
    config: {
      quotePlaceholder: 'Enter a quote',
      captionPlaceholder: 'Quote\'s author',
    },
  },
  delimiter: Delimiter,
  inlineImage: {
    class: InlineImage,
    inlineToolbar: true,
    config: {
      embed: {
        display: true,
      },
    }
  },
  image: {
    class: ImageTool,
    inlineToolbar: true,
  },
  alert: {
    class: Alert,
    inlineToolbar: true,
    config: {
      defaultType: 'danger',
    },
  },
  // tooltip: {
  //   class: Tooltip,
  //   config: {
  //     location: 'top',
  //     highlightColor: '#FFEFD5',
  //     underline: false,
  //     backgroundColor: '#154360',
  //     textColor: '#FDFEFE',
  //     holder: 'body_translations',
  //   }
  // },

}

