<template>
  <div class="speciality-section">
    <IconLogoBig class="background-logo"/>
    <v-container>
      <div class="d-flex flex-wrap justify-center align-center justify-md-space-between">
        <template v-for="item in items">
          <v-card tile flat color="transparent" dark>
            <v-card-text class="px-0">
              <div class="d-flex justify-center ">
                <img :src="item.img" style="height: 90px; width: auto">
              </div>
              <div class="d-flex flex-column align-center text-center">
                <h6 class="white-title my-6">{{ item.title }}</h6>
                <span class="footer-link text-center text" v-html="item.description"></span>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </div>
    </v-container>
  </div>
</template>

<script>
import IconLogoBig from '@/assets/svg-icons/logo/logo-0.svg'

export default {
  name: 'SpecialitySection',
  data() {
    return {
      lang: '',
    }
  },
  components: {
    IconLogoBig
  },
  mounted() {
    this.lang = localStorage.getItem('lang')
  },
  computed: {
    items() {
      return [
        {
          title: this.$t('home.craft.wall.title'),
          description: this.$t('home.craft.wall.description'),
          img: '/img/widgets/speciality/icon-1.png'
        },
        {
          title: this.$t('home.craft.hand.title'),
          description: this.$t('home.craft.hand.description'),
          img: '/img/widgets/speciality/icon-2.png'
        },
        {
          title: this.$t('home.craft.eco.title'),
          description: this.$t('home.craft.eco.description'),
          img: '/img/widgets/speciality/icon-3.png'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">

.container {
  @media (min-width: 1280px) {
    max-width: 1113px;
  }
}
.speciality-section {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 10px;
  background-color: #000000;
  min-height: 290px;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  @media (--t) {
    //max-height: 290px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: unset;
  }

  .white-title {
    font-family: var(--font-family--alethia);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    /*letter-spacing: 0.04em;*/
    @media(--t) {
      font-size: 24px;
    }
  }

  span {
  //max-width: 240px; line-height: 130% !important;
    text-align: center;
    letter-spacing: 0.02em !important;
    text-transform: none;
    font-weight: normal;
    font-size: 16px;
    color: #FFFFFF;
  }

  .background-logo {
    display: inline-block;
    position: absolute;
    top: 3%;
    right: -20%;
    @media (--t) {
      display: inline-block;
      position: absolute;
      top: -40%;
      right: 28.7%;
    }
  }

  .content {
    position: relative;
    z-index: 1;
  }
}
</style>
