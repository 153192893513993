import {axiosInstance} from "@/utils/setAxios";
import * as localforage from "localforage";
import router from "@/router";
import i18n from "@/plugins/i18n"

export default {
    state: {
        access_token: null,
        user: null,
        current_user_data: null,
    },

    mutations: {
        SET_TOKEN(state, payload) {
            state.access_token = payload;
        },
        logoutUser(state) {
            state.user = null;
            state.access_token = null;
            state.coupon = null;
            state.current_user_data = null;

            localStorage.removeItem("order_id");
            localStorage.removeItem("user_data");
            localStorage.removeItem("access_token");
            localforage.removeItem("current_user").then(() => {
            })
        },
        CLEAR_TOKEN(state) {
            state.access_token = null;
        },
        SET_USER_DATA(state, payload) {
            state.user = payload;
        },
        SET_CURRENT_USER(state, payload) {
            state.current_user_data = payload;
            localforage.setItem("current_user", payload).then((res) => {
            });
        },
        CREATE_ADDRESS(state, {address, contact_person}) {
            if (address) {
                address.name = address?.street1;
                state.current_user_data.contact.addresses = state.current_user_data.contact.addresses?.filter(a => a.id !== address.id)
                state.current_user_data.contact.addresses?.push(address)
            }
            if (contact_person) {
                state.current_user_data.contact.contact_people = state.current_user_data.contact.contact_people.filter(c => c.id !== contact_person.id)
                state.current_user_data.contact.contact_people?.push(contact_person);
            }
        },
    },

    actions: {
        INIT_AUTH({commit}) {
            let token;
            let userdata;
            token = localStorage.getItem("access_token");
            userdata = localStorage.getItem("user_data");
            commit("SET_TOKEN", token, userdata);
            commit("SET_USER_DATA", userdata);
            if (!token) {
                commit("CLEAR_TOKEN");
            }
        },
        loginUser({commit}, payload) {
            return axiosInstance
                .post("users/login", {
                    email: payload.user.email,
                    password: payload.user.password,
                })
                .then((response) => {
                    commit("SET_TOKEN", response?.data?.token);
                    commit("SET_USER_DATA", response?.data);
                    localStorage.setItem("access_token", response?.data?.token);
                    localStorage.setItem("user_data", JSON.stringify(response?.data));
                    return [true];
                });
        },
        registerUser({commit}, payload) {
            return axiosInstance
                .post("users", {
                    karavan_user: {
                        email: payload.karavan_user.email,
                        password: payload.karavan_user.password,
                        password_confirmation: payload.karavan_user.password_confirmation,
                        preferred_language: i18n.locale                    },
                    contact: {
                        addresses_attributes: {
                            0: {
                                name: "Initial Address (Please Fill this address)",
                                // company_name: "Blankspace",
                                // street1: null,
                                // street2: null,
                                // city: null,
                                // zipcode: null,
                                // country: null,
                                // state: null
                            }
                        },
                        contact_people_attributes: {
                            0: {
                                first_name: payload.contact.contact_people_attributes.first_name,
                                last_name: payload.contact.contact_people_attributes.last_name,
                                email: payload.karavan_user.email,
                            },
                        },
                    },
                })
                .then((response) => {
                    commit("SET_TOKEN", response?.data?.token);
                    commit("SET_USER_DATA", response?.data?.user);
                    localStorage.setItem("access_token", response?.data?.token);
                    localStorage.setItem("user_data", JSON.stringify(response?.data?.user));
                    return [true];
                });
        },
        updatePassword({commit}, payload) {
            return axiosInstance.patch("users/update_password", payload);
        },
        getCurrentUserData({commit}, payload) {
            return axiosInstance.get("users/get_user").then((current_user_response) => {
                commit("SET_CURRENT_USER", current_user_response?.data);
            }).catch(e => {
                commit('logoutUser')
                commit("CLEAR_CART_LIST");
                setTimeout(() => {
                    router.push({name: 'Home'})
                }, 300)
            })
        },
        updateUserData({commit}, payload) {
            return axiosInstance.patch("users/update_user", payload).then((updated_user_data) => {
                return updated_user_data;
            });
        },
        lostPassword(vuexContext, model) {
            return axiosInstance.patch("users/password_reminder", model).then((res) => {
            });
        },
        updateForgottenPassword({commit}, payload) {
            return axiosInstance.patch("users/update_forgotten_password", payload);
        },
        SEND_FEEDBACK(vuexContext, payload) {
            const body = `<table border='0' cellpadding='0' cellspacing='0'> +
                <tr> +
                <td style='padding: 7px'>Name</td> +
                <td style='padding: 7px' width='2'>:</td> +
                <td> + payload.full_name + </td> +
                </tr> +
                <tr> +
                <td style='padding: 7px'>Email</td> +
                <td style='padding: 7px' width='2'>:</td> +
                <td style='padding: 7px'>" + payload.email + </td> +
                </tr> +
                <tr> +
                <td style='padding: 7px'>Subject</td> +
                <td style='padding: 7px' width='2'>:</td> +
                <td style='padding: 7px'>" + payload.subject + </td> +
                </tr> +
                <tr> +
                <td style='padding: 7px'>Message</td> +
                <td style='padding: 7px' width='2'>:</td> +
                <td style='padding: 7px'>" + payload.message + "</td> +
                </tr>;`;

            const model = {
                email_from: payload.email,
                body,
            };

            axiosInstance.post("pages/send_email", model).then((response) => {
            });
        },
    },

    getters: {
        getAccessToken: (state) => state.tokens?.access_token,
        getAuthStatus: (state) => state.user !== null && state.access_token !== null,
        getUserData: (state) => state.user,
        currentUserData: (state) => state.current_user_data,
        saved_addresses: (state) => state.current_user_data?.contact?.addresses,
    },
};
