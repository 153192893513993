const BASE_URL = location.origin
export const REVIEW_CONTENTS = {
    CanvasPaper: {
        product_id: 2,
        name: "Canvas Paper",
        url: `${BASE_URL}/canvas`,
        description:
            "A canvas on solid wood frame is the classic look everyone should have in their home, it provides a soft and timeless vibe to every room.",
        product_sku: 2,
        image: "/img/papers/opt/canvas-1-opt.jpg"
    },
    Matte:{
        product_id: 3,
        name: "Matte",
        url: `${BASE_URL}/matte-paper`,
        description:
            "This matte photo paper-type is suitable for any kind of print from photography to watercolor. Being high definition, neutral and toned, it will highlight your piece in the best way.",
        product_sku: 3,
        image: "/img/papers/opt/matte-1-opt.jpg"
    },
    Satin:{
        product_id: 4,
        name: "Satin",
        url: `${BASE_URL}/satin-paper`,
        description:
            "This satin photo paper-type is the perfect option for your everyday photo. An affordable option that will make your colors pop.",
        product_sku: 4,
        image: "/img/papers/opt/satin-1-opt.jpg"
    },
    Metallic:{
        product_id: 5,
        name: "Metallic",
        url: `${BASE_URL}/metallic-paper`,
        description:
            "This metallic photo paper is our recommendation for higher quality photography. The glossy and pearlescent finish allows for every detail to stand out.",
        product_sku: 5,
        image: "/img/papers/opt/metallic-1-opt.jpg"
    },
    Textured:{
        product_id: 6,
        name: "Textured",
        url: `${BASE_URL}/textured-paper`,
        description:
            "This high quality textured and thick fine art paper-type paired with our top printing technology will render professional results. It’s the artists’ favorite!",
        product_sku: 6,
        image: "/img/papers/opt/textured-1-opt.jpg"
    },
    Hemp:{
        product_id: 7,
        name: "Hemp",
        url: `${BASE_URL}/hemp-paper`,
        description:
            "This 100% natural hemp Hahnemuehle paper-type is our top-of-the-line and will leave a lasting impression. Choosing this paper-type is also choosing our most sustainable option.",
        product_sku: 7,
        image: "/img/papers/opt/hemp-1-opt.jpg"
    },
    MetalPaper:{
        product_id: 7,
        name: "MetalPaper",
        url: `${BASE_URL}/metal`,
        description:
            "If you desire a metallic matte look for your artwork, look no further! A direct print on brushed aluminum is the most lightweight and durable print you can get.",
        product_sku: 7,
        image: "/img/papers/opt/metal-1-opt.jpg"
    },
    AcrylicPaper:{
        product_id: 8,
        name: "AcrylicPaper",
        url: `${BASE_URL}/acrylic`,
        description:
            "This is our in-house favorite! Nothing beats this look on your home or office walls. We guarantee you that all your guests will talk about it when they see it for the first time. A direct print behind acrylic glass simply brings your artwork to another level!",
        product_sku: 8,
        image: "/img/papers/opt/acrylic-1-opt.jpg"
    },
};
