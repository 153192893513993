<template>
  <div>
    <v-col>
      <div class="address-header-container">
        <div v-if="!_user_has_address" id="placeholder">
          <span class="small-title">{{ $t("profile.no_address") }}</span>
        </div>
        <v-btn text tile :to="{ name: 'Billing Address' }" color="primary" class="text-capitalize">
          <span class="medium-bold">{{ $t("profile.add_address") }}</span>
          <span class="medium-bold ml-2">+</span>
        </v-btn>
      </div>
      <template v-if="_user_has_address">
        <AddressCard
            class="mb-4"
            v-for="(address, index) in _address_list.filter(e => !e._destroy && e.street1)"
            :key="address.id"
            :country="address.state"
            :name="address.name"
            :nation="address.country"
            :street="address.street1"
            :zipcode="address.zipcode"
            @onDelete="deleteAddress(address)"
            @onUpdate="updateAddress(address)"
            :disableDeleteEvent="address.primary || index === 0"
        />
      </template>
    </v-col>
  </div>
</template>

<script>
import {IconPlus} from "@/assets/svg-icons";
import AddressCard from "@/components/cards/shared-card/address-card";
import {mapGetters} from "vuex";
import {axiosInstance} from "@/utils/setAxios";
import {isObject} from "lodash";

export default {
  name: "Address",
  components: {
    AddressCard,
    IconPlus
  },
  data() {
    return {
      address_data: {
        karavan_user: {
          email: ""
        },
        contact: {
          contact_people_attributes: {
            0: {
              id: null,
              first_name: null,
              last_name: null,
              phone: null,
              email: null,
              _destroy: true
            }
          },
          addresses_attributes: {
            0: {
              id: null,
              name: null,
              street1: null,
              street2: null,
              city: null,
              zipcode: null,
              country: null,
              state: null,
              _destroy: true
            }
          }
        }
      }
    };
  },
  methods: {
    deleteAddress(address) {
      const {contact_people, addresses} = this.currentUserData.contact || {}
      let matchedContactPerson = null;
      if (addresses?.length > 0 && contact_people?.length > 0) {
        const addressIndex = addresses?.findIndex(a => a.id === address.id)
        matchedContactPerson = contact_people?.[addressIndex]
      }

      const address_data = {
        contact: {
          addresses_attributes: {
            0: {
              id: address?.id,
              _destroy: true
            }
          },
          ...isObject(matchedContactPerson) && {
            contact_people_attributes: {
              0: {
                id: matchedContactPerson?.id,
                _destroy: true
              }
            }
          }
        }
      };

      // this.$store.commit("SET_LOADER", true);
      axiosInstance
          .patch("users/update_user", address_data)
          .then(updated_user_data => {
            address._destroy = true;
            this.createSuccessNotification(this.$t("notify.account_updated"));
          })
          .catch(() => {
            this.createErrorNotification(this.$t("notify.wrong"));
          })
          .finally(() => {
            setTimeout(() => {
              // this.$store.commit("SET_LOADER", false);
              this.$nextTick(() => this.$forceUpdate());
            }, 500);
          });
    },
    updateAddress(address) {
      this.$router.push({name: 'Update Address', params: {index: this._current_user_data.addresses?.indexOf(address)}});
    }
  },
  created() {
    if (this.$store.getters.getAuthStatus === true) {
      this.address_data.karavan_user.email = this.$store.getters.currentUserData?.karavan_user?.email || "";
    }
  },
  computed: {
    _address_list() {
      return this.$store.getters.currentUserData.contact.addresses;
    },
    _user_has_address() {
      const addressList = this.$store.getters.currentUserData?.contact?.addresses;
      return (addressList.length === 1 && isObject(addressList?.find(i => i.street1)) || addressList.length > 1)
    },
    _current_user_data() {
      if (this.$store.getters.getAuthStatus === true) {
        return this.$store.getters.currentUserData?.contact;
      }
    },
    ...mapGetters(["currentUserData"])
  }
};
</script>
<style scoped>
.address-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 20px;

@media (--t) {
  flex-direction: row

;
}

#add-button {
  order: 1;
}

#placeholder {
  order: 2;
  margin-top: 20px;

@media (--t) {
  margin-top: unset

;
}

}
}
</style>
/*customClass: { container: '...', popup: '...', header: '...', title: '...', closeButton: '...', icon: '...', image: '...', content: '...', input:
'...', inputLabel: '...', validationMessage: '...', actions: '...', confirmButton: '...', denyButton: '...', cancelButton: '...', loader: '...',
footer: '....' }*/
