<template>
  <div class="simple-header">
    <div class="row no-gutters justify-space-between justify-md-start">
      <div v-if="smAndDown" class="col">
        <v-btn v-if="$vuetify.breakpoint.smAndDown" icon color="primary"
               @click="$store.commit('SET_MOBILE_MENU', true)">
          <IconHamburger/>
        </v-btn>
      </div>
      <div class="col" v-if="!smAndDown">
        <router-link :to="{name:'Home'}">
          <p class="page-subtitle text-left">{{ $t('buttons.back') }}</p>
        </router-link>
      </div>
      <div class="col simple-header-logo">
        <router-link :to="{name:'Home'}">
          <IconLogoDesktop class="logo-desktop"/>
          <IconLogoMobile class="logo-mobile"/>
        </router-link>

      </div>
      <div class="col text-right" v-if="smAndDown">
        <v-badge
          :value="$store.getters.get_cart.length > 0"
          color="primary"
          :content="$store.getters.get_cart.length"
          offset-x="20"
          offset-y="20"
        >
          <v-btn
            icon
            :to="{name:'Cart'}"
          >
            <icon-cart-mini/>
          </v-btn>
        </v-badge>
      </div>
      <v-col v-if="!smAndDown"/>
    </div>
  </div>
</template>

<script>

import IconLogoDesktop from '@/assets/svg-icons/logo/logo-1.svg'
import IconLogoMobile from '@/assets/svg-icons/logo/logo-3.svg'

export default {
  name: 'SimpleHeader',
  components: {
    IconLogoDesktop,
    IconLogoMobile
  }
}
</script>

<style scoped lang="scss">
.simple-header {
  background-color: #ffffff;
  overflow: hidden;
  height: 60px;
  padding: 16px 8px;
  @media (--t) {
    padding-top: 20px;
    min-height: 100px;
  }

  p {
    display: none;
    @media (--t) {
      display: block;
      text-decoration: underline;
      margin-left: 20px;
    }
  }

  .simple-header-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-desktop {
      display: none;
      @media (--t) {
        display: flex;
      }
    }

    .logo-mobile {
      display: flex;
      @media (--t) {
        display: none;
      }
    }
  }
}
</style>
