import IconRightArrow from '@/assets/svg-icons/icons/right.svg'
import {get} from 'lodash'
export const tabLayoutMixins = {
  methods: {
    lProp(item = {}, path = '') {
      return get(item, `${path}_${this.lang || 'en'}`)
    },
  },
  computed: {
    mdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    cardProps() {
      return this.mdAndUp ? {height: '100%'} : {minHeight: '100%'}
    },
    imgWidth() {
      return '100%'
    },
  },
  components: {
    IconRightArrow
  }
}
