<template>
  <v-container class="process">
    <Heading :subtitle="$t('home.process.subtitle').toUpperCase()" :title="$t('home.process.title')"/>
    <v-container class="process-card pb-1">
      <div class="d-flex justify-center">
        <template v-for="item in items">
          <ProcessItemCard
            :key="item.title"
            :item="item"
            v-bind="item.props"
            width="320"
            class="mx-auto"
          />
        </template>
      </div>
    </v-container>
    <v-container class="mobile-process pb-0" v-if="smAndDown">
      <AppSwiper :items="items" v-bind="options">
        <template v-slot:item="item">
          <ProcessItemCard
            :item="item"
            :nudge-img="!!item.props"
            card-text-class="px-0 pb-0"
          />
        </template>
      </AppSwiper>
    </v-container>
    <div class="d-flex flex-wrap mx-6 mt-5" v-if="smAndDown">
      <AppButton
        height="50"
        color="primary"
        :to="{name:'Configurator'}"
        block


      >{{ $t("buttons.start") }}</AppButton>
    </div>
  </v-container>
</template>

<script>
import ImageUpload from "@/assets/svg-icons/process/upload.svg";
import ImageCustomize from "@/assets/svg-icons/process/customize.svg";
import ImageEnjoy from "@/assets/svg-icons/process/enjoy.svg";
import ProcessItemCard from '@/components/cards/ProcessItemCard'
import Heading from "../Heading";

export default {
  name: "ProcessSection",
  components: {
    Heading,
    ProcessItemCard,
    ImageUpload,
    ImageEnjoy,
    ImageCustomize,
  },
  data() {
    return {
      options: {
        autoplay:true,
        rewind: true,
        slidesPerView: 1,
        spaceBetween: 16

      },
    }
  },
  computed: {
    items() {
      return [
        {
          title: this.$t("home.process.upload.title"),
          description: this.$t("home.process.upload.description"),
          img: '/img/svg-icons/process/upload.svg'
        },
        {
          title: this.$t("home.process.customize.title"),
          description: this.$t("home.process.customize.description"),
          img: '/img/svg-icons/process/customize.svg',
          props: {
            imgJustify: 'end',
          }
        },
        {
          title: this.$t("home.process.enjoy.title"),
          description: this.$t("home.process.enjoy.description"),
          img: '/img/svg-icons/process/enjoy.svg'
        }

      ]
    }
  }
}
</script>

<style lang="scss">

.process {
  padding-top: 40px;
  padding-bottom: 25px;
  @media (--t) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .swiper-slide {
    width: 280px;
  }

  .process-card {
    display: none;
    @media (--t) {
      display: block;
      margin-top: 72px;
    }

  }

  .mobile-process {
    display: block;
    @media (--t) {
      display: none;
    }
    .swiper-pagination {
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 0;
      position: relative;
    }
  }
}

</style>
