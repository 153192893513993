<template>
  <v-container>
    <h1 class="frame-title text-left">{{ $t('hello') }}, {{currentUserData.contact.contact_people[0].first_name}}</h1>

    <span class="page-subtitle">
      {{$t('dashboard')}}
    </span>

  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Dashboard",
  computed: {
    ...mapGetters(["currentUserData"])
  }
}
</script>

<style scoped>

</style>
