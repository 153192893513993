<template>
  <LayoutTabsContainer ref="container">
      <template #title>
        {{ $t('layouts.title') }}
      </template>
    <template #subTitle>
      {{ $t('layouts.subtitle').toUpperCase() }}
    </template>

    <v-tabs :icons-and-text="!mdAndUp" slider-color="secondary" class="layout-tabs" :vertical="mdAndUp">
      <template v-for="tab in tabs">
        <v-tab active-class="primary--text" @click="$refs.container.alignToBottom()" :key="tab.title">
          <component :is="tab.icon" class="tab-icon"/>
          <div class="d-flex flex-column">
            <span v-html="tab.title.toUpperCase()"></span>
            <span v-html="tab.panelSize.toUpperCase()"></span>
          </div>
          <template v-if="mdAndUp">
            <v-spacer/>
            <IconRightArrow class="arrow"/>
          </template>
        </v-tab>
      </template>

      <template v-for="tab in tabs">
        <v-tab-item :key="tab.title">
          <v-card :color="mdAndUp ? '#f6f6f6' : 'white'" flat v-bind="cardProps">
            <div class="d-flex flex-column align-center fill-height pa-md-10 pa-6  pb-3">
              <v-img
                :src="tab.img"
                contain
                :alt="tab.title"
                :width="imgWidth"
                :aspect-ratio="(501/312)"
                max-width="501"
              />
              <div class="d-flex align-center flex-column">
                <h3 class="info-titles mt-4">{{ tab.title.toUpperCase() }}</h3>
                <p class="medium text-center mt-3" v-if="tab.description">{{ tab.description }}</p>
              </div>
              <template v-if="mdAndUp">
                <v-spacer/>
                <AppButton
                  @click="saveLayout(tab.value)"
                  color="primary"
                  large
                >
                  {{ $t('buttons.start').toUpperCase() }}
                </AppButton>
              </template>
            </div>
          </v-card>
        </v-tab-item>
      </template>
    </v-tabs>

  </LayoutTabsContainer>
</template>

<script>
import { IconGridDefault, IconGridHorizontal, IconGridVertical, IconGridWave } from '@/assets/svg-icons'
import localforage from 'localforage'
import { tabLayoutMixins } from '@/mixins/tabLayoutMixins'

export default {
  name: 'LayoutTab',
  mixins: [tabLayoutMixins],
  props: {
    wave_image: {
      type: String
    },
    vertical_image: {
      type: String
    },
    horizontal_image: {
      type: String
    },
    rectangle_image: {
      type: String
    }
  },
  data: () => ({
    default_layout: '1 Panel',
    vertical_layout: '3 Vertical Panels',
    horizontal_layout: '3 Horizontal Panels',
    wavy_layout: '4 Wavy Panels',

    layoutData: {
      id: '',
      layout: null,
      product_type: null
    }
  }),
  methods: {
    saveLayout(val) {
      this.layoutData.layout = val
      localforage.setItem('layout_data', this.layoutData)
      window.scroll(0, 0)
      this.$router.push({name: 'Configurator'})
    },
    detectProductType() {
      if (this.$route.name === 'CanvasPaper') {
        this.layoutData.id = 1
        this.layoutData.product_type = 'canvas_frame'
      } else if (this.$route.name === 'AcrylicPaper') {
        this.layoutData.id = 3
        this.layoutData.product_type = 'hd_acrylic'
      } else if (this.$route.name === 'MetalPaper') {
        this.layoutData.id = 2
        this.layoutData.product_type = 'aluminum'
      }
    },
  },
  mounted() {
    this.detectProductType()
    localforage.removeItem('layout_data')
  },

  computed: {
    tabs() {
      return [
        {
          title: this.$t('layouts.panel1.panelTitle'),
          panelSize: this.$t('layouts.panel1.panelSize'),
          icon: 'IconGridDefault',
          description: this.$t('layouts.panel1.panelDescription'),
          img: this.rectangle_image,
          value: this.default_layout
        },
        {
          title: this.$t('layouts.panel2.panelTitle'),
          panelSize: this.$t('layouts.panel2.panelSize'),
          icon: 'IconGridVertical',
          description: this.$t('layouts.panel2.panelDescription'),
          img: this.vertical_image,
          value: this.vertical_layout
        },
        {
          title: this.$t('layouts.panel3.panelTitle'),
          panelSize: this.$t('layouts.panel3.panelSize'),
          icon: 'IconGridHorizontal',
          description: this.$t('layouts.panel3.panelDescription'),
          img: this.horizontal_image,
          value: this.horizontal_layout
        },
        {
          title: this.$t('layouts.panel4.panelTitle'),
          panelSize: this.$t('layouts.panel4.panelSize'),
          icon: 'IconGridWave',
          description: this.$t('layouts.panel4.panelDescription'),
          img: this.wave_image,
          value: this.wavy_layout
        }
      ]
    },
    imgWidth() {
      return '100%'
    }
  },
  components: {
    IconGridHorizontal,
    IconGridDefault,
    IconGridVertical,
    IconGridWave
  },
}
</script>
