<template>
  <div class="container">
    <span class="soft-text">
      {{ $t("faq.products.clean-text") }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'FaqProductsClean'
}
</script>

<style scoped>

</style>
