import { axiosInstance } from "@/utils/setAxios";
import alertMixin from "@/mixins/alertMixin";
import i18n from "@/plugins/i18n";
import vm from "vue";
import {orderBy} from "lodash";

export default {
  state: {
    orders: [],
    billing_address: null
  },
  mutations: {
    SET_BILLING_ADDRESS(state, payload) {
      state.billing_address = payload;
    },
    SET_ORDER_LIST(state, payload) {
      state.orders = payload.filter(order => order.status !== "karavan_at_cart");
    }
  },
  actions: {
    GET_ORDERS({ commit }, payload) {
      return axiosInstance.get("orders?page=1&page_size=10000").then(order_response => {
        commit("SET_ORDER_LIST", orderBy(order_response?.data?.invoices, ['created_at'], ['desc']));
      });
    },
    createBillingAddress({ commit }, payload) {
      return axiosInstance
        .patch(`users/update_user`, payload)
        .then(billing_response => {
          const { addresses, contact_people } = billing_response?.data?.contact;
          commit("SET_BILLING_ADDRESS", billing_response?.data?.contact);
          // commit("CREATE_ADDRESS", payload?.contact?.addresses_attributes[0]);
          commit("CREATE_ADDRESS", { address : addresses?.[0], contact_person : contact_people?.[0] });
          return billing_response
        })
        .catch(() => {
          alertMixin.methods.createErrorNotification(i18n.t("notify.wrong"), this._vm);
        });
    }
  },
  getters: {
    getBillingAddress: state => state.billing_address,
    get_order_list: state => state.orders
  }
};
