<template>
  <v-card
    flat
    tile
    color="transparent"
    v-bind="cardAttrs"
  >
    <img :src="src" alt="product_image">
    <v-card-text class="fill-height pt-5">
      <h2 class="title-large">{{ name }}</h2>
      <h3 class="regular-medium">{{ subtitle }}</h3>
      <p class="page-subtitle product-description dark-color--text">{{ detail }}</p>
    </v-card-text>
    <div>
      <h4 class="green-text-bold mb-4">{{ $t('buttons.starting') + ' ' }} {{ price }}$</h4>
      <AppButton color="primary" :to="to">
<!--        {{ buttonText }}-->
        {{ $t('shopNowBtnText') }}
      </AppButton>
    </div>
  </v-card>
</template>


<script>
export default {
  name: 'ProductCard',
  components: {},
  props: {
    price: {
      type: String,
    },
    src: {
      type: String,
      default: require('../../../assets/images/placeholders/placeholder-0.png')
    },
    name: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    buttonText: {
      type: String,
    },
    detail: {
      type: String,
    },
    to: {
      type: [String, Object]
    }


  },

  computed: {
    cardAttrs() {
      return {
        ...this.smAndDown && {to: this.to}
      }
    }
  }
}
</script>


<style lang="scss" scoped>
h2,h3 {
  font-weight: normal;
}
.v-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .v-card__text {
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    > * {
      margin-bottom: 16px;
    }
    .product-description {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    .title-large {
      @media(--t) {
        font-size: 36px;
      }
      line-height: 85% !important;
      letter-spacing: 0.01em !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .regular-medium {
      text-align: left !important;
      line-height: 17px !important;
      letter-spacing: 0.1em !important;
      margin-top: -5px;
    }

    .page-subtitle {
      text-align: left !important;
      line-height: 20px;
    }

  }

  .green-text-bold {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
}
</style>

