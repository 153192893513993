<template>
  <v-app>
    <div style="position: relative; height: 100%;">
      <MobileMenu/>
      <Navigation v-if="!regular_header_routes.includes($route.name)"/>
      <SimpleHeader v-if="simple_header_routes.includes($route.name)"/>
      <!-- <transition mode="out-in" name="fade"> -->
      <router-view :key="$route.fullPath"/>
      <!-- </transition> -->
      <template v-if="isContentLoaded && !hidden_footer_routes.includes($route.name)">
        <SimpleFooter
            v-if="tiny_footer_routes.includes($route.name) && !smAndDown"
            :onProductPage="productPageRoutes.includes($route.name)"
            :style="{
            ...relativeSimpleFooterRoutes.includes($route.name) && {position: 'relative'}
          }"/>
        <Footer v-else/>
      </template>
    </div>

    <MainLoader v-if="initialLoader"/>
    <Loader v-if="percentage_loader.show"/>
<!--    <VersionModal />-->
  </v-app>
</template>

<script>
import Footer from '@/components/shared/navigation/Footer'
import SimpleFooter from '@/components/shared/navigation/SimpleFooter'
import Navigation from '@/components/shared/navigation/Header'
import SimpleHeader from '@/components/shared/navigation/SimpleHeader'
import Loader from '@/components/loader'
import MainLoader from '@/components/MainLoader'
import {mapGetters} from 'vuex'
import localforage from 'localforage'
import MobileMenu from '@/components/menus/MobileMenu'
import {validationMixins} from '@/mixins/validationMixins'
import {full_countries, fr_country_names} from "@/data/countries"
// import VersionModal from "@/components/VersionModal.vue";

export default {
  mixins: [validationMixins],
  data() {
    return {
      scrollPos: 0,
      initialLoader: true,
      // tiny_footer_routes: ["Cart", "Payment", "OrderDetail"],
      tiny_footer_routes: ["Cart", "ShipmentStep", "Payment", "OrderDetail"],
      hidden_footer_routes: [
        // 'ShipmentPage',
        // 'Loader',
        // 'Editor',
        'Configurator',
        // 'Purchase',
        // 'New',
        // 'Shipment',
        // 'Invoice',
        // 'ShipmentStep',
        // 'PaymentStep',
        // 'Conf',
        // 'UpdatePassword',
        // 'Tab',
        // 'LostPassword'
      ],
      simple_header_routes: ['Purchase', 'SignIn', 'SignUp', 'UpdatePassword'],
      simple_footer_routes: [
        'SignIn', 'SignUp', 'UpdatePassword', 'LostPassword',
      ],
      productPageRoutes: [
        // 'Matte','Satin','Metallic', 'Textured','Hemp',
        // 'CanvasPaper', 'MetalPaper', 'AcrylicPaper',
      ],
      regular_header_routes: ['Tab', 'ShipmentPage', 'Purchase', 'SignIn', 'SignUp', 'Invoice', 'Loader', 'UpdatePassword'],
      isContentLoaded : false
    }
  },
  mounted() {

    window.addEventListener("load", () => {
      this.isContentLoaded = true
    })

    if (localStorage.getItem('access_token')) {
      this.$store.dispatch('getCurrentUserData')
    }
    this.$store.dispatch('INIT_AUTH')

    //! Check and Delete Old User Library Dependencies
    localforage.getItem('uil').then(user_image_library => {
      if (!user_image_library?.every(i => i?.thumbnail.hasOwnProperty('base64'))) {
        localforage.removeItem('uil')
      }
    })
    if (this.$i18n.locale !== 'en')
      this.localize(this.$i18n.locale)

    setTimeout(() => this.initialLoader = false, 300)

    document.addEventListener("scroll", this.scrollEvent)
  },
  components: {
    // VersionModal,
    Loader,
    MainLoader,
    SimpleFooter,
    SimpleHeader,
    Navigation,
    Footer,
    MobileMenu
  },
  computed: {
    ...mapGetters({
      percentage_loader: "percentage_loader"
    }),
    relativeSimpleFooterRoutes() {
      return ['Cart', ...this.productPageRoutes]
    },
    simpleFooterRoutes() {
      return [
        ...this.simple_footer_routes,
        ...this.relativeSimpleFooterRoutes
      ]
    }
  },
  methods: {
    scrollEvent(evt) {
      if ((document.body.getBoundingClientRect()).top > this.scrollPos)
        document.body.style.backgroundColor = "#fff"
      else
        document.body.style.backgroundColor = "#000"
      this.scrollPos = (document.body.getBoundingClientRect()).top;
    }
  }
}
</script>

<style lang="scss">
.intercom-lightweight-app-launcher {
  display: none;
  @media (--t) {
    display: block;
  }
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}
</style>
