<template>
  <v-col class="order-payment-card" lg="4" cols="12">
    <div ref="orderItemList" class="order-item-list" :style="scrollBarStyle">
      <slot name="products"/>
    </div>
    <div class="order-summary-section">
      <v-row class="mb-4">
        <v-col cols="12" class="order-title-section py-0">
          <span class="medium">{{ $t("cart.subtotal") }}</span>
          <span class="medium">$ {{ cartTotalAmount.toFixed(2) }}</span>
        </v-col>
        <v-col v-if="hasSampleKit" cols="12" class="order-title-section py-0">
          <span class="medium">{{ $t('cart.sample_pack_kit') }}</span>
          <span class="medium">$ {{ getSampleKit.price.toFixed(2) }}</span>
        </v-col>

        <v-col v-if="!freeShippingAvailable && discountAvailable" cols="12" class="py-0 mt-1">
          <div class="order-title-section">
            <span class="medium">{{ $t("cart.discount") }}
              <span v-if="couponDefinition"> {{ couponDefinition }}</span>
            </span>
            <span class="medium error--text">- $ {{ discountAmount.toFixed(2) }}</span>
          </div>
          <!--          <div class="order-title-section">-->
          <!--            <small class="small black&#45;&#45;text">{{ $t("cart.coupon_code") }}</small>-->
          <!--            <small class="small black&#45;&#45;text">{{ couponCode }}</small>-->
          <!--          </div>-->
        </v-col>
        <v-col v-if="freeShippingAvailable" cols="12" class="order-title-section py-0">
          <span class="medium">{{ $t("cart.shipment") }}</span>
          <span class="medium">Free</span>
        </v-col>
        <v-col v-else-if="shippingPrice" cols="12" class="order-title-section py-0">
          <span class="medium">{{ $t("cart.shipment") }}</span>
          <span class="medium">$ {{ shippingPrice.toFixed(2) }}</span>
        </v-col>
        <v-col cols="12" class="py-0 mt-1">
          <div v-for="(taxItem, index) in _taxes" :key="taxItem.id" class="medium order-title-section"
          >
            <span>{{ `${taxItem.rate_name} (${taxItem.rate_value}%)` }}</span>
            <span>{{ `$ ${taxItem.amount.toFixed(2)}` }}</span>
          </div>
        </v-col>
      </v-row>

      <hr/>

      <v-row>
        <v-col class="order-title-section">
          <span class="medium">{{ $t("cart.total") }}</span>
        </v-col>
        <v-col class="order-value-section">
          <span class="medium">$ {{ totalAmount.toFixed(2) }}</span>
        </v-col>
      </v-row>

      <hr/>

      <template v-if="giftCardAvailable">
        <v-row class="my-0 py-0">
          <v-col class="order-title-section py-0">
            <span class="medium">{{ $t("cart.gift") }}</span>
          </v-col>
          <v-col class="order-value-section py-0">
            <span class="medium error--text">-$ {{ appliedGiftCardsAmount.toFixed(2) }}</span>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col class="order-title-section py-0">
            <span class="medium">{{ $t("cart.grand_total") }}</span>
          </v-col>
          <v-col class="order-value-section py-0">
            <span class="medium">$ {{ cartPayableTotalAmount.toFixed(2) }}</span>
          </v-col>
        </v-row>
      </template>
    </div>
  </v-col>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "OrderPaymentCard",
  components: {
    // GiftCardSummary: () => import("@/components/cards/shared-card/GiftCardSummary"),
  },
  data: () => ({
    scrollBarStyle: {"margin-bottom": 0}
  }),
  computed: {
    ...mapGetters({
      discountAvailable: "discountAvailable",
      discountAmount: "discountAmount",
      couponDefinition: "couponDefinition",
      cartTotalAmount: "cart_total_amount",
      totalAmount: "get_total_amount",
      giftCardAvailable: "giftCardAvailable",
      hasSampleKit: "hasSampleKit",
      getSampleKit: "getSampleKit",
      appliedGiftCardsAmount: "appliedGiftCardsAmount",
      cartPayableTotalAmount: "cartPayableTotalAmount",
      shippingPrice: "get_shipping_price",
      freeShippingAvailable: "freeShippingAvailable",
      _applied_taxes: "_applied_taxes",
      couponCode: "couponCode"
    }),
    _taxes() {
      return this._applied_taxes?.filter((taxItem) => taxItem?.total_amount > 0);
    },
  },
  mounted() {
    const orderItemList = this.$refs?.orderItemList;
    this.scrollBarStyle = {
      "margin-bottom": orderItemList?.scrollHeight > orderItemList?.clientHeight ? "30px" : "0"
    }
  }
};
</script>

<style scoped lang="scss">
.order-payment-card {
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 30px 0;
  background-color: #e8e8e8 !important;
  margin-top: 50px;
  @media (--t) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    margin-top: 0;
  }

  .order-item-list {
    padding-left: 30px;
    padding-right: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 50vh);
    max-height: 40vh;
  }

  .order-summary-section {
    padding-left: 30px;
    padding-right: 30px;
  }

  .order-title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  //.order-title-section {
  //  display: flex;
  //  align-items: flex-start;
  //  flex-direction: column;
  //}

  .order-value-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

hr {
  margin: 12px 0;
  background-color: #9f9f9f;
}
</style>
