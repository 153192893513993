// const {
//     VUE_APP_BASE_URL,
//     VUE_APP_OVERWRITE_BASE_URL,
//     VUE_APP_GIFT_CARD_KARAVAN_PRODUCT_ID,
//     VUE_APP_SAMPLE_PACK_PRODUCT_ID,
//     VUE_APP_SAMPLE_PACK_PRODUCT_VAR_ID
// } = process.env;

const VUE_APP_BASE_URL = "https://puresol-api-st-temp.cap.puresol.io"
const VUE_APP_OVERWRITE_BASE_URL = "https://puresol-api-st-temp.cap.puresol.io"
const VUE_APP_GIFT_CARD_KARAVAN_PRODUCT_ID = "63a9aebb46a5370001ab6933"
const VUE_APP_SAMPLE_PACK_PRODUCT_ID = "62e7e0beede1c20001352437"
const VUE_APP_SAMPLE_PACK_PRODUCT_VAR_ID = "62e7e0beede1c20001352438"
const VUE_APP_ACADEMY_ID = "63bff36bac24bde4ae20a8bf"

export const baseURL = VUE_APP_OVERWRITE_BASE_URL || VUE_APP_BASE_URL;
export const GIFT_CARD_KARAVAN_PRODUCT_ID = VUE_APP_GIFT_CARD_KARAVAN_PRODUCT_ID
export const SAMPLE_PACK_PRODUCT_ID = VUE_APP_SAMPLE_PACK_PRODUCT_ID;
export const SAMPLE_PACK_PRODUCT_VAR_ID = VUE_APP_SAMPLE_PACK_PRODUCT_VAR_ID;
export const ACADEMY_ID = VUE_APP_ACADEMY_ID;
