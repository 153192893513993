export const productSizeMixin = {
    methods: {
        checkAvailabilityForSizeValue(size, comparedValue, baseValue) {
            if (!comparedValue)
                return this.minSize <= size.value.inch && size.value.inch <= this.maxSize

            // baseValue minSize listesindeki en büyükten büyükse o zaman comparedValue'ya kadar return edebiliriz.
            return comparedValue * size.value.inch >= this.minArea
                && comparedValue * size.value.inch <= this.maxArea
                && size.value.inch <= this.maxSize
                && size.value.inch >= this.minSize
        }
    },
    computed: {
        customWidthList() {
            const minSize = Math.min(...[this.verticalSizes[1], this.horizontalSizes[1]].flat())
            // Eğer seçilen width ya da height minSize'dan büyükse fakat maxMinSize'dan küçük ise minSize maxMinSize olmalı..
            if(this.selected_product_height < this.maxMinSize && this.selected_product_height > this.minMinSize){
                return this._number_list?.filter(i => i?.value?.inch >= this.maxMinSize)
            } else if (this.selected_product_height > minSize) {
                return this._number_list?.filter(i => i?.value?.inch <= minSize)
            } else {
                return this._number_list?.filter(item => {
                        return this.checkAvailabilityForSizeValue(item, this.selected_product_height)
                    }
                )
            }
        },
        customHeightList() {
            const minSize = Math.min(...[this.verticalSizes[1], this.horizontalSizes[1]].flat())
            // Eğer seçilen width ya da height minSize'dan büyükse fakat maxMinSize'dan küçük ise minSize maxMinSize olmalı..
            if(this.selected_product_width < this.maxMinSize && this.selected_product_width > this.minMinSize){
                return this._number_list?.filter(i => i?.value?.inch >= this.maxMinSize)
            } else if (this.selected_product_width > minSize) {
                return this._number_list?.filter(i => i?.value?.inch <= minSize)
            } else {
                return this._number_list?.filter(item => {
                        return this.checkAvailabilityForSizeValue(item, this.selected_product_width)
                    }
                )
            }
        },
        minArea() {
            return (this.horizontalSizes[0] || 0) * this.verticalSizes[0] || 0
        },
        maxArea() {
            return (this.horizontalSizes[1] || 0) * this.verticalSizes[1] || 0
        },
        minSize() {
            // Eğer seçilen width ya da height minSize'dan büyükse fakat maxMinSize'dan küçük ise minSize maxMinSize olmalı..
            return Math.min(...[this.verticalSizes, this.horizontalSizes].flat())
        },
        maxSize() {
            return Math.max(...[this.verticalSizes, this.horizontalSizes].flat())
        },
        minMinSize() {
            return Math.min(...[this.verticalSizes[0], this.horizontalSizes[0]].flat())
        },
        maxMinSize(){
            return Math.max(...[this.verticalSizes[0], this.horizontalSizes[0]].flat())
        }
    },
}
