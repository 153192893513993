<template>
  <div>
    <div class="sheet-header">
      <span class="title">{{ title_one }} & {{ title_two }}</span>
      <v-spacer></v-spacer>
      <v-btn class="ma-2" color="lighten-2" icon text @click="$emit('onClose')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-divider class="mt-0 mb-0"/>
  </div>
</template>

<script>
export default {
  name: "SheetHeader",
  props: {
    title_one: {
      type: String,
    },
    title_two: {
      type: String
    }
  }
}
</script>

<style scoped>
.sheet-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
}
</style>