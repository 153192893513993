<template>
  <v-container>
    <h6 class="small-title mb-6">{{ $t('profile.add_address') }}</h6>
    <AppForm
      @keydown.enter.native="validateAndSubmit"
      :form-fields="formFields"
      :loading="loading"
      :form-data="userData"
      ref="form"
      validationPerformed
    />
    <AppButton
      @click="validateAndSubmit"
      color="primary"
      height="50"
      class="mt-6"
      :min-width="$vuetify.breakpoint.mdAndUp ? 400 : 250"
      :loading="loading"
    >
      {{ $t('buttons.save_address') }}
    </AppButton>
  </v-container>
</template>

<script>
import { addressPageMixins } from '@/mixins/addressPageMixins'

export default {
  name: 'Billing',
  mixins: [addressPageMixins],
  data: () => ({
    successMsg: 'notify.address_saved',
    actionName: 'createBillingAddress'
  }),
}
</script>
