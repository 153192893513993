<template>
  <div class="acrylic-page">
    <Heading :subtitle="$t('products.metal.subtitle')" :title="$t('products.metal.title')"/>

    <PhotoPaperSection
      :feature_five="$t('products.metallic.specified.five')"
      :feature_four="$t('products.metal.specified.four')"
      :feature_one="$t('products.metal.specified.one')"
      :feature_three="$t('products.metal.specified.three')"
      :feature_two="$t('products.metal.specified.two')"
      :images="images"
      :subtitle="$t('products.metal.description')"
      :title="$t('products.metal.about').toUpperCase()"
      artist="Jeremie legault "
      social="@thenew.aura"
    />


    <ProductVideoSection src="/video/metal.mp4" />

    <PricingNew/>

    <MaterialCard
      :feature_one="$t('printing-metal.one')"
      :feature_two="$t('printing-metal.two')"
      :is_numbered="false"
      :src="metal_image"
      :subtitle="$t('printing-metal.subtitle')"
      :title="$t('printing-acrylic.title')"
    />

    <HardwareSection/>


    <LayoutTab
      :horizontal_image="canvas_layout_three"
      :rectangle_image="canvas_layout_one"
      :vertical_image="canvas_layout_two"
      :wave_image="canvas_layout_four"
    />

    <UsagePlaces
      :feature_four="$t('products.metal.usage.features.feature_four')"
      :feature_one="$t('products.metal.usage.features.feature_one')"
      :feature_three="$t('products.metal.usage.features.feature_three')"
      :feature_two="$t('products.metal.usage.features.feature_two')"
      :handcrafted_description="$t('products.metal.usage.handcrafted.description')"
      :handcrafted_moments="$t('products.metal.usage.handcrafted.moments')"
      :handcrafted_team="$t('products.metal.usage.handcrafted.team')"
      :handcrafted_title="$t('products.metal.usage.handcrafted.title')"
      :left_image="metal_image_one"
      :right_image="metal_image_two"
      :vibrant_description="$t('products.metal.usage.inks.description')"
      :vibrant_title="$t('products.metal.usage.inks.title')"
      product_artist="Jeremie legault"
      product_social="@thenew.aura"
    />

    <ProductReviewSection/>

    <WorthSection/>
  </div>
</template>

<script>
import PhotoPaperSection from '@/components/section/paper-info-section'
import CustomText from '@/components/shared/CustomText'
import FrameCard from '@/components/cards/shared-card/frame-card'
import LayoutTab from '@/components/tab/layout-tab/LayoutTab'
import MaterialCard from '@/components/cards/shared-card/material-card'

import HardwareSection from '@/components/section/hardware-section'
import UsagePlaces from '@/components/section/example-usage-section'
import WorthSection from '@/components/section/stories-worth-section'
import PricingNew from '@/components/cards/pricing-card-new'
import ProductReviewSection from '../../components/product-review/product-review-section'

export default {
  name: 'Metal',
  metaInfo() {
    return {
      title: this.$t('titles.metal'),
      titleTemplate: '%s - Blankspace',
       htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.$t('titles.metal-content')},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  data() {
    return {
      metal_image: require('../../assets/images/product-type/product-1.png'),
      metal_image_one: '/img/papers/15.webp',
      metal_image_two: '/img/papers/17.webp',

      canvas_layout_one: require('../../assets/images/grid/metal/rectangle.webp'),
      canvas_layout_two: require('../../assets/images/grid/metal/vertical.webp'),
      canvas_layout_three: require('../../assets/images/grid/metal/horizontal.webp'),
      canvas_layout_four: require('../../assets/images/grid/metal/wave.webp'),

      images: [
        {id: 1, src: '/img/papers/opt/metal-1-opt.jpg'},
        {id: 2, src: '/img/papers/opt/metal-0-opt.jpg'}
      ]
    }
  },

  components: {
    ProductReviewSection,
    PricingNew,
    WorthSection,
    UsagePlaces,
    HardwareSection,
    MaterialCard,
    LayoutTab,
    FrameCard,
    CustomText,
    PhotoPaperSection
  }
}
</script>

<style lang="scss" scoped>
.acrylic-page {
  padding-top: 35px;
  overflow-x: hidden !important;
  @media (--t) {
    padding-top: 65px;
  }
}
</style>
