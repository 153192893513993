<template>
  <v-container :fluid="smAndDown">
                <span class="soft-text">{{ $t("faq.image.format-text.one") }}</span
                ><span>  <a class="soft-text font-weight-bold"
                           href="mailto:support@blankspace.ink?subject=I have questions."
                           style="color: black!important;"> support@blankspace.ink</a>.</span>
    <br/><br/>
    <span class="soft-text">{{ $t("faq.image.format-text.two") }}</span>
    <span><a class="font-weight-bold soft-text" href="http://www.scantips.com/calc.html"
             style="color: black!important;" target="_blank">http://www.scantips.com/calc.html </a>. </span>
    <span class="soft-text">{{ $t("faq.image.format-text.two-footer") }}</span>
    <br/><br/>
    <span class="soft-text">{{ $t("faq.image.format-text.three") }}</span>
    <br/><br/>
    <span class="soft-text">{{ $t("faq.image.format-text.four") }}</span>
    <br/><br/>
    <span class="soft-text">{{ $t("faq.image.format-text.five") }}</span>
    <div class="d-flex flex-column mt-5">
      <div>
        <span class="size-item">{{ $t("faq.image.size-item.one-title") }}</span>
        <span class="size-text">{{ $t("faq.image.size-item.one-text") }}</span>
      </div>
      <div>
        <span class="size-item">{{ $t("faq.image.size-item.two-title") }}</span>
        <span class="size-text">{{ $t("faq.image.size-item.two-text") }}</span>
      </div>

      <div>
        <span class="size-item">{{ $t("faq.image.size-item.three-title") }}</span>
        <span class="size-text">{{ $t("faq.image.size-item.three-text") }}</span>
      </div>

      <div>
        <span class="size-item">{{ $t("faq.image.size-item.four-title") }}</span>
        <span class="size-text">{{ $t("faq.image.size-item.four-text") }}</span>
      </div>

      <div>
        <span class="size-item">{{ $t("faq.image.size-item.five-title") }}</span>
        <span class="size-text">{{ $t("faq.image.size-item.five-text") }}</span>
      </div>
    </div>
    <br/><br/>
  </v-container>
</template>

<script>
export default {
  name: 'FaqImageFormat'
}
</script>

<style scoped>

</style>
