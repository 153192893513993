<template>
  <div class="material-card">
    <v-container>
      <Heading :title="title" :subtitle="subtitle"/>

      <div class="card">
        <v-row>
          <v-col cols="12" lg="6" md="12">
            <div class="card-bodies">
              <div>
                <div class="text-container">
                  <span class="number">1. </span><span class="value">{{ feature_one }}</span>
                </div>
                <div class="text-container">
                  <span class="number">2. </span><span class="value">{{ feature_two }}</span>
                </div>
                <div class="text-container">
                  <span v-if="feature_three" class="number">3. </span><span v-if="feature_three"
                                                                            class="value">{{ feature_three }}</span>
                </div>
              </div>
              <p class="page-subtitle">{{ feature_four }}</p>
              <p class="page-subtitle">{{ description }}</p>
              <p class="page-subtitle">{{ secondary }}</p>
            </div>
          </v-col>

          <v-col cols="12" class="image-container" lg="6" md="12">
            <img :src="src" alt="metal_press_image" class="img-fluid">
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import CustomText from "@/components/shared/CustomText";
import Heading from "../../Heading";

export default {
  name: "MaterialCard",
  data() {
    return {}
  },
  components: {
    Heading,
    CustomText,
  },
  props: {
    is_numbered: {
      type: Boolean,
      default: true,
    },
    src: {
      type: String,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    features: {
      type: String,
    },
    description: {
      type: String,
    },
    secondary: {
      type: String,
    },
    feature_one: {
      type: String,
    },
    feature_two: {String},
    feature_three: {String},
    feature_four: {String},
  }
}
</script>

<style lang="postcss" scoped>

.material-card {
  padding-top: 0;
  padding-bottom: 0;
  @media (--t) {
    padding-top: 125px;
    padding-bottom: 125px;
    .container {
      max-width: 1140px;
    }
  }

  .page-subtitle {
    font-size: 16px !important;
    line-height: 19px !important;
    letter-spacing: 0.15em !important;
  }

  ul {
    li {

      list-style: none;
      list-style-type: none;
    }
  }

  .card {
    border: 0;
    .row {
      margin-top: 48px;
    }

    .card-bodies {

      .number {
        font-family: var(--font-family--alethia) !important;
        font-style: normal;
        font-weight: bold !important;
        font-size: 27px !important;
        line-height: 80% !important;
        letter-spacing: 0.02em !important;
        color: #000000 !important;

      }

      .value {
        font-family: Sk-Modernist, 'sans-serif' !important;
        font-style: normal;
        font-weight: normal;
        font-size: 18px !important;
        line-height: 150%;
        letter-spacing: 0.02em !important;
        color: #000000 !important;
      }

      span {
        font-weight: bold;
        font-family: var(--font-family--alethia) !important;
        font-size: 27px !important;
      }

      img {
        margin-top: 50px !important;
        width: 500px !important;
      }

      p {
        text-align: left;
        font-family: Sk-Modernist, 'sans-serif';
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 18px !important;
        line-height: 150% !important;
        letter-spacing: 0.02em !important;
        color: #000000 !important;
      }

      .text-container:not(:first-child) {
        margin-top: 32px;
      }

      :nth-child(3) {
        margin-bottom: 30px !important;
      }


    }


  }
}
.image-container {
  @media (--t) {
    padding-right: 0 !important;
  }

  img {
    @media (--t) {
      float: right;
    }
  }
}
</style>
