<template>
  <div class="select">
    <div @click="isSelect = !isSelect" class="dropdown">
      <h6>English</h6>
      <Down class="ml-2 mb-1" v-if="!isSelect"/>
      <Up class="ml-2 mb-1" v-if="isSelect"/>
    </div>
    <div class="box" v-if="isSelect">
      <span>English</span>
      <span>French</span>
    </div>

  </div>
</template>

<script>

import Down from "@/assets/svg-icons/icons/down-mini.svg";
import Up from "@/assets/svg-icons/icons/up-mini.svg";

export default {
  name: "Select",
  components: {
    Down, Up,
  },
  data() {
    return {
      isSelect: false,
    }
  }
}
</script>

<style scoped>
.select {
  cursor: pointer;


  h6 {
    font-family: Sk-Modernist;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #535353;
  }
  span {
    display: block;
  }

  .dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .box {
    z-index: 99;
    padding: 15px;
    :nth-child(2) {
      cursor: pointer;
      margin-top: 10px;
    }
  }

  li:nth-child(2) {
    margin-top: 20px;
  }
}
</style>