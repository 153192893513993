<template>
  <div class="alert-box">
    <v-icon color="#e03d3e" size="25">mdi mdi-alert-circle-outline</v-icon>
    <div>{{ $t('configurator.resize.alert') }}</div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    condition: {
      type: String
    }
  },
};
</script>

<style lang="scss">
//.alert-box {
//  background-color: #e03d3e;
//  height: 80px;
//  width: 210px;
//  color: #fff !important;;
//  position: absolute;;
//  top: 0;
//  right: 0;
//  padding: 5px 5px !important;
//  box-sizing: content-box;
//  font-size: 14px;
//  line-height: 16px;
//
//  i {
//    margin-bottom: 5px;
//    color: #fff;
//  }
//}
.configurator-preview {
  .alert-box {
    width: 100%;
    color: #e03d3e !important;;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    top: 10px;
    left: 10px;
    box-sizing: content-box;
    font-size: 18px;
    line-height: 18px;
  }

  &.layoutMode {
    .alert-box {
      color: #eee !important;
      i {
        color: #eee !important;
      }
    }
  }

}

</style>
