import { get } from "lodash";
export const globalCommandsMixins = {
  methods: {
    getProp(item, path, defaultValue) {
      return get(item, path, defaultValue);
    },
    excludePick(object, exludes) {
      return Object?.keys(object)?.reduce((obj, key) => {
        if (!exludes.includes(key)) obj[key] = object[key];
        return obj;
      }, {});
    },
    showDate(date) {
      if (!date) return '-';
      const dateObject = {
        dateStyle: 'full',
        timeStyle: 'short'
      };
      return new Intl.DateTimeFormat(navigator.language, dateObject).format(new Date(date));
    },
  },

  computed: {
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
