import i18n from "@/plugins/i18n";
import Home from "../views/routes/main/home-page.vue";

export const routes = [
    {
        path: i18n.t("routes.home"),
        meta: {route_key: "home"},
        name: "Home",
        component: Home,
        alias: [
            i18n.getLocaleMessage('en')['routes']['home'],
            i18n.getLocaleMessage('fr')['routes']['home'],
        ],
    },
    {
        path: i18n.t("routes.about"),
        meta: {route_key: "about"},
        name: "About",
        component: () => import("../views/routes/main/about-page.vue"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['about'],
            i18n.getLocaleMessage('fr')['routes']['about'],
        ],
    },
    {
        path: i18n.t("routes.blog"),
        meta: {route_key: "blog"},
        name: "Blog",
        component: () => import("../views/routes/main/blog-page.vue"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['blog'],
            i18n.getLocaleMessage('fr')['routes']['blog'],
        ],
    },
    {
        path: i18n.t("routes.cart"),
        meta: {route_key: "cart"},
        name: "Cart",
        alias: [
            i18n.getLocaleMessage('en')['routes']['cart'],
            i18n.getLocaleMessage('fr')['routes']['cart'],
        ],
        component: () => import("../views/routes/cart/shopping-cart.vue"),
    },
    {
        path: i18n.t("routes.shop"),
        meta: {route_key: "shop"},
        name: "Shop",
        component: () => import("../views/routes/main/shop-page.vue"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['shop'],
            i18n.getLocaleMessage('fr')['routes']['shop'],
        ],
    },
    {
        path: i18n.t("routes.matte"),
        meta: {route_key: "matte"},
        name: "Matte",
        component: () => import("../views/paper-type/matte-paper.vue"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['matte'],
            i18n.getLocaleMessage('en')['routes']['matte_short'],
            i18n.getLocaleMessage('fr')['routes']['matte'],
            i18n.getLocaleMessage('fr')['routes']['matte_short'],
        ],
    },
    {
        path: i18n.t("routes.satin"),
        meta: {route_key: "satin"},
        name: "Satin",
        component: () => import("../views/paper-type/satin-paper.vue"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['satin'],
            i18n.getLocaleMessage('en')['routes']['satin_short'],
            i18n.getLocaleMessage('fr')['routes']['satin'],
            i18n.getLocaleMessage('fr')['routes']['satin_short'],
        ],
    },
    {
        path: i18n.t("routes.metallic"),
        meta: {route_key: "metallic"},
        name: "Metallic",
        component: () => import("../views/paper-type/metallic-paper.vue"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['metallic'],
            i18n.getLocaleMessage('fr')['routes']['metallic'],
            i18n.getLocaleMessage('en')['routes']['metallic_short'],
            i18n.getLocaleMessage('fr')['routes']['metallic_short'],
        ],
    },
    {
        path: i18n.t("routes.textured"),
        meta: {route_key: "textured"},
        name: "Textured",
        component: () => import("../views/paper-type/textured-paper.vue"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['textured'],
            i18n.getLocaleMessage('en')['routes']['textured_short'],
            i18n.getLocaleMessage('fr')['routes']['textured'],
            i18n.getLocaleMessage('fr')['routes']['textured_short'],
        ],
    },
    {
        path: i18n.t("routes.hemp"),
        meta: {route_key: "hemp"},
        name: "Hemp",
        component: () => import("../views/paper-type/hemp-paper.vue"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['hemp'],
            i18n.getLocaleMessage('en')['routes']['hemp_short'],
            i18n.getLocaleMessage('fr')['routes']['hemp'],
            i18n.getLocaleMessage('fr')['routes']['hemp_short'],
        ],
    },
    {
        path: i18n.t("routes.gift"),
        meta: {route_key: "gift"},
        name: "GiftCertificate",
        component: () => import("../views/Gift-Certificate"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['gift'],
            i18n.getLocaleMessage('fr')['routes']['gift'],
            i18n.getLocaleMessage('en')['routes']['gift_short'],
            i18n.getLocaleMessage('fr')['routes']['gift_short'],
        ],
    },
    {
        path: i18n.t("routes.canvas"),
        meta: {route_key: "canvas"},
        name: "CanvasPaper",
        component: () => import("../views/paper-type/canvas-paper"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['canvas'],
            i18n.getLocaleMessage('en')['routes']['canvas_short'],
            i18n.getLocaleMessage('fr')['routes']['canvas'],
            i18n.getLocaleMessage('fr')['routes']['canvas_short'],
        ],
    },
    {
        path: i18n.t("routes.metal"),
        meta: {route_key: "metal"},
        name: "MetalPaper",
        component: () => import("../views/paper-type/metal-paper"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['metal'],
            i18n.getLocaleMessage('en')['routes']['metal_short'],
            i18n.getLocaleMessage('fr')['routes']['metal'],
            i18n.getLocaleMessage('fr')['routes']['metal_short'],
        ],
    },
    {
        path: i18n.t("routes.acrylic"),
        meta: {route_key: "acrylic"},
        name: "AcrylicPaper",
        component: () => import("../views/paper-type/acrylic-paper"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['acrylic'],
            i18n.getLocaleMessage('en')['routes']['acrylic_short'],
            i18n.getLocaleMessage('fr')['routes']['acrylic'],
            i18n.getLocaleMessage('fr')['routes']['acrylic_short'],
        ],
    },
    {
        // slug gelecek
        path: "/blog/:slug",
        name: "BlogDetail",
        component: () => import("../views/routes/main/blog-detail"),
    },
    {
        path: i18n.t("routes.configurator"),
        meta: {route_key: "configurator"},
        name: "Configurator",
        component: () => import("../views/routes/configurator/index"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['configurator'],
            i18n.getLocaleMessage('fr')['routes']['configurator'],
        ],
    },
    {
        name: "Payment",
        path: i18n.t("routes.payment"),
        meta: {route_key: "payment"},
        component: () => import("../views/routes/cart/payment-step"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['payment'],
            i18n.getLocaleMessage('fr')['routes']['payment'],
        ],
    },
    {
        name: "Terms",
        path: i18n.t("routes.terms"),
        meta: {route_key: "terms"},
        component: () => import("../views/routes/main/terms-page"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['terms'],
            i18n.getLocaleMessage('fr')['routes']['terms'],
        ],
    },
    {
        name: "Privacy",
        path: i18n.t("routes.policy"),
        meta: {route_key: "policy"},
        component: () => import("../views/routes/main/privacy-page"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['policy'],
            i18n.getLocaleMessage('fr')['routes']['policy'],
        ],
    },
    {
        path: "/faq/:section/:question/:questionIndex",
        name: "FaqNavigateToQuestion",
        component: () => import("../views/routes/main/faq-page.vue"),
    },
    {
        path: "/faq",
        name: "Faq",
        component: () => import("../views/routes/main/faq-page.vue"),
    },
    {
        path: "/profile",
        name: "Profile",
        component: () => import("../views/routes/main/profile/profile.vue"),
        children: [
            {path: "dashboard", name: "Dashboard", component: () => import("../views/routes/main/profile/dashboard")},
            {
                path: i18n.t("routes.account") || "account-detail",
                meta: {route_key: "account"},
                name: "Account Details",
                component: () => import("../views/routes/main/profile/account-details"),
                alias: [
                    i18n.getLocaleMessage('en')['routes']['account'],
                    i18n.getLocaleMessage('fr')['routes']['account'],
                ],
            },
            {
                path: i18n.t("routes.change-password"),
                meta: {route_key: "change-password"},
                name: "Change Password",
                component: () => import("../views/routes/main/profile/new-password"),
                alias: [
                    i18n.getLocaleMessage('en')['routes']['change-password'],
                    i18n.getLocaleMessage('fr')['routes']['change-password'],
                ],
            },
            {
                path: i18n.t("routes.orders"),
                name: "Orders",
                meta: {route_key: "orders"},
                component: () => import("../views/routes/main/profile/orders"),
                alias: [
                    i18n.getLocaleMessage('en')['routes']['orders'],
                    i18n.getLocaleMessage('fr')['routes']['orders'],
                ],
            },
            {
                path: i18n.t("routes.address"),
                name: "Address",
                meta: {route_key: "address"},
                component: () => import("../views/routes/main/profile/address"),
                alias: [
                    i18n.getLocaleMessage('en')['routes']['address'],
                    i18n.getLocaleMessage('fr')['routes']['address'],
                ],
            },
            {
                path: i18n.t("routes.create-new-address"),
                meta: {route_key: "create-new-address"},
                name: "Billing Address",
                component: () => import("../views/routes/main/profile/billing-address"),
                alias: [
                    i18n.getLocaleMessage('en')['routes']['create-new-address'],
                    i18n.getLocaleMessage('fr')['routes']['create-new-address'],
                ]
            },
            {
                path: "update-address/:index",
                name: "Update Address",
                component: () => import("../views/routes/main/profile/update-address"),
            },
        ],
    },
    {
        path: i18n.t("routes.shipment"),
        meta: {route_key: "shipment"},
        name: "ShipmentStep",
        component: () => import("../views/routes/cart/shipment-step"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['shipment'],
            i18n.getLocaleMessage('fr')['routes']['shipment'],
        ],
    },
    {
        path: "/login",
        name: "SignIn",
        component: () => import("../views/routes/auth/sign-in"),
    },
    {
        path: "/create-account",
        name: "SignUp",
        component: () => import("../views/routes/auth/sign-up"),
    },
    {
        // path: "/lost-password",
        path: i18n.t("routes.lost-password"),
        meta: {route_key: "lost-password"},
        name: "LostPassword",
        component: () => import("../views/routes/auth/forgot-password"),
        alias: [
            i18n.getLocaleMessage('en')['routes']['lost-password'],
            i18n.getLocaleMessage('fr')['routes']['lost-password'],
        ],
    },
    {
        path: "/contact-us",
        name: "ContactUs",
        component: () => import("../views/routes/main/contact-page"),
    },
    {
        name: "OrderDetail",
        path: "/order-detail",
        component: () => import("../views/routes/cart/summary-step"),
    },
    {
        path: "/update-password",
        name: "UpdatePassword",
        component: () => import("../views/routes/auth/update-password.vue"),
    },
    {
        path: "/prev-order-detail/:id",
        name: "PrevOrder",
        component: () => import("../views/routes/main/profile/prev-order-detail"),
    },

    {
        path: "*",
        name: "404",
        component: () => import("../views/routes/main/home-page.vue"),
    },
];
