<template>
  <div>
    <div class="overlay"></div>
    <div class="spin"></div>
  </div>
</template>

<script>
export default {
  name:"LoaderCircle"
}
</script>

<style scoped>
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  background-color: rgba(241, 241, 241, 0.79);
  position: absolute;
  z-index: 99;
  left: 0;
  right: 0;
  bottom: 0;
  top:0;

}

.spin {
  position: absolute;
  z-index: 9999;
  left: 50%;
  bottom: 50%;
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #077D60;
  border-radius: 50%;
  content: "";
  height: 40px;
  width: 40px;
  position: absolute;
  top: 10%;
  left: 10%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}


@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
</style>