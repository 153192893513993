<template>
  <div class="paper-type">
    <Heading :subtitle="$t('paper.textured.subtitle').toUpperCase()" :title="$t('paper.textured.title')"/>

    <PhotoPaperSection
      :feature_five="$t('paper.textured.specified.five')"
      :feature_four="$t('paper.textured.specified.four')"
      :feature_one="$t('paper.textured.specified.one')"
      :feature_six="$t('paper.textured.specified.six')"
      :feature_three="$t('paper.textured.specified.three')"
      :feature_two="$t('paper.textured.specified.two')"
      :images="images"
      :subtitle="$t('paper.textured.description')"
      :title="$t('paper.textured.about')"
      artist="Audrey Chaïken"
      social="@audreycha.art"
    />

    <PricingNew :vertical-sizes="[5, 94]" :horizontal-sizes="[7, 35]"/>
    <PaperTab/>

    <UsagePlaces
      :feature_five="$t('paper.textured.usage.features.feature_five')"
      :feature_four="$t('paper.textured.usage.features.feature_four')"
      :feature_one="$t('paper.textured.usage.features.feature_one')"
      :feature_six="$t('paper.textured.usage.features.feature_six')"
      :feature_three="$t('paper.textured.usage.features.feature_three')"
      :feature_two="$t('paper.textured.usage.features.feature_two')"
      :handcrafted_description="$t('paper.textured.usage.handcrafted.description')"
      :handcrafted_moments="$t('paper.textured.usage.handcrafted.moments')"
      :handcrafted_team="$t('paper.textured.usage.handcrafted.team')"
      :handcrafted_title="$t('paper.textured.usage.handcrafted.title')"
      :left_image="textured_image_one"
      :right_image="textured_image_two"
      :vibrant_description="$t('paper.textured.usage.vibrant.description')"
      :vibrant_title="$t('paper.textured.usage.vibrant.title')"
      :vibrant_footer="$t('paper.textured.usage.vibrant.footer')"
      product_artist="Audrey Chaïken"
      product_social=" @audreycha.art"
    />

    <ProductReviewSection/>

    <WorthSection/>
  </div>
</template>

<script>

import PhotoPaperSection from '@/components/section/paper-info-section'
import UsagePlaces from '@/components/section/example-usage-section'
import WorthSection from '@/components/section/stories-worth-section'
import PricingNew from '@/components/cards/pricing-card-new'
import PaperTab from '@/components/tab/paper-tab/PaperTab'
import ProductReviewSection from '../../components/product-review/product-review-section'


export default {
  name: 'Satin',
  metaInfo() {
    return {
      title: this.$t('titles.textured'),
      titleTemplate: '%s - Blankspace',
       htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.$t('titles.textured-content')},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  components: {
    ProductReviewSection,
    PricingNew,
    WorthSection,
    UsagePlaces,
    PhotoPaperSection,
    PaperTab
  },
  data() {
    return {
      textured_image_one: require('../../assets/images/usage-new/5.png'),
      textured_image_two: require('../../assets/images/usage-new/4.png'),

      images: [
        {id: 1, src: '/img/papers/opt/textured-1-opt.jpg'},
        {id: 2, src: '/img/papers/opt/textured-0-opt.jpg'}
      ],
    }
  },
}
</script>

<style scoped>


.paper-type {
  padding-top: 35px;
  overflow-x: hidden !important;
  @media (--t) {
    padding-top: 65px;
  }

  h2 {
    letter-spacing: 0.15em;
  }

  .story-section {
    background-color: #F9F9F9;
    padding-top: 85px;

    .story-items {
      margin-top: 51px;
    }
  }
}

</style>
