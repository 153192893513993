<template>
  <div class="paper-type">

    <Heading :title="$t('products.metallic.title')" titleTag="h1" :subtitle="$t('products.metallic.subtitle')"/>

    <PhotoPaperSection
      :feature_five="$t('products.metallic.specified.five')"
      :feature_four="$t('products.metallic.specified.four')"
      :feature_one="$t('products.metallic.specified.one')"
      :feature_six="$t('products.metallic.specified.six')"
      :feature_three="$t('products.metallic.specified.three')"
      :feature_two="$t('products.metallic.specified.two')"
      :images="images"
      :subtitle="$t('products.metallic.description')"
      :title="$t('products.metallic.about')"
    />


    <PricingNew :vertical-sizes="[5, 36]"/>

    <PaperTab/>

    <UsagePlaces
      :feature_five="$t('products.metallic.usage.features.feature_five')"
      :feature_four="$t('products.metallic.usage.features.feature_four')"
      :feature_one="$t('products.metallic.usage.features.feature_one')"
      :feature_six="$t('products.metallic.usage.features.feature_six')"
      :feature_three="$t('products.metallic.usage.features.feature_three')"
      :feature_two="$t('products.metallic.usage.features.feature_two')"
      :handcrafted_description="$t('products.metallic.usage.handcrafted.description')"
      :handcrafted_moments="$t('products.metallic.usage.handcrafted.moments')"
      :handcrafted_team="$t('products.metallic.usage.handcrafted.team')"
      :handcrafted_title="$t('products.metallic.usage.handcrafted.title')"
      :left_image="metallic_image_one"
      :right_image="metallic_image_two"
      :vibrant_description="$t('products.metallic.usage.vibrant.rest')"
      :vibrant_footer="$t('products.metallic.usage.vibrant.footer')"
      :vibrant_title="$t('products.metallic.usage.vibrant.title')"
    />

    <ProductReviewSection/>

    <WorthSection/>


  </div>
</template>

<script>

import PhotoPaperSection from '@/components/section/paper-info-section'
import UsagePlaces from '@/components/section/example-usage-section'
import WorthSection from '@/components/section/stories-worth-section'
import PricingNew from '@/components/cards/pricing-card-new'

import PaperTab from '@/components/tab/paper-tab/PaperTab'
import ProductReviewSection from '../../components/product-review/product-review-section'

export default {
  name: 'Satin',
  metaInfo() {
    return {
      title: this.$t('titles.metallic'),
      titleTemplate: '%s - Blankspace',
       htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.$t('titles.metallic-content')},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  components: {
    ProductReviewSection,
    PricingNew,
    WorthSection,
    UsagePlaces,
    PhotoPaperSection,
    PaperTab
  },
  data() {
    return {
      metallic_image_one: require('../../assets/images/usage-new/8.png'),
      metallic_image_two: require('../../assets/images/usage-new/9.png'),
      images: [
        {id: 1, src: '/img/papers/opt/metallic-1-opt.jpg'},
        {id: 2, src: '/img/papers/opt/metallic-0-opt.jpg'}
      ],
    }
  }
}
</script>

<style scoped>


.paper-type {
  padding-top: 35px;
  overflow-x: hidden !important;
  @media (--t) {
    padding-top: 65px;
  }
}

h2 {
  letter-spacing: 0.15em;
  @media (--t) {
    margin-top: -3px;
  }
}

</style>
