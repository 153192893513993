<template>
  <div class="processing-loader">
    <image-loading class="loading" :text="loaderText" :use-percentage="usePercentage" :percentage="loader_percentage"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import imageLoading from "./imageLoading.vue";
import {isNaN} from "lodash";

export default {
  name: "Loader",
  components: {imageLoading},
  computed: {
    ...mapGetters({
      loader_percentage: "loader_percentage",
      image_processing: "image_processing",
      percentage_loader : "percentage_loader"
    }),
    loaderText() {
      if (this.percentage_loader.uploading) return "uploading"
      else if (this.percentage_loader.processing) return "processing"
      else if (this.percentage_loader.finishing) return "finishing"
    },
    usePercentage(){
      return this.loader_percentage > 0
    }
  }
};
</script>

<style lang="scss">
.processing-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffda;
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  overflow: hidden !important;
}

.progress-bar {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: radial-gradient(closest-side, #fff 79%, transparent 80% 100%), conic-gradient(#009071 10%, #ccc 0);
}
</style>
