<template>
  <v-card
    outlined
    @click="$emit('click')"
    class="py-3 px-3"
    tile
  >
    <v-img
      height="75"
      width="100%"
      style="max-width: 100px!important;"
      :src="image.thumbnail.base64"
      class="configurator-content-material"
    >
      <template v-slot:placeholder>
        <v-row align="center" class="fill-height ma-0" justify="center">
          <v-progress-circular color="grey lighten-5" indeterminate/>
        </v-row>
      </template>
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: 'UserLibraryItemCard',
  props: {
    image: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped>

</style>
