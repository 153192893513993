<template>
  <div class="container">
    <template v-if="english">
      <span class="soft-text">{{ $t('faq.order.ship-text') }}</span>
      <span>
        <a class="soft-text font-weight-bold"
           href="mailto:support@blankspace.ink?subject=I have questions."
           style="color: black!important;"
        >
          support@blankspace.ink
        </a>
      </span>

      <span class="soft-text">{{ $t('faq.order.ship-text-two') }}</span>
    </template>
    <template v-if="french">
      <span class="soft-text">{{ $t("faq.order.ship-text-one") }}</span>

      <span>
        <a class="soft-text font-weight-bold"
           href="mailto:support@blankspace.ink?subject=I have questions."
           style="color: black!important;"
        >
          support@blankspace.ink
        </a>
      </span>
      <span class="soft-text">{{ $t("faq.order.ship-text-three") }}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'FaqOrderShip',
  props: {
    english: {
      type: Boolean,
      default: () => false
    },
    french: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style scoped>

</style>
