<template>
  <div class="wavy-panel" :style="imageContainerSizes">
    <template v-if="imageUrl">
      <img style="width: 100%; height: 100%" v-for="i in 4" :key="i" :src="imageUrl" alt="blankspace" />
    </template>
  </div>
</template>
<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    imageContainerSizes: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageSizes() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.wavy-panel {
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;

    &:first-child {
      clip-path: inset(16% 75% 16% 0);
    }
    &:nth-child(2) {
      clip-path: inset(8% 50% 0 25%);
      margin-left: 10px;
    }
    &:nth-child(3) {
      clip-path: inset(0 25% 8% 50%);
      margin-left: 20px;
    }
    &:last-child {
      clip-path: inset(16% 0 16% 75%);
      margin-left: 30px;
    }
  }
}
</style>
