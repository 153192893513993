<template>
  <div>
    <StoryCardSwiper
      class="slider-story"
      v-for="(items, index) in [story_data_one, story_data_two]"
      :items="items"
      :key="index"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'StorySlide',
  data() {
    return {
      story_data_one: [],
      story_data_two: [],
    }
  },

  computed: {
    ...mapState(['stories', 'posters']),
    ...mapGetters(['get_story_en', 'get_story_fr'])
  },
  created() {
    if (localStorage.getItem('lang') === 'en') {
      this.story_data_one = this.$store.getters.get_story_en.slice(0, 3)
      this.story_data_two = this.$store.getters.get_story_en.slice(3, 6)
    } else if (localStorage.getItem('lang') === 'fr') {
      this.story_data_one = this.$store.getters.get_story_fr.slice(0, 3)
      this.story_data_two = this.$store.getters.get_story_fr.slice(3, 6)
    }
  }
}
</script>

<style lang="scss">
.slider-story {
//  margin-top: 22px;
  &:first-child {
    margin-bottom: 22px;
  }
}
</style>
