<template>
  <div class="usage-sections">
    <Logo class="usage-sections-brand"/>

    <v-container>
      <v-row align="center" justify="space-between" class="mb-lg-8">
        <v-col cols="12" lg="6">
          <div class="d-flex flex-column flex-wrap">
            <v-img :src="right_image" :aspect-ratio="(560/700)" :width="imgWidth"/>

            <div v-if="['MetalPaper','Matte','Textured'].includes($route.name)" class="passive-grey--text d-flex flex-column">
              <span> {{ $t('by') }} &nbsp; {{ product_artist }}</span>
              <span> Instagram &nbsp; {{ product_social }}</span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="6">
          <div class="text-content">
            <h4>{{ handcrafted_title }}</h4>
            <p>{{ handcrafted_description }}</p>
            <ul>
              <li>• {{ feature_one }}</li>
              <li>• {{ feature_two }}</li>
              <li>• {{ feature_three }}</li>
              <li>• {{ feature_four }}</li>
              <li v-if="feature_five && lang === 'en'">• {{ feature_five }}</li>
              <li v-if="feature_six && lang === 'en'">• {{ feature_six }}</li>
            </ul>
            <p>{{ handcrafted_team }}</p>
            <p>{{ handcrafted_moments }}</p>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="space-between" class="mt-lg-16">
        <v-col cols="12" lg="6" order="1" order-lg="0">
          <div class="text-content">
            <h4>{{ vibrant_title }}</h4>
            <p v-if="vibrant_description">
              {{ vibrant_description }}</p>
            <p v-if="lang !== 'fr' && vibrant_footer">
              {{ vibrant_footer }}</p>
          </div>
        </v-col>
        <v-col cols="12" lg="6" order="0" order-lg="1">
          <div class="d-flex flex-column flex-wrap">
            <v-img
              :src="left_image"
              :aspect-ratio="620/570"
              :width="imgWidth"
            />
            <div v-if="['MetalPaper','Matte','Textured'].includes($route.name)" class="passive-grey--text d-flex flex-column pull-bottom" :style="{width: `${imgWidth}`}">
              <span class="text-lg-right"> {{ $t('by') }} &nbsp; {{ product_artist }}</span>
              <span class="text-lg-right"> Instagram &nbsp; {{ product_social }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Logo from '@/assets/svg-icons/logo/logo-7.svg'

export default {
  name: 'ExampleUsageSection',
  components: {Logo},
  props: [
    'left_image',
    'right_image',
    'product_social',
    'product_artist',
    'vibrant_title', 'vibrant_description', 'handcrafted_title', 'handcrafted_moments', 'handcrafted_team', 'handcrafted_description', 'vibrant_footer', 'feature_one', 'feature_two', 'feature_three', 'feature_four', 'feature_five', 'feature_six'],
  computed: {
    // TODO: This is an SSR issue. Because Server environment doesn't have window or localstorage object. We have to fix that on SSR stage.
    //  TODO: Also we have to fix localization issue on whole application codebase :))
    lang() {
      return localStorage.getItem('lang')
    },
    imgWidth() {
      return this.$vuetify.breakpoint.smAndDown ? '100%' : '80%'
    }
  }
}
</script>

<style lang="scss" scoped>
.usage-sections {
  @media(--t) {
    .pull-bottom {
      margin-bottom: -50px;
    }
  }
  letter-spacing: 0.02em;
  > .container {
    max-width: 95%;
    @media (min-width: 1650px) {
      max-width: 1450px;
    }
  }

  ul {
    padding: 0;
    margin-bottom: 16px;
  }

  h4 {
    font-family: var(--font-family--alethia);
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 1;
    letter-spacing: -0.03em;
    color: #000000;
    margin-bottom: 16px;
  }

  .text-content {
    @media (min-width: 1440px) {
      max-width: 80%;

    }
  }

  .usage-sections-brand {
    display: none;
    @media (--t) {
      display: block;
      position: absolute;
      right: 6%;
      top: -258px;
    }
  }

  overflow: hidden;
  background-color: #FFFFFF;
  padding: 30px 0 18px;
  @media (--t) {
    position: relative;
    padding: 48px 0 88px;
    background-color: #f9f8f7;
  }

  .container {
    position: relative;
  }

  &-brand {
    display: none;
    @media (--t) {
      display: block;
      position: absolute;
      right: 6%;
      top: -3%;
    }
  }
}

</style>
