export const authPagesMixins = {
  data: () => ({
    userData: {},
    loading: false,
    validationPerformed : false
  }),

  methods: {
    validateAndSubmit() {
      this.validationPerformed = true;
      this.$validator.validateAll().then((v) => {
        if (v) this.onSubmit();
      });
    },
    validateField(fieldName) {
      if (this.validationPerformed) {
        setTimeout(() => {
          this.$validator.validate(fieldName).then(result => {
            if (result) {
              this.$validator.errors.remove(fieldName);
            }
          });
        }, 200)
      }
    }
  },

  computed: {
    formFields() {
      return [];
    },
  },
};
