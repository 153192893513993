<template>
  <v-row v-if="libraryAvailable" class="mt-md-5">
    <v-col cols="12" class="pb-0 pb-lg-3">
      <p class="configurator-content-titles">
        {{ $t('placeholder.library') }}
      </p>
    </v-col>
    <v-col cols="12" class="library-cards pt-0 pt-lg-3">
      <AppSwiper
          :items="user_image_library"
          v-bind="swiperOptions"
          v-if="smAndDown"
      >
        <template v-slot:item="image">
          <UserLibraryItemCard
              @click="selectImage(image)"
              :image="image"
          />
        </template>
      </AppSwiper>
      <div class="d-flex flex-wrap justify-start" style="gap: 5px;" v-else>
        <template v-for="(image, index) in user_image_library">
          <UserLibraryItemCard
              @click="selectImage(image)"
              :image="image"
              :key="index"
          />
        </template>
      </div>

    </v-col>
  </v-row>
</template>
<script>
import localforage from 'localforage'
import UserLibraryItemCard from '@/components/ui/UserLibraryItemCard'
import {orderBy} from 'lodash'
import {mapGetters} from "vuex";

export default {
  components: {UserLibraryItemCard},
  props: {
    refreshData: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      user_image_library: []
    }
  },
  mounted() {
    this.fetchUserImageLibrary()
  },
  methods: {
    fetchUserImageLibrary() {
      const imagesInCart = this.get_cart?.map(i => i.image_file_id)
      setTimeout(() => {
        localforage.getItem('uil').then(user_image_library => {
          const library = orderBy(user_image_library || [], 'expire', 'desc')
              .filter((image) => image.expire > new Date().getTime() && !imagesInCart?.includes(image.picture_id)) || []
          this.user_image_library = library.slice(0, 5);
          if (user_image_library?.length > this.user_image_library) localforage.setItem("uil", this.user_image_library)
        })
      }, 1000)
    },
    selectImage(image) {
      // this.$store.dispatch("SET_LOADER", {status: true})
      this.$emit('user-image-select-event', image)
    }
  },
  computed: {
    libraryAvailable() {
      return this.user_image_library?.length > 0
    },
    swiperOptions() {
      return {
        slidesPerView: 'auto',
        spaceBetween: 5,
        freeMode: {
          enabled: true,
          sticky: true,
        },
        pagination: false
      }
    },
    ...mapGetters({
      get_cart : "get_cart"
    })
  },
  watch: {
    refreshData(refreshData) {
      if (refreshData) {
        this.fetchUserImageLibrary()
      }
    }
  }
}
</script>

<style lang="scss">
.library-cards {
  overflow-x: hidden;

  .swiper-slide {
    width: 130px;
  }

  .swiper-pagination {
    display: none;
  }
}

</style>
