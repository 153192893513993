<template>
  <v-row class="order-summary mt-5 mb-5">
    <v-col class="summary" lg="8" md="12" cols="12">
      <CustomText tag="h1">Order Summary</CustomText>

      <v-row>
        <v-col class="d-flex flex-row" lg="6" md="4" sm="6">
          <div>
            <h6>Kind</h6>
            <h6>Size</h6>
          </div>
          <div class="ml-3">
            <h6>:</h6>
            <h6>:</h6>
          </div>
          <div class="ml-3">
            <h5>Acrylic</h5>
            <h5>40" x 53"</h5>
          </div>
        </v-col>
        <v-col class="d-flex flex-row" lg="6" md="4" sm="6">
          <div>
            <h6>Quantity</h6>
            <h6>Rails</h6>
          </div>
          <div class="ml-3">
            <h6>:</h6>
            <h6>:</h6>
          </div>
          <div class="ml-3">
            <h5>1 Panel</h5>
            <h5>Aluminum</h5>
          </div>
        </v-col>
      </v-row>

    </v-col>

    <v-col class="add-to-cart" lg="4" cols="12" md="12">
      <CustomText size="" tag="h2">ADD TO CART</CustomText>
    </v-col>
  </v-row>
</template>

<script>
import CustomText from "@/components/shared/CustomText";

export default {
  name: "OrderSummaryCard",
  components: {CustomText}
}
</script>

<style scoped>

h5 {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  letter-spacing: 0.02em;
  color: #077D60;

}

h6 {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  letter-spacing: 0.02em;
  color: #077D60;

}

.order-summary {


  .summary {
    background: #E7F7EF;
    padding: 10px;

    h1 {
      font-family: var(--font-family--alethia);
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 105%;
      letter-spacing: 0.02em;
      color: #077D60;
      text-align: left;
    }
  }

  .add-to-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #077D60;
    padding: 30px;


    h2 {
      font-family: Sk-Modernist;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 150%;
      color: #FFFFFF;
    }
  }

  .add-to-cart:after {
    content: "";
    width: 0;
    height: 0;
    left: 0;
    border-top: 25px solid transparent;
    border-left: 25px solid #E7F7EF;;
    border-bottom: 25px solid transparent;
    position: absolute;
    bottom: 29%;
  }

}

</style>
