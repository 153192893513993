<template>
  <v-list-item class="shop-item">
    <v-badge
      bordered
      top
      right
      color="#828282"
      size="24"
      :content="quantity"
      offset-x="10"
      offset-y="10"
      class="mr-4"
    >
      <FrameThumb v-if="product !== 'gift_card'" :max-width="60" :image-url="src" :width="width" :height="height" :layout="layout" />
      <v-img v-else src="/gift-card.png" :aspect-ratio="(60/80)" :width="60"></v-img>
    </v-badge>
    <v-list-item-content class="align-self-start">
      <h6 v-if="product !== 'gift_card' && product" class="regular-medium font-weight-bold">{{ _material_name }}</h6>
      <h6 v-if="product === 'gift_card'" class="regular-medium font-weight-bold">{{ $t('shop.gift.name') }}</h6>
      <h6 v-if="product === 'gift_card' && gift_card_value" class="regular-medium ">{{ gift_card_value }}</h6>
      <h6 v-if="height && width" class="regular-medium">{{ width }}" x {{ height }}"</h6>
      <h6 v-if="layout" class="regular-medium">{{ layout }}</h6>
      <h6 v-if="frame" class="regular-medium">{{ frame }}</h6>
    </v-list-item-content>
    <v-list-item-action class="align-self-start">
      <v-list-item-action-text>
        <h6 class="medium" v-if="total">${{ total.toFixed(2) }}</h6>
      </v-list-item-action-text>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import CustomText from "@/components/shared/CustomText";
import FrameThumb from "@/components/Preview/Frames/FrameThumb.vue";

export default {
  name: "PurchaseShopItem",
  components: {
    FrameThumb,
    CustomText
  },
  props: {
    gift_card_value: {
      type: [Number, String]
    },
    product: {
      type: String,
    },
    width: {
      type: [String, Number],
    },
    height: {
      type: [String, Number],
    },
    layout: {
      type: String,
    },
    frame: {
      type: String,
    },
    total: {
      type: Number
    },
    quantity: {
      type: Number,
      default: 1,
    },
    src: {
      type: String,
      default: '/gift-card.png'
    }

  },
  computed: {
    _material_name() {
      const productType = this.product === "canvas" ? "canvas_frame" : this.product
      if (localStorage.getItem("lang") === "en") {
        return this.$store.getters.get_material_en.filter(e => e.value === productType)[0]?.title;
      } else {
        return this.$store.getters.get_material_fr.filter(e => e.value === productType)[0]?.title;
      }
    },
  }
}
</script>

<style lang="postcss" scoped>

.image-item::v-deep .v-image__image--cover{
  background-size: contain!important;
}

.shop-item {
  margin-top: 15px;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  h6 {
    text-align: left;
    margin-bottom: 6px;
    line-height: 100%;
  }
}

</style>
