<template>
  <div v-bind="$attrs" class="flex flex-center">
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="background: none; display: block; shape-rendering: auto;"
        width="60px"
        height="60px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
    >
      <g>
        <path d="M10 50A40 40 0 0 0 90 50A40 47.2 0 0 1 10 50" fill="#212529" stroke="none">
          <animateTransform
              attributeName="transform"
              type="rotate"
              dur="0.7246376811594202s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 50 53.6;360 50 53.6"
          ></animateTransform>
        </path>
        <text v-if="usePercentage && percentageIsNotNan"
              x="50" y="60"
              style="font-size:20px; font-weight: bold; text-anchor: middle">
          {{ percentageText }}
        </text>
      </g>
    </svg>
    <span class="processing-text" v-html="$t(`placeholder.${text}`)"></span>
  </div>
</template>

<script>

import {isNaN} from "lodash";

export default {
  name: "imageLoading",
  props: {
    text: {
      required: false,
      default: "processing"
    },
    usePercentage: {
      type: Boolean,
      default: false
    },
    percentage: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    percentageText() {
      return `${Math.floor(this.percentage)}%`
    },
    percentageIsNotNan(){
      return !isNaN(this.percentage)
    }
  }
};
</script>


<style scoped lang="scss">
.processing-text {
  animation: fadeIn 2s;
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>
