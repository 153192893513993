import Vue from 'vue'
import Vuetify from 'vuetify'
/*import 'vuetify/dist/vuetify.min.css'*/

Vue.use(Vuetify)

const opts = {
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#000000',
        secondary: '#009771',
        error: '#ee6055',
        'dark-color': '#393939',
        'passive-grey': '#828282',
        'passive-tab': '#f9f8f7',
        'warning': '#FFD600',
        'bg-grey-1': '#F9F9F9'
      }
    }
  }
}

export default new Vuetify(opts)
