<template>
  <div class="image-engine">
    <div v-show="!layoutMode" ref="imageContainer" :style="_imageContainerSizes" class="imageContainer"></div>
    <component v-if="layoutMode" :is="layoutComponent" :imageUrl="layoutImageURL" :imageContainerSizes="_imageContainerSizes" :width="thumbnailImageWidth" :height="thumbnailImageHeight" />
    <image-loading v-if="imageLoading" class="loading" />
  </div>
</template>

<script>
import * as Hammer from "hammerjs";
import html2canvas from "html2canvas";
import imageLoading from "./imageLoading";

export default {
  name: "ImageEngine",
  components: {
    imageLoading,
    VerticalFrame: () => import("@/components/Preview/Frames/Vertical"),
    HorizontalFrame: () => import("@/components/Preview/Frames/Horizontal"),
    WavyFrame: () => import("@/components/Preview/Frames/Wavy"),
    // RectangleFrame: () => import("./Frames/Rectangle"),
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
    originalImageWidth: {
      type: Number,
      required: true,
    },
    originalImageHeight: {
      type: Number,
      required: true,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    thumbnailImageWidth: {
      type: Number,
      required: true,
    },
    thumbnailImageHeight: {
      type: Number,
      required: true,
    },
    customImageSize: {
      type: Object,
      required: true,
    },
    snapshot: {
      type: Boolean,
      required: false,
      default: false,
    },
    snapshotForAddToCart: {
      type: Boolean,
      required: false,
      default: false,
    },
    snapshotType: {
      type: String,
      required: false,
      default: "base64", // ["blob", "base64"]
    },
    layout: {
      type: String,
      required: true,
      default: "1 Panel",
    },
    layoutMode: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      minScale: 1,
      maxScale: 10,
      imageWidth: undefined,
      imageHeight: undefined,
      containerWidth: undefined,
      containerHeight: undefined,
      displayImageX: 0,
      displayImageY: 0,
      displayImageScale: 1,

      displayDefaultWidth: undefined,
      displayDefaultHeight: undefined,

      rangeX: 0,
      rangeMaxX: 0,
      rangeMinX: 0,

      rangeY: 0,
      rangeMaxY: 0,
      rangeMinY: 0,

      displayImage: undefined,

      displayImageRangeY: 0,

      displayImageCurrentX: 0,
      displayImageCurrentY: 0,
      displayImageCurrentScale: 1,

      imageLoading: true,
      imageContainerSizes: {
        width: 0,
        height: 0,
      },

      thumbnailImageWidth_: 0.0,
      thumbnailImageHeight_: 0.0,

      imageContainer: undefined,
      hammertime: undefined,
      S3: require("aws-sdk/clients/s3"),
      windowWidth: 0,

      openLayoutMode: false,
      layoutImageURL: "",
    };
  },
  methods: {
    resizeContainer() {
      this.containerWidth = this.imageContainer.offsetWidth;
      this.containerHeight = this.imageContainer.offsetHeight;

      if (this.displayDefaultWidth !== undefined && this.displayDefaultHeight !== undefined) {
        this.displayDefaultWidth = this.displayImage.offsetWidth;
        this.displayDefaultHeight = this.displayImage.offsetHeight;
        this.updateRange();
        this.displayImageCurrentX = this.clamp(this.displayImageX, this.rangeMinX, this.rangeMaxX);
        this.displayImageCurrentY = this.clamp(this.displayImageY, this.rangeMinY, this.rangeMaxY);
        this.updateDisplayImage(this.displayImageCurrentX, this.displayImageCurrentY, this.displayImageCurrentScale);
      }
    },
    clamp(value, min, max) {
      return Math.min(Math.max(min, value), max);
    },
    clampScale(newScale) {
      return this.clamp(newScale, this.minScale, this.maxScale);
    },
    updateRange() {
      this.rangeX = Math.max(0, Math.round(this.displayDefaultWidth * this.displayImageCurrentScale) - this.containerWidth);
      this.rangeY = Math.max(0, Math.round(this.displayDefaultHeight * this.displayImageCurrentScale) - this.containerHeight);

      this.rangeMaxX = Math.round(this.rangeX / 2);
      this.rangeMinX = 0 - this.rangeMaxX;

      this.rangeMaxY = Math.round(this.rangeY / 2);
      this.rangeMinY = 0 - this.rangeMaxY;
    },
    updateDisplayImage(x, y, scale) {
      const transform = "translateX(" + x + "px) translateY(" + y + "px) translateZ(0px) scale(" + scale + "," + scale + ")";
      this.displayImage.style.transform = transform;
      this.displayImage.style.WebkitTransform = transform;
      this.displayImage.style.msTransform = transform;
    },
    //! Kendi Yazdığımız Fonksiyonlar..
    setImage() {
      this.displayImage.src = this?.image?.thumbnail?.base64;
      return false;
      this.getBase64URL(`${this.imageUrl}`).then((base64Code) => {
        this.displayImage.src = base64Code;
      });
    },
    displayImageOnLoad() {
      this.imageWidth = this.displayImage.width;
      this.imageHeight = this.displayImage.height;
      this.displayImage.addEventListener("mousedown", (e) => e.preventDefault(), false);
      this.imageContainer.appendChild(this.displayImage);

      this.displayDefaultWidth = this.displayImage.offsetWidth;
      this.displayDefaultHeight = this.displayImage.offsetHeight;

      this.rangeX = Math.max(0, this.displayDefaultWidth - this.containerWidth);
      this.rangeY = Math.max(0, this.displayDefaultHeight - this.containerHeight);

      this.imageLoading = false;
      this.$emit("ready-event", true);
    },
    imageContainerWheel(e) {
      this.displayImageScale = this.displayImageCurrentScale = this.clampScale(this.displayImageScale + e.wheelDelta / 800);
      this.updateRange();
      this.displayImageCurrentX = this.clamp(this.displayImageCurrentX, this.rangeMinX, this.rangeMaxX);
      this.displayImageCurrentY = this.clamp(this.displayImageCurrentY, this.rangeMinY, this.rangeMaxY);
      this.updateDisplayImage(this.displayImageCurrentX, this.displayImageCurrentY, this.displayImageScale);
      this.resizeContainer();
      this.$emit("image-zoom-event", this.currentImageInfo?.currentImageResolution);
    },
    hammertimePan(ev) {
      this.displayImageCurrentX = this.clamp(this.displayImageX + ev.deltaX, this.rangeMinX, this.rangeMaxX);
      this.displayImageCurrentY = this.clamp(this.displayImageY + ev.deltaY, this.rangeMinY, this.rangeMaxY);
      this.updateDisplayImage(this.displayImageCurrentX, this.displayImageCurrentY, this.displayImageScale);
    },
    hammertimePinch(ev) {
      this.displayImageCurrentScale = this.clampScale(ev.scale * this.displayImageScale);
      this.updateRange();
      this.displayImageCurrentX = this.clamp(this.displayImageX + ev.deltaX, this.rangeMinX, this.rangeMaxX);
      this.displayImageCurrentY = this.clamp(this.displayImageY + ev.deltaY, this.rangeMinY, this.rangeMaxY);
      this.updateDisplayImage(this.displayImageCurrentX, this.displayImageCurrentY, this.displayImageCurrentScale);
    },
    hammertimePanPinchEnd() {
      this.displayImageScale = this.displayImageCurrentScale;
      this.displayImageX = this.displayImageCurrentX;
      this.displayImageY = this.displayImageCurrentY;
    },
    async getBase64URL(imageUrl) {
      const Key = imageUrl.split("com/")[1];
      const aggregatedS3 = new this.S3({
        accessKeyId: "AKIAI53FDBEECCPFSWLQ",
        secretAccessKey: "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",
      });
      return aggregatedS3
        .getObject({
          Bucket: "blankspace-upload-service-files",
          Key: Key,
        })
        .promise()
        .then((data) => {
          return `data:${data.ContentType};base64,${data.Body.toString("base64")}`;
        });
      return false;
      // const responseData = await fetch(imageUrl, {
      //   method: "GET",
      //   headers: {
      //     "Access-Control-Allow-Origin": "*", // Required for CORS support to work
      //     "Access-Control-Allow-Credentials": true // Required for cookies, authorization headers with HTTPS
      //   }
      // });
      // const blobObject = await responseData.blob();
      // return new Promise(resolve => {
      //   const imageReader = new FileReader();
      //   imageReader.readAsDataURL(blobObject);
      //   imageReader.onloadend = () => {
      //     const base64 = imageReader.result;
      //     resolve(base64);
      //   };
      // });
    },
    autoScale() {
      this.imageLoading = true;
      setTimeout(() => {
        let scale = 1;
        const scaleX = this.imageContainer.offsetWidth / this.displayImage.width;
        const scaleY = this.imageContainer.offsetHeight / this.displayImage.height;
        scale = scaleX >= scaleY ? scaleX : scaleY;

        this.displayImageScale = scale;
        this.displayImageCurrentScale = scale;
        this.minScale = scale;
        this.resizeContainer();
        this.imageLoading = false;
        this.$emit("image-zoom-event", this.currentImageInfo?.currentImageResolution);
      }, 500);
    },
    takeSnapshot(sendEvent) {
      this.imageLoading = true;
      html2canvas(this.imageContainer, {
        scale: 1,
      }).then(async (canvas) => {
        if (this.snapshotType === "blob") {
          const blob = await new Promise((resolve) => canvas.toBlob(resolve, "image/png"));
          if (sendEvent) this.$emit("snapshot-event", blob);
        } else if (this.snapshotType === "base64") {
          setTimeout(() => {
            this.imageLoading = false;
            this.layoutImageURL = canvas.toDataURL("image/png");
            if (sendEvent) this.$emit("snapshot-event", canvas.toDataURL("image/png"));
            // this.openLayoutMode = true;
          }, 500);
        }
      });
    },
    getOrientation(width, height) {
      let _orientation = null;
      const _aspectRatio = height / width;
      if (_aspectRatio >= 0 && _aspectRatio < 1) {
        // Yatay..
        _orientation = "horizontal";
      } else if (_aspectRatio === 1) {
        // Kare
        _orientation = "square";
      } else if (_aspectRatio > 1) {
        // Dikey
        _orientation = "vertical";
      }

      return {
        orientation: _orientation,
        aspectRatio: _aspectRatio,
      };
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    this.thumbnailImageWidth_ = this.thumbnailImageWidth;
    this.thumbnailImageHeight_ = this.thumbnailImageHeight;

    this.imageContainer = this.$refs.imageContainer;
    this.resizeContainer();

    window.addEventListener("resize", this.resizeContainer, true);

    this.imageContainer.addEventListener("wheel", this.imageContainerWheel, false);

    this.displayImage = new Image();

    this.setImage();

    this.displayImage.onload = () => {
      this.displayImageOnLoad();

      // const deltaLength = this.imageContainer.offsetWidth - this.imageContainer.offsetHeight;
      // let scale = this.imageContainer.offsetHeight / this.displayImage.offsetHeight;
      // //! Yatay Orientation Kontrolü
      // if (deltaLength >= 100) {
      //   scale = this.imageContainer.offsetWidth / (this.displayImage.width + 0.1);
      // }
      let scale = 1;
      const scaleX = this.imageContainer.offsetWidth / this.displayImage.width;
      const scaleY = this.imageContainer.offsetHeight / this.displayImage.height;
      scale = scaleX >= scaleY ? scaleX : scaleY;

      this.displayImageScale = scale;
      this.displayImageCurrentScale = scale;
      this.minScale = scale;
      this.resizeContainer();
    };

    this.hammertime = new Hammer(this.imageContainer);

    this.$nextTick(() => {
      this.hammertime.get("pinch").set({ enable: true });
      this.hammertime.get("pan").set({ direction: Hammer.DIRECTION_ALL });

      this.hammertime.on("pan", this.hammertimePan);
      this.hammertime.on("pinch pinchmove", this.hammertimePinch);
      this.hammertime.on("panend pancancel pinchend pinchcancel", this.hammertimePanPinchEnd);
    });
  },
  computed: {
    // layoutMode() {
    //   return this.layout !== "1 Panel";
    // },
    layoutComponent() {
      if (this.layout === "3 Horizontal Panels") return "HorizontalFrame";
      if (this.layout === "3 Vertical Panels") return "VerticalFrame";
      if (this.layout === "4 Wavy Panels") return "WavyFrame";
    },
    _imageContainerSizes() {
      // adjusted width = <user-chosen height> * aspect ratio
      let width = this.thumbnailImageWidth_;
      let height = this.thumbnailImageHeight_;

      let _orientation = null;
      const _aspectRatio = parseFloat(height / width);

      if (_aspectRatio >= 0 && _aspectRatio < 1) {
        // Yatay..
        _orientation = "horizontal";
      } else if (_aspectRatio === 1) {
        // Kare
        _orientation = "square";
      } else if (_aspectRatio > 1) {
        // Dikey
        _orientation = "vertical";
      }

      let orientation = null;
      if (this.customSizeEnabled) {
        orientation = _orientation;
      } else {
        orientation = this.orientation;
      }

      if (this._is_mobile_device) {
        const base = this.windowWidth - 80;
        if (orientation === "square" && (width >= 500 || width < 500)) {
          width = base;
          height = base;
        } else if (orientation === "horizontal" && (width >= 750 || width < 750)) {
          if (!this.customSizeEnabled) {
            width = base;
            height = base / this.aspectRatio;
          } else {
            height = base * _aspectRatio;
            width = base;
          }
        } else if (orientation === "vertical" && (height >= 750 || height < 750)) {
          if (!this.customSizeEnabled) {
            height = base;
            width = base / this.aspectRatio;
          } else {
            width = base / (height / width);
            height = base;
          }
        }
      } else {
        if (orientation === "square" && (width >= 500 || width < 500)) {
          width = 500;
          height = 500;
        } else if (orientation === "horizontal" && (width >= 750 || width < 750)) {
          if (!this.customSizeEnabled) {
            width = 750;
            height = 750 / this.aspectRatio;
          } else {
            height = 750 * _aspectRatio;
            width = 750;
          }
        } else if (orientation === "vertical" && (height >= 750 || height < 750)) {
          if (!this.customSizeEnabled) {
            height = 750;
            width = 750 / this.aspectRatio;
          } else {
            width = 750 / (height / width);
            height = 750;
          }
        }
      }

      return {
        height: `${height}px`,
        width: `${width}px`,
      };
    },
    _is_mobile_device() {
      return window.innerWidth < 550;
    },
    currentImageInfo() {
      const x_pixel = (this.rangeMaxX / this.displayImageCurrentScale) * (this.originalImageWidth / this.containerWidth);
      const y_pixel = (this.rangeMaxY / this.displayImageCurrentScale) * (this.originalImageHeight / this.containerHeight);

      const scale_x_pixel = (this.displayImageCurrentX / this.displayImageCurrentScale) * (this.originalImageWidth / this.containerWidth);
      const scale_y_pixel = (this.displayImageCurrentY / this.displayImageCurrentScale) * (this.originalImageHeight / this.containerHeight);

      const x1 = parseInt(this.originalImageWidth - x_pixel - scale_x_pixel * -1);
      const x2 = parseInt(this.originalImageWidth - 2 * x_pixel);

      const y1 = parseInt(this.originalImageHeight - y_pixel - scale_y_pixel * -1);
      const y2 = parseInt(this.originalImageHeight - 2 * y_pixel);

      const x1Coord = this.originalImageWidth - x1;
      const x2Coord = this.originalImageWidth - (x1 - x2);

      const y1Coord = this.originalImageHeight - y1;
      const y2Coord = this.originalImageHeight - (y1 - y2);

      // const coords = `(${x1Coord || 0},${y1Coord || 0}) (${x2Coord || this.originalImageWidth},${y2Coord || this.originalImageHeight})`;
      const coords = {
        x1: x1Coord || 0,
        y1: y1Coord || 0,
        x2: x2Coord || this.originalImageWidth,
        y2: y2Coord || this.originalImageHeight,
      };

      return {
        currentImageResolution: {
          width: x2Coord - x1Coord,
          height: y2Coord - y1Coord,
        },
        coords: coords,
        detail: `
            <pre>
                <b>Current</b>
                <b>Scale:</b>     ${this.displayImageCurrentScale.toFixed(4)}
                <b>X:</b>         ${this.displayImageCurrentX}
                <b>Y:</b>         ${this.displayImageCurrentY}

                <b>Range</b>
                <b>rangeX:</b>    ${this.rangeX}
                <b>rangeMinX:</b> ${this.rangeMinX}
                <b>rangeMaxX:</b> ${this.rangeMaxX}/${x_pixel.toFixed(2)}
                <b>x_pixel:</b>   ${x_pixel.toFixed(2)}
                <b>-X Pixel:</b>  ${x1}
                <b>X Pixel:</b>   ${x2}

                <b>rangeY:</b>    ${this.rangeY}
                <b>rangeMinY:</b> ${this.rangeMinY}
                <b>rangeMaxY:</b> ${this.rangeMaxY}/${y_pixel.toFixed(2)}
                <b>y_pixel:</b>   ${y_pixel.toFixed(2)}
                <b>-Y Pixel:</b>  ${y1}
                <b>Y Pixel:</b>   ${y2}

                <b>Coordinates:</b>   ${coords}



                <b>Updated</b>
                <b>Scale:</b>     ${this.displayImageScale.toFixed(4)}
                <b>X:</b>         ${this.displayImageX}
                <b>Y:</b>         ${this.displayImageY}
            </pre>`,
      };
    },
    orientation() {
      const _aspectRatio = parseFloat(this.originalImageHeight / this.originalImageWidth);
      let _orientation = null;

      if (_aspectRatio >= 0 && _aspectRatio < 1) {
        // Yatay..
        _orientation = "horizontal";
      } else if (_aspectRatio === 1) {
        // Kare
        _orientation = "square";
      } else if (_aspectRatio > 1) {
        // Dikey
        _orientation = "vertical";
      }
      return _orientation;
    },
    imageContianerOrientation() {
      if (this.imageContainer?.offsetWidth >= this.imageContainer?.offsetHeight + 100) return "horizontal";
      else if (this.imageContainer?.offsetHeight >= this.imageContainer?.offsetWidth + 100) return "vertical";
      else return "square";
    },
    aspectRatio() {
      if (this.orientation === "horizontal") return this.originalImageWidth / this.originalImageHeight;
      else if (this.orientation === "vertical") return this.originalImageHeight / this.originalImageWidth;
      else return 1;
    },
    customSizeEnabled() {
      return Boolean(this.customImageSize?.width || this.customImageSize?.height);
    },
  },
  watch: {
    // layoutModeOpen(mode) {
    // if (!mode && this.openLayoutMode) {
    //   this.openLayoutMode = false;
    // }
    // },
    // openLayoutMode(mode) {
    //   // this.$emit("layout-mode-changed", mode === true);
    // },
    layoutMode(layoutMode) {
      if (layoutMode) this.takeSnapshot();
      else this.layoutImageURL = "";
    },
    currentImageInfo() {
      this.$emit("coords-event", this.currentImageInfo);
      this.$emit("image-zoom-event", this.currentImageInfo?.currentImageResolution);
    },
    thumbnailImageWidth(width) {
      this.thumbnailImageWidth_ = width;
      this.autoScale();
    },
    thumbnailImageHeight(height) {
      this.thumbnailImageHeight_ = height;
      this.autoScale();
    },
    customImageSize: {
      deep: true,
      handler(size) {
        if (size?.width) {
          this.thumbnailImageWidth_ = size.width;
        }
        if (size?.height) {
          this.thumbnailImageHeight_ = size.height;
        }
        this.autoScale();
      },
    },
    snapshot(snapshot) {
      if (snapshot) {
        this.takeSnapshot();
      }
    },
    snapshotForAddToCart(snapshot) {
      if (snapshot) {
        this.takeSnapshot(true);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.image-engine {
  // position: relative;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fafafac0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}

.loading span {
  margin-top: 10px;
}

.imageContainer {
  z-index: 8;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #2b2b2c;
  transition: all 0.1s ease-in-out;

  /* max-width: 600px;
     max-height: 750px; */
  /* max-width: 750px; /* 1500 */
  /* max-height: 563px; /* 1126 */

  /* top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     margin: auto;
  */
}

.imageContainer > img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  cursor: move;
  touch-action: none;
}
</style>
