<template>
  <div>
    <v-container>
      <h6 class="small-title mb-6">{{$t('profile.personal_info')}}</h6>
      <AppForm
        @keydown.enter.native="validateAndSubmit"
        :validationPerformed="validationPerformed"
        :form-fields="formFields"
        :loading="loading"
        :form-data="userData"
        ref="form"
      />
      <AppButton
        @click="validateAndSubmit"
        color="primary"
        height="50"
        class="mt-6"
        :min-width="$vuetify.breakpoint.mdAndUp ? 400 : 250"
        :loading="loading"
      >
        {{$t('buttons.save_changes')}}
      </AppButton>
    </v-container>

  </div>
</template>

<script>
import {addressPageMixins} from '@/mixins/addressPageMixins'
import {isBoolean} from "lodash";

export default {
  name: "AccountDetail",
  mixins: [addressPageMixins],
  data: () => ({
    actionName: 'updateUserData',
    userData: {
      id: null,
      first_name: null,
      last_name: null,
      phone: null,
      phone_ext: null,
      email: null,
    },
  }),
  methods: {
    fillUserInfo() {
      if (this.$store.getters.currentUserData) {
        const primaryContact = this.$store.getters.currentUserData.contact.contact_people?.find(c => c.primary);
        this.userData = {
          email: this.$store.getters.currentUserData.karavan_user.email,
          ...Boolean(primaryContact) && primaryContact
        }
      }
    },
  },
  mounted() {
    this.fillUserInfo()
  },
  computed: {
    formFields() {
      return [
        {
          comp: 'AppFormInput',
          icon: '',
          colProps: {
            cols: 12,
            lg: 6,
          },
          label: this.$t('placeholder.first_name'),
          name: 'first_name',
          validation: 'required|alpha_spaces'
        },
        {
          comp: 'AppFormInput',
          icon: '',
          colProps: {
            cols: 12,
            lg: 6,
          },
          label: this.$t('placeholder.last_name'),
          name: 'last_name',
          validation: 'required|alpha_spaces'
        },
        {
          comp: 'AppFormInput',
          icon: '',
          colProps: {
            cols: 12,
            lg: 6,
          },
          label: this.$t('placeholder.email'),
          name: 'email',
          validation: 'required|email'
        },
        {
          comp: 'AppFormInput',
          icon: '',
          colProps: {
            cols: 6,
            lg: 2,
          },
          label: this.$t('placeholder.phone_ext'),
          name: 'phone_ext',
          validation: {
            required: true,
            regex: /^[0-9-+()]+$/
          }
        },
        {
          comp: 'AppFormInput',
          icon: '',
          colProps: {
            cols: 6,
            lg: 4,
          },
          label: this.$t('placeholder.phone'),
          name: 'phone',
          validation: 'required|numeric'
        },
      ]
    },
    submitObj() {
      return {
        karavan_user: {
          email: this.$store.getters.currentUserData.karavan_user.email,
        },
        contact: {
          contact_people_attributes: {
            0: this.userData
          },
        }
      }
    }
  }
}
</script>
