<template>
  <div class="price-page-container">
    <div class="container page-container">
      <Heading :subtitle="$t('price.subtitle')" :title="$t('price.title')"/>
      <div class="row card-container">
        <div class="col-12 col-lg-7 size-matters-section">
          <v-card flat tile color="transparent">
            <h3 class="title-large">{{ $t('price.size') }}</h3>
            <p>{{ $t('price.sizeDesc') }}</p>
            <ProductSizeSelector
                :custom="_is_button_active"
                :data="product_price_list"
                @onSelect="selectedSize"
            />
          </v-card>
        </div>
        <div class="col-12 col-lg-5">
          <v-card flat tile color="transparent" class="fill-height">
            <div class="d-flex flex-column fill-height">
              <div class="custom-titles">
                <h3 class="title-large text-md-left text-center ">{{ $t('price.custom') }}</h3>
                <h4 v-if="total_cost" style="color: #077d60 ;font-family: 'Sk-Modernist', sans-serif!important;">$
                  {{ total_cost }}</h4>
              </div>
              <p class="text-lg-left">{{ $t('price.customDesc') }}</p>
              <v-spacer/>
              <div :class="_custom_select_class" class="align-center align-self-md-end justify-space-between d-flex ">
                <AppSelect
                    color="primary"
                    :label="customWidthButtonText"
                    tile
                    dense
                    :items="customWidthList"
                    item-value="value.inch"
                    hide-details
                    :error-messages="customWidthErrors"
                    v-model="selected_product_width"
                    @input="calculatePrice"
                    @click.native="custom_width = true"
                    class="custom-size-selector"

                />

                <div class=" d-flex fill-height align-center mx-4">
                  <IconX/>
                </div>
                <AppSelect
                    color="primary"
                    :label="customHeightButtonText"
                    v-model="selected_product_height"
                    @input="calculatePrice"
                    @click.native="custom_height = true"
                    tile
                    dense
                    :error-messages="customHeightErrors"
                    :items="customHeightList"
                    item-value="value.inch"
                    hide-details
                    class="custom-size-selector"
                />
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
    <div class="button-container container">
      <AppButton
          @click="getStarted"
          color="primary"
          large
          :block="smAndDown"
          class="mb-4 mb-md-0"
      >
        {{ $t('buttons.start').toUpperCase() }}
      </AppButton>
    </div>
  </div>
</template>

<script>
import ProductSizeSelector from '@/components/ui/ProductSizeSelector'
import {IconX} from '@/assets/svg-icons'
import {MaterialPrices} from '@/data/MaterialPrices'
import localforage from 'localforage'
import Heading from '../Heading'
import {
  ACRYLIC_DATA,
  ALIMINUM_DATA,
  CANVAS_DATA,
  HEMP_DATA,
  MATTE_DATA,
  METALLIC_DATA,
  SATIN_DATA,
  TEXTURED_DATA
} from '../pricing-data'
import {productSizeMixin} from '@/mixins/productSizeMixin'

export default {
  name: 'PricingNew',
  mixins: [productSizeMixin],
  props: {
    horizontalSizes: {
      type: Array,
      default: () => [7, 94]
    },
    verticalSizes: {
      type: Array,
      default: () => [5, 46]
    },
  },
  components: {
    Heading,
    ProductSizeSelector,
    IconX
  },
  watch: {
    product_type(product_type) {
      this.model.material = product_type
    }
  },
  mounted() {
    this.product_type_detector()
  },
  data() {
    return {
      custom_height: false,
      custom_width: false,
      custom_size: null,
      product_type: null,
      product_price_list: [],
      total_cost: null,
      selected_product_height: null,
      selected_product_width: null,

      decimalList: Array.from({length: 96}).map((item, index) => {
        const width = ((index + 5.5) * 750) / 69
        const height = ((index + 5.5) * 750) / 69
        return {
          value: {
            pixel: (index + 5.5) * 20,
            inch: index + 5.5
          },
          text: `${index + 5.5}"`,
          width,
          height
        }
      }),
      roundedList: Array.from({length: 96}).map((item, index) => {
        const width = ((index + 5) * 750) / 69
        const height = ((index + 5) * 750) / 69
        return {
          value: {
            pixel: (index + 5) * 20,
            inch: index + 5
          },
          text: `${index + 5}"`,
          width,
          height
        }
      }),

      model: {
        id: null,
        height: null,
        width: null,
        material: null,
        price: null
      }
    }
  },
  methods: {
    getStarted() {

      if (this.selected_product_width || this.selected_product_height) {
        this.model.height = parseFloat(this.selected_product_height)
        this.model.width = parseFloat(this.selected_product_width)
        this.model.custom_size = true;
      }
      window.scrollTo(0, 0)
      localforage.setItem('custom_sizing', { ...this.model })
      this.$router.push({name: 'Configurator'})
    },
    selectedSize(size) {

      if (size) {
        this.selected_product_height = null
        this.selected_product_width = null
        this.model.height = parseInt(size.height)
        this.model.width = parseInt(size.width)

        this.model.material = this.product_type

        this.custom_height = false
        this.custom_width = false

        this.total_cost = null

      } else {
        this.model.height = parseInt(this.selected_product_height)
        this.model.width = parseInt(this.selected_product_width)

      }
      this.$forceUpdate()


      return false

    },

    calculatePrice() {
      if (this.selected_product_height && this.selected_product_width) {
        const image_last_value = (this.selected_product_height * this.selected_product_width) / 144
        const product_type = this.product_type
        this.total_cost = (
            MaterialPrices[product_type].filter(p => image_last_value >= p.min && image_last_value <= p.max)[0].price * image_last_value
        ).toFixed(2)

        this.model.height = parseInt(this.selected_product_height)
        this.model.width = parseInt(this.selected_product_width)
        this.model.price = parseFloat(this.total_cost)
      }
    },
    product_type_detector() {
      if (this.$route.name === 'CanvasPaper') {
        this.product_type = 'canvas_frame'
        this.product_price_list = CANVAS_DATA
        this.model.id = 1
      } else if (this.$route.name === 'AcrylicPaper') {
        this.product_type = 'hd_acrylic'
        this.product_price_list = ACRYLIC_DATA
        this.model.id = 3
      } else if (this.$route.name === 'MetalPaper') {
        this.product_type = 'aluminum'
        this.product_price_list = ALIMINUM_DATA
        this.model.id = 2
      } else if (this.$route.name === 'Matte') {
        this.product_type = 'matte_photo_paper'
        this.product_price_list = MATTE_DATA
        this.model.id = 4
      } else if (this.$route.name === 'Satin') {
        this.product_type = 'satin_photo_paper'
        this.product_price_list = SATIN_DATA
        this.model.id = 5
      } else if (this.$route.name === 'Textured') {
        this.product_type = 'textured_fine_art'
        this.product_price_list = TEXTURED_DATA
        this.model.id = 6
      } else if (this.$route.name === 'Hemp') {
        this.product_type = 'hemp_fine_art'
        this.product_price_list = HEMP_DATA
        this.model.id = 7
      } else if (this.$route.name === 'Metallic') {
        this.product_type = 'metallic_photo_paper'
        this.product_price_list = METALLIC_DATA
        this.model.id = 8
      }
    }
  },
  computed: {
    _is_mobile() {
      return window.innerWidth < 980
    },
    _number_list() {
      const numbers = [...this.decimalList, ...this.roundedList]
      return numbers.sort((a, b) => parseInt(b.text) - parseInt(a.text)).reverse()
    },
    _custom_select_class() {
      if (localStorage.getItem('lang') === 'en') {
        return 'custom-size-dropdowns'
      } else {
        return 'custom-size-dropdowns-fr'
      }
    },
    _is_button_active() {
      if (this.custom_width || this.custom_height) {
        return false
      } else return true
    },
    customButtonTextPrefixes() {
      return {
        'CanvasPaper': 'select-canvas',
        'AcrylicPaper': 'select-acrylic',
        'MetalPaper': 'select-metal',
      }
    },
    customWidthButtonText() {
      return this.smAndDown ? this.$t('buttons.width') : this.$t(`buttons.${this.customButtonTextPrefixes[this.$route.name] || 'select-paper'}-width`)
    },
    customHeightButtonText() {
      return this.smAndDown ? this.$t('buttons.height') : this.$t(`buttons.${this.customButtonTextPrefixes[this.$route.name] || 'select-paper'}-height`)
    },
    customHeightErrors() {
      return this.selected_product_height && !this.customHeightList.map(item => item.value.inch).includes(this.selected_product_height) ? ['has-error'] : []
    },
    customWidthErrors() {
      return this.selected_product_width && !this.customWidthList.map(item => item.value.inch).includes(this.selected_product_width) ? ['has-error'] : []
    }
  },
}
</script>

<style lang="scss">

.x-icon {
  margin-left: 12px;

  @media (--t) {
    margin-left: 10px;
  }

}

.custom-size-selector {
  max-width: 185px;

  &
  .app-select {

    .v-label {
      color: #9F9F9F;
    }

  }
}

.price-page-container {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f9f8f7;
  max-height: 100%;

  .heading-title {
    font-size: 60px;
    line-height: 1;
  }

  .title-large {
    font-size: 36px !important;
  }

  @media (--t) {
    max-height: 650px;
    padding: 60px 32px;
  }


  option {
    background-color: #ffffff;
    color: black;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media (--t) {
      padding-bottom: unset;
      width: unset;
    }

  }

  .page-container.container {
    max-width: 1022px !important;

    @media (--t) {
      padding-bottom: 46px;
    }

    h2 {
      letter-spacing: 0.15em !important;
    }

    h3 {
      text-align: center;

      @media (--t) {
        text-align: left;
      }

    }

    span {
      text-align: center;

      @media (--t) {
        text-align: left;
        line-height: 130%;
        letter-spacing: 0.01em;
      }

    }
  }

  .card-container {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    @media (--t) {
      padding-top: 50px;
    }

  }
}
</style>
