<template>
  <div>
    <v-tooltip color="error" bottom nudge-right="150">
      <template #activator="{ on }">
        <IconQuestion @click="openTip" v-on="on"/>
      </template>
      <div class="tip-container">{{ $t("configurator.upload.popup") }}</div>
    </v-tooltip>
  </div>
</template>

<script>
import IconQuestion from "@/assets/question.svg";

export default {
  name: "TipCard",
  components: {
    IconQuestion
  },
  data() {
    return {
      is_tip_opened: false
    }
  },
  methods: {
    openTip() {
      this.is_tip_opened = !this.is_tip_opened
    }
  },
}
</script>

<style scoped lang="postcss">
.tip-container {
  max-width: 450px;
  border-radius: 10px;
  font-family: 'Sk-Modernist', sans-serif;
  font-size: 15px;
}
</style>
