<template>
  <div class="mobile-configurator">
    <!--    <Alert-->
    <!--        v-model="qualityWarning"-->
    <!--        bottom-->
    <!--        color="white"-->
    <!--        dismissable-->

    <!--    />-->
    <v-container class="px-7">
      <div class="row">
        <div class="col-12">
          <v-responsive
              :aspect-ratio="1"
              height="300"
          >
            <div class="mobile-image-placeholder">
              <div class="d-flex  justify-center align-center flex-column fill-height" v-if="!image" v-ripple
                   @click="$refs.file.click()" style="width: 100%">
                <input ref="file" accept="image/*" class="d-none" type="file" @change="uploadImage($event)"/>
                <IconUploadMini height="80" width="60"/>
                <p class="frame-title my-4">{{ $t('configurator.upload.upload') }}</p>
                <p class="file-types-description passive-grey--text">{{ $t('configurator.placeholderDescription') }}</p>
              </div>
              <ImageEngine
                  v-else
                  :image="image"
                  :customImageSize="customImageSize"
                  :imageUrl="image.thumbnail.url"
                  :originalImageHeight="image.original.height"
                  :originalImageWidth="image.original.width"
                  :snapshot="prepareSnapShot"
                  :snapshotForAddToCart="prepareSnapShotForAddToCart"
                  :thumbnailImageHeight="imageSize.height"
                  :thumbnailImageWidth="imageSize.width"
                  :layoutMode="openLayoutMode"
                  :layout="order.layout"
                  :selectedDimension="selectedDimension"
                  :dimensionList="dimensionList"
                  :customWidthList="customWidthList"
                  :customHeightList="customHeightList"
                  :isMobile="true"
                  @snapshot-event="addToCart"
                  @coords-event="coords = $event"
                  @image-zoom-event="checkQuality"
                  @ready-event="imageIsReady"
              />
            </div>

          </v-responsive>
        </div>
        <div class="col-12" v-if="!!image">
          <AppButton @click="image = null" v-if="!!image" small color="#F9F8F7"
                     class="dark-color--text text-capitalize">
            <v-icon size="16" left>mdi-image-size-select-large</v-icon>
            Select Other Image
          </AppButton>
        </div>
        <div class="col-12">
          <div v-if="!image">
            <!--            <user-image-library-->
            <!--              :refreshData="imageLibraryUpdated"-->
            <!--              @user-image-select-event="prepareEnvironment"-->
            <!--            />-->

            <UserImageLibrary :refreshData="imageLibraryUpdated"
                              @user-image-select-event="prepareOrUploadImage"/>
          </div>
          <div class="selections" v-else>
            <h3 class="configurator-content-titles">Selections</h3>

            <table style="width: 100%">
              <tr v-for="cost in costs" style="vertical-align: top">
                <td class="font-weight-bold text-capitalize" style="width: 90px">{{ cost.title }}</td>
                <td>:</td>
                <td class="px-4">
                  <div class="d-flex flex-column">
                    <template v-if="!!cost.notAvailable">
                      {{ $t('notAvailable') }}
                    </template>
                    <template v-else-if="!!cost.notSelected">
                      {{ $t('noSelection') }}
                    </template>
                    <template v-else>
                      <span>{{ cost.orderProp }}</span>
                      <template v-if="cost.extras">
                      <span v-for="extra in cost.extras">
                        {{ extra }}
                      </span>
                      </template>
                    </template>
                  </div>
                </td>
              </tr>
            </table>

          </div>
        </div>
      </div>
      <p v-if="!image" class="text-left mt-3" style="font-size: 12px;">{{ $t('configurator.upload.description') }}</p>


      <v-bottom-sheet eager scrollable v-model="bottomSheet">
        <v-card height="calc(100vh - 120px)">
          <v-toolbar height="50" flat style="flex: 0 1 auto; width: 100%;" class="mobile-toolbar-container">
            <v-btn
                dense
                tile
                depressed
                @click="activeTab--"
                :disabled="prevBtnIsDisabled"
                color="secondary"
                v-if="tab > 0"
                class="text-capitalize"
                small
            >
              <v-icon class="mr-2" size="18">mdi-arrow-left</v-icon>
              Prev
            </v-btn>
            <v-toolbar-title class="font-alethia text-center font-weight-bold text-capitalize">
              {{ activeTabTitle }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                dense
                tile
                depressed
                @click="activeTab++"
                :disabled="nextBtnIsDisabled"
                color="secondary"
                v-if="tab > 0"
                class="text-capitalize"
                small
            >
              Next
              <v-icon class="ml-2" size="18">mdi-arrow-right</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pa-0" style="padding-bottom: 120px!important;">
            <v-tabs-items v-model="activeTab" touchless ref="tabs">
              <v-tab-item></v-tab-item>
              <v-tab-item :disabled="!image">
                <v-container>
                  <p class="configurator-content-titles">
                    {{ $t("configurator.material.title") }}
                  </p>
                  <v-row dense>
                    <v-col v-for="(material, index) in materialData" :key="index" cols="4">
                      <ProductOption
                          :option="material"
                          @click="SET_MATERIAL_VALUE(material)"
                          :value="material.id === selected_material"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item :disabled="_is_layout_active">
                <v-container>
                  <p class="configurator-content-titles">
                    {{ $t("configurator.resize.title") }}
                  </p>
                  <p class="configurator-content-texts">
                    {{ $t("configurator.resize.description") }}
                  </p>
                  <div class="d-flex flex-wrap flex-row justify-space-between" style="max-width: 100%">
                    <SizeSelect :customSize="customSize" :dimensions="dimensionList" @customSize="customSizeSelected"
                                @onSelect="checkDimension"/>
                  </div>
                  <div v-if="customSize">
                    <div class="size-selection my-5">
                      <AppSelect
                          dense
                          color="primary"
                          :label="$t('buttons.select-width')"
                          tile
                          :items="customWidthList"
                          item-value="value.inch"
                          hide-details
                          v-model="customWidth"
                          @input="customWidthChanged"
                          class="custom-size-selector"
                          return-object

                      />
                      <IconX class="mx-2"/>
                      <AppSelect
                          color="primary"
                          :label="$t('buttons.select-height')"
                          tile
                          :items="customHeightList"
                          item-value="value.inch"
                          hide-details
                          dense
                          v-model="customHeight"
                          @input="customHeightChanged"
                          class="custom-size-selector"
                          return-object

                      />
                    </div>
                  </div>

                  <p class="configurator-content-titles mt-5">
                    {{ $t("configurator.layout") }}
                  </p>

                  <div class="configurator-layout-selection d-flex flex-row justify-space-between pr-2"
                       style="max-width: 564px">
                    <label class="layout-type" @click="SET_LAYOUT('1 Panel')">
                      <IconGridDefault :class="order.layout === '1 Panel' ? 'svg-active' : ''"/>
                    </label>
                    <v-tooltip top color="error" :disabled="multiPanelAvailable">
                      <template v-slot:activator="{ on }">
                        <label
                            class="layout-type"
                            @click="SET_LAYOUT('3 Vertical Panels')"
                            :class="{'disabled': !multiPanelAvailable}"
                            v-on="on"
                        >
                          <IconGridVertical :class="order.layout === '3 Vertical Panels' ? 'svg-active' : ''"/>
                        </label>
                      </template>
                      <span>{{ $t('notAvailable') }}</span>
                    </v-tooltip>
                    <v-tooltip top color="error" :disabled="multiPanelAvailable">
                      <template v-slot:activator="{ on }">
                        <label
                            class="layout-type"
                            @click="SET_LAYOUT('3 Horizontal Panels')"
                            :class="{'disabled': !multiPanelAvailable}"
                            v-on="on"
                        >
                          <IconGridHorizontal :class="order.layout === '3 Horizontal Panels' ? 'svg-active' : ''"/>
                        </label>
                      </template>
                      <span>{{ $t('notAvailable') }}</span>
                    </v-tooltip>
                    <v-tooltip top color="error" :disabled="multiPanelAvailable">
                      <template v-slot:activator="{ on }">
                        <label
                            class="layout-type"
                            @click="SET_LAYOUT('4 Wavy Panels')"
                            :class="{'disabled': !multiPanelAvailable}"
                            v-on="on"
                        >
                          <IconGridWave :class="order.layout === '4 Wavy Panels' ? 'svg-active' : ''"/>
                        </label>
                      </template>
                      <span>{{ $t('notAvailable') }}</span>
                    </v-tooltip>
                  </div>
                </v-container>
              </v-tab-item>
              <v-tab-item refs="tabs" :disabled="!_is_edge_active">
                <v-container v-if="selectedMaterial">
                  <template v-for="addon in addons">
                    <template v-if="selectedMaterial.addons.includes(addon.id)">
                      <p class="configurator-content-titles">
                        {{ addon.title }}
                      </p>
                      <v-row dense class="mb-4">
                        <v-col v-for="(item, index) in addon.items" :key="index" cols="4">
                          <ProductOption
                              :option="item"
                              :value="item.title === order[addon.value]"
                              @click="callAction(addon.action, item)"
                          />
                        </v-col>
                      </v-row>
                    </template>
                  </template>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
      <!--      <v-snackbar v-if="!bottomSheet && !showAddToCartBtn && !this.$store.getters.loaderShow" multi-line elevation="0" color="#F5F5F5" width="100%" :value="true" timeout="-1" class="info-snackbar">-->
      <!--        <p class="medium text-left">{{ $t('configurator.upload.description') }}</p>-->
      <!--      </v-snackbar>-->
      <!--      <p v-if="image && qualityWarning" class="mobile-warning text-left d-flex justify-center align-center">-->
      <!--        <v-icon class="mr-1 error&#45;&#45;text" size="18" >mdi-alert</v-icon>-->
      <!--        {{ $t('configurator.quality') }} <span class="font-weight-bold error&#45;&#45;text ml-1">{{ $t('configurator.poor') }}</span>-->
      <!--      </p>-->
      <div
          class="add-to-cart info-snackbar white--text"
          v-if="order.total_amount > 0 && !this.percentage_loader.show"
      >

        <div class="container py-0 px-0 fill-height">
          <div v-if="!showAddToCartBtn" style="width: 100%;" class="without_button d-flex justify-space-between align-center">
            <span class="black--text pa-3 text-uppercase">{{ $t('configurator.total') }}</span>
            <span class="black--text pa-3" style="font-size: 18px">${{ order.total_amount }}</span>
          </div>
          <div v-else class="with_button fill-height" style="width: 100%;">
            <div class="d-flex justify-space-between align-center justify-center">
              <span class="black--text pa-3 text-uppercase">{{ $t('configurator.total') }}</span>
              <span class="black--text pa-3 pr-5" style="font-size: 18px">${{ order.total_amount }}</span>
            </div>
            <v-btn
                height="100%"
                tile
                color="secondary"
                @click="prepareCart"
            >
              {{ $t("buttons.addCart") }}
            </v-btn>
          </div>
        </div>
      </div>
      <v-bottom-navigation
          id="bottom-navigation"
          color="primary"
          class="bottom-nav"
          grow
          v-model="activeTab"
          app
      >
        <template v-for="item in bottomNav">
          <v-btn v-bind="item.props" v-on="item.on">
            <div>
              <small class="mt-2" v-html="item.title"></small>
            </div>
            <component :is="item.icon" height="18" width="auto"/>
          </v-btn>
        </template>
      </v-bottom-navigation>
      <!--      <Alert v-if="qualityWarning" :condition="qualityMessage"/>-->
    </v-container>
  </div>
</template>
<script>
import SizeSelect from '@/components/buttons/size-select'
import ItemCard from '@/components/cards/item-card'
import BottomNavigation from '@/components/shared/navigation/BottomNavigation'
import AddCartButton from '@/components/buttons/AddCartButton'
import SizeSelectMobile from '@/components/buttons/SizeSelectMobile'
import SheetHeader from '@/components/shared/navigation/SheetHeader'
import {configuratorMixins} from '@/mixins/configuratorMixins'
import {IconPrintMini} from "@/assets/svg-icons";
import Alert from '@/components/cards/unique-card/Alert'
import {mapGetters} from "vuex";

export default {
  name: 'ConfiguratorMobile',
  mixins: [configuratorMixins],
  components: {
    SheetHeader,
    SizeSelectMobile,
    AddCartButton,
    BottomNavigation,
    SizeSelect,
    ItemCard,
    IconPrintMini,
    Alert
  },
  data: () => ({
    selectOtherImage: false,
  }),

  computed: {
    costs() {
      return [
        {
          title: this.bottomNav[1].title,
          orderProp: this.selectedMaterial?.title,
          notSelected: !this.selectedMaterial,
        },
        {
          title: this.bottomNav[2].title,
          orderProp: this.selectedSizeText,
          extras: [
            this.order.layout
          ]
        },
        {
          title: this.bottomNav[3].title,
          orderProp: this.order.product_frame,
          notAvailable: !this._is_edge_active,
          notSelected: this.edgeFrameIsNotSelected,
          extras: [
            this.order.product_edge,
            this.order.product_border,
            this.order.product_hanging_hardware,
          ]
        },
      ]
    },
    activeTab: {
      get() {
        return this.tab
      },
      set(val) {
        let disabledTabs = this.$refs.tabs.$children.map(item => item.$props.disabled)
        this.tab = disabledTabs[val] ? 0 : val
      }
    },
    activeTabTitle() {
      return this.bottomNav[this.tab]?.title
    },
    selectedSizeText() {
      return this.order.selected_size_height && this.order.selected_size_width ? `${this.order.selected_size_width} x ${this.order.selected_size_height}` : 'No selection yet.'
    },
    bottomSheet: {
      get() {
        return this.tab !== 0
      },
      set(val) {
        this.tab = val !== false ? val : 0
      }
    },
    bottomNav() {
      return [
        {
          title: `${this.$t("configurator.upload.tab-one")} & ${this.$t("configurator.upload.tab-two")}`,
          icon: 'IconUploadMini',
          orderProp: '',
          on: {},
          props: {
            disabled: false
          }
        },
        {
          title: `${this.$t("configurator.material.tab-one")} & ${this.$t("configurator.material.tab-two")}`,
          icon: 'IconPrintMini',
          on: {},
          props: {
            disabled: !this.image
          }
        },
        {
          title: `${this.$t("configurator.resize.tab-one")} & ${this.$t("configurator.resize.tab-two")}`,
          icon: 'IconSizeMini',

          on: {},
          props: {
            disabled: this._is_layout_active
          }
        },
        {
          title: `${this.$t("configurator.border.tab-one")} & ${this.$t("configurator.border.tab-two")}`,
          icon: 'IconEdgeMini',
          on: {},
          props: {
            disabled: !this._is_edge_active || this._is_layout_active || !this.order.layout
          }
        },
      ]
    },
    nextBtnIsDisabled() {
      switch (this.tab) {
        case 1:
          return !this.image || !this.order.product_material
        case 2:
          return !this.order.layout
        case 3:
          return !this._is_edge_active
        default:
          return false
      }
    },
    prevBtnIsDisabled() {
      switch (this.tab) {
        case 1:
          return true;
        case 2:
          return !this.image || !this.order.product_material;
        case 3:
          return !this.order.layout;
          // default:
          //   return false
      }
    },
    ...mapGetters({
      percentage_loader: "percentage_loader"
    })
  }
}
</script>

<style lang="scss">
.bottom-nav {
  z-index: 900 !important;

  .v-btn {
    padding: 0 4px !important;

    &.v-btn--active {
      small {
        color: #077d60;
      }

      svg {
        fill: #077d60;
      }
    }

    small {
      width: 100%;
      font-size: 8px;
      display: block;
      margin-top: 4px;
      text-align: center;
      line-height: 10px;
      text-transform: uppercase;
    }

    @media(max-width: 600px) {
      .v-btn__content {
        flex: unset;
        line-height: 1;
        white-space: break-spaces;
        justify-content: flex-end;
        margin-top: 16px;
      }
    }
  }
}

.custom-size-selection {
  margin-top: 3px !important;
}

.quality-warning-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding-right: 40px;
  padding-left: 40px;
  transition: width 2ms;

  span {
    margin-bottom: 10px;
  }

  .border-container {
    width: 60%;
    background-color: red;

    .progress {
      width: 60%;
      height: 3px;
      transition: width 1000ms;
      background-color: red;
    }
  }
}


.size-selection {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;

  .app-select {
    max-width: 125px;
  }
}

.configurator-layout-selection {
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  label {
    svg {
      fill: black;
    }

    .svg-active {
      fill: #009071;
      transition-duration: 700ms;
    }

    input {
      display: none;
    }
  }
}

.mobile-warning {
  position: fixed;
  height: 50px;
  left: 0;
  right: 0;
  bottom: 116px;
  z-index: 203;
}

.mobile-configurator {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*margin-top: 25px;*/
  padding-bottom: 128px;

  td {
    font-size: 12px;
    line-height: 14px;
    vertical-align: top;
    padding: 8px 0;
  }

  .add-to-cart {
    position: fixed;
    height: 50px;
    left: 0;
    right: 0;
    bottom: 56px;
    z-index: 203;
    background: rgba(236, 234, 231, 1);
  }

  .info-snackbar.v-snack .v-snack__wrapper {
    margin: 0;
    padding: 0 12px;
    min-height: 50px;

    .v-snack__content {
      padding: 8px 16px;
    }

    p {
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      letter-spacing: 0.05em;
      margin: 0;
      color: #393939;
    }
  }

  .mobile-image-placeholder {
    box-sizing: border-box;
    background-color: #F9F8F7;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 5px dashed #000000;
    border-radius: 1px;
    height: 100%;
  }

  .installation-input {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #077d60;
    width: 45%;
    height: 10vh;
    cursor: pointer;

    input {
      display: none;
    }

    span {
      font-weight: bold;
      text-transform: uppercase;
      color: #077d60;
      font-size: 16px;
    }
  }

  .file-types-description {
    font-size: 12px;
    line-height: 14px;
  }

  .title {
    font-family: var(--font-family--alethia) !important;
    font-size: 22px;
    font-weight: bolder;
    text-transform: capitalize;
  }

  .grid-container {
    svg {
      fill: black;
    }

    .svg-active {
      fill: #009071;
    }
  }

  .frame-title {
    font-size: 20px;
    letter-spacing: .05em;
  }
}

.configurator-content-titles {
  font-weight: bold;
}


.mobile-toolbar-container {
  width: 100%;

  .v-toolbar__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .v-toolbar__title {
      width: 100%;
    }
  }
}

.with_button{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
