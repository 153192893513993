<template>
  <div class="size-buttons-section">
   <div v-for="size in data">
     <label v-if="size.price" for="button">{{ size.price }}$</label>
     <button
         :class="{'active': custom && selected === size}"
         @click="onSelect(size)"
         :value="size.id"
         id="button">
       {{ size.size }}
     </button>
   </div>
  </div>
</template>

<script>
export default {
  name: "SizeButton",
  data() {
    return {
      selected: false,
    };
  },
  methods:{
    onSelect(size) {
      this.selected = size;
      this.$emit('onSelect',size);
    },
  },
  watch: {
    value() {
      if (this.value) {
        const MATCHED_MATERIAL = this.data?.find(
            (o) => o.id === this.value
        );
        if (MATCHED_MATERIAL) {
          this.selected = MATCHED_MATERIAL?.id;
        }
        this.$forceUpdate();
      }
    },
  },
  props: {
    custom:{
      type:[String,Boolean]
    },
    data:{
      type:Array
    },
    size: {
      type: String,
    },
    price: {
      type: String,
    },
    value:{
      type: [String, Number, Boolean, Object],
      default: null,
    }
  },
}
</script>

<style scoped>
.size-buttons-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 35px;
  @media (--t) {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 0 !important;
    justify-content: space-between !important;
    width: 100%;
  }
}

button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 10px;
  border: 2px solid #009771;
  box-sizing: border-box;
  font-family: Sk-Modernist, 'sans-serif';
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #000000;
  height: 45px;
  background-color: transparent;
  margin-right:12px ;
  &:focus {
    outline: none;
  }

  @media (--t) {
    margin-right:0 ;

  }
}

label {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #009771;
  flex: none;
}
.active {
  background-color: #009771;
  color:#ffffff;
  transition-duration: 700ms;
}
</style>
