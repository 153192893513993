<template>
  <div>
    <span @click="upload" class="upload-button">
      <input @change="onUpload" accept="image/*" ref="file" type="file" />
      {{ $t("configurator.upload.upload") }}
      <span class="svg-background"><IconArrowWhite /></span>
    </span>
  </div>
</template>

<script>
import IconArrowWhite from "@/assets/svg-icons/white-arrow.svg";

export default {
  name: "ImageUploader",
  components: {
    IconArrowWhite
  },
  methods: {
    onUpload(val) {
      this.$emit("onUpload", val);
    },
    upload(e) {
      this.$emit("upload", e);
    }
  }
};
</script>

<style scoped>
.upload-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #009771;
  font-weight: bold;
  font-family: "Sk-Modernist", sans-serif;
  padding-left: 10px;
  padding-right: 5px;
  height: 40px;
  width: 235px;
  margin-bottom: 20px;
  cursor: pointer;

  input {
    display: none;
  }

  .svg-background {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 30px;
    width: 30px;
    background-color: #009771;
  }
}
</style>
