<template>
  <div class="d-flex flex-row flex-wrap" v-if="dimensions">
    <AppButton
      :outlined="!dimension.selected"
      color="#009771"
      height="45"
      width="100"

      @click="onSelect(dimension)"
      class="font-weight-regular"
      dark
      v-for="(dimension, index) in dimensions"
      :key="index"
    >
      <span :class="dimension.selected ? 'white--text' : 'primary--text'">
        {{ dimension.label }}
      </span>
    </AppButton>

    <AppButton
      :class="{ selected: customSize }"
      @click="$emit('customSize', true)"
      :outlined="!customSize"
      color="#009771"
      height="45"
      dark
      class="font-weight-regular"
    >
      <span :class="customSize ? 'white--text' : 'primary--text'">
        {{ $t("price.custom") }}
      </span>
    </AppButton>
  </div>
</template>

<script>
export default {
  name: "SizeSelect",
  data() {
    return {
      selected: false,
    };
  },
  props: {
    dimensions: {
      type: Array,
    },
    customSize: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSelect(dimension) {
      // this.selected = dimension;
      this.dimensions.forEach((d) => (d.selected = false));
      dimension.selected = true;
      this.$emit("customSize", false);
      this.$emit("onSelect", dimension);
    },
  },
  mounted() {
    if (this.customSize) {
      // this.$emit("customSize");
    }
  },
};
</script>

<style lang="postcss" scoped>
.v-btn {
  padding: 9px 10px;
  margin-top: 10px;
  font-size: 15px;
  line-height: 150%;
  font-weight: normal;
  letter-spacing: 0.08em;
  margin-right: 9px;
  background-color: transparent;
  cursor: pointer;
}
</style>
