<template>
  <div class="swiper-container story-swiper" ref="swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in items" :key="index">
        <StoryCard
            class="fill-height"
          :item="item"
        />
      </div>
    </div>
    <div class="swiper-pagination" ref="pagination"></div>
  </div>
</template>

<script>
import {swiperMixins} from '@/mixins/swiperMixins'

export default {
  name: 'StoryCardSwiper',
  mixins: [swiperMixins],
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    options() {
      return {
        slidesPerView: 1,
        autoplay: true,
        loop: true,
        spaceBetween: 16,
        pagination: {
          el: this.$refs.pagination,
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.story-swiper {
  .swiper-slide {
    height: unset!important;
  }

}
.swiper-pagination {
  position: relative;
  margin: 18px 0 0;
  @media (--t) {
    margin: 30px 0;
  }
  bottom: 0;
}

</style>
