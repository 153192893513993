<template>
  <v-container>
    <h6 class="small-title mb-6">{{ $t('profile.update_address') }}</h6>
    <AppForm
      @keydown.enter.native="validateAndSubmit"
      :validationPerformed="validationPerformed"
      :form-fields="formFields"
      :loading="loading"
      :form-data="userData"
      ref="form"
    />
    <AppButton
      @click="validateAndSubmit"
      color="primary"
      height="50"
      class="mt-6"
      :min-width="$vuetify.breakpoint.mdAndUp ? 400 : 250"
      :loading="loading"
    >
      {{$t('buttons.save_address')}}
    </AppButton>
  </v-container>
</template>

<script>
import { pickBy } from 'lodash'
import { addressPageMixins } from '@/mixins/addressPageMixins'


export default {
  name: 'UpdateAddress',
  mixins: [addressPageMixins],
  data: () => ({
    actionName: 'updateUserData'
  }),
  mounted() {
    this.userData = {
      ...this.currentUserData.contact.addresses[this.$route.params.index],
      ...pickBy(this.contactPerson, (value, key) => key !== 'id'),
      contactPersonId: this.contactPerson?.id,
      // email: this.currentUserData.karavan_user.email
    }
  },

  computed: {
    contactPerson() {
      return this.currentUserData?.contact?.contact_people?.[parseInt(this.$route.params.index)]
    }
  }
}
</script>
