<template>
<div class="container">
  <div class="accordion-body">
              <span class="soft-text font-weight-bold">{{ $t("faq.warranty.what-text.one-title") }}</span
              >:
    <span class="soft-text">{{ $t("faq.warranty.what-text.one-text") }}</span>
    <br/><br/>
    <span class="soft-text font-weight-bold">{{ $t("faq.warranty.what-text.two-title") }}</span
    >:
    <span class="soft-text">{{ $t("faq.warranty.what-text.two-text") }}</span>
    <br/><br/>
    <span class="soft-text font-weight-bold">{{ $t("faq.warranty.what-text.three-title") }}</span
    >: <span class="soft-text">{{ $t("faq.warranty.what-text.three-text") }}</span
  ><br/>
  </div>
</div>
</template>

<script>
export default {
  name: 'FaqWarrantyWhat'
}
</script>

<style scoped>

</style>
