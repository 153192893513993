<template>
  <v-row class="cost-card ma-0">
    <v-col class="order-info pa-0">
      <IconCartNew/>
      <ul>
        <li v-if="total">{{ `${$t('configurator.total')}: ${total || 0}` }}</li>
        <li v-if="material">{{ material }}</li>
        <li v-if="width || height">{{ width }}" x {{ height }}"</li>
        <li v-if="layout">{{ _layout_name }}</li>
        <li v-if="hardware">{{ hardware }}</li>
        <li v-if="frame">{{ frame }}</li>
        <li v-if="edge">{{ edge }}</li>
        <li v-if="border">{{ border }}</li>
      </ul>
    </v-col>

    <v-col class="button-section pa-0">
      <AppButton v-if="!disabled" class="add-to-cart-btn" height="100%" width="100%" color="#009071" v-ripple
                 @click="onClick">{{ $t('buttons.addCart') }}
      </AppButton>
      <AppButton v-else :disabled="!nextBtnStatus" class="add-to-cart-btn" height="100%" width="100%" color="#009071" v-ripple
                 @click="$emit('next-action')">
        {{ $t('buttons.next') }}
      </AppButton>
    </v-col>

  </v-row>
</template>

<script>
import {IconCartNew} from '@/assets/svg-icons'

export default {
  name: 'CostCard',
  props: ['nextBtnStatus', 'height', 'width', 'total', 'material', 'layout', 'hardware', 'frame', 'edge', 'border', 'disabled'],
  components: {
    IconCartNew
  },
  methods: {
    onClick(e) {
      this.$emit('onClick', e)
    }
  },
  computed: {
    _layout_name() {
      if (localStorage.getItem('lang') === 'fr') {
        if (this.layout === '3 Horizontal Panels') {
          return '3 Panneaux Horizontaux'
        } else if (this.layout === '1 Panel') {
          return '1 Panneau'
        } else if (this.layout === '3 Vertical Panels') {
          return '3 Panneaux Verticaux'
        } else if (this.layout === '4 Wavy Panels') {
          return '4 Panneaux Ondules'
        }
      } else return this.layout

    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.cost-card {
  position: unset;
  @media (--t) {
    position: absolute;
    bottom: 0;
  }

  .order-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    background-color: #F1EFEC;
    padding-left: 35px;
    height: 180px;
    width: 40vw;


    ul {
      margin-left: 35px;

      li {
        font-family: Sk-Modernist, "sans-serif" !important;
        font-size: 15px;
        text-transform: capitalize;
        color: #000000 !important;
        line-height: 18px !important;
        letter-spacing: 3px;
      }
    }
  }

  .button-section {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff !important;
    font-size: 25px;
    outline: none !important;
    width: 20vw;

    .v-btn {
      font-size: 25px !important;
      font-weight: normal;
    }

    button {
      height: inherit;
      width: inherit;
      background-color: transparent;
      border: none;
      color: #ffffff !important;
      outline: none !important;
    }

    &:after {
      content: "";
      width: 0;
      height: 0;
      left: 0;
      border-top: 25px solid transparent;
      border-left: 25px solid #F1EFEC;
      border-bottom: 25px solid transparent;
      position: absolute;
      bottom: 40%;
    }

  }

  .app-btn.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: rgba(0, 151, 113, .3) !important;
  }
}
</style>
