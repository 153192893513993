<template>
  <figure class="avatar">
    <img :src="imageSrc" :alt="alt" :style="{width: `${size}px`, height: `${size}px`}"/>
  </figure>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    src: {
      type: String,
    },
    alt: {
      type: String
    },
    size: {
      type: Number,
      default: () => 116
    }
  },
  computed: {
    imageSrc(){
      return this.src || require("../../assets/images/avatar-place.jpg")
    }
  }
}
</script>

<style scoped lang="scss">
.avatar {
  display: inline-flex;
  border-radius: 999px;

  img {
    border-radius: inherit;
  }
}
</style>
