<template>
  <div class="usage-section">
    <Logo class="background-logo"/>
    <v-container :fluid="$vuetify.breakpoint.lgAndUp">
      <v-row class="content">
        <v-col class="left " lg="5" md="6" order="2" order-lg="2" order-sm="2" cols="12">
          <img :src="left_image" alt="" class="img-fluid desktop-image-one">
          <img :src="right_image" alt="" class="img-fluid mobile-image-one">

          <div v-if="['MetalPaper','Matte','Textured'].includes($route.name)" class="mt-2">
            <span class="small-title  d-block">
            {{ $t('by') }} &nbsp; {{ product_artist }}
          </span>
            <span class="small-title d-block">
            Instagram &nbsp; {{ product_social }}
          </span>
          </div>

          <div class="vibrant-texts">
            <h1 class="info-titles">{{ vibrant_title }}</h1>
            <p v-if="vibrant_description" class="page-subtitle ">{{ vibrant_description }}</p>
            <p v-if="lang !== 'fr' && vibrant_footer" class="page-subtitle">{{ vibrant_footer }}</p>
          </div>


        </v-col>

        <v-col class="right" lg="5" md="6" order="1" order-lg="2" order-sm="1" cols="12">

          <div class="reverse-section">
            <div id="right-one" class="order-sm-1">
              <h1 class="info-titles">{{ handcrafted_title }}</h1>
              <p class="page-subtitle">{{ handcrafted_description }}</p>
              <ul>
                <li class="page-subtitle">• {{ feature_one }}</li>
                <li class="page-subtitle">• {{ feature_two }}</li>
                <li class="page-subtitle">• {{ feature_three }}</li>
                <li class="page-subtitle">• {{ feature_four }}</li>
                <li v-if="feature_five && lang === 'en'" class="page-subtitle">• {{ feature_five }}</li>
                <li v-if="feature_six && lang === 'en'" class="page-subtitle">• {{ feature_six }}</li>
              </ul>
              <p class="page-subtitle">{{ handcrafted_team }}</p>
              <p class="page-subtitle">{{ handcrafted_moments }}</p>
            </div>
            <img :src="right_image" alt="" class="img-fluid order-sm-2 desktop-image-two">
            <img :src="left_image" alt="" class="img-fluid order-sm-2 mobile-image-two">
          </div>

          <br>

          <div v-if="['MetalPaper','Matte','Textured'].includes($route.name)" class=" mt-2">
            <span class="small-title  d-block">{{ $t('by') }} &nbsp; {{ product_artist }}</span>
            <span class="small-title d-block">Instagram &nbsp; {{ product_social }}</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import Logo from "../../assets/svg-icons/logo/logo-7.svg";

export default {
  name: "UsagePlaces",
  components: {
    Logo
  },
  data() {
    return {
      lang: null,
    }
  },
  props: {
    left_image: {
      type: String,
    },
    right_image: {
      type: String,
    },
    feature_one: {
      type: String
    },
    feature_two: {
      type: String
    },
    feature_three: {
      type: String
    },
    feature_four: {
      type: String
    },
    feature_five: {
      type: String
    },
    feature_six: {
      type: String
    },
    handcrafted_description: {
      type: String,
    },
    handcrafted_moments: {
      type: String,
    },
    handcrafted_team: {
      type: String,
    },
    handcrafted_title: {
      type: String,
    },
    vibrant_title: {
      type: String,
    },
    vibrant_description: {
      type: String
    },
    vibrant_footer: {
      type: String
    },
    handcrafted_social: {
      type: String,
    },
    handcrafted_artist: {
      type: String,
    },
    product_social: {
      type: String,
    },
    product_artist: {
      type: String
    }
  },
  beforeMount() {
    this.lang = localStorage.getItem('lang')
  }
}
</script>

<style lang="postcss" scoped>


.usage-section {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f9f8f7;
  position: relative;
  @media (--t) {
    padding-top: 124px;
    padding-bottom: 60px;
  }

  .desktop-image-one,
  .desktop-image-two {
    display: none;
    @media (--t) {
      display: flex;
    }
  }

  .mobile-image-one,
  .mobile-image-two{
    display: flex;
    @media (--t) {
      display: none;
    }
  }



  .vibrant-texts {

    @media (--t) {
      margin-top: 86px;
    }

    :nth-child(2) {
      @media (--t) {
        margin-top: -5px;
      }
    }

  }

  .background-logo {
    display: none;
    @media (--t) {
      display: block;
      position: absolute;
      top: 0;
      right: 5%;
    }
  }

  .content {
    justify-content: space-evenly;
  }

  .small-title {
    font-family: Sk-Modernist, 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 95%;
    color: #000000;
  }

  .info-titles {
    font-family: var(--font-family--alethia) !important;
    font-size: 40px !important;
    line-height: 102.7% !important;
    color: #000000 !important;
  }

  .page-subtitle {
    font-family: Sk-Modernist, 'sans-serif' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 150% !important;
    letter-spacing: 0.02em !important;
    color: #000000 !important;
  }


  p {
    text-align: justify;
    margin-top: 4px;
  }

  .left {
    padding-left: 20px;
    padding-right: 20px;
    @media (--t) {
      padding: unset;
    }

    h1 {
      margin-top: 35px;
    }

    p {
      max-width: 580px;
    }
  }

  .left {
    overflow: hidden;

    img {
      max-height: 420px;
      width: 100%;
      object-fit: cover;
      margin-top: -25px;
      @media (--t) {
        max-height: unset;
      }
    }


  }

  .right {
    padding-left: 20px;
    padding-right: 20px;
    @media (--t) {
      padding: unset;
    }

    .reverse-section {
      display: flex;
      flex-direction: column-reverse;
      @media (--t) {
        display: flex;
        flex-direction: column;
      }

      img {
        max-height: 420px;
        object-fit: contain;
        @media (--t) {
          max-height: unset;
        }
      }

      h1 {
        margin-top: 20px;
        @media (--t) {
          margin-top: unset;
        }
      }


    }

    img {
      margin-top: 0;
      @media (--t) {
        margin-top: 58px;
      }
    }

    p {
      max-width: 555px;
      margin-top: 15px;
    }

    ul {
      padding: 0;
      max-width: 555px;
      margin-top: 24px;

      li {
        text-align: left;
      }
    }

  }
}
</style>
