<template>
  <header class="header">
    <div class="header-inner">
      <!-- LEFT-NAV -->
      <div class="left-navigation">
        <MegaMenu/>
        <v-btn v-if="$vuetify.breakpoint.smAndDown" icon color="primary" @click="openMenu">
          <IconHamburger/>
        </v-btn>
      </div>

      <!-- DESKTOP-MOBILE ICONS -->
      <div class="logo">
        <router-link :to="{name:'Home'}">
          <IconLogoDesktop class="nav-logo-desktop"/>
        </router-link>
        <router-link :to="{name:'Home'}">
          <IconLogoMobile class="nav-logo-mobile"/>
        </router-link>
      </div>

      <!-- RIGHT-NAV -->
      <nav class="right-navigation">
        <v-btn
            icon
            :to="{name:'Cart'}"
            v-if="smAndDown"
        >
        <span class="mobile-cart-icon">
          <icon-cart-mini/>
          <span v-if="cartItemsCount > 0"
                class="mobile-badge">{{ cartItemsCount }}</span>
        </span>
        </v-btn>

        <div class="navigation-group">
          <router-link :class="$route.name === 'About' ? 'route-active-border' : ''" :to="{name:'About'}"
                       class="header-link">
            {{ $t("navbar.about").toUpperCase() }}
          </router-link>
          <router-link v-if="!getAuthStatus" :class="$route.name === 'SignIn' ? 'route-active-border' : ''"
                       :to="{name:'SignIn'}" class="header-link">
            {{ $t("navbar.login").toUpperCase() }}
          </router-link>
          <router-link v-if="getAuthStatus" class="header-link" :to="{name: 'Account Details'}"
                       active-class="route-active-border">
            {{ $t("navbar.account").toUpperCase() }}
          </router-link>
          <router-link :to="{name:'Cart'}" class="header-link flex-row d-flex align-center justify-center"
                       style="position: relative">
            <IconCartMini class="cart-icon"/>
            <span v-if="cartItemsCount > 0"
                  class="badge">{{ cartItemsCount }}</span>
          </router-link>
          <v-menu :nudge-bottom="60">
            <template v-slot:activator="{ on }">
              <a class="header-link" v-on="on">
                {{ (language || '').toUpperCase() }}
              </a>
            </template>
            <v-list>
              <template v-for="locale in availableLocales">
                <v-list-item :key="locale.code" @click="changeLanguage(locale.code)">
                  {{ locale.code.toUpperCase() }}
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import IconLogoDesktop from "@/assets/svg-icons/logo/logo-1.svg";
import IconLogoMobile from "@/assets/svg-icons/logo/logo-3.svg";
import {IconPlus} from "@/assets/svg-icons";
import Select from "@/components/shared/Select";
import {mapGetters} from "vuex";
import MegaMenu from '@/components/menus/MegaMenu'
import {languageSelctorMixins} from '@/mixins/languageSelctorMixins'

export default {
  name: 'MainHeader',
  mixins: [languageSelctorMixins],
  data() {
    return {
      isLanguageOpened: false,
      isShopOpened: false,
    }
  },
  methods: {
    openMenu() {
      this.$store.commit('SET_MOBILE_MENU', true)
    }
  },
  computed: {
    ...mapGetters({
      cart: "get_cart_without_sample_kit",
      getAuthStatus: "getAuthStatus"
    }),
    cartItemsCount() {
      return this.cart?.length || 0
    }
  },
  components: {
    MegaMenu,
    IconLogoDesktop,
    IconLogoMobile,
    IconPlus,
    Select
  }
}
</script>

<style lang="scss" scoped>

.form-select {
  border: 0 !important;
  padding: 0 !important;
  cursor: pointer;
  font-family: Sk-Modernist, 'sans-serif' !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #535353;
  max-width: 90px;
}

li {
  cursor: pointer;
}

.cart-icon {
  //margin-right: 20px;
}

.badge {
  display: flex;
  right: 12px;
  top: 12px;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  height: 20px;
  width: 20px;
  font-size: 14px;
  color: white;
  background-color: #000000;
  font-family: Sk-Modernist, 'sans-serif';
  border-radius: 100%;
}

.dropdown-menu {
  padding: 0;
  overflow: hidden;
  height: 632px;
  width: 1190px;

  .big-dropdown-left {
    padding: 76px 0 76px 42px;
  }
}

.dropdown-toggle::after {
  display: none;
}


.header {
  z-index: 3;
  left: 0;
  right: 0;
  top: 0;
  background-color: white;
  position: sticky;

  .select {
    outline: none;
    border: 0;
    cursor: pointer;
    font-family: Sk-Modernist, 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.05em;
  }

  .language-select {
    color: #A4A4A4;
  }

  .shop-select {
    color: #000000;
  }

  .header-inner {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    padding: 0 12px 0 12px;
    @media (--t) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    @media (--d) {
      max-width: 85%;
    }


    .navigation-group {
      display: none;
      @media (--t) {
        display: flex;
        align-items: center;
        > * {
          height: 100%;
        }
      }
    }

    .left-navigation {
      .mobile-menu-icon {
        display: flex;
        @media (--t) {
          display: none;
        }
      }
    }

    .logo {
      display: flex;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @media (--t) {
        display: flex;
        justify-content: center;
      }

      .nav-logo-desktop {
        display: none;
        @media (--t) {
          display: flex;
        }
      }

      .nav-logo-mobile {
        display: flex;
        @media (--t) {
          display: none;
        }
      }
    }

    .right-navigation {
      .language {
        cursor: pointer;
      }

      .language-dropdown {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 90px;
        width: 90px;
        top: 90%;
        left: 0;
        position: absolute;
        z-index: 50;
        background-color: white;
        border: 1px solid #000000;
        border-radius: 2px;
        padding: 15px;

        p {
          font-family: Sk-Modernist;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 215.6%;
          display: flex;
          align-items: center;
          letter-spacing: 0.02em;
          color: #000000;
        }
      }

      @media (--t) {
        display: flex;
        justify-content: flex-end;
      }

      .mobile-cart-icon {
        display: flex;
        @media (--t) {
          display: none;
        }

        .mobile-badge {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          background-color: #000000;
          z-index: 1;
          height: 20px;
          width: 20px;
          right: -2px;
          top: -6px;
          border-radius: 50%;
          color: #FFFFFF;
          font-family: Sk-Modernist, "sans-serif";
          font-size: 12px;
        }
      }
    }
  }
}
</style>
