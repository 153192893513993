<template>
  <AppFormInput
    v-bind="$attrs"
    v-model="innerValue"
    v-mask="cardMaskPattern"
  >
    <template v-slot:append>
      <v-icon v-if="!cardBrand">{{ icon }}</v-icon>
      <v-img
        v-else
        :src="logo"
        height="40px"
        width="40px"
        contain
        style="margin-top: -6px"
      />
    </template>
  </AppFormInput>
</template>

<script>
import { formInputMixins } from '@/mixins/formInputMixins'
import {creditCardMixins} from '@/mixins/creditCardMixins'

export default {
  name: 'AppCreditCardInput',
  mixins: [formInputMixins, creditCardMixins],
  computed: {
    cardNumber() {
      return this.innerValue || ''
    },
    icon() {
      return 'mdi-credit-card'
    },
  }
}
</script>

<style scoped>

</style>
