<template>
<div class="container pa-0">
  <div class="video-section mt-lg-15">
    <video id="myVideo" autoplay loop muted>
      <source :src="src" type="video/mp4"/>
    </video>
  </div>
</div>
</template>

<script>
export default {
  name: 'ProductVideoSection',
  props: {
    src: {
      type: String,
      default: () => null,
    }
  }
}
</script>

<style scoped lang="scss">
  .container {
    @media (min-width: 1650px) {
      max-width: 1600px;
    }
    .video-section {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  video {
    width: calc(100% - 40px);
    margin-bottom: 30px;
    object-fit: cover;
    height: 250px;
    @media (--t) {
      width: 100%;
      height: unset;
      margin-bottom: 100px;
      max-width: 1600px;
      max-height: 600px;
      padding: 0;
    }
  }
</style>
