<template>
  <LayoutTabsContainer  ref="container">
    <template #title>
      {{ $t("paper.title") }}
    </template>
    <template #subTitle>
      {{ $t("paper.subtitle") }}
    </template>
    <v-tabs v-model="selected" :icons-and-text="!mdAndUp" slider-color="secondary" class="layout-tabs paper-tab" :vertical="mdAndUp">
      <template v-for="(tab, index) in items">
        <v-tab active-class="secondary--text" @click="$refs.container.alignToBottom()" class="dense" :key="tab.id">
          <div class="d-flex flex-column">
            <span>
              {{ tabs[index] }}
            </span>
          </div>
          <template v-if="mdAndUp">
            <v-spacer/>
            <IconRightArrow class="arrow"/>
          </template>
        </v-tab>
      </template>
      <template v-for="tab in items">
        <v-tab-item :key="tab.title">
          <v-card :color="mdAndUp ? '#f6f6f6' : 'white'" flat v-bind="cardProps">
            <div class="d-flex flex-column align-center fill-height pa-md-8 pa-6" style="width: 100%">
              <v-img
                :src="tab.content.image"
                contain
                :alt="tab.title"
                :width="imgWidth"
                max-width="400"
                :aspect-ratio="(400/280)"
              />
              <div class="d-flex align-center flex-column " style="width: 100%">
                <h3 class="info-titles mt-4 text-uppercase">{{ lProp(tab, 'content.title') }}</h3>
                <ProductPaperPropertyTable
                  class="mt-md-8 mt-4"
                  :value="lProp(tab, 'content.text')"
                  :priceRange="tab.priceRange"
                />
                <AppButton
                  @click="savePaperData(tab)"
                  color="primary"
                  :large="!smAndDown"
                  class="mt-4"
                >
                  {{ $t('buttons.start').toUpperCase() }}
                </AppButton>
              </div>
            </div>
          </v-card>
        </v-tab-item>
      </template>
    </v-tabs>
  </LayoutTabsContainer>
</template>
<script>
import {tabLayoutMixins} from '@/mixins/tabLayoutMixins'
import {pickBy} from 'lodash'
import localforage from 'localforage'
import ProductPaperPropertyTable from '@/components/tab/paper-tab/ProductPaperPropertyTable'

export default {
  name: 'PaperTab',
  mixins: [tabLayoutMixins],
  data() {
    return {
      lang: null,
      tabs: [],
      selected: null,
      items: [
        {
          id: 4,
          code: 'matte',
          paper: 'satin_photo_paper',
          priceRange: 1,
          content: {
            title: "MATTE PHOTO PAPER",
            image: require('@/assets/images/usage-new/14.png'),
            text_en: [
              {
                id: 1,
                text: "Matte Photo Paper"
              },
              {
                id: 2,
                text: "180gsm, 7.5 mil"
              },
              {
                id: 3,
                text: "Matte classic finish"
              },
              {
                id: 4,
                text: "Photography, Digital Art, Watercolor Painting Reproduction"
              },
            ],
            text_fr: [
              {id: 1, text: 'Papier photo mat'},
              {id: 2, text: '180gsm, 7.5 mil'},
              {id: 3, text: 'Fini classique mat'},
              {id: 4, text: 'Photographie, art digital, aquarelle, reproduction de peinture'}
            ],
            title_en: "MATTE PHOTO PAPER",
            title_fr: "PAPIER PHOTO MAT"
          }
        },
        {
          id: 5,
          code: 'satin',
          paper: 'satin_photo_paper',
          priceRange: 1,
          content: {
            image: require('@/assets/images/usage-new/15.png'),
            text_en: [
              {
                id: 1,
                text: "Satin Photo Paper"
              },
              {
                id: 2,
                text: "200gsm, 8mil"
              },
              {
                id: 3,
                text: "Classic satin finish"
              },
              {
                id: 4,
                text: "Photography"
              },
            ],
            text_fr: [
              {
                id: 1,
                text: "Papier photo satin"
              },
              {
                id: 2,
                text: "200gsm, 8mil"
              },
              {
                id: 3,
                text: "Fini classique satin"
              },
              {
                id: 4,
                text: "Photographie"
              },
            ],
            title_en: "SATIN PHOTO PAPER",
            title_fr: "PAPIER PHOTO SATIN"
          },
        },
        {
          id: 6,
          code: 'metallic',
          paper: 'metallic_photo_paper',
          priceRange: 2,
          content: {
            image: require('@/assets/images/usage-new/16.png'),
            text_en: [
              {
                id: 1,
                text: 'Premium Metallic Photo Paper  '
              },
              {
                id: 2,
                text: '270gsm, 10mil'
              },
              {
                id: 3,
                text: 'Chrome pearl glossy finish'
              },
              {
                id: 4,
                text: 'Photography'
              },
            ],
            text_fr: [
              {id: 1, text: 'Papier photo métallique'},
              {id: 2, text: '270gsm, 10mil'},
              {id: 3, text: 'Fini glacé chrome perlé'},
              {id: 4, text: 'Photographie'}
            ],
            title_en: 'METALLIC “GLOSSY” PHOTO PAPER',
            title_fr: 'PAPIER PHOTO MÉTALLIQUE “GLACÉ”'
          }
        },
        {
          id: 7,
          code: 'textured',
          paper: 'textured_fine_art',
          priceRange: 3,
          content: {
            title: "TEXTURED FINE ART PAPER",
            image: require('@/assets/images/usage-new/12.png'),
            text_en: [
              {
                id: 1,
                text: "Premium Textured Fine Art Paper"
              },
              {
                id: 2,
                text: "285gsm, 17.5mil"
              },
              {
                id: 3,
                text: "Heavily textured, white fibers 100% cotton"
              },
              {
                id: 4,
                text: "Digital art, Watercolor, Painting reproduction "
              },
            ],
            text_fr: [
              {
                id: 1,
                text: "Papier beaux-arts texturé  "
              },
              {
                id: 2,
                text: "285gsm, 17.5mil"
              },
              {
                id: 3,
                text: "Très texturé, fibres blanches 100% coton"
              },
              {
                id: 4,
                text: "Art digital, aquarelle, reproduction de peinture"
              },
            ],
            title_en: "TEXTURED FINE ART PAPER",
            title_fr: "PAPIER BEAUX-ARTS TEXTURÉ",
          }
        },
        {
          id: 8,
          code: 'hemp',
          paper: 'hemp_fine_art',
          priceRange: 4,
          content: {
            image: require('@/assets/images/usage-new/13.png'),
            text_en: [
              {
                id: 1,
                text: "Premium Hemp Fine Art Paper  "
              },
              {
                id: 2,
                text: "290gsm, 20mil"
              },
              {
                id: 3,
                text: "Natural white fibers, lightly textured (60% hemp fibre, 40% cotton)"
              },
              {
                id: 4,
                text: "Photography, Digital art, Watercolor, Painting reproduction  "
              },
            ],
            text_fr: [
              {id: 1, text: 'Papier beaux-arts chanvre'},
              {id: 2, text: '290gsm, 20mil'},
              {id: 3, text: 'Fibres blanches naturelles, légèrement texturé (60% fibre de chanvre, 40% coton)'},
              {id: 4, text: 'Photographie, art digital, aquarelle, reproduction de peinture '}
            ],
            title_en: "HEMP FINE ART PAPER",
            title_fr: "PAPIER BEAUX-ARTS CHANVRE"
          }
        }
      ],
    }
  },
  methods: {
    savePaperData(paper) {
      const data = pickBy(paper, ((value, key) => ['id', 'code', 'paper'].includes(key) ))
      localforage.setItem("paper_data", data);
      window.scroll(0,0)
      this.$router.push({name:'Configurator'});
    },
  },
  mounted() {
    this.lang = localStorage.getItem('lang')
    this.tabs = this.tabTitles[this.lang] || []

    if (this.$route.path === this.$t('routes.satin')) {
      this.selected = 1;
    } else if (this.$route.path === this.$t('routes.matte')) {
      this.selected = 0;
    } else if (this.$route.path === this.$t('routes.metallic')) {
      this.selected = 2;
    } else if (this.$route.path === this.$t('routes.hemp')) {
      this.selected = 4;
    } else if (this.$route.path === this.$t('routes.textured')) {
      this.selected = 3;
    }
  },
  computed:{
    tabTitles() {
      return {
        en: ['MATTE PHOTO PAPER', 'SATIN PHOTO PAPER', 'METALLIC “GLOSSY” PHOTO PAPER', 'TEXTURED FINE ART PAPER', 'HEMP FINE ART PAPER'],
        fr: ['PAPIER PHOTO MAT', 'PAPIER PHOTO SATIN', 'PAPIER PHOTO MÉTALLIQUE “GLACÉ”', 'PAPIER BEAUX-ARTS TEXTURÉ', 'PAPIER BEAUX-ARTS CHANVRE']
      }
    },
  },
  components: {
    ProductPaperPropertyTable,
  },
}
</script>

