document.addEventListener("DOMContentLoaded", function () {
    function placeFBPIXELScripts() {
        const scriptElement = document.createElement('script');
        scriptElement.setAttribute("type", "text/javascript")
        scriptElement.innerHTML = `!(function(f, b, e, v, n, t, s) {if (f.fbq) return;n = f.fbq = function() {n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);};if (!f._fbq) f._fbq = n;n.push = n;n.loaded = !0;n.version = "2.0";n.queue = [];t = b.createElement(e);t.async = !0;t.src = v;s = b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t, s);})(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");fbq("init", "{${VUE_APP_FB_PIXEL_ID}}");fbq("track", "PageView");`

        document.head.appendChild(scriptElement)

        const noScriptElement = document.createElement("noscript")
        noScriptElement.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id={${VUE_APP_FB_PIXEL_ID}}&ev=PageView&noscript=1"`
        document.head.appendChild(noScriptElement)
    }

    function placeSTAMPEDIOScripts() {
        const sourceScriptElement = document.createElement("script")
        sourceScriptElement.type = "text/javascript"
        sourceScriptElement.src = "https://cdn1.stamped.io/files/widget.min.js"

        sourceScriptElement.onload = function () {
            const scriptElement = document.createElement("script")
            scriptElement.type = "text/javascript"
            scriptElement.innerHTML = `StampedFn.init({ apiKey: "${VUE_APP_STAMPED_IO_ID}", sId: "201419", storeUrl: "blankspace.ink" });`

            document.head.appendChild(scriptElement)
        }

        document.head.appendChild(sourceScriptElement)
    }

    function placeGTMScripts() {
        // ===========> Adding GTM
        const sourceScriptElement = document.createElement("script")
        sourceScriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${VUE_APP_GTM_ID}`
        sourceScriptElement.async = true

        sourceScriptElement.onload = function () {
            const scriptElement = document.createElement("script")
            scriptElement.type = "text/javascript"
            scriptElement.innerHTML = ` window.dataLayer = window.dataLayer || [];function gtag() {dataLayer.push(arguments);}; gtag("js", new Date());gtag("config", "${VUE_APP_GTM_ID}");`

            document.head.appendChild(scriptElement)
        }

        document.head.appendChild(sourceScriptElement)
    }

    function placeINTERCOMScripts() {
        const sourceScriptElement = document.createElement("script")
        sourceScriptElement.innerHTML = `window.intercomSettings = {app_id: "${VUE_APP_INTERCOM_APP_ID}"};`

        document.head.appendChild(sourceScriptElement)

        const scriptElement = document.createElement("script")
        scriptElement.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic("reattach_activator");ic("update",w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement("script");s.type="text/javascript";s.async=true;s.src="https://widget.intercom.io/widget/${VUE_APP_INTERCOM_APP_ID}";var x=d.getElementsByTagName("script")[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent("onload",l);}else{w.addEventListener("load",l,false);}}})();`
        document.head.appendChild(scriptElement)


    }

    function placeSUMOScripts() {
        const scriptElForSUMO = document.createElement("script")
        scriptElForSUMO.async = true;
        scriptElForSUMO.innerHTML = `(function(s, u, m, o, j, v) {j = u.createElement(m);v = u.getElementsByTagName(m)[0];j.async = 1;j.src = o;j.dataset.sumoSiteId = "${VUE_APP_SUMO_ID}";v.parentNode.insertBefore(j, v);})(window, document, "script", "//load.sumo.com/");`

        document.head.appendChild(scriptElForSUMO)
    }

    function placeSMARTLOOKScripts() {
        const scriptElForSMARTLOOK = document.createElement("script")
        scriptElForSMARTLOOK.type = "text/javascript"
        scriptElForSMARTLOOK.innerHTML = `window.smartlook||(function(d) {var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);})(document);smartlook('init', '${VUE_APP_SMARTLOOK_ID}', { region: 'eu' });`

        document.head.appendChild(scriptElForSMARTLOOK)
    }

    const {
        VUE_APP_FB_PIXEL_ID,
        VUE_APP_STAMPED_IO_ID,
        VUE_APP_GTM_ID,
        VUE_APP_INTERCOM_APP_ID,
        VUE_APP_SUMO_ID,
        VUE_APP_SMARTLOOK_ID,
        NODE_ENV
    } = process.env


    if (NODE_ENV === "production") {
        placeFBPIXELScripts()
        placeSTAMPEDIOScripts()
        placeGTMScripts()
        placeINTERCOMScripts()
        placeSUMOScripts()
        placeSMARTLOOKScripts()
    }
})
