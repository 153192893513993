export const ACCEPTED_FILE_SIZE = 2e8;
// export const ACCEPTED_FILE_TYPES = ["image/png", "image/jpg", "image/jpeg", "image/webp", "image/tiff"];
export const ACCEPTED_FILE_TYPES = ["image/png", "image/jpg", "image/jpeg", "image/webp"];
export const ACCEPTED_MAXIMUM_PIXEL_SIZE = 198288000;
export const ACCEPTED_MAXIMUM_FILE_SIZE = 200000000 // 200MB;
//let UPLOAD_URL = "http://localhost:3000/upload-image";
// let UPLOAD_URL = "https://upload-service.banjhakri.com/upload-image";
let UPLOAD_URL = "/image_uploader";
if (process.env.NODE_ENV === "production") {
  UPLOAD_URL = "/image_uploader";
}
export const UPLOAD_SERVICE_URL = UPLOAD_URL;
