<template>
  <div class="shop">
    <template v-for="section in sections">
      <section :class="section.containerClass" :key="section.type">
        <v-container>
          <Heading :subtitle="$t(`shop.${section.type}.subtitle`)" :title="$t(`shop.${section.type}.title`)"/>
          <AppSwiper v-if="smAndDown" v-bind="options" :items="section.items">
            <template v-slot:item="item">
              <ProductCard
                :buttonText="item.buttonName"
                :detail="item.detail"
                :name="item.name"
                :price="item.price"
                :src="productImage(item.image)"
                :subtitle="item.subtitle"
                :to="{name:item.route}"
              />
            </template>
          </AppSwiper>
          <v-row class="g-4 print-desktop" :class="section.rowClass" v-else>
            <template
              v-for="(poster, index) in section.items"
            >
              <div class="col-12 col-lg-4 col-md-6" :key="index">
                <ProductCard
                  :buttonText="poster.buttonName"
                  :detail="poster.detail"
                  :name="poster.name"
                  :price="poster.price"
                  :src="productImage(poster.image)"
                  :subtitle="poster.subtitle"
                  :to="{name:poster.route}"
                />
              </div>
            </template>
          </v-row>
        </v-container>
      </section>
    </template>

    <section>
      <v-container class="pt-0">
        <v-card class="gift-certificate-section" flat tile>
          <v-row class="align-center my-0">
            <v-col cols="12" lg="7" class="py-0">
              <v-img alt="gift_certificate" src="@/assets/images/new-images/gift-card.png" :aspect-ratio="(750/350)"/>
            </v-col>
            <v-col cols="12" lg="5">
              <div class="gift-certificate-description ml-md-6">
                <h3 class="mid-titles">{{ $t('shop.gift.title') }}</h3>
                <h6 class="regular-medium">{{ $t('shop.gift.subtitle') }}</h6>
                <p class="medium dark-color--text">{{ $t('shop.gift.choose') }}</p>
                <AppButton :to="{name:'GiftCertificate'}" color="primary">
                  {{ $t('buttons.gift') }}
                </AppButton>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import ProductCard from '../../../components/cards/unique-card/ProductCard'

export default {
  name: 'Shop',
  metaInfo() {
    return {
      title: this.$t('titles.shop'),
      titleTemplate: '%s - Blankspace',
       htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.$t('titles.shop-content')},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  components: {
    ProductCard
  },
  data() {
    return {
      options: {
        slidesPerView: 'auto',
        spaceBetween: 18,
        autoplay: true,
        loop: true,
        centeredSlides: true,
      },
      poster_data: [],
      product_data: []
    }
  },
  computed: {
    ...mapState(['products', 'posters']),
    ...mapGetters(['get_poster_en', 'get_poster_fr', 'get_product_en', 'get_product_fr']),
    sections() {
      return [
        {
          type: 'wall',
          items: this.product_data,
          containerClass: ''
        },
        {
          type: 'poster',
          items: this.poster_data,
          containerClass: 'poster-print-section',
          rowClass: 'textured'
        }
      ]
    }
  },
  methods: {
    productImage(image) {
      return require('@/' + image)
    },
    shopData() {
      if (localStorage.getItem('lang') === 'en') {
        this.poster_data = this.$store.getters.get_poster_en
        this.product_data = this.$store.getters.get_product_en
      } else if (localStorage.getItem('lang') === 'fr') {
        this.poster_data = this.$store.getters.get_poster_fr
        this.product_data = this.$store.getters.get_product_fr
      }
    }
  },
  created() {
    this.shopData()
  }
}
</script>

<style lang="scss">
.shop {
  .swiper-pagination {
    position: relative;
    margin: 24px 0 0;
    bottom: unset;
  }
  .swiper-slide {
    width: 287px;
    height: unset;
  }
  margin-top: 70px;
  .heading {
    margin-bottom: 48px;
  }
  section {
    padding: 60px 0 86px;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      @media(max-width: 980px) {
        padding-bottom: 0;
      }
    }
    @media(max-width: 980px) {
      padding: 25px 0;
    }
  }
  .product-card-container {
    margin-bottom: 60px;
  }

  @media (min-width: 1280px) {
    .container {
      max-width: 1170px;
    }
  }

  .print-desktop {
    display: none;
  }

  .row.g-4 {
    @media (--t) {
      display: flex;
      margin: -12px -24px;
      &.textured {
        margin: -24px;
        > .col-12 {
          padding: 24px;
        }
      }
      > .col-12 {
        padding: 12px 24px;
      }
    }
  }

  .print-mobile {
    display: flex;
    @media (--t) {
      display: none;
    }

    button {
      color: red !important;
    }
  }

  h2 {
    letter-spacing: 0.15em;
    margin-top: -5px;
  }

  .poster-print-section {
    background-color: #f9f8f7;
    @media (--t) {
      margin-top: unset;
    }
  }

  .gift-certificate-section {
    flex-direction: row;
    overflow: hidden;
    border-radius: 0;
    border: 0;
    .gift-certificate-description {
      > * {
        margin-bottom: 20px;
        text-align: left !important;
      }
      h3 {
        font-weight: normal;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 0.02em !important;
      }

      h6 {
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.1em !important;

      }

      p {
        font-size: 16px !important;
        line-height: 130% !important;
        letter-spacing: 0.01em !important;
        max-width: 350px !important;
        margin-bottom: 40px;
      }
    }
  }
}
</style>
