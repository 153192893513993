<template>
  <v-container class="privacy-page-title">
    <Heading :title="$t('policy.title')" title-tag="h1"/>
    <div class="row justify-center">
      <div class="col-12 col-lg-10">
        <h3 class="layout-title ">1.0 {{ $t('policy.general.title') }}</h3>
        <p class="medium-gray">1.1 {{ $t('policy.general.1') }}</p>

        <h3 class="layout-title ">2.0 {{ $t('policy.changes.title') }}</h3>
        <p class="medium-gray">2.1 {{ $t('policy.changes.1') }}</p>


        <h3 class="layout-title ">3.0 {{ $t('policy.consent.title') }}</h3>
        <p class="medium-gray">3.1 {{ $t('policy.consent.1') }}</p>


        <h3 class="layout-title ">4.0 {{ $t('policy.information.title') }}</h3>
        <p class="medium-gray">4.1 {{ $t('policy.information.1') }}</p>
        <p class="medium-gray">4.2 {{ $t('policy.information.2') }}</p>
        <p class="medium-gray">4.3 {{ $t('policy.information.3') }}</p>
        <p class="medium-gray">4.4 {{ $t('policy.information.4') }}</p>
        <p class="medium-gray">4.5 {{ $t('policy.information.5') }}</p>
        <p class="medium-gray">4.6 {{ $t('policy.information.6') }}</p>
        <p class="medium-gray">4.7 {{ $t('policy.information.7') }}</p>

        <h3 class="layout-title ">5.0 {{ $t('policy.identifiable.title') }}</h3>
        <p class="medium-gray">5.1 {{ $t('policy.identifiable.1') }}</p>
        <p class="medium-gray">5.1 {{ $t('policy.identifiable.2') }}</p>

        <h3 class="layout-title ">6.0 {{ $t('policy.widgets.title') }}</h3>
        <p class="medium-gray">6.1 {{ $t('policy.widgets.1') }}</p>
        <p class="medium-gray">6.1 {{ $t('policy.widgets.2') }}</p>

        <h3 class="layout-title ">7.0 {{ $t('policy.contact.title') }}</h3>
        <p class="medium-gray">7.1 {{ $t('policy.contact.1') }}</p>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'Privacy',
  metaInfo() {
    return {
      title: this.$t('titles.privacy'),
      titleTemplate: '%s - Blankspace',
       htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
}
</script>

<style lang="postcss" scoped>
.privacy-page-title {
  padding-bottom: 80px;
  padding-top: 80px;

p {
  text-align: justify;
  margin-top: 20px;
}

h3 {
  margin-top: 30px;
}

}
</style>
