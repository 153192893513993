<template>
  <v-container class="auth">
    <form @keydown.enter="validateAndSubmit" class="px-2 px-md-0">
      <h1 class="small-title mb-4">{{ $t("buttons.sign-in-title") }}</h1>
      <template v-for="field in formFields">
        <component
          :loading="loading"
          :is="field.comp"
          :key="field.name"
          :label="field.label"
          v-model="userData[field.name]"
          :append-icon="field.icon"
          data-vv-validate-on="blur lazy"
          :error-messages="errors.collect(field.name)"
          v-validate="field.validation"
          :data-vv-name="field.name"
          :data-vv-as="field.label"
          @input="validateField(field.name)"
        />
      </template>
      <AppButton block color="primary" :loading="loading" @click="validateAndSubmit" large>
        {{ $t("buttons.signIn") }}
      </AppButton>

      <div class="documents">
        <router-link :to="{ name : 'LostPassword' }">
          <p class="page-subtitle">{{ $t("buttons.forgot") }}</p>
        </router-link>
        <router-link :to="{ name: 'SignUp' }">
          <p class="page-subtitle">{{ $t("buttons.create") }}</p>
        </router-link>
      </div>
    </form>
  </v-container>
</template>

<script>
import { authPagesMixins } from "@/mixins/authPagesMixins";

export default {
  name: "SignIn",
  metaInfo() {
    return {
      title: this.$t('titles.account'),
      titleTemplate: '%s - Blankspace',
       htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.$t('titles.home-content')},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === vm.$t("routes.shipment")) {
        vm.userData.route = vm.$t("routes.shipment");
      } else vm.userData.route = "/";
      next("/login");
    });
  },
  mixins: [authPagesMixins],
  data() {
    return {
      userData: {
        email: "",
        password: "",
        route: "/",
      },
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.$store
        .dispatch("loginUser", {
          user: this.userData,
        })
        .then((data) => {
          // TODOGK Bu neden var acaba? Aynı datayi neden 2 kere cekiyoruz anlamadim!!
          this.$store.dispatch("getCurrentUserData");
          this.$router.push(this.userData.route);
        })
        .catch((err) => {
          if (err?.response?.data?.error === "wrong_password") {
            this.createErrorNotification(this.$t("notify.wrong_password"));
          } else if (err?.response?.data?.error === "email_doesnt_exist") {
            this.createErrorNotification(this.$t("notify.unknown_user"));
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  computed: {
    formFields() {
      return [
        {
          comp: "AppFormInput",
          icon: "",
          label: this.$t("placeholder.email"),
          name: "email",
          validation: "required|email",
        },
        {
          comp: "AppPasswordInput",
          icon: "",
          label: this.$t("placeholder.password"),
          name: "password",
          validation: "required|min:6|max:255",
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 150px);
  @media (--t) {
    min-height: calc(100vh - 100px - 52px);
  }

  form {
    width: 100%;
    @media (--t) {
      max-width: 410px;
    }

    .documents {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 15px;
      @media (--t) {
        max-width: 410px;
      }

      p {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
