<template>
  <div class="horizontal-panel" :style="imageContainerSizes">
    <template v-if="imageUrl">
      <img style="width: 100%; height: 100%" v-for="i in 3" :key="i" :src="imageUrl" alt="blankspace" />
    </template>
  </div>
</template>
<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    imageContainerSizes: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageSizes() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.horizontal-panel {
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;

    &:first-child {
      clip-path: inset(0 0 67% 0);
    }
    &:nth-child(2) {
      clip-path: inset(33% 0 33% 0);
      margin-top: 10px;
    }
    &:last-child {
      clip-path: inset(67% 0 0 0);
      margin-top: 20px;
    }
  }
}
</style>
