import {authPagesMixins} from '@/mixins/authPagesMixins'
import {mapGetters} from 'vuex'
import {pickBy, sortBy} from 'lodash'

export const addressPageMixins = {
    mixins: [authPagesMixins],
    data: () => ({
        userData: {
            // contact.contact_people_attributes.0
            first_name: null,
            last_name: null,
            phone: null,
            email: null,
            // addresses_attributes.0
            city: null,
            country: null,
            id: null,
            name: null, // addressName ?
            state: null,
            street1: null,
            street2: null,
            zipcode: null,
            billing_country: null,
            billing_state: null

        },
        successMsg: 'notify.account_updated',
        errorMsg: 'notify.wrong',
        actionName: null,
        validationPerformed: false
    }),
    methods: {
        validateAndSubmit() {
            this.validationPerformed = true;
            this.$refs.form.$validator.validateAll()
                .then(v => {
                    if (v && this.actionName)
                        this.onSubmit()
                })
        },
        onSubmit() {
            this.loading = true

            const userData = {
                ...this.submitObj,
            }
            userData.karavan_user.email = this.userData.email

            this.$store.dispatch(this.actionName, userData)
                .then(data => {
                    this.createSuccessNotification(this.$t(this.successMsg))
                    return this.$store.dispatch('getCurrentUserData')
                })
                .then(() => {
                    this.$router.push({name: 'Address'})
                })
                .catch(error => {
                    this.createErrorNotification(this.$t(this.errorMsg))
                })
                .finally(() => {
                    this.loading = false
                })
        },
        states(country) {
            const selectedCountry = this.getCountry?.find(c => c._id === country);
            return sortBy(selectedCountry?.states || [], 'name')
        },
    },
    computed: {
        ...mapGetters(['getCountry', 'currentUserData', 'getShippingCountry']),
        shipmentStepFormFields() {
            return this.formFields?.filter(f => f.name !== 'email')
        },
        formFields() {
            return [
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 12,
                    },
                    label: this.$t('shipment.address_name'),
                    name: 'name',
                    validation: ''
                },
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 6,
                    },
                    label: this.$t('placeholder.first_name'),
                    name: 'first_name',
                    validation: 'required|alpha_spaces'
                },
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 6,
                    },
                    label: this.$t('placeholder.last_name'),
                    name: 'last_name',
                    validation: 'required|alpha_spaces'
                },
                // {
                //     comp: 'AppFormInput',
                //     icon: '',
                //     colProps: {
                //         cols: 12,
                //         lg: 6,
                //     },
                //     label: this.$t('placeholder.email'),
                //     name: 'email',
                //     validation: 'required|email'
                // },
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 6,
                    },
                    label: this.$t('placeholder.phone'),
                    name: 'phone',
                    validation: 'required|numeric'
                },
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 6,
                    },
                    label: `${this.$t('placeholder.suite')} (${this.$t('placeholder.optional')})`,
                    name: 'street2',
                    validation: ''
                },
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 12,
                    },
                    label: this.$t('placeholder.street'),
                    name: 'street1',
                    validation: 'required'
                },
                {
                    comp: 'AppSelect',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 3,
                    },
                    label: this.$t('placeholder.country'),
                    props: {
                        items: this.getShippingCountry,
                        ...this.selectMenuProps
                    },
                    on: {
                        input: () => {
                            this.userData.state = null
                        }
                    },
                    name: 'country',
                    validation: 'required'
                },
                {
                    comp: 'AppSelect',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 3,
                    },
                    label: this.$t('placeholder.province'),
                    props: {
                        items: this.states(this.userData.country),
                        disabled: this.states(this.userData.country)?.length === 0,
                        ...this.selectMenuProps
                    },
                    name: 'state',
                    validation: 'required'
                },
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 3,
                    },
                    label: this.$t('placeholder.city'),
                    name: 'city',
                    validation: 'required'
                },
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 3,
                    },
                    label: this.$t('placeholder.postcode'),
                    name: 'zipcode',
                    validation: 'required|min:3'
                },
            ]
        },
        emailFormFields() {
            return [
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 12,
                    },
                    label: this.$t('placeholder.email'),
                    name: 'email',
                    validation: 'required|email'
                },
            ]
        },
        billingAddressFormFields() {
            return [
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 6,
                    },
                    label: this.$t('placeholder.first_name'),
                    name: 'first_name',
                    validation: 'required|alpha_spaces'
                },
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 6,
                    },
                    label: this.$t('placeholder.last_name'),
                    name: 'last_name',
                    validation: 'required|alpha_spaces'
                },
                /*{
                  comp: 'AppFormInput',
                  icon: '',
                  colProps: {
                    cols: 12,
                    lg: 6,
                  },
                  label: this.$t('placeholder.email'),
                  name: 'email',
                  validation: 'required|email'
                },*/
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 6,
                    },
                    label: this.$t('placeholder.phone'),
                    name: 'phone',
                    validation: 'required|numeric'
                },
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 6,
                    },
                    label: `${this.$t('placeholder.suite')} (${this.$t('placeholder.optional')})`,
                    name: 'street2',
                    validation: ''
                },
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 12,
                    },
                    label: this.$t('placeholder.street'),
                    name: 'street1',
                    validation: 'required'
                },
                {
                    comp: 'AppSelect',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 3,
                    },
                    label: this.$t('placeholder.country'),
                    props: {
                        items: this.getCountry,
                        ...this.selectMenuProps
                    },
                    on: {
                        input: () => {
                            this.order_data.karavan_order.contact_billing_address.state = null
                        }
                    },
                    name: 'country',
                    validation: 'required'
                },
                {
                    comp: 'AppSelect',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 3,
                    },
                    label: this.$t('placeholder.province'),
                    props: {
                        items: this.states(this.order_data.karavan_order.contact_billing_address?.country),
                        disabled: this.states(this.order_data.karavan_order.contact_billing_address?.country)?.length === 0,
                        ...this.selectMenuProps
                    },
                    name: 'state',
                    validation: 'required'
                },
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 3,
                    },
                    label: this.$t('placeholder.city'),
                    name: 'city',
                    validation: 'required'
                },
                {
                    comp: 'AppFormInput',
                    icon: '',
                    colProps: {
                        cols: 12,
                        lg: 3,
                    },
                    label: this.$t('placeholder.postcode'),
                    name: 'zipcode',
                    validation: 'required|min:3'
                },
            ]
        },
        selectMenuProps() {
            return {
                'item-value': '_id',
                'item-text': 'name',
                menuProps: {
                    nudgeBottom: '56px',
                },
                dense: false
            }
        },
        contactPeopleFields() {
            return ['first_name', 'last_name', 'phone', 'email']
        },
        addressFields() {
            return [
                'city', 'country', 'name', 'state', 'street1',
                'street2', 'zipcode']
        },
        country() {
            return this.getCountry.find(country => country._id === this.userData.country)
        },
        submitObj() {
            return {
                karavan_user: {
                    email: this.currentUserData.karavan_user.email,
                },
                contact: {
                    contact_people_attributes: {
                        0: {
                            ...pickBy(this.userData, (value, key) => this.contactPeopleFields.includes(key)),
                            ...this.userData.contactPersonId && {id: this.userData.contactPersonId},
                            ...this.initialAddressAvailable && {id: this.initialAddressAvailable?.contact_people_id},
                            ...this.contactPerson && {id: this?.contactPerson?.id},
                        },
                    },
                    addresses_attributes: {
                        0: {
                            ...pickBy(this.userData, (value, key) => this.addressFields.includes(key)),
                            ...this.userData.id && {id: this.userData.id},
                            ...this.initialAddressAvailable && {id: this.initialAddressAvailable?.address_id},
                            ...this?.saved_address_data?.id && {id: this.saved_address_data?.id},
                        }
                    },
                },
            }
        }
    },
}
