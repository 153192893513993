<template>
  <v-card outlined class="aphoristic-card fill-height" v-bind="$attrs">
    <v-card-text class="quote">
      <h6 class="text-black-large mb-4">" {{ quotes }} "</h6>
      <p class="medium">{{ writer }}</p>
      <SymbolSmall class="mt-3"/>
    </v-card-text>
  </v-card>
</template>

<script>
import {SymbolSmall} from "@/assets/svg-icons";

export default {
  name: "AphoristicCard",
  components: {
    SymbolSmall
  },
  props: {
    writer: {
      type: String,
    },
    quotes: {
      type: String,
    }
  }
}
</script>

<style scoped>

.aphoristic-card {
  border-radius: 0;


  .quote {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    @media (--t) {
      padding: 40px;
    }
  }
  h6 {
    font-family: var(--font-family--alethia)!important;
    font-size: 25px !important;
    font-weight: normal;
    line-height: 130% !important;
    text-align: center !important;
    color: #000000 !important;
  }

  p {
    font-family: Sk-Modernist ,'sans-serif' !important;
    font-size: 18px !important;
    line-height: 20px !important;
    text-align: center !important;
    letter-spacing: 0.02em !important;
    color: #6D6D6D !important;
  }
}


</style>
