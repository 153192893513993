<template>
<!-- filled  -->
  <div>
  <v-text-field
    v-bind="$attrs"
    outlined
    class="app-form-input rounded-0"
    :immediate="true"
    v-model="innerValue"
  >
    <template v-slot:append>
      <slot name="append"></slot>
    </template>

  </v-text-field>
  </div>
</template>

<script>
import { formInputMixins } from '@/mixins/formInputMixins'

export default {
  name: 'AppFormInput',
  mixins: [formInputMixins],
}
</script>

<style lang="scss">
.v-input.app-form-input.v-text-field.v-text-field--outlined:not(.error--text) {
  .v-label {
    color: #9F9F9F;
  }
  .v-input__control  > .v-input__slot {
    background: #FBFBFB;
    fieldset {
      border-color: #E8E8E8;
    }
    legend {
      color: #9F9F9F;
    }
  }
  &.dense-legend {
    legend{
      width: calc(100% - 4px)!important;
    }
  }
}
</style>
