<template>
  <div class="rectangle-panel" :style="imageContainerSizes">
    <img style="width: 100%; height: 100%" :src="imageUrl" alt="blankspace" />
  </div>
</template>
<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    imageContainerSizes: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imageSizes() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
};
</script>
