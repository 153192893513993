<template>
  <v-hover v-slot="{hover}">
    <v-card flat color="transparent"  class="story-section-container justify-center" tile>
      <div class="fill-height d-flex flex-wrap justify-center justify-md-start">
        <v-img :class="{'hovered': hover}" width="100%" :aspect-ratio="1" :src="src">
          <div class="d-flex btn-container fill-height align-center justify-center" v-if="!smAndDown">
            <AppButton
                :to="to"
                color="white"
                class="primary--text"
            >
              {{ button }}
            </AppButton>
          </div>
        </v-img>
        <div class="card-title mt-4 mt-md-5">
          <h6>“{{ title }}”</h6>
        </div>
        <div class="" style="width: 100%">
          <p>{{ social }}</p>
        </div>
        <div
            v-if="smAndDown"
            class="d-flex justify-center align-self-end"
        >
          <AppButton
              :to="to"
              color="primary"

          >
            {{ button }}
          </AppButton>
        </div>
      </div>
    </v-card>
  </v-hover>
</template>

<script>


export default {
  name: 'StoryCard',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
  },

  computed: {
    title() {
      return (this.item.title || '').toUpperCase()
    },
    button() {
      return this.item.name
    },
    social() {
      return this.item.social
    },
    to() {
      return {name: this.item.route}
    },
    src() {
      return this.item.path || require('@/assets/images/placeholders/placeholder-0.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.story-section-container {
  .app-btn.v-btn {
    &.theme--light.v-btn--active {
      &:before {
        opacity: 0;
      }
    }
  }
  &.v-card {
    .v-image  {
      .app-btn.v-btn {
        opacity: 0;
        transition: opacity .3s ease-in-out;

      }
      .btn-container {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
          opacity: 0;
          transition: opacity .5s ease-in-out;
        }
      }
      &.hovered {
        .btn-container:before {
          opacity: 1;
        }
        .app-btn.v-btn {
          opacity: 1;
        }
      }
    }
    .card-title {
      @media (--t) {
        min-height: 50px;
      }
      h6 {
        text-align: center !important;
        font-weight: bold !important;
        font-size: 16px !important;
        line-height: 150% !important;
        letter-spacing: 0.02em !important;
        color: #000000 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        @media (--t) {
          text-align: left !important;
        }
      }
    }

    p {
      text-align: center !important;
      font-size: 16px !important;
      line-height: 150% !important;
      letter-spacing: 0.02em !important;
      color: #000000 !important;
      @media(--t) {
        text-align: left !important;
        margin-top: 19px;

      }
    }


  }
}


</style>
