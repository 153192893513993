<template>
  <div class="navigation-group">
    <v-menu
        min-width="1100"
        max-height="100%"
        :offset-x="true"
        :nudge-left="menuLeftOffset"
        close-on-content-click
        nudge-bottom="60"
        eager
        open-on-hover
        class="pa-0 my-0"
        content-class="mega-menu-content"
    >
      <template v-slot:activator="{ on }">
        <a ref="shopMenuItem"
           href="javascript:void(0);"
           v-on="on"
           :class="`header-link${isActive ? ' route-active-border' : ''}`"
        >
          {{ $t('navbar.shop').toUpperCase() }}
          <IconArrowDown/>
        </a>
      </template>
      <v-card tile>
        <div class="dropdown-menu">

          <div class="row">
            <div class="big-dropdown-left col col-5">
              <div class="d-flex fill-height flex-column flex-wrap justify-space-between">
                <template v-for="(list, lIndex) in menuLists">
                  <div :key="list.title">
                    <router-link
                        :to="item.to" v-for="(item, index) in list"
                        :key="item.to.name"
                        class="menu-link medium"
                        v-ripple
                    >
                      <div class="d-flex" @mouseover="changeImg(item.img)">
                        {{ item.title }}
                      </div>
                    </router-link>
                  </div>
                </template>
              </div>
            </div>

            <div class="col col-7">
              <v-img
                  transition="fade-transition"
                  :aspect-ratio="(635 / 635)"
                  :src="dropdownImage"
              />
            </div>
          </div>

        </div>
      </v-card>
    </v-menu>
    <router-link :class="$route.name === 'Blog' ? 'route-active-border' : ''" class="header-link"
                 :to="{name:'Blog'}">{{ $t('navbar.blog').toUpperCase() }}
    </router-link>
    <router-link :class="$route.path === '/faq' ? 'route-active-border' : ''" class="header-link"
                 to="/faq">{{ $t('navbar.faq').toUpperCase() }}
    </router-link>
  </div>
</template>

<script>

import IconArrowDown from '@/assets/svg-icons/icons/down-black-mini.svg'

export default {
  name: 'MegaMenu',
  data() {
    return {
      dropdownImage: '/img/dropdown-menu/2.png',
    }
  },
  methods: {
    changeImg(img) {
      if (img)
        this.dropdownImage = img
    }
  },
  computed: {
    menuLeftOffset(){
      return this.$i18n.locale === "en" ? '101' : '152'
    },
    routes() {
      return this.menuLists
          .map(list => list.map(item => item.to.name))
          .flat(1)
    },
    menuLists() {
      return [
        // List 0
        [
          {
            to: {name: 'Shop'},
            title: this.$t('footer.shop.all')
          }
        ],
        //List 1
        [
          {
            to: {name: 'CanvasPaper'},
            title: this.$t('dropdown.canvas'),
            img: '/img/dropdown-menu/7.png',
          },
          {
            to: {name: 'AcrylicPaper'},
            title: this.$t('dropdown.acrylic'),
            img: '/img/dropdown-menu/hd.png',
          },
          {
            to: {name: 'MetalPaper'},
            title: this.$t('dropdown.metal'),
            img: '/img/dropdown-menu/metal.png',
          },
        ],
        //List 2
        [
          {
            to: {name: 'Matte'},
            title: this.$t('dropdown.matte'),
            img: '/img/dropdown-menu/1.png',
          },
          {
            to: {name: 'Satin'},
            title: this.$t('dropdown.satin'),
            img: '/img/dropdown-menu/5.png',
          },
          {
            to: {name: 'Metallic'},
            title: this.$t('dropdown.metallic'),
            img: '/img/dropdown-menu/5.webp',
          },
          {
            to: {name: 'Textured'},
            title: this.$t('dropdown.textured'),
            img: '/img/dropdown-menu/4.png',
          },
          {
            to: {name: 'Hemp'},
            title: this.$t('dropdown.hemp'),
            img: '/img/dropdown-menu/2.png',
          },
        ],
        //List 3
        [
          {
            to: {name: 'GiftCertificate'},
            title: this.$t('dropdown.gift')

          }
        ]

      ]
    },
    isActive() {
      return this.routes.includes(this.$route.name)
    }
  },

  components: {
    IconArrowDown
  },
}
</script>


<style lang="scss" scoped>

.mega-menu-content {
  overflow-y: unset;
  overflow-x: unset;

  .menu-link {
    font-family: Sk-Modernist, 'sans-serif';
    font-size: 18px;
    letter-spacing: 0.02em;
    color: #000000;
    display: block;
    padding: 8px 4px;
    transition: all ease-in-out .3s;

    &:hover, &.router-link-exact-active {
      color: #009771;
      text-decoration: underline;
      text-underline-color: #009771;
    }
  }

  .dropdown-menu {
    .big-dropdown-left {
      padding: 60px 0 76px 42px;
    }
  }


  .dropdown-toggle::after {
    display: none;
  }


  .select {
    outline: none;
    border: 0;
    cursor: pointer;
    font-family: Sk-Modernist, 'sans-serif' !important;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3750em;
    letter-spacing: 0.05em;
  }

  .shop-select {
    color: #000000;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

</style>
