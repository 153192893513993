<template>
  <v-container class="auth">
    <form @submit.stop.prevent="onSubmit" class="mx-auto">
      <h3 class="small-title mb-4">{{$t('profile.change_password')}}</h3>
      <template
        v-for="field in formFields"
      >
        <component
          :loading="loading"
          :is="field.comp"
          :key="field.name"
          :ref="field.name"
          :label="field.label"
          v-model="userData[field.name]"
          :append-icon="field.icon"
          data-vv-validate-on="blur lazy"
          :error-messages="errors.collect(field.name)"
          v-validate="field.validation"
          :data-vv-name="field.name"
          :data-vv-as="field.label"
          @input="validateField(field.name)"
        />
      </template>
      <AppButton
        block
        color="primary"
        :loading="loading"
        @click="validateAndSubmit"
        large
      >
        {{ $t("buttons.save_changes") }}
      </AppButton>
    </form>
  </v-container>
</template>

<script>
import { authPagesMixins} from '@/mixins/authPagesMixins'

export default {
  name: "SignUp",
  mixins: [authPagesMixins],
  data() {
    return {
      userData: {
        current_password: null,
        password: null,
        password_confirmation: null,
      }
    }
  },
  methods: {
    onSubmit() {
      this.loading = true
      this.$store.dispatch("updatePassword", this.submitObj)
        .then(response => {
          this.createSuccessNotification(this.$t('notify.password_updated'))
          this.$router.push({name: 'Dashboard'})
        })
        .catch(err => {
          if (err.response.data.error === 'current_password_wrong') {
            this.createErrorNotification(this.$t('notify.current_password_wrong'))
          }
        })
      .then(() => {
        this.loading = false
      })
    },
  },
  computed: {
    submitObj() {
      return {
        current_password: this.userData.current_password,
        karavan_user: {
          password: this.userData.password,
          password_confirmation: this.userData.password_confirmation
        }
      }
    },
    formFields() {
      return [
        {
          comp: 'AppPasswordInput',
          label: this.$t('placeholder.current_password'),
          name: 'current_password',
          validation: 'required|min:6|max:255'
        },
        {
          comp: 'AppPasswordInput',
          label: this.$t('placeholder.new_password'),
          name: 'password',
          validation: 'required|min:6|max:255'
        },
        {
          comp: 'AppPasswordInput',
          label: this.$t('placeholder.confirm_password'),
          name: 'password_confirmation',
          validation: 'required|min:6|max:255|confirmed:password'
        },
      ]
    }
  }
}
</script>
<style lang="postcss" scoped>
.auth {


  form {

    @media (--t) {
      max-width: 410px;
    }
  }
}
</style>
