<template>
  <div class="faq">
    <Heading :subtitle="$t('faq.subtitle')" title="FAQ" title-tag="h1"/>
    <v-container>
      <div class="row justify-center">
        <div class="col-12 col-lg-10">
          <v-expansion-panels v-model="activePanel" class="mb-6 faq-panel" color="primary" flat tile accordion>
            <template v-for="(section,sIndex) in sections">
              <div class="col-12 pa-0" :ref="section.id">
                <h1 class="title-x-large mb-5 mb-md-6 mt-5 mt-md-10">{{ section.title }}</h1>
              </div>
              <v-expansion-panel
                  :ref="`panel-${sIndex}-${i}`"
                  :id="`panel-${sIndex}-${i}`"
                  v-for="(question,i) in section.questions"
                  :key="question.title"
                  :value="question.title"
              >
<!--                  @click="scrollToPanelHeader(`panel-${sIndex}-${i}`)"-->
                <v-expansion-panel-header
                    ripple
                    expand-icon="mdi-chevron-down"
                >
                  {{ question.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="faq-panel-content accordion-body" eager>
                  <template v-if="question.component">
                    <component
                        :english="english"
                        :french="french"
                        :is="question.component"
                    />
                  </template>
                  <div class="d-flex" v-else v-html="question.content"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </v-expansion-panels>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import FaqImageFormat from '@/views/routes/main/faq/FaqImageFormat'
import FaqImageConsistency from '@/views/routes/main/faq/FaqImageConsistency'
import FaqImageEdit from '@/views/routes/main/faq/FaqImageEdit'
import FaqImageQuality from '@/views/routes/main/faq/FaqImageQuality'
import FaqProductsComparative from '@/views/routes/main/faq/FaqProductsComparative'
import FaqProductsClean from '@/views/routes/main/faq/FaqProductsClean'
import FaqProductsSize from '@/views/routes/main/faq/FaqProductsSize'
import FaqProductsInks from '@/views/routes/main/faq/FaqProductsInks'
import FaqProductsPrint from '@/views/routes/main/faq/FaqProductsPrint'
import FaqOrderShip from '@/views/routes/main/faq/FaqOrderShip'
import FaqWarrantyWhat from '@/views/routes/main/faq/FaqWarrantyWhat'

export default {
  name: "Faq",
  watch: {
    activeSectionIndex: {
      handler(val, oldVal) {
        this.setActiveSection()
      }
    }
  },
  metaInfo() {
    return {
      title: this.$t("titles.faq"),
      titleTemplate: "%s - Blankspace",
      htmlAttrs: {
        lang: "en"
      },
      meta: [{charset: "utf-8"}, {name: "description", content: this.$t("titles.faq-content")}, {
        name: "viewport",
        content: "width=device-width, initial-scale=1"
      }]
    };
  },
  data() {
    return {
      activePanel: null,
      english: false,
      french: false,
    };
  },
  mounted() {
    this.setActiveSection()
    if (localStorage.getItem("lang") === "fr") {
      this.french = true;
      this.english = false;
    } else if (localStorage.getItem("lang") === "en") {
      this.english = true;
      this.french = false;
    }
  },
  methods: {
    scrollToPanelHeader(domId, duration = 300) {
      setTimeout(() => {
        this.$vuetify.goTo(`#${domId}`, {...this.scrollOptions, duration})
      }, 300)
    },

    setActiveSection() {
      const {section, question, questionIndex} = this.$route.params
      if (section && question) {
        const elementId = `panel-${section}-${question}`;
        setTimeout(() => {
          this.activePanel = +questionIndex;
          this.scrollToPanelHeader(`panel-${section}-${question}`)
        }, 300)
      } else if (this.activeSection) {
        setTimeout(() => {
          this.$vuetify.goTo(this.$refs[this.activeSection.id][0], this.scrollOptions)
        }, 300)
      }
      if(this.$route?.hash === "#return") this.activePanel = 21;
    }
  },

  computed: {
    sections() {
      return [
        {
          title: this.$t("faq.image.title"),
          questions: [
            {
              title: this.$t("faq.image.format"),
              component: FaqImageFormat
            },
            {
              title: this.$t("faq.image.quality"),
              component: FaqImageQuality
            },
            {
              title: this.$t("faq.image.physical"),
              content: `<div class="container"><span class="soft-text">${this.$t("faq.image.quality-text")}</span></div> `
            },
            {
              title: this.$t("faq.image.edit"),
              component: FaqImageEdit
            },
            {
              title: this.$t("faq.image.consistency.title"),
              component: FaqImageConsistency
            },
            {
              title: this.$t("faq.image.calibrate.title"),
              content: `<div class="container"><span class="soft-text">${this.$t("faq.image.calibrate.text")}</span></div>`
            },
          ]
        },
        {
          title: this.$t("faq.products.title"),
          questions: [
            {
              title: this.$t("faq.products.comparative"),
              component: FaqProductsComparative
            },
            {
              title: this.$t("faq.products.size"),
              component: FaqProductsSize
            },
            {
              title: this.$t("faq.products.inks"),
              component: FaqProductsInks
            },
            {
              title: this.$t("faq.products.print"),
              component: FaqProductsPrint
            },
            {
              title: this.$t("faq.products.clean"),
              component: FaqProductsClean
            },
          ]
        },
        {
          id: 'shipment',
          title: this.$t("faq.order.title"),
          questions: [
            {
              title: this.$t("faq.order.payment"),
              content: `<div class="container"><span class="soft-text">${this.$t("faq.order.payment-text")}</span></div>`
            },
            {
              title: this.$t("faq.order.ship"),
              component: FaqOrderShip
            },
            {
              title: this.$t("faq.order.time"),
              content: `<div class="container"><span class="soft-text">${this.$t("faq.order.time-text")}</span></div>`
            },
            {
              title: this.$t("faq.order.track"),
              content: `<div class="container"><span class="soft-text">${this.$t("faq.order.track-text")}</span></div>`
            },
            {
              title: this.$t("faq.order.change"),
              content: `<div class="container"><p class="soft-text">${this.$t("faq.order.change-text")} <a class=" font-weight-bold" href="mailto:support@blankspace.ink?subject=I have questions."
                   style="color: black!important;"> support@blankspace.ink </a> ${this.$t("faq.order.change-text-two")}</p>
              </div>`
            },
            {
              title: this.$t("faq.order.pick"),
              content: `<div class="container"><span class="soft-text">${this.$t("faq.order.pick-text")}</span></div>`
            },
            {
              title: this.$t("faq.order.cancel"),
              content: `<div class="container"><span class="soft-text">${this.$t("faq.order.cancel-text")}</span></div>`
            },
            {
              title: this.$t("faq.order.trust"),
              content: `<div class="container"><span class="soft-text">${this.$t("faq.order.trust-text")}</span></div>`
            },
            {
              title: this.$t("faq.order.damage"),
              content: `<div class="container"><span class="soft-text">${this.$t("faq.order.damage-text")}</span></div>`
            }
          ]
        },
        {
          id: 'return',
          title: this.$t("faq.warranty.title"),
          questions: [
            {
              title: this.$t('faq.warranty.what'),
              component: FaqWarrantyWhat
            },
            {
              title: this.$t('faq.warranty.return'),
              content: `<div class="container"><p class="soft-text">${this.$t("faq.warranty.return-text.one")}</p><p class="soft-text">${this.$t("faq.warranty.return-text.two")}</p></div>`
            }
          ]
        },
        {
          id: 'volume',
          title: this.$t("faq.volume.title"),
          questions: [
            {
              title: this.$t('faq.volume.discount'),
              content: `<div class="container"><p class="soft-text mb-0">${this.$t("faq.volume.discount-text")}   <a class="soft-text font-weight-bold" href="mailto:support@blankspace.ink?subject=I have questions."
                            style="color: black!important;"
                            > support@blankspace.ink</a>.</p></div>`
            },
            {
              title: this.$t("faq.volume.promise"),
              content: `<div class="container"><span class="soft-text">${this.$t("faq.volume.promise-text")}</span></div>`
            },
          ]
        }
      ]
    },
    activeSectionIndex() {
      return this.sections.findIndex(section => section.id === this.$route.hash.replace('#', ''))
    },
    activeSection() {
      return this.activeSectionIndex !== -1 ? this.sections[this.activeSectionIndex] : null
    },
    scrollOptions() {
      return {
        duration: 300,
        offset: 106,
      }
    }
  },
  components: {
    FaqImageFormat,
    FaqImageConsistency,
    FaqImageEdit,
    FaqImageQuality,
    FaqProductsComparative,
    FaqProductsClean,
    FaqProductsSize,
    FaqProductsInks,
    FaqProductsPrint,
    FaqOrderShip,
    FaqWarrantyWhat
  },
};
</script>

<style lang="scss">
h1 {
  font-family: var(--font-family--alethia) !important;
  line-height: 105%;
  font-size: 30px;
  color: #000000;
  @media (--t) {
    font-size: 40px;
  }
}

.faq-panel-content {
  .size-text {
    display: block;
    font-family: Sk-Modernist, "sans-serif";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #393939;
    margin-top: 12px;
    @media (--t) {
      display: inline;
      margin-left: 20px;
    }
  }

  .size-item {
    display: inline-block;
    min-height: 30px;
    background-color: #000000;
    color: white;
    font-size: 17px;
    line-height: 24px;
    font-family: Sk-Modernist, "sans-serif";
    width: auto;
    padding: 5px;
    align-items: center;
    margin-top: 10px;
    @media (--t) {
      width: 170px;
      height: 30px;
      margin-top: 7px;
      padding: 2px 2px 2px 15px;
    }
  }

  .soft-text {
    font-size: 16px;
    letter-spacing: 0.02em;
    font-family: Sk-Modernist, "sans-serif" !important;
    line-height: 140%;
    color: #393939;
    @media (--t) {
      font-size: 19px;
    }
  }
}

.faq {
  margin-top: 70px;

  @media (--t) {
    padding-bottom: 64px;
  }

  .v-expansion-panel {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .v-expansion-panel-header {
    padding: 8px 10px;
    font-family: Sk-Modernist, "sans-serif" !important;
    font-style: normal;
    font-weight: bold;
    font-size: 16px !important;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #828282;
    text-align: left;
    min-height: 55px;
    background: #f9f8f7;
    border-radius: 0;
    border: none;

    .v-expansion-panel-header__icon .v-icon {
      color: #828282 !important;
    }

    @media (--t) {
      padding: 16px 24px;
      font-size: 20px !important;
      line-height: 140%;
      min-height: unset;
      height: 50px;
    }

    &.v-expansion-panel-header--active {
      background-color: black;
      color: white;

      .v-expansion-panel-header__icon .v-icon {
        color: white !important;
      }
    }
  }

  .v-expansion-panel-content__wrap {
    padding: 16px 0;
    @media(--t) {
      padding: 16px 24px;
    }

    .container {
      padding-top: 0;
      padding-bottom: 0;

      p:last-child {
        margin: 0;
      }
    }
  }
}

.accordion-body {
  text-align: justify;
}
</style>
