<template>
  <div class="container">
    <span class="soft-text font-weight">1. {{ $t("faq.image.consistency.one.title") }}</span>
    <span class="soft-text ">{{ $t("faq.image.consistency.one.text") }}</span>
    <br/><br/>
    <span class="soft-text font-weight">2. {{ $t("faq.image.consistency.two.title") }}</span
    ><br/><br/>
    <v-container>
      <span class="soft-text ">1. {{ $t("faq.image.consistency.two.inner-title-one") }}</span>
      <span class="soft-text ">{{ $t("faq.image.consistency.two.inner-text-one") }}</span
      ><br/><br/>
      <span class="soft-text ">2. {{ $t("faq.image.consistency.two.inner-title-two") }}</span>
      <span class="soft-text ">{{ $t("faq.image.consistency.two.inner-text-two") }}</span
      ><br/>

      <div class="w-100 d-flex align-center justify-center mt-4">
        <v-img alt="" class="mx-auto" src="@/assets/images/ensure.png" contain
             max-width="400" aspect-ratio="1"/>
      </div>
    </v-container>
    <br/>
    <span class="soft-text ">{{ $t("faq.image.consistency.divider") }}</span> <br/><br/>
    <span class="soft-text font-weight">3. {{ $t("faq.image.consistency.three.title") }}</span>
    <span class="soft-text ">{{ $t("faq.image.consistency.three.text-one") }}</span>
    <span class="soft-text "> {{ $t("faq.image.consistency.three.please") }} </span>
    <span class="soft-text "><a class="font-weight-bold"
                                href="mailto:support@blankspace.ink?subject=I have questions."
                                style="color: black!important;">support@blankspace.ink</a></span>
    <span class="soft-text "> {{ $t("faq.image.consistency.three.if") }}</span>
    <br/><br/>
  </div>
</template>

<script>
export default {
  name: 'FaqImageConsistency'
}
</script>

<style scoped>

</style>
