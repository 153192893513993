import Swiper from 'swiper'
export const swiperMixins = {

  data: () => ({
    swiper: null,
  }),

  mounted() {
    setTimeout(() => {
      this.initSwipers()
    }, 300)
  },
  methods: {
    initSwipers() {
      this.swiper = new Swiper(this.$refs.swiper, this.options)
    }
  },
  computed: {
    options() {
      return {}
    }
  }
}
