export const dialogComponentMixins = {
  props: {
    value: {
      type: Boolean,
      default: () => false
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', !!val)
      }
    }
  }
}
