import {routes} from "@/router/routes";
import {isObject} from "lodash";
import router from "../router"
export const languageSelctorMixins = {
    data: () => ({
        language: null,
    }),
    methods: {
        changeLanguage(code) {
            this.language = code
            localStorage.setItem("lang", this.language)
            this.$i18n.locale = code;
            const newRoute = this.$i18n.getLocaleMessage(code)['routes'][this.$route.meta.route_key];
                this.$router.push(newRoute).then(() => location.reload())
        },
    },

    mounted() {
        if (localStorage.getItem("lang") == null) {
            localStorage.setItem("lang", "en")
        }
        this.language = localStorage.getItem("lang")
    },
    computed: {
        availableLocales() {
            return [
                {
                    title: 'English',
                    code: 'en'
                },
                {
                    title: 'French',
                    code: 'fr'
                },

            ]
        },
        activeLocale() {
            return this.availableLocales.find(item => item.code === this.language)?.title
        }
    },
}
