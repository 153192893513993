<template>
  <div class="checkout-page-container">
    <v-container class="payment-method-container">
      <div class="mt-12">
        <v-row class="justify-space-between">
          <v-col lg="6" cols="12">

            <v-btn text color="primary" tile class="text-capitalize mb-6" style="padding-left: 0;"
                   :to="{ name: 'ShipmentStep' }">
              <v-icon size="35">mdi mdi-chevron-left</v-icon>
              <span class="page-subtitle">{{ $t("cart.back_to_shipping") }}</span>
            </v-btn>

            <v-expansion-panels v-model="billingAddressFormOpen" class="mb-6 faq-panel" color="primary" flat tile
                                accordion>
              <v-expansion-panel>
                <v-expansion-panel-header ripple disable-icon-rotate hide-actions>
                  <div class="font-weight-bold address-title text--black">

                    {{ $t("purchase.billing_address") }}
                    <v-icon>mdi-pencil</v-icon>
                  </div>
                  <hr style="height: 1px; flex: none;"/>
                  <span v-html="billingAddressText"></span>
                </v-expansion-panel-header>

                <v-expansion-panel-content class="accordion-body" eager>
                  <div class="billing-address-form-section">
                    <hr/>
                    <AppForm :form-fields="billingAddressFormFields"
                             :validationPerformed="validationPerformed"
                             :formData="order_data.karavan_order.contact_billing_address"
                             :loading="billing_loading"
                             ref="billingAddressForm" class="mt-7"/>

                    <div style="display: flex; justify-content:flex-end; align-items:center; width: 100%; gap: 10px;">
                      <AppButton
                          @click="cancelChanges"
                          color="light"
                      >
                        {{ $t('buttons.cancel') }}
                      </AppButton>
                      <AppButton
                          :loading="billing_loading"
                          @click="UPDATE_ORDER_INFO"
                          color="primary"
                      >
                        {{ $t('buttons.save') }}
                      </AppButton>

                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <h1 class="large-text font-weight-bold text--black">{{ $t("purchase.pay-info") }}</h1>
            <hr/>
            <AppForm :validation-performed="validationPerformed" :form-fields="formFields" :form-data="userData"
                     class="mt-6" :loading="loading" ref="form"/>
            <div class="d-flex">
              <AppButton :loading="loading" height="50" block @click="validateAndSubmit">{{
                  $t("buttons.make_payment")
                }}
              </AppButton>
            </div>
            <v-alert
                v-if="errorMessage"
                class="mt-6"
                color="red"
                type="error"
                dismissible
            >{{ errorMessage }}
            </v-alert>
          </v-col>
          <OrderPaymentCard>
            <template v-slot:products>
              <PurchaseShopItem
                  v-for="(item, index) in get_cart_without_sample_kit"
                  :key="index"
                  :frame="item.frame"
                  :height="item.height"
                  :layout="item.layout"
                  :product="item.product_type"
                  :gift_card_value="item.name"
                  :quantity="item.quantity"
                  :src="item.thumbnail_base64"
                  :total="item.price * item.quantity"
                  :width="item.width"
              />
            </template>
          </OrderPaymentCard>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import {IconMasterCard, IconPaypals, IconVisa, IconVisaDebit} from "@/assets/svg-icons";
import OrderPaymentCard from "@/components/cards/order-payment-card";
import CustomText from "@/components/shared/CustomText";
import {mapGetters} from "vuex";
import {creditCardMixins} from "@/mixins/creditCardMixins";
import {addressPageMixins} from "@/mixins/addressPageMixins";
import {cloneDeep, isArray} from "lodash";
import {axiosInstance} from "@/utils/setAxios";

export default {
  name: "Checkout",
  mixins: [creditCardMixins, addressPageMixins],
  metaInfo() {
    return {
      title: this.$t("titles.payment"),
      titleTemplate: "%s - Blankspace",
      htmlAttrs: {
        lang: "en",
      },
    };
  },
  components: {
    OrderPaymentCard,
    IconPaypals,
    IconMasterCard,
    IconVisaDebit,
    IconVisa,
    CustomText,
  },
  data() {
    return {
      validationPerformed: false,
      errorMessage: null,
      userData: {
        card_holder_name: null,
        cc_ex_month: null,
        cc_number: null,
        cc_ex_year: null,
        cc_vv: null,
        note: null,
      },
      order_data: {
        karavan_order: {
          contact_billing_address: {}
        }
      },
      contact_billing_address_backup: {},
      billingAddressFormOpen: null,
      loading: false,
      billing_loading: false,
      update_shipment_condition: false,
      total_amount: 0,
    };
  },
  mounted() {
    // this.$store.commit("SET_LOADER", false);
    this.order_data.karavan_order.contact_billing_address = {
      ...this._get_selected_billing_address
    }

    this.contact_billing_address_backup = cloneDeep(this._get_selected_billing_address)

  },
  methods: {
    validateAndSubmit() {
      if (this.creditCardAvailable) {
        this.validationPerformed = true;
        this.$refs.form.$validator.validateAll().then((v) => {
          if (v) this.onSubmit();
        });
      } else this.onSubmit();
    },
    onSubmit() {
      window.scroll(0, 0);
      this.loading = true;
      this.errorMessage = null;
      this.$store
          .dispatch("makePayment", this.paymentData)
          .then((response) => {
            this.$store.state.cart = [];
            // this.$store.commit("SET_LOADER", true);
            this.loading = false;
            return this.$router.push({name: "OrderDetail"});
          })
          .catch((error) => {
            const {messages} = error?.response?.data || {}
            let message = this.$t("notify.payment_failed");
            if (isArray(messages) && messages?.length > 0) {
              message = messages.join("</br>")
            }
            // this.createErrorNotification(message);
            this.errorMessage = message;
            this.loading = false;
            // this.$store.commit("SET_LOADER", false);
          })
    },
    UPDATE_ORDER_INFO() {
      const addressModel = {
        karavan_order: {
          contact_billing_address: this.order_data.karavan_order.contact_billing_address,
        },
      }
      const order_id = localStorage.getItem('order_id') || null
      this.billing_loading = true;
      return axiosInstance
          .patch(`orders/${order_id}/update_order_info`, addressModel)
          .then((response) => {
            this.$store.commit('SET_CONTACT_BILLING_ADDRESS_INFO', this.order_data.karavan_order.contact_billing_address)
            this.contact_billing_address_backup = cloneDeep(this._get_selected_billing_address)
            this.billingAddressFormOpen = null;
          })
          .catch(() => this.createErrorNotification(this.$t('notify.wrong')))
          .finally(() => {
            this.billing_loading = false;
          })
    },
    cancelChanges(){
      this.billingAddressFormOpen = null;
      this.order_data.karavan_order.contact_billing_address = {
        ...this.contact_billing_address_backup
      }
    }
  },
  watch: {
    billingAddressFormOpen(value) {
      if (value === 0) {
        this.contact_billing_address_backup = cloneDeep(this._get_selected_billing_address)
      }
    }
  },
  computed: {
    ...mapGetters(["get_cart", "get_cart_without_sample_kit", "cartPayableTotalAmount", "coupon", "get_coupon_amount", "get_total_amount", "get_gift_cards"]),
    _get_selected_billing_address() {
      return this.$store.state.order.order_summary?.order_info?.karavan_order?.contact_billing_address;
    },
    cardNumber() {
      return this.userData.cc_number || "";
    },
    giftCardCodes() {
      return this.get_gift_cards.map((item) => ({
        code: item.code,
        pin: item.pin,
      }));
    },
    paymentData() {
      return {
        check_out: {
          ...this.userData,
          ...{
            payment_method: "5de182399e2b9000040b8d39",
            payable_total_amount: parseFloat(this.cartPayableTotalAmount.toFixed(2)),
            total_amount: parseFloat(this.get_total_amount.toFixed(2)),
            discount_code: this.coupon ? this.get_coupon_amount.code : "",
            discount_note: "",
            gift_card_codes: this.giftCardCodes,
          },
        },
      };
    },
    formFields() {
      const fieldSource = [
        {
          comp: "AppFormInput",
          icon: "",
          colProps: {
            cols: 12,
          },
          label: this.$t("placeholder.card-holder"),
          name: "card_holder_name",
          validation: "required|alpha_spaces",
          showsAt: () => this.creditCardAvailable
        },
        {
          comp: "AppCreditCardInput",
          icon: "",
          colProps: {
            cols: 12,
          },
          label: this.$t("placeholder.card-number"),
          name: "cc_number",
          validation: "required|luhn",
          showsAt: () => this.creditCardAvailable
        },
        {
          comp: "AppSelect",
          icon: "",
          colProps: {
            cols: 4,
          },
          props: {
            items: this.months,
          },
          label: this.$t("placeholder.month"),
          name: "cc_ex_month",
          validation: "required|numeric",
          showsAt: () => this.creditCardAvailable
        },
        {
          comp: "AppSelect",
          icon: "",
          colProps: {
            cols: 4,
          },
          props: {
            items: this.years,
          },
          label: this.$t("placeholder.year"),
          name: "cc_ex_year",
          validation: "required|numeric",
          showsAt: () => this.creditCardAvailable
        },
        {
          comp: "AppFormInput",
          icon: "",
          colProps: {
            cols: 4,
          },
          label: this.$t("placeholder.cvv"),
          name: "cc_vv",
          validation: `required|numeric|length:${this.cvcNumberLength}`,
          showsAt: () => this.creditCardAvailable
        },
        // {
        //   comp: "hr",
        //   colProps: {
        //     cols: 12,
        //   },
        //   name: "note-hr",
        //   showsAt: () => this.creditCardAvailable
        // },
        {
          comp: "AppTextarea",
          icon: "",
          colProps: {
            cols: 12,
          },
          label: this.$t("placeholder.payment_note"),
          name: "note",
          validation: "max: 200",
          showsAt: () => true
        },
      ];
      return fieldSource?.filter(f => f.showsAt())
    },
    months() {
      if (this.userData?.cc_ex_year === new Date().getFullYear()?.toString()) {
        const activeMonth = new Date().getMonth() + 1;
        return Array.from({length: 12 - activeMonth + 1}, (_, i) => `0${12 - i}`.slice(-2))?.reverse()
      } else {
        return Array.from({length: 12}, (_, i) => `0${i + 1}`.slice(-2));
      }
    },
    years() {
      const selectedMonth = this.userData?.cc_ex_month;
      const currentMonth = `0${new Date().getMonth() + 1}`.slice(-2)
      if (this.userData?.cc_ex_month?.length > 0 && parseInt(selectedMonth) < parseInt(currentMonth)) {
        return Array.from({length: 20}, (_, i) => i + new Date().getFullYear() + 1 + "");
      } else {
        return Array.from({length: 20}, (_, i) => i + new Date().getFullYear() + "");
      }
    },
    creditCardAvailable() {
      return this.cartPayableTotalAmount > 0;
    },
    billingAddressText() {
      const {first_name, last_name, country, state, street1} = this.contact_billing_address_backup;
      return `${first_name} ${last_name} <br /> ${street1} <br/> ${state}/${country}`
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  @media (min-width: 1400px) {
    max-width: 1320px;
  }
}

hr {
  margin: 16px 0;
}

form {
  margin-top: 25px;
}

.checkout-page-container {
  overflow-x: hidden;
  @media (--t) {
    padding-bottom: 150px;
  }
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

::v-deep .v-expansion-panel-header {
  padding: 0 !important;
  padding-right: 30px !important;
  font-family: Sk-Modernist, "sans-serif" !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px !important;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #828282;
  text-align: left;
  min-height: 160px !important;
  height: auto !important;
  // background: #f9f8f7;
  border-radius: 0;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .address-title {
    color: #000;
    margin-bottom: 5px;
    display: flex; justify-content: space-between; align-items:center; width: 100%
  }

  .v-expansion-panel-header__icon .v-icon {
    color: #828282 !important;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (--t) {
    padding: 16px 24px;
    font-size: 20px !important;
    line-height: 140%;
    min-height: unset;
    height: 50px;
  }

  &.v-expansion-panel-header--active {
    // background-color: black;
    // color: white;

    .address-title {
      // color: white;
      margin-bottom: 5px;
    }


    .v-expansion-panel-header__icon .v-icon {
      // color: white !important;
    }
  }
}
</style>
