export const formInputMixins = {
  props: {
    value: {
      type: [String, Number],
      default: () => null
    }
  },

  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    hasError() {
      return this.$attrs['error-messages'].length > 0
    },
  },
  watch : {
    value(value){
      // if(value) this.$emit("input", value)
    }
  }
}
