<template>
  <v-responsive class="product-detail-swiper-wrapper"  max-width="100%" :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '749px'" :aspect-ratio="749/650">
    <div class="swiper-container product-detail-swiper" ref="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in items" :key="index">
          <v-responsive  :aspect-ratio="imgAspectRatio"  >
            <img :src="item.src" alt="" class="product-img" />
          </v-responsive>
        </div>
      </div>
      <div class="swiper-pagination"></div>
      <!-- If we need navigation buttons -->
    </div>
    <template
      v-for="direction in ['next', 'prev']"
    >
      <v-btn
        depressed
        icon
        absolute
        color="primary"
        ref="navBtn"
        :key="`${direction}Btn`"
        :class="`navigation-${direction} nav-btn`"
        height="50"
        width="50"
      >
        <v-icon size="30">mdi-chevron-{{ direction === 'next' ? 'right' : 'left'}}</v-icon>
      </v-btn>
    </template>
  </v-responsive>
</template>

<script>
import {swiperMixins} from '@/mixins/swiperMixins'

export default {
  name: 'ProductDetailSlider',
  mixins: [swiperMixins],
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    options() {
      return {
        spaceBetween: 10,
        slidesPerView: 1,
        loop: true,
        /*pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true, //this.items.length > 10
        },*/
        navigation: {
          nextEl: this.$refs.navBtn[0].$el,
          prevEl: this.$refs.navBtn[1].$el,
        }
      }
    },
    imgAspectRatio() {
      return this.smAndDown ? (750 / 650) : (360 / 345)
    }
  }
}
</script>

<style lang="scss">
.product-detail-swiper-wrapper {
  .nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    &.navigation-prev {
      left: 5px;
    }
    &.navigation-next {
      right: 5px;
    }
  }

}
.product-detail-swiper {
  /*height: 100%;*/

  .swiper-wrapper {
    /*height: 100%;*/

    .swiper-slide {
      width: 100%;
      /*height: 100%;*/
      background-position: center !important;
      background-size: cover !important;
      /*img {
        //width: 750px;
        height: 100%;
        object-fit: cover;
      }*/
      .product-img {
        max-width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
