<template>
  <v-expansion-panels class="order-card">
    <v-expansion-panel>
      <v-expansion-panel-header >
        <div>
          <span class="page-subtitle " style="font-size: 15px!important;">{{ $t('profile.date') }}</span>
          <span class="green-text-bold ml-2">{{ orderTime }}</span>
        </div>
        <template v-slot:actions>
          <v-icon color="primary">
            $expand
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="accordion-body">
          <div>
            <span class="green-text-bold">{{ $t('profile.status') }}</span>
            <span class="medium-bold ml-2">:</span>
            <span class="medium-bold ml-2">{{ status.toUpperCase() }}</span>
          </div>
          <div>
            <span class="green-text-bold">{{ $t('profile.total') }}</span>
            <span class="medium-bold ml-2">:</span>
            <span class="medium-bold ml-2">$ {{ total }}</span>
          </div>
          <v-btn text color="primary" :to="to">
            <span class="medium-bold mr-2">{{ $t('profile.detail') }}</span>
            <IconArrow/>
          </v-btn>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import IconPending from '@/assets/svg-icons/pending.svg'
import IconArrow from '@/assets/svg-icons/icons/more-arrow.svg'
import moment from 'moment'

export default {
  name: 'OrderListCard',
  components: {
    IconPending,
    IconArrow
  },
  props: {
    accordion_id: {
      type: Number,
    },
    date: {
      type: String,
    },
    total: {
      type: Number
    },
    status: {
      type: String
    },
    to: {
      typ: String
    }
  },
  computed: {
    orderTime() {
      return moment(this.date).format('DD.MM.YY')
    }
  }
}
</script>

<style lang="postcss" scoped>

.v-expansion-panels.order-card {
  justify-content: left;

  .v-expansion-panel {
    border: 1px solid #000000;
    border-radius: 0 !important;
  }
  .v-expansion-panel-header--active {
    &:before {
      opacity: .08;
    }
    border-bottom: 1px solid #000000;
  }
}

.accordion-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 16px;
  @media (--t) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  > div {
    height: 40px;
    display: flex;
    align-items: center;
  }
  span {
    font-size: 13px;
  }
}

.page-subtitle {
  color: #393939;
  font-weight: bolder;
  letter-spacing: 0.10em;
}
</style>
