<template>
    <v-img
        lazy-src="/img/banners/home-banner-1.jpg"
        src="/img/banners/home-banner-1-opt.gif"
        content-class="banner-section-content-wrapper"
        :style="{ height }"
        :aspect-ratio="aspectRatio"
    >
      <div class="d-flex fill-height flex-column justify-center">
        <div class="overlay-image-section ">
          <div class="card-img-overlay">
            <div class="text-animation">
              <span class="overlay-title" ref="title">{{ caption }}</span>
            </div>
            <h2 class="overlay-subtitle mb-2">{{ $t('home.overlay.subtitle') }}</h2>

            <AppButton
                v-if="!smAndDown"
                :to="{name:'Configurator'}"
                color="primary"
                class="mt-16"
                large
            >
              {{ $t('buttons.start') }}
            </AppButton>
          </div>
        </div>
        <div class="d-flex justify-center">
          <AppButton
              v-if="smAndDown"
              :to="{name:'Configurator'}"
              color="primary"
              class="mt-4"
          >
            {{ $t('buttons.start') }}
          </AppButton>
        </div>
      </div>
    </v-img>
</template>

<script>
import anime from 'animejs'

export default {
  name: 'BannerSection',

  methods: {
    animate() {
      anime.timeline(
          {
            loop: true,
            loopComplete: () => {
              this.loopCompleted++
            }
          }
      ).add({
        targets: '.text-animation',
        translateX: [40, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: 'easeOutExpo',
        duration: 1200,
        delay: (el, i) => 500 + 30 * i
      })
          .add({
            targets: '.text-animation',
            translateX: [0, -30],
            opacity: [1, 0],
            easing: 'easeInExpo',
            duration: 1100,
            delay: (el, i) => 100 + 30 * i
          })
    },
  },
  data: () => ({
    loopCompleted: 0,
  }),
  mounted() {
    this.animate()
  },

  computed: {
    captions() {
      return Array.from({length: 5}, (_, i) => this.$t(`home.overlay.title-${i + 1}`))
    },
    caption() {
      return this.captions[this.loopCompleted % 5]
    },
    aspectRatio() {
      return this.$vuetify.breakpoint.mdAndUp ? (1920 / 980) : (360 / 250)
    },
    height() {
      return !this.smAndDown ? 'calc(100vh - 100px)' : ''
    }
  }
}
</script>

<style lang="scss">
.banner-section-content-wrapper {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #000000;
    opacity: .1;
    z-index: -1;
  }

  > div {
    z-index: 0;
  }

  .text-animation {
    position: relative;
    width: 100vw;
    display: flex;
    align-items: center;

    @media (--t) {
      position: relative;
      overflow: hidden;
    }
  }

  .text-animation span {
    position: relative;
    display: block;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 30px;
    line-height: normal;
    max-width: 80%;
    margin: 10px auto;
    @media (--t) {
      font-size: 50px;
      max-width: unset;
      margin: 28px auto;
    }
    @media (1280px) {
      font-size: 70px;
    }
    @media(min-width: 1600px) {
      font-size: 100px;
    }
  }

  .overlay-subtitle {
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    @media (max-width: 600px) {
    }
  }

  .overlay-image-section {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border: 0;

    .card-img-overlay {
      background: rgba(249, 248, 247, 0.6);
      padding: 5rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media(max-width: 600px) {
        padding: 0;
      }
    }

  }
}
</style>
