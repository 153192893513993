<template>
  <v-container class="item-card">
    <v-row>
      <v-col
          v-for="(option, index) in options"
          :key="option.text"
          class="d-flex child-flex flex-column "
          cols="4"
          style="padding: 10px"
          @click="selected_item = option.id"
      >
        <label>
          <div style="cursor: pointer">
            <v-img :class="{ selected: option.id === selected_item }" :src="materialImage(option.path)" aspect-ratio="1"
                   class="grey lighten-2"/>
            <span :for="id + index" class="mt-2">{{ option.title }}</span>
            <input :id="id + index" :checked="option.value === value" :name="id" :value="option.value"
                   type="radio" @change="updateValue(option)"/>
          </div>
        </label>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ItemCard",
  data(){
    return {
      selected_item:null,
    }
  },
  model: {
    event: "change"
  },
  props: {
    id: {
      type: String
    },
    legend: {
      type: String
    },
    title: {
      type: String
    },
    label: {
      type: String
    },
    image: {
      type: String
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null
    },
    options: {
      type: [Array]
    },
    selected: {
      default: null,
    }
  },
  methods: {
    updateValue(option) {
      this.$emit("change", option.value);
      this.$emit("frameValue", option?.frame || null);
    },
    materialImage(path) {
      return require("@/" + path);
    }
  },
  mounted() {
      if (this.value) {
        const MATCHED_MATERIAL = this.options?.find(o => o.value === this.value);
        if (MATCHED_MATERIAL) {
          this.selected_item = MATCHED_MATERIAL?.id;
        }
        this.$forceUpdate();
      }
  }
};
</script>

<style lang="postcss" scoped>
.item-card {
  cursor: pointer;

  .selected {
    border: 3px solid #077d60 !important;
    padding: 10px;

    span {
      display: inline-block;
    }

    img {
      transform: scale(0.75);
      -webkit-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
    }
  }

  img {
    cursor: pointer !important;
  }

  input {
    display: none;
  }
}
</style>
