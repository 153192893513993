<template>
  <v-dialog
    class="preview-dialog"
    content-class="preview-dialog"
    :width="dialogWidth"
    :height="dialogHeight"
    v-model="dialog"
    :fullscreen="smAndDown"
  >
    <v-card min-height="100%">
      <v-btn
        class="close-btn"
        color="primary"
        depressed
        fab
        @click="dialog = false"
      >
        <v-icon large class="close-button">mdi-close</v-icon>
      </v-btn>
      <v-card-text class="px-0 fill-height" style="overflow: hidden">
        <div class="d-flex flex-wrap fill-height align-center justify-center">
          <div class="content">
            <div class="layout-images" :style="contentStyle">
              <transition
                :enter-active-class="layout_transition_animations.enter"
                :leave-active-class="layout_transition_animations.leave"
                @before-enter="animation = false"
                @after-enter="animation = true"
                mode="out-in"
              >
                <component
                  v-if="previewReady"
                  :is="activeLayout"
                  :width="width"
                  :height="height"
                  :imageUrl="imageUrl"
                />
              </transition>
            </div>
            <hr class="mt-4 mb-3" />
            <div class="configurator-layout-selection">
              <label class="layout-type">
                <input v-model="active_layout" type="radio" value="1 Panel" />
                <IconGridDefault
                  :class="{ 'svg-active': singlePanelAvailable }"
                />
              </label>

              <label class="layout-type">
                <input
                  v-model="active_layout"
                  type="radio"
                  value="3 Vertical Panels"
                />
                <IconGridVertical
                  :class="{ 'svg-active': verticalPanelsAvailable }"
                />
              </label>

              <label class="layout-type">
                <input
                  v-model="active_layout"
                  type="radio"
                  value="3 Horizontal Panels"
                />
                <IconGridHorizontal
                  :class="{ 'svg-active': horizontalPanelsAvailable }"
                />
              </label>

              <label class="layout-type">
                <input
                  v-model="active_layout"
                  type="radio"
                  value="4 Wavy Panels"
                />
                <IconGridWave :class="{ 'svg-active': wavyPanelsAvailable }" />
              </label>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  IconX,
  IconGridDefault,
  IconGridHorizontal,
  IconGridVertical,
  IconGridWave,
} from "@/assets/svg-icons";
import VerticalFrame from "./Frames/Vertical";
import HorizontalFrame from "./Frames/Horizontal";
import WavyFrame from "./Frames/Wavy";
import RectangleFrame from "./Frames/Rectangle";
import { dialogComponentMixins } from "@/mixins/dialogComponentMixins";

export default {
  mixins: [dialogComponentMixins],
  props: {
    imageUrl: {
      type: String,
      required: false,
    },
    layout: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      active_layout: "",
      width: null,
      height: null,
      animation: false,
      layouts: [
        "1 Panel",
        "3 Vertical Panels",
        "3 Horizontal Panels",
        "4 Wavy Panels",
      ],
      layout_transition_animations: {
        enter: "animate__animated animate__fadeInLeft",
        leave: "animate__animated animate__fadeOutRight",
      },
    };
  },
  mounted() {
    const image = new Image();
    image.src = this.imageUrl;
    image.onload = () => {
      this.active_layout = this.layout;

      // const previewDialog = document.querySelector(
      //   ".preview-dialog.v-dialog--active"
      // );
      // const { offsetWidth, offsetHeight } = previewDialog;
      // const imageWidth = (8 / 10) * offsetWidth;
      // const imageHeight = (8 / 10) * offsetHeight;

      // this.width = imageWidth;
      // this.height = imageHeight;
      this.width = image.naturalWidth;
      this.height = image.naturalHeight;

      if (window.outerWidth <= 1680 || window.outerWidth <= 1920) {
        this.width = image.naturalWidth / 2;
        this.height = 500;
      }
    };
  },
  computed: {
    previewReady() {
      return Boolean(this.width && this.height);
    },
    contentStyle() {
      let width = this.width;
      let height = this.height;

      if (this.verticalPanelsAvailable) {
        width += 20;
      } else if (this.horizontalPanelsAvailable) {
        height += 20;
      } else if (this.wavyPanelsAvailable) {
        width += 30;
      }

      return {
        width: `${width}px`,
        height: `${height}px`,
      };
    },
    activeLayout() {
      if (this.verticalPanelsAvailable) return "VerticalFrame";
      else if (this.horizontalPanelsAvailable) return "HorizontalFrame";
      else if (this.wavyPanelsAvailable) return "WavyFrame";
      else return "RectangleFrame";
    },
    singlePanelAvailable() {
      return this.active_layout === "1 Panel";
    },
    verticalPanelsAvailable() {
      return this.active_layout === "3 Vertical Panels";
    },
    horizontalPanelsAvailable() {
      return this.active_layout === "3 Horizontal Panels";
    },
    wavyPanelsAvailable() {
      return this.active_layout === "4 Wavy Panels";
    },
    dialogWidth() {
      // return "50%";
      return (this.width > 400 ? this.width : 400) + 70;
    },
    dialogHeight() {
      // return "50%";
      return (this.height > 400 ? this.height : 400) + 70;
    },
  },
  watch: {
    dialog(dialog) {
      if (dialog) {
        const image = new Image();
        image.src = this.imageUrl;
        image.onload = () => {
          this.active_layout = this.layout;
          this.width = image.naturalWidth;
          this.height = image.naturalHeight;

          if (window.outerWidth <= 1680 || window.outerWidth <= 1920) {
            this.width = image.naturalWidth / 2;
            this.height = 500;
          }
          // this.width = imageWidth;
          // this.height = imageHeight;
          //   this.$store.commit("SET_LOADER", false);
        };
      }
    },
    active_layout(newLayout, oldLayout) {
      const matchedOldLayout = this.layouts.indexOf(oldLayout);
      const matchedNewLayout = this.layouts.indexOf(newLayout);
      const layoutDelta = matchedNewLayout - matchedOldLayout;

      if (layoutDelta < 0) {
        this.layout_transition_animations = {
          enter: "animate__animated animate__fadeInRight",
          leave: "animate__animated animate__fadeOutLeft",
        };
      } else {
        this.layout_transition_animations = {
          enter: "animate__animated animate__fadeInLeft",
          leave: "animate__animated animate__fadeOutRight",
        };
      }

      this.$emit("set-layout-event", newLayout);
    },
  },
  components: {
    IconX,
    IconGridDefault,
    IconGridHorizontal,
    IconGridVertical,
    IconGridWave,
    VerticalFrame,
    HorizontalFrame,
    WavyFrame,
    RectangleFrame,
  },
};
</script>

<style lang="scss">
.preview-dialog {
  @media (min-width: 1280px) {
    overflow: visible !important;
  }
  .v-card {
    position: relative;
    .close-btn {
      position: absolute;
      top: -20px;
      right: -20px;
      z-index: 1;
      border: 4px solid white !important;
      @media (max-width: 600px) {
        top: 4px;
        right: 4px;
      }
    }
  }
  .content {
    position: relative;
    width: auto;
    height: auto;
    background-color: #fff;
    padding: 20px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .layout-images {
      overflow: hidden;
      transition: all 0.3s linear;
    }

    .configurator-layout-selection {
      display: flex;
      justify-content: space-around;
      width: 100%;
      align-items: center;
      max-width: 564px;
      label {
        margin-right: 10px;

        svg {
          fill: black;
        }

        .svg-active {
          fill: #009071;
          transition-duration: 0.3s;
        }

        input {
          display: none;
        }
      }
    }
  }
}
</style>
