<template>
  <div v-bind="$attrs" class="flex flex-center">
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="background: none; display: block; shape-rendering: auto;"
        width="60px"
        height="60px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
    >
      <g>
        <path d="M10 50A40 40 0 0 0 90 50A40 47.2 0 0 1 10 50" fill="#212529" stroke="none">
          <animateTransform
              attributeName="transform"
              type="rotate"
              dur="0.7246376811594202s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 50 53.6;360 50 53.6"
          ></animateTransform>
        </path>
      </g>
    </svg>
    <span class="processing-text" v-html="$t(`placeholder.processing`)"></span>
  </div>
</template>

<script>
export default {
  name: "imageLoading",
  props: {
    text: {
      required: false,
      default: "processing"
    },
  },
};
</script>


<style scoped lang="scss">
.processing-text {
  animation: fadeIn 2s;
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>
