<template>
  <v-row v-if="couponAvailable && !_only_gift_card">
    <v-col class="order-title-section">
      <div class="d-flex justify-space-between">
        <div class="medium" style="display: flex; justify-content: center; align-items: center;">
          <span v-if="freeShippingAvailable">{{ $t("cart.shipment") }}</span>
          <span v-else-if="estimatedDiscountAvailable || itemsCountHasChanges">{{ $t("cart.estimated_discount") }}
            <span v-if="couponDefinition"> {{ couponDefinition }}</span>
          </span>
          <span v-else>{{ $t("cart.discount") }} <span v-if="couponDefinition"> {{ couponDefinition }}</span></span>
          <v-tooltip color="error" top>
            <template v-slot:activator="{ on }">
              <v-btn class="black--text" icon x-small v-on="on" @click="$store.dispatch('DELETE_COUPON')">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span> {{ `${$t('cart.remove')} ${couponCode}` }}</span>
          </v-tooltip>
        </div>
        <span v-if="!freeShippingAvailable" class="medium error--text">-{{ couponDescription }}</span>
        <span v-else class="medium">Free</span>
      </div>
      <!--          <div class="d-flex justify-space-between">-->
      <!--            <small class="small black&#45;&#45;text">{{ $t("cart.coupon_code") }}</small>-->
      <!--            <small class="small black&#45;&#45;text">{{ couponCode }}</small>-->
      <!--          </div>-->
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "discount-card",
  computed: {
    ...mapGetters({
      cartTotalAmount: "cart_total_amount",
      couponAvailable: "coupon",
      couponDescription: "couponDescription",
      couponCode: "couponCode",
      couponDefinition: "couponDefinition",
      estimatedDiscountAvailable: "estimatedDiscountAvailable",
      itemsCountHasChanges : "itemsCountHasChanges",
      freeShippingAvailable : "freeShippingAvailable",
      discountRate : "discountRate",
      discountAmount : "discountAmount",
      discountHasChanges : "discountHasChanges",
      _only_gift_card : "_only_gift_card"
    }),
  },
}
</script>

<style scoped>

</style>
