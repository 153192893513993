<template>
  <v-textarea class="rounded-0" outlined v-bind="$attrs" v-model="innerValue" />
</template>

<script>
import { formInputMixins } from '@/mixins/formInputMixins'

export default {
  name: 'AppTextarea',
  mixins: [formInputMixins],
}
</script>

<style scoped>

</style>
