<template>
  <v-container class="auth">
    <div class="form-wrapper">
      <AppForm
        :form-fields="formFields"
        :form-data="model"
        ref="form"
        :loading="loading"
      />
      <AppButton :loading="loading" block large @click="validateAndSubmit">{{ $t('buttons.send_message') }}</AppButton>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'ForgotPassword',
  metaInfo() {
    return {
      title: this.$t('titles.contact'),
      titleTemplate: '%s - Blankspace',
       htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.$t('titles.contact-content')},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  data() {
    return {
      model: {
        full_name: null,
        email: null,
        message: null,
        subject: null,
      },
      loading: false
    }
  },
  methods: {
    validateAndSubmit() {
      this.$refs.form.$validator
        .validateAll()
        .then(v => {
          if (v)
            this.onSubmit()
        })
    },
    onSubmit() {
      this.loading = true
      setTimeout(() => {
        this.createSuccessNotification(this.$t('notify.message'))
        this.$router.push('Home')
      }, 3000)
    },
  },
  computed: {
    formFields() {
      return [
        {
          comp: 'AppFormInput',
          colProps: {
            cols: 12,
          },
          label: this.$t('placeholder.full_name'),
          name: 'full_name',
          validation: 'required|alpha_spaces'
        },
        {
          comp: 'AppFormInput',
          colProps: {
            cols: 12,
          },
          label: this.$t('placeholder.email'),
          name: 'email',
          validation: 'required|email'
        },
        {
          comp: 'AppFormInput',
          colProps: {
            cols: 12,
          },
          label: this.$t('placeholder.subject'),
          name: 'subject',
          validation: 'required|max:200'
        },
        {
          comp: 'AppTextarea',
          colProps: {
            cols: 12,
          },
          label: this.$t('placeholder.message'),
          name: 'message',
          validation: 'required|max:1000'
        },

      ]
    }
  }
}
</script>
<style lang="postcss" scoped>

.auth {
  margin-top: 7%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  .form-wrapper {
    @media (--t) {
      max-width: 410px;
    }
  }
}


</style>
