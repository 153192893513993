<template>
  <div class="acrylic-page">
    <Heading :subtitle="$t('products.canvas.subtitle').toUpperCase()" :title="$t('products.canvas.title')"/>

    <PhotoPaperSection
      :feature_five="$t('products.canvas.details.five')"
      :feature_four="$t('products.canvas.details.four')"
      :feature_one="$t('products.canvas.details.one')"
      :feature_three="$t('products.canvas.details.three')"
      :feature_two="$t('products.canvas.details.two')"
      :images="images"
      :subtitle="$t('products.canvas.description')"
      :title="$t('products.canvas.about')"
    />

    <ProductVideoSection
      src="/video/canvas.mp4"
    />

    <PricingNew/>

    <EdgeFrameSection
      :items="global_edges"
      :sub-title="$t('edge.subtitle').toUpperCase()"
      :title="$t('edge.title')"
      :swiper-options="primaryOptions"
      :spaceBetween="50"
    />

    <EdgeFrameSection
      :items="global_frames"
      :sub-title="$t('frame.subtitle').toUpperCase()"
      :title="$t('frame.title')"
      :swiper-options="secondaryOptions"
      :spaceBetween="100"
      has-bg
    />

    <LayoutTab
      :horizontal_image="canvas_layout_three"
      :rectangle_image="canvas_layout_one"
      :vertical_image="canvas_layout_two"
      :wave_image="canvas_layout_four"
    />

    <UsagePlaces
      :feature_four="$t('products.canvas.usage.features.feature_four')"
      :feature_one="$t('products.canvas.usage.features.feature_one')"
      :feature_three="$t('products.canvas.usage.features.feature_three')"
      :feature_two="$t('products.canvas.usage.features.feature_two')"
      :handcrafted_description="$t('products.canvas.usage.handcrafted.description')"
      :handcrafted_moments="$t('products.canvas.usage.handcrafted.moments')"
      :handcrafted_team="$t('products.canvas.usage.handcrafted.team')"
      :handcrafted_title="$t('products.canvas.usage.handcrafted.title')"
      :left_image="canvas_image_one"
      :right_image="canvas_image_two"
      :vibrant_description="$t('products.canvas.usage.inks.description')"
      :vibrant_title="$t('products.canvas.usage.inks.title')"
    />

    <ProductReviewSection/>
    <WorthSection/>

  </div>
</template>

<script>

import PhotoPaperSection from '@/components/section/paper-info-section'
import FrameCard from '@/components/cards/shared-card/frame-card'
import LayoutTab from '@/components/tab/layout-tab/LayoutTab'
import UsagePlaces from '@/components/section/example-usage-section'
import WorthSection from '@/components/section/stories-worth-section'
import PricingNew from '@/components/cards/pricing-card-new'
import ProductReviewSection from '../../components/product-review/product-review-section'
import axios from 'axios'
import { REFERRAL_PUBLIC_KEY, REFERRAL_SECRET_KEY } from '@/utils/refersion'
import EdgeFrameSection from '@/components/section/EdgeFrameSection'
export default {
  name: 'Canvas',
  metaInfo() {
    return {
      title: this.$t('titles.canvas'),
      titleTemplate: '%s - Blankspace',
      htmlAttrs: {
        lang: 'en'
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.$t('titles.canvas-content')},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  components: {
    EdgeFrameSection,
    ProductReviewSection,
    PricingNew,
    WorthSection,
    UsagePlaces,
    LayoutTab,
    FrameCard,
    PhotoPaperSection,
  },
  data() {
    return {
      canvas_image_one: '/img/papers/13.png',
      canvas_image_two: '/img/papers/16.webp',

      canvas_layout_one: require('../../assets/images/grid/canvas/rectangle.webp'),
      canvas_layout_two: require('../../assets/images/grid/canvas/vertical.webp'),
      canvas_layout_three: require('../../assets/images/grid/canvas/horizontal.webp'),
      canvas_layout_four: require('../../assets/images/grid/canvas/wave.webp'),

      primaryOptions: {
        breakpoints: {
          1024: {
            spaceBetween: 50,
            slidesPerView: 4,
            loop: this.smAndDown,
          }
        }
      },
      secondaryOptions: {
        breakpoints: {
          1024: {
            spaceBetween: 100,
            slidesPerView: 3,
            loop: this.smAndDown,
          }
        }
      },

      global_edges: [],
      global_frames: [],

      images: [
        {id: 1, src: '/img/papers/opt/canvas-0-opt.jpg'},
        {id: 2, src: '/img/papers/opt/canvas-1-opt.jpg'}
      ]
    }
  },
  methods: {
    globalData() {
      if (localStorage.getItem('lang') === 'en') {
        this.global_edges = this.$store.getters.get_edges_en
        this.global_frames = this.$store.getters.get_frames_en
      } else if (localStorage.getItem('lang') === 'fr') {
        this.global_edges = this.$store.getters.get_edges_fr
        this.global_frames = this.$store.getters.get_frames_fr
      }
    },
    SEND() {
      axios.post('https://www.refersion.com/api/check_account', {
        refersion_public_key: REFERRAL_PUBLIC_KEY,
        refersion_secret_key: REFERRAL_SECRET_KEY
      })
    }
  },
  mounted() {
    this.globalData()
  }
}
</script>

<style lang="postcss">
.pagination-container-display {
  display: flex;
  @media (--t) {
    display: none;

  }
}

.grid-image {
  width: inherit;
  height: inherit;
}

.review-section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.acrylic-page {
  padding-top: 35px;
  overflow-x: hidden !important;
  @media (--t) {
    padding-top: 65px;
  }

  h1 {
    @media (--t) {
      margin-left: -5px !important;
    }
  }


  .frames {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #f9f9f9;
    @media (--t) {
      padding-top: 96px;
      padding-bottom: 96px;
    }

    .page-subtitle {
      margin-top: -5px;
    }

    .frame-item {
      margin-left: 20px;
      @media (--t) {
        margin-left: 70px;
      }
    }

    .slide-contain {
      margin-top: 10px;
    }

  }
}

.containers {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1343px;
}

.story-section {
  background-color: #f9f9f9;
  padding-top: 85px;

  .story-items {
    margin-top: 51px;
  }
}
</style>
