<template>
  <v-checkbox
    :color="color"
    v-bind="$attrs"
    v-model="innerValue"
    :hide-details="hideDetails"
    class="mt-0"
  />
</template>

<script>
export default {
  name: 'AppCheckbox',
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    hideDetails: {
      type: Boolean,
      default: () => true
    },
    color: {
      type: String,
      default: () => 'secondary'
    },
  },

  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped>

</style>
