<template>
  <component :is="!smAndDown ? 'ConfiguratorDesktop' : 'ConfiguratorMobile'"></component>
</template>

<script>
import ConfiguratorMobile from "@/views/routes/configurator/configurator-mobile";
import ConfiguratorDesktop from "@/views/routes/configurator/configurator-desktop";

export default {
  name: "Configurator",
  components: {ConfiguratorMobile, ConfiguratorDesktop}
}
</script>
<style lang="scss">
.layout-type {
  &.disabled {
    &:hover {
      svg {
        cursor: not-allowed;
      }
    }
    svg {
      fill: grey!important;
    }
  }
}
</style>
