<template>
  <div class="review-section-container">
    <div class="review-header">
      <Heading :title="$t('review.title')" :subtitle="$t('review.subtitle')"/>
    </div>
    <div class="mx-auto" style="max-width: 1470px;margin-top: 37px; margin-bottom: 60px">
      <div class="swiper-container product-detail-swiper" ref="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(slide, index) in review_list" :key="index">
            <v-card flat height="100%"  class="review-card">
              <v-card-text class="fill-height review-content">
                <div class="d-flex justify-center">
                  <v-avatar :size="116" color="primary" dark>
                    <div class="white--text text-h4">{{ printAuthorAvatar(slide) }}</div>
                  </v-avatar>
                </div>
                <div class="review-text mt-4">- {{ slide.author }}
                </div>
                <div class="d-flex justify-center my-4">
                  <v-rating
                    :value="slide.reviewRating"
                    color="warning"
                    background-color="warning"
                    readonly
                  />
                </div>
                <div class="d-flex justify-center">
                  <div class="review-title">
                    {{ slide.reviewTitle || 'N/A' }}
                  </div>
                </div>
                <div class="text-center review-text review-body">
                  <div>{{ slide.reviewMessage }}</div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-center justify-center">
      <AppButton @click="dialog = true" class="mx-auto" large>{{ $t('buttons.add_review') }}</AppButton>
    </div>
    <v-dialog v-model="dialog" max-width="500" :overlay-opacity=".90" :fullscreen="$vuetify.breakpoint.smAndDown" scrollable>
      <v-card>
        <v-toolbar flat class="small-title">{{ $t('buttons.review') }}</v-toolbar>
        <v-card-text class="pt-5">
          <AppForm
            :form-fields="formFields"
            :form-data="formModel"
            ref="form"
            :loading="loading"
          />
        </v-card-text>
        <v-card-actions>
          <AppButton large block @click="validateAndSubmit" :loading="loading">
            {{ $t('buttons.send-review') }}
          </AppButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { REVIEW_CONTENTS } from '../../plugins/stamped'
import Avatar from '../shared/Avatar'
import IconStar from '@/assets/svg-icons/icons/star.svg'
import { swiperMixins } from '@/mixins/swiperMixins'

export default {
  name: 'ProductReviewSection',
  mixins: [swiperMixins],
  data: () => ({
    dialog: false,
    review_list: null,
    formModel: {
      rating: 0,
      comment_title: null,
      comment: null,
      author_name: null,
      author_email: null,
      product_id: null,
      product_url: null,
      product_sku: null,
    },
    loading: false,
  }),
  methods: {
    validateAndSubmit() {
      this.$refs.form.$validator
        .validateAll()
        .then(v => {
          if (v)
            this.onSubmit()
        })
    },

    onSubmit() {
      this.loading = true
      let FORM_DATA = new FormData()
      FORM_DATA.append('productId', this.formModel.product_id)
      FORM_DATA.append('author', this.formModel.author_name)
      FORM_DATA.append('email', this.formModel.author_email)
      FORM_DATA.append('reviewRating', this.formModel.rating)
      FORM_DATA.append('productName', this.formModel.product_name)
      FORM_DATA.append('productSKU', this.formModel.product_sku)
      FORM_DATA.append('reviewMessage', this.formModel.comment)
      FORM_DATA.append('reviewTitle', this.formModel.comment_title)
      FORM_DATA.append('productUrl', window.location.href)//'http://localhost:8080/#/en/wall-prints/canvas'
      FORM_DATA.append('reviewSource', 'api')

      axios.post('https://stamped.io/api/reviews3?apiKey=pubkey-a231150RXBt53UEo94eDgl3V3527q6&sId=201419', FORM_DATA)
        .then(res => {
          this.createSuccessNotification(this.$t('notify.review'))
          this.dialog = false
        })
        .catch(error => {
          this.createErrorNotification(this.$t('notify.wrong'))
        })
        .then(() => {
          this.loading = false
        })
    },

    getProductReview() {
      // const url = `https://stamped.io/api/widget/reviews?productId=${this.getReviewProduct.product_id}&storeUrl=201419`
      const url = `https://stamped.io/api/widget/reviews?storeUrl=201419`
      return axios.get(url, {
        auth: {
          username: 'pubkey-a231150RXBt53UEo94eDgl3V3527q6',
          password: 'key-jj9XEiU504Q39j9MW4QL50dO56pui4'
        },
      }).then(res => {
        this.review_list = res.data.data
        console.log("this.review_list", this.review_list)
        return res.data.data;
      })
    },
    printAuthorAvatar(item) {
      const userNames = item?.author?.split(' ')?.map(n => n[0])?.join('')
      return userNames;
    }
  },
  mounted() {
    this.getProductReview()
      .then(() => {
        this.swiper.destroy()
        setTimeout(() => {
          this.initSwipers()
        }, 700)
      })
    this.formModel.product_id = this.getReviewProduct.product_id
    this.formModel.product_name = this.getReviewProduct.name
    this.formModel.product_sku = this.getReviewProduct.product_sku
    this.formModel.product_url = this.getReviewProduct.url

    if(this.$route?.query?.action === "review"){
      this.dialog = true;
    }
  },

  computed: {
    loopEnable() {
      return (this.review_list || []).length > (this.smAndDown ? 1 : 5)
    },
    options() {
      return {
        slidesPerView: 1,
        spaceBetween: 16,
        loop: this.loopEnable,
        autoplay: true,
        centeredSlides: !this.loopEnable,
        breakpoints: {
          640: {
            slidesPerView: 3,
            spaceBetween: 16
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 16
          }
        }
      }
    },
    getReviewProduct() {
      return REVIEW_CONTENTS[this.$route.name] || {}
    },
    formFields() {
      return [
        {
          comp: 'AppRatingInput',
          colProps: {
            cols: 12,
          },
          label: this.$t('placeholder.rating'),
          name: 'rating',
          validation: 'required|numeric'
        },
        {
          comp: 'AppFormInput',
          colProps: {
            cols: 12,
          },
          label: this.$t('placeholder.full_name'),
          name: 'author_name',
          validation: 'required|alpha_spaces'
        },
        {
          comp: 'AppFormInput',
          colProps: {
            cols: 12,
          },
          label: this.$t('placeholder.email'),
          name: 'author_email',
          validation: 'required|email'
        },
        {
          comp: 'AppFormInput',
          colProps: {
            cols: 12,
          },
          label: this.$t('placeholder.subject'),
          name: 'comment_title',
          validation: 'required|max:200'
        },
        {
          comp: 'AppTextarea',
          colProps: {
            cols: 12,
          },
          label: this.$t('placeholder.message'),
          name: 'comment',
          validation: 'required|max:1000'
        },

      ]
    },
  },
  watch : {
    dialog(onShow){
      if(!onShow){
        const { product_id, name, product_sku, url } = this.getReviewProduct
        this.formModel = {
          rating: 0,
          comment_title: null,
          comment: null,
          author_name: null,
          author_email: null,
          product_id: product_id,
          product_url: url,
          product_sku: product_sku,
          product_name: name
        }
      }
    }
  },

  components: {
    Avatar,
    IconStar
  },
}
</script>

<style scoped lang="scss">


.review-header {
  display: flex;
  flex-direction: column;

  h2 {
    margin-top: -8px;
  }
}

.mobile-card {
  margin-left: 30px;
  margin-right: 20px;
  @media (--t) {
    margin-left: unset;
    margin-right: unset;
  }
}

.review-section-container {
  padding-top: 48px;
  padding-bottom: 54px;
  @media(--t) {
    padding-bottom: 88px;
  }
}

.review-card {
  .review-content > * {
    width: 100%;
  }
}

.review-text {
  font-family: Sk-Modernist, 'sans-serif';
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #393939;
  margin-top: 17px;

  .review-title {
    height: 64px;
    width: 100%;

    > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  &.review-body {
    height: 256px;

    > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 12;
      -webkit-box-orient: vertical;

    }
  }
}

</style>
