import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import vuetify from "@/plugins/vuetify/vuetify";

import router from "./router";
import store from "./store";
import i18n from "@/plugins/i18n";
import axios from "axios";
import { axiosInstance as karavanAdapter, blankAxios as blankspaceAdapter } from "@/utils/setAxios";
import VeeValidate, { Validator } from "vee-validate";
import en from "vee-validate/dist/locale/en";
import validateLuhn from "@/library/validateLuhn";
import { VueMaskDirective } from "v-mask";
import { globalCommandsMixins } from "@/mixins/globalCommandsMixins";

import "./registerExternalScripts"

Vue.directive("mask", VueMaskDirective);
Vue.use(VeeValidate, {
  mode: "eager",
});
Validator.localize("en", en);
Validator.extend("luhn", {
  getMessage: (field, params, data) => {
    return i18n.t("purchase.card-number-validation");
  },
  validate: (value) => validateLuhn(value),
});

import "swiper/css/swiper.min.css";
import "./styles/app.css";
import anime from "@/plugins/anime/anime";

import alertMixin from "@/mixins/alertMixin";
import VueSweetalert2 from "vue-sweetalert2";
const IconHamburger = () => import("@/assets/svg-icons/icons/hamburger.svg");
const IconCartMini = () => import("@/assets/svg-icons/icons/cart.svg");
const Heading = () => import("@/components/Heading");
const ProductVideoSection = () => import("@/components/section/ProductVideoSection");
const AppButton = () => import("@/components/ui/AppButton");
const ProductDetailSlider = () => import("@/components/sliders/ProductDetailSlider");
const StoryCardSwiper = () => import("@/components/sliders/StoryCardSwiper");
const AppSwiper = () => import("@/components/sliders/AppSwiper");
const AppCheckbox = () => import("@/components/ui/AppCheckbox");
const AppFormInput = () => import("@/components/ui/AppFormInput");
const AppTextarea = () => import("@/components/ui/AppTextarea");
const AppPasswordInput = () => import("@/components/ui/AppPasswordInput");
const AppRatingInput = () => import("@/components/ui/AppRatingInput");
const AppCreditCardInput = () => import("@/components/ui/AppCreditCardInput");
const ProductOption = () => import("@/components/ui/ProductOption");
const AppForm = () => import("@/components/ui/AppForm");
const AppFormContent = () => import("@/components/ui/AppFormContent");
const LayoutTabsContainer = () => import("@/components/layouts/LayoutTabsContainer");
const StoryCard = () => import("@/components/cards/shared-card/story-card");
const InspiringStories = () => import("@/components/ui/InspiringStories");
const PurchaseShopItem = () => import("@/components/cards/unique-card/PurchaseShopItem");
const OrderPreview = () => import("@/components/ui/OrderPreview");
const JoinCommunitySection = () => import("@/components/section/community-section");
import "sweetalert2/dist/sweetalert2.min.css";
import VueMeta from "vue-meta";

const AppSelect = () => import("@/components/ui/AppSelect");

Vue.component("IconHamburger", IconHamburger);
Vue.component("IconCartMini", IconCartMini);
Vue.component("Heading", Heading);
Vue.component("ProductOption", ProductOption);
Vue.component("ProductVideoSection", ProductVideoSection);
Vue.component("ProductDetailSlider", ProductDetailSlider);
Vue.component("AppSwiper", AppSwiper);
Vue.component("StoryCardSwiper", StoryCardSwiper);
Vue.component("StoryCard", StoryCard);
Vue.component("AppForm", AppForm);
Vue.component("AppFormContent", AppFormContent);
Vue.component("AppSelect", AppSelect);
Vue.component("AppButton", AppButton);
Vue.component("AppCheckbox", AppCheckbox);
Vue.component("AppFormInput", AppFormInput);
Vue.component("AppTextarea", AppTextarea);
Vue.component("AppPasswordInput", AppPasswordInput);
Vue.component("AppRatingInput", AppRatingInput);
Vue.component("LayoutTabsContainer", LayoutTabsContainer);
Vue.component("AppCreditCardInput", AppCreditCardInput);
Vue.component("InspiringStories", InspiringStories);
Vue.component("PurchaseShopItem", PurchaseShopItem);
Vue.component("OrderPreview", OrderPreview);
Vue.component("JoinCommunitySection", JoinCommunitySection);

Vue.use(VueMeta);
Vue.use(VueSweetalert2);

Vue.mixin(alertMixin);
Vue.mixin(globalCommandsMixins);

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$log = console.log;
Vue.prototype.$karavanAdapter = karavanAdapter;
Vue.prototype.$blankspaceAdapter = blankspaceAdapter;

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
new Vue({
  anime,
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
