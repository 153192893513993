<template>
  <component :class="size" :is="tag">
    <slot/>
  </component>
</template>


<script>
export default {
  name: 'CustomText',
  props: {
    tag: {
      type: String,
      default: 'span'
    },
    size: {
      type: String,
      default: 'regular-text',
      validator: function (value) {
        return [
          'tertiary-title',
          'font-regular',
          'light-regular-text',
          'forty-title',
          'fifth-title',
          'sixth-title',
          'font-regular-bigger',
          'pre-title',
          'gray-regular-text',
          'light-gray-text',
          'purchase-titles',
          'config-tab-font',
          'white-secondary-title',
          'overlay-title',
          'overlay-subtitle',


          'quotes-title',
          'quotes-writer',
          'blog-title',
          'button-text',
          'blogs-author',
          'button-text',
          'story-title',
          'story-social',
          'print-title',
          'print-subtitle',
          'print-description',
          'print-price',
          'instagram-comment',
          'instagram-profile',
          'footer-title',
          'footer-link',
          'page-title',
          'process-title',
          'regular-text',
          'page-title',
          'page-subtitle'
        ].indexOf(value) !== -1
      }
    }
  }
}
</script>
<style scoped>
.price {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  text-align: right;
  letter-spacing: 0.02em;
  color: #000000;

}
.pre-title {
  font-family: var(--font-family--alethia);
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 105%;
  color: #000000;
}



.tertiary-title {
  font-family: var(--font-family--alethia);
  font-size: 35px;
  color: #000000;
}

.forty-title {
  font-family: var(--font-family--alethia);
  font-weight: normal;
  font-size: 40px;
  color: #000000;
}

.fifth-title {
  font-family: var(--font-family--alethia);
  font-weight: normal;
  font-size: 36px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #000000;
}

.sixth-title {
  font-family: var(--font-family--alethia);
  font-weight: normal;
  font-size: 27px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #000000;
}

.light-regular-text {
  font-family: Sk-Modernist;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #393939;

}

.gray-regular-text {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #9D9D9D;

}

.font-regular {
  font-family: Sk-Modernist;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
}

.font-regular-bigger {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #393939;

}

.white-secondary-title {
  font-family: var(--font-family--alethia);
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.white-regular-text {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.price-medium {
  font-family: Sk-Modernist;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: #009771;
}

.light-gray-text {
  font-family: Sk-Modernist;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  display: flex;
  letter-spacing: 0.02em;
  color: #434343;

}

.config-tab-font {
  font-family: var(--font-family--alethia);
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;

}


.page-title {
  font-family: var(--font-family--alethia);
  font-weight: normal;
  font-size: 60px;
  color: #000000;
  text-align: center;
}

.page-subtitle {
  font-family: Sk-Modernist;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.15em;
  color: #000000;
}

.purchase-titles {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: #000000;
}

.purchase-item {
  font-family: Sk-Modernist;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: #000000;
}

.overlay-title {
  text-align: center;
  color: #FFFFFF;
  font-family: var(--font-family--alethia);
  font-style: normal;
  font-weight: normal;
  font-size: 35.4887px;
  line-height: 91.3%;
  text-align: center;


  @media (--t) {
    font-family: var(--font-family--alethia);
    font-style: normal;
    font-weight: normal;
    text-align: center;
    font-size: 100px;
    color: #FFFFFF;
  }
}

.overlay-subtitle {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.15em;
  color: #FFFFFF;
  @media (--t) {
    font-family: Sk-Modernist;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.15em;
    color: #FFFFFF;

  }
}

.process-title {
  font-family: var(--font-family--alethia);
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
}

.process-description {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
}

.quotes-title {
  color: var(--color_black_900);
  font-weight: var(--type_base1_font-weight);
  font-size: var(--type_base12_font-size_desktop);
}

.quotes-writer {
  color: var(--color_black_700);
  font-size: var(--type_base6_font-size_desktop);
  font-weight: rgb(var(--type_base1_font-weight));
}

.blog-title {
  color: var(--color_black_900);
  font-weight: var(--type_base1_font-weight);
  font-size: var(--type_head4_font-size_desktop);
  line-height: 93%;
  letter-spacing: 0.02em;
  font-family: 'Sk-Modernist';
}

.blog-author {
  font-size: var(--type_base4_font-size_desktop);;
  color: var(--color_black_900);
  line-height: 130%;
  letter-spacing: 0.01em;
}

.button-text {
  font-size: var(--type_base4_font-size_desktop);
  font-weight: var(--type_base2_font-weight);
  color: var(--color_white_900);
  line-height: 150%;
  letter-spacing: 0.02em;
}

.story-title {
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--color_black_900);
  font-weight: var(--type_base2_font-weight);
  font-size: var(--type_base4_font-size_desktop);
}

.story-social {
  color: var(--color_black_900);
  line-height: 150%;
  letter-spacing: 0.02em;
  font-weight: var(--type_base1_font-weight);
  font-size: var(--type_base4_font-size_desktop);
}

.print-title {
  letter-spacing: 0.02em;
  font-size: var(--type_head4_font-size_desktop);
  font-weight: var(--type_base1_font-weight);

}

.print-subtitle {
  letter-spacing: 0.1em;
  color: var(--color_black_900);
  font-size: var(--type_base2_font-size_desktop);
  font-weight: var(--type_base1_font-weight);
}

.print-description {
  line-height: 130%;
  letter-spacing: 0.01em;
  color: var(--color_black_900);
  font-size: var(--type_base4_font-size_desktop);
  font-weight: var(--type_base1_font-weight);
}

.print-price {
  line-height: 19px;
  letter-spacing: 0.1em;
  color: var(--color_green_800);
  font-weight: var(--type_base3_font-weight);
}

.instagram-comment {
  text-align: center;
  line-height: 120%;
  color: var(--color_black_900);
  font-size: var(--type_base13_font-size_desktop);
  font-weight: var(--type_base1_font-weight);
}

.instagram-profile {
  text-align: center;
  color: var(--color_black_900);
  font-size: var(--type_base6_font-size_desktop);
  font-weight: var(--type_base1_font-weight);
}

.footer-title {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.footer-link {
  font-family: Sk-Modernist;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.regular-text {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #000000;
}
</style>
