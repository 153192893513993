<template>
  <div class="size-buttons-section">
    <div v-for="size in data" class="d-flex flex-column mr-2 mr-md-4">
      <label v-if="size.price" :for="`size-button-${size.id}`" class="mb-2">{{ size.price }}$</label>
      <v-btn
        :class="{'active': custom && selected.id === size.id}"
        @click="onSelect(size)"
        outlined
        tile
      >
        {{ size.size }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductSizeSelector',
  props: {
    custom:{
      type:[String,Boolean]
    },
    data:{
      type:Array
    },
  },
  data: () => ({
    selected: false,
  }),
  methods:{
    onSelect(size) {
      this.selected = size;
      this.$emit('onSelect',size);
    },
  },
}
</script>

<style scoped lang="scss">
.size-buttons-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 35px;
  @media (--t) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 33px;
  }

  :nth-child(5),
  :nth-child(4) {
    margin-top: 15px;
    @media (--t) {
      margin-top: unset;
    }
  }

  .v-btn--outlined {
    border: thin solid #000000;
    color: #9F9F9F;
    padding: 0 8px;
    min-width: unset;
    &:before {

    }
    &:hover {
      color: #000000;
      background-color: #B9DCCB;
    }
    &.active {
      color: white;
      &:before {
        background-color: #009771;
        opacity: 1;
      }
    }
  }

  label {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #009771;
    flex: none;
  }
}
</style>
