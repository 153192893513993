import { render, staticRenderFns } from "./story-card.vue?vue&type=template&id=29b75afc&scoped=true&"
import script from "./story-card.vue?vue&type=script&lang=js&"
export * from "./story-card.vue?vue&type=script&lang=js&"
import style0 from "./story-card.vue?vue&type=style&index=0&id=29b75afc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29b75afc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VHover,VImg})
