<script>
export default {
  name: "FrameThumb",
  props: {
    imageUrl: String,
    width: String,
    height: String,
    layout : String,
    maxWidth : Number
  },
  computed : {
    layoutSVG(){
      switch (this.layout) {
        case "3 Horizontal Panels":
          return `<svg xmlns="http://www.w3.org/2000/svg" width="80" height="55" viewBox="0 0 115 80" fill="black" class=""><path d="M0 0h115v21.587H0V0zM0 29.206h115v21.588H0V29.207zM115 58.413H0V80h115V58.413z" fill="fill"></path></svg>`
        case "3 Vertical Panels":
          return `<svg xmlns="http://www.w3.org/2000/svg" width="80" height="55" viewBox="0 0 115 81" fill="black" class=""><path d="M0 0h33.222v80.5H0V0zM40.889 0H74.11v80.5H40.889V0zM115 0H81.778v80.5H115V0z" fill="fill"></path></svg>`
        case "4 Wavy Panels":
          return `<svg xmlns="http://www.w3.org/2000/svg" width="80" height="60" viewBox="0 0 115 81" fill="black" class=""><path d="M60.567 0h24.15v72.833h-24.15V0zM54.433 7.667h-24.15V80.5h24.15V7.667zM24.533 11.5H0V69h24.533V11.5zM115 11.5H90.85V69H115V11.5z" fill="fill"></path></svg>`
      }
    },
    canvasWidth(){
      const sizeRate = 100 / this.maxWidth;
      if(this.orientation === "vertical") return 85 / sizeRate
      else if(this.orientation === "horizontal") return 120 / sizeRate
      else return 120 / sizeRate
    },
    canvasHeight(){
      const sizeRate = 100 / this.maxWidth;
      if(this.orientation === "vertical") return 136 / sizeRate
      else if(this.orientation === "horizontal") return 75 / sizeRate
      else return 120 / sizeRate
    },
    orientation(){
      const _aspectRatio = this.height / this.width;
      if (_aspectRatio >= 0 && _aspectRatio < 1) return "horizontal";
      else if (_aspectRatio === 1) return "square";
      else if (_aspectRatio > 1) return "vertical";
    }
  },
  mounted(){
    this.drawImage();
  },
  methods : {
    drawImage(){
      const canvas = this.$refs?.layoutCanvas; // document.querySelector('#layoutCanvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = this.imageUrl;
      img.onload = () => {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const svg = new Image();
        svg.src = `data:image/svg+xml;utf8,${this.layoutSVG}`;
        svg.onload = () => {
          ctx.globalCompositeOperation = 'destination-in';
          ctx.drawImage(svg, 0, 0, canvas.width, canvas.height);
        }
      }
    }
  },
  watch : {
    layout(layout){
      this.drawImage();
    }
  }
}
</script>

<template>
  <canvas id="layoutCanvas" ref="layoutCanvas" :width="canvasWidth" :height="canvasHeight"></canvas>
</template>
