export const intercomMixin = {
    beforeDestroy() {
        const interComApp = document.querySelector(".intercom-lightweight-app");
        if (interComApp) {
            interComApp.style.display = "block";
        }
    },
    mounted() {
        // alert();
        // TODOGK : Bu sağlıklı bir kod değil. Çünkü diğer app'in load olup olmadığını bilmiyoruz. Bunu değiştireceğiz.
        setTimeout(() => {
            const interComApp = document.querySelector(".intercom-lightweight-app");
            if (interComApp) {
                interComApp.style.display = "none";
            }
        }, 1000);
    },
}

