<template>
  <v-card
    v-bind="$attrs"
    class="fill-height d-flex flex-column hardware-card p-0"
    color="transparent"
    flat
    tile
  >
    <v-img width="100%" :src="src" :aspect-ratio="280/250"/>
    <v-card-text class="pl-0">
      <h6 class="hardware-title">{{ name }}</h6>
      <p class="hardware-description">{{ description }} </p>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: "HardwareCard",
  props: {
    src: {
      type: String,
      default:
          require("../../../assets/images/placeholders/placeholder-0.png")
    },
    name: {
      type: String,
    },
    description: {
      type: String,
    },
  }
}
</script>

<style lang="scss" scoped>

.hardware-card {
  .hardware-title {
    font-family: Sk-Modernist, sans-serif !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 150% !important;
    letter-spacing: 0.02em !important;
    color: #000000 !important;
  }

  .hardware-description {
    font-family: Sk-Modernist, sans-serif !important;
    font-size: 16px  !important;
    line-height: 150% !important;
    letter-spacing: 0.02em !important;
    color: #000000 !important;
  }

  img {
    max-width: unset;
    max-height: unset;
    @media (--t) {
      max-width: 280px;
      max-height: 250px;
    }
  }

}


h6 {
  font-weight: bold;
}
</style>
