<template>
  <div class="product-paper-table-wrapper">
    <div class="d-flex justify-center mb-2" v-if="smAndDown">
      <PriceRange :value="priceRange" />
    </div>
    <table>
      <tbody>
      <template v-for="(prop, index) in productProperties">
        <tr>
          <td>{{ headers[index] }}</td>
          <td>:</td>
          <td>
            <div class="d-flex justify-space-between">
              <span class="prop-value">{{ prop.text }}</span>
              <PriceRange :value="priceRange" v-if="index === 0 && !smAndDown"/>
            </div>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import PriceRange from '@/components/ui/PriceRange'
export default {
  name: 'ProductPaperPropertyTable',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    priceRange: {
      type: Number,
      default: () => 1
    }
  },
  computed: {
    headers() {
      return [
        this.$t("paper.paper-tab.one-title"),
        this.$t("paper.paper-tab.two-title"),
        this.$t("paper.paper-tab.three-title"),
        this.$t("paper.paper-tab.four-title")
      ]
    },
    productProperties() {
      return this.value
    }
  },
  components: {
    PriceRange
  }
}
</script>

<style scoped lang="scss">
.product-paper-table-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  @media(--t) {
    width: 470px;

  }
  table {
    width: 100%;
    td {
      font-size: 12px;
      line-height: 1.68;
      @media (--t) {
        font-size: 18px;
        &:first-child {
          width: 160px;
        }
        .prop-value {
          min-width: 200px;
          margin-right: 16px;
        }
      }
      vertical-align: text-top;
      letter-spacing: 0.02em;
      color: #000000;
      .price-range-item {
        display: block;
        font-size: 18px;
      }
      &:first-child {
        font-weight: bold;
        padding-right: 4px;
      }
      &:last-child {
        padding-left: 16px;
        color: #393939;
      }
    }
  }
}
</style>
