import { render, staticRenderFns } from "./FaqProductsPrint.vue?vue&type=template&id=5c3b7a01&scoped=true&"
import script from "./FaqProductsPrint.vue?vue&type=script&lang=js&"
export * from "./FaqProductsPrint.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c3b7a01",
  null
  
)

export default component.exports