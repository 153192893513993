<template>
  <svg :width="width" :height="height" v-bind="$attrs" :viewBox="`0 0 24 24`" :fill="fill" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.4893 2.63978C3.63818 2.25424 4.00889 2 4.42217 2H19.6164C20.0296 2 20.4003 2.25418 20.5492 2.63965L22.0505 6.5258L22.0505 6.52582C22.6825 8.16186 22.1687 9.8696 21.017 10.9336C21.0185 10.9556 21.0192 10.9777 21.0192 11V21C21.0192 21.5523 20.5715 22 20.0192 22H4.01921C3.46693 22 3.01921 21.5523 3.01921 21V11C3.01921 10.9781 3.01992 10.9563 3.02131 10.9347C1.86981 9.87108 1.35627 8.16366 1.98784 6.52808L1.98784 6.52808L3.4893 2.63978ZM5.01921 11.9341V20H19.0192V11.9338C18.7814 11.9772 18.5353 12 18.2823 12C17.0879 12 16.011 11.4758 15.2747 10.6434C14.5417 11.4752 13.4684 12 12.2726 12H11.7634C10.5676 12 9.49436 11.4753 8.76137 10.6436C8.02525 11.4758 6.94853 12 5.75416 12C5.50185 12 5.25636 11.9773 5.01921 11.9341ZM9.76335 8C9.76335 9.10457 10.6588 10 11.7634 10H12.2726C13.3771 10 14.2726 9.10457 14.2726 8C14.2726 7.44772 14.7203 7 15.2726 7C15.8248 7 16.2726 7.44772 16.2726 8C16.2726 9.10035 17.1735 10 18.2823 10C19.7114 10 20.6945 8.5659 20.1848 7.2465C20.1848 7.2465 20.1848 7.24649 20.1848 7.24648L18.9307 4H5.10799L3.85358 7.24852C3.85358 7.24852 3.85358 7.24852 3.85358 7.24852C3.34431 8.5674 4.32648 10 5.75416 10C6.86271 10 7.76335 9.10058 7.76335 8C7.76335 7.44772 8.21107 7 8.76335 7C9.31564 7 9.76335 7.44772 9.76335 8Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: "ShopSvg",
  props: {
    width: {
      type: [String, Number],
      default: () => 24
    },
    height: {
      type: [String, Number],
      default: () => 24
    },
    fill: {
      type: [String],
      default: () => '#656565'
    }
  },
}
</script>

<style scoped>

</style>
