<template>
  <!--  v-if="hashChanged && isProductionEnv"-->
  <div v-if="hashChanged && isProductionEnv">
    <div v-if="!smAndDown" class="refresh-container">
      <div class="notification-header">
        <div class="text-center">
          <IconLogoDesktop style="cursor: default" class="nav-logo-desktop"/>
        </div>
      </div>
      <div class="notification-body mt-3">
        <div class="notification-button">
          <p class="text-center font12" v-html="$t('notification.version.update_available')"></p>
          <p class="text-center"><span
              class="font10 text-danger font-weight-bold">{{ $t('notification.version.not_updating_warning') }}</span>
          </p>
        </div>
        <div class="refresh-button text-center">
          <v-btn
              tile
              color="secondary"
              @click="reloadApp"
          >
            {{ $t("general.update") }}
          </v-btn>
        </div>
      </div>
    </div>
    <div v-else class="refresh-container-mobile">
      <div class="notification-header">
        <div class="text-center">
          <IconLogoMobile style="cursor: default" class="nav-logo-desktop"/>
        </div>
      </div>
      <div class="notification-body mt-3">
        <div class="notification-button">
          <p class="text-center font12" v-html="$t('notification.version.update_available')"></p>
          <p class="text-center"><span
              class="font10 text-danger font-weight-bold">{{ $t('notification.version.not_updating_warning') }}</span>
          </p>
        </div>
        <div class="refresh-button text-center">
          <v-btn
              tile
              color="secondary"
              @click="reloadApp"
          >
            {{ $t("general.update") }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {versionMixin} from '@/mixins/versionMixin';
import IconLogoDesktop from "@/assets/svg-icons/logo/logo-10.svg";
import IconLogoMobile from "@/assets/svg-icons/logo/logo-3.svg";

export default {
  name: "VersionModal",
  components: {IconLogoDesktop, IconLogoMobile},
  mixins: [versionMixin],
};
</script>

<style scoped lang="scss">

.refresh-container {
  width: 16%;
  position: fixed;
  bottom: 10px;
  right: 15px;
  background-color: #fff;
  padding: 25px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);

  .refresh-button {
    cursor: pointer;
  }
}

.refresh-container-mobile {
  width: 50%;
  position: fixed;
  bottom: 10px;
  right: 15px;
  background-color: #fff;
  padding: 25px;
  z-index: 99999999;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  font-size: 12px;

  .refresh-button {
    cursor: pointer;
  }
}


</style>
