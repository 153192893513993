<template>
  <svg :width="width" :height="height" v-bind="$attrs" :viewBox="`0 0 41 40`" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0787 3.06916C10.3952 2.70747 10.8524 2.5 11.333 2.5H29.6664C30.147 2.5 30.6042 2.70747 30.9207 3.06916L36.754 9.73583C37.1127 10.1458 37.2346 10.682 37.1306 11.177C37.1542 11.3368 37.1664 11.5003 37.1664 11.6667V33.3333C37.1664 35.1743 35.674 36.6667 33.833 36.6667H7.16636C5.32542 36.6667 3.83303 35.1743 3.83303 33.3333V11.6667C3.83303 11.5003 3.84522 11.3368 3.86875 11.177C3.76484 10.682 3.88667 10.1458 4.2454 9.73583L10.0787 3.06916ZM9.90181 8.33333H31.0976L28.9101 5.83333H12.0893L9.90181 8.33333ZM33.833 11.6667H7.16636V33.3333H33.833V11.6667ZM13.7912 20.0069C13.7912 19.0864 14.5374 18.3403 15.4579 18.3403H25.4579C26.3783 18.3403 27.1245 19.0864 27.1245 20.0069C27.1245 20.9274 26.3783 21.6736 25.4579 21.6736H15.4579C14.5374 21.6736 13.7912 20.9274 13.7912 20.0069Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: "ShopSvg",
  props: {
    width: {
      type: [String, Number],
      default: () => 24
    },
    height: {
      type: [String, Number],
      default: () => 24
    },
    fill: {
      type: [String],
      default: () => '#656565'
    }
  },
}
</script>

<style scoped>

</style>
