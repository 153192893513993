import axios from "axios";
import { baseURL } from "./config";
// const baseURL = "https://core-test.puresol.io"
// const baseURL = "https://puresol-api-st-temp.cap.puresol.io"

let axiosInstance = axios.create({
  baseURL: `${baseURL}/public/api/v1/karavan/`,
  headers: { "Content-Type": "application/json" },
});

let publicAcademyAxios = axios.create({
  baseURL: `${baseURL}/public/api/v1/academy`,
  headers: { "Content-Type": "application/json" },
})

publicAcademyAxios.interceptors.request.use((config) => {
  const currentLang = localStorage.getItem('lang');
  const requestURL = config["url"];
  config["url"] = `${requestURL}?lng=${currentLang}`
  if(requestURL.includes("?")){
    config["url"] = `${requestURL}&lng=${currentLang}`
  }

  return config;
});

axiosInstance.interceptors.request.use((config) => {
  config.headers["karavan-user-tknk"] = localStorage.access_token;
  config.headers["karavan-store-tknk"] =
    "eyJhbGciOiJIUzI1NiJ9.eyJrYXJhdmFuX3N0b3JlX2lkIjoiNWRkODA0OTBiZmNkYjYwYmEwOGU3Y2JjIiwiY2xpZW50X2lkIjoiNWRkNDY2YTliZmNkYjYwYzI1OWI3NzgzIiwiY3JlYXRlZF9kYXRlIjoxNTc0NDM4MDMyLCJleHAiOjg0MjU1NzQ0MzgwMzJ9.sHiH2RrSJ6O1LWUlQAeqKBTu8-VwVcdYO0iu03JHydA";
  config.headers["app"] = "karavan";

  const currentLang = localStorage.getItem('lang');
  const requestURL = config["url"];
  config["url"] = `${requestURL}?lng=${currentLang}`
  if(requestURL.includes("?")){
    config["url"] = `${requestURL}&lng=${currentLang}`
  }

  return config;
});

let blankAxios = axios.create({
  baseURL:
    `${baseURL}/public/api/v1/client/blankspace/products/`,
  headers: { "Content-Type": "application/json" },
});

if (localStorage.access_token) {
  blankAxios.defaults.headers.common["karavan-user-tknk"] =
    localStorage.access_token;
}
blankAxios.defaults.headers.common["karavan-user-tknk"] =
  localStorage.access_token;
blankAxios.defaults.headers.common["karavan-store-tknk"] =
  "eyJhbGciOiJIUzI1NiJ9.eyJrYXJhdmFuX3N0b3JlX2lkIjoiNWRkODA0OTBiZmNkYjYwYmEwOGU3Y2JjIiwiY2xpZW50X2lkIjoiNWRkNDY2YTliZmNkYjYwYzI1OWI3NzgzIiwiY3JlYXRlZF9kYXRlIjoxNTc0NDM4MDMyLCJleHAiOjg0MjU1NzQ0MzgwMzJ9.sHiH2RrSJ6O1LWUlQAeqKBTu8-VwVcdYO0iu03JHydA";
blankAxios.defaults.headers.common["app"] = "karavan";
// blankAxios.defaults.headers.common['lang'] = localStorage.getItem('lang');


blankAxios.interceptors.request.use((config) => {
  const currentLang = localStorage.getItem('lang');
  const requestURL = config["url"];
  config["url"] = `${requestURL}?lng=${currentLang}`
  if(requestURL.includes("?")){
    config["url"] = `${requestURL}&lng=${currentLang}`
  }

  return config;
});


export { axiosInstance, blankAxios, publicAcademyAxios };
