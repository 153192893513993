<template>
  <div class="processing-loader flex flex-center">
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="background: none; display: block; shape-rendering: auto;"
        width="60px"
        height="60px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
    >
      <g>
        <path d="M10 50A40 40 0 0 0 90 50A40 47.2 0 0 1 10 50" fill="#212529" stroke="none">
          <animateTransform
              attributeName="transform"
              type="rotate"
              dur="0.7246376811594202s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 50 53.6;360 50 53.6"
          ></animateTransform>
        </path>
        <text v-if="percentage_loader.usePercentage"
              x="50" y="60"
              style="font-size:20px; font-weight: bold; text-anchor: middle">
          {{ percentage_loader.percentage }}%
        </text>
      </g>
    </svg>
    <span v-if="loaderText" class="processing-text" v-html="$t(`placeholder.${loaderText}`)"></span>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import imageLoading from "./imageLoading.vue";

export default {
  name: "Loader",
  components: {imageLoading},
  computed: {
    ...mapGetters({
      percentage_loader : "percentage_loader"
    }),
    loaderText() {
      if (this.percentage_loader.uploading) return "uploading"
      else if (this.percentage_loader.processing) return "processing"
      else return false
    },
  }
};
</script>

<style lang="scss">
.processing-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffda;
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  overflow: hidden !important;
}

.processing-text {
  animation: fadeIn 2s;
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
