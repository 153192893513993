<template>
  <div class="tool-card">
    <button @click="onRight" class="tool-button"
            :title="$t('tools.right')" v-b-tooltip.hover.left>
      <IconRotateRight/>
    </button>

    <button @click="onLeft"
            class="tool-button"
            :title="$t('tools.left')"
            v-b-tooltip.hover.left>
      <IconRotateLeft/>
    </button>

    <button
        @click="flipY"
        class="tool-button"
        :title="$t('tools.horizontal')"
        v-b-tooltip.hover.left>
      <IconFlipHorizontal/>
    </button>

    <button
        @click="flipX"
        class="tool-button"
        :title="$t('tools.vertical')"
        v-b-tooltip.hover.left>
      <IconFlipVertical/>
    </button>

    <button
        @click="zoomIn"
        class="tool-button"
        :title="$t('tools.in')"
        v-b-tooltip.hover.left>
      <IconZoomIn/>
    </button>

    <button
        @click="zoomOut"
        class="tool-button"
        :title="$t('tools.out')"
        v-b-tooltip.hover.left>
      <IconZoomOut/>
    </button>

    <button
        @click="onDelete"
        class="tool-button"
        :title="$t('tools.clear')"
        v-b-tooltip.hover.left>
      <IconDelete/>
    </button>
  </div>
</template>

<script>
import {
  IconCartNew,
  IconDelete,
  IconEdgeMini,
  IconFlipHorizontal,
  IconFlipVertical,
  IconGridDefault,
  IconGridHorizontal,
  IconGridVertical,
  IconGridWave,
  IconPrintMini,
  IconRotateLeft,
  IconRotateRight,
  IconSave,
  IconSizeMini,
  IconUploadMini,
  IconX,
  IconZoomIn,
  IconZoomOut,
} from "@/assets/svg-icons";

export default {
  name: "ToolCard",
  components: {
    IconDelete,
    IconEdgeMini,
    IconFlipHorizontal,
    IconFlipVertical,
    IconRotateLeft,
    IconRotateRight,
    IconZoomIn,
    IconZoomOut,
  },
  methods: {
    onRight(e) {
      this.$emit('onRight', e)
    },
    onLeft(e) {
      this.$emit('onLeft', e)
    },
    flipY(e) {
      this.$emit('flipY', e)
    },
    flipX(e) {
      this.$emit('flipX', e)
    },
    zoomIn(e) {
      this.$emit('zoomIn', e)
    },
    zoomOut(e) {
      this.$emit('zoomOut', e)
    },
    onDelete(e) {
      this.$emit('onDelete', e)
    },
  }
}
</script>

<style lang="postcss" scoped>
.tool-card {
  display: none;
  @media (--t) {
    display: flex;
    position: fixed;
    left: 3%;
    top: 25%;
    flex-direction: column;
    margin-right: 30px;
    border: 3px solid #000000;
    align-items: center;
    background: transparent;
  }


  .tool-button {
    padding: 20px;
    background: none;
    border: 0;
    outline: none;

    &:hover {
      background-color: #F9F9F9;
    }
  }

}
</style>