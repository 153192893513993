<template>
  <div class="paper-type">


    <Heading :title="$t('paper.satin.title')" :subtitle="$t('paper.satin.subtitle')"/>

    <PhotoPaperSection
      :feature_five="$t('paper.satin.specified.five')"
      :feature_four="$t('paper.satin.specified.four')"
      :feature_one="$t('paper.satin.specified.one')"
      :feature_six="$t('paper.satin.specified.six')"
      :feature_three="$t('paper.satin.specified.three')"
      :feature_two="$t('paper.satin.specified.two')"
      :images="images"
      :subtitle="$t('paper.satin.description')"
      :title="$t('paper.satin.about')"
    />

    <PricingNew :vertical-sizes="[5, 59]"/>

    <PaperTab/>

    <UsagePlaces
      :feature_four="$t('paper.satin.usage.features.feature_four')"
      :feature_five="$t('paper.satin.usage.features.feature_five')"
      :feature_six="$t('paper.satin.usage.features.feature_six')"
      :feature_one="$t('paper.satin.usage.features.feature_one')"
      :feature_three="$t('paper.satin.usage.features.feature_three')"
      :feature_two="$t('paper.satin.usage.features.feature_two')"

      :handcrafted_description="$t('paper.satin.usage.handcrafted.description')"
      :handcrafted_moments="$t('paper.satin.usage.handcrafted.moments')"
      :handcrafted_team="$t('paper.satin.usage.handcrafted.team')"
      :handcrafted_title="$t('paper.satin.usage.handcrafted.title')"

      :left_image="satin_image_one"
      :right_image="satin_image_two"

      :vibrant_footer="$t('paper.satin.usage.inks.footer')"
      :vibrant_description="$t('paper.satin.usage.inks.description')"
      :vibrant_title="$t('paper.satin.usage.inks.title')"
    />

    <ProductReviewSection/>

    <WorthSection/>


  </div>
</template>

<script>

import PhotoPaperSection from '@/components/section/paper-info-section'
import UsagePlaces from '@/components/section/example-usage-section'
import WorthSection from '@/components/section/stories-worth-section'
import PricingNew from '@/components/cards/pricing-card-new'
import PaperTab from '@/components/tab/paper-tab/PaperTab'
import ProductReviewSection from '../../components/product-review/product-review-section'


export default {
  name: 'Satin',
  metaInfo() {
    return {
      title: this.$t('titles.satin'),
      titleTemplate: '%s - Blankspace',
       htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.$t('titles.satin-content')},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  components: {
    ProductReviewSection,
    PaperTab,
    PricingNew,
    WorthSection,
    UsagePlaces,
    PhotoPaperSection,
  },

  data() {
    return {
      satin_image_one: require('../../assets/images/usage-new/11.png'),
      satin_image_two: require('../../assets/images/usage-new/10.png'),
      images: [
        {id: 1, src: '/img/papers/opt/satin-0-opt.jpg'},
        {id: 2, src: '/img/papers/opt/satin-1-opt.jpg'}
      ],
    }
  }
}
</script>

<style scoped>


.paper-type {
  padding-top: 35px;
  overflow-x: hidden !important;
  @media (--t) {
    padding-top: 65px;
  }
}

h2 {
  letter-spacing: 0.15em;
  @media (--t) {
    margin-top: 10px;
  }
}
</style>
