<template>
  <div class="acrylic-page">
    <Heading :title="$t('products.acrylic.title')" :subtitle="$t('products.acrylic.subtitle')"/>

    <PhotoPaperSection
        :feature_five="$t('products.acrylic.specified.five')"
        :feature_four="$t('products.acrylic.specified.four')"
        :feature_one="$t('products.acrylic.specified.one')"
        :feature_three="$t('products.acrylic.specified.three')"
        :feature_two="$t('products.acrylic.specified.two')"
        :images="images"
        :subtitle="$t('products.acrylic.description')"
        :title="$t('products.acrylic.about')"
    />


    <ProductVideoSection src="/video/acrylics.mp4" />

    <PricingNew style="background: #F9F8F7;"/>

    <MaterialCard
        class="list-dot"
        :feature_four="$t('products.acrylic.details.four')"
        :feature_one="$t('printing-acrylic.one')"
        :feature_three="$t('printing-acrylic.three')"
        :feature_two="$t('printing-acrylic.two')"
        :src="acrylic_image"
        :subtitle="$t('printing-acrylic.subtitle')"
        :title="$t('printing-acrylic.title')"
    />

    <HardwareSection/>

    <LayoutTab
        :horizontal_image="canvas_layout_three"
        :rectangle_image="canvas_layout_one"
        :vertical_image="canvas_layout_two"
        :wave_image="canvas_layout_four"
    />


    <UsagePlaces
        :vibrant_description="$t('products.acrylic.usage.inks.description')"
        :vibrant_title="$t('products.acrylic.usage.inks.title')"
        :handcrafted_title="$t('products.acrylic.usage.handcrafted.title')"
        :handcrafted_description="$t('products.acrylic.usage.handcrafted.description')"
        :handcrafted_team="$t('products.acrylic.usage.handcrafted.team')"
        :handcrafted_moments="$t('products.acrylic.usage.handcrafted.moments')"
        :feature_four="$t('products.acrylic.usage.features.feature_four')"
        :feature_one="$t('products.acrylic.usage.features.feature_one')"
        :feature_three="$t('products.acrylic.usage.features.feature_three')"
        :feature_two="$t('products.acrylic.usage.features.feature_two')"
        :left_image="acrylic_image_one"
        :right_image="acrylic_image_two"
    />


    <ProductReviewSection/>
    <WorthSection/>

  </div>
</template>

<script>
import PhotoPaperSection from "@/components/section/paper-info-section";
import CustomText from "@/components/shared/CustomText";
import LayoutTab from "@/components/tab/layout-tab/LayoutTab";
import MaterialCard from "@/components/cards/shared-card/material-card";

import HardwareSection from "@/components/section/hardware-section";
import UsagePlaces from "@/components/section/example-usage-section";
import WorthSection from "@/components/section/stories-worth-section";
import PricingNew from "@/components/cards/pricing-card-new";
import ProductReviewSection from "../../components/product-review/product-review-section";

export default {
  name: "ProductDetail",
  metaInfo() {
    return {
      title: this.$t('titles.acrylic'),
      titleTemplate: '%s - Blankspace',
       htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.$t('titles.acrylic-content') },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ]
    }
  },
  data() {
    return {
      acrylic_image: require('../../assets/new-mat.png'),
      acrylic_image_one: '/img/papers/12.png',
      acrylic_image_two: '/img/papers/14.webp',

      canvas_layout_one: require('../../assets/images/grid/acrylic/rectangle.webp'),
      canvas_layout_two: require('../../assets/images/grid/acrylic/vertical.webp'),
      canvas_layout_three: require('../../assets/images/grid/acrylic/horizontal.webp'),
      canvas_layout_four: require('../../assets/images/grid/acrylic/wave.webp'),

      images: [
        {id: 2, src: '/img/papers/opt/acrylic-1-opt.jpg'},
        {id: 1, src: '/img/papers/opt/acrylic-0-opt.jpg'}
      ],
    }
  },

  components: {
    ProductReviewSection,
    PricingNew,
    WorthSection,
    UsagePlaces,
    HardwareSection,
    MaterialCard,
    LayoutTab,
    CustomText,
    PhotoPaperSection,
  }
}
</script>

<style lang="postcss">


.acrylic-page {
  padding-top: 35px;
  overflow-x: hidden !important;
  @media (--t) {
    padding-top: 65px;
  }

  .list-dot {
    li {
      list-style: outside !important;
    }
  }

}


</style>
