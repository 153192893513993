<template>
  <v-container class="auth">
    <form class="mx-auto" @submit.stop.prevent="onSubmit">
      <h1 class="small-title mb-4">{{ $t('buttons.update-password-title') }}</h1>
      <template
          v-for="field in formFields"
      >
        <component
            :loading="loading"
            :is="field.comp"
            :key="field.name"
            :ref="field.name"
            :label="field.label"
            v-model="userData[field.name]"
            :append-icon="field.icon"
            data-vv-validate-on="blur lazy"
            :error-messages="errors.collect(field.name)"
            v-validate="field.validation"
            :data-vv-name="field.name"
            :data-vv-as="field.label"
            @input="validateField(field.name)"
        />
      </template>
      <AppButton
          block
          color="primary"
          :loading="loading"
          @click="validateAndSubmit"
          large
      >
        {{ $t('buttons.update') }}
      </AppButton>

      <div class="documents">
        <router-link to="/create-account">
          <p class="page-subtitle">{{ $t('buttons.create') }}</p>
        </router-link>
      </div>
    </form>
  </v-container>
</template>

<script>
import {authPagesMixins} from '@/mixins/authPagesMixins'
import {pick} from "lodash";

export default {
  name: 'UpdatePassword',
  mixins: [authPagesMixins],
  data() {
    return {
      userData: {
        karavan_user_ps_tknk: '',
        password: '',
        password_confirmation: ''
      },
    }
  },
  mounted() {
    if (!this.$route.query.id) {
      this.$router.push({name: "Home"})
    } else {
      this.userData.karavan_user_ps_tknk = this.$route.query.id;
    }
  },
  methods: {
    onSubmit() {
      this.loading = true
      const userData = {
        karavan_user: {
          ...pick(this.userData, ["password", "password_confirmation"]),
        },
        karavan_user_ps_tknk: this.userData.karavan_user_ps_tknk
      }
      this.$store.dispatch('updateForgottenPassword', userData)
          .then(res => {
            this.createSuccessNotification(this.$t('notify.password_updated'))
            this.$router.push({name: "SignIn"})
          })
          .catch(err => {
            if (err.response.data.error === 'invalid_token') {
              this.createErrorNotification(this.$t('notify.invalid_activation'))
            } else if (err.response.data.error === 'expired_token') {
              this.createErrorNotification(this.$t('notify.invalid_activation'))
              this.$router.push({path: '/lost-password'})
            }
          })
          .then(() => {
            this.loading = false
          })
    },
  },
  computed: {
    formFields() {
      return [
        {
          comp: 'AppPasswordInput',
          icon: '',
          label: this.$t('placeholder.new_password'),
          name: 'password',
          validation: 'required|min:6|max:255'
        },
        {
          comp: 'AppPasswordInput',
          icon: '',
          label: this.$t('placeholder.confirm_password'),
          name: 'password_confirmation',
          validation: 'required|min:6|max:255|confirmed:password'
        },
      ]
    }
  }
}
</script>

<style lang="postcss" scoped>

.auth {
  margin-top: 11%;
  min-height: 100vh;
  @media (--t) {
    min-height: 60vh;
  }

  form {
    @media (--t) {
      max-width: 410px;
    }

    .documents {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 15px;
      @media (--t) {
        max-width: 410px;
      }

      p {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

</style>
