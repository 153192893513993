<template>
  <div class="inspire-card">
    <div>
      <img :src="src"  alt="">
    </div>
    <div class="inspire-text">
      <h6 class="person-info mb-4">
        <strong>{{ name }}, {{ title }}</strong>
        <span style="color: #077D60">
            {{ social }}
          </span>
      </h6>
      <p>{{ comment_one }}</p>
      <p>{{ comment_two }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InspiredCustomerCard',
  data() {
    return {
      person: require('../../../assets/images/inspires/inspire-0.webp')
    }
  },
  props: {
    name: {
      type: String,
    },
    social: {
      type: String,
    },
    comment_one: {
      type: String,
    },
    comment_two: {
      type: String
    },
    src: {
      type: String
    },
    title: {
      type: String,
    }
  }
}
</script>

<style lang="scss" scoped>

.inspire-card {
  img {
    object-fit: cover;
    max-width: 179px;
    height: 223px;


  }
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  /*margin-bottom: 20px;*/

  @media (--t) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 828px;
  }

  .inspire-text {
    margin-top: 15px;
    max-width: 300px;
    text-align: center;
    @media (--t) {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-left: 50px;
      max-width: 100%;
      margin-top: 36px;
      text-align: left;
    }

    .person-info {
      font-family: Sk-Modernist, 'sans-serif' !important;
      font-size: 17px !important;
      line-height: 20px !important;
      letter-spacing: 0.02em !important;
      color: #000000 !important;
    }

    p {
      font-family: Sk-Modernist, 'sans-serif';
      text-align: center !important;
      font-size: 18px !important;
      line-height: 20px !important;
      letter-spacing: 0.02em !important;
      margin-top: 30px !important;
      @media (--t) {
        text-align: left !important;
        margin-top: 0 !important;
      }

    }

  }

}

</style>
