<template>
  <div class="d-flex">
    <span
      v-for="i in 4"
      :key="i"
      :style="{color: (i <= priceRange ? '#009771' : '#818181' )}"
      class="price-range-item"
    >
      $
    </span>
  </div>
</template>

<script>
export default {
  name: 'PriceRange',
  props: {
    value: {
      type: Number,
      default: () => 0
    }
  },

  computed: {
    priceRange() {
      return this.value
    }
  }
}
</script>

<style scoped>

</style>
