<template>
  <v-card :to="{ name : 'BlogDetail', params : { slug : blog.permalink}}" color="transparent" flat>
    <div class="d-flex flex-wrap">
      <div>
        <v-img
            :aspect-ratio="(130/120)"
            :src="blog.cover_image"
            width="130"
        />
      </div>
      <div class="blog-content">
        <h3 class="page-subtitle text-left font-weight-bold mt-3">{{ blog.title }}</h3>
        <p class="page-subtitle text-left" v-if="blog.description">
          {{ blog.description }}
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "BlogItem",
  props: {
    blog: {
      type: Object,
      required: true,
      default: () => ({})
    },
  }
}
</script>
<style scoped lang="scss">
.v-card {
  .blog-content {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 130px);
    padding: 16px;
    justify-content: center;
    height: 100%;

    h3 {
      letter-spacing: .02em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
