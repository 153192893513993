<template>
  <v-container class="layout-select">

    <div class="d-flex flex-wrap" ref="tabWrapper">
      <div class="d-flex flex-column justify-center pl-lg-6">
        <h3 class="page-title text-left d-inline-flex">
          <slot name="title"></slot>
        </h3>
        <h4 class="page-subtitle text-left mb-lg-15 d-inline-flex">
          <slot name="subTitle"></slot>
        </h4>
      </div>
      <slot></slot>
    </div>
    <!--    <div class="row">
      <v-col cols="12" class="px-md-3 px-0" ref="tabWrapper">
        <slot></slot>
      </v-col>
    </div>-->
  </v-container>

</template>

<script>
export default {
  name: 'LayoutTabsContainer',
  methods: {
    alignToBottom() {
      if (this.$vuetify.breakpoint.mdAndUp)
        return false

      setTimeout(() => {

        this.$vuetify.goTo(this.$refs.tabWrapper, {
          duration: 300,
          offset: 60,
        })
      }, 300)


    }
  }
}
</script>

<style lang="scss">
@import "src/styles/components/layout-tab";
</style>
