<template>
  <div class="order-detail">
    <v-container class="order-detail-container">
      <slot name="prepend"></slot>
      <v-row class="grid-container">
        <v-col v-if="order" class="pb-0 pt-0 pr-lg-8" lg="7" md="6" cols="12">
          <slot name="header"></slot>
          <hr class="mt-0" v-if="!$slots.header">
          <div class="order-contact">
            <h3>{{ $t('purchase.customer') }}</h3>
            <v-row justify="space-between" class="mt-0">
              <v-col class="contact-left " md="6" cols="12">
                <h4>{{ $t('purchase.contact') }}</h4>
                <span>{{ contactPerson }}</span>
              </v-col>
              <v-col class="contact-right " md="6" cols="12">
                <h4>{{ $t('purchase.payment') }}</h4>
                <div v-for="payment in appliedPayments" :key="payment.payment_id">
                  <span>{{ getPaymentAmount(payment) }}</span>
                </div>
              </v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="contact-left" md="6" cols="12">
                <h4>{{ $t('purchase.address') }}</h4>
                <div v-if="shippingAddressAvailable">
                  <span>{{
                      shippingAddress.first_name || ''
                    }} {{ shippingAddress.last_name }}</span>
                  <span>{{ shippingAddress.street1 }}</span>
                  <span>{{ shippingAddress.country }} / {{
                      shippingAddress.state
                    }}</span>
                  <span>
                  <span v-if="shippingAddress.phone_ext" class="d-inline">{{ shippingAddress.phone_ext }} </span>
                  {{ shippingAddress.phone }}
                </span>
                </div>
                <span v-else>None</span>
              </v-col>
              <v-col class="contact-right" md="6" cols="12">
                <h4>{{ $t('purchase.bill') }}</h4>
                <span>{{ billingAddress.first_name || '' }} {{ billingAddress.last_name }}</span>
                <span>{{ billingAddress.street1 }}</span>
                <span>{{ billingAddress.country }} /{{ billingAddress.state }} </span>
                <span>
                  <span v-if="billingAddress.phone_ext" class="d-inline">{{
                      billingAddress.phone_ext
                    }}</span>
                  {{ billingAddress.phone }}
                </span>
              </v-col>
            </v-row>
            <div class="mt-3">
              <h4>{{ $t('purchase.ship') }}</h4>
              <span>{{ shipmentMethod }}</span>
              <hr>
            </div>
          </div>

        </v-col>
        <v-col v-else class="pb-0 pt-0 pr-lg-8" lg="7" md="6" cols="12">
          <v-skeleton-loader
              style="margin-left: 15px;"
              type="heading"
          />
          <v-row justify="space-between" class="mt-0">
            <v-col class="contact-left" md="6" cols="12">
              <v-skeleton-loader type="article"/>
            </v-col>
            <v-col class="contact-right " md="6" cols="12">
              <v-skeleton-loader type="article"/>
            </v-col>
          </v-row>
          <v-row justify="space-between" class="mt-0">
            <v-col class="contact-left" md="6" cols="12">
              <v-skeleton-loader type="article"/>
            </v-col>
            <v-col class="contact-right " md="6" cols="12">
              <v-skeleton-loader type="article"/>
            </v-col>
          </v-row>
          <v-row justify="space-between" class="mt-0">
            <v-col class="contact-left" md="6" cols="12">
              <v-skeleton-loader type="article"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="order" class="pl-lg-8 py-0" style="max-width: 400px;">
          <div class="order-summary-section fill-height">
            <div class="order-list">
              <PurchaseShopItem
                  v-for="(item, index) in invoiceItems"
                  :key="index"
                  :product="getField(item, 'type')"
                  :gift_card_value="getField(item, 'name')"
                  :quantity="item.quantity"
                  :src="item.picture_thumbnail || null"
                  :total="item.total_price"
                  :layout="getField(item, 'layout')"
                  :frame="getField(item, 'frame')"
                  :width="getField(item, 'width')"
                  :height="getField(item, 'height')"
              />
            </div>
            <div class="order-footer mt-5">
              <div>
                <v-row>
                  <v-col class="order-title-section my-0 py-0">
                    <span class="medium">{{ $t("cart.subtotal") }}</span>
                    <span v-if="samplePackAvailable" class="medium">{{ $t("cart.sample_pack_kit") }}</span>
                  </v-col>

                  <v-col class="order-value-section my-0 py-0">
                    <span class="medium">$ {{ subTotal.toFixed(2) }}</span>
                    <span v-if="samplePackAvailable" class="medium">$ {{ samplePack.total_price.toFixed(2) }}</span>
                  </v-col>
                </v-row>

                <v-row v-if="discountAvailable">
                  <v-col class="order-title-section my-0 py-0">
                    <span class="medium">{{ $t("cart.discount") }}</span>
                  </v-col>
                  <v-col class="order-value-section my-0 py-0">
                    <span class="medium error--text">- $ {{ totalDiscountAmount.toFixed(2) }}</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="order-title-section my-0 py-0">
                    <span class="medium" v-if="shippingPrice">{{ $t("cart.shipment") }}</span>
                    <span v-for="(taxItem, index) in appliedTaxes" :key="taxItem.id" class="medium">
                      {{ `${taxItem.rate_name} (${taxItem.rate_value}%)` }}
                    </span>
                  </v-col>

                  <v-col class="order-value-section my-0 py-0">
                    <span class="medium" v-if="shippingPrice">$ {{ shippingPrice.toFixed(2) }}</span>
                    <span v-for="(taxItem, index) in appliedTaxes" :key="taxItem.id"
                          class="medium"> {{ `$ ${taxItem.amount}` }} </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="order-title-section">
                    <span class="medium">{{ $t("cart.grand_total") }}</span>
                  </v-col>
                  <v-col class="order-value-section">
                    <span class="medium">$ {{ totalAmount.toFixed(2) }}</span>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
        <v-col v-else class="pl-lg-8 py-0" style="max-width: 400px;">
          <div class="order-summary-section fill-height">
            <div class="order-list">
              <v-skeleton-loader v-for="i in 3" :key="i" type="list-item-avatar"/>
            </div>
            <div>
              <div>
                <v-row>
                  <v-col>
                    <v-skeleton-loader v-for="i in 5" :key="i" class="mb-2" type="text"/>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="grid-container">
        <v-col class="py-0 pr-lg-8" lg="7" cols="12">
          <div class="d-flex flex-wrap justify-lg-space-between">
            <div>
              <AppButton
                  :to="{name: 'Orders'}"
                  color="primary"
                  :large="!smAndDown"
                  class="d-flex justify-content-center align-content-center"
              >
                {{ $t('buttons.orders') }}
              </AppButton>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {cloneDeep, get, isArray, isObject, pick} from "lodash";
import {axiosInstance} from "@/utils/setAxios";
import {SAMPLE_PACK_PRODUCT_ID} from "@/utils/config"

export default {
  name: 'PrevOrderPreview',
  data() {
    return {
      order: null,
      get_cart_without_sample_kit: [],
      cartTotalAmount: 0,
      hasSampleKit: false,
      getSampleKit: null,
      discountAmount: 0,
      appliedGiftCardsAmount: 0,
      shipment_method: null,
      _applied_taxes: [],
      isLoad: false,
    }
  },
  methods: {
    getField(item, fieldName) {
      if (fieldName === "type" && item.name?.includes("Gift Card $")) return "gift_card"
      if (fieldName === "name") return item?.inventory_product_hash?.var?.name
      return item?.inventory_product_hash?.var?.[`prod_${fieldName}`]
    },
    getPaymentAmount(payment) {
      const {payment_amount, credit_card_info, payment_method} = payment
      if (payment_method === 'Credit Card') {
        return `${this.$t('ending_with')} ${credit_card_info?.last_four_digits} - $${payment_amount?.toFixed(2)}`
      } else if (payment_method === 'Gift Card') {
        return `${this.$t('cart.gift')} - $${payment_amount?.toFixed(2)}`
      }
    }
  },
  computed: {
    shipmentMethod() {
      const {karavan_shipment_service_items} = this.invoice;
      const selectedService = karavan_shipment_service_items?.find(s => s.selected)
      let shipmentMethod = "None";
      if (selectedService) {
        shipmentMethod = selectedService?.service_name
      }
      return shipmentMethod;
    },
    shippingAddressAvailable() {
      const {contact_shipping_address} = this.invoice
      return isObject(contact_shipping_address) && !!Object.keys(contact_shipping_address)?.length
    },
    invoice() {
      return this.order?.invoice || null
    },
    invoiceItems() {
      return this.order?.invoice_items?.filter(i => i?.inventory_product_hash?.id !== SAMPLE_PACK_PRODUCT_ID) || [];
    },
    shipmentItems() {
      return this.order?.shipment_items || [];
    },
    shippingAddress() {
      return this.invoice?.contact_shipping_address
    },
    billingAddress() {
      return this.invoice?.contact_billing_address
    },
    appliedPayments() {
      return get(this.invoice, "proforma_payments") || []
    },
    onlyGiftCardPayment() {
      const paymentList = get(this.invoice, "proforma_payments") || [];
      return paymentList?.every(payment => payment.payment_method === 'Gift Card')
    },
    totalAmount() {
      return this.invoice?.total_amount || 0
    },
    totalDiscountAmount() {
      return this.invoice?.total_discount
    },
    discountAvailable() {
      return this.invoice?.total_discount > 0
    },
    contactPerson() {
      return this.invoice?.contact_person?.email || this.invoice?.contact_name
    },
    appliedTaxes() {
      return this.invoice?.applied_taxes || []
    },
    shippingPrice() {
      const shippingPrice = this.shipmentItems?.find(s => s.id)
      return shippingPrice?.total_price || 0
    },
    subTotal() {
      return this.invoiceItems?.reduce((total, i) => total + i?.total_price, 0)
    },
    orderAvailable() {
      return isObject(this.order) && this?.order?.invoice?.id
    },
    samplePack() {
      return this.order?.invoice_items?.find(item => item?.inventory_product_hash?.id === SAMPLE_PACK_PRODUCT_ID)
    },
    samplePackAvailable(){
      return Boolean(this.samplePack)
    }
  },
  mounted() {
    axiosInstance.get('orders/' + this.$route.params.id).then(response => {
      setTimeout(() => {
        this.order = pick(response?.data, ["invoice", "invoice_items", "shipment_items"]);
      }, 300)
    })
  },
}
</script>

<style scoped lang="scss">
.order-detail {
  p {
    line-height: 150%;
    margin-bottom: 20px;
    letter-spacing: 0.02em;
    font-size: 14px;
    @media (--t) {
      font-size: 20px;
    }
  }

  hr {
    height: 1px;
    background: #E8E8E8;
    border: 0;
    margin: 20px 0;

    &:last-child {
      margin: 44px 0 0;
    }
  }

  h1, h2 {
    font-family: var(--font-family--alethia);
    line-height: 1;
    color: #000000;
    margin-bottom: 8px;
  }

  h1 {
    font-size: 30px;
    @media (--t) {
      font-size: 48px;
    }
  }

  h2 {
    font-size: 24px;
    @media (--t) {
      font-size: 36px;
    }
  }


  //overflow-y: hidden !important;
  //overflow-x: scroll !important;
  .order-detail-container {
    padding-top: 35px;
    padding-bottom: 35px;

    @media (--t) {
      max-width: 1260px;
      width: 100vw;
      padding-bottom: 112px;
    }

    .grid-container {
      display: flex;
      //justify-content: space-between;
      margin-top: 65px;

      .order-header {

        span {
          font-size: 12px;
          letter-spacing: 0.02em;
          @media (--t) {
            font-size: 18px;
          }

        }

      }

      .order-message {

        span {
          font-size: 18px;
          line-height: 105%;
        }


      }

      .order-contact {
        h3 {
          font-family: Sk-Modernist, 'san-serif';
          font-weight: bold;
          font-size: 16px;
          margin: 10px 0;
          letter-spacing: 0.02em;
          color: #000000;
          @media (--t) {
            font-size: 20px;
            margin: 50px 0 10px;
          }
        }

        h4 {
          font-family: Sk-Modernist, 'sans-serif';
          font-weight: bold;
          font-size: 14px;
          @media (--t) {
            font-size: 18px;
          }
          line-height: 150%;
          letter-spacing: 0.02em;
          color: #000000;
          margin-bottom: 4px;
        }

        span {
          display: block;
          line-height: 1.5;
          font-family: Sk-Modernist, 'sans-serif';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          @media (--t) {
            font-size: 18px;
          }
          letter-spacing: 0.02em;
          color: #393939;
        }
      }

      .order-summary-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #E8E8E8;
        padding: 30px;
        margin-top: 50px;
        @media (--t) {
          margin-top: unset;
        }

        .order-list::v-deep .v-skeleton-loader__list-item-avatar {
          background-color: transparent !important;
        }

        .order-footer {

          .order-title-section {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
          }

          .order-value-section {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }

        }


      }


    }
  }
}

</style>
