<template>
  <v-card
      class="blog-card fill-height"
      flat
      outlined
      :color="cardColor"
      :to="to"
      width="100%"
      tile
  >
    <v-img :src="src" :aspect-ratio="aspectRatio" :alt="title"/>
    <v-card-text>
      <v-card-title class="pl-0">
        <h5 class="title-large">{{ title }}</h5>
      </v-card-title>
      <div class="author">
        <div class="author-info">
          <Avatar :src="authorAvatar" :size="42"/>
          <div>
            <p class="page-subtitle mb-0 ml-3">{{ authorName }}</p>
            <p class="author-title ml-3">{{ authorTitle }}</p>
          </div>
        </div>
        <AppButton
            :to="to"
            color="primary"
        >
          {{ $t('buttons.read') }}
        </AppButton>
      </div>

    </v-card-text>

  </v-card>

</template>

<script>

import CustomText from '@/components/shared/CustomText'
import Avatar from '@/components/shared/Avatar'

export default {
  name: 'BlogCard',
  components: {Avatar, CustomText},
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
  },

  computed: {
    to() {
      return `/blog/${this.item.permalink}`
    },
    src() {
      return this.item.cover_image || '/img/placeholders/blog.png'
    },
    title() {
      return this.item.title
    },
    authorName() {
      return this.item?.author?.name
    },
    authorTitle() {
      return this.item?.author?.title
    },
    authorAvatar() {
      return this.item?.author?.avatar
    },
    cardColor() {
      return this.$vuetify.breakpoint.mdAndUp ? '#FBFBFB' : ''
    },
    aspectRatio() {
      return (320 / 200)
    }
  }
}

</script>

<style lang="scss" scoped>
h5 {
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.blog-card {
  margin-top: 0;
  @media (--t) {
    margin-bottom: 10px;
  }

  .card-img-top {
    height: 200px;
    @media (--t) {
      height: unset;
    }
  }

  .v-card__title {
    min-height: 95px;
    align-items: unset;
  }

  img {
    border-radius: 0;
  }

  .author {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .author-info {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }

    .author-title {
      margin: 0;
      color: #aaa;
    }

    button {
      height: 45px;
      background: #000000;
      font-family: Sk-Modernist, 'sans-serif' !important;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.02em !important;
      color: #FFFFFF;
      padding: 10px 22px;
    }

  }
}

</style>
