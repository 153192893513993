<template>
  <div class="paper-type">
    <Heading :title="$t('paper.matte.title')" :subtitle="$t('paper.matte.subtitle').toUpperCase()"/>

    <PhotoPaperSection
        social="@malo.illustration"
        artist="Malorie Vuillemin"
        :feature_five="$t('paper.matte.specified.five')"
        :feature_four="$t('paper.matte.specified.four')"
        :feature_one="$t('paper.matte.specified.one')"
        :feature_six="$t('paper.matte.specified.six')"
        :feature_three="$t('paper.matte.specified.three')"
        :feature_two="$t('paper.matte.specified.two')"
        :images="images"
        :subtitle="$t('paper.matte.description')"
        :title="$t('paper.matte.about')"
    />

    <PricingNew :vertical-sizes="[5, 59]"/>

    <PaperTab/>


    <UsagePlaces
        product_artist="Malorie Vuillemin"
        product_social="@malo.illustration"
        :vibrant_footer="$t('paper.matte.usage.inks.footer')"
        :vibrant_description="$t('paper.matte.usage.inks.description')"
        :vibrant_title="$t('paper.matte.usage.inks.title')"

        :handcrafted_title="$t('paper.matte.usage.handcrafted.title')"
        :handcrafted_description="$t('paper.matte.usage.handcrafted.description')"
        :handcrafted_team="$t('paper.matte.usage.handcrafted.team')"
        :handcrafted_moments="$t('paper.matte.usage.handcrafted.moments')"

        :feature_one="$t('paper.matte.usage.features.feature_one')"
        :feature_two="$t('paper.matte.usage.features.feature_two')"
        :feature_three="$t('paper.matte.usage.features.feature_three')"
        :feature_four="$t('paper.matte.usage.features.feature_four')"
        :feature_five="$t('paper.matte.usage.features.feature_five')"
        :feature_six="$t('paper.matte.usage.features.feature_six')"

        :left_image="matte_image_one"
        :right_image="matte_image_two"
    />

 <ProductReviewSection/>

    <WorthSection/>


  </div>
</template>

<script>

import PhotoPaperSection from "@/components/section/paper-info-section";
import UsagePlaces from "@/components/section/example-usage-section";
import WorthSection from "@/components/section/stories-worth-section";
import PricingNew from "@/components/cards/pricing-card-new";
import PaperTab from '@/components/tab/paper-tab/PaperTab'
import ProductReviewSection from "../../components/product-review/product-review-section";

export default {
  name: "Paper",
  metaInfo() {
    return {
      title: this.$t('titles.matte'),
      titleTemplate: '%s - Blankspace',
       htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.$t('titles.matte-content') },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ]
    }
  },
  components: {
    ProductReviewSection,
    PricingNew,
    WorthSection,
    UsagePlaces,
    PhotoPaperSection,
    PaperTab
  },
  data() {
    return {
      matte_image_one: require('../../assets/images/usage-new/1.png'),
      matte_image_two: require('../../assets/images/usage-new/0.png'),
      images: [
        {id: 1, src: '/img/papers/opt/matte-0-opt.jpg'},
        {id: 2, src: '/img/papers/opt/matte-1-opt.jpg'}
      ],
    }
  }
}
</script>

<style scoped>


.paper-type {
  padding-top: 35px;
  overflow-x: hidden !important;
  @media (--t) {
    padding-top: 65px;
  }
}
.page-subtitle {
  letter-spacing: 0.15em;
}

</style>
