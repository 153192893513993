<template>
  <div class="container">
    <p class="soft-text mb-3">{{ $t("faq.products.inks-text.one-text") }}
      <strong>{{$t("faq.products.inks-text.two-text")}}</strong>
     {{ $t("faq.products.inks-text.three-text") }}
    </p>
    <p class="soft-text mb-0">
      {{ $t("faq.products.inks-text.four-text") }}
      <strong> {{ $t("faq.products.inks-text.five-text") }}</strong>
      {{ $t("faq.products.inks-text.six-text") }}
      <strong> {{ $t("faq.products.inks-text.seven-text") }}</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: 'FaqProductsInks'
}
</script>

<style scoped lang="scss">
  strong {
    color: black!important;
  }
</style>
