import {fr_countries, en_countries} from "@/data/countries"

export default {
    state: {
        country_list: {
            en: en_countries,
            fr: fr_countries,
        },
        state_list: null,
    },
    mutations: {},
    actions: {},
    getters: {
        getCountry: (state) => state.country_list[localStorage.lang || "en"],
        getShippingCountry: state => state.country_list[localStorage.lang || "en"]?.filter(c => c.only_shipping)
    }
}
