<template>
  <v-card outlined tile>
    <v-card-text class="pa-6">
      <div class="d-flex justify-space-between">
        <span class="regular-medium text-left">{{ name }}</span>
        <div>
          <span @click="onUpdate" class="regular-medium text-right" type="button">{{ $t('profile.edit') }}</span>
          <span  v-if="!disableDeleteEvent" class="regular-medium text-right ml-2">|</span>
          <span v-if="!disableDeleteEvent" @click="onDelete" class="regular-medium text-right ml-2" type="button">{{$t('profile.delete')}}</span>
        </div>
      </div>
      <p class="mt-3">{{ street }} - {{ country }} / {{ nation }}</p>
      <span>{{ zipcode }}</span>
      <span class="regular-medium text-left">{{ phone }}</span>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "AddressCard",
  props: {
    disableDeleteEvent : {
      required : false,
      type: Boolean,
      default : false
    },
    name: {
      type: String,
    },
    street: {
      type: String
    },
    country: {
      type: String,
    },
    phone: {
      type: String
    },
    nation: {
      type: String,
    },
    zipcode: {
      type: String
    }

  },
  methods: {
    onDelete(e) {
      this.$emit('onDelete', e)
    },
    onUpdate(e) {
      this.$emit('onUpdate', e)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-card.v-sheet--outlined {
  border: 1px solid #000000;
}
</style>
