<template>
  <div class="hardware">
    <IconLogoCorner class="transparent-logo"/>
    <Heading :subtitle="$t('hardware.subtitle')" :title="$t('hardware.title')"/>

    <v-container>
      <AppSwiper v-if="smAndDown && hardware_data" v-bind="options" :items="hardware_data">
        <template v-slot:item="item">
          <HardwareCard
            :description="item.description"
            width="280px"
            class="mb-5"
            :name="item.title"
            :src="item.path"/>
        </template>
      </AppSwiper>
      <div v-else class="items">
        <template v-for="(ware,index) in hardware_data">
          <HardwareCard
            width="280px"
            :key="index"
            :description="ware.description"
            :name="ware.title"
            :src="ware.path"
            class="desktop-items"
          />
        </template>

      </div>
    </v-container>
  </div>
</template>

<script>

import HardwareCard from '@/components/cards/shared-card/hardware-card'
import IconLogoCorner from '@/assets/svg-icons/logo/logo-9.svg'
import { mapGetters } from 'vuex'

export default {
  name: 'HardwareSection',
  components: {
    HardwareCard,
    IconLogoCorner
  },
  data() {
    return {
      hardware_data: null,
      hardware: [
        {
          id: 1,
          name: 'Aluminum rails',
          description: 'Most popular. Easy to install on your wall.',
          src: '/img/hardware/hardware-0.webp'
        },
        {
          id: 1,
          name: 'Barrels',
          description: 'Extra solid and stable, your print won’t budge.',
          src: '/img/hardware/hardware-1.webp'
        }
      ]
    }
  },
  methods: {
    hardwareData() {
      if (localStorage.getItem('lang') === 'en') {
        this.hardware_data = this.$store.getters.get_hardware_en
      } else if (localStorage.getItem('lang') === 'fr') {
        this.hardware_data = this.$store.getters.get_hardware_fr
      }
    }
  },
  computed: {
    ...mapGetters([
      'get_hardware_en',
      'get_hardware_fr',
    ]),
    options() {
      return {
        loop: true,
        autoplay: true,
        slidesPerView: this.smAndDown ? 'auto' : 1,
        centeredSlides: this.smAndDown,
        spaceBetween: 16,

      }
    }
  },
  created() {
    this.hardwareData()
  }
}
</script>

<style lang="scss">

.hardware {
  background-color: #F9F8F7;
  position: relative;
  overflow: hidden;
  padding-top: 20px;
  @media (--t) {
    padding-top: 48px;
    padding-bottom: 10px;
  }

  .pagination-container {
    top: 80%;
  }

  .pagination {
    background-color: #000000 !important;
  }

  .pagination[aria-current] {
    background-color: #009771 !important;
  }

  .mobile-items {
    display: flex;
    @media (--t) {
      display: none;
    }
  }

  .desktop-items {
    display: none;
    @media (--t) {
      display: flex;
      margin-left: 50px;
      margin-right: 50px;
    }
  }

  h1, h2 {
    z-index: 2;
  }

  .transparent-logo {
    display: none;
    @media (--t) {
      display: inline-block;
      position: absolute;
      top: -17%;
      right: 12%;
      z-index: 0;
    }
  }

  .items {
    display: none;
    @media (--t) {
      display: flex;
    }
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    position: relative;
  }
  .swiper-slide {
    width: 280px!important;
  }
}
</style>
