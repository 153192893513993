<template>
  <div class="home overflow-hidden">
    <BannerSection />
    <ProcessSection style="max-width: 1150px"/>
    <div class="story-section">
      <v-container style="max-width: 1150px" >
        <Heading :title="$t('home.stories.title')" :subtitle="$t('home.stories.subtitle')"/>
        <StorySlide v-if="smAndDown" class="mt-7"/>
        <v-row v-else class="story-items">
          <div
            class="col col-lg-4 col-md-6"
            v-for="(item,index) in story_data"
            :key="index"
          >
            <StoryCard
              :item="item"
            />
          </div>
        </v-row>

      </v-container>
    </div>

    <SpecialitySection/>

    <InstagramSection/>
    <v-container class="about-section">

      <Heading :title="$t('home.about.title')" :subtitle="$t('home.about.subtitle')"/>

      <div class="medium text-center" v-html="$t('home.about.description')"></div>
      <p class="medium text-center mb-8">{{ $t('home.about.footer') }}</p>
      <AppButton
        :to="{name:'About'}"
        color="primary"
      >
        {{ $t('buttons.find') }}
      </AppButton>
    </v-container>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'
import ProcessSection from '@/components/section/process-section'
import SpecialitySection from '@/components/section/speciality-section'
import StorySlide from '@/components/StorySlide'
import InstagramSection from '@/components/section/home-instagram-section'
import BannerSection from '@/components/section/BannerSection'

export default {
  name: 'Home',
  metaInfo() {
    return {
      title: this.$t('titles.home'),
      titleTemplate: '%s - Blankspace',
       htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.$t('titles.home-content')},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  components: {
    BannerSection,
    StorySlide,
    SpecialitySection,
    ProcessSection,
    InstagramSection
  },
  data() {
    return {
      story_data: [],
    }
  },
  methods: {
    instagramImage(path) {
      return require('@/' + path)
    },
    storyData() {
      if (localStorage.getItem('lang') === 'en') {
        this.story_data = this.$store.getters.get_story_en
      } else if (localStorage.getItem('lang') === 'fr') {
        this.story_data = this.$store.getters.get_story_fr
      }
    },
  },
  mounted() {
    this.storyData()
  },
  computed: {
    ...mapState(['stories', 'posters']),
    ...mapGetters(['get_story_en', 'get_story_fr']),
  }
}
</script>
<style lang="scss">
.home {

  .page-subtitle {
    letter-spacing: 0.15em;
  }

  .story-section {
    padding-top: 40px;
    padding-bottom: 8px;
    background-color: #F9F9F9;
    @media (--t) {
      padding-top: 60px;
      padding-bottom: 90px;
    }

    .sub {
      max-width: 320px;
      @media(--t) {
        max-width: unset;
        margin-top: 10px;
        padding-top: 0;
      }
    }

    .main {
      max-width: 263px;
      @media(--t) {
        max-width: unset;
      }
    }


    .story-items {
      display: none;
      @media (--t) {
        display: flex;
        margin-top: 88px;
      }
    }

    .second-slide {
      display: block;
      margin-top: 30px;
      @media (--t) {
        display: none;
      }

    }

  }

  .about-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 820px;
    padding-top: 5px;
    padding-bottom: 30px;
    margin-top: 80px;
    @media (--t) {
      padding-top: 100px;
      padding-bottom: 120px;
      margin-top: unset;
    }


    .medium {
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      letter-spacing: 0.02em;
      color: #393939;
      margin-top: 10px;
    }

    button {
      font-family: Sk-Modernist, "sans-serif" !important;
      padding: 8px 16px !important;
      background-color: #000000 !important;
      font-weight: bold !important;
      font-size: 16px !important;
      line-height: 150% !important;
      letter-spacing: 0.02em !important;
      color: #FFFFFF !important;
      margin-top: 15px !important;
    }
  }

}


</style>
