<template>
  <div class="container">
    <div class="d-flex justify-center flex-column align-center">
      <div class="">
        <img v-if="english" alt="" class="img-fluid" src="@/assets/images/table-en.png"/>
        <img v-if="french" alt="" class="img-fluid" src="@/assets/images/table-fr.png"/>
      </div>
      <p class="mt-3 soft-text font-weight-bold">Note: {{ $t("faq.paper_footer") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqProductsSize',
  props: {
    english: {
      type: Boolean,
      default: () => false
    },
    french: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style scoped>

</style>
