<template>
  <div class="app-form-content">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AppFormContent'
}
</script>

<style lang="scss" scoped>

</style>
