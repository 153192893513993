<template>
  <div class="simple-footer">
    <v-container :style="{maxWidth: _footer_max_width + 'px'}" class="footer-container">
      <v-row class="justify-space-between align-center ">
        <v-col :lg="terms_layout" class="footer-bottom-terms " cols="12">
          <router-link :to="{name:'Terms'}">
            <span class="term-title">{{ $t('footer.terms') }}</span>
          </router-link>
          <router-link :to="{name:'Privacy'}">
            <span class="term-title">{{ $t('footer.privacy') }}</span>
          </router-link>
        </v-col>

        <v-col class="footer-payment-methods" :class="{'pr-lg-5': onCartPage}" lg="3" cols="12">
          <IconVisaDebit fill="#ffffff"/>
          <IconVisa fill="#ffffff"/>
          <IconMasterCard fill="#ffffff"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {IconMasterCard, IconPaypals, IconVisa, IconVisaDebit} from "@/assets/svg-icons";

export default {
  name: "SimpleFooter",
  props: {
    onProductPage: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    IconPaypals, IconVisa, IconVisaDebit, IconMasterCard
  },
  computed: {
    _footer_max_width() {
      if (this.onCartPage) {
        return 1320;
      } else return 1200
    },
    onCartPage() {
      return this.$route.name === 'Cart'
    },
    terms_layout() {
      const lang = localStorage.getItem('lang');
      if (lang === 'en' && this.$route.name === 'Cart') {
        return 3;
      } else if (lang === 'fr') {
        return 5
      } else return 4
    }
  }
}
</script>

<style lang="scss" scoped>
.simple-footer {
  background-color: #000000;
  overflow: hidden;
  /*padding-bottom: 5px;*/
  @media (--t) {
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: unset;
  }

  .footer-container {

    .footer-bottom-terms {
      display: flex;
      justify-content: space-around;
      @media (--t) {
        justify-content: space-between;
      }
      a {
        text-align: unset;
        display: flex;
        @media(--t) {
          &:nth-child(1) {
            text-align: left;
          }

          &:nth-child(2) {
            text-align: right;
          }
        }
      }

      .term-title {
        font-family: Sk-Modernist, 'sans-serif' !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFFFFF;
      }

    }


    .footer-payment-methods {
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
      max-width: 238px;
      margin-left: auto;
      margin-right: auto;
      @media (--t) {
        max-width: unset;
        margin-left: unset;
        margin-right: unset;
      }

      svg {
        cursor: auto !important;
      }
    }
  }
}
</style>
