export const CANVAS_DATA = [
    {
        id: 1,
        size: '7" x 5"',
        price: "37.67",
        height: 5,
        width: 7,
        selected: false
    },
    {
        id: 2,
        size: '15" x 10"',
        price: "57.29",
        height: 10,
        width: 15,
        selected: false
    },
    {
        id: 3,
        size: '24" x 16"',
        price: "80.00",
        height: 16,
        width: 24,
        selected: false
    },
    {
        id: 4,
        size: '36" x 24"',
        price: "108.00",
        height: 24,
        width: 36,
        selected: false
    },
    {
        id: 5,
        size: '60" x 40"',
        price: "400.00",
        height: 40,
        width: 60,
        selected: false
    }
];
export const MATTE_DATA = [
    {
        id: 1,
        size: '7" x 5"',
        price: "8.26",
        height: 5,
        width: 7,
        selected: false
    },
    {
        id: 2,
        size: '15" x 10"',
        price: "12.50",
        height: 10,
        width: 15,
        selected: false
    },
    {
        id: 3,
        size: '24" x 16"',
        price: "20.00",
        height: 16,
        width: 24,
        selected: false
    },
    {
        id: 4,
        size: '36" x 24"',
        price: "40.50",
        height: 24,
        width: 36,
        selected: false
    },
    {
        id: 5,
        size: '60" x 40"',
        price: "110.83",
        height: 40,
        width: 60,
        selected: false
    }
];
export const TEXTURED_DATA = [
    {
        id: 1,
        size: '7" x 5"',
        price: "15.80",
        height: 5,
        width: 7,
        selected: false
    },
    {
        id: 2,
        size: '15" x 10"',
        price: "26.04",
        height: 10,
        width: 15,
        selected: false
    },
    {
        id: 3,
        size: '24" x 16"',
        price: "48.00",
        height: 16,
        width: 24,
        selected: false
    },
    {
        id: 4,
        size: '36" x 24"',
        price: "108.00",
        height: 24,
        width: 36,
        selected: false
    },
    {
        id: 5,
        size: '51" x 34"',
        price: "204.71",
        height: 34,
        width: 51,
        selected: false
    }
];
export const SATIN_DATA = [
    {
        id: 1,
        size: '7" x 5"',
        price: "8.26",
        height: 5,
        width: 7,
        selected: false
    },
    {
        id: 2,
        size: '15" x 10"',
        price: "12.50",
        height: 10,
        width: 15
    },
    {
        id: 3,
        size: '24" x 16"',
        price: "20.00",
        height: 16,
        width: 24,
        selected: false
    },
    {
        id: 4,
        size: '36" x 24"',
        price: "40.50",
        height: 24,
        width: 36,
        selected: false
    },
    {
        id: 5,
        size: '60" x 40"',
        price: "110.83",
        height: 40,
        width: 60,
        selected: false
    }
];
export const HEMP_DATA = [
    {
        id: 1,
        size: '7" x 5"',
        price: "18.23",
        height: 5,
        width: 7,
        selected: false
    },
    {
        id: 2,
        size: '15" x 10"',
        price: "33.33",
        height: 10,
        width: 15,
        selected: false
    },
    {
        id: 3,
        size: '24" x 16"',
        price: "64.00",
        height: 16,
        width: 24,
        selected: false
    },
    {
        id: 4,
        size: '36" x 24"',
        price: "126.00",
        height: 24,
        width: 36,
        selected: false
    },
    {
        id: 5,
        size: '51" x 34"',
        price: "240.83",
        height: 34,
        width: 51,
        selected: false
    }
];
export const METALLIC_DATA = [
    {
        id: 1,
        size: '7" x 5"',
        price: "12.15",
        height: 5,
        width: 7,
        selected: false
    },
    {
        id: 2,
        size: '15" x 10"',
        price: "18.75",
        height: 10,
        width: 15,
        selected: false
    },
    {
        id: 3,
        size: '24" x 16"',
        price: "35.33",
        height: 16,
        width: 24,
        selected: false
    },
    {
        id: 4,
        size: '30" x 20"',
        price: "50.00",
        height: 20,
        width: 30,
        selected: false
    },
    {
        id: 5,
        size: '36" x 24"',
        price: "70.50",
        height: 24,
        width: 36,
        selected: false
    },
];
export const ALIMINUM_DATA = [
    {
        id: 1,
        size: '7" x 5"',
        price: "44.97",
        height: 5,
        width: 7,
        selected: false
    },
    {
        id: 2,
        size: '15" x 10"',
        price: "83.33",
        height: 10,
        width: 15,
        selected: false
    },
    {
        id: 3,
        size: '24" x 16"',
        price: "144.00",
        height: 16,
        width: 24,
        selected: false
    },
    {
        id: 4,
        size: '36" x 24"',
        price: "264.00",
        height: 24,
        width: 36,
        selected: false
    },
    {
        id: 5,
        size: '60" x 40"',
        price: "800.00",
        height: 40,
        width: 60,
        selected: false
    }
];
export const ACRYLIC_DATA = [
    {
        id: 1,
        size: '7" x 5"',
        price: "60.76",
        height: 5,
        width: 7,
        selected: false
    },
    {
        id: 2,
        size: '15" x 10"',
        price: "109.38",
        height: 10,
        width: 15,
        selected: false
    },
    {
        id: 3,
        size: '24" x 16"',
        price: "173.33",
        height: 16,
        width: 24,
        selected: false
    },
    {
        id: 4,
        size: '36" x 24"',
        price: "288.00",
        height: 24,
        width: 36,
        selected: false
    },
    {
        id: 5,
        size: '60" x 40"',
        price: "883.33",
        height: 40,
        width: 60,
        selected: false
    }
]
