<template>
<v-card flat v-bind="$attrs">
  <v-card-text :class="cardTextClass">
    <div :class="`d-flex justify-${imgJustify}${ nudgeImg ? ' pl-8' : ''}`">
      <img height="260px"  :src="item.img"/>
    </div>
    <div class="d-flex text-center mt-6 flex-column align-center">
      <h6 class="title-large mb-3">{{ item.title }}</h6>
      <div style="max-width: 200px">
        <p class="page-subtitle dark-color--text">{{ item.description }}</p>
      </div>
    </div>
  </v-card-text>
</v-card>
</template>

<script>
export default {
  name: 'ProcessItemCard',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    imgJustify: {
      type: String,
      default: () => 'center'
    },
    cardTextClass: {
      type: String,
      default: () => null
    },
    nudgeImg: {
      type: Boolean,
      default: () => false,
    }
  }
}
</script>

<style scoped lang="scss">
img {
  height: 260px;
  width: auto;
}
.page-subtitle {
  display: block;
  font-size: 16px;
  max-width: 264px !important;
  letter-spacing: 0.02em !important;

  @media (--t) {
    font-family: var(--font-family-modernist);
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    text-align: center !important;
    color: #000000 !important;
    margin-top: 10px;
  }
}
</style>
