<template>
<AppFormInput
  v-bind="$attrs"
  :type="fieldType"
  :immediate="true"
  v-model="innerValue"
>
  <template v-slot:append>
    <v-tooltip
      :color="tooltipColor"
      top
    >
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          @click="showPass = !showPass"
          :color="hasError ? 'error' : '#656565'"
        >
          {{ icon }}
        </v-icon>
      </template>
      <span>{{ showPassText }}</span>
    </v-tooltip>
  </template>
</AppFormInput>
</template>

<script>
import {formInputMixins} from '@/mixins/formInputMixins'

export default {
  name: 'AppPasswordInput',
  mixins: [formInputMixins],
  data: () => ({
    showPass: false
  }),
  computed: {
    fieldType() {
      return this.showPass ? 'text' : 'password'
    },
    icon() {
      return this.showPass ? 'mdi-lock-open-variant-outline' : 'mdi-lock-outline'
    },
    tooltipColor() {
      return this.$attrs.color || 'secondary'
    },
    showPassText() {
      return this.showPass ? 'Hide Password' : 'Show Password'
    }
  }

}
</script>
