<template>
  <div class="card d-flex flex-md-row flex-column flex-wrap">
    <v-img
      v-bind="imgProps"
      :src="src"
      alt="instagram_comments"
      eager
    />
    <div class="card-body">
      <span class="frame-title ">{{ message }}</span>
      <span class="medium passive-grey--text">{{ customer }}</span>
      <IconSymbol class="icon"/>
    </div>
  </div>
</template>

<script>
import CustomText from '@/components/shared/CustomText'
import IconSymbol from '../../../../public/favicon.svg'

export default {
  name: 'InstagramCard',
  props: {
    src: {
      type: String,
      default: () => null
    },
    message: {
      type: String,
      default: () => null
    },
    customer: {
      type: String,
      default: () => null
    }
  },
  computed: {

    imgProps() {
      return this.$vuetify.breakpoint.mdAndDown ? {
        aspectRatio: 1,
        height: 300
      } : {
        aspectRatio: (295 / 250),
      }
    }
  },
  components: {
    CustomText,
    IconSymbol,
  },
}
</script>

<style lang="scss" scoped>
.card {
  height: 100% !important;
  border: 0 !important;
  border-radius: 0 !important;
  @media (--t) {
    position: relative;
    display: flex;
    flex-direction: row;
    border: 0;
  }


}

.card-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  @media(min-width: 980px) {
    max-width: 50%;
  }

  .frame-title {
    font-family: var(--font-family--alethia) !important;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    padding: 16px;
    letter-spacing: -0.03em;
    color: #000000;
    @media (--t) {
      font-size: 17px;
      line-height: 17px;
    }
    @media(min-width: 1904px) {
      font-size: 27px;
      line-height: 120%;
    }
  }

  .medium {
    font-family: Sk-Modernist, 'sans-serif' !important;
    font-style: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #000000;
    @media(--t) {
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      position: absolute;
      bottom: 24%;
    }
    @media (min-width: 1904px) {
      font-size: 18px;
    }
  }

  .icon {
    display: none;
    @media (--t) {
      display: block;
      position: absolute;
      bottom: 2%;
    }
  }
}

.frame-title {
  text-align: center;
  letter-spacing: -0.03em;
  line-height: 120%;
}

.mobile {
  display: flex;
  @media (--t) {
    display: flex;
    padding-left: 0;
  }
}


</style>
