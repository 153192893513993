<template>
  <div class="container">
  <p class="soft-text"
  >
    {{ $t('faq.image.edit-text-one') }}
  <span>
    <a class="soft-text font-weight-bold"
       href="mailto:support@blankspace.ink?subject=I have questions."
       style="color: black!important;"> support@blankspace.ink</a>
  </span> {{ $t('faq.image.edit-text-two') }}</p>
  </div>
</template>

<script>
export default {
  name: 'FaqImageEdit'
}
</script>

<style scoped>

</style>
