<template>
  <h3></h3>
</template>

<script>
import {axiosInstance} from '@/utils/setAxios'
import {LogoDesktopBig} from '@/assets/svg-icons'
import {pick} from "lodash";

export default {
  name: 'OrderDetail',
  components: {
    LogoDesktopBig,
  },
  data() {
    return {
      order_data : {}
    }
  },
  created() {
    axiosInstance.get('orders/' + this.$route.params.id).then(response => {
      this.order_data = pick(response?.data, ["invoice", "invoice_items", "shipment_items"]);
    })
  },
  computed: {
   orderAvailable(){
     return this?.order_data?.invoice?.id
   }
  },
}
</script>


<style lang="scss">


</style>
