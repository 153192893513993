<template>
  <div>
    <div :style="wrapperStyle" class="cart-page-container">
      <v-container v-if="get_cart_without_sample_kit.length > 0" class="cart-page px-5">
        <div style="margin: 0 -30px">
          <v-btn text color="primary" tile class="mb-2 text-capitalize" :to="{ name: 'Configurator' }">
            <v-icon size="35">mdi mdi-chevron-left</v-icon>
            <span class="page-subtitle">{{ $t("cart.back") }}</span>
          </v-btn>
        </div>
        <div class="promise-container">
          <span class="promise-title">{{ $t("cart.blankspace-promise-title") }} {{ $t("cart.blankspace-promise-we") }}</span>
          <span class="promise-title promise-secondary">{{ $t("cart.blankspace-promise-second") }}</span>
        </div>

        <v-row class="cart-inner justify-space-between">
          <v-col class="pb-0 pt-0" lg="8">
            <div class="cart-header">
              <h2 class="title mb-2">{{ $t("cart.my") }}</h2>
              <span v-if="get_cart_without_sample_kit.length" class="cart-quantity" :class="{ 'passive-grey--text': !smAndDown }">{{ get_cart_without_sample_kit.length }} {{ $t("cart.item") }}</span>
            </div>
            <hr />
          </v-col>
        </v-row>
        <v-row class="my-0 cart-row">
          <v-col lg="8" cols="12">
            <ShoppingItem
              v-for="(item, index) in get_cart_without_sample_kit"
              :key="index"
              :item="item"
              :border="item.border"
              :edge="item.edge"
              :frame="item.frame"
              :gift_card_value="item.gift_card_value"
              :layout="item.layout"
              :material="item.product_type"
              :price="item.price * item.quantity"
              :quantity="item.quantity"
              :rails="item.hardware"
              :size_height="item.height"
              :size_width="item.width"
              :src="item.thumbnail_base64"
              @decrease="decrement(item)"
              @increase="increment(item)"
              @remove="removeProduct(item)"
            />
            <v-checkbox v-model="addSampleKit" color="secondary">
              <template v-slot:label>
                <span class="primary--text" v-html="$t('addSampleKitText')"></span>
              </template>
            </v-checkbox>
          </v-col>
          <v-col lg="4" cols="12">
            <OrderSummary class="summary-card" @onCheckout="checkout" />
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else class="empty-cart">
        <h1 class="page-title">{{ $t("cart.empty_cart") }} !</h1>
        <AppButton :to="{ name: 'Configurator' }" color="primary" large>
          {{ $t("buttons.start") }}
        </AppButton>
      </v-container>
    </div>

    <v-container v-if="get_cart_without_sample_kit.length > 0" class="cart-page mb-lg-6">
      <v-row class="info-card-container" style="max-width: 1065px">
        <v-col lg="4" md="4" cols="12">
          <div class="icon-wrapper">
            <IconThree :height="smAndDown ? 40 : 70" />
          </div>
          <div class="text-container">
            <span class="title">{{ $t("cart.info-cards.one-title") }}</span
            ><br />
            <span class="text">{{ $t("cart.info-cards.one-text") }}</span
            ><br />
            <router-link to="/faq/3/0/20">
              <span class="link"> {{ $t("cart.info-cards.one-see") }}</span>
            </router-link>
          </div>
        </v-col>
        <v-col lg="4" md="4" cols="12">
          <div class="icon-wrapper">
            <img :style="{ height: `${smAndDown ? '48px' : '80px'}`, width: 'auto' }" alt="" src="../../../assets/svg-icons/icons/p-1.png" />
          </div>
          <div class="text-container">
            <span class="title">{{ $t("cart.info-cards.two-title") }}</span
            ><br />
            <span class="text">{{ $t("cart.info-cards.two-text") }}</span>
            <router-link to="/faq/1/2/8">
              <span class="link"> {{ $t("cart.info-cards.one-see") }}</span>
            </router-link>
          </div>
        </v-col>
        <v-col lg="4" md="4" cols="12">
          <div class="icon-wrapper">
            <IconOne :height="smAndDown ? 32 : 60" />
          </div>
          <div class="text-container">
            <span class="title">{{ $t("cart.info-cards.three-title") }}</span
            ><br />
            <span class="text">{{ $t("cart.info-cards.three-text") }}</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

export default {
  name: "Cart",
  metaInfo() {
    return {
      title: this.$t("titles.cart"),
      titleTemplate: "%s - Blankspace",
      htmlAttrs: {
        lang: "en",
      },
    };
  },
  data() {
    return {
      total_amount: null,
    };
  },
  components: {
    CustomText: () => import("@/components/shared/CustomText"),
    OrderSummary: () => import("@/components/section/order-summary-section"),
    ShoppingItem: () => import("@/components/shopping-item"),
    SimpleFooter: () => import("@/components/shared/navigation/SimpleFooter"),
    IconArrowLeft: () => import("@/assets/svg-icons/icons/left-arrow.svg"),
    IconOne: () => import("@/assets/svg-icons/icons/p-1.svg"),
    IconTwo: () => import("@/assets/svg-icons/icons/p-2.svg"),
    IconThree: () => import("@/assets/svg-icons/icons/p-3.svg"),
  },

  computed: {
    ...mapGetters(["get_cart_without_sample_kit", "hasSampleKit"]),
    hasProducts() {
      return this.get_cart_without_sample_kit.length > 0;
    },
    wrapperStyle() {
      const height = this.smAndDown ? 60 : this.get_cart_without_sample_kit.length > 0 ? 309 : 150;
      return {
        [this.get_cart_without_sample_kit.length > 0 ? "minHeight" : "height"]: `calc(100vh - ${height}px)`,
        ...(!this.hasProducts && { alignItems: "center" }),
        display: "flex",
      };
    },
    addSampleKit: {
      get() {
        return this.hasSampleKit;
      },
      set(val) {
        this.$store.commit("SET_SAMPLE_KIT", val);
      },
    },
  },

  methods: {
    checkout() {
      window.scroll(0, 0);
      router.push({ name: "ShipmentStep" });
      this.$store.commit("SET_SUMMARY_ITEMS", this.get_cart_without_sample_kit);
    },
    removeProduct(index) {
      this.$store.dispatch("REMOVE_CART_ITEM", index);
    },
    increment(item) {
      this.$store.dispatch("INCREASE_QUANTITY", item);
    },
    decrement(item) {
      this.$store.dispatch("DECREASE_QUANTITY", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-row.row {
  margin: -12px -30px;
  .col-12 {
    padding: 12px 30px;
  }
}

.v-btn {
  .page-subtitle {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 2px;
      bottom: -2px;
      /*background-color: currentColor;*/
      width: 0;
      height: 1px;
      opacity: 0;
      border: 1px solid currentColor;
      transition: width linear 0.15s, opacity ease-in 0.2s;
    }
  }

  &:hover {
    &:before {
      opacity: 0;
    }

    .page-subtitle::before {
      opacity: 1;
      width: calc(100% - 4px);
    }
  }
}

.icon-one {
  margin-bottom: -20px;
  margin-left: -4px;
  @media (--t) {
    margin-top: unset;
    margin-left: unset;
    margin-bottom: unset;
  }
}

.icon-two {
  //margin-top:-30px; margin-bottom: -20px;
  @media (--t) {
    margin-top: -27px;
    margin-bottom: 0;
    margin-left: -4px;
  }
}

.icon-three {
  margin-top: -20px;
  margin-bottom: -20px;
  @media (--t) {
    margin-bottom: -5px;
    margin-top: 10px;
  }
}

.cart-inner {
  margin-top: 20px;
  @media (--t) {
    margin-top: 62px;
  }
}

.cart-page {
  overflow: hidden;
  padding-top: 30px;
  @media (--t) {
    padding-top: 70px;
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
  }

  .text-container {
    @media (--t) {
      height: 150px;
    }
    max-width: 296px;
  }

  .info-card-container {
    padding-left: 20px;
    padding-right: 20px;
    @media (--t) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 40px;
    }

    .title {
      font-family: Basilia, "sans-serif" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 32px !important;
      display: flex !important;
      align-items: center !important;
      letter-spacing: 0.02em !important;
      color: #000000 !important;
      margin-bottom: -23px !important;
      margin-top: 20px !important;
    }

    .link,
    .text {
      display: inline !important;
      max-width: 296px !important;
      font-family: Sk-Modernist, "sans-serif" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 14px !important;
      line-height: 140% !important;
      letter-spacing: 0.02em !important;
      color: #000000 !important;
    }

    .link {
      display: inline !important;
      text-decoration: underline !important;
    }
  }

  .promise-container {
    padding-left: 0px;
    padding-right: 0px;
    @media (--t) {
      padding-left: 0;
      padding-right: 0;
      max-width: 650px;
      margin-top: 16px;
    }

    .promise-title {
      font-family: Sk-Modernist, "sans-serif";
      font-size: 14px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #000000;
      display: block;
      @media (--t) {
        font-size: 18px;
        color: #828282;
      }
    }

    :nth-child(2) {
      display: block;
      @media (--t) {
        display: unset;
      }
    }

    .promise-title.promise-secondary {
      font-size: 12px;
      @media (--t) {
        font-size: 18px;
      }
    }
  }

  .cart-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 5px;

    hr {
      height: 1px;
      background-color: #e8e8e8;
    }

    .title {
      font-family: var(--font-family--alethia) !important;
      font-style: normal;
      font-weight: normal;
      font-size: 36px !important;
      line-height: 105%;
      color: #000000;
      @media (--t) {
        font-size: 48px !important;
      }
    }

    span {
      font-family: Sk-Modernist;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      display: flex;
      align-items: flex-end;
      text-align: right;
      letter-spacing: 0.02em;
      color: #000000;
    }
  }
}

.icon-wrapper {
  display: flex;
  @media (--t) {
    height: 80px;
    align-items: end;
  }
}

.empty-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .page-title {
    font-size: 35px;
    line-height: 1;
    margin-bottom: 16px;
    @media (--t) {
      font-size: 45px;
    }
  }
}

.cart-quantity {
  font-size: 20px !important;
  @media (--t) {
    font-size: 18px !important;
  }
}
</style>
