import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/routes/main/home-page.vue";
import store from "../store/index";
import {routes} from "@/router/routes";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({
                        selector: to.hash,
                    });
                }, 1000);
            });
        }
        if (savedPosition) {
            return savedPosition;
        }
    },
});

router.beforeEach(async (to, from, next) => {
    const checkoutPages = ["ShipmentStep", "Payment"];

    window.scrollTo(0, 0);
    await store?.restored;
    const isAuthenticated = store.getters.getAuthStatus;
    const cartHasItem = store.state?.order?.cart?.length > 0

    if (to?.name === "Configurator") document.querySelector("html").style.overflowY = "hidden"
    else document.querySelector("html").style.overflowY = "scroll";

    if (checkoutPages.includes(to.name) && !cartHasItem) next({path: "/"});

    if (to.path === "/") next();
    if (to.path === "/login" && !isAuthenticated) next();
    if (to.path === "/login" && isAuthenticated) next({path: "/"});

    if (to.path === "/profile" && !isAuthenticated) next({path: "/login"});
    if (to.path === "/profile" && isAuthenticated) next();

    if (to.name === "Payment" && from.name !== "ShipmentStep") next({name: "Configurator"});
    if (to.name === "Payment" && from.name === "ShipmentStep") next();

    if (to.name === "ShipmentStep" && from.name === "Cart") next();
    if (to.name === "ShipmentStep" && from.name === "Payment") next();
    else next();
});

router.beforeEach((to, from, next) => {
    if (to?.name === "Configurator") {
        document.querySelector("html").style.overflowY = "hidden";
    } else {
        document.querySelector("html").style.overflowY = "scroll";
    }
    next();
});

/*router.afterEach(() => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 1000);
});*/

export default router;
