<template>
  <div class="blog">
    <v-container :fluid="$vuetify.breakpoint.lgAndUp" style="max-width:1263px; min-height: 100VH">

      <Heading :title="$t('blog.title')" :subtitle="$t('blog.subtitle')"/>

      <div class="featured-section">
        <CustomText size="pre-title" tag="h3"> {{ $t('blog.featured') }}</CustomText>
        <v-row style="min-height: 50vh" class="blog-list">
          <div
              v-for="item in (!loading ? $store.getters.getBlogList : 6)"
              :key="item.id"
              class="col-12 col-md-6"
          >
            <BlogCard
                :item="item"
                v-if="!loading"
            />
            <v-responsive
                v-else
                :aspect-ratio="(605/597)">
              <v-skeleton-loader
                  type="image, image, card-heading, paragraph"
              />
            </v-responsive>
          </div>
        </v-row>
      </div>
      <div class="latest-section">
        <h3 class="tertiary-title">{{ $t('blog.latest') }}</h3>
        <v-row justify="space-between">
          <div
              v-for="item in paginatedOrders"
              class="col-12 col-lg-4 col-md-6"
              :key="item.id"
          >
            <BlogCard
                :item="item"
                class="latest-team-blog"
            />
          </div>
        </v-row>
      </div>


      <div class="d-flex justify-center mt-8">
        <AppButton
            v-if="currentPage * maxPerPage < this.$store.getters.getBlogList.length"
            outlined
            @click="loadMore"
        >
          {{ $t('blog.loadMoreBtnText') }}
        </AppButton>
      </div>

    </v-container>


  </div>
</template>

<script>
import CustomText from '@/components/shared/CustomText'
import BlogCard from '@/components/cards/shared-card/blog-card'
import {mapGetters} from 'vuex'

export default {
  name: 'Blog',
  components: {BlogCard, CustomText},
  metaInfo() {
    return {
      title: this.$t('titles.blog'),
      titleTemplate: '%s - Blankspace',
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.$t('titles.blog-content')},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  data: () => {
    return {
      currentPage: 1,
      maxPerPage: 3,
      showReadMore: true,
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['getBlogList']),
    totalResults() {
      return this.$store.getters.getBlogList.length
    },
    hasResult() {
      return this.totalResults > 0
    },
    pageCount() {
      return Math.ceil(this.totalResults / this.maxPerPage)
    },
    pageOffest() {
      return this.maxPerPage * this.currentPage
    },
    paginatedOrders() {
      return this.$store.getters.getBlogList?.slice(0, this.currentPage * this.maxPerPage)
    }
  },
  methods: {
    loadMore() {
      this.currentPage += 1
    },
    getPosts() {
      this.loading = true
      this.$store.dispatch('GET_BLOG_POSTS')
          .then(() => {
            setTimeout(() => {
              this.loading = false
            }, 750)
          })
          .catch(error => {
            console.log(error)
          })
    }
  },
  mounted() {
    this.getPosts()
  }
}
</script>

<style scoped lang="scss">
.blog {
  padding-top: 20px;
  padding-bottom: 18px;
  @media(--t) {
    padding-top: 65px;
    padding-bottom: 50px;
    .row {
      margin: -30px;

      .col-12 {
        padding: 30px;
      }
    }
  }


  .page-title {
    font-family: var(--font-family--alethia) !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 45px !important;
    line-height: 96.3% !important;
    text-align: center !important;
    color: #000000 !important;
    @media (--t) {
      font-size: 60px !important;
      line-height: 60px !important;
    }
  }

  .page-subtitle {
    font-family: Sk-Modernist, sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    text-align: center !important;
    letter-spacing: 0.15em !important;
    color: #000000 !important;
    @media (--t) {
      font-size: 16px !important;
    }
  }

  h3 {
    font-family: var(--font-family--alethia);
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 30px;
    color: #000000;
    @media (--t) {
      //padding-bottom: 15px;
    }
  }

  .featured-section {
    padding-top: 30px;
    @media(--t) {
      padding-top: 63px;
    }

  }

  .latest-section {
    margin-top: 45px;

    .latest-team-blog {
      //width: 380px; //max-width: 405px;
    }

  }


  .load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
  }
}


.third {

  font-family: var(--font-family--alethia);
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 105%;
  /* or 50px */


  color: #000000;
}
</style>
