<template>
  <div v-if="isLoaded" class="blog-detail">
    <v-container class="mt-7">
      <h1 class="article-title">{{ article.title }}</h1>
    </v-container>
    <div v-if="coverImageEnabled" id="cover_image_container" class="cover_image"></div>
    <v-container class="editor-container">
      <div id="body_translations"></div>
    </v-container>

    <v-container style="max-width: 900px">
      <div class="article-info-container">
        <div class="blog-author-container">
          <Avatar :src="authorAvatar" :size="52"/>
          <div class="ml-2 d-flex flex-column justify-lg-center fill-height">
            <div class="d-block">
              <span class="author d-block mb-2">{{ getProp(article, 'author.name') }}</span>
              <div class="passive-grey--text"> {{ getProp(article, 'author.title') }}</div>
            </div>
          </div>
        </div>
        <span class="post-date passive-grey--text">{{ showDate(getProp(article, 'created_at')) }}</span>
      </div>
    </v-container>

    <v-container class="story">
      <h3 class="tertiary-title">{{ $t('blog.next') }}</h3>
      <v-row class="g-4 " cols="1" cols-lg="3" cols-md="2">
        <div
            class="col-12 col-lg-4 col-md-6"
            v-for="item in getBlogList"
            :key="item.id"
        >
          <BlogCard
              :item="item"
          />
        </div>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import BlogCard from '@/components/cards/shared-card/blog-card'
import {publicAcademyAxios} from '@/utils/setAxios'
import {mapGetters} from 'vuex'
import localforage from 'localforage'
import Avatar from '@/components/shared/Avatar'
import EditorJS from '@editorjs/editorjs'
import {EDITOR_TOOLS} from '@/components/editor/tools'
import {isObject} from "lodash";

export default {
  name: 'BlogDetail',
  components: {Avatar, BlogCard},
  data() {
    return {
      blog_data: null,
      article: null,
      authors: [],
      categories: [],
      tags: [],
      title: null,
      editorJS: null,

    }
  },
  created() {
    // this.$store.commit("SET_LOADER", true);
    // ?lng=${this.$i18n.locale}
    publicAcademyAxios.get(`articles/${this.$route.params.slug}`).then(({data: {article}}) => {
      this.blog_data = article;
      this.article = article
      this.title = article.title
      const {file_id_url_map} = article;
      let articleBody = JSON.parse(article.body);
      articleBody = articleBody?.reduce((list, item) => {
        if (item.type === "image") {
          const fileURL = file_id_url_map[item.data.file?.file_id]
          if (fileURL) item.data.file.url = fileURL
        }
        list.push(item)
        return list;
      }, [])

      this.editorJS = new EditorJS({
        holder: 'body_translations',
        autofocus: true,
        tools: EDITOR_TOOLS,
        data: {
          blocks: articleBody
        },
        readOnly: true,
        onReady: this.editorIsReady
      })
    })
  },
  mounted() {
    if (!this.getBlogList?.length) {
      this.$store.dispatch('GET_BLOG_POSTS')
    }
  },
  methods: {
    setImageSettings() {
      if (this.imageCaptionEnabled) {
        const images = document.querySelectorAll(".image-tool__caption") || [];
        images?.forEach(imgElement => {
          imgElement.style.display = "block";
        })
      }
    },
    setCoverImageSettings() {
      const coverImage = new Image();
      coverImage.src = this.getProp(this.article, 'cover_image');
      coverImage.onload = () => {
        const {cover_image_position} = this.article?.document_settings || {}
        if (isObject(cover_image_position)) {
          const {top, left} = cover_image_position;
          coverImage.style.top = top;
          coverImage.style.left = left;
        }
        document.querySelector("#cover_image_container").appendChild(coverImage)
      }
    },
    editorIsReady() {
      this.setImageSettings();
      // this.$store.commit("SET_LOADER", false);
    }
  },
  computed: {
    ...mapGetters(['getBlogList']),
    coverImageEnabled() {
      return this.article?.document_settings?.cover_image && this.article?.cover_image;
    },
    imageCaptionEnabled() {
      return this.article?.document_settings?.image_caption
    },
    authorAvatar() {
      return this.article?.author?.avatar
    },
    isLoaded() {
      return isObject(this.article)
    }
  },
  watch: {
    isLoaded(isLoaded) {
      if (this.coverImageEnabled && isLoaded) {
        this.setCoverImageSettings();
      }
    }
  }
}
</script>

<style lang="scss">

.article-title {
  font-family: var(--font-family--alethia) !important;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  line-height: 100%;
  letter-spacing: -.03em;
  font-size: 45px;
  text-align: center;
  width: 100% !important;
  padding-left: 30px;
  padding-right: 30px;
  @media(--t) {
    font-size: 60px;
  }
}

.editor-container {
  width: 100% !important;
  padding-left: 30px;
  padding-right: 30px;
  @media (--t) {
    padding-left: unset;
    padding-right: unset;
  }
}

.codex-editor__redactor {
  padding-bottom: 0 !important;
}

#body_translations {
  //background-color: red;
}

.cdx-simple-image__caption {
  display: none;
}

.ce-block__content {
  max-width: 900px;
}

.ce-header {
  font-family: var(--font-family--alethia) !important;
}

.ce-block {
  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family--alethia) !important;
    font-style: normal;
    font-weight: normal;
    text-align: left;
    color: #000000;
    line-height: 100%;
    letter-spacing: -.03em;
  }

  h1 {
    font-size: 45px;
    text-align: center;
    @media(--t) {
      font-size: 60px;
    }
  }

  h3 {
    font-size: 28px;
    @media (--t) {
      font-size: 40px;
      padding-left: unset;
      padding-right: unset;
    }
  }

  h2 {
    font-size: 32px;
    @media(--t) {
      font-size: 45px;
    }
  }

  .ce-paragraph {
    font-family: Sk-Modernist, 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #393939;
    @media (--t) {
      //text-align: justify;
      padding-left: unset;
      padding-right: unset;
    }
  }

  .image-tool__image {
    display: flex;
    justify-content: center;
  }

  a {
    font-family: Sk-Modernist, 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #077D60 !important;
    text-decoration: none !important;
    position: relative;
    display: inline-block;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border: 0;
      height: 2px;
      background-color: #077d60;
      transition: width linear .3s;
    }

    &:hover {
      color: #00644b !important;

      &:before {
        width: 0;
      }

    }
  }

  p, span {

    font-family: Sk-Modernist, 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #000000;
    margin-left: 20px;
    padding-right: 20px;
    @media (--t) {
      font-size: 18px;
      padding-left: unset;
      padding-right: unset;
    }
  }

  img {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    margin: 0 0 -.9rem;
    object-fit: cover;
    width: 100%;
    @media (--t) {
      width: unset;
    }

  }

}

.blog-detail {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 87px;

  .article-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .post-date {
      font-style: italic;
      font-size: 14px;
    }

    .blog-author-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      > * {
        height: 52px;
      }

      .author {
        font-family: Sk-Modernist, 'sans-serif';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0.01em;
        color: #000000;
      }

      .title {
        font-family: Sk-Modernist, 'sans-serif';
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        align-items: center;
        letter-spacing: 0.01em;
        color: #7D7D7D;

        p {
          margin: 0;
          font-size: 13px;
          line-height: 1;
        }
      }

    }
  }


  .blog-influence {
    max-width: 800px;

    h2 {
      text-align: left;
    }

    p {
      margin-top: 40px;
      text-align: left;

    }

    img {
      margin-top: 40px;
    }

  }

  .story {
    margin-top: 40px;
    max-width: 1260px;

    .tertiary-title {
      font-weight: 500;
      margin-bottom: 30px;
    }
  }
}

/* EditorJS Styles for Documentation Tool.. */

.inline-image__picture--withBackground, .image-tool__image {
  background: transparent !important;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cdx-block.image-tool {
  .image-tool__caption {
    display: none;

    &[contenteditable="false"] {
      text-align: center;
      color: #000;
      border: none;
      box-shadow: unset;
      -webkit-box-shadow: unset;
      padding: 0;
    }

    span {
      font-family: unset !important;
      font-size: unset !important;
    }
  }
}

.inline-image__caption {
  display: none;

  &[contenteditable="false"] {
    text-align: center;
    font-style: italic;
    color: #ccc;
    border: none;
    box-shadow: unset;
    -webkit-box-shadow: unset;
    padding: 0;
  }
}

.cdx-alert {
  border-radius: 0 !important;
}

.ce-block__content .cdx-alert-primary {
  border-left: 4px solid #4299e1 !important;
}

.ce-block__content .cdx-alert-secondary {
  border-left: 4px solid #cbd5e0 !important;
}

.ce-block__content .cdx-alert-info {
  border-left: 4px solid #4cd4ce !important;
}

.ce-block__content .cdx-alert-danger {
  border-left: 4px solid #fc8181 !important;
}

.ce-block__content .cdx-alert-success {
  border-left: 4px solid #68d391 !important;
}

.ce-block__content .cdx-alert-warning {
  border-left: 4px solid #ed8936 !important;
}

.ce-block__content .cdx-alert-light {
  border-left: 4px solid #edf2f7 !important;
}

.ce-block__content .cdx-alert-dark {
  border-left: 4px solid #1a202c !important;
}

.tc-row .tc-cell[heading="Heading"] {
  background-color: #f0f1f3 !important;
}

.tc-row .tc-cell {
  border-left: 1px solid #e8e8eb;
}

.cover_image {
  height: 250px;
  position: relative;
  overflow-y: hidden;
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    position: absolute;
    width: 100%;
    object-fit: cover;
  }
}

.image-tool__image {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

</style>
