<template>
  <div class="heading">
    <component :is="titleTag" class="heading-title">{{ title }}</component>
    <component :is="subTitleTag" class="heading-subtitle mt-3 mt-md-5">{{ subtitle }}</component>
  </div>
</template>

<script>
export default {
  name: 'Heading',
  props: {
    title: {
      type: String,
      default: () => null
    },
    subtitle: {
      type: String,
      default: () => null
    },
    titleTag: {
      default: () => 'h3'
    },
    subTitleTag: {
      default: () => 'h4'
    }
  },
}
</script>

<style lang="scss" scoped>

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  @media (--t) {
    padding: unset;
  }

  &-title {
    display: block;
    font-size: 38px !important;
    line-height: 1.1;
    text-align: center !important;
    color: #000000 !important;
    font-family: var(--font-family--alethia)!important;
    font-weight: normal !important;
    @media (--t) {
      font-size: 60px !important;
      line-height:60px !important;
    }
  }

  &-subtitle {
    display: block;
    font-family: Sk-Modernist, 'sans-serif';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.15em;
    color: #000000;
  }

}

</style>
