<template>
  <v-container class="auth">
    <form @keydown.enter="onSubmit" class="px-2 px-md-0">
      <h1 class="small-title mb-4">{{ $t('buttons.create') }}</h1>
      <template
        v-for="field in formFields"
      >
        <component
          :loading="loading"
          :is="field.comp"
          :key="field.name"
          :ref="field.name"
          :label="field.label"
          v-model="userData[field.name]"
          :append-icon="field.icon"
          :error-messages="errors.collect(field.name)"
          data-vv-validate-on="blur lazy"
          v-validate="field.validation"
          :data-vv-name="field.name"
          :data-vv-as="field.label"
          @input="validateField(field.name)"
        />
      </template>
      <router-link :to="{name:'Privacy'}">
        <div class="page-subtitle text-left text-decoration-underline mb-3">{{ $t('buttons.privacy') }}</div>
      </router-link>

        <p class="description">{{ $t('buttons.term') }}
          <router-link :to="{name:'Terms'}">
          <span class="text-decoration-underline">{{ $t('buttons.terms_link') }}</span>
          </router-link>
        </p>
      <AppButton
        block
        color="primary"
        :loading="loading"
        @click="validateAndSubmit"
        large
      >
        {{ $t('buttons.signup') }}
      </AppButton>
      <div class=" d-flex justify-center mt-2">
        <span class="page-subtitle">{{ $t('buttons.have') }}
          <router-link class="text-dark" style="text-decoration: underline!important;" to="/login">
            {{ $t('buttons.have_sign_in') }}
          </router-link>
        </span>
      </div>
    </form>

  </v-container>
</template>

<script>
import { pickBy } from 'lodash'
import {authPagesMixins} from '@/mixins/authPagesMixins'

export default {
  name: 'SignUp',
  mixins: [authPagesMixins],
  metaInfo() {
    return {
      title: this.$t('titles.account'),
      titleTemplate: '%s - Blankspace',
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.$t('titles.home-content')},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  data() {
    return {
      userData: {
        email: null,
        first_name: null,
        last_name: null,
        password: null,
        password_confirmation: null,
      },
    }
  },
  methods: {
    onSubmit() {
      this.loading = true
      this.$store.dispatch('registerUser', this.submitObj)
        .then(data => {
          this.createSuccessNotification(this.$t('notify.register_done'))
          return this.$store.dispatch('getCurrentUserData')
        })
        .then(user => this.$router.push({name: 'Home'}))
        .catch(err => {
          if (err.response.data.error === 'email_already_exists') {
            this.createErrorNotification(this.$t('notify.user_exists'))
            this.$router.push({name: 'SignIn'})
          } else {
            this.createErrorNotification(this.$t('notify.wrong'))
          }
        })
      .then(() => {
        this.loading = false
      })
    },
  },

  computed: {
    karavanUserFields() {
      return [
        'email',
        'password',
        'password_confirmation'
      ]
    },
    contactPeopleFields() {
      return ['first_name', 'last_name']
    },
    submitObj() {
      return {
        karavan_user: {
          ...pickBy(this.userData, (value, key) => this.karavanUserFields.includes(key)),
          preferred_language: this.$i18n.locale
        },
        contact: {
          contact_people_attributes: {
            ...pickBy(this.userData, (value, key) => this.contactPeopleFields.includes(key))
          },
          preferred_language: this.$i18n.locale
        }
      }
    },
    formFields() {
      return [
        {
          comp: 'AppFormInput',
          icon: '',
          label: this.$t('placeholder.first_name'),
          name: 'first_name',
          validation: 'required|alpha_spaces'
        },
        {
          comp: 'AppFormInput',
          icon: '',
          label: this.$t('placeholder.last_name'),
          name: 'last_name',
          validation: 'required|alpha_spaces'
        },
        {
          comp: 'AppFormInput',
          icon: '',
          label: this.$t('placeholder.email'),
          name: 'email',
          validation: 'required|email'
        },
        {
          comp: 'AppPasswordInput',
          icon: '',
          label: this.$t('placeholder.create_password'),
          name: 'password',
          validation: 'required|min:6|max:255'
        },
        {
          comp: 'AppPasswordInput',
          icon: '',
          label: this.$t('placeholder.confirm_password'),
          name: 'password_confirmation',
          validation: 'required|min:6|max:255|confirmed:password'
        },
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 85vh;


  .text-dark {
    display: inline-block;
    text-decoration: underline !important;
    color: #000000;

  }

  form {
    //margin-top: 12%;
    @media (--t) {
      max-width: 410px;
    }

    .description {
      cursor: pointer;
      font-family: Sk-Modernist, "sans-serif";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #878787;

      span {
        color: black;
        cursor: pointer;
      }
    }
  }

}
</style>
