<template>
  <div class="shipment-step-page mb-10">
    <v-container class="shipment-page-container">
      <div class="page-header-container">
        <h1 class="page-title mb-3">Blankspace</h1>
        <v-breadcrumbs divider="/">
          <v-breadcrumbs-item :to="{ name: 'Cart' }">{{ $t('shipment.inner_routes.cart') }}</v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'ShipmentStep' }">
            {{ $t('shipment.inner_routes.ship') }}
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </div>
      <v-row class="shipment-page-layout-row">
        <!-- SHIPMENT AND INVOICE DATA FORM SECTION-->
        <v-col class="shipment-form-section" cols="12" lg="6">
          <h2 class="page-subtitle">{{ $t('shipment.deliveryOptions.title') }}</h2>
          <hr class="my-4"/>
          <v-btn-toggle
              mandatory
              borderless
              dense
              class="d-flex justify-space-between radio-button-group flex-column flex-md-row"
              v-model="order_data.deliveryOption"
          >
            <v-tooltip color="primary" top max-width="250">
              <template v-slot:activator="{ on }">
                <v-btn
                    v-on="option.value === 'pickup' && {...on}"
                    v-for="(option, index) in deliveryOptions"
                    :key="`option-${index}`"
                    class="radio-button flex-fill mx-0 mx-md-5 mb-4 mb-md-0"
                    :value="option.value"
                    :disabled="option.disabled"
                >
                  <div class="flex-column">
                    <div class="logo">
                      <component
                          :is="option.icon"
                          :width="40"
                          :height="40"
                          :fill="option.iconFill"
                      />
                    </div>
                    <span>{{ option.name }}</span>
                  </div>
                </v-btn>
              </template>
              <span>{{ pickupLocationAddress }}</span>
            </v-tooltip>


          </v-btn-toggle>
          <!-- EMAIL FORM FOR VISITORS -->
          <div v-if="!currentUserData">
            <v-row class="align-center d-flex pl-0">
              <v-col lg="6" cols="12">
                <h1 class="large-text">{{ $t('shipment.contact') }}</h1>
                <span class="page-subtitle"
                >{{ $t('shipment.already') }}
                  <router-link :to="{ name: 'SignIn' }" class="login-link"
                               style="color: #009771 !important">{{ $t('navbar.login') }}</router-link>
                </span>
              </v-col>
              <v-col lg="6" cols="12">
                <!-- E-mail -->
                <AppForm :loading="loading" :form-fields="emailFormFields"
                         :validationPerformed="validationPerformed"
                         :formData="order_data.karavan_order.contact_person"
                         ref="emailForm"/>
              </v-col>
            </v-row>
            <hr class="my-4"/>
          </div>
          <AppSelect
              v-else
              item-text="street1"
              :label="$t(`shipment.select_${!onlyBillingAddress ? 'shipment' : 'payment'}`)"
              :items="addressListData"
              v-model="saved_address_data" @input="SELECT_SAVED_ADDRESS" return-object
          />
          <template v-if="!onlyBillingAddress">
            <div class="shipment-selection-section">
              <h2 class="page-subtitle">{{ $t('shipment.shipping') }}</h2>
              <AppCheckbox v-if="currentUserData" :label="$t('shipment.save_address')" v-model="save_address"/>
            </div>
            <hr class="my-4"/>
          </template>

          <!--  SHIPMENT FORM SECTION  -->
          <div class="shipment-address-form-section">
            <AppForm v-if="!onlyBillingAddress" class="mt-7" :form-fields="shipmentAddressFormFields"
                     :validationPerformed="validationPerformed"
                     :formData="userData"
                     :loading="loading"
                     ref="form"/>
            <div class="billing-selection-section">
              <h2 class="page-subtitle">{{ $t('shipment.billing') }}</h2>
              <AppCheckbox v-if="!onlyBillingAddress" v-model="order_data.karavan_order.billing_same_shipping"
                           :label="$t('shipment.same')"/>
            </div>
          </div>

          <!--  INVOICE FORM SECTION  -->
          <div v-if="!order_data.karavan_order.billing_same_shipping || onlyBillingAddress"
               class="billing-address-form-section">
            <hr/>
            <AppForm :form-fields="billingAddressFormFields"
                     :validationPerformed="validationPerformed"
                     :formData="order_data.karavan_order.contact_billing_address"
                     :loading="loading"
                     ref="billingAddressForm" class="mt-7"/>
          </div>
        </v-col>
        <v-col class="order-information-section" lg="4">
          <div class="order-info-card-container">
            <div class="order-info-card">
              <PurchaseShopItem
                  v-for="(item, index) in get_cart_without_sample_kit"
                  :key="index"
                  :frame="item.frame"
                  :gift_card_value="item.name"
                  :height="item.height"
                  :layout="item.layout"
                  :product="item.product_type"
                  :quantity="item.quantity"
                  :src="item.thumbnail_base64"
                  :total="item.price * item.quantity"
                  :width="item.width"
              />
            </div>
            <div class="order-info-footer">
              <v-row v-if="cart_total_amount">
                <v-col class="order-title-section">
                  <span class="medium">{{ $t('cart.subtotal') }}</span>
                </v-col>
                <v-col class="order-value-section">
                  <span class="medium">${{ _sub_total.toFixed(2) }}</span>
                </v-col>
              </v-row>

              <v-row v-if="hasSampleKit" class="my-0 py-0">
                <v-col class="order-title-section">
                  <span class="medium">{{ $t('cart.sample_pack_kit') }}</span>
                </v-col>
                <v-col class="order-value-section">
                  <span class="medium">$ {{ getSampleKit.price.toFixed(2) }}</span>
                </v-col>
              </v-row>

              <DiscountCard/>
              <hr/>

              <v-row>
                <v-col cols="12" class="my-0 pb-0">
                  <GiftCardSummary class="py-0"/>
                </v-col>
                <v-col cols="12" class="my-0 py-0 d-flex justify-space-between align-center">
                  <span class="medium">{{ $t('cart.estimated') }}</span>
                  <span class="medium">${{ _cart_total.toFixed(2) }}</span>
                </v-col>
              </v-row>
            </div>
          </div>
          <!-- GIFT CARD AND COUPON CODE FORM -->
          <form class="discount-form-section" @keydown.enter.prevent="APPLY_DISCOUNT">
            <v-row class="mt-4" dense>
              <v-col>
                <AppFormInput
                    :label="$t('placeholder.gift_code')"
                    v-model="couponForm.reward_code"
                    :class="{
                    'dense-legend': gift_pin_active && !!couponForm.reward_code,
                  }"
                    hide-details
                />
              </v-col>
              <v-col v-if="gift_pin_active && !!couponForm.reward_code">
                <AppFormInput :label="$t('placeholder.gift_card_pin')" v-model="couponForm.reward_pin" hide-details/>
              </v-col>
              <v-col>
                <AppButton width="200" height="50" @click="APPLY_DISCOUNT">
                  {{ $t('buttons.apply') }}
                </AppButton>
              </v-col>
            </v-row>
          </form>
          <AppButton :loading="loading" class="mt-4" @click="validateAndSubmit" height="50" block>
            {{ $t('buttons.save_checkout') }}
          </AppButton>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {axiosInstance} from '@/utils/setAxios'
import {mapGetters} from 'vuex'
import localforage from 'localforage'
import {addressPageMixins} from '@/mixins/addressPageMixins'
import {cloneDeep, isObject, pick} from 'lodash'
import ShipSvg from '@/components/ui/ShipSvg'
import ShopSvg from '@/components/ui/ShopSvg'
import SimpleFooter from '@/components/shared/navigation/SimpleFooter'

export default {
  components: {
    SimpleFooter,
    DiscountCard: () => import('@/components/cards/shared-card/DiscountCard'),
    GiftCardSummary: () => import("@/components/cards/shared-card/GiftCardSummary"),
    ShopSvg,
    ShipSvg
  },
  name: 'ShipmentStep',
  mixins: [addressPageMixins],
  watch: {
    'couponForm.reward_code': {
      handler(val, oldVal) {
        if (oldVal && !val) this.gift_pin_active = false
      },
    },
    async isShip(isShip) {
      if (this._get_billing_same_shipping === false && isObject(this._get_selected_billing_address)) {
        this.order_data.karavan_order.billing_same_shipping = false;
      } else {
        this.order_data.karavan_order.billing_same_shipping = isShip
      }
      // this.order_data.karavan_order.billing_same_shipping = this.onlyBillingAddress || isShip
      if (isShip) {
        this.order_data.pickup_location = null;
      } else {
        if (isObject(this.saved_address_data)) {
          this.SELECT_SAVED_ADDRESS();
        }
        if (!this.order_data.pickup_location) {
          // this.order_data.pickup_location = await this.getPickupLocation();
          this.order_data.pickup_location = this.pickupLocation?.id
        }
      }
      console.log("this.order_data.pickup_location", this.order_data.pickup_location)
    },
    onlyBillingAddress(onlyBillingAddress) {
      if (onlyBillingAddress) {
        if (isObject(this.saved_address_data)) {
          this.SELECT_SAVED_ADDRESS({}, true);
        }
      }
    }
  },
  data() {
    return {
      order_data: {
        karavan_order: {
          billing_same_shipping: true,
          contact_person: {
            email: '',
          },
          contact_billing_address: {
            company_name: '',
            first_name: '',
            last_name: '',
            street1: '',
            street2: '',
            city: '',
            zipcode: '',
            country: '',
            state: '',
            phone: '',
          },
          contact_shipping_address: {
            company_name: '',
            first_name: '',
            last_name: '',
            street1: '',
            street2: '',
            city: '',
            zipcode: '',
            country: '',
            state: '',
            phone: '',
          },
        },
        order_items: {
          items: [],
        },
        deliveryOption: null,
        pickup_location: null
      },
      couponForm: {
        reward_code: '',
        reward_pin: null,
      },
      pickupLocation: null,
      gift_pin_active: false,
      order_id: null,
      save_address: false,
      saved_address_data: null,
      loading: false,
      validationPerformed: false
    }
  },
  methods: {
    getPickupLocation() {
      return this.$karavanAdapter.get("stores/info").then(({data: {store: {pickup_locations}}}) => {
        console.log("pickup_locations[0]", pickup_locations[0])
        return pickup_locations[0] || {
          "id": "5dd466a9bfcdb60c259b7785",
          "name": "Main Location",
          "address": {
            "street1": "2330 Rue Masson",
            "street2": "",
            "city": "Montreal",
            "state": "QC",
            "country": "CA",
            "phone": null,
            "zipcode": "H2G 2A6"
          }
        }
      })
    },
    validateAndSubmit() {
      this.loading = true;
      let validations = []
      if (!this.onlyBillingAddress) {
        validations = [this.$refs.form.$validator.validateAll()];
        if (!this.order_data.karavan_order.billing_same_shipping) validations.push(this.$refs.billingAddressForm.$validator.validateAll())
        if (!this.getAuthStatus) validations.push(this.$refs.emailForm.$validator.validateAll());
      } else {
        validations = [this.$refs.billingAddressForm.$validator.validateAll()]
        if (!this.getAuthStatus) validations.push(this.$refs.emailForm.$validator.validateAll());
      }

      this.validationPerformed = true;

      Promise.all(validations).then(validation_results => {
        if (validation_results.every(v => v)) this.CREATE_NEW_ORDER()
        else this.loading = false;
      })
    },
    CREATE_NEW_ORDER() {
      window.scroll(0, 0)
      this.order_data.karavan_order.contact_shipping_address = {
        ...this.userData,
      }

      if (this.order_data.karavan_order.billing_same_shipping) {
        this.order_data.karavan_order.contact_billing_address = this.order_data.karavan_order.contact_shipping_address

      }
      if (this.save_address && this.currentUserData) {
        this.CREATE_ADDRESS()
      }

      this.$store.commit("SET_ITEMS_COUNT")
      if (this.order_id) this.UPDATE_ORDER_AND_PRODUCTS()
      else this.CREATE_ORDER()
    },
    // ORDER CREATING AND UPDATING ACTIONS
    //!================================================== GK ====================================================================================================
    //! Order'a gönderilecek data'yi temizlemeye filtelemeye yarayan fonksiyon
    GET_ORDER_DATA() {
      // TODOGK Eğer Cart Item içerisinde Gift Card Varsa sadece onları seç..!! ?? XXGIFTCARDXX
      if (this._cart_has_gift_card || this.hasSampleKit) {
        this.order_data.order_items.items = this.$store.getters.get_cart.filter((item) => ["sample_pack_kit", "gift_card"].includes(item.product_type))
      }

      let orderData = {
        ...this.order_data
      }

      if (orderData.deliveryOption === "pickup" && orderData.pickup_location !== null) {
        orderData.karavan_order.contact_shipping_address = {
          company_name: null,
          first_name: null,
          last_name: null,
          street1: null,
          street2: null,
          city: null,
          zipcode: null,
          country: null,
          state: null,
          phone: null,
        }
      } else {
        delete orderData.pickup_location
      }

      delete orderData.deliveryOption;

      if (orderData?.billing_same_shipping) {
        delete orderData.contact_billing_address
      }

      return orderData
    },
    //! ADD_TO_CUSTOM_PRODUCT öncesindeki ürünlerin son ayarlarının yapılması.
    PREPARE_PRODUCTS_TO_ADD_TO_ORDER() {
      let cartItemList = JSON.parse(JSON.stringify(this.$store.getters.get_cart.filter((item) => !["gift_card", "sample_pack_kit"].includes(item.product_type))))
      cartItemList = cartItemList?.map((item) => ({...this.excludePick(item, ['svg_file_base64', 'thumbnail_base64', 'svg_file_id', 'thumbnail_id'])}))
      const customProductModel = {custom_products: {order_id: this.order_id, products: cartItemList}}

      this.$store.commit('SET_SUMMARY_TOTAL', this.$store.getters.cart_total_amount)
      this.$store.dispatch('ADD_CUSTOM_PRODUCT', customProductModel)
    },
    //! Bu Arkadaşın amacı Summary ve Oluşan Order ID Bilgisini Saklamak. Özel bir sebebi yok bunu ayırmamın kodu yalınlaştırmak için yapıldı.
    SET_ORDER_INFO_AND_SUMMARY({id}) {
      this.order_id = id
      localStorage.setItem('order_id', this.order_id)
      this.$store.commit('SET_SUMMARY_ID', this.order_id)
      this.$store.commit('SET_SUMMARY_INFO', {...this.order_data})
    },

    CREATE_ORDER() {
      // return false;
      // this.$store.commit('SET_LOADER', true)
      this.$karavanAdapter
          .post('orders', this.GET_ORDER_DATA())
          .then((create_order_response) => {
            if (create_order_response?.status === 201) {
              this.SET_ORDER_INFO_AND_SUMMARY(create_order_response.data)
              //! GIFTCARD Kontrolleri..
              if (this._cart_has_gift_card || this.hasSampleKit) {
                const {applied_taxes, total_discount, total_amount} = create_order_response.data
                this.SAMPLE_KIT_AND_GIFT_CARD_CONTROL(create_order_response?.data)
                this.$store.commit('SET_APPLIED_TAXES', applied_taxes || [])
                this.$store.commit('SET_TOTAL_AMOUNT', total_amount || 0)
              }
              //! Product'ları Order'a Ekleyelim.
              this.PREPARE_PRODUCTS_TO_ADD_TO_ORDER()
            }
          })
          .catch((e) => this.createErrorNotification(this.$t('notify.wrong')))
    },
    async UPDATE_ORDER_AND_PRODUCTS() {
      //! ORDER INFO UPDATE.
      this.UPDATE_ORDER_INFO().then((update_order_info_response) => {
        const cartHasNewItem = this.$store.getters.get_cart?.filter(i => !["gift_card", "sample_pack_kit"].includes(i.product_type))?.some((item) => !item?.id) //! Hepsi id bilgisine sahipse yeni kayit yok demektir..

        this.$store
            .dispatch('UPDATE_ORDER_PRODUCTS')
            .then((update_order_products_response) => {
              this.SAMPLE_KIT_AND_GIFT_CARD_CONTROL(update_order_products_response?.data || {});
              //! AYNI İŞLEM GIFT CARD İÇİN DE YAPILACAK..
              if (cartHasNewItem) {
                //! ADD PRODUCTS
                this.PREPARE_PRODUCTS_TO_ADD_TO_ORDER()
              } else {
                this.$store.commit('SET_SUMMARY_TOTAL', this.$store.getters.cart_total_amount)
                this.$store.dispatch('UPDATE_SELECTED_SHIPMENT')
              }
            })
            .catch((e) => {
              this.$store.commit('SET_LOADER', false)
            })
      })
    },
    //! ORDER Adres bilgisini Update eden metod.
    UPDATE_ORDER_INFO() {
      const addressModel = {
        karavan_order: {
          billing_same_shipping: this.order_data.karavan_order.billing_same_shipping,
          // contact_shipping_address: {
          //   ...this.isShip ? {...this.order_data.karavan_order.contact_shipping_address } : {}
          // },
          contact_shipping_address: this.order_data.karavan_order.contact_shipping_address,
          contact_billing_address: this.order_data.karavan_order.contact_billing_address,
        },
        ...!this.isShip && {pickup_location: this.order_data?.pickup_location}
      }
      // this.$store.commit('SET_LOADER', true)
      return axiosInstance
          .patch(`orders/${this.order_id}/update_order_info`, addressModel)
          .then((response) => {
            const {shipment_rates} = response.data
            this.$store.commit('SET_SHIPMENT_RATES', shipment_rates || [])
            this.$store.commit('SET_SUMMARY_INFO', {...this.order_data})
          })
          .catch(() => this.createErrorNotification(this.$t('notify.wrong')))
          .finally(() => {
            this.$store.commit('SET_LOADER', false)
          })
    },
    //!================================================== GK ====================================================================================================
    SAMPLE_KIT_AND_GIFT_CARD_CONTROL({invoice_items}) {
      // TODOGK : Tekrar tekrar aynı cümleyi, filter, map ve condition olarak kullanmak doğru değil. XXGIFTCARDXX
      //! Silinen SamplePackKit Handler
      if (this.hasDeletedSampleKit) {
        this.$store.commit("REMOVE_SAMPLE_PACK_KIT")
      }
      if (invoice_items && (this._cart_has_gift_card || this.hasSampleKit)) {
        this.$store.dispatch('SET_CUSTOM_PRODUCT', invoice_items)
      }
      // TODO XXGIFTCARDXX
      if (this._only_gift_card) {
        this.$router.push({name: 'Payment'})
        return false
      }
    },
    // GIFT CARD AND COUPON CODE APPLY
    APPLY_DISCOUNT() {
      if (this._only_gift_card) {
        this.createWarningNotification(this.$t('notify.invalid_discount_code_for_only_gift_card'))
        return;
      }

      axiosInstance
          .get('rewards/verify_reward_eligibility', {
            params: {
              reward_code: this.couponForm.reward_code?.toLowerCase(),
              reward_pin: this.couponForm.reward_pin,
            },
          })
          .then((response) => {
            //! GIFT_CARD ACTIONS
            if (response.data.reward?.program === 'gift_card' && response.data.gift_card_active) {
              const STORED_GIFT_CARDS = this.$store.state.order.gift_cards
              const eligible = response.data.eligible
              if (!STORED_GIFT_CARDS.map((e) => e.code).includes(this.couponForm.reward_code)) {
                this.gift_pin_active = true
                const model = {
                  temp_id: new Date().getTime(),
                  code: this.couponForm.reward_code,
                  pin: this.couponForm.reward_pin,
                  data: {...response.data},
                }

                //! Bu nedir? Neden eligible bu şekilde kontrol edildi ve neden gift card tutarı total'den büyük olunca sorun?
                // if (!!eligible && response.data.reward.amount > this._cart_total) {
                //!! Eğer eligible ise ve giftCard amount > sub Total'den devam et...
                // if (eligible && response.data.reward.amount < this.cart_total_amount) return this.createErrorNotification(this.$t('notify.wrong'))
                if (!eligible && !!this.couponForm.reward_pin) this.createErrorNotification(this.$t('wrongPinCodeAlert'))
                else if (eligible) {
                  this.createSuccessNotification(this.$t('notify.gift_card_applied'))
                  this.$store.commit('SET_GIFT_CARD', model)
                  this.gift_pin_active = false
                }
              } else this.createErrorNotification(this.$t('notify.already'))
            }


            //! DISCOUNT ACTIONS
            else if (response.data.reward?.program === 'discount') {

              const {program} = this.coupon || {};
              const discountCode = this.couponForm.reward_code?.toLowerCase();

              if (program === "discount" && this.couponCode === discountCode) {
                this.createWarningNotification(this.$t('notify.discount_code_already_card_applied'))
                return;
              }

              const STORED_DISCOUNT = this.$store.state.order?.coupon || ''
              if (!(STORED_DISCOUNT === this.couponForm.reward_code)) {
                const model = {
                  temp_id: new Date().getTime(),
                  code: this.couponForm.reward_code,
                  ...response.data,
                }
                // this.$store.dispatch('DELETE_COUPON')
                this.$store.commit("SET_DISCOUNT_AMOUNT", 0)
                this.$store.commit('SET_COUPON', model)
                this.createSuccessNotification(this.$t('notify.discount_done'))
              } else this.createErrorNotification(this.$t('notify.already'))
            } else {
              this.createErrorNotification(this.$t('notify.code_not_exist'))
            }
            if (!!response.data?.eligible) {
              this.couponForm.reward_code = ''
              this.couponForm.reward_pin = null
            }
          })
          .catch((err) => {
            if (err?.response?.status === 406) {
              this.createErrorNotification(this.$t('notify.code_not_exist'))
            }
          })
    },
    // CREATE NEW ADDRESS
    CREATE_ADDRESS() {
      this.$store.dispatch('createBillingAddress', this.submitObj)
    },
    SELECT_SAVED_ADDRESS(addressData, hasUpdatedAddress) {
      this.userData = {
        ...this.saved_address_data,
        ...this.contactPerson,
        ...hasUpdatedAddress && this._get_selected_shipping_address,
      }

      this.userData.email = this.currentUserData.karavan_user.email
      if (this.onlyBillingAddress) {
        this.order_data.karavan_order.contact_billing_address = this._get_selected_billing_address || this.userData
      }

      if (this.validationPerformed) {
        setTimeout(() => {
          this.$refs.form.$validator.validateAll()
        }, 200)
      }
    },
  },
  computed: {
    ...mapGetters(['getAuthStatus', 'currentUserData', 'get_cart', 'get_cart_without_sample_kit', 'cart_total_amount', 'coupon', 'discountAmount', 'get_coupon_amount', 'appliedGiftCardsAmount', 'couponDescription', 'discountAvailable', 'giftCardAvailable', 'hasSampleKit', 'hasDeletedSampleKit', 'getSampleKit', "samplePackKitObject", "estimatedDiscountAvailable", "estimatedDiscountAmount", "_only_gift_card", "_cart_has_gift_card", "get_order_info", "get_order_summary", "coupon", "couponCode"]),
    //! Aşağıdaki metotların yazılmasının sebebi getter ile bu bilgilerin tutarlı bir şekilde gelmemesidir.
    _get_billing_same_shipping() {
      return this.$store.state.order.order_summary?.order_info?.karavan_order?.billing_same_shipping;
    },
    _get_selected_billing_address() {
      return this.$store.state.order.order_summary?.order_info?.karavan_order?.contact_billing_address;
    },
    _get_selected_shipping_address() {
      return this.$store.state.order.order_summary?.order_info?.karavan_order?.contact_shipping_address;
    },
    _get_delivery_option() {
      return this.$store.state.order.order_summary?.order_info?.deliveryOption
    },
    total_amount() {
      return this.get_cart?.reduce((acc, item) => acc + item.price * item.quantity, 0) || 0
    },
    couponCode() {
      return this.get_coupon_amount.code || null
    },
    _cart_has_gift_card() {
      const giftCardCondition = (item) => item.product_type === 'gift_card'
      return this.$store.getters.get_cart.some(giftCardCondition)
    },
    _sub_total() {
      return this.cart_total_amount
    },
    _cart_total() {
      let discountAmount = this.discountAvailable ? this.discountAmount : 0
      if (this.estimatedDiscountAvailable) discountAmount = this.estimatedDiscountAmount;
      const giftCardAmount = this.giftCardAvailable ? this.appliedGiftCardsAmount : 0
      const samplePackKitAmount = this.hasSampleKit ? 10 : 0;
      const total = this.cart_total_amount + samplePackKitAmount - discountAmount - giftCardAmount
      return total > 0 ? total : 0
    },
    selectedAddressIndex() {
      return this.saved_address_data ? this.currentUserData.contact.addresses.findIndex(a => a.id === this.saved_address_data?.id) : null
    },
    contactPerson() {
      return this.selectedAddressIndex !== null ? this.currentUserData?.contact?.contact_people?.[this.selectedAddressIndex] : null
    },
    initialAddressAvailable() {
      //! Süreçsel olarak ilk user creation işleminden sonra address ve contact people attributes dummy şekilde üretiliyor.
      //! Kullanıcı ilk alışverisinde bir adres girdiği zaman bu dummy kayit yerine girilen adres kayıt ediliyor yani sadece bu senaryo için
      //! yeni bir adres üretilmiyor. (Daha sonra tabi ki de yeni adres üretiliyor.)
      //! Bu Computed işte bu koşulların sağlanıp sağlanmadığını kontrol ediyor.
      const condition = this.currentUserData?.contact?.addresses?.length === 1 && isObject(this.currentUserData?.contact?.addresses?.find(a => !a.street1));
      if (condition) {
        const contact = this.currentUserData?.contact;
        return {
          address_id: contact?.addresses[0]?.id,
          contact_people_id: contact?.contact_people?.[0]?.id
        }
      }
      return false
    },
    addressListData() {
      const contactPeople = this.currentUserData?.contact?.contact_people;
      return this.currentUserData?.contact?.addresses?.filter(i => !i._destroy && i.street1)?.map((address, index) => {
        const addressObject = {
          ...address,
          ...contactPeople?.[index] && pick(contactPeople?.[index], ["phone"])
        }
        return addressObject
      }) || [];
    },
    deliveryOptions() {
      return [
        {
          name: this.$t('shipment.deliveryOptions.ship'),
          icon: ShipSvg,
          iconFill: this.order_data.deliveryOption === 'ship' ? '#009771' : '#000000',
          value: 'ship'
        },
        {
          name: this.$t('shipment.deliveryOptions.pickup'),
          icon: ShopSvg,
          disabled: false,//!this.availableStores.length,
          iconFill: this.order_data.deliveryOption === 'pickup' ? '#009771' : '#000000',
          value: 'pickup'
        }
      ]
    },
    isShip() {
      return this.order_data.deliveryOption === 'ship'
    },
    onlyBillingAddress() {
      return this._only_gift_card || !this.isShip
    },
    shipmentAddressFormFields() {
      // if(this.saved_address_data){
      //   return this.shipmentStepFormFields?.filter(f => f.name !== 'name')
      // }
      return this.shipmentStepFormFields?.filter(f => f.name !== 'name')
    },
    pickupLocationAddress() {
      const {address} = this.pickupLocation || {}
      const {street1, city, country} = address || {}
      return this.$t("shipment.deliveryOptions.pickup_address_tooltip", {street: street1, city})
    },

  },
  async beforeCreate() {
    const restored = await this.$store?.restored;
  },
  async mounted() {
    const restored = await this.$store?.restored;
    this.order_id = localStorage.getItem('order_id') || null

    if (this.$store.getters.getAuthStatus) {
      setTimeout(() => {
        this.order_data.karavan_order.contact_person.email = this.currentUserData?.karavan_user?.email
        this.order_data.karavan_order.billing_same_shipping = this._get_billing_same_shipping;
        this.userData.email = this.currentUserData?.karavan_user?.email

        //this.order_id &&
        if (isObject(this._get_selected_shipping_address)) {
          this.saved_address_data = this.addressListData?.find(a => this._get_selected_shipping_address.street1 && a.street1 === this._get_selected_shipping_address.street1)
          this.SELECT_SAVED_ADDRESS({}, true)
        }

        //this.order_id &&
        if (this._get_billing_same_shipping === false && isObject(this._get_selected_billing_address)) {
          this.order_data.karavan_order.contact_billing_address = {
            ...this._get_selected_billing_address
          }
        }

        if (!this._get_billing_same_shipping && !this._get_selected_billing_address) {
          this.order_data.karavan_order.billing_same_shipping = true;
        }

        if (!this.addressListData?.length) {
          this.save_address = true;
        }

      }, 1000)
    } else {
      setTimeout(() => {
        //! Eğer kullanıcı giriş yapmamışsa ve adres bilgisi varsa onu da set etmemiz gerekiyor.
        if (this.order_id && isObject(this._get_selected_shipping_address)) {
          this.order_data.karavan_order.billing_same_shipping = this._get_billing_same_shipping;
          this.order_data.karavan_order.contact_person.email = this.get_order_summary?.order_info?.karavan_order?.contact_person?.email
          this.userData = {
            ...this._get_selected_shipping_address
          }
        }

        if (this.order_id && this._get_billing_same_shipping === false && isObject(this._get_selected_billing_address)) {
          this.order_data.karavan_order.contact_billing_address = {
            ...this._get_selected_billing_address
          }
        }

        if (!this._get_billing_same_shipping && !this._get_selected_billing_address) {
          this.order_data.karavan_order.billing_same_shipping = true;
        }

        if (!this.addressListData?.length) {
          this.save_address = true;
        }


      }, 1000)
    }

    this.pickupLocation = await this.getPickupLocation()

    if (this._get_delivery_option) {
      this.order_data.deliveryOption = this._get_delivery_option;

      if(this.order_data.deliveryOption === "pickup"){
        this.order_data.pickup_location = this.pickupLocation?.id
      }
    }

  },
  beforeDestroy() {
    localforage.removeItem('discounted_total')
    localforage.removeItem('discount_value')
    localforage.removeItem('coupon_value')
    this.$store.commit('SET_SUMMARY_TOTAL', this.cart_total_amount)
  },
}
</script>

<style lang="scss" scoped>
.radio-button-group {
  background-color: #FAFAFA !important;
  margin-bottom: 50px;
  margin-top: 16px;

  .radio-button {
    padding: 16px 32px !important;
    border: 1px solid #DCD9D1 !important;
    border-radius: 8px !important;
    background-color: #fff !important;
    color: #B6B6B6 !important;
    transition: all ease .3s;
    height: auto !important;

    &:first-child {
      margin-left: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }

    &.v-btn--active {
      //color: $secondary-color !important;
    }
  }
}

.container {
  @media (min-width: 1400px) {
    max-width: 1320px;
  }
}

.shipment-step-page {
  hr {
    margin: 12px 0;
    background-color: #9f9f9f;
  }

  .shipment-page-container {
    .page-header-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 0 20px 0;
      @media (--t) {
        padding: 0 0 0 0;
        flex-direction: row;
        justify-content: space-between;
      }

      .page-title {
        text-align: left;
      }

      .breadcrumb {
        background-color: transparent;
        padding-top: 0;
        padding-left: 0;

        .breadcrumb-item {
          font-family: "Sk-Modernist", sans-serif;
          color: #393939;
        }

        .active {
          color: #000000;
        }
      }
    }

    .shipment-page-layout-row {
      display: flex;
      justify-content: space-between;

      @media (--t) {
        margin-bottom: 50px !important;
      }

      .shipment-form-section {
        h2 {
          text-align: left;
        }

        .shipment-selection-section {
          display: flex;
          flex-direction: column;

          h2 {
            margin-bottom: 16px;
          }

          @media (--t) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            h2 {
              margin: 0;
            }
          }
        }

        .billing-selection-section {
          display: flex;
          flex-direction: column;

          h2 {
            margin-bottom: 16px;
          }

          @media (--t) {
            h2 {
              margin: 0;
            }
            flex-direction: row;
            justify-content: space-between;
          }
        }

        .billing-address-form-section {
          .form-long {
            margin-top: 0 !important;
          }
        }
      }

      .order-information-section {
        height: unset;
        @media (--t) {
          height: 80vh;
        }

        .order-info-card-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 62.5vh;
          //padding: 30px;
          padding: 30px 0;
          background-color: #e8e8e8 !important;

          .order-info-card {
            overflow-x: hidden;
            overflow-y: auto;
            padding: 0 30px;
          }

          .order-info-footer {
            padding: 0 30px;
          }

          .order-title-section {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
          }

          .order-value-section {
            display: flex;
            flex-direction: column !important;
            align-items: flex-end !important;
          }
        }
      }
    }
  }
}
</style>
