<template>
  <v-row v-if="giftCardAvailable">
    <v-col class="d-flex justify-space-between align-center">
      <div class="medium" style="display: flex; justify-content: center; align-items: center;">
        <span class="medium">{{ $t('cart.gift')}}</span>
        <v-tooltip color="error" top>
          <template v-slot:activator="{ on }">
            <v-btn class="black--text" icon x-small v-on="on" @click="$store.commit('DELETE_GIFT_CARDS')">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span> {{ $t('cart.remove') }}</span>
        </v-tooltip>
      </div>
      <span class="medium error--text">- ${{ appliedGiftCardsAmount.toFixed(2) }}</span>
    </v-col>
  </v-row>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "gift-card-summary",
  computed: {
    ...mapGetters({
      giftCardAvailable: "giftCardAvailable",
      appliedGiftCardsAmount: "appliedGiftCardsAmount",
      cartTotalAmount: "get_total_amount",
    }),
    giftCardDescription() {
      return `You have $${this.appliedGiftCardsAmount} gift card. We will reduce this amount from general total. Thus, The Estimated total could be different.`
    }
  },
}
</script>

<style scoped>

</style>
