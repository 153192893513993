export const MaterialPrices = {
    hd_acrylic: [
        {
            min: 0,
            max: 0.4,
            price: 250.0
        },
        {
            min: 0.4,
            max: 0.5,
            price: 150.0
        },
        {
            min: 0.5,
            max: 0.7,
            price: 125.0
        },
        {
            min: 0.7,
            max: 1.0,
            price: 125.0
        },
        {
            min: 1.0,
            max: 1.4,
            price: 105.0
        },
        {
            min: 1.4,
            max: 1.7,
            price: 80.0
        },
        {
            min: 1.7,
            max: 2,
            price: 75.0
        },
        {
            min: 2,
            max: 2.7,
            price: 65.0
        },
        {
            min: 2.7,
            max: 3.5,
            price: 58.0
        },
        {
            min: 3.5,
            max: 5,
            price: 50.0
        },
        {
            min: 5,
            max: 8,
            price: 48.0
        },
        {
            min: 8,
            max: 12,
            price: 47.0
        },
        {
            min: 12,
            max: 15,
            price: 54.0
        },
        {
            min: 15,
            max: 18,
            price: 53.0
        },
        {
            min: 18,
            max: 20.17,
            price: 52.0
        },
        {
            min: 20.17,
            max: 24,
            price: 51.0
        },
        {
            min: 24,
            max: 9999,
            price: 55.0
        }
    ],
    aluminum: [
        {
            min: 0,
            max: 0.4,
            price: 185.0
        },
        {
            min: 0.4,
            max: 0.5,
            price: 120.0
        },
        {
            min: 0.5,
            max: 0.7,
            price: 100.0
        },
        {
            min: 0.7,
            max: 1.0,
            price: 90.0
        },
        {
            min: 1.0,
            max: 1.4,
            price: 80.0
        },
        {
            min: 1.4,
            max: 1.7,
            price: 58.0
        },
        {
            min: 1.7,
            max: 2,
            price: 56.0
        },
        {
            min: 2,
            max: 2.7,
            price: 54.0
        },
        {
            min: 2.7,
            max: 3.5,
            price: 52.0
        },
        {
            min: 3.5,
            max: 5,
            price: 47.0
        },
        {
            min: 5,
            max: 8,
            price: 44.0
        },
        {
            min: 8,
            max: 12,
            price: 41.0
        },
        {
            min: 12,
            max: 15,
            price: 46.0
        },
        {
            min: 15,
            max: 18,
            price: 48.0
        },
        {
            min: 18,
            max: 20.17,
            price: 49.0
        },
        {
            min: 20.17,
            max: 24,
            price: 51.0
        },
        {
            min: 24,
            max: 9999,
            price: 54.0
        }
    ],
    satin_photo_paper: [
        {
            min: 0,
            max: 0.4,
            price: 34.0
        },
        {
            min: 0.4,
            max: 0.5,
            price: 16.0
        },
        {
            min: 0.5,
            max: 0.7,
            price: 14.0
        },
        {
            min: 0.7,
            max: 1.0,
            price: 13.0
        },
        {
            min: 1.0,
            max: 1.4,
            price: 12.0
        },
        {
            min: 1.4,
            max: 1.7,
            price: 10.0
        },
        {
            min: 1.7,
            max: 2,
            price: 8.0
        },
        {
            min: 2,
            max: 2.7,
            price: 7.5
        },
        {
            min: 2.7,
            max: 3.5,
            price: 7.5
        },
        {
            min: 3.5,
            max: 5,
            price: 7.0
        },
        {
            min: 5,
            max: 8,
            price: 6.75
        },
        {
            min: 8,
            max: 12,
            price: 7.5
        },
        {
            min: 12,
            max: 15,
            price: 6.75
        },
        {
            min: 15,
            max: 18,
            price: 6.65
        },
        {
            min: 18,
            max: 20.17,
            price: 6.35
        },
        {
            min: 20.17,
            max: 24,
            price: 6.35
        },
        {
            min: 24,
            max: 9999,
            price: 6.35
        }
    ],
    matte_photo_paper: [
        {
            min: 0,
            max: 0.4,
            price: 34.0
        },
        {
            min: 0.4,
            max: 0.5,
            price: 16.0
        },
        {
            min: 0.5,
            max: 0.7,
            price: 14.0
        },
        {
            min: 0.7,
            max: 1.0,
            price: 13.0
        },
        {
            min: 1.0,
            max: 1.4,
            price: 12.0
        },
        {
            min: 1.4,
            max: 1.7,
            price: 10.0
        },
        {
            min: 1.7,
            max: 2,
            price: 8.0
        },
        {
            min: 2,
            max: 2.7,
            price: 7.5
        },
        {
            min: 2.7,
            max: 3.5,
            price: 7.5
        },
        {
            min: 3.5,
            max: 5,
            price: 7.0
        },
        {
            min: 5,
            max: 8,
            price: 6.75
        },
        {
            min: 8,
            max: 12,
            price: 7.5
        },
        {
            min: 12,
            max: 15,
            price: 6.75
        },
        {
            min: 15,
            max: 18,
            price: 6.65
        },
        {
            min: 18,
            max: 20.17,
            price: 6.35
        },
        {
            min: 20.17,
            max: 24,
            price: 6.35
        },
        {
            min: 24,
            max: 9999,
            price: 6.35
        }
    ],
    metallic_photo_paper: [
        {
            min: 0,
            max: 0.4,
            price: 50.0
        },
        {
            min: 0.4,
            max: 0.5,
            price: 25.0
        },
        {
            min: 0.5,
            max: 0.7,
            price: 22.0
        },
        {
            min: 0.7,
            max: 1.0,
            price: 20.0
        },
        {
            min: 1.0,
            max: 1.4,
            price: 18.0
        },
        {
            min: 1.4,
            max: 1.7,
            price: 16.0
        },
        {
            min: 1.7,
            max: 2,
            price: 14.0
        },
        {
            min: 2,
            max: 2.7,
            price: 13.25
        },
        {
            min: 2.7,
            max: 3.5,
            price: 13.0
        },
        {
            min: 3.5,
            max: 5,
            price: 12.0
        },
        {
            min: 5,
            max: 8,
            price: 11.75
        },
        {
            min: 8,
            max: 12,
            price: 11.65
        },
        {
            min: 12,
            max: 15,
            price: 11.55
        },
        {
            min: 15,
            max: 18,
            price: 11.5
        },
        {
            min: 18,
            max: 20.17,
            price: 11.4
        },
        {
            min: 20.17,
            max: 24,
            price: 11.4
        },
        {
            min: 24,
            max: 9999,
            price: 11.4
        }
    ],
    canvas_frame: [
        {
            min: 0,
            max: 0.4,
            price: 155.0
        },
        {
            min: 0.4,
            max: 0.5,
            price: 80.0
        },
        {
            min: 0.5,
            max: 0.7,
            price: 62.0
        },
        {
            min: 0.7,
            max: 1.0,
            price: 58.0
        },
        {
            min: 1.0,
            max: 1.4,
            price: 55.0
        },
        {
            min: 1.4,
            max: 1.7,
            price: 43.0
        },
        {
            min: 1.7,
            max: 2,
            price: 37.0
        },
        {
            min: 2,
            max: 2.7,
            price: 30.0
        },
        {
            min: 2.7,
            max: 3.5,
            price: 28.0
        },
        {
            min: 3.5,
            max: 5,
            price: 23.0
        },
        {
            min: 5,
            max: 8,
            price: 18.0
        },
        {
            min: 8,
            max: 12,
            price: 23.0
        },
        {
            min: 12,
            max: 15,
            price: 24.0
        },
        {
            min: 15,
            max: 18,
            price: 24.0
        },
        {
            min: 18,
            max: 20.17,
            price: 25.0
        },
        {
            min: 20.17,
            max: 24,
            price: 28.0
        },
        {
            min: 24,
            max: 9999,
            price: 28.0
        }
    ],
    canvas: [
        {
            min: 0,
            max: 0.4,
            price: 100.0
        },
        {
            min: 0.4,
            max: 0.5,
            price: 65.0
        },
        {
            min: 0.5,
            max: 0.7,
            price: 55.0
        },
        {
            min: 0.7,
            max: 1.0,
            price: 44.0
        },
        {
            min: 1.0,
            max: 1.4,
            price: 35.0
        },
        {
            min: 1.4,
            max: 1.7,
            price: 28.0
        },
        {
            min: 1.7,
            max: 2,
            price: 26
        },
        {
            min: 2,
            max: 2.7,
            price: 19.0
        },
        {
            min: 2.7,
            max: 3.5,
            price: 18.0
        },
        {
            min: 3.5,
            max: 5,
            price: 15.5
        },
        {
            min: 5,
            max: 8,
            price: 13.5
        },
        {
            min: 8,
            max: 12,
            price: 13.0
        },
        {
            min: 12,
            max: 15,
            price: 12.5
        },
        {
            min: 15,
            max: 18,
            price: 11.5
        },
        {
            min: 18,
            max: 20.17,
            price: 11.0
        },
        {
            min: 20.17,
            max: 24,
            price: 11.0
        },
        {
            min: 24,
            max: 9999,
            price: 11.0
        }
    ],
    hemp_fine_art: [
        {
            min: 0,
            max: 0.4,
            price: 75.0
        },
        {
            min: 0.4,
            max: 0.5,
            price: 50.0
        },
        {
            min: 0.5,
            max: 0.7,
            price: 42.0
        },
        {
            min: 0.7,
            max: 1,
            price: 35.0
        },
        {
            min: 1,
            max: 1.4,
            price: 32.0
        },
        {
            min: 1.4,
            max: 1.7,
            price: 29.0
        },
        {
            min: 1.7,
            max: 2,
            price: 27.0
        },
        {
            min: 2,
            max: 2.7,
            price: 24.0
        },
        {
            min: 2.7,
            max: 3.5,
            price: 23.0
        },
        {
            min: 3.5,
            max: 5,
            price: 22.0
        },
        {
            min: 5,
            max: 8,
            price: 21.0
        },
        {
            min: 8,
            max: 12,
            price: 20.5
        },
        {
            min: 12,
            max: 15,
            price: 20.0
        },
        {
            min: 15,
            max: 18,
            price: 19.5
        },
        {
            min: 18,
            max: 20.17,
            price: 19.25
        },
        {
            min: 20.17,
            max: 24,
            price: 19.25
        },
        {
            min: 24,
            max: 9999,
            price: 19.25
        }
    ],
    textured_fine_art: [
        {
            min: 0,
            max: 0.4,
            price: 65.0
        },
        {
            min: 0.4,
            max: 0.5,
            price: 40.0
        },
        {
            min: 0.5,
            max: 0.7,
            price: 32.0
        },
        {
            min: 0.7,
            max: 1.0,
            price: 27.0
        },
        {
            min: 1.0,
            max: 1.4,
            price: 25.0
        },
        {
            min: 1.4,
            max: 1.7,
            price: 23.0
        },
        {
            min: 1.7,
            max: 2,
            price: 21.0
        },
        {
            min: 2,
            max: 2.7,
            price: 18.0
        },
        {
            min: 2.7,
            max: 3.5,
            price: 17.0
        },
        {
            min: 3.5,
            max: 5,
            price: 19.0
        },
        {
            min: 5,
            max: 8,
            price: 18.0
        },
        {
            min: 8,
            max: 12,
            price: 17.5
        },
        {
            min: 12,
            max: 15,
            price: 17.0
        },
        {
            min: 15,
            max: 18,
            price: 16.75
        },
        {
            min: 18,
            max: 20.17,
            price: 16.5
        },
        {
            min: 20.17,
            max: 24,
            price: 16.5
        },
        {
            min: 24,
            max: 9999,
            price: 16.5
        }
    ]
};
