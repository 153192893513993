<template>
  <button @click="selectAdd"  class="card address-select-card p-3 col col-lg-5 col-sm-12 col-md-12 mt-2">
    <span class="medium-bold text-left">{{name}}</span>
    <span class="medium-gray text-left mt-2">{{address}}</span>
    <div class="footer">
      <span class="page-subtitle text-left">{{country}} / {{state}}</span>
      <span class="page-subtitle zip">{{zipcode}}</span>
    </div>
  </button>
</template>

<script>
export default {
  name: "AddressSelectionCard",
  model: {
    event: "change",
  },
  methods:{
    selectAdd(e) {
      this.$emit("selectAdd", e);
    },
  },
  props: ["name",'zipcode','country','address','state','value']
};
</script>


<style lang="postcss" scoped>

.address-select-card {
  display: flex;
  border-radius:0;
  margin-bottom: 20px;
  border: 1px solid #000000;
  outline: none;
  min-height: 150px;
  max-height: 150px;
  margin-right: 0;
  @media (--t) {
    margin-left: 30px;
  }

  .medium-gray {
    font-size: 14px;
  }
  .footer {
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    width: 100%;
  }

  .zip {
    float: right;
  }
}


/*.selected {
  img {
    outline: 3px solid #008F71;
  }

  span {
    &:after {
      display: flex;
      align-items: center;
      justify-content: center;
      content: url("../../assets/white.png");
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 0;
      background-color: #008F71;
      z-index: 50;
      position: absolute;
      margin-right: auto;
      bottom: 0;
    }
  }
}



.material-card {
  padding: 5px;
  border: 0 !important;


  label {
    cursor: pointer;

    .title {

      @media (--t) {
        font-family: Sk-Modernist, 'sans-serif';
        font-size: 15px;
        color: black;
      }
    }

  }



  input {
    display: none;
  }

  .card-item {
    display: flex;
    flex-direction: column;
  }
}*/
</style>