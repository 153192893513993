<template>
  <div class="d-flex flex-md-column flex-column-reverse">
    <span class="configurator-content-texts">{{ option[itemText] }}</span>
    <span v-if="option.subtitle" class="configurator-content-texts">
      {{ option.subtitle }}
    </span>
    <v-img
      :class="{selected_item_material: value}"
      v-ripple
      :src="option.path"
      v-bind="_imgProps"
      width="100%"
      style="flex: 0 1 auto"
      class="configurator-content-material mt-md-4 mb-md-0"
      @click="$emit('click')"
    >
    </v-img>
  </div>
</template>

<script>
export default {
  name: 'ProductOption',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      default: () => ({})
    },
    imgProps: {
      type: Object,
      default: () => ({})
    },
    itemText: {
      type: String,
      default: () => 'title'
    }

  },

  computed: {
    _imgProps() {
      return {
        ...{
          'aspect-ratio': this.smAndDown ? 1 : 1.5
        },
        ...this.imgProps
      }
    }
  }
}
</script>

<style scoped lang="scss">
.configurator-content-texts {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 14px !important;
  color: #000000 !important;
  font-family: Sk-Modernist, "sans-serif" !important;
  max-width: 350px;
}
.configurator-content-material {
  cursor: pointer;
}
.selected_item_material {
  outline: 3px solid #129572;

  @media (min-width: 1280px) {
    &:after {
      display: flex;
      align-items: center;
      justify-content: center;
      //background-image: url("../../../assets/check.svg");
      content: url("../../assets/white-1.png");
      width: 40px;
      height: 40px;
      border-radius: 0;
      background-color: #129572;
      z-index: 50;
      position: absolute;
      margin-right: auto;
      float: left;
      bottom: 0 !important;
      padding-top: 7px;
    }
  }
}
</style>
