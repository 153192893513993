
import product_frame from "../../../data/edge-frame-material/en/frame.json";
import product_hardware from "../../../data/edge-frame-material/en/hardware.json";
import hardware from "../../../data/edge-frame-material/en/hardware.json";
import product_edge from "../../../data/edge-frame-material/en/edge.json";
import product_material from "../../../data/edge-frame-material/en/material.json";
import product_border from "../../../data/edge-frame-material/en/border.json";

import product_frame_fr from "../../../data/edge-frame-material/fr/frame-fr.json";

import product_edge_fr from "../../../data/edge-frame-material/fr/edge-fr.json";
import product_border_fr from "../../../data/edge-frame-material/fr/border-fr.json";

import posters_en from "../../../data/posters/en/posters.json";
import posters from "../../../data/posters/en/posters.json";
import posters_fr from "../../../data/posters/fr/posters-fr.json";

import product_en from "../../../data/products/en/products.json";
import products from "../../../data/products/en/products.json";
import product_fr from "../../../data/products/fr/products-fr.json";

import story_en from "../../../data/story/en/stories.json";
import stories from "../../../data/story/en/stories.json";
import story_fr from "../../../data/story/fr/stories-fr.json";

import edges_en from "../../../data/edge/en/edging.json";
import edging from "../../../data/edge/en/edging.json";
import edges_fr from "../../../data/edge/fr/edge-fr.json";

import frames_en from "../../../data/frame/en/framing.json";
import framing from "../../../data/frame/en/framing.json";
import frames_fr from "../../../data/frame/fr/framing-fr.json";

import worth_en from "../../../data/worth/en/worth.json";
import worth from "../../../data/worth/en/worth.json";
import worth_fr from "../../../data/worth/fr/worth-fr.json";

import inspire_en from "../../../data/inspired/en/inspire.json";
import inspire_fr from "../../../data/inspired/fr/inspire-fr.json";
import inspire from "../../../data/inspire.json";
import blog from "../../../data/blog.json";
import faq from "../../../data/faq.json";

const mapTitles = (items, locale = 'en') => {
    return items.map(item => ({
        ...item,
        title: item.title[locale],
        ...item.description && {description: item.description[locale]},
        ...item.subtitle && {subtitle: item.subtitle[locale]},
    }))
}
export default {
    state: {
        product_material: product_material,
        product_hardware: product_hardware,
        product_edge: product_edge,
        product_frame: product_frame,
        product_border: product_border,

        posters_en: posters_en,
        posters_fr: posters_fr,

        product_en: product_en,
        product_fr: product_fr,

        story_en: story_en,
        story_fr: story_fr,

        edges_fr: edges_fr,
        edges_en: edges_en,

        frames_en:frames_en,
        frames_fr:frames_fr,

        worth_en:worth_en,
        worth_fr:worth_fr,

        inspire_en:inspire_en,
        inspire_fr:inspire_fr,

        edges: edging,
        frames: framing,
        stories: stories,
        inspire: inspire,
        blog: blog,
        products: products,
        posters: posters,
        faq: faq,
        worth: worth,
    },
    getters: {
        get_material_en: (state) => mapTitles(state.product_material, 'en'),
        get_edge_en: (state) => mapTitles(state.product_edge, 'en'),
        get_frame_en: (state) => mapTitles(state.product_frame, 'en'),
        get_hardware_en: (state) => mapTitles(state.product_hardware, 'en'),
        get_border_en: (state) => mapTitles(state.product_border, 'en'),
        get_material_fr: (state) => mapTitles(state.product_material, 'fr'),
        get_edge_fr: (state) => mapTitles(state.product_edge, 'fr'),
        get_frame_fr: (state) => mapTitles(state.product_frame, 'fr'),
        get_hardware_fr: (state) => mapTitles(state.product_hardware, 'fr'),
        get_border_fr: (state) => mapTitles(state.product_border, 'fr'),

        get_poster_en: (state) => state.posters_en,
        get_poster_fr: (state) => state.posters_fr,

        get_product_en: (state) => state.product_en,
        get_product_fr: (state) => state.product_fr,

        get_story_en: (state) => state.story_en,
        get_story_fr: (state) => state.story_fr,

        get_edges_en: (state) => state.edges_en,
        get_edges_fr: (state) => state.edges_fr,

        get_frames_en:(state) => state.frames_en,
        get_frames_fr:(state) => state.frames_fr,

        get_worth_en:(state) => state.worth_en,
        get_worth_fr:(state) => state.worth_fr,

        get_inspire_en:(state) => state.inspire_en,
        get_inspire_fr:(state) => state.inspire_fr,

    },
}
