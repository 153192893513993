import {axiosInstance, blankAxios, publicAcademyAxios} from "@/utils/setAxios";
import Vue from "vue";
import router from "@/router";
import localforage from "localforage";
import alertMixin from "@/mixins/alertMixin";
import i18n from "@/plugins/i18n";
import {cloneDeep, get, isNumber, isObject, pick} from "lodash";
import {SAMPLE_PACK_PRODUCT_ID, SAMPLE_PACK_PRODUCT_VAR_ID, ACADEMY_ID} from "@/utils/config";
import {commit} from "lodash/seq";

const removeProductIdsFromUserLibrary = (productIds) => {
    localforage.getItem('uil').then(user_image_library => {
        user_image_library = user_image_library?.map(image => {
            if (productIds.includes(image?.picture_id)) {
                delete image.picture_id
            }
            return image
        })
        localforage.setItem('uil', user_image_library)
    })
}

export default {
    state: {
        blogList: null,
        coupon: {},
        discountAmount: 0,
        discount_code_applied_status: false,
        total_amount: 0,
        items_count: 0,
        items_quantity_count: 0,
        LOADING_ACTIVE: false,
        alert: false,
        shipping_rates: null,
        shipping_price: null,
        shipment_method: null,
        payment_methods: null,
        loader: false,
        loader_percentage: 0,
        image_processing: false,
        percentage_loader: {
            show: false,
            usePercentage: false,
            percentage: null,
            uploading: false,
            processing: false,
            finishing: false
        },
        cart: [],
        gift_cards: [],
        shipment_rates: [],
        applied_taxes: [],
        order_summary: {
            sampleKit: false,
            order_items: [],
            total_amount: null,
            order_info: null,
            order_id: null,
            discount_amount: null,
            order_card: null,
        },
    },
    mutations: {
        SET_PERCENTAGE_LOADER(state, {show, usePercentage, uploading, processing, finishing, total, loaded}) {
            const percentage_loader_obj = {
                ...state.percentage_loader,
                show,
                usePercentage,
                uploading,
                processing,
                finishing,
                percentage
            };

            let percentage = 0;
            if (usePercentage && uploading && isNumber(total) && isNumber(loaded)) {
                percentage = Math.floor(loaded * 100 / total);
                percentage_loader_obj.percentage = percentage;
            }

            if (!show) {
                percentage_loader_obj.uploading = false;
                percentage_loader_obj.usePercentage = false;
                percentage_loader_obj.processing = false;
                percentage_loader_obj.finishing = false;
                percentage_loader_obj.percentage = null;
            } else if (percentage === 100 && uploading) {
                percentage_loader_obj.uploading = false;
                percentage_loader_obj.usePercentage = false;
                percentage_loader_obj.processing = true;
            }

            // else if(finishing){
            //     percentage_loader_obj.uploading = false;
            //     percentage_loader_obj.usePercentage = false;
            //     percentage_loader_obj.processing = false;
            // }

            state.percentage_loader = {
                ...percentage_loader_obj
            }
        },
        SET_ITEMS_COUNT(state) {
            state.items_quantity_count = state.cart.filter(item => item.product_type !== 'gift_card')?.reduce((acc, i) => acc += i.quantity, 0)
            state.items_count = state.cart.filter(item => item.product_type !== 'gift_card').length
        },
        SET_DISCOUNT_AMOUNT(state, discountAmount) {
            state.discountAmount = discountAmount;
        },
        SET_APPLIED_TAXES(state, appliedTaxes) {
            state.applied_taxes = appliedTaxes;
        },
        SET_SUMMARY_CARD(state, payload) {
            state.order_summary.order_card = payload;
        },
        SET_SUMMARY_ID(state, payload) {
            state.order_summary.order_id = payload;
        },
        SET_SUMMARY_ITEMS(state, orderItems) {
            state.order_summary.order_items = orderItems;
        },
        SET_SUMMARY_TOTAL(state, payload) {
            state.order_summary.order_total = payload;
        },
        SET_SUMMARY_INFO(state, payload) {
            state.order_summary.order_info = payload;
        },
        SET_CONTACT_BILLING_ADDRESS_INFO(state, contact_billing_address) {
            state.order_summary.order_info.karavan_order.billing_same_shipping = false;
            state.order_summary.order_info.karavan_order.contact_billing_address = contact_billing_address;
        },
        SET_GIFT_CARD(state, giftCard) {
            state.gift_cards.push(giftCard);
        },
        DELETE_GIFT_CARDS(state) {
            state.gift_cards = []
        },
        SET_BLOG_LIST(state, blogList) {
            state.blogList = blogList;
        },
        ADD_TO_CART(state, payload) {
            state.cart.push({
                ...payload,
                temp_id: new Date().getTime(),
            });
        },
        SET_LOADER(state, payload) {
            // const noLoaderRoutes = ["ShipmentStep", "Payment"];
            // if (!noLoaderRoutes.includes(router.app?._route?.name)) {}
            state.loader = payload;
            // state.loader = false;
        },
        SET_LOADER_PERCENTAGE(state, {max, current}) {
            state.loader_percentage = current * 100 / max
            state.image_processing = state.loader_percentage === 100
        },
        SET_LOADER_INITIAL(state) {
            state.loader_percentage = 0;
        },
        REMOVE_CART_ITEM(state, payload) {
            const index = state.cart.indexOf(payload);
            state.cart.splice(index, 1);
            if (payload?.image_file_id && payload.invoice_item_id) {
                removeProductIdsFromUserLibrary([payload.image_file_id])
            }
            const hasSamplePack = state.cart?.find(i => i.sample_pack_kit);
            if (state.cart?.length === 1 && hasSamplePack) {
                state.cart = [];
            }

            if (!state.cart.length) {
                state.shipment_rates = [];
                state.applied_taxes = [];
                state.gift_cards = [];
                state.shipping_price = null;
                state.shipment_method = null;
                state.cart = [];
                state.order = {};
                state.coupon = {};
                state.order_summary = {};
                state.discountAmount = 0;
                state.total_amount = 0;
                state.items_count = 0;
                state.items_quantity_count = 0;
                state.discount_code_applied_status = false;
                localStorage.removeItem("order_id");
            }
        },
        INCREASE_QUANTITY: (state, payload) => {
            const cartItem = state.cart?.find(c => c.temp_id === payload.temp_id || (payload?.id && c.id && c?.id === payload?.id));
            if (cartItem) {
                cartItem.quantity++;
            }
        },
        DECREASE_QUANTITY: (state, payload) => {
            const cartItem = state.cart?.find(c => c.temp_id === payload.temp_id || (payload?.id && c.id && c?.id === payload?.id))
            if (cartItem?.quantity > 1) {
                cartItem.quantity--;
            }
            if (!state.cart.length) {
                localStorage.removeItem("order_id");
                state.discountAmount = 0;
                state.coupon = null;
                state.total_amount = 0;
                if (payload?.image_file_id) {
                    removeProductIdsFromUserLibrary([payload.image_file_id])
                }
            }
        },

        SET_COUPON(state, payload) {
            state.coupon = payload;
            if (!Object.keys(payload)?.length) {
                state.discountAmount = 0;
            }
        },
        SET_SHIPPING_PRICE(state, payload) {
            state.shipping_price = payload;
        },
        SET_TOTAL_AMOUNT(state, total_amount) {
            state.total_amount = total_amount;
        },
        SET_SHIPMENT_METHOD(state, shipment_method) {
            state.shipment_method = shipment_method;
        },
        SET_DISCOUNT_CODE_APPLIED_STATUS(state, appliedStatus) {
            state.discount_code_applied_status = appliedStatus
        },
        SET_CUSTOM_PRODUCT(state, invoiceItems) {
            invoiceItems.forEach((product) => {
                let matchedProductIndex = state.cart.findIndex((p) => p.temp_id == product.temp_id || p?.id === product?.id);
                if (matchedProductIndex > -1) {
                    Vue.set(state.cart, matchedProductIndex, {
                        ...cloneDeep(state.cart[matchedProductIndex]),
                        id: product.id,
                        invoice_item_id: ["gift_card", "sample_pack_kit"].includes(state.cart[matchedProductIndex].product_type) ? product.id : product.invoice_item_id,
                        // inventory_product: product.id,
                        inventory_product: ["gift_card", "sample_pack_kit"].includes(state.cart[matchedProductIndex].product_type) ? product.inventory_product : product.id,
                        inventory_product_var_id: ["gift_card", "sample_pack_kit"].includes(state.cart[matchedProductIndex].product_type) ? product.inventory_product_var_id : product.var_id,
                        thumbnail_id: product.thumbnail_id,
                    });
                }
            });
        },
        SET_PAYMENT_METHODS(state, payload) {
            state.payment_methods = payload;
        },
        CLEAR_CART_LIST(state) {
            state.shipment_rates = [];
            state.applied_taxes = [];
            state.gift_cards = [];
            state.shipping_price = null;
            state.shipment_method = null;
            state.cart = [];
            state.order = {};
            state.coupon = {};
            state.order_summary = {};
            state.discountAmount = 0;
            state.discount_code_applied_status = false;
            state.total_amount = 0;
            localStorage.removeItem("order_id");
        },
        SET_SHIPMENT_RATES(state, payload) {
            localforage.setItem("shipment_rates", payload).then((res) => {
            });
            state.shipment_rates = payload;
        },
        CLEAR_SUMMARY(state, payload) {
            // TODOGK burada sampleKit eklenmeli...
            state.order_summary = {
                sampleKit: true,
            };
            state.shipment_rates = [];
            state.coupon = {};
            localforage.removeItem("applied_taxes");
        },
        SET_SAMPLE_KIT(state, payload) {
            state.order_summary.sampleKit = payload;

            const matchedSamplePackKit = state.cart?.find(item => item.product_type === "sample_pack_kit")

            if (matchedSamplePackKit) {
                if (matchedSamplePackKit?.id || matchedSamplePackKit?.invoice_item_id) {
                    if (matchedSamplePackKit?._destroy) {
                        delete matchedSamplePackKit._destroy
                        matchedSamplePackKit.quantity = 1;
                    } else {
                        matchedSamplePackKit._destroy = true;
                        matchedSamplePackKit.quantity = 0;
                    }
                } else {
                    state.cart = state.cart?.filter(c => c.product_type !== "sample_pack_kit")
                }
            } else {
                const samplePackData = {
                    sample_pack_kit: true,
                    inventory_product: SAMPLE_PACK_PRODUCT_ID,
                    inventory_product_var_id: SAMPLE_PACK_PRODUCT_VAR_ID,
                    quantity: 1,
                    price: 10,
                    temp_id: new Date().getTime(),
                    product_type: "sample_pack_kit"
                }
                state.cart.push(samplePackData);
            }
        },
        REMOVE_SAMPLE_PACK_KIT(state) {
            state.cart = state.cart.filter(i => !i.sample_pack_kit)
        },
        REMOVE_PRODUCT_IDS_FROM_USER_LIBRARY(state, productIds) {
            removeProductIdsFromUserLibrary(productIds)
        },
    },
    actions: {
        SET_CUSTOM_PRODUCT({commit}, payload) {
            commit("SET_CUSTOM_PRODUCT", payload);
        },
        CLEAR_SUMMARY({commit}, payload) {
            commit("CLEAR_SUMMARY");
        },
        CLEAR_CART({commit}, payload) {
            commit("CLEAR_CART_LIST");
        },
        SET_LOADER({commit}, {status, max, current, hiddenScroll}) {
            if (router?.app?._route?.name === "Configurator" || hiddenScroll) {
                document.querySelector('html').style.overflowY = 'hidden'
            } else {
                document.querySelector("html").style.overflowY = "scroll";
            }
            commit("SET_LOADER", status);
            commit("SET_LOADER_PERCENTAGE", {max, current})
        },
        //! CART'a yeni Ürün eklemek için Kullanılan Action
        SET_NEW_ITEM({commit}, payload) {
            commit("ADD_TO_CART", payload);
            router.push({name: "Cart"});
        },
        //************************ Ürün Ekleme Süreçlerinde Kullanılan Metodlar ************************* */
        //* NEW 1.ADIM
        ADD_CUSTOM_PRODUCT({commit, dispatch}, customProductModel) {
            //! Product Type İsmi için Manipülasyon Üzerinde DEğişiklik..
            const orderProducts =
                customProductModel.custom_products.products?.map((product) => {
                    const withoutFrame = ["Canvas roll only", "EN ROULEAU"].includes(product?.frame)
                    const productTypeName = withoutFrame && product.product_type === "canvas_frame" ? "canvas" : product.product_type;
                    return {
                        ...product,
                        ...!product?.sample_pack_kit ? {
                            product_type: productTypeName,
                            product_type_name: productTypeName,
                            frame: product?.frame_ || "",
                        } : {}
                    };
                }) || [];

            const hasNewItem = orderProducts?.some((p) => !p?.id);

            if (hasNewItem) {
                //! Sorgu Atılabilmesi için FormData'nın hazırlanması
                let formData = new FormData();
                formData.append("custom_products[order_id]", customProductModel?.custom_products?.order_id);
                orderProducts
                    .filter((p) => !p?.id)
                    .forEach((product, index) => {
                        Object?.keys(product)?.forEach((product_key) => {
                            if (product_key !== "frame_") {
                                if ((product?.id && (product_key === "quantity" || product_key === "id")) || !product?.id) {
                                    if (product_key === "crop_coords") {
                                        formData.append(`custom_products[products][${index}][${product_key}]`, JSON.stringify(product[product_key]));
                                    } else {
                                        formData.append(`custom_products[products][${index}][${product_key}]`, product[product_key]);
                                    }
                                }
                            }
                        });
                    });

                //! Product'ın Order'a Eklenmesi..
                blankAxios
                    .post("add_custom_products_to_order", formData)
                    .then(async ({status, data: {shipment_rates, applied_taxes, shipping_price, products}}) => {
                        if (status === 201) {
                            //! Gelen Product'lar ile beraber Product Bilgisinin Set edilmesi.
                            commit("SET_CUSTOM_PRODUCT", products);
                            commit("SET_SHIPMENT_RATES", shipment_rates || []);
                            const productIds = products?.map(p => p.image_file_id)
                            commit("REMOVE_PRODUCT_IDS_FROM_USER_LIBRARY", productIds)
                            dispatch("GET_PAYMENT_METHODS");
                        }
                    })
                    .catch((error) => alertMixin.methods.createErrorNotification(i18n.t("notify.wrong", this._vm)))
                // .finally(() => commit("SET_LOADER", false));
            }
        },
        //* NEW 2.ADIM
        GET_PAYMENT_METHODS({state, commit, dispatch}) {
            // commit("SET_LOADER", true);
            axiosInstance
                .get("orders/get_payment_methods")
                .then((response) => {
                    const paymentMethodId = response?.data?.payment_methods?.[0]?.id;
                    //!Buradan Payment Gelmezse o zaman sistem patlar. Bu durumda kullanıcıyı geri göndermemiz gerekir.
                    if (!paymentMethodId) {
                        alertMixin.methods.createErrorNotification(i18n.t("notify.wrong"));
                        // commit("SET_LOADER", false);
                        router.push({name: "Cart"});
                        return false;
                    }
                    commit("SET_PAYMENT_METHODS", paymentMethodId); //! Şu an kullanılmıyor. Fakat ileride kullanılabilir..
                    dispatch("UPDATE_SELECTED_SHIPMENT");
                })
                .catch((e) => {
                    alertMixin.methods.createErrorNotification(i18n.t("notify.wrong"));
                    // commit("SET_LOADER", false);
                });
            // .finally(() => commit("SET_LOADER", false));
        },
        //* NEW 3.ADIM
        UPDATE_SELECTED_SHIPMENT({state, commit}) {
            // commit("SET_LOADER", true);
            const orderId = state?.order_summary?.order_id;
            const shipmentRateId = state?.shipment_rates?.[0]?.id;

            //! Bu model ile UpdateSelectedShipment isteği atıyoruz..
            const updateSelectedShipmentModel = {
                karavan_order: {
                    karavan_shipment_service_items: [
                        {
                            selected: true,
                            id: shipmentRateId,
                        },
                    ],
                },
                ...(Boolean(state?.coupon?.code) && {discount_code: state?.coupon?.code?.toLowerCase()}),
            };
            axiosInstance
                .patch(`orders/${orderId}/update_selected_shipment`, updateSelectedShipmentModel)
                .then((update_selected_shipment_response) => {
                    const {
                        data: {
                            shipment_items: shipment_rates,
                            accounting_invoice: {applied_taxes, total_discount, total_amount, shipment_method},
                        },
                    } = update_selected_shipment_response;

                    const shipping_price = shipment_rates?.[0]?.sale_price;

                    // //! Applied Taxes, Shipment Rates ve Shipping Price bilgilerinin Set Edilmesi..
                    commit("SET_SHIPMENT_RATES", shipment_rates || []);
                    commit("SET_APPLIED_TAXES", applied_taxes || []);
                    commit("SET_SHIPPING_PRICE", shipping_price || 0);
                    commit("SET_TOTAL_AMOUNT", total_amount || 0);
                    commit("SET_SHIPMENT_METHOD", shipment_method);

                    if (isObject(state?.coupon?.reward)) {
                        commit("SET_DISCOUNT_AMOUNT", total_discount || 0);
                        commit("SET_DISCOUNT_CODE_APPLIED_STATUS", true);
                    }

                    if (shipment_method === "pickup" || shipping_price >= 0) {
                        router.push({name: "Payment"});
                    } else {
                        // commit("SET_LOADER", false);
                    }
                })
                .catch((e) => {
                    // commit("SET_LOADER", false);
                    alertMixin.methods.createErrorNotification(i18n.t("notify.wrong"));
                });
        },
        //* UPDATE
        UPDATE_ORDER_PRODUCTS({state, commit}) {
            // commit("SET_LOADER", true);
            const orderId = state?.order_summary?.order_id;
            const orderItems = state.cart
                ?.filter((p) => p?.id || p?.product_type === "sample_pack_kit" || p?.product_type === "gift_card")
                .map((product) => {
                    return {
                        ...product.invoice_item_id && {id: product.invoice_item_id},
                        ...!product.invoice_item_id && {temp_id: product.temp_id},
                        inventory_product: product.inventory_product,
                        inventory_product_var_id: product.inventory_product_var_id,
                        quantity: !product?._destroy ? product.quantity : 0,
                    };
                });

            return axiosInstance.patch(`orders/${orderId}/update_order_products`, {
                order_items: {
                    items: orderItems,
                },
            });
        },
        //************************ Ürün Ekleme Süreçlerinde Kullanılan Metodlar ************************* */
        makePayment({commit, state}, payload) {
            const orderId = state?.order_summary?.order_id;
            return axiosInstance.post(`orders/${orderId}/pay_order`, payload).then((response) => {
                commit("SET_SUMMARY_CARD", payload);
                /*TODO: Is this statement really necessary? */
                // localStorage.removeItem("order_id");
                // commit("CLEAR_CART_LIST");
                // state.gift_cards = [];
                return response;
            });
        },
        GET_BLOG_POSTS({commit}, payload) {
            publicAcademyAxios.get(`/articles?academy=${ACADEMY_ID}&show_all=true`)
                .then(({data: {articles}}) => {
                    commit("SET_BLOG_LIST", articles || []);
                    return articles
                })
        },
        CHECK_SAMPLE_PACK_AND_REMOVE_CART_ITEM({commit, state}, payload) {
            //! silinen ürün haricinde id'si olan bir ürün var mi?
            const hasOrderedCartItem = state.cart.some(i => i?.invoice_item_id && i?.id !== payload?.id)
            //! silinen ürün haricinde id'si olmayan ürün var mi?
            const hasUnOrderedCartItem = state.cart.filter(i => !i?.invoice_item_id)?.length > 0
            //! Ordered Olan Sample Pack var mi?

            const hasSamplePack = state.cart?.find(i => i.sample_pack_kit && i.invoice_item_id);
            if (!(hasOrderedCartItem || (hasSamplePack && hasUnOrderedCartItem))) {
                // Order Silinecek..
                state.shipment_rates = [];
                state.applied_taxes = [];
                state.shipping_price = null;
                state.order = {};
                const karavan_order = {...state.order_summary?.order_info?.karavan_order}
                state.order_summary = {
                    order_info: {}
                };
                state.order_summary.order_info.karavan_order = karavan_order;
                state.discountAmount = 0;
                localStorage.removeItem("order_id");
            }

            if (state.cart?.length === 2 && hasSamplePack) {
                const willDeleteSamplePackData = {
                    order_items: {
                        items: [{
                            ...pick(hasSamplePack, ["inventory_product", "inventory_product_var_id"]),
                            id: hasSamplePack.invoice_item_id,
                            quantity: 0
                        }]
                    }
                }
                axiosInstance
                    .patch(`orders/${localStorage?.order_id}/update_order_products`, willDeleteSamplePackData)
                    .then(() => commit("REMOVE_CART_ITEM", payload))
                    .catch(e => commit("REMOVE_CART_ITEM", payload))
                // .finally(() => commit("SET_LOADER", false));
            } else {
                commit("REMOVE_CART_ITEM", payload);
            }
        },
        DELETE_ORDERED_CART_ITEM({dispatch}, payload) {
            // dispatch("CHECK_SAMPLE_PACK_AND_REMOVE_CART_ITEM", payload); return false;
            const removeProduct = {
                custom_products: {
                    order_id: localStorage?.order_id,
                    products: [{id: payload.id, remove_product: true}]
                },
            };
            blankAxios
                .post("add_custom_products_to_order", removeProduct)
                .finally(() => dispatch("CHECK_SAMPLE_PACK_AND_REMOVE_CART_ITEM", payload))
        },
        DELETE_ORDERED_GIFT_CARD({dispatch}, payload) {
            const willUpdateData = {order_items: {items: [{...payload, id: payload.invoice_item_id, quantity: 0}]}}
            axiosInstance
                .patch(`orders/${localStorage?.order_id}/update_order_products`, willUpdateData)
                .then(() => dispatch("CHECK_SAMPLE_PACK_AND_REMOVE_CART_ITEM", payload))
        },
        REMOVE_CART_ITEM({commit, dispatch}, payload) {
            if (payload?.id && payload.product_type !== "gift_card") {
                dispatch("DELETE_ORDERED_CART_ITEM", payload)
            } else if (payload?.id && payload.product_type === "gift_card") {
                dispatch("DELETE_ORDERED_GIFT_CARD", payload)
            } else {
                dispatch("CHECK_SAMPLE_PACK_AND_REMOVE_CART_ITEM", payload);
            }
        },
        INCREASE_QUANTITY({commit}, payload) {
            commit("INCREASE_QUANTITY", payload);
        },
        DECREASE_QUANTITY({commit}, payload) {
            commit("DECREASE_QUANTITY", payload);
        },
        DELETE_COUPON({commit, state, getters}) {
            if (state.discount_code_applied_status) {
                const orderId = state?.order_summary?.order_id;
                // commit("SET_LOADER", true);
                axiosInstance
                    .delete(`orders/${orderId}/discount`)
                    .then(({status}) => {
                        if (status === 200) {
                            commit("SET_COUPON", {})
                            commit("SET_DISCOUNT_CODE_APPLIED_STATUS", false)
                        }
                    })
                    .catch(e => console.log(e))
                // .finally(() => commit("SET_LOADER", false))
            } else {
                commit("SET_COUPON", {})
            }
        },
    },
    getters: {
        get_cart: (state) => state.cart,
        get_cart_without_sample_kit: (state) => state?.cart?.filter(item => !item.sample_pack_kit),
        cart_total_amount: (state) => {
            return state?.cart?.filter(i => !i.sample_pack_kit)?.reduce((total, product) => total + (parseFloat(product.price) * product.quantity), 0)
        },
        samplePackKitObject: state => state.cart?.find(i => i.sample_pack_kit),
        getSampleKit(state) {
            return state.cart?.find(i => i.sample_pack_kit && i?.quantity > 0)
        },
        hasSampleKit(state, getters) {
            return Boolean(getters.getSampleKit)
        },
        hasDeletedSampleKit(state) {
            return state.cart?.find(i => i.sample_pack_kit && i?.quantity === 0 && i._destroy)
        },
        coupon: (state) => get(state, "coupon.reward") || null,
        couponDescription(state, getters) {
            if (isObject(getters.coupon)) {
                const {amount_type, apply_on, amount, code} = getters.coupon;
                if (getters.itemsCountHasChanges) return `$${getters?.discountRate?.toFixed(2)}`
                else if (state.discountAmount > 0) return `$ ${state.discountAmount?.toFixed(2)}`
                else return `$${getters?.discountRate?.toFixed(2)}`
            } else {
                return "-"
            }
        },
        freeShippingAvailable(state, getters) {
            if (isObject(getters.coupon)) {
                const {amount_type, amount, apply_on} = getters.coupon;
                return apply_on === "on_shipping" && amount_type === "free"
            }
            return false
        },
        estimatedDiscountAvailable(state, getters) {
            return (state.discountAmount > 0 && state.cart.some(i => !i.invoice_item_id)) || !getters.freeShippingAvailable && isObject(getters.coupon) && state.discountAmount === 0
        },
        discountHasChanges(state) {
            return state.discountAmount > 0 && (Math.abs(state.discountRate - state.discountAmount) > 1)
        },
        itemsCountHasChanges(state) {
            const cartQuantity = state?.cart?.filter(item => item.product_type !== 'gift_card')?.reduce((acc, i) => acc += i.quantity, 0)
            return state.items_count !== state?.cart?.filter(item => item.product_type !== 'gift_card')?.length || state.items_quantity_count !== cartQuantity;
        },
        estimatedDiscountAmount(state, getters) {
            if (getters._only_gift_card) return 0;
            let amountValue = 0
            if (getters.estimatedDiscountAvailable) {
                const {amount_type, amount} = getters.coupon;
                amountValue = amount
                if (amount_type === "percentage") amountValue = (getters.cart_total_amount - getters.gift_card_total_amount) * (amount / 100)
                return amountValue;
            }
            return amountValue
        },
        couponDefinition(state, getters) {
            if (isObject(getters.coupon)) {
                const {amount_type, apply_on, amount, code} = getters.coupon || {}
                if (amount_type === "percentage") return `(${amount}%)`
            }
            return false;
        },
        discountRate: (state, getters) => {
            let totalAmount = 0;
            if (getters._only_gift_card) return 0;
            if (state?.coupon?.eligible) {
                const {reward} = state?.coupon;
                const {apply_on} = reward;

                if (apply_on === "all_invoice" || apply_on === "all_invoice_ex_shipping") {
                    state?.cart?.filter(i => !i.sample_pack_kit)?.reduce((total, product) => total + (parseFloat(product.price) * product.quantity), 0)

                    totalAmount = getters.cart_total_amount - getters.gift_card_total_amount;
                } else if (apply_on === "on_shipping") {
                    const selectedCountry = getters._get_selected_shipping_address?.country
                    const addressIsInShippingCountries = state.coupon.reward?.on_shipping_countries?.includes(selectedCountry)
                    if (addressIsInShippingCountries) {
                        totalAmount = getters.get_shipping_price;
                    } else {
                        totalAmount = 0;
                    }
                } else if (apply_on === "product_types") {
                    const productTypes = reward?.apply_on_inventory_product_types?.map((type) => type?.name);
                    totalAmount = state.cart?.filter?.((i) => productTypes.includes(i.product_type))?.reduce((total, cartItem) => (total += parseFloat(cartItem.price) * cartItem.quantity), 0);
                    totalAmount -= getters.gift_card_total_amount;
                }

                // if (apply_on === "on_shipping") totalAmount = getters.get_shipping_price;
                if (getters.coupon.amount_type === "percentage") {
                    return totalAmount * (getters.coupon.amount / 100);
                } else {
                    return getters.coupon.amount || 0;
                }
                // return getters.coupon ? (getters.coupon.amount_type === "percentage" ? getters.cart_total_amount * (getters.coupon.amount / 100) : getters.coupon.amount) : 0;
            } else {
                return 0;
            }
        },
        discountAmount: (state, getters) => {
            return state.discountAmount
        },
        discountAvailable(state, getters) {
            return state?.coupon?.eligible && state.discountAmount > 0
        },
        giftCardAvailable(state) {
            return state.gift_cards?.length > 0
        },
        get_total_amount(state) {
            return state.total_amount
        },
        cartPayableTotalAmount: (state, getters) => {
            const totalAmount = getters.get_total_amount - getters.appliedGiftCardsAmount;
            return totalAmount > 0 ? totalAmount : 0;
        },
        get_shipping_price: (state) => state.shipping_price,
        loaderShow: (state) => state.loader,
        get_coupon_amount: (state) => state.coupon,
        couponCode: state => state?.coupon?.code,
        getBlogList: (state) => state.blogList || [],
        get_shipment_rates: (state) => state.shipment_rates,
        shipment_method: state => state.shipment_method,
        _applied_taxes: (state) => state.applied_taxes,
        get_gift_cards: (state) => state.gift_cards,
        appliedGiftCardsAmount: (state, getters) => {
            const {
                cart_total_amount,
                get_total_amount,
                hasSampleKit,
                estimatedDiscountAmount,
                discountAmount
            } = getters
            const isCartPage = router.app?._route?.name === "Cart"
            const isShipmentPage = router.app?._route?.name === "ShipmentStep"
            const isPaymentPage = router.app?._route?.name === "Payment"
            const isOrderDetailPage = router.app?._route?.name === "OrderDetail"
            const giftCardsTotal = getters.get_gift_cards?.reduce((acc, item) => acc + item.data?.reward?.amount, 0) || 0

            if (isCartPage || isShipmentPage) {
                let cartTotalAmount = cart_total_amount;
                if (hasSampleKit) cartTotalAmount += 10;
                if (estimatedDiscountAmount > 0) cartTotalAmount -= estimatedDiscountAmount;
                else if (discountAmount > 0) cartTotalAmount -= discountAmount;
                if (giftCardsTotal > cartTotalAmount) return cartTotalAmount;
                else return giftCardsTotal
            } else if (isPaymentPage || isOrderDetailPage) {
                let totalAmount = get_total_amount;
                if (giftCardsTotal > totalAmount) return totalAmount;
                else return giftCardsTotal
            } else {
                return giftCardsTotal
            }

        },
        _get_selected_shipping_address: (state) => state?.order_summary?.order_info?.karavan_order?.contact_shipping_address,
        _get_selected_billing_address: (state) => state?.order_summary?.order_info?.karavan_order?.contact_billing_address,
        _get_billing_same_shipping: (state) => state?.order_summary?.order_info?.karavan_order?.billing_same_shipping,
        _only_gift_card(state) {
            const items = state.cart.map((item) => item.product_type)
            const is_gift = (x) => x === 'gift_card'
            return items.every(is_gift)
        },
        _cart_has_gift_card(state) {
            const items = state.cart.map((item) => item.product_type)
            const is_gift = (x) => x === 'gift_card'
            return items.some(is_gift)
        },
        gift_card_total_amount: (state, getters) => state.cart
            ?.filter(c => c.product_type === "gift_card")
            ?.reduce((acc, i) => acc += (i.price * i.quantity), 0),
        get_order_info: state => state.order_summary?.order_info || {},
        get_order_summary: state => state.order_summary || {},
        loader_percentage: state => state.loader_percentage,
        image_processing: state => state.image_processing,
        percentage_loader: state => state.percentage_loader
    },
};
