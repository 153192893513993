export default {
  methods: {
    createSuccessNotification(title, instance) {
      this.createNotification(title, "success", instance);
    },
    createErrorNotification(title, instance) {
      this.createNotification(title, "error", instance);
    },
    createWarningNotification(title, instance) {
      this.createNotification(title, "warning", instance);
    },
    createNotification(title, icon, instance) {
      if (!instance) {
        instance = this;
      }
      instance.$swal({
        toast: true,
        position: "top-end",
        icon: icon,
        title: title,
        showCancelButton: false,
        showConfirmButton: false,
        timer: 2000,
        buttons: false
      });
    }
  }
};
