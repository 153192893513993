export const en_countries = [
    {
        "_id": "CA",
        "code": "CA",
        "name": "Canada",
        "states": [
            {
                "_id": "AB",
                "code": "AB",
                "name": "Alberta",
                "pk": "CA__AB"
            },
            {
                "_id": "BC",
                "code": "BC",
                "name": "British Columbia",
                "pk": "CA__BC"
            },
            {
                "_id": "MB",
                "code": "MB",
                "name": "Manitoba",
                "pk": "CA__MB"
            },
            {
                "_id": "NB",
                "code": "NB",
                "name": "New Brunswick",
                "pk": "CA__NB"
            },
            {
                "_id": "NL",
                "code": "NL",
                "name": "Newfoundland and Labrador",
                "pk": "CA__NL"
            },
            {
                "_id": "NT",
                "code": "NT",
                "name": "Northwest Territories",
                "pk": "CA__NT"
            },
            {
                "_id": "NS",
                "code": "NS",
                "name": "Nova Scotia",
                "pk": "CA__NS"
            },
            {
                "_id": "NU",
                "code": "NU",
                "name": "Nunavut",
                "pk": "CA__NU"
            },
            {
                "_id": "ON",
                "code": "ON",
                "name": "Ontario",
                "pk": "CA__ON"
            },
            {
                "_id": "PE",
                "code": "PE",
                "name": "Prince Edward Island",
                "pk": "CA__PE"
            },
            {
                "_id": "QC",
                "code": "QC",
                "name": "Quebec",
                "pk": "CA__QC"
            },
            {
                "_id": "SK",
                "code": "SK",
                "name": "Saskatchewan",
                "pk": "CA__SK"
            },
            {
                "_id": "YT",
                "code": "YT",
                "name": "Yukon",
                "pk": "CA__YT"
            }
        ],
        "only_shipping": true,
        "pk": "CA"
    },
    {
        "_id": "US",
        "code": "US",
        "name": "United States",
        "states": [
            {
                "_id": "alabama",
                "code": "AL",
                "name": "Alabama",
                "pk": "US__alabama"
            },
            {
                "_id": "alaska",
                "code": "AK",
                "name": "Alaska",
                "pk": "US__alaska"
            },
            {
                "_id": "arizona",
                "code": "AZ",
                "name": "Arizona",
                "pk": "US__arizona"
            },
            {
                "_id": "arkansas",
                "code": "AR",
                "name": "Arkansas",
                "pk": "US__arkansas"
            },
            {
                "_id": "california",
                "code": "CA",
                "name": "California",
                "pk": "US__california"
            },
            {
                "_id": "colorado",
                "code": "CO",
                "name": "Colorado",
                "pk": "US__colorado"
            },
            {
                "_id": "connecticut",
                "code": "CT",
                "name": "Connecticut",
                "pk": "US__connecticut"
            },
            {
                "_id": "delaware",
                "code": "DE",
                "name": "Delaware",
                "pk": "US__delaware"
            },
            {
                "_id": "district_of_columbia",
                "code": "DC",
                "name": "District of Columbia",
                "pk": "US__district_of_columbia"
            },
            {
                "_id": "florida",
                "code": "FL",
                "name": "Florida",
                "pk": "US__florida"
            },
            {
                "_id": "georgia",
                "code": "GA",
                "name": "Georgia",
                "pk": "US__georgia"
            },
            {
                "_id": "hawaii",
                "code": "HI",
                "name": "Hawaii",
                "pk": "US__hawaii"
            },
            {
                "_id": "idaho",
                "code": "ID",
                "name": "Idaho",
                "pk": "US__idaho"
            },
            {
                "_id": "illinois",
                "code": "IL",
                "name": "Illinois",
                "pk": "US__illinois"
            },
            {
                "_id": "indiana",
                "code": "IN",
                "name": "Indiana",
                "pk": "US__indiana"
            },
            {
                "_id": "iowa",
                "code": "IA",
                "name": "Iowa",
                "pk": "US__iowa"
            },
            {
                "_id": "kansas",
                "code": "KS",
                "name": "Kansas",
                "pk": "US__kansas"
            },
            {
                "_id": "kentucky",
                "code": "KY",
                "name": "Kentucky",
                "pk": "US__kentucky"
            },
            {
                "_id": "louisiana",
                "code": "LA",
                "name": "Louisiana",
                "pk": "US__louisiana"
            },
            {
                "_id": "maine",
                "code": "ME",
                "name": "Maine",
                "pk": "US__maine"
            },
            {
                "_id": "maryland",
                "code": "MD",
                "name": "Maryland",
                "pk": "US__maryland"
            },
            {
                "_id": "massachusetts",
                "code": "MA",
                "name": "Massachusetts",
                "pk": "US__massachusetts"
            },
            {
                "_id": "michigan",
                "code": "MI",
                "name": "Michigan",
                "pk": "US__michigan"
            },
            {
                "_id": "minnesota",
                "code": "MN",
                "name": "Minnesota",
                "pk": "US__minnesota"
            },
            {
                "_id": "mississippi",
                "code": "MS",
                "name": "Mississippi",
                "pk": "US__mississippi"
            },
            {
                "_id": "missouri",
                "code": "MO",
                "name": "Missouri",
                "pk": "US__missouri"
            },
            {
                "_id": "montana",
                "code": "MT",
                "name": "Montana",
                "pk": "US__montana"
            },
            {
                "_id": "nebraska",
                "code": "NE",
                "name": "Nebraska",
                "pk": "US__nebraska"
            },
            {
                "_id": "nevada",
                "code": "NV",
                "name": "Nevada",
                "pk": "US__nevada"
            },
            {
                "_id": "new_hampshire",
                "code": "NH",
                "name": "New Hampshire",
                "pk": "US__new_hampshire"
            },
            {
                "_id": "new_jersey",
                "code": "NJ",
                "name": "New Jersey",
                "pk": "US__new_jersey"
            },
            {
                "_id": "new_mexico",
                "code": "NM",
                "name": "New Mexico",
                "pk": "US__new_mexico"
            },
            {
                "_id": "new_york",
                "code": "NY",
                "name": "New York",
                "pk": "US__new_york"
            },
            {
                "_id": "north_carolina",
                "code": "NC",
                "name": "North Carolina",
                "pk": "US__north_carolina"
            },
            {
                "_id": "north_dakota",
                "code": "ND",
                "name": "North Dakota",
                "pk": "US__north_dakota"
            },
            {
                "_id": "ohio",
                "code": "OH",
                "name": "Ohio",
                "pk": "US__ohio"
            },
            {
                "_id": "oklahoma",
                "code": "OK",
                "name": "Oklahoma",
                "pk": "US__oklahoma"
            },
            {
                "_id": "oregon",
                "code": "OR",
                "name": "Oregon",
                "pk": "US__oregon"
            },
            {
                "_id": "pennsylvania",
                "code": "PA",
                "name": "Pennsylvania",
                "pk": "US__pennsylvania"
            },
            {
                "_id": "rhode_island",
                "code": "RI",
                "name": "Rhode Island",
                "pk": "US__rhode_island"
            },
            {
                "_id": "south_carolina",
                "code": "SC",
                "name": "South Carolina",
                "pk": "US__south_carolina"
            },
            {
                "_id": "south_dakota",
                "code": "SD",
                "name": "South Dakota",
                "pk": "US__south_dakota"
            },
            {
                "_id": "tennessee",
                "code": "TN",
                "name": "Tennessee",
                "pk": "US__tennessee"
            },
            {
                "_id": "texas",
                "code": "TX",
                "name": "Texas",
                "pk": "US__texas"
            },
            {
                "_id": "utah",
                "code": "UT",
                "name": "Utah",
                "pk": "US__utah"
            },
            {
                "_id": "vermont",
                "code": "VT",
                "name": "Vermont",
                "pk": "US__vermont"
            },
            {
                "_id": "virginia",
                "code": "VA",
                "name": "Virginia",
                "pk": "US__virginia"
            },
            {
                "_id": "washington",
                "code": "WA",
                "name": "Washington",
                "pk": "US__washington"
            },
            {
                "_id": "west_virginia",
                "code": "WV",
                "name": "West Virginia",
                "pk": "US__west_virginia"
            },
            {
                "_id": "wisconsin",
                "code": "WI",
                "name": "Wisconsin",
                "pk": "US__wisconsin"
            },
            {
                "_id": "wyoming",
                "code": "WY",
                "name": "Wyoming",
                "pk": "US__wyoming"
            }
        ],
        "only_shipping": true,
        "pk": "US"
    },
    {
        "_id": "AF",
        "code": "AF",
        "name": "Afghanistan",
        "states": [
            {
                "_id": "badakhshan",
                "code": "badakhshan",
                "name": "Badakhshan",
                "pk": "AF__badakhshan"
            },
            {
                "_id": "badgis",
                "code": "badgis",
                "name": "Badgis",
                "pk": "AF__badgis"
            },
            {
                "_id": "baglan",
                "code": "baglan",
                "name": "Baglan",
                "pk": "AF__baglan"
            },
            {
                "_id": "balkh",
                "code": "balkh",
                "name": "Balkh",
                "pk": "AF__balkh"
            },
            {
                "_id": "bamiyan",
                "code": "bamiyan",
                "name": "Bamiyan",
                "pk": "AF__bamiyan"
            },
            {
                "_id": "farah",
                "code": "farah",
                "name": "Farah",
                "pk": "AF__farah"
            },
            {
                "_id": "faryab",
                "code": "faryab",
                "name": "Faryab",
                "pk": "AF__faryab"
            },
            {
                "_id": "gawr",
                "code": "gawr",
                "name": "Gawr",
                "pk": "AF__gawr"
            },
            {
                "_id": "gazni",
                "code": "gazni",
                "name": "Gazni",
                "pk": "AF__gazni"
            },
            {
                "_id": "herat",
                "code": "herat",
                "name": "Herat",
                "pk": "AF__herat"
            },
            {
                "_id": "hilmand",
                "code": "hilmand",
                "name": "Hilmand",
                "pk": "AF__hilmand"
            },
            {
                "_id": "jawzjan",
                "code": "jawzjan",
                "name": "Jawzjan",
                "pk": "AF__jawzjan"
            },
            {
                "_id": "kabul",
                "code": "kabul",
                "name": "Kabul",
                "pk": "AF__kabul"
            },
            {
                "_id": "kapisa",
                "code": "kapisa",
                "name": "Kapisa",
                "pk": "AF__kapisa"
            },
            {
                "_id": "khawst",
                "code": "khawst",
                "name": "Khawst",
                "pk": "AF__khawst"
            },
            {
                "_id": "kunar",
                "code": "kunar",
                "name": "Kunar",
                "pk": "AF__kunar"
            },
            {
                "_id": "lagman",
                "code": "lagman",
                "name": "Lagman",
                "pk": "AF__lagman"
            },
            {
                "_id": "lawghar",
                "code": "lawghar",
                "name": "Lawghar",
                "pk": "AF__lawghar"
            },
            {
                "_id": "nangarhar",
                "code": "nangarhar",
                "name": "Nangarhar",
                "pk": "AF__nangarhar"
            },
            {
                "_id": "nimruz",
                "code": "nimruz",
                "name": "Nimruz",
                "pk": "AF__nimruz"
            },
            {
                "_id": "nuristan",
                "code": "nuristan",
                "name": "Nuristan",
                "pk": "AF__nuristan"
            },
            {
                "_id": "paktika",
                "code": "paktika",
                "name": "Paktika",
                "pk": "AF__paktika"
            },
            {
                "_id": "paktiya",
                "code": "paktiya",
                "name": "Paktiya",
                "pk": "AF__paktiya"
            },
            {
                "_id": "parwan",
                "code": "parwan",
                "name": "Parwan",
                "pk": "AF__parwan"
            },
            {
                "_id": "qandahar",
                "code": "qandahar",
                "name": "Qandahar",
                "pk": "AF__qandahar"
            },
            {
                "_id": "qunduz",
                "code": "qunduz",
                "name": "Qunduz",
                "pk": "AF__qunduz"
            },
            {
                "_id": "samangan",
                "code": "samangan",
                "name": "Samangan",
                "pk": "AF__samangan"
            },
            {
                "_id": "sar-e_pul",
                "code": "sar-e_pul",
                "name": "Sar-e Pul",
                "pk": "AF__sar-e_pul"
            },
            {
                "_id": "takhar",
                "code": "takhar",
                "name": "Takhar",
                "pk": "AF__takhar"
            },
            {
                "_id": "uruzgan",
                "code": "uruzgan",
                "name": "Uruzgan",
                "pk": "AF__uruzgan"
            },
            {
                "_id": "wardag",
                "code": "wardag",
                "name": "Wardag",
                "pk": "AF__wardag"
            },
            {
                "_id": "zabul",
                "code": "zabul",
                "name": "Zabul",
                "pk": "AF__zabul"
            }
        ],
        "pk": "AF"
    },
    {
        "_id": "AL",
        "code": "AL",
        "name": "Albania",
        "states": [
            {
                "_id": "berat",
                "code": "berat",
                "name": "Berat",
                "pk": "AL__berat"
            },
            {
                "_id": "bulqize",
                "code": "bulqize",
                "name": "Bulqize",
                "pk": "AL__bulqize"
            },
            {
                "_id": "delvine",
                "code": "delvine",
                "name": "Delvine",
                "pk": "AL__delvine"
            },
            {
                "_id": "devoll",
                "code": "devoll",
                "name": "Devoll",
                "pk": "AL__devoll"
            },
            {
                "_id": "dibre",
                "code": "dibre",
                "name": "Dibre",
                "pk": "AL__dibre"
            },
            {
                "_id": "durres",
                "code": "durres",
                "name": "Durres",
                "pk": "AL__durres"
            },
            {
                "_id": "elbasan",
                "code": "elbasan",
                "name": "Elbasan",
                "pk": "AL__elbasan"
            },
            {
                "_id": "fier",
                "code": "fier",
                "name": "Fier",
                "pk": "AL__fier"
            },
            {
                "_id": "gjirokaster",
                "code": "gjirokaster",
                "name": "Gjirokaster",
                "pk": "AL__gjirokaster"
            },
            {
                "_id": "gramsh",
                "code": "gramsh",
                "name": "Gramsh",
                "pk": "AL__gramsh"
            },
            {
                "_id": "has",
                "code": "has",
                "name": "Has",
                "pk": "AL__has"
            },
            {
                "_id": "kavaje",
                "code": "kavaje",
                "name": "Kavaje",
                "pk": "AL__kavaje"
            },
            {
                "_id": "kolonje",
                "code": "kolonje",
                "name": "Kolonje",
                "pk": "AL__kolonje"
            },
            {
                "_id": "korce",
                "code": "korce",
                "name": "Korce",
                "pk": "AL__korce"
            },
            {
                "_id": "kruje",
                "code": "kruje",
                "name": "Kruje",
                "pk": "AL__kruje"
            },
            {
                "_id": "kucove",
                "code": "kucove",
                "name": "Kucove",
                "pk": "AL__kucove"
            },
            {
                "_id": "kukes",
                "code": "kukes",
                "name": "Kukes",
                "pk": "AL__kukes"
            },
            {
                "_id": "kurbin",
                "code": "kurbin",
                "name": "Kurbin",
                "pk": "AL__kurbin"
            },
            {
                "_id": "lezhe",
                "code": "lezhe",
                "name": "Lezhe",
                "pk": "AL__lezhe"
            },
            {
                "_id": "librazhd",
                "code": "librazhd",
                "name": "Librazhd",
                "pk": "AL__librazhd"
            },
            {
                "_id": "lushnje",
                "code": "lushnje",
                "name": "Lushnje",
                "pk": "AL__lushnje"
            },
            {
                "_id": "mallakaster",
                "code": "mallakaster",
                "name": "Mallakaster",
                "pk": "AL__mallakaster"
            },
            {
                "_id": "malsi_e_madhe",
                "code": "malsi_e_madhe",
                "name": "Malsi e Madhe",
                "pk": "AL__malsi_e_madhe"
            },
            {
                "_id": "mat",
                "code": "mat",
                "name": "Mat",
                "pk": "AL__mat"
            },
            {
                "_id": "mirdite",
                "code": "mirdite",
                "name": "Mirdite",
                "pk": "AL__mirdite"
            },
            {
                "_id": "peqin",
                "code": "peqin",
                "name": "Peqin",
                "pk": "AL__peqin"
            },
            {
                "_id": "permet",
                "code": "permet",
                "name": "Permet",
                "pk": "AL__permet"
            },
            {
                "_id": "pogradec",
                "code": "pogradec",
                "name": "Pogradec",
                "pk": "AL__pogradec"
            },
            {
                "_id": "puke",
                "code": "puke",
                "name": "Puke",
                "pk": "AL__puke"
            },
            {
                "_id": "sarande",
                "code": "sarande",
                "name": "Sarande",
                "pk": "AL__sarande"
            },
            {
                "_id": "shkoder",
                "code": "shkoder",
                "name": "Shkoder",
                "pk": "AL__shkoder"
            },
            {
                "_id": "skrapar",
                "code": "skrapar",
                "name": "Skrapar",
                "pk": "AL__skrapar"
            },
            {
                "_id": "tepelene",
                "code": "tepelene",
                "name": "Tepelene",
                "pk": "AL__tepelene"
            },
            {
                "_id": "tirane",
                "code": "tirane",
                "name": "Tirane",
                "pk": "AL__tirane"
            },
            {
                "_id": "tropoje",
                "code": "tropoje",
                "name": "Tropoje",
                "pk": "AL__tropoje"
            },
            {
                "_id": "vlore",
                "code": "vlore",
                "name": "Vlore",
                "pk": "AL__vlore"
            }
        ],
        "pk": "AL"
    },
    {
        "_id": "DZ",
        "code": "DZ",
        "name": "Algeria",
        "states": [
            {
                "_id": "ayn_daflah",
                "code": "ayn_daflah",
                "name": "Ayn Daflah",
                "pk": "DZ__ayn_daflah"
            },
            {
                "_id": "ayn_tamushanat",
                "code": "ayn_tamushanat",
                "name": "Ayn Tamushanat",
                "pk": "DZ__ayn_tamushanat"
            },
            {
                "_id": "adrar",
                "code": "adrar",
                "name": "Adrar",
                "pk": "DZ__adrar"
            },
            {
                "_id": "algiers",
                "code": "algiers",
                "name": "Algiers",
                "pk": "DZ__algiers"
            },
            {
                "_id": "annabah",
                "code": "annabah",
                "name": "Annabah",
                "pk": "DZ__annabah"
            },
            {
                "_id": "bashshar",
                "code": "bashshar",
                "name": "Bashshar",
                "pk": "DZ__bashshar"
            },
            {
                "_id": "batnah",
                "code": "batnah",
                "name": "Batnah",
                "pk": "DZ__batnah"
            },
            {
                "_id": "bijayah",
                "code": "bijayah",
                "name": "Bijayah",
                "pk": "DZ__bijayah"
            },
            {
                "_id": "biskrah",
                "code": "biskrah",
                "name": "Biskrah",
                "pk": "DZ__biskrah"
            },
            {
                "_id": "blidah",
                "code": "blidah",
                "name": "Blidah",
                "pk": "DZ__blidah"
            },
            {
                "_id": "buirah",
                "code": "buirah",
                "name": "Buirah",
                "pk": "DZ__buirah"
            },
            {
                "_id": "bumardas",
                "code": "bumardas",
                "name": "Bumardas",
                "pk": "DZ__bumardas"
            },
            {
                "_id": "burj_bu_arririj",
                "code": "burj_bu_arririj",
                "name": "Burj Bu Arririj",
                "pk": "DZ__burj_bu_arririj"
            },
            {
                "_id": "ghalizan",
                "code": "ghalizan",
                "name": "Ghalizan",
                "pk": "DZ__ghalizan"
            },
            {
                "_id": "ghardayah",
                "code": "ghardayah",
                "name": "Ghardayah",
                "pk": "DZ__ghardayah"
            },
            {
                "_id": "ilizi",
                "code": "ilizi",
                "name": "Ilizi",
                "pk": "DZ__ilizi"
            },
            {
                "_id": "jijili",
                "code": "jijili",
                "name": "Jijili",
                "pk": "DZ__jijili"
            },
            {
                "_id": "jilfah",
                "code": "jilfah",
                "name": "Jilfah",
                "pk": "DZ__jilfah"
            },
            {
                "_id": "khanshalah",
                "code": "khanshalah",
                "name": "Khanshalah",
                "pk": "DZ__khanshalah"
            },
            {
                "_id": "masilah",
                "code": "masilah",
                "name": "Masilah",
                "pk": "DZ__masilah"
            },
            {
                "_id": "midyah",
                "code": "midyah",
                "name": "Midyah",
                "pk": "DZ__midyah"
            },
            {
                "_id": "milah",
                "code": "milah",
                "name": "Milah",
                "pk": "DZ__milah"
            },
            {
                "_id": "muaskar",
                "code": "muaskar",
                "name": "Muaskar",
                "pk": "DZ__muaskar"
            },
            {
                "_id": "mustaghanam",
                "code": "mustaghanam",
                "name": "Mustaghanam",
                "pk": "DZ__mustaghanam"
            },
            {
                "_id": "naama",
                "code": "naama",
                "name": "Naama",
                "pk": "DZ__naama"
            },
            {
                "_id": "oran",
                "code": "oran",
                "name": "Oran",
                "pk": "DZ__oran"
            },
            {
                "_id": "ouargla",
                "code": "ouargla",
                "name": "Ouargla",
                "pk": "DZ__ouargla"
            },
            {
                "_id": "qalmah",
                "code": "qalmah",
                "name": "Qalmah",
                "pk": "DZ__qalmah"
            },
            {
                "_id": "qustantinah",
                "code": "qustantinah",
                "name": "Qustantinah",
                "pk": "DZ__qustantinah"
            },
            {
                "_id": "sakikdah",
                "code": "sakikdah",
                "name": "Sakikdah",
                "pk": "DZ__sakikdah"
            },
            {
                "_id": "satif",
                "code": "satif",
                "name": "Satif",
                "pk": "DZ__satif"
            },
            {
                "_id": "sayda",
                "code": "sayda",
                "name": "Sayda",
                "pk": "DZ__sayda"
            },
            {
                "_id": "sidi_ban-al-abbas",
                "code": "sidi_ban-al-abbas",
                "name": "Sidi ban-al-Abbas",
                "pk": "DZ__sidi_ban-al-abbas"
            },
            {
                "_id": "suq_ahras",
                "code": "suq_ahras",
                "name": "Suq Ahras",
                "pk": "DZ__suq_ahras"
            },
            {
                "_id": "tamanghasat",
                "code": "tamanghasat",
                "name": "Tamanghasat",
                "pk": "DZ__tamanghasat"
            },
            {
                "_id": "tibazah",
                "code": "tibazah",
                "name": "Tibazah",
                "pk": "DZ__tibazah"
            },
            {
                "_id": "tibissah",
                "code": "tibissah",
                "name": "Tibissah",
                "pk": "DZ__tibissah"
            },
            {
                "_id": "tilimsan",
                "code": "tilimsan",
                "name": "Tilimsan",
                "pk": "DZ__tilimsan"
            },
            {
                "_id": "tinduf",
                "code": "tinduf",
                "name": "Tinduf",
                "pk": "DZ__tinduf"
            },
            {
                "_id": "tisamsilt",
                "code": "tisamsilt",
                "name": "Tisamsilt",
                "pk": "DZ__tisamsilt"
            },
            {
                "_id": "tiyarat",
                "code": "tiyarat",
                "name": "Tiyarat",
                "pk": "DZ__tiyarat"
            },
            {
                "_id": "tizi_wazu",
                "code": "tizi_wazu",
                "name": "Tizi Wazu",
                "pk": "DZ__tizi_wazu"
            },
            {
                "_id": "umm-al-bawaghi",
                "code": "umm-al-bawaghi",
                "name": "Umm-al-Bawaghi",
                "pk": "DZ__umm-al-bawaghi"
            },
            {
                "_id": "wahran",
                "code": "wahran",
                "name": "Wahran",
                "pk": "DZ__wahran"
            },
            {
                "_id": "warqla",
                "code": "warqla",
                "name": "Warqla",
                "pk": "DZ__warqla"
            },
            {
                "_id": "wilaya_d_alger",
                "code": "wilaya_d_alger",
                "name": "Wilaya d Alger",
                "pk": "DZ__wilaya_d_alger"
            },
            {
                "_id": "wilaya_de_bejaia",
                "code": "wilaya_de_bejaia",
                "name": "Wilaya de Bejaia",
                "pk": "DZ__wilaya_de_bejaia"
            },
            {
                "_id": "wilaya_de_constantine",
                "code": "wilaya_de_constantine",
                "name": "Wilaya de Constantine",
                "pk": "DZ__wilaya_de_constantine"
            },
            {
                "_id": "al-aghwat",
                "code": "al-aghwat",
                "name": "al-Aghwat",
                "pk": "DZ__al-aghwat"
            },
            {
                "_id": "al-bayadh",
                "code": "al-bayadh",
                "name": "al-Bayadh",
                "pk": "DZ__al-bayadh"
            },
            {
                "_id": "al-jazair",
                "code": "al-jazair",
                "name": "al-Jazair",
                "pk": "DZ__al-jazair"
            },
            {
                "_id": "al-wad",
                "code": "al-wad",
                "name": "al-Wad",
                "pk": "DZ__al-wad"
            },
            {
                "_id": "ash-shalif",
                "code": "ash-shalif",
                "name": "ash-Shalif",
                "pk": "DZ__ash-shalif"
            },
            {
                "_id": "at-tarif",
                "code": "at-tarif",
                "name": "at-Tarif",
                "pk": "DZ__at-tarif"
            }
        ],
        "pk": "DZ"
    },
    {
        "_id": "AS",
        "code": "AS",
        "name": "American Samoa",
        "states": [
            {
                "_id": "eastern",
                "code": "eastern",
                "name": "Eastern",
                "pk": "AS__eastern"
            },
            {
                "_id": "manua",
                "code": "manua",
                "name": "Manua",
                "pk": "AS__manua"
            },
            {
                "_id": "swains_island",
                "code": "swains_island",
                "name": "Swains Island",
                "pk": "AS__swains_island"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "AS__western"
            }
        ],
        "pk": "AS"
    },
    {
        "_id": "AD",
        "code": "AD",
        "name": "Andorra",
        "states": [
            {
                "_id": "andorra_la_vella",
                "code": "andorra_la_vella",
                "name": "Andorra la Vella",
                "pk": "AD__andorra_la_vella"
            },
            {
                "_id": "canillo",
                "code": "canillo",
                "name": "Canillo",
                "pk": "AD__canillo"
            },
            {
                "_id": "encamp",
                "code": "encamp",
                "name": "Encamp",
                "pk": "AD__encamp"
            },
            {
                "_id": "la_massana",
                "code": "la_massana",
                "name": "La Massana",
                "pk": "AD__la_massana"
            },
            {
                "_id": "les_escaldes",
                "code": "les_escaldes",
                "name": "Les Escaldes",
                "pk": "AD__les_escaldes"
            },
            {
                "_id": "ordino",
                "code": "ordino",
                "name": "Ordino",
                "pk": "AD__ordino"
            },
            {
                "_id": "sant_julia_de_loria",
                "code": "sant_julia_de_loria",
                "name": "Sant Julia de Loria",
                "pk": "AD__sant_julia_de_loria"
            }
        ],
        "pk": "AD"
    },
    {
        "_id": "AO",
        "code": "AO",
        "name": "Angola",
        "states": [
            {
                "_id": "bengo",
                "code": "bengo",
                "name": "Bengo",
                "pk": "AO__bengo"
            },
            {
                "_id": "benguela",
                "code": "benguela",
                "name": "Benguela",
                "pk": "AO__benguela"
            },
            {
                "_id": "bie",
                "code": "bie",
                "name": "Bie",
                "pk": "AO__bie"
            },
            {
                "_id": "cabinda",
                "code": "cabinda",
                "name": "Cabinda",
                "pk": "AO__cabinda"
            },
            {
                "_id": "cunene",
                "code": "cunene",
                "name": "Cunene",
                "pk": "AO__cunene"
            },
            {
                "_id": "huambo",
                "code": "huambo",
                "name": "Huambo",
                "pk": "AO__huambo"
            },
            {
                "_id": "huila",
                "code": "huila",
                "name": "Huila",
                "pk": "AO__huila"
            },
            {
                "_id": "kuando-kubango",
                "code": "kuando-kubango",
                "name": "Kuando-Kubango",
                "pk": "AO__kuando-kubango"
            },
            {
                "_id": "kwanza_norte",
                "code": "kwanza_norte",
                "name": "Kwanza Norte",
                "pk": "AO__kwanza_norte"
            },
            {
                "_id": "kwanza_sul",
                "code": "kwanza_sul",
                "name": "Kwanza Sul",
                "pk": "AO__kwanza_sul"
            },
            {
                "_id": "luanda",
                "code": "luanda",
                "name": "Luanda",
                "pk": "AO__luanda"
            },
            {
                "_id": "lunda_norte",
                "code": "lunda_norte",
                "name": "Lunda Norte",
                "pk": "AO__lunda_norte"
            },
            {
                "_id": "lunda_sul",
                "code": "lunda_sul",
                "name": "Lunda Sul",
                "pk": "AO__lunda_sul"
            },
            {
                "_id": "malanje",
                "code": "malanje",
                "name": "Malanje",
                "pk": "AO__malanje"
            },
            {
                "_id": "moxico",
                "code": "moxico",
                "name": "Moxico",
                "pk": "AO__moxico"
            },
            {
                "_id": "namibe",
                "code": "namibe",
                "name": "Namibe",
                "pk": "AO__namibe"
            },
            {
                "_id": "uige",
                "code": "uige",
                "name": "Uige",
                "pk": "AO__uige"
            },
            {
                "_id": "zaire",
                "code": "zaire",
                "name": "Zaire",
                "pk": "AO__zaire"
            }
        ],
        "pk": "AO"
    },
    {
        "_id": "AI",
        "code": "AI",
        "name": "Anguilla",
        "states": [
            {
                "_id": "other_provinces",
                "code": "other_provinces",
                "name": "Other Provinces",
                "pk": "AI__other_provinces"
            }
        ],
        "pk": "AI"
    },
    {
        "_id": "AQ",
        "code": "AQ",
        "name": "Antarctica",
        "states": [
            {
                "_id": "sector_claimed_by_argentina/ch",
                "code": "sector_claimed_by_argentina/ch",
                "name": "Sector claimed by Argentina/Ch",
                "pk": "AQ__sector_claimed_by_argentina/ch"
            },
            {
                "_id": "sector_claimed_by_argentina/uk",
                "code": "sector_claimed_by_argentina/uk",
                "name": "Sector claimed by Argentina/UK",
                "pk": "AQ__sector_claimed_by_argentina/uk"
            },
            {
                "_id": "sector_claimed_by_australia",
                "code": "sector_claimed_by_australia",
                "name": "Sector claimed by Australia",
                "pk": "AQ__sector_claimed_by_australia"
            },
            {
                "_id": "sector_claimed_by_france",
                "code": "sector_claimed_by_france",
                "name": "Sector claimed by France",
                "pk": "AQ__sector_claimed_by_france"
            },
            {
                "_id": "sector_claimed_by_new_zealand",
                "code": "sector_claimed_by_new_zealand",
                "name": "Sector claimed by New Zealand",
                "pk": "AQ__sector_claimed_by_new_zealand"
            },
            {
                "_id": "sector_claimed_by_norway",
                "code": "sector_claimed_by_norway",
                "name": "Sector claimed by Norway",
                "pk": "AQ__sector_claimed_by_norway"
            },
            {
                "_id": "unclaimed_sector",
                "code": "unclaimed_sector",
                "name": "Unclaimed Sector",
                "pk": "AQ__unclaimed_sector"
            }
        ],
        "pk": "AQ"
    },
    {
        "_id": "AG",
        "code": "AG",
        "name": "Antigua And Barbuda",
        "states": [
            {
                "_id": "barbuda",
                "code": "barbuda",
                "name": "Barbuda",
                "pk": "AG__barbuda"
            },
            {
                "_id": "saint_george",
                "code": "saint_george",
                "name": "Saint George",
                "pk": "AG__saint_george"
            },
            {
                "_id": "saint_john",
                "code": "saint_john",
                "name": "Saint John",
                "pk": "AG__saint_john"
            },
            {
                "_id": "saint_mary",
                "code": "saint_mary",
                "name": "Saint Mary",
                "pk": "AG__saint_mary"
            },
            {
                "_id": "saint_paul",
                "code": "saint_paul",
                "name": "Saint Paul",
                "pk": "AG__saint_paul"
            },
            {
                "_id": "saint_peter",
                "code": "saint_peter",
                "name": "Saint Peter",
                "pk": "AG__saint_peter"
            },
            {
                "_id": "saint_philip",
                "code": "saint_philip",
                "name": "Saint Philip",
                "pk": "AG__saint_philip"
            }
        ],
        "pk": "AG"
    },
    {
        "_id": "AR",
        "code": "AR",
        "name": "Argentina",
        "states": [
            {
                "_id": "buenos_aires",
                "code": "buenos_aires",
                "name": "Buenos Aires",
                "pk": "AR__buenos_aires"
            },
            {
                "_id": "catamarca",
                "code": "catamarca",
                "name": "Catamarca",
                "pk": "AR__catamarca"
            },
            {
                "_id": "chaco",
                "code": "chaco",
                "name": "Chaco",
                "pk": "AR__chaco"
            },
            {
                "_id": "chubut",
                "code": "chubut",
                "name": "Chubut",
                "pk": "AR__chubut"
            },
            {
                "_id": "cordoba",
                "code": "cordoba",
                "name": "Cordoba",
                "pk": "AR__cordoba"
            },
            {
                "_id": "corrientes",
                "code": "corrientes",
                "name": "Corrientes",
                "pk": "AR__corrientes"
            },
            {
                "_id": "distrito_federal",
                "code": "distrito_federal",
                "name": "Distrito Federal",
                "pk": "AR__distrito_federal"
            },
            {
                "_id": "entre_rios",
                "code": "entre_rios",
                "name": "Entre Rios",
                "pk": "AR__entre_rios"
            },
            {
                "_id": "formosa",
                "code": "formosa",
                "name": "Formosa",
                "pk": "AR__formosa"
            },
            {
                "_id": "jujuy",
                "code": "jujuy",
                "name": "Jujuy",
                "pk": "AR__jujuy"
            },
            {
                "_id": "la_pampa",
                "code": "la_pampa",
                "name": "La Pampa",
                "pk": "AR__la_pampa"
            },
            {
                "_id": "la_rioja",
                "code": "la_rioja",
                "name": "La Rioja",
                "pk": "AR__la_rioja"
            },
            {
                "_id": "mendoza",
                "code": "mendoza",
                "name": "Mendoza",
                "pk": "AR__mendoza"
            },
            {
                "_id": "misiones",
                "code": "misiones",
                "name": "Misiones",
                "pk": "AR__misiones"
            },
            {
                "_id": "neuquen",
                "code": "neuquen",
                "name": "Neuquen",
                "pk": "AR__neuquen"
            },
            {
                "_id": "rio_negro",
                "code": "rio_negro",
                "name": "Rio Negro",
                "pk": "AR__rio_negro"
            },
            {
                "_id": "salta",
                "code": "salta",
                "name": "Salta",
                "pk": "AR__salta"
            },
            {
                "_id": "san_juan",
                "code": "san_juan",
                "name": "San Juan",
                "pk": "AR__san_juan"
            },
            {
                "_id": "san_luis",
                "code": "san_luis",
                "name": "San Luis",
                "pk": "AR__san_luis"
            },
            {
                "_id": "santa_cruz",
                "code": "santa_cruz",
                "name": "Santa Cruz",
                "pk": "AR__santa_cruz"
            },
            {
                "_id": "santa_fe",
                "code": "santa_fe",
                "name": "Santa Fe",
                "pk": "AR__santa_fe"
            },
            {
                "_id": "santiago_del_estero",
                "code": "santiago_del_estero",
                "name": "Santiago del Estero",
                "pk": "AR__santiago_del_estero"
            },
            {
                "_id": "tierra_del_fuego",
                "code": "tierra_del_fuego",
                "name": "Tierra del Fuego",
                "pk": "AR__tierra_del_fuego"
            },
            {
                "_id": "tucuman",
                "code": "tucuman",
                "name": "Tucuman",
                "pk": "AR__tucuman"
            }
        ],
        "pk": "AR"
    },
    {
        "_id": "AM",
        "code": "AM",
        "name": "Armenia",
        "states": [
            {
                "_id": "aragatsotn",
                "code": "aragatsotn",
                "name": "Aragatsotn",
                "pk": "AM__aragatsotn"
            },
            {
                "_id": "ararat",
                "code": "ararat",
                "name": "Ararat",
                "pk": "AM__ararat"
            },
            {
                "_id": "armavir",
                "code": "armavir",
                "name": "Armavir",
                "pk": "AM__armavir"
            },
            {
                "_id": "gegharkunik",
                "code": "gegharkunik",
                "name": "Gegharkunik",
                "pk": "AM__gegharkunik"
            },
            {
                "_id": "kotaik",
                "code": "kotaik",
                "name": "Kotaik",
                "pk": "AM__kotaik"
            },
            {
                "_id": "lori",
                "code": "lori",
                "name": "Lori",
                "pk": "AM__lori"
            },
            {
                "_id": "shirak",
                "code": "shirak",
                "name": "Shirak",
                "pk": "AM__shirak"
            },
            {
                "_id": "stepanakert",
                "code": "stepanakert",
                "name": "Stepanakert",
                "pk": "AM__stepanakert"
            },
            {
                "_id": "syunik",
                "code": "syunik",
                "name": "Syunik",
                "pk": "AM__syunik"
            },
            {
                "_id": "tavush",
                "code": "tavush",
                "name": "Tavush",
                "pk": "AM__tavush"
            },
            {
                "_id": "vayots_dzor",
                "code": "vayots_dzor",
                "name": "Vayots Dzor",
                "pk": "AM__vayots_dzor"
            },
            {
                "_id": "yerevan",
                "code": "yerevan",
                "name": "Yerevan",
                "pk": "AM__yerevan"
            }
        ],
        "pk": "AM"
    },
    {
        "_id": "AW",
        "code": "AW",
        "name": "Aruba",
        "states": [
            {
                "_id": "aruba",
                "code": "aruba",
                "name": "Aruba",
                "pk": "AW__aruba"
            }
        ],
        "pk": "AW"
    },
    {
        "_id": "AU",
        "code": "AU",
        "name": "Australia",
        "states": [
            {
                "_id": "auckland",
                "code": "auckland",
                "name": "Auckland",
                "pk": "AU__auckland"
            },
            {
                "_id": "australian_capital_territory",
                "code": "australian_capital_territory",
                "name": "Australian Capital Territory",
                "pk": "AU__australian_capital_territory"
            },
            {
                "_id": "balgowlah",
                "code": "balgowlah",
                "name": "Balgowlah",
                "pk": "AU__balgowlah"
            },
            {
                "_id": "balmain",
                "code": "balmain",
                "name": "Balmain",
                "pk": "AU__balmain"
            },
            {
                "_id": "bankstown",
                "code": "bankstown",
                "name": "Bankstown",
                "pk": "AU__bankstown"
            },
            {
                "_id": "baulkham_hills",
                "code": "baulkham_hills",
                "name": "Baulkham Hills",
                "pk": "AU__baulkham_hills"
            },
            {
                "_id": "bonnet_bay",
                "code": "bonnet_bay",
                "name": "Bonnet Bay",
                "pk": "AU__bonnet_bay"
            },
            {
                "_id": "camberwell",
                "code": "camberwell",
                "name": "Camberwell",
                "pk": "AU__camberwell"
            },
            {
                "_id": "carole_park",
                "code": "carole_park",
                "name": "Carole Park",
                "pk": "AU__carole_park"
            },
            {
                "_id": "castle_hill",
                "code": "castle_hill",
                "name": "Castle Hill",
                "pk": "AU__castle_hill"
            },
            {
                "_id": "caulfield",
                "code": "caulfield",
                "name": "Caulfield",
                "pk": "AU__caulfield"
            },
            {
                "_id": "chatswood",
                "code": "chatswood",
                "name": "Chatswood",
                "pk": "AU__chatswood"
            },
            {
                "_id": "cheltenham",
                "code": "cheltenham",
                "name": "Cheltenham",
                "pk": "AU__cheltenham"
            },
            {
                "_id": "cherrybrook",
                "code": "cherrybrook",
                "name": "Cherrybrook",
                "pk": "AU__cherrybrook"
            },
            {
                "_id": "clayton",
                "code": "clayton",
                "name": "Clayton",
                "pk": "AU__clayton"
            },
            {
                "_id": "collingwood",
                "code": "collingwood",
                "name": "Collingwood",
                "pk": "AU__collingwood"
            },
            {
                "_id": "frenchs_forest",
                "code": "frenchs_forest",
                "name": "Frenchs Forest",
                "pk": "AU__frenchs_forest"
            },
            {
                "_id": "hawthorn",
                "code": "hawthorn",
                "name": "Hawthorn",
                "pk": "AU__hawthorn"
            },
            {
                "_id": "jannnali",
                "code": "jannnali",
                "name": "Jannnali",
                "pk": "AU__jannnali"
            },
            {
                "_id": "knoxfield",
                "code": "knoxfield",
                "name": "Knoxfield",
                "pk": "AU__knoxfield"
            },
            {
                "_id": "melbourne",
                "code": "melbourne",
                "name": "Melbourne",
                "pk": "AU__melbourne"
            },
            {
                "_id": "new_south_wales",
                "code": "new_south_wales",
                "name": "New South Wales",
                "pk": "AU__new_south_wales"
            },
            {
                "_id": "northern_territory",
                "code": "northern_territory",
                "name": "Northern Territory",
                "pk": "AU__northern_territory"
            },
            {
                "_id": "perth",
                "code": "perth",
                "name": "Perth",
                "pk": "AU__perth"
            },
            {
                "_id": "queensland",
                "code": "queensland",
                "name": "Queensland",
                "pk": "AU__queensland"
            },
            {
                "_id": "south_australia",
                "code": "south_australia",
                "name": "South Australia",
                "pk": "AU__south_australia"
            },
            {
                "_id": "tasmania",
                "code": "tasmania",
                "name": "Tasmania",
                "pk": "AU__tasmania"
            },
            {
                "_id": "templestowe",
                "code": "templestowe",
                "name": "Templestowe",
                "pk": "AU__templestowe"
            },
            {
                "_id": "victoria",
                "code": "victoria",
                "name": "Victoria",
                "pk": "AU__victoria"
            },
            {
                "_id": "werribee_south",
                "code": "werribee_south",
                "name": "Werribee south",
                "pk": "AU__werribee_south"
            },
            {
                "_id": "western_australia",
                "code": "western_australia",
                "name": "Western Australia",
                "pk": "AU__western_australia"
            },
            {
                "_id": "wheeler",
                "code": "wheeler",
                "name": "Wheeler",
                "pk": "AU__wheeler"
            }
        ],
        "only_shipping": true,
        "pk": "AU"
    },
    {
        "_id": "AT",
        "code": "AT",
        "name": "Austria",
        "states": [
            {
                "_id": "bundesland_salzburg",
                "code": "bundesland_salzburg",
                "name": "Bundesland Salzburg",
                "pk": "AT__bundesland_salzburg"
            },
            {
                "_id": "bundesland_steiermark",
                "code": "bundesland_steiermark",
                "name": "Bundesland Steiermark",
                "pk": "AT__bundesland_steiermark"
            },
            {
                "_id": "bundesland_tirol",
                "code": "bundesland_tirol",
                "name": "Bundesland Tirol",
                "pk": "AT__bundesland_tirol"
            },
            {
                "_id": "burgenland",
                "code": "burgenland",
                "name": "Burgenland",
                "pk": "AT__burgenland"
            },
            {
                "_id": "carinthia",
                "code": "carinthia",
                "name": "Carinthia",
                "pk": "AT__carinthia"
            },
            {
                "_id": "karnten",
                "code": "karnten",
                "name": "Karnten",
                "pk": "AT__karnten"
            },
            {
                "_id": "liezen",
                "code": "liezen",
                "name": "Liezen",
                "pk": "AT__liezen"
            },
            {
                "_id": "lower_austria",
                "code": "lower_austria",
                "name": "Lower Austria",
                "pk": "AT__lower_austria"
            },
            {
                "_id": "niederosterreich",
                "code": "niederosterreich",
                "name": "Niederosterreich",
                "pk": "AT__niederosterreich"
            },
            {
                "_id": "oberosterreich",
                "code": "oberosterreich",
                "name": "Oberosterreich",
                "pk": "AT__oberosterreich"
            },
            {
                "_id": "salzburg",
                "code": "salzburg",
                "name": "Salzburg",
                "pk": "AT__salzburg"
            },
            {
                "_id": "schleswig-holstein",
                "code": "schleswig-holstein",
                "name": "Schleswig-Holstein",
                "pk": "AT__schleswig-holstein"
            },
            {
                "_id": "steiermark",
                "code": "steiermark",
                "name": "Steiermark",
                "pk": "AT__steiermark"
            },
            {
                "_id": "styria",
                "code": "styria",
                "name": "Styria",
                "pk": "AT__styria"
            },
            {
                "_id": "tirol",
                "code": "tirol",
                "name": "Tirol",
                "pk": "AT__tirol"
            },
            {
                "_id": "upper_austria",
                "code": "upper_austria",
                "name": "Upper Austria",
                "pk": "AT__upper_austria"
            },
            {
                "_id": "vorarlberg",
                "code": "vorarlberg",
                "name": "Vorarlberg",
                "pk": "AT__vorarlberg"
            },
            {
                "_id": "wien",
                "code": "wien",
                "name": "Wien",
                "pk": "AT__wien"
            }
        ],
        "pk": "AT"
    },
    {
        "_id": "AZ",
        "code": "AZ",
        "name": "Azerbaijan",
        "states": [
            {
                "_id": "abseron",
                "code": "abseron",
                "name": "Abseron",
                "pk": "AZ__abseron"
            },
            {
                "_id": "baki_sahari",
                "code": "baki_sahari",
                "name": "Baki Sahari",
                "pk": "AZ__baki_sahari"
            },
            {
                "_id": "ganca",
                "code": "ganca",
                "name": "Ganca",
                "pk": "AZ__ganca"
            },
            {
                "_id": "ganja",
                "code": "ganja",
                "name": "Ganja",
                "pk": "AZ__ganja"
            },
            {
                "_id": "kalbacar",
                "code": "kalbacar",
                "name": "Kalbacar",
                "pk": "AZ__kalbacar"
            },
            {
                "_id": "lankaran",
                "code": "lankaran",
                "name": "Lankaran",
                "pk": "AZ__lankaran"
            },
            {
                "_id": "mil-qarabax",
                "code": "mil-qarabax",
                "name": "Mil-Qarabax",
                "pk": "AZ__mil-qarabax"
            },
            {
                "_id": "mugan-salyan",
                "code": "mugan-salyan",
                "name": "Mugan-Salyan",
                "pk": "AZ__mugan-salyan"
            },
            {
                "_id": "nagorni-qarabax",
                "code": "nagorni-qarabax",
                "name": "Nagorni-Qarabax",
                "pk": "AZ__nagorni-qarabax"
            },
            {
                "_id": "naxcivan",
                "code": "naxcivan",
                "name": "Naxcivan",
                "pk": "AZ__naxcivan"
            },
            {
                "_id": "priaraks",
                "code": "priaraks",
                "name": "Priaraks",
                "pk": "AZ__priaraks"
            },
            {
                "_id": "qazax",
                "code": "qazax",
                "name": "Qazax",
                "pk": "AZ__qazax"
            },
            {
                "_id": "saki",
                "code": "saki",
                "name": "Saki",
                "pk": "AZ__saki"
            },
            {
                "_id": "sirvan",
                "code": "sirvan",
                "name": "Sirvan",
                "pk": "AZ__sirvan"
            },
            {
                "_id": "xacmaz",
                "code": "xacmaz",
                "name": "Xacmaz",
                "pk": "AZ__xacmaz"
            }
        ],
        "pk": "AZ"
    },
    {
        "_id": "BS",
        "code": "BS",
        "name": "Bahamas The",
        "states": [
            {
                "_id": "abaco",
                "code": "abaco",
                "name": "Abaco",
                "pk": "BS__abaco"
            },
            {
                "_id": "acklins_island",
                "code": "acklins_island",
                "name": "Acklins Island",
                "pk": "BS__acklins_island"
            },
            {
                "_id": "andros",
                "code": "andros",
                "name": "Andros",
                "pk": "BS__andros"
            },
            {
                "_id": "berry_islands",
                "code": "berry_islands",
                "name": "Berry Islands",
                "pk": "BS__berry_islands"
            },
            {
                "_id": "biminis",
                "code": "biminis",
                "name": "Biminis",
                "pk": "BS__biminis"
            },
            {
                "_id": "cat_island",
                "code": "cat_island",
                "name": "Cat Island",
                "pk": "BS__cat_island"
            },
            {
                "_id": "crooked_island",
                "code": "crooked_island",
                "name": "Crooked Island",
                "pk": "BS__crooked_island"
            },
            {
                "_id": "eleuthera",
                "code": "eleuthera",
                "name": "Eleuthera",
                "pk": "BS__eleuthera"
            },
            {
                "_id": "exuma_and_cays",
                "code": "exuma_and_cays",
                "name": "Exuma and Cays",
                "pk": "BS__exuma_and_cays"
            },
            {
                "_id": "grand_bahama",
                "code": "grand_bahama",
                "name": "Grand Bahama",
                "pk": "BS__grand_bahama"
            },
            {
                "_id": "inagua_islands",
                "code": "inagua_islands",
                "name": "Inagua Islands",
                "pk": "BS__inagua_islands"
            },
            {
                "_id": "long_island",
                "code": "long_island",
                "name": "Long Island",
                "pk": "BS__long_island"
            },
            {
                "_id": "mayaguana",
                "code": "mayaguana",
                "name": "Mayaguana",
                "pk": "BS__mayaguana"
            },
            {
                "_id": "new_providence",
                "code": "new_providence",
                "name": "New Providence",
                "pk": "BS__new_providence"
            },
            {
                "_id": "ragged_island",
                "code": "ragged_island",
                "name": "Ragged Island",
                "pk": "BS__ragged_island"
            },
            {
                "_id": "rum_cay",
                "code": "rum_cay",
                "name": "Rum Cay",
                "pk": "BS__rum_cay"
            },
            {
                "_id": "san_salvador",
                "code": "san_salvador",
                "name": "San Salvador",
                "pk": "BS__san_salvador"
            }
        ],
        "pk": "BS"
    },
    {
        "_id": "BH",
        "code": "BH",
        "name": "Bahrain",
        "states": [
            {
                "_id": "isa",
                "code": "isa",
                "name": "Isa",
                "pk": "BH__isa"
            },
            {
                "_id": "badiyah",
                "code": "badiyah",
                "name": "Badiyah",
                "pk": "BH__badiyah"
            },
            {
                "_id": "hidd",
                "code": "hidd",
                "name": "Hidd",
                "pk": "BH__hidd"
            },
            {
                "_id": "jidd_hafs",
                "code": "jidd_hafs",
                "name": "Jidd Hafs",
                "pk": "BH__jidd_hafs"
            },
            {
                "_id": "mahama",
                "code": "mahama",
                "name": "Mahama",
                "pk": "BH__mahama"
            },
            {
                "_id": "manama",
                "code": "manama",
                "name": "Manama",
                "pk": "BH__manama"
            },
            {
                "_id": "sitrah",
                "code": "sitrah",
                "name": "Sitrah",
                "pk": "BH__sitrah"
            },
            {
                "_id": "al-manamah",
                "code": "al-manamah",
                "name": "al-Manamah",
                "pk": "BH__al-manamah"
            },
            {
                "_id": "al-muharraq",
                "code": "al-muharraq",
                "name": "al-Muharraq",
                "pk": "BH__al-muharraq"
            },
            {
                "_id": "ar-rifaa",
                "code": "ar-rifaa",
                "name": "ar-Rifaa",
                "pk": "BH__ar-rifaa"
            }
        ],
        "pk": "BH"
    },
    {
        "_id": "BD",
        "code": "BD",
        "name": "Bangladesh",
        "states": [
            {
                "_id": "bagar_hat",
                "code": "bagar_hat",
                "name": "Bagar Hat",
                "pk": "BD__bagar_hat"
            },
            {
                "_id": "bandarban",
                "code": "bandarban",
                "name": "Bandarban",
                "pk": "BD__bandarban"
            },
            {
                "_id": "barguna",
                "code": "barguna",
                "name": "Barguna",
                "pk": "BD__barguna"
            },
            {
                "_id": "barisal",
                "code": "barisal",
                "name": "Barisal",
                "pk": "BD__barisal"
            },
            {
                "_id": "bhola",
                "code": "bhola",
                "name": "Bhola",
                "pk": "BD__bhola"
            },
            {
                "_id": "bogora",
                "code": "bogora",
                "name": "Bogora",
                "pk": "BD__bogora"
            },
            {
                "_id": "brahman_bariya",
                "code": "brahman_bariya",
                "name": "Brahman Bariya",
                "pk": "BD__brahman_bariya"
            },
            {
                "_id": "chandpur",
                "code": "chandpur",
                "name": "Chandpur",
                "pk": "BD__chandpur"
            },
            {
                "_id": "chattagam",
                "code": "chattagam",
                "name": "Chattagam",
                "pk": "BD__chattagam"
            },
            {
                "_id": "chittagong_division",
                "code": "chittagong_division",
                "name": "Chittagong Division",
                "pk": "BD__chittagong_division"
            },
            {
                "_id": "chuadanga",
                "code": "chuadanga",
                "name": "Chuadanga",
                "pk": "BD__chuadanga"
            },
            {
                "_id": "dhaka",
                "code": "dhaka",
                "name": "Dhaka",
                "pk": "BD__dhaka"
            },
            {
                "_id": "dinajpur",
                "code": "dinajpur",
                "name": "Dinajpur",
                "pk": "BD__dinajpur"
            },
            {
                "_id": "faridpur",
                "code": "faridpur",
                "name": "Faridpur",
                "pk": "BD__faridpur"
            },
            {
                "_id": "feni",
                "code": "feni",
                "name": "Feni",
                "pk": "BD__feni"
            },
            {
                "_id": "gaybanda",
                "code": "gaybanda",
                "name": "Gaybanda",
                "pk": "BD__gaybanda"
            },
            {
                "_id": "gazipur",
                "code": "gazipur",
                "name": "Gazipur",
                "pk": "BD__gazipur"
            },
            {
                "_id": "gopalganj",
                "code": "gopalganj",
                "name": "Gopalganj",
                "pk": "BD__gopalganj"
            },
            {
                "_id": "habiganj",
                "code": "habiganj",
                "name": "Habiganj",
                "pk": "BD__habiganj"
            },
            {
                "_id": "jaipur_hat",
                "code": "jaipur_hat",
                "name": "Jaipur Hat",
                "pk": "BD__jaipur_hat"
            },
            {
                "_id": "jamalpur",
                "code": "jamalpur",
                "name": "Jamalpur",
                "pk": "BD__jamalpur"
            },
            {
                "_id": "jessor",
                "code": "jessor",
                "name": "Jessor",
                "pk": "BD__jessor"
            },
            {
                "_id": "jhalakati",
                "code": "jhalakati",
                "name": "Jhalakati",
                "pk": "BD__jhalakati"
            },
            {
                "_id": "jhanaydah",
                "code": "jhanaydah",
                "name": "Jhanaydah",
                "pk": "BD__jhanaydah"
            },
            {
                "_id": "khagrachhari",
                "code": "khagrachhari",
                "name": "Khagrachhari",
                "pk": "BD__khagrachhari"
            },
            {
                "_id": "khulna",
                "code": "khulna",
                "name": "Khulna",
                "pk": "BD__khulna"
            },
            {
                "_id": "kishorganj",
                "code": "kishorganj",
                "name": "Kishorganj",
                "pk": "BD__kishorganj"
            },
            {
                "_id": "koks_bazar",
                "code": "koks_bazar",
                "name": "Koks Bazar",
                "pk": "BD__koks_bazar"
            },
            {
                "_id": "komilla",
                "code": "komilla",
                "name": "Komilla",
                "pk": "BD__komilla"
            },
            {
                "_id": "kurigram",
                "code": "kurigram",
                "name": "Kurigram",
                "pk": "BD__kurigram"
            },
            {
                "_id": "kushtiya",
                "code": "kushtiya",
                "name": "Kushtiya",
                "pk": "BD__kushtiya"
            },
            {
                "_id": "lakshmipur",
                "code": "lakshmipur",
                "name": "Lakshmipur",
                "pk": "BD__lakshmipur"
            },
            {
                "_id": "lalmanir_hat",
                "code": "lalmanir_hat",
                "name": "Lalmanir Hat",
                "pk": "BD__lalmanir_hat"
            },
            {
                "_id": "madaripur",
                "code": "madaripur",
                "name": "Madaripur",
                "pk": "BD__madaripur"
            },
            {
                "_id": "magura",
                "code": "magura",
                "name": "Magura",
                "pk": "BD__magura"
            },
            {
                "_id": "maimansingh",
                "code": "maimansingh",
                "name": "Maimansingh",
                "pk": "BD__maimansingh"
            },
            {
                "_id": "manikganj",
                "code": "manikganj",
                "name": "Manikganj",
                "pk": "BD__manikganj"
            },
            {
                "_id": "maulvi_bazar",
                "code": "maulvi_bazar",
                "name": "Maulvi Bazar",
                "pk": "BD__maulvi_bazar"
            },
            {
                "_id": "meherpur",
                "code": "meherpur",
                "name": "Meherpur",
                "pk": "BD__meherpur"
            },
            {
                "_id": "munshiganj",
                "code": "munshiganj",
                "name": "Munshiganj",
                "pk": "BD__munshiganj"
            },
            {
                "_id": "naral",
                "code": "naral",
                "name": "Naral",
                "pk": "BD__naral"
            },
            {
                "_id": "narayanganj",
                "code": "narayanganj",
                "name": "Narayanganj",
                "pk": "BD__narayanganj"
            },
            {
                "_id": "narsingdi",
                "code": "narsingdi",
                "name": "Narsingdi",
                "pk": "BD__narsingdi"
            },
            {
                "_id": "nator",
                "code": "nator",
                "name": "Nator",
                "pk": "BD__nator"
            },
            {
                "_id": "naugaon",
                "code": "naugaon",
                "name": "Naugaon",
                "pk": "BD__naugaon"
            },
            {
                "_id": "nawabganj",
                "code": "nawabganj",
                "name": "Nawabganj",
                "pk": "BD__nawabganj"
            },
            {
                "_id": "netrakona",
                "code": "netrakona",
                "name": "Netrakona",
                "pk": "BD__netrakona"
            },
            {
                "_id": "nilphamari",
                "code": "nilphamari",
                "name": "Nilphamari",
                "pk": "BD__nilphamari"
            },
            {
                "_id": "noakhali",
                "code": "noakhali",
                "name": "Noakhali",
                "pk": "BD__noakhali"
            },
            {
                "_id": "pabna",
                "code": "pabna",
                "name": "Pabna",
                "pk": "BD__pabna"
            },
            {
                "_id": "panchagarh",
                "code": "panchagarh",
                "name": "Panchagarh",
                "pk": "BD__panchagarh"
            },
            {
                "_id": "patuakhali",
                "code": "patuakhali",
                "name": "Patuakhali",
                "pk": "BD__patuakhali"
            },
            {
                "_id": "pirojpur",
                "code": "pirojpur",
                "name": "Pirojpur",
                "pk": "BD__pirojpur"
            },
            {
                "_id": "rajbari",
                "code": "rajbari",
                "name": "Rajbari",
                "pk": "BD__rajbari"
            },
            {
                "_id": "rajshahi",
                "code": "rajshahi",
                "name": "Rajshahi",
                "pk": "BD__rajshahi"
            },
            {
                "_id": "rangamati",
                "code": "rangamati",
                "name": "Rangamati",
                "pk": "BD__rangamati"
            },
            {
                "_id": "rangpur",
                "code": "rangpur",
                "name": "Rangpur",
                "pk": "BD__rangpur"
            },
            {
                "_id": "satkhira",
                "code": "satkhira",
                "name": "Satkhira",
                "pk": "BD__satkhira"
            },
            {
                "_id": "shariatpur",
                "code": "shariatpur",
                "name": "Shariatpur",
                "pk": "BD__shariatpur"
            },
            {
                "_id": "sherpur",
                "code": "sherpur",
                "name": "Sherpur",
                "pk": "BD__sherpur"
            },
            {
                "_id": "silhat",
                "code": "silhat",
                "name": "Silhat",
                "pk": "BD__silhat"
            },
            {
                "_id": "sirajganj",
                "code": "sirajganj",
                "name": "Sirajganj",
                "pk": "BD__sirajganj"
            },
            {
                "_id": "sunamganj",
                "code": "sunamganj",
                "name": "Sunamganj",
                "pk": "BD__sunamganj"
            },
            {
                "_id": "tangayal",
                "code": "tangayal",
                "name": "Tangayal",
                "pk": "BD__tangayal"
            },
            {
                "_id": "thakurgaon",
                "code": "thakurgaon",
                "name": "Thakurgaon",
                "pk": "BD__thakurgaon"
            }
        ],
        "pk": "BD"
    },
    {
        "_id": "BB",
        "code": "BB",
        "name": "Barbados",
        "states": [
            {
                "_id": "christ_church",
                "code": "christ_church",
                "name": "Christ Church",
                "pk": "BB__christ_church"
            },
            {
                "_id": "saint_andrew",
                "code": "saint_andrew",
                "name": "Saint Andrew",
                "pk": "BB__saint_andrew"
            },
            {
                "_id": "saint_george",
                "code": "saint_george",
                "name": "Saint George",
                "pk": "BB__saint_george"
            },
            {
                "_id": "saint_james",
                "code": "saint_james",
                "name": "Saint James",
                "pk": "BB__saint_james"
            },
            {
                "_id": "saint_john",
                "code": "saint_john",
                "name": "Saint John",
                "pk": "BB__saint_john"
            },
            {
                "_id": "saint_joseph",
                "code": "saint_joseph",
                "name": "Saint Joseph",
                "pk": "BB__saint_joseph"
            },
            {
                "_id": "saint_lucy",
                "code": "saint_lucy",
                "name": "Saint Lucy",
                "pk": "BB__saint_lucy"
            },
            {
                "_id": "saint_michael",
                "code": "saint_michael",
                "name": "Saint Michael",
                "pk": "BB__saint_michael"
            },
            {
                "_id": "saint_peter",
                "code": "saint_peter",
                "name": "Saint Peter",
                "pk": "BB__saint_peter"
            },
            {
                "_id": "saint_philip",
                "code": "saint_philip",
                "name": "Saint Philip",
                "pk": "BB__saint_philip"
            },
            {
                "_id": "saint_thomas",
                "code": "saint_thomas",
                "name": "Saint Thomas",
                "pk": "BB__saint_thomas"
            }
        ],
        "pk": "BB"
    },
    {
        "_id": "BY",
        "code": "BY",
        "name": "Belarus",
        "states": [
            {
                "_id": "brest",
                "code": "brest",
                "name": "Brest",
                "pk": "BY__brest"
            },
            {
                "_id": "homjel",
                "code": "homjel",
                "name": "Homjel",
                "pk": "BY__homjel"
            },
            {
                "_id": "hrodna",
                "code": "hrodna",
                "name": "Hrodna",
                "pk": "BY__hrodna"
            },
            {
                "_id": "mahiljow",
                "code": "mahiljow",
                "name": "Mahiljow",
                "pk": "BY__mahiljow"
            },
            {
                "_id": "mahilyowskaya_voblasts",
                "code": "mahilyowskaya_voblasts",
                "name": "Mahilyowskaya Voblasts",
                "pk": "BY__mahilyowskaya_voblasts"
            },
            {
                "_id": "minsk",
                "code": "minsk",
                "name": "Minsk",
                "pk": "BY__minsk"
            },
            {
                "_id": "minskaja_voblasts",
                "code": "minskaja_voblasts",
                "name": "Minskaja Voblasts",
                "pk": "BY__minskaja_voblasts"
            },
            {
                "_id": "petrik",
                "code": "petrik",
                "name": "Petrik",
                "pk": "BY__petrik"
            },
            {
                "_id": "vicebsk",
                "code": "vicebsk",
                "name": "Vicebsk",
                "pk": "BY__vicebsk"
            }
        ],
        "pk": "BY"
    },
    {
        "_id": "BE",
        "code": "BE",
        "name": "Belgium",
        "states": [
            {
                "_id": "antwerpen",
                "code": "antwerpen",
                "name": "Antwerpen",
                "pk": "BE__antwerpen"
            },
            {
                "_id": "berchem",
                "code": "berchem",
                "name": "Berchem",
                "pk": "BE__berchem"
            },
            {
                "_id": "brabant",
                "code": "brabant",
                "name": "Brabant",
                "pk": "BE__brabant"
            },
            {
                "_id": "brabant_wallon",
                "code": "brabant_wallon",
                "name": "Brabant Wallon",
                "pk": "BE__brabant_wallon"
            },
            {
                "_id": "brussel",
                "code": "brussel",
                "name": "Brussel",
                "pk": "BE__brussel"
            },
            {
                "_id": "east_flanders",
                "code": "east_flanders",
                "name": "East Flanders",
                "pk": "BE__east_flanders"
            },
            {
                "_id": "hainaut",
                "code": "hainaut",
                "name": "Hainaut",
                "pk": "BE__hainaut"
            },
            {
                "_id": "liege",
                "code": "liege",
                "name": "Liege",
                "pk": "BE__liege"
            },
            {
                "_id": "limburg",
                "code": "limburg",
                "name": "Limburg",
                "pk": "BE__limburg"
            },
            {
                "_id": "luxembourg",
                "code": "luxembourg",
                "name": "Luxembourg",
                "pk": "BE__luxembourg"
            },
            {
                "_id": "namur",
                "code": "namur",
                "name": "Namur",
                "pk": "BE__namur"
            },
            {
                "_id": "ontario",
                "code": "ontario",
                "name": "Ontario",
                "pk": "BE__ontario"
            },
            {
                "_id": "oost-vlaanderen",
                "code": "oost-vlaanderen",
                "name": "Oost-Vlaanderen",
                "pk": "BE__oost-vlaanderen"
            },
            {
                "_id": "provincie_brabant",
                "code": "provincie_brabant",
                "name": "Provincie Brabant",
                "pk": "BE__provincie_brabant"
            },
            {
                "_id": "vlaams-brabant",
                "code": "vlaams-brabant",
                "name": "Vlaams-Brabant",
                "pk": "BE__vlaams-brabant"
            },
            {
                "_id": "wallonne",
                "code": "wallonne",
                "name": "Wallonne",
                "pk": "BE__wallonne"
            },
            {
                "_id": "west-vlaanderen",
                "code": "west-vlaanderen",
                "name": "West-Vlaanderen",
                "pk": "BE__west-vlaanderen"
            }
        ],
        "only_shipping": true,
        "pk": "BE"
    },
    {
        "_id": "BZ",
        "code": "BZ",
        "name": "Belize",
        "states": [
            {
                "_id": "belize",
                "code": "belize",
                "name": "Belize",
                "pk": "BZ__belize"
            },
            {
                "_id": "cayo",
                "code": "cayo",
                "name": "Cayo",
                "pk": "BZ__cayo"
            },
            {
                "_id": "corozal",
                "code": "corozal",
                "name": "Corozal",
                "pk": "BZ__corozal"
            },
            {
                "_id": "orange_walk",
                "code": "orange_walk",
                "name": "Orange Walk",
                "pk": "BZ__orange_walk"
            },
            {
                "_id": "stann_creek",
                "code": "stann_creek",
                "name": "Stann Creek",
                "pk": "BZ__stann_creek"
            },
            {
                "_id": "toledo",
                "code": "toledo",
                "name": "Toledo",
                "pk": "BZ__toledo"
            }
        ],
        "pk": "BZ"
    },
    {
        "_id": "BJ",
        "code": "BJ",
        "name": "Benin",
        "states": [
            {
                "_id": "alibori",
                "code": "alibori",
                "name": "Alibori",
                "pk": "BJ__alibori"
            },
            {
                "_id": "atacora",
                "code": "atacora",
                "name": "Atacora",
                "pk": "BJ__atacora"
            },
            {
                "_id": "atlantique",
                "code": "atlantique",
                "name": "Atlantique",
                "pk": "BJ__atlantique"
            },
            {
                "_id": "borgou",
                "code": "borgou",
                "name": "Borgou",
                "pk": "BJ__borgou"
            },
            {
                "_id": "collines",
                "code": "collines",
                "name": "Collines",
                "pk": "BJ__collines"
            },
            {
                "_id": "couffo",
                "code": "couffo",
                "name": "Couffo",
                "pk": "BJ__couffo"
            },
            {
                "_id": "donga",
                "code": "donga",
                "name": "Donga",
                "pk": "BJ__donga"
            },
            {
                "_id": "littoral",
                "code": "littoral",
                "name": "Littoral",
                "pk": "BJ__littoral"
            },
            {
                "_id": "mono",
                "code": "mono",
                "name": "Mono",
                "pk": "BJ__mono"
            },
            {
                "_id": "oueme",
                "code": "oueme",
                "name": "Oueme",
                "pk": "BJ__oueme"
            },
            {
                "_id": "plateau",
                "code": "plateau",
                "name": "Plateau",
                "pk": "BJ__plateau"
            },
            {
                "_id": "zou",
                "code": "zou",
                "name": "Zou",
                "pk": "BJ__zou"
            }
        ],
        "pk": "BJ"
    },
    {
        "_id": "BM",
        "code": "BM",
        "name": "Bermuda",
        "states": [
            {
                "_id": "hamilton",
                "code": "hamilton",
                "name": "Hamilton",
                "pk": "BM__hamilton"
            },
            {
                "_id": "saint_george",
                "code": "saint_george",
                "name": "Saint George",
                "pk": "BM__saint_george"
            }
        ],
        "pk": "BM"
    },
    {
        "_id": "BT",
        "code": "BT",
        "name": "Bhutan",
        "states": [
            {
                "_id": "bumthang",
                "code": "bumthang",
                "name": "Bumthang",
                "pk": "BT__bumthang"
            },
            {
                "_id": "chhukha",
                "code": "chhukha",
                "name": "Chhukha",
                "pk": "BT__chhukha"
            },
            {
                "_id": "chirang",
                "code": "chirang",
                "name": "Chirang",
                "pk": "BT__chirang"
            },
            {
                "_id": "daga",
                "code": "daga",
                "name": "Daga",
                "pk": "BT__daga"
            },
            {
                "_id": "geylegphug",
                "code": "geylegphug",
                "name": "Geylegphug",
                "pk": "BT__geylegphug"
            },
            {
                "_id": "ha",
                "code": "ha",
                "name": "Ha",
                "pk": "BT__ha"
            },
            {
                "_id": "lhuntshi",
                "code": "lhuntshi",
                "name": "Lhuntshi",
                "pk": "BT__lhuntshi"
            },
            {
                "_id": "mongar",
                "code": "mongar",
                "name": "Mongar",
                "pk": "BT__mongar"
            },
            {
                "_id": "pemagatsel",
                "code": "pemagatsel",
                "name": "Pemagatsel",
                "pk": "BT__pemagatsel"
            },
            {
                "_id": "punakha",
                "code": "punakha",
                "name": "Punakha",
                "pk": "BT__punakha"
            },
            {
                "_id": "rinpung",
                "code": "rinpung",
                "name": "Rinpung",
                "pk": "BT__rinpung"
            },
            {
                "_id": "samchi",
                "code": "samchi",
                "name": "Samchi",
                "pk": "BT__samchi"
            },
            {
                "_id": "samdrup_jongkhar",
                "code": "samdrup_jongkhar",
                "name": "Samdrup Jongkhar",
                "pk": "BT__samdrup_jongkhar"
            },
            {
                "_id": "shemgang",
                "code": "shemgang",
                "name": "Shemgang",
                "pk": "BT__shemgang"
            },
            {
                "_id": "tashigang",
                "code": "tashigang",
                "name": "Tashigang",
                "pk": "BT__tashigang"
            },
            {
                "_id": "timphu",
                "code": "timphu",
                "name": "Timphu",
                "pk": "BT__timphu"
            },
            {
                "_id": "tongsa",
                "code": "tongsa",
                "name": "Tongsa",
                "pk": "BT__tongsa"
            },
            {
                "_id": "wangdiphodrang",
                "code": "wangdiphodrang",
                "name": "Wangdiphodrang",
                "pk": "BT__wangdiphodrang"
            }
        ],
        "pk": "BT"
    },
    {
        "_id": "BO",
        "code": "BO",
        "name": "Bolivia",
        "states": [
            {
                "_id": "beni",
                "code": "beni",
                "name": "Beni",
                "pk": "BO__beni"
            },
            {
                "_id": "chuquisaca",
                "code": "chuquisaca",
                "name": "Chuquisaca",
                "pk": "BO__chuquisaca"
            },
            {
                "_id": "cochabamba",
                "code": "cochabamba",
                "name": "Cochabamba",
                "pk": "BO__cochabamba"
            },
            {
                "_id": "la_paz",
                "code": "la_paz",
                "name": "La Paz",
                "pk": "BO__la_paz"
            },
            {
                "_id": "oruro",
                "code": "oruro",
                "name": "Oruro",
                "pk": "BO__oruro"
            },
            {
                "_id": "pando",
                "code": "pando",
                "name": "Pando",
                "pk": "BO__pando"
            },
            {
                "_id": "potosi",
                "code": "potosi",
                "name": "Potosi",
                "pk": "BO__potosi"
            },
            {
                "_id": "santa_cruz",
                "code": "santa_cruz",
                "name": "Santa Cruz",
                "pk": "BO__santa_cruz"
            },
            {
                "_id": "tarija",
                "code": "tarija",
                "name": "Tarija",
                "pk": "BO__tarija"
            }
        ],
        "pk": "BO"
    },
    {
        "_id": "BA",
        "code": "BA",
        "name": "Bosnia and Herzegovina",
        "states": [
            {
                "_id": "federacija_bosna_i_hercegovina",
                "code": "federacija_bosna_i_hercegovina",
                "name": "Federacija Bosna i Hercegovina",
                "pk": "BA__federacija_bosna_i_hercegovina"
            },
            {
                "_id": "republika_srpska",
                "code": "republika_srpska",
                "name": "Republika Srpska",
                "pk": "BA__republika_srpska"
            }
        ],
        "pk": "BA"
    },
    {
        "_id": "BW",
        "code": "BW",
        "name": "Botswana",
        "states": [
            {
                "_id": "central_bobonong",
                "code": "central_bobonong",
                "name": "Central Bobonong",
                "pk": "BW__central_bobonong"
            },
            {
                "_id": "central_boteti",
                "code": "central_boteti",
                "name": "Central Boteti",
                "pk": "BW__central_boteti"
            },
            {
                "_id": "central_mahalapye",
                "code": "central_mahalapye",
                "name": "Central Mahalapye",
                "pk": "BW__central_mahalapye"
            },
            {
                "_id": "central_serowe-palapye",
                "code": "central_serowe-palapye",
                "name": "Central Serowe-Palapye",
                "pk": "BW__central_serowe-palapye"
            },
            {
                "_id": "central_tutume",
                "code": "central_tutume",
                "name": "Central Tutume",
                "pk": "BW__central_tutume"
            },
            {
                "_id": "chobe",
                "code": "chobe",
                "name": "Chobe",
                "pk": "BW__chobe"
            },
            {
                "_id": "francistown",
                "code": "francistown",
                "name": "Francistown",
                "pk": "BW__francistown"
            },
            {
                "_id": "gaborone",
                "code": "gaborone",
                "name": "Gaborone",
                "pk": "BW__gaborone"
            },
            {
                "_id": "ghanzi",
                "code": "ghanzi",
                "name": "Ghanzi",
                "pk": "BW__ghanzi"
            },
            {
                "_id": "jwaneng",
                "code": "jwaneng",
                "name": "Jwaneng",
                "pk": "BW__jwaneng"
            },
            {
                "_id": "kgalagadi_north",
                "code": "kgalagadi_north",
                "name": "Kgalagadi North",
                "pk": "BW__kgalagadi_north"
            },
            {
                "_id": "kgalagadi_south",
                "code": "kgalagadi_south",
                "name": "Kgalagadi South",
                "pk": "BW__kgalagadi_south"
            },
            {
                "_id": "kgatleng",
                "code": "kgatleng",
                "name": "Kgatleng",
                "pk": "BW__kgatleng"
            },
            {
                "_id": "kweneng",
                "code": "kweneng",
                "name": "Kweneng",
                "pk": "BW__kweneng"
            },
            {
                "_id": "lobatse",
                "code": "lobatse",
                "name": "Lobatse",
                "pk": "BW__lobatse"
            },
            {
                "_id": "ngamiland",
                "code": "ngamiland",
                "name": "Ngamiland",
                "pk": "BW__ngamiland"
            },
            {
                "_id": "ngwaketse",
                "code": "ngwaketse",
                "name": "Ngwaketse",
                "pk": "BW__ngwaketse"
            },
            {
                "_id": "north_east",
                "code": "north_east",
                "name": "North East",
                "pk": "BW__north_east"
            },
            {
                "_id": "okavango",
                "code": "okavango",
                "name": "Okavango",
                "pk": "BW__okavango"
            },
            {
                "_id": "orapa",
                "code": "orapa",
                "name": "Orapa",
                "pk": "BW__orapa"
            },
            {
                "_id": "selibe_phikwe",
                "code": "selibe_phikwe",
                "name": "Selibe Phikwe",
                "pk": "BW__selibe_phikwe"
            },
            {
                "_id": "south_east",
                "code": "south_east",
                "name": "South East",
                "pk": "BW__south_east"
            },
            {
                "_id": "sowa",
                "code": "sowa",
                "name": "Sowa",
                "pk": "BW__sowa"
            }
        ],
        "pk": "BW"
    },
    {
        "_id": "BV",
        "code": "BV",
        "name": "Bouvet Island",
        "states": [
            {
                "_id": "bouvet_island",
                "code": "bouvet_island",
                "name": "Bouvet Island",
                "pk": "BV__bouvet_island"
            }
        ],
        "pk": "BV"
    },
    {
        "_id": "BR",
        "code": "BR",
        "name": "Brazil",
        "states": [
            {
                "_id": "acre",
                "code": "acre",
                "name": "Acre",
                "pk": "BR__acre"
            },
            {
                "_id": "alagoas",
                "code": "alagoas",
                "name": "Alagoas",
                "pk": "BR__alagoas"
            },
            {
                "_id": "amapa",
                "code": "amapa",
                "name": "Amapa",
                "pk": "BR__amapa"
            },
            {
                "_id": "amazonas",
                "code": "amazonas",
                "name": "Amazonas",
                "pk": "BR__amazonas"
            },
            {
                "_id": "bahia",
                "code": "bahia",
                "name": "Bahia",
                "pk": "BR__bahia"
            },
            {
                "_id": "ceara",
                "code": "ceara",
                "name": "Ceara",
                "pk": "BR__ceara"
            },
            {
                "_id": "distrito_federal",
                "code": "distrito_federal",
                "name": "Distrito Federal",
                "pk": "BR__distrito_federal"
            },
            {
                "_id": "espirito_santo",
                "code": "espirito_santo",
                "name": "Espirito Santo",
                "pk": "BR__espirito_santo"
            },
            {
                "_id": "estado_de_sao_paulo",
                "code": "estado_de_sao_paulo",
                "name": "Estado de Sao Paulo",
                "pk": "BR__estado_de_sao_paulo"
            },
            {
                "_id": "goias",
                "code": "goias",
                "name": "Goias",
                "pk": "BR__goias"
            },
            {
                "_id": "maranhao",
                "code": "maranhao",
                "name": "Maranhao",
                "pk": "BR__maranhao"
            },
            {
                "_id": "mato_grosso",
                "code": "mato_grosso",
                "name": "Mato Grosso",
                "pk": "BR__mato_grosso"
            },
            {
                "_id": "mato_grosso_do_sul",
                "code": "mato_grosso_do_sul",
                "name": "Mato Grosso do Sul",
                "pk": "BR__mato_grosso_do_sul"
            },
            {
                "_id": "minas_gerais",
                "code": "minas_gerais",
                "name": "Minas Gerais",
                "pk": "BR__minas_gerais"
            },
            {
                "_id": "para",
                "code": "para",
                "name": "Para",
                "pk": "BR__para"
            },
            {
                "_id": "paraiba",
                "code": "paraiba",
                "name": "Paraiba",
                "pk": "BR__paraiba"
            },
            {
                "_id": "parana",
                "code": "parana",
                "name": "Parana",
                "pk": "BR__parana"
            },
            {
                "_id": "pernambuco",
                "code": "pernambuco",
                "name": "Pernambuco",
                "pk": "BR__pernambuco"
            },
            {
                "_id": "piaui",
                "code": "piaui",
                "name": "Piaui",
                "pk": "BR__piaui"
            },
            {
                "_id": "rio_grande_do_norte",
                "code": "rio_grande_do_norte",
                "name": "Rio Grande do Norte",
                "pk": "BR__rio_grande_do_norte"
            },
            {
                "_id": "rio_grande_do_sul",
                "code": "rio_grande_do_sul",
                "name": "Rio Grande do Sul",
                "pk": "BR__rio_grande_do_sul"
            },
            {
                "_id": "rio_de_janeiro",
                "code": "rio_de_janeiro",
                "name": "Rio de Janeiro",
                "pk": "BR__rio_de_janeiro"
            },
            {
                "_id": "rondonia",
                "code": "rondonia",
                "name": "Rondonia",
                "pk": "BR__rondonia"
            },
            {
                "_id": "roraima",
                "code": "roraima",
                "name": "Roraima",
                "pk": "BR__roraima"
            },
            {
                "_id": "santa_catarina",
                "code": "santa_catarina",
                "name": "Santa Catarina",
                "pk": "BR__santa_catarina"
            },
            {
                "_id": "sao_paulo",
                "code": "sao_paulo",
                "name": "Sao Paulo",
                "pk": "BR__sao_paulo"
            },
            {
                "_id": "sergipe",
                "code": "sergipe",
                "name": "Sergipe",
                "pk": "BR__sergipe"
            },
            {
                "_id": "tocantins",
                "code": "tocantins",
                "name": "Tocantins",
                "pk": "BR__tocantins"
            }
        ],
        "pk": "BR"
    },
    {
        "_id": "IO",
        "code": "IO",
        "name": "British Indian Ocean Territory",
        "states": [
            {
                "_id": "british_indian_ocean_territory",
                "code": "british_indian_ocean_territory",
                "name": "British Indian Ocean Territory",
                "pk": "IO__british_indian_ocean_territory"
            }
        ],
        "pk": "IO"
    },
    {
        "_id": "BN",
        "code": "BN",
        "name": "Brunei",
        "states": [
            {
                "_id": "belait",
                "code": "belait",
                "name": "Belait",
                "pk": "BN__belait"
            },
            {
                "_id": "brunei-muara",
                "code": "brunei-muara",
                "name": "Brunei-Muara",
                "pk": "BN__brunei-muara"
            },
            {
                "_id": "temburong",
                "code": "temburong",
                "name": "Temburong",
                "pk": "BN__temburong"
            },
            {
                "_id": "tutong",
                "code": "tutong",
                "name": "Tutong",
                "pk": "BN__tutong"
            }
        ],
        "pk": "BN"
    },
    {
        "_id": "BG",
        "code": "BG",
        "name": "Bulgaria",
        "states": [
            {
                "_id": "blagoevgrad",
                "code": "blagoevgrad",
                "name": "Blagoevgrad",
                "pk": "BG__blagoevgrad"
            },
            {
                "_id": "burgas",
                "code": "burgas",
                "name": "Burgas",
                "pk": "BG__burgas"
            },
            {
                "_id": "dobrich",
                "code": "dobrich",
                "name": "Dobrich",
                "pk": "BG__dobrich"
            },
            {
                "_id": "gabrovo",
                "code": "gabrovo",
                "name": "Gabrovo",
                "pk": "BG__gabrovo"
            },
            {
                "_id": "haskovo",
                "code": "haskovo",
                "name": "Haskovo",
                "pk": "BG__haskovo"
            },
            {
                "_id": "jambol",
                "code": "jambol",
                "name": "Jambol",
                "pk": "BG__jambol"
            },
            {
                "_id": "kardzhali",
                "code": "kardzhali",
                "name": "Kardzhali",
                "pk": "BG__kardzhali"
            },
            {
                "_id": "kjustendil",
                "code": "kjustendil",
                "name": "Kjustendil",
                "pk": "BG__kjustendil"
            },
            {
                "_id": "lovech",
                "code": "lovech",
                "name": "Lovech",
                "pk": "BG__lovech"
            },
            {
                "_id": "montana",
                "code": "montana",
                "name": "Montana",
                "pk": "BG__montana"
            },
            {
                "_id": "oblast_sofiya-grad",
                "code": "oblast_sofiya-grad",
                "name": "Oblast Sofiya-Grad",
                "pk": "BG__oblast_sofiya-grad"
            },
            {
                "_id": "pazardzhik",
                "code": "pazardzhik",
                "name": "Pazardzhik",
                "pk": "BG__pazardzhik"
            },
            {
                "_id": "pernik",
                "code": "pernik",
                "name": "Pernik",
                "pk": "BG__pernik"
            },
            {
                "_id": "pleven",
                "code": "pleven",
                "name": "Pleven",
                "pk": "BG__pleven"
            },
            {
                "_id": "plovdiv",
                "code": "plovdiv",
                "name": "Plovdiv",
                "pk": "BG__plovdiv"
            },
            {
                "_id": "razgrad",
                "code": "razgrad",
                "name": "Razgrad",
                "pk": "BG__razgrad"
            },
            {
                "_id": "ruse",
                "code": "ruse",
                "name": "Ruse",
                "pk": "BG__ruse"
            },
            {
                "_id": "shumen",
                "code": "shumen",
                "name": "Shumen",
                "pk": "BG__shumen"
            },
            {
                "_id": "silistra",
                "code": "silistra",
                "name": "Silistra",
                "pk": "BG__silistra"
            },
            {
                "_id": "sliven",
                "code": "sliven",
                "name": "Sliven",
                "pk": "BG__sliven"
            },
            {
                "_id": "smoljan",
                "code": "smoljan",
                "name": "Smoljan",
                "pk": "BG__smoljan"
            },
            {
                "_id": "sofija_grad",
                "code": "sofija_grad",
                "name": "Sofija grad",
                "pk": "BG__sofija_grad"
            },
            {
                "_id": "sofijska_oblast",
                "code": "sofijska_oblast",
                "name": "Sofijska oblast",
                "pk": "BG__sofijska_oblast"
            },
            {
                "_id": "stara_zagora",
                "code": "stara_zagora",
                "name": "Stara Zagora",
                "pk": "BG__stara_zagora"
            },
            {
                "_id": "targovishte",
                "code": "targovishte",
                "name": "Targovishte",
                "pk": "BG__targovishte"
            },
            {
                "_id": "varna",
                "code": "varna",
                "name": "Varna",
                "pk": "BG__varna"
            },
            {
                "_id": "veliko_tarnovo",
                "code": "veliko_tarnovo",
                "name": "Veliko Tarnovo",
                "pk": "BG__veliko_tarnovo"
            },
            {
                "_id": "vidin",
                "code": "vidin",
                "name": "Vidin",
                "pk": "BG__vidin"
            },
            {
                "_id": "vraca",
                "code": "vraca",
                "name": "Vraca",
                "pk": "BG__vraca"
            },
            {
                "_id": "yablaniza",
                "code": "yablaniza",
                "name": "Yablaniza",
                "pk": "BG__yablaniza"
            }
        ],
        "pk": "BG"
    },
    {
        "_id": "BF",
        "code": "BF",
        "name": "Burkina Faso",
        "states": [
            {
                "_id": "bale",
                "code": "bale",
                "name": "Bale",
                "pk": "BF__bale"
            },
            {
                "_id": "bam",
                "code": "bam",
                "name": "Bam",
                "pk": "BF__bam"
            },
            {
                "_id": "bazega",
                "code": "bazega",
                "name": "Bazega",
                "pk": "BF__bazega"
            },
            {
                "_id": "bougouriba",
                "code": "bougouriba",
                "name": "Bougouriba",
                "pk": "BF__bougouriba"
            },
            {
                "_id": "boulgou",
                "code": "boulgou",
                "name": "Boulgou",
                "pk": "BF__boulgou"
            },
            {
                "_id": "boulkiemde",
                "code": "boulkiemde",
                "name": "Boulkiemde",
                "pk": "BF__boulkiemde"
            },
            {
                "_id": "comoe",
                "code": "comoe",
                "name": "Comoe",
                "pk": "BF__comoe"
            },
            {
                "_id": "ganzourgou",
                "code": "ganzourgou",
                "name": "Ganzourgou",
                "pk": "BF__ganzourgou"
            },
            {
                "_id": "gnagna",
                "code": "gnagna",
                "name": "Gnagna",
                "pk": "BF__gnagna"
            },
            {
                "_id": "gourma",
                "code": "gourma",
                "name": "Gourma",
                "pk": "BF__gourma"
            },
            {
                "_id": "houet",
                "code": "houet",
                "name": "Houet",
                "pk": "BF__houet"
            },
            {
                "_id": "ioba",
                "code": "ioba",
                "name": "Ioba",
                "pk": "BF__ioba"
            },
            {
                "_id": "kadiogo",
                "code": "kadiogo",
                "name": "Kadiogo",
                "pk": "BF__kadiogo"
            },
            {
                "_id": "kenedougou",
                "code": "kenedougou",
                "name": "Kenedougou",
                "pk": "BF__kenedougou"
            },
            {
                "_id": "komandjari",
                "code": "komandjari",
                "name": "Komandjari",
                "pk": "BF__komandjari"
            },
            {
                "_id": "kompienga",
                "code": "kompienga",
                "name": "Kompienga",
                "pk": "BF__kompienga"
            },
            {
                "_id": "kossi",
                "code": "kossi",
                "name": "Kossi",
                "pk": "BF__kossi"
            },
            {
                "_id": "kouritenga",
                "code": "kouritenga",
                "name": "Kouritenga",
                "pk": "BF__kouritenga"
            },
            {
                "_id": "kourweogo",
                "code": "kourweogo",
                "name": "Kourweogo",
                "pk": "BF__kourweogo"
            },
            {
                "_id": "leraba",
                "code": "leraba",
                "name": "Leraba",
                "pk": "BF__leraba"
            },
            {
                "_id": "mouhoun",
                "code": "mouhoun",
                "name": "Mouhoun",
                "pk": "BF__mouhoun"
            },
            {
                "_id": "nahouri",
                "code": "nahouri",
                "name": "Nahouri",
                "pk": "BF__nahouri"
            },
            {
                "_id": "namentenga",
                "code": "namentenga",
                "name": "Namentenga",
                "pk": "BF__namentenga"
            },
            {
                "_id": "noumbiel",
                "code": "noumbiel",
                "name": "Noumbiel",
                "pk": "BF__noumbiel"
            },
            {
                "_id": "oubritenga",
                "code": "oubritenga",
                "name": "Oubritenga",
                "pk": "BF__oubritenga"
            },
            {
                "_id": "oudalan",
                "code": "oudalan",
                "name": "Oudalan",
                "pk": "BF__oudalan"
            },
            {
                "_id": "passore",
                "code": "passore",
                "name": "Passore",
                "pk": "BF__passore"
            },
            {
                "_id": "poni",
                "code": "poni",
                "name": "Poni",
                "pk": "BF__poni"
            },
            {
                "_id": "sanguie",
                "code": "sanguie",
                "name": "Sanguie",
                "pk": "BF__sanguie"
            },
            {
                "_id": "sanmatenga",
                "code": "sanmatenga",
                "name": "Sanmatenga",
                "pk": "BF__sanmatenga"
            },
            {
                "_id": "seno",
                "code": "seno",
                "name": "Seno",
                "pk": "BF__seno"
            },
            {
                "_id": "sissili",
                "code": "sissili",
                "name": "Sissili",
                "pk": "BF__sissili"
            },
            {
                "_id": "soum",
                "code": "soum",
                "name": "Soum",
                "pk": "BF__soum"
            },
            {
                "_id": "sourou",
                "code": "sourou",
                "name": "Sourou",
                "pk": "BF__sourou"
            },
            {
                "_id": "tapoa",
                "code": "tapoa",
                "name": "Tapoa",
                "pk": "BF__tapoa"
            },
            {
                "_id": "tuy",
                "code": "tuy",
                "name": "Tuy",
                "pk": "BF__tuy"
            },
            {
                "_id": "yatenga",
                "code": "yatenga",
                "name": "Yatenga",
                "pk": "BF__yatenga"
            },
            {
                "_id": "zondoma",
                "code": "zondoma",
                "name": "Zondoma",
                "pk": "BF__zondoma"
            },
            {
                "_id": "zoundweogo",
                "code": "zoundweogo",
                "name": "Zoundweogo",
                "pk": "BF__zoundweogo"
            }
        ],
        "pk": "BF"
    },
    {
        "_id": "BI",
        "code": "BI",
        "name": "Burundi",
        "states": [
            {
                "_id": "bubanza",
                "code": "bubanza",
                "name": "Bubanza",
                "pk": "BI__bubanza"
            },
            {
                "_id": "bujumbura",
                "code": "bujumbura",
                "name": "Bujumbura",
                "pk": "BI__bujumbura"
            },
            {
                "_id": "bururi",
                "code": "bururi",
                "name": "Bururi",
                "pk": "BI__bururi"
            },
            {
                "_id": "cankuzo",
                "code": "cankuzo",
                "name": "Cankuzo",
                "pk": "BI__cankuzo"
            },
            {
                "_id": "cibitoke",
                "code": "cibitoke",
                "name": "Cibitoke",
                "pk": "BI__cibitoke"
            },
            {
                "_id": "gitega",
                "code": "gitega",
                "name": "Gitega",
                "pk": "BI__gitega"
            },
            {
                "_id": "karuzi",
                "code": "karuzi",
                "name": "Karuzi",
                "pk": "BI__karuzi"
            },
            {
                "_id": "kayanza",
                "code": "kayanza",
                "name": "Kayanza",
                "pk": "BI__kayanza"
            },
            {
                "_id": "kirundo",
                "code": "kirundo",
                "name": "Kirundo",
                "pk": "BI__kirundo"
            },
            {
                "_id": "makamba",
                "code": "makamba",
                "name": "Makamba",
                "pk": "BI__makamba"
            },
            {
                "_id": "muramvya",
                "code": "muramvya",
                "name": "Muramvya",
                "pk": "BI__muramvya"
            },
            {
                "_id": "muyinga",
                "code": "muyinga",
                "name": "Muyinga",
                "pk": "BI__muyinga"
            },
            {
                "_id": "ngozi",
                "code": "ngozi",
                "name": "Ngozi",
                "pk": "BI__ngozi"
            },
            {
                "_id": "rutana",
                "code": "rutana",
                "name": "Rutana",
                "pk": "BI__rutana"
            },
            {
                "_id": "ruyigi",
                "code": "ruyigi",
                "name": "Ruyigi",
                "pk": "BI__ruyigi"
            }
        ],
        "pk": "BI"
    },
    {
        "_id": "KH",
        "code": "KH",
        "name": "Cambodia",
        "states": [
            {
                "_id": "banteay_mean_chey",
                "code": "banteay_mean_chey",
                "name": "Banteay Mean Chey",
                "pk": "KH__banteay_mean_chey"
            },
            {
                "_id": "bat_dambang",
                "code": "bat_dambang",
                "name": "Bat Dambang",
                "pk": "KH__bat_dambang"
            },
            {
                "_id": "kampong_cham",
                "code": "kampong_cham",
                "name": "Kampong Cham",
                "pk": "KH__kampong_cham"
            },
            {
                "_id": "kampong_chhnang",
                "code": "kampong_chhnang",
                "name": "Kampong Chhnang",
                "pk": "KH__kampong_chhnang"
            },
            {
                "_id": "kampong_spoeu",
                "code": "kampong_spoeu",
                "name": "Kampong Spoeu",
                "pk": "KH__kampong_spoeu"
            },
            {
                "_id": "kampong_thum",
                "code": "kampong_thum",
                "name": "Kampong Thum",
                "pk": "KH__kampong_thum"
            },
            {
                "_id": "kampot",
                "code": "kampot",
                "name": "Kampot",
                "pk": "KH__kampot"
            },
            {
                "_id": "kandal",
                "code": "kandal",
                "name": "Kandal",
                "pk": "KH__kandal"
            },
            {
                "_id": "kaoh_kong",
                "code": "kaoh_kong",
                "name": "Kaoh Kong",
                "pk": "KH__kaoh_kong"
            },
            {
                "_id": "kracheh",
                "code": "kracheh",
                "name": "Kracheh",
                "pk": "KH__kracheh"
            },
            {
                "_id": "krong_kaeb",
                "code": "krong_kaeb",
                "name": "Krong Kaeb",
                "pk": "KH__krong_kaeb"
            },
            {
                "_id": "krong_pailin",
                "code": "krong_pailin",
                "name": "Krong Pailin",
                "pk": "KH__krong_pailin"
            },
            {
                "_id": "krong_preah_sihanouk",
                "code": "krong_preah_sihanouk",
                "name": "Krong Preah Sihanouk",
                "pk": "KH__krong_preah_sihanouk"
            },
            {
                "_id": "mondol_kiri",
                "code": "mondol_kiri",
                "name": "Mondol Kiri",
                "pk": "KH__mondol_kiri"
            },
            {
                "_id": "otdar_mean_chey",
                "code": "otdar_mean_chey",
                "name": "Otdar Mean Chey",
                "pk": "KH__otdar_mean_chey"
            },
            {
                "_id": "phnum_penh",
                "code": "phnum_penh",
                "name": "Phnum Penh",
                "pk": "KH__phnum_penh"
            },
            {
                "_id": "pousat",
                "code": "pousat",
                "name": "Pousat",
                "pk": "KH__pousat"
            },
            {
                "_id": "preah_vihear",
                "code": "preah_vihear",
                "name": "Preah Vihear",
                "pk": "KH__preah_vihear"
            },
            {
                "_id": "prey_veaeng",
                "code": "prey_veaeng",
                "name": "Prey Veaeng",
                "pk": "KH__prey_veaeng"
            },
            {
                "_id": "rotanak_kiri",
                "code": "rotanak_kiri",
                "name": "Rotanak Kiri",
                "pk": "KH__rotanak_kiri"
            },
            {
                "_id": "siem_reab",
                "code": "siem_reab",
                "name": "Siem Reab",
                "pk": "KH__siem_reab"
            },
            {
                "_id": "stueng_traeng",
                "code": "stueng_traeng",
                "name": "Stueng Traeng",
                "pk": "KH__stueng_traeng"
            },
            {
                "_id": "svay_rieng",
                "code": "svay_rieng",
                "name": "Svay Rieng",
                "pk": "KH__svay_rieng"
            },
            {
                "_id": "takaev",
                "code": "takaev",
                "name": "Takaev",
                "pk": "KH__takaev"
            }
        ],
        "pk": "KH"
    },
    {
        "_id": "CM",
        "code": "CM",
        "name": "Cameroon",
        "states": [
            {
                "_id": "adamaoua",
                "code": "adamaoua",
                "name": "Adamaoua",
                "pk": "CM__adamaoua"
            },
            {
                "_id": "centre",
                "code": "centre",
                "name": "Centre",
                "pk": "CM__centre"
            },
            {
                "_id": "est",
                "code": "est",
                "name": "Est",
                "pk": "CM__est"
            },
            {
                "_id": "littoral",
                "code": "littoral",
                "name": "Littoral",
                "pk": "CM__littoral"
            },
            {
                "_id": "nord",
                "code": "nord",
                "name": "Nord",
                "pk": "CM__nord"
            },
            {
                "_id": "nord_extreme",
                "code": "nord_extreme",
                "name": "Nord Extreme",
                "pk": "CM__nord_extreme"
            },
            {
                "_id": "nordouest",
                "code": "nordouest",
                "name": "Nordouest",
                "pk": "CM__nordouest"
            },
            {
                "_id": "ouest",
                "code": "ouest",
                "name": "Ouest",
                "pk": "CM__ouest"
            },
            {
                "_id": "sud",
                "code": "sud",
                "name": "Sud",
                "pk": "CM__sud"
            },
            {
                "_id": "sudouest",
                "code": "sudouest",
                "name": "Sudouest",
                "pk": "CM__sudouest"
            }
        ],
        "pk": "CM"
    },
    {
        "_id": "CV",
        "code": "CV",
        "name": "Cape Verde",
        "states": [
            {
                "_id": "boavista",
                "code": "boavista",
                "name": "Boavista",
                "pk": "CV__boavista"
            },
            {
                "_id": "brava",
                "code": "brava",
                "name": "Brava",
                "pk": "CV__brava"
            },
            {
                "_id": "fogo",
                "code": "fogo",
                "name": "Fogo",
                "pk": "CV__fogo"
            },
            {
                "_id": "maio",
                "code": "maio",
                "name": "Maio",
                "pk": "CV__maio"
            },
            {
                "_id": "sal",
                "code": "sal",
                "name": "Sal",
                "pk": "CV__sal"
            },
            {
                "_id": "santo_antao",
                "code": "santo_antao",
                "name": "Santo Antao",
                "pk": "CV__santo_antao"
            },
            {
                "_id": "sao_nicolau",
                "code": "sao_nicolau",
                "name": "Sao Nicolau",
                "pk": "CV__sao_nicolau"
            },
            {
                "_id": "sao_tiago",
                "code": "sao_tiago",
                "name": "Sao Tiago",
                "pk": "CV__sao_tiago"
            },
            {
                "_id": "sao_vicente",
                "code": "sao_vicente",
                "name": "Sao Vicente",
                "pk": "CV__sao_vicente"
            }
        ],
        "pk": "CV"
    },
    {
        "_id": "KY",
        "code": "KY",
        "name": "Cayman Islands",
        "states": [
            {
                "_id": "grand_cayman",
                "code": "grand_cayman",
                "name": "Grand Cayman",
                "pk": "KY__grand_cayman"
            }
        ],
        "pk": "KY"
    },
    {
        "_id": "CF",
        "code": "CF",
        "name": "Central African Republic",
        "states": [
            {
                "_id": "bamingui-bangoran",
                "code": "bamingui-bangoran",
                "name": "Bamingui-Bangoran",
                "pk": "CF__bamingui-bangoran"
            },
            {
                "_id": "bangui",
                "code": "bangui",
                "name": "Bangui",
                "pk": "CF__bangui"
            },
            {
                "_id": "basse-kotto",
                "code": "basse-kotto",
                "name": "Basse-Kotto",
                "pk": "CF__basse-kotto"
            },
            {
                "_id": "haut-mbomou",
                "code": "haut-mbomou",
                "name": "Haut-Mbomou",
                "pk": "CF__haut-mbomou"
            },
            {
                "_id": "haute-kotto",
                "code": "haute-kotto",
                "name": "Haute-Kotto",
                "pk": "CF__haute-kotto"
            },
            {
                "_id": "kemo",
                "code": "kemo",
                "name": "Kemo",
                "pk": "CF__kemo"
            },
            {
                "_id": "lobaye",
                "code": "lobaye",
                "name": "Lobaye",
                "pk": "CF__lobaye"
            },
            {
                "_id": "mambere-kadei",
                "code": "mambere-kadei",
                "name": "Mambere-Kadei",
                "pk": "CF__mambere-kadei"
            },
            {
                "_id": "mbomou",
                "code": "mbomou",
                "name": "Mbomou",
                "pk": "CF__mbomou"
            },
            {
                "_id": "nana-gribizi",
                "code": "nana-gribizi",
                "name": "Nana-Gribizi",
                "pk": "CF__nana-gribizi"
            },
            {
                "_id": "nana-mambere",
                "code": "nana-mambere",
                "name": "Nana-Mambere",
                "pk": "CF__nana-mambere"
            },
            {
                "_id": "ombella_mpoko",
                "code": "ombella_mpoko",
                "name": "Ombella Mpoko",
                "pk": "CF__ombella_mpoko"
            },
            {
                "_id": "ouaka",
                "code": "ouaka",
                "name": "Ouaka",
                "pk": "CF__ouaka"
            },
            {
                "_id": "ouham",
                "code": "ouham",
                "name": "Ouham",
                "pk": "CF__ouham"
            },
            {
                "_id": "ouham-pende",
                "code": "ouham-pende",
                "name": "Ouham-Pende",
                "pk": "CF__ouham-pende"
            },
            {
                "_id": "sangha-mbaere",
                "code": "sangha-mbaere",
                "name": "Sangha-Mbaere",
                "pk": "CF__sangha-mbaere"
            },
            {
                "_id": "vakaga",
                "code": "vakaga",
                "name": "Vakaga",
                "pk": "CF__vakaga"
            }
        ],
        "pk": "CF"
    },
    {
        "_id": "TD",
        "code": "TD",
        "name": "Chad",
        "states": [
            {
                "_id": "batha",
                "code": "batha",
                "name": "Batha",
                "pk": "TD__batha"
            },
            {
                "_id": "biltine",
                "code": "biltine",
                "name": "Biltine",
                "pk": "TD__biltine"
            },
            {
                "_id": "bourkou-ennedi-tibesti",
                "code": "bourkou-ennedi-tibesti",
                "name": "Bourkou-Ennedi-Tibesti",
                "pk": "TD__bourkou-ennedi-tibesti"
            },
            {
                "_id": "chari-baguirmi",
                "code": "chari-baguirmi",
                "name": "Chari-Baguirmi",
                "pk": "TD__chari-baguirmi"
            },
            {
                "_id": "guera",
                "code": "guera",
                "name": "Guera",
                "pk": "TD__guera"
            },
            {
                "_id": "kanem",
                "code": "kanem",
                "name": "Kanem",
                "pk": "TD__kanem"
            },
            {
                "_id": "lac",
                "code": "lac",
                "name": "Lac",
                "pk": "TD__lac"
            },
            {
                "_id": "logone_occidental",
                "code": "logone_occidental",
                "name": "Logone Occidental",
                "pk": "TD__logone_occidental"
            },
            {
                "_id": "logone_oriental",
                "code": "logone_oriental",
                "name": "Logone Oriental",
                "pk": "TD__logone_oriental"
            },
            {
                "_id": "mayo-kebbi",
                "code": "mayo-kebbi",
                "name": "Mayo-Kebbi",
                "pk": "TD__mayo-kebbi"
            },
            {
                "_id": "moyen-chari",
                "code": "moyen-chari",
                "name": "Moyen-Chari",
                "pk": "TD__moyen-chari"
            },
            {
                "_id": "ouaddai",
                "code": "ouaddai",
                "name": "Ouaddai",
                "pk": "TD__ouaddai"
            },
            {
                "_id": "salamat",
                "code": "salamat",
                "name": "Salamat",
                "pk": "TD__salamat"
            },
            {
                "_id": "tandjile",
                "code": "tandjile",
                "name": "Tandjile",
                "pk": "TD__tandjile"
            }
        ],
        "pk": "TD"
    },
    {
        "_id": "CL",
        "code": "CL",
        "name": "Chile",
        "states": [
            {
                "_id": "aisen",
                "code": "aisen",
                "name": "Aisen",
                "pk": "CL__aisen"
            },
            {
                "_id": "antofagasta",
                "code": "antofagasta",
                "name": "Antofagasta",
                "pk": "CL__antofagasta"
            },
            {
                "_id": "araucania",
                "code": "araucania",
                "name": "Araucania",
                "pk": "CL__araucania"
            },
            {
                "_id": "atacama",
                "code": "atacama",
                "name": "Atacama",
                "pk": "CL__atacama"
            },
            {
                "_id": "bio_bio",
                "code": "bio_bio",
                "name": "Bio Bio",
                "pk": "CL__bio_bio"
            },
            {
                "_id": "coquimbo",
                "code": "coquimbo",
                "name": "Coquimbo",
                "pk": "CL__coquimbo"
            },
            {
                "_id": "libertador_general_bernardo_o",
                "code": "libertador_general_bernardo_o",
                "name": "Libertador General Bernardo O",
                "pk": "CL__libertador_general_bernardo_o"
            },
            {
                "_id": "los_lagos",
                "code": "los_lagos",
                "name": "Los Lagos",
                "pk": "CL__los_lagos"
            },
            {
                "_id": "magellanes",
                "code": "magellanes",
                "name": "Magellanes",
                "pk": "CL__magellanes"
            },
            {
                "_id": "maule",
                "code": "maule",
                "name": "Maule",
                "pk": "CL__maule"
            },
            {
                "_id": "metropolitana",
                "code": "metropolitana",
                "name": "Metropolitana",
                "pk": "CL__metropolitana"
            },
            {
                "_id": "metropolitana_de_santiago",
                "code": "metropolitana_de_santiago",
                "name": "Metropolitana de Santiago",
                "pk": "CL__metropolitana_de_santiago"
            },
            {
                "_id": "tarapaca",
                "code": "tarapaca",
                "name": "Tarapaca",
                "pk": "CL__tarapaca"
            },
            {
                "_id": "valparaiso",
                "code": "valparaiso",
                "name": "Valparaiso",
                "pk": "CL__valparaiso"
            }
        ],
        "pk": "CL"
    },
    {
        "_id": "CN",
        "code": "CN",
        "name": "China",
        "states": [
            {
                "_id": "anhui",
                "code": "anhui",
                "name": "Anhui",
                "pk": "CN__anhui"
            },
            {
                "_id": "anhui_province",
                "code": "anhui_province",
                "name": "Anhui Province",
                "pk": "CN__anhui_province"
            },
            {
                "_id": "anhui_sheng",
                "code": "anhui_sheng",
                "name": "Anhui Sheng",
                "pk": "CN__anhui_sheng"
            },
            {
                "_id": "aomen",
                "code": "aomen",
                "name": "Aomen",
                "pk": "CN__aomen"
            },
            {
                "_id": "beijing",
                "code": "beijing",
                "name": "Beijing",
                "pk": "CN__beijing"
            },
            {
                "_id": "beijing_shi",
                "code": "beijing_shi",
                "name": "Beijing Shi",
                "pk": "CN__beijing_shi"
            },
            {
                "_id": "chongqing",
                "code": "chongqing",
                "name": "Chongqing",
                "pk": "CN__chongqing"
            },
            {
                "_id": "fujian",
                "code": "fujian",
                "name": "Fujian",
                "pk": "CN__fujian"
            },
            {
                "_id": "fujian_sheng",
                "code": "fujian_sheng",
                "name": "Fujian Sheng",
                "pk": "CN__fujian_sheng"
            },
            {
                "_id": "gansu",
                "code": "gansu",
                "name": "Gansu",
                "pk": "CN__gansu"
            },
            {
                "_id": "guangdong",
                "code": "guangdong",
                "name": "Guangdong",
                "pk": "CN__guangdong"
            },
            {
                "_id": "guangdong_sheng",
                "code": "guangdong_sheng",
                "name": "Guangdong Sheng",
                "pk": "CN__guangdong_sheng"
            },
            {
                "_id": "guangxi",
                "code": "guangxi",
                "name": "Guangxi",
                "pk": "CN__guangxi"
            },
            {
                "_id": "guizhou",
                "code": "guizhou",
                "name": "Guizhou",
                "pk": "CN__guizhou"
            },
            {
                "_id": "hainan",
                "code": "hainan",
                "name": "Hainan",
                "pk": "CN__hainan"
            },
            {
                "_id": "hebei",
                "code": "hebei",
                "name": "Hebei",
                "pk": "CN__hebei"
            },
            {
                "_id": "heilongjiang",
                "code": "heilongjiang",
                "name": "Heilongjiang",
                "pk": "CN__heilongjiang"
            },
            {
                "_id": "henan",
                "code": "henan",
                "name": "Henan",
                "pk": "CN__henan"
            },
            {
                "_id": "hubei",
                "code": "hubei",
                "name": "Hubei",
                "pk": "CN__hubei"
            },
            {
                "_id": "hunan",
                "code": "hunan",
                "name": "Hunan",
                "pk": "CN__hunan"
            },
            {
                "_id": "jiangsu",
                "code": "jiangsu",
                "name": "Jiangsu",
                "pk": "CN__jiangsu"
            },
            {
                "_id": "jiangsu_sheng",
                "code": "jiangsu_sheng",
                "name": "Jiangsu Sheng",
                "pk": "CN__jiangsu_sheng"
            },
            {
                "_id": "jiangxi",
                "code": "jiangxi",
                "name": "Jiangxi",
                "pk": "CN__jiangxi"
            },
            {
                "_id": "jilin",
                "code": "jilin",
                "name": "Jilin",
                "pk": "CN__jilin"
            },
            {
                "_id": "liaoning",
                "code": "liaoning",
                "name": "Liaoning",
                "pk": "CN__liaoning"
            },
            {
                "_id": "liaoning_sheng",
                "code": "liaoning_sheng",
                "name": "Liaoning Sheng",
                "pk": "CN__liaoning_sheng"
            },
            {
                "_id": "nei_monggol",
                "code": "nei_monggol",
                "name": "Nei Monggol",
                "pk": "CN__nei_monggol"
            },
            {
                "_id": "ningxia_hui",
                "code": "ningxia_hui",
                "name": "Ningxia Hui",
                "pk": "CN__ningxia_hui"
            },
            {
                "_id": "qinghai",
                "code": "qinghai",
                "name": "Qinghai",
                "pk": "CN__qinghai"
            },
            {
                "_id": "shaanxi",
                "code": "shaanxi",
                "name": "Shaanxi",
                "pk": "CN__shaanxi"
            },
            {
                "_id": "shandong",
                "code": "shandong",
                "name": "Shandong",
                "pk": "CN__shandong"
            },
            {
                "_id": "shandong_sheng",
                "code": "shandong_sheng",
                "name": "Shandong Sheng",
                "pk": "CN__shandong_sheng"
            },
            {
                "_id": "shanghai",
                "code": "shanghai",
                "name": "Shanghai",
                "pk": "CN__shanghai"
            },
            {
                "_id": "shanxi",
                "code": "shanxi",
                "name": "Shanxi",
                "pk": "CN__shanxi"
            },
            {
                "_id": "sichuan",
                "code": "sichuan",
                "name": "Sichuan",
                "pk": "CN__sichuan"
            },
            {
                "_id": "tianjin",
                "code": "tianjin",
                "name": "Tianjin",
                "pk": "CN__tianjin"
            },
            {
                "_id": "xianggang",
                "code": "xianggang",
                "name": "Xianggang",
                "pk": "CN__xianggang"
            },
            {
                "_id": "xinjiang",
                "code": "xinjiang",
                "name": "Xinjiang",
                "pk": "CN__xinjiang"
            },
            {
                "_id": "xizang",
                "code": "xizang",
                "name": "Xizang",
                "pk": "CN__xizang"
            },
            {
                "_id": "yunnan",
                "code": "yunnan",
                "name": "Yunnan",
                "pk": "CN__yunnan"
            },
            {
                "_id": "zhejiang",
                "code": "zhejiang",
                "name": "Zhejiang",
                "pk": "CN__zhejiang"
            },
            {
                "_id": "zhejiang_sheng",
                "code": "zhejiang_sheng",
                "name": "Zhejiang Sheng",
                "pk": "CN__zhejiang_sheng"
            }
        ],
        "pk": "CN"
    },
    {
        "_id": "CX",
        "code": "CX",
        "name": "Christmas Island",
        "states": [
            {
                "_id": "christmas_island",
                "code": "christmas_island",
                "name": "Christmas Island",
                "pk": "CX__christmas_island"
            }
        ],
        "pk": "CX"
    },
    {
        "_id": "CC",
        "code": "CC",
        "name": "Cocos Keeling Islands",
        "states": [
            {
                "_id": "cocos_(keeling_islands",
                "code": "cocos_(keeling_islands",
                "name": "Cocos (Keeling Islands",
                "pk": "CC__cocos_(keeling_islands"
            }
        ],
        "pk": "CC"
    },
    {
        "_id": "CO",
        "code": "CO",
        "name": "Colombia",
        "states": [
            {
                "_id": "amazonas",
                "code": "amazonas",
                "name": "Amazonas",
                "pk": "CO__amazonas"
            },
            {
                "_id": "antioquia",
                "code": "antioquia",
                "name": "Antioquia",
                "pk": "CO__antioquia"
            },
            {
                "_id": "arauca",
                "code": "arauca",
                "name": "Arauca",
                "pk": "CO__arauca"
            },
            {
                "_id": "atlantico",
                "code": "atlantico",
                "name": "Atlantico",
                "pk": "CO__atlantico"
            },
            {
                "_id": "bogota",
                "code": "bogota",
                "name": "Bogota",
                "pk": "CO__bogota"
            },
            {
                "_id": "bolivar",
                "code": "bolivar",
                "name": "Bolivar",
                "pk": "CO__bolivar"
            },
            {
                "_id": "boyaca",
                "code": "boyaca",
                "name": "Boyaca",
                "pk": "CO__boyaca"
            },
            {
                "_id": "caldas",
                "code": "caldas",
                "name": "Caldas",
                "pk": "CO__caldas"
            },
            {
                "_id": "caqueta",
                "code": "caqueta",
                "name": "Caqueta",
                "pk": "CO__caqueta"
            },
            {
                "_id": "casanare",
                "code": "casanare",
                "name": "Casanare",
                "pk": "CO__casanare"
            },
            {
                "_id": "cauca",
                "code": "cauca",
                "name": "Cauca",
                "pk": "CO__cauca"
            },
            {
                "_id": "cesar",
                "code": "cesar",
                "name": "Cesar",
                "pk": "CO__cesar"
            },
            {
                "_id": "choco",
                "code": "choco",
                "name": "Choco",
                "pk": "CO__choco"
            },
            {
                "_id": "cordoba",
                "code": "cordoba",
                "name": "Cordoba",
                "pk": "CO__cordoba"
            },
            {
                "_id": "cundinamarca",
                "code": "cundinamarca",
                "name": "Cundinamarca",
                "pk": "CO__cundinamarca"
            },
            {
                "_id": "guainia",
                "code": "guainia",
                "name": "Guainia",
                "pk": "CO__guainia"
            },
            {
                "_id": "guaviare",
                "code": "guaviare",
                "name": "Guaviare",
                "pk": "CO__guaviare"
            },
            {
                "_id": "huila",
                "code": "huila",
                "name": "Huila",
                "pk": "CO__huila"
            },
            {
                "_id": "la_guajira",
                "code": "la_guajira",
                "name": "La Guajira",
                "pk": "CO__la_guajira"
            },
            {
                "_id": "magdalena",
                "code": "magdalena",
                "name": "Magdalena",
                "pk": "CO__magdalena"
            },
            {
                "_id": "meta",
                "code": "meta",
                "name": "Meta",
                "pk": "CO__meta"
            },
            {
                "_id": "narino",
                "code": "narino",
                "name": "Narino",
                "pk": "CO__narino"
            },
            {
                "_id": "norte_de_santander",
                "code": "norte_de_santander",
                "name": "Norte de Santander",
                "pk": "CO__norte_de_santander"
            },
            {
                "_id": "putumayo",
                "code": "putumayo",
                "name": "Putumayo",
                "pk": "CO__putumayo"
            },
            {
                "_id": "quindio",
                "code": "quindio",
                "name": "Quindio",
                "pk": "CO__quindio"
            },
            {
                "_id": "risaralda",
                "code": "risaralda",
                "name": "Risaralda",
                "pk": "CO__risaralda"
            },
            {
                "_id": "san_andres_y_providencia",
                "code": "san_andres_y_providencia",
                "name": "San Andres y Providencia",
                "pk": "CO__san_andres_y_providencia"
            },
            {
                "_id": "santander",
                "code": "santander",
                "name": "Santander",
                "pk": "CO__santander"
            },
            {
                "_id": "sucre",
                "code": "sucre",
                "name": "Sucre",
                "pk": "CO__sucre"
            },
            {
                "_id": "tolima",
                "code": "tolima",
                "name": "Tolima",
                "pk": "CO__tolima"
            },
            {
                "_id": "valle_del_cauca",
                "code": "valle_del_cauca",
                "name": "Valle del Cauca",
                "pk": "CO__valle_del_cauca"
            },
            {
                "_id": "vaupes",
                "code": "vaupes",
                "name": "Vaupes",
                "pk": "CO__vaupes"
            },
            {
                "_id": "vichada",
                "code": "vichada",
                "name": "Vichada",
                "pk": "CO__vichada"
            }
        ],
        "pk": "CO"
    },
    {
        "_id": "KM",
        "code": "KM",
        "name": "Comoros",
        "states": [
            {
                "_id": "mwali",
                "code": "mwali",
                "name": "Mwali",
                "pk": "KM__mwali"
            },
            {
                "_id": "njazidja",
                "code": "njazidja",
                "name": "Njazidja",
                "pk": "KM__njazidja"
            },
            {
                "_id": "nzwani",
                "code": "nzwani",
                "name": "Nzwani",
                "pk": "KM__nzwani"
            }
        ],
        "pk": "KM"
    },
    {
        "_id": "CK",
        "code": "CK",
        "name": "Cook Islands",
        "states": [
            {
                "_id": "aitutaki",
                "code": "aitutaki",
                "name": "Aitutaki",
                "pk": "CK__aitutaki"
            },
            {
                "_id": "atiu",
                "code": "atiu",
                "name": "Atiu",
                "pk": "CK__atiu"
            },
            {
                "_id": "mangaia",
                "code": "mangaia",
                "name": "Mangaia",
                "pk": "CK__mangaia"
            },
            {
                "_id": "manihiki",
                "code": "manihiki",
                "name": "Manihiki",
                "pk": "CK__manihiki"
            },
            {
                "_id": "mauke",
                "code": "mauke",
                "name": "Mauke",
                "pk": "CK__mauke"
            },
            {
                "_id": "mitiaro",
                "code": "mitiaro",
                "name": "Mitiaro",
                "pk": "CK__mitiaro"
            },
            {
                "_id": "nassau",
                "code": "nassau",
                "name": "Nassau",
                "pk": "CK__nassau"
            },
            {
                "_id": "pukapuka",
                "code": "pukapuka",
                "name": "Pukapuka",
                "pk": "CK__pukapuka"
            },
            {
                "_id": "rakahanga",
                "code": "rakahanga",
                "name": "Rakahanga",
                "pk": "CK__rakahanga"
            },
            {
                "_id": "rarotonga",
                "code": "rarotonga",
                "name": "Rarotonga",
                "pk": "CK__rarotonga"
            },
            {
                "_id": "tongareva",
                "code": "tongareva",
                "name": "Tongareva",
                "pk": "CK__tongareva"
            }
        ],
        "pk": "CK"
    },
    {
        "_id": "CR",
        "code": "CR",
        "name": "Costa Rica",
        "states": [
            {
                "_id": "alajuela",
                "code": "alajuela",
                "name": "Alajuela",
                "pk": "CR__alajuela"
            },
            {
                "_id": "cartago",
                "code": "cartago",
                "name": "Cartago",
                "pk": "CR__cartago"
            },
            {
                "_id": "guanacaste",
                "code": "guanacaste",
                "name": "Guanacaste",
                "pk": "CR__guanacaste"
            },
            {
                "_id": "heredia",
                "code": "heredia",
                "name": "Heredia",
                "pk": "CR__heredia"
            },
            {
                "_id": "limon",
                "code": "limon",
                "name": "Limon",
                "pk": "CR__limon"
            },
            {
                "_id": "puntarenas",
                "code": "puntarenas",
                "name": "Puntarenas",
                "pk": "CR__puntarenas"
            },
            {
                "_id": "san_jose",
                "code": "san_jose",
                "name": "San Jose",
                "pk": "CR__san_jose"
            }
        ],
        "pk": "CR"
    },
    {
        "_id": "CI",
        "code": "CI",
        "name": "Cote DIvoire Ivory Coast",
        "states": [
            {
                "_id": "abidjan",
                "code": "abidjan",
                "name": "Abidjan",
                "pk": "CI__abidjan"
            },
            {
                "_id": "agneby",
                "code": "agneby",
                "name": "Agneby",
                "pk": "CI__agneby"
            },
            {
                "_id": "bafing",
                "code": "bafing",
                "name": "Bafing",
                "pk": "CI__bafing"
            },
            {
                "_id": "denguele",
                "code": "denguele",
                "name": "Denguele",
                "pk": "CI__denguele"
            },
            {
                "_id": "dix-huit_montagnes",
                "code": "dix-huit_montagnes",
                "name": "Dix-huit Montagnes",
                "pk": "CI__dix-huit_montagnes"
            },
            {
                "_id": "fromager",
                "code": "fromager",
                "name": "Fromager",
                "pk": "CI__fromager"
            },
            {
                "_id": "haut-sassandra",
                "code": "haut-sassandra",
                "name": "Haut-Sassandra",
                "pk": "CI__haut-sassandra"
            },
            {
                "_id": "lacs",
                "code": "lacs",
                "name": "Lacs",
                "pk": "CI__lacs"
            },
            {
                "_id": "lagunes",
                "code": "lagunes",
                "name": "Lagunes",
                "pk": "CI__lagunes"
            },
            {
                "_id": "marahoue",
                "code": "marahoue",
                "name": "Marahoue",
                "pk": "CI__marahoue"
            },
            {
                "_id": "moyen-cavally",
                "code": "moyen-cavally",
                "name": "Moyen-Cavally",
                "pk": "CI__moyen-cavally"
            },
            {
                "_id": "moyen-comoe",
                "code": "moyen-comoe",
                "name": "Moyen-Comoe",
                "pk": "CI__moyen-comoe"
            },
            {
                "_id": "nzi-comoe",
                "code": "nzi-comoe",
                "name": "Nzi-Comoe",
                "pk": "CI__nzi-comoe"
            },
            {
                "_id": "sassandra",
                "code": "sassandra",
                "name": "Sassandra",
                "pk": "CI__sassandra"
            },
            {
                "_id": "savanes",
                "code": "savanes",
                "name": "Savanes",
                "pk": "CI__savanes"
            },
            {
                "_id": "sud-bandama",
                "code": "sud-bandama",
                "name": "Sud-Bandama",
                "pk": "CI__sud-bandama"
            },
            {
                "_id": "sud-comoe",
                "code": "sud-comoe",
                "name": "Sud-Comoe",
                "pk": "CI__sud-comoe"
            },
            {
                "_id": "vallee_du_bandama",
                "code": "vallee_du_bandama",
                "name": "Vallee du Bandama",
                "pk": "CI__vallee_du_bandama"
            },
            {
                "_id": "worodougou",
                "code": "worodougou",
                "name": "Worodougou",
                "pk": "CI__worodougou"
            },
            {
                "_id": "zanzan",
                "code": "zanzan",
                "name": "Zanzan",
                "pk": "CI__zanzan"
            }
        ],
        "pk": "CI"
    },
    {
        "_id": "HR",
        "code": "HR",
        "name": "Croatia Hrvatska",
        "states": [
            {
                "_id": "bjelovar-bilogora",
                "code": "bjelovar-bilogora",
                "name": "Bjelovar-Bilogora",
                "pk": "HR__bjelovar-bilogora"
            },
            {
                "_id": "dubrovnik-neretva",
                "code": "dubrovnik-neretva",
                "name": "Dubrovnik-Neretva",
                "pk": "HR__dubrovnik-neretva"
            },
            {
                "_id": "grad_zagreb",
                "code": "grad_zagreb",
                "name": "Grad Zagreb",
                "pk": "HR__grad_zagreb"
            },
            {
                "_id": "istra",
                "code": "istra",
                "name": "Istra",
                "pk": "HR__istra"
            },
            {
                "_id": "karlovac",
                "code": "karlovac",
                "name": "Karlovac",
                "pk": "HR__karlovac"
            },
            {
                "_id": "koprivnica-krizhevci",
                "code": "koprivnica-krizhevci",
                "name": "Koprivnica-Krizhevci",
                "pk": "HR__koprivnica-krizhevci"
            },
            {
                "_id": "krapina-zagorje",
                "code": "krapina-zagorje",
                "name": "Krapina-Zagorje",
                "pk": "HR__krapina-zagorje"
            },
            {
                "_id": "lika-senj",
                "code": "lika-senj",
                "name": "Lika-Senj",
                "pk": "HR__lika-senj"
            },
            {
                "_id": "medhimurje",
                "code": "medhimurje",
                "name": "Medhimurje",
                "pk": "HR__medhimurje"
            },
            {
                "_id": "medimurska_zupanija",
                "code": "medimurska_zupanija",
                "name": "Medimurska Zupanija",
                "pk": "HR__medimurska_zupanija"
            },
            {
                "_id": "osijek-baranja",
                "code": "osijek-baranja",
                "name": "Osijek-Baranja",
                "pk": "HR__osijek-baranja"
            },
            {
                "_id": "osjecko-baranjska_zupanija",
                "code": "osjecko-baranjska_zupanija",
                "name": "Osjecko-Baranjska Zupanija",
                "pk": "HR__osjecko-baranjska_zupanija"
            },
            {
                "_id": "pozhega-slavonija",
                "code": "pozhega-slavonija",
                "name": "Pozhega-Slavonija",
                "pk": "HR__pozhega-slavonija"
            },
            {
                "_id": "primorje-gorski_kotar",
                "code": "primorje-gorski_kotar",
                "name": "Primorje-Gorski Kotar",
                "pk": "HR__primorje-gorski_kotar"
            },
            {
                "_id": "shibenik-knin",
                "code": "shibenik-knin",
                "name": "Shibenik-Knin",
                "pk": "HR__shibenik-knin"
            },
            {
                "_id": "sisak-moslavina",
                "code": "sisak-moslavina",
                "name": "Sisak-Moslavina",
                "pk": "HR__sisak-moslavina"
            },
            {
                "_id": "slavonski_brod-posavina",
                "code": "slavonski_brod-posavina",
                "name": "Slavonski Brod-Posavina",
                "pk": "HR__slavonski_brod-posavina"
            },
            {
                "_id": "split-dalmacija",
                "code": "split-dalmacija",
                "name": "Split-Dalmacija",
                "pk": "HR__split-dalmacija"
            },
            {
                "_id": "varazhdin",
                "code": "varazhdin",
                "name": "Varazhdin",
                "pk": "HR__varazhdin"
            },
            {
                "_id": "virovitica-podravina",
                "code": "virovitica-podravina",
                "name": "Virovitica-Podravina",
                "pk": "HR__virovitica-podravina"
            },
            {
                "_id": "vukovar-srijem",
                "code": "vukovar-srijem",
                "name": "Vukovar-Srijem",
                "pk": "HR__vukovar-srijem"
            },
            {
                "_id": "zadar",
                "code": "zadar",
                "name": "Zadar",
                "pk": "HR__zadar"
            },
            {
                "_id": "zagreb",
                "code": "zagreb",
                "name": "Zagreb",
                "pk": "HR__zagreb"
            }
        ],
        "pk": "HR"
    },
    {
        "_id": "CU",
        "code": "CU",
        "name": "Cuba",
        "states": [
            {
                "_id": "camaguey",
                "code": "camaguey",
                "name": "Camaguey",
                "pk": "CU__camaguey"
            },
            {
                "_id": "ciego_de_avila",
                "code": "ciego_de_avila",
                "name": "Ciego de Avila",
                "pk": "CU__ciego_de_avila"
            },
            {
                "_id": "cienfuegos",
                "code": "cienfuegos",
                "name": "Cienfuegos",
                "pk": "CU__cienfuegos"
            },
            {
                "_id": "ciudad_de_la_habana",
                "code": "ciudad_de_la_habana",
                "name": "Ciudad de la Habana",
                "pk": "CU__ciudad_de_la_habana"
            },
            {
                "_id": "granma",
                "code": "granma",
                "name": "Granma",
                "pk": "CU__granma"
            },
            {
                "_id": "guantanamo",
                "code": "guantanamo",
                "name": "Guantanamo",
                "pk": "CU__guantanamo"
            },
            {
                "_id": "habana",
                "code": "habana",
                "name": "Habana",
                "pk": "CU__habana"
            },
            {
                "_id": "holguin",
                "code": "holguin",
                "name": "Holguin",
                "pk": "CU__holguin"
            },
            {
                "_id": "isla_de_la_juventud",
                "code": "isla_de_la_juventud",
                "name": "Isla de la Juventud",
                "pk": "CU__isla_de_la_juventud"
            },
            {
                "_id": "la_habana",
                "code": "la_habana",
                "name": "La Habana",
                "pk": "CU__la_habana"
            },
            {
                "_id": "las_tunas",
                "code": "las_tunas",
                "name": "Las Tunas",
                "pk": "CU__las_tunas"
            },
            {
                "_id": "matanzas",
                "code": "matanzas",
                "name": "Matanzas",
                "pk": "CU__matanzas"
            },
            {
                "_id": "pinar_del_rio",
                "code": "pinar_del_rio",
                "name": "Pinar del Rio",
                "pk": "CU__pinar_del_rio"
            },
            {
                "_id": "sancti_spiritus",
                "code": "sancti_spiritus",
                "name": "Sancti Spiritus",
                "pk": "CU__sancti_spiritus"
            },
            {
                "_id": "santiago_de_cuba",
                "code": "santiago_de_cuba",
                "name": "Santiago de Cuba",
                "pk": "CU__santiago_de_cuba"
            },
            {
                "_id": "villa_clara",
                "code": "villa_clara",
                "name": "Villa Clara",
                "pk": "CU__villa_clara"
            }
        ],
        "pk": "CU"
    },
    {
        "_id": "CY",
        "code": "CY",
        "name": "Cyprus",
        "states": [
            {
                "_id": "government_controlled_area",
                "code": "government_controlled_area",
                "name": "Government controlled area",
                "pk": "CY__government_controlled_area"
            },
            {
                "_id": "limassol",
                "code": "limassol",
                "name": "Limassol",
                "pk": "CY__limassol"
            },
            {
                "_id": "nicosia_district",
                "code": "nicosia_district",
                "name": "Nicosia District",
                "pk": "CY__nicosia_district"
            },
            {
                "_id": "paphos",
                "code": "paphos",
                "name": "Paphos",
                "pk": "CY__paphos"
            },
            {
                "_id": "turkish_controlled_area",
                "code": "turkish_controlled_area",
                "name": "Turkish controlled area",
                "pk": "CY__turkish_controlled_area"
            }
        ],
        "pk": "CY"
    },
    {
        "_id": "CZ",
        "code": "CZ",
        "name": "Czech Republic",
        "states": [
            {
                "_id": "central_bohemian",
                "code": "central_bohemian",
                "name": "Central Bohemian",
                "pk": "CZ__central_bohemian"
            },
            {
                "_id": "frycovice",
                "code": "frycovice",
                "name": "Frycovice",
                "pk": "CZ__frycovice"
            },
            {
                "_id": "jihocesky_kraj",
                "code": "jihocesky_kraj",
                "name": "Jihocesky Kraj",
                "pk": "CZ__jihocesky_kraj"
            },
            {
                "_id": "jihochesky",
                "code": "jihochesky",
                "name": "Jihochesky",
                "pk": "CZ__jihochesky"
            },
            {
                "_id": "jihomoravsky",
                "code": "jihomoravsky",
                "name": "Jihomoravsky",
                "pk": "CZ__jihomoravsky"
            },
            {
                "_id": "karlovarsky",
                "code": "karlovarsky",
                "name": "Karlovarsky",
                "pk": "CZ__karlovarsky"
            },
            {
                "_id": "klecany",
                "code": "klecany",
                "name": "Klecany",
                "pk": "CZ__klecany"
            },
            {
                "_id": "kralovehradecky",
                "code": "kralovehradecky",
                "name": "Kralovehradecky",
                "pk": "CZ__kralovehradecky"
            },
            {
                "_id": "liberecky",
                "code": "liberecky",
                "name": "Liberecky",
                "pk": "CZ__liberecky"
            },
            {
                "_id": "lipov",
                "code": "lipov",
                "name": "Lipov",
                "pk": "CZ__lipov"
            },
            {
                "_id": "moravskoslezsky",
                "code": "moravskoslezsky",
                "name": "Moravskoslezsky",
                "pk": "CZ__moravskoslezsky"
            },
            {
                "_id": "olomoucky",
                "code": "olomoucky",
                "name": "Olomoucky",
                "pk": "CZ__olomoucky"
            },
            {
                "_id": "olomoucky_kraj",
                "code": "olomoucky_kraj",
                "name": "Olomoucky Kraj",
                "pk": "CZ__olomoucky_kraj"
            },
            {
                "_id": "pardubicky",
                "code": "pardubicky",
                "name": "Pardubicky",
                "pk": "CZ__pardubicky"
            },
            {
                "_id": "plzensky",
                "code": "plzensky",
                "name": "Plzensky",
                "pk": "CZ__plzensky"
            },
            {
                "_id": "praha",
                "code": "praha",
                "name": "Praha",
                "pk": "CZ__praha"
            },
            {
                "_id": "rajhrad",
                "code": "rajhrad",
                "name": "Rajhrad",
                "pk": "CZ__rajhrad"
            },
            {
                "_id": "smirice",
                "code": "smirice",
                "name": "Smirice",
                "pk": "CZ__smirice"
            },
            {
                "_id": "south_moravian",
                "code": "south_moravian",
                "name": "South Moravian",
                "pk": "CZ__south_moravian"
            },
            {
                "_id": "straz_nad_nisou",
                "code": "straz_nad_nisou",
                "name": "Straz nad Nisou",
                "pk": "CZ__straz_nad_nisou"
            },
            {
                "_id": "stredochesky",
                "code": "stredochesky",
                "name": "Stredochesky",
                "pk": "CZ__stredochesky"
            },
            {
                "_id": "unicov",
                "code": "unicov",
                "name": "Unicov",
                "pk": "CZ__unicov"
            },
            {
                "_id": "ustecky",
                "code": "ustecky",
                "name": "Ustecky",
                "pk": "CZ__ustecky"
            },
            {
                "_id": "valletta",
                "code": "valletta",
                "name": "Valletta",
                "pk": "CZ__valletta"
            },
            {
                "_id": "velesin",
                "code": "velesin",
                "name": "Velesin",
                "pk": "CZ__velesin"
            },
            {
                "_id": "vysochina",
                "code": "vysochina",
                "name": "Vysochina",
                "pk": "CZ__vysochina"
            },
            {
                "_id": "zlinsky",
                "code": "zlinsky",
                "name": "Zlinsky",
                "pk": "CZ__zlinsky"
            }
        ],
        "pk": "CZ"
    },
    {
        "_id": "CD",
        "code": "CD",
        "name": "Democratic Republic Of The Congo",
        "states": [
            {
                "_id": "bandundu",
                "code": "bandundu",
                "name": "Bandundu",
                "pk": "CD__bandundu"
            },
            {
                "_id": "bas-congo",
                "code": "bas-congo",
                "name": "Bas-Congo",
                "pk": "CD__bas-congo"
            },
            {
                "_id": "equateur",
                "code": "equateur",
                "name": "Equateur",
                "pk": "CD__equateur"
            },
            {
                "_id": "haut-congo",
                "code": "haut-congo",
                "name": "Haut-Congo",
                "pk": "CD__haut-congo"
            },
            {
                "_id": "kasai-occidental",
                "code": "kasai-occidental",
                "name": "Kasai-Occidental",
                "pk": "CD__kasai-occidental"
            },
            {
                "_id": "kasai-oriental",
                "code": "kasai-oriental",
                "name": "Kasai-Oriental",
                "pk": "CD__kasai-oriental"
            },
            {
                "_id": "katanga",
                "code": "katanga",
                "name": "Katanga",
                "pk": "CD__katanga"
            },
            {
                "_id": "kinshasa",
                "code": "kinshasa",
                "name": "Kinshasa",
                "pk": "CD__kinshasa"
            },
            {
                "_id": "maniema",
                "code": "maniema",
                "name": "Maniema",
                "pk": "CD__maniema"
            },
            {
                "_id": "nord-kivu",
                "code": "nord-kivu",
                "name": "Nord-Kivu",
                "pk": "CD__nord-kivu"
            },
            {
                "_id": "sud-kivu",
                "code": "sud-kivu",
                "name": "Sud-Kivu",
                "pk": "CD__sud-kivu"
            }
        ],
        "pk": "CD"
    },
    {
        "_id": "DK",
        "code": "DK",
        "name": "Denmark",
        "states": [
            {
                "_id": "arhus",
                "code": "arhus",
                "name": "Arhus",
                "pk": "DK__arhus"
            },
            {
                "_id": "bornholm",
                "code": "bornholm",
                "name": "Bornholm",
                "pk": "DK__bornholm"
            },
            {
                "_id": "frederiksborg",
                "code": "frederiksborg",
                "name": "Frederiksborg",
                "pk": "DK__frederiksborg"
            },
            {
                "_id": "fyn",
                "code": "fyn",
                "name": "Fyn",
                "pk": "DK__fyn"
            },
            {
                "_id": "hovedstaden",
                "code": "hovedstaden",
                "name": "Hovedstaden",
                "pk": "DK__hovedstaden"
            },
            {
                "_id": "kobenhavn",
                "code": "kobenhavn",
                "name": "Kobenhavn",
                "pk": "DK__kobenhavn"
            },
            {
                "_id": "kobenhavns_amt",
                "code": "kobenhavns_amt",
                "name": "Kobenhavns Amt",
                "pk": "DK__kobenhavns_amt"
            },
            {
                "_id": "kobenhavns_kommune",
                "code": "kobenhavns_kommune",
                "name": "Kobenhavns Kommune",
                "pk": "DK__kobenhavns_kommune"
            },
            {
                "_id": "nordjylland",
                "code": "nordjylland",
                "name": "Nordjylland",
                "pk": "DK__nordjylland"
            },
            {
                "_id": "ribe",
                "code": "ribe",
                "name": "Ribe",
                "pk": "DK__ribe"
            },
            {
                "_id": "ringkobing",
                "code": "ringkobing",
                "name": "Ringkobing",
                "pk": "DK__ringkobing"
            },
            {
                "_id": "roervig",
                "code": "roervig",
                "name": "Roervig",
                "pk": "DK__roervig"
            },
            {
                "_id": "roskilde",
                "code": "roskilde",
                "name": "Roskilde",
                "pk": "DK__roskilde"
            },
            {
                "_id": "roslev",
                "code": "roslev",
                "name": "Roslev",
                "pk": "DK__roslev"
            },
            {
                "_id": "sjaelland",
                "code": "sjaelland",
                "name": "Sjaelland",
                "pk": "DK__sjaelland"
            },
            {
                "_id": "soeborg",
                "code": "soeborg",
                "name": "Soeborg",
                "pk": "DK__soeborg"
            },
            {
                "_id": "sonderjylland",
                "code": "sonderjylland",
                "name": "Sonderjylland",
                "pk": "DK__sonderjylland"
            },
            {
                "_id": "storstrom",
                "code": "storstrom",
                "name": "Storstrom",
                "pk": "DK__storstrom"
            },
            {
                "_id": "syddanmark",
                "code": "syddanmark",
                "name": "Syddanmark",
                "pk": "DK__syddanmark"
            },
            {
                "_id": "toelloese",
                "code": "toelloese",
                "name": "Toelloese",
                "pk": "DK__toelloese"
            },
            {
                "_id": "vejle",
                "code": "vejle",
                "name": "Vejle",
                "pk": "DK__vejle"
            },
            {
                "_id": "vestsjalland",
                "code": "vestsjalland",
                "name": "Vestsjalland",
                "pk": "DK__vestsjalland"
            },
            {
                "_id": "viborg",
                "code": "viborg",
                "name": "Viborg",
                "pk": "DK__viborg"
            }
        ],
        "only_shipping": true,
        "pk": "DK"
    },
    {
        "_id": "DJ",
        "code": "DJ",
        "name": "Djibouti",
        "states": [
            {
                "_id": "ali_sabih",
                "code": "ali_sabih",
                "name": "Ali Sabih",
                "pk": "DJ__ali_sabih"
            },
            {
                "_id": "dikhil",
                "code": "dikhil",
                "name": "Dikhil",
                "pk": "DJ__dikhil"
            },
            {
                "_id": "jibuti",
                "code": "jibuti",
                "name": "Jibuti",
                "pk": "DJ__jibuti"
            },
            {
                "_id": "tajurah",
                "code": "tajurah",
                "name": "Tajurah",
                "pk": "DJ__tajurah"
            },
            {
                "_id": "ubuk",
                "code": "ubuk",
                "name": "Ubuk",
                "pk": "DJ__ubuk"
            }
        ],
        "pk": "DJ"
    },
    {
        "_id": "DM",
        "code": "DM",
        "name": "Dominica",
        "states": [
            {
                "_id": "saint_andrew",
                "code": "saint_andrew",
                "name": "Saint Andrew",
                "pk": "DM__saint_andrew"
            },
            {
                "_id": "saint_david",
                "code": "saint_david",
                "name": "Saint David",
                "pk": "DM__saint_david"
            },
            {
                "_id": "saint_george",
                "code": "saint_george",
                "name": "Saint George",
                "pk": "DM__saint_george"
            },
            {
                "_id": "saint_john",
                "code": "saint_john",
                "name": "Saint John",
                "pk": "DM__saint_john"
            },
            {
                "_id": "saint_joseph",
                "code": "saint_joseph",
                "name": "Saint Joseph",
                "pk": "DM__saint_joseph"
            },
            {
                "_id": "saint_luke",
                "code": "saint_luke",
                "name": "Saint Luke",
                "pk": "DM__saint_luke"
            },
            {
                "_id": "saint_mark",
                "code": "saint_mark",
                "name": "Saint Mark",
                "pk": "DM__saint_mark"
            },
            {
                "_id": "saint_patrick",
                "code": "saint_patrick",
                "name": "Saint Patrick",
                "pk": "DM__saint_patrick"
            },
            {
                "_id": "saint_paul",
                "code": "saint_paul",
                "name": "Saint Paul",
                "pk": "DM__saint_paul"
            },
            {
                "_id": "saint_peter",
                "code": "saint_peter",
                "name": "Saint Peter",
                "pk": "DM__saint_peter"
            }
        ],
        "pk": "DM"
    },
    {
        "_id": "DO",
        "code": "DO",
        "name": "Dominican Republic",
        "states": [
            {
                "_id": "azua",
                "code": "azua",
                "name": "Azua",
                "pk": "DO__azua"
            },
            {
                "_id": "bahoruco",
                "code": "bahoruco",
                "name": "Bahoruco",
                "pk": "DO__bahoruco"
            },
            {
                "_id": "barahona",
                "code": "barahona",
                "name": "Barahona",
                "pk": "DO__barahona"
            },
            {
                "_id": "dajabon",
                "code": "dajabon",
                "name": "Dajabon",
                "pk": "DO__dajabon"
            },
            {
                "_id": "distrito_nacional",
                "code": "distrito_nacional",
                "name": "Distrito Nacional",
                "pk": "DO__distrito_nacional"
            },
            {
                "_id": "duarte",
                "code": "duarte",
                "name": "Duarte",
                "pk": "DO__duarte"
            },
            {
                "_id": "el_seybo",
                "code": "el_seybo",
                "name": "El Seybo",
                "pk": "DO__el_seybo"
            },
            {
                "_id": "elias_pina",
                "code": "elias_pina",
                "name": "Elias Pina",
                "pk": "DO__elias_pina"
            },
            {
                "_id": "espaillat",
                "code": "espaillat",
                "name": "Espaillat",
                "pk": "DO__espaillat"
            },
            {
                "_id": "hato_mayor",
                "code": "hato_mayor",
                "name": "Hato Mayor",
                "pk": "DO__hato_mayor"
            },
            {
                "_id": "independencia",
                "code": "independencia",
                "name": "Independencia",
                "pk": "DO__independencia"
            },
            {
                "_id": "la_altagracia",
                "code": "la_altagracia",
                "name": "La Altagracia",
                "pk": "DO__la_altagracia"
            },
            {
                "_id": "la_romana",
                "code": "la_romana",
                "name": "La Romana",
                "pk": "DO__la_romana"
            },
            {
                "_id": "la_vega",
                "code": "la_vega",
                "name": "La Vega",
                "pk": "DO__la_vega"
            },
            {
                "_id": "maria_trinidad_sanchez",
                "code": "maria_trinidad_sanchez",
                "name": "Maria Trinidad Sanchez",
                "pk": "DO__maria_trinidad_sanchez"
            },
            {
                "_id": "monsenor_nouel",
                "code": "monsenor_nouel",
                "name": "Monsenor Nouel",
                "pk": "DO__monsenor_nouel"
            },
            {
                "_id": "monte_cristi",
                "code": "monte_cristi",
                "name": "Monte Cristi",
                "pk": "DO__monte_cristi"
            },
            {
                "_id": "monte_plata",
                "code": "monte_plata",
                "name": "Monte Plata",
                "pk": "DO__monte_plata"
            },
            {
                "_id": "pedernales",
                "code": "pedernales",
                "name": "Pedernales",
                "pk": "DO__pedernales"
            },
            {
                "_id": "peravia",
                "code": "peravia",
                "name": "Peravia",
                "pk": "DO__peravia"
            },
            {
                "_id": "puerto_plata",
                "code": "puerto_plata",
                "name": "Puerto Plata",
                "pk": "DO__puerto_plata"
            },
            {
                "_id": "salcedo",
                "code": "salcedo",
                "name": "Salcedo",
                "pk": "DO__salcedo"
            },
            {
                "_id": "samana",
                "code": "samana",
                "name": "Samana",
                "pk": "DO__samana"
            },
            {
                "_id": "san_cristobal",
                "code": "san_cristobal",
                "name": "San Cristobal",
                "pk": "DO__san_cristobal"
            },
            {
                "_id": "san_juan",
                "code": "san_juan",
                "name": "San Juan",
                "pk": "DO__san_juan"
            },
            {
                "_id": "san_pedro_de_macoris",
                "code": "san_pedro_de_macoris",
                "name": "San Pedro de Macoris",
                "pk": "DO__san_pedro_de_macoris"
            },
            {
                "_id": "sanchez_ramirez",
                "code": "sanchez_ramirez",
                "name": "Sanchez Ramirez",
                "pk": "DO__sanchez_ramirez"
            },
            {
                "_id": "santiago",
                "code": "santiago",
                "name": "Santiago",
                "pk": "DO__santiago"
            },
            {
                "_id": "santiago_rodriguez",
                "code": "santiago_rodriguez",
                "name": "Santiago Rodriguez",
                "pk": "DO__santiago_rodriguez"
            },
            {
                "_id": "valverde",
                "code": "valverde",
                "name": "Valverde",
                "pk": "DO__valverde"
            }
        ],
        "pk": "DO"
    },
    {
        "_id": "TP",
        "code": "TP",
        "name": "East Timor",
        "states": [
            {
                "_id": "aileu",
                "code": "aileu",
                "name": "Aileu",
                "pk": "TP__aileu"
            },
            {
                "_id": "ainaro",
                "code": "ainaro",
                "name": "Ainaro",
                "pk": "TP__ainaro"
            },
            {
                "_id": "ambeno",
                "code": "ambeno",
                "name": "Ambeno",
                "pk": "TP__ambeno"
            },
            {
                "_id": "baucau",
                "code": "baucau",
                "name": "Baucau",
                "pk": "TP__baucau"
            },
            {
                "_id": "bobonaro",
                "code": "bobonaro",
                "name": "Bobonaro",
                "pk": "TP__bobonaro"
            },
            {
                "_id": "cova_lima",
                "code": "cova_lima",
                "name": "Cova Lima",
                "pk": "TP__cova_lima"
            },
            {
                "_id": "dili",
                "code": "dili",
                "name": "Dili",
                "pk": "TP__dili"
            },
            {
                "_id": "ermera",
                "code": "ermera",
                "name": "Ermera",
                "pk": "TP__ermera"
            },
            {
                "_id": "lautem",
                "code": "lautem",
                "name": "Lautem",
                "pk": "TP__lautem"
            },
            {
                "_id": "liquica",
                "code": "liquica",
                "name": "Liquica",
                "pk": "TP__liquica"
            },
            {
                "_id": "manatuto",
                "code": "manatuto",
                "name": "Manatuto",
                "pk": "TP__manatuto"
            },
            {
                "_id": "manufahi",
                "code": "manufahi",
                "name": "Manufahi",
                "pk": "TP__manufahi"
            },
            {
                "_id": "viqueque",
                "code": "viqueque",
                "name": "Viqueque",
                "pk": "TP__viqueque"
            }
        ],
        "pk": "TP"
    },
    {
        "_id": "EC",
        "code": "EC",
        "name": "Ecuador",
        "states": [
            {
                "_id": "azuay",
                "code": "azuay",
                "name": "Azuay",
                "pk": "EC__azuay"
            },
            {
                "_id": "bolivar",
                "code": "bolivar",
                "name": "Bolivar",
                "pk": "EC__bolivar"
            },
            {
                "_id": "canar",
                "code": "canar",
                "name": "Canar",
                "pk": "EC__canar"
            },
            {
                "_id": "carchi",
                "code": "carchi",
                "name": "Carchi",
                "pk": "EC__carchi"
            },
            {
                "_id": "chimborazo",
                "code": "chimborazo",
                "name": "Chimborazo",
                "pk": "EC__chimborazo"
            },
            {
                "_id": "cotopaxi",
                "code": "cotopaxi",
                "name": "Cotopaxi",
                "pk": "EC__cotopaxi"
            },
            {
                "_id": "el_oro",
                "code": "el_oro",
                "name": "El Oro",
                "pk": "EC__el_oro"
            },
            {
                "_id": "esmeraldas",
                "code": "esmeraldas",
                "name": "Esmeraldas",
                "pk": "EC__esmeraldas"
            },
            {
                "_id": "galapagos",
                "code": "galapagos",
                "name": "Galapagos",
                "pk": "EC__galapagos"
            },
            {
                "_id": "guayas",
                "code": "guayas",
                "name": "Guayas",
                "pk": "EC__guayas"
            },
            {
                "_id": "imbabura",
                "code": "imbabura",
                "name": "Imbabura",
                "pk": "EC__imbabura"
            },
            {
                "_id": "loja",
                "code": "loja",
                "name": "Loja",
                "pk": "EC__loja"
            },
            {
                "_id": "los_rios",
                "code": "los_rios",
                "name": "Los Rios",
                "pk": "EC__los_rios"
            },
            {
                "_id": "manabi",
                "code": "manabi",
                "name": "Manabi",
                "pk": "EC__manabi"
            },
            {
                "_id": "morona_santiago",
                "code": "morona_santiago",
                "name": "Morona Santiago",
                "pk": "EC__morona_santiago"
            },
            {
                "_id": "napo",
                "code": "napo",
                "name": "Napo",
                "pk": "EC__napo"
            },
            {
                "_id": "orellana",
                "code": "orellana",
                "name": "Orellana",
                "pk": "EC__orellana"
            },
            {
                "_id": "pastaza",
                "code": "pastaza",
                "name": "Pastaza",
                "pk": "EC__pastaza"
            },
            {
                "_id": "pichincha",
                "code": "pichincha",
                "name": "Pichincha",
                "pk": "EC__pichincha"
            },
            {
                "_id": "sucumbios",
                "code": "sucumbios",
                "name": "Sucumbios",
                "pk": "EC__sucumbios"
            },
            {
                "_id": "tungurahua",
                "code": "tungurahua",
                "name": "Tungurahua",
                "pk": "EC__tungurahua"
            },
            {
                "_id": "zamora_chinchipe",
                "code": "zamora_chinchipe",
                "name": "Zamora Chinchipe",
                "pk": "EC__zamora_chinchipe"
            }
        ],
        "pk": "EC"
    },
    {
        "_id": "EG",
        "code": "EG",
        "name": "Egypt",
        "states": [
            {
                "_id": "aswan",
                "code": "aswan",
                "name": "Aswan",
                "pk": "EG__aswan"
            },
            {
                "_id": "asyut",
                "code": "asyut",
                "name": "Asyut",
                "pk": "EG__asyut"
            },
            {
                "_id": "bani_suwayf",
                "code": "bani_suwayf",
                "name": "Bani Suwayf",
                "pk": "EG__bani_suwayf"
            },
            {
                "_id": "bur_said",
                "code": "bur_said",
                "name": "Bur Said",
                "pk": "EG__bur_said"
            },
            {
                "_id": "cairo",
                "code": "cairo",
                "name": "Cairo",
                "pk": "EG__cairo"
            },
            {
                "_id": "dumyat",
                "code": "dumyat",
                "name": "Dumyat",
                "pk": "EG__dumyat"
            },
            {
                "_id": "kafr-ash-shaykh",
                "code": "kafr-ash-shaykh",
                "name": "Kafr-ash-Shaykh",
                "pk": "EG__kafr-ash-shaykh"
            },
            {
                "_id": "matruh",
                "code": "matruh",
                "name": "Matruh",
                "pk": "EG__matruh"
            },
            {
                "_id": "muhafazat_ad_daqahliyah",
                "code": "muhafazat_ad_daqahliyah",
                "name": "Muhafazat ad Daqahliyah",
                "pk": "EG__muhafazat_ad_daqahliyah"
            },
            {
                "_id": "muhafazat_al_fayyum",
                "code": "muhafazat_al_fayyum",
                "name": "Muhafazat al Fayyum",
                "pk": "EG__muhafazat_al_fayyum"
            },
            {
                "_id": "muhafazat_al_gharbiyah",
                "code": "muhafazat_al_gharbiyah",
                "name": "Muhafazat al Gharbiyah",
                "pk": "EG__muhafazat_al_gharbiyah"
            },
            {
                "_id": "muhafazat_al_iskandariyah",
                "code": "muhafazat_al_iskandariyah",
                "name": "Muhafazat al Iskandariyah",
                "pk": "EG__muhafazat_al_iskandariyah"
            },
            {
                "_id": "muhafazat_al_qahirah",
                "code": "muhafazat_al_qahirah",
                "name": "Muhafazat al Qahirah",
                "pk": "EG__muhafazat_al_qahirah"
            },
            {
                "_id": "qina",
                "code": "qina",
                "name": "Qina",
                "pk": "EG__qina"
            },
            {
                "_id": "sawhaj",
                "code": "sawhaj",
                "name": "Sawhaj",
                "pk": "EG__sawhaj"
            },
            {
                "_id": "sina_al-janubiyah",
                "code": "sina_al-janubiyah",
                "name": "Sina al-Janubiyah",
                "pk": "EG__sina_al-janubiyah"
            },
            {
                "_id": "sina_ash-shamaliyah",
                "code": "sina_ash-shamaliyah",
                "name": "Sina ash-Shamaliyah",
                "pk": "EG__sina_ash-shamaliyah"
            },
            {
                "_id": "ad-daqahliyah",
                "code": "ad-daqahliyah",
                "name": "ad-Daqahliyah",
                "pk": "EG__ad-daqahliyah"
            },
            {
                "_id": "al-bahr-al-ahmar",
                "code": "al-bahr-al-ahmar",
                "name": "al-Bahr-al-Ahmar",
                "pk": "EG__al-bahr-al-ahmar"
            },
            {
                "_id": "al-buhayrah",
                "code": "al-buhayrah",
                "name": "al-Buhayrah",
                "pk": "EG__al-buhayrah"
            },
            {
                "_id": "al-fayyum",
                "code": "al-fayyum",
                "name": "al-Fayyum",
                "pk": "EG__al-fayyum"
            },
            {
                "_id": "al-gharbiyah",
                "code": "al-gharbiyah",
                "name": "al-Gharbiyah",
                "pk": "EG__al-gharbiyah"
            },
            {
                "_id": "al-iskandariyah",
                "code": "al-iskandariyah",
                "name": "al-Iskandariyah",
                "pk": "EG__al-iskandariyah"
            },
            {
                "_id": "al-ismailiyah",
                "code": "al-ismailiyah",
                "name": "al-Ismailiyah",
                "pk": "EG__al-ismailiyah"
            },
            {
                "_id": "al-jizah",
                "code": "al-jizah",
                "name": "al-Jizah",
                "pk": "EG__al-jizah"
            },
            {
                "_id": "al-minufiyah",
                "code": "al-minufiyah",
                "name": "al-Minufiyah",
                "pk": "EG__al-minufiyah"
            },
            {
                "_id": "al-minya",
                "code": "al-minya",
                "name": "al-Minya",
                "pk": "EG__al-minya"
            },
            {
                "_id": "al-qahira",
                "code": "al-qahira",
                "name": "al-Qahira",
                "pk": "EG__al-qahira"
            },
            {
                "_id": "al-qalyubiyah",
                "code": "al-qalyubiyah",
                "name": "al-Qalyubiyah",
                "pk": "EG__al-qalyubiyah"
            },
            {
                "_id": "al-uqsur",
                "code": "al-uqsur",
                "name": "al-Uqsur",
                "pk": "EG__al-uqsur"
            },
            {
                "_id": "al-wadi_al-jadid",
                "code": "al-wadi_al-jadid",
                "name": "al-Wadi al-Jadid",
                "pk": "EG__al-wadi_al-jadid"
            },
            {
                "_id": "as-suways",
                "code": "as-suways",
                "name": "as-Suways",
                "pk": "EG__as-suways"
            },
            {
                "_id": "ash-sharqiyah",
                "code": "ash-sharqiyah",
                "name": "ash-Sharqiyah",
                "pk": "EG__ash-sharqiyah"
            }
        ],
        "pk": "EG"
    },
    {
        "_id": "SV",
        "code": "SV",
        "name": "El Salvador",
        "states": [
            {
                "_id": "ahuachapan",
                "code": "ahuachapan",
                "name": "Ahuachapan",
                "pk": "SV__ahuachapan"
            },
            {
                "_id": "cabanas",
                "code": "cabanas",
                "name": "Cabanas",
                "pk": "SV__cabanas"
            },
            {
                "_id": "chalatenango",
                "code": "chalatenango",
                "name": "Chalatenango",
                "pk": "SV__chalatenango"
            },
            {
                "_id": "cuscatlan",
                "code": "cuscatlan",
                "name": "Cuscatlan",
                "pk": "SV__cuscatlan"
            },
            {
                "_id": "la_libertad",
                "code": "la_libertad",
                "name": "La Libertad",
                "pk": "SV__la_libertad"
            },
            {
                "_id": "la_paz",
                "code": "la_paz",
                "name": "La Paz",
                "pk": "SV__la_paz"
            },
            {
                "_id": "la_union",
                "code": "la_union",
                "name": "La Union",
                "pk": "SV__la_union"
            },
            {
                "_id": "morazan",
                "code": "morazan",
                "name": "Morazan",
                "pk": "SV__morazan"
            },
            {
                "_id": "san_miguel",
                "code": "san_miguel",
                "name": "San Miguel",
                "pk": "SV__san_miguel"
            },
            {
                "_id": "san_salvador",
                "code": "san_salvador",
                "name": "San Salvador",
                "pk": "SV__san_salvador"
            },
            {
                "_id": "san_vicente",
                "code": "san_vicente",
                "name": "San Vicente",
                "pk": "SV__san_vicente"
            },
            {
                "_id": "santa_ana",
                "code": "santa_ana",
                "name": "Santa Ana",
                "pk": "SV__santa_ana"
            },
            {
                "_id": "sonsonate",
                "code": "sonsonate",
                "name": "Sonsonate",
                "pk": "SV__sonsonate"
            },
            {
                "_id": "usulutan",
                "code": "usulutan",
                "name": "Usulutan",
                "pk": "SV__usulutan"
            }
        ],
        "pk": "SV"
    },
    {
        "_id": "GQ",
        "code": "GQ",
        "name": "Equatorial Guinea",
        "states": [
            {
                "_id": "annobon",
                "code": "annobon",
                "name": "Annobon",
                "pk": "GQ__annobon"
            },
            {
                "_id": "bioko_norte",
                "code": "bioko_norte",
                "name": "Bioko Norte",
                "pk": "GQ__bioko_norte"
            },
            {
                "_id": "bioko_sur",
                "code": "bioko_sur",
                "name": "Bioko Sur",
                "pk": "GQ__bioko_sur"
            },
            {
                "_id": "centro_sur",
                "code": "centro_sur",
                "name": "Centro Sur",
                "pk": "GQ__centro_sur"
            },
            {
                "_id": "kie-ntem",
                "code": "kie-ntem",
                "name": "Kie-Ntem",
                "pk": "GQ__kie-ntem"
            },
            {
                "_id": "litoral",
                "code": "litoral",
                "name": "Litoral",
                "pk": "GQ__litoral"
            },
            {
                "_id": "wele-nzas",
                "code": "wele-nzas",
                "name": "Wele-Nzas",
                "pk": "GQ__wele-nzas"
            }
        ],
        "pk": "GQ"
    },
    {
        "_id": "ER",
        "code": "ER",
        "name": "Eritrea",
        "states": [
            {
                "_id": "anseba",
                "code": "anseba",
                "name": "Anseba",
                "pk": "ER__anseba"
            },
            {
                "_id": "debub",
                "code": "debub",
                "name": "Debub",
                "pk": "ER__debub"
            },
            {
                "_id": "debub-keih-bahri",
                "code": "debub-keih-bahri",
                "name": "Debub-Keih-Bahri",
                "pk": "ER__debub-keih-bahri"
            },
            {
                "_id": "gash-barka",
                "code": "gash-barka",
                "name": "Gash-Barka",
                "pk": "ER__gash-barka"
            },
            {
                "_id": "maekel",
                "code": "maekel",
                "name": "Maekel",
                "pk": "ER__maekel"
            },
            {
                "_id": "semien-keih-bahri",
                "code": "semien-keih-bahri",
                "name": "Semien-Keih-Bahri",
                "pk": "ER__semien-keih-bahri"
            }
        ],
        "pk": "ER"
    },
    {
        "_id": "EE",
        "code": "EE",
        "name": "Estonia",
        "states": [
            {
                "_id": "harju",
                "code": "harju",
                "name": "Harju",
                "pk": "EE__harju"
            },
            {
                "_id": "hiiu",
                "code": "hiiu",
                "name": "Hiiu",
                "pk": "EE__hiiu"
            },
            {
                "_id": "ida-viru",
                "code": "ida-viru",
                "name": "Ida-Viru",
                "pk": "EE__ida-viru"
            },
            {
                "_id": "jarva",
                "code": "jarva",
                "name": "Jarva",
                "pk": "EE__jarva"
            },
            {
                "_id": "jogeva",
                "code": "jogeva",
                "name": "Jogeva",
                "pk": "EE__jogeva"
            },
            {
                "_id": "laane",
                "code": "laane",
                "name": "Laane",
                "pk": "EE__laane"
            },
            {
                "_id": "laane-viru",
                "code": "laane-viru",
                "name": "Laane-Viru",
                "pk": "EE__laane-viru"
            },
            {
                "_id": "parnu",
                "code": "parnu",
                "name": "Parnu",
                "pk": "EE__parnu"
            },
            {
                "_id": "polva",
                "code": "polva",
                "name": "Polva",
                "pk": "EE__polva"
            },
            {
                "_id": "rapla",
                "code": "rapla",
                "name": "Rapla",
                "pk": "EE__rapla"
            },
            {
                "_id": "saare",
                "code": "saare",
                "name": "Saare",
                "pk": "EE__saare"
            },
            {
                "_id": "tartu",
                "code": "tartu",
                "name": "Tartu",
                "pk": "EE__tartu"
            },
            {
                "_id": "valga",
                "code": "valga",
                "name": "Valga",
                "pk": "EE__valga"
            },
            {
                "_id": "viljandi",
                "code": "viljandi",
                "name": "Viljandi",
                "pk": "EE__viljandi"
            },
            {
                "_id": "voru",
                "code": "voru",
                "name": "Voru",
                "pk": "EE__voru"
            }
        ],
        "pk": "EE"
    },
    {
        "_id": "ET",
        "code": "ET",
        "name": "Ethiopia",
        "states": [
            {
                "_id": "addis_abeba",
                "code": "addis_abeba",
                "name": "Addis Abeba",
                "pk": "ET__addis_abeba"
            },
            {
                "_id": "afar",
                "code": "afar",
                "name": "Afar",
                "pk": "ET__afar"
            },
            {
                "_id": "amhara",
                "code": "amhara",
                "name": "Amhara",
                "pk": "ET__amhara"
            },
            {
                "_id": "benishangul",
                "code": "benishangul",
                "name": "Benishangul",
                "pk": "ET__benishangul"
            },
            {
                "_id": "diredawa",
                "code": "diredawa",
                "name": "Diredawa",
                "pk": "ET__diredawa"
            },
            {
                "_id": "gambella",
                "code": "gambella",
                "name": "Gambella",
                "pk": "ET__gambella"
            },
            {
                "_id": "harar",
                "code": "harar",
                "name": "Harar",
                "pk": "ET__harar"
            },
            {
                "_id": "jigjiga",
                "code": "jigjiga",
                "name": "Jigjiga",
                "pk": "ET__jigjiga"
            },
            {
                "_id": "mekele",
                "code": "mekele",
                "name": "Mekele",
                "pk": "ET__mekele"
            },
            {
                "_id": "oromia",
                "code": "oromia",
                "name": "Oromia",
                "pk": "ET__oromia"
            },
            {
                "_id": "somali",
                "code": "somali",
                "name": "Somali",
                "pk": "ET__somali"
            },
            {
                "_id": "southern",
                "code": "southern",
                "name": "Southern",
                "pk": "ET__southern"
            },
            {
                "_id": "tigray",
                "code": "tigray",
                "name": "Tigray",
                "pk": "ET__tigray"
            }
        ],
        "pk": "ET"
    },
    {
        "_id": "XA",
        "code": "XA",
        "name": "External Territories of Australia",
        "states": [
            {
                "_id": "christmas_island",
                "code": "christmas_island",
                "name": "Christmas Island",
                "pk": "XA__christmas_island"
            },
            {
                "_id": "cocos_islands",
                "code": "cocos_islands",
                "name": "Cocos Islands",
                "pk": "XA__cocos_islands"
            },
            {
                "_id": "coral_sea_islands",
                "code": "coral_sea_islands",
                "name": "Coral Sea Islands",
                "pk": "XA__coral_sea_islands"
            }
        ],
        "pk": "XA"
    },
    {
        "_id": "FK",
        "code": "FK",
        "name": "Falkland Islands",
        "states": [
            {
                "_id": "falkland_islands",
                "code": "falkland_islands",
                "name": "Falkland Islands",
                "pk": "FK__falkland_islands"
            },
            {
                "_id": "south_georgia",
                "code": "south_georgia",
                "name": "South Georgia",
                "pk": "FK__south_georgia"
            }
        ],
        "pk": "FK"
    },
    {
        "_id": "FO",
        "code": "FO",
        "name": "Faroe Islands",
        "states": [
            {
                "_id": "klaksvik",
                "code": "klaksvik",
                "name": "Klaksvik",
                "pk": "FO__klaksvik"
            },
            {
                "_id": "nor_ara_eysturoy",
                "code": "nor_ara_eysturoy",
                "name": "Nor ara Eysturoy",
                "pk": "FO__nor_ara_eysturoy"
            },
            {
                "_id": "nor_oy",
                "code": "nor_oy",
                "name": "Nor oy",
                "pk": "FO__nor_oy"
            },
            {
                "_id": "sandoy",
                "code": "sandoy",
                "name": "Sandoy",
                "pk": "FO__sandoy"
            },
            {
                "_id": "streymoy",
                "code": "streymoy",
                "name": "Streymoy",
                "pk": "FO__streymoy"
            },
            {
                "_id": "su_uroy",
                "code": "su_uroy",
                "name": "Su uroy",
                "pk": "FO__su_uroy"
            },
            {
                "_id": "sy_ra_eysturoy",
                "code": "sy_ra_eysturoy",
                "name": "Sy ra Eysturoy",
                "pk": "FO__sy_ra_eysturoy"
            },
            {
                "_id": "torshavn",
                "code": "torshavn",
                "name": "Torshavn",
                "pk": "FO__torshavn"
            },
            {
                "_id": "vaga",
                "code": "vaga",
                "name": "Vaga",
                "pk": "FO__vaga"
            }
        ],
        "pk": "FO"
    },
    {
        "_id": "FJ",
        "code": "FJ",
        "name": "Fiji Islands",
        "states": [
            {
                "_id": "central",
                "code": "central",
                "name": "Central",
                "pk": "FJ__central"
            },
            {
                "_id": "eastern",
                "code": "eastern",
                "name": "Eastern",
                "pk": "FJ__eastern"
            },
            {
                "_id": "northern",
                "code": "northern",
                "name": "Northern",
                "pk": "FJ__northern"
            },
            {
                "_id": "south_pacific",
                "code": "south_pacific",
                "name": "South Pacific",
                "pk": "FJ__south_pacific"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "FJ__western"
            }
        ],
        "pk": "FJ"
    },
    {
        "_id": "FI",
        "code": "FI",
        "name": "Finland",
        "states": [
            {
                "_id": "ahvenanmaa",
                "code": "ahvenanmaa",
                "name": "Ahvenanmaa",
                "pk": "FI__ahvenanmaa"
            },
            {
                "_id": "etela-karjala",
                "code": "etela-karjala",
                "name": "Etela-Karjala",
                "pk": "FI__etela-karjala"
            },
            {
                "_id": "etela-pohjanmaa",
                "code": "etela-pohjanmaa",
                "name": "Etela-Pohjanmaa",
                "pk": "FI__etela-pohjanmaa"
            },
            {
                "_id": "etela-savo",
                "code": "etela-savo",
                "name": "Etela-Savo",
                "pk": "FI__etela-savo"
            },
            {
                "_id": "etela-suomen_laani",
                "code": "etela-suomen_laani",
                "name": "Etela-Suomen Laani",
                "pk": "FI__etela-suomen_laani"
            },
            {
                "_id": "ita-suomen_laani",
                "code": "ita-suomen_laani",
                "name": "Ita-Suomen Laani",
                "pk": "FI__ita-suomen_laani"
            },
            {
                "_id": "ita-uusimaa",
                "code": "ita-uusimaa",
                "name": "Ita-Uusimaa",
                "pk": "FI__ita-uusimaa"
            },
            {
                "_id": "kainuu",
                "code": "kainuu",
                "name": "Kainuu",
                "pk": "FI__kainuu"
            },
            {
                "_id": "kanta-hame",
                "code": "kanta-hame",
                "name": "Kanta-Hame",
                "pk": "FI__kanta-hame"
            },
            {
                "_id": "keski-pohjanmaa",
                "code": "keski-pohjanmaa",
                "name": "Keski-Pohjanmaa",
                "pk": "FI__keski-pohjanmaa"
            },
            {
                "_id": "keski-suomi",
                "code": "keski-suomi",
                "name": "Keski-Suomi",
                "pk": "FI__keski-suomi"
            },
            {
                "_id": "kymenlaakso",
                "code": "kymenlaakso",
                "name": "Kymenlaakso",
                "pk": "FI__kymenlaakso"
            },
            {
                "_id": "lansi-suomen_laani",
                "code": "lansi-suomen_laani",
                "name": "Lansi-Suomen Laani",
                "pk": "FI__lansi-suomen_laani"
            },
            {
                "_id": "lappi",
                "code": "lappi",
                "name": "Lappi",
                "pk": "FI__lappi"
            },
            {
                "_id": "northern_savonia",
                "code": "northern_savonia",
                "name": "Northern Savonia",
                "pk": "FI__northern_savonia"
            },
            {
                "_id": "ostrobothnia",
                "code": "ostrobothnia",
                "name": "Ostrobothnia",
                "pk": "FI__ostrobothnia"
            },
            {
                "_id": "oulun_laani",
                "code": "oulun_laani",
                "name": "Oulun Laani",
                "pk": "FI__oulun_laani"
            },
            {
                "_id": "paijat-hame",
                "code": "paijat-hame",
                "name": "Paijat-Hame",
                "pk": "FI__paijat-hame"
            },
            {
                "_id": "pirkanmaa",
                "code": "pirkanmaa",
                "name": "Pirkanmaa",
                "pk": "FI__pirkanmaa"
            },
            {
                "_id": "pohjanmaa",
                "code": "pohjanmaa",
                "name": "Pohjanmaa",
                "pk": "FI__pohjanmaa"
            },
            {
                "_id": "pohjois-karjala",
                "code": "pohjois-karjala",
                "name": "Pohjois-Karjala",
                "pk": "FI__pohjois-karjala"
            },
            {
                "_id": "pohjois-pohjanmaa",
                "code": "pohjois-pohjanmaa",
                "name": "Pohjois-Pohjanmaa",
                "pk": "FI__pohjois-pohjanmaa"
            },
            {
                "_id": "pohjois-savo",
                "code": "pohjois-savo",
                "name": "Pohjois-Savo",
                "pk": "FI__pohjois-savo"
            },
            {
                "_id": "saarijarvi",
                "code": "saarijarvi",
                "name": "Saarijarvi",
                "pk": "FI__saarijarvi"
            },
            {
                "_id": "satakunta",
                "code": "satakunta",
                "name": "Satakunta",
                "pk": "FI__satakunta"
            },
            {
                "_id": "southern_savonia",
                "code": "southern_savonia",
                "name": "Southern Savonia",
                "pk": "FI__southern_savonia"
            },
            {
                "_id": "tavastia_proper",
                "code": "tavastia_proper",
                "name": "Tavastia Proper",
                "pk": "FI__tavastia_proper"
            },
            {
                "_id": "uleaborgs_lan",
                "code": "uleaborgs_lan",
                "name": "Uleaborgs Lan",
                "pk": "FI__uleaborgs_lan"
            },
            {
                "_id": "uusimaa",
                "code": "uusimaa",
                "name": "Uusimaa",
                "pk": "FI__uusimaa"
            },
            {
                "_id": "varsinais-suomi",
                "code": "varsinais-suomi",
                "name": "Varsinais-Suomi",
                "pk": "FI__varsinais-suomi"
            }
        ],
        "pk": "FI"
    },
    {
        "_id": "FR",
        "code": "FR",
        "name": "France",
        "states": [
            {
                "_id": "ain",
                "code": "ain",
                "name": "Ain",
                "pk": "FR__ain"
            },
            {
                "_id": "aisne",
                "code": "aisne",
                "name": "Aisne",
                "pk": "FR__aisne"
            },
            {
                "_id": "albi_le_sequestre",
                "code": "albi_le_sequestre",
                "name": "Albi Le Sequestre",
                "pk": "FR__albi_le_sequestre"
            },
            {
                "_id": "allier",
                "code": "allier",
                "name": "Allier",
                "pk": "FR__allier"
            },
            {
                "_id": "alpes-cote_dazur",
                "code": "alpes-cote_dazur",
                "name": "Alpes-Cote dAzur",
                "pk": "FR__alpes-cote_dazur"
            },
            {
                "_id": "alpes-maritimes",
                "code": "alpes-maritimes",
                "name": "Alpes-Maritimes",
                "pk": "FR__alpes-maritimes"
            },
            {
                "_id": "alpes-de-haute-provence",
                "code": "alpes-de-haute-provence",
                "name": "Alpes-de-Haute-Provence",
                "pk": "FR__alpes-de-haute-provence"
            },
            {
                "_id": "alsace",
                "code": "alsace",
                "name": "Alsace",
                "pk": "FR__alsace"
            },
            {
                "_id": "aquitaine",
                "code": "aquitaine",
                "name": "Aquitaine",
                "pk": "FR__aquitaine"
            },
            {
                "_id": "ardeche",
                "code": "ardeche",
                "name": "Ardeche",
                "pk": "FR__ardeche"
            },
            {
                "_id": "ardennes",
                "code": "ardennes",
                "name": "Ardennes",
                "pk": "FR__ardennes"
            },
            {
                "_id": "ariege",
                "code": "ariege",
                "name": "Ariege",
                "pk": "FR__ariege"
            },
            {
                "_id": "aube",
                "code": "aube",
                "name": "Aube",
                "pk": "FR__aube"
            },
            {
                "_id": "aude",
                "code": "aude",
                "name": "Aude",
                "pk": "FR__aude"
            },
            {
                "_id": "auvergne",
                "code": "auvergne",
                "name": "Auvergne",
                "pk": "FR__auvergne"
            },
            {
                "_id": "aveyron",
                "code": "aveyron",
                "name": "Aveyron",
                "pk": "FR__aveyron"
            },
            {
                "_id": "bas-rhin",
                "code": "bas-rhin",
                "name": "Bas-Rhin",
                "pk": "FR__bas-rhin"
            },
            {
                "_id": "basse-normandie",
                "code": "basse-normandie",
                "name": "Basse-Normandie",
                "pk": "FR__basse-normandie"
            },
            {
                "_id": "bouches-du-rhone",
                "code": "bouches-du-rhone",
                "name": "Bouches-du-Rhone",
                "pk": "FR__bouches-du-rhone"
            },
            {
                "_id": "bourgogne",
                "code": "bourgogne",
                "name": "Bourgogne",
                "pk": "FR__bourgogne"
            },
            {
                "_id": "bretagne",
                "code": "bretagne",
                "name": "Bretagne",
                "pk": "FR__bretagne"
            },
            {
                "_id": "brittany",
                "code": "brittany",
                "name": "Brittany",
                "pk": "FR__brittany"
            },
            {
                "_id": "burgundy",
                "code": "burgundy",
                "name": "Burgundy",
                "pk": "FR__burgundy"
            },
            {
                "_id": "calvados",
                "code": "calvados",
                "name": "Calvados",
                "pk": "FR__calvados"
            },
            {
                "_id": "cantal",
                "code": "cantal",
                "name": "Cantal",
                "pk": "FR__cantal"
            },
            {
                "_id": "cedex",
                "code": "cedex",
                "name": "Cedex",
                "pk": "FR__cedex"
            },
            {
                "_id": "centre",
                "code": "centre",
                "name": "Centre",
                "pk": "FR__centre"
            },
            {
                "_id": "charente",
                "code": "charente",
                "name": "Charente",
                "pk": "FR__charente"
            },
            {
                "_id": "charente-maritime",
                "code": "charente-maritime",
                "name": "Charente-Maritime",
                "pk": "FR__charente-maritime"
            },
            {
                "_id": "cher",
                "code": "cher",
                "name": "Cher",
                "pk": "FR__cher"
            },
            {
                "_id": "correze",
                "code": "correze",
                "name": "Correze",
                "pk": "FR__correze"
            },
            {
                "_id": "corse-du-sud",
                "code": "corse-du-sud",
                "name": "Corse-du-Sud",
                "pk": "FR__corse-du-sud"
            },
            {
                "_id": "cote-dor",
                "code": "cote-dor",
                "name": "Cote-dOr",
                "pk": "FR__cote-dor"
            },
            {
                "_id": "cotes-darmor",
                "code": "cotes-darmor",
                "name": "Cotes-dArmor",
                "pk": "FR__cotes-darmor"
            },
            {
                "_id": "creuse",
                "code": "creuse",
                "name": "Creuse",
                "pk": "FR__creuse"
            },
            {
                "_id": "crolles",
                "code": "crolles",
                "name": "Crolles",
                "pk": "FR__crolles"
            },
            {
                "_id": "deux-sevres",
                "code": "deux-sevres",
                "name": "Deux-Sevres",
                "pk": "FR__deux-sevres"
            },
            {
                "_id": "dordogne",
                "code": "dordogne",
                "name": "Dordogne",
                "pk": "FR__dordogne"
            },
            {
                "_id": "doubs",
                "code": "doubs",
                "name": "Doubs",
                "pk": "FR__doubs"
            },
            {
                "_id": "drome",
                "code": "drome",
                "name": "Drome",
                "pk": "FR__drome"
            },
            {
                "_id": "essonne",
                "code": "essonne",
                "name": "Essonne",
                "pk": "FR__essonne"
            },
            {
                "_id": "eure",
                "code": "eure",
                "name": "Eure",
                "pk": "FR__eure"
            },
            {
                "_id": "eure-et-loir",
                "code": "eure-et-loir",
                "name": "Eure-et-Loir",
                "pk": "FR__eure-et-loir"
            },
            {
                "_id": "feucherolles",
                "code": "feucherolles",
                "name": "Feucherolles",
                "pk": "FR__feucherolles"
            },
            {
                "_id": "finistere",
                "code": "finistere",
                "name": "Finistere",
                "pk": "FR__finistere"
            },
            {
                "_id": "franche-comte",
                "code": "franche-comte",
                "name": "Franche-Comte",
                "pk": "FR__franche-comte"
            },
            {
                "_id": "gard",
                "code": "gard",
                "name": "Gard",
                "pk": "FR__gard"
            },
            {
                "_id": "gers",
                "code": "gers",
                "name": "Gers",
                "pk": "FR__gers"
            },
            {
                "_id": "gironde",
                "code": "gironde",
                "name": "Gironde",
                "pk": "FR__gironde"
            },
            {
                "_id": "haut-rhin",
                "code": "haut-rhin",
                "name": "Haut-Rhin",
                "pk": "FR__haut-rhin"
            },
            {
                "_id": "haute-corse",
                "code": "haute-corse",
                "name": "Haute-Corse",
                "pk": "FR__haute-corse"
            },
            {
                "_id": "haute-garonne",
                "code": "haute-garonne",
                "name": "Haute-Garonne",
                "pk": "FR__haute-garonne"
            },
            {
                "_id": "haute-loire",
                "code": "haute-loire",
                "name": "Haute-Loire",
                "pk": "FR__haute-loire"
            },
            {
                "_id": "haute-marne",
                "code": "haute-marne",
                "name": "Haute-Marne",
                "pk": "FR__haute-marne"
            },
            {
                "_id": "haute-saone",
                "code": "haute-saone",
                "name": "Haute-Saone",
                "pk": "FR__haute-saone"
            },
            {
                "_id": "haute-savoie",
                "code": "haute-savoie",
                "name": "Haute-Savoie",
                "pk": "FR__haute-savoie"
            },
            {
                "_id": "haute-vienne",
                "code": "haute-vienne",
                "name": "Haute-Vienne",
                "pk": "FR__haute-vienne"
            },
            {
                "_id": "hautes-alpes",
                "code": "hautes-alpes",
                "name": "Hautes-Alpes",
                "pk": "FR__hautes-alpes"
            },
            {
                "_id": "hautes-pyrenees",
                "code": "hautes-pyrenees",
                "name": "Hautes-Pyrenees",
                "pk": "FR__hautes-pyrenees"
            },
            {
                "_id": "hauts-de-seine",
                "code": "hauts-de-seine",
                "name": "Hauts-de-Seine",
                "pk": "FR__hauts-de-seine"
            },
            {
                "_id": "herault",
                "code": "herault",
                "name": "Herault",
                "pk": "FR__herault"
            },
            {
                "_id": "ile-de-france",
                "code": "ile-de-france",
                "name": "Ile-de-France",
                "pk": "FR__ile-de-france"
            },
            {
                "_id": "ille-et-vilaine",
                "code": "ille-et-vilaine",
                "name": "Ille-et-Vilaine",
                "pk": "FR__ille-et-vilaine"
            },
            {
                "_id": "indre",
                "code": "indre",
                "name": "Indre",
                "pk": "FR__indre"
            },
            {
                "_id": "indre-et-loire",
                "code": "indre-et-loire",
                "name": "Indre-et-Loire",
                "pk": "FR__indre-et-loire"
            },
            {
                "_id": "isere",
                "code": "isere",
                "name": "Isere",
                "pk": "FR__isere"
            },
            {
                "_id": "jura",
                "code": "jura",
                "name": "Jura",
                "pk": "FR__jura"
            },
            {
                "_id": "klagenfurt",
                "code": "klagenfurt",
                "name": "Klagenfurt",
                "pk": "FR__klagenfurt"
            },
            {
                "_id": "landes",
                "code": "landes",
                "name": "Landes",
                "pk": "FR__landes"
            },
            {
                "_id": "languedoc-roussillon",
                "code": "languedoc-roussillon",
                "name": "Languedoc-Roussillon",
                "pk": "FR__languedoc-roussillon"
            },
            {
                "_id": "larcay",
                "code": "larcay",
                "name": "Larcay",
                "pk": "FR__larcay"
            },
            {
                "_id": "le_castellet",
                "code": "le_castellet",
                "name": "Le Castellet",
                "pk": "FR__le_castellet"
            },
            {
                "_id": "le_creusot",
                "code": "le_creusot",
                "name": "Le Creusot",
                "pk": "FR__le_creusot"
            },
            {
                "_id": "limousin",
                "code": "limousin",
                "name": "Limousin",
                "pk": "FR__limousin"
            },
            {
                "_id": "loir-et-cher",
                "code": "loir-et-cher",
                "name": "Loir-et-Cher",
                "pk": "FR__loir-et-cher"
            },
            {
                "_id": "loire",
                "code": "loire",
                "name": "Loire",
                "pk": "FR__loire"
            },
            {
                "_id": "loire-atlantique",
                "code": "loire-atlantique",
                "name": "Loire-Atlantique",
                "pk": "FR__loire-atlantique"
            },
            {
                "_id": "loiret",
                "code": "loiret",
                "name": "Loiret",
                "pk": "FR__loiret"
            },
            {
                "_id": "lorraine",
                "code": "lorraine",
                "name": "Lorraine",
                "pk": "FR__lorraine"
            },
            {
                "_id": "lot",
                "code": "lot",
                "name": "Lot",
                "pk": "FR__lot"
            },
            {
                "_id": "lot-et-garonne",
                "code": "lot-et-garonne",
                "name": "Lot-et-Garonne",
                "pk": "FR__lot-et-garonne"
            },
            {
                "_id": "lower_normandy",
                "code": "lower_normandy",
                "name": "Lower Normandy",
                "pk": "FR__lower_normandy"
            },
            {
                "_id": "lozere",
                "code": "lozere",
                "name": "Lozere",
                "pk": "FR__lozere"
            },
            {
                "_id": "maine-et-loire",
                "code": "maine-et-loire",
                "name": "Maine-et-Loire",
                "pk": "FR__maine-et-loire"
            },
            {
                "_id": "manche",
                "code": "manche",
                "name": "Manche",
                "pk": "FR__manche"
            },
            {
                "_id": "marne",
                "code": "marne",
                "name": "Marne",
                "pk": "FR__marne"
            },
            {
                "_id": "mayenne",
                "code": "mayenne",
                "name": "Mayenne",
                "pk": "FR__mayenne"
            },
            {
                "_id": "meurthe-et-moselle",
                "code": "meurthe-et-moselle",
                "name": "Meurthe-et-Moselle",
                "pk": "FR__meurthe-et-moselle"
            },
            {
                "_id": "meuse",
                "code": "meuse",
                "name": "Meuse",
                "pk": "FR__meuse"
            },
            {
                "_id": "midi-pyrenees",
                "code": "midi-pyrenees",
                "name": "Midi-Pyrenees",
                "pk": "FR__midi-pyrenees"
            },
            {
                "_id": "morbihan",
                "code": "morbihan",
                "name": "Morbihan",
                "pk": "FR__morbihan"
            },
            {
                "_id": "moselle",
                "code": "moselle",
                "name": "Moselle",
                "pk": "FR__moselle"
            },
            {
                "_id": "nievre",
                "code": "nievre",
                "name": "Nievre",
                "pk": "FR__nievre"
            },
            {
                "_id": "nord",
                "code": "nord",
                "name": "Nord",
                "pk": "FR__nord"
            },
            {
                "_id": "nord-pas-de-calais",
                "code": "nord-pas-de-calais",
                "name": "Nord-Pas-de-Calais",
                "pk": "FR__nord-pas-de-calais"
            },
            {
                "_id": "oise",
                "code": "oise",
                "name": "Oise",
                "pk": "FR__oise"
            },
            {
                "_id": "orne",
                "code": "orne",
                "name": "Orne",
                "pk": "FR__orne"
            },
            {
                "_id": "paris",
                "code": "paris",
                "name": "Paris",
                "pk": "FR__paris"
            },
            {
                "_id": "pas-de-calais",
                "code": "pas-de-calais",
                "name": "Pas-de-Calais",
                "pk": "FR__pas-de-calais"
            },
            {
                "_id": "pays_de_la_loire",
                "code": "pays_de_la_loire",
                "name": "Pays de la Loire",
                "pk": "FR__pays_de_la_loire"
            },
            {
                "_id": "pays-de-la-loire",
                "code": "pays-de-la-loire",
                "name": "Pays-de-la-Loire",
                "pk": "FR__pays-de-la-loire"
            },
            {
                "_id": "picardy",
                "code": "picardy",
                "name": "Picardy",
                "pk": "FR__picardy"
            },
            {
                "_id": "puy-de-dome",
                "code": "puy-de-dome",
                "name": "Puy-de-Dome",
                "pk": "FR__puy-de-dome"
            },
            {
                "_id": "pyrenees-atlantiques",
                "code": "pyrenees-atlantiques",
                "name": "Pyrenees-Atlantiques",
                "pk": "FR__pyrenees-atlantiques"
            },
            {
                "_id": "pyrenees-orientales",
                "code": "pyrenees-orientales",
                "name": "Pyrenees-Orientales",
                "pk": "FR__pyrenees-orientales"
            },
            {
                "_id": "quelmes",
                "code": "quelmes",
                "name": "Quelmes",
                "pk": "FR__quelmes"
            },
            {
                "_id": "rhone",
                "code": "rhone",
                "name": "Rhone",
                "pk": "FR__rhone"
            },
            {
                "_id": "rhone-alpes",
                "code": "rhone-alpes",
                "name": "Rhone-Alpes",
                "pk": "FR__rhone-alpes"
            },
            {
                "_id": "saint_ouen",
                "code": "saint_ouen",
                "name": "Saint Ouen",
                "pk": "FR__saint_ouen"
            },
            {
                "_id": "saint_viatre",
                "code": "saint_viatre",
                "name": "Saint Viatre",
                "pk": "FR__saint_viatre"
            },
            {
                "_id": "saone-et-loire",
                "code": "saone-et-loire",
                "name": "Saone-et-Loire",
                "pk": "FR__saone-et-loire"
            },
            {
                "_id": "sarthe",
                "code": "sarthe",
                "name": "Sarthe",
                "pk": "FR__sarthe"
            },
            {
                "_id": "savoie",
                "code": "savoie",
                "name": "Savoie",
                "pk": "FR__savoie"
            },
            {
                "_id": "seine-maritime",
                "code": "seine-maritime",
                "name": "Seine-Maritime",
                "pk": "FR__seine-maritime"
            },
            {
                "_id": "seine-saint-denis",
                "code": "seine-saint-denis",
                "name": "Seine-Saint-Denis",
                "pk": "FR__seine-saint-denis"
            },
            {
                "_id": "seine-et-marne",
                "code": "seine-et-marne",
                "name": "Seine-et-Marne",
                "pk": "FR__seine-et-marne"
            },
            {
                "_id": "somme",
                "code": "somme",
                "name": "Somme",
                "pk": "FR__somme"
            },
            {
                "_id": "sophia_antipolis",
                "code": "sophia_antipolis",
                "name": "Sophia Antipolis",
                "pk": "FR__sophia_antipolis"
            },
            {
                "_id": "souvans",
                "code": "souvans",
                "name": "Souvans",
                "pk": "FR__souvans"
            },
            {
                "_id": "tarn",
                "code": "tarn",
                "name": "Tarn",
                "pk": "FR__tarn"
            },
            {
                "_id": "tarn-et-garonne",
                "code": "tarn-et-garonne",
                "name": "Tarn-et-Garonne",
                "pk": "FR__tarn-et-garonne"
            },
            {
                "_id": "territoire_de_belfort",
                "code": "territoire_de_belfort",
                "name": "Territoire de Belfort",
                "pk": "FR__territoire_de_belfort"
            },
            {
                "_id": "treignac",
                "code": "treignac",
                "name": "Treignac",
                "pk": "FR__treignac"
            },
            {
                "_id": "upper_normandy",
                "code": "upper_normandy",
                "name": "Upper Normandy",
                "pk": "FR__upper_normandy"
            },
            {
                "_id": "val-doise",
                "code": "val-doise",
                "name": "Val-dOise",
                "pk": "FR__val-doise"
            },
            {
                "_id": "val-de-marne",
                "code": "val-de-marne",
                "name": "Val-de-Marne",
                "pk": "FR__val-de-marne"
            },
            {
                "_id": "var",
                "code": "var",
                "name": "Var",
                "pk": "FR__var"
            },
            {
                "_id": "vaucluse",
                "code": "vaucluse",
                "name": "Vaucluse",
                "pk": "FR__vaucluse"
            },
            {
                "_id": "vellise",
                "code": "vellise",
                "name": "Vellise",
                "pk": "FR__vellise"
            },
            {
                "_id": "vendee",
                "code": "vendee",
                "name": "Vendee",
                "pk": "FR__vendee"
            },
            {
                "_id": "vienne",
                "code": "vienne",
                "name": "Vienne",
                "pk": "FR__vienne"
            },
            {
                "_id": "vosges",
                "code": "vosges",
                "name": "Vosges",
                "pk": "FR__vosges"
            },
            {
                "_id": "yonne",
                "code": "yonne",
                "name": "Yonne",
                "pk": "FR__yonne"
            },
            {
                "_id": "yvelines",
                "code": "yvelines",
                "name": "Yvelines",
                "pk": "FR__yvelines"
            }
        ],
        "only_shipping": true,
        "pk": "FR"
    },
    {
        "_id": "GF",
        "code": "GF",
        "name": "French Guiana",
        "states": [
            {
                "_id": "cayenne",
                "code": "cayenne",
                "name": "Cayenne",
                "pk": "GF__cayenne"
            },
            {
                "_id": "saint-laurent-du-maroni",
                "code": "saint-laurent-du-maroni",
                "name": "Saint-Laurent-du-Maroni",
                "pk": "GF__saint-laurent-du-maroni"
            }
        ],
        "pk": "GF"
    },
    {
        "_id": "PF",
        "code": "PF",
        "name": "French Polynesia",
        "states": [
            {
                "_id": "iles_du_vent",
                "code": "iles_du_vent",
                "name": "Iles du Vent",
                "pk": "PF__iles_du_vent"
            },
            {
                "_id": "iles_sous_le_vent",
                "code": "iles_sous_le_vent",
                "name": "Iles sous le Vent",
                "pk": "PF__iles_sous_le_vent"
            },
            {
                "_id": "marquesas",
                "code": "marquesas",
                "name": "Marquesas",
                "pk": "PF__marquesas"
            },
            {
                "_id": "tuamotu",
                "code": "tuamotu",
                "name": "Tuamotu",
                "pk": "PF__tuamotu"
            },
            {
                "_id": "tubuai",
                "code": "tubuai",
                "name": "Tubuai",
                "pk": "PF__tubuai"
            }
        ],
        "pk": "PF"
    },
    {
        "_id": "TF",
        "code": "TF",
        "name": "French Southern Territories",
        "states": [
            {
                "_id": "amsterdam",
                "code": "amsterdam",
                "name": "Amsterdam",
                "pk": "TF__amsterdam"
            },
            {
                "_id": "crozet_islands",
                "code": "crozet_islands",
                "name": "Crozet Islands",
                "pk": "TF__crozet_islands"
            },
            {
                "_id": "kerguelen",
                "code": "kerguelen",
                "name": "Kerguelen",
                "pk": "TF__kerguelen"
            }
        ],
        "pk": "TF"
    },
    {
        "_id": "GA",
        "code": "GA",
        "name": "Gabon",
        "states": [
            {
                "_id": "estuaire",
                "code": "estuaire",
                "name": "Estuaire",
                "pk": "GA__estuaire"
            },
            {
                "_id": "haut-ogooue",
                "code": "haut-ogooue",
                "name": "Haut-Ogooue",
                "pk": "GA__haut-ogooue"
            },
            {
                "_id": "moyen-ogooue",
                "code": "moyen-ogooue",
                "name": "Moyen-Ogooue",
                "pk": "GA__moyen-ogooue"
            },
            {
                "_id": "ngounie",
                "code": "ngounie",
                "name": "Ngounie",
                "pk": "GA__ngounie"
            },
            {
                "_id": "nyanga",
                "code": "nyanga",
                "name": "Nyanga",
                "pk": "GA__nyanga"
            },
            {
                "_id": "ogooue-ivindo",
                "code": "ogooue-ivindo",
                "name": "Ogooue-Ivindo",
                "pk": "GA__ogooue-ivindo"
            },
            {
                "_id": "ogooue-lolo",
                "code": "ogooue-lolo",
                "name": "Ogooue-Lolo",
                "pk": "GA__ogooue-lolo"
            },
            {
                "_id": "ogooue-maritime",
                "code": "ogooue-maritime",
                "name": "Ogooue-Maritime",
                "pk": "GA__ogooue-maritime"
            },
            {
                "_id": "woleu-ntem",
                "code": "woleu-ntem",
                "name": "Woleu-Ntem",
                "pk": "GA__woleu-ntem"
            }
        ],
        "pk": "GA"
    },
    {
        "_id": "GM",
        "code": "GM",
        "name": "Gambia The",
        "states": [
            {
                "_id": "banjul",
                "code": "banjul",
                "name": "Banjul",
                "pk": "GM__banjul"
            },
            {
                "_id": "basse",
                "code": "basse",
                "name": "Basse",
                "pk": "GM__basse"
            },
            {
                "_id": "brikama",
                "code": "brikama",
                "name": "Brikama",
                "pk": "GM__brikama"
            },
            {
                "_id": "janjanbureh",
                "code": "janjanbureh",
                "name": "Janjanbureh",
                "pk": "GM__janjanbureh"
            },
            {
                "_id": "kanifing",
                "code": "kanifing",
                "name": "Kanifing",
                "pk": "GM__kanifing"
            },
            {
                "_id": "kerewan",
                "code": "kerewan",
                "name": "Kerewan",
                "pk": "GM__kerewan"
            },
            {
                "_id": "kuntaur",
                "code": "kuntaur",
                "name": "Kuntaur",
                "pk": "GM__kuntaur"
            },
            {
                "_id": "mansakonko",
                "code": "mansakonko",
                "name": "Mansakonko",
                "pk": "GM__mansakonko"
            }
        ],
        "pk": "GM"
    },
    {
        "_id": "GE",
        "code": "GE",
        "name": "Georgia",
        "states": [
            {
                "_id": "abhasia",
                "code": "abhasia",
                "name": "Abhasia",
                "pk": "GE__abhasia"
            },
            {
                "_id": "ajaria",
                "code": "ajaria",
                "name": "Ajaria",
                "pk": "GE__ajaria"
            },
            {
                "_id": "guria",
                "code": "guria",
                "name": "Guria",
                "pk": "GE__guria"
            },
            {
                "_id": "imereti",
                "code": "imereti",
                "name": "Imereti",
                "pk": "GE__imereti"
            },
            {
                "_id": "kaheti",
                "code": "kaheti",
                "name": "Kaheti",
                "pk": "GE__kaheti"
            },
            {
                "_id": "kvemo_kartli",
                "code": "kvemo_kartli",
                "name": "Kvemo Kartli",
                "pk": "GE__kvemo_kartli"
            },
            {
                "_id": "mcheta-mtianeti",
                "code": "mcheta-mtianeti",
                "name": "Mcheta-Mtianeti",
                "pk": "GE__mcheta-mtianeti"
            },
            {
                "_id": "racha",
                "code": "racha",
                "name": "Racha",
                "pk": "GE__racha"
            },
            {
                "_id": "samagrelo-zemo_svaneti",
                "code": "samagrelo-zemo_svaneti",
                "name": "Samagrelo-Zemo Svaneti",
                "pk": "GE__samagrelo-zemo_svaneti"
            },
            {
                "_id": "samche-zhavaheti",
                "code": "samche-zhavaheti",
                "name": "Samche-Zhavaheti",
                "pk": "GE__samche-zhavaheti"
            },
            {
                "_id": "shida_kartli",
                "code": "shida_kartli",
                "name": "Shida Kartli",
                "pk": "GE__shida_kartli"
            },
            {
                "_id": "tbilisi",
                "code": "tbilisi",
                "name": "Tbilisi",
                "pk": "GE__tbilisi"
            }
        ],
        "pk": "GE"
    },
    {
        "_id": "DE",
        "code": "DE",
        "name": "Germany",
        "states": [
            {
                "_id": "auvergne",
                "code": "auvergne",
                "name": "Auvergne",
                "pk": "DE__auvergne"
            },
            {
                "_id": "baden-wurttemberg",
                "code": "baden-wurttemberg",
                "name": "Baden-Wurttemberg",
                "pk": "DE__baden-wurttemberg"
            },
            {
                "_id": "bavaria",
                "code": "bavaria",
                "name": "Bavaria",
                "pk": "DE__bavaria"
            },
            {
                "_id": "bayern",
                "code": "bayern",
                "name": "Bayern",
                "pk": "DE__bayern"
            },
            {
                "_id": "beilstein_wurtt",
                "code": "beilstein_wurtt",
                "name": "Beilstein Wurtt",
                "pk": "DE__beilstein_wurtt"
            },
            {
                "_id": "berlin",
                "code": "berlin",
                "name": "Berlin",
                "pk": "DE__berlin"
            },
            {
                "_id": "brandenburg",
                "code": "brandenburg",
                "name": "Brandenburg",
                "pk": "DE__brandenburg"
            },
            {
                "_id": "bremen",
                "code": "bremen",
                "name": "Bremen",
                "pk": "DE__bremen"
            },
            {
                "_id": "dreisbach",
                "code": "dreisbach",
                "name": "Dreisbach",
                "pk": "DE__dreisbach"
            },
            {
                "_id": "freistaat_bayern",
                "code": "freistaat_bayern",
                "name": "Freistaat Bayern",
                "pk": "DE__freistaat_bayern"
            },
            {
                "_id": "hamburg",
                "code": "hamburg",
                "name": "Hamburg",
                "pk": "DE__hamburg"
            },
            {
                "_id": "hannover",
                "code": "hannover",
                "name": "Hannover",
                "pk": "DE__hannover"
            },
            {
                "_id": "heroldstatt",
                "code": "heroldstatt",
                "name": "Heroldstatt",
                "pk": "DE__heroldstatt"
            },
            {
                "_id": "hessen",
                "code": "hessen",
                "name": "Hessen",
                "pk": "DE__hessen"
            },
            {
                "_id": "kortenberg",
                "code": "kortenberg",
                "name": "Kortenberg",
                "pk": "DE__kortenberg"
            },
            {
                "_id": "laasdorf",
                "code": "laasdorf",
                "name": "Laasdorf",
                "pk": "DE__laasdorf"
            },
            {
                "_id": "land_baden-wurttemberg",
                "code": "land_baden-wurttemberg",
                "name": "Land Baden-Wurttemberg",
                "pk": "DE__land_baden-wurttemberg"
            },
            {
                "_id": "land_bayern",
                "code": "land_bayern",
                "name": "Land Bayern",
                "pk": "DE__land_bayern"
            },
            {
                "_id": "land_brandenburg",
                "code": "land_brandenburg",
                "name": "Land Brandenburg",
                "pk": "DE__land_brandenburg"
            },
            {
                "_id": "land_hessen",
                "code": "land_hessen",
                "name": "Land Hessen",
                "pk": "DE__land_hessen"
            },
            {
                "_id": "land_mecklenburg-vorpommern",
                "code": "land_mecklenburg-vorpommern",
                "name": "Land Mecklenburg-Vorpommern",
                "pk": "DE__land_mecklenburg-vorpommern"
            },
            {
                "_id": "land_nordrhein-westfalen",
                "code": "land_nordrhein-westfalen",
                "name": "Land Nordrhein-Westfalen",
                "pk": "DE__land_nordrhein-westfalen"
            },
            {
                "_id": "land_rheinland-pfalz",
                "code": "land_rheinland-pfalz",
                "name": "Land Rheinland-Pfalz",
                "pk": "DE__land_rheinland-pfalz"
            },
            {
                "_id": "land_sachsen",
                "code": "land_sachsen",
                "name": "Land Sachsen",
                "pk": "DE__land_sachsen"
            },
            {
                "_id": "land_sachsen-anhalt",
                "code": "land_sachsen-anhalt",
                "name": "Land Sachsen-Anhalt",
                "pk": "DE__land_sachsen-anhalt"
            },
            {
                "_id": "land_thuringen",
                "code": "land_thuringen",
                "name": "Land Thuringen",
                "pk": "DE__land_thuringen"
            },
            {
                "_id": "lower_saxony",
                "code": "lower_saxony",
                "name": "Lower Saxony",
                "pk": "DE__lower_saxony"
            },
            {
                "_id": "mecklenburg-vorpommern",
                "code": "mecklenburg-vorpommern",
                "name": "Mecklenburg-Vorpommern",
                "pk": "DE__mecklenburg-vorpommern"
            },
            {
                "_id": "mulfingen",
                "code": "mulfingen",
                "name": "Mulfingen",
                "pk": "DE__mulfingen"
            },
            {
                "_id": "munich",
                "code": "munich",
                "name": "Munich",
                "pk": "DE__munich"
            },
            {
                "_id": "neubeuern",
                "code": "neubeuern",
                "name": "Neubeuern",
                "pk": "DE__neubeuern"
            },
            {
                "_id": "niedersachsen",
                "code": "niedersachsen",
                "name": "Niedersachsen",
                "pk": "DE__niedersachsen"
            },
            {
                "_id": "noord-holland",
                "code": "noord-holland",
                "name": "Noord-Holland",
                "pk": "DE__noord-holland"
            },
            {
                "_id": "nordrhein-westfalen",
                "code": "nordrhein-westfalen",
                "name": "Nordrhein-Westfalen",
                "pk": "DE__nordrhein-westfalen"
            },
            {
                "_id": "north_rhine-westphalia",
                "code": "north_rhine-westphalia",
                "name": "North Rhine-Westphalia",
                "pk": "DE__north_rhine-westphalia"
            },
            {
                "_id": "osterode",
                "code": "osterode",
                "name": "Osterode",
                "pk": "DE__osterode"
            },
            {
                "_id": "rheinland-pfalz",
                "code": "rheinland-pfalz",
                "name": "Rheinland-Pfalz",
                "pk": "DE__rheinland-pfalz"
            },
            {
                "_id": "rhineland-palatinate",
                "code": "rhineland-palatinate",
                "name": "Rhineland-Palatinate",
                "pk": "DE__rhineland-palatinate"
            },
            {
                "_id": "saarland",
                "code": "saarland",
                "name": "Saarland",
                "pk": "DE__saarland"
            },
            {
                "_id": "sachsen",
                "code": "sachsen",
                "name": "Sachsen",
                "pk": "DE__sachsen"
            },
            {
                "_id": "sachsen-anhalt",
                "code": "sachsen-anhalt",
                "name": "Sachsen-Anhalt",
                "pk": "DE__sachsen-anhalt"
            },
            {
                "_id": "saxony",
                "code": "saxony",
                "name": "Saxony",
                "pk": "DE__saxony"
            },
            {
                "_id": "schleswig-holstein",
                "code": "schleswig-holstein",
                "name": "Schleswig-Holstein",
                "pk": "DE__schleswig-holstein"
            },
            {
                "_id": "thuringia",
                "code": "thuringia",
                "name": "Thuringia",
                "pk": "DE__thuringia"
            },
            {
                "_id": "webling",
                "code": "webling",
                "name": "Webling",
                "pk": "DE__webling"
            },
            {
                "_id": "weinstrabe",
                "code": "weinstrabe",
                "name": "Weinstrabe",
                "pk": "DE__weinstrabe"
            },
            {
                "_id": "schlobborn",
                "code": "schlobborn",
                "name": "schlobborn",
                "pk": "DE__schlobborn"
            }
        ],
        "only_shipping": true,
        "pk": "DE"
    },
    {
        "_id": "GH",
        "code": "GH",
        "name": "Ghana",
        "states": [
            {
                "_id": "ashanti",
                "code": "ashanti",
                "name": "Ashanti",
                "pk": "GH__ashanti"
            },
            {
                "_id": "brong-ahafo",
                "code": "brong-ahafo",
                "name": "Brong-Ahafo",
                "pk": "GH__brong-ahafo"
            },
            {
                "_id": "central",
                "code": "central",
                "name": "Central",
                "pk": "GH__central"
            },
            {
                "_id": "eastern",
                "code": "eastern",
                "name": "Eastern",
                "pk": "GH__eastern"
            },
            {
                "_id": "greater_accra",
                "code": "greater_accra",
                "name": "Greater Accra",
                "pk": "GH__greater_accra"
            },
            {
                "_id": "northern",
                "code": "northern",
                "name": "Northern",
                "pk": "GH__northern"
            },
            {
                "_id": "upper_east",
                "code": "upper_east",
                "name": "Upper East",
                "pk": "GH__upper_east"
            },
            {
                "_id": "upper_west",
                "code": "upper_west",
                "name": "Upper West",
                "pk": "GH__upper_west"
            },
            {
                "_id": "volta",
                "code": "volta",
                "name": "Volta",
                "pk": "GH__volta"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "GH__western"
            }
        ],
        "pk": "GH"
    },
    {
        "_id": "GI",
        "code": "GI",
        "name": "Gibraltar",
        "states": [
            {
                "_id": "gibraltar",
                "code": "gibraltar",
                "name": "Gibraltar",
                "pk": "GI__gibraltar"
            }
        ],
        "pk": "GI"
    },
    {
        "_id": "GR",
        "code": "GR",
        "name": "Greece",
        "states": [
            {
                "_id": "acharnes",
                "code": "acharnes",
                "name": "Acharnes",
                "pk": "GR__acharnes"
            },
            {
                "_id": "ahaia",
                "code": "ahaia",
                "name": "Ahaia",
                "pk": "GR__ahaia"
            },
            {
                "_id": "aitolia_kai_akarnania",
                "code": "aitolia_kai_akarnania",
                "name": "Aitolia kai Akarnania",
                "pk": "GR__aitolia_kai_akarnania"
            },
            {
                "_id": "argolis",
                "code": "argolis",
                "name": "Argolis",
                "pk": "GR__argolis"
            },
            {
                "_id": "arkadia",
                "code": "arkadia",
                "name": "Arkadia",
                "pk": "GR__arkadia"
            },
            {
                "_id": "arta",
                "code": "arta",
                "name": "Arta",
                "pk": "GR__arta"
            },
            {
                "_id": "attica",
                "code": "attica",
                "name": "Attica",
                "pk": "GR__attica"
            },
            {
                "_id": "attiki",
                "code": "attiki",
                "name": "Attiki",
                "pk": "GR__attiki"
            },
            {
                "_id": "ayion_oros",
                "code": "ayion_oros",
                "name": "Ayion Oros",
                "pk": "GR__ayion_oros"
            },
            {
                "_id": "crete",
                "code": "crete",
                "name": "Crete",
                "pk": "GR__crete"
            },
            {
                "_id": "dodekanisos",
                "code": "dodekanisos",
                "name": "Dodekanisos",
                "pk": "GR__dodekanisos"
            },
            {
                "_id": "drama",
                "code": "drama",
                "name": "Drama",
                "pk": "GR__drama"
            },
            {
                "_id": "evia",
                "code": "evia",
                "name": "Evia",
                "pk": "GR__evia"
            },
            {
                "_id": "evritania",
                "code": "evritania",
                "name": "Evritania",
                "pk": "GR__evritania"
            },
            {
                "_id": "evros",
                "code": "evros",
                "name": "Evros",
                "pk": "GR__evros"
            },
            {
                "_id": "evvoia",
                "code": "evvoia",
                "name": "Evvoia",
                "pk": "GR__evvoia"
            },
            {
                "_id": "florina",
                "code": "florina",
                "name": "Florina",
                "pk": "GR__florina"
            },
            {
                "_id": "fokis",
                "code": "fokis",
                "name": "Fokis",
                "pk": "GR__fokis"
            },
            {
                "_id": "fthiotis",
                "code": "fthiotis",
                "name": "Fthiotis",
                "pk": "GR__fthiotis"
            },
            {
                "_id": "grevena",
                "code": "grevena",
                "name": "Grevena",
                "pk": "GR__grevena"
            },
            {
                "_id": "halandri",
                "code": "halandri",
                "name": "Halandri",
                "pk": "GR__halandri"
            },
            {
                "_id": "halkidiki",
                "code": "halkidiki",
                "name": "Halkidiki",
                "pk": "GR__halkidiki"
            },
            {
                "_id": "hania",
                "code": "hania",
                "name": "Hania",
                "pk": "GR__hania"
            },
            {
                "_id": "heraklion",
                "code": "heraklion",
                "name": "Heraklion",
                "pk": "GR__heraklion"
            },
            {
                "_id": "hios",
                "code": "hios",
                "name": "Hios",
                "pk": "GR__hios"
            },
            {
                "_id": "ilia",
                "code": "ilia",
                "name": "Ilia",
                "pk": "GR__ilia"
            },
            {
                "_id": "imathia",
                "code": "imathia",
                "name": "Imathia",
                "pk": "GR__imathia"
            },
            {
                "_id": "ioannina",
                "code": "ioannina",
                "name": "Ioannina",
                "pk": "GR__ioannina"
            },
            {
                "_id": "iraklion",
                "code": "iraklion",
                "name": "Iraklion",
                "pk": "GR__iraklion"
            },
            {
                "_id": "karditsa",
                "code": "karditsa",
                "name": "Karditsa",
                "pk": "GR__karditsa"
            },
            {
                "_id": "kastoria",
                "code": "kastoria",
                "name": "Kastoria",
                "pk": "GR__kastoria"
            },
            {
                "_id": "kavala",
                "code": "kavala",
                "name": "Kavala",
                "pk": "GR__kavala"
            },
            {
                "_id": "kefallinia",
                "code": "kefallinia",
                "name": "Kefallinia",
                "pk": "GR__kefallinia"
            },
            {
                "_id": "kerkira",
                "code": "kerkira",
                "name": "Kerkira",
                "pk": "GR__kerkira"
            },
            {
                "_id": "kiklades",
                "code": "kiklades",
                "name": "Kiklades",
                "pk": "GR__kiklades"
            },
            {
                "_id": "kilkis",
                "code": "kilkis",
                "name": "Kilkis",
                "pk": "GR__kilkis"
            },
            {
                "_id": "korinthia",
                "code": "korinthia",
                "name": "Korinthia",
                "pk": "GR__korinthia"
            },
            {
                "_id": "kozani",
                "code": "kozani",
                "name": "Kozani",
                "pk": "GR__kozani"
            },
            {
                "_id": "lakonia",
                "code": "lakonia",
                "name": "Lakonia",
                "pk": "GR__lakonia"
            },
            {
                "_id": "larisa",
                "code": "larisa",
                "name": "Larisa",
                "pk": "GR__larisa"
            },
            {
                "_id": "lasithi",
                "code": "lasithi",
                "name": "Lasithi",
                "pk": "GR__lasithi"
            },
            {
                "_id": "lesvos",
                "code": "lesvos",
                "name": "Lesvos",
                "pk": "GR__lesvos"
            },
            {
                "_id": "levkas",
                "code": "levkas",
                "name": "Levkas",
                "pk": "GR__levkas"
            },
            {
                "_id": "magnisia",
                "code": "magnisia",
                "name": "Magnisia",
                "pk": "GR__magnisia"
            },
            {
                "_id": "messinia",
                "code": "messinia",
                "name": "Messinia",
                "pk": "GR__messinia"
            },
            {
                "_id": "nomos_attikis",
                "code": "nomos_attikis",
                "name": "Nomos Attikis",
                "pk": "GR__nomos_attikis"
            },
            {
                "_id": "nomos_zakynthou",
                "code": "nomos_zakynthou",
                "name": "Nomos Zakynthou",
                "pk": "GR__nomos_zakynthou"
            },
            {
                "_id": "pella",
                "code": "pella",
                "name": "Pella",
                "pk": "GR__pella"
            },
            {
                "_id": "pieria",
                "code": "pieria",
                "name": "Pieria",
                "pk": "GR__pieria"
            },
            {
                "_id": "piraios",
                "code": "piraios",
                "name": "Piraios",
                "pk": "GR__piraios"
            },
            {
                "_id": "preveza",
                "code": "preveza",
                "name": "Preveza",
                "pk": "GR__preveza"
            },
            {
                "_id": "rethimni",
                "code": "rethimni",
                "name": "Rethimni",
                "pk": "GR__rethimni"
            },
            {
                "_id": "rodopi",
                "code": "rodopi",
                "name": "Rodopi",
                "pk": "GR__rodopi"
            },
            {
                "_id": "samos",
                "code": "samos",
                "name": "Samos",
                "pk": "GR__samos"
            },
            {
                "_id": "serrai",
                "code": "serrai",
                "name": "Serrai",
                "pk": "GR__serrai"
            },
            {
                "_id": "thesprotia",
                "code": "thesprotia",
                "name": "Thesprotia",
                "pk": "GR__thesprotia"
            },
            {
                "_id": "thessaloniki",
                "code": "thessaloniki",
                "name": "Thessaloniki",
                "pk": "GR__thessaloniki"
            },
            {
                "_id": "trikala",
                "code": "trikala",
                "name": "Trikala",
                "pk": "GR__trikala"
            },
            {
                "_id": "voiotia",
                "code": "voiotia",
                "name": "Voiotia",
                "pk": "GR__voiotia"
            },
            {
                "_id": "west_greece",
                "code": "west_greece",
                "name": "West Greece",
                "pk": "GR__west_greece"
            },
            {
                "_id": "xanthi",
                "code": "xanthi",
                "name": "Xanthi",
                "pk": "GR__xanthi"
            },
            {
                "_id": "zakinthos",
                "code": "zakinthos",
                "name": "Zakinthos",
                "pk": "GR__zakinthos"
            }
        ],
        "pk": "GR"
    },
    {
        "_id": "GL",
        "code": "GL",
        "name": "Greenland",
        "states": [
            {
                "_id": "aasiaat",
                "code": "aasiaat",
                "name": "Aasiaat",
                "pk": "GL__aasiaat"
            },
            {
                "_id": "ammassalik",
                "code": "ammassalik",
                "name": "Ammassalik",
                "pk": "GL__ammassalik"
            },
            {
                "_id": "illoqqortoormiut",
                "code": "illoqqortoormiut",
                "name": "Illoqqortoormiut",
                "pk": "GL__illoqqortoormiut"
            },
            {
                "_id": "ilulissat",
                "code": "ilulissat",
                "name": "Ilulissat",
                "pk": "GL__ilulissat"
            },
            {
                "_id": "ivittuut",
                "code": "ivittuut",
                "name": "Ivittuut",
                "pk": "GL__ivittuut"
            },
            {
                "_id": "kangaatsiaq",
                "code": "kangaatsiaq",
                "name": "Kangaatsiaq",
                "pk": "GL__kangaatsiaq"
            },
            {
                "_id": "maniitsoq",
                "code": "maniitsoq",
                "name": "Maniitsoq",
                "pk": "GL__maniitsoq"
            },
            {
                "_id": "nanortalik",
                "code": "nanortalik",
                "name": "Nanortalik",
                "pk": "GL__nanortalik"
            },
            {
                "_id": "narsaq",
                "code": "narsaq",
                "name": "Narsaq",
                "pk": "GL__narsaq"
            },
            {
                "_id": "nuuk",
                "code": "nuuk",
                "name": "Nuuk",
                "pk": "GL__nuuk"
            },
            {
                "_id": "paamiut",
                "code": "paamiut",
                "name": "Paamiut",
                "pk": "GL__paamiut"
            },
            {
                "_id": "qaanaaq",
                "code": "qaanaaq",
                "name": "Qaanaaq",
                "pk": "GL__qaanaaq"
            },
            {
                "_id": "qaqortoq",
                "code": "qaqortoq",
                "name": "Qaqortoq",
                "pk": "GL__qaqortoq"
            },
            {
                "_id": "qasigiannguit",
                "code": "qasigiannguit",
                "name": "Qasigiannguit",
                "pk": "GL__qasigiannguit"
            },
            {
                "_id": "qeqertarsuaq",
                "code": "qeqertarsuaq",
                "name": "Qeqertarsuaq",
                "pk": "GL__qeqertarsuaq"
            },
            {
                "_id": "sisimiut",
                "code": "sisimiut",
                "name": "Sisimiut",
                "pk": "GL__sisimiut"
            },
            {
                "_id": "udenfor_kommunal_inddeling",
                "code": "udenfor_kommunal_inddeling",
                "name": "Udenfor kommunal inddeling",
                "pk": "GL__udenfor_kommunal_inddeling"
            },
            {
                "_id": "upernavik",
                "code": "upernavik",
                "name": "Upernavik",
                "pk": "GL__upernavik"
            },
            {
                "_id": "uummannaq",
                "code": "uummannaq",
                "name": "Uummannaq",
                "pk": "GL__uummannaq"
            }
        ],
        "pk": "GL"
    },
    {
        "_id": "GD",
        "code": "GD",
        "name": "Grenada",
        "states": [
            {
                "_id": "carriacou-petite_martinique",
                "code": "carriacou-petite_martinique",
                "name": "Carriacou-Petite Martinique",
                "pk": "GD__carriacou-petite_martinique"
            },
            {
                "_id": "saint_andrew",
                "code": "saint_andrew",
                "name": "Saint Andrew",
                "pk": "GD__saint_andrew"
            },
            {
                "_id": "saint_davids",
                "code": "saint_davids",
                "name": "Saint Davids",
                "pk": "GD__saint_davids"
            },
            {
                "_id": "saint_georges",
                "code": "saint_georges",
                "name": "Saint Georges",
                "pk": "GD__saint_georges"
            },
            {
                "_id": "saint_john",
                "code": "saint_john",
                "name": "Saint John",
                "pk": "GD__saint_john"
            },
            {
                "_id": "saint_mark",
                "code": "saint_mark",
                "name": "Saint Mark",
                "pk": "GD__saint_mark"
            },
            {
                "_id": "saint_patrick",
                "code": "saint_patrick",
                "name": "Saint Patrick",
                "pk": "GD__saint_patrick"
            }
        ],
        "pk": "GD"
    },
    {
        "_id": "GP",
        "code": "GP",
        "name": "Guadeloupe",
        "states": [
            {
                "_id": "basse-terre",
                "code": "basse-terre",
                "name": "Basse-Terre",
                "pk": "GP__basse-terre"
            },
            {
                "_id": "grande-terre",
                "code": "grande-terre",
                "name": "Grande-Terre",
                "pk": "GP__grande-terre"
            },
            {
                "_id": "iles_des_saintes",
                "code": "iles_des_saintes",
                "name": "Iles des Saintes",
                "pk": "GP__iles_des_saintes"
            },
            {
                "_id": "la_desirade",
                "code": "la_desirade",
                "name": "La Desirade",
                "pk": "GP__la_desirade"
            },
            {
                "_id": "marie-galante",
                "code": "marie-galante",
                "name": "Marie-Galante",
                "pk": "GP__marie-galante"
            },
            {
                "_id": "saint_barthelemy",
                "code": "saint_barthelemy",
                "name": "Saint Barthelemy",
                "pk": "GP__saint_barthelemy"
            },
            {
                "_id": "saint_martin",
                "code": "saint_martin",
                "name": "Saint Martin",
                "pk": "GP__saint_martin"
            }
        ],
        "pk": "GP"
    },
    {
        "_id": "GU",
        "code": "GU",
        "name": "Guam",
        "states": [
            {
                "_id": "agana_heights",
                "code": "agana_heights",
                "name": "Agana Heights",
                "pk": "GU__agana_heights"
            },
            {
                "_id": "agat",
                "code": "agat",
                "name": "Agat",
                "pk": "GU__agat"
            },
            {
                "_id": "barrigada",
                "code": "barrigada",
                "name": "Barrigada",
                "pk": "GU__barrigada"
            },
            {
                "_id": "chalan-pago-ordot",
                "code": "chalan-pago-ordot",
                "name": "Chalan-Pago-Ordot",
                "pk": "GU__chalan-pago-ordot"
            },
            {
                "_id": "dededo",
                "code": "dededo",
                "name": "Dededo",
                "pk": "GU__dededo"
            },
            {
                "_id": "hagatna",
                "code": "hagatna",
                "name": "Hagatna",
                "pk": "GU__hagatna"
            },
            {
                "_id": "inarajan",
                "code": "inarajan",
                "name": "Inarajan",
                "pk": "GU__inarajan"
            },
            {
                "_id": "mangilao",
                "code": "mangilao",
                "name": "Mangilao",
                "pk": "GU__mangilao"
            },
            {
                "_id": "merizo",
                "code": "merizo",
                "name": "Merizo",
                "pk": "GU__merizo"
            },
            {
                "_id": "mongmong-toto-maite",
                "code": "mongmong-toto-maite",
                "name": "Mongmong-Toto-Maite",
                "pk": "GU__mongmong-toto-maite"
            },
            {
                "_id": "santa_rita",
                "code": "santa_rita",
                "name": "Santa Rita",
                "pk": "GU__santa_rita"
            },
            {
                "_id": "sinajana",
                "code": "sinajana",
                "name": "Sinajana",
                "pk": "GU__sinajana"
            },
            {
                "_id": "talofofo",
                "code": "talofofo",
                "name": "Talofofo",
                "pk": "GU__talofofo"
            },
            {
                "_id": "tamuning",
                "code": "tamuning",
                "name": "Tamuning",
                "pk": "GU__tamuning"
            },
            {
                "_id": "yigo",
                "code": "yigo",
                "name": "Yigo",
                "pk": "GU__yigo"
            },
            {
                "_id": "yona",
                "code": "yona",
                "name": "Yona",
                "pk": "GU__yona"
            }
        ],
        "pk": "GU"
    },
    {
        "_id": "GT",
        "code": "GT",
        "name": "Guatemala",
        "states": [
            {
                "_id": "alta_verapaz",
                "code": "alta_verapaz",
                "name": "Alta Verapaz",
                "pk": "GT__alta_verapaz"
            },
            {
                "_id": "baja_verapaz",
                "code": "baja_verapaz",
                "name": "Baja Verapaz",
                "pk": "GT__baja_verapaz"
            },
            {
                "_id": "chimaltenango",
                "code": "chimaltenango",
                "name": "Chimaltenango",
                "pk": "GT__chimaltenango"
            },
            {
                "_id": "chiquimula",
                "code": "chiquimula",
                "name": "Chiquimula",
                "pk": "GT__chiquimula"
            },
            {
                "_id": "el_progreso",
                "code": "el_progreso",
                "name": "El Progreso",
                "pk": "GT__el_progreso"
            },
            {
                "_id": "escuintla",
                "code": "escuintla",
                "name": "Escuintla",
                "pk": "GT__escuintla"
            },
            {
                "_id": "guatemala",
                "code": "guatemala",
                "name": "Guatemala",
                "pk": "GT__guatemala"
            },
            {
                "_id": "huehuetenango",
                "code": "huehuetenango",
                "name": "Huehuetenango",
                "pk": "GT__huehuetenango"
            },
            {
                "_id": "izabal",
                "code": "izabal",
                "name": "Izabal",
                "pk": "GT__izabal"
            },
            {
                "_id": "jalapa",
                "code": "jalapa",
                "name": "Jalapa",
                "pk": "GT__jalapa"
            },
            {
                "_id": "jutiapa",
                "code": "jutiapa",
                "name": "Jutiapa",
                "pk": "GT__jutiapa"
            },
            {
                "_id": "peten",
                "code": "peten",
                "name": "Peten",
                "pk": "GT__peten"
            },
            {
                "_id": "quezaltenango",
                "code": "quezaltenango",
                "name": "Quezaltenango",
                "pk": "GT__quezaltenango"
            },
            {
                "_id": "quiche",
                "code": "quiche",
                "name": "Quiche",
                "pk": "GT__quiche"
            },
            {
                "_id": "retalhuleu",
                "code": "retalhuleu",
                "name": "Retalhuleu",
                "pk": "GT__retalhuleu"
            },
            {
                "_id": "sacatepequez",
                "code": "sacatepequez",
                "name": "Sacatepequez",
                "pk": "GT__sacatepequez"
            },
            {
                "_id": "san_marcos",
                "code": "san_marcos",
                "name": "San Marcos",
                "pk": "GT__san_marcos"
            },
            {
                "_id": "santa_rosa",
                "code": "santa_rosa",
                "name": "Santa Rosa",
                "pk": "GT__santa_rosa"
            },
            {
                "_id": "solola",
                "code": "solola",
                "name": "Solola",
                "pk": "GT__solola"
            },
            {
                "_id": "suchitepequez",
                "code": "suchitepequez",
                "name": "Suchitepequez",
                "pk": "GT__suchitepequez"
            },
            {
                "_id": "totonicapan",
                "code": "totonicapan",
                "name": "Totonicapan",
                "pk": "GT__totonicapan"
            },
            {
                "_id": "zacapa",
                "code": "zacapa",
                "name": "Zacapa",
                "pk": "GT__zacapa"
            }
        ],
        "pk": "GT"
    },
    {
        "_id": "XU",
        "code": "XU",
        "name": "Guernsey and Alderney",
        "states": [
            {
                "_id": "alderney",
                "code": "alderney",
                "name": "Alderney",
                "pk": "XU__alderney"
            },
            {
                "_id": "castel",
                "code": "castel",
                "name": "Castel",
                "pk": "XU__castel"
            },
            {
                "_id": "forest",
                "code": "forest",
                "name": "Forest",
                "pk": "XU__forest"
            },
            {
                "_id": "saint_andrew",
                "code": "saint_andrew",
                "name": "Saint Andrew",
                "pk": "XU__saint_andrew"
            },
            {
                "_id": "saint_martin",
                "code": "saint_martin",
                "name": "Saint Martin",
                "pk": "XU__saint_martin"
            },
            {
                "_id": "saint_peter_port",
                "code": "saint_peter_port",
                "name": "Saint Peter Port",
                "pk": "XU__saint_peter_port"
            },
            {
                "_id": "saint_pierre_du_bois",
                "code": "saint_pierre_du_bois",
                "name": "Saint Pierre du Bois",
                "pk": "XU__saint_pierre_du_bois"
            },
            {
                "_id": "saint_sampson",
                "code": "saint_sampson",
                "name": "Saint Sampson",
                "pk": "XU__saint_sampson"
            },
            {
                "_id": "saint_saviour",
                "code": "saint_saviour",
                "name": "Saint Saviour",
                "pk": "XU__saint_saviour"
            },
            {
                "_id": "sark",
                "code": "sark",
                "name": "Sark",
                "pk": "XU__sark"
            },
            {
                "_id": "torteval",
                "code": "torteval",
                "name": "Torteval",
                "pk": "XU__torteval"
            },
            {
                "_id": "vale",
                "code": "vale",
                "name": "Vale",
                "pk": "XU__vale"
            }
        ],
        "pk": "XU"
    },
    {
        "_id": "GN",
        "code": "GN",
        "name": "Guinea",
        "states": [
            {
                "_id": "beyla",
                "code": "beyla",
                "name": "Beyla",
                "pk": "GN__beyla"
            },
            {
                "_id": "boffa",
                "code": "boffa",
                "name": "Boffa",
                "pk": "GN__boffa"
            },
            {
                "_id": "boke",
                "code": "boke",
                "name": "Boke",
                "pk": "GN__boke"
            },
            {
                "_id": "conakry",
                "code": "conakry",
                "name": "Conakry",
                "pk": "GN__conakry"
            },
            {
                "_id": "coyah",
                "code": "coyah",
                "name": "Coyah",
                "pk": "GN__coyah"
            },
            {
                "_id": "dabola",
                "code": "dabola",
                "name": "Dabola",
                "pk": "GN__dabola"
            },
            {
                "_id": "dalaba",
                "code": "dalaba",
                "name": "Dalaba",
                "pk": "GN__dalaba"
            },
            {
                "_id": "dinguiraye",
                "code": "dinguiraye",
                "name": "Dinguiraye",
                "pk": "GN__dinguiraye"
            },
            {
                "_id": "faranah",
                "code": "faranah",
                "name": "Faranah",
                "pk": "GN__faranah"
            },
            {
                "_id": "forecariah",
                "code": "forecariah",
                "name": "Forecariah",
                "pk": "GN__forecariah"
            },
            {
                "_id": "fria",
                "code": "fria",
                "name": "Fria",
                "pk": "GN__fria"
            },
            {
                "_id": "gaoual",
                "code": "gaoual",
                "name": "Gaoual",
                "pk": "GN__gaoual"
            },
            {
                "_id": "gueckedou",
                "code": "gueckedou",
                "name": "Gueckedou",
                "pk": "GN__gueckedou"
            },
            {
                "_id": "kankan",
                "code": "kankan",
                "name": "Kankan",
                "pk": "GN__kankan"
            },
            {
                "_id": "kerouane",
                "code": "kerouane",
                "name": "Kerouane",
                "pk": "GN__kerouane"
            },
            {
                "_id": "kindia",
                "code": "kindia",
                "name": "Kindia",
                "pk": "GN__kindia"
            },
            {
                "_id": "kissidougou",
                "code": "kissidougou",
                "name": "Kissidougou",
                "pk": "GN__kissidougou"
            },
            {
                "_id": "koubia",
                "code": "koubia",
                "name": "Koubia",
                "pk": "GN__koubia"
            },
            {
                "_id": "koundara",
                "code": "koundara",
                "name": "Koundara",
                "pk": "GN__koundara"
            },
            {
                "_id": "kouroussa",
                "code": "kouroussa",
                "name": "Kouroussa",
                "pk": "GN__kouroussa"
            },
            {
                "_id": "labe",
                "code": "labe",
                "name": "Labe",
                "pk": "GN__labe"
            },
            {
                "_id": "lola",
                "code": "lola",
                "name": "Lola",
                "pk": "GN__lola"
            },
            {
                "_id": "macenta",
                "code": "macenta",
                "name": "Macenta",
                "pk": "GN__macenta"
            },
            {
                "_id": "mali",
                "code": "mali",
                "name": "Mali",
                "pk": "GN__mali"
            },
            {
                "_id": "mamou",
                "code": "mamou",
                "name": "Mamou",
                "pk": "GN__mamou"
            },
            {
                "_id": "mandiana",
                "code": "mandiana",
                "name": "Mandiana",
                "pk": "GN__mandiana"
            },
            {
                "_id": "nzerekore",
                "code": "nzerekore",
                "name": "Nzerekore",
                "pk": "GN__nzerekore"
            },
            {
                "_id": "pita",
                "code": "pita",
                "name": "Pita",
                "pk": "GN__pita"
            },
            {
                "_id": "siguiri",
                "code": "siguiri",
                "name": "Siguiri",
                "pk": "GN__siguiri"
            },
            {
                "_id": "telimele",
                "code": "telimele",
                "name": "Telimele",
                "pk": "GN__telimele"
            },
            {
                "_id": "tougue",
                "code": "tougue",
                "name": "Tougue",
                "pk": "GN__tougue"
            },
            {
                "_id": "yomou",
                "code": "yomou",
                "name": "Yomou",
                "pk": "GN__yomou"
            }
        ],
        "pk": "GN"
    },
    {
        "_id": "GW",
        "code": "GW",
        "name": "Guinea-Bissau",
        "states": [
            {
                "_id": "bafata",
                "code": "bafata",
                "name": "Bafata",
                "pk": "GW__bafata"
            },
            {
                "_id": "bissau",
                "code": "bissau",
                "name": "Bissau",
                "pk": "GW__bissau"
            },
            {
                "_id": "bolama",
                "code": "bolama",
                "name": "Bolama",
                "pk": "GW__bolama"
            },
            {
                "_id": "cacheu",
                "code": "cacheu",
                "name": "Cacheu",
                "pk": "GW__cacheu"
            },
            {
                "_id": "gabu",
                "code": "gabu",
                "name": "Gabu",
                "pk": "GW__gabu"
            },
            {
                "_id": "oio",
                "code": "oio",
                "name": "Oio",
                "pk": "GW__oio"
            },
            {
                "_id": "quinara",
                "code": "quinara",
                "name": "Quinara",
                "pk": "GW__quinara"
            },
            {
                "_id": "tombali",
                "code": "tombali",
                "name": "Tombali",
                "pk": "GW__tombali"
            }
        ],
        "pk": "GW"
    },
    {
        "_id": "GY",
        "code": "GY",
        "name": "Guyana",
        "states": [
            {
                "_id": "barima-waini",
                "code": "barima-waini",
                "name": "Barima-Waini",
                "pk": "GY__barima-waini"
            },
            {
                "_id": "cuyuni-mazaruni",
                "code": "cuyuni-mazaruni",
                "name": "Cuyuni-Mazaruni",
                "pk": "GY__cuyuni-mazaruni"
            },
            {
                "_id": "demerara-mahaica",
                "code": "demerara-mahaica",
                "name": "Demerara-Mahaica",
                "pk": "GY__demerara-mahaica"
            },
            {
                "_id": "east_berbice-corentyne",
                "code": "east_berbice-corentyne",
                "name": "East Berbice-Corentyne",
                "pk": "GY__east_berbice-corentyne"
            },
            {
                "_id": "essequibo_islands-west_demerar",
                "code": "essequibo_islands-west_demerar",
                "name": "Essequibo Islands-West Demerar",
                "pk": "GY__essequibo_islands-west_demerar"
            },
            {
                "_id": "mahaica-berbice",
                "code": "mahaica-berbice",
                "name": "Mahaica-Berbice",
                "pk": "GY__mahaica-berbice"
            },
            {
                "_id": "pomeroon-supenaam",
                "code": "pomeroon-supenaam",
                "name": "Pomeroon-Supenaam",
                "pk": "GY__pomeroon-supenaam"
            },
            {
                "_id": "potaro-siparuni",
                "code": "potaro-siparuni",
                "name": "Potaro-Siparuni",
                "pk": "GY__potaro-siparuni"
            },
            {
                "_id": "upper_demerara-berbice",
                "code": "upper_demerara-berbice",
                "name": "Upper Demerara-Berbice",
                "pk": "GY__upper_demerara-berbice"
            },
            {
                "_id": "upper_takutu-upper_essequibo",
                "code": "upper_takutu-upper_essequibo",
                "name": "Upper Takutu-Upper Essequibo",
                "pk": "GY__upper_takutu-upper_essequibo"
            }
        ],
        "pk": "GY"
    },
    {
        "_id": "HT",
        "code": "HT",
        "name": "Haiti",
        "states": [
            {
                "_id": "artibonite",
                "code": "artibonite",
                "name": "Artibonite",
                "pk": "HT__artibonite"
            },
            {
                "_id": "centre",
                "code": "centre",
                "name": "Centre",
                "pk": "HT__centre"
            },
            {
                "_id": "grandanse",
                "code": "grandanse",
                "name": "GrandAnse",
                "pk": "HT__grandanse"
            },
            {
                "_id": "nord",
                "code": "nord",
                "name": "Nord",
                "pk": "HT__nord"
            },
            {
                "_id": "nord-est",
                "code": "nord-est",
                "name": "Nord-Est",
                "pk": "HT__nord-est"
            },
            {
                "_id": "nord-ouest",
                "code": "nord-ouest",
                "name": "Nord-Ouest",
                "pk": "HT__nord-ouest"
            },
            {
                "_id": "ouest",
                "code": "ouest",
                "name": "Ouest",
                "pk": "HT__ouest"
            },
            {
                "_id": "sud",
                "code": "sud",
                "name": "Sud",
                "pk": "HT__sud"
            },
            {
                "_id": "sud-est",
                "code": "sud-est",
                "name": "Sud-Est",
                "pk": "HT__sud-est"
            }
        ],
        "pk": "HT"
    },
    {
        "_id": "HM",
        "code": "HM",
        "name": "Heard and McDonald Islands",
        "states": [
            {
                "_id": "heard_and_mcdonald_islands",
                "code": "heard_and_mcdonald_islands",
                "name": "Heard and McDonald Islands",
                "pk": "HM__heard_and_mcdonald_islands"
            }
        ],
        "pk": "HM"
    },
    {
        "_id": "HN",
        "code": "HN",
        "name": "Honduras",
        "states": [
            {
                "_id": "atlantida",
                "code": "atlantida",
                "name": "Atlantida",
                "pk": "HN__atlantida"
            },
            {
                "_id": "choluteca",
                "code": "choluteca",
                "name": "Choluteca",
                "pk": "HN__choluteca"
            },
            {
                "_id": "colon",
                "code": "colon",
                "name": "Colon",
                "pk": "HN__colon"
            },
            {
                "_id": "comayagua",
                "code": "comayagua",
                "name": "Comayagua",
                "pk": "HN__comayagua"
            },
            {
                "_id": "copan",
                "code": "copan",
                "name": "Copan",
                "pk": "HN__copan"
            },
            {
                "_id": "cortes",
                "code": "cortes",
                "name": "Cortes",
                "pk": "HN__cortes"
            },
            {
                "_id": "distrito_central",
                "code": "distrito_central",
                "name": "Distrito Central",
                "pk": "HN__distrito_central"
            },
            {
                "_id": "el_paraiso",
                "code": "el_paraiso",
                "name": "El Paraiso",
                "pk": "HN__el_paraiso"
            },
            {
                "_id": "francisco_morazan",
                "code": "francisco_morazan",
                "name": "Francisco Morazan",
                "pk": "HN__francisco_morazan"
            },
            {
                "_id": "gracias_a_dios",
                "code": "gracias_a_dios",
                "name": "Gracias a Dios",
                "pk": "HN__gracias_a_dios"
            },
            {
                "_id": "intibuca",
                "code": "intibuca",
                "name": "Intibuca",
                "pk": "HN__intibuca"
            },
            {
                "_id": "islas_de_la_bahia",
                "code": "islas_de_la_bahia",
                "name": "Islas de la Bahia",
                "pk": "HN__islas_de_la_bahia"
            },
            {
                "_id": "la_paz",
                "code": "la_paz",
                "name": "La Paz",
                "pk": "HN__la_paz"
            },
            {
                "_id": "lempira",
                "code": "lempira",
                "name": "Lempira",
                "pk": "HN__lempira"
            },
            {
                "_id": "ocotepeque",
                "code": "ocotepeque",
                "name": "Ocotepeque",
                "pk": "HN__ocotepeque"
            },
            {
                "_id": "olancho",
                "code": "olancho",
                "name": "Olancho",
                "pk": "HN__olancho"
            },
            {
                "_id": "santa_barbara",
                "code": "santa_barbara",
                "name": "Santa Barbara",
                "pk": "HN__santa_barbara"
            },
            {
                "_id": "valle",
                "code": "valle",
                "name": "Valle",
                "pk": "HN__valle"
            },
            {
                "_id": "yoro",
                "code": "yoro",
                "name": "Yoro",
                "pk": "HN__yoro"
            }
        ],
        "pk": "HN"
    },
    {
        "_id": "HK",
        "code": "HK",
        "name": "Hong Kong S.A.R.",
        "states": [
            {
                "_id": "hong_kong",
                "code": "hong_kong",
                "name": "Hong Kong",
                "pk": "HK__hong_kong"
            }
        ],
        "pk": "HK"
    },
    {
        "_id": "HU",
        "code": "HU",
        "name": "Hungary",
        "states": [
            {
                "_id": "bacs-kiskun",
                "code": "bacs-kiskun",
                "name": "Bacs-Kiskun",
                "pk": "HU__bacs-kiskun"
            },
            {
                "_id": "baranya",
                "code": "baranya",
                "name": "Baranya",
                "pk": "HU__baranya"
            },
            {
                "_id": "bekes",
                "code": "bekes",
                "name": "Bekes",
                "pk": "HU__bekes"
            },
            {
                "_id": "borsod-abauj-zemplen",
                "code": "borsod-abauj-zemplen",
                "name": "Borsod-Abauj-Zemplen",
                "pk": "HU__borsod-abauj-zemplen"
            },
            {
                "_id": "budapest",
                "code": "budapest",
                "name": "Budapest",
                "pk": "HU__budapest"
            },
            {
                "_id": "csongrad",
                "code": "csongrad",
                "name": "Csongrad",
                "pk": "HU__csongrad"
            },
            {
                "_id": "fejer",
                "code": "fejer",
                "name": "Fejer",
                "pk": "HU__fejer"
            },
            {
                "_id": "gyor-moson-sopron",
                "code": "gyor-moson-sopron",
                "name": "Gyor-Moson-Sopron",
                "pk": "HU__gyor-moson-sopron"
            },
            {
                "_id": "hajdu-bihar",
                "code": "hajdu-bihar",
                "name": "Hajdu-Bihar",
                "pk": "HU__hajdu-bihar"
            },
            {
                "_id": "heves",
                "code": "heves",
                "name": "Heves",
                "pk": "HU__heves"
            },
            {
                "_id": "jasz-nagykun-szolnok",
                "code": "jasz-nagykun-szolnok",
                "name": "Jasz-Nagykun-Szolnok",
                "pk": "HU__jasz-nagykun-szolnok"
            },
            {
                "_id": "komarom-esztergom",
                "code": "komarom-esztergom",
                "name": "Komarom-Esztergom",
                "pk": "HU__komarom-esztergom"
            },
            {
                "_id": "nograd",
                "code": "nograd",
                "name": "Nograd",
                "pk": "HU__nograd"
            },
            {
                "_id": "pest",
                "code": "pest",
                "name": "Pest",
                "pk": "HU__pest"
            },
            {
                "_id": "somogy",
                "code": "somogy",
                "name": "Somogy",
                "pk": "HU__somogy"
            },
            {
                "_id": "szabolcs-szatmar-bereg",
                "code": "szabolcs-szatmar-bereg",
                "name": "Szabolcs-Szatmar-Bereg",
                "pk": "HU__szabolcs-szatmar-bereg"
            },
            {
                "_id": "tolna",
                "code": "tolna",
                "name": "Tolna",
                "pk": "HU__tolna"
            },
            {
                "_id": "vas",
                "code": "vas",
                "name": "Vas",
                "pk": "HU__vas"
            },
            {
                "_id": "veszprem",
                "code": "veszprem",
                "name": "Veszprem",
                "pk": "HU__veszprem"
            },
            {
                "_id": "zala",
                "code": "zala",
                "name": "Zala",
                "pk": "HU__zala"
            }
        ],
        "pk": "HU"
    },
    {
        "_id": "IS",
        "code": "IS",
        "name": "Iceland",
        "states": [
            {
                "_id": "austurland",
                "code": "austurland",
                "name": "Austurland",
                "pk": "IS__austurland"
            },
            {
                "_id": "gullbringusysla",
                "code": "gullbringusysla",
                "name": "Gullbringusysla",
                "pk": "IS__gullbringusysla"
            },
            {
                "_id": "hofu_borgarsva_i",
                "code": "hofu_borgarsva_i",
                "name": "Hofu borgarsva i",
                "pk": "IS__hofu_borgarsva_i"
            },
            {
                "_id": "nor_urland_eystra",
                "code": "nor_urland_eystra",
                "name": "Nor urland eystra",
                "pk": "IS__nor_urland_eystra"
            },
            {
                "_id": "nor_urland_vestra",
                "code": "nor_urland_vestra",
                "name": "Nor urland vestra",
                "pk": "IS__nor_urland_vestra"
            },
            {
                "_id": "su_urland",
                "code": "su_urland",
                "name": "Su urland",
                "pk": "IS__su_urland"
            },
            {
                "_id": "su_urnes",
                "code": "su_urnes",
                "name": "Su urnes",
                "pk": "IS__su_urnes"
            },
            {
                "_id": "vestfir_ir",
                "code": "vestfir_ir",
                "name": "Vestfir ir",
                "pk": "IS__vestfir_ir"
            },
            {
                "_id": "vesturland",
                "code": "vesturland",
                "name": "Vesturland",
                "pk": "IS__vesturland"
            }
        ],
        "pk": "IS"
    },
    {
        "_id": "IN",
        "code": "IN",
        "name": "India",
        "states": [
            {
                "_id": "andaman_and_nicobar_islands",
                "code": "andaman_and_nicobar_islands",
                "name": "Andaman and Nicobar Islands",
                "pk": "IN__andaman_and_nicobar_islands"
            },
            {
                "_id": "andhra_pradesh",
                "code": "andhra_pradesh",
                "name": "Andhra Pradesh",
                "pk": "IN__andhra_pradesh"
            },
            {
                "_id": "arunachal_pradesh",
                "code": "arunachal_pradesh",
                "name": "Arunachal Pradesh",
                "pk": "IN__arunachal_pradesh"
            },
            {
                "_id": "assam",
                "code": "assam",
                "name": "Assam",
                "pk": "IN__assam"
            },
            {
                "_id": "bihar",
                "code": "bihar",
                "name": "Bihar",
                "pk": "IN__bihar"
            },
            {
                "_id": "chandigarh",
                "code": "chandigarh",
                "name": "Chandigarh",
                "pk": "IN__chandigarh"
            },
            {
                "_id": "chhattisgarh",
                "code": "chhattisgarh",
                "name": "Chhattisgarh",
                "pk": "IN__chhattisgarh"
            },
            {
                "_id": "dadra_and_nagar_haveli",
                "code": "dadra_and_nagar_haveli",
                "name": "Dadra and Nagar Haveli",
                "pk": "IN__dadra_and_nagar_haveli"
            },
            {
                "_id": "daman_and_diu",
                "code": "daman_and_diu",
                "name": "Daman and Diu",
                "pk": "IN__daman_and_diu"
            },
            {
                "_id": "delhi",
                "code": "delhi",
                "name": "Delhi",
                "pk": "IN__delhi"
            },
            {
                "_id": "goa",
                "code": "goa",
                "name": "Goa",
                "pk": "IN__goa"
            },
            {
                "_id": "gujarat",
                "code": "gujarat",
                "name": "Gujarat",
                "pk": "IN__gujarat"
            },
            {
                "_id": "haryana",
                "code": "haryana",
                "name": "Haryana",
                "pk": "IN__haryana"
            },
            {
                "_id": "himachal_pradesh",
                "code": "himachal_pradesh",
                "name": "Himachal Pradesh",
                "pk": "IN__himachal_pradesh"
            },
            {
                "_id": "jammu_and_kashmir",
                "code": "jammu_and_kashmir",
                "name": "Jammu and Kashmir",
                "pk": "IN__jammu_and_kashmir"
            },
            {
                "_id": "jharkhand",
                "code": "jharkhand",
                "name": "Jharkhand",
                "pk": "IN__jharkhand"
            },
            {
                "_id": "karnataka",
                "code": "karnataka",
                "name": "Karnataka",
                "pk": "IN__karnataka"
            },
            {
                "_id": "kenmore",
                "code": "kenmore",
                "name": "Kenmore",
                "pk": "IN__kenmore"
            },
            {
                "_id": "kerala",
                "code": "kerala",
                "name": "Kerala",
                "pk": "IN__kerala"
            },
            {
                "_id": "lakshadweep",
                "code": "lakshadweep",
                "name": "Lakshadweep",
                "pk": "IN__lakshadweep"
            },
            {
                "_id": "madhya_pradesh",
                "code": "madhya_pradesh",
                "name": "Madhya Pradesh",
                "pk": "IN__madhya_pradesh"
            },
            {
                "_id": "maharashtra",
                "code": "maharashtra",
                "name": "Maharashtra",
                "pk": "IN__maharashtra"
            },
            {
                "_id": "manipur",
                "code": "manipur",
                "name": "Manipur",
                "pk": "IN__manipur"
            },
            {
                "_id": "meghalaya",
                "code": "meghalaya",
                "name": "Meghalaya",
                "pk": "IN__meghalaya"
            },
            {
                "_id": "mizoram",
                "code": "mizoram",
                "name": "Mizoram",
                "pk": "IN__mizoram"
            },
            {
                "_id": "nagaland",
                "code": "nagaland",
                "name": "Nagaland",
                "pk": "IN__nagaland"
            },
            {
                "_id": "narora",
                "code": "narora",
                "name": "Narora",
                "pk": "IN__narora"
            },
            {
                "_id": "natwar",
                "code": "natwar",
                "name": "Natwar",
                "pk": "IN__natwar"
            },
            {
                "_id": "odisha",
                "code": "odisha",
                "name": "Odisha",
                "pk": "IN__odisha"
            },
            {
                "_id": "paschim_medinipur",
                "code": "paschim_medinipur",
                "name": "Paschim Medinipur",
                "pk": "IN__paschim_medinipur"
            },
            {
                "_id": "pondicherry",
                "code": "pondicherry",
                "name": "Pondicherry",
                "pk": "IN__pondicherry"
            },
            {
                "_id": "punjab",
                "code": "punjab",
                "name": "Punjab",
                "pk": "IN__punjab"
            },
            {
                "_id": "rajasthan",
                "code": "rajasthan",
                "name": "Rajasthan",
                "pk": "IN__rajasthan"
            },
            {
                "_id": "sikkim",
                "code": "sikkim",
                "name": "Sikkim",
                "pk": "IN__sikkim"
            },
            {
                "_id": "tamil_nadu",
                "code": "tamil_nadu",
                "name": "Tamil Nadu",
                "pk": "IN__tamil_nadu"
            },
            {
                "_id": "telangana",
                "code": "telangana",
                "name": "Telangana",
                "pk": "IN__telangana"
            },
            {
                "_id": "tripura",
                "code": "tripura",
                "name": "Tripura",
                "pk": "IN__tripura"
            },
            {
                "_id": "uttar_pradesh",
                "code": "uttar_pradesh",
                "name": "Uttar Pradesh",
                "pk": "IN__uttar_pradesh"
            },
            {
                "_id": "uttarakhand",
                "code": "uttarakhand",
                "name": "Uttarakhand",
                "pk": "IN__uttarakhand"
            },
            {
                "_id": "vaishali",
                "code": "vaishali",
                "name": "Vaishali",
                "pk": "IN__vaishali"
            },
            {
                "_id": "west_bengal",
                "code": "west_bengal",
                "name": "West Bengal",
                "pk": "IN__west_bengal"
            }
        ],
        "pk": "IN"
    },
    {
        "_id": "ID",
        "code": "ID",
        "name": "Indonesia",
        "states": [
            {
                "_id": "aceh",
                "code": "aceh",
                "name": "Aceh",
                "pk": "ID__aceh"
            },
            {
                "_id": "bali",
                "code": "bali",
                "name": "Bali",
                "pk": "ID__bali"
            },
            {
                "_id": "bangka-belitung",
                "code": "bangka-belitung",
                "name": "Bangka-Belitung",
                "pk": "ID__bangka-belitung"
            },
            {
                "_id": "banten",
                "code": "banten",
                "name": "Banten",
                "pk": "ID__banten"
            },
            {
                "_id": "bengkulu",
                "code": "bengkulu",
                "name": "Bengkulu",
                "pk": "ID__bengkulu"
            },
            {
                "_id": "gandaria",
                "code": "gandaria",
                "name": "Gandaria",
                "pk": "ID__gandaria"
            },
            {
                "_id": "gorontalo",
                "code": "gorontalo",
                "name": "Gorontalo",
                "pk": "ID__gorontalo"
            },
            {
                "_id": "jakarta",
                "code": "jakarta",
                "name": "Jakarta",
                "pk": "ID__jakarta"
            },
            {
                "_id": "jambi",
                "code": "jambi",
                "name": "Jambi",
                "pk": "ID__jambi"
            },
            {
                "_id": "jawa_barat",
                "code": "jawa_barat",
                "name": "Jawa Barat",
                "pk": "ID__jawa_barat"
            },
            {
                "_id": "jawa_tengah",
                "code": "jawa_tengah",
                "name": "Jawa Tengah",
                "pk": "ID__jawa_tengah"
            },
            {
                "_id": "jawa_timur",
                "code": "jawa_timur",
                "name": "Jawa Timur",
                "pk": "ID__jawa_timur"
            },
            {
                "_id": "kalimantan_barat",
                "code": "kalimantan_barat",
                "name": "Kalimantan Barat",
                "pk": "ID__kalimantan_barat"
            },
            {
                "_id": "kalimantan_selatan",
                "code": "kalimantan_selatan",
                "name": "Kalimantan Selatan",
                "pk": "ID__kalimantan_selatan"
            },
            {
                "_id": "kalimantan_tengah",
                "code": "kalimantan_tengah",
                "name": "Kalimantan Tengah",
                "pk": "ID__kalimantan_tengah"
            },
            {
                "_id": "kalimantan_timur",
                "code": "kalimantan_timur",
                "name": "Kalimantan Timur",
                "pk": "ID__kalimantan_timur"
            },
            {
                "_id": "kendal",
                "code": "kendal",
                "name": "Kendal",
                "pk": "ID__kendal"
            },
            {
                "_id": "lampung",
                "code": "lampung",
                "name": "Lampung",
                "pk": "ID__lampung"
            },
            {
                "_id": "maluku",
                "code": "maluku",
                "name": "Maluku",
                "pk": "ID__maluku"
            },
            {
                "_id": "maluku_utara",
                "code": "maluku_utara",
                "name": "Maluku Utara",
                "pk": "ID__maluku_utara"
            },
            {
                "_id": "nusa_tenggara_barat",
                "code": "nusa_tenggara_barat",
                "name": "Nusa Tenggara Barat",
                "pk": "ID__nusa_tenggara_barat"
            },
            {
                "_id": "nusa_tenggara_timur",
                "code": "nusa_tenggara_timur",
                "name": "Nusa Tenggara Timur",
                "pk": "ID__nusa_tenggara_timur"
            },
            {
                "_id": "papua",
                "code": "papua",
                "name": "Papua",
                "pk": "ID__papua"
            },
            {
                "_id": "riau",
                "code": "riau",
                "name": "Riau",
                "pk": "ID__riau"
            },
            {
                "_id": "riau_kepulauan",
                "code": "riau_kepulauan",
                "name": "Riau Kepulauan",
                "pk": "ID__riau_kepulauan"
            },
            {
                "_id": "solo",
                "code": "solo",
                "name": "Solo",
                "pk": "ID__solo"
            },
            {
                "_id": "sulawesi_selatan",
                "code": "sulawesi_selatan",
                "name": "Sulawesi Selatan",
                "pk": "ID__sulawesi_selatan"
            },
            {
                "_id": "sulawesi_tengah",
                "code": "sulawesi_tengah",
                "name": "Sulawesi Tengah",
                "pk": "ID__sulawesi_tengah"
            },
            {
                "_id": "sulawesi_tenggara",
                "code": "sulawesi_tenggara",
                "name": "Sulawesi Tenggara",
                "pk": "ID__sulawesi_tenggara"
            },
            {
                "_id": "sulawesi_utara",
                "code": "sulawesi_utara",
                "name": "Sulawesi Utara",
                "pk": "ID__sulawesi_utara"
            },
            {
                "_id": "sumatera_barat",
                "code": "sumatera_barat",
                "name": "Sumatera Barat",
                "pk": "ID__sumatera_barat"
            },
            {
                "_id": "sumatera_selatan",
                "code": "sumatera_selatan",
                "name": "Sumatera Selatan",
                "pk": "ID__sumatera_selatan"
            },
            {
                "_id": "sumatera_utara",
                "code": "sumatera_utara",
                "name": "Sumatera Utara",
                "pk": "ID__sumatera_utara"
            },
            {
                "_id": "yogyakarta",
                "code": "yogyakarta",
                "name": "Yogyakarta",
                "pk": "ID__yogyakarta"
            }
        ],
        "pk": "ID"
    },
    {
        "_id": "IR",
        "code": "IR",
        "name": "Iran",
        "states": [
            {
                "_id": "ardabil",
                "code": "ardabil",
                "name": "Ardabil",
                "pk": "IR__ardabil"
            },
            {
                "_id": "azarbayjan-e_bakhtari",
                "code": "azarbayjan-e_bakhtari",
                "name": "Azarbayjan-e Bakhtari",
                "pk": "IR__azarbayjan-e_bakhtari"
            },
            {
                "_id": "azarbayjan-e_khavari",
                "code": "azarbayjan-e_khavari",
                "name": "Azarbayjan-e Khavari",
                "pk": "IR__azarbayjan-e_khavari"
            },
            {
                "_id": "bushehr",
                "code": "bushehr",
                "name": "Bushehr",
                "pk": "IR__bushehr"
            },
            {
                "_id": "chahar_mahal-e_bakhtiari",
                "code": "chahar_mahal-e_bakhtiari",
                "name": "Chahar Mahal-e Bakhtiari",
                "pk": "IR__chahar_mahal-e_bakhtiari"
            },
            {
                "_id": "esfahan",
                "code": "esfahan",
                "name": "Esfahan",
                "pk": "IR__esfahan"
            },
            {
                "_id": "fars",
                "code": "fars",
                "name": "Fars",
                "pk": "IR__fars"
            },
            {
                "_id": "gilan",
                "code": "gilan",
                "name": "Gilan",
                "pk": "IR__gilan"
            },
            {
                "_id": "golestan",
                "code": "golestan",
                "name": "Golestan",
                "pk": "IR__golestan"
            },
            {
                "_id": "hamadan",
                "code": "hamadan",
                "name": "Hamadan",
                "pk": "IR__hamadan"
            },
            {
                "_id": "hormozgan",
                "code": "hormozgan",
                "name": "Hormozgan",
                "pk": "IR__hormozgan"
            },
            {
                "_id": "ilam",
                "code": "ilam",
                "name": "Ilam",
                "pk": "IR__ilam"
            },
            {
                "_id": "kerman",
                "code": "kerman",
                "name": "Kerman",
                "pk": "IR__kerman"
            },
            {
                "_id": "kermanshah",
                "code": "kermanshah",
                "name": "Kermanshah",
                "pk": "IR__kermanshah"
            },
            {
                "_id": "khorasan",
                "code": "khorasan",
                "name": "Khorasan",
                "pk": "IR__khorasan"
            },
            {
                "_id": "khuzestan",
                "code": "khuzestan",
                "name": "Khuzestan",
                "pk": "IR__khuzestan"
            },
            {
                "_id": "kohgiluyeh-e_boyerahmad",
                "code": "kohgiluyeh-e_boyerahmad",
                "name": "Kohgiluyeh-e Boyerahmad",
                "pk": "IR__kohgiluyeh-e_boyerahmad"
            },
            {
                "_id": "kordestan",
                "code": "kordestan",
                "name": "Kordestan",
                "pk": "IR__kordestan"
            },
            {
                "_id": "lorestan",
                "code": "lorestan",
                "name": "Lorestan",
                "pk": "IR__lorestan"
            },
            {
                "_id": "markazi",
                "code": "markazi",
                "name": "Markazi",
                "pk": "IR__markazi"
            },
            {
                "_id": "mazandaran",
                "code": "mazandaran",
                "name": "Mazandaran",
                "pk": "IR__mazandaran"
            },
            {
                "_id": "ostan-e_esfahan",
                "code": "ostan-e_esfahan",
                "name": "Ostan-e Esfahan",
                "pk": "IR__ostan-e_esfahan"
            },
            {
                "_id": "qazvin",
                "code": "qazvin",
                "name": "Qazvin",
                "pk": "IR__qazvin"
            },
            {
                "_id": "qom",
                "code": "qom",
                "name": "Qom",
                "pk": "IR__qom"
            },
            {
                "_id": "semnan",
                "code": "semnan",
                "name": "Semnan",
                "pk": "IR__semnan"
            },
            {
                "_id": "sistan-e_baluchestan",
                "code": "sistan-e_baluchestan",
                "name": "Sistan-e Baluchestan",
                "pk": "IR__sistan-e_baluchestan"
            },
            {
                "_id": "tehran",
                "code": "tehran",
                "name": "Tehran",
                "pk": "IR__tehran"
            },
            {
                "_id": "yazd",
                "code": "yazd",
                "name": "Yazd",
                "pk": "IR__yazd"
            },
            {
                "_id": "zanjan",
                "code": "zanjan",
                "name": "Zanjan",
                "pk": "IR__zanjan"
            }
        ],
        "pk": "IR"
    },
    {
        "_id": "IQ",
        "code": "IQ",
        "name": "Iraq",
        "states": [
            {
                "_id": "babil",
                "code": "babil",
                "name": "Babil",
                "pk": "IQ__babil"
            },
            {
                "_id": "baghdad",
                "code": "baghdad",
                "name": "Baghdad",
                "pk": "IQ__baghdad"
            },
            {
                "_id": "dahuk",
                "code": "dahuk",
                "name": "Dahuk",
                "pk": "IQ__dahuk"
            },
            {
                "_id": "dhi_qar",
                "code": "dhi_qar",
                "name": "Dhi Qar",
                "pk": "IQ__dhi_qar"
            },
            {
                "_id": "diyala",
                "code": "diyala",
                "name": "Diyala",
                "pk": "IQ__diyala"
            },
            {
                "_id": "erbil",
                "code": "erbil",
                "name": "Erbil",
                "pk": "IQ__erbil"
            },
            {
                "_id": "irbil",
                "code": "irbil",
                "name": "Irbil",
                "pk": "IQ__irbil"
            },
            {
                "_id": "karbala",
                "code": "karbala",
                "name": "Karbala",
                "pk": "IQ__karbala"
            },
            {
                "_id": "kurdistan",
                "code": "kurdistan",
                "name": "Kurdistan",
                "pk": "IQ__kurdistan"
            },
            {
                "_id": "maysan",
                "code": "maysan",
                "name": "Maysan",
                "pk": "IQ__maysan"
            },
            {
                "_id": "ninawa",
                "code": "ninawa",
                "name": "Ninawa",
                "pk": "IQ__ninawa"
            },
            {
                "_id": "salah-ad-din",
                "code": "salah-ad-din",
                "name": "Salah-ad-Din",
                "pk": "IQ__salah-ad-din"
            },
            {
                "_id": "wasit",
                "code": "wasit",
                "name": "Wasit",
                "pk": "IQ__wasit"
            },
            {
                "_id": "al-anbar",
                "code": "al-anbar",
                "name": "al-Anbar",
                "pk": "IQ__al-anbar"
            },
            {
                "_id": "al-basrah",
                "code": "al-basrah",
                "name": "al-Basrah",
                "pk": "IQ__al-basrah"
            },
            {
                "_id": "al-muthanna",
                "code": "al-muthanna",
                "name": "al-Muthanna",
                "pk": "IQ__al-muthanna"
            },
            {
                "_id": "al-qadisiyah",
                "code": "al-qadisiyah",
                "name": "al-Qadisiyah",
                "pk": "IQ__al-qadisiyah"
            },
            {
                "_id": "an-najaf",
                "code": "an-najaf",
                "name": "an-Najaf",
                "pk": "IQ__an-najaf"
            },
            {
                "_id": "as-sulaymaniyah",
                "code": "as-sulaymaniyah",
                "name": "as-Sulaymaniyah",
                "pk": "IQ__as-sulaymaniyah"
            },
            {
                "_id": "at-tamim",
                "code": "at-tamim",
                "name": "at-Tamim",
                "pk": "IQ__at-tamim"
            }
        ],
        "pk": "IQ"
    },
    {
        "_id": "IE",
        "code": "IE",
        "name": "Ireland",
        "states": [
            {
                "_id": "armagh",
                "code": "armagh",
                "name": "Armagh",
                "pk": "IE__armagh"
            },
            {
                "_id": "carlow",
                "code": "carlow",
                "name": "Carlow",
                "pk": "IE__carlow"
            },
            {
                "_id": "cavan",
                "code": "cavan",
                "name": "Cavan",
                "pk": "IE__cavan"
            },
            {
                "_id": "clare",
                "code": "clare",
                "name": "Clare",
                "pk": "IE__clare"
            },
            {
                "_id": "cork",
                "code": "cork",
                "name": "Cork",
                "pk": "IE__cork"
            },
            {
                "_id": "donegal",
                "code": "donegal",
                "name": "Donegal",
                "pk": "IE__donegal"
            },
            {
                "_id": "dublin",
                "code": "dublin",
                "name": "Dublin",
                "pk": "IE__dublin"
            },
            {
                "_id": "galway",
                "code": "galway",
                "name": "Galway",
                "pk": "IE__galway"
            },
            {
                "_id": "kerry",
                "code": "kerry",
                "name": "Kerry",
                "pk": "IE__kerry"
            },
            {
                "_id": "kildare",
                "code": "kildare",
                "name": "Kildare",
                "pk": "IE__kildare"
            },
            {
                "_id": "kilkenny",
                "code": "kilkenny",
                "name": "Kilkenny",
                "pk": "IE__kilkenny"
            },
            {
                "_id": "laois",
                "code": "laois",
                "name": "Laois",
                "pk": "IE__laois"
            },
            {
                "_id": "leinster",
                "code": "leinster",
                "name": "Leinster",
                "pk": "IE__leinster"
            },
            {
                "_id": "leitrim",
                "code": "leitrim",
                "name": "Leitrim",
                "pk": "IE__leitrim"
            },
            {
                "_id": "limerick",
                "code": "limerick",
                "name": "Limerick",
                "pk": "IE__limerick"
            },
            {
                "_id": "loch_garman",
                "code": "loch_garman",
                "name": "Loch Garman",
                "pk": "IE__loch_garman"
            },
            {
                "_id": "longford",
                "code": "longford",
                "name": "Longford",
                "pk": "IE__longford"
            },
            {
                "_id": "louth",
                "code": "louth",
                "name": "Louth",
                "pk": "IE__louth"
            },
            {
                "_id": "mayo",
                "code": "mayo",
                "name": "Mayo",
                "pk": "IE__mayo"
            },
            {
                "_id": "meath",
                "code": "meath",
                "name": "Meath",
                "pk": "IE__meath"
            },
            {
                "_id": "monaghan",
                "code": "monaghan",
                "name": "Monaghan",
                "pk": "IE__monaghan"
            },
            {
                "_id": "offaly",
                "code": "offaly",
                "name": "Offaly",
                "pk": "IE__offaly"
            },
            {
                "_id": "roscommon",
                "code": "roscommon",
                "name": "Roscommon",
                "pk": "IE__roscommon"
            },
            {
                "_id": "sligo",
                "code": "sligo",
                "name": "Sligo",
                "pk": "IE__sligo"
            },
            {
                "_id": "tipperary_north_riding",
                "code": "tipperary_north_riding",
                "name": "Tipperary North Riding",
                "pk": "IE__tipperary_north_riding"
            },
            {
                "_id": "tipperary_south_riding",
                "code": "tipperary_south_riding",
                "name": "Tipperary South Riding",
                "pk": "IE__tipperary_south_riding"
            },
            {
                "_id": "ulster",
                "code": "ulster",
                "name": "Ulster",
                "pk": "IE__ulster"
            },
            {
                "_id": "waterford",
                "code": "waterford",
                "name": "Waterford",
                "pk": "IE__waterford"
            },
            {
                "_id": "westmeath",
                "code": "westmeath",
                "name": "Westmeath",
                "pk": "IE__westmeath"
            },
            {
                "_id": "wexford",
                "code": "wexford",
                "name": "Wexford",
                "pk": "IE__wexford"
            },
            {
                "_id": "wicklow",
                "code": "wicklow",
                "name": "Wicklow",
                "pk": "IE__wicklow"
            }
        ],
        "only_shipping": true,
        "pk": "IE"
    },
    {
        "_id": "IL",
        "code": "IL",
        "name": "Israel",
        "states": [
            {
                "_id": "beit_hanania",
                "code": "beit_hanania",
                "name": "Beit Hanania",
                "pk": "IL__beit_hanania"
            },
            {
                "_id": "ben_gurion_airport",
                "code": "ben_gurion_airport",
                "name": "Ben Gurion Airport",
                "pk": "IL__ben_gurion_airport"
            },
            {
                "_id": "bethlehem",
                "code": "bethlehem",
                "name": "Bethlehem",
                "pk": "IL__bethlehem"
            },
            {
                "_id": "caesarea",
                "code": "caesarea",
                "name": "Caesarea",
                "pk": "IL__caesarea"
            },
            {
                "_id": "centre",
                "code": "centre",
                "name": "Centre",
                "pk": "IL__centre"
            },
            {
                "_id": "gaza",
                "code": "gaza",
                "name": "Gaza",
                "pk": "IL__gaza"
            },
            {
                "_id": "hadaron",
                "code": "hadaron",
                "name": "Hadaron",
                "pk": "IL__hadaron"
            },
            {
                "_id": "haifa_district",
                "code": "haifa_district",
                "name": "Haifa District",
                "pk": "IL__haifa_district"
            },
            {
                "_id": "hamerkaz",
                "code": "hamerkaz",
                "name": "Hamerkaz",
                "pk": "IL__hamerkaz"
            },
            {
                "_id": "hazafon",
                "code": "hazafon",
                "name": "Hazafon",
                "pk": "IL__hazafon"
            },
            {
                "_id": "hebron",
                "code": "hebron",
                "name": "Hebron",
                "pk": "IL__hebron"
            },
            {
                "_id": "jaffa",
                "code": "jaffa",
                "name": "Jaffa",
                "pk": "IL__jaffa"
            },
            {
                "_id": "jerusalem",
                "code": "jerusalem",
                "name": "Jerusalem",
                "pk": "IL__jerusalem"
            },
            {
                "_id": "khefa",
                "code": "khefa",
                "name": "Khefa",
                "pk": "IL__khefa"
            },
            {
                "_id": "kiryat_yam",
                "code": "kiryat_yam",
                "name": "Kiryat Yam",
                "pk": "IL__kiryat_yam"
            },
            {
                "_id": "lower_galilee",
                "code": "lower_galilee",
                "name": "Lower Galilee",
                "pk": "IL__lower_galilee"
            },
            {
                "_id": "qalqilya",
                "code": "qalqilya",
                "name": "Qalqilya",
                "pk": "IL__qalqilya"
            },
            {
                "_id": "talme_elazar",
                "code": "talme_elazar",
                "name": "Talme Elazar",
                "pk": "IL__talme_elazar"
            },
            {
                "_id": "tel_aviv",
                "code": "tel_aviv",
                "name": "Tel Aviv",
                "pk": "IL__tel_aviv"
            },
            {
                "_id": "tsafon",
                "code": "tsafon",
                "name": "Tsafon",
                "pk": "IL__tsafon"
            },
            {
                "_id": "umm_el_fahem",
                "code": "umm_el_fahem",
                "name": "Umm El Fahem",
                "pk": "IL__umm_el_fahem"
            },
            {
                "_id": "yerushalayim",
                "code": "yerushalayim",
                "name": "Yerushalayim",
                "pk": "IL__yerushalayim"
            }
        ],
        "pk": "IL"
    },
    {
        "_id": "IT",
        "code": "IT",
        "name": "Italy",
        "states": [
            {
                "_id": "abruzzi",
                "code": "abruzzi",
                "name": "Abruzzi",
                "pk": "IT__abruzzi"
            },
            {
                "_id": "abruzzo",
                "code": "abruzzo",
                "name": "Abruzzo",
                "pk": "IT__abruzzo"
            },
            {
                "_id": "agrigento",
                "code": "agrigento",
                "name": "Agrigento",
                "pk": "IT__agrigento"
            },
            {
                "_id": "alessandria",
                "code": "alessandria",
                "name": "Alessandria",
                "pk": "IT__alessandria"
            },
            {
                "_id": "ancona",
                "code": "ancona",
                "name": "Ancona",
                "pk": "IT__ancona"
            },
            {
                "_id": "arezzo",
                "code": "arezzo",
                "name": "Arezzo",
                "pk": "IT__arezzo"
            },
            {
                "_id": "ascoli_piceno",
                "code": "ascoli_piceno",
                "name": "Ascoli Piceno",
                "pk": "IT__ascoli_piceno"
            },
            {
                "_id": "asti",
                "code": "asti",
                "name": "Asti",
                "pk": "IT__asti"
            },
            {
                "_id": "avellino",
                "code": "avellino",
                "name": "Avellino",
                "pk": "IT__avellino"
            },
            {
                "_id": "bari",
                "code": "bari",
                "name": "Bari",
                "pk": "IT__bari"
            },
            {
                "_id": "basilicata",
                "code": "basilicata",
                "name": "Basilicata",
                "pk": "IT__basilicata"
            },
            {
                "_id": "belluno",
                "code": "belluno",
                "name": "Belluno",
                "pk": "IT__belluno"
            },
            {
                "_id": "benevento",
                "code": "benevento",
                "name": "Benevento",
                "pk": "IT__benevento"
            },
            {
                "_id": "bergamo",
                "code": "bergamo",
                "name": "Bergamo",
                "pk": "IT__bergamo"
            },
            {
                "_id": "biella",
                "code": "biella",
                "name": "Biella",
                "pk": "IT__biella"
            },
            {
                "_id": "bologna",
                "code": "bologna",
                "name": "Bologna",
                "pk": "IT__bologna"
            },
            {
                "_id": "bolzano",
                "code": "bolzano",
                "name": "Bolzano",
                "pk": "IT__bolzano"
            },
            {
                "_id": "brescia",
                "code": "brescia",
                "name": "Brescia",
                "pk": "IT__brescia"
            },
            {
                "_id": "brindisi",
                "code": "brindisi",
                "name": "Brindisi",
                "pk": "IT__brindisi"
            },
            {
                "_id": "calabria",
                "code": "calabria",
                "name": "Calabria",
                "pk": "IT__calabria"
            },
            {
                "_id": "campania",
                "code": "campania",
                "name": "Campania",
                "pk": "IT__campania"
            },
            {
                "_id": "cartoceto",
                "code": "cartoceto",
                "name": "Cartoceto",
                "pk": "IT__cartoceto"
            },
            {
                "_id": "caserta",
                "code": "caserta",
                "name": "Caserta",
                "pk": "IT__caserta"
            },
            {
                "_id": "catania",
                "code": "catania",
                "name": "Catania",
                "pk": "IT__catania"
            },
            {
                "_id": "chieti",
                "code": "chieti",
                "name": "Chieti",
                "pk": "IT__chieti"
            },
            {
                "_id": "como",
                "code": "como",
                "name": "Como",
                "pk": "IT__como"
            },
            {
                "_id": "cosenza",
                "code": "cosenza",
                "name": "Cosenza",
                "pk": "IT__cosenza"
            },
            {
                "_id": "cremona",
                "code": "cremona",
                "name": "Cremona",
                "pk": "IT__cremona"
            },
            {
                "_id": "cuneo",
                "code": "cuneo",
                "name": "Cuneo",
                "pk": "IT__cuneo"
            },
            {
                "_id": "emilia-romagna",
                "code": "emilia-romagna",
                "name": "Emilia-Romagna",
                "pk": "IT__emilia-romagna"
            },
            {
                "_id": "ferrara",
                "code": "ferrara",
                "name": "Ferrara",
                "pk": "IT__ferrara"
            },
            {
                "_id": "firenze",
                "code": "firenze",
                "name": "Firenze",
                "pk": "IT__firenze"
            },
            {
                "_id": "florence",
                "code": "florence",
                "name": "Florence",
                "pk": "IT__florence"
            },
            {
                "_id": "forli-cesena_",
                "code": "forli-cesena_",
                "name": "Forli-Cesena ",
                "pk": "IT__forli-cesena_"
            },
            {
                "_id": "friuli-venezia_giulia",
                "code": "friuli-venezia_giulia",
                "name": "Friuli-Venezia Giulia",
                "pk": "IT__friuli-venezia_giulia"
            },
            {
                "_id": "frosinone",
                "code": "frosinone",
                "name": "Frosinone",
                "pk": "IT__frosinone"
            },
            {
                "_id": "genoa",
                "code": "genoa",
                "name": "Genoa",
                "pk": "IT__genoa"
            },
            {
                "_id": "gorizia",
                "code": "gorizia",
                "name": "Gorizia",
                "pk": "IT__gorizia"
            },
            {
                "_id": "laquila",
                "code": "laquila",
                "name": "LAquila",
                "pk": "IT__laquila"
            },
            {
                "_id": "lazio",
                "code": "lazio",
                "name": "Lazio",
                "pk": "IT__lazio"
            },
            {
                "_id": "lecce",
                "code": "lecce",
                "name": "Lecce",
                "pk": "IT__lecce"
            },
            {
                "_id": "lecco",
                "code": "lecco",
                "name": "Lecco",
                "pk": "IT__lecco"
            },
            {
                "_id": "lecco_province",
                "code": "lecco_province",
                "name": "Lecco Province",
                "pk": "IT__lecco_province"
            },
            {
                "_id": "liguria",
                "code": "liguria",
                "name": "Liguria",
                "pk": "IT__liguria"
            },
            {
                "_id": "lodi",
                "code": "lodi",
                "name": "Lodi",
                "pk": "IT__lodi"
            },
            {
                "_id": "lombardia",
                "code": "lombardia",
                "name": "Lombardia",
                "pk": "IT__lombardia"
            },
            {
                "_id": "lombardy",
                "code": "lombardy",
                "name": "Lombardy",
                "pk": "IT__lombardy"
            },
            {
                "_id": "macerata",
                "code": "macerata",
                "name": "Macerata",
                "pk": "IT__macerata"
            },
            {
                "_id": "mantova",
                "code": "mantova",
                "name": "Mantova",
                "pk": "IT__mantova"
            },
            {
                "_id": "marche",
                "code": "marche",
                "name": "Marche",
                "pk": "IT__marche"
            },
            {
                "_id": "messina",
                "code": "messina",
                "name": "Messina",
                "pk": "IT__messina"
            },
            {
                "_id": "milan",
                "code": "milan",
                "name": "Milan",
                "pk": "IT__milan"
            },
            {
                "_id": "modena",
                "code": "modena",
                "name": "Modena",
                "pk": "IT__modena"
            },
            {
                "_id": "molise",
                "code": "molise",
                "name": "Molise",
                "pk": "IT__molise"
            },
            {
                "_id": "molteno",
                "code": "molteno",
                "name": "Molteno",
                "pk": "IT__molteno"
            },
            {
                "_id": "montenegro",
                "code": "montenegro",
                "name": "Montenegro",
                "pk": "IT__montenegro"
            },
            {
                "_id": "monza_and_brianza",
                "code": "monza_and_brianza",
                "name": "Monza and Brianza",
                "pk": "IT__monza_and_brianza"
            },
            {
                "_id": "naples",
                "code": "naples",
                "name": "Naples",
                "pk": "IT__naples"
            },
            {
                "_id": "novara",
                "code": "novara",
                "name": "Novara",
                "pk": "IT__novara"
            },
            {
                "_id": "padova",
                "code": "padova",
                "name": "Padova",
                "pk": "IT__padova"
            },
            {
                "_id": "parma",
                "code": "parma",
                "name": "Parma",
                "pk": "IT__parma"
            },
            {
                "_id": "pavia",
                "code": "pavia",
                "name": "Pavia",
                "pk": "IT__pavia"
            },
            {
                "_id": "perugia",
                "code": "perugia",
                "name": "Perugia",
                "pk": "IT__perugia"
            },
            {
                "_id": "pesaro-urbino",
                "code": "pesaro-urbino",
                "name": "Pesaro-Urbino",
                "pk": "IT__pesaro-urbino"
            },
            {
                "_id": "piacenza",
                "code": "piacenza",
                "name": "Piacenza",
                "pk": "IT__piacenza"
            },
            {
                "_id": "piedmont",
                "code": "piedmont",
                "name": "Piedmont",
                "pk": "IT__piedmont"
            },
            {
                "_id": "piemonte",
                "code": "piemonte",
                "name": "Piemonte",
                "pk": "IT__piemonte"
            },
            {
                "_id": "pisa",
                "code": "pisa",
                "name": "Pisa",
                "pk": "IT__pisa"
            },
            {
                "_id": "pordenone",
                "code": "pordenone",
                "name": "Pordenone",
                "pk": "IT__pordenone"
            },
            {
                "_id": "potenza",
                "code": "potenza",
                "name": "Potenza",
                "pk": "IT__potenza"
            },
            {
                "_id": "puglia",
                "code": "puglia",
                "name": "Puglia",
                "pk": "IT__puglia"
            },
            {
                "_id": "reggio_emilia",
                "code": "reggio_emilia",
                "name": "Reggio Emilia",
                "pk": "IT__reggio_emilia"
            },
            {
                "_id": "rimini",
                "code": "rimini",
                "name": "Rimini",
                "pk": "IT__rimini"
            },
            {
                "_id": "roma",
                "code": "roma",
                "name": "Roma",
                "pk": "IT__roma"
            },
            {
                "_id": "salerno",
                "code": "salerno",
                "name": "Salerno",
                "pk": "IT__salerno"
            },
            {
                "_id": "sardegna",
                "code": "sardegna",
                "name": "Sardegna",
                "pk": "IT__sardegna"
            },
            {
                "_id": "sassari",
                "code": "sassari",
                "name": "Sassari",
                "pk": "IT__sassari"
            },
            {
                "_id": "savona",
                "code": "savona",
                "name": "Savona",
                "pk": "IT__savona"
            },
            {
                "_id": "sicilia",
                "code": "sicilia",
                "name": "Sicilia",
                "pk": "IT__sicilia"
            },
            {
                "_id": "siena",
                "code": "siena",
                "name": "Siena",
                "pk": "IT__siena"
            },
            {
                "_id": "sondrio",
                "code": "sondrio",
                "name": "Sondrio",
                "pk": "IT__sondrio"
            },
            {
                "_id": "south_tyrol",
                "code": "south_tyrol",
                "name": "South Tyrol",
                "pk": "IT__south_tyrol"
            },
            {
                "_id": "taranto",
                "code": "taranto",
                "name": "Taranto",
                "pk": "IT__taranto"
            },
            {
                "_id": "teramo",
                "code": "teramo",
                "name": "Teramo",
                "pk": "IT__teramo"
            },
            {
                "_id": "torino",
                "code": "torino",
                "name": "Torino",
                "pk": "IT__torino"
            },
            {
                "_id": "toscana",
                "code": "toscana",
                "name": "Toscana",
                "pk": "IT__toscana"
            },
            {
                "_id": "trapani",
                "code": "trapani",
                "name": "Trapani",
                "pk": "IT__trapani"
            },
            {
                "_id": "trentino-alto_adige",
                "code": "trentino-alto_adige",
                "name": "Trentino-Alto Adige",
                "pk": "IT__trentino-alto_adige"
            },
            {
                "_id": "trento",
                "code": "trento",
                "name": "Trento",
                "pk": "IT__trento"
            },
            {
                "_id": "treviso",
                "code": "treviso",
                "name": "Treviso",
                "pk": "IT__treviso"
            },
            {
                "_id": "udine",
                "code": "udine",
                "name": "Udine",
                "pk": "IT__udine"
            },
            {
                "_id": "umbria",
                "code": "umbria",
                "name": "Umbria",
                "pk": "IT__umbria"
            },
            {
                "_id": "valle_daosta",
                "code": "valle_daosta",
                "name": "Valle dAosta",
                "pk": "IT__valle_daosta"
            },
            {
                "_id": "varese",
                "code": "varese",
                "name": "Varese",
                "pk": "IT__varese"
            },
            {
                "_id": "veneto",
                "code": "veneto",
                "name": "Veneto",
                "pk": "IT__veneto"
            },
            {
                "_id": "venezia",
                "code": "venezia",
                "name": "Venezia",
                "pk": "IT__venezia"
            },
            {
                "_id": "verbano-cusio-ossola",
                "code": "verbano-cusio-ossola",
                "name": "Verbano-Cusio-Ossola",
                "pk": "IT__verbano-cusio-ossola"
            },
            {
                "_id": "vercelli",
                "code": "vercelli",
                "name": "Vercelli",
                "pk": "IT__vercelli"
            },
            {
                "_id": "verona",
                "code": "verona",
                "name": "Verona",
                "pk": "IT__verona"
            },
            {
                "_id": "vicenza",
                "code": "vicenza",
                "name": "Vicenza",
                "pk": "IT__vicenza"
            },
            {
                "_id": "viterbo",
                "code": "viterbo",
                "name": "Viterbo",
                "pk": "IT__viterbo"
            }
        ],
        "pk": "IT"
    },
    {
        "_id": "JM",
        "code": "JM",
        "name": "Jamaica",
        "states": [
            {
                "_id": "buxoro_viloyati",
                "code": "buxoro_viloyati",
                "name": "Buxoro Viloyati",
                "pk": "JM__buxoro_viloyati"
            },
            {
                "_id": "clarendon",
                "code": "clarendon",
                "name": "Clarendon",
                "pk": "JM__clarendon"
            },
            {
                "_id": "hanover",
                "code": "hanover",
                "name": "Hanover",
                "pk": "JM__hanover"
            },
            {
                "_id": "kingston",
                "code": "kingston",
                "name": "Kingston",
                "pk": "JM__kingston"
            },
            {
                "_id": "manchester",
                "code": "manchester",
                "name": "Manchester",
                "pk": "JM__manchester"
            },
            {
                "_id": "portland",
                "code": "portland",
                "name": "Portland",
                "pk": "JM__portland"
            },
            {
                "_id": "saint_andrews",
                "code": "saint_andrews",
                "name": "Saint Andrews",
                "pk": "JM__saint_andrews"
            },
            {
                "_id": "saint_ann",
                "code": "saint_ann",
                "name": "Saint Ann",
                "pk": "JM__saint_ann"
            },
            {
                "_id": "saint_catherine",
                "code": "saint_catherine",
                "name": "Saint Catherine",
                "pk": "JM__saint_catherine"
            },
            {
                "_id": "saint_elizabeth",
                "code": "saint_elizabeth",
                "name": "Saint Elizabeth",
                "pk": "JM__saint_elizabeth"
            },
            {
                "_id": "saint_james",
                "code": "saint_james",
                "name": "Saint James",
                "pk": "JM__saint_james"
            },
            {
                "_id": "saint_mary",
                "code": "saint_mary",
                "name": "Saint Mary",
                "pk": "JM__saint_mary"
            },
            {
                "_id": "saint_thomas",
                "code": "saint_thomas",
                "name": "Saint Thomas",
                "pk": "JM__saint_thomas"
            },
            {
                "_id": "trelawney",
                "code": "trelawney",
                "name": "Trelawney",
                "pk": "JM__trelawney"
            },
            {
                "_id": "westmoreland",
                "code": "westmoreland",
                "name": "Westmoreland",
                "pk": "JM__westmoreland"
            }
        ],
        "pk": "JM"
    },
    {
        "_id": "JP",
        "code": "JP",
        "name": "Japan",
        "states": [
            {
                "_id": "aichi",
                "code": "aichi",
                "name": "Aichi",
                "pk": "JP__aichi"
            },
            {
                "_id": "akita",
                "code": "akita",
                "name": "Akita",
                "pk": "JP__akita"
            },
            {
                "_id": "aomori",
                "code": "aomori",
                "name": "Aomori",
                "pk": "JP__aomori"
            },
            {
                "_id": "chiba",
                "code": "chiba",
                "name": "Chiba",
                "pk": "JP__chiba"
            },
            {
                "_id": "ehime",
                "code": "ehime",
                "name": "Ehime",
                "pk": "JP__ehime"
            },
            {
                "_id": "fukui",
                "code": "fukui",
                "name": "Fukui",
                "pk": "JP__fukui"
            },
            {
                "_id": "fukuoka",
                "code": "fukuoka",
                "name": "Fukuoka",
                "pk": "JP__fukuoka"
            },
            {
                "_id": "fukushima",
                "code": "fukushima",
                "name": "Fukushima",
                "pk": "JP__fukushima"
            },
            {
                "_id": "gifu",
                "code": "gifu",
                "name": "Gifu",
                "pk": "JP__gifu"
            },
            {
                "_id": "gumma",
                "code": "gumma",
                "name": "Gumma",
                "pk": "JP__gumma"
            },
            {
                "_id": "hiroshima",
                "code": "hiroshima",
                "name": "Hiroshima",
                "pk": "JP__hiroshima"
            },
            {
                "_id": "hokkaido",
                "code": "hokkaido",
                "name": "Hokkaido",
                "pk": "JP__hokkaido"
            },
            {
                "_id": "hyogo",
                "code": "hyogo",
                "name": "Hyogo",
                "pk": "JP__hyogo"
            },
            {
                "_id": "ibaraki",
                "code": "ibaraki",
                "name": "Ibaraki",
                "pk": "JP__ibaraki"
            },
            {
                "_id": "ishikawa",
                "code": "ishikawa",
                "name": "Ishikawa",
                "pk": "JP__ishikawa"
            },
            {
                "_id": "iwate",
                "code": "iwate",
                "name": "Iwate",
                "pk": "JP__iwate"
            },
            {
                "_id": "kagawa",
                "code": "kagawa",
                "name": "Kagawa",
                "pk": "JP__kagawa"
            },
            {
                "_id": "kagoshima",
                "code": "kagoshima",
                "name": "Kagoshima",
                "pk": "JP__kagoshima"
            },
            {
                "_id": "kanagawa",
                "code": "kanagawa",
                "name": "Kanagawa",
                "pk": "JP__kanagawa"
            },
            {
                "_id": "kanto",
                "code": "kanto",
                "name": "Kanto",
                "pk": "JP__kanto"
            },
            {
                "_id": "kochi",
                "code": "kochi",
                "name": "Kochi",
                "pk": "JP__kochi"
            },
            {
                "_id": "kumamoto",
                "code": "kumamoto",
                "name": "Kumamoto",
                "pk": "JP__kumamoto"
            },
            {
                "_id": "kyoto",
                "code": "kyoto",
                "name": "Kyoto",
                "pk": "JP__kyoto"
            },
            {
                "_id": "mie",
                "code": "mie",
                "name": "Mie",
                "pk": "JP__mie"
            },
            {
                "_id": "miyagi",
                "code": "miyagi",
                "name": "Miyagi",
                "pk": "JP__miyagi"
            },
            {
                "_id": "miyazaki",
                "code": "miyazaki",
                "name": "Miyazaki",
                "pk": "JP__miyazaki"
            },
            {
                "_id": "nagano",
                "code": "nagano",
                "name": "Nagano",
                "pk": "JP__nagano"
            },
            {
                "_id": "nagasaki",
                "code": "nagasaki",
                "name": "Nagasaki",
                "pk": "JP__nagasaki"
            },
            {
                "_id": "nara",
                "code": "nara",
                "name": "Nara",
                "pk": "JP__nara"
            },
            {
                "_id": "niigata",
                "code": "niigata",
                "name": "Niigata",
                "pk": "JP__niigata"
            },
            {
                "_id": "oita",
                "code": "oita",
                "name": "Oita",
                "pk": "JP__oita"
            },
            {
                "_id": "okayama",
                "code": "okayama",
                "name": "Okayama",
                "pk": "JP__okayama"
            },
            {
                "_id": "okinawa",
                "code": "okinawa",
                "name": "Okinawa",
                "pk": "JP__okinawa"
            },
            {
                "_id": "osaka",
                "code": "osaka",
                "name": "Osaka",
                "pk": "JP__osaka"
            },
            {
                "_id": "saga",
                "code": "saga",
                "name": "Saga",
                "pk": "JP__saga"
            },
            {
                "_id": "saitama",
                "code": "saitama",
                "name": "Saitama",
                "pk": "JP__saitama"
            },
            {
                "_id": "shiga",
                "code": "shiga",
                "name": "Shiga",
                "pk": "JP__shiga"
            },
            {
                "_id": "shimane",
                "code": "shimane",
                "name": "Shimane",
                "pk": "JP__shimane"
            },
            {
                "_id": "shizuoka",
                "code": "shizuoka",
                "name": "Shizuoka",
                "pk": "JP__shizuoka"
            },
            {
                "_id": "tochigi",
                "code": "tochigi",
                "name": "Tochigi",
                "pk": "JP__tochigi"
            },
            {
                "_id": "tokushima",
                "code": "tokushima",
                "name": "Tokushima",
                "pk": "JP__tokushima"
            },
            {
                "_id": "tokyo",
                "code": "tokyo",
                "name": "Tokyo",
                "pk": "JP__tokyo"
            },
            {
                "_id": "tottori",
                "code": "tottori",
                "name": "Tottori",
                "pk": "JP__tottori"
            },
            {
                "_id": "toyama",
                "code": "toyama",
                "name": "Toyama",
                "pk": "JP__toyama"
            },
            {
                "_id": "wakayama",
                "code": "wakayama",
                "name": "Wakayama",
                "pk": "JP__wakayama"
            },
            {
                "_id": "yamagata",
                "code": "yamagata",
                "name": "Yamagata",
                "pk": "JP__yamagata"
            },
            {
                "_id": "yamaguchi",
                "code": "yamaguchi",
                "name": "Yamaguchi",
                "pk": "JP__yamaguchi"
            },
            {
                "_id": "yamanashi",
                "code": "yamanashi",
                "name": "Yamanashi",
                "pk": "JP__yamanashi"
            }
        ],
        "pk": "JP"
    },
    {
        "_id": "XJ",
        "code": "XJ",
        "name": "Jersey",
        "states": [
            {
                "_id": "grouville",
                "code": "grouville",
                "name": "Grouville",
                "pk": "XJ__grouville"
            },
            {
                "_id": "saint_brelade",
                "code": "saint_brelade",
                "name": "Saint Brelade",
                "pk": "XJ__saint_brelade"
            },
            {
                "_id": "saint_clement",
                "code": "saint_clement",
                "name": "Saint Clement",
                "pk": "XJ__saint_clement"
            },
            {
                "_id": "saint_helier",
                "code": "saint_helier",
                "name": "Saint Helier",
                "pk": "XJ__saint_helier"
            },
            {
                "_id": "saint_john",
                "code": "saint_john",
                "name": "Saint John",
                "pk": "XJ__saint_john"
            },
            {
                "_id": "saint_lawrence",
                "code": "saint_lawrence",
                "name": "Saint Lawrence",
                "pk": "XJ__saint_lawrence"
            },
            {
                "_id": "saint_martin",
                "code": "saint_martin",
                "name": "Saint Martin",
                "pk": "XJ__saint_martin"
            },
            {
                "_id": "saint_mary",
                "code": "saint_mary",
                "name": "Saint Mary",
                "pk": "XJ__saint_mary"
            },
            {
                "_id": "saint_peter",
                "code": "saint_peter",
                "name": "Saint Peter",
                "pk": "XJ__saint_peter"
            },
            {
                "_id": "saint_saviour",
                "code": "saint_saviour",
                "name": "Saint Saviour",
                "pk": "XJ__saint_saviour"
            },
            {
                "_id": "trinity",
                "code": "trinity",
                "name": "Trinity",
                "pk": "XJ__trinity"
            }
        ],
        "pk": "XJ"
    },
    {
        "_id": "JO",
        "code": "JO",
        "name": "Jordan",
        "states": [
            {
                "_id": "ajlun",
                "code": "ajlun",
                "name": "Ajlun",
                "pk": "JO__ajlun"
            },
            {
                "_id": "amman",
                "code": "amman",
                "name": "Amman",
                "pk": "JO__amman"
            },
            {
                "_id": "irbid",
                "code": "irbid",
                "name": "Irbid",
                "pk": "JO__irbid"
            },
            {
                "_id": "jarash",
                "code": "jarash",
                "name": "Jarash",
                "pk": "JO__jarash"
            },
            {
                "_id": "maan",
                "code": "maan",
                "name": "Maan",
                "pk": "JO__maan"
            },
            {
                "_id": "madaba",
                "code": "madaba",
                "name": "Madaba",
                "pk": "JO__madaba"
            },
            {
                "_id": "al-aqabah",
                "code": "al-aqabah",
                "name": "al-Aqabah",
                "pk": "JO__al-aqabah"
            },
            {
                "_id": "al-balqa",
                "code": "al-balqa",
                "name": "al-Balqa",
                "pk": "JO__al-balqa"
            },
            {
                "_id": "al-karak",
                "code": "al-karak",
                "name": "al-Karak",
                "pk": "JO__al-karak"
            },
            {
                "_id": "al-mafraq",
                "code": "al-mafraq",
                "name": "al-Mafraq",
                "pk": "JO__al-mafraq"
            },
            {
                "_id": "at-tafilah",
                "code": "at-tafilah",
                "name": "at-Tafilah",
                "pk": "JO__at-tafilah"
            },
            {
                "_id": "az-zarqa",
                "code": "az-zarqa",
                "name": "az-Zarqa",
                "pk": "JO__az-zarqa"
            }
        ],
        "pk": "JO"
    },
    {
        "_id": "KZ",
        "code": "KZ",
        "name": "Kazakhstan",
        "states": [
            {
                "_id": "akmecet",
                "code": "akmecet",
                "name": "Akmecet",
                "pk": "KZ__akmecet"
            },
            {
                "_id": "akmola",
                "code": "akmola",
                "name": "Akmola",
                "pk": "KZ__akmola"
            },
            {
                "_id": "aktobe",
                "code": "aktobe",
                "name": "Aktobe",
                "pk": "KZ__aktobe"
            },
            {
                "_id": "almati",
                "code": "almati",
                "name": "Almati",
                "pk": "KZ__almati"
            },
            {
                "_id": "atirau",
                "code": "atirau",
                "name": "Atirau",
                "pk": "KZ__atirau"
            },
            {
                "_id": "batis_kazakstan",
                "code": "batis_kazakstan",
                "name": "Batis Kazakstan",
                "pk": "KZ__batis_kazakstan"
            },
            {
                "_id": "burlinsky_region",
                "code": "burlinsky_region",
                "name": "Burlinsky Region",
                "pk": "KZ__burlinsky_region"
            },
            {
                "_id": "karagandi",
                "code": "karagandi",
                "name": "Karagandi",
                "pk": "KZ__karagandi"
            },
            {
                "_id": "kostanay",
                "code": "kostanay",
                "name": "Kostanay",
                "pk": "KZ__kostanay"
            },
            {
                "_id": "mankistau",
                "code": "mankistau",
                "name": "Mankistau",
                "pk": "KZ__mankistau"
            },
            {
                "_id": "ontustik_kazakstan",
                "code": "ontustik_kazakstan",
                "name": "Ontustik Kazakstan",
                "pk": "KZ__ontustik_kazakstan"
            },
            {
                "_id": "pavlodar",
                "code": "pavlodar",
                "name": "Pavlodar",
                "pk": "KZ__pavlodar"
            },
            {
                "_id": "sigis_kazakstan",
                "code": "sigis_kazakstan",
                "name": "Sigis Kazakstan",
                "pk": "KZ__sigis_kazakstan"
            },
            {
                "_id": "soltustik_kazakstan",
                "code": "soltustik_kazakstan",
                "name": "Soltustik Kazakstan",
                "pk": "KZ__soltustik_kazakstan"
            },
            {
                "_id": "taraz",
                "code": "taraz",
                "name": "Taraz",
                "pk": "KZ__taraz"
            }
        ],
        "pk": "KZ"
    },
    {
        "_id": "KE",
        "code": "KE",
        "name": "Kenya",
        "states": [
            {
                "_id": "central",
                "code": "central",
                "name": "Central",
                "pk": "KE__central"
            },
            {
                "_id": "coast",
                "code": "coast",
                "name": "Coast",
                "pk": "KE__coast"
            },
            {
                "_id": "eastern",
                "code": "eastern",
                "name": "Eastern",
                "pk": "KE__eastern"
            },
            {
                "_id": "nairobi",
                "code": "nairobi",
                "name": "Nairobi",
                "pk": "KE__nairobi"
            },
            {
                "_id": "north_eastern",
                "code": "north_eastern",
                "name": "North Eastern",
                "pk": "KE__north_eastern"
            },
            {
                "_id": "nyanza",
                "code": "nyanza",
                "name": "Nyanza",
                "pk": "KE__nyanza"
            },
            {
                "_id": "rift_valley",
                "code": "rift_valley",
                "name": "Rift Valley",
                "pk": "KE__rift_valley"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "KE__western"
            }
        ],
        "pk": "KE"
    },
    {
        "_id": "KI",
        "code": "KI",
        "name": "Kiribati",
        "states": [
            {
                "_id": "abaiang",
                "code": "abaiang",
                "name": "Abaiang",
                "pk": "KI__abaiang"
            },
            {
                "_id": "abemana",
                "code": "abemana",
                "name": "Abemana",
                "pk": "KI__abemana"
            },
            {
                "_id": "aranuka",
                "code": "aranuka",
                "name": "Aranuka",
                "pk": "KI__aranuka"
            },
            {
                "_id": "arorae",
                "code": "arorae",
                "name": "Arorae",
                "pk": "KI__arorae"
            },
            {
                "_id": "banaba",
                "code": "banaba",
                "name": "Banaba",
                "pk": "KI__banaba"
            },
            {
                "_id": "beru",
                "code": "beru",
                "name": "Beru",
                "pk": "KI__beru"
            },
            {
                "_id": "butaritari",
                "code": "butaritari",
                "name": "Butaritari",
                "pk": "KI__butaritari"
            },
            {
                "_id": "kiritimati",
                "code": "kiritimati",
                "name": "Kiritimati",
                "pk": "KI__kiritimati"
            },
            {
                "_id": "kuria",
                "code": "kuria",
                "name": "Kuria",
                "pk": "KI__kuria"
            },
            {
                "_id": "maiana",
                "code": "maiana",
                "name": "Maiana",
                "pk": "KI__maiana"
            },
            {
                "_id": "makin",
                "code": "makin",
                "name": "Makin",
                "pk": "KI__makin"
            },
            {
                "_id": "marakei",
                "code": "marakei",
                "name": "Marakei",
                "pk": "KI__marakei"
            },
            {
                "_id": "nikunau",
                "code": "nikunau",
                "name": "Nikunau",
                "pk": "KI__nikunau"
            },
            {
                "_id": "nonouti",
                "code": "nonouti",
                "name": "Nonouti",
                "pk": "KI__nonouti"
            },
            {
                "_id": "onotoa",
                "code": "onotoa",
                "name": "Onotoa",
                "pk": "KI__onotoa"
            },
            {
                "_id": "phoenix_islands",
                "code": "phoenix_islands",
                "name": "Phoenix Islands",
                "pk": "KI__phoenix_islands"
            },
            {
                "_id": "tabiteuea_north",
                "code": "tabiteuea_north",
                "name": "Tabiteuea North",
                "pk": "KI__tabiteuea_north"
            },
            {
                "_id": "tabiteuea_south",
                "code": "tabiteuea_south",
                "name": "Tabiteuea South",
                "pk": "KI__tabiteuea_south"
            },
            {
                "_id": "tabuaeran",
                "code": "tabuaeran",
                "name": "Tabuaeran",
                "pk": "KI__tabuaeran"
            },
            {
                "_id": "tamana",
                "code": "tamana",
                "name": "Tamana",
                "pk": "KI__tamana"
            },
            {
                "_id": "tarawa_north",
                "code": "tarawa_north",
                "name": "Tarawa North",
                "pk": "KI__tarawa_north"
            },
            {
                "_id": "tarawa_south",
                "code": "tarawa_south",
                "name": "Tarawa South",
                "pk": "KI__tarawa_south"
            },
            {
                "_id": "teraina",
                "code": "teraina",
                "name": "Teraina",
                "pk": "KI__teraina"
            }
        ],
        "pk": "KI"
    },
    {
        "_id": "KP",
        "code": "KP",
        "name": "Korea North",
        "states": [
            {
                "_id": "chagangdo",
                "code": "chagangdo",
                "name": "Chagangdo",
                "pk": "KP__chagangdo"
            },
            {
                "_id": "hamgyeongbukto",
                "code": "hamgyeongbukto",
                "name": "Hamgyeongbukto",
                "pk": "KP__hamgyeongbukto"
            },
            {
                "_id": "hamgyeongnamdo",
                "code": "hamgyeongnamdo",
                "name": "Hamgyeongnamdo",
                "pk": "KP__hamgyeongnamdo"
            },
            {
                "_id": "hwanghaebukto",
                "code": "hwanghaebukto",
                "name": "Hwanghaebukto",
                "pk": "KP__hwanghaebukto"
            },
            {
                "_id": "hwanghaenamdo",
                "code": "hwanghaenamdo",
                "name": "Hwanghaenamdo",
                "pk": "KP__hwanghaenamdo"
            },
            {
                "_id": "kaeseong",
                "code": "kaeseong",
                "name": "Kaeseong",
                "pk": "KP__kaeseong"
            },
            {
                "_id": "kangweon",
                "code": "kangweon",
                "name": "Kangweon",
                "pk": "KP__kangweon"
            },
            {
                "_id": "nampo",
                "code": "nampo",
                "name": "Nampo",
                "pk": "KP__nampo"
            },
            {
                "_id": "pyeonganbukto",
                "code": "pyeonganbukto",
                "name": "Pyeonganbukto",
                "pk": "KP__pyeonganbukto"
            },
            {
                "_id": "pyeongannamdo",
                "code": "pyeongannamdo",
                "name": "Pyeongannamdo",
                "pk": "KP__pyeongannamdo"
            },
            {
                "_id": "pyeongyang",
                "code": "pyeongyang",
                "name": "Pyeongyang",
                "pk": "KP__pyeongyang"
            },
            {
                "_id": "yanggang",
                "code": "yanggang",
                "name": "Yanggang",
                "pk": "KP__yanggang"
            }
        ],
        "pk": "KP"
    },
    {
        "_id": "KR",
        "code": "KR",
        "name": "Korea South",
        "states": [
            {
                "_id": "busan",
                "code": "busan",
                "name": "Busan",
                "pk": "KR__busan"
            },
            {
                "_id": "cheju",
                "code": "cheju",
                "name": "Cheju",
                "pk": "KR__cheju"
            },
            {
                "_id": "chollabuk",
                "code": "chollabuk",
                "name": "Chollabuk",
                "pk": "KR__chollabuk"
            },
            {
                "_id": "chollanam",
                "code": "chollanam",
                "name": "Chollanam",
                "pk": "KR__chollanam"
            },
            {
                "_id": "chungbuk",
                "code": "chungbuk",
                "name": "Chungbuk",
                "pk": "KR__chungbuk"
            },
            {
                "_id": "chungcheongbuk",
                "code": "chungcheongbuk",
                "name": "Chungcheongbuk",
                "pk": "KR__chungcheongbuk"
            },
            {
                "_id": "chungcheongnam",
                "code": "chungcheongnam",
                "name": "Chungcheongnam",
                "pk": "KR__chungcheongnam"
            },
            {
                "_id": "chungnam",
                "code": "chungnam",
                "name": "Chungnam",
                "pk": "KR__chungnam"
            },
            {
                "_id": "daegu",
                "code": "daegu",
                "name": "Daegu",
                "pk": "KR__daegu"
            },
            {
                "_id": "gangwon-do",
                "code": "gangwon-do",
                "name": "Gangwon-do",
                "pk": "KR__gangwon-do"
            },
            {
                "_id": "goyang-si",
                "code": "goyang-si",
                "name": "Goyang-si",
                "pk": "KR__goyang-si"
            },
            {
                "_id": "gyeonggi-do",
                "code": "gyeonggi-do",
                "name": "Gyeonggi-do",
                "pk": "KR__gyeonggi-do"
            },
            {
                "_id": "gyeongsang_",
                "code": "gyeongsang_",
                "name": "Gyeongsang ",
                "pk": "KR__gyeongsang_"
            },
            {
                "_id": "gyeongsangnam-do",
                "code": "gyeongsangnam-do",
                "name": "Gyeongsangnam-do",
                "pk": "KR__gyeongsangnam-do"
            },
            {
                "_id": "incheon",
                "code": "incheon",
                "name": "Incheon",
                "pk": "KR__incheon"
            },
            {
                "_id": "jeju-si",
                "code": "jeju-si",
                "name": "Jeju-Si",
                "pk": "KR__jeju-si"
            },
            {
                "_id": "jeonbuk",
                "code": "jeonbuk",
                "name": "Jeonbuk",
                "pk": "KR__jeonbuk"
            },
            {
                "_id": "kangweon",
                "code": "kangweon",
                "name": "Kangweon",
                "pk": "KR__kangweon"
            },
            {
                "_id": "kwangju",
                "code": "kwangju",
                "name": "Kwangju",
                "pk": "KR__kwangju"
            },
            {
                "_id": "kyeonggi",
                "code": "kyeonggi",
                "name": "Kyeonggi",
                "pk": "KR__kyeonggi"
            },
            {
                "_id": "kyeongsangbuk",
                "code": "kyeongsangbuk",
                "name": "Kyeongsangbuk",
                "pk": "KR__kyeongsangbuk"
            },
            {
                "_id": "kyeongsangnam",
                "code": "kyeongsangnam",
                "name": "Kyeongsangnam",
                "pk": "KR__kyeongsangnam"
            },
            {
                "_id": "kyonggi-do",
                "code": "kyonggi-do",
                "name": "Kyonggi-do",
                "pk": "KR__kyonggi-do"
            },
            {
                "_id": "kyungbuk-do",
                "code": "kyungbuk-do",
                "name": "Kyungbuk-Do",
                "pk": "KR__kyungbuk-do"
            },
            {
                "_id": "kyunggi-do",
                "code": "kyunggi-do",
                "name": "Kyunggi-Do",
                "pk": "KR__kyunggi-do"
            },
            {
                "_id": "kyunggi-do",
                "code": "kyunggi-do",
                "name": "Kyunggi-do",
                "pk": "KR__kyunggi-do"
            },
            {
                "_id": "pusan",
                "code": "pusan",
                "name": "Pusan",
                "pk": "KR__pusan"
            },
            {
                "_id": "seoul",
                "code": "seoul",
                "name": "Seoul",
                "pk": "KR__seoul"
            },
            {
                "_id": "sudogwon",
                "code": "sudogwon",
                "name": "Sudogwon",
                "pk": "KR__sudogwon"
            },
            {
                "_id": "taegu",
                "code": "taegu",
                "name": "Taegu",
                "pk": "KR__taegu"
            },
            {
                "_id": "taejeon",
                "code": "taejeon",
                "name": "Taejeon",
                "pk": "KR__taejeon"
            },
            {
                "_id": "taejon-gwangyoksi",
                "code": "taejon-gwangyoksi",
                "name": "Taejon-gwangyoksi",
                "pk": "KR__taejon-gwangyoksi"
            },
            {
                "_id": "ulsan",
                "code": "ulsan",
                "name": "Ulsan",
                "pk": "KR__ulsan"
            },
            {
                "_id": "wonju",
                "code": "wonju",
                "name": "Wonju",
                "pk": "KR__wonju"
            },
            {
                "_id": "gwangyoksi",
                "code": "gwangyoksi",
                "name": "gwangyoksi",
                "pk": "KR__gwangyoksi"
            }
        ],
        "pk": "KR"
    },
    {
        "_id": "KW",
        "code": "KW",
        "name": "Kuwait",
        "states": [
            {
                "_id": "al_asimah",
                "code": "al_asimah",
                "name": "Al Asimah",
                "pk": "KW__al_asimah"
            },
            {
                "_id": "hawalli",
                "code": "hawalli",
                "name": "Hawalli",
                "pk": "KW__hawalli"
            },
            {
                "_id": "mishref",
                "code": "mishref",
                "name": "Mishref",
                "pk": "KW__mishref"
            },
            {
                "_id": "qadesiya",
                "code": "qadesiya",
                "name": "Qadesiya",
                "pk": "KW__qadesiya"
            },
            {
                "_id": "safat",
                "code": "safat",
                "name": "Safat",
                "pk": "KW__safat"
            },
            {
                "_id": "salmiya",
                "code": "salmiya",
                "name": "Salmiya",
                "pk": "KW__salmiya"
            },
            {
                "_id": "al-ahmadi",
                "code": "al-ahmadi",
                "name": "al-Ahmadi",
                "pk": "KW__al-ahmadi"
            },
            {
                "_id": "al-farwaniyah",
                "code": "al-farwaniyah",
                "name": "al-Farwaniyah",
                "pk": "KW__al-farwaniyah"
            },
            {
                "_id": "al-jahra",
                "code": "al-jahra",
                "name": "al-Jahra",
                "pk": "KW__al-jahra"
            },
            {
                "_id": "al-kuwayt",
                "code": "al-kuwayt",
                "name": "al-Kuwayt",
                "pk": "KW__al-kuwayt"
            }
        ],
        "pk": "KW"
    },
    {
        "_id": "KG",
        "code": "KG",
        "name": "Kyrgyzstan",
        "states": [
            {
                "_id": "batken",
                "code": "batken",
                "name": "Batken",
                "pk": "KG__batken"
            },
            {
                "_id": "bishkek",
                "code": "bishkek",
                "name": "Bishkek",
                "pk": "KG__bishkek"
            },
            {
                "_id": "chui",
                "code": "chui",
                "name": "Chui",
                "pk": "KG__chui"
            },
            {
                "_id": "issyk-kul",
                "code": "issyk-kul",
                "name": "Issyk-Kul",
                "pk": "KG__issyk-kul"
            },
            {
                "_id": "jalal-abad",
                "code": "jalal-abad",
                "name": "Jalal-Abad",
                "pk": "KG__jalal-abad"
            },
            {
                "_id": "naryn",
                "code": "naryn",
                "name": "Naryn",
                "pk": "KG__naryn"
            },
            {
                "_id": "osh",
                "code": "osh",
                "name": "Osh",
                "pk": "KG__osh"
            },
            {
                "_id": "talas",
                "code": "talas",
                "name": "Talas",
                "pk": "KG__talas"
            }
        ],
        "pk": "KG"
    },
    {
        "_id": "LA",
        "code": "LA",
        "name": "Laos",
        "states": [
            {
                "_id": "attopu",
                "code": "attopu",
                "name": "Attopu",
                "pk": "LA__attopu"
            },
            {
                "_id": "bokeo",
                "code": "bokeo",
                "name": "Bokeo",
                "pk": "LA__bokeo"
            },
            {
                "_id": "bolikhamsay",
                "code": "bolikhamsay",
                "name": "Bolikhamsay",
                "pk": "LA__bolikhamsay"
            },
            {
                "_id": "champasak",
                "code": "champasak",
                "name": "Champasak",
                "pk": "LA__champasak"
            },
            {
                "_id": "houaphanh",
                "code": "houaphanh",
                "name": "Houaphanh",
                "pk": "LA__houaphanh"
            },
            {
                "_id": "khammouane",
                "code": "khammouane",
                "name": "Khammouane",
                "pk": "LA__khammouane"
            },
            {
                "_id": "luang_nam_tha",
                "code": "luang_nam_tha",
                "name": "Luang Nam Tha",
                "pk": "LA__luang_nam_tha"
            },
            {
                "_id": "luang_prabang",
                "code": "luang_prabang",
                "name": "Luang Prabang",
                "pk": "LA__luang_prabang"
            },
            {
                "_id": "oudomxay",
                "code": "oudomxay",
                "name": "Oudomxay",
                "pk": "LA__oudomxay"
            },
            {
                "_id": "phongsaly",
                "code": "phongsaly",
                "name": "Phongsaly",
                "pk": "LA__phongsaly"
            },
            {
                "_id": "saravan",
                "code": "saravan",
                "name": "Saravan",
                "pk": "LA__saravan"
            },
            {
                "_id": "savannakhet",
                "code": "savannakhet",
                "name": "Savannakhet",
                "pk": "LA__savannakhet"
            },
            {
                "_id": "sekong",
                "code": "sekong",
                "name": "Sekong",
                "pk": "LA__sekong"
            },
            {
                "_id": "viangchan_prefecture",
                "code": "viangchan_prefecture",
                "name": "Viangchan Prefecture",
                "pk": "LA__viangchan_prefecture"
            },
            {
                "_id": "viangchan_province",
                "code": "viangchan_province",
                "name": "Viangchan Province",
                "pk": "LA__viangchan_province"
            },
            {
                "_id": "xaignabury",
                "code": "xaignabury",
                "name": "Xaignabury",
                "pk": "LA__xaignabury"
            },
            {
                "_id": "xiang_khuang",
                "code": "xiang_khuang",
                "name": "Xiang Khuang",
                "pk": "LA__xiang_khuang"
            }
        ],
        "pk": "LA"
    },
    {
        "_id": "LV",
        "code": "LV",
        "name": "Latvia",
        "states": [
            {
                "_id": "aizkraukles",
                "code": "aizkraukles",
                "name": "Aizkraukles",
                "pk": "LV__aizkraukles"
            },
            {
                "_id": "aluksnes",
                "code": "aluksnes",
                "name": "Aluksnes",
                "pk": "LV__aluksnes"
            },
            {
                "_id": "balvu",
                "code": "balvu",
                "name": "Balvu",
                "pk": "LV__balvu"
            },
            {
                "_id": "bauskas",
                "code": "bauskas",
                "name": "Bauskas",
                "pk": "LV__bauskas"
            },
            {
                "_id": "cesu",
                "code": "cesu",
                "name": "Cesu",
                "pk": "LV__cesu"
            },
            {
                "_id": "daugavpils",
                "code": "daugavpils",
                "name": "Daugavpils",
                "pk": "LV__daugavpils"
            },
            {
                "_id": "daugavpils_city",
                "code": "daugavpils_city",
                "name": "Daugavpils City",
                "pk": "LV__daugavpils_city"
            },
            {
                "_id": "dobeles",
                "code": "dobeles",
                "name": "Dobeles",
                "pk": "LV__dobeles"
            },
            {
                "_id": "gulbenes",
                "code": "gulbenes",
                "name": "Gulbenes",
                "pk": "LV__gulbenes"
            },
            {
                "_id": "jekabspils",
                "code": "jekabspils",
                "name": "Jekabspils",
                "pk": "LV__jekabspils"
            },
            {
                "_id": "jelgava",
                "code": "jelgava",
                "name": "Jelgava",
                "pk": "LV__jelgava"
            },
            {
                "_id": "jelgavas",
                "code": "jelgavas",
                "name": "Jelgavas",
                "pk": "LV__jelgavas"
            },
            {
                "_id": "jurmala_city",
                "code": "jurmala_city",
                "name": "Jurmala City",
                "pk": "LV__jurmala_city"
            },
            {
                "_id": "kraslavas",
                "code": "kraslavas",
                "name": "Kraslavas",
                "pk": "LV__kraslavas"
            },
            {
                "_id": "kuldigas",
                "code": "kuldigas",
                "name": "Kuldigas",
                "pk": "LV__kuldigas"
            },
            {
                "_id": "liepaja",
                "code": "liepaja",
                "name": "Liepaja",
                "pk": "LV__liepaja"
            },
            {
                "_id": "liepajas",
                "code": "liepajas",
                "name": "Liepajas",
                "pk": "LV__liepajas"
            },
            {
                "_id": "limbazhu",
                "code": "limbazhu",
                "name": "Limbazhu",
                "pk": "LV__limbazhu"
            },
            {
                "_id": "ludzas",
                "code": "ludzas",
                "name": "Ludzas",
                "pk": "LV__ludzas"
            },
            {
                "_id": "madonas",
                "code": "madonas",
                "name": "Madonas",
                "pk": "LV__madonas"
            },
            {
                "_id": "ogres",
                "code": "ogres",
                "name": "Ogres",
                "pk": "LV__ogres"
            },
            {
                "_id": "preilu",
                "code": "preilu",
                "name": "Preilu",
                "pk": "LV__preilu"
            },
            {
                "_id": "rezekne",
                "code": "rezekne",
                "name": "Rezekne",
                "pk": "LV__rezekne"
            },
            {
                "_id": "rezeknes",
                "code": "rezeknes",
                "name": "Rezeknes",
                "pk": "LV__rezeknes"
            },
            {
                "_id": "riga",
                "code": "riga",
                "name": "Riga",
                "pk": "LV__riga"
            },
            {
                "_id": "rigas",
                "code": "rigas",
                "name": "Rigas",
                "pk": "LV__rigas"
            },
            {
                "_id": "saldus",
                "code": "saldus",
                "name": "Saldus",
                "pk": "LV__saldus"
            },
            {
                "_id": "talsu",
                "code": "talsu",
                "name": "Talsu",
                "pk": "LV__talsu"
            },
            {
                "_id": "tukuma",
                "code": "tukuma",
                "name": "Tukuma",
                "pk": "LV__tukuma"
            },
            {
                "_id": "valkas",
                "code": "valkas",
                "name": "Valkas",
                "pk": "LV__valkas"
            },
            {
                "_id": "valmieras",
                "code": "valmieras",
                "name": "Valmieras",
                "pk": "LV__valmieras"
            },
            {
                "_id": "ventspils",
                "code": "ventspils",
                "name": "Ventspils",
                "pk": "LV__ventspils"
            },
            {
                "_id": "ventspils_city",
                "code": "ventspils_city",
                "name": "Ventspils City",
                "pk": "LV__ventspils_city"
            }
        ],
        "pk": "LV"
    },
    {
        "_id": "LB",
        "code": "LB",
        "name": "Lebanon",
        "states": [
            {
                "_id": "beirut",
                "code": "beirut",
                "name": "Beirut",
                "pk": "LB__beirut"
            },
            {
                "_id": "jabal_lubnan",
                "code": "jabal_lubnan",
                "name": "Jabal Lubnan",
                "pk": "LB__jabal_lubnan"
            },
            {
                "_id": "mohafazat_liban-nord",
                "code": "mohafazat_liban-nord",
                "name": "Mohafazat Liban-Nord",
                "pk": "LB__mohafazat_liban-nord"
            },
            {
                "_id": "mohafazat_mont-liban",
                "code": "mohafazat_mont-liban",
                "name": "Mohafazat Mont-Liban",
                "pk": "LB__mohafazat_mont-liban"
            },
            {
                "_id": "sidon",
                "code": "sidon",
                "name": "Sidon",
                "pk": "LB__sidon"
            },
            {
                "_id": "al-biqa",
                "code": "al-biqa",
                "name": "al-Biqa",
                "pk": "LB__al-biqa"
            },
            {
                "_id": "al-janub",
                "code": "al-janub",
                "name": "al-Janub",
                "pk": "LB__al-janub"
            },
            {
                "_id": "an-nabatiyah",
                "code": "an-nabatiyah",
                "name": "an-Nabatiyah",
                "pk": "LB__an-nabatiyah"
            },
            {
                "_id": "ash-shamal",
                "code": "ash-shamal",
                "name": "ash-Shamal",
                "pk": "LB__ash-shamal"
            }
        ],
        "pk": "LB"
    },
    {
        "_id": "LS",
        "code": "LS",
        "name": "Lesotho",
        "states": [
            {
                "_id": "berea",
                "code": "berea",
                "name": "Berea",
                "pk": "LS__berea"
            },
            {
                "_id": "butha-buthe",
                "code": "butha-buthe",
                "name": "Butha-Buthe",
                "pk": "LS__butha-buthe"
            },
            {
                "_id": "leribe",
                "code": "leribe",
                "name": "Leribe",
                "pk": "LS__leribe"
            },
            {
                "_id": "mafeteng",
                "code": "mafeteng",
                "name": "Mafeteng",
                "pk": "LS__mafeteng"
            },
            {
                "_id": "maseru",
                "code": "maseru",
                "name": "Maseru",
                "pk": "LS__maseru"
            },
            {
                "_id": "mohales_hoek",
                "code": "mohales_hoek",
                "name": "Mohales Hoek",
                "pk": "LS__mohales_hoek"
            },
            {
                "_id": "mokhotlong",
                "code": "mokhotlong",
                "name": "Mokhotlong",
                "pk": "LS__mokhotlong"
            },
            {
                "_id": "qachas_nek",
                "code": "qachas_nek",
                "name": "Qachas Nek",
                "pk": "LS__qachas_nek"
            },
            {
                "_id": "quthing",
                "code": "quthing",
                "name": "Quthing",
                "pk": "LS__quthing"
            },
            {
                "_id": "thaba-tseka",
                "code": "thaba-tseka",
                "name": "Thaba-Tseka",
                "pk": "LS__thaba-tseka"
            }
        ],
        "pk": "LS"
    },
    {
        "_id": "LR",
        "code": "LR",
        "name": "Liberia",
        "states": [
            {
                "_id": "bomi",
                "code": "bomi",
                "name": "Bomi",
                "pk": "LR__bomi"
            },
            {
                "_id": "bong",
                "code": "bong",
                "name": "Bong",
                "pk": "LR__bong"
            },
            {
                "_id": "grand_bassa",
                "code": "grand_bassa",
                "name": "Grand Bassa",
                "pk": "LR__grand_bassa"
            },
            {
                "_id": "grand_cape_mount",
                "code": "grand_cape_mount",
                "name": "Grand Cape Mount",
                "pk": "LR__grand_cape_mount"
            },
            {
                "_id": "grand_gedeh",
                "code": "grand_gedeh",
                "name": "Grand Gedeh",
                "pk": "LR__grand_gedeh"
            },
            {
                "_id": "loffa",
                "code": "loffa",
                "name": "Loffa",
                "pk": "LR__loffa"
            },
            {
                "_id": "margibi",
                "code": "margibi",
                "name": "Margibi",
                "pk": "LR__margibi"
            },
            {
                "_id": "maryland_and_grand_kru",
                "code": "maryland_and_grand_kru",
                "name": "Maryland and Grand Kru",
                "pk": "LR__maryland_and_grand_kru"
            },
            {
                "_id": "montserrado",
                "code": "montserrado",
                "name": "Montserrado",
                "pk": "LR__montserrado"
            },
            {
                "_id": "nimba",
                "code": "nimba",
                "name": "Nimba",
                "pk": "LR__nimba"
            },
            {
                "_id": "rivercess",
                "code": "rivercess",
                "name": "Rivercess",
                "pk": "LR__rivercess"
            },
            {
                "_id": "sinoe",
                "code": "sinoe",
                "name": "Sinoe",
                "pk": "LR__sinoe"
            }
        ],
        "pk": "LR"
    },
    {
        "_id": "LY",
        "code": "LY",
        "name": "Libya",
        "states": [
            {
                "_id": "ajdabiya",
                "code": "ajdabiya",
                "name": "Ajdabiya",
                "pk": "LY__ajdabiya"
            },
            {
                "_id": "fezzan",
                "code": "fezzan",
                "name": "Fezzan",
                "pk": "LY__fezzan"
            },
            {
                "_id": "banghazi",
                "code": "banghazi",
                "name": "Banghazi",
                "pk": "LY__banghazi"
            },
            {
                "_id": "darnah",
                "code": "darnah",
                "name": "Darnah",
                "pk": "LY__darnah"
            },
            {
                "_id": "ghadamis",
                "code": "ghadamis",
                "name": "Ghadamis",
                "pk": "LY__ghadamis"
            },
            {
                "_id": "gharyan",
                "code": "gharyan",
                "name": "Gharyan",
                "pk": "LY__gharyan"
            },
            {
                "_id": "misratah",
                "code": "misratah",
                "name": "Misratah",
                "pk": "LY__misratah"
            },
            {
                "_id": "murzuq",
                "code": "murzuq",
                "name": "Murzuq",
                "pk": "LY__murzuq"
            },
            {
                "_id": "sabha",
                "code": "sabha",
                "name": "Sabha",
                "pk": "LY__sabha"
            },
            {
                "_id": "sawfajjin",
                "code": "sawfajjin",
                "name": "Sawfajjin",
                "pk": "LY__sawfajjin"
            },
            {
                "_id": "surt",
                "code": "surt",
                "name": "Surt",
                "pk": "LY__surt"
            },
            {
                "_id": "tarabulus",
                "code": "tarabulus",
                "name": "Tarabulus",
                "pk": "LY__tarabulus"
            },
            {
                "_id": "tarhunah",
                "code": "tarhunah",
                "name": "Tarhunah",
                "pk": "LY__tarhunah"
            },
            {
                "_id": "tripolitania",
                "code": "tripolitania",
                "name": "Tripolitania",
                "pk": "LY__tripolitania"
            },
            {
                "_id": "tubruq",
                "code": "tubruq",
                "name": "Tubruq",
                "pk": "LY__tubruq"
            },
            {
                "_id": "yafran",
                "code": "yafran",
                "name": "Yafran",
                "pk": "LY__yafran"
            },
            {
                "_id": "zlitan",
                "code": "zlitan",
                "name": "Zlitan",
                "pk": "LY__zlitan"
            },
            {
                "_id": "al-aziziyah",
                "code": "al-aziziyah",
                "name": "al-Aziziyah",
                "pk": "LY__al-aziziyah"
            },
            {
                "_id": "al-fatih",
                "code": "al-fatih",
                "name": "al-Fatih",
                "pk": "LY__al-fatih"
            },
            {
                "_id": "al-jabal_al_akhdar",
                "code": "al-jabal_al_akhdar",
                "name": "al-Jabal al Akhdar",
                "pk": "LY__al-jabal_al_akhdar"
            },
            {
                "_id": "al-jufrah",
                "code": "al-jufrah",
                "name": "al-Jufrah",
                "pk": "LY__al-jufrah"
            },
            {
                "_id": "al-khums",
                "code": "al-khums",
                "name": "al-Khums",
                "pk": "LY__al-khums"
            },
            {
                "_id": "al-kufrah",
                "code": "al-kufrah",
                "name": "al-Kufrah",
                "pk": "LY__al-kufrah"
            },
            {
                "_id": "an-nuqat_al-khams",
                "code": "an-nuqat_al-khams",
                "name": "an-Nuqat al-Khams",
                "pk": "LY__an-nuqat_al-khams"
            },
            {
                "_id": "ash-shati",
                "code": "ash-shati",
                "name": "ash-Shati",
                "pk": "LY__ash-shati"
            },
            {
                "_id": "az-zawiyah",
                "code": "az-zawiyah",
                "name": "az-Zawiyah",
                "pk": "LY__az-zawiyah"
            }
        ],
        "pk": "LY"
    },
    {
        "_id": "LI",
        "code": "LI",
        "name": "Liechtenstein",
        "states": [
            {
                "_id": "balzers",
                "code": "balzers",
                "name": "Balzers",
                "pk": "LI__balzers"
            },
            {
                "_id": "eschen",
                "code": "eschen",
                "name": "Eschen",
                "pk": "LI__eschen"
            },
            {
                "_id": "gamprin",
                "code": "gamprin",
                "name": "Gamprin",
                "pk": "LI__gamprin"
            },
            {
                "_id": "mauren",
                "code": "mauren",
                "name": "Mauren",
                "pk": "LI__mauren"
            },
            {
                "_id": "planken",
                "code": "planken",
                "name": "Planken",
                "pk": "LI__planken"
            },
            {
                "_id": "ruggell",
                "code": "ruggell",
                "name": "Ruggell",
                "pk": "LI__ruggell"
            },
            {
                "_id": "schaan",
                "code": "schaan",
                "name": "Schaan",
                "pk": "LI__schaan"
            },
            {
                "_id": "schellenberg",
                "code": "schellenberg",
                "name": "Schellenberg",
                "pk": "LI__schellenberg"
            },
            {
                "_id": "triesen",
                "code": "triesen",
                "name": "Triesen",
                "pk": "LI__triesen"
            },
            {
                "_id": "triesenberg",
                "code": "triesenberg",
                "name": "Triesenberg",
                "pk": "LI__triesenberg"
            },
            {
                "_id": "vaduz",
                "code": "vaduz",
                "name": "Vaduz",
                "pk": "LI__vaduz"
            }
        ],
        "pk": "LI"
    },
    {
        "_id": "LT",
        "code": "LT",
        "name": "Lithuania",
        "states": [
            {
                "_id": "alytaus",
                "code": "alytaus",
                "name": "Alytaus",
                "pk": "LT__alytaus"
            },
            {
                "_id": "anyksciai",
                "code": "anyksciai",
                "name": "Anyksciai",
                "pk": "LT__anyksciai"
            },
            {
                "_id": "kauno",
                "code": "kauno",
                "name": "Kauno",
                "pk": "LT__kauno"
            },
            {
                "_id": "klaipedos",
                "code": "klaipedos",
                "name": "Klaipedos",
                "pk": "LT__klaipedos"
            },
            {
                "_id": "marijampoles",
                "code": "marijampoles",
                "name": "Marijampoles",
                "pk": "LT__marijampoles"
            },
            {
                "_id": "panevezhio",
                "code": "panevezhio",
                "name": "Panevezhio",
                "pk": "LT__panevezhio"
            },
            {
                "_id": "panevezys",
                "code": "panevezys",
                "name": "Panevezys",
                "pk": "LT__panevezys"
            },
            {
                "_id": "shiauliu",
                "code": "shiauliu",
                "name": "Shiauliu",
                "pk": "LT__shiauliu"
            },
            {
                "_id": "taurages",
                "code": "taurages",
                "name": "Taurages",
                "pk": "LT__taurages"
            },
            {
                "_id": "telshiu",
                "code": "telshiu",
                "name": "Telshiu",
                "pk": "LT__telshiu"
            },
            {
                "_id": "telsiai",
                "code": "telsiai",
                "name": "Telsiai",
                "pk": "LT__telsiai"
            },
            {
                "_id": "utenos",
                "code": "utenos",
                "name": "Utenos",
                "pk": "LT__utenos"
            },
            {
                "_id": "vilniaus",
                "code": "vilniaus",
                "name": "Vilniaus",
                "pk": "LT__vilniaus"
            }
        ],
        "pk": "LT"
    },
    {
        "_id": "LU",
        "code": "LU",
        "name": "Luxembourg",
        "states": [
            {
                "_id": "capellen",
                "code": "capellen",
                "name": "Capellen",
                "pk": "LU__capellen"
            },
            {
                "_id": "clervaux",
                "code": "clervaux",
                "name": "Clervaux",
                "pk": "LU__clervaux"
            },
            {
                "_id": "diekirch",
                "code": "diekirch",
                "name": "Diekirch",
                "pk": "LU__diekirch"
            },
            {
                "_id": "echternach",
                "code": "echternach",
                "name": "Echternach",
                "pk": "LU__echternach"
            },
            {
                "_id": "esch-sur-alzette",
                "code": "esch-sur-alzette",
                "name": "Esch-sur-Alzette",
                "pk": "LU__esch-sur-alzette"
            },
            {
                "_id": "grevenmacher",
                "code": "grevenmacher",
                "name": "Grevenmacher",
                "pk": "LU__grevenmacher"
            },
            {
                "_id": "luxembourg",
                "code": "luxembourg",
                "name": "Luxembourg",
                "pk": "LU__luxembourg"
            },
            {
                "_id": "mersch",
                "code": "mersch",
                "name": "Mersch",
                "pk": "LU__mersch"
            },
            {
                "_id": "redange",
                "code": "redange",
                "name": "Redange",
                "pk": "LU__redange"
            },
            {
                "_id": "remich",
                "code": "remich",
                "name": "Remich",
                "pk": "LU__remich"
            },
            {
                "_id": "vianden",
                "code": "vianden",
                "name": "Vianden",
                "pk": "LU__vianden"
            },
            {
                "_id": "wiltz",
                "code": "wiltz",
                "name": "Wiltz",
                "pk": "LU__wiltz"
            }
        ],
        "pk": "LU"
    },
    {
        "_id": "MO",
        "code": "MO",
        "name": "Macau S.A.R.",
        "states": [
            {
                "_id": "macau",
                "code": "macau",
                "name": "Macau",
                "pk": "MO__macau"
            }
        ],
        "pk": "MO"
    },
    {
        "_id": "MK",
        "code": "MK",
        "name": "Macedonia",
        "states": [
            {
                "_id": "berovo",
                "code": "berovo",
                "name": "Berovo",
                "pk": "MK__berovo"
            },
            {
                "_id": "bitola",
                "code": "bitola",
                "name": "Bitola",
                "pk": "MK__bitola"
            },
            {
                "_id": "brod",
                "code": "brod",
                "name": "Brod",
                "pk": "MK__brod"
            },
            {
                "_id": "debar",
                "code": "debar",
                "name": "Debar",
                "pk": "MK__debar"
            },
            {
                "_id": "delchevo",
                "code": "delchevo",
                "name": "Delchevo",
                "pk": "MK__delchevo"
            },
            {
                "_id": "demir_hisar",
                "code": "demir_hisar",
                "name": "Demir Hisar",
                "pk": "MK__demir_hisar"
            },
            {
                "_id": "gevgelija",
                "code": "gevgelija",
                "name": "Gevgelija",
                "pk": "MK__gevgelija"
            },
            {
                "_id": "gostivar",
                "code": "gostivar",
                "name": "Gostivar",
                "pk": "MK__gostivar"
            },
            {
                "_id": "kavadarci",
                "code": "kavadarci",
                "name": "Kavadarci",
                "pk": "MK__kavadarci"
            },
            {
                "_id": "kichevo",
                "code": "kichevo",
                "name": "Kichevo",
                "pk": "MK__kichevo"
            },
            {
                "_id": "kochani",
                "code": "kochani",
                "name": "Kochani",
                "pk": "MK__kochani"
            },
            {
                "_id": "kratovo",
                "code": "kratovo",
                "name": "Kratovo",
                "pk": "MK__kratovo"
            },
            {
                "_id": "kriva_palanka",
                "code": "kriva_palanka",
                "name": "Kriva Palanka",
                "pk": "MK__kriva_palanka"
            },
            {
                "_id": "krushevo",
                "code": "krushevo",
                "name": "Krushevo",
                "pk": "MK__krushevo"
            },
            {
                "_id": "kumanovo",
                "code": "kumanovo",
                "name": "Kumanovo",
                "pk": "MK__kumanovo"
            },
            {
                "_id": "negotino",
                "code": "negotino",
                "name": "Negotino",
                "pk": "MK__negotino"
            },
            {
                "_id": "ohrid",
                "code": "ohrid",
                "name": "Ohrid",
                "pk": "MK__ohrid"
            },
            {
                "_id": "prilep",
                "code": "prilep",
                "name": "Prilep",
                "pk": "MK__prilep"
            },
            {
                "_id": "probishtip",
                "code": "probishtip",
                "name": "Probishtip",
                "pk": "MK__probishtip"
            },
            {
                "_id": "radovish",
                "code": "radovish",
                "name": "Radovish",
                "pk": "MK__radovish"
            },
            {
                "_id": "resen",
                "code": "resen",
                "name": "Resen",
                "pk": "MK__resen"
            },
            {
                "_id": "shtip",
                "code": "shtip",
                "name": "Shtip",
                "pk": "MK__shtip"
            },
            {
                "_id": "skopje",
                "code": "skopje",
                "name": "Skopje",
                "pk": "MK__skopje"
            },
            {
                "_id": "struga",
                "code": "struga",
                "name": "Struga",
                "pk": "MK__struga"
            },
            {
                "_id": "strumica",
                "code": "strumica",
                "name": "Strumica",
                "pk": "MK__strumica"
            },
            {
                "_id": "sveti_nikole",
                "code": "sveti_nikole",
                "name": "Sveti Nikole",
                "pk": "MK__sveti_nikole"
            },
            {
                "_id": "tetovo",
                "code": "tetovo",
                "name": "Tetovo",
                "pk": "MK__tetovo"
            },
            {
                "_id": "valandovo",
                "code": "valandovo",
                "name": "Valandovo",
                "pk": "MK__valandovo"
            },
            {
                "_id": "veles",
                "code": "veles",
                "name": "Veles",
                "pk": "MK__veles"
            },
            {
                "_id": "vinica",
                "code": "vinica",
                "name": "Vinica",
                "pk": "MK__vinica"
            }
        ],
        "pk": "MK"
    },
    {
        "_id": "MG",
        "code": "MG",
        "name": "Madagascar",
        "states": [
            {
                "_id": "antananarivo",
                "code": "antananarivo",
                "name": "Antananarivo",
                "pk": "MG__antananarivo"
            },
            {
                "_id": "antsiranana",
                "code": "antsiranana",
                "name": "Antsiranana",
                "pk": "MG__antsiranana"
            },
            {
                "_id": "fianarantsoa",
                "code": "fianarantsoa",
                "name": "Fianarantsoa",
                "pk": "MG__fianarantsoa"
            },
            {
                "_id": "mahajanga",
                "code": "mahajanga",
                "name": "Mahajanga",
                "pk": "MG__mahajanga"
            },
            {
                "_id": "toamasina",
                "code": "toamasina",
                "name": "Toamasina",
                "pk": "MG__toamasina"
            },
            {
                "_id": "toliary",
                "code": "toliary",
                "name": "Toliary",
                "pk": "MG__toliary"
            }
        ],
        "pk": "MG"
    },
    {
        "_id": "MW",
        "code": "MW",
        "name": "Malawi",
        "states": [
            {
                "_id": "balaka",
                "code": "balaka",
                "name": "Balaka",
                "pk": "MW__balaka"
            },
            {
                "_id": "blantyre_city",
                "code": "blantyre_city",
                "name": "Blantyre City",
                "pk": "MW__blantyre_city"
            },
            {
                "_id": "chikwawa",
                "code": "chikwawa",
                "name": "Chikwawa",
                "pk": "MW__chikwawa"
            },
            {
                "_id": "chiradzulu",
                "code": "chiradzulu",
                "name": "Chiradzulu",
                "pk": "MW__chiradzulu"
            },
            {
                "_id": "chitipa",
                "code": "chitipa",
                "name": "Chitipa",
                "pk": "MW__chitipa"
            },
            {
                "_id": "dedza",
                "code": "dedza",
                "name": "Dedza",
                "pk": "MW__dedza"
            },
            {
                "_id": "dowa",
                "code": "dowa",
                "name": "Dowa",
                "pk": "MW__dowa"
            },
            {
                "_id": "karonga",
                "code": "karonga",
                "name": "Karonga",
                "pk": "MW__karonga"
            },
            {
                "_id": "kasungu",
                "code": "kasungu",
                "name": "Kasungu",
                "pk": "MW__kasungu"
            },
            {
                "_id": "lilongwe_city",
                "code": "lilongwe_city",
                "name": "Lilongwe City",
                "pk": "MW__lilongwe_city"
            },
            {
                "_id": "machinga",
                "code": "machinga",
                "name": "Machinga",
                "pk": "MW__machinga"
            },
            {
                "_id": "mangochi",
                "code": "mangochi",
                "name": "Mangochi",
                "pk": "MW__mangochi"
            },
            {
                "_id": "mchinji",
                "code": "mchinji",
                "name": "Mchinji",
                "pk": "MW__mchinji"
            },
            {
                "_id": "mulanje",
                "code": "mulanje",
                "name": "Mulanje",
                "pk": "MW__mulanje"
            },
            {
                "_id": "mwanza",
                "code": "mwanza",
                "name": "Mwanza",
                "pk": "MW__mwanza"
            },
            {
                "_id": "mzimba",
                "code": "mzimba",
                "name": "Mzimba",
                "pk": "MW__mzimba"
            },
            {
                "_id": "mzuzu_city",
                "code": "mzuzu_city",
                "name": "Mzuzu City",
                "pk": "MW__mzuzu_city"
            },
            {
                "_id": "nkhata_bay",
                "code": "nkhata_bay",
                "name": "Nkhata Bay",
                "pk": "MW__nkhata_bay"
            },
            {
                "_id": "nkhotakota",
                "code": "nkhotakota",
                "name": "Nkhotakota",
                "pk": "MW__nkhotakota"
            },
            {
                "_id": "nsanje",
                "code": "nsanje",
                "name": "Nsanje",
                "pk": "MW__nsanje"
            },
            {
                "_id": "ntcheu",
                "code": "ntcheu",
                "name": "Ntcheu",
                "pk": "MW__ntcheu"
            },
            {
                "_id": "ntchisi",
                "code": "ntchisi",
                "name": "Ntchisi",
                "pk": "MW__ntchisi"
            },
            {
                "_id": "phalombe",
                "code": "phalombe",
                "name": "Phalombe",
                "pk": "MW__phalombe"
            },
            {
                "_id": "rumphi",
                "code": "rumphi",
                "name": "Rumphi",
                "pk": "MW__rumphi"
            },
            {
                "_id": "salima",
                "code": "salima",
                "name": "Salima",
                "pk": "MW__salima"
            },
            {
                "_id": "thyolo",
                "code": "thyolo",
                "name": "Thyolo",
                "pk": "MW__thyolo"
            },
            {
                "_id": "zomba_municipality",
                "code": "zomba_municipality",
                "name": "Zomba Municipality",
                "pk": "MW__zomba_municipality"
            }
        ],
        "pk": "MW"
    },
    {
        "_id": "MY",
        "code": "MY",
        "name": "Malaysia",
        "states": [
            {
                "_id": "johor",
                "code": "johor",
                "name": "Johor",
                "pk": "MY__johor"
            },
            {
                "_id": "kedah",
                "code": "kedah",
                "name": "Kedah",
                "pk": "MY__kedah"
            },
            {
                "_id": "kelantan",
                "code": "kelantan",
                "name": "Kelantan",
                "pk": "MY__kelantan"
            },
            {
                "_id": "kuala_lumpur",
                "code": "kuala_lumpur",
                "name": "Kuala Lumpur",
                "pk": "MY__kuala_lumpur"
            },
            {
                "_id": "labuan",
                "code": "labuan",
                "name": "Labuan",
                "pk": "MY__labuan"
            },
            {
                "_id": "melaka",
                "code": "melaka",
                "name": "Melaka",
                "pk": "MY__melaka"
            },
            {
                "_id": "negeri_johor",
                "code": "negeri_johor",
                "name": "Negeri Johor",
                "pk": "MY__negeri_johor"
            },
            {
                "_id": "negeri_sembilan",
                "code": "negeri_sembilan",
                "name": "Negeri Sembilan",
                "pk": "MY__negeri_sembilan"
            },
            {
                "_id": "pahang",
                "code": "pahang",
                "name": "Pahang",
                "pk": "MY__pahang"
            },
            {
                "_id": "penang",
                "code": "penang",
                "name": "Penang",
                "pk": "MY__penang"
            },
            {
                "_id": "perak",
                "code": "perak",
                "name": "Perak",
                "pk": "MY__perak"
            },
            {
                "_id": "perlis",
                "code": "perlis",
                "name": "Perlis",
                "pk": "MY__perlis"
            },
            {
                "_id": "pulau_pinang",
                "code": "pulau_pinang",
                "name": "Pulau Pinang",
                "pk": "MY__pulau_pinang"
            },
            {
                "_id": "sabah",
                "code": "sabah",
                "name": "Sabah",
                "pk": "MY__sabah"
            },
            {
                "_id": "sarawak",
                "code": "sarawak",
                "name": "Sarawak",
                "pk": "MY__sarawak"
            },
            {
                "_id": "selangor",
                "code": "selangor",
                "name": "Selangor",
                "pk": "MY__selangor"
            },
            {
                "_id": "sembilan",
                "code": "sembilan",
                "name": "Sembilan",
                "pk": "MY__sembilan"
            },
            {
                "_id": "terengganu",
                "code": "terengganu",
                "name": "Terengganu",
                "pk": "MY__terengganu"
            }
        ],
        "pk": "MY"
    },
    {
        "_id": "MV",
        "code": "MV",
        "name": "Maldives",
        "states": [
            {
                "_id": "alif_alif",
                "code": "alif_alif",
                "name": "Alif Alif",
                "pk": "MV__alif_alif"
            },
            {
                "_id": "alif_dhaal",
                "code": "alif_dhaal",
                "name": "Alif Dhaal",
                "pk": "MV__alif_dhaal"
            },
            {
                "_id": "baa",
                "code": "baa",
                "name": "Baa",
                "pk": "MV__baa"
            },
            {
                "_id": "dhaal",
                "code": "dhaal",
                "name": "Dhaal",
                "pk": "MV__dhaal"
            },
            {
                "_id": "faaf",
                "code": "faaf",
                "name": "Faaf",
                "pk": "MV__faaf"
            },
            {
                "_id": "gaaf_alif",
                "code": "gaaf_alif",
                "name": "Gaaf Alif",
                "pk": "MV__gaaf_alif"
            },
            {
                "_id": "gaaf_dhaal",
                "code": "gaaf_dhaal",
                "name": "Gaaf Dhaal",
                "pk": "MV__gaaf_dhaal"
            },
            {
                "_id": "ghaviyani",
                "code": "ghaviyani",
                "name": "Ghaviyani",
                "pk": "MV__ghaviyani"
            },
            {
                "_id": "haa_alif",
                "code": "haa_alif",
                "name": "Haa Alif",
                "pk": "MV__haa_alif"
            },
            {
                "_id": "haa_dhaal",
                "code": "haa_dhaal",
                "name": "Haa Dhaal",
                "pk": "MV__haa_dhaal"
            },
            {
                "_id": "kaaf",
                "code": "kaaf",
                "name": "Kaaf",
                "pk": "MV__kaaf"
            },
            {
                "_id": "laam",
                "code": "laam",
                "name": "Laam",
                "pk": "MV__laam"
            },
            {
                "_id": "lhaviyani",
                "code": "lhaviyani",
                "name": "Lhaviyani",
                "pk": "MV__lhaviyani"
            },
            {
                "_id": "male",
                "code": "male",
                "name": "Male",
                "pk": "MV__male"
            },
            {
                "_id": "miim",
                "code": "miim",
                "name": "Miim",
                "pk": "MV__miim"
            },
            {
                "_id": "nuun",
                "code": "nuun",
                "name": "Nuun",
                "pk": "MV__nuun"
            },
            {
                "_id": "raa",
                "code": "raa",
                "name": "Raa",
                "pk": "MV__raa"
            },
            {
                "_id": "shaviyani",
                "code": "shaviyani",
                "name": "Shaviyani",
                "pk": "MV__shaviyani"
            },
            {
                "_id": "siin",
                "code": "siin",
                "name": "Siin",
                "pk": "MV__siin"
            },
            {
                "_id": "thaa",
                "code": "thaa",
                "name": "Thaa",
                "pk": "MV__thaa"
            },
            {
                "_id": "vaav",
                "code": "vaav",
                "name": "Vaav",
                "pk": "MV__vaav"
            }
        ],
        "pk": "MV"
    },
    {
        "_id": "ML",
        "code": "ML",
        "name": "Mali",
        "states": [
            {
                "_id": "bamako",
                "code": "bamako",
                "name": "Bamako",
                "pk": "ML__bamako"
            },
            {
                "_id": "gao",
                "code": "gao",
                "name": "Gao",
                "pk": "ML__gao"
            },
            {
                "_id": "kayes",
                "code": "kayes",
                "name": "Kayes",
                "pk": "ML__kayes"
            },
            {
                "_id": "kidal",
                "code": "kidal",
                "name": "Kidal",
                "pk": "ML__kidal"
            },
            {
                "_id": "koulikoro",
                "code": "koulikoro",
                "name": "Koulikoro",
                "pk": "ML__koulikoro"
            },
            {
                "_id": "mopti",
                "code": "mopti",
                "name": "Mopti",
                "pk": "ML__mopti"
            },
            {
                "_id": "segou",
                "code": "segou",
                "name": "Segou",
                "pk": "ML__segou"
            },
            {
                "_id": "sikasso",
                "code": "sikasso",
                "name": "Sikasso",
                "pk": "ML__sikasso"
            },
            {
                "_id": "tombouctou",
                "code": "tombouctou",
                "name": "Tombouctou",
                "pk": "ML__tombouctou"
            }
        ],
        "pk": "ML"
    },
    {
        "_id": "MT",
        "code": "MT",
        "name": "Malta",
        "states": [
            {
                "_id": "gozo_and_comino",
                "code": "gozo_and_comino",
                "name": "Gozo and Comino",
                "pk": "MT__gozo_and_comino"
            },
            {
                "_id": "inner_harbour",
                "code": "inner_harbour",
                "name": "Inner Harbour",
                "pk": "MT__inner_harbour"
            },
            {
                "_id": "northern",
                "code": "northern",
                "name": "Northern",
                "pk": "MT__northern"
            },
            {
                "_id": "outer_harbour",
                "code": "outer_harbour",
                "name": "Outer Harbour",
                "pk": "MT__outer_harbour"
            },
            {
                "_id": "south_eastern",
                "code": "south_eastern",
                "name": "South Eastern",
                "pk": "MT__south_eastern"
            },
            {
                "_id": "valletta",
                "code": "valletta",
                "name": "Valletta",
                "pk": "MT__valletta"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "MT__western"
            }
        ],
        "pk": "MT"
    },
    {
        "_id": "XM",
        "code": "XM",
        "name": "Man Isle of",
        "states": [
            {
                "_id": "castletown",
                "code": "castletown",
                "name": "Castletown",
                "pk": "XM__castletown"
            },
            {
                "_id": "douglas",
                "code": "douglas",
                "name": "Douglas",
                "pk": "XM__douglas"
            },
            {
                "_id": "laxey",
                "code": "laxey",
                "name": "Laxey",
                "pk": "XM__laxey"
            },
            {
                "_id": "onchan",
                "code": "onchan",
                "name": "Onchan",
                "pk": "XM__onchan"
            },
            {
                "_id": "peel",
                "code": "peel",
                "name": "Peel",
                "pk": "XM__peel"
            },
            {
                "_id": "port_erin",
                "code": "port_erin",
                "name": "Port Erin",
                "pk": "XM__port_erin"
            },
            {
                "_id": "port_saint_mary",
                "code": "port_saint_mary",
                "name": "Port Saint Mary",
                "pk": "XM__port_saint_mary"
            },
            {
                "_id": "ramsey",
                "code": "ramsey",
                "name": "Ramsey",
                "pk": "XM__ramsey"
            }
        ],
        "pk": "XM"
    },
    {
        "_id": "MH",
        "code": "MH",
        "name": "Marshall Islands",
        "states": [
            {
                "_id": "ailinlaplap",
                "code": "ailinlaplap",
                "name": "Ailinlaplap",
                "pk": "MH__ailinlaplap"
            },
            {
                "_id": "ailuk",
                "code": "ailuk",
                "name": "Ailuk",
                "pk": "MH__ailuk"
            },
            {
                "_id": "arno",
                "code": "arno",
                "name": "Arno",
                "pk": "MH__arno"
            },
            {
                "_id": "aur",
                "code": "aur",
                "name": "Aur",
                "pk": "MH__aur"
            },
            {
                "_id": "bikini",
                "code": "bikini",
                "name": "Bikini",
                "pk": "MH__bikini"
            },
            {
                "_id": "ebon",
                "code": "ebon",
                "name": "Ebon",
                "pk": "MH__ebon"
            },
            {
                "_id": "enewetak",
                "code": "enewetak",
                "name": "Enewetak",
                "pk": "MH__enewetak"
            },
            {
                "_id": "jabat",
                "code": "jabat",
                "name": "Jabat",
                "pk": "MH__jabat"
            },
            {
                "_id": "jaluit",
                "code": "jaluit",
                "name": "Jaluit",
                "pk": "MH__jaluit"
            },
            {
                "_id": "kili",
                "code": "kili",
                "name": "Kili",
                "pk": "MH__kili"
            },
            {
                "_id": "kwajalein",
                "code": "kwajalein",
                "name": "Kwajalein",
                "pk": "MH__kwajalein"
            },
            {
                "_id": "lae",
                "code": "lae",
                "name": "Lae",
                "pk": "MH__lae"
            },
            {
                "_id": "lib",
                "code": "lib",
                "name": "Lib",
                "pk": "MH__lib"
            },
            {
                "_id": "likiep",
                "code": "likiep",
                "name": "Likiep",
                "pk": "MH__likiep"
            },
            {
                "_id": "majuro",
                "code": "majuro",
                "name": "Majuro",
                "pk": "MH__majuro"
            },
            {
                "_id": "maloelap",
                "code": "maloelap",
                "name": "Maloelap",
                "pk": "MH__maloelap"
            },
            {
                "_id": "mejit",
                "code": "mejit",
                "name": "Mejit",
                "pk": "MH__mejit"
            },
            {
                "_id": "mili",
                "code": "mili",
                "name": "Mili",
                "pk": "MH__mili"
            },
            {
                "_id": "namorik",
                "code": "namorik",
                "name": "Namorik",
                "pk": "MH__namorik"
            },
            {
                "_id": "namu",
                "code": "namu",
                "name": "Namu",
                "pk": "MH__namu"
            },
            {
                "_id": "rongelap",
                "code": "rongelap",
                "name": "Rongelap",
                "pk": "MH__rongelap"
            },
            {
                "_id": "ujae",
                "code": "ujae",
                "name": "Ujae",
                "pk": "MH__ujae"
            },
            {
                "_id": "utrik",
                "code": "utrik",
                "name": "Utrik",
                "pk": "MH__utrik"
            },
            {
                "_id": "wotho",
                "code": "wotho",
                "name": "Wotho",
                "pk": "MH__wotho"
            },
            {
                "_id": "wotje",
                "code": "wotje",
                "name": "Wotje",
                "pk": "MH__wotje"
            }
        ],
        "pk": "MH"
    },
    {
        "_id": "MQ",
        "code": "MQ",
        "name": "Martinique",
        "states": [
            {
                "_id": "fort-de-france",
                "code": "fort-de-france",
                "name": "Fort-de-France",
                "pk": "MQ__fort-de-france"
            },
            {
                "_id": "la_trinite",
                "code": "la_trinite",
                "name": "La Trinite",
                "pk": "MQ__la_trinite"
            },
            {
                "_id": "le_marin",
                "code": "le_marin",
                "name": "Le Marin",
                "pk": "MQ__le_marin"
            },
            {
                "_id": "saint-pierre",
                "code": "saint-pierre",
                "name": "Saint-Pierre",
                "pk": "MQ__saint-pierre"
            }
        ],
        "pk": "MQ"
    },
    {
        "_id": "MR",
        "code": "MR",
        "name": "Mauritania",
        "states": [
            {
                "_id": "adrar",
                "code": "adrar",
                "name": "Adrar",
                "pk": "MR__adrar"
            },
            {
                "_id": "assaba",
                "code": "assaba",
                "name": "Assaba",
                "pk": "MR__assaba"
            },
            {
                "_id": "brakna",
                "code": "brakna",
                "name": "Brakna",
                "pk": "MR__brakna"
            },
            {
                "_id": "dhakhlat_nawadibu",
                "code": "dhakhlat_nawadibu",
                "name": "Dhakhlat Nawadibu",
                "pk": "MR__dhakhlat_nawadibu"
            },
            {
                "_id": "hudh-al-gharbi",
                "code": "hudh-al-gharbi",
                "name": "Hudh-al-Gharbi",
                "pk": "MR__hudh-al-gharbi"
            },
            {
                "_id": "hudh-ash-sharqi",
                "code": "hudh-ash-sharqi",
                "name": "Hudh-ash-Sharqi",
                "pk": "MR__hudh-ash-sharqi"
            },
            {
                "_id": "inshiri",
                "code": "inshiri",
                "name": "Inshiri",
                "pk": "MR__inshiri"
            },
            {
                "_id": "nawakshut",
                "code": "nawakshut",
                "name": "Nawakshut",
                "pk": "MR__nawakshut"
            },
            {
                "_id": "qidimagha",
                "code": "qidimagha",
                "name": "Qidimagha",
                "pk": "MR__qidimagha"
            },
            {
                "_id": "qurqul",
                "code": "qurqul",
                "name": "Qurqul",
                "pk": "MR__qurqul"
            },
            {
                "_id": "taqant",
                "code": "taqant",
                "name": "Taqant",
                "pk": "MR__taqant"
            },
            {
                "_id": "tiris_zammur",
                "code": "tiris_zammur",
                "name": "Tiris Zammur",
                "pk": "MR__tiris_zammur"
            },
            {
                "_id": "trarza",
                "code": "trarza",
                "name": "Trarza",
                "pk": "MR__trarza"
            }
        ],
        "pk": "MR"
    },
    {
        "_id": "MU",
        "code": "MU",
        "name": "Mauritius",
        "states": [
            {
                "_id": "black_river",
                "code": "black_river",
                "name": "Black River",
                "pk": "MU__black_river"
            },
            {
                "_id": "eau_coulee",
                "code": "eau_coulee",
                "name": "Eau Coulee",
                "pk": "MU__eau_coulee"
            },
            {
                "_id": "flacq",
                "code": "flacq",
                "name": "Flacq",
                "pk": "MU__flacq"
            },
            {
                "_id": "floreal",
                "code": "floreal",
                "name": "Floreal",
                "pk": "MU__floreal"
            },
            {
                "_id": "grand_port",
                "code": "grand_port",
                "name": "Grand Port",
                "pk": "MU__grand_port"
            },
            {
                "_id": "moka",
                "code": "moka",
                "name": "Moka",
                "pk": "MU__moka"
            },
            {
                "_id": "pamplempousses",
                "code": "pamplempousses",
                "name": "Pamplempousses",
                "pk": "MU__pamplempousses"
            },
            {
                "_id": "plaines_wilhelm",
                "code": "plaines_wilhelm",
                "name": "Plaines Wilhelm",
                "pk": "MU__plaines_wilhelm"
            },
            {
                "_id": "port_louis",
                "code": "port_louis",
                "name": "Port Louis",
                "pk": "MU__port_louis"
            },
            {
                "_id": "riviere_du_rempart",
                "code": "riviere_du_rempart",
                "name": "Riviere du Rempart",
                "pk": "MU__riviere_du_rempart"
            },
            {
                "_id": "rodrigues",
                "code": "rodrigues",
                "name": "Rodrigues",
                "pk": "MU__rodrigues"
            },
            {
                "_id": "rose_hill",
                "code": "rose_hill",
                "name": "Rose Hill",
                "pk": "MU__rose_hill"
            },
            {
                "_id": "savanne",
                "code": "savanne",
                "name": "Savanne",
                "pk": "MU__savanne"
            }
        ],
        "pk": "MU"
    },
    {
        "_id": "YT",
        "code": "YT",
        "name": "Mayotte",
        "states": [
            {
                "_id": "mayotte",
                "code": "mayotte",
                "name": "Mayotte",
                "pk": "YT__mayotte"
            },
            {
                "_id": "pamanzi",
                "code": "pamanzi",
                "name": "Pamanzi",
                "pk": "YT__pamanzi"
            }
        ],
        "pk": "YT"
    },
    {
        "_id": "MX",
        "code": "MX",
        "name": "Mexico",
        "states": [
            {
                "_id": "aguascalientes",
                "code": "aguascalientes",
                "name": "Aguascalientes",
                "pk": "MX__aguascalientes"
            },
            {
                "_id": "baja_california",
                "code": "baja_california",
                "name": "Baja California",
                "pk": "MX__baja_california"
            },
            {
                "_id": "baja_california_sur",
                "code": "baja_california_sur",
                "name": "Baja California Sur",
                "pk": "MX__baja_california_sur"
            },
            {
                "_id": "campeche",
                "code": "campeche",
                "name": "Campeche",
                "pk": "MX__campeche"
            },
            {
                "_id": "chiapas",
                "code": "chiapas",
                "name": "Chiapas",
                "pk": "MX__chiapas"
            },
            {
                "_id": "chihuahua",
                "code": "chihuahua",
                "name": "Chihuahua",
                "pk": "MX__chihuahua"
            },
            {
                "_id": "coahuila",
                "code": "coahuila",
                "name": "Coahuila",
                "pk": "MX__coahuila"
            },
            {
                "_id": "colima",
                "code": "colima",
                "name": "Colima",
                "pk": "MX__colima"
            },
            {
                "_id": "distrito_federal",
                "code": "distrito_federal",
                "name": "Distrito Federal",
                "pk": "MX__distrito_federal"
            },
            {
                "_id": "durango",
                "code": "durango",
                "name": "Durango",
                "pk": "MX__durango"
            },
            {
                "_id": "estado_de_mexico",
                "code": "estado_de_mexico",
                "name": "Estado de Mexico",
                "pk": "MX__estado_de_mexico"
            },
            {
                "_id": "guanajuato",
                "code": "guanajuato",
                "name": "Guanajuato",
                "pk": "MX__guanajuato"
            },
            {
                "_id": "guerrero",
                "code": "guerrero",
                "name": "Guerrero",
                "pk": "MX__guerrero"
            },
            {
                "_id": "hidalgo",
                "code": "hidalgo",
                "name": "Hidalgo",
                "pk": "MX__hidalgo"
            },
            {
                "_id": "jalisco",
                "code": "jalisco",
                "name": "Jalisco",
                "pk": "MX__jalisco"
            },
            {
                "_id": "mexico",
                "code": "mexico",
                "name": "Mexico",
                "pk": "MX__mexico"
            },
            {
                "_id": "michoacan",
                "code": "michoacan",
                "name": "Michoacan",
                "pk": "MX__michoacan"
            },
            {
                "_id": "morelos",
                "code": "morelos",
                "name": "Morelos",
                "pk": "MX__morelos"
            },
            {
                "_id": "nayarit",
                "code": "nayarit",
                "name": "Nayarit",
                "pk": "MX__nayarit"
            },
            {
                "_id": "nuevo_leon",
                "code": "nuevo_leon",
                "name": "Nuevo Leon",
                "pk": "MX__nuevo_leon"
            },
            {
                "_id": "oaxaca",
                "code": "oaxaca",
                "name": "Oaxaca",
                "pk": "MX__oaxaca"
            },
            {
                "_id": "puebla",
                "code": "puebla",
                "name": "Puebla",
                "pk": "MX__puebla"
            },
            {
                "_id": "queretaro",
                "code": "queretaro",
                "name": "Queretaro",
                "pk": "MX__queretaro"
            },
            {
                "_id": "quintana_roo",
                "code": "quintana_roo",
                "name": "Quintana Roo",
                "pk": "MX__quintana_roo"
            },
            {
                "_id": "san_luis_potosi",
                "code": "san_luis_potosi",
                "name": "San Luis Potosi",
                "pk": "MX__san_luis_potosi"
            },
            {
                "_id": "sinaloa",
                "code": "sinaloa",
                "name": "Sinaloa",
                "pk": "MX__sinaloa"
            },
            {
                "_id": "sonora",
                "code": "sonora",
                "name": "Sonora",
                "pk": "MX__sonora"
            },
            {
                "_id": "tabasco",
                "code": "tabasco",
                "name": "Tabasco",
                "pk": "MX__tabasco"
            },
            {
                "_id": "tamaulipas",
                "code": "tamaulipas",
                "name": "Tamaulipas",
                "pk": "MX__tamaulipas"
            },
            {
                "_id": "tlaxcala",
                "code": "tlaxcala",
                "name": "Tlaxcala",
                "pk": "MX__tlaxcala"
            },
            {
                "_id": "veracruz",
                "code": "veracruz",
                "name": "Veracruz",
                "pk": "MX__veracruz"
            },
            {
                "_id": "yucatan",
                "code": "yucatan",
                "name": "Yucatan",
                "pk": "MX__yucatan"
            },
            {
                "_id": "zacatecas",
                "code": "zacatecas",
                "name": "Zacatecas",
                "pk": "MX__zacatecas"
            }
        ],
        "only_shipping": true,
        "pk": "MX"
    },
    {
        "_id": "FM",
        "code": "FM",
        "name": "Micronesia",
        "states": [
            {
                "_id": "chuuk",
                "code": "chuuk",
                "name": "Chuuk",
                "pk": "FM__chuuk"
            },
            {
                "_id": "kusaie",
                "code": "kusaie",
                "name": "Kusaie",
                "pk": "FM__kusaie"
            },
            {
                "_id": "pohnpei",
                "code": "pohnpei",
                "name": "Pohnpei",
                "pk": "FM__pohnpei"
            },
            {
                "_id": "yap",
                "code": "yap",
                "name": "Yap",
                "pk": "FM__yap"
            }
        ],
        "pk": "FM"
    },
    {
        "_id": "MD",
        "code": "MD",
        "name": "Moldova",
        "states": [
            {
                "_id": "balti",
                "code": "balti",
                "name": "Balti",
                "pk": "MD__balti"
            },
            {
                "_id": "cahul",
                "code": "cahul",
                "name": "Cahul",
                "pk": "MD__cahul"
            },
            {
                "_id": "chisinau",
                "code": "chisinau",
                "name": "Chisinau",
                "pk": "MD__chisinau"
            },
            {
                "_id": "chisinau_oras",
                "code": "chisinau_oras",
                "name": "Chisinau Oras",
                "pk": "MD__chisinau_oras"
            },
            {
                "_id": "edinet",
                "code": "edinet",
                "name": "Edinet",
                "pk": "MD__edinet"
            },
            {
                "_id": "gagauzia",
                "code": "gagauzia",
                "name": "Gagauzia",
                "pk": "MD__gagauzia"
            },
            {
                "_id": "lapusna",
                "code": "lapusna",
                "name": "Lapusna",
                "pk": "MD__lapusna"
            },
            {
                "_id": "orhei",
                "code": "orhei",
                "name": "Orhei",
                "pk": "MD__orhei"
            },
            {
                "_id": "soroca",
                "code": "soroca",
                "name": "Soroca",
                "pk": "MD__soroca"
            },
            {
                "_id": "taraclia",
                "code": "taraclia",
                "name": "Taraclia",
                "pk": "MD__taraclia"
            },
            {
                "_id": "tighina",
                "code": "tighina",
                "name": "Tighina",
                "pk": "MD__tighina"
            },
            {
                "_id": "transnistria",
                "code": "transnistria",
                "name": "Transnistria",
                "pk": "MD__transnistria"
            },
            {
                "_id": "ungheni",
                "code": "ungheni",
                "name": "Ungheni",
                "pk": "MD__ungheni"
            }
        ],
        "pk": "MD"
    },
    {
        "_id": "MC",
        "code": "MC",
        "name": "Monaco",
        "states": [
            {
                "_id": "fontvieille",
                "code": "fontvieille",
                "name": "Fontvieille",
                "pk": "MC__fontvieille"
            },
            {
                "_id": "la_condamine",
                "code": "la_condamine",
                "name": "La Condamine",
                "pk": "MC__la_condamine"
            },
            {
                "_id": "monaco-ville",
                "code": "monaco-ville",
                "name": "Monaco-Ville",
                "pk": "MC__monaco-ville"
            },
            {
                "_id": "monte_carlo",
                "code": "monte_carlo",
                "name": "Monte Carlo",
                "pk": "MC__monte_carlo"
            }
        ],
        "pk": "MC"
    },
    {
        "_id": "MN",
        "code": "MN",
        "name": "Mongolia",
        "states": [
            {
                "_id": "arhangaj",
                "code": "arhangaj",
                "name": "Arhangaj",
                "pk": "MN__arhangaj"
            },
            {
                "_id": "bajan-olgij",
                "code": "bajan-olgij",
                "name": "Bajan-Olgij",
                "pk": "MN__bajan-olgij"
            },
            {
                "_id": "bajanhongor",
                "code": "bajanhongor",
                "name": "Bajanhongor",
                "pk": "MN__bajanhongor"
            },
            {
                "_id": "bulgan",
                "code": "bulgan",
                "name": "Bulgan",
                "pk": "MN__bulgan"
            },
            {
                "_id": "darhan-uul",
                "code": "darhan-uul",
                "name": "Darhan-Uul",
                "pk": "MN__darhan-uul"
            },
            {
                "_id": "dornod",
                "code": "dornod",
                "name": "Dornod",
                "pk": "MN__dornod"
            },
            {
                "_id": "dornogovi",
                "code": "dornogovi",
                "name": "Dornogovi",
                "pk": "MN__dornogovi"
            },
            {
                "_id": "dundgovi",
                "code": "dundgovi",
                "name": "Dundgovi",
                "pk": "MN__dundgovi"
            },
            {
                "_id": "govi-altaj",
                "code": "govi-altaj",
                "name": "Govi-Altaj",
                "pk": "MN__govi-altaj"
            },
            {
                "_id": "govisumber",
                "code": "govisumber",
                "name": "Govisumber",
                "pk": "MN__govisumber"
            },
            {
                "_id": "hentij",
                "code": "hentij",
                "name": "Hentij",
                "pk": "MN__hentij"
            },
            {
                "_id": "hovd",
                "code": "hovd",
                "name": "Hovd",
                "pk": "MN__hovd"
            },
            {
                "_id": "hovsgol",
                "code": "hovsgol",
                "name": "Hovsgol",
                "pk": "MN__hovsgol"
            },
            {
                "_id": "omnogovi",
                "code": "omnogovi",
                "name": "Omnogovi",
                "pk": "MN__omnogovi"
            },
            {
                "_id": "orhon",
                "code": "orhon",
                "name": "Orhon",
                "pk": "MN__orhon"
            },
            {
                "_id": "ovorhangaj",
                "code": "ovorhangaj",
                "name": "Ovorhangaj",
                "pk": "MN__ovorhangaj"
            },
            {
                "_id": "selenge",
                "code": "selenge",
                "name": "Selenge",
                "pk": "MN__selenge"
            },
            {
                "_id": "suhbaatar",
                "code": "suhbaatar",
                "name": "Suhbaatar",
                "pk": "MN__suhbaatar"
            },
            {
                "_id": "tov",
                "code": "tov",
                "name": "Tov",
                "pk": "MN__tov"
            },
            {
                "_id": "ulaanbaatar",
                "code": "ulaanbaatar",
                "name": "Ulaanbaatar",
                "pk": "MN__ulaanbaatar"
            },
            {
                "_id": "uvs",
                "code": "uvs",
                "name": "Uvs",
                "pk": "MN__uvs"
            },
            {
                "_id": "zavhan",
                "code": "zavhan",
                "name": "Zavhan",
                "pk": "MN__zavhan"
            }
        ],
        "pk": "MN"
    },
    {
        "_id": "MS",
        "code": "MS",
        "name": "Montserrat",
        "states": [
            {
                "_id": "montserrat",
                "code": "montserrat",
                "name": "Montserrat",
                "pk": "MS__montserrat"
            }
        ],
        "pk": "MS"
    },
    {
        "_id": "MA",
        "code": "MA",
        "name": "Morocco",
        "states": [
            {
                "_id": "agadir",
                "code": "agadir",
                "name": "Agadir",
                "pk": "MA__agadir"
            },
            {
                "_id": "casablanca",
                "code": "casablanca",
                "name": "Casablanca",
                "pk": "MA__casablanca"
            },
            {
                "_id": "chaouia-ouardigha",
                "code": "chaouia-ouardigha",
                "name": "Chaouia-Ouardigha",
                "pk": "MA__chaouia-ouardigha"
            },
            {
                "_id": "doukkala-abda",
                "code": "doukkala-abda",
                "name": "Doukkala-Abda",
                "pk": "MA__doukkala-abda"
            },
            {
                "_id": "fes-boulemane",
                "code": "fes-boulemane",
                "name": "Fes-Boulemane",
                "pk": "MA__fes-boulemane"
            },
            {
                "_id": "gharb-chrarda-beni_hssen",
                "code": "gharb-chrarda-beni_hssen",
                "name": "Gharb-Chrarda-Beni Hssen",
                "pk": "MA__gharb-chrarda-beni_hssen"
            },
            {
                "_id": "guelmim",
                "code": "guelmim",
                "name": "Guelmim",
                "pk": "MA__guelmim"
            },
            {
                "_id": "kenitra",
                "code": "kenitra",
                "name": "Kenitra",
                "pk": "MA__kenitra"
            },
            {
                "_id": "marrakech-tensift-al_haouz",
                "code": "marrakech-tensift-al_haouz",
                "name": "Marrakech-Tensift-Al Haouz",
                "pk": "MA__marrakech-tensift-al_haouz"
            },
            {
                "_id": "meknes-tafilalet",
                "code": "meknes-tafilalet",
                "name": "Meknes-Tafilalet",
                "pk": "MA__meknes-tafilalet"
            },
            {
                "_id": "oriental",
                "code": "oriental",
                "name": "Oriental",
                "pk": "MA__oriental"
            },
            {
                "_id": "oujda",
                "code": "oujda",
                "name": "Oujda",
                "pk": "MA__oujda"
            },
            {
                "_id": "province_de_tanger",
                "code": "province_de_tanger",
                "name": "Province de Tanger",
                "pk": "MA__province_de_tanger"
            },
            {
                "_id": "rabat-sale-zammour-zaer",
                "code": "rabat-sale-zammour-zaer",
                "name": "Rabat-Sale-Zammour-Zaer",
                "pk": "MA__rabat-sale-zammour-zaer"
            },
            {
                "_id": "sala_al_jadida",
                "code": "sala_al_jadida",
                "name": "Sala Al Jadida",
                "pk": "MA__sala_al_jadida"
            },
            {
                "_id": "settat",
                "code": "settat",
                "name": "Settat",
                "pk": "MA__settat"
            },
            {
                "_id": "souss_massa-draa",
                "code": "souss_massa-draa",
                "name": "Souss Massa-Draa",
                "pk": "MA__souss_massa-draa"
            },
            {
                "_id": "tadla-azilal",
                "code": "tadla-azilal",
                "name": "Tadla-Azilal",
                "pk": "MA__tadla-azilal"
            },
            {
                "_id": "tangier-tetouan",
                "code": "tangier-tetouan",
                "name": "Tangier-Tetouan",
                "pk": "MA__tangier-tetouan"
            },
            {
                "_id": "taza-al_hoceima-taounate",
                "code": "taza-al_hoceima-taounate",
                "name": "Taza-Al Hoceima-Taounate",
                "pk": "MA__taza-al_hoceima-taounate"
            },
            {
                "_id": "wilaya_de_casablanca",
                "code": "wilaya_de_casablanca",
                "name": "Wilaya de Casablanca",
                "pk": "MA__wilaya_de_casablanca"
            },
            {
                "_id": "wilaya_de_rabat-sale",
                "code": "wilaya_de_rabat-sale",
                "name": "Wilaya de Rabat-Sale",
                "pk": "MA__wilaya_de_rabat-sale"
            }
        ],
        "pk": "MA"
    },
    {
        "_id": "MZ",
        "code": "MZ",
        "name": "Mozambique",
        "states": [
            {
                "_id": "cabo_delgado",
                "code": "cabo_delgado",
                "name": "Cabo Delgado",
                "pk": "MZ__cabo_delgado"
            },
            {
                "_id": "gaza",
                "code": "gaza",
                "name": "Gaza",
                "pk": "MZ__gaza"
            },
            {
                "_id": "inhambane",
                "code": "inhambane",
                "name": "Inhambane",
                "pk": "MZ__inhambane"
            },
            {
                "_id": "manica",
                "code": "manica",
                "name": "Manica",
                "pk": "MZ__manica"
            },
            {
                "_id": "maputo",
                "code": "maputo",
                "name": "Maputo",
                "pk": "MZ__maputo"
            },
            {
                "_id": "maputo_provincia",
                "code": "maputo_provincia",
                "name": "Maputo Provincia",
                "pk": "MZ__maputo_provincia"
            },
            {
                "_id": "nampula",
                "code": "nampula",
                "name": "Nampula",
                "pk": "MZ__nampula"
            },
            {
                "_id": "niassa",
                "code": "niassa",
                "name": "Niassa",
                "pk": "MZ__niassa"
            },
            {
                "_id": "sofala",
                "code": "sofala",
                "name": "Sofala",
                "pk": "MZ__sofala"
            },
            {
                "_id": "tete",
                "code": "tete",
                "name": "Tete",
                "pk": "MZ__tete"
            },
            {
                "_id": "zambezia",
                "code": "zambezia",
                "name": "Zambezia",
                "pk": "MZ__zambezia"
            }
        ],
        "pk": "MZ"
    },
    {
        "_id": "MM",
        "code": "MM",
        "name": "Myanmar",
        "states": [
            {
                "_id": "ayeyarwady",
                "code": "ayeyarwady",
                "name": "Ayeyarwady",
                "pk": "MM__ayeyarwady"
            },
            {
                "_id": "bago",
                "code": "bago",
                "name": "Bago",
                "pk": "MM__bago"
            },
            {
                "_id": "chin",
                "code": "chin",
                "name": "Chin",
                "pk": "MM__chin"
            },
            {
                "_id": "kachin",
                "code": "kachin",
                "name": "Kachin",
                "pk": "MM__kachin"
            },
            {
                "_id": "kayah",
                "code": "kayah",
                "name": "Kayah",
                "pk": "MM__kayah"
            },
            {
                "_id": "kayin",
                "code": "kayin",
                "name": "Kayin",
                "pk": "MM__kayin"
            },
            {
                "_id": "magway",
                "code": "magway",
                "name": "Magway",
                "pk": "MM__magway"
            },
            {
                "_id": "mandalay",
                "code": "mandalay",
                "name": "Mandalay",
                "pk": "MM__mandalay"
            },
            {
                "_id": "mon",
                "code": "mon",
                "name": "Mon",
                "pk": "MM__mon"
            },
            {
                "_id": "nay_pyi_taw",
                "code": "nay_pyi_taw",
                "name": "Nay Pyi Taw",
                "pk": "MM__nay_pyi_taw"
            },
            {
                "_id": "rakhine",
                "code": "rakhine",
                "name": "Rakhine",
                "pk": "MM__rakhine"
            },
            {
                "_id": "sagaing",
                "code": "sagaing",
                "name": "Sagaing",
                "pk": "MM__sagaing"
            },
            {
                "_id": "shan",
                "code": "shan",
                "name": "Shan",
                "pk": "MM__shan"
            },
            {
                "_id": "tanintharyi",
                "code": "tanintharyi",
                "name": "Tanintharyi",
                "pk": "MM__tanintharyi"
            },
            {
                "_id": "yangon",
                "code": "yangon",
                "name": "Yangon",
                "pk": "MM__yangon"
            }
        ],
        "pk": "MM"
    },
    {
        "_id": "NA",
        "code": "NA",
        "name": "Namibia",
        "states": [
            {
                "_id": "caprivi",
                "code": "caprivi",
                "name": "Caprivi",
                "pk": "NA__caprivi"
            },
            {
                "_id": "erongo",
                "code": "erongo",
                "name": "Erongo",
                "pk": "NA__erongo"
            },
            {
                "_id": "hardap",
                "code": "hardap",
                "name": "Hardap",
                "pk": "NA__hardap"
            },
            {
                "_id": "karas",
                "code": "karas",
                "name": "Karas",
                "pk": "NA__karas"
            },
            {
                "_id": "kavango",
                "code": "kavango",
                "name": "Kavango",
                "pk": "NA__kavango"
            },
            {
                "_id": "khomas",
                "code": "khomas",
                "name": "Khomas",
                "pk": "NA__khomas"
            },
            {
                "_id": "kunene",
                "code": "kunene",
                "name": "Kunene",
                "pk": "NA__kunene"
            },
            {
                "_id": "ohangwena",
                "code": "ohangwena",
                "name": "Ohangwena",
                "pk": "NA__ohangwena"
            },
            {
                "_id": "omaheke",
                "code": "omaheke",
                "name": "Omaheke",
                "pk": "NA__omaheke"
            },
            {
                "_id": "omusati",
                "code": "omusati",
                "name": "Omusati",
                "pk": "NA__omusati"
            },
            {
                "_id": "oshana",
                "code": "oshana",
                "name": "Oshana",
                "pk": "NA__oshana"
            },
            {
                "_id": "oshikoto",
                "code": "oshikoto",
                "name": "Oshikoto",
                "pk": "NA__oshikoto"
            },
            {
                "_id": "otjozondjupa",
                "code": "otjozondjupa",
                "name": "Otjozondjupa",
                "pk": "NA__otjozondjupa"
            }
        ],
        "pk": "NA"
    },
    {
        "_id": "NR",
        "code": "NR",
        "name": "Nauru",
        "states": [
            {
                "_id": "yaren",
                "code": "yaren",
                "name": "Yaren",
                "pk": "NR__yaren"
            }
        ],
        "pk": "NR"
    },
    {
        "_id": "NP",
        "code": "NP",
        "name": "Nepal",
        "states": [
            {
                "_id": "bagmati",
                "code": "bagmati",
                "name": "Bagmati",
                "pk": "NP__bagmati"
            },
            {
                "_id": "bheri",
                "code": "bheri",
                "name": "Bheri",
                "pk": "NP__bheri"
            },
            {
                "_id": "dhawalagiri",
                "code": "dhawalagiri",
                "name": "Dhawalagiri",
                "pk": "NP__dhawalagiri"
            },
            {
                "_id": "gandaki",
                "code": "gandaki",
                "name": "Gandaki",
                "pk": "NP__gandaki"
            },
            {
                "_id": "janakpur",
                "code": "janakpur",
                "name": "Janakpur",
                "pk": "NP__janakpur"
            },
            {
                "_id": "karnali",
                "code": "karnali",
                "name": "Karnali",
                "pk": "NP__karnali"
            },
            {
                "_id": "koshi",
                "code": "koshi",
                "name": "Koshi",
                "pk": "NP__koshi"
            },
            {
                "_id": "lumbini",
                "code": "lumbini",
                "name": "Lumbini",
                "pk": "NP__lumbini"
            },
            {
                "_id": "mahakali",
                "code": "mahakali",
                "name": "Mahakali",
                "pk": "NP__mahakali"
            },
            {
                "_id": "mechi",
                "code": "mechi",
                "name": "Mechi",
                "pk": "NP__mechi"
            },
            {
                "_id": "narayani",
                "code": "narayani",
                "name": "Narayani",
                "pk": "NP__narayani"
            },
            {
                "_id": "rapti",
                "code": "rapti",
                "name": "Rapti",
                "pk": "NP__rapti"
            },
            {
                "_id": "sagarmatha",
                "code": "sagarmatha",
                "name": "Sagarmatha",
                "pk": "NP__sagarmatha"
            },
            {
                "_id": "seti",
                "code": "seti",
                "name": "Seti",
                "pk": "NP__seti"
            }
        ],
        "pk": "NP"
    },
    {
        "_id": "AN",
        "code": "AN",
        "name": "Netherlands Antilles",
        "states": [
            {
                "_id": "bonaire",
                "code": "bonaire",
                "name": "Bonaire",
                "pk": "AN__bonaire"
            },
            {
                "_id": "curacao",
                "code": "curacao",
                "name": "Curacao",
                "pk": "AN__curacao"
            },
            {
                "_id": "saba",
                "code": "saba",
                "name": "Saba",
                "pk": "AN__saba"
            },
            {
                "_id": "sint_eustatius",
                "code": "sint_eustatius",
                "name": "Sint Eustatius",
                "pk": "AN__sint_eustatius"
            },
            {
                "_id": "sint_maarten",
                "code": "sint_maarten",
                "name": "Sint Maarten",
                "pk": "AN__sint_maarten"
            }
        ],
        "pk": "AN"
    },
    {
        "_id": "NL",
        "code": "NL",
        "name": "Netherlands The",
        "states": [
            {
                "_id": "amsterdam",
                "code": "amsterdam",
                "name": "Amsterdam",
                "pk": "NL__amsterdam"
            },
            {
                "_id": "benelux",
                "code": "benelux",
                "name": "Benelux",
                "pk": "NL__benelux"
            },
            {
                "_id": "drenthe",
                "code": "drenthe",
                "name": "Drenthe",
                "pk": "NL__drenthe"
            },
            {
                "_id": "flevoland",
                "code": "flevoland",
                "name": "Flevoland",
                "pk": "NL__flevoland"
            },
            {
                "_id": "friesland",
                "code": "friesland",
                "name": "Friesland",
                "pk": "NL__friesland"
            },
            {
                "_id": "gelderland",
                "code": "gelderland",
                "name": "Gelderland",
                "pk": "NL__gelderland"
            },
            {
                "_id": "groningen",
                "code": "groningen",
                "name": "Groningen",
                "pk": "NL__groningen"
            },
            {
                "_id": "limburg",
                "code": "limburg",
                "name": "Limburg",
                "pk": "NL__limburg"
            },
            {
                "_id": "noord-brabant",
                "code": "noord-brabant",
                "name": "Noord-Brabant",
                "pk": "NL__noord-brabant"
            },
            {
                "_id": "noord-holland",
                "code": "noord-holland",
                "name": "Noord-Holland",
                "pk": "NL__noord-holland"
            },
            {
                "_id": "overijssel",
                "code": "overijssel",
                "name": "Overijssel",
                "pk": "NL__overijssel"
            },
            {
                "_id": "south_holland",
                "code": "south_holland",
                "name": "South Holland",
                "pk": "NL__south_holland"
            },
            {
                "_id": "utrecht",
                "code": "utrecht",
                "name": "Utrecht",
                "pk": "NL__utrecht"
            },
            {
                "_id": "zeeland",
                "code": "zeeland",
                "name": "Zeeland",
                "pk": "NL__zeeland"
            },
            {
                "_id": "zuid-holland",
                "code": "zuid-holland",
                "name": "Zuid-Holland",
                "pk": "NL__zuid-holland"
            }
        ],
        "pk": "NL"
    },
    {
        "_id": "NC",
        "code": "NC",
        "name": "New Caledonia",
        "states": [
            {
                "_id": "iles",
                "code": "iles",
                "name": "Iles",
                "pk": "NC__iles"
            },
            {
                "_id": "nord",
                "code": "nord",
                "name": "Nord",
                "pk": "NC__nord"
            },
            {
                "_id": "sud",
                "code": "sud",
                "name": "Sud",
                "pk": "NC__sud"
            }
        ],
        "pk": "NC"
    },
    {
        "_id": "NZ",
        "code": "NZ",
        "name": "New Zealand",
        "states": [
            {
                "_id": "area_outside_region",
                "code": "area_outside_region",
                "name": "Area Outside Region",
                "pk": "NZ__area_outside_region"
            },
            {
                "_id": "auckland",
                "code": "auckland",
                "name": "Auckland",
                "pk": "NZ__auckland"
            },
            {
                "_id": "bay_of_plenty",
                "code": "bay_of_plenty",
                "name": "Bay of Plenty",
                "pk": "NZ__bay_of_plenty"
            },
            {
                "_id": "canterbury",
                "code": "canterbury",
                "name": "Canterbury",
                "pk": "NZ__canterbury"
            },
            {
                "_id": "christchurch",
                "code": "christchurch",
                "name": "Christchurch",
                "pk": "NZ__christchurch"
            },
            {
                "_id": "gisborne",
                "code": "gisborne",
                "name": "Gisborne",
                "pk": "NZ__gisborne"
            },
            {
                "_id": "hawkes_bay",
                "code": "hawkes_bay",
                "name": "Hawkes Bay",
                "pk": "NZ__hawkes_bay"
            },
            {
                "_id": "manawatu-wanganui",
                "code": "manawatu-wanganui",
                "name": "Manawatu-Wanganui",
                "pk": "NZ__manawatu-wanganui"
            },
            {
                "_id": "marlborough",
                "code": "marlborough",
                "name": "Marlborough",
                "pk": "NZ__marlborough"
            },
            {
                "_id": "nelson",
                "code": "nelson",
                "name": "Nelson",
                "pk": "NZ__nelson"
            },
            {
                "_id": "northland",
                "code": "northland",
                "name": "Northland",
                "pk": "NZ__northland"
            },
            {
                "_id": "otago",
                "code": "otago",
                "name": "Otago",
                "pk": "NZ__otago"
            },
            {
                "_id": "rodney",
                "code": "rodney",
                "name": "Rodney",
                "pk": "NZ__rodney"
            },
            {
                "_id": "southland",
                "code": "southland",
                "name": "Southland",
                "pk": "NZ__southland"
            },
            {
                "_id": "taranaki",
                "code": "taranaki",
                "name": "Taranaki",
                "pk": "NZ__taranaki"
            },
            {
                "_id": "tasman",
                "code": "tasman",
                "name": "Tasman",
                "pk": "NZ__tasman"
            },
            {
                "_id": "waikato",
                "code": "waikato",
                "name": "Waikato",
                "pk": "NZ__waikato"
            },
            {
                "_id": "wellington",
                "code": "wellington",
                "name": "Wellington",
                "pk": "NZ__wellington"
            },
            {
                "_id": "west_coast",
                "code": "west_coast",
                "name": "West Coast",
                "pk": "NZ__west_coast"
            }
        ],
        "pk": "NZ"
    },
    {
        "_id": "NI",
        "code": "NI",
        "name": "Nicaragua",
        "states": [
            {
                "_id": "atlantico_norte",
                "code": "atlantico_norte",
                "name": "Atlantico Norte",
                "pk": "NI__atlantico_norte"
            },
            {
                "_id": "atlantico_sur",
                "code": "atlantico_sur",
                "name": "Atlantico Sur",
                "pk": "NI__atlantico_sur"
            },
            {
                "_id": "boaco",
                "code": "boaco",
                "name": "Boaco",
                "pk": "NI__boaco"
            },
            {
                "_id": "carazo",
                "code": "carazo",
                "name": "Carazo",
                "pk": "NI__carazo"
            },
            {
                "_id": "chinandega",
                "code": "chinandega",
                "name": "Chinandega",
                "pk": "NI__chinandega"
            },
            {
                "_id": "chontales",
                "code": "chontales",
                "name": "Chontales",
                "pk": "NI__chontales"
            },
            {
                "_id": "esteli",
                "code": "esteli",
                "name": "Esteli",
                "pk": "NI__esteli"
            },
            {
                "_id": "granada",
                "code": "granada",
                "name": "Granada",
                "pk": "NI__granada"
            },
            {
                "_id": "jinotega",
                "code": "jinotega",
                "name": "Jinotega",
                "pk": "NI__jinotega"
            },
            {
                "_id": "leon",
                "code": "leon",
                "name": "Leon",
                "pk": "NI__leon"
            },
            {
                "_id": "madriz",
                "code": "madriz",
                "name": "Madriz",
                "pk": "NI__madriz"
            },
            {
                "_id": "managua",
                "code": "managua",
                "name": "Managua",
                "pk": "NI__managua"
            },
            {
                "_id": "masaya",
                "code": "masaya",
                "name": "Masaya",
                "pk": "NI__masaya"
            },
            {
                "_id": "matagalpa",
                "code": "matagalpa",
                "name": "Matagalpa",
                "pk": "NI__matagalpa"
            },
            {
                "_id": "nueva_segovia",
                "code": "nueva_segovia",
                "name": "Nueva Segovia",
                "pk": "NI__nueva_segovia"
            },
            {
                "_id": "rio_san_juan",
                "code": "rio_san_juan",
                "name": "Rio San Juan",
                "pk": "NI__rio_san_juan"
            },
            {
                "_id": "rivas",
                "code": "rivas",
                "name": "Rivas",
                "pk": "NI__rivas"
            }
        ],
        "pk": "NI"
    },
    {
        "_id": "NE",
        "code": "NE",
        "name": "Niger",
        "states": [
            {
                "_id": "agadez",
                "code": "agadez",
                "name": "Agadez",
                "pk": "NE__agadez"
            },
            {
                "_id": "diffa",
                "code": "diffa",
                "name": "Diffa",
                "pk": "NE__diffa"
            },
            {
                "_id": "dosso",
                "code": "dosso",
                "name": "Dosso",
                "pk": "NE__dosso"
            },
            {
                "_id": "maradi",
                "code": "maradi",
                "name": "Maradi",
                "pk": "NE__maradi"
            },
            {
                "_id": "niamey",
                "code": "niamey",
                "name": "Niamey",
                "pk": "NE__niamey"
            },
            {
                "_id": "tahoua",
                "code": "tahoua",
                "name": "Tahoua",
                "pk": "NE__tahoua"
            },
            {
                "_id": "tillabery",
                "code": "tillabery",
                "name": "Tillabery",
                "pk": "NE__tillabery"
            },
            {
                "_id": "zinder",
                "code": "zinder",
                "name": "Zinder",
                "pk": "NE__zinder"
            }
        ],
        "pk": "NE"
    },
    {
        "_id": "NG",
        "code": "NG",
        "name": "Nigeria",
        "states": [
            {
                "_id": "abia",
                "code": "abia",
                "name": "Abia",
                "pk": "NG__abia"
            },
            {
                "_id": "abuja_federal_capital_territor",
                "code": "abuja_federal_capital_territor",
                "name": "Abuja Federal Capital Territor",
                "pk": "NG__abuja_federal_capital_territor"
            },
            {
                "_id": "adamawa",
                "code": "adamawa",
                "name": "Adamawa",
                "pk": "NG__adamawa"
            },
            {
                "_id": "akwa_ibom",
                "code": "akwa_ibom",
                "name": "Akwa Ibom",
                "pk": "NG__akwa_ibom"
            },
            {
                "_id": "anambra",
                "code": "anambra",
                "name": "Anambra",
                "pk": "NG__anambra"
            },
            {
                "_id": "bauchi",
                "code": "bauchi",
                "name": "Bauchi",
                "pk": "NG__bauchi"
            },
            {
                "_id": "bayelsa",
                "code": "bayelsa",
                "name": "Bayelsa",
                "pk": "NG__bayelsa"
            },
            {
                "_id": "benue",
                "code": "benue",
                "name": "Benue",
                "pk": "NG__benue"
            },
            {
                "_id": "borno",
                "code": "borno",
                "name": "Borno",
                "pk": "NG__borno"
            },
            {
                "_id": "cross_river",
                "code": "cross_river",
                "name": "Cross River",
                "pk": "NG__cross_river"
            },
            {
                "_id": "delta",
                "code": "delta",
                "name": "Delta",
                "pk": "NG__delta"
            },
            {
                "_id": "ebonyi",
                "code": "ebonyi",
                "name": "Ebonyi",
                "pk": "NG__ebonyi"
            },
            {
                "_id": "edo",
                "code": "edo",
                "name": "Edo",
                "pk": "NG__edo"
            },
            {
                "_id": "ekiti",
                "code": "ekiti",
                "name": "Ekiti",
                "pk": "NG__ekiti"
            },
            {
                "_id": "enugu",
                "code": "enugu",
                "name": "Enugu",
                "pk": "NG__enugu"
            },
            {
                "_id": "gombe",
                "code": "gombe",
                "name": "Gombe",
                "pk": "NG__gombe"
            },
            {
                "_id": "imo",
                "code": "imo",
                "name": "Imo",
                "pk": "NG__imo"
            },
            {
                "_id": "jigawa",
                "code": "jigawa",
                "name": "Jigawa",
                "pk": "NG__jigawa"
            },
            {
                "_id": "kaduna",
                "code": "kaduna",
                "name": "Kaduna",
                "pk": "NG__kaduna"
            },
            {
                "_id": "kano",
                "code": "kano",
                "name": "Kano",
                "pk": "NG__kano"
            },
            {
                "_id": "katsina",
                "code": "katsina",
                "name": "Katsina",
                "pk": "NG__katsina"
            },
            {
                "_id": "kebbi",
                "code": "kebbi",
                "name": "Kebbi",
                "pk": "NG__kebbi"
            },
            {
                "_id": "kogi",
                "code": "kogi",
                "name": "Kogi",
                "pk": "NG__kogi"
            },
            {
                "_id": "kwara",
                "code": "kwara",
                "name": "Kwara",
                "pk": "NG__kwara"
            },
            {
                "_id": "lagos",
                "code": "lagos",
                "name": "Lagos",
                "pk": "NG__lagos"
            },
            {
                "_id": "nassarawa",
                "code": "nassarawa",
                "name": "Nassarawa",
                "pk": "NG__nassarawa"
            },
            {
                "_id": "niger",
                "code": "niger",
                "name": "Niger",
                "pk": "NG__niger"
            },
            {
                "_id": "ogun",
                "code": "ogun",
                "name": "Ogun",
                "pk": "NG__ogun"
            },
            {
                "_id": "ondo",
                "code": "ondo",
                "name": "Ondo",
                "pk": "NG__ondo"
            },
            {
                "_id": "osun",
                "code": "osun",
                "name": "Osun",
                "pk": "NG__osun"
            },
            {
                "_id": "oyo",
                "code": "oyo",
                "name": "Oyo",
                "pk": "NG__oyo"
            },
            {
                "_id": "plateau",
                "code": "plateau",
                "name": "Plateau",
                "pk": "NG__plateau"
            },
            {
                "_id": "rivers",
                "code": "rivers",
                "name": "Rivers",
                "pk": "NG__rivers"
            },
            {
                "_id": "sokoto",
                "code": "sokoto",
                "name": "Sokoto",
                "pk": "NG__sokoto"
            },
            {
                "_id": "taraba",
                "code": "taraba",
                "name": "Taraba",
                "pk": "NG__taraba"
            },
            {
                "_id": "yobe",
                "code": "yobe",
                "name": "Yobe",
                "pk": "NG__yobe"
            },
            {
                "_id": "zamfara",
                "code": "zamfara",
                "name": "Zamfara",
                "pk": "NG__zamfara"
            }
        ],
        "pk": "NG"
    },
    {
        "_id": "NU",
        "code": "NU",
        "name": "Niue",
        "states": [
            {
                "_id": "niue",
                "code": "niue",
                "name": "Niue",
                "pk": "NU__niue"
            }
        ],
        "pk": "NU"
    },
    {
        "_id": "NF",
        "code": "NF",
        "name": "Norfolk Island",
        "states": [
            {
                "_id": "norfolk_island",
                "code": "norfolk_island",
                "name": "Norfolk Island",
                "pk": "NF__norfolk_island"
            }
        ],
        "pk": "NF"
    },
    {
        "_id": "MP",
        "code": "MP",
        "name": "Northern Mariana Islands",
        "states": [
            {
                "_id": "northern_islands",
                "code": "northern_islands",
                "name": "Northern Islands",
                "pk": "MP__northern_islands"
            },
            {
                "_id": "rota",
                "code": "rota",
                "name": "Rota",
                "pk": "MP__rota"
            },
            {
                "_id": "saipan",
                "code": "saipan",
                "name": "Saipan",
                "pk": "MP__saipan"
            },
            {
                "_id": "tinian",
                "code": "tinian",
                "name": "Tinian",
                "pk": "MP__tinian"
            }
        ],
        "pk": "MP"
    },
    {
        "_id": "NO",
        "code": "NO",
        "name": "Norway",
        "states": [
            {
                "_id": "akershus",
                "code": "akershus",
                "name": "Akershus",
                "pk": "NO__akershus"
            },
            {
                "_id": "aust_agder",
                "code": "aust_agder",
                "name": "Aust Agder",
                "pk": "NO__aust_agder"
            },
            {
                "_id": "bergen",
                "code": "bergen",
                "name": "Bergen",
                "pk": "NO__bergen"
            },
            {
                "_id": "buskerud",
                "code": "buskerud",
                "name": "Buskerud",
                "pk": "NO__buskerud"
            },
            {
                "_id": "finnmark",
                "code": "finnmark",
                "name": "Finnmark",
                "pk": "NO__finnmark"
            },
            {
                "_id": "hedmark",
                "code": "hedmark",
                "name": "Hedmark",
                "pk": "NO__hedmark"
            },
            {
                "_id": "hordaland",
                "code": "hordaland",
                "name": "Hordaland",
                "pk": "NO__hordaland"
            },
            {
                "_id": "moere_og_romsdal",
                "code": "moere_og_romsdal",
                "name": "Moere og Romsdal",
                "pk": "NO__moere_og_romsdal"
            },
            {
                "_id": "nord_trondelag",
                "code": "nord_trondelag",
                "name": "Nord Trondelag",
                "pk": "NO__nord_trondelag"
            },
            {
                "_id": "nordland",
                "code": "nordland",
                "name": "Nordland",
                "pk": "NO__nordland"
            },
            {
                "_id": "oestfold",
                "code": "oestfold",
                "name": "Oestfold",
                "pk": "NO__oestfold"
            },
            {
                "_id": "oppland",
                "code": "oppland",
                "name": "Oppland",
                "pk": "NO__oppland"
            },
            {
                "_id": "oslo",
                "code": "oslo",
                "name": "Oslo",
                "pk": "NO__oslo"
            },
            {
                "_id": "rogaland",
                "code": "rogaland",
                "name": "Rogaland",
                "pk": "NO__rogaland"
            },
            {
                "_id": "soer_troendelag",
                "code": "soer_troendelag",
                "name": "Soer Troendelag",
                "pk": "NO__soer_troendelag"
            },
            {
                "_id": "sogn_og_fjordane",
                "code": "sogn_og_fjordane",
                "name": "Sogn og Fjordane",
                "pk": "NO__sogn_og_fjordane"
            },
            {
                "_id": "stavern",
                "code": "stavern",
                "name": "Stavern",
                "pk": "NO__stavern"
            },
            {
                "_id": "sykkylven",
                "code": "sykkylven",
                "name": "Sykkylven",
                "pk": "NO__sykkylven"
            },
            {
                "_id": "telemark",
                "code": "telemark",
                "name": "Telemark",
                "pk": "NO__telemark"
            },
            {
                "_id": "troms",
                "code": "troms",
                "name": "Troms",
                "pk": "NO__troms"
            },
            {
                "_id": "vest_agder",
                "code": "vest_agder",
                "name": "Vest Agder",
                "pk": "NO__vest_agder"
            },
            {
                "_id": "vestfold",
                "code": "vestfold",
                "name": "Vestfold",
                "pk": "NO__vestfold"
            },
            {
                "_id": "ãƒâ˜stfold",
                "code": "ãƒâ˜stfold",
                "name": "ÃƒÂ˜stfold",
                "pk": "NO__ãƒâ˜stfold"
            }
        ],
        "pk": "NO"
    },
    {
        "_id": "OM",
        "code": "OM",
        "name": "Oman",
        "states": [
            {
                "_id": "al_buraimi",
                "code": "al_buraimi",
                "name": "Al Buraimi",
                "pk": "OM__al_buraimi"
            },
            {
                "_id": "dhufar",
                "code": "dhufar",
                "name": "Dhufar",
                "pk": "OM__dhufar"
            },
            {
                "_id": "masqat",
                "code": "masqat",
                "name": "Masqat",
                "pk": "OM__masqat"
            },
            {
                "_id": "musandam",
                "code": "musandam",
                "name": "Musandam",
                "pk": "OM__musandam"
            },
            {
                "_id": "rusayl",
                "code": "rusayl",
                "name": "Rusayl",
                "pk": "OM__rusayl"
            },
            {
                "_id": "wadi_kabir",
                "code": "wadi_kabir",
                "name": "Wadi Kabir",
                "pk": "OM__wadi_kabir"
            },
            {
                "_id": "ad-dakhiliyah",
                "code": "ad-dakhiliyah",
                "name": "ad-Dakhiliyah",
                "pk": "OM__ad-dakhiliyah"
            },
            {
                "_id": "adh-dhahirah",
                "code": "adh-dhahirah",
                "name": "adh-Dhahirah",
                "pk": "OM__adh-dhahirah"
            },
            {
                "_id": "al-batinah",
                "code": "al-batinah",
                "name": "al-Batinah",
                "pk": "OM__al-batinah"
            },
            {
                "_id": "ash-sharqiyah",
                "code": "ash-sharqiyah",
                "name": "ash-Sharqiyah",
                "pk": "OM__ash-sharqiyah"
            }
        ],
        "pk": "OM"
    },
    {
        "_id": "PK",
        "code": "PK",
        "name": "Pakistan",
        "states": [
            {
                "_id": "baluchistan",
                "code": "baluchistan",
                "name": "Baluchistan",
                "pk": "PK__baluchistan"
            },
            {
                "_id": "federal_capital_area",
                "code": "federal_capital_area",
                "name": "Federal Capital Area",
                "pk": "PK__federal_capital_area"
            },
            {
                "_id": "federally_administered_tribal_",
                "code": "federally_administered_tribal_",
                "name": "Federally administered Tribal ",
                "pk": "PK__federally_administered_tribal_"
            },
            {
                "_id": "north-west_frontier",
                "code": "north-west_frontier",
                "name": "North-West Frontier",
                "pk": "PK__north-west_frontier"
            },
            {
                "_id": "northern_areas",
                "code": "northern_areas",
                "name": "Northern Areas",
                "pk": "PK__northern_areas"
            },
            {
                "_id": "punjab",
                "code": "punjab",
                "name": "Punjab",
                "pk": "PK__punjab"
            },
            {
                "_id": "sind",
                "code": "sind",
                "name": "Sind",
                "pk": "PK__sind"
            }
        ],
        "pk": "PK"
    },
    {
        "_id": "PW",
        "code": "PW",
        "name": "Palau",
        "states": [
            {
                "_id": "aimeliik",
                "code": "aimeliik",
                "name": "Aimeliik",
                "pk": "PW__aimeliik"
            },
            {
                "_id": "airai",
                "code": "airai",
                "name": "Airai",
                "pk": "PW__airai"
            },
            {
                "_id": "angaur",
                "code": "angaur",
                "name": "Angaur",
                "pk": "PW__angaur"
            },
            {
                "_id": "hatobohei",
                "code": "hatobohei",
                "name": "Hatobohei",
                "pk": "PW__hatobohei"
            },
            {
                "_id": "kayangel",
                "code": "kayangel",
                "name": "Kayangel",
                "pk": "PW__kayangel"
            },
            {
                "_id": "koror",
                "code": "koror",
                "name": "Koror",
                "pk": "PW__koror"
            },
            {
                "_id": "melekeok",
                "code": "melekeok",
                "name": "Melekeok",
                "pk": "PW__melekeok"
            },
            {
                "_id": "ngaraard",
                "code": "ngaraard",
                "name": "Ngaraard",
                "pk": "PW__ngaraard"
            },
            {
                "_id": "ngardmau",
                "code": "ngardmau",
                "name": "Ngardmau",
                "pk": "PW__ngardmau"
            },
            {
                "_id": "ngaremlengui",
                "code": "ngaremlengui",
                "name": "Ngaremlengui",
                "pk": "PW__ngaremlengui"
            },
            {
                "_id": "ngatpang",
                "code": "ngatpang",
                "name": "Ngatpang",
                "pk": "PW__ngatpang"
            },
            {
                "_id": "ngchesar",
                "code": "ngchesar",
                "name": "Ngchesar",
                "pk": "PW__ngchesar"
            },
            {
                "_id": "ngerchelong",
                "code": "ngerchelong",
                "name": "Ngerchelong",
                "pk": "PW__ngerchelong"
            },
            {
                "_id": "ngiwal",
                "code": "ngiwal",
                "name": "Ngiwal",
                "pk": "PW__ngiwal"
            },
            {
                "_id": "peleliu",
                "code": "peleliu",
                "name": "Peleliu",
                "pk": "PW__peleliu"
            },
            {
                "_id": "sonsorol",
                "code": "sonsorol",
                "name": "Sonsorol",
                "pk": "PW__sonsorol"
            }
        ],
        "pk": "PW"
    },
    {
        "_id": "PS",
        "code": "PS",
        "name": "Palestinian Territory Occupied",
        "states": [
            {
                "_id": "ariha",
                "code": "ariha",
                "name": "Ariha",
                "pk": "PS__ariha"
            },
            {
                "_id": "bayt_lahm",
                "code": "bayt_lahm",
                "name": "Bayt Lahm",
                "pk": "PS__bayt_lahm"
            },
            {
                "_id": "bethlehem",
                "code": "bethlehem",
                "name": "Bethlehem",
                "pk": "PS__bethlehem"
            },
            {
                "_id": "dayr-al-balah",
                "code": "dayr-al-balah",
                "name": "Dayr-al-Balah",
                "pk": "PS__dayr-al-balah"
            },
            {
                "_id": "ghazzah",
                "code": "ghazzah",
                "name": "Ghazzah",
                "pk": "PS__ghazzah"
            },
            {
                "_id": "ghazzah_ash-shamaliyah",
                "code": "ghazzah_ash-shamaliyah",
                "name": "Ghazzah ash-Shamaliyah",
                "pk": "PS__ghazzah_ash-shamaliyah"
            },
            {
                "_id": "janin",
                "code": "janin",
                "name": "Janin",
                "pk": "PS__janin"
            },
            {
                "_id": "khan_yunis",
                "code": "khan_yunis",
                "name": "Khan Yunis",
                "pk": "PS__khan_yunis"
            },
            {
                "_id": "nabulus",
                "code": "nabulus",
                "name": "Nabulus",
                "pk": "PS__nabulus"
            },
            {
                "_id": "qalqilyah",
                "code": "qalqilyah",
                "name": "Qalqilyah",
                "pk": "PS__qalqilyah"
            },
            {
                "_id": "rafah",
                "code": "rafah",
                "name": "Rafah",
                "pk": "PS__rafah"
            },
            {
                "_id": "ram_allah_wal-birah",
                "code": "ram_allah_wal-birah",
                "name": "Ram Allah wal-Birah",
                "pk": "PS__ram_allah_wal-birah"
            },
            {
                "_id": "salfit",
                "code": "salfit",
                "name": "Salfit",
                "pk": "PS__salfit"
            },
            {
                "_id": "tubas",
                "code": "tubas",
                "name": "Tubas",
                "pk": "PS__tubas"
            },
            {
                "_id": "tulkarm",
                "code": "tulkarm",
                "name": "Tulkarm",
                "pk": "PS__tulkarm"
            },
            {
                "_id": "al-khalil",
                "code": "al-khalil",
                "name": "al-Khalil",
                "pk": "PS__al-khalil"
            },
            {
                "_id": "al-quds",
                "code": "al-quds",
                "name": "al-Quds",
                "pk": "PS__al-quds"
            }
        ],
        "pk": "PS"
    },
    {
        "_id": "PA",
        "code": "PA",
        "name": "Panama",
        "states": [
            {
                "_id": "bocas_del_toro",
                "code": "bocas_del_toro",
                "name": "Bocas del Toro",
                "pk": "PA__bocas_del_toro"
            },
            {
                "_id": "chiriqui",
                "code": "chiriqui",
                "name": "Chiriqui",
                "pk": "PA__chiriqui"
            },
            {
                "_id": "cocle",
                "code": "cocle",
                "name": "Cocle",
                "pk": "PA__cocle"
            },
            {
                "_id": "colon",
                "code": "colon",
                "name": "Colon",
                "pk": "PA__colon"
            },
            {
                "_id": "darien",
                "code": "darien",
                "name": "Darien",
                "pk": "PA__darien"
            },
            {
                "_id": "embera",
                "code": "embera",
                "name": "Embera",
                "pk": "PA__embera"
            },
            {
                "_id": "herrera",
                "code": "herrera",
                "name": "Herrera",
                "pk": "PA__herrera"
            },
            {
                "_id": "kuna_yala",
                "code": "kuna_yala",
                "name": "Kuna Yala",
                "pk": "PA__kuna_yala"
            },
            {
                "_id": "los_santos",
                "code": "los_santos",
                "name": "Los Santos",
                "pk": "PA__los_santos"
            },
            {
                "_id": "ngobe_bugle",
                "code": "ngobe_bugle",
                "name": "Ngobe Bugle",
                "pk": "PA__ngobe_bugle"
            },
            {
                "_id": "panama",
                "code": "panama",
                "name": "Panama",
                "pk": "PA__panama"
            },
            {
                "_id": "veraguas",
                "code": "veraguas",
                "name": "Veraguas",
                "pk": "PA__veraguas"
            }
        ],
        "pk": "PA"
    },
    {
        "_id": "PG",
        "code": "PG",
        "name": "Papua new Guinea",
        "states": [
            {
                "_id": "east_new_britain",
                "code": "east_new_britain",
                "name": "East New Britain",
                "pk": "PG__east_new_britain"
            },
            {
                "_id": "east_sepik",
                "code": "east_sepik",
                "name": "East Sepik",
                "pk": "PG__east_sepik"
            },
            {
                "_id": "eastern_highlands",
                "code": "eastern_highlands",
                "name": "Eastern Highlands",
                "pk": "PG__eastern_highlands"
            },
            {
                "_id": "enga",
                "code": "enga",
                "name": "Enga",
                "pk": "PG__enga"
            },
            {
                "_id": "fly_river",
                "code": "fly_river",
                "name": "Fly River",
                "pk": "PG__fly_river"
            },
            {
                "_id": "gulf",
                "code": "gulf",
                "name": "Gulf",
                "pk": "PG__gulf"
            },
            {
                "_id": "madang",
                "code": "madang",
                "name": "Madang",
                "pk": "PG__madang"
            },
            {
                "_id": "manus",
                "code": "manus",
                "name": "Manus",
                "pk": "PG__manus"
            },
            {
                "_id": "milne_bay",
                "code": "milne_bay",
                "name": "Milne Bay",
                "pk": "PG__milne_bay"
            },
            {
                "_id": "morobe",
                "code": "morobe",
                "name": "Morobe",
                "pk": "PG__morobe"
            },
            {
                "_id": "national_capital_district",
                "code": "national_capital_district",
                "name": "National Capital District",
                "pk": "PG__national_capital_district"
            },
            {
                "_id": "new_ireland",
                "code": "new_ireland",
                "name": "New Ireland",
                "pk": "PG__new_ireland"
            },
            {
                "_id": "north_solomons",
                "code": "north_solomons",
                "name": "North Solomons",
                "pk": "PG__north_solomons"
            },
            {
                "_id": "oro",
                "code": "oro",
                "name": "Oro",
                "pk": "PG__oro"
            },
            {
                "_id": "sandaun",
                "code": "sandaun",
                "name": "Sandaun",
                "pk": "PG__sandaun"
            },
            {
                "_id": "simbu",
                "code": "simbu",
                "name": "Simbu",
                "pk": "PG__simbu"
            },
            {
                "_id": "southern_highlands",
                "code": "southern_highlands",
                "name": "Southern Highlands",
                "pk": "PG__southern_highlands"
            },
            {
                "_id": "west_new_britain",
                "code": "west_new_britain",
                "name": "West New Britain",
                "pk": "PG__west_new_britain"
            },
            {
                "_id": "western_highlands",
                "code": "western_highlands",
                "name": "Western Highlands",
                "pk": "PG__western_highlands"
            }
        ],
        "pk": "PG"
    },
    {
        "_id": "PY",
        "code": "PY",
        "name": "Paraguay",
        "states": [
            {
                "_id": "alto_paraguay",
                "code": "alto_paraguay",
                "name": "Alto Paraguay",
                "pk": "PY__alto_paraguay"
            },
            {
                "_id": "alto_parana",
                "code": "alto_parana",
                "name": "Alto Parana",
                "pk": "PY__alto_parana"
            },
            {
                "_id": "amambay",
                "code": "amambay",
                "name": "Amambay",
                "pk": "PY__amambay"
            },
            {
                "_id": "asuncion",
                "code": "asuncion",
                "name": "Asuncion",
                "pk": "PY__asuncion"
            },
            {
                "_id": "boqueron",
                "code": "boqueron",
                "name": "Boqueron",
                "pk": "PY__boqueron"
            },
            {
                "_id": "caaguazu",
                "code": "caaguazu",
                "name": "Caaguazu",
                "pk": "PY__caaguazu"
            },
            {
                "_id": "caazapa",
                "code": "caazapa",
                "name": "Caazapa",
                "pk": "PY__caazapa"
            },
            {
                "_id": "canendiyu",
                "code": "canendiyu",
                "name": "Canendiyu",
                "pk": "PY__canendiyu"
            },
            {
                "_id": "central",
                "code": "central",
                "name": "Central",
                "pk": "PY__central"
            },
            {
                "_id": "concepcion",
                "code": "concepcion",
                "name": "Concepcion",
                "pk": "PY__concepcion"
            },
            {
                "_id": "cordillera",
                "code": "cordillera",
                "name": "Cordillera",
                "pk": "PY__cordillera"
            },
            {
                "_id": "guaira",
                "code": "guaira",
                "name": "Guaira",
                "pk": "PY__guaira"
            },
            {
                "_id": "itapua",
                "code": "itapua",
                "name": "Itapua",
                "pk": "PY__itapua"
            },
            {
                "_id": "misiones",
                "code": "misiones",
                "name": "Misiones",
                "pk": "PY__misiones"
            },
            {
                "_id": "neembucu",
                "code": "neembucu",
                "name": "Neembucu",
                "pk": "PY__neembucu"
            },
            {
                "_id": "paraguari",
                "code": "paraguari",
                "name": "Paraguari",
                "pk": "PY__paraguari"
            },
            {
                "_id": "presidente_hayes",
                "code": "presidente_hayes",
                "name": "Presidente Hayes",
                "pk": "PY__presidente_hayes"
            },
            {
                "_id": "san_pedro",
                "code": "san_pedro",
                "name": "San Pedro",
                "pk": "PY__san_pedro"
            }
        ],
        "pk": "PY"
    },
    {
        "_id": "PE",
        "code": "PE",
        "name": "Peru",
        "states": [
            {
                "_id": "amazonas",
                "code": "amazonas",
                "name": "Amazonas",
                "pk": "PE__amazonas"
            },
            {
                "_id": "ancash",
                "code": "ancash",
                "name": "Ancash",
                "pk": "PE__ancash"
            },
            {
                "_id": "apurimac",
                "code": "apurimac",
                "name": "Apurimac",
                "pk": "PE__apurimac"
            },
            {
                "_id": "arequipa",
                "code": "arequipa",
                "name": "Arequipa",
                "pk": "PE__arequipa"
            },
            {
                "_id": "ayacucho",
                "code": "ayacucho",
                "name": "Ayacucho",
                "pk": "PE__ayacucho"
            },
            {
                "_id": "cajamarca",
                "code": "cajamarca",
                "name": "Cajamarca",
                "pk": "PE__cajamarca"
            },
            {
                "_id": "cusco",
                "code": "cusco",
                "name": "Cusco",
                "pk": "PE__cusco"
            },
            {
                "_id": "huancavelica",
                "code": "huancavelica",
                "name": "Huancavelica",
                "pk": "PE__huancavelica"
            },
            {
                "_id": "huanuco",
                "code": "huanuco",
                "name": "Huanuco",
                "pk": "PE__huanuco"
            },
            {
                "_id": "ica",
                "code": "ica",
                "name": "Ica",
                "pk": "PE__ica"
            },
            {
                "_id": "junin",
                "code": "junin",
                "name": "Junin",
                "pk": "PE__junin"
            },
            {
                "_id": "la_libertad",
                "code": "la_libertad",
                "name": "La Libertad",
                "pk": "PE__la_libertad"
            },
            {
                "_id": "lambayeque",
                "code": "lambayeque",
                "name": "Lambayeque",
                "pk": "PE__lambayeque"
            },
            {
                "_id": "lima_y_callao",
                "code": "lima_y_callao",
                "name": "Lima y Callao",
                "pk": "PE__lima_y_callao"
            },
            {
                "_id": "loreto",
                "code": "loreto",
                "name": "Loreto",
                "pk": "PE__loreto"
            },
            {
                "_id": "madre_de_dios",
                "code": "madre_de_dios",
                "name": "Madre de Dios",
                "pk": "PE__madre_de_dios"
            },
            {
                "_id": "moquegua",
                "code": "moquegua",
                "name": "Moquegua",
                "pk": "PE__moquegua"
            },
            {
                "_id": "pasco",
                "code": "pasco",
                "name": "Pasco",
                "pk": "PE__pasco"
            },
            {
                "_id": "piura",
                "code": "piura",
                "name": "Piura",
                "pk": "PE__piura"
            },
            {
                "_id": "puno",
                "code": "puno",
                "name": "Puno",
                "pk": "PE__puno"
            },
            {
                "_id": "san_martin",
                "code": "san_martin",
                "name": "San Martin",
                "pk": "PE__san_martin"
            },
            {
                "_id": "tacna",
                "code": "tacna",
                "name": "Tacna",
                "pk": "PE__tacna"
            },
            {
                "_id": "tumbes",
                "code": "tumbes",
                "name": "Tumbes",
                "pk": "PE__tumbes"
            },
            {
                "_id": "ucayali",
                "code": "ucayali",
                "name": "Ucayali",
                "pk": "PE__ucayali"
            }
        ],
        "pk": "PE"
    },
    {
        "_id": "PH",
        "code": "PH",
        "name": "Philippines",
        "states": [
            {
                "_id": "batangas",
                "code": "batangas",
                "name": "Batangas",
                "pk": "PH__batangas"
            },
            {
                "_id": "bicol",
                "code": "bicol",
                "name": "Bicol",
                "pk": "PH__bicol"
            },
            {
                "_id": "bulacan",
                "code": "bulacan",
                "name": "Bulacan",
                "pk": "PH__bulacan"
            },
            {
                "_id": "cagayan",
                "code": "cagayan",
                "name": "Cagayan",
                "pk": "PH__cagayan"
            },
            {
                "_id": "caraga",
                "code": "caraga",
                "name": "Caraga",
                "pk": "PH__caraga"
            },
            {
                "_id": "central_luzon",
                "code": "central_luzon",
                "name": "Central Luzon",
                "pk": "PH__central_luzon"
            },
            {
                "_id": "central_mindanao",
                "code": "central_mindanao",
                "name": "Central Mindanao",
                "pk": "PH__central_mindanao"
            },
            {
                "_id": "central_visayas",
                "code": "central_visayas",
                "name": "Central Visayas",
                "pk": "PH__central_visayas"
            },
            {
                "_id": "cordillera",
                "code": "cordillera",
                "name": "Cordillera",
                "pk": "PH__cordillera"
            },
            {
                "_id": "davao",
                "code": "davao",
                "name": "Davao",
                "pk": "PH__davao"
            },
            {
                "_id": "eastern_visayas",
                "code": "eastern_visayas",
                "name": "Eastern Visayas",
                "pk": "PH__eastern_visayas"
            },
            {
                "_id": "greater_metropolitan_area",
                "code": "greater_metropolitan_area",
                "name": "Greater Metropolitan Area",
                "pk": "PH__greater_metropolitan_area"
            },
            {
                "_id": "ilocos",
                "code": "ilocos",
                "name": "Ilocos",
                "pk": "PH__ilocos"
            },
            {
                "_id": "laguna",
                "code": "laguna",
                "name": "Laguna",
                "pk": "PH__laguna"
            },
            {
                "_id": "luzon",
                "code": "luzon",
                "name": "Luzon",
                "pk": "PH__luzon"
            },
            {
                "_id": "mactan",
                "code": "mactan",
                "name": "Mactan",
                "pk": "PH__mactan"
            },
            {
                "_id": "metropolitan_manila_area",
                "code": "metropolitan_manila_area",
                "name": "Metropolitan Manila Area",
                "pk": "PH__metropolitan_manila_area"
            },
            {
                "_id": "muslim_mindanao",
                "code": "muslim_mindanao",
                "name": "Muslim Mindanao",
                "pk": "PH__muslim_mindanao"
            },
            {
                "_id": "northern_mindanao",
                "code": "northern_mindanao",
                "name": "Northern Mindanao",
                "pk": "PH__northern_mindanao"
            },
            {
                "_id": "southern_mindanao",
                "code": "southern_mindanao",
                "name": "Southern Mindanao",
                "pk": "PH__southern_mindanao"
            },
            {
                "_id": "southern_tagalog",
                "code": "southern_tagalog",
                "name": "Southern Tagalog",
                "pk": "PH__southern_tagalog"
            },
            {
                "_id": "western_mindanao",
                "code": "western_mindanao",
                "name": "Western Mindanao",
                "pk": "PH__western_mindanao"
            },
            {
                "_id": "western_visayas",
                "code": "western_visayas",
                "name": "Western Visayas",
                "pk": "PH__western_visayas"
            }
        ],
        "pk": "PH"
    },
    {
        "_id": "PN",
        "code": "PN",
        "name": "Pitcairn Island",
        "states": [
            {
                "_id": "pitcairn_island",
                "code": "pitcairn_island",
                "name": "Pitcairn Island",
                "pk": "PN__pitcairn_island"
            }
        ],
        "pk": "PN"
    },
    {
        "_id": "PL",
        "code": "PL",
        "name": "Poland",
        "states": [
            {
                "_id": "biale_blota",
                "code": "biale_blota",
                "name": "Biale Blota",
                "pk": "PL__biale_blota"
            },
            {
                "_id": "dobroszyce",
                "code": "dobroszyce",
                "name": "Dobroszyce",
                "pk": "PL__dobroszyce"
            },
            {
                "_id": "dolnoslaskie",
                "code": "dolnoslaskie",
                "name": "Dolnoslaskie",
                "pk": "PL__dolnoslaskie"
            },
            {
                "_id": "dziekanow_lesny",
                "code": "dziekanow_lesny",
                "name": "Dziekanow Lesny",
                "pk": "PL__dziekanow_lesny"
            },
            {
                "_id": "hopowo",
                "code": "hopowo",
                "name": "Hopowo",
                "pk": "PL__hopowo"
            },
            {
                "_id": "kartuzy",
                "code": "kartuzy",
                "name": "Kartuzy",
                "pk": "PL__kartuzy"
            },
            {
                "_id": "koscian",
                "code": "koscian",
                "name": "Koscian",
                "pk": "PL__koscian"
            },
            {
                "_id": "krakow",
                "code": "krakow",
                "name": "Krakow",
                "pk": "PL__krakow"
            },
            {
                "_id": "kujawsko-pomorskie",
                "code": "kujawsko-pomorskie",
                "name": "Kujawsko-Pomorskie",
                "pk": "PL__kujawsko-pomorskie"
            },
            {
                "_id": "lodzkie",
                "code": "lodzkie",
                "name": "Lodzkie",
                "pk": "PL__lodzkie"
            },
            {
                "_id": "lubelskie",
                "code": "lubelskie",
                "name": "Lubelskie",
                "pk": "PL__lubelskie"
            },
            {
                "_id": "lubuskie",
                "code": "lubuskie",
                "name": "Lubuskie",
                "pk": "PL__lubuskie"
            },
            {
                "_id": "malomice",
                "code": "malomice",
                "name": "Malomice",
                "pk": "PL__malomice"
            },
            {
                "_id": "malopolskie",
                "code": "malopolskie",
                "name": "Malopolskie",
                "pk": "PL__malopolskie"
            },
            {
                "_id": "mazowieckie",
                "code": "mazowieckie",
                "name": "Mazowieckie",
                "pk": "PL__mazowieckie"
            },
            {
                "_id": "mirkow",
                "code": "mirkow",
                "name": "Mirkow",
                "pk": "PL__mirkow"
            },
            {
                "_id": "opolskie",
                "code": "opolskie",
                "name": "Opolskie",
                "pk": "PL__opolskie"
            },
            {
                "_id": "ostrowiec",
                "code": "ostrowiec",
                "name": "Ostrowiec",
                "pk": "PL__ostrowiec"
            },
            {
                "_id": "podkarpackie",
                "code": "podkarpackie",
                "name": "Podkarpackie",
                "pk": "PL__podkarpackie"
            },
            {
                "_id": "podlaskie",
                "code": "podlaskie",
                "name": "Podlaskie",
                "pk": "PL__podlaskie"
            },
            {
                "_id": "polska",
                "code": "polska",
                "name": "Polska",
                "pk": "PL__polska"
            },
            {
                "_id": "pomorskie",
                "code": "pomorskie",
                "name": "Pomorskie",
                "pk": "PL__pomorskie"
            },
            {
                "_id": "poznan",
                "code": "poznan",
                "name": "Poznan",
                "pk": "PL__poznan"
            },
            {
                "_id": "pruszkow",
                "code": "pruszkow",
                "name": "Pruszkow",
                "pk": "PL__pruszkow"
            },
            {
                "_id": "rymanowska",
                "code": "rymanowska",
                "name": "Rymanowska",
                "pk": "PL__rymanowska"
            },
            {
                "_id": "rzeszow",
                "code": "rzeszow",
                "name": "Rzeszow",
                "pk": "PL__rzeszow"
            },
            {
                "_id": "slaskie",
                "code": "slaskie",
                "name": "Slaskie",
                "pk": "PL__slaskie"
            },
            {
                "_id": "stare_pole",
                "code": "stare_pole",
                "name": "Stare Pole",
                "pk": "PL__stare_pole"
            },
            {
                "_id": "swietokrzyskie",
                "code": "swietokrzyskie",
                "name": "Swietokrzyskie",
                "pk": "PL__swietokrzyskie"
            },
            {
                "_id": "warminsko-mazurskie",
                "code": "warminsko-mazurskie",
                "name": "Warminsko-Mazurskie",
                "pk": "PL__warminsko-mazurskie"
            },
            {
                "_id": "warsaw",
                "code": "warsaw",
                "name": "Warsaw",
                "pk": "PL__warsaw"
            },
            {
                "_id": "wejherowo",
                "code": "wejherowo",
                "name": "Wejherowo",
                "pk": "PL__wejherowo"
            },
            {
                "_id": "wielkopolskie",
                "code": "wielkopolskie",
                "name": "Wielkopolskie",
                "pk": "PL__wielkopolskie"
            },
            {
                "_id": "wroclaw",
                "code": "wroclaw",
                "name": "Wroclaw",
                "pk": "PL__wroclaw"
            },
            {
                "_id": "zachodnio-pomorskie",
                "code": "zachodnio-pomorskie",
                "name": "Zachodnio-Pomorskie",
                "pk": "PL__zachodnio-pomorskie"
            },
            {
                "_id": "zukowo",
                "code": "zukowo",
                "name": "Zukowo",
                "pk": "PL__zukowo"
            }
        ],
        "pk": "PL"
    },
    {
        "_id": "PT",
        "code": "PT",
        "name": "Portugal",
        "states": [
            {
                "_id": "abrantes",
                "code": "abrantes",
                "name": "Abrantes",
                "pk": "PT__abrantes"
            },
            {
                "_id": "acores",
                "code": "acores",
                "name": "Acores",
                "pk": "PT__acores"
            },
            {
                "_id": "alentejo",
                "code": "alentejo",
                "name": "Alentejo",
                "pk": "PT__alentejo"
            },
            {
                "_id": "algarve",
                "code": "algarve",
                "name": "Algarve",
                "pk": "PT__algarve"
            },
            {
                "_id": "braga",
                "code": "braga",
                "name": "Braga",
                "pk": "PT__braga"
            },
            {
                "_id": "centro",
                "code": "centro",
                "name": "Centro",
                "pk": "PT__centro"
            },
            {
                "_id": "distrito_de_leiria",
                "code": "distrito_de_leiria",
                "name": "Distrito de Leiria",
                "pk": "PT__distrito_de_leiria"
            },
            {
                "_id": "distrito_de_viana_do_castelo",
                "code": "distrito_de_viana_do_castelo",
                "name": "Distrito de Viana do Castelo",
                "pk": "PT__distrito_de_viana_do_castelo"
            },
            {
                "_id": "distrito_de_vila_real",
                "code": "distrito_de_vila_real",
                "name": "Distrito de Vila Real",
                "pk": "PT__distrito_de_vila_real"
            },
            {
                "_id": "distrito_do_porto",
                "code": "distrito_do_porto",
                "name": "Distrito do Porto",
                "pk": "PT__distrito_do_porto"
            },
            {
                "_id": "lisboa_e_vale_do_tejo",
                "code": "lisboa_e_vale_do_tejo",
                "name": "Lisboa e Vale do Tejo",
                "pk": "PT__lisboa_e_vale_do_tejo"
            },
            {
                "_id": "madeira",
                "code": "madeira",
                "name": "Madeira",
                "pk": "PT__madeira"
            },
            {
                "_id": "norte",
                "code": "norte",
                "name": "Norte",
                "pk": "PT__norte"
            },
            {
                "_id": "paivas",
                "code": "paivas",
                "name": "Paivas",
                "pk": "PT__paivas"
            }
        ],
        "pk": "PT"
    },
    {
        "_id": "PR",
        "code": "PR",
        "name": "Puerto Rico",
        "states": [
            {
                "_id": "arecibo",
                "code": "arecibo",
                "name": "Arecibo",
                "pk": "PR__arecibo"
            },
            {
                "_id": "bayamon",
                "code": "bayamon",
                "name": "Bayamon",
                "pk": "PR__bayamon"
            },
            {
                "_id": "carolina",
                "code": "carolina",
                "name": "Carolina",
                "pk": "PR__carolina"
            },
            {
                "_id": "florida",
                "code": "florida",
                "name": "Florida",
                "pk": "PR__florida"
            },
            {
                "_id": "guayama",
                "code": "guayama",
                "name": "Guayama",
                "pk": "PR__guayama"
            },
            {
                "_id": "humacao",
                "code": "humacao",
                "name": "Humacao",
                "pk": "PR__humacao"
            },
            {
                "_id": "mayaguez-aguadilla",
                "code": "mayaguez-aguadilla",
                "name": "Mayaguez-Aguadilla",
                "pk": "PR__mayaguez-aguadilla"
            },
            {
                "_id": "ponce",
                "code": "ponce",
                "name": "Ponce",
                "pk": "PR__ponce"
            },
            {
                "_id": "salinas",
                "code": "salinas",
                "name": "Salinas",
                "pk": "PR__salinas"
            },
            {
                "_id": "san_juan",
                "code": "san_juan",
                "name": "San Juan",
                "pk": "PR__san_juan"
            }
        ],
        "pk": "PR"
    },
    {
        "_id": "QA",
        "code": "QA",
        "name": "Qatar",
        "states": [
            {
                "_id": "doha",
                "code": "doha",
                "name": "Doha",
                "pk": "QA__doha"
            },
            {
                "_id": "jarian-al-batnah",
                "code": "jarian-al-batnah",
                "name": "Jarian-al-Batnah",
                "pk": "QA__jarian-al-batnah"
            },
            {
                "_id": "umm_salal",
                "code": "umm_salal",
                "name": "Umm Salal",
                "pk": "QA__umm_salal"
            },
            {
                "_id": "ad-dawhah",
                "code": "ad-dawhah",
                "name": "ad-Dawhah",
                "pk": "QA__ad-dawhah"
            },
            {
                "_id": "al-ghuwayriyah",
                "code": "al-ghuwayriyah",
                "name": "al-Ghuwayriyah",
                "pk": "QA__al-ghuwayriyah"
            },
            {
                "_id": "al-jumayliyah",
                "code": "al-jumayliyah",
                "name": "al-Jumayliyah",
                "pk": "QA__al-jumayliyah"
            },
            {
                "_id": "al-khawr",
                "code": "al-khawr",
                "name": "al-Khawr",
                "pk": "QA__al-khawr"
            },
            {
                "_id": "al-wakrah",
                "code": "al-wakrah",
                "name": "al-Wakrah",
                "pk": "QA__al-wakrah"
            },
            {
                "_id": "ar-rayyan",
                "code": "ar-rayyan",
                "name": "ar-Rayyan",
                "pk": "QA__ar-rayyan"
            },
            {
                "_id": "ash-shamal",
                "code": "ash-shamal",
                "name": "ash-Shamal",
                "pk": "QA__ash-shamal"
            }
        ],
        "pk": "QA"
    },
    {
        "_id": "CG",
        "code": "CG",
        "name": "Republic Of The Congo",
        "states": [
            {
                "_id": "bouenza",
                "code": "bouenza",
                "name": "Bouenza",
                "pk": "CG__bouenza"
            },
            {
                "_id": "brazzaville",
                "code": "brazzaville",
                "name": "Brazzaville",
                "pk": "CG__brazzaville"
            },
            {
                "_id": "cuvette",
                "code": "cuvette",
                "name": "Cuvette",
                "pk": "CG__cuvette"
            },
            {
                "_id": "kouilou",
                "code": "kouilou",
                "name": "Kouilou",
                "pk": "CG__kouilou"
            },
            {
                "_id": "lekoumou",
                "code": "lekoumou",
                "name": "Lekoumou",
                "pk": "CG__lekoumou"
            },
            {
                "_id": "likouala",
                "code": "likouala",
                "name": "Likouala",
                "pk": "CG__likouala"
            },
            {
                "_id": "niari",
                "code": "niari",
                "name": "Niari",
                "pk": "CG__niari"
            },
            {
                "_id": "plateaux",
                "code": "plateaux",
                "name": "Plateaux",
                "pk": "CG__plateaux"
            },
            {
                "_id": "pool",
                "code": "pool",
                "name": "Pool",
                "pk": "CG__pool"
            },
            {
                "_id": "sangha",
                "code": "sangha",
                "name": "Sangha",
                "pk": "CG__sangha"
            }
        ],
        "pk": "CG"
    },
    {
        "_id": "RE",
        "code": "RE",
        "name": "Reunion",
        "states": [
            {
                "_id": "saint-benoit",
                "code": "saint-benoit",
                "name": "Saint-Benoit",
                "pk": "RE__saint-benoit"
            },
            {
                "_id": "saint-denis",
                "code": "saint-denis",
                "name": "Saint-Denis",
                "pk": "RE__saint-denis"
            },
            {
                "_id": "saint-paul",
                "code": "saint-paul",
                "name": "Saint-Paul",
                "pk": "RE__saint-paul"
            },
            {
                "_id": "saint-pierre",
                "code": "saint-pierre",
                "name": "Saint-Pierre",
                "pk": "RE__saint-pierre"
            }
        ],
        "pk": "RE"
    },
    {
        "_id": "RO",
        "code": "RO",
        "name": "Romania",
        "states": [
            {
                "_id": "alba",
                "code": "alba",
                "name": "Alba",
                "pk": "RO__alba"
            },
            {
                "_id": "arad",
                "code": "arad",
                "name": "Arad",
                "pk": "RO__arad"
            },
            {
                "_id": "arges",
                "code": "arges",
                "name": "Arges",
                "pk": "RO__arges"
            },
            {
                "_id": "bacau",
                "code": "bacau",
                "name": "Bacau",
                "pk": "RO__bacau"
            },
            {
                "_id": "bihor",
                "code": "bihor",
                "name": "Bihor",
                "pk": "RO__bihor"
            },
            {
                "_id": "bistrita-nasaud",
                "code": "bistrita-nasaud",
                "name": "Bistrita-Nasaud",
                "pk": "RO__bistrita-nasaud"
            },
            {
                "_id": "botosani",
                "code": "botosani",
                "name": "Botosani",
                "pk": "RO__botosani"
            },
            {
                "_id": "braila",
                "code": "braila",
                "name": "Braila",
                "pk": "RO__braila"
            },
            {
                "_id": "brasov",
                "code": "brasov",
                "name": "Brasov",
                "pk": "RO__brasov"
            },
            {
                "_id": "bucuresti",
                "code": "bucuresti",
                "name": "Bucuresti",
                "pk": "RO__bucuresti"
            },
            {
                "_id": "buzau",
                "code": "buzau",
                "name": "Buzau",
                "pk": "RO__buzau"
            },
            {
                "_id": "calarasi",
                "code": "calarasi",
                "name": "Calarasi",
                "pk": "RO__calarasi"
            },
            {
                "_id": "caras-severin",
                "code": "caras-severin",
                "name": "Caras-Severin",
                "pk": "RO__caras-severin"
            },
            {
                "_id": "cluj",
                "code": "cluj",
                "name": "Cluj",
                "pk": "RO__cluj"
            },
            {
                "_id": "constanta",
                "code": "constanta",
                "name": "Constanta",
                "pk": "RO__constanta"
            },
            {
                "_id": "covasna",
                "code": "covasna",
                "name": "Covasna",
                "pk": "RO__covasna"
            },
            {
                "_id": "dambovita",
                "code": "dambovita",
                "name": "Dambovita",
                "pk": "RO__dambovita"
            },
            {
                "_id": "dolj",
                "code": "dolj",
                "name": "Dolj",
                "pk": "RO__dolj"
            },
            {
                "_id": "galati",
                "code": "galati",
                "name": "Galati",
                "pk": "RO__galati"
            },
            {
                "_id": "giurgiu",
                "code": "giurgiu",
                "name": "Giurgiu",
                "pk": "RO__giurgiu"
            },
            {
                "_id": "gorj",
                "code": "gorj",
                "name": "Gorj",
                "pk": "RO__gorj"
            },
            {
                "_id": "harghita",
                "code": "harghita",
                "name": "Harghita",
                "pk": "RO__harghita"
            },
            {
                "_id": "hunedoara",
                "code": "hunedoara",
                "name": "Hunedoara",
                "pk": "RO__hunedoara"
            },
            {
                "_id": "ialomita",
                "code": "ialomita",
                "name": "Ialomita",
                "pk": "RO__ialomita"
            },
            {
                "_id": "iasi",
                "code": "iasi",
                "name": "Iasi",
                "pk": "RO__iasi"
            },
            {
                "_id": "ilfov",
                "code": "ilfov",
                "name": "Ilfov",
                "pk": "RO__ilfov"
            },
            {
                "_id": "maramures",
                "code": "maramures",
                "name": "Maramures",
                "pk": "RO__maramures"
            },
            {
                "_id": "mehedinti",
                "code": "mehedinti",
                "name": "Mehedinti",
                "pk": "RO__mehedinti"
            },
            {
                "_id": "mures",
                "code": "mures",
                "name": "Mures",
                "pk": "RO__mures"
            },
            {
                "_id": "neamt",
                "code": "neamt",
                "name": "Neamt",
                "pk": "RO__neamt"
            },
            {
                "_id": "olt",
                "code": "olt",
                "name": "Olt",
                "pk": "RO__olt"
            },
            {
                "_id": "prahova",
                "code": "prahova",
                "name": "Prahova",
                "pk": "RO__prahova"
            },
            {
                "_id": "salaj",
                "code": "salaj",
                "name": "Salaj",
                "pk": "RO__salaj"
            },
            {
                "_id": "satu_mare",
                "code": "satu_mare",
                "name": "Satu Mare",
                "pk": "RO__satu_mare"
            },
            {
                "_id": "sibiu",
                "code": "sibiu",
                "name": "Sibiu",
                "pk": "RO__sibiu"
            },
            {
                "_id": "sondelor",
                "code": "sondelor",
                "name": "Sondelor",
                "pk": "RO__sondelor"
            },
            {
                "_id": "suceava",
                "code": "suceava",
                "name": "Suceava",
                "pk": "RO__suceava"
            },
            {
                "_id": "teleorman",
                "code": "teleorman",
                "name": "Teleorman",
                "pk": "RO__teleorman"
            },
            {
                "_id": "timis",
                "code": "timis",
                "name": "Timis",
                "pk": "RO__timis"
            },
            {
                "_id": "tulcea",
                "code": "tulcea",
                "name": "Tulcea",
                "pk": "RO__tulcea"
            },
            {
                "_id": "valcea",
                "code": "valcea",
                "name": "Valcea",
                "pk": "RO__valcea"
            },
            {
                "_id": "vaslui",
                "code": "vaslui",
                "name": "Vaslui",
                "pk": "RO__vaslui"
            },
            {
                "_id": "vrancea",
                "code": "vrancea",
                "name": "Vrancea",
                "pk": "RO__vrancea"
            }
        ],
        "pk": "RO"
    },
    {
        "_id": "RU",
        "code": "RU",
        "name": "Russia",
        "states": [
            {
                "_id": "adygeja",
                "code": "adygeja",
                "name": "Adygeja",
                "pk": "RU__adygeja"
            },
            {
                "_id": "aga",
                "code": "aga",
                "name": "Aga",
                "pk": "RU__aga"
            },
            {
                "_id": "alanija",
                "code": "alanija",
                "name": "Alanija",
                "pk": "RU__alanija"
            },
            {
                "_id": "altaj",
                "code": "altaj",
                "name": "Altaj",
                "pk": "RU__altaj"
            },
            {
                "_id": "amur",
                "code": "amur",
                "name": "Amur",
                "pk": "RU__amur"
            },
            {
                "_id": "arhangelsk",
                "code": "arhangelsk",
                "name": "Arhangelsk",
                "pk": "RU__arhangelsk"
            },
            {
                "_id": "astrahan",
                "code": "astrahan",
                "name": "Astrahan",
                "pk": "RU__astrahan"
            },
            {
                "_id": "bashkortostan",
                "code": "bashkortostan",
                "name": "Bashkortostan",
                "pk": "RU__bashkortostan"
            },
            {
                "_id": "belgorod",
                "code": "belgorod",
                "name": "Belgorod",
                "pk": "RU__belgorod"
            },
            {
                "_id": "brjansk",
                "code": "brjansk",
                "name": "Brjansk",
                "pk": "RU__brjansk"
            },
            {
                "_id": "burjatija",
                "code": "burjatija",
                "name": "Burjatija",
                "pk": "RU__burjatija"
            },
            {
                "_id": "chechenija",
                "code": "chechenija",
                "name": "Chechenija",
                "pk": "RU__chechenija"
            },
            {
                "_id": "cheljabinsk",
                "code": "cheljabinsk",
                "name": "Cheljabinsk",
                "pk": "RU__cheljabinsk"
            },
            {
                "_id": "chita",
                "code": "chita",
                "name": "Chita",
                "pk": "RU__chita"
            },
            {
                "_id": "chukotka",
                "code": "chukotka",
                "name": "Chukotka",
                "pk": "RU__chukotka"
            },
            {
                "_id": "chuvashija",
                "code": "chuvashija",
                "name": "Chuvashija",
                "pk": "RU__chuvashija"
            },
            {
                "_id": "dagestan",
                "code": "dagestan",
                "name": "Dagestan",
                "pk": "RU__dagestan"
            },
            {
                "_id": "evenkija",
                "code": "evenkija",
                "name": "Evenkija",
                "pk": "RU__evenkija"
            },
            {
                "_id": "gorno-altaj",
                "code": "gorno-altaj",
                "name": "Gorno-Altaj",
                "pk": "RU__gorno-altaj"
            },
            {
                "_id": "habarovsk",
                "code": "habarovsk",
                "name": "Habarovsk",
                "pk": "RU__habarovsk"
            },
            {
                "_id": "hakasija",
                "code": "hakasija",
                "name": "Hakasija",
                "pk": "RU__hakasija"
            },
            {
                "_id": "hanty-mansija",
                "code": "hanty-mansija",
                "name": "Hanty-Mansija",
                "pk": "RU__hanty-mansija"
            },
            {
                "_id": "ingusetija",
                "code": "ingusetija",
                "name": "Ingusetija",
                "pk": "RU__ingusetija"
            },
            {
                "_id": "irkutsk",
                "code": "irkutsk",
                "name": "Irkutsk",
                "pk": "RU__irkutsk"
            },
            {
                "_id": "ivanovo",
                "code": "ivanovo",
                "name": "Ivanovo",
                "pk": "RU__ivanovo"
            },
            {
                "_id": "jamalo-nenets",
                "code": "jamalo-nenets",
                "name": "Jamalo-Nenets",
                "pk": "RU__jamalo-nenets"
            },
            {
                "_id": "jaroslavl",
                "code": "jaroslavl",
                "name": "Jaroslavl",
                "pk": "RU__jaroslavl"
            },
            {
                "_id": "jevrej",
                "code": "jevrej",
                "name": "Jevrej",
                "pk": "RU__jevrej"
            },
            {
                "_id": "kabardino-balkarija",
                "code": "kabardino-balkarija",
                "name": "Kabardino-Balkarija",
                "pk": "RU__kabardino-balkarija"
            },
            {
                "_id": "kaliningrad",
                "code": "kaliningrad",
                "name": "Kaliningrad",
                "pk": "RU__kaliningrad"
            },
            {
                "_id": "kalmykija",
                "code": "kalmykija",
                "name": "Kalmykija",
                "pk": "RU__kalmykija"
            },
            {
                "_id": "kaluga",
                "code": "kaluga",
                "name": "Kaluga",
                "pk": "RU__kaluga"
            },
            {
                "_id": "kamchatka",
                "code": "kamchatka",
                "name": "Kamchatka",
                "pk": "RU__kamchatka"
            },
            {
                "_id": "karachaj-cherkessija",
                "code": "karachaj-cherkessija",
                "name": "Karachaj-Cherkessija",
                "pk": "RU__karachaj-cherkessija"
            },
            {
                "_id": "karelija",
                "code": "karelija",
                "name": "Karelija",
                "pk": "RU__karelija"
            },
            {
                "_id": "kemerovo",
                "code": "kemerovo",
                "name": "Kemerovo",
                "pk": "RU__kemerovo"
            },
            {
                "_id": "khabarovskiy_kray",
                "code": "khabarovskiy_kray",
                "name": "Khabarovskiy Kray",
                "pk": "RU__khabarovskiy_kray"
            },
            {
                "_id": "kirov",
                "code": "kirov",
                "name": "Kirov",
                "pk": "RU__kirov"
            },
            {
                "_id": "komi",
                "code": "komi",
                "name": "Komi",
                "pk": "RU__komi"
            },
            {
                "_id": "komi-permjakija",
                "code": "komi-permjakija",
                "name": "Komi-Permjakija",
                "pk": "RU__komi-permjakija"
            },
            {
                "_id": "korjakija",
                "code": "korjakija",
                "name": "Korjakija",
                "pk": "RU__korjakija"
            },
            {
                "_id": "kostroma",
                "code": "kostroma",
                "name": "Kostroma",
                "pk": "RU__kostroma"
            },
            {
                "_id": "krasnodar",
                "code": "krasnodar",
                "name": "Krasnodar",
                "pk": "RU__krasnodar"
            },
            {
                "_id": "krasnojarsk",
                "code": "krasnojarsk",
                "name": "Krasnojarsk",
                "pk": "RU__krasnojarsk"
            },
            {
                "_id": "krasnoyarskiy_kray",
                "code": "krasnoyarskiy_kray",
                "name": "Krasnoyarskiy Kray",
                "pk": "RU__krasnoyarskiy_kray"
            },
            {
                "_id": "kurgan",
                "code": "kurgan",
                "name": "Kurgan",
                "pk": "RU__kurgan"
            },
            {
                "_id": "kursk",
                "code": "kursk",
                "name": "Kursk",
                "pk": "RU__kursk"
            },
            {
                "_id": "leningrad",
                "code": "leningrad",
                "name": "Leningrad",
                "pk": "RU__leningrad"
            },
            {
                "_id": "lipeck",
                "code": "lipeck",
                "name": "Lipeck",
                "pk": "RU__lipeck"
            },
            {
                "_id": "magadan",
                "code": "magadan",
                "name": "Magadan",
                "pk": "RU__magadan"
            },
            {
                "_id": "marij_el",
                "code": "marij_el",
                "name": "Marij El",
                "pk": "RU__marij_el"
            },
            {
                "_id": "mordovija",
                "code": "mordovija",
                "name": "Mordovija",
                "pk": "RU__mordovija"
            },
            {
                "_id": "moscow",
                "code": "moscow",
                "name": "Moscow",
                "pk": "RU__moscow"
            },
            {
                "_id": "moskovskaja_oblast",
                "code": "moskovskaja_oblast",
                "name": "Moskovskaja Oblast",
                "pk": "RU__moskovskaja_oblast"
            },
            {
                "_id": "moskovskaya_oblast",
                "code": "moskovskaya_oblast",
                "name": "Moskovskaya Oblast",
                "pk": "RU__moskovskaya_oblast"
            },
            {
                "_id": "moskva",
                "code": "moskva",
                "name": "Moskva",
                "pk": "RU__moskva"
            },
            {
                "_id": "murmansk",
                "code": "murmansk",
                "name": "Murmansk",
                "pk": "RU__murmansk"
            },
            {
                "_id": "nenets",
                "code": "nenets",
                "name": "Nenets",
                "pk": "RU__nenets"
            },
            {
                "_id": "nizhnij_novgorod",
                "code": "nizhnij_novgorod",
                "name": "Nizhnij Novgorod",
                "pk": "RU__nizhnij_novgorod"
            },
            {
                "_id": "novgorod",
                "code": "novgorod",
                "name": "Novgorod",
                "pk": "RU__novgorod"
            },
            {
                "_id": "novokusnezk",
                "code": "novokusnezk",
                "name": "Novokusnezk",
                "pk": "RU__novokusnezk"
            },
            {
                "_id": "novosibirsk",
                "code": "novosibirsk",
                "name": "Novosibirsk",
                "pk": "RU__novosibirsk"
            },
            {
                "_id": "omsk",
                "code": "omsk",
                "name": "Omsk",
                "pk": "RU__omsk"
            },
            {
                "_id": "orenburg",
                "code": "orenburg",
                "name": "Orenburg",
                "pk": "RU__orenburg"
            },
            {
                "_id": "orjol",
                "code": "orjol",
                "name": "Orjol",
                "pk": "RU__orjol"
            },
            {
                "_id": "penza",
                "code": "penza",
                "name": "Penza",
                "pk": "RU__penza"
            },
            {
                "_id": "perm",
                "code": "perm",
                "name": "Perm",
                "pk": "RU__perm"
            },
            {
                "_id": "primorje",
                "code": "primorje",
                "name": "Primorje",
                "pk": "RU__primorje"
            },
            {
                "_id": "pskov",
                "code": "pskov",
                "name": "Pskov",
                "pk": "RU__pskov"
            },
            {
                "_id": "pskovskaya_oblast",
                "code": "pskovskaya_oblast",
                "name": "Pskovskaya Oblast",
                "pk": "RU__pskovskaya_oblast"
            },
            {
                "_id": "rjazan",
                "code": "rjazan",
                "name": "Rjazan",
                "pk": "RU__rjazan"
            },
            {
                "_id": "rostov",
                "code": "rostov",
                "name": "Rostov",
                "pk": "RU__rostov"
            },
            {
                "_id": "saha",
                "code": "saha",
                "name": "Saha",
                "pk": "RU__saha"
            },
            {
                "_id": "sahalin",
                "code": "sahalin",
                "name": "Sahalin",
                "pk": "RU__sahalin"
            },
            {
                "_id": "samara",
                "code": "samara",
                "name": "Samara",
                "pk": "RU__samara"
            },
            {
                "_id": "samarskaya",
                "code": "samarskaya",
                "name": "Samarskaya",
                "pk": "RU__samarskaya"
            },
            {
                "_id": "sankt-peterburg",
                "code": "sankt-peterburg",
                "name": "Sankt-Peterburg",
                "pk": "RU__sankt-peterburg"
            },
            {
                "_id": "saratov",
                "code": "saratov",
                "name": "Saratov",
                "pk": "RU__saratov"
            },
            {
                "_id": "smolensk",
                "code": "smolensk",
                "name": "Smolensk",
                "pk": "RU__smolensk"
            },
            {
                "_id": "stavropol",
                "code": "stavropol",
                "name": "Stavropol",
                "pk": "RU__stavropol"
            },
            {
                "_id": "sverdlovsk",
                "code": "sverdlovsk",
                "name": "Sverdlovsk",
                "pk": "RU__sverdlovsk"
            },
            {
                "_id": "tajmyrija",
                "code": "tajmyrija",
                "name": "Tajmyrija",
                "pk": "RU__tajmyrija"
            },
            {
                "_id": "tambov",
                "code": "tambov",
                "name": "Tambov",
                "pk": "RU__tambov"
            },
            {
                "_id": "tatarstan",
                "code": "tatarstan",
                "name": "Tatarstan",
                "pk": "RU__tatarstan"
            },
            {
                "_id": "tjumen",
                "code": "tjumen",
                "name": "Tjumen",
                "pk": "RU__tjumen"
            },
            {
                "_id": "tomsk",
                "code": "tomsk",
                "name": "Tomsk",
                "pk": "RU__tomsk"
            },
            {
                "_id": "tula",
                "code": "tula",
                "name": "Tula",
                "pk": "RU__tula"
            },
            {
                "_id": "tver",
                "code": "tver",
                "name": "Tver",
                "pk": "RU__tver"
            },
            {
                "_id": "tyva",
                "code": "tyva",
                "name": "Tyva",
                "pk": "RU__tyva"
            },
            {
                "_id": "udmurtija",
                "code": "udmurtija",
                "name": "Udmurtija",
                "pk": "RU__udmurtija"
            },
            {
                "_id": "uljanovsk",
                "code": "uljanovsk",
                "name": "Uljanovsk",
                "pk": "RU__uljanovsk"
            },
            {
                "_id": "ulyanovskaya_oblast",
                "code": "ulyanovskaya_oblast",
                "name": "Ulyanovskaya Oblast",
                "pk": "RU__ulyanovskaya_oblast"
            },
            {
                "_id": "ust-orda",
                "code": "ust-orda",
                "name": "Ust-Orda",
                "pk": "RU__ust-orda"
            },
            {
                "_id": "vladimir",
                "code": "vladimir",
                "name": "Vladimir",
                "pk": "RU__vladimir"
            },
            {
                "_id": "volgograd",
                "code": "volgograd",
                "name": "Volgograd",
                "pk": "RU__volgograd"
            },
            {
                "_id": "vologda",
                "code": "vologda",
                "name": "Vologda",
                "pk": "RU__vologda"
            },
            {
                "_id": "voronezh",
                "code": "voronezh",
                "name": "Voronezh",
                "pk": "RU__voronezh"
            }
        ],
        "pk": "RU"
    },
    {
        "_id": "RW",
        "code": "RW",
        "name": "Rwanda",
        "states": [
            {
                "_id": "butare",
                "code": "butare",
                "name": "Butare",
                "pk": "RW__butare"
            },
            {
                "_id": "byumba",
                "code": "byumba",
                "name": "Byumba",
                "pk": "RW__byumba"
            },
            {
                "_id": "cyangugu",
                "code": "cyangugu",
                "name": "Cyangugu",
                "pk": "RW__cyangugu"
            },
            {
                "_id": "gikongoro",
                "code": "gikongoro",
                "name": "Gikongoro",
                "pk": "RW__gikongoro"
            },
            {
                "_id": "gisenyi",
                "code": "gisenyi",
                "name": "Gisenyi",
                "pk": "RW__gisenyi"
            },
            {
                "_id": "gitarama",
                "code": "gitarama",
                "name": "Gitarama",
                "pk": "RW__gitarama"
            },
            {
                "_id": "kibungo",
                "code": "kibungo",
                "name": "Kibungo",
                "pk": "RW__kibungo"
            },
            {
                "_id": "kibuye",
                "code": "kibuye",
                "name": "Kibuye",
                "pk": "RW__kibuye"
            },
            {
                "_id": "kigali-ngali",
                "code": "kigali-ngali",
                "name": "Kigali-ngali",
                "pk": "RW__kigali-ngali"
            },
            {
                "_id": "ruhengeri",
                "code": "ruhengeri",
                "name": "Ruhengeri",
                "pk": "RW__ruhengeri"
            }
        ],
        "pk": "RW"
    },
    {
        "_id": "SH",
        "code": "SH",
        "name": "Saint Helena",
        "states": [
            {
                "_id": "ascension",
                "code": "ascension",
                "name": "Ascension",
                "pk": "SH__ascension"
            },
            {
                "_id": "gough_island",
                "code": "gough_island",
                "name": "Gough Island",
                "pk": "SH__gough_island"
            },
            {
                "_id": "saint_helena",
                "code": "saint_helena",
                "name": "Saint Helena",
                "pk": "SH__saint_helena"
            },
            {
                "_id": "tristan_da_cunha",
                "code": "tristan_da_cunha",
                "name": "Tristan da Cunha",
                "pk": "SH__tristan_da_cunha"
            }
        ],
        "pk": "SH"
    },
    {
        "_id": "KN",
        "code": "KN",
        "name": "Saint Kitts And Nevis",
        "states": [
            {
                "_id": "christ_church_nichola_town",
                "code": "christ_church_nichola_town",
                "name": "Christ Church Nichola Town",
                "pk": "KN__christ_church_nichola_town"
            },
            {
                "_id": "saint_anne_sandy_point",
                "code": "saint_anne_sandy_point",
                "name": "Saint Anne Sandy Point",
                "pk": "KN__saint_anne_sandy_point"
            },
            {
                "_id": "saint_george_basseterre",
                "code": "saint_george_basseterre",
                "name": "Saint George Basseterre",
                "pk": "KN__saint_george_basseterre"
            },
            {
                "_id": "saint_george_gingerland",
                "code": "saint_george_gingerland",
                "name": "Saint George Gingerland",
                "pk": "KN__saint_george_gingerland"
            },
            {
                "_id": "saint_james_windward",
                "code": "saint_james_windward",
                "name": "Saint James Windward",
                "pk": "KN__saint_james_windward"
            },
            {
                "_id": "saint_john_capesterre",
                "code": "saint_john_capesterre",
                "name": "Saint John Capesterre",
                "pk": "KN__saint_john_capesterre"
            },
            {
                "_id": "saint_john_figtree",
                "code": "saint_john_figtree",
                "name": "Saint John Figtree",
                "pk": "KN__saint_john_figtree"
            },
            {
                "_id": "saint_mary_cayon",
                "code": "saint_mary_cayon",
                "name": "Saint Mary Cayon",
                "pk": "KN__saint_mary_cayon"
            },
            {
                "_id": "saint_paul_capesterre",
                "code": "saint_paul_capesterre",
                "name": "Saint Paul Capesterre",
                "pk": "KN__saint_paul_capesterre"
            },
            {
                "_id": "saint_paul_charlestown",
                "code": "saint_paul_charlestown",
                "name": "Saint Paul Charlestown",
                "pk": "KN__saint_paul_charlestown"
            },
            {
                "_id": "saint_peter_basseterre",
                "code": "saint_peter_basseterre",
                "name": "Saint Peter Basseterre",
                "pk": "KN__saint_peter_basseterre"
            },
            {
                "_id": "saint_thomas_lowland",
                "code": "saint_thomas_lowland",
                "name": "Saint Thomas Lowland",
                "pk": "KN__saint_thomas_lowland"
            },
            {
                "_id": "saint_thomas_middle_island",
                "code": "saint_thomas_middle_island",
                "name": "Saint Thomas Middle Island",
                "pk": "KN__saint_thomas_middle_island"
            },
            {
                "_id": "trinity_palmetto_point",
                "code": "trinity_palmetto_point",
                "name": "Trinity Palmetto Point",
                "pk": "KN__trinity_palmetto_point"
            }
        ],
        "pk": "KN"
    },
    {
        "_id": "LC",
        "code": "LC",
        "name": "Saint Lucia",
        "states": [
            {
                "_id": "anse-la-raye",
                "code": "anse-la-raye",
                "name": "Anse-la-Raye",
                "pk": "LC__anse-la-raye"
            },
            {
                "_id": "canaries",
                "code": "canaries",
                "name": "Canaries",
                "pk": "LC__canaries"
            },
            {
                "_id": "castries",
                "code": "castries",
                "name": "Castries",
                "pk": "LC__castries"
            },
            {
                "_id": "choiseul",
                "code": "choiseul",
                "name": "Choiseul",
                "pk": "LC__choiseul"
            },
            {
                "_id": "dennery",
                "code": "dennery",
                "name": "Dennery",
                "pk": "LC__dennery"
            },
            {
                "_id": "gros_inlet",
                "code": "gros_inlet",
                "name": "Gros Inlet",
                "pk": "LC__gros_inlet"
            },
            {
                "_id": "laborie",
                "code": "laborie",
                "name": "Laborie",
                "pk": "LC__laborie"
            },
            {
                "_id": "micoud",
                "code": "micoud",
                "name": "Micoud",
                "pk": "LC__micoud"
            },
            {
                "_id": "soufriere",
                "code": "soufriere",
                "name": "Soufriere",
                "pk": "LC__soufriere"
            },
            {
                "_id": "vieux_fort",
                "code": "vieux_fort",
                "name": "Vieux Fort",
                "pk": "LC__vieux_fort"
            }
        ],
        "pk": "LC"
    },
    {
        "_id": "PM",
        "code": "PM",
        "name": "Saint Pierre and Miquelon",
        "states": [
            {
                "_id": "miquelon-langlade",
                "code": "miquelon-langlade",
                "name": "Miquelon-Langlade",
                "pk": "PM__miquelon-langlade"
            },
            {
                "_id": "saint-pierre",
                "code": "saint-pierre",
                "name": "Saint-Pierre",
                "pk": "PM__saint-pierre"
            }
        ],
        "pk": "PM"
    },
    {
        "_id": "VC",
        "code": "VC",
        "name": "Saint Vincent And The Grenadines",
        "states": [
            {
                "_id": "charlotte",
                "code": "charlotte",
                "name": "Charlotte",
                "pk": "VC__charlotte"
            },
            {
                "_id": "grenadines",
                "code": "grenadines",
                "name": "Grenadines",
                "pk": "VC__grenadines"
            },
            {
                "_id": "saint_andrew",
                "code": "saint_andrew",
                "name": "Saint Andrew",
                "pk": "VC__saint_andrew"
            },
            {
                "_id": "saint_david",
                "code": "saint_david",
                "name": "Saint David",
                "pk": "VC__saint_david"
            },
            {
                "_id": "saint_george",
                "code": "saint_george",
                "name": "Saint George",
                "pk": "VC__saint_george"
            },
            {
                "_id": "saint_patrick",
                "code": "saint_patrick",
                "name": "Saint Patrick",
                "pk": "VC__saint_patrick"
            }
        ],
        "pk": "VC"
    },
    {
        "_id": "WS",
        "code": "WS",
        "name": "Samoa",
        "states": [
            {
                "_id": "aana",
                "code": "aana",
                "name": "Aana",
                "pk": "WS__aana"
            },
            {
                "_id": "aiga-i-le-tai",
                "code": "aiga-i-le-tai",
                "name": "Aiga-i-le-Tai",
                "pk": "WS__aiga-i-le-tai"
            },
            {
                "_id": "atua",
                "code": "atua",
                "name": "Atua",
                "pk": "WS__atua"
            },
            {
                "_id": "faasaleleaga",
                "code": "faasaleleaga",
                "name": "Faasaleleaga",
                "pk": "WS__faasaleleaga"
            },
            {
                "_id": "gagaemauga",
                "code": "gagaemauga",
                "name": "Gagaemauga",
                "pk": "WS__gagaemauga"
            },
            {
                "_id": "gagaifomauga",
                "code": "gagaifomauga",
                "name": "Gagaifomauga",
                "pk": "WS__gagaifomauga"
            },
            {
                "_id": "palauli",
                "code": "palauli",
                "name": "Palauli",
                "pk": "WS__palauli"
            },
            {
                "_id": "satupaitea",
                "code": "satupaitea",
                "name": "Satupaitea",
                "pk": "WS__satupaitea"
            },
            {
                "_id": "tuamasaga",
                "code": "tuamasaga",
                "name": "Tuamasaga",
                "pk": "WS__tuamasaga"
            },
            {
                "_id": "vaa-o-fonoti",
                "code": "vaa-o-fonoti",
                "name": "Vaa-o-Fonoti",
                "pk": "WS__vaa-o-fonoti"
            },
            {
                "_id": "vaisigano",
                "code": "vaisigano",
                "name": "Vaisigano",
                "pk": "WS__vaisigano"
            }
        ],
        "pk": "WS"
    },
    {
        "_id": "SM",
        "code": "SM",
        "name": "San Marino",
        "states": [
            {
                "_id": "acquaviva",
                "code": "acquaviva",
                "name": "Acquaviva",
                "pk": "SM__acquaviva"
            },
            {
                "_id": "borgo_maggiore",
                "code": "borgo_maggiore",
                "name": "Borgo Maggiore",
                "pk": "SM__borgo_maggiore"
            },
            {
                "_id": "chiesanuova",
                "code": "chiesanuova",
                "name": "Chiesanuova",
                "pk": "SM__chiesanuova"
            },
            {
                "_id": "domagnano",
                "code": "domagnano",
                "name": "Domagnano",
                "pk": "SM__domagnano"
            },
            {
                "_id": "faetano",
                "code": "faetano",
                "name": "Faetano",
                "pk": "SM__faetano"
            },
            {
                "_id": "fiorentino",
                "code": "fiorentino",
                "name": "Fiorentino",
                "pk": "SM__fiorentino"
            },
            {
                "_id": "montegiardino",
                "code": "montegiardino",
                "name": "Montegiardino",
                "pk": "SM__montegiardino"
            },
            {
                "_id": "san_marino",
                "code": "san_marino",
                "name": "San Marino",
                "pk": "SM__san_marino"
            },
            {
                "_id": "serravalle",
                "code": "serravalle",
                "name": "Serravalle",
                "pk": "SM__serravalle"
            }
        ],
        "pk": "SM"
    },
    {
        "_id": "ST",
        "code": "ST",
        "name": "Sao Tome and Principe",
        "states": [
            {
                "_id": "agua_grande",
                "code": "agua_grande",
                "name": "Agua Grande",
                "pk": "ST__agua_grande"
            },
            {
                "_id": "cantagalo",
                "code": "cantagalo",
                "name": "Cantagalo",
                "pk": "ST__cantagalo"
            },
            {
                "_id": "lemba",
                "code": "lemba",
                "name": "Lemba",
                "pk": "ST__lemba"
            },
            {
                "_id": "lobata",
                "code": "lobata",
                "name": "Lobata",
                "pk": "ST__lobata"
            },
            {
                "_id": "me-zochi",
                "code": "me-zochi",
                "name": "Me-Zochi",
                "pk": "ST__me-zochi"
            },
            {
                "_id": "pague",
                "code": "pague",
                "name": "Pague",
                "pk": "ST__pague"
            }
        ],
        "pk": "ST"
    },
    {
        "_id": "SA",
        "code": "SA",
        "name": "Saudi Arabia",
        "states": [
            {
                "_id": "al_khobar",
                "code": "al_khobar",
                "name": "Al Khobar",
                "pk": "SA__al_khobar"
            },
            {
                "_id": "aseer",
                "code": "aseer",
                "name": "Aseer",
                "pk": "SA__aseer"
            },
            {
                "_id": "ash_sharqiyah",
                "code": "ash_sharqiyah",
                "name": "Ash Sharqiyah",
                "pk": "SA__ash_sharqiyah"
            },
            {
                "_id": "asir",
                "code": "asir",
                "name": "Asir",
                "pk": "SA__asir"
            },
            {
                "_id": "central_province",
                "code": "central_province",
                "name": "Central Province",
                "pk": "SA__central_province"
            },
            {
                "_id": "eastern_province",
                "code": "eastern_province",
                "name": "Eastern Province",
                "pk": "SA__eastern_province"
            },
            {
                "_id": "hail",
                "code": "hail",
                "name": "Hail",
                "pk": "SA__hail"
            },
            {
                "_id": "jawf",
                "code": "jawf",
                "name": "Jawf",
                "pk": "SA__jawf"
            },
            {
                "_id": "jizan",
                "code": "jizan",
                "name": "Jizan",
                "pk": "SA__jizan"
            },
            {
                "_id": "makkah",
                "code": "makkah",
                "name": "Makkah",
                "pk": "SA__makkah"
            },
            {
                "_id": "najran",
                "code": "najran",
                "name": "Najran",
                "pk": "SA__najran"
            },
            {
                "_id": "qasim",
                "code": "qasim",
                "name": "Qasim",
                "pk": "SA__qasim"
            },
            {
                "_id": "tabuk",
                "code": "tabuk",
                "name": "Tabuk",
                "pk": "SA__tabuk"
            },
            {
                "_id": "western_province",
                "code": "western_province",
                "name": "Western Province",
                "pk": "SA__western_province"
            },
            {
                "_id": "al-bahah",
                "code": "al-bahah",
                "name": "al-Bahah",
                "pk": "SA__al-bahah"
            },
            {
                "_id": "al-hudud-ash-shamaliyah",
                "code": "al-hudud-ash-shamaliyah",
                "name": "al-Hudud-ash-Shamaliyah",
                "pk": "SA__al-hudud-ash-shamaliyah"
            },
            {
                "_id": "al-madinah",
                "code": "al-madinah",
                "name": "al-Madinah",
                "pk": "SA__al-madinah"
            },
            {
                "_id": "ar-riyad",
                "code": "ar-riyad",
                "name": "ar-Riyad",
                "pk": "SA__ar-riyad"
            }
        ],
        "pk": "SA"
    },
    {
        "_id": "SN",
        "code": "SN",
        "name": "Senegal",
        "states": [
            {
                "_id": "dakar",
                "code": "dakar",
                "name": "Dakar",
                "pk": "SN__dakar"
            },
            {
                "_id": "diourbel",
                "code": "diourbel",
                "name": "Diourbel",
                "pk": "SN__diourbel"
            },
            {
                "_id": "fatick",
                "code": "fatick",
                "name": "Fatick",
                "pk": "SN__fatick"
            },
            {
                "_id": "kaolack",
                "code": "kaolack",
                "name": "Kaolack",
                "pk": "SN__kaolack"
            },
            {
                "_id": "kolda",
                "code": "kolda",
                "name": "Kolda",
                "pk": "SN__kolda"
            },
            {
                "_id": "louga",
                "code": "louga",
                "name": "Louga",
                "pk": "SN__louga"
            },
            {
                "_id": "saint-louis",
                "code": "saint-louis",
                "name": "Saint-Louis",
                "pk": "SN__saint-louis"
            },
            {
                "_id": "tambacounda",
                "code": "tambacounda",
                "name": "Tambacounda",
                "pk": "SN__tambacounda"
            },
            {
                "_id": "thies",
                "code": "thies",
                "name": "Thies",
                "pk": "SN__thies"
            },
            {
                "_id": "ziguinchor",
                "code": "ziguinchor",
                "name": "Ziguinchor",
                "pk": "SN__ziguinchor"
            }
        ],
        "pk": "SN"
    },
    {
        "_id": "RS",
        "code": "RS",
        "name": "Serbia",
        "states": [
            {
                "_id": "central_serbia",
                "code": "central_serbia",
                "name": "Central Serbia",
                "pk": "RS__central_serbia"
            },
            {
                "_id": "kosovo_and_metohija",
                "code": "kosovo_and_metohija",
                "name": "Kosovo and Metohija",
                "pk": "RS__kosovo_and_metohija"
            },
            {
                "_id": "vojvodina",
                "code": "vojvodina",
                "name": "Vojvodina",
                "pk": "RS__vojvodina"
            }
        ],
        "pk": "RS"
    },
    {
        "_id": "SC",
        "code": "SC",
        "name": "Seychelles",
        "states": [
            {
                "_id": "anse_boileau",
                "code": "anse_boileau",
                "name": "Anse Boileau",
                "pk": "SC__anse_boileau"
            },
            {
                "_id": "anse_royale",
                "code": "anse_royale",
                "name": "Anse Royale",
                "pk": "SC__anse_royale"
            },
            {
                "_id": "cascade",
                "code": "cascade",
                "name": "Cascade",
                "pk": "SC__cascade"
            },
            {
                "_id": "takamaka",
                "code": "takamaka",
                "name": "Takamaka",
                "pk": "SC__takamaka"
            },
            {
                "_id": "victoria",
                "code": "victoria",
                "name": "Victoria",
                "pk": "SC__victoria"
            }
        ],
        "pk": "SC"
    },
    {
        "_id": "SL",
        "code": "SL",
        "name": "Sierra Leone",
        "states": [
            {
                "_id": "eastern",
                "code": "eastern",
                "name": "Eastern",
                "pk": "SL__eastern"
            },
            {
                "_id": "northern",
                "code": "northern",
                "name": "Northern",
                "pk": "SL__northern"
            },
            {
                "_id": "southern",
                "code": "southern",
                "name": "Southern",
                "pk": "SL__southern"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "SL__western"
            }
        ],
        "pk": "SL"
    },
    {
        "_id": "SG",
        "code": "SG",
        "name": "Singapore",
        "states": [
            {
                "_id": "singapore",
                "code": "singapore",
                "name": "Singapore",
                "pk": "SG__singapore"
            }
        ],
        "pk": "SG"
    },
    {
        "_id": "SK",
        "code": "SK",
        "name": "Slovakia",
        "states": [
            {
                "_id": "banskobystricky",
                "code": "banskobystricky",
                "name": "Banskobystricky",
                "pk": "SK__banskobystricky"
            },
            {
                "_id": "bratislavsky",
                "code": "bratislavsky",
                "name": "Bratislavsky",
                "pk": "SK__bratislavsky"
            },
            {
                "_id": "kosicky",
                "code": "kosicky",
                "name": "Kosicky",
                "pk": "SK__kosicky"
            },
            {
                "_id": "nitriansky",
                "code": "nitriansky",
                "name": "Nitriansky",
                "pk": "SK__nitriansky"
            },
            {
                "_id": "presovsky",
                "code": "presovsky",
                "name": "Presovsky",
                "pk": "SK__presovsky"
            },
            {
                "_id": "trenciansky",
                "code": "trenciansky",
                "name": "Trenciansky",
                "pk": "SK__trenciansky"
            },
            {
                "_id": "trnavsky",
                "code": "trnavsky",
                "name": "Trnavsky",
                "pk": "SK__trnavsky"
            },
            {
                "_id": "zilinsky",
                "code": "zilinsky",
                "name": "Zilinsky",
                "pk": "SK__zilinsky"
            }
        ],
        "pk": "SK"
    },
    {
        "_id": "SI",
        "code": "SI",
        "name": "Slovenia",
        "states": [
            {
                "_id": "benedikt",
                "code": "benedikt",
                "name": "Benedikt",
                "pk": "SI__benedikt"
            },
            {
                "_id": "gorenjska",
                "code": "gorenjska",
                "name": "Gorenjska",
                "pk": "SI__gorenjska"
            },
            {
                "_id": "gorishka",
                "code": "gorishka",
                "name": "Gorishka",
                "pk": "SI__gorishka"
            },
            {
                "_id": "jugovzhodna_slovenija",
                "code": "jugovzhodna_slovenija",
                "name": "Jugovzhodna Slovenija",
                "pk": "SI__jugovzhodna_slovenija"
            },
            {
                "_id": "koroshka",
                "code": "koroshka",
                "name": "Koroshka",
                "pk": "SI__koroshka"
            },
            {
                "_id": "notranjsko-krashka",
                "code": "notranjsko-krashka",
                "name": "Notranjsko-krashka",
                "pk": "SI__notranjsko-krashka"
            },
            {
                "_id": "obalno-krashka",
                "code": "obalno-krashka",
                "name": "Obalno-krashka",
                "pk": "SI__obalno-krashka"
            },
            {
                "_id": "obcina_domzale",
                "code": "obcina_domzale",
                "name": "Obcina Domzale",
                "pk": "SI__obcina_domzale"
            },
            {
                "_id": "obcina_vitanje",
                "code": "obcina_vitanje",
                "name": "Obcina Vitanje",
                "pk": "SI__obcina_vitanje"
            },
            {
                "_id": "osrednjeslovenska",
                "code": "osrednjeslovenska",
                "name": "Osrednjeslovenska",
                "pk": "SI__osrednjeslovenska"
            },
            {
                "_id": "podravska",
                "code": "podravska",
                "name": "Podravska",
                "pk": "SI__podravska"
            },
            {
                "_id": "pomurska",
                "code": "pomurska",
                "name": "Pomurska",
                "pk": "SI__pomurska"
            },
            {
                "_id": "savinjska",
                "code": "savinjska",
                "name": "Savinjska",
                "pk": "SI__savinjska"
            },
            {
                "_id": "slovenian_littoral",
                "code": "slovenian_littoral",
                "name": "Slovenian Littoral",
                "pk": "SI__slovenian_littoral"
            },
            {
                "_id": "spodnjeposavska",
                "code": "spodnjeposavska",
                "name": "Spodnjeposavska",
                "pk": "SI__spodnjeposavska"
            },
            {
                "_id": "zasavska",
                "code": "zasavska",
                "name": "Zasavska",
                "pk": "SI__zasavska"
            }
        ],
        "pk": "SI"
    },
    {
        "_id": "XG",
        "code": "XG",
        "name": "Smaller Territories of the UK",
        "states": [
            {
                "_id": "pitcairn",
                "code": "pitcairn",
                "name": "Pitcairn",
                "pk": "XG__pitcairn"
            }
        ],
        "pk": "XG"
    },
    {
        "_id": "SB",
        "code": "SB",
        "name": "Solomon Islands",
        "states": [
            {
                "_id": "central",
                "code": "central",
                "name": "Central",
                "pk": "SB__central"
            },
            {
                "_id": "choiseul",
                "code": "choiseul",
                "name": "Choiseul",
                "pk": "SB__choiseul"
            },
            {
                "_id": "guadalcanal",
                "code": "guadalcanal",
                "name": "Guadalcanal",
                "pk": "SB__guadalcanal"
            },
            {
                "_id": "isabel",
                "code": "isabel",
                "name": "Isabel",
                "pk": "SB__isabel"
            },
            {
                "_id": "makira_and_ulawa",
                "code": "makira_and_ulawa",
                "name": "Makira and Ulawa",
                "pk": "SB__makira_and_ulawa"
            },
            {
                "_id": "malaita",
                "code": "malaita",
                "name": "Malaita",
                "pk": "SB__malaita"
            },
            {
                "_id": "rennell_and_bellona",
                "code": "rennell_and_bellona",
                "name": "Rennell and Bellona",
                "pk": "SB__rennell_and_bellona"
            },
            {
                "_id": "temotu",
                "code": "temotu",
                "name": "Temotu",
                "pk": "SB__temotu"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "SB__western"
            }
        ],
        "pk": "SB"
    },
    {
        "_id": "SO",
        "code": "SO",
        "name": "Somalia",
        "states": [
            {
                "_id": "awdal",
                "code": "awdal",
                "name": "Awdal",
                "pk": "SO__awdal"
            },
            {
                "_id": "bakol",
                "code": "bakol",
                "name": "Bakol",
                "pk": "SO__bakol"
            },
            {
                "_id": "banadir",
                "code": "banadir",
                "name": "Banadir",
                "pk": "SO__banadir"
            },
            {
                "_id": "bari",
                "code": "bari",
                "name": "Bari",
                "pk": "SO__bari"
            },
            {
                "_id": "bay",
                "code": "bay",
                "name": "Bay",
                "pk": "SO__bay"
            },
            {
                "_id": "galgudug",
                "code": "galgudug",
                "name": "Galgudug",
                "pk": "SO__galgudug"
            },
            {
                "_id": "gedo",
                "code": "gedo",
                "name": "Gedo",
                "pk": "SO__gedo"
            },
            {
                "_id": "hiran",
                "code": "hiran",
                "name": "Hiran",
                "pk": "SO__hiran"
            },
            {
                "_id": "jubbada_hose",
                "code": "jubbada_hose",
                "name": "Jubbada Hose",
                "pk": "SO__jubbada_hose"
            },
            {
                "_id": "jubbadha_dexe",
                "code": "jubbadha_dexe",
                "name": "Jubbadha Dexe",
                "pk": "SO__jubbadha_dexe"
            },
            {
                "_id": "mudug",
                "code": "mudug",
                "name": "Mudug",
                "pk": "SO__mudug"
            },
            {
                "_id": "nugal",
                "code": "nugal",
                "name": "Nugal",
                "pk": "SO__nugal"
            },
            {
                "_id": "sanag",
                "code": "sanag",
                "name": "Sanag",
                "pk": "SO__sanag"
            },
            {
                "_id": "shabellaha_dhexe",
                "code": "shabellaha_dhexe",
                "name": "Shabellaha Dhexe",
                "pk": "SO__shabellaha_dhexe"
            },
            {
                "_id": "shabellaha_hose",
                "code": "shabellaha_hose",
                "name": "Shabellaha Hose",
                "pk": "SO__shabellaha_hose"
            },
            {
                "_id": "togdher",
                "code": "togdher",
                "name": "Togdher",
                "pk": "SO__togdher"
            },
            {
                "_id": "woqoyi_galbed",
                "code": "woqoyi_galbed",
                "name": "Woqoyi Galbed",
                "pk": "SO__woqoyi_galbed"
            }
        ],
        "pk": "SO"
    },
    {
        "_id": "ZA",
        "code": "ZA",
        "name": "South Africa",
        "states": [
            {
                "_id": "eastern_cape",
                "code": "eastern_cape",
                "name": "Eastern Cape",
                "pk": "ZA__eastern_cape"
            },
            {
                "_id": "free_state",
                "code": "free_state",
                "name": "Free State",
                "pk": "ZA__free_state"
            },
            {
                "_id": "gauteng",
                "code": "gauteng",
                "name": "Gauteng",
                "pk": "ZA__gauteng"
            },
            {
                "_id": "kempton_park",
                "code": "kempton_park",
                "name": "Kempton Park",
                "pk": "ZA__kempton_park"
            },
            {
                "_id": "kramerville",
                "code": "kramerville",
                "name": "Kramerville",
                "pk": "ZA__kramerville"
            },
            {
                "_id": "kwazulu_natal",
                "code": "kwazulu_natal",
                "name": "KwaZulu Natal",
                "pk": "ZA__kwazulu_natal"
            },
            {
                "_id": "limpopo",
                "code": "limpopo",
                "name": "Limpopo",
                "pk": "ZA__limpopo"
            },
            {
                "_id": "mpumalanga",
                "code": "mpumalanga",
                "name": "Mpumalanga",
                "pk": "ZA__mpumalanga"
            },
            {
                "_id": "north_west",
                "code": "north_west",
                "name": "North West",
                "pk": "ZA__north_west"
            },
            {
                "_id": "northern_cape",
                "code": "northern_cape",
                "name": "Northern Cape",
                "pk": "ZA__northern_cape"
            },
            {
                "_id": "parow",
                "code": "parow",
                "name": "Parow",
                "pk": "ZA__parow"
            },
            {
                "_id": "table_view",
                "code": "table_view",
                "name": "Table View",
                "pk": "ZA__table_view"
            },
            {
                "_id": "umtentweni",
                "code": "umtentweni",
                "name": "Umtentweni",
                "pk": "ZA__umtentweni"
            },
            {
                "_id": "western_cape",
                "code": "western_cape",
                "name": "Western Cape",
                "pk": "ZA__western_cape"
            }
        ],
        "pk": "ZA"
    },
    {
        "_id": "GS",
        "code": "GS",
        "name": "South Georgia",
        "states": [
            {
                "_id": "south_georgia",
                "code": "south_georgia",
                "name": "South Georgia",
                "pk": "GS__south_georgia"
            }
        ],
        "pk": "GS"
    },
    {
        "_id": "SS",
        "code": "SS",
        "name": "South Sudan",
        "states": [
            {
                "_id": "central_equatoria",
                "code": "central_equatoria",
                "name": "Central Equatoria",
                "pk": "SS__central_equatoria"
            }
        ],
        "pk": "SS"
    },
    {
        "_id": "ES",
        "code": "ES",
        "name": "Spain",
        "states": [
            {
                "_id": "a_coruna",
                "code": "a_coruna",
                "name": "A Coruna",
                "pk": "ES__a_coruna"
            },
            {
                "_id": "alacant",
                "code": "alacant",
                "name": "Alacant",
                "pk": "ES__alacant"
            },
            {
                "_id": "alava",
                "code": "alava",
                "name": "Alava",
                "pk": "ES__alava"
            },
            {
                "_id": "albacete",
                "code": "albacete",
                "name": "Albacete",
                "pk": "ES__albacete"
            },
            {
                "_id": "almeria",
                "code": "almeria",
                "name": "Almeria",
                "pk": "ES__almeria"
            },
            {
                "_id": "andalucia",
                "code": "andalucia",
                "name": "Andalucia",
                "pk": "ES__andalucia"
            },
            {
                "_id": "asturias",
                "code": "asturias",
                "name": "Asturias",
                "pk": "ES__asturias"
            },
            {
                "_id": "avila",
                "code": "avila",
                "name": "Avila",
                "pk": "ES__avila"
            },
            {
                "_id": "badajoz",
                "code": "badajoz",
                "name": "Badajoz",
                "pk": "ES__badajoz"
            },
            {
                "_id": "balears",
                "code": "balears",
                "name": "Balears",
                "pk": "ES__balears"
            },
            {
                "_id": "barcelona",
                "code": "barcelona",
                "name": "Barcelona",
                "pk": "ES__barcelona"
            },
            {
                "_id": "bertamirans",
                "code": "bertamirans",
                "name": "Bertamirans",
                "pk": "ES__bertamirans"
            },
            {
                "_id": "biscay",
                "code": "biscay",
                "name": "Biscay",
                "pk": "ES__biscay"
            },
            {
                "_id": "burgos",
                "code": "burgos",
                "name": "Burgos",
                "pk": "ES__burgos"
            },
            {
                "_id": "caceres",
                "code": "caceres",
                "name": "Caceres",
                "pk": "ES__caceres"
            },
            {
                "_id": "cadiz",
                "code": "cadiz",
                "name": "Cadiz",
                "pk": "ES__cadiz"
            },
            {
                "_id": "cantabria",
                "code": "cantabria",
                "name": "Cantabria",
                "pk": "ES__cantabria"
            },
            {
                "_id": "castello",
                "code": "castello",
                "name": "Castello",
                "pk": "ES__castello"
            },
            {
                "_id": "catalunya",
                "code": "catalunya",
                "name": "Catalunya",
                "pk": "ES__catalunya"
            },
            {
                "_id": "ceuta",
                "code": "ceuta",
                "name": "Ceuta",
                "pk": "ES__ceuta"
            },
            {
                "_id": "ciudad_real",
                "code": "ciudad_real",
                "name": "Ciudad Real",
                "pk": "ES__ciudad_real"
            },
            {
                "_id": "comunidad_autonoma_de_canarias",
                "code": "comunidad_autonoma_de_canarias",
                "name": "Comunidad Autonoma de Canarias",
                "pk": "ES__comunidad_autonoma_de_canarias"
            },
            {
                "_id": "comunidad_autonoma_de_cataluna",
                "code": "comunidad_autonoma_de_cataluna",
                "name": "Comunidad Autonoma de Cataluna",
                "pk": "ES__comunidad_autonoma_de_cataluna"
            },
            {
                "_id": "comunidad_autonoma_de_galicia",
                "code": "comunidad_autonoma_de_galicia",
                "name": "Comunidad Autonoma de Galicia",
                "pk": "ES__comunidad_autonoma_de_galicia"
            },
            {
                "_id": "comunidad_autonoma_de_las_isla",
                "code": "comunidad_autonoma_de_las_isla",
                "name": "Comunidad Autonoma de las Isla",
                "pk": "ES__comunidad_autonoma_de_las_isla"
            },
            {
                "_id": "comunidad_autonoma_del_princip",
                "code": "comunidad_autonoma_del_princip",
                "name": "Comunidad Autonoma del Princip",
                "pk": "ES__comunidad_autonoma_del_princip"
            },
            {
                "_id": "comunidad_valenciana",
                "code": "comunidad_valenciana",
                "name": "Comunidad Valenciana",
                "pk": "ES__comunidad_valenciana"
            },
            {
                "_id": "cordoba",
                "code": "cordoba",
                "name": "Cordoba",
                "pk": "ES__cordoba"
            },
            {
                "_id": "cuenca",
                "code": "cuenca",
                "name": "Cuenca",
                "pk": "ES__cuenca"
            },
            {
                "_id": "gipuzkoa",
                "code": "gipuzkoa",
                "name": "Gipuzkoa",
                "pk": "ES__gipuzkoa"
            },
            {
                "_id": "girona",
                "code": "girona",
                "name": "Girona",
                "pk": "ES__girona"
            },
            {
                "_id": "granada",
                "code": "granada",
                "name": "Granada",
                "pk": "ES__granada"
            },
            {
                "_id": "guadalajara",
                "code": "guadalajara",
                "name": "Guadalajara",
                "pk": "ES__guadalajara"
            },
            {
                "_id": "guipuzcoa",
                "code": "guipuzcoa",
                "name": "Guipuzcoa",
                "pk": "ES__guipuzcoa"
            },
            {
                "_id": "huelva",
                "code": "huelva",
                "name": "Huelva",
                "pk": "ES__huelva"
            },
            {
                "_id": "huesca",
                "code": "huesca",
                "name": "Huesca",
                "pk": "ES__huesca"
            },
            {
                "_id": "jaen",
                "code": "jaen",
                "name": "Jaen",
                "pk": "ES__jaen"
            },
            {
                "_id": "la_rioja",
                "code": "la_rioja",
                "name": "La Rioja",
                "pk": "ES__la_rioja"
            },
            {
                "_id": "las_palmas",
                "code": "las_palmas",
                "name": "Las Palmas",
                "pk": "ES__las_palmas"
            },
            {
                "_id": "leon",
                "code": "leon",
                "name": "Leon",
                "pk": "ES__leon"
            },
            {
                "_id": "lerida",
                "code": "lerida",
                "name": "Lerida",
                "pk": "ES__lerida"
            },
            {
                "_id": "lleida",
                "code": "lleida",
                "name": "Lleida",
                "pk": "ES__lleida"
            },
            {
                "_id": "lugo",
                "code": "lugo",
                "name": "Lugo",
                "pk": "ES__lugo"
            },
            {
                "_id": "madrid",
                "code": "madrid",
                "name": "Madrid",
                "pk": "ES__madrid"
            },
            {
                "_id": "malaga",
                "code": "malaga",
                "name": "Malaga",
                "pk": "ES__malaga"
            },
            {
                "_id": "melilla",
                "code": "melilla",
                "name": "Melilla",
                "pk": "ES__melilla"
            },
            {
                "_id": "murcia",
                "code": "murcia",
                "name": "Murcia",
                "pk": "ES__murcia"
            },
            {
                "_id": "navarra",
                "code": "navarra",
                "name": "Navarra",
                "pk": "ES__navarra"
            },
            {
                "_id": "ourense",
                "code": "ourense",
                "name": "Ourense",
                "pk": "ES__ourense"
            },
            {
                "_id": "pais_vasco",
                "code": "pais_vasco",
                "name": "Pais Vasco",
                "pk": "ES__pais_vasco"
            },
            {
                "_id": "palencia",
                "code": "palencia",
                "name": "Palencia",
                "pk": "ES__palencia"
            },
            {
                "_id": "pontevedra",
                "code": "pontevedra",
                "name": "Pontevedra",
                "pk": "ES__pontevedra"
            },
            {
                "_id": "salamanca",
                "code": "salamanca",
                "name": "Salamanca",
                "pk": "ES__salamanca"
            },
            {
                "_id": "santa_cruz_de_tenerife",
                "code": "santa_cruz_de_tenerife",
                "name": "Santa Cruz de Tenerife",
                "pk": "ES__santa_cruz_de_tenerife"
            },
            {
                "_id": "segovia",
                "code": "segovia",
                "name": "Segovia",
                "pk": "ES__segovia"
            },
            {
                "_id": "sevilla",
                "code": "sevilla",
                "name": "Sevilla",
                "pk": "ES__sevilla"
            },
            {
                "_id": "soria",
                "code": "soria",
                "name": "Soria",
                "pk": "ES__soria"
            },
            {
                "_id": "tarragona",
                "code": "tarragona",
                "name": "Tarragona",
                "pk": "ES__tarragona"
            },
            {
                "_id": "tenerife",
                "code": "tenerife",
                "name": "Tenerife",
                "pk": "ES__tenerife"
            },
            {
                "_id": "teruel",
                "code": "teruel",
                "name": "Teruel",
                "pk": "ES__teruel"
            },
            {
                "_id": "toledo",
                "code": "toledo",
                "name": "Toledo",
                "pk": "ES__toledo"
            },
            {
                "_id": "valencia",
                "code": "valencia",
                "name": "Valencia",
                "pk": "ES__valencia"
            },
            {
                "_id": "valladolid",
                "code": "valladolid",
                "name": "Valladolid",
                "pk": "ES__valladolid"
            },
            {
                "_id": "vizcaya",
                "code": "vizcaya",
                "name": "Vizcaya",
                "pk": "ES__vizcaya"
            },
            {
                "_id": "zamora",
                "code": "zamora",
                "name": "Zamora",
                "pk": "ES__zamora"
            },
            {
                "_id": "zaragoza",
                "code": "zaragoza",
                "name": "Zaragoza",
                "pk": "ES__zaragoza"
            }
        ],
        "only_shipping": true,
        "pk": "ES"
    },
    {
        "_id": "LK",
        "code": "LK",
        "name": "Sri Lanka",
        "states": [
            {
                "_id": "amparai",
                "code": "amparai",
                "name": "Amparai",
                "pk": "LK__amparai"
            },
            {
                "_id": "anuradhapuraya",
                "code": "anuradhapuraya",
                "name": "Anuradhapuraya",
                "pk": "LK__anuradhapuraya"
            },
            {
                "_id": "badulla",
                "code": "badulla",
                "name": "Badulla",
                "pk": "LK__badulla"
            },
            {
                "_id": "boralesgamuwa",
                "code": "boralesgamuwa",
                "name": "Boralesgamuwa",
                "pk": "LK__boralesgamuwa"
            },
            {
                "_id": "colombo",
                "code": "colombo",
                "name": "Colombo",
                "pk": "LK__colombo"
            },
            {
                "_id": "galla",
                "code": "galla",
                "name": "Galla",
                "pk": "LK__galla"
            },
            {
                "_id": "gampaha",
                "code": "gampaha",
                "name": "Gampaha",
                "pk": "LK__gampaha"
            },
            {
                "_id": "hambantota",
                "code": "hambantota",
                "name": "Hambantota",
                "pk": "LK__hambantota"
            },
            {
                "_id": "kalatura",
                "code": "kalatura",
                "name": "Kalatura",
                "pk": "LK__kalatura"
            },
            {
                "_id": "kegalla",
                "code": "kegalla",
                "name": "Kegalla",
                "pk": "LK__kegalla"
            },
            {
                "_id": "kilinochchi",
                "code": "kilinochchi",
                "name": "Kilinochchi",
                "pk": "LK__kilinochchi"
            },
            {
                "_id": "kurunegala",
                "code": "kurunegala",
                "name": "Kurunegala",
                "pk": "LK__kurunegala"
            },
            {
                "_id": "madakalpuwa",
                "code": "madakalpuwa",
                "name": "Madakalpuwa",
                "pk": "LK__madakalpuwa"
            },
            {
                "_id": "maha_nuwara",
                "code": "maha_nuwara",
                "name": "Maha Nuwara",
                "pk": "LK__maha_nuwara"
            },
            {
                "_id": "malwana",
                "code": "malwana",
                "name": "Malwana",
                "pk": "LK__malwana"
            },
            {
                "_id": "mannarama",
                "code": "mannarama",
                "name": "Mannarama",
                "pk": "LK__mannarama"
            },
            {
                "_id": "matale",
                "code": "matale",
                "name": "Matale",
                "pk": "LK__matale"
            },
            {
                "_id": "matara",
                "code": "matara",
                "name": "Matara",
                "pk": "LK__matara"
            },
            {
                "_id": "monaragala",
                "code": "monaragala",
                "name": "Monaragala",
                "pk": "LK__monaragala"
            },
            {
                "_id": "mullaitivu",
                "code": "mullaitivu",
                "name": "Mullaitivu",
                "pk": "LK__mullaitivu"
            },
            {
                "_id": "north_eastern_province",
                "code": "north_eastern_province",
                "name": "North Eastern Province",
                "pk": "LK__north_eastern_province"
            },
            {
                "_id": "north_western_province",
                "code": "north_western_province",
                "name": "North Western Province",
                "pk": "LK__north_western_province"
            },
            {
                "_id": "nuwara_eliya",
                "code": "nuwara_eliya",
                "name": "Nuwara Eliya",
                "pk": "LK__nuwara_eliya"
            },
            {
                "_id": "polonnaruwa",
                "code": "polonnaruwa",
                "name": "Polonnaruwa",
                "pk": "LK__polonnaruwa"
            },
            {
                "_id": "puttalama",
                "code": "puttalama",
                "name": "Puttalama",
                "pk": "LK__puttalama"
            },
            {
                "_id": "ratnapuraya",
                "code": "ratnapuraya",
                "name": "Ratnapuraya",
                "pk": "LK__ratnapuraya"
            },
            {
                "_id": "southern_province",
                "code": "southern_province",
                "name": "Southern Province",
                "pk": "LK__southern_province"
            },
            {
                "_id": "tirikunamalaya",
                "code": "tirikunamalaya",
                "name": "Tirikunamalaya",
                "pk": "LK__tirikunamalaya"
            },
            {
                "_id": "tuscany",
                "code": "tuscany",
                "name": "Tuscany",
                "pk": "LK__tuscany"
            },
            {
                "_id": "vavuniyawa",
                "code": "vavuniyawa",
                "name": "Vavuniyawa",
                "pk": "LK__vavuniyawa"
            },
            {
                "_id": "western_province",
                "code": "western_province",
                "name": "Western Province",
                "pk": "LK__western_province"
            },
            {
                "_id": "yapanaya",
                "code": "yapanaya",
                "name": "Yapanaya",
                "pk": "LK__yapanaya"
            },
            {
                "_id": "kadawatha",
                "code": "kadawatha",
                "name": "kadawatha",
                "pk": "LK__kadawatha"
            }
        ],
        "pk": "LK"
    },
    {
        "_id": "SD",
        "code": "SD",
        "name": "Sudan",
        "states": [
            {
                "_id": "aali-an-nil",
                "code": "aali-an-nil",
                "name": "Aali-an-Nil",
                "pk": "SD__aali-an-nil"
            },
            {
                "_id": "bahr-al-jabal",
                "code": "bahr-al-jabal",
                "name": "Bahr-al-Jabal",
                "pk": "SD__bahr-al-jabal"
            },
            {
                "_id": "central_equatoria",
                "code": "central_equatoria",
                "name": "Central Equatoria",
                "pk": "SD__central_equatoria"
            },
            {
                "_id": "gharb_bahr-al-ghazal",
                "code": "gharb_bahr-al-ghazal",
                "name": "Gharb Bahr-al-Ghazal",
                "pk": "SD__gharb_bahr-al-ghazal"
            },
            {
                "_id": "gharb_darfur",
                "code": "gharb_darfur",
                "name": "Gharb Darfur",
                "pk": "SD__gharb_darfur"
            },
            {
                "_id": "gharb_kurdufan",
                "code": "gharb_kurdufan",
                "name": "Gharb Kurdufan",
                "pk": "SD__gharb_kurdufan"
            },
            {
                "_id": "gharb-al-istiwaiyah",
                "code": "gharb-al-istiwaiyah",
                "name": "Gharb-al-Istiwaiyah",
                "pk": "SD__gharb-al-istiwaiyah"
            },
            {
                "_id": "janub_darfur",
                "code": "janub_darfur",
                "name": "Janub Darfur",
                "pk": "SD__janub_darfur"
            },
            {
                "_id": "janub_kurdufan",
                "code": "janub_kurdufan",
                "name": "Janub Kurdufan",
                "pk": "SD__janub_kurdufan"
            },
            {
                "_id": "junqali",
                "code": "junqali",
                "name": "Junqali",
                "pk": "SD__junqali"
            },
            {
                "_id": "kassala",
                "code": "kassala",
                "name": "Kassala",
                "pk": "SD__kassala"
            },
            {
                "_id": "nahr-an-nil",
                "code": "nahr-an-nil",
                "name": "Nahr-an-Nil",
                "pk": "SD__nahr-an-nil"
            },
            {
                "_id": "shamal_bahr-al-ghazal",
                "code": "shamal_bahr-al-ghazal",
                "name": "Shamal Bahr-al-Ghazal",
                "pk": "SD__shamal_bahr-al-ghazal"
            },
            {
                "_id": "shamal_darfur",
                "code": "shamal_darfur",
                "name": "Shamal Darfur",
                "pk": "SD__shamal_darfur"
            },
            {
                "_id": "shamal_kurdufan",
                "code": "shamal_kurdufan",
                "name": "Shamal Kurdufan",
                "pk": "SD__shamal_kurdufan"
            },
            {
                "_id": "sharq-al-istiwaiyah",
                "code": "sharq-al-istiwaiyah",
                "name": "Sharq-al-Istiwaiyah",
                "pk": "SD__sharq-al-istiwaiyah"
            },
            {
                "_id": "sinnar",
                "code": "sinnar",
                "name": "Sinnar",
                "pk": "SD__sinnar"
            },
            {
                "_id": "warab",
                "code": "warab",
                "name": "Warab",
                "pk": "SD__warab"
            },
            {
                "_id": "wilayat_al_khartum",
                "code": "wilayat_al_khartum",
                "name": "Wilayat al Khartum",
                "pk": "SD__wilayat_al_khartum"
            },
            {
                "_id": "al-bahr-al-ahmar",
                "code": "al-bahr-al-ahmar",
                "name": "al-Bahr-al-Ahmar",
                "pk": "SD__al-bahr-al-ahmar"
            },
            {
                "_id": "al-buhayrat",
                "code": "al-buhayrat",
                "name": "al-Buhayrat",
                "pk": "SD__al-buhayrat"
            },
            {
                "_id": "al-jazirah",
                "code": "al-jazirah",
                "name": "al-Jazirah",
                "pk": "SD__al-jazirah"
            },
            {
                "_id": "al-khartum",
                "code": "al-khartum",
                "name": "al-Khartum",
                "pk": "SD__al-khartum"
            },
            {
                "_id": "al-qadarif",
                "code": "al-qadarif",
                "name": "al-Qadarif",
                "pk": "SD__al-qadarif"
            },
            {
                "_id": "al-wahdah",
                "code": "al-wahdah",
                "name": "al-Wahdah",
                "pk": "SD__al-wahdah"
            },
            {
                "_id": "an-nil-al-abyad",
                "code": "an-nil-al-abyad",
                "name": "an-Nil-al-Abyad",
                "pk": "SD__an-nil-al-abyad"
            },
            {
                "_id": "an-nil-al-azraq",
                "code": "an-nil-al-azraq",
                "name": "an-Nil-al-Azraq",
                "pk": "SD__an-nil-al-azraq"
            },
            {
                "_id": "ash-shamaliyah",
                "code": "ash-shamaliyah",
                "name": "ash-Shamaliyah",
                "pk": "SD__ash-shamaliyah"
            }
        ],
        "pk": "SD"
    },
    {
        "_id": "SR",
        "code": "SR",
        "name": "Suriname",
        "states": [
            {
                "_id": "brokopondo",
                "code": "brokopondo",
                "name": "Brokopondo",
                "pk": "SR__brokopondo"
            },
            {
                "_id": "commewijne",
                "code": "commewijne",
                "name": "Commewijne",
                "pk": "SR__commewijne"
            },
            {
                "_id": "coronie",
                "code": "coronie",
                "name": "Coronie",
                "pk": "SR__coronie"
            },
            {
                "_id": "marowijne",
                "code": "marowijne",
                "name": "Marowijne",
                "pk": "SR__marowijne"
            },
            {
                "_id": "nickerie",
                "code": "nickerie",
                "name": "Nickerie",
                "pk": "SR__nickerie"
            },
            {
                "_id": "para",
                "code": "para",
                "name": "Para",
                "pk": "SR__para"
            },
            {
                "_id": "paramaribo",
                "code": "paramaribo",
                "name": "Paramaribo",
                "pk": "SR__paramaribo"
            },
            {
                "_id": "saramacca",
                "code": "saramacca",
                "name": "Saramacca",
                "pk": "SR__saramacca"
            },
            {
                "_id": "wanica",
                "code": "wanica",
                "name": "Wanica",
                "pk": "SR__wanica"
            }
        ],
        "pk": "SR"
    },
    {
        "_id": "SJ",
        "code": "SJ",
        "name": "Svalbard And Jan Mayen Islands",
        "states": [
            {
                "_id": "svalbard",
                "code": "svalbard",
                "name": "Svalbard",
                "pk": "SJ__svalbard"
            }
        ],
        "pk": "SJ"
    },
    {
        "_id": "SZ",
        "code": "SZ",
        "name": "Swaziland",
        "states": [
            {
                "_id": "hhohho",
                "code": "hhohho",
                "name": "Hhohho",
                "pk": "SZ__hhohho"
            },
            {
                "_id": "lubombo",
                "code": "lubombo",
                "name": "Lubombo",
                "pk": "SZ__lubombo"
            },
            {
                "_id": "manzini",
                "code": "manzini",
                "name": "Manzini",
                "pk": "SZ__manzini"
            },
            {
                "_id": "shiselweni",
                "code": "shiselweni",
                "name": "Shiselweni",
                "pk": "SZ__shiselweni"
            }
        ],
        "pk": "SZ"
    },
    {
        "_id": "SE",
        "code": "SE",
        "name": "Sweden",
        "states": [
            {
                "_id": "alvsborgs_lan",
                "code": "alvsborgs_lan",
                "name": "Alvsborgs Lan",
                "pk": "SE__alvsborgs_lan"
            },
            {
                "_id": "angermanland",
                "code": "angermanland",
                "name": "Angermanland",
                "pk": "SE__angermanland"
            },
            {
                "_id": "blekinge",
                "code": "blekinge",
                "name": "Blekinge",
                "pk": "SE__blekinge"
            },
            {
                "_id": "bohuslan",
                "code": "bohuslan",
                "name": "Bohuslan",
                "pk": "SE__bohuslan"
            },
            {
                "_id": "dalarna",
                "code": "dalarna",
                "name": "Dalarna",
                "pk": "SE__dalarna"
            },
            {
                "_id": "gavleborg",
                "code": "gavleborg",
                "name": "Gavleborg",
                "pk": "SE__gavleborg"
            },
            {
                "_id": "gaza",
                "code": "gaza",
                "name": "Gaza",
                "pk": "SE__gaza"
            },
            {
                "_id": "gotland",
                "code": "gotland",
                "name": "Gotland",
                "pk": "SE__gotland"
            },
            {
                "_id": "halland",
                "code": "halland",
                "name": "Halland",
                "pk": "SE__halland"
            },
            {
                "_id": "jamtland",
                "code": "jamtland",
                "name": "Jamtland",
                "pk": "SE__jamtland"
            },
            {
                "_id": "jonkoping",
                "code": "jonkoping",
                "name": "Jonkoping",
                "pk": "SE__jonkoping"
            },
            {
                "_id": "kalmar",
                "code": "kalmar",
                "name": "Kalmar",
                "pk": "SE__kalmar"
            },
            {
                "_id": "kristianstads",
                "code": "kristianstads",
                "name": "Kristianstads",
                "pk": "SE__kristianstads"
            },
            {
                "_id": "kronoberg",
                "code": "kronoberg",
                "name": "Kronoberg",
                "pk": "SE__kronoberg"
            },
            {
                "_id": "norrbotten",
                "code": "norrbotten",
                "name": "Norrbotten",
                "pk": "SE__norrbotten"
            },
            {
                "_id": "orebro",
                "code": "orebro",
                "name": "Orebro",
                "pk": "SE__orebro"
            },
            {
                "_id": "ostergotland",
                "code": "ostergotland",
                "name": "Ostergotland",
                "pk": "SE__ostergotland"
            },
            {
                "_id": "saltsjo-boo",
                "code": "saltsjo-boo",
                "name": "Saltsjo-Boo",
                "pk": "SE__saltsjo-boo"
            },
            {
                "_id": "skane",
                "code": "skane",
                "name": "Skane",
                "pk": "SE__skane"
            },
            {
                "_id": "smaland",
                "code": "smaland",
                "name": "Smaland",
                "pk": "SE__smaland"
            },
            {
                "_id": "sodermanland",
                "code": "sodermanland",
                "name": "Sodermanland",
                "pk": "SE__sodermanland"
            },
            {
                "_id": "stockholm",
                "code": "stockholm",
                "name": "Stockholm",
                "pk": "SE__stockholm"
            },
            {
                "_id": "uppsala",
                "code": "uppsala",
                "name": "Uppsala",
                "pk": "SE__uppsala"
            },
            {
                "_id": "varmland",
                "code": "varmland",
                "name": "Varmland",
                "pk": "SE__varmland"
            },
            {
                "_id": "vasterbotten",
                "code": "vasterbotten",
                "name": "Vasterbotten",
                "pk": "SE__vasterbotten"
            },
            {
                "_id": "vastergotland",
                "code": "vastergotland",
                "name": "Vastergotland",
                "pk": "SE__vastergotland"
            },
            {
                "_id": "vasternorrland",
                "code": "vasternorrland",
                "name": "Vasternorrland",
                "pk": "SE__vasternorrland"
            },
            {
                "_id": "vastmanland",
                "code": "vastmanland",
                "name": "Vastmanland",
                "pk": "SE__vastmanland"
            },
            {
                "_id": "vastra_gotaland",
                "code": "vastra_gotaland",
                "name": "Vastra Gotaland",
                "pk": "SE__vastra_gotaland"
            }
        ],
        "pk": "SE"
    },
    {
        "_id": "CH",
        "code": "CH",
        "name": "Switzerland",
        "states": [
            {
                "_id": "aargau",
                "code": "aargau",
                "name": "Aargau",
                "pk": "CH__aargau"
            },
            {
                "_id": "appenzell_inner-rhoden",
                "code": "appenzell_inner-rhoden",
                "name": "Appenzell Inner-Rhoden",
                "pk": "CH__appenzell_inner-rhoden"
            },
            {
                "_id": "appenzell-ausser_rhoden",
                "code": "appenzell-ausser_rhoden",
                "name": "Appenzell-Ausser Rhoden",
                "pk": "CH__appenzell-ausser_rhoden"
            },
            {
                "_id": "basel-landschaft",
                "code": "basel-landschaft",
                "name": "Basel-Landschaft",
                "pk": "CH__basel-landschaft"
            },
            {
                "_id": "basel-stadt",
                "code": "basel-stadt",
                "name": "Basel-Stadt",
                "pk": "CH__basel-stadt"
            },
            {
                "_id": "bern",
                "code": "bern",
                "name": "Bern",
                "pk": "CH__bern"
            },
            {
                "_id": "canton_ticino",
                "code": "canton_ticino",
                "name": "Canton Ticino",
                "pk": "CH__canton_ticino"
            },
            {
                "_id": "fribourg",
                "code": "fribourg",
                "name": "Fribourg",
                "pk": "CH__fribourg"
            },
            {
                "_id": "geneve",
                "code": "geneve",
                "name": "Geneve",
                "pk": "CH__geneve"
            },
            {
                "_id": "glarus",
                "code": "glarus",
                "name": "Glarus",
                "pk": "CH__glarus"
            },
            {
                "_id": "graubunden",
                "code": "graubunden",
                "name": "Graubunden",
                "pk": "CH__graubunden"
            },
            {
                "_id": "heerbrugg",
                "code": "heerbrugg",
                "name": "Heerbrugg",
                "pk": "CH__heerbrugg"
            },
            {
                "_id": "jura",
                "code": "jura",
                "name": "Jura",
                "pk": "CH__jura"
            },
            {
                "_id": "kanton_aargau",
                "code": "kanton_aargau",
                "name": "Kanton Aargau",
                "pk": "CH__kanton_aargau"
            },
            {
                "_id": "luzern",
                "code": "luzern",
                "name": "Luzern",
                "pk": "CH__luzern"
            },
            {
                "_id": "morbio_inferiore",
                "code": "morbio_inferiore",
                "name": "Morbio Inferiore",
                "pk": "CH__morbio_inferiore"
            },
            {
                "_id": "muhen",
                "code": "muhen",
                "name": "Muhen",
                "pk": "CH__muhen"
            },
            {
                "_id": "neuchatel",
                "code": "neuchatel",
                "name": "Neuchatel",
                "pk": "CH__neuchatel"
            },
            {
                "_id": "nidwalden",
                "code": "nidwalden",
                "name": "Nidwalden",
                "pk": "CH__nidwalden"
            },
            {
                "_id": "obwalden",
                "code": "obwalden",
                "name": "Obwalden",
                "pk": "CH__obwalden"
            },
            {
                "_id": "sankt_gallen",
                "code": "sankt_gallen",
                "name": "Sankt Gallen",
                "pk": "CH__sankt_gallen"
            },
            {
                "_id": "schaffhausen",
                "code": "schaffhausen",
                "name": "Schaffhausen",
                "pk": "CH__schaffhausen"
            },
            {
                "_id": "schwyz",
                "code": "schwyz",
                "name": "Schwyz",
                "pk": "CH__schwyz"
            },
            {
                "_id": "solothurn",
                "code": "solothurn",
                "name": "Solothurn",
                "pk": "CH__solothurn"
            },
            {
                "_id": "thurgau",
                "code": "thurgau",
                "name": "Thurgau",
                "pk": "CH__thurgau"
            },
            {
                "_id": "ticino",
                "code": "ticino",
                "name": "Ticino",
                "pk": "CH__ticino"
            },
            {
                "_id": "uri",
                "code": "uri",
                "name": "Uri",
                "pk": "CH__uri"
            },
            {
                "_id": "valais",
                "code": "valais",
                "name": "Valais",
                "pk": "CH__valais"
            },
            {
                "_id": "vaud",
                "code": "vaud",
                "name": "Vaud",
                "pk": "CH__vaud"
            },
            {
                "_id": "vauffelin",
                "code": "vauffelin",
                "name": "Vauffelin",
                "pk": "CH__vauffelin"
            },
            {
                "_id": "zug",
                "code": "zug",
                "name": "Zug",
                "pk": "CH__zug"
            },
            {
                "_id": "zurich",
                "code": "zurich",
                "name": "Zurich",
                "pk": "CH__zurich"
            }
        ],
        "only_shipping": true,
        "pk": "CH"
    },
    {
        "_id": "SY",
        "code": "SY",
        "name": "Syria",
        "states": [
            {
                "_id": "aleppo",
                "code": "aleppo",
                "name": "Aleppo",
                "pk": "SY__aleppo"
            },
            {
                "_id": "dara",
                "code": "dara",
                "name": "Dara",
                "pk": "SY__dara"
            },
            {
                "_id": "dayr-az-zawr",
                "code": "dayr-az-zawr",
                "name": "Dayr-az-Zawr",
                "pk": "SY__dayr-az-zawr"
            },
            {
                "_id": "dimashq",
                "code": "dimashq",
                "name": "Dimashq",
                "pk": "SY__dimashq"
            },
            {
                "_id": "halab",
                "code": "halab",
                "name": "Halab",
                "pk": "SY__halab"
            },
            {
                "_id": "hamah",
                "code": "hamah",
                "name": "Hamah",
                "pk": "SY__hamah"
            },
            {
                "_id": "hims",
                "code": "hims",
                "name": "Hims",
                "pk": "SY__hims"
            },
            {
                "_id": "idlib",
                "code": "idlib",
                "name": "Idlib",
                "pk": "SY__idlib"
            },
            {
                "_id": "madinat_dimashq",
                "code": "madinat_dimashq",
                "name": "Madinat Dimashq",
                "pk": "SY__madinat_dimashq"
            },
            {
                "_id": "tartus",
                "code": "tartus",
                "name": "Tartus",
                "pk": "SY__tartus"
            },
            {
                "_id": "al-hasakah",
                "code": "al-hasakah",
                "name": "al-Hasakah",
                "pk": "SY__al-hasakah"
            },
            {
                "_id": "al-ladhiqiyah",
                "code": "al-ladhiqiyah",
                "name": "al-Ladhiqiyah",
                "pk": "SY__al-ladhiqiyah"
            },
            {
                "_id": "al-qunaytirah",
                "code": "al-qunaytirah",
                "name": "al-Qunaytirah",
                "pk": "SY__al-qunaytirah"
            },
            {
                "_id": "ar-raqqah",
                "code": "ar-raqqah",
                "name": "ar-Raqqah",
                "pk": "SY__ar-raqqah"
            },
            {
                "_id": "as-suwayda",
                "code": "as-suwayda",
                "name": "as-Suwayda",
                "pk": "SY__as-suwayda"
            }
        ],
        "pk": "SY"
    },
    {
        "_id": "TW",
        "code": "TW",
        "name": "Taiwan",
        "states": [
            {
                "_id": "changhwa",
                "code": "changhwa",
                "name": "Changhwa",
                "pk": "TW__changhwa"
            },
            {
                "_id": "chiayi_hsien",
                "code": "chiayi_hsien",
                "name": "Chiayi Hsien",
                "pk": "TW__chiayi_hsien"
            },
            {
                "_id": "chiayi_shih",
                "code": "chiayi_shih",
                "name": "Chiayi Shih",
                "pk": "TW__chiayi_shih"
            },
            {
                "_id": "eastern_taipei",
                "code": "eastern_taipei",
                "name": "Eastern Taipei",
                "pk": "TW__eastern_taipei"
            },
            {
                "_id": "hsinchu_hsien",
                "code": "hsinchu_hsien",
                "name": "Hsinchu Hsien",
                "pk": "TW__hsinchu_hsien"
            },
            {
                "_id": "hsinchu_shih",
                "code": "hsinchu_shih",
                "name": "Hsinchu Shih",
                "pk": "TW__hsinchu_shih"
            },
            {
                "_id": "hualien",
                "code": "hualien",
                "name": "Hualien",
                "pk": "TW__hualien"
            },
            {
                "_id": "ilan",
                "code": "ilan",
                "name": "Ilan",
                "pk": "TW__ilan"
            },
            {
                "_id": "kaohsiung_hsien",
                "code": "kaohsiung_hsien",
                "name": "Kaohsiung Hsien",
                "pk": "TW__kaohsiung_hsien"
            },
            {
                "_id": "kaohsiung_shih",
                "code": "kaohsiung_shih",
                "name": "Kaohsiung Shih",
                "pk": "TW__kaohsiung_shih"
            },
            {
                "_id": "keelung_shih",
                "code": "keelung_shih",
                "name": "Keelung Shih",
                "pk": "TW__keelung_shih"
            },
            {
                "_id": "kinmen",
                "code": "kinmen",
                "name": "Kinmen",
                "pk": "TW__kinmen"
            },
            {
                "_id": "miaoli",
                "code": "miaoli",
                "name": "Miaoli",
                "pk": "TW__miaoli"
            },
            {
                "_id": "nantou",
                "code": "nantou",
                "name": "Nantou",
                "pk": "TW__nantou"
            },
            {
                "_id": "northern_taiwan",
                "code": "northern_taiwan",
                "name": "Northern Taiwan",
                "pk": "TW__northern_taiwan"
            },
            {
                "_id": "penghu",
                "code": "penghu",
                "name": "Penghu",
                "pk": "TW__penghu"
            },
            {
                "_id": "pingtung",
                "code": "pingtung",
                "name": "Pingtung",
                "pk": "TW__pingtung"
            },
            {
                "_id": "taichung",
                "code": "taichung",
                "name": "Taichung",
                "pk": "TW__taichung"
            },
            {
                "_id": "taichung_hsien",
                "code": "taichung_hsien",
                "name": "Taichung Hsien",
                "pk": "TW__taichung_hsien"
            },
            {
                "_id": "taichung_shih",
                "code": "taichung_shih",
                "name": "Taichung Shih",
                "pk": "TW__taichung_shih"
            },
            {
                "_id": "tainan_hsien",
                "code": "tainan_hsien",
                "name": "Tainan Hsien",
                "pk": "TW__tainan_hsien"
            },
            {
                "_id": "tainan_shih",
                "code": "tainan_shih",
                "name": "Tainan Shih",
                "pk": "TW__tainan_shih"
            },
            {
                "_id": "taipei_hsien",
                "code": "taipei_hsien",
                "name": "Taipei Hsien",
                "pk": "TW__taipei_hsien"
            },
            {
                "_id": "taipei_shih_/_taipei_hsien",
                "code": "taipei_shih_/_taipei_hsien",
                "name": "Taipei Shih / Taipei Hsien",
                "pk": "TW__taipei_shih_/_taipei_hsien"
            },
            {
                "_id": "taitung",
                "code": "taitung",
                "name": "Taitung",
                "pk": "TW__taitung"
            },
            {
                "_id": "taoyuan",
                "code": "taoyuan",
                "name": "Taoyuan",
                "pk": "TW__taoyuan"
            },
            {
                "_id": "yilan",
                "code": "yilan",
                "name": "Yilan",
                "pk": "TW__yilan"
            },
            {
                "_id": "yun-lin_hsien",
                "code": "yun-lin_hsien",
                "name": "Yun-Lin Hsien",
                "pk": "TW__yun-lin_hsien"
            },
            {
                "_id": "yunlin",
                "code": "yunlin",
                "name": "Yunlin",
                "pk": "TW__yunlin"
            }
        ],
        "pk": "TW"
    },
    {
        "_id": "TJ",
        "code": "TJ",
        "name": "Tajikistan",
        "states": [
            {
                "_id": "dushanbe",
                "code": "dushanbe",
                "name": "Dushanbe",
                "pk": "TJ__dushanbe"
            },
            {
                "_id": "gorno-badakhshan",
                "code": "gorno-badakhshan",
                "name": "Gorno-Badakhshan",
                "pk": "TJ__gorno-badakhshan"
            },
            {
                "_id": "karotegin",
                "code": "karotegin",
                "name": "Karotegin",
                "pk": "TJ__karotegin"
            },
            {
                "_id": "khatlon",
                "code": "khatlon",
                "name": "Khatlon",
                "pk": "TJ__khatlon"
            },
            {
                "_id": "sughd",
                "code": "sughd",
                "name": "Sughd",
                "pk": "TJ__sughd"
            }
        ],
        "pk": "TJ"
    },
    {
        "_id": "TZ",
        "code": "TZ",
        "name": "Tanzania",
        "states": [
            {
                "_id": "arusha",
                "code": "arusha",
                "name": "Arusha",
                "pk": "TZ__arusha"
            },
            {
                "_id": "dar_es_salaam",
                "code": "dar_es_salaam",
                "name": "Dar es Salaam",
                "pk": "TZ__dar_es_salaam"
            },
            {
                "_id": "dodoma",
                "code": "dodoma",
                "name": "Dodoma",
                "pk": "TZ__dodoma"
            },
            {
                "_id": "iringa",
                "code": "iringa",
                "name": "Iringa",
                "pk": "TZ__iringa"
            },
            {
                "_id": "kagera",
                "code": "kagera",
                "name": "Kagera",
                "pk": "TZ__kagera"
            },
            {
                "_id": "kigoma",
                "code": "kigoma",
                "name": "Kigoma",
                "pk": "TZ__kigoma"
            },
            {
                "_id": "kilimanjaro",
                "code": "kilimanjaro",
                "name": "Kilimanjaro",
                "pk": "TZ__kilimanjaro"
            },
            {
                "_id": "lindi",
                "code": "lindi",
                "name": "Lindi",
                "pk": "TZ__lindi"
            },
            {
                "_id": "mara",
                "code": "mara",
                "name": "Mara",
                "pk": "TZ__mara"
            },
            {
                "_id": "mbeya",
                "code": "mbeya",
                "name": "Mbeya",
                "pk": "TZ__mbeya"
            },
            {
                "_id": "morogoro",
                "code": "morogoro",
                "name": "Morogoro",
                "pk": "TZ__morogoro"
            },
            {
                "_id": "mtwara",
                "code": "mtwara",
                "name": "Mtwara",
                "pk": "TZ__mtwara"
            },
            {
                "_id": "mwanza",
                "code": "mwanza",
                "name": "Mwanza",
                "pk": "TZ__mwanza"
            },
            {
                "_id": "pwani",
                "code": "pwani",
                "name": "Pwani",
                "pk": "TZ__pwani"
            },
            {
                "_id": "rukwa",
                "code": "rukwa",
                "name": "Rukwa",
                "pk": "TZ__rukwa"
            },
            {
                "_id": "ruvuma",
                "code": "ruvuma",
                "name": "Ruvuma",
                "pk": "TZ__ruvuma"
            },
            {
                "_id": "shinyanga",
                "code": "shinyanga",
                "name": "Shinyanga",
                "pk": "TZ__shinyanga"
            },
            {
                "_id": "singida",
                "code": "singida",
                "name": "Singida",
                "pk": "TZ__singida"
            },
            {
                "_id": "tabora",
                "code": "tabora",
                "name": "Tabora",
                "pk": "TZ__tabora"
            },
            {
                "_id": "tanga",
                "code": "tanga",
                "name": "Tanga",
                "pk": "TZ__tanga"
            },
            {
                "_id": "zanzibar_and_pemba",
                "code": "zanzibar_and_pemba",
                "name": "Zanzibar and Pemba",
                "pk": "TZ__zanzibar_and_pemba"
            }
        ],
        "pk": "TZ"
    },
    {
        "_id": "TH",
        "code": "TH",
        "name": "Thailand",
        "states": [
            {
                "_id": "amnat_charoen",
                "code": "amnat_charoen",
                "name": "Amnat Charoen",
                "pk": "TH__amnat_charoen"
            },
            {
                "_id": "ang_thong",
                "code": "ang_thong",
                "name": "Ang Thong",
                "pk": "TH__ang_thong"
            },
            {
                "_id": "bangkok",
                "code": "bangkok",
                "name": "Bangkok",
                "pk": "TH__bangkok"
            },
            {
                "_id": "buri_ram",
                "code": "buri_ram",
                "name": "Buri Ram",
                "pk": "TH__buri_ram"
            },
            {
                "_id": "chachoengsao",
                "code": "chachoengsao",
                "name": "Chachoengsao",
                "pk": "TH__chachoengsao"
            },
            {
                "_id": "chai_nat",
                "code": "chai_nat",
                "name": "Chai Nat",
                "pk": "TH__chai_nat"
            },
            {
                "_id": "chaiyaphum",
                "code": "chaiyaphum",
                "name": "Chaiyaphum",
                "pk": "TH__chaiyaphum"
            },
            {
                "_id": "changwat_chaiyaphum",
                "code": "changwat_chaiyaphum",
                "name": "Changwat Chaiyaphum",
                "pk": "TH__changwat_chaiyaphum"
            },
            {
                "_id": "chanthaburi",
                "code": "chanthaburi",
                "name": "Chanthaburi",
                "pk": "TH__chanthaburi"
            },
            {
                "_id": "chiang_mai",
                "code": "chiang_mai",
                "name": "Chiang Mai",
                "pk": "TH__chiang_mai"
            },
            {
                "_id": "chiang_rai",
                "code": "chiang_rai",
                "name": "Chiang Rai",
                "pk": "TH__chiang_rai"
            },
            {
                "_id": "chon_buri",
                "code": "chon_buri",
                "name": "Chon Buri",
                "pk": "TH__chon_buri"
            },
            {
                "_id": "chumphon",
                "code": "chumphon",
                "name": "Chumphon",
                "pk": "TH__chumphon"
            },
            {
                "_id": "kalasin",
                "code": "kalasin",
                "name": "Kalasin",
                "pk": "TH__kalasin"
            },
            {
                "_id": "kamphaeng_phet",
                "code": "kamphaeng_phet",
                "name": "Kamphaeng Phet",
                "pk": "TH__kamphaeng_phet"
            },
            {
                "_id": "kanchanaburi",
                "code": "kanchanaburi",
                "name": "Kanchanaburi",
                "pk": "TH__kanchanaburi"
            },
            {
                "_id": "khon_kaen",
                "code": "khon_kaen",
                "name": "Khon Kaen",
                "pk": "TH__khon_kaen"
            },
            {
                "_id": "krabi",
                "code": "krabi",
                "name": "Krabi",
                "pk": "TH__krabi"
            },
            {
                "_id": "krung_thep",
                "code": "krung_thep",
                "name": "Krung Thep",
                "pk": "TH__krung_thep"
            },
            {
                "_id": "lampang",
                "code": "lampang",
                "name": "Lampang",
                "pk": "TH__lampang"
            },
            {
                "_id": "lamphun",
                "code": "lamphun",
                "name": "Lamphun",
                "pk": "TH__lamphun"
            },
            {
                "_id": "loei",
                "code": "loei",
                "name": "Loei",
                "pk": "TH__loei"
            },
            {
                "_id": "lop_buri",
                "code": "lop_buri",
                "name": "Lop Buri",
                "pk": "TH__lop_buri"
            },
            {
                "_id": "mae_hong_son",
                "code": "mae_hong_son",
                "name": "Mae Hong Son",
                "pk": "TH__mae_hong_son"
            },
            {
                "_id": "maha_sarakham",
                "code": "maha_sarakham",
                "name": "Maha Sarakham",
                "pk": "TH__maha_sarakham"
            },
            {
                "_id": "mukdahan",
                "code": "mukdahan",
                "name": "Mukdahan",
                "pk": "TH__mukdahan"
            },
            {
                "_id": "nakhon_nayok",
                "code": "nakhon_nayok",
                "name": "Nakhon Nayok",
                "pk": "TH__nakhon_nayok"
            },
            {
                "_id": "nakhon_pathom",
                "code": "nakhon_pathom",
                "name": "Nakhon Pathom",
                "pk": "TH__nakhon_pathom"
            },
            {
                "_id": "nakhon_phanom",
                "code": "nakhon_phanom",
                "name": "Nakhon Phanom",
                "pk": "TH__nakhon_phanom"
            },
            {
                "_id": "nakhon_ratchasima",
                "code": "nakhon_ratchasima",
                "name": "Nakhon Ratchasima",
                "pk": "TH__nakhon_ratchasima"
            },
            {
                "_id": "nakhon_sawan",
                "code": "nakhon_sawan",
                "name": "Nakhon Sawan",
                "pk": "TH__nakhon_sawan"
            },
            {
                "_id": "nakhon_si_thammarat",
                "code": "nakhon_si_thammarat",
                "name": "Nakhon Si Thammarat",
                "pk": "TH__nakhon_si_thammarat"
            },
            {
                "_id": "nan",
                "code": "nan",
                "name": "Nan",
                "pk": "TH__nan"
            },
            {
                "_id": "narathiwat",
                "code": "narathiwat",
                "name": "Narathiwat",
                "pk": "TH__narathiwat"
            },
            {
                "_id": "nong_bua_lam_phu",
                "code": "nong_bua_lam_phu",
                "name": "Nong Bua Lam Phu",
                "pk": "TH__nong_bua_lam_phu"
            },
            {
                "_id": "nong_khai",
                "code": "nong_khai",
                "name": "Nong Khai",
                "pk": "TH__nong_khai"
            },
            {
                "_id": "nonthaburi",
                "code": "nonthaburi",
                "name": "Nonthaburi",
                "pk": "TH__nonthaburi"
            },
            {
                "_id": "pathum_thani",
                "code": "pathum_thani",
                "name": "Pathum Thani",
                "pk": "TH__pathum_thani"
            },
            {
                "_id": "pattani",
                "code": "pattani",
                "name": "Pattani",
                "pk": "TH__pattani"
            },
            {
                "_id": "phangnga",
                "code": "phangnga",
                "name": "Phangnga",
                "pk": "TH__phangnga"
            },
            {
                "_id": "phatthalung",
                "code": "phatthalung",
                "name": "Phatthalung",
                "pk": "TH__phatthalung"
            },
            {
                "_id": "phayao",
                "code": "phayao",
                "name": "Phayao",
                "pk": "TH__phayao"
            },
            {
                "_id": "phetchabun",
                "code": "phetchabun",
                "name": "Phetchabun",
                "pk": "TH__phetchabun"
            },
            {
                "_id": "phetchaburi",
                "code": "phetchaburi",
                "name": "Phetchaburi",
                "pk": "TH__phetchaburi"
            },
            {
                "_id": "phichit",
                "code": "phichit",
                "name": "Phichit",
                "pk": "TH__phichit"
            },
            {
                "_id": "phitsanulok",
                "code": "phitsanulok",
                "name": "Phitsanulok",
                "pk": "TH__phitsanulok"
            },
            {
                "_id": "phra_nakhon_si_ayutthaya",
                "code": "phra_nakhon_si_ayutthaya",
                "name": "Phra Nakhon Si Ayutthaya",
                "pk": "TH__phra_nakhon_si_ayutthaya"
            },
            {
                "_id": "phrae",
                "code": "phrae",
                "name": "Phrae",
                "pk": "TH__phrae"
            },
            {
                "_id": "phuket",
                "code": "phuket",
                "name": "Phuket",
                "pk": "TH__phuket"
            },
            {
                "_id": "prachin_buri",
                "code": "prachin_buri",
                "name": "Prachin Buri",
                "pk": "TH__prachin_buri"
            },
            {
                "_id": "prachuap_khiri_khan",
                "code": "prachuap_khiri_khan",
                "name": "Prachuap Khiri Khan",
                "pk": "TH__prachuap_khiri_khan"
            },
            {
                "_id": "ranong",
                "code": "ranong",
                "name": "Ranong",
                "pk": "TH__ranong"
            },
            {
                "_id": "ratchaburi",
                "code": "ratchaburi",
                "name": "Ratchaburi",
                "pk": "TH__ratchaburi"
            },
            {
                "_id": "rayong",
                "code": "rayong",
                "name": "Rayong",
                "pk": "TH__rayong"
            },
            {
                "_id": "roi_et",
                "code": "roi_et",
                "name": "Roi Et",
                "pk": "TH__roi_et"
            },
            {
                "_id": "sa_kaeo",
                "code": "sa_kaeo",
                "name": "Sa Kaeo",
                "pk": "TH__sa_kaeo"
            },
            {
                "_id": "sakon_nakhon",
                "code": "sakon_nakhon",
                "name": "Sakon Nakhon",
                "pk": "TH__sakon_nakhon"
            },
            {
                "_id": "samut_prakan",
                "code": "samut_prakan",
                "name": "Samut Prakan",
                "pk": "TH__samut_prakan"
            },
            {
                "_id": "samut_sakhon",
                "code": "samut_sakhon",
                "name": "Samut Sakhon",
                "pk": "TH__samut_sakhon"
            },
            {
                "_id": "samut_songkhran",
                "code": "samut_songkhran",
                "name": "Samut Songkhran",
                "pk": "TH__samut_songkhran"
            },
            {
                "_id": "saraburi",
                "code": "saraburi",
                "name": "Saraburi",
                "pk": "TH__saraburi"
            },
            {
                "_id": "satun",
                "code": "satun",
                "name": "Satun",
                "pk": "TH__satun"
            },
            {
                "_id": "si_sa_ket",
                "code": "si_sa_ket",
                "name": "Si Sa Ket",
                "pk": "TH__si_sa_ket"
            },
            {
                "_id": "sing_buri",
                "code": "sing_buri",
                "name": "Sing Buri",
                "pk": "TH__sing_buri"
            },
            {
                "_id": "songkhla",
                "code": "songkhla",
                "name": "Songkhla",
                "pk": "TH__songkhla"
            },
            {
                "_id": "sukhothai",
                "code": "sukhothai",
                "name": "Sukhothai",
                "pk": "TH__sukhothai"
            },
            {
                "_id": "suphan_buri",
                "code": "suphan_buri",
                "name": "Suphan Buri",
                "pk": "TH__suphan_buri"
            },
            {
                "_id": "surat_thani",
                "code": "surat_thani",
                "name": "Surat Thani",
                "pk": "TH__surat_thani"
            },
            {
                "_id": "surin",
                "code": "surin",
                "name": "Surin",
                "pk": "TH__surin"
            },
            {
                "_id": "tak",
                "code": "tak",
                "name": "Tak",
                "pk": "TH__tak"
            },
            {
                "_id": "trang",
                "code": "trang",
                "name": "Trang",
                "pk": "TH__trang"
            },
            {
                "_id": "trat",
                "code": "trat",
                "name": "Trat",
                "pk": "TH__trat"
            },
            {
                "_id": "ubon_ratchathani",
                "code": "ubon_ratchathani",
                "name": "Ubon Ratchathani",
                "pk": "TH__ubon_ratchathani"
            },
            {
                "_id": "udon_thani",
                "code": "udon_thani",
                "name": "Udon Thani",
                "pk": "TH__udon_thani"
            },
            {
                "_id": "uthai_thani",
                "code": "uthai_thani",
                "name": "Uthai Thani",
                "pk": "TH__uthai_thani"
            },
            {
                "_id": "uttaradit",
                "code": "uttaradit",
                "name": "Uttaradit",
                "pk": "TH__uttaradit"
            },
            {
                "_id": "yala",
                "code": "yala",
                "name": "Yala",
                "pk": "TH__yala"
            },
            {
                "_id": "yasothon",
                "code": "yasothon",
                "name": "Yasothon",
                "pk": "TH__yasothon"
            }
        ],
        "pk": "TH"
    },
    {
        "_id": "TG",
        "code": "TG",
        "name": "Togo",
        "states": [
            {
                "_id": "centre",
                "code": "centre",
                "name": "Centre",
                "pk": "TG__centre"
            },
            {
                "_id": "kara",
                "code": "kara",
                "name": "Kara",
                "pk": "TG__kara"
            },
            {
                "_id": "maritime",
                "code": "maritime",
                "name": "Maritime",
                "pk": "TG__maritime"
            },
            {
                "_id": "plateaux",
                "code": "plateaux",
                "name": "Plateaux",
                "pk": "TG__plateaux"
            },
            {
                "_id": "savanes",
                "code": "savanes",
                "name": "Savanes",
                "pk": "TG__savanes"
            }
        ],
        "pk": "TG"
    },
    {
        "_id": "TK",
        "code": "TK",
        "name": "Tokelau",
        "states": [
            {
                "_id": "atafu",
                "code": "atafu",
                "name": "Atafu",
                "pk": "TK__atafu"
            },
            {
                "_id": "fakaofo",
                "code": "fakaofo",
                "name": "Fakaofo",
                "pk": "TK__fakaofo"
            },
            {
                "_id": "nukunonu",
                "code": "nukunonu",
                "name": "Nukunonu",
                "pk": "TK__nukunonu"
            }
        ],
        "pk": "TK"
    },
    {
        "_id": "TO",
        "code": "TO",
        "name": "Tonga",
        "states": [
            {
                "_id": "eua",
                "code": "eua",
                "name": "Eua",
                "pk": "TO__eua"
            },
            {
                "_id": "haapai",
                "code": "haapai",
                "name": "Haapai",
                "pk": "TO__haapai"
            },
            {
                "_id": "niuas",
                "code": "niuas",
                "name": "Niuas",
                "pk": "TO__niuas"
            },
            {
                "_id": "tongatapu",
                "code": "tongatapu",
                "name": "Tongatapu",
                "pk": "TO__tongatapu"
            },
            {
                "_id": "vavau",
                "code": "vavau",
                "name": "Vavau",
                "pk": "TO__vavau"
            }
        ],
        "pk": "TO"
    },
    {
        "_id": "TT",
        "code": "TT",
        "name": "Trinidad And Tobago",
        "states": [
            {
                "_id": "arima-tunapuna-piarco",
                "code": "arima-tunapuna-piarco",
                "name": "Arima-Tunapuna-Piarco",
                "pk": "TT__arima-tunapuna-piarco"
            },
            {
                "_id": "caroni",
                "code": "caroni",
                "name": "Caroni",
                "pk": "TT__caroni"
            },
            {
                "_id": "chaguanas",
                "code": "chaguanas",
                "name": "Chaguanas",
                "pk": "TT__chaguanas"
            },
            {
                "_id": "couva-tabaquite-talparo",
                "code": "couva-tabaquite-talparo",
                "name": "Couva-Tabaquite-Talparo",
                "pk": "TT__couva-tabaquite-talparo"
            },
            {
                "_id": "diego_martin",
                "code": "diego_martin",
                "name": "Diego Martin",
                "pk": "TT__diego_martin"
            },
            {
                "_id": "glencoe",
                "code": "glencoe",
                "name": "Glencoe",
                "pk": "TT__glencoe"
            },
            {
                "_id": "penal_debe",
                "code": "penal_debe",
                "name": "Penal Debe",
                "pk": "TT__penal_debe"
            },
            {
                "_id": "point_fortin",
                "code": "point_fortin",
                "name": "Point Fortin",
                "pk": "TT__point_fortin"
            },
            {
                "_id": "port_of_spain",
                "code": "port_of_spain",
                "name": "Port of Spain",
                "pk": "TT__port_of_spain"
            },
            {
                "_id": "princes_town",
                "code": "princes_town",
                "name": "Princes Town",
                "pk": "TT__princes_town"
            },
            {
                "_id": "saint_george",
                "code": "saint_george",
                "name": "Saint George",
                "pk": "TT__saint_george"
            },
            {
                "_id": "san_fernando",
                "code": "san_fernando",
                "name": "San Fernando",
                "pk": "TT__san_fernando"
            },
            {
                "_id": "san_juan",
                "code": "san_juan",
                "name": "San Juan",
                "pk": "TT__san_juan"
            },
            {
                "_id": "sangre_grande",
                "code": "sangre_grande",
                "name": "Sangre Grande",
                "pk": "TT__sangre_grande"
            },
            {
                "_id": "siparia",
                "code": "siparia",
                "name": "Siparia",
                "pk": "TT__siparia"
            },
            {
                "_id": "tobago",
                "code": "tobago",
                "name": "Tobago",
                "pk": "TT__tobago"
            }
        ],
        "pk": "TT"
    },
    {
        "_id": "TN",
        "code": "TN",
        "name": "Tunisia",
        "states": [
            {
                "_id": "aryanah",
                "code": "aryanah",
                "name": "Aryanah",
                "pk": "TN__aryanah"
            },
            {
                "_id": "bajah",
                "code": "bajah",
                "name": "Bajah",
                "pk": "TN__bajah"
            },
            {
                "_id": "bin_arus",
                "code": "bin_arus",
                "name": "Bin Arus",
                "pk": "TN__bin_arus"
            },
            {
                "_id": "binzart",
                "code": "binzart",
                "name": "Binzart",
                "pk": "TN__binzart"
            },
            {
                "_id": "gouvernorat_de_ariana",
                "code": "gouvernorat_de_ariana",
                "name": "Gouvernorat de Ariana",
                "pk": "TN__gouvernorat_de_ariana"
            },
            {
                "_id": "gouvernorat_de_nabeul",
                "code": "gouvernorat_de_nabeul",
                "name": "Gouvernorat de Nabeul",
                "pk": "TN__gouvernorat_de_nabeul"
            },
            {
                "_id": "gouvernorat_de_sousse",
                "code": "gouvernorat_de_sousse",
                "name": "Gouvernorat de Sousse",
                "pk": "TN__gouvernorat_de_sousse"
            },
            {
                "_id": "hammamet_yasmine",
                "code": "hammamet_yasmine",
                "name": "Hammamet Yasmine",
                "pk": "TN__hammamet_yasmine"
            },
            {
                "_id": "jundubah",
                "code": "jundubah",
                "name": "Jundubah",
                "pk": "TN__jundubah"
            },
            {
                "_id": "madaniyin",
                "code": "madaniyin",
                "name": "Madaniyin",
                "pk": "TN__madaniyin"
            },
            {
                "_id": "manubah",
                "code": "manubah",
                "name": "Manubah",
                "pk": "TN__manubah"
            },
            {
                "_id": "monastir",
                "code": "monastir",
                "name": "Monastir",
                "pk": "TN__monastir"
            },
            {
                "_id": "nabul",
                "code": "nabul",
                "name": "Nabul",
                "pk": "TN__nabul"
            },
            {
                "_id": "qabis",
                "code": "qabis",
                "name": "Qabis",
                "pk": "TN__qabis"
            },
            {
                "_id": "qafsah",
                "code": "qafsah",
                "name": "Qafsah",
                "pk": "TN__qafsah"
            },
            {
                "_id": "qibili",
                "code": "qibili",
                "name": "Qibili",
                "pk": "TN__qibili"
            },
            {
                "_id": "safaqis",
                "code": "safaqis",
                "name": "Safaqis",
                "pk": "TN__safaqis"
            },
            {
                "_id": "sfax",
                "code": "sfax",
                "name": "Sfax",
                "pk": "TN__sfax"
            },
            {
                "_id": "sidi_bu_zayd",
                "code": "sidi_bu_zayd",
                "name": "Sidi Bu Zayd",
                "pk": "TN__sidi_bu_zayd"
            },
            {
                "_id": "silyanah",
                "code": "silyanah",
                "name": "Silyanah",
                "pk": "TN__silyanah"
            },
            {
                "_id": "susah",
                "code": "susah",
                "name": "Susah",
                "pk": "TN__susah"
            },
            {
                "_id": "tatawin",
                "code": "tatawin",
                "name": "Tatawin",
                "pk": "TN__tatawin"
            },
            {
                "_id": "tawzar",
                "code": "tawzar",
                "name": "Tawzar",
                "pk": "TN__tawzar"
            },
            {
                "_id": "tunis",
                "code": "tunis",
                "name": "Tunis",
                "pk": "TN__tunis"
            },
            {
                "_id": "zaghwan",
                "code": "zaghwan",
                "name": "Zaghwan",
                "pk": "TN__zaghwan"
            },
            {
                "_id": "al-kaf",
                "code": "al-kaf",
                "name": "al-Kaf",
                "pk": "TN__al-kaf"
            },
            {
                "_id": "al-mahdiyah",
                "code": "al-mahdiyah",
                "name": "al-Mahdiyah",
                "pk": "TN__al-mahdiyah"
            },
            {
                "_id": "al-munastir",
                "code": "al-munastir",
                "name": "al-Munastir",
                "pk": "TN__al-munastir"
            },
            {
                "_id": "al-qasrayn",
                "code": "al-qasrayn",
                "name": "al-Qasrayn",
                "pk": "TN__al-qasrayn"
            },
            {
                "_id": "al-qayrawan",
                "code": "al-qayrawan",
                "name": "al-Qayrawan",
                "pk": "TN__al-qayrawan"
            }
        ],
        "pk": "TN"
    },
    {
        "_id": "TR",
        "code": "TR",
        "name": "Turkey",
        "states": [
            {
                "_id": "adana",
                "code": "adana",
                "name": "Adana",
                "pk": "TR__adana"
            },
            {
                "_id": "adiyaman",
                "code": "adiyaman",
                "name": "Adiyaman",
                "pk": "TR__adiyaman"
            },
            {
                "_id": "afyon",
                "code": "afyon",
                "name": "Afyon",
                "pk": "TR__afyon"
            },
            {
                "_id": "agri",
                "code": "agri",
                "name": "Agri",
                "pk": "TR__agri"
            },
            {
                "_id": "aksaray",
                "code": "aksaray",
                "name": "Aksaray",
                "pk": "TR__aksaray"
            },
            {
                "_id": "amasya",
                "code": "amasya",
                "name": "Amasya",
                "pk": "TR__amasya"
            },
            {
                "_id": "ankara",
                "code": "ankara",
                "name": "Ankara",
                "pk": "TR__ankara"
            },
            {
                "_id": "antalya",
                "code": "antalya",
                "name": "Antalya",
                "pk": "TR__antalya"
            },
            {
                "_id": "ardahan",
                "code": "ardahan",
                "name": "Ardahan",
                "pk": "TR__ardahan"
            },
            {
                "_id": "artvin",
                "code": "artvin",
                "name": "Artvin",
                "pk": "TR__artvin"
            },
            {
                "_id": "aydin",
                "code": "aydin",
                "name": "Aydin",
                "pk": "TR__aydin"
            },
            {
                "_id": "balikesir",
                "code": "balikesir",
                "name": "Balikesir",
                "pk": "TR__balikesir"
            },
            {
                "_id": "bartin",
                "code": "bartin",
                "name": "Bartin",
                "pk": "TR__bartin"
            },
            {
                "_id": "batman",
                "code": "batman",
                "name": "Batman",
                "pk": "TR__batman"
            },
            {
                "_id": "bayburt",
                "code": "bayburt",
                "name": "Bayburt",
                "pk": "TR__bayburt"
            },
            {
                "_id": "bilecik",
                "code": "bilecik",
                "name": "Bilecik",
                "pk": "TR__bilecik"
            },
            {
                "_id": "bingol",
                "code": "bingol",
                "name": "Bingol",
                "pk": "TR__bingol"
            },
            {
                "_id": "bitlis",
                "code": "bitlis",
                "name": "Bitlis",
                "pk": "TR__bitlis"
            },
            {
                "_id": "bolu",
                "code": "bolu",
                "name": "Bolu",
                "pk": "TR__bolu"
            },
            {
                "_id": "burdur",
                "code": "burdur",
                "name": "Burdur",
                "pk": "TR__burdur"
            },
            {
                "_id": "bursa",
                "code": "bursa",
                "name": "Bursa",
                "pk": "TR__bursa"
            },
            {
                "_id": "canakkale",
                "code": "canakkale",
                "name": "Canakkale",
                "pk": "TR__canakkale"
            },
            {
                "_id": "cankiri",
                "code": "cankiri",
                "name": "Cankiri",
                "pk": "TR__cankiri"
            },
            {
                "_id": "corum",
                "code": "corum",
                "name": "Corum",
                "pk": "TR__corum"
            },
            {
                "_id": "denizli",
                "code": "denizli",
                "name": "Denizli",
                "pk": "TR__denizli"
            },
            {
                "_id": "diyarbakir",
                "code": "diyarbakir",
                "name": "Diyarbakir",
                "pk": "TR__diyarbakir"
            },
            {
                "_id": "duzce",
                "code": "duzce",
                "name": "Duzce",
                "pk": "TR__duzce"
            },
            {
                "_id": "edirne",
                "code": "edirne",
                "name": "Edirne",
                "pk": "TR__edirne"
            },
            {
                "_id": "elazig",
                "code": "elazig",
                "name": "Elazig",
                "pk": "TR__elazig"
            },
            {
                "_id": "erzincan",
                "code": "erzincan",
                "name": "Erzincan",
                "pk": "TR__erzincan"
            },
            {
                "_id": "erzurum",
                "code": "erzurum",
                "name": "Erzurum",
                "pk": "TR__erzurum"
            },
            {
                "_id": "eskisehir",
                "code": "eskisehir",
                "name": "Eskisehir",
                "pk": "TR__eskisehir"
            },
            {
                "_id": "gaziantep",
                "code": "gaziantep",
                "name": "Gaziantep",
                "pk": "TR__gaziantep"
            },
            {
                "_id": "giresun",
                "code": "giresun",
                "name": "Giresun",
                "pk": "TR__giresun"
            },
            {
                "_id": "gumushane",
                "code": "gumushane",
                "name": "Gumushane",
                "pk": "TR__gumushane"
            },
            {
                "_id": "hakkari",
                "code": "hakkari",
                "name": "Hakkari",
                "pk": "TR__hakkari"
            },
            {
                "_id": "hatay",
                "code": "hatay",
                "name": "Hatay",
                "pk": "TR__hatay"
            },
            {
                "_id": "icel",
                "code": "icel",
                "name": "Icel",
                "pk": "TR__icel"
            },
            {
                "_id": "igdir",
                "code": "igdir",
                "name": "Igdir",
                "pk": "TR__igdir"
            },
            {
                "_id": "isparta",
                "code": "isparta",
                "name": "Isparta",
                "pk": "TR__isparta"
            },
            {
                "_id": "istanbul",
                "code": "istanbul",
                "name": "Istanbul",
                "pk": "TR__istanbul"
            },
            {
                "_id": "izmir",
                "code": "izmir",
                "name": "Izmir",
                "pk": "TR__izmir"
            },
            {
                "_id": "kahramanmaras",
                "code": "kahramanmaras",
                "name": "Kahramanmaras",
                "pk": "TR__kahramanmaras"
            },
            {
                "_id": "karabuk",
                "code": "karabuk",
                "name": "Karabuk",
                "pk": "TR__karabuk"
            },
            {
                "_id": "karaman",
                "code": "karaman",
                "name": "Karaman",
                "pk": "TR__karaman"
            },
            {
                "_id": "kars",
                "code": "kars",
                "name": "Kars",
                "pk": "TR__kars"
            },
            {
                "_id": "karsiyaka",
                "code": "karsiyaka",
                "name": "Karsiyaka",
                "pk": "TR__karsiyaka"
            },
            {
                "_id": "kastamonu",
                "code": "kastamonu",
                "name": "Kastamonu",
                "pk": "TR__kastamonu"
            },
            {
                "_id": "kayseri",
                "code": "kayseri",
                "name": "Kayseri",
                "pk": "TR__kayseri"
            },
            {
                "_id": "kilis",
                "code": "kilis",
                "name": "Kilis",
                "pk": "TR__kilis"
            },
            {
                "_id": "kirikkale",
                "code": "kirikkale",
                "name": "Kirikkale",
                "pk": "TR__kirikkale"
            },
            {
                "_id": "kirklareli",
                "code": "kirklareli",
                "name": "Kirklareli",
                "pk": "TR__kirklareli"
            },
            {
                "_id": "kirsehir",
                "code": "kirsehir",
                "name": "Kirsehir",
                "pk": "TR__kirsehir"
            },
            {
                "_id": "kocaeli",
                "code": "kocaeli",
                "name": "Kocaeli",
                "pk": "TR__kocaeli"
            },
            {
                "_id": "konya",
                "code": "konya",
                "name": "Konya",
                "pk": "TR__konya"
            },
            {
                "_id": "kutahya",
                "code": "kutahya",
                "name": "Kutahya",
                "pk": "TR__kutahya"
            },
            {
                "_id": "lefkosa",
                "code": "lefkosa",
                "name": "Lefkosa",
                "pk": "TR__lefkosa"
            },
            {
                "_id": "malatya",
                "code": "malatya",
                "name": "Malatya",
                "pk": "TR__malatya"
            },
            {
                "_id": "manisa",
                "code": "manisa",
                "name": "Manisa",
                "pk": "TR__manisa"
            },
            {
                "_id": "mardin",
                "code": "mardin",
                "name": "Mardin",
                "pk": "TR__mardin"
            },
            {
                "_id": "mugla",
                "code": "mugla",
                "name": "Mugla",
                "pk": "TR__mugla"
            },
            {
                "_id": "mus",
                "code": "mus",
                "name": "Mus",
                "pk": "TR__mus"
            },
            {
                "_id": "nevsehir",
                "code": "nevsehir",
                "name": "Nevsehir",
                "pk": "TR__nevsehir"
            },
            {
                "_id": "nigde",
                "code": "nigde",
                "name": "Nigde",
                "pk": "TR__nigde"
            },
            {
                "_id": "ordu",
                "code": "ordu",
                "name": "Ordu",
                "pk": "TR__ordu"
            },
            {
                "_id": "osmaniye",
                "code": "osmaniye",
                "name": "Osmaniye",
                "pk": "TR__osmaniye"
            },
            {
                "_id": "rize",
                "code": "rize",
                "name": "Rize",
                "pk": "TR__rize"
            },
            {
                "_id": "sakarya",
                "code": "sakarya",
                "name": "Sakarya",
                "pk": "TR__sakarya"
            },
            {
                "_id": "samsun",
                "code": "samsun",
                "name": "Samsun",
                "pk": "TR__samsun"
            },
            {
                "_id": "sanliurfa",
                "code": "sanliurfa",
                "name": "Sanliurfa",
                "pk": "TR__sanliurfa"
            },
            {
                "_id": "siirt",
                "code": "siirt",
                "name": "Siirt",
                "pk": "TR__siirt"
            },
            {
                "_id": "sinop",
                "code": "sinop",
                "name": "Sinop",
                "pk": "TR__sinop"
            },
            {
                "_id": "sirnak",
                "code": "sirnak",
                "name": "Sirnak",
                "pk": "TR__sirnak"
            },
            {
                "_id": "sivas",
                "code": "sivas",
                "name": "Sivas",
                "pk": "TR__sivas"
            },
            {
                "_id": "tekirdag",
                "code": "tekirdag",
                "name": "Tekirdag",
                "pk": "TR__tekirdag"
            },
            {
                "_id": "tokat",
                "code": "tokat",
                "name": "Tokat",
                "pk": "TR__tokat"
            },
            {
                "_id": "trabzon",
                "code": "trabzon",
                "name": "Trabzon",
                "pk": "TR__trabzon"
            },
            {
                "_id": "tunceli",
                "code": "tunceli",
                "name": "Tunceli",
                "pk": "TR__tunceli"
            },
            {
                "_id": "usak",
                "code": "usak",
                "name": "Usak",
                "pk": "TR__usak"
            },
            {
                "_id": "van",
                "code": "van",
                "name": "Van",
                "pk": "TR__van"
            },
            {
                "_id": "yalova",
                "code": "yalova",
                "name": "Yalova",
                "pk": "TR__yalova"
            },
            {
                "_id": "yozgat",
                "code": "yozgat",
                "name": "Yozgat",
                "pk": "TR__yozgat"
            },
            {
                "_id": "zonguldak",
                "code": "zonguldak",
                "name": "Zonguldak",
                "pk": "TR__zonguldak"
            }
        ],
        "pk": "TR"
    },
    {
        "_id": "TM",
        "code": "TM",
        "name": "Turkmenistan",
        "states": [
            {
                "_id": "ahal",
                "code": "ahal",
                "name": "Ahal",
                "pk": "TM__ahal"
            },
            {
                "_id": "asgabat",
                "code": "asgabat",
                "name": "Asgabat",
                "pk": "TM__asgabat"
            },
            {
                "_id": "balkan",
                "code": "balkan",
                "name": "Balkan",
                "pk": "TM__balkan"
            },
            {
                "_id": "dasoguz",
                "code": "dasoguz",
                "name": "Dasoguz",
                "pk": "TM__dasoguz"
            },
            {
                "_id": "lebap",
                "code": "lebap",
                "name": "Lebap",
                "pk": "TM__lebap"
            },
            {
                "_id": "mari",
                "code": "mari",
                "name": "Mari",
                "pk": "TM__mari"
            }
        ],
        "pk": "TM"
    },
    {
        "_id": "TC",
        "code": "TC",
        "name": "Turks And Caicos Islands",
        "states": [
            {
                "_id": "grand_turk",
                "code": "grand_turk",
                "name": "Grand Turk",
                "pk": "TC__grand_turk"
            },
            {
                "_id": "south_caicos_and_east_caicos",
                "code": "south_caicos_and_east_caicos",
                "name": "South Caicos and East Caicos",
                "pk": "TC__south_caicos_and_east_caicos"
            }
        ],
        "pk": "TC"
    },
    {
        "_id": "TV",
        "code": "TV",
        "name": "Tuvalu",
        "states": [
            {
                "_id": "funafuti",
                "code": "funafuti",
                "name": "Funafuti",
                "pk": "TV__funafuti"
            },
            {
                "_id": "nanumanga",
                "code": "nanumanga",
                "name": "Nanumanga",
                "pk": "TV__nanumanga"
            },
            {
                "_id": "nanumea",
                "code": "nanumea",
                "name": "Nanumea",
                "pk": "TV__nanumea"
            },
            {
                "_id": "niutao",
                "code": "niutao",
                "name": "Niutao",
                "pk": "TV__niutao"
            },
            {
                "_id": "nui",
                "code": "nui",
                "name": "Nui",
                "pk": "TV__nui"
            },
            {
                "_id": "nukufetau",
                "code": "nukufetau",
                "name": "Nukufetau",
                "pk": "TV__nukufetau"
            },
            {
                "_id": "nukulaelae",
                "code": "nukulaelae",
                "name": "Nukulaelae",
                "pk": "TV__nukulaelae"
            },
            {
                "_id": "vaitupu",
                "code": "vaitupu",
                "name": "Vaitupu",
                "pk": "TV__vaitupu"
            }
        ],
        "pk": "TV"
    },
    {
        "_id": "UG",
        "code": "UG",
        "name": "Uganda",
        "states": [
            {
                "_id": "central",
                "code": "central",
                "name": "Central",
                "pk": "UG__central"
            },
            {
                "_id": "eastern",
                "code": "eastern",
                "name": "Eastern",
                "pk": "UG__eastern"
            },
            {
                "_id": "northern",
                "code": "northern",
                "name": "Northern",
                "pk": "UG__northern"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "UG__western"
            }
        ],
        "pk": "UG"
    },
    {
        "_id": "UA",
        "code": "UA",
        "name": "Ukraine",
        "states": [
            {
                "_id": "cherkaska",
                "code": "cherkaska",
                "name": "Cherkaska",
                "pk": "UA__cherkaska"
            },
            {
                "_id": "chernihivska",
                "code": "chernihivska",
                "name": "Chernihivska",
                "pk": "UA__chernihivska"
            },
            {
                "_id": "chernivetska",
                "code": "chernivetska",
                "name": "Chernivetska",
                "pk": "UA__chernivetska"
            },
            {
                "_id": "crimea",
                "code": "crimea",
                "name": "Crimea",
                "pk": "UA__crimea"
            },
            {
                "_id": "dnipropetrovska",
                "code": "dnipropetrovska",
                "name": "Dnipropetrovska",
                "pk": "UA__dnipropetrovska"
            },
            {
                "_id": "donetska",
                "code": "donetska",
                "name": "Donetska",
                "pk": "UA__donetska"
            },
            {
                "_id": "ivano-frankivska",
                "code": "ivano-frankivska",
                "name": "Ivano-Frankivska",
                "pk": "UA__ivano-frankivska"
            },
            {
                "_id": "kharkiv",
                "code": "kharkiv",
                "name": "Kharkiv",
                "pk": "UA__kharkiv"
            },
            {
                "_id": "kharkov",
                "code": "kharkov",
                "name": "Kharkov",
                "pk": "UA__kharkov"
            },
            {
                "_id": "khersonska",
                "code": "khersonska",
                "name": "Khersonska",
                "pk": "UA__khersonska"
            },
            {
                "_id": "khmelnytska",
                "code": "khmelnytska",
                "name": "Khmelnytska",
                "pk": "UA__khmelnytska"
            },
            {
                "_id": "kirovohrad",
                "code": "kirovohrad",
                "name": "Kirovohrad",
                "pk": "UA__kirovohrad"
            },
            {
                "_id": "krym",
                "code": "krym",
                "name": "Krym",
                "pk": "UA__krym"
            },
            {
                "_id": "kyyiv",
                "code": "kyyiv",
                "name": "Kyyiv",
                "pk": "UA__kyyiv"
            },
            {
                "_id": "kyyivska",
                "code": "kyyivska",
                "name": "Kyyivska",
                "pk": "UA__kyyivska"
            },
            {
                "_id": "lvivska",
                "code": "lvivska",
                "name": "Lvivska",
                "pk": "UA__lvivska"
            },
            {
                "_id": "luhanska",
                "code": "luhanska",
                "name": "Luhanska",
                "pk": "UA__luhanska"
            },
            {
                "_id": "mykolayivska",
                "code": "mykolayivska",
                "name": "Mykolayivska",
                "pk": "UA__mykolayivska"
            },
            {
                "_id": "odeska",
                "code": "odeska",
                "name": "Odeska",
                "pk": "UA__odeska"
            },
            {
                "_id": "odessa",
                "code": "odessa",
                "name": "Odessa",
                "pk": "UA__odessa"
            },
            {
                "_id": "poltavska",
                "code": "poltavska",
                "name": "Poltavska",
                "pk": "UA__poltavska"
            },
            {
                "_id": "rivnenska",
                "code": "rivnenska",
                "name": "Rivnenska",
                "pk": "UA__rivnenska"
            },
            {
                "_id": "sevastopol",
                "code": "sevastopol",
                "name": "Sevastopol",
                "pk": "UA__sevastopol"
            },
            {
                "_id": "sumska",
                "code": "sumska",
                "name": "Sumska",
                "pk": "UA__sumska"
            },
            {
                "_id": "ternopilska",
                "code": "ternopilska",
                "name": "Ternopilska",
                "pk": "UA__ternopilska"
            },
            {
                "_id": "volynska",
                "code": "volynska",
                "name": "Volynska",
                "pk": "UA__volynska"
            },
            {
                "_id": "vynnytska",
                "code": "vynnytska",
                "name": "Vynnytska",
                "pk": "UA__vynnytska"
            },
            {
                "_id": "zakarpatska",
                "code": "zakarpatska",
                "name": "Zakarpatska",
                "pk": "UA__zakarpatska"
            },
            {
                "_id": "zaporizhia",
                "code": "zaporizhia",
                "name": "Zaporizhia",
                "pk": "UA__zaporizhia"
            },
            {
                "_id": "zhytomyrska",
                "code": "zhytomyrska",
                "name": "Zhytomyrska",
                "pk": "UA__zhytomyrska"
            }
        ],
        "pk": "UA"
    },
    {
        "_id": "AE",
        "code": "AE",
        "name": "United Arab Emirates",
        "states": [
            {
                "_id": "abu_zabi",
                "code": "abu_zabi",
                "name": "Abu Zabi",
                "pk": "AE__abu_zabi"
            },
            {
                "_id": "ajman",
                "code": "ajman",
                "name": "Ajman",
                "pk": "AE__ajman"
            },
            {
                "_id": "dubai",
                "code": "dubai",
                "name": "Dubai",
                "pk": "AE__dubai"
            },
            {
                "_id": "ras_al-khaymah",
                "code": "ras_al-khaymah",
                "name": "Ras al-Khaymah",
                "pk": "AE__ras_al-khaymah"
            },
            {
                "_id": "sharjah",
                "code": "sharjah",
                "name": "Sharjah",
                "pk": "AE__sharjah"
            },
            {
                "_id": "sharjha",
                "code": "sharjha",
                "name": "Sharjha",
                "pk": "AE__sharjha"
            },
            {
                "_id": "umm_al_qaywayn",
                "code": "umm_al_qaywayn",
                "name": "Umm al Qaywayn",
                "pk": "AE__umm_al_qaywayn"
            },
            {
                "_id": "al-fujayrah",
                "code": "al-fujayrah",
                "name": "al-Fujayrah",
                "pk": "AE__al-fujayrah"
            },
            {
                "_id": "ash-shariqah",
                "code": "ash-shariqah",
                "name": "ash-Shariqah",
                "pk": "AE__ash-shariqah"
            }
        ],
        "pk": "AE"
    },
    {
        "_id": "GB",
        "code": "GB",
        "name": "United Kingdom",
        "states": [
            {
                "_id": "aberdeen",
                "code": "aberdeen",
                "name": "Aberdeen",
                "pk": "GB__aberdeen"
            },
            {
                "_id": "aberdeenshire",
                "code": "aberdeenshire",
                "name": "Aberdeenshire",
                "pk": "GB__aberdeenshire"
            },
            {
                "_id": "argyll",
                "code": "argyll",
                "name": "Argyll",
                "pk": "GB__argyll"
            },
            {
                "_id": "armagh",
                "code": "armagh",
                "name": "Armagh",
                "pk": "GB__armagh"
            },
            {
                "_id": "bedfordshire",
                "code": "bedfordshire",
                "name": "Bedfordshire",
                "pk": "GB__bedfordshire"
            },
            {
                "_id": "belfast",
                "code": "belfast",
                "name": "Belfast",
                "pk": "GB__belfast"
            },
            {
                "_id": "berkshire",
                "code": "berkshire",
                "name": "Berkshire",
                "pk": "GB__berkshire"
            },
            {
                "_id": "birmingham",
                "code": "birmingham",
                "name": "Birmingham",
                "pk": "GB__birmingham"
            },
            {
                "_id": "brechin",
                "code": "brechin",
                "name": "Brechin",
                "pk": "GB__brechin"
            },
            {
                "_id": "bridgnorth",
                "code": "bridgnorth",
                "name": "Bridgnorth",
                "pk": "GB__bridgnorth"
            },
            {
                "_id": "bristol",
                "code": "bristol",
                "name": "Bristol",
                "pk": "GB__bristol"
            },
            {
                "_id": "buckinghamshire",
                "code": "buckinghamshire",
                "name": "Buckinghamshire",
                "pk": "GB__buckinghamshire"
            },
            {
                "_id": "cambridge",
                "code": "cambridge",
                "name": "Cambridge",
                "pk": "GB__cambridge"
            },
            {
                "_id": "cambridgeshire",
                "code": "cambridgeshire",
                "name": "Cambridgeshire",
                "pk": "GB__cambridgeshire"
            },
            {
                "_id": "channel_islands",
                "code": "channel_islands",
                "name": "Channel Islands",
                "pk": "GB__channel_islands"
            },
            {
                "_id": "cheshire",
                "code": "cheshire",
                "name": "Cheshire",
                "pk": "GB__cheshire"
            },
            {
                "_id": "cleveland",
                "code": "cleveland",
                "name": "Cleveland",
                "pk": "GB__cleveland"
            },
            {
                "_id": "co_fermanagh",
                "code": "co_fermanagh",
                "name": "Co Fermanagh",
                "pk": "GB__co_fermanagh"
            },
            {
                "_id": "conwy",
                "code": "conwy",
                "name": "Conwy",
                "pk": "GB__conwy"
            },
            {
                "_id": "cornwall",
                "code": "cornwall",
                "name": "Cornwall",
                "pk": "GB__cornwall"
            },
            {
                "_id": "coventry",
                "code": "coventry",
                "name": "Coventry",
                "pk": "GB__coventry"
            },
            {
                "_id": "craven_arms",
                "code": "craven_arms",
                "name": "Craven Arms",
                "pk": "GB__craven_arms"
            },
            {
                "_id": "cumbria",
                "code": "cumbria",
                "name": "Cumbria",
                "pk": "GB__cumbria"
            },
            {
                "_id": "denbighshire",
                "code": "denbighshire",
                "name": "Denbighshire",
                "pk": "GB__denbighshire"
            },
            {
                "_id": "derby",
                "code": "derby",
                "name": "Derby",
                "pk": "GB__derby"
            },
            {
                "_id": "derbyshire",
                "code": "derbyshire",
                "name": "Derbyshire",
                "pk": "GB__derbyshire"
            },
            {
                "_id": "devon",
                "code": "devon",
                "name": "Devon",
                "pk": "GB__devon"
            },
            {
                "_id": "dial_code_dungannon",
                "code": "dial_code_dungannon",
                "name": "Dial Code Dungannon",
                "pk": "GB__dial_code_dungannon"
            },
            {
                "_id": "didcot",
                "code": "didcot",
                "name": "Didcot",
                "pk": "GB__didcot"
            },
            {
                "_id": "dorset",
                "code": "dorset",
                "name": "Dorset",
                "pk": "GB__dorset"
            },
            {
                "_id": "dunbartonshire",
                "code": "dunbartonshire",
                "name": "Dunbartonshire",
                "pk": "GB__dunbartonshire"
            },
            {
                "_id": "durham",
                "code": "durham",
                "name": "Durham",
                "pk": "GB__durham"
            },
            {
                "_id": "east_dunbartonshire",
                "code": "east_dunbartonshire",
                "name": "East Dunbartonshire",
                "pk": "GB__east_dunbartonshire"
            },
            {
                "_id": "east_lothian",
                "code": "east_lothian",
                "name": "East Lothian",
                "pk": "GB__east_lothian"
            },
            {
                "_id": "east_midlands",
                "code": "east_midlands",
                "name": "East Midlands",
                "pk": "GB__east_midlands"
            },
            {
                "_id": "east_sussex",
                "code": "east_sussex",
                "name": "East Sussex",
                "pk": "GB__east_sussex"
            },
            {
                "_id": "east_yorkshire",
                "code": "east_yorkshire",
                "name": "East Yorkshire",
                "pk": "GB__east_yorkshire"
            },
            {
                "_id": "england",
                "code": "england",
                "name": "England",
                "pk": "GB__england"
            },
            {
                "_id": "essex",
                "code": "essex",
                "name": "Essex",
                "pk": "GB__essex"
            },
            {
                "_id": "fermanagh",
                "code": "fermanagh",
                "name": "Fermanagh",
                "pk": "GB__fermanagh"
            },
            {
                "_id": "fife",
                "code": "fife",
                "name": "Fife",
                "pk": "GB__fife"
            },
            {
                "_id": "flintshire",
                "code": "flintshire",
                "name": "Flintshire",
                "pk": "GB__flintshire"
            },
            {
                "_id": "fulham",
                "code": "fulham",
                "name": "Fulham",
                "pk": "GB__fulham"
            },
            {
                "_id": "gainsborough",
                "code": "gainsborough",
                "name": "Gainsborough",
                "pk": "GB__gainsborough"
            },
            {
                "_id": "glocestershire",
                "code": "glocestershire",
                "name": "Glocestershire",
                "pk": "GB__glocestershire"
            },
            {
                "_id": "gwent",
                "code": "gwent",
                "name": "Gwent",
                "pk": "GB__gwent"
            },
            {
                "_id": "hampshire",
                "code": "hampshire",
                "name": "Hampshire",
                "pk": "GB__hampshire"
            },
            {
                "_id": "hants",
                "code": "hants",
                "name": "Hants",
                "pk": "GB__hants"
            },
            {
                "_id": "herefordshire",
                "code": "herefordshire",
                "name": "Herefordshire",
                "pk": "GB__herefordshire"
            },
            {
                "_id": "hertfordshire",
                "code": "hertfordshire",
                "name": "Hertfordshire",
                "pk": "GB__hertfordshire"
            },
            {
                "_id": "ireland",
                "code": "ireland",
                "name": "Ireland",
                "pk": "GB__ireland"
            },
            {
                "_id": "isle_of_man",
                "code": "isle_of_man",
                "name": "Isle Of Man",
                "pk": "GB__isle_of_man"
            },
            {
                "_id": "isle_of_wight",
                "code": "isle_of_wight",
                "name": "Isle of Wight",
                "pk": "GB__isle_of_wight"
            },
            {
                "_id": "kenford",
                "code": "kenford",
                "name": "Kenford",
                "pk": "GB__kenford"
            },
            {
                "_id": "kent",
                "code": "kent",
                "name": "Kent",
                "pk": "GB__kent"
            },
            {
                "_id": "kilmarnock",
                "code": "kilmarnock",
                "name": "Kilmarnock",
                "pk": "GB__kilmarnock"
            },
            {
                "_id": "lanarkshire",
                "code": "lanarkshire",
                "name": "Lanarkshire",
                "pk": "GB__lanarkshire"
            },
            {
                "_id": "lancashire",
                "code": "lancashire",
                "name": "Lancashire",
                "pk": "GB__lancashire"
            },
            {
                "_id": "leicestershire",
                "code": "leicestershire",
                "name": "Leicestershire",
                "pk": "GB__leicestershire"
            },
            {
                "_id": "lincolnshire",
                "code": "lincolnshire",
                "name": "Lincolnshire",
                "pk": "GB__lincolnshire"
            },
            {
                "_id": "llanymynech",
                "code": "llanymynech",
                "name": "Llanymynech",
                "pk": "GB__llanymynech"
            },
            {
                "_id": "london",
                "code": "london",
                "name": "London",
                "pk": "GB__london"
            },
            {
                "_id": "ludlow",
                "code": "ludlow",
                "name": "Ludlow",
                "pk": "GB__ludlow"
            },
            {
                "_id": "manchester",
                "code": "manchester",
                "name": "Manchester",
                "pk": "GB__manchester"
            },
            {
                "_id": "mayfair",
                "code": "mayfair",
                "name": "Mayfair",
                "pk": "GB__mayfair"
            },
            {
                "_id": "merseyside",
                "code": "merseyside",
                "name": "Merseyside",
                "pk": "GB__merseyside"
            },
            {
                "_id": "mid_glamorgan",
                "code": "mid_glamorgan",
                "name": "Mid Glamorgan",
                "pk": "GB__mid_glamorgan"
            },
            {
                "_id": "middlesex",
                "code": "middlesex",
                "name": "Middlesex",
                "pk": "GB__middlesex"
            },
            {
                "_id": "mildenhall",
                "code": "mildenhall",
                "name": "Mildenhall",
                "pk": "GB__mildenhall"
            },
            {
                "_id": "monmouthshire",
                "code": "monmouthshire",
                "name": "Monmouthshire",
                "pk": "GB__monmouthshire"
            },
            {
                "_id": "newton_stewart",
                "code": "newton_stewart",
                "name": "Newton Stewart",
                "pk": "GB__newton_stewart"
            },
            {
                "_id": "norfolk",
                "code": "norfolk",
                "name": "Norfolk",
                "pk": "GB__norfolk"
            },
            {
                "_id": "north_humberside",
                "code": "north_humberside",
                "name": "North Humberside",
                "pk": "GB__north_humberside"
            },
            {
                "_id": "north_yorkshire",
                "code": "north_yorkshire",
                "name": "North Yorkshire",
                "pk": "GB__north_yorkshire"
            },
            {
                "_id": "northamptonshire",
                "code": "northamptonshire",
                "name": "Northamptonshire",
                "pk": "GB__northamptonshire"
            },
            {
                "_id": "northants",
                "code": "northants",
                "name": "Northants",
                "pk": "GB__northants"
            },
            {
                "_id": "northern_ireland",
                "code": "northern_ireland",
                "name": "Northern Ireland",
                "pk": "GB__northern_ireland"
            },
            {
                "_id": "northumberland",
                "code": "northumberland",
                "name": "Northumberland",
                "pk": "GB__northumberland"
            },
            {
                "_id": "nottinghamshire",
                "code": "nottinghamshire",
                "name": "Nottinghamshire",
                "pk": "GB__nottinghamshire"
            },
            {
                "_id": "oxford",
                "code": "oxford",
                "name": "Oxford",
                "pk": "GB__oxford"
            },
            {
                "_id": "powys",
                "code": "powys",
                "name": "Powys",
                "pk": "GB__powys"
            },
            {
                "_id": "roos-shire",
                "code": "roos-shire",
                "name": "Roos-shire",
                "pk": "GB__roos-shire"
            },
            {
                "_id": "sussex",
                "code": "sussex",
                "name": "SUSSEX",
                "pk": "GB__sussex"
            },
            {
                "_id": "sark",
                "code": "sark",
                "name": "Sark",
                "pk": "GB__sark"
            },
            {
                "_id": "scotland",
                "code": "scotland",
                "name": "Scotland",
                "pk": "GB__scotland"
            },
            {
                "_id": "scottish_borders",
                "code": "scottish_borders",
                "name": "Scottish Borders",
                "pk": "GB__scottish_borders"
            },
            {
                "_id": "shropshire",
                "code": "shropshire",
                "name": "Shropshire",
                "pk": "GB__shropshire"
            },
            {
                "_id": "somerset",
                "code": "somerset",
                "name": "Somerset",
                "pk": "GB__somerset"
            },
            {
                "_id": "south_glamorgan",
                "code": "south_glamorgan",
                "name": "South Glamorgan",
                "pk": "GB__south_glamorgan"
            },
            {
                "_id": "south_wales",
                "code": "south_wales",
                "name": "South Wales",
                "pk": "GB__south_wales"
            },
            {
                "_id": "south_yorkshire",
                "code": "south_yorkshire",
                "name": "South Yorkshire",
                "pk": "GB__south_yorkshire"
            },
            {
                "_id": "southwell",
                "code": "southwell",
                "name": "Southwell",
                "pk": "GB__southwell"
            },
            {
                "_id": "staffordshire",
                "code": "staffordshire",
                "name": "Staffordshire",
                "pk": "GB__staffordshire"
            },
            {
                "_id": "strabane",
                "code": "strabane",
                "name": "Strabane",
                "pk": "GB__strabane"
            },
            {
                "_id": "suffolk",
                "code": "suffolk",
                "name": "Suffolk",
                "pk": "GB__suffolk"
            },
            {
                "_id": "surrey",
                "code": "surrey",
                "name": "Surrey",
                "pk": "GB__surrey"
            },
            {
                "_id": "sussex",
                "code": "sussex",
                "name": "Sussex",
                "pk": "GB__sussex"
            },
            {
                "_id": "twickenham",
                "code": "twickenham",
                "name": "Twickenham",
                "pk": "GB__twickenham"
            },
            {
                "_id": "tyne_and_wear",
                "code": "tyne_and_wear",
                "name": "Tyne and Wear",
                "pk": "GB__tyne_and_wear"
            },
            {
                "_id": "tyrone",
                "code": "tyrone",
                "name": "Tyrone",
                "pk": "GB__tyrone"
            },
            {
                "_id": "utah",
                "code": "utah",
                "name": "Utah",
                "pk": "GB__utah"
            },
            {
                "_id": "wales",
                "code": "wales",
                "name": "Wales",
                "pk": "GB__wales"
            },
            {
                "_id": "warwickshire",
                "code": "warwickshire",
                "name": "Warwickshire",
                "pk": "GB__warwickshire"
            },
            {
                "_id": "west_lothian",
                "code": "west_lothian",
                "name": "West Lothian",
                "pk": "GB__west_lothian"
            },
            {
                "_id": "west_midlands",
                "code": "west_midlands",
                "name": "West Midlands",
                "pk": "GB__west_midlands"
            },
            {
                "_id": "west_sussex",
                "code": "west_sussex",
                "name": "West Sussex",
                "pk": "GB__west_sussex"
            },
            {
                "_id": "west_yorkshire",
                "code": "west_yorkshire",
                "name": "West Yorkshire",
                "pk": "GB__west_yorkshire"
            },
            {
                "_id": "whissendine",
                "code": "whissendine",
                "name": "Whissendine",
                "pk": "GB__whissendine"
            },
            {
                "_id": "wiltshire",
                "code": "wiltshire",
                "name": "Wiltshire",
                "pk": "GB__wiltshire"
            },
            {
                "_id": "wokingham",
                "code": "wokingham",
                "name": "Wokingham",
                "pk": "GB__wokingham"
            },
            {
                "_id": "worcestershire",
                "code": "worcestershire",
                "name": "Worcestershire",
                "pk": "GB__worcestershire"
            },
            {
                "_id": "wrexham",
                "code": "wrexham",
                "name": "Wrexham",
                "pk": "GB__wrexham"
            },
            {
                "_id": "wurttemberg",
                "code": "wurttemberg",
                "name": "Wurttemberg",
                "pk": "GB__wurttemberg"
            },
            {
                "_id": "yorkshire",
                "code": "yorkshire",
                "name": "Yorkshire",
                "pk": "GB__yorkshire"
            }
        ],
        "only_shipping": true,
        "pk": "GB"
    },
    {
        "_id": "UM",
        "code": "UM",
        "name": "United States Minor Outlying Islands",
        "pk": "UM"
    },
    {
        "_id": "UY",
        "code": "UY",
        "name": "Uruguay",
        "states": [
            {
                "_id": "artigas",
                "code": "artigas",
                "name": "Artigas",
                "pk": "UY__artigas"
            },
            {
                "_id": "canelones",
                "code": "canelones",
                "name": "Canelones",
                "pk": "UY__canelones"
            },
            {
                "_id": "cerro_largo",
                "code": "cerro_largo",
                "name": "Cerro Largo",
                "pk": "UY__cerro_largo"
            },
            {
                "_id": "colonia",
                "code": "colonia",
                "name": "Colonia",
                "pk": "UY__colonia"
            },
            {
                "_id": "durazno",
                "code": "durazno",
                "name": "Durazno",
                "pk": "UY__durazno"
            },
            {
                "_id": "florida",
                "code": "florida",
                "name": "FLorida",
                "pk": "UY__florida"
            },
            {
                "_id": "flores",
                "code": "flores",
                "name": "Flores",
                "pk": "UY__flores"
            },
            {
                "_id": "lavalleja",
                "code": "lavalleja",
                "name": "Lavalleja",
                "pk": "UY__lavalleja"
            },
            {
                "_id": "maldonado",
                "code": "maldonado",
                "name": "Maldonado",
                "pk": "UY__maldonado"
            },
            {
                "_id": "montevideo",
                "code": "montevideo",
                "name": "Montevideo",
                "pk": "UY__montevideo"
            },
            {
                "_id": "paysandu",
                "code": "paysandu",
                "name": "Paysandu",
                "pk": "UY__paysandu"
            },
            {
                "_id": "rio_negro",
                "code": "rio_negro",
                "name": "Rio Negro",
                "pk": "UY__rio_negro"
            },
            {
                "_id": "rivera",
                "code": "rivera",
                "name": "Rivera",
                "pk": "UY__rivera"
            },
            {
                "_id": "rocha",
                "code": "rocha",
                "name": "Rocha",
                "pk": "UY__rocha"
            },
            {
                "_id": "salto",
                "code": "salto",
                "name": "Salto",
                "pk": "UY__salto"
            },
            {
                "_id": "san_jose",
                "code": "san_jose",
                "name": "San Jose",
                "pk": "UY__san_jose"
            },
            {
                "_id": "soriano",
                "code": "soriano",
                "name": "Soriano",
                "pk": "UY__soriano"
            },
            {
                "_id": "tacuarembo",
                "code": "tacuarembo",
                "name": "Tacuarembo",
                "pk": "UY__tacuarembo"
            },
            {
                "_id": "treinta_y_tres",
                "code": "treinta_y_tres",
                "name": "Treinta y Tres",
                "pk": "UY__treinta_y_tres"
            }
        ],
        "pk": "UY"
    },
    {
        "_id": "UZ",
        "code": "UZ",
        "name": "Uzbekistan",
        "states": [
            {
                "_id": "andijon",
                "code": "andijon",
                "name": "Andijon",
                "pk": "UZ__andijon"
            },
            {
                "_id": "buhoro",
                "code": "buhoro",
                "name": "Buhoro",
                "pk": "UZ__buhoro"
            },
            {
                "_id": "buxoro_viloyati",
                "code": "buxoro_viloyati",
                "name": "Buxoro Viloyati",
                "pk": "UZ__buxoro_viloyati"
            },
            {
                "_id": "cizah",
                "code": "cizah",
                "name": "Cizah",
                "pk": "UZ__cizah"
            },
            {
                "_id": "fargona",
                "code": "fargona",
                "name": "Fargona",
                "pk": "UZ__fargona"
            },
            {
                "_id": "horazm",
                "code": "horazm",
                "name": "Horazm",
                "pk": "UZ__horazm"
            },
            {
                "_id": "kaskadar",
                "code": "kaskadar",
                "name": "Kaskadar",
                "pk": "UZ__kaskadar"
            },
            {
                "_id": "korakalpogiston",
                "code": "korakalpogiston",
                "name": "Korakalpogiston",
                "pk": "UZ__korakalpogiston"
            },
            {
                "_id": "namangan",
                "code": "namangan",
                "name": "Namangan",
                "pk": "UZ__namangan"
            },
            {
                "_id": "navoi",
                "code": "navoi",
                "name": "Navoi",
                "pk": "UZ__navoi"
            },
            {
                "_id": "samarkand",
                "code": "samarkand",
                "name": "Samarkand",
                "pk": "UZ__samarkand"
            },
            {
                "_id": "sirdare",
                "code": "sirdare",
                "name": "Sirdare",
                "pk": "UZ__sirdare"
            },
            {
                "_id": "surhondar",
                "code": "surhondar",
                "name": "Surhondar",
                "pk": "UZ__surhondar"
            },
            {
                "_id": "toskent",
                "code": "toskent",
                "name": "Toskent",
                "pk": "UZ__toskent"
            }
        ],
        "pk": "UZ"
    },
    {
        "_id": "VU",
        "code": "VU",
        "name": "Vanuatu",
        "states": [
            {
                "_id": "malampa",
                "code": "malampa",
                "name": "Malampa",
                "pk": "VU__malampa"
            },
            {
                "_id": "penama",
                "code": "penama",
                "name": "Penama",
                "pk": "VU__penama"
            },
            {
                "_id": "sanma",
                "code": "sanma",
                "name": "Sanma",
                "pk": "VU__sanma"
            },
            {
                "_id": "shefa",
                "code": "shefa",
                "name": "Shefa",
                "pk": "VU__shefa"
            },
            {
                "_id": "tafea",
                "code": "tafea",
                "name": "Tafea",
                "pk": "VU__tafea"
            },
            {
                "_id": "torba",
                "code": "torba",
                "name": "Torba",
                "pk": "VU__torba"
            }
        ],
        "pk": "VU"
    },
    {
        "_id": "VA",
        "code": "VA",
        "name": "Vatican City State Holy See",
        "states": [
            {
                "_id": "vatican_city_state_(holy_see",
                "code": "vatican_city_state_(holy_see",
                "name": "Vatican City State (Holy See",
                "pk": "VA__vatican_city_state_(holy_see"
            }
        ],
        "pk": "VA"
    },
    {
        "_id": "VE",
        "code": "VE",
        "name": "Venezuela",
        "states": [
            {
                "_id": "amazonas",
                "code": "amazonas",
                "name": "Amazonas",
                "pk": "VE__amazonas"
            },
            {
                "_id": "anzoategui",
                "code": "anzoategui",
                "name": "Anzoategui",
                "pk": "VE__anzoategui"
            },
            {
                "_id": "apure",
                "code": "apure",
                "name": "Apure",
                "pk": "VE__apure"
            },
            {
                "_id": "aragua",
                "code": "aragua",
                "name": "Aragua",
                "pk": "VE__aragua"
            },
            {
                "_id": "barinas",
                "code": "barinas",
                "name": "Barinas",
                "pk": "VE__barinas"
            },
            {
                "_id": "bolivar",
                "code": "bolivar",
                "name": "Bolivar",
                "pk": "VE__bolivar"
            },
            {
                "_id": "carabobo",
                "code": "carabobo",
                "name": "Carabobo",
                "pk": "VE__carabobo"
            },
            {
                "_id": "cojedes",
                "code": "cojedes",
                "name": "Cojedes",
                "pk": "VE__cojedes"
            },
            {
                "_id": "delta_amacuro",
                "code": "delta_amacuro",
                "name": "Delta Amacuro",
                "pk": "VE__delta_amacuro"
            },
            {
                "_id": "distrito_federal",
                "code": "distrito_federal",
                "name": "Distrito Federal",
                "pk": "VE__distrito_federal"
            },
            {
                "_id": "falcon",
                "code": "falcon",
                "name": "Falcon",
                "pk": "VE__falcon"
            },
            {
                "_id": "guarico",
                "code": "guarico",
                "name": "Guarico",
                "pk": "VE__guarico"
            },
            {
                "_id": "lara",
                "code": "lara",
                "name": "Lara",
                "pk": "VE__lara"
            },
            {
                "_id": "merida",
                "code": "merida",
                "name": "Merida",
                "pk": "VE__merida"
            },
            {
                "_id": "miranda",
                "code": "miranda",
                "name": "Miranda",
                "pk": "VE__miranda"
            },
            {
                "_id": "monagas",
                "code": "monagas",
                "name": "Monagas",
                "pk": "VE__monagas"
            },
            {
                "_id": "nueva_esparta",
                "code": "nueva_esparta",
                "name": "Nueva Esparta",
                "pk": "VE__nueva_esparta"
            },
            {
                "_id": "portuguesa",
                "code": "portuguesa",
                "name": "Portuguesa",
                "pk": "VE__portuguesa"
            },
            {
                "_id": "sucre",
                "code": "sucre",
                "name": "Sucre",
                "pk": "VE__sucre"
            },
            {
                "_id": "tachira",
                "code": "tachira",
                "name": "Tachira",
                "pk": "VE__tachira"
            },
            {
                "_id": "trujillo",
                "code": "trujillo",
                "name": "Trujillo",
                "pk": "VE__trujillo"
            },
            {
                "_id": "vargas",
                "code": "vargas",
                "name": "Vargas",
                "pk": "VE__vargas"
            },
            {
                "_id": "yaracuy",
                "code": "yaracuy",
                "name": "Yaracuy",
                "pk": "VE__yaracuy"
            },
            {
                "_id": "zulia",
                "code": "zulia",
                "name": "Zulia",
                "pk": "VE__zulia"
            }
        ],
        "pk": "VE"
    },
    {
        "_id": "VN",
        "code": "VN",
        "name": "Vietnam",
        "states": [
            {
                "_id": "bac_giang",
                "code": "bac_giang",
                "name": "Bac Giang",
                "pk": "VN__bac_giang"
            },
            {
                "_id": "binh_dinh",
                "code": "binh_dinh",
                "name": "Binh Dinh",
                "pk": "VN__binh_dinh"
            },
            {
                "_id": "binh_duong",
                "code": "binh_duong",
                "name": "Binh Duong",
                "pk": "VN__binh_duong"
            },
            {
                "_id": "da_nang",
                "code": "da_nang",
                "name": "Da Nang",
                "pk": "VN__da_nang"
            },
            {
                "_id": "dong_bang_song_cuu_long",
                "code": "dong_bang_song_cuu_long",
                "name": "Dong Bang Song Cuu Long",
                "pk": "VN__dong_bang_song_cuu_long"
            },
            {
                "_id": "dong_bang_song_hong",
                "code": "dong_bang_song_hong",
                "name": "Dong Bang Song Hong",
                "pk": "VN__dong_bang_song_hong"
            },
            {
                "_id": "dong_nai",
                "code": "dong_nai",
                "name": "Dong Nai",
                "pk": "VN__dong_nai"
            },
            {
                "_id": "dong_nam_bo",
                "code": "dong_nam_bo",
                "name": "Dong Nam Bo",
                "pk": "VN__dong_nam_bo"
            },
            {
                "_id": "duyen_hai_mien_trung",
                "code": "duyen_hai_mien_trung",
                "name": "Duyen Hai Mien Trung",
                "pk": "VN__duyen_hai_mien_trung"
            },
            {
                "_id": "hanoi",
                "code": "hanoi",
                "name": "Hanoi",
                "pk": "VN__hanoi"
            },
            {
                "_id": "hung_yen",
                "code": "hung_yen",
                "name": "Hung Yen",
                "pk": "VN__hung_yen"
            },
            {
                "_id": "khu_bon_cu",
                "code": "khu_bon_cu",
                "name": "Khu Bon Cu",
                "pk": "VN__khu_bon_cu"
            },
            {
                "_id": "long_an",
                "code": "long_an",
                "name": "Long An",
                "pk": "VN__long_an"
            },
            {
                "_id": "mien_nui_va_trung_du",
                "code": "mien_nui_va_trung_du",
                "name": "Mien Nui Va Trung Du",
                "pk": "VN__mien_nui_va_trung_du"
            },
            {
                "_id": "thai_nguyen",
                "code": "thai_nguyen",
                "name": "Thai Nguyen",
                "pk": "VN__thai_nguyen"
            },
            {
                "_id": "thanh_pho_ho_chi_minh",
                "code": "thanh_pho_ho_chi_minh",
                "name": "Thanh Pho Ho Chi Minh",
                "pk": "VN__thanh_pho_ho_chi_minh"
            },
            {
                "_id": "thu_do_ha_noi",
                "code": "thu_do_ha_noi",
                "name": "Thu Do Ha Noi",
                "pk": "VN__thu_do_ha_noi"
            },
            {
                "_id": "tinh_can_tho",
                "code": "tinh_can_tho",
                "name": "Tinh Can Tho",
                "pk": "VN__tinh_can_tho"
            },
            {
                "_id": "tinh_da_nang",
                "code": "tinh_da_nang",
                "name": "Tinh Da Nang",
                "pk": "VN__tinh_da_nang"
            },
            {
                "_id": "tinh_gia_lai",
                "code": "tinh_gia_lai",
                "name": "Tinh Gia Lai",
                "pk": "VN__tinh_gia_lai"
            }
        ],
        "pk": "VN"
    },
    {
        "_id": "VG",
        "code": "VG",
        "name": "Virgin Islands British",
        "states": [
            {
                "_id": "anegada",
                "code": "anegada",
                "name": "Anegada",
                "pk": "VG__anegada"
            },
            {
                "_id": "jost_van_dyke",
                "code": "jost_van_dyke",
                "name": "Jost van Dyke",
                "pk": "VG__jost_van_dyke"
            },
            {
                "_id": "tortola",
                "code": "tortola",
                "name": "Tortola",
                "pk": "VG__tortola"
            }
        ],
        "pk": "VG"
    },
    {
        "_id": "VI",
        "code": "VI",
        "name": "Virgin Islands US",
        "states": [
            {
                "_id": "saint_croix",
                "code": "saint_croix",
                "name": "Saint Croix",
                "pk": "VI__saint_croix"
            },
            {
                "_id": "saint_john",
                "code": "saint_john",
                "name": "Saint John",
                "pk": "VI__saint_john"
            },
            {
                "_id": "saint_thomas",
                "code": "saint_thomas",
                "name": "Saint Thomas",
                "pk": "VI__saint_thomas"
            }
        ],
        "pk": "VI"
    },
    {
        "_id": "WF",
        "code": "WF",
        "name": "Wallis And Futuna Islands",
        "states": [
            {
                "_id": "alo",
                "code": "alo",
                "name": "Alo",
                "pk": "WF__alo"
            },
            {
                "_id": "singave",
                "code": "singave",
                "name": "Singave",
                "pk": "WF__singave"
            },
            {
                "_id": "wallis",
                "code": "wallis",
                "name": "Wallis",
                "pk": "WF__wallis"
            }
        ],
        "pk": "WF"
    },
    {
        "_id": "EH",
        "code": "EH",
        "name": "Western Sahara",
        "states": [
            {
                "_id": "bu_jaydur",
                "code": "bu_jaydur",
                "name": "Bu Jaydur",
                "pk": "EH__bu_jaydur"
            },
            {
                "_id": "wad-adh-dhahab",
                "code": "wad-adh-dhahab",
                "name": "Wad-adh-Dhahab",
                "pk": "EH__wad-adh-dhahab"
            },
            {
                "_id": "al-ayun",
                "code": "al-ayun",
                "name": "al-Ayun",
                "pk": "EH__al-ayun"
            },
            {
                "_id": "as-samarah",
                "code": "as-samarah",
                "name": "as-Samarah",
                "pk": "EH__as-samarah"
            }
        ],
        "pk": "EH"
    },
    {
        "_id": "YE",
        "code": "YE",
        "name": "Yemen",
        "states": [
            {
                "_id": "adan",
                "code": "adan",
                "name": "Adan",
                "pk": "YE__adan"
            },
            {
                "_id": "abyan",
                "code": "abyan",
                "name": "Abyan",
                "pk": "YE__abyan"
            },
            {
                "_id": "dhamar",
                "code": "dhamar",
                "name": "Dhamar",
                "pk": "YE__dhamar"
            },
            {
                "_id": "hadramaut",
                "code": "hadramaut",
                "name": "Hadramaut",
                "pk": "YE__hadramaut"
            },
            {
                "_id": "hajjah",
                "code": "hajjah",
                "name": "Hajjah",
                "pk": "YE__hajjah"
            },
            {
                "_id": "hudaydah",
                "code": "hudaydah",
                "name": "Hudaydah",
                "pk": "YE__hudaydah"
            },
            {
                "_id": "ibb",
                "code": "ibb",
                "name": "Ibb",
                "pk": "YE__ibb"
            },
            {
                "_id": "lahij",
                "code": "lahij",
                "name": "Lahij",
                "pk": "YE__lahij"
            },
            {
                "_id": "marib",
                "code": "marib",
                "name": "Marib",
                "pk": "YE__marib"
            },
            {
                "_id": "madinat_sana",
                "code": "madinat_sana",
                "name": "Madinat Sana",
                "pk": "YE__madinat_sana"
            },
            {
                "_id": "sadah",
                "code": "sadah",
                "name": "Sadah",
                "pk": "YE__sadah"
            },
            {
                "_id": "sana",
                "code": "sana",
                "name": "Sana",
                "pk": "YE__sana"
            },
            {
                "_id": "shabwah",
                "code": "shabwah",
                "name": "Shabwah",
                "pk": "YE__shabwah"
            },
            {
                "_id": "taizz",
                "code": "taizz",
                "name": "Taizz",
                "pk": "YE__taizz"
            },
            {
                "_id": "al-bayda",
                "code": "al-bayda",
                "name": "al-Bayda",
                "pk": "YE__al-bayda"
            },
            {
                "_id": "al-hudaydah",
                "code": "al-hudaydah",
                "name": "al-Hudaydah",
                "pk": "YE__al-hudaydah"
            },
            {
                "_id": "al-jawf",
                "code": "al-jawf",
                "name": "al-Jawf",
                "pk": "YE__al-jawf"
            },
            {
                "_id": "al-mahrah",
                "code": "al-mahrah",
                "name": "al-Mahrah",
                "pk": "YE__al-mahrah"
            },
            {
                "_id": "al-mahwit",
                "code": "al-mahwit",
                "name": "al-Mahwit",
                "pk": "YE__al-mahwit"
            }
        ],
        "pk": "YE"
    },
    {
        "_id": "YU",
        "code": "YU",
        "name": "Yugoslavia",
        "states": [
            {
                "_id": "central_serbia",
                "code": "central_serbia",
                "name": "Central Serbia",
                "pk": "YU__central_serbia"
            },
            {
                "_id": "kosovo_and_metohija",
                "code": "kosovo_and_metohija",
                "name": "Kosovo and Metohija",
                "pk": "YU__kosovo_and_metohija"
            },
            {
                "_id": "montenegro",
                "code": "montenegro",
                "name": "Montenegro",
                "pk": "YU__montenegro"
            },
            {
                "_id": "republic_of_serbia",
                "code": "republic_of_serbia",
                "name": "Republic of Serbia",
                "pk": "YU__republic_of_serbia"
            },
            {
                "_id": "serbia",
                "code": "serbia",
                "name": "Serbia",
                "pk": "YU__serbia"
            },
            {
                "_id": "vojvodina",
                "code": "vojvodina",
                "name": "Vojvodina",
                "pk": "YU__vojvodina"
            }
        ],
        "pk": "YU"
    },
    {
        "_id": "ZM",
        "code": "ZM",
        "name": "Zambia",
        "states": [
            {
                "_id": "central",
                "code": "central",
                "name": "Central",
                "pk": "ZM__central"
            },
            {
                "_id": "copperbelt",
                "code": "copperbelt",
                "name": "Copperbelt",
                "pk": "ZM__copperbelt"
            },
            {
                "_id": "eastern",
                "code": "eastern",
                "name": "Eastern",
                "pk": "ZM__eastern"
            },
            {
                "_id": "luapala",
                "code": "luapala",
                "name": "Luapala",
                "pk": "ZM__luapala"
            },
            {
                "_id": "lusaka",
                "code": "lusaka",
                "name": "Lusaka",
                "pk": "ZM__lusaka"
            },
            {
                "_id": "north-western",
                "code": "north-western",
                "name": "North-Western",
                "pk": "ZM__north-western"
            },
            {
                "_id": "northern",
                "code": "northern",
                "name": "Northern",
                "pk": "ZM__northern"
            },
            {
                "_id": "southern",
                "code": "southern",
                "name": "Southern",
                "pk": "ZM__southern"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "ZM__western"
            }
        ],
        "pk": "ZM"
    },
    {
        "_id": "ZW",
        "code": "ZW",
        "name": "Zimbabwe",
        "states": [
            {
                "_id": "bulawayo",
                "code": "bulawayo",
                "name": "Bulawayo",
                "pk": "ZW__bulawayo"
            },
            {
                "_id": "harare",
                "code": "harare",
                "name": "Harare",
                "pk": "ZW__harare"
            },
            {
                "_id": "manicaland",
                "code": "manicaland",
                "name": "Manicaland",
                "pk": "ZW__manicaland"
            },
            {
                "_id": "mashonaland_central",
                "code": "mashonaland_central",
                "name": "Mashonaland Central",
                "pk": "ZW__mashonaland_central"
            },
            {
                "_id": "mashonaland_east",
                "code": "mashonaland_east",
                "name": "Mashonaland East",
                "pk": "ZW__mashonaland_east"
            },
            {
                "_id": "mashonaland_west",
                "code": "mashonaland_west",
                "name": "Mashonaland West",
                "pk": "ZW__mashonaland_west"
            },
            {
                "_id": "masvingo",
                "code": "masvingo",
                "name": "Masvingo",
                "pk": "ZW__masvingo"
            },
            {
                "_id": "matabeleland_north",
                "code": "matabeleland_north",
                "name": "Matabeleland North",
                "pk": "ZW__matabeleland_north"
            },
            {
                "_id": "matabeleland_south",
                "code": "matabeleland_south",
                "name": "Matabeleland South",
                "pk": "ZW__matabeleland_south"
            },
            {
                "_id": "midlands",
                "code": "midlands",
                "name": "Midlands",
                "pk": "ZW__midlands"
            }
        ],
        "pk": "ZW"
    }
]

export const fr_countries = [
    {
        "_id": "CA",
        "code": "CA",
        "name": "Canada",
        "states": [
            {
                "_id": "AB",
                "code": "AB",
                "name": "Alberta",
                "pk": "CA__AB"
            },
            {
                "_id": "BC",
                "code": "BC",
                "name": "British Columbia",
                "pk": "CA__BC"
            },
            {
                "_id": "MB",
                "code": "MB",
                "name": "Manitoba",
                "pk": "CA__MB"
            },
            {
                "_id": "NB",
                "code": "NB",
                "name": "New Brunswick",
                "pk": "CA__NB"
            },
            {
                "_id": "NL",
                "code": "NL",
                "name": "Newfoundland and Labrador",
                "pk": "CA__NL"
            },
            {
                "_id": "NT",
                "code": "NT",
                "name": "Northwest Territories",
                "pk": "CA__NT"
            },
            {
                "_id": "NS",
                "code": "NS",
                "name": "Nova Scotia",
                "pk": "CA__NS"
            },
            {
                "_id": "NU",
                "code": "NU",
                "name": "Nunavut",
                "pk": "CA__NU"
            },
            {
                "_id": "ON",
                "code": "ON",
                "name": "Ontario",
                "pk": "CA__ON"
            },
            {
                "_id": "PE",
                "code": "PE",
                "name": "Prince Edward Island",
                "pk": "CA__PE"
            },
            {
                "_id": "QC",
                "code": "QC",
                "name": "Quebec",
                "pk": "CA__QC"
            },
            {
                "_id": "SK",
                "code": "SK",
                "name": "Saskatchewan",
                "pk": "CA__SK"
            },
            {
                "_id": "YT",
                "code": "YT",
                "name": "Yukon",
                "pk": "CA__YT"
            }
        ],
        "only_shipping": true,
        "pk": "CA"
    },
    {
        "_id": "US",
        "code": "US",
        "name": "États-Unis",
        "states": [
            {
                "_id": "alabama",
                "code": "AL",
                "name": "Alabama",
                "pk": "US__alabama"
            },
            {
                "_id": "alaska",
                "code": "AK",
                "name": "Alaska",
                "pk": "US__alaska"
            },
            {
                "_id": "arizona",
                "code": "AZ",
                "name": "Arizona",
                "pk": "US__arizona"
            },
            {
                "_id": "arkansas",
                "code": "AR",
                "name": "Arkansas",
                "pk": "US__arkansas"
            },
            {
                "_id": "california",
                "code": "CA",
                "name": "California",
                "pk": "US__california"
            },
            {
                "_id": "colorado",
                "code": "CO",
                "name": "Colorado",
                "pk": "US__colorado"
            },
            {
                "_id": "connecticut",
                "code": "CT",
                "name": "Connecticut",
                "pk": "US__connecticut"
            },
            {
                "_id": "delaware",
                "code": "DE",
                "name": "Delaware",
                "pk": "US__delaware"
            },
            {
                "_id": "district_of_columbia",
                "code": "DC",
                "name": "District of Columbia",
                "pk": "US__district_of_columbia"
            },
            {
                "_id": "florida",
                "code": "FL",
                "name": "Florida",
                "pk": "US__florida"
            },
            {
                "_id": "georgia",
                "code": "GA",
                "name": "Georgia",
                "pk": "US__georgia"
            },
            {
                "_id": "hawaii",
                "code": "HI",
                "name": "Hawaii",
                "pk": "US__hawaii"
            },
            {
                "_id": "idaho",
                "code": "ID",
                "name": "Idaho",
                "pk": "US__idaho"
            },
            {
                "_id": "illinois",
                "code": "IL",
                "name": "Illinois",
                "pk": "US__illinois"
            },
            {
                "_id": "indiana",
                "code": "IN",
                "name": "Indiana",
                "pk": "US__indiana"
            },
            {
                "_id": "iowa",
                "code": "IA",
                "name": "Iowa",
                "pk": "US__iowa"
            },
            {
                "_id": "kansas",
                "code": "KS",
                "name": "Kansas",
                "pk": "US__kansas"
            },
            {
                "_id": "kentucky",
                "code": "KY",
                "name": "Kentucky",
                "pk": "US__kentucky"
            },
            {
                "_id": "louisiana",
                "code": "LA",
                "name": "Louisiana",
                "pk": "US__louisiana"
            },
            {
                "_id": "maine",
                "code": "ME",
                "name": "Maine",
                "pk": "US__maine"
            },
            {
                "_id": "maryland",
                "code": "MD",
                "name": "Maryland",
                "pk": "US__maryland"
            },
            {
                "_id": "massachusetts",
                "code": "MA",
                "name": "Massachusetts",
                "pk": "US__massachusetts"
            },
            {
                "_id": "michigan",
                "code": "MI",
                "name": "Michigan",
                "pk": "US__michigan"
            },
            {
                "_id": "minnesota",
                "code": "MN",
                "name": "Minnesota",
                "pk": "US__minnesota"
            },
            {
                "_id": "mississippi",
                "code": "MS",
                "name": "Mississippi",
                "pk": "US__mississippi"
            },
            {
                "_id": "missouri",
                "code": "MO",
                "name": "Missouri",
                "pk": "US__missouri"
            },
            {
                "_id": "montana",
                "code": "MT",
                "name": "Montana",
                "pk": "US__montana"
            },
            {
                "_id": "nebraska",
                "code": "NE",
                "name": "Nebraska",
                "pk": "US__nebraska"
            },
            {
                "_id": "nevada",
                "code": "NV",
                "name": "Nevada",
                "pk": "US__nevada"
            },
            {
                "_id": "new_hampshire",
                "code": "NH",
                "name": "New Hampshire",
                "pk": "US__new_hampshire"
            },
            {
                "_id": "new_jersey",
                "code": "NJ",
                "name": "New Jersey",
                "pk": "US__new_jersey"
            },
            {
                "_id": "new_mexico",
                "code": "NM",
                "name": "New Mexico",
                "pk": "US__new_mexico"
            },
            {
                "_id": "new_york",
                "code": "NY",
                "name": "New York",
                "pk": "US__new_york"
            },
            {
                "_id": "north_carolina",
                "code": "NC",
                "name": "North Carolina",
                "pk": "US__north_carolina"
            },
            {
                "_id": "north_dakota",
                "code": "ND",
                "name": "North Dakota",
                "pk": "US__north_dakota"
            },
            {
                "_id": "ohio",
                "code": "OH",
                "name": "Ohio",
                "pk": "US__ohio"
            },
            {
                "_id": "oklahoma",
                "code": "OK",
                "name": "Oklahoma",
                "pk": "US__oklahoma"
            },
            {
                "_id": "oregon",
                "code": "OR",
                "name": "Oregon",
                "pk": "US__oregon"
            },
            {
                "_id": "pennsylvania",
                "code": "PA",
                "name": "Pennsylvania",
                "pk": "US__pennsylvania"
            },
            {
                "_id": "rhode_island",
                "code": "RI",
                "name": "Rhode Island",
                "pk": "US__rhode_island"
            },
            {
                "_id": "south_carolina",
                "code": "SC",
                "name": "South Carolina",
                "pk": "US__south_carolina"
            },
            {
                "_id": "south_dakota",
                "code": "SD",
                "name": "South Dakota",
                "pk": "US__south_dakota"
            },
            {
                "_id": "tennessee",
                "code": "TN",
                "name": "Tennessee",
                "pk": "US__tennessee"
            },
            {
                "_id": "texas",
                "code": "TX",
                "name": "Texas",
                "pk": "US__texas"
            },
            {
                "_id": "utah",
                "code": "UT",
                "name": "Utah",
                "pk": "US__utah"
            },
            {
                "_id": "vermont",
                "code": "VT",
                "name": "Vermont",
                "pk": "US__vermont"
            },
            {
                "_id": "virginia",
                "code": "VA",
                "name": "Virginia",
                "pk": "US__virginia"
            },
            {
                "_id": "washington",
                "code": "WA",
                "name": "Washington",
                "pk": "US__washington"
            },
            {
                "_id": "west_virginia",
                "code": "WV",
                "name": "West Virginia",
                "pk": "US__west_virginia"
            },
            {
                "_id": "wisconsin",
                "code": "WI",
                "name": "Wisconsin",
                "pk": "US__wisconsin"
            },
            {
                "_id": "wyoming",
                "code": "WY",
                "name": "Wyoming",
                "pk": "US__wyoming"
            }
        ],
        "only_shipping": true,
        "pk": "US"
    },
    {
        "_id": "AF",
        "code": "AF",
        "name": "Afghanistan",
        "states": [
            {
                "_id": "badakhshan",
                "code": "badakhshan",
                "name": "Badakhshan",
                "pk": "AF__badakhshan"
            },
            {
                "_id": "badgis",
                "code": "badgis",
                "name": "Badgis",
                "pk": "AF__badgis"
            },
            {
                "_id": "baglan",
                "code": "baglan",
                "name": "Baglan",
                "pk": "AF__baglan"
            },
            {
                "_id": "balkh",
                "code": "balkh",
                "name": "Balkh",
                "pk": "AF__balkh"
            },
            {
                "_id": "bamiyan",
                "code": "bamiyan",
                "name": "Bamiyan",
                "pk": "AF__bamiyan"
            },
            {
                "_id": "farah",
                "code": "farah",
                "name": "Farah",
                "pk": "AF__farah"
            },
            {
                "_id": "faryab",
                "code": "faryab",
                "name": "Faryab",
                "pk": "AF__faryab"
            },
            {
                "_id": "gawr",
                "code": "gawr",
                "name": "Gawr",
                "pk": "AF__gawr"
            },
            {
                "_id": "gazni",
                "code": "gazni",
                "name": "Gazni",
                "pk": "AF__gazni"
            },
            {
                "_id": "herat",
                "code": "herat",
                "name": "Herat",
                "pk": "AF__herat"
            },
            {
                "_id": "hilmand",
                "code": "hilmand",
                "name": "Hilmand",
                "pk": "AF__hilmand"
            },
            {
                "_id": "jawzjan",
                "code": "jawzjan",
                "name": "Jawzjan",
                "pk": "AF__jawzjan"
            },
            {
                "_id": "kabul",
                "code": "kabul",
                "name": "Kabul",
                "pk": "AF__kabul"
            },
            {
                "_id": "kapisa",
                "code": "kapisa",
                "name": "Kapisa",
                "pk": "AF__kapisa"
            },
            {
                "_id": "khawst",
                "code": "khawst",
                "name": "Khawst",
                "pk": "AF__khawst"
            },
            {
                "_id": "kunar",
                "code": "kunar",
                "name": "Kunar",
                "pk": "AF__kunar"
            },
            {
                "_id": "lagman",
                "code": "lagman",
                "name": "Lagman",
                "pk": "AF__lagman"
            },
            {
                "_id": "lawghar",
                "code": "lawghar",
                "name": "Lawghar",
                "pk": "AF__lawghar"
            },
            {
                "_id": "nangarhar",
                "code": "nangarhar",
                "name": "Nangarhar",
                "pk": "AF__nangarhar"
            },
            {
                "_id": "nimruz",
                "code": "nimruz",
                "name": "Nimruz",
                "pk": "AF__nimruz"
            },
            {
                "_id": "nuristan",
                "code": "nuristan",
                "name": "Nuristan",
                "pk": "AF__nuristan"
            },
            {
                "_id": "paktika",
                "code": "paktika",
                "name": "Paktika",
                "pk": "AF__paktika"
            },
            {
                "_id": "paktiya",
                "code": "paktiya",
                "name": "Paktiya",
                "pk": "AF__paktiya"
            },
            {
                "_id": "parwan",
                "code": "parwan",
                "name": "Parwan",
                "pk": "AF__parwan"
            },
            {
                "_id": "qandahar",
                "code": "qandahar",
                "name": "Qandahar",
                "pk": "AF__qandahar"
            },
            {
                "_id": "qunduz",
                "code": "qunduz",
                "name": "Qunduz",
                "pk": "AF__qunduz"
            },
            {
                "_id": "samangan",
                "code": "samangan",
                "name": "Samangan",
                "pk": "AF__samangan"
            },
            {
                "_id": "sar-e_pul",
                "code": "sar-e_pul",
                "name": "Sar-e Pul",
                "pk": "AF__sar-e_pul"
            },
            {
                "_id": "takhar",
                "code": "takhar",
                "name": "Takhar",
                "pk": "AF__takhar"
            },
            {
                "_id": "uruzgan",
                "code": "uruzgan",
                "name": "Uruzgan",
                "pk": "AF__uruzgan"
            },
            {
                "_id": "wardag",
                "code": "wardag",
                "name": "Wardag",
                "pk": "AF__wardag"
            },
            {
                "_id": "zabul",
                "code": "zabul",
                "name": "Zabul",
                "pk": "AF__zabul"
            }
        ],
        "pk": "AF"
    },
    {
        "_id": "AL",
        "code": "AL",
        "name": "Albania",
        "states": [
            {
                "_id": "berat",
                "code": "berat",
                "name": "Berat",
                "pk": "AL__berat"
            },
            {
                "_id": "bulqize",
                "code": "bulqize",
                "name": "Bulqize",
                "pk": "AL__bulqize"
            },
            {
                "_id": "delvine",
                "code": "delvine",
                "name": "Delvine",
                "pk": "AL__delvine"
            },
            {
                "_id": "devoll",
                "code": "devoll",
                "name": "Devoll",
                "pk": "AL__devoll"
            },
            {
                "_id": "dibre",
                "code": "dibre",
                "name": "Dibre",
                "pk": "AL__dibre"
            },
            {
                "_id": "durres",
                "code": "durres",
                "name": "Durres",
                "pk": "AL__durres"
            },
            {
                "_id": "elbasan",
                "code": "elbasan",
                "name": "Elbasan",
                "pk": "AL__elbasan"
            },
            {
                "_id": "fier",
                "code": "fier",
                "name": "Fier",
                "pk": "AL__fier"
            },
            {
                "_id": "gjirokaster",
                "code": "gjirokaster",
                "name": "Gjirokaster",
                "pk": "AL__gjirokaster"
            },
            {
                "_id": "gramsh",
                "code": "gramsh",
                "name": "Gramsh",
                "pk": "AL__gramsh"
            },
            {
                "_id": "has",
                "code": "has",
                "name": "Has",
                "pk": "AL__has"
            },
            {
                "_id": "kavaje",
                "code": "kavaje",
                "name": "Kavaje",
                "pk": "AL__kavaje"
            },
            {
                "_id": "kolonje",
                "code": "kolonje",
                "name": "Kolonje",
                "pk": "AL__kolonje"
            },
            {
                "_id": "korce",
                "code": "korce",
                "name": "Korce",
                "pk": "AL__korce"
            },
            {
                "_id": "kruje",
                "code": "kruje",
                "name": "Kruje",
                "pk": "AL__kruje"
            },
            {
                "_id": "kucove",
                "code": "kucove",
                "name": "Kucove",
                "pk": "AL__kucove"
            },
            {
                "_id": "kukes",
                "code": "kukes",
                "name": "Kukes",
                "pk": "AL__kukes"
            },
            {
                "_id": "kurbin",
                "code": "kurbin",
                "name": "Kurbin",
                "pk": "AL__kurbin"
            },
            {
                "_id": "lezhe",
                "code": "lezhe",
                "name": "Lezhe",
                "pk": "AL__lezhe"
            },
            {
                "_id": "librazhd",
                "code": "librazhd",
                "name": "Librazhd",
                "pk": "AL__librazhd"
            },
            {
                "_id": "lushnje",
                "code": "lushnje",
                "name": "Lushnje",
                "pk": "AL__lushnje"
            },
            {
                "_id": "mallakaster",
                "code": "mallakaster",
                "name": "Mallakaster",
                "pk": "AL__mallakaster"
            },
            {
                "_id": "malsi_e_madhe",
                "code": "malsi_e_madhe",
                "name": "Malsi e Madhe",
                "pk": "AL__malsi_e_madhe"
            },
            {
                "_id": "mat",
                "code": "mat",
                "name": "Mat",
                "pk": "AL__mat"
            },
            {
                "_id": "mirdite",
                "code": "mirdite",
                "name": "Mirdite",
                "pk": "AL__mirdite"
            },
            {
                "_id": "peqin",
                "code": "peqin",
                "name": "Peqin",
                "pk": "AL__peqin"
            },
            {
                "_id": "permet",
                "code": "permet",
                "name": "Permet",
                "pk": "AL__permet"
            },
            {
                "_id": "pogradec",
                "code": "pogradec",
                "name": "Pogradec",
                "pk": "AL__pogradec"
            },
            {
                "_id": "puke",
                "code": "puke",
                "name": "Puke",
                "pk": "AL__puke"
            },
            {
                "_id": "sarande",
                "code": "sarande",
                "name": "Sarande",
                "pk": "AL__sarande"
            },
            {
                "_id": "shkoder",
                "code": "shkoder",
                "name": "Shkoder",
                "pk": "AL__shkoder"
            },
            {
                "_id": "skrapar",
                "code": "skrapar",
                "name": "Skrapar",
                "pk": "AL__skrapar"
            },
            {
                "_id": "tepelene",
                "code": "tepelene",
                "name": "Tepelene",
                "pk": "AL__tepelene"
            },
            {
                "_id": "tirane",
                "code": "tirane",
                "name": "Tirane",
                "pk": "AL__tirane"
            },
            {
                "_id": "tropoje",
                "code": "tropoje",
                "name": "Tropoje",
                "pk": "AL__tropoje"
            },
            {
                "_id": "vlore",
                "code": "vlore",
                "name": "Vlore",
                "pk": "AL__vlore"
            }
        ],
        "pk": "AL"
    },
    {
        "_id": "DZ",
        "code": "DZ",
        "name": "Algérie",
        "states": [
            {
                "_id": "ayn_daflah",
                "code": "ayn_daflah",
                "name": "Ayn Daflah",
                "pk": "DZ__ayn_daflah"
            },
            {
                "_id": "ayn_tamushanat",
                "code": "ayn_tamushanat",
                "name": "Ayn Tamushanat",
                "pk": "DZ__ayn_tamushanat"
            },
            {
                "_id": "adrar",
                "code": "adrar",
                "name": "Adrar",
                "pk": "DZ__adrar"
            },
            {
                "_id": "algiers",
                "code": "algiers",
                "name": "Algiers",
                "pk": "DZ__algiers"
            },
            {
                "_id": "annabah",
                "code": "annabah",
                "name": "Annabah",
                "pk": "DZ__annabah"
            },
            {
                "_id": "bashshar",
                "code": "bashshar",
                "name": "Bashshar",
                "pk": "DZ__bashshar"
            },
            {
                "_id": "batnah",
                "code": "batnah",
                "name": "Batnah",
                "pk": "DZ__batnah"
            },
            {
                "_id": "bijayah",
                "code": "bijayah",
                "name": "Bijayah",
                "pk": "DZ__bijayah"
            },
            {
                "_id": "biskrah",
                "code": "biskrah",
                "name": "Biskrah",
                "pk": "DZ__biskrah"
            },
            {
                "_id": "blidah",
                "code": "blidah",
                "name": "Blidah",
                "pk": "DZ__blidah"
            },
            {
                "_id": "buirah",
                "code": "buirah",
                "name": "Buirah",
                "pk": "DZ__buirah"
            },
            {
                "_id": "bumardas",
                "code": "bumardas",
                "name": "Bumardas",
                "pk": "DZ__bumardas"
            },
            {
                "_id": "burj_bu_arririj",
                "code": "burj_bu_arririj",
                "name": "Burj Bu Arririj",
                "pk": "DZ__burj_bu_arririj"
            },
            {
                "_id": "ghalizan",
                "code": "ghalizan",
                "name": "Ghalizan",
                "pk": "DZ__ghalizan"
            },
            {
                "_id": "ghardayah",
                "code": "ghardayah",
                "name": "Ghardayah",
                "pk": "DZ__ghardayah"
            },
            {
                "_id": "ilizi",
                "code": "ilizi",
                "name": "Ilizi",
                "pk": "DZ__ilizi"
            },
            {
                "_id": "jijili",
                "code": "jijili",
                "name": "Jijili",
                "pk": "DZ__jijili"
            },
            {
                "_id": "jilfah",
                "code": "jilfah",
                "name": "Jilfah",
                "pk": "DZ__jilfah"
            },
            {
                "_id": "khanshalah",
                "code": "khanshalah",
                "name": "Khanshalah",
                "pk": "DZ__khanshalah"
            },
            {
                "_id": "masilah",
                "code": "masilah",
                "name": "Masilah",
                "pk": "DZ__masilah"
            },
            {
                "_id": "midyah",
                "code": "midyah",
                "name": "Midyah",
                "pk": "DZ__midyah"
            },
            {
                "_id": "milah",
                "code": "milah",
                "name": "Milah",
                "pk": "DZ__milah"
            },
            {
                "_id": "muaskar",
                "code": "muaskar",
                "name": "Muaskar",
                "pk": "DZ__muaskar"
            },
            {
                "_id": "mustaghanam",
                "code": "mustaghanam",
                "name": "Mustaghanam",
                "pk": "DZ__mustaghanam"
            },
            {
                "_id": "naama",
                "code": "naama",
                "name": "Naama",
                "pk": "DZ__naama"
            },
            {
                "_id": "oran",
                "code": "oran",
                "name": "Oran",
                "pk": "DZ__oran"
            },
            {
                "_id": "ouargla",
                "code": "ouargla",
                "name": "Ouargla",
                "pk": "DZ__ouargla"
            },
            {
                "_id": "qalmah",
                "code": "qalmah",
                "name": "Qalmah",
                "pk": "DZ__qalmah"
            },
            {
                "_id": "qustantinah",
                "code": "qustantinah",
                "name": "Qustantinah",
                "pk": "DZ__qustantinah"
            },
            {
                "_id": "sakikdah",
                "code": "sakikdah",
                "name": "Sakikdah",
                "pk": "DZ__sakikdah"
            },
            {
                "_id": "satif",
                "code": "satif",
                "name": "Satif",
                "pk": "DZ__satif"
            },
            {
                "_id": "sayda",
                "code": "sayda",
                "name": "Sayda",
                "pk": "DZ__sayda"
            },
            {
                "_id": "sidi_ban-al-abbas",
                "code": "sidi_ban-al-abbas",
                "name": "Sidi ban-al-Abbas",
                "pk": "DZ__sidi_ban-al-abbas"
            },
            {
                "_id": "suq_ahras",
                "code": "suq_ahras",
                "name": "Suq Ahras",
                "pk": "DZ__suq_ahras"
            },
            {
                "_id": "tamanghasat",
                "code": "tamanghasat",
                "name": "Tamanghasat",
                "pk": "DZ__tamanghasat"
            },
            {
                "_id": "tibazah",
                "code": "tibazah",
                "name": "Tibazah",
                "pk": "DZ__tibazah"
            },
            {
                "_id": "tibissah",
                "code": "tibissah",
                "name": "Tibissah",
                "pk": "DZ__tibissah"
            },
            {
                "_id": "tilimsan",
                "code": "tilimsan",
                "name": "Tilimsan",
                "pk": "DZ__tilimsan"
            },
            {
                "_id": "tinduf",
                "code": "tinduf",
                "name": "Tinduf",
                "pk": "DZ__tinduf"
            },
            {
                "_id": "tisamsilt",
                "code": "tisamsilt",
                "name": "Tisamsilt",
                "pk": "DZ__tisamsilt"
            },
            {
                "_id": "tiyarat",
                "code": "tiyarat",
                "name": "Tiyarat",
                "pk": "DZ__tiyarat"
            },
            {
                "_id": "tizi_wazu",
                "code": "tizi_wazu",
                "name": "Tizi Wazu",
                "pk": "DZ__tizi_wazu"
            },
            {
                "_id": "umm-al-bawaghi",
                "code": "umm-al-bawaghi",
                "name": "Umm-al-Bawaghi",
                "pk": "DZ__umm-al-bawaghi"
            },
            {
                "_id": "wahran",
                "code": "wahran",
                "name": "Wahran",
                "pk": "DZ__wahran"
            },
            {
                "_id": "warqla",
                "code": "warqla",
                "name": "Warqla",
                "pk": "DZ__warqla"
            },
            {
                "_id": "wilaya_d_alger",
                "code": "wilaya_d_alger",
                "name": "Wilaya d Alger",
                "pk": "DZ__wilaya_d_alger"
            },
            {
                "_id": "wilaya_de_bejaia",
                "code": "wilaya_de_bejaia",
                "name": "Wilaya de Bejaia",
                "pk": "DZ__wilaya_de_bejaia"
            },
            {
                "_id": "wilaya_de_constantine",
                "code": "wilaya_de_constantine",
                "name": "Wilaya de Constantine",
                "pk": "DZ__wilaya_de_constantine"
            },
            {
                "_id": "al-aghwat",
                "code": "al-aghwat",
                "name": "al-Aghwat",
                "pk": "DZ__al-aghwat"
            },
            {
                "_id": "al-bayadh",
                "code": "al-bayadh",
                "name": "al-Bayadh",
                "pk": "DZ__al-bayadh"
            },
            {
                "_id": "al-jazair",
                "code": "al-jazair",
                "name": "al-Jazair",
                "pk": "DZ__al-jazair"
            },
            {
                "_id": "al-wad",
                "code": "al-wad",
                "name": "al-Wad",
                "pk": "DZ__al-wad"
            },
            {
                "_id": "ash-shalif",
                "code": "ash-shalif",
                "name": "ash-Shalif",
                "pk": "DZ__ash-shalif"
            },
            {
                "_id": "at-tarif",
                "code": "at-tarif",
                "name": "at-Tarif",
                "pk": "DZ__at-tarif"
            }
        ],
        "pk": "DZ"
    },
    {
        "_id": "AS",
        "code": "AS",
        "name": "Samoa américaines",
        "states": [
            {
                "_id": "eastern",
                "code": "eastern",
                "name": "Eastern",
                "pk": "AS__eastern"
            },
            {
                "_id": "manua",
                "code": "manua",
                "name": "Manua",
                "pk": "AS__manua"
            },
            {
                "_id": "swains_island",
                "code": "swains_island",
                "name": "Swains Island",
                "pk": "AS__swains_island"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "AS__western"
            }
        ],
        "pk": "AS"
    },
    {
        "_id": "AD",
        "code": "AD",
        "name": "Andorre",
        "states": [
            {
                "_id": "andorra_la_vella",
                "code": "andorra_la_vella",
                "name": "Andorra la Vella",
                "pk": "AD__andorra_la_vella"
            },
            {
                "_id": "canillo",
                "code": "canillo",
                "name": "Canillo",
                "pk": "AD__canillo"
            },
            {
                "_id": "encamp",
                "code": "encamp",
                "name": "Encamp",
                "pk": "AD__encamp"
            },
            {
                "_id": "la_massana",
                "code": "la_massana",
                "name": "La Massana",
                "pk": "AD__la_massana"
            },
            {
                "_id": "les_escaldes",
                "code": "les_escaldes",
                "name": "Les Escaldes",
                "pk": "AD__les_escaldes"
            },
            {
                "_id": "ordino",
                "code": "ordino",
                "name": "Ordino",
                "pk": "AD__ordino"
            },
            {
                "_id": "sant_julia_de_loria",
                "code": "sant_julia_de_loria",
                "name": "Sant Julia de Loria",
                "pk": "AD__sant_julia_de_loria"
            }
        ],
        "pk": "AD"
    },
    {
        "_id": "AO",
        "code": "AO",
        "name": "Angola",
        "states": [
            {
                "_id": "bengo",
                "code": "bengo",
                "name": "Bengo",
                "pk": "AO__bengo"
            },
            {
                "_id": "benguela",
                "code": "benguela",
                "name": "Benguela",
                "pk": "AO__benguela"
            },
            {
                "_id": "bie",
                "code": "bie",
                "name": "Bie",
                "pk": "AO__bie"
            },
            {
                "_id": "cabinda",
                "code": "cabinda",
                "name": "Cabinda",
                "pk": "AO__cabinda"
            },
            {
                "_id": "cunene",
                "code": "cunene",
                "name": "Cunene",
                "pk": "AO__cunene"
            },
            {
                "_id": "huambo",
                "code": "huambo",
                "name": "Huambo",
                "pk": "AO__huambo"
            },
            {
                "_id": "huila",
                "code": "huila",
                "name": "Huila",
                "pk": "AO__huila"
            },
            {
                "_id": "kuando-kubango",
                "code": "kuando-kubango",
                "name": "Kuando-Kubango",
                "pk": "AO__kuando-kubango"
            },
            {
                "_id": "kwanza_norte",
                "code": "kwanza_norte",
                "name": "Kwanza Norte",
                "pk": "AO__kwanza_norte"
            },
            {
                "_id": "kwanza_sul",
                "code": "kwanza_sul",
                "name": "Kwanza Sul",
                "pk": "AO__kwanza_sul"
            },
            {
                "_id": "luanda",
                "code": "luanda",
                "name": "Luanda",
                "pk": "AO__luanda"
            },
            {
                "_id": "lunda_norte",
                "code": "lunda_norte",
                "name": "Lunda Norte",
                "pk": "AO__lunda_norte"
            },
            {
                "_id": "lunda_sul",
                "code": "lunda_sul",
                "name": "Lunda Sul",
                "pk": "AO__lunda_sul"
            },
            {
                "_id": "malanje",
                "code": "malanje",
                "name": "Malanje",
                "pk": "AO__malanje"
            },
            {
                "_id": "moxico",
                "code": "moxico",
                "name": "Moxico",
                "pk": "AO__moxico"
            },
            {
                "_id": "namibe",
                "code": "namibe",
                "name": "Namibe",
                "pk": "AO__namibe"
            },
            {
                "_id": "uige",
                "code": "uige",
                "name": "Uige",
                "pk": "AO__uige"
            },
            {
                "_id": "zaire",
                "code": "zaire",
                "name": "Zaire",
                "pk": "AO__zaire"
            }
        ],
        "pk": "AO"
    },
    {
        "_id": "AI",
        "code": "AI",
        "name": "Anguilla",
        "states": [
            {
                "_id": "other_provinces",
                "code": "other_provinces",
                "name": "Other Provinces",
                "pk": "AI__other_provinces"
            }
        ],
        "pk": "AI"
    },
    {
        "_id": "AQ",
        "code": "AQ",
        "name": "Antarctique",
        "states": [
            {
                "_id": "sector_claimed_by_argentina/ch",
                "code": "sector_claimed_by_argentina/ch",
                "name": "Sector claimed by Argentina/Ch",
                "pk": "AQ__sector_claimed_by_argentina/ch"
            },
            {
                "_id": "sector_claimed_by_argentina/uk",
                "code": "sector_claimed_by_argentina/uk",
                "name": "Sector claimed by Argentina/UK",
                "pk": "AQ__sector_claimed_by_argentina/uk"
            },
            {
                "_id": "sector_claimed_by_australia",
                "code": "sector_claimed_by_australia",
                "name": "Sector claimed by Australia",
                "pk": "AQ__sector_claimed_by_australia"
            },
            {
                "_id": "sector_claimed_by_france",
                "code": "sector_claimed_by_france",
                "name": "Sector claimed by France",
                "pk": "AQ__sector_claimed_by_france"
            },
            {
                "_id": "sector_claimed_by_new_zealand",
                "code": "sector_claimed_by_new_zealand",
                "name": "Sector claimed by New Zealand",
                "pk": "AQ__sector_claimed_by_new_zealand"
            },
            {
                "_id": "sector_claimed_by_norway",
                "code": "sector_claimed_by_norway",
                "name": "Sector claimed by Norway",
                "pk": "AQ__sector_claimed_by_norway"
            },
            {
                "_id": "unclaimed_sector",
                "code": "unclaimed_sector",
                "name": "Unclaimed Sector",
                "pk": "AQ__unclaimed_sector"
            }
        ],
        "pk": "AQ"
    },
    {
        "_id": "AG",
        "code": "AG",
        "name": "Antigua-et-Barbuda",
        "states": [
            {
                "_id": "barbuda",
                "code": "barbuda",
                "name": "Barbuda",
                "pk": "AG__barbuda"
            },
            {
                "_id": "saint_george",
                "code": "saint_george",
                "name": "Saint George",
                "pk": "AG__saint_george"
            },
            {
                "_id": "saint_john",
                "code": "saint_john",
                "name": "Saint John",
                "pk": "AG__saint_john"
            },
            {
                "_id": "saint_mary",
                "code": "saint_mary",
                "name": "Saint Mary",
                "pk": "AG__saint_mary"
            },
            {
                "_id": "saint_paul",
                "code": "saint_paul",
                "name": "Saint Paul",
                "pk": "AG__saint_paul"
            },
            {
                "_id": "saint_peter",
                "code": "saint_peter",
                "name": "Saint Peter",
                "pk": "AG__saint_peter"
            },
            {
                "_id": "saint_philip",
                "code": "saint_philip",
                "name": "Saint Philip",
                "pk": "AG__saint_philip"
            }
        ],
        "pk": "AG"
    },
    {
        "_id": "AR",
        "code": "AR",
        "name": "Argentine",
        "states": [
            {
                "_id": "buenos_aires",
                "code": "buenos_aires",
                "name": "Buenos Aires",
                "pk": "AR__buenos_aires"
            },
            {
                "_id": "catamarca",
                "code": "catamarca",
                "name": "Catamarca",
                "pk": "AR__catamarca"
            },
            {
                "_id": "chaco",
                "code": "chaco",
                "name": "Chaco",
                "pk": "AR__chaco"
            },
            {
                "_id": "chubut",
                "code": "chubut",
                "name": "Chubut",
                "pk": "AR__chubut"
            },
            {
                "_id": "cordoba",
                "code": "cordoba",
                "name": "Cordoba",
                "pk": "AR__cordoba"
            },
            {
                "_id": "corrientes",
                "code": "corrientes",
                "name": "Corrientes",
                "pk": "AR__corrientes"
            },
            {
                "_id": "distrito_federal",
                "code": "distrito_federal",
                "name": "Distrito Federal",
                "pk": "AR__distrito_federal"
            },
            {
                "_id": "entre_rios",
                "code": "entre_rios",
                "name": "Entre Rios",
                "pk": "AR__entre_rios"
            },
            {
                "_id": "formosa",
                "code": "formosa",
                "name": "Formosa",
                "pk": "AR__formosa"
            },
            {
                "_id": "jujuy",
                "code": "jujuy",
                "name": "Jujuy",
                "pk": "AR__jujuy"
            },
            {
                "_id": "la_pampa",
                "code": "la_pampa",
                "name": "La Pampa",
                "pk": "AR__la_pampa"
            },
            {
                "_id": "la_rioja",
                "code": "la_rioja",
                "name": "La Rioja",
                "pk": "AR__la_rioja"
            },
            {
                "_id": "mendoza",
                "code": "mendoza",
                "name": "Mendoza",
                "pk": "AR__mendoza"
            },
            {
                "_id": "misiones",
                "code": "misiones",
                "name": "Misiones",
                "pk": "AR__misiones"
            },
            {
                "_id": "neuquen",
                "code": "neuquen",
                "name": "Neuquen",
                "pk": "AR__neuquen"
            },
            {
                "_id": "rio_negro",
                "code": "rio_negro",
                "name": "Rio Negro",
                "pk": "AR__rio_negro"
            },
            {
                "_id": "salta",
                "code": "salta",
                "name": "Salta",
                "pk": "AR__salta"
            },
            {
                "_id": "san_juan",
                "code": "san_juan",
                "name": "San Juan",
                "pk": "AR__san_juan"
            },
            {
                "_id": "san_luis",
                "code": "san_luis",
                "name": "San Luis",
                "pk": "AR__san_luis"
            },
            {
                "_id": "santa_cruz",
                "code": "santa_cruz",
                "name": "Santa Cruz",
                "pk": "AR__santa_cruz"
            },
            {
                "_id": "santa_fe",
                "code": "santa_fe",
                "name": "Santa Fe",
                "pk": "AR__santa_fe"
            },
            {
                "_id": "santiago_del_estero",
                "code": "santiago_del_estero",
                "name": "Santiago del Estero",
                "pk": "AR__santiago_del_estero"
            },
            {
                "_id": "tierra_del_fuego",
                "code": "tierra_del_fuego",
                "name": "Tierra del Fuego",
                "pk": "AR__tierra_del_fuego"
            },
            {
                "_id": "tucuman",
                "code": "tucuman",
                "name": "Tucuman",
                "pk": "AR__tucuman"
            }
        ],
        "pk": "AR"
    },
    {
        "_id": "AM",
        "code": "AM",
        "name": "Arménie",
        "states": [
            {
                "_id": "aragatsotn",
                "code": "aragatsotn",
                "name": "Aragatsotn",
                "pk": "AM__aragatsotn"
            },
            {
                "_id": "ararat",
                "code": "ararat",
                "name": "Ararat",
                "pk": "AM__ararat"
            },
            {
                "_id": "armavir",
                "code": "armavir",
                "name": "Armavir",
                "pk": "AM__armavir"
            },
            {
                "_id": "gegharkunik",
                "code": "gegharkunik",
                "name": "Gegharkunik",
                "pk": "AM__gegharkunik"
            },
            {
                "_id": "kotaik",
                "code": "kotaik",
                "name": "Kotaik",
                "pk": "AM__kotaik"
            },
            {
                "_id": "lori",
                "code": "lori",
                "name": "Lori",
                "pk": "AM__lori"
            },
            {
                "_id": "shirak",
                "code": "shirak",
                "name": "Shirak",
                "pk": "AM__shirak"
            },
            {
                "_id": "stepanakert",
                "code": "stepanakert",
                "name": "Stepanakert",
                "pk": "AM__stepanakert"
            },
            {
                "_id": "syunik",
                "code": "syunik",
                "name": "Syunik",
                "pk": "AM__syunik"
            },
            {
                "_id": "tavush",
                "code": "tavush",
                "name": "Tavush",
                "pk": "AM__tavush"
            },
            {
                "_id": "vayots_dzor",
                "code": "vayots_dzor",
                "name": "Vayots Dzor",
                "pk": "AM__vayots_dzor"
            },
            {
                "_id": "yerevan",
                "code": "yerevan",
                "name": "Yerevan",
                "pk": "AM__yerevan"
            }
        ],
        "pk": "AM"
    },
    {
        "_id": "AW",
        "code": "AW",
        "name": "Aruba",
        "states": [
            {
                "_id": "aruba",
                "code": "aruba",
                "name": "Aruba",
                "pk": "AW__aruba"
            }
        ],
        "pk": "AW"
    },
    {
        "_id": "AU",
        "code": "AU",
        "name": "Australie",
        "states": [
            {
                "_id": "auckland",
                "code": "auckland",
                "name": "Auckland",
                "pk": "AU__auckland"
            },
            {
                "_id": "australian_capital_territory",
                "code": "australian_capital_territory",
                "name": "Australian Capital Territory",
                "pk": "AU__australian_capital_territory"
            },
            {
                "_id": "balgowlah",
                "code": "balgowlah",
                "name": "Balgowlah",
                "pk": "AU__balgowlah"
            },
            {
                "_id": "balmain",
                "code": "balmain",
                "name": "Balmain",
                "pk": "AU__balmain"
            },
            {
                "_id": "bankstown",
                "code": "bankstown",
                "name": "Bankstown",
                "pk": "AU__bankstown"
            },
            {
                "_id": "baulkham_hills",
                "code": "baulkham_hills",
                "name": "Baulkham Hills",
                "pk": "AU__baulkham_hills"
            },
            {
                "_id": "bonnet_bay",
                "code": "bonnet_bay",
                "name": "Bonnet Bay",
                "pk": "AU__bonnet_bay"
            },
            {
                "_id": "camberwell",
                "code": "camberwell",
                "name": "Camberwell",
                "pk": "AU__camberwell"
            },
            {
                "_id": "carole_park",
                "code": "carole_park",
                "name": "Carole Park",
                "pk": "AU__carole_park"
            },
            {
                "_id": "castle_hill",
                "code": "castle_hill",
                "name": "Castle Hill",
                "pk": "AU__castle_hill"
            },
            {
                "_id": "caulfield",
                "code": "caulfield",
                "name": "Caulfield",
                "pk": "AU__caulfield"
            },
            {
                "_id": "chatswood",
                "code": "chatswood",
                "name": "Chatswood",
                "pk": "AU__chatswood"
            },
            {
                "_id": "cheltenham",
                "code": "cheltenham",
                "name": "Cheltenham",
                "pk": "AU__cheltenham"
            },
            {
                "_id": "cherrybrook",
                "code": "cherrybrook",
                "name": "Cherrybrook",
                "pk": "AU__cherrybrook"
            },
            {
                "_id": "clayton",
                "code": "clayton",
                "name": "Clayton",
                "pk": "AU__clayton"
            },
            {
                "_id": "collingwood",
                "code": "collingwood",
                "name": "Collingwood",
                "pk": "AU__collingwood"
            },
            {
                "_id": "frenchs_forest",
                "code": "frenchs_forest",
                "name": "Frenchs Forest",
                "pk": "AU__frenchs_forest"
            },
            {
                "_id": "hawthorn",
                "code": "hawthorn",
                "name": "Hawthorn",
                "pk": "AU__hawthorn"
            },
            {
                "_id": "jannnali",
                "code": "jannnali",
                "name": "Jannnali",
                "pk": "AU__jannnali"
            },
            {
                "_id": "knoxfield",
                "code": "knoxfield",
                "name": "Knoxfield",
                "pk": "AU__knoxfield"
            },
            {
                "_id": "melbourne",
                "code": "melbourne",
                "name": "Melbourne",
                "pk": "AU__melbourne"
            },
            {
                "_id": "new_south_wales",
                "code": "new_south_wales",
                "name": "New South Wales",
                "pk": "AU__new_south_wales"
            },
            {
                "_id": "northern_territory",
                "code": "northern_territory",
                "name": "Northern Territory",
                "pk": "AU__northern_territory"
            },
            {
                "_id": "perth",
                "code": "perth",
                "name": "Perth",
                "pk": "AU__perth"
            },
            {
                "_id": "queensland",
                "code": "queensland",
                "name": "Queensland",
                "pk": "AU__queensland"
            },
            {
                "_id": "south_australia",
                "code": "south_australia",
                "name": "South Australia",
                "pk": "AU__south_australia"
            },
            {
                "_id": "tasmania",
                "code": "tasmania",
                "name": "Tasmania",
                "pk": "AU__tasmania"
            },
            {
                "_id": "templestowe",
                "code": "templestowe",
                "name": "Templestowe",
                "pk": "AU__templestowe"
            },
            {
                "_id": "victoria",
                "code": "victoria",
                "name": "Victoria",
                "pk": "AU__victoria"
            },
            {
                "_id": "werribee_south",
                "code": "werribee_south",
                "name": "Werribee south",
                "pk": "AU__werribee_south"
            },
            {
                "_id": "western_australia",
                "code": "western_australia",
                "name": "Western Australia",
                "pk": "AU__western_australia"
            },
            {
                "_id": "wheeler",
                "code": "wheeler",
                "name": "Wheeler",
                "pk": "AU__wheeler"
            }
        ],
        "only_shipping": true,
        "pk": "AU"
    },
    {
        "_id": "AT",
        "code": "AT",
        "name": "Autriche",
        "states": [
            {
                "_id": "bundesland_salzburg",
                "code": "bundesland_salzburg",
                "name": "Bundesland Salzburg",
                "pk": "AT__bundesland_salzburg"
            },
            {
                "_id": "bundesland_steiermark",
                "code": "bundesland_steiermark",
                "name": "Bundesland Steiermark",
                "pk": "AT__bundesland_steiermark"
            },
            {
                "_id": "bundesland_tirol",
                "code": "bundesland_tirol",
                "name": "Bundesland Tirol",
                "pk": "AT__bundesland_tirol"
            },
            {
                "_id": "burgenland",
                "code": "burgenland",
                "name": "Burgenland",
                "pk": "AT__burgenland"
            },
            {
                "_id": "carinthia",
                "code": "carinthia",
                "name": "Carinthia",
                "pk": "AT__carinthia"
            },
            {
                "_id": "karnten",
                "code": "karnten",
                "name": "Karnten",
                "pk": "AT__karnten"
            },
            {
                "_id": "liezen",
                "code": "liezen",
                "name": "Liezen",
                "pk": "AT__liezen"
            },
            {
                "_id": "lower_austria",
                "code": "lower_austria",
                "name": "Lower Austria",
                "pk": "AT__lower_austria"
            },
            {
                "_id": "niederosterreich",
                "code": "niederosterreich",
                "name": "Niederosterreich",
                "pk": "AT__niederosterreich"
            },
            {
                "_id": "oberosterreich",
                "code": "oberosterreich",
                "name": "Oberosterreich",
                "pk": "AT__oberosterreich"
            },
            {
                "_id": "salzburg",
                "code": "salzburg",
                "name": "Salzburg",
                "pk": "AT__salzburg"
            },
            {
                "_id": "schleswig-holstein",
                "code": "schleswig-holstein",
                "name": "Schleswig-Holstein",
                "pk": "AT__schleswig-holstein"
            },
            {
                "_id": "steiermark",
                "code": "steiermark",
                "name": "Steiermark",
                "pk": "AT__steiermark"
            },
            {
                "_id": "styria",
                "code": "styria",
                "name": "Styria",
                "pk": "AT__styria"
            },
            {
                "_id": "tirol",
                "code": "tirol",
                "name": "Tirol",
                "pk": "AT__tirol"
            },
            {
                "_id": "upper_austria",
                "code": "upper_austria",
                "name": "Upper Austria",
                "pk": "AT__upper_austria"
            },
            {
                "_id": "vorarlberg",
                "code": "vorarlberg",
                "name": "Vorarlberg",
                "pk": "AT__vorarlberg"
            },
            {
                "_id": "wien",
                "code": "wien",
                "name": "Wien",
                "pk": "AT__wien"
            }
        ],
        "pk": "AT"
    },
    {
        "_id": "AZ",
        "code": "AZ",
        "name": "Azerbaïdjan",
        "states": [
            {
                "_id": "abseron",
                "code": "abseron",
                "name": "Abseron",
                "pk": "AZ__abseron"
            },
            {
                "_id": "baki_sahari",
                "code": "baki_sahari",
                "name": "Baki Sahari",
                "pk": "AZ__baki_sahari"
            },
            {
                "_id": "ganca",
                "code": "ganca",
                "name": "Ganca",
                "pk": "AZ__ganca"
            },
            {
                "_id": "ganja",
                "code": "ganja",
                "name": "Ganja",
                "pk": "AZ__ganja"
            },
            {
                "_id": "kalbacar",
                "code": "kalbacar",
                "name": "Kalbacar",
                "pk": "AZ__kalbacar"
            },
            {
                "_id": "lankaran",
                "code": "lankaran",
                "name": "Lankaran",
                "pk": "AZ__lankaran"
            },
            {
                "_id": "mil-qarabax",
                "code": "mil-qarabax",
                "name": "Mil-Qarabax",
                "pk": "AZ__mil-qarabax"
            },
            {
                "_id": "mugan-salyan",
                "code": "mugan-salyan",
                "name": "Mugan-Salyan",
                "pk": "AZ__mugan-salyan"
            },
            {
                "_id": "nagorni-qarabax",
                "code": "nagorni-qarabax",
                "name": "Nagorni-Qarabax",
                "pk": "AZ__nagorni-qarabax"
            },
            {
                "_id": "naxcivan",
                "code": "naxcivan",
                "name": "Naxcivan",
                "pk": "AZ__naxcivan"
            },
            {
                "_id": "priaraks",
                "code": "priaraks",
                "name": "Priaraks",
                "pk": "AZ__priaraks"
            },
            {
                "_id": "qazax",
                "code": "qazax",
                "name": "Qazax",
                "pk": "AZ__qazax"
            },
            {
                "_id": "saki",
                "code": "saki",
                "name": "Saki",
                "pk": "AZ__saki"
            },
            {
                "_id": "sirvan",
                "code": "sirvan",
                "name": "Sirvan",
                "pk": "AZ__sirvan"
            },
            {
                "_id": "xacmaz",
                "code": "xacmaz",
                "name": "Xacmaz",
                "pk": "AZ__xacmaz"
            }
        ],
        "pk": "AZ"
    },
    {
        "_id": "BS",
        "code": "BS",
        "name": "Bahamas",
        "states": [
            {
                "_id": "abaco",
                "code": "abaco",
                "name": "Abaco",
                "pk": "BS__abaco"
            },
            {
                "_id": "acklins_island",
                "code": "acklins_island",
                "name": "Acklins Island",
                "pk": "BS__acklins_island"
            },
            {
                "_id": "andros",
                "code": "andros",
                "name": "Andros",
                "pk": "BS__andros"
            },
            {
                "_id": "berry_islands",
                "code": "berry_islands",
                "name": "Berry Islands",
                "pk": "BS__berry_islands"
            },
            {
                "_id": "biminis",
                "code": "biminis",
                "name": "Biminis",
                "pk": "BS__biminis"
            },
            {
                "_id": "cat_island",
                "code": "cat_island",
                "name": "Cat Island",
                "pk": "BS__cat_island"
            },
            {
                "_id": "crooked_island",
                "code": "crooked_island",
                "name": "Crooked Island",
                "pk": "BS__crooked_island"
            },
            {
                "_id": "eleuthera",
                "code": "eleuthera",
                "name": "Eleuthera",
                "pk": "BS__eleuthera"
            },
            {
                "_id": "exuma_and_cays",
                "code": "exuma_and_cays",
                "name": "Exuma and Cays",
                "pk": "BS__exuma_and_cays"
            },
            {
                "_id": "grand_bahama",
                "code": "grand_bahama",
                "name": "Grand Bahama",
                "pk": "BS__grand_bahama"
            },
            {
                "_id": "inagua_islands",
                "code": "inagua_islands",
                "name": "Inagua Islands",
                "pk": "BS__inagua_islands"
            },
            {
                "_id": "long_island",
                "code": "long_island",
                "name": "Long Island",
                "pk": "BS__long_island"
            },
            {
                "_id": "mayaguana",
                "code": "mayaguana",
                "name": "Mayaguana",
                "pk": "BS__mayaguana"
            },
            {
                "_id": "new_providence",
                "code": "new_providence",
                "name": "New Providence",
                "pk": "BS__new_providence"
            },
            {
                "_id": "ragged_island",
                "code": "ragged_island",
                "name": "Ragged Island",
                "pk": "BS__ragged_island"
            },
            {
                "_id": "rum_cay",
                "code": "rum_cay",
                "name": "Rum Cay",
                "pk": "BS__rum_cay"
            },
            {
                "_id": "san_salvador",
                "code": "san_salvador",
                "name": "San Salvador",
                "pk": "BS__san_salvador"
            }
        ],
        "pk": "BS"
    },
    {
        "_id": "BH",
        "code": "BH",
        "name": "Bahreïn",
        "states": [
            {
                "_id": "isa",
                "code": "isa",
                "name": "Isa",
                "pk": "BH__isa"
            },
            {
                "_id": "badiyah",
                "code": "badiyah",
                "name": "Badiyah",
                "pk": "BH__badiyah"
            },
            {
                "_id": "hidd",
                "code": "hidd",
                "name": "Hidd",
                "pk": "BH__hidd"
            },
            {
                "_id": "jidd_hafs",
                "code": "jidd_hafs",
                "name": "Jidd Hafs",
                "pk": "BH__jidd_hafs"
            },
            {
                "_id": "mahama",
                "code": "mahama",
                "name": "Mahama",
                "pk": "BH__mahama"
            },
            {
                "_id": "manama",
                "code": "manama",
                "name": "Manama",
                "pk": "BH__manama"
            },
            {
                "_id": "sitrah",
                "code": "sitrah",
                "name": "Sitrah",
                "pk": "BH__sitrah"
            },
            {
                "_id": "al-manamah",
                "code": "al-manamah",
                "name": "al-Manamah",
                "pk": "BH__al-manamah"
            },
            {
                "_id": "al-muharraq",
                "code": "al-muharraq",
                "name": "al-Muharraq",
                "pk": "BH__al-muharraq"
            },
            {
                "_id": "ar-rifaa",
                "code": "ar-rifaa",
                "name": "ar-Rifaa",
                "pk": "BH__ar-rifaa"
            }
        ],
        "pk": "BH"
    },
    {
        "_id": "BD",
        "code": "BD",
        "name": "Bangladesh",
        "states": [
            {
                "_id": "bagar_hat",
                "code": "bagar_hat",
                "name": "Bagar Hat",
                "pk": "BD__bagar_hat"
            },
            {
                "_id": "bandarban",
                "code": "bandarban",
                "name": "Bandarban",
                "pk": "BD__bandarban"
            },
            {
                "_id": "barguna",
                "code": "barguna",
                "name": "Barguna",
                "pk": "BD__barguna"
            },
            {
                "_id": "barisal",
                "code": "barisal",
                "name": "Barisal",
                "pk": "BD__barisal"
            },
            {
                "_id": "bhola",
                "code": "bhola",
                "name": "Bhola",
                "pk": "BD__bhola"
            },
            {
                "_id": "bogora",
                "code": "bogora",
                "name": "Bogora",
                "pk": "BD__bogora"
            },
            {
                "_id": "brahman_bariya",
                "code": "brahman_bariya",
                "name": "Brahman Bariya",
                "pk": "BD__brahman_bariya"
            },
            {
                "_id": "chandpur",
                "code": "chandpur",
                "name": "Chandpur",
                "pk": "BD__chandpur"
            },
            {
                "_id": "chattagam",
                "code": "chattagam",
                "name": "Chattagam",
                "pk": "BD__chattagam"
            },
            {
                "_id": "chittagong_division",
                "code": "chittagong_division",
                "name": "Chittagong Division",
                "pk": "BD__chittagong_division"
            },
            {
                "_id": "chuadanga",
                "code": "chuadanga",
                "name": "Chuadanga",
                "pk": "BD__chuadanga"
            },
            {
                "_id": "dhaka",
                "code": "dhaka",
                "name": "Dhaka",
                "pk": "BD__dhaka"
            },
            {
                "_id": "dinajpur",
                "code": "dinajpur",
                "name": "Dinajpur",
                "pk": "BD__dinajpur"
            },
            {
                "_id": "faridpur",
                "code": "faridpur",
                "name": "Faridpur",
                "pk": "BD__faridpur"
            },
            {
                "_id": "feni",
                "code": "feni",
                "name": "Feni",
                "pk": "BD__feni"
            },
            {
                "_id": "gaybanda",
                "code": "gaybanda",
                "name": "Gaybanda",
                "pk": "BD__gaybanda"
            },
            {
                "_id": "gazipur",
                "code": "gazipur",
                "name": "Gazipur",
                "pk": "BD__gazipur"
            },
            {
                "_id": "gopalganj",
                "code": "gopalganj",
                "name": "Gopalganj",
                "pk": "BD__gopalganj"
            },
            {
                "_id": "habiganj",
                "code": "habiganj",
                "name": "Habiganj",
                "pk": "BD__habiganj"
            },
            {
                "_id": "jaipur_hat",
                "code": "jaipur_hat",
                "name": "Jaipur Hat",
                "pk": "BD__jaipur_hat"
            },
            {
                "_id": "jamalpur",
                "code": "jamalpur",
                "name": "Jamalpur",
                "pk": "BD__jamalpur"
            },
            {
                "_id": "jessor",
                "code": "jessor",
                "name": "Jessor",
                "pk": "BD__jessor"
            },
            {
                "_id": "jhalakati",
                "code": "jhalakati",
                "name": "Jhalakati",
                "pk": "BD__jhalakati"
            },
            {
                "_id": "jhanaydah",
                "code": "jhanaydah",
                "name": "Jhanaydah",
                "pk": "BD__jhanaydah"
            },
            {
                "_id": "khagrachhari",
                "code": "khagrachhari",
                "name": "Khagrachhari",
                "pk": "BD__khagrachhari"
            },
            {
                "_id": "khulna",
                "code": "khulna",
                "name": "Khulna",
                "pk": "BD__khulna"
            },
            {
                "_id": "kishorganj",
                "code": "kishorganj",
                "name": "Kishorganj",
                "pk": "BD__kishorganj"
            },
            {
                "_id": "koks_bazar",
                "code": "koks_bazar",
                "name": "Koks Bazar",
                "pk": "BD__koks_bazar"
            },
            {
                "_id": "komilla",
                "code": "komilla",
                "name": "Komilla",
                "pk": "BD__komilla"
            },
            {
                "_id": "kurigram",
                "code": "kurigram",
                "name": "Kurigram",
                "pk": "BD__kurigram"
            },
            {
                "_id": "kushtiya",
                "code": "kushtiya",
                "name": "Kushtiya",
                "pk": "BD__kushtiya"
            },
            {
                "_id": "lakshmipur",
                "code": "lakshmipur",
                "name": "Lakshmipur",
                "pk": "BD__lakshmipur"
            },
            {
                "_id": "lalmanir_hat",
                "code": "lalmanir_hat",
                "name": "Lalmanir Hat",
                "pk": "BD__lalmanir_hat"
            },
            {
                "_id": "madaripur",
                "code": "madaripur",
                "name": "Madaripur",
                "pk": "BD__madaripur"
            },
            {
                "_id": "magura",
                "code": "magura",
                "name": "Magura",
                "pk": "BD__magura"
            },
            {
                "_id": "maimansingh",
                "code": "maimansingh",
                "name": "Maimansingh",
                "pk": "BD__maimansingh"
            },
            {
                "_id": "manikganj",
                "code": "manikganj",
                "name": "Manikganj",
                "pk": "BD__manikganj"
            },
            {
                "_id": "maulvi_bazar",
                "code": "maulvi_bazar",
                "name": "Maulvi Bazar",
                "pk": "BD__maulvi_bazar"
            },
            {
                "_id": "meherpur",
                "code": "meherpur",
                "name": "Meherpur",
                "pk": "BD__meherpur"
            },
            {
                "_id": "munshiganj",
                "code": "munshiganj",
                "name": "Munshiganj",
                "pk": "BD__munshiganj"
            },
            {
                "_id": "naral",
                "code": "naral",
                "name": "Naral",
                "pk": "BD__naral"
            },
            {
                "_id": "narayanganj",
                "code": "narayanganj",
                "name": "Narayanganj",
                "pk": "BD__narayanganj"
            },
            {
                "_id": "narsingdi",
                "code": "narsingdi",
                "name": "Narsingdi",
                "pk": "BD__narsingdi"
            },
            {
                "_id": "nator",
                "code": "nator",
                "name": "Nator",
                "pk": "BD__nator"
            },
            {
                "_id": "naugaon",
                "code": "naugaon",
                "name": "Naugaon",
                "pk": "BD__naugaon"
            },
            {
                "_id": "nawabganj",
                "code": "nawabganj",
                "name": "Nawabganj",
                "pk": "BD__nawabganj"
            },
            {
                "_id": "netrakona",
                "code": "netrakona",
                "name": "Netrakona",
                "pk": "BD__netrakona"
            },
            {
                "_id": "nilphamari",
                "code": "nilphamari",
                "name": "Nilphamari",
                "pk": "BD__nilphamari"
            },
            {
                "_id": "noakhali",
                "code": "noakhali",
                "name": "Noakhali",
                "pk": "BD__noakhali"
            },
            {
                "_id": "pabna",
                "code": "pabna",
                "name": "Pabna",
                "pk": "BD__pabna"
            },
            {
                "_id": "panchagarh",
                "code": "panchagarh",
                "name": "Panchagarh",
                "pk": "BD__panchagarh"
            },
            {
                "_id": "patuakhali",
                "code": "patuakhali",
                "name": "Patuakhali",
                "pk": "BD__patuakhali"
            },
            {
                "_id": "pirojpur",
                "code": "pirojpur",
                "name": "Pirojpur",
                "pk": "BD__pirojpur"
            },
            {
                "_id": "rajbari",
                "code": "rajbari",
                "name": "Rajbari",
                "pk": "BD__rajbari"
            },
            {
                "_id": "rajshahi",
                "code": "rajshahi",
                "name": "Rajshahi",
                "pk": "BD__rajshahi"
            },
            {
                "_id": "rangamati",
                "code": "rangamati",
                "name": "Rangamati",
                "pk": "BD__rangamati"
            },
            {
                "_id": "rangpur",
                "code": "rangpur",
                "name": "Rangpur",
                "pk": "BD__rangpur"
            },
            {
                "_id": "satkhira",
                "code": "satkhira",
                "name": "Satkhira",
                "pk": "BD__satkhira"
            },
            {
                "_id": "shariatpur",
                "code": "shariatpur",
                "name": "Shariatpur",
                "pk": "BD__shariatpur"
            },
            {
                "_id": "sherpur",
                "code": "sherpur",
                "name": "Sherpur",
                "pk": "BD__sherpur"
            },
            {
                "_id": "silhat",
                "code": "silhat",
                "name": "Silhat",
                "pk": "BD__silhat"
            },
            {
                "_id": "sirajganj",
                "code": "sirajganj",
                "name": "Sirajganj",
                "pk": "BD__sirajganj"
            },
            {
                "_id": "sunamganj",
                "code": "sunamganj",
                "name": "Sunamganj",
                "pk": "BD__sunamganj"
            },
            {
                "_id": "tangayal",
                "code": "tangayal",
                "name": "Tangayal",
                "pk": "BD__tangayal"
            },
            {
                "_id": "thakurgaon",
                "code": "thakurgaon",
                "name": "Thakurgaon",
                "pk": "BD__thakurgaon"
            }
        ],
        "pk": "BD"
    },
    {
        "_id": "BB",
        "code": "BB",
        "name": "Barbade",
        "states": [
            {
                "_id": "christ_church",
                "code": "christ_church",
                "name": "Christ Church",
                "pk": "BB__christ_church"
            },
            {
                "_id": "saint_andrew",
                "code": "saint_andrew",
                "name": "Saint Andrew",
                "pk": "BB__saint_andrew"
            },
            {
                "_id": "saint_george",
                "code": "saint_george",
                "name": "Saint George",
                "pk": "BB__saint_george"
            },
            {
                "_id": "saint_james",
                "code": "saint_james",
                "name": "Saint James",
                "pk": "BB__saint_james"
            },
            {
                "_id": "saint_john",
                "code": "saint_john",
                "name": "Saint John",
                "pk": "BB__saint_john"
            },
            {
                "_id": "saint_joseph",
                "code": "saint_joseph",
                "name": "Saint Joseph",
                "pk": "BB__saint_joseph"
            },
            {
                "_id": "saint_lucy",
                "code": "saint_lucy",
                "name": "Saint Lucy",
                "pk": "BB__saint_lucy"
            },
            {
                "_id": "saint_michael",
                "code": "saint_michael",
                "name": "Saint Michael",
                "pk": "BB__saint_michael"
            },
            {
                "_id": "saint_peter",
                "code": "saint_peter",
                "name": "Saint Peter",
                "pk": "BB__saint_peter"
            },
            {
                "_id": "saint_philip",
                "code": "saint_philip",
                "name": "Saint Philip",
                "pk": "BB__saint_philip"
            },
            {
                "_id": "saint_thomas",
                "code": "saint_thomas",
                "name": "Saint Thomas",
                "pk": "BB__saint_thomas"
            }
        ],
        "pk": "BB"
    },
    {
        "_id": "BY",
        "code": "BY",
        "name": "Biélorussie",
        "states": [
            {
                "_id": "brest",
                "code": "brest",
                "name": "Brest",
                "pk": "BY__brest"
            },
            {
                "_id": "homjel",
                "code": "homjel",
                "name": "Homjel",
                "pk": "BY__homjel"
            },
            {
                "_id": "hrodna",
                "code": "hrodna",
                "name": "Hrodna",
                "pk": "BY__hrodna"
            },
            {
                "_id": "mahiljow",
                "code": "mahiljow",
                "name": "Mahiljow",
                "pk": "BY__mahiljow"
            },
            {
                "_id": "mahilyowskaya_voblasts",
                "code": "mahilyowskaya_voblasts",
                "name": "Mahilyowskaya Voblasts",
                "pk": "BY__mahilyowskaya_voblasts"
            },
            {
                "_id": "minsk",
                "code": "minsk",
                "name": "Minsk",
                "pk": "BY__minsk"
            },
            {
                "_id": "minskaja_voblasts",
                "code": "minskaja_voblasts",
                "name": "Minskaja Voblasts",
                "pk": "BY__minskaja_voblasts"
            },
            {
                "_id": "petrik",
                "code": "petrik",
                "name": "Petrik",
                "pk": "BY__petrik"
            },
            {
                "_id": "vicebsk",
                "code": "vicebsk",
                "name": "Vicebsk",
                "pk": "BY__vicebsk"
            }
        ],
        "pk": "BY"
    },
    {
        "_id": "BE",
        "code": "BE",
        "name": "Belgique",
        "states": [
            {
                "_id": "antwerpen",
                "code": "antwerpen",
                "name": "Antwerpen",
                "pk": "BE__antwerpen"
            },
            {
                "_id": "berchem",
                "code": "berchem",
                "name": "Berchem",
                "pk": "BE__berchem"
            },
            {
                "_id": "brabant",
                "code": "brabant",
                "name": "Brabant",
                "pk": "BE__brabant"
            },
            {
                "_id": "brabant_wallon",
                "code": "brabant_wallon",
                "name": "Brabant Wallon",
                "pk": "BE__brabant_wallon"
            },
            {
                "_id": "brussel",
                "code": "brussel",
                "name": "Brussel",
                "pk": "BE__brussel"
            },
            {
                "_id": "east_flanders",
                "code": "east_flanders",
                "name": "East Flanders",
                "pk": "BE__east_flanders"
            },
            {
                "_id": "hainaut",
                "code": "hainaut",
                "name": "Hainaut",
                "pk": "BE__hainaut"
            },
            {
                "_id": "liege",
                "code": "liege",
                "name": "Liege",
                "pk": "BE__liege"
            },
            {
                "_id": "limburg",
                "code": "limburg",
                "name": "Limburg",
                "pk": "BE__limburg"
            },
            {
                "_id": "luxembourg",
                "code": "luxembourg",
                "name": "Luxembourg",
                "pk": "BE__luxembourg"
            },
            {
                "_id": "namur",
                "code": "namur",
                "name": "Namur",
                "pk": "BE__namur"
            },
            {
                "_id": "ontario",
                "code": "ontario",
                "name": "Ontario",
                "pk": "BE__ontario"
            },
            {
                "_id": "oost-vlaanderen",
                "code": "oost-vlaanderen",
                "name": "Oost-Vlaanderen",
                "pk": "BE__oost-vlaanderen"
            },
            {
                "_id": "provincie_brabant",
                "code": "provincie_brabant",
                "name": "Provincie Brabant",
                "pk": "BE__provincie_brabant"
            },
            {
                "_id": "vlaams-brabant",
                "code": "vlaams-brabant",
                "name": "Vlaams-Brabant",
                "pk": "BE__vlaams-brabant"
            },
            {
                "_id": "wallonne",
                "code": "wallonne",
                "name": "Wallonne",
                "pk": "BE__wallonne"
            },
            {
                "_id": "west-vlaanderen",
                "code": "west-vlaanderen",
                "name": "West-Vlaanderen",
                "pk": "BE__west-vlaanderen"
            }
        ],
        "only_shipping": true,
        "pk": "BE"
    },
    {
        "_id": "BZ",
        "code": "BZ",
        "name": "Belize",
        "states": [
            {
                "_id": "belize",
                "code": "belize",
                "name": "Belize",
                "pk": "BZ__belize"
            },
            {
                "_id": "cayo",
                "code": "cayo",
                "name": "Cayo",
                "pk": "BZ__cayo"
            },
            {
                "_id": "corozal",
                "code": "corozal",
                "name": "Corozal",
                "pk": "BZ__corozal"
            },
            {
                "_id": "orange_walk",
                "code": "orange_walk",
                "name": "Orange Walk",
                "pk": "BZ__orange_walk"
            },
            {
                "_id": "stann_creek",
                "code": "stann_creek",
                "name": "Stann Creek",
                "pk": "BZ__stann_creek"
            },
            {
                "_id": "toledo",
                "code": "toledo",
                "name": "Toledo",
                "pk": "BZ__toledo"
            }
        ],
        "pk": "BZ"
    },
    {
        "_id": "BJ",
        "code": "BJ",
        "name": "Bénin",
        "states": [
            {
                "_id": "alibori",
                "code": "alibori",
                "name": "Alibori",
                "pk": "BJ__alibori"
            },
            {
                "_id": "atacora",
                "code": "atacora",
                "name": "Atacora",
                "pk": "BJ__atacora"
            },
            {
                "_id": "atlantique",
                "code": "atlantique",
                "name": "Atlantique",
                "pk": "BJ__atlantique"
            },
            {
                "_id": "borgou",
                "code": "borgou",
                "name": "Borgou",
                "pk": "BJ__borgou"
            },
            {
                "_id": "collines",
                "code": "collines",
                "name": "Collines",
                "pk": "BJ__collines"
            },
            {
                "_id": "couffo",
                "code": "couffo",
                "name": "Couffo",
                "pk": "BJ__couffo"
            },
            {
                "_id": "donga",
                "code": "donga",
                "name": "Donga",
                "pk": "BJ__donga"
            },
            {
                "_id": "littoral",
                "code": "littoral",
                "name": "Littoral",
                "pk": "BJ__littoral"
            },
            {
                "_id": "mono",
                "code": "mono",
                "name": "Mono",
                "pk": "BJ__mono"
            },
            {
                "_id": "oueme",
                "code": "oueme",
                "name": "Oueme",
                "pk": "BJ__oueme"
            },
            {
                "_id": "plateau",
                "code": "plateau",
                "name": "Plateau",
                "pk": "BJ__plateau"
            },
            {
                "_id": "zou",
                "code": "zou",
                "name": "Zou",
                "pk": "BJ__zou"
            }
        ],
        "pk": "BJ"
    },
    {
        "_id": "BM",
        "code": "BM",
        "name": "Bermudes",
        "states": [
            {
                "_id": "hamilton",
                "code": "hamilton",
                "name": "Hamilton",
                "pk": "BM__hamilton"
            },
            {
                "_id": "saint_george",
                "code": "saint_george",
                "name": "Saint George",
                "pk": "BM__saint_george"
            }
        ],
        "pk": "BM"
    },
    {
        "_id": "BT",
        "code": "BT",
        "name": "Bhoutan",
        "states": [
            {
                "_id": "bumthang",
                "code": "bumthang",
                "name": "Bumthang",
                "pk": "BT__bumthang"
            },
            {
                "_id": "chhukha",
                "code": "chhukha",
                "name": "Chhukha",
                "pk": "BT__chhukha"
            },
            {
                "_id": "chirang",
                "code": "chirang",
                "name": "Chirang",
                "pk": "BT__chirang"
            },
            {
                "_id": "daga",
                "code": "daga",
                "name": "Daga",
                "pk": "BT__daga"
            },
            {
                "_id": "geylegphug",
                "code": "geylegphug",
                "name": "Geylegphug",
                "pk": "BT__geylegphug"
            },
            {
                "_id": "ha",
                "code": "ha",
                "name": "Ha",
                "pk": "BT__ha"
            },
            {
                "_id": "lhuntshi",
                "code": "lhuntshi",
                "name": "Lhuntshi",
                "pk": "BT__lhuntshi"
            },
            {
                "_id": "mongar",
                "code": "mongar",
                "name": "Mongar",
                "pk": "BT__mongar"
            },
            {
                "_id": "pemagatsel",
                "code": "pemagatsel",
                "name": "Pemagatsel",
                "pk": "BT__pemagatsel"
            },
            {
                "_id": "punakha",
                "code": "punakha",
                "name": "Punakha",
                "pk": "BT__punakha"
            },
            {
                "_id": "rinpung",
                "code": "rinpung",
                "name": "Rinpung",
                "pk": "BT__rinpung"
            },
            {
                "_id": "samchi",
                "code": "samchi",
                "name": "Samchi",
                "pk": "BT__samchi"
            },
            {
                "_id": "samdrup_jongkhar",
                "code": "samdrup_jongkhar",
                "name": "Samdrup Jongkhar",
                "pk": "BT__samdrup_jongkhar"
            },
            {
                "_id": "shemgang",
                "code": "shemgang",
                "name": "Shemgang",
                "pk": "BT__shemgang"
            },
            {
                "_id": "tashigang",
                "code": "tashigang",
                "name": "Tashigang",
                "pk": "BT__tashigang"
            },
            {
                "_id": "timphu",
                "code": "timphu",
                "name": "Timphu",
                "pk": "BT__timphu"
            },
            {
                "_id": "tongsa",
                "code": "tongsa",
                "name": "Tongsa",
                "pk": "BT__tongsa"
            },
            {
                "_id": "wangdiphodrang",
                "code": "wangdiphodrang",
                "name": "Wangdiphodrang",
                "pk": "BT__wangdiphodrang"
            }
        ],
        "pk": "BT"
    },
    {
        "_id": "BO",
        "code": "BO",
        "name": "Bolivie",
        "states": [
            {
                "_id": "beni",
                "code": "beni",
                "name": "Beni",
                "pk": "BO__beni"
            },
            {
                "_id": "chuquisaca",
                "code": "chuquisaca",
                "name": "Chuquisaca",
                "pk": "BO__chuquisaca"
            },
            {
                "_id": "cochabamba",
                "code": "cochabamba",
                "name": "Cochabamba",
                "pk": "BO__cochabamba"
            },
            {
                "_id": "la_paz",
                "code": "la_paz",
                "name": "La Paz",
                "pk": "BO__la_paz"
            },
            {
                "_id": "oruro",
                "code": "oruro",
                "name": "Oruro",
                "pk": "BO__oruro"
            },
            {
                "_id": "pando",
                "code": "pando",
                "name": "Pando",
                "pk": "BO__pando"
            },
            {
                "_id": "potosi",
                "code": "potosi",
                "name": "Potosi",
                "pk": "BO__potosi"
            },
            {
                "_id": "santa_cruz",
                "code": "santa_cruz",
                "name": "Santa Cruz",
                "pk": "BO__santa_cruz"
            },
            {
                "_id": "tarija",
                "code": "tarija",
                "name": "Tarija",
                "pk": "BO__tarija"
            }
        ],
        "pk": "BO"
    },
    {
        "_id": "BA",
        "code": "BA",
        "name": "Bosnie-Herzégovine",
        "states": [
            {
                "_id": "federacija_bosna_i_hercegovina",
                "code": "federacija_bosna_i_hercegovina",
                "name": "Federacija Bosna i Hercegovina",
                "pk": "BA__federacija_bosna_i_hercegovina"
            },
            {
                "_id": "republika_srpska",
                "code": "republika_srpska",
                "name": "Republika Srpska",
                "pk": "BA__republika_srpska"
            }
        ],
        "pk": "BA"
    },
    {
        "_id": "BW",
        "code": "BW",
        "name": "Botswana",
        "states": [
            {
                "_id": "central_bobonong",
                "code": "central_bobonong",
                "name": "Central Bobonong",
                "pk": "BW__central_bobonong"
            },
            {
                "_id": "central_boteti",
                "code": "central_boteti",
                "name": "Central Boteti",
                "pk": "BW__central_boteti"
            },
            {
                "_id": "central_mahalapye",
                "code": "central_mahalapye",
                "name": "Central Mahalapye",
                "pk": "BW__central_mahalapye"
            },
            {
                "_id": "central_serowe-palapye",
                "code": "central_serowe-palapye",
                "name": "Central Serowe-Palapye",
                "pk": "BW__central_serowe-palapye"
            },
            {
                "_id": "central_tutume",
                "code": "central_tutume",
                "name": "Central Tutume",
                "pk": "BW__central_tutume"
            },
            {
                "_id": "chobe",
                "code": "chobe",
                "name": "Chobe",
                "pk": "BW__chobe"
            },
            {
                "_id": "francistown",
                "code": "francistown",
                "name": "Francistown",
                "pk": "BW__francistown"
            },
            {
                "_id": "gaborone",
                "code": "gaborone",
                "name": "Gaborone",
                "pk": "BW__gaborone"
            },
            {
                "_id": "ghanzi",
                "code": "ghanzi",
                "name": "Ghanzi",
                "pk": "BW__ghanzi"
            },
            {
                "_id": "jwaneng",
                "code": "jwaneng",
                "name": "Jwaneng",
                "pk": "BW__jwaneng"
            },
            {
                "_id": "kgalagadi_north",
                "code": "kgalagadi_north",
                "name": "Kgalagadi North",
                "pk": "BW__kgalagadi_north"
            },
            {
                "_id": "kgalagadi_south",
                "code": "kgalagadi_south",
                "name": "Kgalagadi South",
                "pk": "BW__kgalagadi_south"
            },
            {
                "_id": "kgatleng",
                "code": "kgatleng",
                "name": "Kgatleng",
                "pk": "BW__kgatleng"
            },
            {
                "_id": "kweneng",
                "code": "kweneng",
                "name": "Kweneng",
                "pk": "BW__kweneng"
            },
            {
                "_id": "lobatse",
                "code": "lobatse",
                "name": "Lobatse",
                "pk": "BW__lobatse"
            },
            {
                "_id": "ngamiland",
                "code": "ngamiland",
                "name": "Ngamiland",
                "pk": "BW__ngamiland"
            },
            {
                "_id": "ngwaketse",
                "code": "ngwaketse",
                "name": "Ngwaketse",
                "pk": "BW__ngwaketse"
            },
            {
                "_id": "north_east",
                "code": "north_east",
                "name": "North East",
                "pk": "BW__north_east"
            },
            {
                "_id": "okavango",
                "code": "okavango",
                "name": "Okavango",
                "pk": "BW__okavango"
            },
            {
                "_id": "orapa",
                "code": "orapa",
                "name": "Orapa",
                "pk": "BW__orapa"
            },
            {
                "_id": "selibe_phikwe",
                "code": "selibe_phikwe",
                "name": "Selibe Phikwe",
                "pk": "BW__selibe_phikwe"
            },
            {
                "_id": "south_east",
                "code": "south_east",
                "name": "South East",
                "pk": "BW__south_east"
            },
            {
                "_id": "sowa",
                "code": "sowa",
                "name": "Sowa",
                "pk": "BW__sowa"
            }
        ],
        "pk": "BW"
    },
    {
        "_id": "BV",
        "code": "BV",
        "name": "Île Bouvet",
        "states": [
            {
                "_id": "bouvet_island",
                "code": "bouvet_island",
                "name": "Bouvet Island",
                "pk": "BV__bouvet_island"
            }
        ],
        "pk": "BV"
    },
    {
        "_id": "BR",
        "code": "BR",
        "name": "Brésil",
        "states": [
            {
                "_id": "acre",
                "code": "acre",
                "name": "Acre",
                "pk": "BR__acre"
            },
            {
                "_id": "alagoas",
                "code": "alagoas",
                "name": "Alagoas",
                "pk": "BR__alagoas"
            },
            {
                "_id": "amapa",
                "code": "amapa",
                "name": "Amapa",
                "pk": "BR__amapa"
            },
            {
                "_id": "amazonas",
                "code": "amazonas",
                "name": "Amazonas",
                "pk": "BR__amazonas"
            },
            {
                "_id": "bahia",
                "code": "bahia",
                "name": "Bahia",
                "pk": "BR__bahia"
            },
            {
                "_id": "ceara",
                "code": "ceara",
                "name": "Ceara",
                "pk": "BR__ceara"
            },
            {
                "_id": "distrito_federal",
                "code": "distrito_federal",
                "name": "Distrito Federal",
                "pk": "BR__distrito_federal"
            },
            {
                "_id": "espirito_santo",
                "code": "espirito_santo",
                "name": "Espirito Santo",
                "pk": "BR__espirito_santo"
            },
            {
                "_id": "estado_de_sao_paulo",
                "code": "estado_de_sao_paulo",
                "name": "Estado de Sao Paulo",
                "pk": "BR__estado_de_sao_paulo"
            },
            {
                "_id": "goias",
                "code": "goias",
                "name": "Goias",
                "pk": "BR__goias"
            },
            {
                "_id": "maranhao",
                "code": "maranhao",
                "name": "Maranhao",
                "pk": "BR__maranhao"
            },
            {
                "_id": "mato_grosso",
                "code": "mato_grosso",
                "name": "Mato Grosso",
                "pk": "BR__mato_grosso"
            },
            {
                "_id": "mato_grosso_do_sul",
                "code": "mato_grosso_do_sul",
                "name": "Mato Grosso do Sul",
                "pk": "BR__mato_grosso_do_sul"
            },
            {
                "_id": "minas_gerais",
                "code": "minas_gerais",
                "name": "Minas Gerais",
                "pk": "BR__minas_gerais"
            },
            {
                "_id": "para",
                "code": "para",
                "name": "Para",
                "pk": "BR__para"
            },
            {
                "_id": "paraiba",
                "code": "paraiba",
                "name": "Paraiba",
                "pk": "BR__paraiba"
            },
            {
                "_id": "parana",
                "code": "parana",
                "name": "Parana",
                "pk": "BR__parana"
            },
            {
                "_id": "pernambuco",
                "code": "pernambuco",
                "name": "Pernambuco",
                "pk": "BR__pernambuco"
            },
            {
                "_id": "piaui",
                "code": "piaui",
                "name": "Piaui",
                "pk": "BR__piaui"
            },
            {
                "_id": "rio_grande_do_norte",
                "code": "rio_grande_do_norte",
                "name": "Rio Grande do Norte",
                "pk": "BR__rio_grande_do_norte"
            },
            {
                "_id": "rio_grande_do_sul",
                "code": "rio_grande_do_sul",
                "name": "Rio Grande do Sul",
                "pk": "BR__rio_grande_do_sul"
            },
            {
                "_id": "rio_de_janeiro",
                "code": "rio_de_janeiro",
                "name": "Rio de Janeiro",
                "pk": "BR__rio_de_janeiro"
            },
            {
                "_id": "rondonia",
                "code": "rondonia",
                "name": "Rondonia",
                "pk": "BR__rondonia"
            },
            {
                "_id": "roraima",
                "code": "roraima",
                "name": "Roraima",
                "pk": "BR__roraima"
            },
            {
                "_id": "santa_catarina",
                "code": "santa_catarina",
                "name": "Santa Catarina",
                "pk": "BR__santa_catarina"
            },
            {
                "_id": "sao_paulo",
                "code": "sao_paulo",
                "name": "Sao Paulo",
                "pk": "BR__sao_paulo"
            },
            {
                "_id": "sergipe",
                "code": "sergipe",
                "name": "Sergipe",
                "pk": "BR__sergipe"
            },
            {
                "_id": "tocantins",
                "code": "tocantins",
                "name": "Tocantins",
                "pk": "BR__tocantins"
            }
        ],
        "pk": "BR"
    },
    {
        "_id": "IO",
        "code": "IO",
        "name": "Territoire britannique de l'océan Indien",
        "states": [
            {
                "_id": "british_indian_ocean_territory",
                "code": "british_indian_ocean_territory",
                "name": "British Indian Ocean Territory",
                "pk": "IO__british_indian_ocean_territory"
            }
        ],
        "pk": "IO"
    },
    {
        "_id": "BN",
        "code": "BN",
        "name": "Brunei",
        "states": [
            {
                "_id": "belait",
                "code": "belait",
                "name": "Belait",
                "pk": "BN__belait"
            },
            {
                "_id": "brunei-muara",
                "code": "brunei-muara",
                "name": "Brunei-Muara",
                "pk": "BN__brunei-muara"
            },
            {
                "_id": "temburong",
                "code": "temburong",
                "name": "Temburong",
                "pk": "BN__temburong"
            },
            {
                "_id": "tutong",
                "code": "tutong",
                "name": "Tutong",
                "pk": "BN__tutong"
            }
        ],
        "pk": "BN"
    },
    {
        "_id": "BG",
        "code": "BG",
        "name": "Bulgarie",
        "states": [
            {
                "_id": "blagoevgrad",
                "code": "blagoevgrad",
                "name": "Blagoevgrad",
                "pk": "BG__blagoevgrad"
            },
            {
                "_id": "burgas",
                "code": "burgas",
                "name": "Burgas",
                "pk": "BG__burgas"
            },
            {
                "_id": "dobrich",
                "code": "dobrich",
                "name": "Dobrich",
                "pk": "BG__dobrich"
            },
            {
                "_id": "gabrovo",
                "code": "gabrovo",
                "name": "Gabrovo",
                "pk": "BG__gabrovo"
            },
            {
                "_id": "haskovo",
                "code": "haskovo",
                "name": "Haskovo",
                "pk": "BG__haskovo"
            },
            {
                "_id": "jambol",
                "code": "jambol",
                "name": "Jambol",
                "pk": "BG__jambol"
            },
            {
                "_id": "kardzhali",
                "code": "kardzhali",
                "name": "Kardzhali",
                "pk": "BG__kardzhali"
            },
            {
                "_id": "kjustendil",
                "code": "kjustendil",
                "name": "Kjustendil",
                "pk": "BG__kjustendil"
            },
            {
                "_id": "lovech",
                "code": "lovech",
                "name": "Lovech",
                "pk": "BG__lovech"
            },
            {
                "_id": "montana",
                "code": "montana",
                "name": "Montana",
                "pk": "BG__montana"
            },
            {
                "_id": "oblast_sofiya-grad",
                "code": "oblast_sofiya-grad",
                "name": "Oblast Sofiya-Grad",
                "pk": "BG__oblast_sofiya-grad"
            },
            {
                "_id": "pazardzhik",
                "code": "pazardzhik",
                "name": "Pazardzhik",
                "pk": "BG__pazardzhik"
            },
            {
                "_id": "pernik",
                "code": "pernik",
                "name": "Pernik",
                "pk": "BG__pernik"
            },
            {
                "_id": "pleven",
                "code": "pleven",
                "name": "Pleven",
                "pk": "BG__pleven"
            },
            {
                "_id": "plovdiv",
                "code": "plovdiv",
                "name": "Plovdiv",
                "pk": "BG__plovdiv"
            },
            {
                "_id": "razgrad",
                "code": "razgrad",
                "name": "Razgrad",
                "pk": "BG__razgrad"
            },
            {
                "_id": "ruse",
                "code": "ruse",
                "name": "Ruse",
                "pk": "BG__ruse"
            },
            {
                "_id": "shumen",
                "code": "shumen",
                "name": "Shumen",
                "pk": "BG__shumen"
            },
            {
                "_id": "silistra",
                "code": "silistra",
                "name": "Silistra",
                "pk": "BG__silistra"
            },
            {
                "_id": "sliven",
                "code": "sliven",
                "name": "Sliven",
                "pk": "BG__sliven"
            },
            {
                "_id": "smoljan",
                "code": "smoljan",
                "name": "Smoljan",
                "pk": "BG__smoljan"
            },
            {
                "_id": "sofija_grad",
                "code": "sofija_grad",
                "name": "Sofija grad",
                "pk": "BG__sofija_grad"
            },
            {
                "_id": "sofijska_oblast",
                "code": "sofijska_oblast",
                "name": "Sofijska oblast",
                "pk": "BG__sofijska_oblast"
            },
            {
                "_id": "stara_zagora",
                "code": "stara_zagora",
                "name": "Stara Zagora",
                "pk": "BG__stara_zagora"
            },
            {
                "_id": "targovishte",
                "code": "targovishte",
                "name": "Targovishte",
                "pk": "BG__targovishte"
            },
            {
                "_id": "varna",
                "code": "varna",
                "name": "Varna",
                "pk": "BG__varna"
            },
            {
                "_id": "veliko_tarnovo",
                "code": "veliko_tarnovo",
                "name": "Veliko Tarnovo",
                "pk": "BG__veliko_tarnovo"
            },
            {
                "_id": "vidin",
                "code": "vidin",
                "name": "Vidin",
                "pk": "BG__vidin"
            },
            {
                "_id": "vraca",
                "code": "vraca",
                "name": "Vraca",
                "pk": "BG__vraca"
            },
            {
                "_id": "yablaniza",
                "code": "yablaniza",
                "name": "Yablaniza",
                "pk": "BG__yablaniza"
            }
        ],
        "pk": "BG"
    },
    {
        "_id": "BF",
        "code": "BF",
        "name": "Burkina Faso",
        "states": [
            {
                "_id": "bale",
                "code": "bale",
                "name": "Bale",
                "pk": "BF__bale"
            },
            {
                "_id": "bam",
                "code": "bam",
                "name": "Bam",
                "pk": "BF__bam"
            },
            {
                "_id": "bazega",
                "code": "bazega",
                "name": "Bazega",
                "pk": "BF__bazega"
            },
            {
                "_id": "bougouriba",
                "code": "bougouriba",
                "name": "Bougouriba",
                "pk": "BF__bougouriba"
            },
            {
                "_id": "boulgou",
                "code": "boulgou",
                "name": "Boulgou",
                "pk": "BF__boulgou"
            },
            {
                "_id": "boulkiemde",
                "code": "boulkiemde",
                "name": "Boulkiemde",
                "pk": "BF__boulkiemde"
            },
            {
                "_id": "comoe",
                "code": "comoe",
                "name": "Comoe",
                "pk": "BF__comoe"
            },
            {
                "_id": "ganzourgou",
                "code": "ganzourgou",
                "name": "Ganzourgou",
                "pk": "BF__ganzourgou"
            },
            {
                "_id": "gnagna",
                "code": "gnagna",
                "name": "Gnagna",
                "pk": "BF__gnagna"
            },
            {
                "_id": "gourma",
                "code": "gourma",
                "name": "Gourma",
                "pk": "BF__gourma"
            },
            {
                "_id": "houet",
                "code": "houet",
                "name": "Houet",
                "pk": "BF__houet"
            },
            {
                "_id": "ioba",
                "code": "ioba",
                "name": "Ioba",
                "pk": "BF__ioba"
            },
            {
                "_id": "kadiogo",
                "code": "kadiogo",
                "name": "Kadiogo",
                "pk": "BF__kadiogo"
            },
            {
                "_id": "kenedougou",
                "code": "kenedougou",
                "name": "Kenedougou",
                "pk": "BF__kenedougou"
            },
            {
                "_id": "komandjari",
                "code": "komandjari",
                "name": "Komandjari",
                "pk": "BF__komandjari"
            },
            {
                "_id": "kompienga",
                "code": "kompienga",
                "name": "Kompienga",
                "pk": "BF__kompienga"
            },
            {
                "_id": "kossi",
                "code": "kossi",
                "name": "Kossi",
                "pk": "BF__kossi"
            },
            {
                "_id": "kouritenga",
                "code": "kouritenga",
                "name": "Kouritenga",
                "pk": "BF__kouritenga"
            },
            {
                "_id": "kourweogo",
                "code": "kourweogo",
                "name": "Kourweogo",
                "pk": "BF__kourweogo"
            },
            {
                "_id": "leraba",
                "code": "leraba",
                "name": "Leraba",
                "pk": "BF__leraba"
            },
            {
                "_id": "mouhoun",
                "code": "mouhoun",
                "name": "Mouhoun",
                "pk": "BF__mouhoun"
            },
            {
                "_id": "nahouri",
                "code": "nahouri",
                "name": "Nahouri",
                "pk": "BF__nahouri"
            },
            {
                "_id": "namentenga",
                "code": "namentenga",
                "name": "Namentenga",
                "pk": "BF__namentenga"
            },
            {
                "_id": "noumbiel",
                "code": "noumbiel",
                "name": "Noumbiel",
                "pk": "BF__noumbiel"
            },
            {
                "_id": "oubritenga",
                "code": "oubritenga",
                "name": "Oubritenga",
                "pk": "BF__oubritenga"
            },
            {
                "_id": "oudalan",
                "code": "oudalan",
                "name": "Oudalan",
                "pk": "BF__oudalan"
            },
            {
                "_id": "passore",
                "code": "passore",
                "name": "Passore",
                "pk": "BF__passore"
            },
            {
                "_id": "poni",
                "code": "poni",
                "name": "Poni",
                "pk": "BF__poni"
            },
            {
                "_id": "sanguie",
                "code": "sanguie",
                "name": "Sanguie",
                "pk": "BF__sanguie"
            },
            {
                "_id": "sanmatenga",
                "code": "sanmatenga",
                "name": "Sanmatenga",
                "pk": "BF__sanmatenga"
            },
            {
                "_id": "seno",
                "code": "seno",
                "name": "Seno",
                "pk": "BF__seno"
            },
            {
                "_id": "sissili",
                "code": "sissili",
                "name": "Sissili",
                "pk": "BF__sissili"
            },
            {
                "_id": "soum",
                "code": "soum",
                "name": "Soum",
                "pk": "BF__soum"
            },
            {
                "_id": "sourou",
                "code": "sourou",
                "name": "Sourou",
                "pk": "BF__sourou"
            },
            {
                "_id": "tapoa",
                "code": "tapoa",
                "name": "Tapoa",
                "pk": "BF__tapoa"
            },
            {
                "_id": "tuy",
                "code": "tuy",
                "name": "Tuy",
                "pk": "BF__tuy"
            },
            {
                "_id": "yatenga",
                "code": "yatenga",
                "name": "Yatenga",
                "pk": "BF__yatenga"
            },
            {
                "_id": "zondoma",
                "code": "zondoma",
                "name": "Zondoma",
                "pk": "BF__zondoma"
            },
            {
                "_id": "zoundweogo",
                "code": "zoundweogo",
                "name": "Zoundweogo",
                "pk": "BF__zoundweogo"
            }
        ],
        "pk": "BF"
    },
    {
        "_id": "BI",
        "code": "BI",
        "name": "Burundi",
        "states": [
            {
                "_id": "bubanza",
                "code": "bubanza",
                "name": "Bubanza",
                "pk": "BI__bubanza"
            },
            {
                "_id": "bujumbura",
                "code": "bujumbura",
                "name": "Bujumbura",
                "pk": "BI__bujumbura"
            },
            {
                "_id": "bururi",
                "code": "bururi",
                "name": "Bururi",
                "pk": "BI__bururi"
            },
            {
                "_id": "cankuzo",
                "code": "cankuzo",
                "name": "Cankuzo",
                "pk": "BI__cankuzo"
            },
            {
                "_id": "cibitoke",
                "code": "cibitoke",
                "name": "Cibitoke",
                "pk": "BI__cibitoke"
            },
            {
                "_id": "gitega",
                "code": "gitega",
                "name": "Gitega",
                "pk": "BI__gitega"
            },
            {
                "_id": "karuzi",
                "code": "karuzi",
                "name": "Karuzi",
                "pk": "BI__karuzi"
            },
            {
                "_id": "kayanza",
                "code": "kayanza",
                "name": "Kayanza",
                "pk": "BI__kayanza"
            },
            {
                "_id": "kirundo",
                "code": "kirundo",
                "name": "Kirundo",
                "pk": "BI__kirundo"
            },
            {
                "_id": "makamba",
                "code": "makamba",
                "name": "Makamba",
                "pk": "BI__makamba"
            },
            {
                "_id": "muramvya",
                "code": "muramvya",
                "name": "Muramvya",
                "pk": "BI__muramvya"
            },
            {
                "_id": "muyinga",
                "code": "muyinga",
                "name": "Muyinga",
                "pk": "BI__muyinga"
            },
            {
                "_id": "ngozi",
                "code": "ngozi",
                "name": "Ngozi",
                "pk": "BI__ngozi"
            },
            {
                "_id": "rutana",
                "code": "rutana",
                "name": "Rutana",
                "pk": "BI__rutana"
            },
            {
                "_id": "ruyigi",
                "code": "ruyigi",
                "name": "Ruyigi",
                "pk": "BI__ruyigi"
            }
        ],
        "pk": "BI"
    },
    {
        "_id": "KH",
        "code": "KH",
        "name": "Cambodge",
        "states": [
            {
                "_id": "banteay_mean_chey",
                "code": "banteay_mean_chey",
                "name": "Banteay Mean Chey",
                "pk": "KH__banteay_mean_chey"
            },
            {
                "_id": "bat_dambang",
                "code": "bat_dambang",
                "name": "Bat Dambang",
                "pk": "KH__bat_dambang"
            },
            {
                "_id": "kampong_cham",
                "code": "kampong_cham",
                "name": "Kampong Cham",
                "pk": "KH__kampong_cham"
            },
            {
                "_id": "kampong_chhnang",
                "code": "kampong_chhnang",
                "name": "Kampong Chhnang",
                "pk": "KH__kampong_chhnang"
            },
            {
                "_id": "kampong_spoeu",
                "code": "kampong_spoeu",
                "name": "Kampong Spoeu",
                "pk": "KH__kampong_spoeu"
            },
            {
                "_id": "kampong_thum",
                "code": "kampong_thum",
                "name": "Kampong Thum",
                "pk": "KH__kampong_thum"
            },
            {
                "_id": "kampot",
                "code": "kampot",
                "name": "Kampot",
                "pk": "KH__kampot"
            },
            {
                "_id": "kandal",
                "code": "kandal",
                "name": "Kandal",
                "pk": "KH__kandal"
            },
            {
                "_id": "kaoh_kong",
                "code": "kaoh_kong",
                "name": "Kaoh Kong",
                "pk": "KH__kaoh_kong"
            },
            {
                "_id": "kracheh",
                "code": "kracheh",
                "name": "Kracheh",
                "pk": "KH__kracheh"
            },
            {
                "_id": "krong_kaeb",
                "code": "krong_kaeb",
                "name": "Krong Kaeb",
                "pk": "KH__krong_kaeb"
            },
            {
                "_id": "krong_pailin",
                "code": "krong_pailin",
                "name": "Krong Pailin",
                "pk": "KH__krong_pailin"
            },
            {
                "_id": "krong_preah_sihanouk",
                "code": "krong_preah_sihanouk",
                "name": "Krong Preah Sihanouk",
                "pk": "KH__krong_preah_sihanouk"
            },
            {
                "_id": "mondol_kiri",
                "code": "mondol_kiri",
                "name": "Mondol Kiri",
                "pk": "KH__mondol_kiri"
            },
            {
                "_id": "otdar_mean_chey",
                "code": "otdar_mean_chey",
                "name": "Otdar Mean Chey",
                "pk": "KH__otdar_mean_chey"
            },
            {
                "_id": "phnum_penh",
                "code": "phnum_penh",
                "name": "Phnum Penh",
                "pk": "KH__phnum_penh"
            },
            {
                "_id": "pousat",
                "code": "pousat",
                "name": "Pousat",
                "pk": "KH__pousat"
            },
            {
                "_id": "preah_vihear",
                "code": "preah_vihear",
                "name": "Preah Vihear",
                "pk": "KH__preah_vihear"
            },
            {
                "_id": "prey_veaeng",
                "code": "prey_veaeng",
                "name": "Prey Veaeng",
                "pk": "KH__prey_veaeng"
            },
            {
                "_id": "rotanak_kiri",
                "code": "rotanak_kiri",
                "name": "Rotanak Kiri",
                "pk": "KH__rotanak_kiri"
            },
            {
                "_id": "siem_reab",
                "code": "siem_reab",
                "name": "Siem Reab",
                "pk": "KH__siem_reab"
            },
            {
                "_id": "stueng_traeng",
                "code": "stueng_traeng",
                "name": "Stueng Traeng",
                "pk": "KH__stueng_traeng"
            },
            {
                "_id": "svay_rieng",
                "code": "svay_rieng",
                "name": "Svay Rieng",
                "pk": "KH__svay_rieng"
            },
            {
                "_id": "takaev",
                "code": "takaev",
                "name": "Takaev",
                "pk": "KH__takaev"
            }
        ],
        "pk": "KH"
    },
    {
        "_id": "CM",
        "code": "CM",
        "name": "Cameroun",
        "states": [
            {
                "_id": "adamaoua",
                "code": "adamaoua",
                "name": "Adamaoua",
                "pk": "CM__adamaoua"
            },
            {
                "_id": "centre",
                "code": "centre",
                "name": "Centre",
                "pk": "CM__centre"
            },
            {
                "_id": "est",
                "code": "est",
                "name": "Est",
                "pk": "CM__est"
            },
            {
                "_id": "littoral",
                "code": "littoral",
                "name": "Littoral",
                "pk": "CM__littoral"
            },
            {
                "_id": "nord",
                "code": "nord",
                "name": "Nord",
                "pk": "CM__nord"
            },
            {
                "_id": "nord_extreme",
                "code": "nord_extreme",
                "name": "Nord Extreme",
                "pk": "CM__nord_extreme"
            },
            {
                "_id": "nordouest",
                "code": "nordouest",
                "name": "Nordouest",
                "pk": "CM__nordouest"
            },
            {
                "_id": "ouest",
                "code": "ouest",
                "name": "Ouest",
                "pk": "CM__ouest"
            },
            {
                "_id": "sud",
                "code": "sud",
                "name": "Sud",
                "pk": "CM__sud"
            },
            {
                "_id": "sudouest",
                "code": "sudouest",
                "name": "Sudouest",
                "pk": "CM__sudouest"
            }
        ],
        "pk": "CM"
    },
    {
        "_id": "CV",
        "code": "CV",
        "name": "Cap-Vert",
        "states": [
            {
                "_id": "boavista",
                "code": "boavista",
                "name": "Boavista",
                "pk": "CV__boavista"
            },
            {
                "_id": "brava",
                "code": "brava",
                "name": "Brava",
                "pk": "CV__brava"
            },
            {
                "_id": "fogo",
                "code": "fogo",
                "name": "Fogo",
                "pk": "CV__fogo"
            },
            {
                "_id": "maio",
                "code": "maio",
                "name": "Maio",
                "pk": "CV__maio"
            },
            {
                "_id": "sal",
                "code": "sal",
                "name": "Sal",
                "pk": "CV__sal"
            },
            {
                "_id": "santo_antao",
                "code": "santo_antao",
                "name": "Santo Antao",
                "pk": "CV__santo_antao"
            },
            {
                "_id": "sao_nicolau",
                "code": "sao_nicolau",
                "name": "Sao Nicolau",
                "pk": "CV__sao_nicolau"
            },
            {
                "_id": "sao_tiago",
                "code": "sao_tiago",
                "name": "Sao Tiago",
                "pk": "CV__sao_tiago"
            },
            {
                "_id": "sao_vicente",
                "code": "sao_vicente",
                "name": "Sao Vicente",
                "pk": "CV__sao_vicente"
            }
        ],
        "pk": "CV"
    },
    {
        "_id": "KY",
        "code": "KY",
        "name": "Îles Caïmans",
        "states": [
            {
                "_id": "grand_cayman",
                "code": "grand_cayman",
                "name": "Grand Cayman",
                "pk": "KY__grand_cayman"
            }
        ],
        "pk": "KY"
    },
    {
        "_id": "CF",
        "code": "CF",
        "name": "République centrafricaine",
        "states": [
            {
                "_id": "bamingui-bangoran",
                "code": "bamingui-bangoran",
                "name": "Bamingui-Bangoran",
                "pk": "CF__bamingui-bangoran"
            },
            {
                "_id": "bangui",
                "code": "bangui",
                "name": "Bangui",
                "pk": "CF__bangui"
            },
            {
                "_id": "basse-kotto",
                "code": "basse-kotto",
                "name": "Basse-Kotto",
                "pk": "CF__basse-kotto"
            },
            {
                "_id": "haut-mbomou",
                "code": "haut-mbomou",
                "name": "Haut-Mbomou",
                "pk": "CF__haut-mbomou"
            },
            {
                "_id": "haute-kotto",
                "code": "haute-kotto",
                "name": "Haute-Kotto",
                "pk": "CF__haute-kotto"
            },
            {
                "_id": "kemo",
                "code": "kemo",
                "name": "Kemo",
                "pk": "CF__kemo"
            },
            {
                "_id": "lobaye",
                "code": "lobaye",
                "name": "Lobaye",
                "pk": "CF__lobaye"
            },
            {
                "_id": "mambere-kadei",
                "code": "mambere-kadei",
                "name": "Mambere-Kadei",
                "pk": "CF__mambere-kadei"
            },
            {
                "_id": "mbomou",
                "code": "mbomou",
                "name": "Mbomou",
                "pk": "CF__mbomou"
            },
            {
                "_id": "nana-gribizi",
                "code": "nana-gribizi",
                "name": "Nana-Gribizi",
                "pk": "CF__nana-gribizi"
            },
            {
                "_id": "nana-mambere",
                "code": "nana-mambere",
                "name": "Nana-Mambere",
                "pk": "CF__nana-mambere"
            },
            {
                "_id": "ombella_mpoko",
                "code": "ombella_mpoko",
                "name": "Ombella Mpoko",
                "pk": "CF__ombella_mpoko"
            },
            {
                "_id": "ouaka",
                "code": "ouaka",
                "name": "Ouaka",
                "pk": "CF__ouaka"
            },
            {
                "_id": "ouham",
                "code": "ouham",
                "name": "Ouham",
                "pk": "CF__ouham"
            },
            {
                "_id": "ouham-pende",
                "code": "ouham-pende",
                "name": "Ouham-Pende",
                "pk": "CF__ouham-pende"
            },
            {
                "_id": "sangha-mbaere",
                "code": "sangha-mbaere",
                "name": "Sangha-Mbaere",
                "pk": "CF__sangha-mbaere"
            },
            {
                "_id": "vakaga",
                "code": "vakaga",
                "name": "Vakaga",
                "pk": "CF__vakaga"
            }
        ],
        "pk": "CF"
    },
    {
        "_id": "TD",
        "code": "TD",
        "name": "Tchad",
        "states": [
            {
                "_id": "batha",
                "code": "batha",
                "name": "Batha",
                "pk": "TD__batha"
            },
            {
                "_id": "biltine",
                "code": "biltine",
                "name": "Biltine",
                "pk": "TD__biltine"
            },
            {
                "_id": "bourkou-ennedi-tibesti",
                "code": "bourkou-ennedi-tibesti",
                "name": "Bourkou-Ennedi-Tibesti",
                "pk": "TD__bourkou-ennedi-tibesti"
            },
            {
                "_id": "chari-baguirmi",
                "code": "chari-baguirmi",
                "name": "Chari-Baguirmi",
                "pk": "TD__chari-baguirmi"
            },
            {
                "_id": "guera",
                "code": "guera",
                "name": "Guera",
                "pk": "TD__guera"
            },
            {
                "_id": "kanem",
                "code": "kanem",
                "name": "Kanem",
                "pk": "TD__kanem"
            },
            {
                "_id": "lac",
                "code": "lac",
                "name": "Lac",
                "pk": "TD__lac"
            },
            {
                "_id": "logone_occidental",
                "code": "logone_occidental",
                "name": "Logone Occidental",
                "pk": "TD__logone_occidental"
            },
            {
                "_id": "logone_oriental",
                "code": "logone_oriental",
                "name": "Logone Oriental",
                "pk": "TD__logone_oriental"
            },
            {
                "_id": "mayo-kebbi",
                "code": "mayo-kebbi",
                "name": "Mayo-Kebbi",
                "pk": "TD__mayo-kebbi"
            },
            {
                "_id": "moyen-chari",
                "code": "moyen-chari",
                "name": "Moyen-Chari",
                "pk": "TD__moyen-chari"
            },
            {
                "_id": "ouaddai",
                "code": "ouaddai",
                "name": "Ouaddai",
                "pk": "TD__ouaddai"
            },
            {
                "_id": "salamat",
                "code": "salamat",
                "name": "Salamat",
                "pk": "TD__salamat"
            },
            {
                "_id": "tandjile",
                "code": "tandjile",
                "name": "Tandjile",
                "pk": "TD__tandjile"
            }
        ],
        "pk": "TD"
    },
    {
        "_id": "CL",
        "code": "CL",
        "name": "Chili",
        "states": [
            {
                "_id": "aisen",
                "code": "aisen",
                "name": "Aisen",
                "pk": "CL__aisen"
            },
            {
                "_id": "antofagasta",
                "code": "antofagasta",
                "name": "Antofagasta",
                "pk": "CL__antofagasta"
            },
            {
                "_id": "araucania",
                "code": "araucania",
                "name": "Araucania",
                "pk": "CL__araucania"
            },
            {
                "_id": "atacama",
                "code": "atacama",
                "name": "Atacama",
                "pk": "CL__atacama"
            },
            {
                "_id": "bio_bio",
                "code": "bio_bio",
                "name": "Bio Bio",
                "pk": "CL__bio_bio"
            },
            {
                "_id": "coquimbo",
                "code": "coquimbo",
                "name": "Coquimbo",
                "pk": "CL__coquimbo"
            },
            {
                "_id": "libertador_general_bernardo_o",
                "code": "libertador_general_bernardo_o",
                "name": "Libertador General Bernardo O",
                "pk": "CL__libertador_general_bernardo_o"
            },
            {
                "_id": "los_lagos",
                "code": "los_lagos",
                "name": "Los Lagos",
                "pk": "CL__los_lagos"
            },
            {
                "_id": "magellanes",
                "code": "magellanes",
                "name": "Magellanes",
                "pk": "CL__magellanes"
            },
            {
                "_id": "maule",
                "code": "maule",
                "name": "Maule",
                "pk": "CL__maule"
            },
            {
                "_id": "metropolitana",
                "code": "metropolitana",
                "name": "Metropolitana",
                "pk": "CL__metropolitana"
            },
            {
                "_id": "metropolitana_de_santiago",
                "code": "metropolitana_de_santiago",
                "name": "Metropolitana de Santiago",
                "pk": "CL__metropolitana_de_santiago"
            },
            {
                "_id": "tarapaca",
                "code": "tarapaca",
                "name": "Tarapaca",
                "pk": "CL__tarapaca"
            },
            {
                "_id": "valparaiso",
                "code": "valparaiso",
                "name": "Valparaiso",
                "pk": "CL__valparaiso"
            }
        ],
        "pk": "CL"
    },
    {
        "_id": "CN",
        "code": "CN",
        "name": "Chine",
        "states": [
            {
                "_id": "anhui",
                "code": "anhui",
                "name": "Anhui",
                "pk": "CN__anhui"
            },
            {
                "_id": "anhui_province",
                "code": "anhui_province",
                "name": "Anhui Province",
                "pk": "CN__anhui_province"
            },
            {
                "_id": "anhui_sheng",
                "code": "anhui_sheng",
                "name": "Anhui Sheng",
                "pk": "CN__anhui_sheng"
            },
            {
                "_id": "aomen",
                "code": "aomen",
                "name": "Aomen",
                "pk": "CN__aomen"
            },
            {
                "_id": "beijing",
                "code": "beijing",
                "name": "Beijing",
                "pk": "CN__beijing"
            },
            {
                "_id": "beijing_shi",
                "code": "beijing_shi",
                "name": "Beijing Shi",
                "pk": "CN__beijing_shi"
            },
            {
                "_id": "chongqing",
                "code": "chongqing",
                "name": "Chongqing",
                "pk": "CN__chongqing"
            },
            {
                "_id": "fujian",
                "code": "fujian",
                "name": "Fujian",
                "pk": "CN__fujian"
            },
            {
                "_id": "fujian_sheng",
                "code": "fujian_sheng",
                "name": "Fujian Sheng",
                "pk": "CN__fujian_sheng"
            },
            {
                "_id": "gansu",
                "code": "gansu",
                "name": "Gansu",
                "pk": "CN__gansu"
            },
            {
                "_id": "guangdong",
                "code": "guangdong",
                "name": "Guangdong",
                "pk": "CN__guangdong"
            },
            {
                "_id": "guangdong_sheng",
                "code": "guangdong_sheng",
                "name": "Guangdong Sheng",
                "pk": "CN__guangdong_sheng"
            },
            {
                "_id": "guangxi",
                "code": "guangxi",
                "name": "Guangxi",
                "pk": "CN__guangxi"
            },
            {
                "_id": "guizhou",
                "code": "guizhou",
                "name": "Guizhou",
                "pk": "CN__guizhou"
            },
            {
                "_id": "hainan",
                "code": "hainan",
                "name": "Hainan",
                "pk": "CN__hainan"
            },
            {
                "_id": "hebei",
                "code": "hebei",
                "name": "Hebei",
                "pk": "CN__hebei"
            },
            {
                "_id": "heilongjiang",
                "code": "heilongjiang",
                "name": "Heilongjiang",
                "pk": "CN__heilongjiang"
            },
            {
                "_id": "henan",
                "code": "henan",
                "name": "Henan",
                "pk": "CN__henan"
            },
            {
                "_id": "hubei",
                "code": "hubei",
                "name": "Hubei",
                "pk": "CN__hubei"
            },
            {
                "_id": "hunan",
                "code": "hunan",
                "name": "Hunan",
                "pk": "CN__hunan"
            },
            {
                "_id": "jiangsu",
                "code": "jiangsu",
                "name": "Jiangsu",
                "pk": "CN__jiangsu"
            },
            {
                "_id": "jiangsu_sheng",
                "code": "jiangsu_sheng",
                "name": "Jiangsu Sheng",
                "pk": "CN__jiangsu_sheng"
            },
            {
                "_id": "jiangxi",
                "code": "jiangxi",
                "name": "Jiangxi",
                "pk": "CN__jiangxi"
            },
            {
                "_id": "jilin",
                "code": "jilin",
                "name": "Jilin",
                "pk": "CN__jilin"
            },
            {
                "_id": "liaoning",
                "code": "liaoning",
                "name": "Liaoning",
                "pk": "CN__liaoning"
            },
            {
                "_id": "liaoning_sheng",
                "code": "liaoning_sheng",
                "name": "Liaoning Sheng",
                "pk": "CN__liaoning_sheng"
            },
            {
                "_id": "nei_monggol",
                "code": "nei_monggol",
                "name": "Nei Monggol",
                "pk": "CN__nei_monggol"
            },
            {
                "_id": "ningxia_hui",
                "code": "ningxia_hui",
                "name": "Ningxia Hui",
                "pk": "CN__ningxia_hui"
            },
            {
                "_id": "qinghai",
                "code": "qinghai",
                "name": "Qinghai",
                "pk": "CN__qinghai"
            },
            {
                "_id": "shaanxi",
                "code": "shaanxi",
                "name": "Shaanxi",
                "pk": "CN__shaanxi"
            },
            {
                "_id": "shandong",
                "code": "shandong",
                "name": "Shandong",
                "pk": "CN__shandong"
            },
            {
                "_id": "shandong_sheng",
                "code": "shandong_sheng",
                "name": "Shandong Sheng",
                "pk": "CN__shandong_sheng"
            },
            {
                "_id": "shanghai",
                "code": "shanghai",
                "name": "Shanghai",
                "pk": "CN__shanghai"
            },
            {
                "_id": "shanxi",
                "code": "shanxi",
                "name": "Shanxi",
                "pk": "CN__shanxi"
            },
            {
                "_id": "sichuan",
                "code": "sichuan",
                "name": "Sichuan",
                "pk": "CN__sichuan"
            },
            {
                "_id": "tianjin",
                "code": "tianjin",
                "name": "Tianjin",
                "pk": "CN__tianjin"
            },
            {
                "_id": "xianggang",
                "code": "xianggang",
                "name": "Xianggang",
                "pk": "CN__xianggang"
            },
            {
                "_id": "xinjiang",
                "code": "xinjiang",
                "name": "Xinjiang",
                "pk": "CN__xinjiang"
            },
            {
                "_id": "xizang",
                "code": "xizang",
                "name": "Xizang",
                "pk": "CN__xizang"
            },
            {
                "_id": "yunnan",
                "code": "yunnan",
                "name": "Yunnan",
                "pk": "CN__yunnan"
            },
            {
                "_id": "zhejiang",
                "code": "zhejiang",
                "name": "Zhejiang",
                "pk": "CN__zhejiang"
            },
            {
                "_id": "zhejiang_sheng",
                "code": "zhejiang_sheng",
                "name": "Zhejiang Sheng",
                "pk": "CN__zhejiang_sheng"
            }
        ],
        "pk": "CN"
    },
    {
        "_id": "CX",
        "code": "CX",
        "name": "Île Christmas",
        "states": [
            {
                "_id": "christmas_island",
                "code": "christmas_island",
                "name": "Christmas Island",
                "pk": "CX__christmas_island"
            }
        ],
        "pk": "CX"
    },
    {
        "_id": "CC",
        "code": "CC",
        "name": "Îles Cocos",
        "states": [
            {
                "_id": "cocos_(keeling_islands",
                "code": "cocos_(keeling_islands",
                "name": "Cocos (Keeling Islands",
                "pk": "CC__cocos_(keeling_islands"
            }
        ],
        "pk": "CC"
    },
    {
        "_id": "CO",
        "code": "CO",
        "name": "Colombie",
        "states": [
            {
                "_id": "amazonas",
                "code": "amazonas",
                "name": "Amazonas",
                "pk": "CO__amazonas"
            },
            {
                "_id": "antioquia",
                "code": "antioquia",
                "name": "Antioquia",
                "pk": "CO__antioquia"
            },
            {
                "_id": "arauca",
                "code": "arauca",
                "name": "Arauca",
                "pk": "CO__arauca"
            },
            {
                "_id": "atlantico",
                "code": "atlantico",
                "name": "Atlantico",
                "pk": "CO__atlantico"
            },
            {
                "_id": "bogota",
                "code": "bogota",
                "name": "Bogota",
                "pk": "CO__bogota"
            },
            {
                "_id": "bolivar",
                "code": "bolivar",
                "name": "Bolivar",
                "pk": "CO__bolivar"
            },
            {
                "_id": "boyaca",
                "code": "boyaca",
                "name": "Boyaca",
                "pk": "CO__boyaca"
            },
            {
                "_id": "caldas",
                "code": "caldas",
                "name": "Caldas",
                "pk": "CO__caldas"
            },
            {
                "_id": "caqueta",
                "code": "caqueta",
                "name": "Caqueta",
                "pk": "CO__caqueta"
            },
            {
                "_id": "casanare",
                "code": "casanare",
                "name": "Casanare",
                "pk": "CO__casanare"
            },
            {
                "_id": "cauca",
                "code": "cauca",
                "name": "Cauca",
                "pk": "CO__cauca"
            },
            {
                "_id": "cesar",
                "code": "cesar",
                "name": "Cesar",
                "pk": "CO__cesar"
            },
            {
                "_id": "choco",
                "code": "choco",
                "name": "Choco",
                "pk": "CO__choco"
            },
            {
                "_id": "cordoba",
                "code": "cordoba",
                "name": "Cordoba",
                "pk": "CO__cordoba"
            },
            {
                "_id": "cundinamarca",
                "code": "cundinamarca",
                "name": "Cundinamarca",
                "pk": "CO__cundinamarca"
            },
            {
                "_id": "guainia",
                "code": "guainia",
                "name": "Guainia",
                "pk": "CO__guainia"
            },
            {
                "_id": "guaviare",
                "code": "guaviare",
                "name": "Guaviare",
                "pk": "CO__guaviare"
            },
            {
                "_id": "huila",
                "code": "huila",
                "name": "Huila",
                "pk": "CO__huila"
            },
            {
                "_id": "la_guajira",
                "code": "la_guajira",
                "name": "La Guajira",
                "pk": "CO__la_guajira"
            },
            {
                "_id": "magdalena",
                "code": "magdalena",
                "name": "Magdalena",
                "pk": "CO__magdalena"
            },
            {
                "_id": "meta",
                "code": "meta",
                "name": "Meta",
                "pk": "CO__meta"
            },
            {
                "_id": "narino",
                "code": "narino",
                "name": "Narino",
                "pk": "CO__narino"
            },
            {
                "_id": "norte_de_santander",
                "code": "norte_de_santander",
                "name": "Norte de Santander",
                "pk": "CO__norte_de_santander"
            },
            {
                "_id": "putumayo",
                "code": "putumayo",
                "name": "Putumayo",
                "pk": "CO__putumayo"
            },
            {
                "_id": "quindio",
                "code": "quindio",
                "name": "Quindio",
                "pk": "CO__quindio"
            },
            {
                "_id": "risaralda",
                "code": "risaralda",
                "name": "Risaralda",
                "pk": "CO__risaralda"
            },
            {
                "_id": "san_andres_y_providencia",
                "code": "san_andres_y_providencia",
                "name": "San Andres y Providencia",
                "pk": "CO__san_andres_y_providencia"
            },
            {
                "_id": "santander",
                "code": "santander",
                "name": "Santander",
                "pk": "CO__santander"
            },
            {
                "_id": "sucre",
                "code": "sucre",
                "name": "Sucre",
                "pk": "CO__sucre"
            },
            {
                "_id": "tolima",
                "code": "tolima",
                "name": "Tolima",
                "pk": "CO__tolima"
            },
            {
                "_id": "valle_del_cauca",
                "code": "valle_del_cauca",
                "name": "Valle del Cauca",
                "pk": "CO__valle_del_cauca"
            },
            {
                "_id": "vaupes",
                "code": "vaupes",
                "name": "Vaupes",
                "pk": "CO__vaupes"
            },
            {
                "_id": "vichada",
                "code": "vichada",
                "name": "Vichada",
                "pk": "CO__vichada"
            }
        ],
        "pk": "CO"
    },
    {
        "_id": "KM",
        "code": "KM",
        "name": "Comores",
        "states": [
            {
                "_id": "mwali",
                "code": "mwali",
                "name": "Mwali",
                "pk": "KM__mwali"
            },
            {
                "_id": "njazidja",
                "code": "njazidja",
                "name": "Njazidja",
                "pk": "KM__njazidja"
            },
            {
                "_id": "nzwani",
                "code": "nzwani",
                "name": "Nzwani",
                "pk": "KM__nzwani"
            }
        ],
        "pk": "KM"
    },
    {
        "_id": "CK",
        "code": "CK",
        "name": "Îles Cook",
        "states": [
            {
                "_id": "aitutaki",
                "code": "aitutaki",
                "name": "Aitutaki",
                "pk": "CK__aitutaki"
            },
            {
                "_id": "atiu",
                "code": "atiu",
                "name": "Atiu",
                "pk": "CK__atiu"
            },
            {
                "_id": "mangaia",
                "code": "mangaia",
                "name": "Mangaia",
                "pk": "CK__mangaia"
            },
            {
                "_id": "manihiki",
                "code": "manihiki",
                "name": "Manihiki",
                "pk": "CK__manihiki"
            },
            {
                "_id": "mauke",
                "code": "mauke",
                "name": "Mauke",
                "pk": "CK__mauke"
            },
            {
                "_id": "mitiaro",
                "code": "mitiaro",
                "name": "Mitiaro",
                "pk": "CK__mitiaro"
            },
            {
                "_id": "nassau",
                "code": "nassau",
                "name": "Nassau",
                "pk": "CK__nassau"
            },
            {
                "_id": "pukapuka",
                "code": "pukapuka",
                "name": "Pukapuka",
                "pk": "CK__pukapuka"
            },
            {
                "_id": "rakahanga",
                "code": "rakahanga",
                "name": "Rakahanga",
                "pk": "CK__rakahanga"
            },
            {
                "_id": "rarotonga",
                "code": "rarotonga",
                "name": "Rarotonga",
                "pk": "CK__rarotonga"
            },
            {
                "_id": "tongareva",
                "code": "tongareva",
                "name": "Tongareva",
                "pk": "CK__tongareva"
            }
        ],
        "pk": "CK"
    },
    {
        "_id": "CR",
        "code": "CR",
        "name": "Costa Rica",
        "states": [
            {
                "_id": "alajuela",
                "code": "alajuela",
                "name": "Alajuela",
                "pk": "CR__alajuela"
            },
            {
                "_id": "cartago",
                "code": "cartago",
                "name": "Cartago",
                "pk": "CR__cartago"
            },
            {
                "_id": "guanacaste",
                "code": "guanacaste",
                "name": "Guanacaste",
                "pk": "CR__guanacaste"
            },
            {
                "_id": "heredia",
                "code": "heredia",
                "name": "Heredia",
                "pk": "CR__heredia"
            },
            {
                "_id": "limon",
                "code": "limon",
                "name": "Limon",
                "pk": "CR__limon"
            },
            {
                "_id": "puntarenas",
                "code": "puntarenas",
                "name": "Puntarenas",
                "pk": "CR__puntarenas"
            },
            {
                "_id": "san_jose",
                "code": "san_jose",
                "name": "San Jose",
                "pk": "CR__san_jose"
            }
        ],
        "pk": "CR"
    },
    {
        "_id": "CI",
        "code": "CI",
        "name": "Côte d'Ivoire",
        "states": [
            {
                "_id": "abidjan",
                "code": "abidjan",
                "name": "Abidjan",
                "pk": "CI__abidjan"
            },
            {
                "_id": "agneby",
                "code": "agneby",
                "name": "Agneby",
                "pk": "CI__agneby"
            },
            {
                "_id": "bafing",
                "code": "bafing",
                "name": "Bafing",
                "pk": "CI__bafing"
            },
            {
                "_id": "denguele",
                "code": "denguele",
                "name": "Denguele",
                "pk": "CI__denguele"
            },
            {
                "_id": "dix-huit_montagnes",
                "code": "dix-huit_montagnes",
                "name": "Dix-huit Montagnes",
                "pk": "CI__dix-huit_montagnes"
            },
            {
                "_id": "fromager",
                "code": "fromager",
                "name": "Fromager",
                "pk": "CI__fromager"
            },
            {
                "_id": "haut-sassandra",
                "code": "haut-sassandra",
                "name": "Haut-Sassandra",
                "pk": "CI__haut-sassandra"
            },
            {
                "_id": "lacs",
                "code": "lacs",
                "name": "Lacs",
                "pk": "CI__lacs"
            },
            {
                "_id": "lagunes",
                "code": "lagunes",
                "name": "Lagunes",
                "pk": "CI__lagunes"
            },
            {
                "_id": "marahoue",
                "code": "marahoue",
                "name": "Marahoue",
                "pk": "CI__marahoue"
            },
            {
                "_id": "moyen-cavally",
                "code": "moyen-cavally",
                "name": "Moyen-Cavally",
                "pk": "CI__moyen-cavally"
            },
            {
                "_id": "moyen-comoe",
                "code": "moyen-comoe",
                "name": "Moyen-Comoe",
                "pk": "CI__moyen-comoe"
            },
            {
                "_id": "nzi-comoe",
                "code": "nzi-comoe",
                "name": "Nzi-Comoe",
                "pk": "CI__nzi-comoe"
            },
            {
                "_id": "sassandra",
                "code": "sassandra",
                "name": "Sassandra",
                "pk": "CI__sassandra"
            },
            {
                "_id": "savanes",
                "code": "savanes",
                "name": "Savanes",
                "pk": "CI__savanes"
            },
            {
                "_id": "sud-bandama",
                "code": "sud-bandama",
                "name": "Sud-Bandama",
                "pk": "CI__sud-bandama"
            },
            {
                "_id": "sud-comoe",
                "code": "sud-comoe",
                "name": "Sud-Comoe",
                "pk": "CI__sud-comoe"
            },
            {
                "_id": "vallee_du_bandama",
                "code": "vallee_du_bandama",
                "name": "Vallee du Bandama",
                "pk": "CI__vallee_du_bandama"
            },
            {
                "_id": "worodougou",
                "code": "worodougou",
                "name": "Worodougou",
                "pk": "CI__worodougou"
            },
            {
                "_id": "zanzan",
                "code": "zanzan",
                "name": "Zanzan",
                "pk": "CI__zanzan"
            }
        ],
        "pk": "CI"
    },
    {
        "_id": "HR",
        "code": "HR",
        "name": "Croatie",
        "states": [
            {
                "_id": "bjelovar-bilogora",
                "code": "bjelovar-bilogora",
                "name": "Bjelovar-Bilogora",
                "pk": "HR__bjelovar-bilogora"
            },
            {
                "_id": "dubrovnik-neretva",
                "code": "dubrovnik-neretva",
                "name": "Dubrovnik-Neretva",
                "pk": "HR__dubrovnik-neretva"
            },
            {
                "_id": "grad_zagreb",
                "code": "grad_zagreb",
                "name": "Grad Zagreb",
                "pk": "HR__grad_zagreb"
            },
            {
                "_id": "istra",
                "code": "istra",
                "name": "Istra",
                "pk": "HR__istra"
            },
            {
                "_id": "karlovac",
                "code": "karlovac",
                "name": "Karlovac",
                "pk": "HR__karlovac"
            },
            {
                "_id": "koprivnica-krizhevci",
                "code": "koprivnica-krizhevci",
                "name": "Koprivnica-Krizhevci",
                "pk": "HR__koprivnica-krizhevci"
            },
            {
                "_id": "krapina-zagorje",
                "code": "krapina-zagorje",
                "name": "Krapina-Zagorje",
                "pk": "HR__krapina-zagorje"
            },
            {
                "_id": "lika-senj",
                "code": "lika-senj",
                "name": "Lika-Senj",
                "pk": "HR__lika-senj"
            },
            {
                "_id": "medhimurje",
                "code": "medhimurje",
                "name": "Medhimurje",
                "pk": "HR__medhimurje"
            },
            {
                "_id": "medimurska_zupanija",
                "code": "medimurska_zupanija",
                "name": "Medimurska Zupanija",
                "pk": "HR__medimurska_zupanija"
            },
            {
                "_id": "osijek-baranja",
                "code": "osijek-baranja",
                "name": "Osijek-Baranja",
                "pk": "HR__osijek-baranja"
            },
            {
                "_id": "osjecko-baranjska_zupanija",
                "code": "osjecko-baranjska_zupanija",
                "name": "Osjecko-Baranjska Zupanija",
                "pk": "HR__osjecko-baranjska_zupanija"
            },
            {
                "_id": "pozhega-slavonija",
                "code": "pozhega-slavonija",
                "name": "Pozhega-Slavonija",
                "pk": "HR__pozhega-slavonija"
            },
            {
                "_id": "primorje-gorski_kotar",
                "code": "primorje-gorski_kotar",
                "name": "Primorje-Gorski Kotar",
                "pk": "HR__primorje-gorski_kotar"
            },
            {
                "_id": "shibenik-knin",
                "code": "shibenik-knin",
                "name": "Shibenik-Knin",
                "pk": "HR__shibenik-knin"
            },
            {
                "_id": "sisak-moslavina",
                "code": "sisak-moslavina",
                "name": "Sisak-Moslavina",
                "pk": "HR__sisak-moslavina"
            },
            {
                "_id": "slavonski_brod-posavina",
                "code": "slavonski_brod-posavina",
                "name": "Slavonski Brod-Posavina",
                "pk": "HR__slavonski_brod-posavina"
            },
            {
                "_id": "split-dalmacija",
                "code": "split-dalmacija",
                "name": "Split-Dalmacija",
                "pk": "HR__split-dalmacija"
            },
            {
                "_id": "varazhdin",
                "code": "varazhdin",
                "name": "Varazhdin",
                "pk": "HR__varazhdin"
            },
            {
                "_id": "virovitica-podravina",
                "code": "virovitica-podravina",
                "name": "Virovitica-Podravina",
                "pk": "HR__virovitica-podravina"
            },
            {
                "_id": "vukovar-srijem",
                "code": "vukovar-srijem",
                "name": "Vukovar-Srijem",
                "pk": "HR__vukovar-srijem"
            },
            {
                "_id": "zadar",
                "code": "zadar",
                "name": "Zadar",
                "pk": "HR__zadar"
            },
            {
                "_id": "zagreb",
                "code": "zagreb",
                "name": "Zagreb",
                "pk": "HR__zagreb"
            }
        ],
        "pk": "HR"
    },
    {
        "_id": "CU",
        "code": "CU",
        "name": "Cuba",
        "states": [
            {
                "_id": "camaguey",
                "code": "camaguey",
                "name": "Camaguey",
                "pk": "CU__camaguey"
            },
            {
                "_id": "ciego_de_avila",
                "code": "ciego_de_avila",
                "name": "Ciego de Avila",
                "pk": "CU__ciego_de_avila"
            },
            {
                "_id": "cienfuegos",
                "code": "cienfuegos",
                "name": "Cienfuegos",
                "pk": "CU__cienfuegos"
            },
            {
                "_id": "ciudad_de_la_habana",
                "code": "ciudad_de_la_habana",
                "name": "Ciudad de la Habana",
                "pk": "CU__ciudad_de_la_habana"
            },
            {
                "_id": "granma",
                "code": "granma",
                "name": "Granma",
                "pk": "CU__granma"
            },
            {
                "_id": "guantanamo",
                "code": "guantanamo",
                "name": "Guantanamo",
                "pk": "CU__guantanamo"
            },
            {
                "_id": "habana",
                "code": "habana",
                "name": "Habana",
                "pk": "CU__habana"
            },
            {
                "_id": "holguin",
                "code": "holguin",
                "name": "Holguin",
                "pk": "CU__holguin"
            },
            {
                "_id": "isla_de_la_juventud",
                "code": "isla_de_la_juventud",
                "name": "Isla de la Juventud",
                "pk": "CU__isla_de_la_juventud"
            },
            {
                "_id": "la_habana",
                "code": "la_habana",
                "name": "La Habana",
                "pk": "CU__la_habana"
            },
            {
                "_id": "las_tunas",
                "code": "las_tunas",
                "name": "Las Tunas",
                "pk": "CU__las_tunas"
            },
            {
                "_id": "matanzas",
                "code": "matanzas",
                "name": "Matanzas",
                "pk": "CU__matanzas"
            },
            {
                "_id": "pinar_del_rio",
                "code": "pinar_del_rio",
                "name": "Pinar del Rio",
                "pk": "CU__pinar_del_rio"
            },
            {
                "_id": "sancti_spiritus",
                "code": "sancti_spiritus",
                "name": "Sancti Spiritus",
                "pk": "CU__sancti_spiritus"
            },
            {
                "_id": "santiago_de_cuba",
                "code": "santiago_de_cuba",
                "name": "Santiago de Cuba",
                "pk": "CU__santiago_de_cuba"
            },
            {
                "_id": "villa_clara",
                "code": "villa_clara",
                "name": "Villa Clara",
                "pk": "CU__villa_clara"
            }
        ],
        "pk": "CU"
    },
    {
        "_id": "CY",
        "code": "CY",
        "name": "Chypre",
        "states": [
            {
                "_id": "government_controlled_area",
                "code": "government_controlled_area",
                "name": "Government controlled area",
                "pk": "CY__government_controlled_area"
            },
            {
                "_id": "limassol",
                "code": "limassol",
                "name": "Limassol",
                "pk": "CY__limassol"
            },
            {
                "_id": "nicosia_district",
                "code": "nicosia_district",
                "name": "Nicosia District",
                "pk": "CY__nicosia_district"
            },
            {
                "_id": "paphos",
                "code": "paphos",
                "name": "Paphos",
                "pk": "CY__paphos"
            },
            {
                "_id": "turkish_controlled_area",
                "code": "turkish_controlled_area",
                "name": "Turkish controlled area",
                "pk": "CY__turkish_controlled_area"
            }
        ],
        "pk": "CY"
    },
    {
        "_id": "CZ",
        "code": "CZ",
        "name": "République tchèque",
        "states": [
            {
                "_id": "central_bohemian",
                "code": "central_bohemian",
                "name": "Central Bohemian",
                "pk": "CZ__central_bohemian"
            },
            {
                "_id": "frycovice",
                "code": "frycovice",
                "name": "Frycovice",
                "pk": "CZ__frycovice"
            },
            {
                "_id": "jihocesky_kraj",
                "code": "jihocesky_kraj",
                "name": "Jihocesky Kraj",
                "pk": "CZ__jihocesky_kraj"
            },
            {
                "_id": "jihochesky",
                "code": "jihochesky",
                "name": "Jihochesky",
                "pk": "CZ__jihochesky"
            },
            {
                "_id": "jihomoravsky",
                "code": "jihomoravsky",
                "name": "Jihomoravsky",
                "pk": "CZ__jihomoravsky"
            },
            {
                "_id": "karlovarsky",
                "code": "karlovarsky",
                "name": "Karlovarsky",
                "pk": "CZ__karlovarsky"
            },
            {
                "_id": "klecany",
                "code": "klecany",
                "name": "Klecany",
                "pk": "CZ__klecany"
            },
            {
                "_id": "kralovehradecky",
                "code": "kralovehradecky",
                "name": "Kralovehradecky",
                "pk": "CZ__kralovehradecky"
            },
            {
                "_id": "liberecky",
                "code": "liberecky",
                "name": "Liberecky",
                "pk": "CZ__liberecky"
            },
            {
                "_id": "lipov",
                "code": "lipov",
                "name": "Lipov",
                "pk": "CZ__lipov"
            },
            {
                "_id": "moravskoslezsky",
                "code": "moravskoslezsky",
                "name": "Moravskoslezsky",
                "pk": "CZ__moravskoslezsky"
            },
            {
                "_id": "olomoucky",
                "code": "olomoucky",
                "name": "Olomoucky",
                "pk": "CZ__olomoucky"
            },
            {
                "_id": "olomoucky_kraj",
                "code": "olomoucky_kraj",
                "name": "Olomoucky Kraj",
                "pk": "CZ__olomoucky_kraj"
            },
            {
                "_id": "pardubicky",
                "code": "pardubicky",
                "name": "Pardubicky",
                "pk": "CZ__pardubicky"
            },
            {
                "_id": "plzensky",
                "code": "plzensky",
                "name": "Plzensky",
                "pk": "CZ__plzensky"
            },
            {
                "_id": "praha",
                "code": "praha",
                "name": "Praha",
                "pk": "CZ__praha"
            },
            {
                "_id": "rajhrad",
                "code": "rajhrad",
                "name": "Rajhrad",
                "pk": "CZ__rajhrad"
            },
            {
                "_id": "smirice",
                "code": "smirice",
                "name": "Smirice",
                "pk": "CZ__smirice"
            },
            {
                "_id": "south_moravian",
                "code": "south_moravian",
                "name": "South Moravian",
                "pk": "CZ__south_moravian"
            },
            {
                "_id": "straz_nad_nisou",
                "code": "straz_nad_nisou",
                "name": "Straz nad Nisou",
                "pk": "CZ__straz_nad_nisou"
            },
            {
                "_id": "stredochesky",
                "code": "stredochesky",
                "name": "Stredochesky",
                "pk": "CZ__stredochesky"
            },
            {
                "_id": "unicov",
                "code": "unicov",
                "name": "Unicov",
                "pk": "CZ__unicov"
            },
            {
                "_id": "ustecky",
                "code": "ustecky",
                "name": "Ustecky",
                "pk": "CZ__ustecky"
            },
            {
                "_id": "valletta",
                "code": "valletta",
                "name": "Valletta",
                "pk": "CZ__valletta"
            },
            {
                "_id": "velesin",
                "code": "velesin",
                "name": "Velesin",
                "pk": "CZ__velesin"
            },
            {
                "_id": "vysochina",
                "code": "vysochina",
                "name": "Vysochina",
                "pk": "CZ__vysochina"
            },
            {
                "_id": "zlinsky",
                "code": "zlinsky",
                "name": "Zlinsky",
                "pk": "CZ__zlinsky"
            }
        ],
        "pk": "CZ"
    },
    {
        "_id": "CD",
        "code": "CD",
        "name": "République démocratique du Congo",
        "states": [
            {
                "_id": "bandundu",
                "code": "bandundu",
                "name": "Bandundu",
                "pk": "CD__bandundu"
            },
            {
                "_id": "bas-congo",
                "code": "bas-congo",
                "name": "Bas-Congo",
                "pk": "CD__bas-congo"
            },
            {
                "_id": "equateur",
                "code": "equateur",
                "name": "Equateur",
                "pk": "CD__equateur"
            },
            {
                "_id": "haut-congo",
                "code": "haut-congo",
                "name": "Haut-Congo",
                "pk": "CD__haut-congo"
            },
            {
                "_id": "kasai-occidental",
                "code": "kasai-occidental",
                "name": "Kasai-Occidental",
                "pk": "CD__kasai-occidental"
            },
            {
                "_id": "kasai-oriental",
                "code": "kasai-oriental",
                "name": "Kasai-Oriental",
                "pk": "CD__kasai-oriental"
            },
            {
                "_id": "katanga",
                "code": "katanga",
                "name": "Katanga",
                "pk": "CD__katanga"
            },
            {
                "_id": "kinshasa",
                "code": "kinshasa",
                "name": "Kinshasa",
                "pk": "CD__kinshasa"
            },
            {
                "_id": "maniema",
                "code": "maniema",
                "name": "Maniema",
                "pk": "CD__maniema"
            },
            {
                "_id": "nord-kivu",
                "code": "nord-kivu",
                "name": "Nord-Kivu",
                "pk": "CD__nord-kivu"
            },
            {
                "_id": "sud-kivu",
                "code": "sud-kivu",
                "name": "Sud-Kivu",
                "pk": "CD__sud-kivu"
            }
        ],
        "pk": "CD"
    },
    {
        "_id": "DK",
        "code": "DK",
        "name": "Danemark",
        "states": [
            {
                "_id": "arhus",
                "code": "arhus",
                "name": "Arhus",
                "pk": "DK__arhus"
            },
            {
                "_id": "bornholm",
                "code": "bornholm",
                "name": "Bornholm",
                "pk": "DK__bornholm"
            },
            {
                "_id": "frederiksborg",
                "code": "frederiksborg",
                "name": "Frederiksborg",
                "pk": "DK__frederiksborg"
            },
            {
                "_id": "fyn",
                "code": "fyn",
                "name": "Fyn",
                "pk": "DK__fyn"
            },
            {
                "_id": "hovedstaden",
                "code": "hovedstaden",
                "name": "Hovedstaden",
                "pk": "DK__hovedstaden"
            },
            {
                "_id": "kobenhavn",
                "code": "kobenhavn",
                "name": "Kobenhavn",
                "pk": "DK__kobenhavn"
            },
            {
                "_id": "kobenhavns_amt",
                "code": "kobenhavns_amt",
                "name": "Kobenhavns Amt",
                "pk": "DK__kobenhavns_amt"
            },
            {
                "_id": "kobenhavns_kommune",
                "code": "kobenhavns_kommune",
                "name": "Kobenhavns Kommune",
                "pk": "DK__kobenhavns_kommune"
            },
            {
                "_id": "nordjylland",
                "code": "nordjylland",
                "name": "Nordjylland",
                "pk": "DK__nordjylland"
            },
            {
                "_id": "ribe",
                "code": "ribe",
                "name": "Ribe",
                "pk": "DK__ribe"
            },
            {
                "_id": "ringkobing",
                "code": "ringkobing",
                "name": "Ringkobing",
                "pk": "DK__ringkobing"
            },
            {
                "_id": "roervig",
                "code": "roervig",
                "name": "Roervig",
                "pk": "DK__roervig"
            },
            {
                "_id": "roskilde",
                "code": "roskilde",
                "name": "Roskilde",
                "pk": "DK__roskilde"
            },
            {
                "_id": "roslev",
                "code": "roslev",
                "name": "Roslev",
                "pk": "DK__roslev"
            },
            {
                "_id": "sjaelland",
                "code": "sjaelland",
                "name": "Sjaelland",
                "pk": "DK__sjaelland"
            },
            {
                "_id": "soeborg",
                "code": "soeborg",
                "name": "Soeborg",
                "pk": "DK__soeborg"
            },
            {
                "_id": "sonderjylland",
                "code": "sonderjylland",
                "name": "Sonderjylland",
                "pk": "DK__sonderjylland"
            },
            {
                "_id": "storstrom",
                "code": "storstrom",
                "name": "Storstrom",
                "pk": "DK__storstrom"
            },
            {
                "_id": "syddanmark",
                "code": "syddanmark",
                "name": "Syddanmark",
                "pk": "DK__syddanmark"
            },
            {
                "_id": "toelloese",
                "code": "toelloese",
                "name": "Toelloese",
                "pk": "DK__toelloese"
            },
            {
                "_id": "vejle",
                "code": "vejle",
                "name": "Vejle",
                "pk": "DK__vejle"
            },
            {
                "_id": "vestsjalland",
                "code": "vestsjalland",
                "name": "Vestsjalland",
                "pk": "DK__vestsjalland"
            },
            {
                "_id": "viborg",
                "code": "viborg",
                "name": "Viborg",
                "pk": "DK__viborg"
            }
        ],
        "only_shipping": true,
        "pk": "DK"
    },
    {
        "_id": "DJ",
        "code": "DJ",
        "name": "Djibouti",
        "states": [
            {
                "_id": "ali_sabih",
                "code": "ali_sabih",
                "name": "Ali Sabih",
                "pk": "DJ__ali_sabih"
            },
            {
                "_id": "dikhil",
                "code": "dikhil",
                "name": "Dikhil",
                "pk": "DJ__dikhil"
            },
            {
                "_id": "jibuti",
                "code": "jibuti",
                "name": "Jibuti",
                "pk": "DJ__jibuti"
            },
            {
                "_id": "tajurah",
                "code": "tajurah",
                "name": "Tajurah",
                "pk": "DJ__tajurah"
            },
            {
                "_id": "ubuk",
                "code": "ubuk",
                "name": "Ubuk",
                "pk": "DJ__ubuk"
            }
        ],
        "pk": "DJ"
    },
    {
        "_id": "DM",
        "code": "DM",
        "name": "Dominique",
        "states": [
            {
                "_id": "saint_andrew",
                "code": "saint_andrew",
                "name": "Saint Andrew",
                "pk": "DM__saint_andrew"
            },
            {
                "_id": "saint_david",
                "code": "saint_david",
                "name": "Saint David",
                "pk": "DM__saint_david"
            },
            {
                "_id": "saint_george",
                "code": "saint_george",
                "name": "Saint George",
                "pk": "DM__saint_george"
            },
            {
                "_id": "saint_john",
                "code": "saint_john",
                "name": "Saint John",
                "pk": "DM__saint_john"
            },
            {
                "_id": "saint_joseph",
                "code": "saint_joseph",
                "name": "Saint Joseph",
                "pk": "DM__saint_joseph"
            },
            {
                "_id": "saint_luke",
                "code": "saint_luke",
                "name": "Saint Luke",
                "pk": "DM__saint_luke"
            },
            {
                "_id": "saint_mark",
                "code": "saint_mark",
                "name": "Saint Mark",
                "pk": "DM__saint_mark"
            },
            {
                "_id": "saint_patrick",
                "code": "saint_patrick",
                "name": "Saint Patrick",
                "pk": "DM__saint_patrick"
            },
            {
                "_id": "saint_paul",
                "code": "saint_paul",
                "name": "Saint Paul",
                "pk": "DM__saint_paul"
            },
            {
                "_id": "saint_peter",
                "code": "saint_peter",
                "name": "Saint Peter",
                "pk": "DM__saint_peter"
            }
        ],
        "pk": "DM"
    },
    {
        "_id": "DO",
        "code": "DO",
        "name": "République dominicaine",
        "states": [
            {
                "_id": "azua",
                "code": "azua",
                "name": "Azua",
                "pk": "DO__azua"
            },
            {
                "_id": "bahoruco",
                "code": "bahoruco",
                "name": "Bahoruco",
                "pk": "DO__bahoruco"
            },
            {
                "_id": "barahona",
                "code": "barahona",
                "name": "Barahona",
                "pk": "DO__barahona"
            },
            {
                "_id": "dajabon",
                "code": "dajabon",
                "name": "Dajabon",
                "pk": "DO__dajabon"
            },
            {
                "_id": "distrito_nacional",
                "code": "distrito_nacional",
                "name": "Distrito Nacional",
                "pk": "DO__distrito_nacional"
            },
            {
                "_id": "duarte",
                "code": "duarte",
                "name": "Duarte",
                "pk": "DO__duarte"
            },
            {
                "_id": "el_seybo",
                "code": "el_seybo",
                "name": "El Seybo",
                "pk": "DO__el_seybo"
            },
            {
                "_id": "elias_pina",
                "code": "elias_pina",
                "name": "Elias Pina",
                "pk": "DO__elias_pina"
            },
            {
                "_id": "espaillat",
                "code": "espaillat",
                "name": "Espaillat",
                "pk": "DO__espaillat"
            },
            {
                "_id": "hato_mayor",
                "code": "hato_mayor",
                "name": "Hato Mayor",
                "pk": "DO__hato_mayor"
            },
            {
                "_id": "independencia",
                "code": "independencia",
                "name": "Independencia",
                "pk": "DO__independencia"
            },
            {
                "_id": "la_altagracia",
                "code": "la_altagracia",
                "name": "La Altagracia",
                "pk": "DO__la_altagracia"
            },
            {
                "_id": "la_romana",
                "code": "la_romana",
                "name": "La Romana",
                "pk": "DO__la_romana"
            },
            {
                "_id": "la_vega",
                "code": "la_vega",
                "name": "La Vega",
                "pk": "DO__la_vega"
            },
            {
                "_id": "maria_trinidad_sanchez",
                "code": "maria_trinidad_sanchez",
                "name": "Maria Trinidad Sanchez",
                "pk": "DO__maria_trinidad_sanchez"
            },
            {
                "_id": "monsenor_nouel",
                "code": "monsenor_nouel",
                "name": "Monsenor Nouel",
                "pk": "DO__monsenor_nouel"
            },
            {
                "_id": "monte_cristi",
                "code": "monte_cristi",
                "name": "Monte Cristi",
                "pk": "DO__monte_cristi"
            },
            {
                "_id": "monte_plata",
                "code": "monte_plata",
                "name": "Monte Plata",
                "pk": "DO__monte_plata"
            },
            {
                "_id": "pedernales",
                "code": "pedernales",
                "name": "Pedernales",
                "pk": "DO__pedernales"
            },
            {
                "_id": "peravia",
                "code": "peravia",
                "name": "Peravia",
                "pk": "DO__peravia"
            },
            {
                "_id": "puerto_plata",
                "code": "puerto_plata",
                "name": "Puerto Plata",
                "pk": "DO__puerto_plata"
            },
            {
                "_id": "salcedo",
                "code": "salcedo",
                "name": "Salcedo",
                "pk": "DO__salcedo"
            },
            {
                "_id": "samana",
                "code": "samana",
                "name": "Samana",
                "pk": "DO__samana"
            },
            {
                "_id": "san_cristobal",
                "code": "san_cristobal",
                "name": "San Cristobal",
                "pk": "DO__san_cristobal"
            },
            {
                "_id": "san_juan",
                "code": "san_juan",
                "name": "San Juan",
                "pk": "DO__san_juan"
            },
            {
                "_id": "san_pedro_de_macoris",
                "code": "san_pedro_de_macoris",
                "name": "San Pedro de Macoris",
                "pk": "DO__san_pedro_de_macoris"
            },
            {
                "_id": "sanchez_ramirez",
                "code": "sanchez_ramirez",
                "name": "Sanchez Ramirez",
                "pk": "DO__sanchez_ramirez"
            },
            {
                "_id": "santiago",
                "code": "santiago",
                "name": "Santiago",
                "pk": "DO__santiago"
            },
            {
                "_id": "santiago_rodriguez",
                "code": "santiago_rodriguez",
                "name": "Santiago Rodriguez",
                "pk": "DO__santiago_rodriguez"
            },
            {
                "_id": "valverde",
                "code": "valverde",
                "name": "Valverde",
                "pk": "DO__valverde"
            }
        ],
        "pk": "DO"
    },
    {
        "_id": "TP",
        "code": "TP",
        "name": "Timor oriental",
        "states": [
            {
                "_id": "aileu",
                "code": "aileu",
                "name": "Aileu",
                "pk": "TP__aileu"
            },
            {
                "_id": "ainaro",
                "code": "ainaro",
                "name": "Ainaro",
                "pk": "TP__ainaro"
            },
            {
                "_id": "ambeno",
                "code": "ambeno",
                "name": "Ambeno",
                "pk": "TP__ambeno"
            },
            {
                "_id": "baucau",
                "code": "baucau",
                "name": "Baucau",
                "pk": "TP__baucau"
            },
            {
                "_id": "bobonaro",
                "code": "bobonaro",
                "name": "Bobonaro",
                "pk": "TP__bobonaro"
            },
            {
                "_id": "cova_lima",
                "code": "cova_lima",
                "name": "Cova Lima",
                "pk": "TP__cova_lima"
            },
            {
                "_id": "dili",
                "code": "dili",
                "name": "Dili",
                "pk": "TP__dili"
            },
            {
                "_id": "ermera",
                "code": "ermera",
                "name": "Ermera",
                "pk": "TP__ermera"
            },
            {
                "_id": "lautem",
                "code": "lautem",
                "name": "Lautem",
                "pk": "TP__lautem"
            },
            {
                "_id": "liquica",
                "code": "liquica",
                "name": "Liquica",
                "pk": "TP__liquica"
            },
            {
                "_id": "manatuto",
                "code": "manatuto",
                "name": "Manatuto",
                "pk": "TP__manatuto"
            },
            {
                "_id": "manufahi",
                "code": "manufahi",
                "name": "Manufahi",
                "pk": "TP__manufahi"
            },
            {
                "_id": "viqueque",
                "code": "viqueque",
                "name": "Viqueque",
                "pk": "TP__viqueque"
            }
        ],
        "pk": "TP"
    },
    {
        "_id": "EC",
        "code": "EC",
        "name": "Équateur",
        "states": [
            {
                "_id": "azuay",
                "code": "azuay",
                "name": "Azuay",
                "pk": "EC__azuay"
            },
            {
                "_id": "bolivar",
                "code": "bolivar",
                "name": "Bolivar",
                "pk": "EC__bolivar"
            },
            {
                "_id": "canar",
                "code": "canar",
                "name": "Canar",
                "pk": "EC__canar"
            },
            {
                "_id": "carchi",
                "code": "carchi",
                "name": "Carchi",
                "pk": "EC__carchi"
            },
            {
                "_id": "chimborazo",
                "code": "chimborazo",
                "name": "Chimborazo",
                "pk": "EC__chimborazo"
            },
            {
                "_id": "cotopaxi",
                "code": "cotopaxi",
                "name": "Cotopaxi",
                "pk": "EC__cotopaxi"
            },
            {
                "_id": "el_oro",
                "code": "el_oro",
                "name": "El Oro",
                "pk": "EC__el_oro"
            },
            {
                "_id": "esmeraldas",
                "code": "esmeraldas",
                "name": "Esmeraldas",
                "pk": "EC__esmeraldas"
            },
            {
                "_id": "galapagos",
                "code": "galapagos",
                "name": "Galapagos",
                "pk": "EC__galapagos"
            },
            {
                "_id": "guayas",
                "code": "guayas",
                "name": "Guayas",
                "pk": "EC__guayas"
            },
            {
                "_id": "imbabura",
                "code": "imbabura",
                "name": "Imbabura",
                "pk": "EC__imbabura"
            },
            {
                "_id": "loja",
                "code": "loja",
                "name": "Loja",
                "pk": "EC__loja"
            },
            {
                "_id": "los_rios",
                "code": "los_rios",
                "name": "Los Rios",
                "pk": "EC__los_rios"
            },
            {
                "_id": "manabi",
                "code": "manabi",
                "name": "Manabi",
                "pk": "EC__manabi"
            },
            {
                "_id": "morona_santiago",
                "code": "morona_santiago",
                "name": "Morona Santiago",
                "pk": "EC__morona_santiago"
            },
            {
                "_id": "napo",
                "code": "napo",
                "name": "Napo",
                "pk": "EC__napo"
            },
            {
                "_id": "orellana",
                "code": "orellana",
                "name": "Orellana",
                "pk": "EC__orellana"
            },
            {
                "_id": "pastaza",
                "code": "pastaza",
                "name": "Pastaza",
                "pk": "EC__pastaza"
            },
            {
                "_id": "pichincha",
                "code": "pichincha",
                "name": "Pichincha",
                "pk": "EC__pichincha"
            },
            {
                "_id": "sucumbios",
                "code": "sucumbios",
                "name": "Sucumbios",
                "pk": "EC__sucumbios"
            },
            {
                "_id": "tungurahua",
                "code": "tungurahua",
                "name": "Tungurahua",
                "pk": "EC__tungurahua"
            },
            {
                "_id": "zamora_chinchipe",
                "code": "zamora_chinchipe",
                "name": "Zamora Chinchipe",
                "pk": "EC__zamora_chinchipe"
            }
        ],
        "pk": "EC"
    },
    {
        "_id": "EG",
        "code": "EG",
        "name": "Égypte",
        "states": [
            {
                "_id": "aswan",
                "code": "aswan",
                "name": "Aswan",
                "pk": "EG__aswan"
            },
            {
                "_id": "asyut",
                "code": "asyut",
                "name": "Asyut",
                "pk": "EG__asyut"
            },
            {
                "_id": "bani_suwayf",
                "code": "bani_suwayf",
                "name": "Bani Suwayf",
                "pk": "EG__bani_suwayf"
            },
            {
                "_id": "bur_said",
                "code": "bur_said",
                "name": "Bur Said",
                "pk": "EG__bur_said"
            },
            {
                "_id": "cairo",
                "code": "cairo",
                "name": "Cairo",
                "pk": "EG__cairo"
            },
            {
                "_id": "dumyat",
                "code": "dumyat",
                "name": "Dumyat",
                "pk": "EG__dumyat"
            },
            {
                "_id": "kafr-ash-shaykh",
                "code": "kafr-ash-shaykh",
                "name": "Kafr-ash-Shaykh",
                "pk": "EG__kafr-ash-shaykh"
            },
            {
                "_id": "matruh",
                "code": "matruh",
                "name": "Matruh",
                "pk": "EG__matruh"
            },
            {
                "_id": "muhafazat_ad_daqahliyah",
                "code": "muhafazat_ad_daqahliyah",
                "name": "Muhafazat ad Daqahliyah",
                "pk": "EG__muhafazat_ad_daqahliyah"
            },
            {
                "_id": "muhafazat_al_fayyum",
                "code": "muhafazat_al_fayyum",
                "name": "Muhafazat al Fayyum",
                "pk": "EG__muhafazat_al_fayyum"
            },
            {
                "_id": "muhafazat_al_gharbiyah",
                "code": "muhafazat_al_gharbiyah",
                "name": "Muhafazat al Gharbiyah",
                "pk": "EG__muhafazat_al_gharbiyah"
            },
            {
                "_id": "muhafazat_al_iskandariyah",
                "code": "muhafazat_al_iskandariyah",
                "name": "Muhafazat al Iskandariyah",
                "pk": "EG__muhafazat_al_iskandariyah"
            },
            {
                "_id": "muhafazat_al_qahirah",
                "code": "muhafazat_al_qahirah",
                "name": "Muhafazat al Qahirah",
                "pk": "EG__muhafazat_al_qahirah"
            },
            {
                "_id": "qina",
                "code": "qina",
                "name": "Qina",
                "pk": "EG__qina"
            },
            {
                "_id": "sawhaj",
                "code": "sawhaj",
                "name": "Sawhaj",
                "pk": "EG__sawhaj"
            },
            {
                "_id": "sina_al-janubiyah",
                "code": "sina_al-janubiyah",
                "name": "Sina al-Janubiyah",
                "pk": "EG__sina_al-janubiyah"
            },
            {
                "_id": "sina_ash-shamaliyah",
                "code": "sina_ash-shamaliyah",
                "name": "Sina ash-Shamaliyah",
                "pk": "EG__sina_ash-shamaliyah"
            },
            {
                "_id": "ad-daqahliyah",
                "code": "ad-daqahliyah",
                "name": "ad-Daqahliyah",
                "pk": "EG__ad-daqahliyah"
            },
            {
                "_id": "al-bahr-al-ahmar",
                "code": "al-bahr-al-ahmar",
                "name": "al-Bahr-al-Ahmar",
                "pk": "EG__al-bahr-al-ahmar"
            },
            {
                "_id": "al-buhayrah",
                "code": "al-buhayrah",
                "name": "al-Buhayrah",
                "pk": "EG__al-buhayrah"
            },
            {
                "_id": "al-fayyum",
                "code": "al-fayyum",
                "name": "al-Fayyum",
                "pk": "EG__al-fayyum"
            },
            {
                "_id": "al-gharbiyah",
                "code": "al-gharbiyah",
                "name": "al-Gharbiyah",
                "pk": "EG__al-gharbiyah"
            },
            {
                "_id": "al-iskandariyah",
                "code": "al-iskandariyah",
                "name": "al-Iskandariyah",
                "pk": "EG__al-iskandariyah"
            },
            {
                "_id": "al-ismailiyah",
                "code": "al-ismailiyah",
                "name": "al-Ismailiyah",
                "pk": "EG__al-ismailiyah"
            },
            {
                "_id": "al-jizah",
                "code": "al-jizah",
                "name": "al-Jizah",
                "pk": "EG__al-jizah"
            },
            {
                "_id": "al-minufiyah",
                "code": "al-minufiyah",
                "name": "al-Minufiyah",
                "pk": "EG__al-minufiyah"
            },
            {
                "_id": "al-minya",
                "code": "al-minya",
                "name": "al-Minya",
                "pk": "EG__al-minya"
            },
            {
                "_id": "al-qahira",
                "code": "al-qahira",
                "name": "al-Qahira",
                "pk": "EG__al-qahira"
            },
            {
                "_id": "al-qalyubiyah",
                "code": "al-qalyubiyah",
                "name": "al-Qalyubiyah",
                "pk": "EG__al-qalyubiyah"
            },
            {
                "_id": "al-uqsur",
                "code": "al-uqsur",
                "name": "al-Uqsur",
                "pk": "EG__al-uqsur"
            },
            {
                "_id": "al-wadi_al-jadid",
                "code": "al-wadi_al-jadid",
                "name": "al-Wadi al-Jadid",
                "pk": "EG__al-wadi_al-jadid"
            },
            {
                "_id": "as-suways",
                "code": "as-suways",
                "name": "as-Suways",
                "pk": "EG__as-suways"
            },
            {
                "_id": "ash-sharqiyah",
                "code": "ash-sharqiyah",
                "name": "ash-Sharqiyah",
                "pk": "EG__ash-sharqiyah"
            }
        ],
        "pk": "EG"
    },
    {
        "_id": "SV",
        "code": "SV",
        "name": "Salvador",
        "states": [
            {
                "_id": "ahuachapan",
                "code": "ahuachapan",
                "name": "Ahuachapan",
                "pk": "SV__ahuachapan"
            },
            {
                "_id": "cabanas",
                "code": "cabanas",
                "name": "Cabanas",
                "pk": "SV__cabanas"
            },
            {
                "_id": "chalatenango",
                "code": "chalatenango",
                "name": "Chalatenango",
                "pk": "SV__chalatenango"
            },
            {
                "_id": "cuscatlan",
                "code": "cuscatlan",
                "name": "Cuscatlan",
                "pk": "SV__cuscatlan"
            },
            {
                "_id": "la_libertad",
                "code": "la_libertad",
                "name": "La Libertad",
                "pk": "SV__la_libertad"
            },
            {
                "_id": "la_paz",
                "code": "la_paz",
                "name": "La Paz",
                "pk": "SV__la_paz"
            },
            {
                "_id": "la_union",
                "code": "la_union",
                "name": "La Union",
                "pk": "SV__la_union"
            },
            {
                "_id": "morazan",
                "code": "morazan",
                "name": "Morazan",
                "pk": "SV__morazan"
            },
            {
                "_id": "san_miguel",
                "code": "san_miguel",
                "name": "San Miguel",
                "pk": "SV__san_miguel"
            },
            {
                "_id": "san_salvador",
                "code": "san_salvador",
                "name": "San Salvador",
                "pk": "SV__san_salvador"
            },
            {
                "_id": "san_vicente",
                "code": "san_vicente",
                "name": "San Vicente",
                "pk": "SV__san_vicente"
            },
            {
                "_id": "santa_ana",
                "code": "santa_ana",
                "name": "Santa Ana",
                "pk": "SV__santa_ana"
            },
            {
                "_id": "sonsonate",
                "code": "sonsonate",
                "name": "Sonsonate",
                "pk": "SV__sonsonate"
            },
            {
                "_id": "usulutan",
                "code": "usulutan",
                "name": "Usulutan",
                "pk": "SV__usulutan"
            }
        ],
        "pk": "SV"
    },
    {
        "_id": "GQ",
        "code": "GQ",
        "name": "Guinée équatoriale",
        "states": [
            {
                "_id": "annobon",
                "code": "annobon",
                "name": "Annobon",
                "pk": "GQ__annobon"
            },
            {
                "_id": "bioko_norte",
                "code": "bioko_norte",
                "name": "Bioko Norte",
                "pk": "GQ__bioko_norte"
            },
            {
                "_id": "bioko_sur",
                "code": "bioko_sur",
                "name": "Bioko Sur",
                "pk": "GQ__bioko_sur"
            },
            {
                "_id": "centro_sur",
                "code": "centro_sur",
                "name": "Centro Sur",
                "pk": "GQ__centro_sur"
            },
            {
                "_id": "kie-ntem",
                "code": "kie-ntem",
                "name": "Kie-Ntem",
                "pk": "GQ__kie-ntem"
            },
            {
                "_id": "litoral",
                "code": "litoral",
                "name": "Litoral",
                "pk": "GQ__litoral"
            },
            {
                "_id": "wele-nzas",
                "code": "wele-nzas",
                "name": "Wele-Nzas",
                "pk": "GQ__wele-nzas"
            }
        ],
        "pk": "GQ"
    },
    {
        "_id": "ER",
        "code": "ER",
        "name": "Érythrée",
        "states": [
            {
                "_id": "anseba",
                "code": "anseba",
                "name": "Anseba",
                "pk": "ER__anseba"
            },
            {
                "_id": "debub",
                "code": "debub",
                "name": "Debub",
                "pk": "ER__debub"
            },
            {
                "_id": "debub-keih-bahri",
                "code": "debub-keih-bahri",
                "name": "Debub-Keih-Bahri",
                "pk": "ER__debub-keih-bahri"
            },
            {
                "_id": "gash-barka",
                "code": "gash-barka",
                "name": "Gash-Barka",
                "pk": "ER__gash-barka"
            },
            {
                "_id": "maekel",
                "code": "maekel",
                "name": "Maekel",
                "pk": "ER__maekel"
            },
            {
                "_id": "semien-keih-bahri",
                "code": "semien-keih-bahri",
                "name": "Semien-Keih-Bahri",
                "pk": "ER__semien-keih-bahri"
            }
        ],
        "pk": "ER"
    },
    {
        "_id": "EE",
        "code": "EE",
        "name": "Estonie",
        "states": [
            {
                "_id": "harju",
                "code": "harju",
                "name": "Harju",
                "pk": "EE__harju"
            },
            {
                "_id": "hiiu",
                "code": "hiiu",
                "name": "Hiiu",
                "pk": "EE__hiiu"
            },
            {
                "_id": "ida-viru",
                "code": "ida-viru",
                "name": "Ida-Viru",
                "pk": "EE__ida-viru"
            },
            {
                "_id": "jarva",
                "code": "jarva",
                "name": "Jarva",
                "pk": "EE__jarva"
            },
            {
                "_id": "jogeva",
                "code": "jogeva",
                "name": "Jogeva",
                "pk": "EE__jogeva"
            },
            {
                "_id": "laane",
                "code": "laane",
                "name": "Laane",
                "pk": "EE__laane"
            },
            {
                "_id": "laane-viru",
                "code": "laane-viru",
                "name": "Laane-Viru",
                "pk": "EE__laane-viru"
            },
            {
                "_id": "parnu",
                "code": "parnu",
                "name": "Parnu",
                "pk": "EE__parnu"
            },
            {
                "_id": "polva",
                "code": "polva",
                "name": "Polva",
                "pk": "EE__polva"
            },
            {
                "_id": "rapla",
                "code": "rapla",
                "name": "Rapla",
                "pk": "EE__rapla"
            },
            {
                "_id": "saare",
                "code": "saare",
                "name": "Saare",
                "pk": "EE__saare"
            },
            {
                "_id": "tartu",
                "code": "tartu",
                "name": "Tartu",
                "pk": "EE__tartu"
            },
            {
                "_id": "valga",
                "code": "valga",
                "name": "Valga",
                "pk": "EE__valga"
            },
            {
                "_id": "viljandi",
                "code": "viljandi",
                "name": "Viljandi",
                "pk": "EE__viljandi"
            },
            {
                "_id": "voru",
                "code": "voru",
                "name": "Voru",
                "pk": "EE__voru"
            }
        ],
        "pk": "EE"
    },
    {
        "_id": "ET",
        "code": "ET",
        "name": "Éthiopie",
        "states": [
            {
                "_id": "addis_abeba",
                "code": "addis_abeba",
                "name": "Addis Abeba",
                "pk": "ET__addis_abeba"
            },
            {
                "_id": "afar",
                "code": "afar",
                "name": "Afar",
                "pk": "ET__afar"
            },
            {
                "_id": "amhara",
                "code": "amhara",
                "name": "Amhara",
                "pk": "ET__amhara"
            },
            {
                "_id": "benishangul",
                "code": "benishangul",
                "name": "Benishangul",
                "pk": "ET__benishangul"
            },
            {
                "_id": "diredawa",
                "code": "diredawa",
                "name": "Diredawa",
                "pk": "ET__diredawa"
            },
            {
                "_id": "gambella",
                "code": "gambella",
                "name": "Gambella",
                "pk": "ET__gambella"
            },
            {
                "_id": "harar",
                "code": "harar",
                "name": "Harar",
                "pk": "ET__harar"
            },
            {
                "_id": "jigjiga",
                "code": "jigjiga",
                "name": "Jigjiga",
                "pk": "ET__jigjiga"
            },
            {
                "_id": "mekele",
                "code": "mekele",
                "name": "Mekele",
                "pk": "ET__mekele"
            },
            {
                "_id": "oromia",
                "code": "oromia",
                "name": "Oromia",
                "pk": "ET__oromia"
            },
            {
                "_id": "somali",
                "code": "somali",
                "name": "Somali",
                "pk": "ET__somali"
            },
            {
                "_id": "southern",
                "code": "southern",
                "name": "Southern",
                "pk": "ET__southern"
            },
            {
                "_id": "tigray",
                "code": "tigray",
                "name": "Tigray",
                "pk": "ET__tigray"
            }
        ],
        "pk": "ET"
    },
    {
        "_id": "XA",
        "code": "XA",
        "name": "Îles extérieures de l'Australie",
        "states": [
            {
                "_id": "christmas_island",
                "code": "christmas_island",
                "name": "Christmas Island",
                "pk": "XA__christmas_island"
            },
            {
                "_id": "cocos_islands",
                "code": "cocos_islands",
                "name": "Cocos Islands",
                "pk": "XA__cocos_islands"
            },
            {
                "_id": "coral_sea_islands",
                "code": "coral_sea_islands",
                "name": "Coral Sea Islands",
                "pk": "XA__coral_sea_islands"
            }
        ],
        "pk": "XA"
    },
    {
        "_id": "FK",
        "code": "FK",
        "name": "Îles Falkland",
        "states": [
            {
                "_id": "falkland_islands",
                "code": "falkland_islands",
                "name": "Falkland Islands",
                "pk": "FK__falkland_islands"
            },
            {
                "_id": "south_georgia",
                "code": "south_georgia",
                "name": "South Georgia",
                "pk": "FK__south_georgia"
            }
        ],
        "pk": "FK"
    },
    {
        "_id": "FO",
        "code": "FO",
        "name": "Îles Féroé",
        "states": [
            {
                "_id": "klaksvik",
                "code": "klaksvik",
                "name": "Klaksvik",
                "pk": "FO__klaksvik"
            },
            {
                "_id": "nor_ara_eysturoy",
                "code": "nor_ara_eysturoy",
                "name": "Nor ara Eysturoy",
                "pk": "FO__nor_ara_eysturoy"
            },
            {
                "_id": "nor_oy",
                "code": "nor_oy",
                "name": "Nor oy",
                "pk": "FO__nor_oy"
            },
            {
                "_id": "sandoy",
                "code": "sandoy",
                "name": "Sandoy",
                "pk": "FO__sandoy"
            },
            {
                "_id": "streymoy",
                "code": "streymoy",
                "name": "Streymoy",
                "pk": "FO__streymoy"
            },
            {
                "_id": "su_uroy",
                "code": "su_uroy",
                "name": "Su uroy",
                "pk": "FO__su_uroy"
            },
            {
                "_id": "sy_ra_eysturoy",
                "code": "sy_ra_eysturoy",
                "name": "Sy ra Eysturoy",
                "pk": "FO__sy_ra_eysturoy"
            },
            {
                "_id": "torshavn",
                "code": "torshavn",
                "name": "Torshavn",
                "pk": "FO__torshavn"
            },
            {
                "_id": "vaga",
                "code": "vaga",
                "name": "Vaga",
                "pk": "FO__vaga"
            }
        ],
        "pk": "FO"
    },
    {
        "_id": "FJ",
        "code": "FJ",
        "name": "Fidji",
        "states": [
            {
                "_id": "central",
                "code": "central",
                "name": "Central",
                "pk": "FJ__central"
            },
            {
                "_id": "eastern",
                "code": "eastern",
                "name": "Eastern",
                "pk": "FJ__eastern"
            },
            {
                "_id": "northern",
                "code": "northern",
                "name": "Northern",
                "pk": "FJ__northern"
            },
            {
                "_id": "south_pacific",
                "code": "south_pacific",
                "name": "South Pacific",
                "pk": "FJ__south_pacific"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "FJ__western"
            }
        ],
        "pk": "FJ"
    },
    {
        "_id": "FI",
        "code": "FI",
        "name": "Finlande",
        "states": [
            {
                "_id": "ahvenanmaa",
                "code": "ahvenanmaa",
                "name": "Ahvenanmaa",
                "pk": "FI__ahvenanmaa"
            },
            {
                "_id": "etela-karjala",
                "code": "etela-karjala",
                "name": "Etela-Karjala",
                "pk": "FI__etela-karjala"
            },
            {
                "_id": "etela-pohjanmaa",
                "code": "etela-pohjanmaa",
                "name": "Etela-Pohjanmaa",
                "pk": "FI__etela-pohjanmaa"
            },
            {
                "_id": "etela-savo",
                "code": "etela-savo",
                "name": "Etela-Savo",
                "pk": "FI__etela-savo"
            },
            {
                "_id": "etela-suomen_laani",
                "code": "etela-suomen_laani",
                "name": "Etela-Suomen Laani",
                "pk": "FI__etela-suomen_laani"
            },
            {
                "_id": "ita-suomen_laani",
                "code": "ita-suomen_laani",
                "name": "Ita-Suomen Laani",
                "pk": "FI__ita-suomen_laani"
            },
            {
                "_id": "ita-uusimaa",
                "code": "ita-uusimaa",
                "name": "Ita-Uusimaa",
                "pk": "FI__ita-uusimaa"
            },
            {
                "_id": "kainuu",
                "code": "kainuu",
                "name": "Kainuu",
                "pk": "FI__kainuu"
            },
            {
                "_id": "kanta-hame",
                "code": "kanta-hame",
                "name": "Kanta-Hame",
                "pk": "FI__kanta-hame"
            },
            {
                "_id": "keski-pohjanmaa",
                "code": "keski-pohjanmaa",
                "name": "Keski-Pohjanmaa",
                "pk": "FI__keski-pohjanmaa"
            },
            {
                "_id": "keski-suomi",
                "code": "keski-suomi",
                "name": "Keski-Suomi",
                "pk": "FI__keski-suomi"
            },
            {
                "_id": "kymenlaakso",
                "code": "kymenlaakso",
                "name": "Kymenlaakso",
                "pk": "FI__kymenlaakso"
            },
            {
                "_id": "lansi-suomen_laani",
                "code": "lansi-suomen_laani",
                "name": "Lansi-Suomen Laani",
                "pk": "FI__lansi-suomen_laani"
            },
            {
                "_id": "lappi",
                "code": "lappi",
                "name": "Lappi",
                "pk": "FI__lappi"
            },
            {
                "_id": "northern_savonia",
                "code": "northern_savonia",
                "name": "Northern Savonia",
                "pk": "FI__northern_savonia"
            },
            {
                "_id": "ostrobothnia",
                "code": "ostrobothnia",
                "name": "Ostrobothnia",
                "pk": "FI__ostrobothnia"
            },
            {
                "_id": "oulun_laani",
                "code": "oulun_laani",
                "name": "Oulun Laani",
                "pk": "FI__oulun_laani"
            },
            {
                "_id": "paijat-hame",
                "code": "paijat-hame",
                "name": "Paijat-Hame",
                "pk": "FI__paijat-hame"
            },
            {
                "_id": "pirkanmaa",
                "code": "pirkanmaa",
                "name": "Pirkanmaa",
                "pk": "FI__pirkanmaa"
            },
            {
                "_id": "pohjanmaa",
                "code": "pohjanmaa",
                "name": "Pohjanmaa",
                "pk": "FI__pohjanmaa"
            },
            {
                "_id": "pohjois-karjala",
                "code": "pohjois-karjala",
                "name": "Pohjois-Karjala",
                "pk": "FI__pohjois-karjala"
            },
            {
                "_id": "pohjois-pohjanmaa",
                "code": "pohjois-pohjanmaa",
                "name": "Pohjois-Pohjanmaa",
                "pk": "FI__pohjois-pohjanmaa"
            },
            {
                "_id": "pohjois-savo",
                "code": "pohjois-savo",
                "name": "Pohjois-Savo",
                "pk": "FI__pohjois-savo"
            },
            {
                "_id": "saarijarvi",
                "code": "saarijarvi",
                "name": "Saarijarvi",
                "pk": "FI__saarijarvi"
            },
            {
                "_id": "satakunta",
                "code": "satakunta",
                "name": "Satakunta",
                "pk": "FI__satakunta"
            },
            {
                "_id": "southern_savonia",
                "code": "southern_savonia",
                "name": "Southern Savonia",
                "pk": "FI__southern_savonia"
            },
            {
                "_id": "tavastia_proper",
                "code": "tavastia_proper",
                "name": "Tavastia Proper",
                "pk": "FI__tavastia_proper"
            },
            {
                "_id": "uleaborgs_lan",
                "code": "uleaborgs_lan",
                "name": "Uleaborgs Lan",
                "pk": "FI__uleaborgs_lan"
            },
            {
                "_id": "uusimaa",
                "code": "uusimaa",
                "name": "Uusimaa",
                "pk": "FI__uusimaa"
            },
            {
                "_id": "varsinais-suomi",
                "code": "varsinais-suomi",
                "name": "Varsinais-Suomi",
                "pk": "FI__varsinais-suomi"
            }
        ],
        "pk": "FI"
    },
    {
        "_id": "FR",
        "code": "FR",
        "name": "France",
        "states": [
            {
                "_id": "ain",
                "code": "ain",
                "name": "Ain",
                "pk": "FR__ain"
            },
            {
                "_id": "aisne",
                "code": "aisne",
                "name": "Aisne",
                "pk": "FR__aisne"
            },
            {
                "_id": "albi_le_sequestre",
                "code": "albi_le_sequestre",
                "name": "Albi Le Sequestre",
                "pk": "FR__albi_le_sequestre"
            },
            {
                "_id": "allier",
                "code": "allier",
                "name": "Allier",
                "pk": "FR__allier"
            },
            {
                "_id": "alpes-cote_dazur",
                "code": "alpes-cote_dazur",
                "name": "Alpes-Cote dAzur",
                "pk": "FR__alpes-cote_dazur"
            },
            {
                "_id": "alpes-maritimes",
                "code": "alpes-maritimes",
                "name": "Alpes-Maritimes",
                "pk": "FR__alpes-maritimes"
            },
            {
                "_id": "alpes-de-haute-provence",
                "code": "alpes-de-haute-provence",
                "name": "Alpes-de-Haute-Provence",
                "pk": "FR__alpes-de-haute-provence"
            },
            {
                "_id": "alsace",
                "code": "alsace",
                "name": "Alsace",
                "pk": "FR__alsace"
            },
            {
                "_id": "aquitaine",
                "code": "aquitaine",
                "name": "Aquitaine",
                "pk": "FR__aquitaine"
            },
            {
                "_id": "ardeche",
                "code": "ardeche",
                "name": "Ardeche",
                "pk": "FR__ardeche"
            },
            {
                "_id": "ardennes",
                "code": "ardennes",
                "name": "Ardennes",
                "pk": "FR__ardennes"
            },
            {
                "_id": "ariege",
                "code": "ariege",
                "name": "Ariege",
                "pk": "FR__ariege"
            },
            {
                "_id": "aube",
                "code": "aube",
                "name": "Aube",
                "pk": "FR__aube"
            },
            {
                "_id": "aude",
                "code": "aude",
                "name": "Aude",
                "pk": "FR__aude"
            },
            {
                "_id": "auvergne",
                "code": "auvergne",
                "name": "Auvergne",
                "pk": "FR__auvergne"
            },
            {
                "_id": "aveyron",
                "code": "aveyron",
                "name": "Aveyron",
                "pk": "FR__aveyron"
            },
            {
                "_id": "bas-rhin",
                "code": "bas-rhin",
                "name": "Bas-Rhin",
                "pk": "FR__bas-rhin"
            },
            {
                "_id": "basse-normandie",
                "code": "basse-normandie",
                "name": "Basse-Normandie",
                "pk": "FR__basse-normandie"
            },
            {
                "_id": "bouches-du-rhone",
                "code": "bouches-du-rhone",
                "name": "Bouches-du-Rhone",
                "pk": "FR__bouches-du-rhone"
            },
            {
                "_id": "bourgogne",
                "code": "bourgogne",
                "name": "Bourgogne",
                "pk": "FR__bourgogne"
            },
            {
                "_id": "bretagne",
                "code": "bretagne",
                "name": "Bretagne",
                "pk": "FR__bretagne"
            },
            {
                "_id": "brittany",
                "code": "brittany",
                "name": "Brittany",
                "pk": "FR__brittany"
            },
            {
                "_id": "burgundy",
                "code": "burgundy",
                "name": "Burgundy",
                "pk": "FR__burgundy"
            },
            {
                "_id": "calvados",
                "code": "calvados",
                "name": "Calvados",
                "pk": "FR__calvados"
            },
            {
                "_id": "cantal",
                "code": "cantal",
                "name": "Cantal",
                "pk": "FR__cantal"
            },
            {
                "_id": "cedex",
                "code": "cedex",
                "name": "Cedex",
                "pk": "FR__cedex"
            },
            {
                "_id": "centre",
                "code": "centre",
                "name": "Centre",
                "pk": "FR__centre"
            },
            {
                "_id": "charente",
                "code": "charente",
                "name": "Charente",
                "pk": "FR__charente"
            },
            {
                "_id": "charente-maritime",
                "code": "charente-maritime",
                "name": "Charente-Maritime",
                "pk": "FR__charente-maritime"
            },
            {
                "_id": "cher",
                "code": "cher",
                "name": "Cher",
                "pk": "FR__cher"
            },
            {
                "_id": "correze",
                "code": "correze",
                "name": "Correze",
                "pk": "FR__correze"
            },
            {
                "_id": "corse-du-sud",
                "code": "corse-du-sud",
                "name": "Corse-du-Sud",
                "pk": "FR__corse-du-sud"
            },
            {
                "_id": "cote-dor",
                "code": "cote-dor",
                "name": "Cote-dOr",
                "pk": "FR__cote-dor"
            },
            {
                "_id": "cotes-darmor",
                "code": "cotes-darmor",
                "name": "Cotes-dArmor",
                "pk": "FR__cotes-darmor"
            },
            {
                "_id": "creuse",
                "code": "creuse",
                "name": "Creuse",
                "pk": "FR__creuse"
            },
            {
                "_id": "crolles",
                "code": "crolles",
                "name": "Crolles",
                "pk": "FR__crolles"
            },
            {
                "_id": "deux-sevres",
                "code": "deux-sevres",
                "name": "Deux-Sevres",
                "pk": "FR__deux-sevres"
            },
            {
                "_id": "dordogne",
                "code": "dordogne",
                "name": "Dordogne",
                "pk": "FR__dordogne"
            },
            {
                "_id": "doubs",
                "code": "doubs",
                "name": "Doubs",
                "pk": "FR__doubs"
            },
            {
                "_id": "drome",
                "code": "drome",
                "name": "Drome",
                "pk": "FR__drome"
            },
            {
                "_id": "essonne",
                "code": "essonne",
                "name": "Essonne",
                "pk": "FR__essonne"
            },
            {
                "_id": "eure",
                "code": "eure",
                "name": "Eure",
                "pk": "FR__eure"
            },
            {
                "_id": "eure-et-loir",
                "code": "eure-et-loir",
                "name": "Eure-et-Loir",
                "pk": "FR__eure-et-loir"
            },
            {
                "_id": "feucherolles",
                "code": "feucherolles",
                "name": "Feucherolles",
                "pk": "FR__feucherolles"
            },
            {
                "_id": "finistere",
                "code": "finistere",
                "name": "Finistere",
                "pk": "FR__finistere"
            },
            {
                "_id": "franche-comte",
                "code": "franche-comte",
                "name": "Franche-Comte",
                "pk": "FR__franche-comte"
            },
            {
                "_id": "gard",
                "code": "gard",
                "name": "Gard",
                "pk": "FR__gard"
            },
            {
                "_id": "gers",
                "code": "gers",
                "name": "Gers",
                "pk": "FR__gers"
            },
            {
                "_id": "gironde",
                "code": "gironde",
                "name": "Gironde",
                "pk": "FR__gironde"
            },
            {
                "_id": "haut-rhin",
                "code": "haut-rhin",
                "name": "Haut-Rhin",
                "pk": "FR__haut-rhin"
            },
            {
                "_id": "haute-corse",
                "code": "haute-corse",
                "name": "Haute-Corse",
                "pk": "FR__haute-corse"
            },
            {
                "_id": "haute-garonne",
                "code": "haute-garonne",
                "name": "Haute-Garonne",
                "pk": "FR__haute-garonne"
            },
            {
                "_id": "haute-loire",
                "code": "haute-loire",
                "name": "Haute-Loire",
                "pk": "FR__haute-loire"
            },
            {
                "_id": "haute-marne",
                "code": "haute-marne",
                "name": "Haute-Marne",
                "pk": "FR__haute-marne"
            },
            {
                "_id": "haute-saone",
                "code": "haute-saone",
                "name": "Haute-Saone",
                "pk": "FR__haute-saone"
            },
            {
                "_id": "haute-savoie",
                "code": "haute-savoie",
                "name": "Haute-Savoie",
                "pk": "FR__haute-savoie"
            },
            {
                "_id": "haute-vienne",
                "code": "haute-vienne",
                "name": "Haute-Vienne",
                "pk": "FR__haute-vienne"
            },
            {
                "_id": "hautes-alpes",
                "code": "hautes-alpes",
                "name": "Hautes-Alpes",
                "pk": "FR__hautes-alpes"
            },
            {
                "_id": "hautes-pyrenees",
                "code": "hautes-pyrenees",
                "name": "Hautes-Pyrenees",
                "pk": "FR__hautes-pyrenees"
            },
            {
                "_id": "hauts-de-seine",
                "code": "hauts-de-seine",
                "name": "Hauts-de-Seine",
                "pk": "FR__hauts-de-seine"
            },
            {
                "_id": "herault",
                "code": "herault",
                "name": "Herault",
                "pk": "FR__herault"
            },
            {
                "_id": "ile-de-france",
                "code": "ile-de-france",
                "name": "Ile-de-France",
                "pk": "FR__ile-de-france"
            },
            {
                "_id": "ille-et-vilaine",
                "code": "ille-et-vilaine",
                "name": "Ille-et-Vilaine",
                "pk": "FR__ille-et-vilaine"
            },
            {
                "_id": "indre",
                "code": "indre",
                "name": "Indre",
                "pk": "FR__indre"
            },
            {
                "_id": "indre-et-loire",
                "code": "indre-et-loire",
                "name": "Indre-et-Loire",
                "pk": "FR__indre-et-loire"
            },
            {
                "_id": "isere",
                "code": "isere",
                "name": "Isere",
                "pk": "FR__isere"
            },
            {
                "_id": "jura",
                "code": "jura",
                "name": "Jura",
                "pk": "FR__jura"
            },
            {
                "_id": "klagenfurt",
                "code": "klagenfurt",
                "name": "Klagenfurt",
                "pk": "FR__klagenfurt"
            },
            {
                "_id": "landes",
                "code": "landes",
                "name": "Landes",
                "pk": "FR__landes"
            },
            {
                "_id": "languedoc-roussillon",
                "code": "languedoc-roussillon",
                "name": "Languedoc-Roussillon",
                "pk": "FR__languedoc-roussillon"
            },
            {
                "_id": "larcay",
                "code": "larcay",
                "name": "Larcay",
                "pk": "FR__larcay"
            },
            {
                "_id": "le_castellet",
                "code": "le_castellet",
                "name": "Le Castellet",
                "pk": "FR__le_castellet"
            },
            {
                "_id": "le_creusot",
                "code": "le_creusot",
                "name": "Le Creusot",
                "pk": "FR__le_creusot"
            },
            {
                "_id": "limousin",
                "code": "limousin",
                "name": "Limousin",
                "pk": "FR__limousin"
            },
            {
                "_id": "loir-et-cher",
                "code": "loir-et-cher",
                "name": "Loir-et-Cher",
                "pk": "FR__loir-et-cher"
            },
            {
                "_id": "loire",
                "code": "loire",
                "name": "Loire",
                "pk": "FR__loire"
            },
            {
                "_id": "loire-atlantique",
                "code": "loire-atlantique",
                "name": "Loire-Atlantique",
                "pk": "FR__loire-atlantique"
            },
            {
                "_id": "loiret",
                "code": "loiret",
                "name": "Loiret",
                "pk": "FR__loiret"
            },
            {
                "_id": "lorraine",
                "code": "lorraine",
                "name": "Lorraine",
                "pk": "FR__lorraine"
            },
            {
                "_id": "lot",
                "code": "lot",
                "name": "Lot",
                "pk": "FR__lot"
            },
            {
                "_id": "lot-et-garonne",
                "code": "lot-et-garonne",
                "name": "Lot-et-Garonne",
                "pk": "FR__lot-et-garonne"
            },
            {
                "_id": "lower_normandy",
                "code": "lower_normandy",
                "name": "Lower Normandy",
                "pk": "FR__lower_normandy"
            },
            {
                "_id": "lozere",
                "code": "lozere",
                "name": "Lozere",
                "pk": "FR__lozere"
            },
            {
                "_id": "maine-et-loire",
                "code": "maine-et-loire",
                "name": "Maine-et-Loire",
                "pk": "FR__maine-et-loire"
            },
            {
                "_id": "manche",
                "code": "manche",
                "name": "Manche",
                "pk": "FR__manche"
            },
            {
                "_id": "marne",
                "code": "marne",
                "name": "Marne",
                "pk": "FR__marne"
            },
            {
                "_id": "mayenne",
                "code": "mayenne",
                "name": "Mayenne",
                "pk": "FR__mayenne"
            },
            {
                "_id": "meurthe-et-moselle",
                "code": "meurthe-et-moselle",
                "name": "Meurthe-et-Moselle",
                "pk": "FR__meurthe-et-moselle"
            },
            {
                "_id": "meuse",
                "code": "meuse",
                "name": "Meuse",
                "pk": "FR__meuse"
            },
            {
                "_id": "midi-pyrenees",
                "code": "midi-pyrenees",
                "name": "Midi-Pyrenees",
                "pk": "FR__midi-pyrenees"
            },
            {
                "_id": "morbihan",
                "code": "morbihan",
                "name": "Morbihan",
                "pk": "FR__morbihan"
            },
            {
                "_id": "moselle",
                "code": "moselle",
                "name": "Moselle",
                "pk": "FR__moselle"
            },
            {
                "_id": "nievre",
                "code": "nievre",
                "name": "Nievre",
                "pk": "FR__nievre"
            },
            {
                "_id": "nord",
                "code": "nord",
                "name": "Nord",
                "pk": "FR__nord"
            },
            {
                "_id": "nord-pas-de-calais",
                "code": "nord-pas-de-calais",
                "name": "Nord-Pas-de-Calais",
                "pk": "FR__nord-pas-de-calais"
            },
            {
                "_id": "oise",
                "code": "oise",
                "name": "Oise",
                "pk": "FR__oise"
            },
            {
                "_id": "orne",
                "code": "orne",
                "name": "Orne",
                "pk": "FR__orne"
            },
            {
                "_id": "paris",
                "code": "paris",
                "name": "Paris",
                "pk": "FR__paris"
            },
            {
                "_id": "pas-de-calais",
                "code": "pas-de-calais",
                "name": "Pas-de-Calais",
                "pk": "FR__pas-de-calais"
            },
            {
                "_id": "pays_de_la_loire",
                "code": "pays_de_la_loire",
                "name": "Pays de la Loire",
                "pk": "FR__pays_de_la_loire"
            },
            {
                "_id": "pays-de-la-loire",
                "code": "pays-de-la-loire",
                "name": "Pays-de-la-Loire",
                "pk": "FR__pays-de-la-loire"
            },
            {
                "_id": "picardy",
                "code": "picardy",
                "name": "Picardy",
                "pk": "FR__picardy"
            },
            {
                "_id": "puy-de-dome",
                "code": "puy-de-dome",
                "name": "Puy-de-Dome",
                "pk": "FR__puy-de-dome"
            },
            {
                "_id": "pyrenees-atlantiques",
                "code": "pyrenees-atlantiques",
                "name": "Pyrenees-Atlantiques",
                "pk": "FR__pyrenees-atlantiques"
            },
            {
                "_id": "pyrenees-orientales",
                "code": "pyrenees-orientales",
                "name": "Pyrenees-Orientales",
                "pk": "FR__pyrenees-orientales"
            },
            {
                "_id": "quelmes",
                "code": "quelmes",
                "name": "Quelmes",
                "pk": "FR__quelmes"
            },
            {
                "_id": "rhone",
                "code": "rhone",
                "name": "Rhone",
                "pk": "FR__rhone"
            },
            {
                "_id": "rhone-alpes",
                "code": "rhone-alpes",
                "name": "Rhone-Alpes",
                "pk": "FR__rhone-alpes"
            },
            {
                "_id": "saint_ouen",
                "code": "saint_ouen",
                "name": "Saint Ouen",
                "pk": "FR__saint_ouen"
            },
            {
                "_id": "saint_viatre",
                "code": "saint_viatre",
                "name": "Saint Viatre",
                "pk": "FR__saint_viatre"
            },
            {
                "_id": "saone-et-loire",
                "code": "saone-et-loire",
                "name": "Saone-et-Loire",
                "pk": "FR__saone-et-loire"
            },
            {
                "_id": "sarthe",
                "code": "sarthe",
                "name": "Sarthe",
                "pk": "FR__sarthe"
            },
            {
                "_id": "savoie",
                "code": "savoie",
                "name": "Savoie",
                "pk": "FR__savoie"
            },
            {
                "_id": "seine-maritime",
                "code": "seine-maritime",
                "name": "Seine-Maritime",
                "pk": "FR__seine-maritime"
            },
            {
                "_id": "seine-saint-denis",
                "code": "seine-saint-denis",
                "name": "Seine-Saint-Denis",
                "pk": "FR__seine-saint-denis"
            },
            {
                "_id": "seine-et-marne",
                "code": "seine-et-marne",
                "name": "Seine-et-Marne",
                "pk": "FR__seine-et-marne"
            },
            {
                "_id": "somme",
                "code": "somme",
                "name": "Somme",
                "pk": "FR__somme"
            },
            {
                "_id": "sophia_antipolis",
                "code": "sophia_antipolis",
                "name": "Sophia Antipolis",
                "pk": "FR__sophia_antipolis"
            },
            {
                "_id": "souvans",
                "code": "souvans",
                "name": "Souvans",
                "pk": "FR__souvans"
            },
            {
                "_id": "tarn",
                "code": "tarn",
                "name": "Tarn",
                "pk": "FR__tarn"
            },
            {
                "_id": "tarn-et-garonne",
                "code": "tarn-et-garonne",
                "name": "Tarn-et-Garonne",
                "pk": "FR__tarn-et-garonne"
            },
            {
                "_id": "territoire_de_belfort",
                "code": "territoire_de_belfort",
                "name": "Territoire de Belfort",
                "pk": "FR__territoire_de_belfort"
            },
            {
                "_id": "treignac",
                "code": "treignac",
                "name": "Treignac",
                "pk": "FR__treignac"
            },
            {
                "_id": "upper_normandy",
                "code": "upper_normandy",
                "name": "Upper Normandy",
                "pk": "FR__upper_normandy"
            },
            {
                "_id": "val-doise",
                "code": "val-doise",
                "name": "Val-dOise",
                "pk": "FR__val-doise"
            },
            {
                "_id": "val-de-marne",
                "code": "val-de-marne",
                "name": "Val-de-Marne",
                "pk": "FR__val-de-marne"
            },
            {
                "_id": "var",
                "code": "var",
                "name": "Var",
                "pk": "FR__var"
            },
            {
                "_id": "vaucluse",
                "code": "vaucluse",
                "name": "Vaucluse",
                "pk": "FR__vaucluse"
            },
            {
                "_id": "vellise",
                "code": "vellise",
                "name": "Vellise",
                "pk": "FR__vellise"
            },
            {
                "_id": "vendee",
                "code": "vendee",
                "name": "Vendee",
                "pk": "FR__vendee"
            },
            {
                "_id": "vienne",
                "code": "vienne",
                "name": "Vienne",
                "pk": "FR__vienne"
            },
            {
                "_id": "vosges",
                "code": "vosges",
                "name": "Vosges",
                "pk": "FR__vosges"
            },
            {
                "_id": "yonne",
                "code": "yonne",
                "name": "Yonne",
                "pk": "FR__yonne"
            },
            {
                "_id": "yvelines",
                "code": "yvelines",
                "name": "Yvelines",
                "pk": "FR__yvelines"
            }
        ],
        "only_shipping": true,
        "pk": "FR"
    },
    {
        "_id": "GF",
        "code": "GF",
        "name": "Guyane française",
        "states": [
            {
                "_id": "cayenne",
                "code": "cayenne",
                "name": "Cayenne",
                "pk": "GF__cayenne"
            },
            {
                "_id": "saint-laurent-du-maroni",
                "code": "saint-laurent-du-maroni",
                "name": "Saint-Laurent-du-Maroni",
                "pk": "GF__saint-laurent-du-maroni"
            }
        ],
        "pk": "GF"
    },
    {
        "_id": "PF",
        "code": "PF",
        "name": "Polynésie française",
        "states": [
            {
                "_id": "iles_du_vent",
                "code": "iles_du_vent",
                "name": "Iles du Vent",
                "pk": "PF__iles_du_vent"
            },
            {
                "_id": "iles_sous_le_vent",
                "code": "iles_sous_le_vent",
                "name": "Iles sous le Vent",
                "pk": "PF__iles_sous_le_vent"
            },
            {
                "_id": "marquesas",
                "code": "marquesas",
                "name": "Marquesas",
                "pk": "PF__marquesas"
            },
            {
                "_id": "tuamotu",
                "code": "tuamotu",
                "name": "Tuamotu",
                "pk": "PF__tuamotu"
            },
            {
                "_id": "tubuai",
                "code": "tubuai",
                "name": "Tubuai",
                "pk": "PF__tubuai"
            }
        ],
        "pk": "PF"
    },
    {
        "_id": "TF",
        "code": "TF",
        "name": "Terres australes françaises",
        "states": [
            {
                "_id": "amsterdam",
                "code": "amsterdam",
                "name": "Amsterdam",
                "pk": "TF__amsterdam"
            },
            {
                "_id": "crozet_islands",
                "code": "crozet_islands",
                "name": "Crozet Islands",
                "pk": "TF__crozet_islands"
            },
            {
                "_id": "kerguelen",
                "code": "kerguelen",
                "name": "Kerguelen",
                "pk": "TF__kerguelen"
            }
        ],
        "pk": "TF"
    },
    {
        "_id": "GA",
        "code": "GA",
        "name": "Gabon",
        "states": [
            {
                "_id": "estuaire",
                "code": "estuaire",
                "name": "Estuaire",
                "pk": "GA__estuaire"
            },
            {
                "_id": "haut-ogooue",
                "code": "haut-ogooue",
                "name": "Haut-Ogooue",
                "pk": "GA__haut-ogooue"
            },
            {
                "_id": "moyen-ogooue",
                "code": "moyen-ogooue",
                "name": "Moyen-Ogooue",
                "pk": "GA__moyen-ogooue"
            },
            {
                "_id": "ngounie",
                "code": "ngounie",
                "name": "Ngounie",
                "pk": "GA__ngounie"
            },
            {
                "_id": "nyanga",
                "code": "nyanga",
                "name": "Nyanga",
                "pk": "GA__nyanga"
            },
            {
                "_id": "ogooue-ivindo",
                "code": "ogooue-ivindo",
                "name": "Ogooue-Ivindo",
                "pk": "GA__ogooue-ivindo"
            },
            {
                "_id": "ogooue-lolo",
                "code": "ogooue-lolo",
                "name": "Ogooue-Lolo",
                "pk": "GA__ogooue-lolo"
            },
            {
                "_id": "ogooue-maritime",
                "code": "ogooue-maritime",
                "name": "Ogooue-Maritime",
                "pk": "GA__ogooue-maritime"
            },
            {
                "_id": "woleu-ntem",
                "code": "woleu-ntem",
                "name": "Woleu-Ntem",
                "pk": "GA__woleu-ntem"
            }
        ],
        "pk": "GA"
    },
    {
        "_id": "GM",
        "code": "GM",
        "name": "Gambie",
        "states": [
            {
                "_id": "banjul",
                "code": "banjul",
                "name": "Banjul",
                "pk": "GM__banjul"
            },
            {
                "_id": "basse",
                "code": "basse",
                "name": "Basse",
                "pk": "GM__basse"
            },
            {
                "_id": "brikama",
                "code": "brikama",
                "name": "Brikama",
                "pk": "GM__brikama"
            },
            {
                "_id": "janjanbureh",
                "code": "janjanbureh",
                "name": "Janjanbureh",
                "pk": "GM__janjanbureh"
            },
            {
                "_id": "kanifing",
                "code": "kanifing",
                "name": "Kanifing",
                "pk": "GM__kanifing"
            },
            {
                "_id": "kerewan",
                "code": "kerewan",
                "name": "Kerewan",
                "pk": "GM__kerewan"
            },
            {
                "_id": "kuntaur",
                "code": "kuntaur",
                "name": "Kuntaur",
                "pk": "GM__kuntaur"
            },
            {
                "_id": "mansakonko",
                "code": "mansakonko",
                "name": "Mansakonko",
                "pk": "GM__mansakonko"
            }
        ],
        "pk": "GM"
    },
    {
        "_id": "GE",
        "code": "GE",
        "name": "Géorgie",
        "states": [
            {
                "_id": "abhasia",
                "code": "abhasia",
                "name": "Abhasia",
                "pk": "GE__abhasia"
            },
            {
                "_id": "ajaria",
                "code": "ajaria",
                "name": "Ajaria",
                "pk": "GE__ajaria"
            },
            {
                "_id": "guria",
                "code": "guria",
                "name": "Guria",
                "pk": "GE__guria"
            },
            {
                "_id": "imereti",
                "code": "imereti",
                "name": "Imereti",
                "pk": "GE__imereti"
            },
            {
                "_id": "kaheti",
                "code": "kaheti",
                "name": "Kaheti",
                "pk": "GE__kaheti"
            },
            {
                "_id": "kvemo_kartli",
                "code": "kvemo_kartli",
                "name": "Kvemo Kartli",
                "pk": "GE__kvemo_kartli"
            },
            {
                "_id": "mcheta-mtianeti",
                "code": "mcheta-mtianeti",
                "name": "Mcheta-Mtianeti",
                "pk": "GE__mcheta-mtianeti"
            },
            {
                "_id": "racha",
                "code": "racha",
                "name": "Racha",
                "pk": "GE__racha"
            },
            {
                "_id": "samagrelo-zemo_svaneti",
                "code": "samagrelo-zemo_svaneti",
                "name": "Samagrelo-Zemo Svaneti",
                "pk": "GE__samagrelo-zemo_svaneti"
            },
            {
                "_id": "samche-zhavaheti",
                "code": "samche-zhavaheti",
                "name": "Samche-Zhavaheti",
                "pk": "GE__samche-zhavaheti"
            },
            {
                "_id": "shida_kartli",
                "code": "shida_kartli",
                "name": "Shida Kartli",
                "pk": "GE__shida_kartli"
            },
            {
                "_id": "tbilisi",
                "code": "tbilisi",
                "name": "Tbilisi",
                "pk": "GE__tbilisi"
            }
        ],
        "pk": "GE"
    },
    {
        "_id": "DE",
        "code": "DE",
        "name": "Allemagne",
        "states": [
            {
                "_id": "auvergne",
                "code": "auvergne",
                "name": "Auvergne",
                "pk": "DE__auvergne"
            },
            {
                "_id": "baden-wurttemberg",
                "code": "baden-wurttemberg",
                "name": "Baden-Wurttemberg",
                "pk": "DE__baden-wurttemberg"
            },
            {
                "_id": "bavaria",
                "code": "bavaria",
                "name": "Bavaria",
                "pk": "DE__bavaria"
            },
            {
                "_id": "bayern",
                "code": "bayern",
                "name": "Bayern",
                "pk": "DE__bayern"
            },
            {
                "_id": "beilstein_wurtt",
                "code": "beilstein_wurtt",
                "name": "Beilstein Wurtt",
                "pk": "DE__beilstein_wurtt"
            },
            {
                "_id": "berlin",
                "code": "berlin",
                "name": "Berlin",
                "pk": "DE__berlin"
            },
            {
                "_id": "brandenburg",
                "code": "brandenburg",
                "name": "Brandenburg",
                "pk": "DE__brandenburg"
            },
            {
                "_id": "bremen",
                "code": "bremen",
                "name": "Bremen",
                "pk": "DE__bremen"
            },
            {
                "_id": "dreisbach",
                "code": "dreisbach",
                "name": "Dreisbach",
                "pk": "DE__dreisbach"
            },
            {
                "_id": "freistaat_bayern",
                "code": "freistaat_bayern",
                "name": "Freistaat Bayern",
                "pk": "DE__freistaat_bayern"
            },
            {
                "_id": "hamburg",
                "code": "hamburg",
                "name": "Hamburg",
                "pk": "DE__hamburg"
            },
            {
                "_id": "hannover",
                "code": "hannover",
                "name": "Hannover",
                "pk": "DE__hannover"
            },
            {
                "_id": "heroldstatt",
                "code": "heroldstatt",
                "name": "Heroldstatt",
                "pk": "DE__heroldstatt"
            },
            {
                "_id": "hessen",
                "code": "hessen",
                "name": "Hessen",
                "pk": "DE__hessen"
            },
            {
                "_id": "kortenberg",
                "code": "kortenberg",
                "name": "Kortenberg",
                "pk": "DE__kortenberg"
            },
            {
                "_id": "laasdorf",
                "code": "laasdorf",
                "name": "Laasdorf",
                "pk": "DE__laasdorf"
            },
            {
                "_id": "land_baden-wurttemberg",
                "code": "land_baden-wurttemberg",
                "name": "Land Baden-Wurttemberg",
                "pk": "DE__land_baden-wurttemberg"
            },
            {
                "_id": "land_bayern",
                "code": "land_bayern",
                "name": "Land Bayern",
                "pk": "DE__land_bayern"
            },
            {
                "_id": "land_brandenburg",
                "code": "land_brandenburg",
                "name": "Land Brandenburg",
                "pk": "DE__land_brandenburg"
            },
            {
                "_id": "land_hessen",
                "code": "land_hessen",
                "name": "Land Hessen",
                "pk": "DE__land_hessen"
            },
            {
                "_id": "land_mecklenburg-vorpommern",
                "code": "land_mecklenburg-vorpommern",
                "name": "Land Mecklenburg-Vorpommern",
                "pk": "DE__land_mecklenburg-vorpommern"
            },
            {
                "_id": "land_nordrhein-westfalen",
                "code": "land_nordrhein-westfalen",
                "name": "Land Nordrhein-Westfalen",
                "pk": "DE__land_nordrhein-westfalen"
            },
            {
                "_id": "land_rheinland-pfalz",
                "code": "land_rheinland-pfalz",
                "name": "Land Rheinland-Pfalz",
                "pk": "DE__land_rheinland-pfalz"
            },
            {
                "_id": "land_sachsen",
                "code": "land_sachsen",
                "name": "Land Sachsen",
                "pk": "DE__land_sachsen"
            },
            {
                "_id": "land_sachsen-anhalt",
                "code": "land_sachsen-anhalt",
                "name": "Land Sachsen-Anhalt",
                "pk": "DE__land_sachsen-anhalt"
            },
            {
                "_id": "land_thuringen",
                "code": "land_thuringen",
                "name": "Land Thuringen",
                "pk": "DE__land_thuringen"
            },
            {
                "_id": "lower_saxony",
                "code": "lower_saxony",
                "name": "Lower Saxony",
                "pk": "DE__lower_saxony"
            },
            {
                "_id": "mecklenburg-vorpommern",
                "code": "mecklenburg-vorpommern",
                "name": "Mecklenburg-Vorpommern",
                "pk": "DE__mecklenburg-vorpommern"
            },
            {
                "_id": "mulfingen",
                "code": "mulfingen",
                "name": "Mulfingen",
                "pk": "DE__mulfingen"
            },
            {
                "_id": "munich",
                "code": "munich",
                "name": "Munich",
                "pk": "DE__munich"
            },
            {
                "_id": "neubeuern",
                "code": "neubeuern",
                "name": "Neubeuern",
                "pk": "DE__neubeuern"
            },
            {
                "_id": "niedersachsen",
                "code": "niedersachsen",
                "name": "Niedersachsen",
                "pk": "DE__niedersachsen"
            },
            {
                "_id": "noord-holland",
                "code": "noord-holland",
                "name": "Noord-Holland",
                "pk": "DE__noord-holland"
            },
            {
                "_id": "nordrhein-westfalen",
                "code": "nordrhein-westfalen",
                "name": "Nordrhein-Westfalen",
                "pk": "DE__nordrhein-westfalen"
            },
            {
                "_id": "north_rhine-westphalia",
                "code": "north_rhine-westphalia",
                "name": "North Rhine-Westphalia",
                "pk": "DE__north_rhine-westphalia"
            },
            {
                "_id": "osterode",
                "code": "osterode",
                "name": "Osterode",
                "pk": "DE__osterode"
            },
            {
                "_id": "rheinland-pfalz",
                "code": "rheinland-pfalz",
                "name": "Rheinland-Pfalz",
                "pk": "DE__rheinland-pfalz"
            },
            {
                "_id": "rhineland-palatinate",
                "code": "rhineland-palatinate",
                "name": "Rhineland-Palatinate",
                "pk": "DE__rhineland-palatinate"
            },
            {
                "_id": "saarland",
                "code": "saarland",
                "name": "Saarland",
                "pk": "DE__saarland"
            },
            {
                "_id": "sachsen",
                "code": "sachsen",
                "name": "Sachsen",
                "pk": "DE__sachsen"
            },
            {
                "_id": "sachsen-anhalt",
                "code": "sachsen-anhalt",
                "name": "Sachsen-Anhalt",
                "pk": "DE__sachsen-anhalt"
            },
            {
                "_id": "saxony",
                "code": "saxony",
                "name": "Saxony",
                "pk": "DE__saxony"
            },
            {
                "_id": "schleswig-holstein",
                "code": "schleswig-holstein",
                "name": "Schleswig-Holstein",
                "pk": "DE__schleswig-holstein"
            },
            {
                "_id": "thuringia",
                "code": "thuringia",
                "name": "Thuringia",
                "pk": "DE__thuringia"
            },
            {
                "_id": "webling",
                "code": "webling",
                "name": "Webling",
                "pk": "DE__webling"
            },
            {
                "_id": "weinstrabe",
                "code": "weinstrabe",
                "name": "Weinstrabe",
                "pk": "DE__weinstrabe"
            },
            {
                "_id": "schlobborn",
                "code": "schlobborn",
                "name": "schlobborn",
                "pk": "DE__schlobborn"
            }
        ],
        "only_shipping": true,
        "pk": "DE"
    },
    {
        "_id": "GH",
        "code": "GH",
        "name": "Ghana",
        "states": [
            {
                "_id": "ashanti",
                "code": "ashanti",
                "name": "Ashanti",
                "pk": "GH__ashanti"
            },
            {
                "_id": "brong-ahafo",
                "code": "brong-ahafo",
                "name": "Brong-Ahafo",
                "pk": "GH__brong-ahafo"
            },
            {
                "_id": "central",
                "code": "central",
                "name": "Central",
                "pk": "GH__central"
            },
            {
                "_id": "eastern",
                "code": "eastern",
                "name": "Eastern",
                "pk": "GH__eastern"
            },
            {
                "_id": "greater_accra",
                "code": "greater_accra",
                "name": "Greater Accra",
                "pk": "GH__greater_accra"
            },
            {
                "_id": "northern",
                "code": "northern",
                "name": "Northern",
                "pk": "GH__northern"
            },
            {
                "_id": "upper_east",
                "code": "upper_east",
                "name": "Upper East",
                "pk": "GH__upper_east"
            },
            {
                "_id": "upper_west",
                "code": "upper_west",
                "name": "Upper West",
                "pk": "GH__upper_west"
            },
            {
                "_id": "volta",
                "code": "volta",
                "name": "Volta",
                "pk": "GH__volta"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "GH__western"
            }
        ],
        "pk": "GH"
    },
    {
        "_id": "GI",
        "code": "GI",
        "name": "Gibraltar",
        "states": [
            {
                "_id": "gibraltar",
                "code": "gibraltar",
                "name": "Gibraltar",
                "pk": "GI__gibraltar"
            }
        ],
        "pk": "GI"
    },
    {
        "_id": "GR",
        "code": "GR",
        "name": "Grèce",
        "states": [
            {
                "_id": "acharnes",
                "code": "acharnes",
                "name": "Acharnes",
                "pk": "GR__acharnes"
            },
            {
                "_id": "ahaia",
                "code": "ahaia",
                "name": "Ahaia",
                "pk": "GR__ahaia"
            },
            {
                "_id": "aitolia_kai_akarnania",
                "code": "aitolia_kai_akarnania",
                "name": "Aitolia kai Akarnania",
                "pk": "GR__aitolia_kai_akarnania"
            },
            {
                "_id": "argolis",
                "code": "argolis",
                "name": "Argolis",
                "pk": "GR__argolis"
            },
            {
                "_id": "arkadia",
                "code": "arkadia",
                "name": "Arkadia",
                "pk": "GR__arkadia"
            },
            {
                "_id": "arta",
                "code": "arta",
                "name": "Arta",
                "pk": "GR__arta"
            },
            {
                "_id": "attica",
                "code": "attica",
                "name": "Attica",
                "pk": "GR__attica"
            },
            {
                "_id": "attiki",
                "code": "attiki",
                "name": "Attiki",
                "pk": "GR__attiki"
            },
            {
                "_id": "ayion_oros",
                "code": "ayion_oros",
                "name": "Ayion Oros",
                "pk": "GR__ayion_oros"
            },
            {
                "_id": "crete",
                "code": "crete",
                "name": "Crete",
                "pk": "GR__crete"
            },
            {
                "_id": "dodekanisos",
                "code": "dodekanisos",
                "name": "Dodekanisos",
                "pk": "GR__dodekanisos"
            },
            {
                "_id": "drama",
                "code": "drama",
                "name": "Drama",
                "pk": "GR__drama"
            },
            {
                "_id": "evia",
                "code": "evia",
                "name": "Evia",
                "pk": "GR__evia"
            },
            {
                "_id": "evritania",
                "code": "evritania",
                "name": "Evritania",
                "pk": "GR__evritania"
            },
            {
                "_id": "evros",
                "code": "evros",
                "name": "Evros",
                "pk": "GR__evros"
            },
            {
                "_id": "evvoia",
                "code": "evvoia",
                "name": "Evvoia",
                "pk": "GR__evvoia"
            },
            {
                "_id": "florina",
                "code": "florina",
                "name": "Florina",
                "pk": "GR__florina"
            },
            {
                "_id": "fokis",
                "code": "fokis",
                "name": "Fokis",
                "pk": "GR__fokis"
            },
            {
                "_id": "fthiotis",
                "code": "fthiotis",
                "name": "Fthiotis",
                "pk": "GR__fthiotis"
            },
            {
                "_id": "grevena",
                "code": "grevena",
                "name": "Grevena",
                "pk": "GR__grevena"
            },
            {
                "_id": "halandri",
                "code": "halandri",
                "name": "Halandri",
                "pk": "GR__halandri"
            },
            {
                "_id": "halkidiki",
                "code": "halkidiki",
                "name": "Halkidiki",
                "pk": "GR__halkidiki"
            },
            {
                "_id": "hania",
                "code": "hania",
                "name": "Hania",
                "pk": "GR__hania"
            },
            {
                "_id": "heraklion",
                "code": "heraklion",
                "name": "Heraklion",
                "pk": "GR__heraklion"
            },
            {
                "_id": "hios",
                "code": "hios",
                "name": "Hios",
                "pk": "GR__hios"
            },
            {
                "_id": "ilia",
                "code": "ilia",
                "name": "Ilia",
                "pk": "GR__ilia"
            },
            {
                "_id": "imathia",
                "code": "imathia",
                "name": "Imathia",
                "pk": "GR__imathia"
            },
            {
                "_id": "ioannina",
                "code": "ioannina",
                "name": "Ioannina",
                "pk": "GR__ioannina"
            },
            {
                "_id": "iraklion",
                "code": "iraklion",
                "name": "Iraklion",
                "pk": "GR__iraklion"
            },
            {
                "_id": "karditsa",
                "code": "karditsa",
                "name": "Karditsa",
                "pk": "GR__karditsa"
            },
            {
                "_id": "kastoria",
                "code": "kastoria",
                "name": "Kastoria",
                "pk": "GR__kastoria"
            },
            {
                "_id": "kavala",
                "code": "kavala",
                "name": "Kavala",
                "pk": "GR__kavala"
            },
            {
                "_id": "kefallinia",
                "code": "kefallinia",
                "name": "Kefallinia",
                "pk": "GR__kefallinia"
            },
            {
                "_id": "kerkira",
                "code": "kerkira",
                "name": "Kerkira",
                "pk": "GR__kerkira"
            },
            {
                "_id": "kiklades",
                "code": "kiklades",
                "name": "Kiklades",
                "pk": "GR__kiklades"
            },
            {
                "_id": "kilkis",
                "code": "kilkis",
                "name": "Kilkis",
                "pk": "GR__kilkis"
            },
            {
                "_id": "korinthia",
                "code": "korinthia",
                "name": "Korinthia",
                "pk": "GR__korinthia"
            },
            {
                "_id": "kozani",
                "code": "kozani",
                "name": "Kozani",
                "pk": "GR__kozani"
            },
            {
                "_id": "lakonia",
                "code": "lakonia",
                "name": "Lakonia",
                "pk": "GR__lakonia"
            },
            {
                "_id": "larisa",
                "code": "larisa",
                "name": "Larisa",
                "pk": "GR__larisa"
            },
            {
                "_id": "lasithi",
                "code": "lasithi",
                "name": "Lasithi",
                "pk": "GR__lasithi"
            },
            {
                "_id": "lesvos",
                "code": "lesvos",
                "name": "Lesvos",
                "pk": "GR__lesvos"
            },
            {
                "_id": "levkas",
                "code": "levkas",
                "name": "Levkas",
                "pk": "GR__levkas"
            },
            {
                "_id": "magnisia",
                "code": "magnisia",
                "name": "Magnisia",
                "pk": "GR__magnisia"
            },
            {
                "_id": "messinia",
                "code": "messinia",
                "name": "Messinia",
                "pk": "GR__messinia"
            },
            {
                "_id": "nomos_attikis",
                "code": "nomos_attikis",
                "name": "Nomos Attikis",
                "pk": "GR__nomos_attikis"
            },
            {
                "_id": "nomos_zakynthou",
                "code": "nomos_zakynthou",
                "name": "Nomos Zakynthou",
                "pk": "GR__nomos_zakynthou"
            },
            {
                "_id": "pella",
                "code": "pella",
                "name": "Pella",
                "pk": "GR__pella"
            },
            {
                "_id": "pieria",
                "code": "pieria",
                "name": "Pieria",
                "pk": "GR__pieria"
            },
            {
                "_id": "piraios",
                "code": "piraios",
                "name": "Piraios",
                "pk": "GR__piraios"
            },
            {
                "_id": "preveza",
                "code": "preveza",
                "name": "Preveza",
                "pk": "GR__preveza"
            },
            {
                "_id": "rethimni",
                "code": "rethimni",
                "name": "Rethimni",
                "pk": "GR__rethimni"
            },
            {
                "_id": "rodopi",
                "code": "rodopi",
                "name": "Rodopi",
                "pk": "GR__rodopi"
            },
            {
                "_id": "samos",
                "code": "samos",
                "name": "Samos",
                "pk": "GR__samos"
            },
            {
                "_id": "serrai",
                "code": "serrai",
                "name": "Serrai",
                "pk": "GR__serrai"
            },
            {
                "_id": "thesprotia",
                "code": "thesprotia",
                "name": "Thesprotia",
                "pk": "GR__thesprotia"
            },
            {
                "_id": "thessaloniki",
                "code": "thessaloniki",
                "name": "Thessaloniki",
                "pk": "GR__thessaloniki"
            },
            {
                "_id": "trikala",
                "code": "trikala",
                "name": "Trikala",
                "pk": "GR__trikala"
            },
            {
                "_id": "voiotia",
                "code": "voiotia",
                "name": "Voiotia",
                "pk": "GR__voiotia"
            },
            {
                "_id": "west_greece",
                "code": "west_greece",
                "name": "West Greece",
                "pk": "GR__west_greece"
            },
            {
                "_id": "xanthi",
                "code": "xanthi",
                "name": "Xanthi",
                "pk": "GR__xanthi"
            },
            {
                "_id": "zakinthos",
                "code": "zakinthos",
                "name": "Zakinthos",
                "pk": "GR__zakinthos"
            }
        ],
        "pk": "GR"
    },
    {
        "_id": "GL",
        "code": "GL",
        "name": "Groenland",
        "states": [
            {
                "_id": "aasiaat",
                "code": "aasiaat",
                "name": "Aasiaat",
                "pk": "GL__aasiaat"
            },
            {
                "_id": "ammassalik",
                "code": "ammassalik",
                "name": "Ammassalik",
                "pk": "GL__ammassalik"
            },
            {
                "_id": "illoqqortoormiut",
                "code": "illoqqortoormiut",
                "name": "Illoqqortoormiut",
                "pk": "GL__illoqqortoormiut"
            },
            {
                "_id": "ilulissat",
                "code": "ilulissat",
                "name": "Ilulissat",
                "pk": "GL__ilulissat"
            },
            {
                "_id": "ivittuut",
                "code": "ivittuut",
                "name": "Ivittuut",
                "pk": "GL__ivittuut"
            },
            {
                "_id": "kangaatsiaq",
                "code": "kangaatsiaq",
                "name": "Kangaatsiaq",
                "pk": "GL__kangaatsiaq"
            },
            {
                "_id": "maniitsoq",
                "code": "maniitsoq",
                "name": "Maniitsoq",
                "pk": "GL__maniitsoq"
            },
            {
                "_id": "nanortalik",
                "code": "nanortalik",
                "name": "Nanortalik",
                "pk": "GL__nanortalik"
            },
            {
                "_id": "narsaq",
                "code": "narsaq",
                "name": "Narsaq",
                "pk": "GL__narsaq"
            },
            {
                "_id": "nuuk",
                "code": "nuuk",
                "name": "Nuuk",
                "pk": "GL__nuuk"
            },
            {
                "_id": "paamiut",
                "code": "paamiut",
                "name": "Paamiut",
                "pk": "GL__paamiut"
            },
            {
                "_id": "qaanaaq",
                "code": "qaanaaq",
                "name": "Qaanaaq",
                "pk": "GL__qaanaaq"
            },
            {
                "_id": "qaqortoq",
                "code": "qaqortoq",
                "name": "Qaqortoq",
                "pk": "GL__qaqortoq"
            },
            {
                "_id": "qasigiannguit",
                "code": "qasigiannguit",
                "name": "Qasigiannguit",
                "pk": "GL__qasigiannguit"
            },
            {
                "_id": "qeqertarsuaq",
                "code": "qeqertarsuaq",
                "name": "Qeqertarsuaq",
                "pk": "GL__qeqertarsuaq"
            },
            {
                "_id": "sisimiut",
                "code": "sisimiut",
                "name": "Sisimiut",
                "pk": "GL__sisimiut"
            },
            {
                "_id": "udenfor_kommunal_inddeling",
                "code": "udenfor_kommunal_inddeling",
                "name": "Udenfor kommunal inddeling",
                "pk": "GL__udenfor_kommunal_inddeling"
            },
            {
                "_id": "upernavik",
                "code": "upernavik",
                "name": "Upernavik",
                "pk": "GL__upernavik"
            },
            {
                "_id": "uummannaq",
                "code": "uummannaq",
                "name": "Uummannaq",
                "pk": "GL__uummannaq"
            }
        ],
        "pk": "GL"
    },
    {
        "_id": "GD",
        "code": "GD",
        "name": "Grenade",
        "states": [
            {
                "_id": "carriacou-petite_martinique",
                "code": "carriacou-petite_martinique",
                "name": "Carriacou-Petite Martinique",
                "pk": "GD__carriacou-petite_martinique"
            },
            {
                "_id": "saint_andrew",
                "code": "saint_andrew",
                "name": "Saint Andrew",
                "pk": "GD__saint_andrew"
            },
            {
                "_id": "saint_davids",
                "code": "saint_davids",
                "name": "Saint Davids",
                "pk": "GD__saint_davids"
            },
            {
                "_id": "saint_georges",
                "code": "saint_georges",
                "name": "Saint Georges",
                "pk": "GD__saint_georges"
            },
            {
                "_id": "saint_john",
                "code": "saint_john",
                "name": "Saint John",
                "pk": "GD__saint_john"
            },
            {
                "_id": "saint_mark",
                "code": "saint_mark",
                "name": "Saint Mark",
                "pk": "GD__saint_mark"
            },
            {
                "_id": "saint_patrick",
                "code": "saint_patrick",
                "name": "Saint Patrick",
                "pk": "GD__saint_patrick"
            }
        ],
        "pk": "GD"
    },
    {
        "_id": "GP",
        "code": "GP",
        "name": "Guadeloupe",
        "states": [
            {
                "_id": "basse-terre",
                "code": "basse-terre",
                "name": "Basse-Terre",
                "pk": "GP__basse-terre"
            },
            {
                "_id": "grande-terre",
                "code": "grande-terre",
                "name": "Grande-Terre",
                "pk": "GP__grande-terre"
            },
            {
                "_id": "iles_des_saintes",
                "code": "iles_des_saintes",
                "name": "Iles des Saintes",
                "pk": "GP__iles_des_saintes"
            },
            {
                "_id": "la_desirade",
                "code": "la_desirade",
                "name": "La Desirade",
                "pk": "GP__la_desirade"
            },
            {
                "_id": "marie-galante",
                "code": "marie-galante",
                "name": "Marie-Galante",
                "pk": "GP__marie-galante"
            },
            {
                "_id": "saint_barthelemy",
                "code": "saint_barthelemy",
                "name": "Saint Barthelemy",
                "pk": "GP__saint_barthelemy"
            },
            {
                "_id": "saint_martin",
                "code": "saint_martin",
                "name": "Saint Martin",
                "pk": "GP__saint_martin"
            }
        ],
        "pk": "GP"
    },
    {
        "_id": "GU",
        "code": "GU",
        "name": "Guam",
        "states": [
            {
                "_id": "agana_heights",
                "code": "agana_heights",
                "name": "Agana Heights",
                "pk": "GU__agana_heights"
            },
            {
                "_id": "agat",
                "code": "agat",
                "name": "Agat",
                "pk": "GU__agat"
            },
            {
                "_id": "barrigada",
                "code": "barrigada",
                "name": "Barrigada",
                "pk": "GU__barrigada"
            },
            {
                "_id": "chalan-pago-ordot",
                "code": "chalan-pago-ordot",
                "name": "Chalan-Pago-Ordot",
                "pk": "GU__chalan-pago-ordot"
            },
            {
                "_id": "dededo",
                "code": "dededo",
                "name": "Dededo",
                "pk": "GU__dededo"
            },
            {
                "_id": "hagatna",
                "code": "hagatna",
                "name": "Hagatna",
                "pk": "GU__hagatna"
            },
            {
                "_id": "inarajan",
                "code": "inarajan",
                "name": "Inarajan",
                "pk": "GU__inarajan"
            },
            {
                "_id": "mangilao",
                "code": "mangilao",
                "name": "Mangilao",
                "pk": "GU__mangilao"
            },
            {
                "_id": "merizo",
                "code": "merizo",
                "name": "Merizo",
                "pk": "GU__merizo"
            },
            {
                "_id": "mongmong-toto-maite",
                "code": "mongmong-toto-maite",
                "name": "Mongmong-Toto-Maite",
                "pk": "GU__mongmong-toto-maite"
            },
            {
                "_id": "santa_rita",
                "code": "santa_rita",
                "name": "Santa Rita",
                "pk": "GU__santa_rita"
            },
            {
                "_id": "sinajana",
                "code": "sinajana",
                "name": "Sinajana",
                "pk": "GU__sinajana"
            },
            {
                "_id": "talofofo",
                "code": "talofofo",
                "name": "Talofofo",
                "pk": "GU__talofofo"
            },
            {
                "_id": "tamuning",
                "code": "tamuning",
                "name": "Tamuning",
                "pk": "GU__tamuning"
            },
            {
                "_id": "yigo",
                "code": "yigo",
                "name": "Yigo",
                "pk": "GU__yigo"
            },
            {
                "_id": "yona",
                "code": "yona",
                "name": "Yona",
                "pk": "GU__yona"
            }
        ],
        "pk": "GU"
    },
    {
        "_id": "GT",
        "code": "GT",
        "name": "Guatemala",
        "states": [
            {
                "_id": "alta_verapaz",
                "code": "alta_verapaz",
                "name": "Alta Verapaz",
                "pk": "GT__alta_verapaz"
            },
            {
                "_id": "baja_verapaz",
                "code": "baja_verapaz",
                "name": "Baja Verapaz",
                "pk": "GT__baja_verapaz"
            },
            {
                "_id": "chimaltenango",
                "code": "chimaltenango",
                "name": "Chimaltenango",
                "pk": "GT__chimaltenango"
            },
            {
                "_id": "chiquimula",
                "code": "chiquimula",
                "name": "Chiquimula",
                "pk": "GT__chiquimula"
            },
            {
                "_id": "el_progreso",
                "code": "el_progreso",
                "name": "El Progreso",
                "pk": "GT__el_progreso"
            },
            {
                "_id": "escuintla",
                "code": "escuintla",
                "name": "Escuintla",
                "pk": "GT__escuintla"
            },
            {
                "_id": "guatemala",
                "code": "guatemala",
                "name": "Guatemala",
                "pk": "GT__guatemala"
            },
            {
                "_id": "huehuetenango",
                "code": "huehuetenango",
                "name": "Huehuetenango",
                "pk": "GT__huehuetenango"
            },
            {
                "_id": "izabal",
                "code": "izabal",
                "name": "Izabal",
                "pk": "GT__izabal"
            },
            {
                "_id": "jalapa",
                "code": "jalapa",
                "name": "Jalapa",
                "pk": "GT__jalapa"
            },
            {
                "_id": "jutiapa",
                "code": "jutiapa",
                "name": "Jutiapa",
                "pk": "GT__jutiapa"
            },
            {
                "_id": "peten",
                "code": "peten",
                "name": "Peten",
                "pk": "GT__peten"
            },
            {
                "_id": "quezaltenango",
                "code": "quezaltenango",
                "name": "Quezaltenango",
                "pk": "GT__quezaltenango"
            },
            {
                "_id": "quiche",
                "code": "quiche",
                "name": "Quiche",
                "pk": "GT__quiche"
            },
            {
                "_id": "retalhuleu",
                "code": "retalhuleu",
                "name": "Retalhuleu",
                "pk": "GT__retalhuleu"
            },
            {
                "_id": "sacatepequez",
                "code": "sacatepequez",
                "name": "Sacatepequez",
                "pk": "GT__sacatepequez"
            },
            {
                "_id": "san_marcos",
                "code": "san_marcos",
                "name": "San Marcos",
                "pk": "GT__san_marcos"
            },
            {
                "_id": "santa_rosa",
                "code": "santa_rosa",
                "name": "Santa Rosa",
                "pk": "GT__santa_rosa"
            },
            {
                "_id": "solola",
                "code": "solola",
                "name": "Solola",
                "pk": "GT__solola"
            },
            {
                "_id": "suchitepequez",
                "code": "suchitepequez",
                "name": "Suchitepequez",
                "pk": "GT__suchitepequez"
            },
            {
                "_id": "totonicapan",
                "code": "totonicapan",
                "name": "Totonicapan",
                "pk": "GT__totonicapan"
            },
            {
                "_id": "zacapa",
                "code": "zacapa",
                "name": "Zacapa",
                "pk": "GT__zacapa"
            }
        ],
        "pk": "GT"
    },
    {
        "_id": "XU",
        "code": "XU",
        "name": "Guernsey et Alderney",
        "states": [
            {
                "_id": "alderney",
                "code": "alderney",
                "name": "Alderney",
                "pk": "XU__alderney"
            },
            {
                "_id": "castel",
                "code": "castel",
                "name": "Castel",
                "pk": "XU__castel"
            },
            {
                "_id": "forest",
                "code": "forest",
                "name": "Forest",
                "pk": "XU__forest"
            },
            {
                "_id": "saint_andrew",
                "code": "saint_andrew",
                "name": "Saint Andrew",
                "pk": "XU__saint_andrew"
            },
            {
                "_id": "saint_martin",
                "code": "saint_martin",
                "name": "Saint Martin",
                "pk": "XU__saint_martin"
            },
            {
                "_id": "saint_peter_port",
                "code": "saint_peter_port",
                "name": "Saint Peter Port",
                "pk": "XU__saint_peter_port"
            },
            {
                "_id": "saint_pierre_du_bois",
                "code": "saint_pierre_du_bois",
                "name": "Saint Pierre du Bois",
                "pk": "XU__saint_pierre_du_bois"
            },
            {
                "_id": "saint_sampson",
                "code": "saint_sampson",
                "name": "Saint Sampson",
                "pk": "XU__saint_sampson"
            },
            {
                "_id": "saint_saviour",
                "code": "saint_saviour",
                "name": "Saint Saviour",
                "pk": "XU__saint_saviour"
            },
            {
                "_id": "sark",
                "code": "sark",
                "name": "Sark",
                "pk": "XU__sark"
            },
            {
                "_id": "torteval",
                "code": "torteval",
                "name": "Torteval",
                "pk": "XU__torteval"
            },
            {
                "_id": "vale",
                "code": "vale",
                "name": "Vale",
                "pk": "XU__vale"
            }
        ],
        "pk": "XU"
    },
    {
        "_id": "GN",
        "code": "GN",
        "name": "Guinée",
        "states": [
            {
                "_id": "beyla",
                "code": "beyla",
                "name": "Beyla",
                "pk": "GN__beyla"
            },
            {
                "_id": "boffa",
                "code": "boffa",
                "name": "Boffa",
                "pk": "GN__boffa"
            },
            {
                "_id": "boke",
                "code": "boke",
                "name": "Boke",
                "pk": "GN__boke"
            },
            {
                "_id": "conakry",
                "code": "conakry",
                "name": "Conakry",
                "pk": "GN__conakry"
            },
            {
                "_id": "coyah",
                "code": "coyah",
                "name": "Coyah",
                "pk": "GN__coyah"
            },
            {
                "_id": "dabola",
                "code": "dabola",
                "name": "Dabola",
                "pk": "GN__dabola"
            },
            {
                "_id": "dalaba",
                "code": "dalaba",
                "name": "Dalaba",
                "pk": "GN__dalaba"
            },
            {
                "_id": "dinguiraye",
                "code": "dinguiraye",
                "name": "Dinguiraye",
                "pk": "GN__dinguiraye"
            },
            {
                "_id": "faranah",
                "code": "faranah",
                "name": "Faranah",
                "pk": "GN__faranah"
            },
            {
                "_id": "forecariah",
                "code": "forecariah",
                "name": "Forecariah",
                "pk": "GN__forecariah"
            },
            {
                "_id": "fria",
                "code": "fria",
                "name": "Fria",
                "pk": "GN__fria"
            },
            {
                "_id": "gaoual",
                "code": "gaoual",
                "name": "Gaoual",
                "pk": "GN__gaoual"
            },
            {
                "_id": "gueckedou",
                "code": "gueckedou",
                "name": "Gueckedou",
                "pk": "GN__gueckedou"
            },
            {
                "_id": "kankan",
                "code": "kankan",
                "name": "Kankan",
                "pk": "GN__kankan"
            },
            {
                "_id": "kerouane",
                "code": "kerouane",
                "name": "Kerouane",
                "pk": "GN__kerouane"
            },
            {
                "_id": "kindia",
                "code": "kindia",
                "name": "Kindia",
                "pk": "GN__kindia"
            },
            {
                "_id": "kissidougou",
                "code": "kissidougou",
                "name": "Kissidougou",
                "pk": "GN__kissidougou"
            },
            {
                "_id": "koubia",
                "code": "koubia",
                "name": "Koubia",
                "pk": "GN__koubia"
            },
            {
                "_id": "koundara",
                "code": "koundara",
                "name": "Koundara",
                "pk": "GN__koundara"
            },
            {
                "_id": "kouroussa",
                "code": "kouroussa",
                "name": "Kouroussa",
                "pk": "GN__kouroussa"
            },
            {
                "_id": "labe",
                "code": "labe",
                "name": "Labe",
                "pk": "GN__labe"
            },
            {
                "_id": "lola",
                "code": "lola",
                "name": "Lola",
                "pk": "GN__lola"
            },
            {
                "_id": "macenta",
                "code": "macenta",
                "name": "Macenta",
                "pk": "GN__macenta"
            },
            {
                "_id": "mali",
                "code": "mali",
                "name": "Mali",
                "pk": "GN__mali"
            },
            {
                "_id": "mamou",
                "code": "mamou",
                "name": "Mamou",
                "pk": "GN__mamou"
            },
            {
                "_id": "mandiana",
                "code": "mandiana",
                "name": "Mandiana",
                "pk": "GN__mandiana"
            },
            {
                "_id": "nzerekore",
                "code": "nzerekore",
                "name": "Nzerekore",
                "pk": "GN__nzerekore"
            },
            {
                "_id": "pita",
                "code": "pita",
                "name": "Pita",
                "pk": "GN__pita"
            },
            {
                "_id": "siguiri",
                "code": "siguiri",
                "name": "Siguiri",
                "pk": "GN__siguiri"
            },
            {
                "_id": "telimele",
                "code": "telimele",
                "name": "Telimele",
                "pk": "GN__telimele"
            },
            {
                "_id": "tougue",
                "code": "tougue",
                "name": "Tougue",
                "pk": "GN__tougue"
            },
            {
                "_id": "yomou",
                "code": "yomou",
                "name": "Yomou",
                "pk": "GN__yomou"
            }
        ],
        "pk": "GN"
    },
    {
        "_id": "GW",
        "code": "GW",
        "name": "Guinée-Bissau",
        "states": [
            {
                "_id": "bafata",
                "code": "bafata",
                "name": "Bafata",
                "pk": "GW__bafata"
            },
            {
                "_id": "bissau",
                "code": "bissau",
                "name": "Bissau",
                "pk": "GW__bissau"
            },
            {
                "_id": "bolama",
                "code": "bolama",
                "name": "Bolama",
                "pk": "GW__bolama"
            },
            {
                "_id": "cacheu",
                "code": "cacheu",
                "name": "Cacheu",
                "pk": "GW__cacheu"
            },
            {
                "_id": "gabu",
                "code": "gabu",
                "name": "Gabu",
                "pk": "GW__gabu"
            },
            {
                "_id": "oio",
                "code": "oio",
                "name": "Oio",
                "pk": "GW__oio"
            },
            {
                "_id": "quinara",
                "code": "quinara",
                "name": "Quinara",
                "pk": "GW__quinara"
            },
            {
                "_id": "tombali",
                "code": "tombali",
                "name": "Tombali",
                "pk": "GW__tombali"
            }
        ],
        "pk": "GW"
    },
    {
        "_id": "GY",
        "code": "GY",
        "name": "Guyane",
        "states": [
            {
                "_id": "barima-waini",
                "code": "barima-waini",
                "name": "Barima-Waini",
                "pk": "GY__barima-waini"
            },
            {
                "_id": "cuyuni-mazaruni",
                "code": "cuyuni-mazaruni",
                "name": "Cuyuni-Mazaruni",
                "pk": "GY__cuyuni-mazaruni"
            },
            {
                "_id": "demerara-mahaica",
                "code": "demerara-mahaica",
                "name": "Demerara-Mahaica",
                "pk": "GY__demerara-mahaica"
            },
            {
                "_id": "east_berbice-corentyne",
                "code": "east_berbice-corentyne",
                "name": "East Berbice-Corentyne",
                "pk": "GY__east_berbice-corentyne"
            },
            {
                "_id": "essequibo_islands-west_demerar",
                "code": "essequibo_islands-west_demerar",
                "name": "Essequibo Islands-West Demerar",
                "pk": "GY__essequibo_islands-west_demerar"
            },
            {
                "_id": "mahaica-berbice",
                "code": "mahaica-berbice",
                "name": "Mahaica-Berbice",
                "pk": "GY__mahaica-berbice"
            },
            {
                "_id": "pomeroon-supenaam",
                "code": "pomeroon-supenaam",
                "name": "Pomeroon-Supenaam",
                "pk": "GY__pomeroon-supenaam"
            },
            {
                "_id": "potaro-siparuni",
                "code": "potaro-siparuni",
                "name": "Potaro-Siparuni",
                "pk": "GY__potaro-siparuni"
            },
            {
                "_id": "upper_demerara-berbice",
                "code": "upper_demerara-berbice",
                "name": "Upper Demerara-Berbice",
                "pk": "GY__upper_demerara-berbice"
            },
            {
                "_id": "upper_takutu-upper_essequibo",
                "code": "upper_takutu-upper_essequibo",
                "name": "Upper Takutu-Upper Essequibo",
                "pk": "GY__upper_takutu-upper_essequibo"
            }
        ],
        "pk": "GY"
    },
    {
        "_id": "HT",
        "code": "HT",
        "name": "Haïti",
        "states": [
            {
                "_id": "artibonite",
                "code": "artibonite",
                "name": "Artibonite",
                "pk": "HT__artibonite"
            },
            {
                "_id": "centre",
                "code": "centre",
                "name": "Centre",
                "pk": "HT__centre"
            },
            {
                "_id": "grandanse",
                "code": "grandanse",
                "name": "GrandAnse",
                "pk": "HT__grandanse"
            },
            {
                "_id": "nord",
                "code": "nord",
                "name": "Nord",
                "pk": "HT__nord"
            },
            {
                "_id": "nord-est",
                "code": "nord-est",
                "name": "Nord-Est",
                "pk": "HT__nord-est"
            },
            {
                "_id": "nord-ouest",
                "code": "nord-ouest",
                "name": "Nord-Ouest",
                "pk": "HT__nord-ouest"
            },
            {
                "_id": "ouest",
                "code": "ouest",
                "name": "Ouest",
                "pk": "HT__ouest"
            },
            {
                "_id": "sud",
                "code": "sud",
                "name": "Sud",
                "pk": "HT__sud"
            },
            {
                "_id": "sud-est",
                "code": "sud-est",
                "name": "Sud-Est",
                "pk": "HT__sud-est"
            }
        ],
        "pk": "HT"
    },
    {
        "_id": "HM",
        "code": "HM",
        "name": "Îles Heard-et-MacDonald",
        "states": [
            {
                "_id": "heard_and_mcdonald_islands",
                "code": "heard_and_mcdonald_islands",
                "name": "Heard and McDonald Islands",
                "pk": "HM__heard_and_mcdonald_islands"
            }
        ],
        "pk": "HM"
    },
    {
        "_id": "HN",
        "code": "HN",
        "name": "Honduras",
        "states": [
            {
                "_id": "atlantida",
                "code": "atlantida",
                "name": "Atlantida",
                "pk": "HN__atlantida"
            },
            {
                "_id": "choluteca",
                "code": "choluteca",
                "name": "Choluteca",
                "pk": "HN__choluteca"
            },
            {
                "_id": "colon",
                "code": "colon",
                "name": "Colon",
                "pk": "HN__colon"
            },
            {
                "_id": "comayagua",
                "code": "comayagua",
                "name": "Comayagua",
                "pk": "HN__comayagua"
            },
            {
                "_id": "copan",
                "code": "copan",
                "name": "Copan",
                "pk": "HN__copan"
            },
            {
                "_id": "cortes",
                "code": "cortes",
                "name": "Cortes",
                "pk": "HN__cortes"
            },
            {
                "_id": "distrito_central",
                "code": "distrito_central",
                "name": "Distrito Central",
                "pk": "HN__distrito_central"
            },
            {
                "_id": "el_paraiso",
                "code": "el_paraiso",
                "name": "El Paraiso",
                "pk": "HN__el_paraiso"
            },
            {
                "_id": "francisco_morazan",
                "code": "francisco_morazan",
                "name": "Francisco Morazan",
                "pk": "HN__francisco_morazan"
            },
            {
                "_id": "gracias_a_dios",
                "code": "gracias_a_dios",
                "name": "Gracias a Dios",
                "pk": "HN__gracias_a_dios"
            },
            {
                "_id": "intibuca",
                "code": "intibuca",
                "name": "Intibuca",
                "pk": "HN__intibuca"
            },
            {
                "_id": "islas_de_la_bahia",
                "code": "islas_de_la_bahia",
                "name": "Islas de la Bahia",
                "pk": "HN__islas_de_la_bahia"
            },
            {
                "_id": "la_paz",
                "code": "la_paz",
                "name": "La Paz",
                "pk": "HN__la_paz"
            },
            {
                "_id": "lempira",
                "code": "lempira",
                "name": "Lempira",
                "pk": "HN__lempira"
            },
            {
                "_id": "ocotepeque",
                "code": "ocotepeque",
                "name": "Ocotepeque",
                "pk": "HN__ocotepeque"
            },
            {
                "_id": "olancho",
                "code": "olancho",
                "name": "Olancho",
                "pk": "HN__olancho"
            },
            {
                "_id": "santa_barbara",
                "code": "santa_barbara",
                "name": "Santa Barbara",
                "pk": "HN__santa_barbara"
            },
            {
                "_id": "valle",
                "code": "valle",
                "name": "Valle",
                "pk": "HN__valle"
            },
            {
                "_id": "yoro",
                "code": "yoro",
                "name": "Yoro",
                "pk": "HN__yoro"
            }
        ],
        "pk": "HN"
    },
    {
        "_id": "HK",
        "code": "HK",
        "name": "R.A.S. de Hong Kong",
        "states": [
            {
                "_id": "hong_kong",
                "code": "hong_kong",
                "name": "Hong Kong",
                "pk": "HK__hong_kong"
            }
        ],
        "pk": "HK"
    },
    {
        "_id": "HU",
        "code": "HU",
        "name": "Hongrie",
        "states": [
            {
                "_id": "bacs-kiskun",
                "code": "bacs-kiskun",
                "name": "Bacs-Kiskun",
                "pk": "HU__bacs-kiskun"
            },
            {
                "_id": "baranya",
                "code": "baranya",
                "name": "Baranya",
                "pk": "HU__baranya"
            },
            {
                "_id": "bekes",
                "code": "bekes",
                "name": "Bekes",
                "pk": "HU__bekes"
            },
            {
                "_id": "borsod-abauj-zemplen",
                "code": "borsod-abauj-zemplen",
                "name": "Borsod-Abauj-Zemplen",
                "pk": "HU__borsod-abauj-zemplen"
            },
            {
                "_id": "budapest",
                "code": "budapest",
                "name": "Budapest",
                "pk": "HU__budapest"
            },
            {
                "_id": "csongrad",
                "code": "csongrad",
                "name": "Csongrad",
                "pk": "HU__csongrad"
            },
            {
                "_id": "fejer",
                "code": "fejer",
                "name": "Fejer",
                "pk": "HU__fejer"
            },
            {
                "_id": "gyor-moson-sopron",
                "code": "gyor-moson-sopron",
                "name": "Gyor-Moson-Sopron",
                "pk": "HU__gyor-moson-sopron"
            },
            {
                "_id": "hajdu-bihar",
                "code": "hajdu-bihar",
                "name": "Hajdu-Bihar",
                "pk": "HU__hajdu-bihar"
            },
            {
                "_id": "heves",
                "code": "heves",
                "name": "Heves",
                "pk": "HU__heves"
            },
            {
                "_id": "jasz-nagykun-szolnok",
                "code": "jasz-nagykun-szolnok",
                "name": "Jasz-Nagykun-Szolnok",
                "pk": "HU__jasz-nagykun-szolnok"
            },
            {
                "_id": "komarom-esztergom",
                "code": "komarom-esztergom",
                "name": "Komarom-Esztergom",
                "pk": "HU__komarom-esztergom"
            },
            {
                "_id": "nograd",
                "code": "nograd",
                "name": "Nograd",
                "pk": "HU__nograd"
            },
            {
                "_id": "pest",
                "code": "pest",
                "name": "Pest",
                "pk": "HU__pest"
            },
            {
                "_id": "somogy",
                "code": "somogy",
                "name": "Somogy",
                "pk": "HU__somogy"
            },
            {
                "_id": "szabolcs-szatmar-bereg",
                "code": "szabolcs-szatmar-bereg",
                "name": "Szabolcs-Szatmar-Bereg",
                "pk": "HU__szabolcs-szatmar-bereg"
            },
            {
                "_id": "tolna",
                "code": "tolna",
                "name": "Tolna",
                "pk": "HU__tolna"
            },
            {
                "_id": "vas",
                "code": "vas",
                "name": "Vas",
                "pk": "HU__vas"
            },
            {
                "_id": "veszprem",
                "code": "veszprem",
                "name": "Veszprem",
                "pk": "HU__veszprem"
            },
            {
                "_id": "zala",
                "code": "zala",
                "name": "Zala",
                "pk": "HU__zala"
            }
        ],
        "pk": "HU"
    },
    {
        "_id": "IS",
        "code": "IS",
        "name": "Islande",
        "states": [
            {
                "_id": "austurland",
                "code": "austurland",
                "name": "Austurland",
                "pk": "IS__austurland"
            },
            {
                "_id": "gullbringusysla",
                "code": "gullbringusysla",
                "name": "Gullbringusysla",
                "pk": "IS__gullbringusysla"
            },
            {
                "_id": "hofu_borgarsva_i",
                "code": "hofu_borgarsva_i",
                "name": "Hofu borgarsva i",
                "pk": "IS__hofu_borgarsva_i"
            },
            {
                "_id": "nor_urland_eystra",
                "code": "nor_urland_eystra",
                "name": "Nor urland eystra",
                "pk": "IS__nor_urland_eystra"
            },
            {
                "_id": "nor_urland_vestra",
                "code": "nor_urland_vestra",
                "name": "Nor urland vestra",
                "pk": "IS__nor_urland_vestra"
            },
            {
                "_id": "su_urland",
                "code": "su_urland",
                "name": "Su urland",
                "pk": "IS__su_urland"
            },
            {
                "_id": "su_urnes",
                "code": "su_urnes",
                "name": "Su urnes",
                "pk": "IS__su_urnes"
            },
            {
                "_id": "vestfir_ir",
                "code": "vestfir_ir",
                "name": "Vestfir ir",
                "pk": "IS__vestfir_ir"
            },
            {
                "_id": "vesturland",
                "code": "vesturland",
                "name": "Vesturland",
                "pk": "IS__vesturland"
            }
        ],
        "pk": "IS"
    },
    {
        "_id": "IN",
        "code": "IN",
        "name": "Inde",
        "states": [
            {
                "_id": "andaman_and_nicobar_islands",
                "code": "andaman_and_nicobar_islands",
                "name": "Andaman and Nicobar Islands",
                "pk": "IN__andaman_and_nicobar_islands"
            },
            {
                "_id": "andhra_pradesh",
                "code": "andhra_pradesh",
                "name": "Andhra Pradesh",
                "pk": "IN__andhra_pradesh"
            },
            {
                "_id": "arunachal_pradesh",
                "code": "arunachal_pradesh",
                "name": "Arunachal Pradesh",
                "pk": "IN__arunachal_pradesh"
            },
            {
                "_id": "assam",
                "code": "assam",
                "name": "Assam",
                "pk": "IN__assam"
            },
            {
                "_id": "bihar",
                "code": "bihar",
                "name": "Bihar",
                "pk": "IN__bihar"
            },
            {
                "_id": "chandigarh",
                "code": "chandigarh",
                "name": "Chandigarh",
                "pk": "IN__chandigarh"
            },
            {
                "_id": "chhattisgarh",
                "code": "chhattisgarh",
                "name": "Chhattisgarh",
                "pk": "IN__chhattisgarh"
            },
            {
                "_id": "dadra_and_nagar_haveli",
                "code": "dadra_and_nagar_haveli",
                "name": "Dadra and Nagar Haveli",
                "pk": "IN__dadra_and_nagar_haveli"
            },
            {
                "_id": "daman_and_diu",
                "code": "daman_and_diu",
                "name": "Daman and Diu",
                "pk": "IN__daman_and_diu"
            },
            {
                "_id": "delhi",
                "code": "delhi",
                "name": "Delhi",
                "pk": "IN__delhi"
            },
            {
                "_id": "goa",
                "code": "goa",
                "name": "Goa",
                "pk": "IN__goa"
            },
            {
                "_id": "gujarat",
                "code": "gujarat",
                "name": "Gujarat",
                "pk": "IN__gujarat"
            },
            {
                "_id": "haryana",
                "code": "haryana",
                "name": "Haryana",
                "pk": "IN__haryana"
            },
            {
                "_id": "himachal_pradesh",
                "code": "himachal_pradesh",
                "name": "Himachal Pradesh",
                "pk": "IN__himachal_pradesh"
            },
            {
                "_id": "jammu_and_kashmir",
                "code": "jammu_and_kashmir",
                "name": "Jammu and Kashmir",
                "pk": "IN__jammu_and_kashmir"
            },
            {
                "_id": "jharkhand",
                "code": "jharkhand",
                "name": "Jharkhand",
                "pk": "IN__jharkhand"
            },
            {
                "_id": "karnataka",
                "code": "karnataka",
                "name": "Karnataka",
                "pk": "IN__karnataka"
            },
            {
                "_id": "kenmore",
                "code": "kenmore",
                "name": "Kenmore",
                "pk": "IN__kenmore"
            },
            {
                "_id": "kerala",
                "code": "kerala",
                "name": "Kerala",
                "pk": "IN__kerala"
            },
            {
                "_id": "lakshadweep",
                "code": "lakshadweep",
                "name": "Lakshadweep",
                "pk": "IN__lakshadweep"
            },
            {
                "_id": "madhya_pradesh",
                "code": "madhya_pradesh",
                "name": "Madhya Pradesh",
                "pk": "IN__madhya_pradesh"
            },
            {
                "_id": "maharashtra",
                "code": "maharashtra",
                "name": "Maharashtra",
                "pk": "IN__maharashtra"
            },
            {
                "_id": "manipur",
                "code": "manipur",
                "name": "Manipur",
                "pk": "IN__manipur"
            },
            {
                "_id": "meghalaya",
                "code": "meghalaya",
                "name": "Meghalaya",
                "pk": "IN__meghalaya"
            },
            {
                "_id": "mizoram",
                "code": "mizoram",
                "name": "Mizoram",
                "pk": "IN__mizoram"
            },
            {
                "_id": "nagaland",
                "code": "nagaland",
                "name": "Nagaland",
                "pk": "IN__nagaland"
            },
            {
                "_id": "narora",
                "code": "narora",
                "name": "Narora",
                "pk": "IN__narora"
            },
            {
                "_id": "natwar",
                "code": "natwar",
                "name": "Natwar",
                "pk": "IN__natwar"
            },
            {
                "_id": "odisha",
                "code": "odisha",
                "name": "Odisha",
                "pk": "IN__odisha"
            },
            {
                "_id": "paschim_medinipur",
                "code": "paschim_medinipur",
                "name": "Paschim Medinipur",
                "pk": "IN__paschim_medinipur"
            },
            {
                "_id": "pondicherry",
                "code": "pondicherry",
                "name": "Pondicherry",
                "pk": "IN__pondicherry"
            },
            {
                "_id": "punjab",
                "code": "punjab",
                "name": "Punjab",
                "pk": "IN__punjab"
            },
            {
                "_id": "rajasthan",
                "code": "rajasthan",
                "name": "Rajasthan",
                "pk": "IN__rajasthan"
            },
            {
                "_id": "sikkim",
                "code": "sikkim",
                "name": "Sikkim",
                "pk": "IN__sikkim"
            },
            {
                "_id": "tamil_nadu",
                "code": "tamil_nadu",
                "name": "Tamil Nadu",
                "pk": "IN__tamil_nadu"
            },
            {
                "_id": "telangana",
                "code": "telangana",
                "name": "Telangana",
                "pk": "IN__telangana"
            },
            {
                "_id": "tripura",
                "code": "tripura",
                "name": "Tripura",
                "pk": "IN__tripura"
            },
            {
                "_id": "uttar_pradesh",
                "code": "uttar_pradesh",
                "name": "Uttar Pradesh",
                "pk": "IN__uttar_pradesh"
            },
            {
                "_id": "uttarakhand",
                "code": "uttarakhand",
                "name": "Uttarakhand",
                "pk": "IN__uttarakhand"
            },
            {
                "_id": "vaishali",
                "code": "vaishali",
                "name": "Vaishali",
                "pk": "IN__vaishali"
            },
            {
                "_id": "west_bengal",
                "code": "west_bengal",
                "name": "West Bengal",
                "pk": "IN__west_bengal"
            }
        ],
        "pk": "IN"
    },
    {
        "_id": "ID",
        "code": "ID",
        "name": "Indonésie",
        "states": [
            {
                "_id": "aceh",
                "code": "aceh",
                "name": "Aceh",
                "pk": "ID__aceh"
            },
            {
                "_id": "bali",
                "code": "bali",
                "name": "Bali",
                "pk": "ID__bali"
            },
            {
                "_id": "bangka-belitung",
                "code": "bangka-belitung",
                "name": "Bangka-Belitung",
                "pk": "ID__bangka-belitung"
            },
            {
                "_id": "banten",
                "code": "banten",
                "name": "Banten",
                "pk": "ID__banten"
            },
            {
                "_id": "bengkulu",
                "code": "bengkulu",
                "name": "Bengkulu",
                "pk": "ID__bengkulu"
            },
            {
                "_id": "gandaria",
                "code": "gandaria",
                "name": "Gandaria",
                "pk": "ID__gandaria"
            },
            {
                "_id": "gorontalo",
                "code": "gorontalo",
                "name": "Gorontalo",
                "pk": "ID__gorontalo"
            },
            {
                "_id": "jakarta",
                "code": "jakarta",
                "name": "Jakarta",
                "pk": "ID__jakarta"
            },
            {
                "_id": "jambi",
                "code": "jambi",
                "name": "Jambi",
                "pk": "ID__jambi"
            },
            {
                "_id": "jawa_barat",
                "code": "jawa_barat",
                "name": "Jawa Barat",
                "pk": "ID__jawa_barat"
            },
            {
                "_id": "jawa_tengah",
                "code": "jawa_tengah",
                "name": "Jawa Tengah",
                "pk": "ID__jawa_tengah"
            },
            {
                "_id": "jawa_timur",
                "code": "jawa_timur",
                "name": "Jawa Timur",
                "pk": "ID__jawa_timur"
            },
            {
                "_id": "kalimantan_barat",
                "code": "kalimantan_barat",
                "name": "Kalimantan Barat",
                "pk": "ID__kalimantan_barat"
            },
            {
                "_id": "kalimantan_selatan",
                "code": "kalimantan_selatan",
                "name": "Kalimantan Selatan",
                "pk": "ID__kalimantan_selatan"
            },
            {
                "_id": "kalimantan_tengah",
                "code": "kalimantan_tengah",
                "name": "Kalimantan Tengah",
                "pk": "ID__kalimantan_tengah"
            },
            {
                "_id": "kalimantan_timur",
                "code": "kalimantan_timur",
                "name": "Kalimantan Timur",
                "pk": "ID__kalimantan_timur"
            },
            {
                "_id": "kendal",
                "code": "kendal",
                "name": "Kendal",
                "pk": "ID__kendal"
            },
            {
                "_id": "lampung",
                "code": "lampung",
                "name": "Lampung",
                "pk": "ID__lampung"
            },
            {
                "_id": "maluku",
                "code": "maluku",
                "name": "Maluku",
                "pk": "ID__maluku"
            },
            {
                "_id": "maluku_utara",
                "code": "maluku_utara",
                "name": "Maluku Utara",
                "pk": "ID__maluku_utara"
            },
            {
                "_id": "nusa_tenggara_barat",
                "code": "nusa_tenggara_barat",
                "name": "Nusa Tenggara Barat",
                "pk": "ID__nusa_tenggara_barat"
            },
            {
                "_id": "nusa_tenggara_timur",
                "code": "nusa_tenggara_timur",
                "name": "Nusa Tenggara Timur",
                "pk": "ID__nusa_tenggara_timur"
            },
            {
                "_id": "papua",
                "code": "papua",
                "name": "Papua",
                "pk": "ID__papua"
            },
            {
                "_id": "riau",
                "code": "riau",
                "name": "Riau",
                "pk": "ID__riau"
            },
            {
                "_id": "riau_kepulauan",
                "code": "riau_kepulauan",
                "name": "Riau Kepulauan",
                "pk": "ID__riau_kepulauan"
            },
            {
                "_id": "solo",
                "code": "solo",
                "name": "Solo",
                "pk": "ID__solo"
            },
            {
                "_id": "sulawesi_selatan",
                "code": "sulawesi_selatan",
                "name": "Sulawesi Selatan",
                "pk": "ID__sulawesi_selatan"
            },
            {
                "_id": "sulawesi_tengah",
                "code": "sulawesi_tengah",
                "name": "Sulawesi Tengah",
                "pk": "ID__sulawesi_tengah"
            },
            {
                "_id": "sulawesi_tenggara",
                "code": "sulawesi_tenggara",
                "name": "Sulawesi Tenggara",
                "pk": "ID__sulawesi_tenggara"
            },
            {
                "_id": "sulawesi_utara",
                "code": "sulawesi_utara",
                "name": "Sulawesi Utara",
                "pk": "ID__sulawesi_utara"
            },
            {
                "_id": "sumatera_barat",
                "code": "sumatera_barat",
                "name": "Sumatera Barat",
                "pk": "ID__sumatera_barat"
            },
            {
                "_id": "sumatera_selatan",
                "code": "sumatera_selatan",
                "name": "Sumatera Selatan",
                "pk": "ID__sumatera_selatan"
            },
            {
                "_id": "sumatera_utara",
                "code": "sumatera_utara",
                "name": "Sumatera Utara",
                "pk": "ID__sumatera_utara"
            },
            {
                "_id": "yogyakarta",
                "code": "yogyakarta",
                "name": "Yogyakarta",
                "pk": "ID__yogyakarta"
            }
        ],
        "pk": "ID"
    },
    {
        "_id": "IR",
        "code": "IR",
        "name": "Iran",
        "states": [
            {
                "_id": "ardabil",
                "code": "ardabil",
                "name": "Ardabil",
                "pk": "IR__ardabil"
            },
            {
                "_id": "azarbayjan-e_bakhtari",
                "code": "azarbayjan-e_bakhtari",
                "name": "Azarbayjan-e Bakhtari",
                "pk": "IR__azarbayjan-e_bakhtari"
            },
            {
                "_id": "azarbayjan-e_khavari",
                "code": "azarbayjan-e_khavari",
                "name": "Azarbayjan-e Khavari",
                "pk": "IR__azarbayjan-e_khavari"
            },
            {
                "_id": "bushehr",
                "code": "bushehr",
                "name": "Bushehr",
                "pk": "IR__bushehr"
            },
            {
                "_id": "chahar_mahal-e_bakhtiari",
                "code": "chahar_mahal-e_bakhtiari",
                "name": "Chahar Mahal-e Bakhtiari",
                "pk": "IR__chahar_mahal-e_bakhtiari"
            },
            {
                "_id": "esfahan",
                "code": "esfahan",
                "name": "Esfahan",
                "pk": "IR__esfahan"
            },
            {
                "_id": "fars",
                "code": "fars",
                "name": "Fars",
                "pk": "IR__fars"
            },
            {
                "_id": "gilan",
                "code": "gilan",
                "name": "Gilan",
                "pk": "IR__gilan"
            },
            {
                "_id": "golestan",
                "code": "golestan",
                "name": "Golestan",
                "pk": "IR__golestan"
            },
            {
                "_id": "hamadan",
                "code": "hamadan",
                "name": "Hamadan",
                "pk": "IR__hamadan"
            },
            {
                "_id": "hormozgan",
                "code": "hormozgan",
                "name": "Hormozgan",
                "pk": "IR__hormozgan"
            },
            {
                "_id": "ilam",
                "code": "ilam",
                "name": "Ilam",
                "pk": "IR__ilam"
            },
            {
                "_id": "kerman",
                "code": "kerman",
                "name": "Kerman",
                "pk": "IR__kerman"
            },
            {
                "_id": "kermanshah",
                "code": "kermanshah",
                "name": "Kermanshah",
                "pk": "IR__kermanshah"
            },
            {
                "_id": "khorasan",
                "code": "khorasan",
                "name": "Khorasan",
                "pk": "IR__khorasan"
            },
            {
                "_id": "khuzestan",
                "code": "khuzestan",
                "name": "Khuzestan",
                "pk": "IR__khuzestan"
            },
            {
                "_id": "kohgiluyeh-e_boyerahmad",
                "code": "kohgiluyeh-e_boyerahmad",
                "name": "Kohgiluyeh-e Boyerahmad",
                "pk": "IR__kohgiluyeh-e_boyerahmad"
            },
            {
                "_id": "kordestan",
                "code": "kordestan",
                "name": "Kordestan",
                "pk": "IR__kordestan"
            },
            {
                "_id": "lorestan",
                "code": "lorestan",
                "name": "Lorestan",
                "pk": "IR__lorestan"
            },
            {
                "_id": "markazi",
                "code": "markazi",
                "name": "Markazi",
                "pk": "IR__markazi"
            },
            {
                "_id": "mazandaran",
                "code": "mazandaran",
                "name": "Mazandaran",
                "pk": "IR__mazandaran"
            },
            {
                "_id": "ostan-e_esfahan",
                "code": "ostan-e_esfahan",
                "name": "Ostan-e Esfahan",
                "pk": "IR__ostan-e_esfahan"
            },
            {
                "_id": "qazvin",
                "code": "qazvin",
                "name": "Qazvin",
                "pk": "IR__qazvin"
            },
            {
                "_id": "qom",
                "code": "qom",
                "name": "Qom",
                "pk": "IR__qom"
            },
            {
                "_id": "semnan",
                "code": "semnan",
                "name": "Semnan",
                "pk": "IR__semnan"
            },
            {
                "_id": "sistan-e_baluchestan",
                "code": "sistan-e_baluchestan",
                "name": "Sistan-e Baluchestan",
                "pk": "IR__sistan-e_baluchestan"
            },
            {
                "_id": "tehran",
                "code": "tehran",
                "name": "Tehran",
                "pk": "IR__tehran"
            },
            {
                "_id": "yazd",
                "code": "yazd",
                "name": "Yazd",
                "pk": "IR__yazd"
            },
            {
                "_id": "zanjan",
                "code": "zanjan",
                "name": "Zanjan",
                "pk": "IR__zanjan"
            }
        ],
        "pk": "IR"
    },
    {
        "_id": "IQ",
        "code": "IQ",
        "name": "Irak",
        "states": [
            {
                "_id": "babil",
                "code": "babil",
                "name": "Babil",
                "pk": "IQ__babil"
            },
            {
                "_id": "baghdad",
                "code": "baghdad",
                "name": "Baghdad",
                "pk": "IQ__baghdad"
            },
            {
                "_id": "dahuk",
                "code": "dahuk",
                "name": "Dahuk",
                "pk": "IQ__dahuk"
            },
            {
                "_id": "dhi_qar",
                "code": "dhi_qar",
                "name": "Dhi Qar",
                "pk": "IQ__dhi_qar"
            },
            {
                "_id": "diyala",
                "code": "diyala",
                "name": "Diyala",
                "pk": "IQ__diyala"
            },
            {
                "_id": "erbil",
                "code": "erbil",
                "name": "Erbil",
                "pk": "IQ__erbil"
            },
            {
                "_id": "irbil",
                "code": "irbil",
                "name": "Irbil",
                "pk": "IQ__irbil"
            },
            {
                "_id": "karbala",
                "code": "karbala",
                "name": "Karbala",
                "pk": "IQ__karbala"
            },
            {
                "_id": "kurdistan",
                "code": "kurdistan",
                "name": "Kurdistan",
                "pk": "IQ__kurdistan"
            },
            {
                "_id": "maysan",
                "code": "maysan",
                "name": "Maysan",
                "pk": "IQ__maysan"
            },
            {
                "_id": "ninawa",
                "code": "ninawa",
                "name": "Ninawa",
                "pk": "IQ__ninawa"
            },
            {
                "_id": "salah-ad-din",
                "code": "salah-ad-din",
                "name": "Salah-ad-Din",
                "pk": "IQ__salah-ad-din"
            },
            {
                "_id": "wasit",
                "code": "wasit",
                "name": "Wasit",
                "pk": "IQ__wasit"
            },
            {
                "_id": "al-anbar",
                "code": "al-anbar",
                "name": "al-Anbar",
                "pk": "IQ__al-anbar"
            },
            {
                "_id": "al-basrah",
                "code": "al-basrah",
                "name": "al-Basrah",
                "pk": "IQ__al-basrah"
            },
            {
                "_id": "al-muthanna",
                "code": "al-muthanna",
                "name": "al-Muthanna",
                "pk": "IQ__al-muthanna"
            },
            {
                "_id": "al-qadisiyah",
                "code": "al-qadisiyah",
                "name": "al-Qadisiyah",
                "pk": "IQ__al-qadisiyah"
            },
            {
                "_id": "an-najaf",
                "code": "an-najaf",
                "name": "an-Najaf",
                "pk": "IQ__an-najaf"
            },
            {
                "_id": "as-sulaymaniyah",
                "code": "as-sulaymaniyah",
                "name": "as-Sulaymaniyah",
                "pk": "IQ__as-sulaymaniyah"
            },
            {
                "_id": "at-tamim",
                "code": "at-tamim",
                "name": "at-Tamim",
                "pk": "IQ__at-tamim"
            }
        ],
        "pk": "IQ"
    },
    {
        "_id": "IE",
        "code": "IE",
        "name": "Irlande",
        "states": [
            {
                "_id": "armagh",
                "code": "armagh",
                "name": "Armagh",
                "pk": "IE__armagh"
            },
            {
                "_id": "carlow",
                "code": "carlow",
                "name": "Carlow",
                "pk": "IE__carlow"
            },
            {
                "_id": "cavan",
                "code": "cavan",
                "name": "Cavan",
                "pk": "IE__cavan"
            },
            {
                "_id": "clare",
                "code": "clare",
                "name": "Clare",
                "pk": "IE__clare"
            },
            {
                "_id": "cork",
                "code": "cork",
                "name": "Cork",
                "pk": "IE__cork"
            },
            {
                "_id": "donegal",
                "code": "donegal",
                "name": "Donegal",
                "pk": "IE__donegal"
            },
            {
                "_id": "dublin",
                "code": "dublin",
                "name": "Dublin",
                "pk": "IE__dublin"
            },
            {
                "_id": "galway",
                "code": "galway",
                "name": "Galway",
                "pk": "IE__galway"
            },
            {
                "_id": "kerry",
                "code": "kerry",
                "name": "Kerry",
                "pk": "IE__kerry"
            },
            {
                "_id": "kildare",
                "code": "kildare",
                "name": "Kildare",
                "pk": "IE__kildare"
            },
            {
                "_id": "kilkenny",
                "code": "kilkenny",
                "name": "Kilkenny",
                "pk": "IE__kilkenny"
            },
            {
                "_id": "laois",
                "code": "laois",
                "name": "Laois",
                "pk": "IE__laois"
            },
            {
                "_id": "leinster",
                "code": "leinster",
                "name": "Leinster",
                "pk": "IE__leinster"
            },
            {
                "_id": "leitrim",
                "code": "leitrim",
                "name": "Leitrim",
                "pk": "IE__leitrim"
            },
            {
                "_id": "limerick",
                "code": "limerick",
                "name": "Limerick",
                "pk": "IE__limerick"
            },
            {
                "_id": "loch_garman",
                "code": "loch_garman",
                "name": "Loch Garman",
                "pk": "IE__loch_garman"
            },
            {
                "_id": "longford",
                "code": "longford",
                "name": "Longford",
                "pk": "IE__longford"
            },
            {
                "_id": "louth",
                "code": "louth",
                "name": "Louth",
                "pk": "IE__louth"
            },
            {
                "_id": "mayo",
                "code": "mayo",
                "name": "Mayo",
                "pk": "IE__mayo"
            },
            {
                "_id": "meath",
                "code": "meath",
                "name": "Meath",
                "pk": "IE__meath"
            },
            {
                "_id": "monaghan",
                "code": "monaghan",
                "name": "Monaghan",
                "pk": "IE__monaghan"
            },
            {
                "_id": "offaly",
                "code": "offaly",
                "name": "Offaly",
                "pk": "IE__offaly"
            },
            {
                "_id": "roscommon",
                "code": "roscommon",
                "name": "Roscommon",
                "pk": "IE__roscommon"
            },
            {
                "_id": "sligo",
                "code": "sligo",
                "name": "Sligo",
                "pk": "IE__sligo"
            },
            {
                "_id": "tipperary_north_riding",
                "code": "tipperary_north_riding",
                "name": "Tipperary North Riding",
                "pk": "IE__tipperary_north_riding"
            },
            {
                "_id": "tipperary_south_riding",
                "code": "tipperary_south_riding",
                "name": "Tipperary South Riding",
                "pk": "IE__tipperary_south_riding"
            },
            {
                "_id": "ulster",
                "code": "ulster",
                "name": "Ulster",
                "pk": "IE__ulster"
            },
            {
                "_id": "waterford",
                "code": "waterford",
                "name": "Waterford",
                "pk": "IE__waterford"
            },
            {
                "_id": "westmeath",
                "code": "westmeath",
                "name": "Westmeath",
                "pk": "IE__westmeath"
            },
            {
                "_id": "wexford",
                "code": "wexford",
                "name": "Wexford",
                "pk": "IE__wexford"
            },
            {
                "_id": "wicklow",
                "code": "wicklow",
                "name": "Wicklow",
                "pk": "IE__wicklow"
            }
        ],
        "only_shipping": true,
        "pk": "IE"
    },
    {
        "_id": "IL",
        "code": "IL",
        "name": "Israël",
        "states": [
            {
                "_id": "beit_hanania",
                "code": "beit_hanania",
                "name": "Beit Hanania",
                "pk": "IL__beit_hanania"
            },
            {
                "_id": "ben_gurion_airport",
                "code": "ben_gurion_airport",
                "name": "Ben Gurion Airport",
                "pk": "IL__ben_gurion_airport"
            },
            {
                "_id": "bethlehem",
                "code": "bethlehem",
                "name": "Bethlehem",
                "pk": "IL__bethlehem"
            },
            {
                "_id": "caesarea",
                "code": "caesarea",
                "name": "Caesarea",
                "pk": "IL__caesarea"
            },
            {
                "_id": "centre",
                "code": "centre",
                "name": "Centre",
                "pk": "IL__centre"
            },
            {
                "_id": "gaza",
                "code": "gaza",
                "name": "Gaza",
                "pk": "IL__gaza"
            },
            {
                "_id": "hadaron",
                "code": "hadaron",
                "name": "Hadaron",
                "pk": "IL__hadaron"
            },
            {
                "_id": "haifa_district",
                "code": "haifa_district",
                "name": "Haifa District",
                "pk": "IL__haifa_district"
            },
            {
                "_id": "hamerkaz",
                "code": "hamerkaz",
                "name": "Hamerkaz",
                "pk": "IL__hamerkaz"
            },
            {
                "_id": "hazafon",
                "code": "hazafon",
                "name": "Hazafon",
                "pk": "IL__hazafon"
            },
            {
                "_id": "hebron",
                "code": "hebron",
                "name": "Hebron",
                "pk": "IL__hebron"
            },
            {
                "_id": "jaffa",
                "code": "jaffa",
                "name": "Jaffa",
                "pk": "IL__jaffa"
            },
            {
                "_id": "jerusalem",
                "code": "jerusalem",
                "name": "Jerusalem",
                "pk": "IL__jerusalem"
            },
            {
                "_id": "khefa",
                "code": "khefa",
                "name": "Khefa",
                "pk": "IL__khefa"
            },
            {
                "_id": "kiryat_yam",
                "code": "kiryat_yam",
                "name": "Kiryat Yam",
                "pk": "IL__kiryat_yam"
            },
            {
                "_id": "lower_galilee",
                "code": "lower_galilee",
                "name": "Lower Galilee",
                "pk": "IL__lower_galilee"
            },
            {
                "_id": "qalqilya",
                "code": "qalqilya",
                "name": "Qalqilya",
                "pk": "IL__qalqilya"
            },
            {
                "_id": "talme_elazar",
                "code": "talme_elazar",
                "name": "Talme Elazar",
                "pk": "IL__talme_elazar"
            },
            {
                "_id": "tel_aviv",
                "code": "tel_aviv",
                "name": "Tel Aviv",
                "pk": "IL__tel_aviv"
            },
            {
                "_id": "tsafon",
                "code": "tsafon",
                "name": "Tsafon",
                "pk": "IL__tsafon"
            },
            {
                "_id": "umm_el_fahem",
                "code": "umm_el_fahem",
                "name": "Umm El Fahem",
                "pk": "IL__umm_el_fahem"
            },
            {
                "_id": "yerushalayim",
                "code": "yerushalayim",
                "name": "Yerushalayim",
                "pk": "IL__yerushalayim"
            }
        ],
        "pk": "IL"
    },
    {
        "_id": "IT",
        "code": "IT",
        "name": "Italie",
        "states": [
            {
                "_id": "abruzzi",
                "code": "abruzzi",
                "name": "Abruzzi",
                "pk": "IT__abruzzi"
            },
            {
                "_id": "abruzzo",
                "code": "abruzzo",
                "name": "Abruzzo",
                "pk": "IT__abruzzo"
            },
            {
                "_id": "agrigento",
                "code": "agrigento",
                "name": "Agrigento",
                "pk": "IT__agrigento"
            },
            {
                "_id": "alessandria",
                "code": "alessandria",
                "name": "Alessandria",
                "pk": "IT__alessandria"
            },
            {
                "_id": "ancona",
                "code": "ancona",
                "name": "Ancona",
                "pk": "IT__ancona"
            },
            {
                "_id": "arezzo",
                "code": "arezzo",
                "name": "Arezzo",
                "pk": "IT__arezzo"
            },
            {
                "_id": "ascoli_piceno",
                "code": "ascoli_piceno",
                "name": "Ascoli Piceno",
                "pk": "IT__ascoli_piceno"
            },
            {
                "_id": "asti",
                "code": "asti",
                "name": "Asti",
                "pk": "IT__asti"
            },
            {
                "_id": "avellino",
                "code": "avellino",
                "name": "Avellino",
                "pk": "IT__avellino"
            },
            {
                "_id": "bari",
                "code": "bari",
                "name": "Bari",
                "pk": "IT__bari"
            },
            {
                "_id": "basilicata",
                "code": "basilicata",
                "name": "Basilicata",
                "pk": "IT__basilicata"
            },
            {
                "_id": "belluno",
                "code": "belluno",
                "name": "Belluno",
                "pk": "IT__belluno"
            },
            {
                "_id": "benevento",
                "code": "benevento",
                "name": "Benevento",
                "pk": "IT__benevento"
            },
            {
                "_id": "bergamo",
                "code": "bergamo",
                "name": "Bergamo",
                "pk": "IT__bergamo"
            },
            {
                "_id": "biella",
                "code": "biella",
                "name": "Biella",
                "pk": "IT__biella"
            },
            {
                "_id": "bologna",
                "code": "bologna",
                "name": "Bologna",
                "pk": "IT__bologna"
            },
            {
                "_id": "bolzano",
                "code": "bolzano",
                "name": "Bolzano",
                "pk": "IT__bolzano"
            },
            {
                "_id": "brescia",
                "code": "brescia",
                "name": "Brescia",
                "pk": "IT__brescia"
            },
            {
                "_id": "brindisi",
                "code": "brindisi",
                "name": "Brindisi",
                "pk": "IT__brindisi"
            },
            {
                "_id": "calabria",
                "code": "calabria",
                "name": "Calabria",
                "pk": "IT__calabria"
            },
            {
                "_id": "campania",
                "code": "campania",
                "name": "Campania",
                "pk": "IT__campania"
            },
            {
                "_id": "cartoceto",
                "code": "cartoceto",
                "name": "Cartoceto",
                "pk": "IT__cartoceto"
            },
            {
                "_id": "caserta",
                "code": "caserta",
                "name": "Caserta",
                "pk": "IT__caserta"
            },
            {
                "_id": "catania",
                "code": "catania",
                "name": "Catania",
                "pk": "IT__catania"
            },
            {
                "_id": "chieti",
                "code": "chieti",
                "name": "Chieti",
                "pk": "IT__chieti"
            },
            {
                "_id": "como",
                "code": "como",
                "name": "Como",
                "pk": "IT__como"
            },
            {
                "_id": "cosenza",
                "code": "cosenza",
                "name": "Cosenza",
                "pk": "IT__cosenza"
            },
            {
                "_id": "cremona",
                "code": "cremona",
                "name": "Cremona",
                "pk": "IT__cremona"
            },
            {
                "_id": "cuneo",
                "code": "cuneo",
                "name": "Cuneo",
                "pk": "IT__cuneo"
            },
            {
                "_id": "emilia-romagna",
                "code": "emilia-romagna",
                "name": "Emilia-Romagna",
                "pk": "IT__emilia-romagna"
            },
            {
                "_id": "ferrara",
                "code": "ferrara",
                "name": "Ferrara",
                "pk": "IT__ferrara"
            },
            {
                "_id": "firenze",
                "code": "firenze",
                "name": "Firenze",
                "pk": "IT__firenze"
            },
            {
                "_id": "florence",
                "code": "florence",
                "name": "Florence",
                "pk": "IT__florence"
            },
            {
                "_id": "forli-cesena_",
                "code": "forli-cesena_",
                "name": "Forli-Cesena ",
                "pk": "IT__forli-cesena_"
            },
            {
                "_id": "friuli-venezia_giulia",
                "code": "friuli-venezia_giulia",
                "name": "Friuli-Venezia Giulia",
                "pk": "IT__friuli-venezia_giulia"
            },
            {
                "_id": "frosinone",
                "code": "frosinone",
                "name": "Frosinone",
                "pk": "IT__frosinone"
            },
            {
                "_id": "genoa",
                "code": "genoa",
                "name": "Genoa",
                "pk": "IT__genoa"
            },
            {
                "_id": "gorizia",
                "code": "gorizia",
                "name": "Gorizia",
                "pk": "IT__gorizia"
            },
            {
                "_id": "laquila",
                "code": "laquila",
                "name": "LAquila",
                "pk": "IT__laquila"
            },
            {
                "_id": "lazio",
                "code": "lazio",
                "name": "Lazio",
                "pk": "IT__lazio"
            },
            {
                "_id": "lecce",
                "code": "lecce",
                "name": "Lecce",
                "pk": "IT__lecce"
            },
            {
                "_id": "lecco",
                "code": "lecco",
                "name": "Lecco",
                "pk": "IT__lecco"
            },
            {
                "_id": "lecco_province",
                "code": "lecco_province",
                "name": "Lecco Province",
                "pk": "IT__lecco_province"
            },
            {
                "_id": "liguria",
                "code": "liguria",
                "name": "Liguria",
                "pk": "IT__liguria"
            },
            {
                "_id": "lodi",
                "code": "lodi",
                "name": "Lodi",
                "pk": "IT__lodi"
            },
            {
                "_id": "lombardia",
                "code": "lombardia",
                "name": "Lombardia",
                "pk": "IT__lombardia"
            },
            {
                "_id": "lombardy",
                "code": "lombardy",
                "name": "Lombardy",
                "pk": "IT__lombardy"
            },
            {
                "_id": "macerata",
                "code": "macerata",
                "name": "Macerata",
                "pk": "IT__macerata"
            },
            {
                "_id": "mantova",
                "code": "mantova",
                "name": "Mantova",
                "pk": "IT__mantova"
            },
            {
                "_id": "marche",
                "code": "marche",
                "name": "Marche",
                "pk": "IT__marche"
            },
            {
                "_id": "messina",
                "code": "messina",
                "name": "Messina",
                "pk": "IT__messina"
            },
            {
                "_id": "milan",
                "code": "milan",
                "name": "Milan",
                "pk": "IT__milan"
            },
            {
                "_id": "modena",
                "code": "modena",
                "name": "Modena",
                "pk": "IT__modena"
            },
            {
                "_id": "molise",
                "code": "molise",
                "name": "Molise",
                "pk": "IT__molise"
            },
            {
                "_id": "molteno",
                "code": "molteno",
                "name": "Molteno",
                "pk": "IT__molteno"
            },
            {
                "_id": "montenegro",
                "code": "montenegro",
                "name": "Montenegro",
                "pk": "IT__montenegro"
            },
            {
                "_id": "monza_and_brianza",
                "code": "monza_and_brianza",
                "name": "Monza and Brianza",
                "pk": "IT__monza_and_brianza"
            },
            {
                "_id": "naples",
                "code": "naples",
                "name": "Naples",
                "pk": "IT__naples"
            },
            {
                "_id": "novara",
                "code": "novara",
                "name": "Novara",
                "pk": "IT__novara"
            },
            {
                "_id": "padova",
                "code": "padova",
                "name": "Padova",
                "pk": "IT__padova"
            },
            {
                "_id": "parma",
                "code": "parma",
                "name": "Parma",
                "pk": "IT__parma"
            },
            {
                "_id": "pavia",
                "code": "pavia",
                "name": "Pavia",
                "pk": "IT__pavia"
            },
            {
                "_id": "perugia",
                "code": "perugia",
                "name": "Perugia",
                "pk": "IT__perugia"
            },
            {
                "_id": "pesaro-urbino",
                "code": "pesaro-urbino",
                "name": "Pesaro-Urbino",
                "pk": "IT__pesaro-urbino"
            },
            {
                "_id": "piacenza",
                "code": "piacenza",
                "name": "Piacenza",
                "pk": "IT__piacenza"
            },
            {
                "_id": "piedmont",
                "code": "piedmont",
                "name": "Piedmont",
                "pk": "IT__piedmont"
            },
            {
                "_id": "piemonte",
                "code": "piemonte",
                "name": "Piemonte",
                "pk": "IT__piemonte"
            },
            {
                "_id": "pisa",
                "code": "pisa",
                "name": "Pisa",
                "pk": "IT__pisa"
            },
            {
                "_id": "pordenone",
                "code": "pordenone",
                "name": "Pordenone",
                "pk": "IT__pordenone"
            },
            {
                "_id": "potenza",
                "code": "potenza",
                "name": "Potenza",
                "pk": "IT__potenza"
            },
            {
                "_id": "puglia",
                "code": "puglia",
                "name": "Puglia",
                "pk": "IT__puglia"
            },
            {
                "_id": "reggio_emilia",
                "code": "reggio_emilia",
                "name": "Reggio Emilia",
                "pk": "IT__reggio_emilia"
            },
            {
                "_id": "rimini",
                "code": "rimini",
                "name": "Rimini",
                "pk": "IT__rimini"
            },
            {
                "_id": "roma",
                "code": "roma",
                "name": "Roma",
                "pk": "IT__roma"
            },
            {
                "_id": "salerno",
                "code": "salerno",
                "name": "Salerno",
                "pk": "IT__salerno"
            },
            {
                "_id": "sardegna",
                "code": "sardegna",
                "name": "Sardegna",
                "pk": "IT__sardegna"
            },
            {
                "_id": "sassari",
                "code": "sassari",
                "name": "Sassari",
                "pk": "IT__sassari"
            },
            {
                "_id": "savona",
                "code": "savona",
                "name": "Savona",
                "pk": "IT__savona"
            },
            {
                "_id": "sicilia",
                "code": "sicilia",
                "name": "Sicilia",
                "pk": "IT__sicilia"
            },
            {
                "_id": "siena",
                "code": "siena",
                "name": "Siena",
                "pk": "IT__siena"
            },
            {
                "_id": "sondrio",
                "code": "sondrio",
                "name": "Sondrio",
                "pk": "IT__sondrio"
            },
            {
                "_id": "south_tyrol",
                "code": "south_tyrol",
                "name": "South Tyrol",
                "pk": "IT__south_tyrol"
            },
            {
                "_id": "taranto",
                "code": "taranto",
                "name": "Taranto",
                "pk": "IT__taranto"
            },
            {
                "_id": "teramo",
                "code": "teramo",
                "name": "Teramo",
                "pk": "IT__teramo"
            },
            {
                "_id": "torino",
                "code": "torino",
                "name": "Torino",
                "pk": "IT__torino"
            },
            {
                "_id": "toscana",
                "code": "toscana",
                "name": "Toscana",
                "pk": "IT__toscana"
            },
            {
                "_id": "trapani",
                "code": "trapani",
                "name": "Trapani",
                "pk": "IT__trapani"
            },
            {
                "_id": "trentino-alto_adige",
                "code": "trentino-alto_adige",
                "name": "Trentino-Alto Adige",
                "pk": "IT__trentino-alto_adige"
            },
            {
                "_id": "trento",
                "code": "trento",
                "name": "Trento",
                "pk": "IT__trento"
            },
            {
                "_id": "treviso",
                "code": "treviso",
                "name": "Treviso",
                "pk": "IT__treviso"
            },
            {
                "_id": "udine",
                "code": "udine",
                "name": "Udine",
                "pk": "IT__udine"
            },
            {
                "_id": "umbria",
                "code": "umbria",
                "name": "Umbria",
                "pk": "IT__umbria"
            },
            {
                "_id": "valle_daosta",
                "code": "valle_daosta",
                "name": "Valle dAosta",
                "pk": "IT__valle_daosta"
            },
            {
                "_id": "varese",
                "code": "varese",
                "name": "Varese",
                "pk": "IT__varese"
            },
            {
                "_id": "veneto",
                "code": "veneto",
                "name": "Veneto",
                "pk": "IT__veneto"
            },
            {
                "_id": "venezia",
                "code": "venezia",
                "name": "Venezia",
                "pk": "IT__venezia"
            },
            {
                "_id": "verbano-cusio-ossola",
                "code": "verbano-cusio-ossola",
                "name": "Verbano-Cusio-Ossola",
                "pk": "IT__verbano-cusio-ossola"
            },
            {
                "_id": "vercelli",
                "code": "vercelli",
                "name": "Vercelli",
                "pk": "IT__vercelli"
            },
            {
                "_id": "verona",
                "code": "verona",
                "name": "Verona",
                "pk": "IT__verona"
            },
            {
                "_id": "vicenza",
                "code": "vicenza",
                "name": "Vicenza",
                "pk": "IT__vicenza"
            },
            {
                "_id": "viterbo",
                "code": "viterbo",
                "name": "Viterbo",
                "pk": "IT__viterbo"
            }
        ],
        "pk": "IT"
    },
    {
        "_id": "JM",
        "code": "JM",
        "name": "Jamaïque",
        "states": [
            {
                "_id": "buxoro_viloyati",
                "code": "buxoro_viloyati",
                "name": "Buxoro Viloyati",
                "pk": "JM__buxoro_viloyati"
            },
            {
                "_id": "clarendon",
                "code": "clarendon",
                "name": "Clarendon",
                "pk": "JM__clarendon"
            },
            {
                "_id": "hanover",
                "code": "hanover",
                "name": "Hanover",
                "pk": "JM__hanover"
            },
            {
                "_id": "kingston",
                "code": "kingston",
                "name": "Kingston",
                "pk": "JM__kingston"
            },
            {
                "_id": "manchester",
                "code": "manchester",
                "name": "Manchester",
                "pk": "JM__manchester"
            },
            {
                "_id": "portland",
                "code": "portland",
                "name": "Portland",
                "pk": "JM__portland"
            },
            {
                "_id": "saint_andrews",
                "code": "saint_andrews",
                "name": "Saint Andrews",
                "pk": "JM__saint_andrews"
            },
            {
                "_id": "saint_ann",
                "code": "saint_ann",
                "name": "Saint Ann",
                "pk": "JM__saint_ann"
            },
            {
                "_id": "saint_catherine",
                "code": "saint_catherine",
                "name": "Saint Catherine",
                "pk": "JM__saint_catherine"
            },
            {
                "_id": "saint_elizabeth",
                "code": "saint_elizabeth",
                "name": "Saint Elizabeth",
                "pk": "JM__saint_elizabeth"
            },
            {
                "_id": "saint_james",
                "code": "saint_james",
                "name": "Saint James",
                "pk": "JM__saint_james"
            },
            {
                "_id": "saint_mary",
                "code": "saint_mary",
                "name": "Saint Mary",
                "pk": "JM__saint_mary"
            },
            {
                "_id": "saint_thomas",
                "code": "saint_thomas",
                "name": "Saint Thomas",
                "pk": "JM__saint_thomas"
            },
            {
                "_id": "trelawney",
                "code": "trelawney",
                "name": "Trelawney",
                "pk": "JM__trelawney"
            },
            {
                "_id": "westmoreland",
                "code": "westmoreland",
                "name": "Westmoreland",
                "pk": "JM__westmoreland"
            }
        ],
        "pk": "JM"
    },
    {
        "_id": "JP",
        "code": "JP",
        "name": "Japon",
        "states": [
            {
                "_id": "aichi",
                "code": "aichi",
                "name": "Aichi",
                "pk": "JP__aichi"
            },
            {
                "_id": "akita",
                "code": "akita",
                "name": "Akita",
                "pk": "JP__akita"
            },
            {
                "_id": "aomori",
                "code": "aomori",
                "name": "Aomori",
                "pk": "JP__aomori"
            },
            {
                "_id": "chiba",
                "code": "chiba",
                "name": "Chiba",
                "pk": "JP__chiba"
            },
            {
                "_id": "ehime",
                "code": "ehime",
                "name": "Ehime",
                "pk": "JP__ehime"
            },
            {
                "_id": "fukui",
                "code": "fukui",
                "name": "Fukui",
                "pk": "JP__fukui"
            },
            {
                "_id": "fukuoka",
                "code": "fukuoka",
                "name": "Fukuoka",
                "pk": "JP__fukuoka"
            },
            {
                "_id": "fukushima",
                "code": "fukushima",
                "name": "Fukushima",
                "pk": "JP__fukushima"
            },
            {
                "_id": "gifu",
                "code": "gifu",
                "name": "Gifu",
                "pk": "JP__gifu"
            },
            {
                "_id": "gumma",
                "code": "gumma",
                "name": "Gumma",
                "pk": "JP__gumma"
            },
            {
                "_id": "hiroshima",
                "code": "hiroshima",
                "name": "Hiroshima",
                "pk": "JP__hiroshima"
            },
            {
                "_id": "hokkaido",
                "code": "hokkaido",
                "name": "Hokkaido",
                "pk": "JP__hokkaido"
            },
            {
                "_id": "hyogo",
                "code": "hyogo",
                "name": "Hyogo",
                "pk": "JP__hyogo"
            },
            {
                "_id": "ibaraki",
                "code": "ibaraki",
                "name": "Ibaraki",
                "pk": "JP__ibaraki"
            },
            {
                "_id": "ishikawa",
                "code": "ishikawa",
                "name": "Ishikawa",
                "pk": "JP__ishikawa"
            },
            {
                "_id": "iwate",
                "code": "iwate",
                "name": "Iwate",
                "pk": "JP__iwate"
            },
            {
                "_id": "kagawa",
                "code": "kagawa",
                "name": "Kagawa",
                "pk": "JP__kagawa"
            },
            {
                "_id": "kagoshima",
                "code": "kagoshima",
                "name": "Kagoshima",
                "pk": "JP__kagoshima"
            },
            {
                "_id": "kanagawa",
                "code": "kanagawa",
                "name": "Kanagawa",
                "pk": "JP__kanagawa"
            },
            {
                "_id": "kanto",
                "code": "kanto",
                "name": "Kanto",
                "pk": "JP__kanto"
            },
            {
                "_id": "kochi",
                "code": "kochi",
                "name": "Kochi",
                "pk": "JP__kochi"
            },
            {
                "_id": "kumamoto",
                "code": "kumamoto",
                "name": "Kumamoto",
                "pk": "JP__kumamoto"
            },
            {
                "_id": "kyoto",
                "code": "kyoto",
                "name": "Kyoto",
                "pk": "JP__kyoto"
            },
            {
                "_id": "mie",
                "code": "mie",
                "name": "Mie",
                "pk": "JP__mie"
            },
            {
                "_id": "miyagi",
                "code": "miyagi",
                "name": "Miyagi",
                "pk": "JP__miyagi"
            },
            {
                "_id": "miyazaki",
                "code": "miyazaki",
                "name": "Miyazaki",
                "pk": "JP__miyazaki"
            },
            {
                "_id": "nagano",
                "code": "nagano",
                "name": "Nagano",
                "pk": "JP__nagano"
            },
            {
                "_id": "nagasaki",
                "code": "nagasaki",
                "name": "Nagasaki",
                "pk": "JP__nagasaki"
            },
            {
                "_id": "nara",
                "code": "nara",
                "name": "Nara",
                "pk": "JP__nara"
            },
            {
                "_id": "niigata",
                "code": "niigata",
                "name": "Niigata",
                "pk": "JP__niigata"
            },
            {
                "_id": "oita",
                "code": "oita",
                "name": "Oita",
                "pk": "JP__oita"
            },
            {
                "_id": "okayama",
                "code": "okayama",
                "name": "Okayama",
                "pk": "JP__okayama"
            },
            {
                "_id": "okinawa",
                "code": "okinawa",
                "name": "Okinawa",
                "pk": "JP__okinawa"
            },
            {
                "_id": "osaka",
                "code": "osaka",
                "name": "Osaka",
                "pk": "JP__osaka"
            },
            {
                "_id": "saga",
                "code": "saga",
                "name": "Saga",
                "pk": "JP__saga"
            },
            {
                "_id": "saitama",
                "code": "saitama",
                "name": "Saitama",
                "pk": "JP__saitama"
            },
            {
                "_id": "shiga",
                "code": "shiga",
                "name": "Shiga",
                "pk": "JP__shiga"
            },
            {
                "_id": "shimane",
                "code": "shimane",
                "name": "Shimane",
                "pk": "JP__shimane"
            },
            {
                "_id": "shizuoka",
                "code": "shizuoka",
                "name": "Shizuoka",
                "pk": "JP__shizuoka"
            },
            {
                "_id": "tochigi",
                "code": "tochigi",
                "name": "Tochigi",
                "pk": "JP__tochigi"
            },
            {
                "_id": "tokushima",
                "code": "tokushima",
                "name": "Tokushima",
                "pk": "JP__tokushima"
            },
            {
                "_id": "tokyo",
                "code": "tokyo",
                "name": "Tokyo",
                "pk": "JP__tokyo"
            },
            {
                "_id": "tottori",
                "code": "tottori",
                "name": "Tottori",
                "pk": "JP__tottori"
            },
            {
                "_id": "toyama",
                "code": "toyama",
                "name": "Toyama",
                "pk": "JP__toyama"
            },
            {
                "_id": "wakayama",
                "code": "wakayama",
                "name": "Wakayama",
                "pk": "JP__wakayama"
            },
            {
                "_id": "yamagata",
                "code": "yamagata",
                "name": "Yamagata",
                "pk": "JP__yamagata"
            },
            {
                "_id": "yamaguchi",
                "code": "yamaguchi",
                "name": "Yamaguchi",
                "pk": "JP__yamaguchi"
            },
            {
                "_id": "yamanashi",
                "code": "yamanashi",
                "name": "Yamanashi",
                "pk": "JP__yamanashi"
            }
        ],
        "pk": "JP"
    },
    {
        "_id": "XJ",
        "code": "XJ",
        "name": "Jersey",
        "states": [
            {
                "_id": "grouville",
                "code": "grouville",
                "name": "Grouville",
                "pk": "XJ__grouville"
            },
            {
                "_id": "saint_brelade",
                "code": "saint_brelade",
                "name": "Saint Brelade",
                "pk": "XJ__saint_brelade"
            },
            {
                "_id": "saint_clement",
                "code": "saint_clement",
                "name": "Saint Clement",
                "pk": "XJ__saint_clement"
            },
            {
                "_id": "saint_helier",
                "code": "saint_helier",
                "name": "Saint Helier",
                "pk": "XJ__saint_helier"
            },
            {
                "_id": "saint_john",
                "code": "saint_john",
                "name": "Saint John",
                "pk": "XJ__saint_john"
            },
            {
                "_id": "saint_lawrence",
                "code": "saint_lawrence",
                "name": "Saint Lawrence",
                "pk": "XJ__saint_lawrence"
            },
            {
                "_id": "saint_martin",
                "code": "saint_martin",
                "name": "Saint Martin",
                "pk": "XJ__saint_martin"
            },
            {
                "_id": "saint_mary",
                "code": "saint_mary",
                "name": "Saint Mary",
                "pk": "XJ__saint_mary"
            },
            {
                "_id": "saint_peter",
                "code": "saint_peter",
                "name": "Saint Peter",
                "pk": "XJ__saint_peter"
            },
            {
                "_id": "saint_saviour",
                "code": "saint_saviour",
                "name": "Saint Saviour",
                "pk": "XJ__saint_saviour"
            },
            {
                "_id": "trinity",
                "code": "trinity",
                "name": "Trinity",
                "pk": "XJ__trinity"
            }
        ],
        "pk": "XJ"
    },
    {
        "_id": "JO",
        "code": "JO",
        "name": "Jordanie",
        "states": [
            {
                "_id": "ajlun",
                "code": "ajlun",
                "name": "Ajlun",
                "pk": "JO__ajlun"
            },
            {
                "_id": "amman",
                "code": "amman",
                "name": "Amman",
                "pk": "JO__amman"
            },
            {
                "_id": "irbid",
                "code": "irbid",
                "name": "Irbid",
                "pk": "JO__irbid"
            },
            {
                "_id": "jarash",
                "code": "jarash",
                "name": "Jarash",
                "pk": "JO__jarash"
            },
            {
                "_id": "maan",
                "code": "maan",
                "name": "Maan",
                "pk": "JO__maan"
            },
            {
                "_id": "madaba",
                "code": "madaba",
                "name": "Madaba",
                "pk": "JO__madaba"
            },
            {
                "_id": "al-aqabah",
                "code": "al-aqabah",
                "name": "al-Aqabah",
                "pk": "JO__al-aqabah"
            },
            {
                "_id": "al-balqa",
                "code": "al-balqa",
                "name": "al-Balqa",
                "pk": "JO__al-balqa"
            },
            {
                "_id": "al-karak",
                "code": "al-karak",
                "name": "al-Karak",
                "pk": "JO__al-karak"
            },
            {
                "_id": "al-mafraq",
                "code": "al-mafraq",
                "name": "al-Mafraq",
                "pk": "JO__al-mafraq"
            },
            {
                "_id": "at-tafilah",
                "code": "at-tafilah",
                "name": "at-Tafilah",
                "pk": "JO__at-tafilah"
            },
            {
                "_id": "az-zarqa",
                "code": "az-zarqa",
                "name": "az-Zarqa",
                "pk": "JO__az-zarqa"
            }
        ],
        "pk": "JO"
    },
    {
        "_id": "KZ",
        "code": "KZ",
        "name": "Kazakhstan",
        "states": [
            {
                "_id": "akmecet",
                "code": "akmecet",
                "name": "Akmecet",
                "pk": "KZ__akmecet"
            },
            {
                "_id": "akmola",
                "code": "akmola",
                "name": "Akmola",
                "pk": "KZ__akmola"
            },
            {
                "_id": "aktobe",
                "code": "aktobe",
                "name": "Aktobe",
                "pk": "KZ__aktobe"
            },
            {
                "_id": "almati",
                "code": "almati",
                "name": "Almati",
                "pk": "KZ__almati"
            },
            {
                "_id": "atirau",
                "code": "atirau",
                "name": "Atirau",
                "pk": "KZ__atirau"
            },
            {
                "_id": "batis_kazakstan",
                "code": "batis_kazakstan",
                "name": "Batis Kazakstan",
                "pk": "KZ__batis_kazakstan"
            },
            {
                "_id": "burlinsky_region",
                "code": "burlinsky_region",
                "name": "Burlinsky Region",
                "pk": "KZ__burlinsky_region"
            },
            {
                "_id": "karagandi",
                "code": "karagandi",
                "name": "Karagandi",
                "pk": "KZ__karagandi"
            },
            {
                "_id": "kostanay",
                "code": "kostanay",
                "name": "Kostanay",
                "pk": "KZ__kostanay"
            },
            {
                "_id": "mankistau",
                "code": "mankistau",
                "name": "Mankistau",
                "pk": "KZ__mankistau"
            },
            {
                "_id": "ontustik_kazakstan",
                "code": "ontustik_kazakstan",
                "name": "Ontustik Kazakstan",
                "pk": "KZ__ontustik_kazakstan"
            },
            {
                "_id": "pavlodar",
                "code": "pavlodar",
                "name": "Pavlodar",
                "pk": "KZ__pavlodar"
            },
            {
                "_id": "sigis_kazakstan",
                "code": "sigis_kazakstan",
                "name": "Sigis Kazakstan",
                "pk": "KZ__sigis_kazakstan"
            },
            {
                "_id": "soltustik_kazakstan",
                "code": "soltustik_kazakstan",
                "name": "Soltustik Kazakstan",
                "pk": "KZ__soltustik_kazakstan"
            },
            {
                "_id": "taraz",
                "code": "taraz",
                "name": "Taraz",
                "pk": "KZ__taraz"
            }
        ],
        "pk": "KZ"
    },
    {
        "_id": "KE",
        "code": "KE",
        "name": "Kenya",
        "states": [
            {
                "_id": "central",
                "code": "central",
                "name": "Central",
                "pk": "KE__central"
            },
            {
                "_id": "coast",
                "code": "coast",
                "name": "Coast",
                "pk": "KE__coast"
            },
            {
                "_id": "eastern",
                "code": "eastern",
                "name": "Eastern",
                "pk": "KE__eastern"
            },
            {
                "_id": "nairobi",
                "code": "nairobi",
                "name": "Nairobi",
                "pk": "KE__nairobi"
            },
            {
                "_id": "north_eastern",
                "code": "north_eastern",
                "name": "North Eastern",
                "pk": "KE__north_eastern"
            },
            {
                "_id": "nyanza",
                "code": "nyanza",
                "name": "Nyanza",
                "pk": "KE__nyanza"
            },
            {
                "_id": "rift_valley",
                "code": "rift_valley",
                "name": "Rift Valley",
                "pk": "KE__rift_valley"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "KE__western"
            }
        ],
        "pk": "KE"
    },
    {
        "_id": "KI",
        "code": "KI",
        "name": "Kiribati",
        "states": [
            {
                "_id": "abaiang",
                "code": "abaiang",
                "name": "Abaiang",
                "pk": "KI__abaiang"
            },
            {
                "_id": "abemana",
                "code": "abemana",
                "name": "Abemana",
                "pk": "KI__abemana"
            },
            {
                "_id": "aranuka",
                "code": "aranuka",
                "name": "Aranuka",
                "pk": "KI__aranuka"
            },
            {
                "_id": "arorae",
                "code": "arorae",
                "name": "Arorae",
                "pk": "KI__arorae"
            },
            {
                "_id": "banaba",
                "code": "banaba",
                "name": "Banaba",
                "pk": "KI__banaba"
            },
            {
                "_id": "beru",
                "code": "beru",
                "name": "Beru",
                "pk": "KI__beru"
            },
            {
                "_id": "butaritari",
                "code": "butaritari",
                "name": "Butaritari",
                "pk": "KI__butaritari"
            },
            {
                "_id": "kiritimati",
                "code": "kiritimati",
                "name": "Kiritimati",
                "pk": "KI__kiritimati"
            },
            {
                "_id": "kuria",
                "code": "kuria",
                "name": "Kuria",
                "pk": "KI__kuria"
            },
            {
                "_id": "maiana",
                "code": "maiana",
                "name": "Maiana",
                "pk": "KI__maiana"
            },
            {
                "_id": "makin",
                "code": "makin",
                "name": "Makin",
                "pk": "KI__makin"
            },
            {
                "_id": "marakei",
                "code": "marakei",
                "name": "Marakei",
                "pk": "KI__marakei"
            },
            {
                "_id": "nikunau",
                "code": "nikunau",
                "name": "Nikunau",
                "pk": "KI__nikunau"
            },
            {
                "_id": "nonouti",
                "code": "nonouti",
                "name": "Nonouti",
                "pk": "KI__nonouti"
            },
            {
                "_id": "onotoa",
                "code": "onotoa",
                "name": "Onotoa",
                "pk": "KI__onotoa"
            },
            {
                "_id": "phoenix_islands",
                "code": "phoenix_islands",
                "name": "Phoenix Islands",
                "pk": "KI__phoenix_islands"
            },
            {
                "_id": "tabiteuea_north",
                "code": "tabiteuea_north",
                "name": "Tabiteuea North",
                "pk": "KI__tabiteuea_north"
            },
            {
                "_id": "tabiteuea_south",
                "code": "tabiteuea_south",
                "name": "Tabiteuea South",
                "pk": "KI__tabiteuea_south"
            },
            {
                "_id": "tabuaeran",
                "code": "tabuaeran",
                "name": "Tabuaeran",
                "pk": "KI__tabuaeran"
            },
            {
                "_id": "tamana",
                "code": "tamana",
                "name": "Tamana",
                "pk": "KI__tamana"
            },
            {
                "_id": "tarawa_north",
                "code": "tarawa_north",
                "name": "Tarawa North",
                "pk": "KI__tarawa_north"
            },
            {
                "_id": "tarawa_south",
                "code": "tarawa_south",
                "name": "Tarawa South",
                "pk": "KI__tarawa_south"
            },
            {
                "_id": "teraina",
                "code": "teraina",
                "name": "Teraina",
                "pk": "KI__teraina"
            }
        ],
        "pk": "KI"
    },
    {
        "_id": "KP",
        "code": "KP",
        "name": "Corée du Nord",
        "states": [
            {
                "_id": "chagangdo",
                "code": "chagangdo",
                "name": "Chagangdo",
                "pk": "KP__chagangdo"
            },
            {
                "_id": "hamgyeongbukto",
                "code": "hamgyeongbukto",
                "name": "Hamgyeongbukto",
                "pk": "KP__hamgyeongbukto"
            },
            {
                "_id": "hamgyeongnamdo",
                "code": "hamgyeongnamdo",
                "name": "Hamgyeongnamdo",
                "pk": "KP__hamgyeongnamdo"
            },
            {
                "_id": "hwanghaebukto",
                "code": "hwanghaebukto",
                "name": "Hwanghaebukto",
                "pk": "KP__hwanghaebukto"
            },
            {
                "_id": "hwanghaenamdo",
                "code": "hwanghaenamdo",
                "name": "Hwanghaenamdo",
                "pk": "KP__hwanghaenamdo"
            },
            {
                "_id": "kaeseong",
                "code": "kaeseong",
                "name": "Kaeseong",
                "pk": "KP__kaeseong"
            },
            {
                "_id": "kangweon",
                "code": "kangweon",
                "name": "Kangweon",
                "pk": "KP__kangweon"
            },
            {
                "_id": "nampo",
                "code": "nampo",
                "name": "Nampo",
                "pk": "KP__nampo"
            },
            {
                "_id": "pyeonganbukto",
                "code": "pyeonganbukto",
                "name": "Pyeonganbukto",
                "pk": "KP__pyeonganbukto"
            },
            {
                "_id": "pyeongannamdo",
                "code": "pyeongannamdo",
                "name": "Pyeongannamdo",
                "pk": "KP__pyeongannamdo"
            },
            {
                "_id": "pyeongyang",
                "code": "pyeongyang",
                "name": "Pyeongyang",
                "pk": "KP__pyeongyang"
            },
            {
                "_id": "yanggang",
                "code": "yanggang",
                "name": "Yanggang",
                "pk": "KP__yanggang"
            }
        ],
        "pk": "KP"
    },
    {
        "_id": "KR",
        "code": "KR",
        "name": "Corée du Sud",
        "states": [
            {
                "_id": "busan",
                "code": "busan",
                "name": "Busan",
                "pk": "KR__busan"
            },
            {
                "_id": "cheju",
                "code": "cheju",
                "name": "Cheju",
                "pk": "KR__cheju"
            },
            {
                "_id": "chollabuk",
                "code": "chollabuk",
                "name": "Chollabuk",
                "pk": "KR__chollabuk"
            },
            {
                "_id": "chollanam",
                "code": "chollanam",
                "name": "Chollanam",
                "pk": "KR__chollanam"
            },
            {
                "_id": "chungbuk",
                "code": "chungbuk",
                "name": "Chungbuk",
                "pk": "KR__chungbuk"
            },
            {
                "_id": "chungcheongbuk",
                "code": "chungcheongbuk",
                "name": "Chungcheongbuk",
                "pk": "KR__chungcheongbuk"
            },
            {
                "_id": "chungcheongnam",
                "code": "chungcheongnam",
                "name": "Chungcheongnam",
                "pk": "KR__chungcheongnam"
            },
            {
                "_id": "chungnam",
                "code": "chungnam",
                "name": "Chungnam",
                "pk": "KR__chungnam"
            },
            {
                "_id": "daegu",
                "code": "daegu",
                "name": "Daegu",
                "pk": "KR__daegu"
            },
            {
                "_id": "gangwon-do",
                "code": "gangwon-do",
                "name": "Gangwon-do",
                "pk": "KR__gangwon-do"
            },
            {
                "_id": "goyang-si",
                "code": "goyang-si",
                "name": "Goyang-si",
                "pk": "KR__goyang-si"
            },
            {
                "_id": "gyeonggi-do",
                "code": "gyeonggi-do",
                "name": "Gyeonggi-do",
                "pk": "KR__gyeonggi-do"
            },
            {
                "_id": "gyeongsang_",
                "code": "gyeongsang_",
                "name": "Gyeongsang ",
                "pk": "KR__gyeongsang_"
            },
            {
                "_id": "gyeongsangnam-do",
                "code": "gyeongsangnam-do",
                "name": "Gyeongsangnam-do",
                "pk": "KR__gyeongsangnam-do"
            },
            {
                "_id": "incheon",
                "code": "incheon",
                "name": "Incheon",
                "pk": "KR__incheon"
            },
            {
                "_id": "jeju-si",
                "code": "jeju-si",
                "name": "Jeju-Si",
                "pk": "KR__jeju-si"
            },
            {
                "_id": "jeonbuk",
                "code": "jeonbuk",
                "name": "Jeonbuk",
                "pk": "KR__jeonbuk"
            },
            {
                "_id": "kangweon",
                "code": "kangweon",
                "name": "Kangweon",
                "pk": "KR__kangweon"
            },
            {
                "_id": "kwangju",
                "code": "kwangju",
                "name": "Kwangju",
                "pk": "KR__kwangju"
            },
            {
                "_id": "kyeonggi",
                "code": "kyeonggi",
                "name": "Kyeonggi",
                "pk": "KR__kyeonggi"
            },
            {
                "_id": "kyeongsangbuk",
                "code": "kyeongsangbuk",
                "name": "Kyeongsangbuk",
                "pk": "KR__kyeongsangbuk"
            },
            {
                "_id": "kyeongsangnam",
                "code": "kyeongsangnam",
                "name": "Kyeongsangnam",
                "pk": "KR__kyeongsangnam"
            },
            {
                "_id": "kyonggi-do",
                "code": "kyonggi-do",
                "name": "Kyonggi-do",
                "pk": "KR__kyonggi-do"
            },
            {
                "_id": "kyungbuk-do",
                "code": "kyungbuk-do",
                "name": "Kyungbuk-Do",
                "pk": "KR__kyungbuk-do"
            },
            {
                "_id": "kyunggi-do",
                "code": "kyunggi-do",
                "name": "Kyunggi-Do",
                "pk": "KR__kyunggi-do"
            },
            {
                "_id": "kyunggi-do",
                "code": "kyunggi-do",
                "name": "Kyunggi-do",
                "pk": "KR__kyunggi-do"
            },
            {
                "_id": "pusan",
                "code": "pusan",
                "name": "Pusan",
                "pk": "KR__pusan"
            },
            {
                "_id": "seoul",
                "code": "seoul",
                "name": "Seoul",
                "pk": "KR__seoul"
            },
            {
                "_id": "sudogwon",
                "code": "sudogwon",
                "name": "Sudogwon",
                "pk": "KR__sudogwon"
            },
            {
                "_id": "taegu",
                "code": "taegu",
                "name": "Taegu",
                "pk": "KR__taegu"
            },
            {
                "_id": "taejeon",
                "code": "taejeon",
                "name": "Taejeon",
                "pk": "KR__taejeon"
            },
            {
                "_id": "taejon-gwangyoksi",
                "code": "taejon-gwangyoksi",
                "name": "Taejon-gwangyoksi",
                "pk": "KR__taejon-gwangyoksi"
            },
            {
                "_id": "ulsan",
                "code": "ulsan",
                "name": "Ulsan",
                "pk": "KR__ulsan"
            },
            {
                "_id": "wonju",
                "code": "wonju",
                "name": "Wonju",
                "pk": "KR__wonju"
            },
            {
                "_id": "gwangyoksi",
                "code": "gwangyoksi",
                "name": "gwangyoksi",
                "pk": "KR__gwangyoksi"
            }
        ],
        "pk": "KR"
    },
    {
        "_id": "KW",
        "code": "KW",
        "name": "Koweït",
        "states": [
            {
                "_id": "al_asimah",
                "code": "al_asimah",
                "name": "Al Asimah",
                "pk": "KW__al_asimah"
            },
            {
                "_id": "hawalli",
                "code": "hawalli",
                "name": "Hawalli",
                "pk": "KW__hawalli"
            },
            {
                "_id": "mishref",
                "code": "mishref",
                "name": "Mishref",
                "pk": "KW__mishref"
            },
            {
                "_id": "qadesiya",
                "code": "qadesiya",
                "name": "Qadesiya",
                "pk": "KW__qadesiya"
            },
            {
                "_id": "safat",
                "code": "safat",
                "name": "Safat",
                "pk": "KW__safat"
            },
            {
                "_id": "salmiya",
                "code": "salmiya",
                "name": "Salmiya",
                "pk": "KW__salmiya"
            },
            {
                "_id": "al-ahmadi",
                "code": "al-ahmadi",
                "name": "al-Ahmadi",
                "pk": "KW__al-ahmadi"
            },
            {
                "_id": "al-farwaniyah",
                "code": "al-farwaniyah",
                "name": "al-Farwaniyah",
                "pk": "KW__al-farwaniyah"
            },
            {
                "_id": "al-jahra",
                "code": "al-jahra",
                "name": "al-Jahra",
                "pk": "KW__al-jahra"
            },
            {
                "_id": "al-kuwayt",
                "code": "al-kuwayt",
                "name": "al-Kuwayt",
                "pk": "KW__al-kuwayt"
            }
        ],
        "pk": "KW"
    },
    {
        "_id": "KG",
        "code": "KG",
        "name": "Kirghizistan",
        "states": [
            {
                "_id": "batken",
                "code": "batken",
                "name": "Batken",
                "pk": "KG__batken"
            },
            {
                "_id": "bishkek",
                "code": "bishkek",
                "name": "Bishkek",
                "pk": "KG__bishkek"
            },
            {
                "_id": "chui",
                "code": "chui",
                "name": "Chui",
                "pk": "KG__chui"
            },
            {
                "_id": "issyk-kul",
                "code": "issyk-kul",
                "name": "Issyk-Kul",
                "pk": "KG__issyk-kul"
            },
            {
                "_id": "jalal-abad",
                "code": "jalal-abad",
                "name": "Jalal-Abad",
                "pk": "KG__jalal-abad"
            },
            {
                "_id": "naryn",
                "code": "naryn",
                "name": "Naryn",
                "pk": "KG__naryn"
            },
            {
                "_id": "osh",
                "code": "osh",
                "name": "Osh",
                "pk": "KG__osh"
            },
            {
                "_id": "talas",
                "code": "talas",
                "name": "Talas",
                "pk": "KG__talas"
            }
        ],
        "pk": "KG"
    },
    {
        "_id": "LA",
        "code": "LA",
        "name": "Laos",
        "states": [
            {
                "_id": "attopu",
                "code": "attopu",
                "name": "Attopu",
                "pk": "LA__attopu"
            },
            {
                "_id": "bokeo",
                "code": "bokeo",
                "name": "Bokeo",
                "pk": "LA__bokeo"
            },
            {
                "_id": "bolikhamsay",
                "code": "bolikhamsay",
                "name": "Bolikhamsay",
                "pk": "LA__bolikhamsay"
            },
            {
                "_id": "champasak",
                "code": "champasak",
                "name": "Champasak",
                "pk": "LA__champasak"
            },
            {
                "_id": "houaphanh",
                "code": "houaphanh",
                "name": "Houaphanh",
                "pk": "LA__houaphanh"
            },
            {
                "_id": "khammouane",
                "code": "khammouane",
                "name": "Khammouane",
                "pk": "LA__khammouane"
            },
            {
                "_id": "luang_nam_tha",
                "code": "luang_nam_tha",
                "name": "Luang Nam Tha",
                "pk": "LA__luang_nam_tha"
            },
            {
                "_id": "luang_prabang",
                "code": "luang_prabang",
                "name": "Luang Prabang",
                "pk": "LA__luang_prabang"
            },
            {
                "_id": "oudomxay",
                "code": "oudomxay",
                "name": "Oudomxay",
                "pk": "LA__oudomxay"
            },
            {
                "_id": "phongsaly",
                "code": "phongsaly",
                "name": "Phongsaly",
                "pk": "LA__phongsaly"
            },
            {
                "_id": "saravan",
                "code": "saravan",
                "name": "Saravan",
                "pk": "LA__saravan"
            },
            {
                "_id": "savannakhet",
                "code": "savannakhet",
                "name": "Savannakhet",
                "pk": "LA__savannakhet"
            },
            {
                "_id": "sekong",
                "code": "sekong",
                "name": "Sekong",
                "pk": "LA__sekong"
            },
            {
                "_id": "viangchan_prefecture",
                "code": "viangchan_prefecture",
                "name": "Viangchan Prefecture",
                "pk": "LA__viangchan_prefecture"
            },
            {
                "_id": "viangchan_province",
                "code": "viangchan_province",
                "name": "Viangchan Province",
                "pk": "LA__viangchan_province"
            },
            {
                "_id": "xaignabury",
                "code": "xaignabury",
                "name": "Xaignabury",
                "pk": "LA__xaignabury"
            },
            {
                "_id": "xiang_khuang",
                "code": "xiang_khuang",
                "name": "Xiang Khuang",
                "pk": "LA__xiang_khuang"
            }
        ],
        "pk": "LA"
    },
    {
        "_id": "LV",
        "code": "LV",
        "name": "Lettonie",
        "states": [
            {
                "_id": "aizkraukles",
                "code": "aizkraukles",
                "name": "Aizkraukles",
                "pk": "LV__aizkraukles"
            },
            {
                "_id": "aluksnes",
                "code": "aluksnes",
                "name": "Aluksnes",
                "pk": "LV__aluksnes"
            },
            {
                "_id": "balvu",
                "code": "balvu",
                "name": "Balvu",
                "pk": "LV__balvu"
            },
            {
                "_id": "bauskas",
                "code": "bauskas",
                "name": "Bauskas",
                "pk": "LV__bauskas"
            },
            {
                "_id": "cesu",
                "code": "cesu",
                "name": "Cesu",
                "pk": "LV__cesu"
            },
            {
                "_id": "daugavpils",
                "code": "daugavpils",
                "name": "Daugavpils",
                "pk": "LV__daugavpils"
            },
            {
                "_id": "daugavpils_city",
                "code": "daugavpils_city",
                "name": "Daugavpils City",
                "pk": "LV__daugavpils_city"
            },
            {
                "_id": "dobeles",
                "code": "dobeles",
                "name": "Dobeles",
                "pk": "LV__dobeles"
            },
            {
                "_id": "gulbenes",
                "code": "gulbenes",
                "name": "Gulbenes",
                "pk": "LV__gulbenes"
            },
            {
                "_id": "jekabspils",
                "code": "jekabspils",
                "name": "Jekabspils",
                "pk": "LV__jekabspils"
            },
            {
                "_id": "jelgava",
                "code": "jelgava",
                "name": "Jelgava",
                "pk": "LV__jelgava"
            },
            {
                "_id": "jelgavas",
                "code": "jelgavas",
                "name": "Jelgavas",
                "pk": "LV__jelgavas"
            },
            {
                "_id": "jurmala_city",
                "code": "jurmala_city",
                "name": "Jurmala City",
                "pk": "LV__jurmala_city"
            },
            {
                "_id": "kraslavas",
                "code": "kraslavas",
                "name": "Kraslavas",
                "pk": "LV__kraslavas"
            },
            {
                "_id": "kuldigas",
                "code": "kuldigas",
                "name": "Kuldigas",
                "pk": "LV__kuldigas"
            },
            {
                "_id": "liepaja",
                "code": "liepaja",
                "name": "Liepaja",
                "pk": "LV__liepaja"
            },
            {
                "_id": "liepajas",
                "code": "liepajas",
                "name": "Liepajas",
                "pk": "LV__liepajas"
            },
            {
                "_id": "limbazhu",
                "code": "limbazhu",
                "name": "Limbazhu",
                "pk": "LV__limbazhu"
            },
            {
                "_id": "ludzas",
                "code": "ludzas",
                "name": "Ludzas",
                "pk": "LV__ludzas"
            },
            {
                "_id": "madonas",
                "code": "madonas",
                "name": "Madonas",
                "pk": "LV__madonas"
            },
            {
                "_id": "ogres",
                "code": "ogres",
                "name": "Ogres",
                "pk": "LV__ogres"
            },
            {
                "_id": "preilu",
                "code": "preilu",
                "name": "Preilu",
                "pk": "LV__preilu"
            },
            {
                "_id": "rezekne",
                "code": "rezekne",
                "name": "Rezekne",
                "pk": "LV__rezekne"
            },
            {
                "_id": "rezeknes",
                "code": "rezeknes",
                "name": "Rezeknes",
                "pk": "LV__rezeknes"
            },
            {
                "_id": "riga",
                "code": "riga",
                "name": "Riga",
                "pk": "LV__riga"
            },
            {
                "_id": "rigas",
                "code": "rigas",
                "name": "Rigas",
                "pk": "LV__rigas"
            },
            {
                "_id": "saldus",
                "code": "saldus",
                "name": "Saldus",
                "pk": "LV__saldus"
            },
            {
                "_id": "talsu",
                "code": "talsu",
                "name": "Talsu",
                "pk": "LV__talsu"
            },
            {
                "_id": "tukuma",
                "code": "tukuma",
                "name": "Tukuma",
                "pk": "LV__tukuma"
            },
            {
                "_id": "valkas",
                "code": "valkas",
                "name": "Valkas",
                "pk": "LV__valkas"
            },
            {
                "_id": "valmieras",
                "code": "valmieras",
                "name": "Valmieras",
                "pk": "LV__valmieras"
            },
            {
                "_id": "ventspils",
                "code": "ventspils",
                "name": "Ventspils",
                "pk": "LV__ventspils"
            },
            {
                "_id": "ventspils_city",
                "code": "ventspils_city",
                "name": "Ventspils City",
                "pk": "LV__ventspils_city"
            }
        ],
        "pk": "LV"
    },
    {
        "_id": "LB",
        "code": "LB",
        "name": "Liban",
        "states": [
            {
                "_id": "beirut",
                "code": "beirut",
                "name": "Beirut",
                "pk": "LB__beirut"
            },
            {
                "_id": "jabal_lubnan",
                "code": "jabal_lubnan",
                "name": "Jabal Lubnan",
                "pk": "LB__jabal_lubnan"
            },
            {
                "_id": "mohafazat_liban-nord",
                "code": "mohafazat_liban-nord",
                "name": "Mohafazat Liban-Nord",
                "pk": "LB__mohafazat_liban-nord"
            },
            {
                "_id": "mohafazat_mont-liban",
                "code": "mohafazat_mont-liban",
                "name": "Mohafazat Mont-Liban",
                "pk": "LB__mohafazat_mont-liban"
            },
            {
                "_id": "sidon",
                "code": "sidon",
                "name": "Sidon",
                "pk": "LB__sidon"
            },
            {
                "_id": "al-biqa",
                "code": "al-biqa",
                "name": "al-Biqa",
                "pk": "LB__al-biqa"
            },
            {
                "_id": "al-janub",
                "code": "al-janub",
                "name": "al-Janub",
                "pk": "LB__al-janub"
            },
            {
                "_id": "an-nabatiyah",
                "code": "an-nabatiyah",
                "name": "an-Nabatiyah",
                "pk": "LB__an-nabatiyah"
            },
            {
                "_id": "ash-shamal",
                "code": "ash-shamal",
                "name": "ash-Shamal",
                "pk": "LB__ash-shamal"
            }
        ],
        "pk": "LB"
    },
    {
        "_id": "LS",
        "code": "LS",
        "name": "Lesotho",
        "states": [
            {
                "_id": "berea",
                "code": "berea",
                "name": "Berea",
                "pk": "LS__berea"
            },
            {
                "_id": "butha-buthe",
                "code": "butha-buthe",
                "name": "Butha-Buthe",
                "pk": "LS__butha-buthe"
            },
            {
                "_id": "leribe",
                "code": "leribe",
                "name": "Leribe",
                "pk": "LS__leribe"
            },
            {
                "_id": "mafeteng",
                "code": "mafeteng",
                "name": "Mafeteng",
                "pk": "LS__mafeteng"
            },
            {
                "_id": "maseru",
                "code": "maseru",
                "name": "Maseru",
                "pk": "LS__maseru"
            },
            {
                "_id": "mohales_hoek",
                "code": "mohales_hoek",
                "name": "Mohales Hoek",
                "pk": "LS__mohales_hoek"
            },
            {
                "_id": "mokhotlong",
                "code": "mokhotlong",
                "name": "Mokhotlong",
                "pk": "LS__mokhotlong"
            },
            {
                "_id": "qachas_nek",
                "code": "qachas_nek",
                "name": "Qachas Nek",
                "pk": "LS__qachas_nek"
            },
            {
                "_id": "quthing",
                "code": "quthing",
                "name": "Quthing",
                "pk": "LS__quthing"
            },
            {
                "_id": "thaba-tseka",
                "code": "thaba-tseka",
                "name": "Thaba-Tseka",
                "pk": "LS__thaba-tseka"
            }
        ],
        "pk": "LS"
    },
    {
        "_id": "LR",
        "code": "LR",
        "name": "Libéria",
        "states": [
            {
                "_id": "bomi",
                "code": "bomi",
                "name": "Bomi",
                "pk": "LR__bomi"
            },
            {
                "_id": "bong",
                "code": "bong",
                "name": "Bong",
                "pk": "LR__bong"
            },
            {
                "_id": "grand_bassa",
                "code": "grand_bassa",
                "name": "Grand Bassa",
                "pk": "LR__grand_bassa"
            },
            {
                "_id": "grand_cape_mount",
                "code": "grand_cape_mount",
                "name": "Grand Cape Mount",
                "pk": "LR__grand_cape_mount"
            },
            {
                "_id": "grand_gedeh",
                "code": "grand_gedeh",
                "name": "Grand Gedeh",
                "pk": "LR__grand_gedeh"
            },
            {
                "_id": "loffa",
                "code": "loffa",
                "name": "Loffa",
                "pk": "LR__loffa"
            },
            {
                "_id": "margibi",
                "code": "margibi",
                "name": "Margibi",
                "pk": "LR__margibi"
            },
            {
                "_id": "maryland_and_grand_kru",
                "code": "maryland_and_grand_kru",
                "name": "Maryland and Grand Kru",
                "pk": "LR__maryland_and_grand_kru"
            },
            {
                "_id": "montserrado",
                "code": "montserrado",
                "name": "Montserrado",
                "pk": "LR__montserrado"
            },
            {
                "_id": "nimba",
                "code": "nimba",
                "name": "Nimba",
                "pk": "LR__nimba"
            },
            {
                "_id": "rivercess",
                "code": "rivercess",
                "name": "Rivercess",
                "pk": "LR__rivercess"
            },
            {
                "_id": "sinoe",
                "code": "sinoe",
                "name": "Sinoe",
                "pk": "LR__sinoe"
            }
        ],
        "pk": "LR"
    },
    {
        "_id": "LY",
        "code": "LY",
        "name": "Libye",
        "states": [
            {
                "_id": "ajdabiya",
                "code": "ajdabiya",
                "name": "Ajdabiya",
                "pk": "LY__ajdabiya"
            },
            {
                "_id": "fezzan",
                "code": "fezzan",
                "name": "Fezzan",
                "pk": "LY__fezzan"
            },
            {
                "_id": "banghazi",
                "code": "banghazi",
                "name": "Banghazi",
                "pk": "LY__banghazi"
            },
            {
                "_id": "darnah",
                "code": "darnah",
                "name": "Darnah",
                "pk": "LY__darnah"
            },
            {
                "_id": "ghadamis",
                "code": "ghadamis",
                "name": "Ghadamis",
                "pk": "LY__ghadamis"
            },
            {
                "_id": "gharyan",
                "code": "gharyan",
                "name": "Gharyan",
                "pk": "LY__gharyan"
            },
            {
                "_id": "misratah",
                "code": "misratah",
                "name": "Misratah",
                "pk": "LY__misratah"
            },
            {
                "_id": "murzuq",
                "code": "murzuq",
                "name": "Murzuq",
                "pk": "LY__murzuq"
            },
            {
                "_id": "sabha",
                "code": "sabha",
                "name": "Sabha",
                "pk": "LY__sabha"
            },
            {
                "_id": "sawfajjin",
                "code": "sawfajjin",
                "name": "Sawfajjin",
                "pk": "LY__sawfajjin"
            },
            {
                "_id": "surt",
                "code": "surt",
                "name": "Surt",
                "pk": "LY__surt"
            },
            {
                "_id": "tarabulus",
                "code": "tarabulus",
                "name": "Tarabulus",
                "pk": "LY__tarabulus"
            },
            {
                "_id": "tarhunah",
                "code": "tarhunah",
                "name": "Tarhunah",
                "pk": "LY__tarhunah"
            },
            {
                "_id": "tripolitania",
                "code": "tripolitania",
                "name": "Tripolitania",
                "pk": "LY__tripolitania"
            },
            {
                "_id": "tubruq",
                "code": "tubruq",
                "name": "Tubruq",
                "pk": "LY__tubruq"
            },
            {
                "_id": "yafran",
                "code": "yafran",
                "name": "Yafran",
                "pk": "LY__yafran"
            },
            {
                "_id": "zlitan",
                "code": "zlitan",
                "name": "Zlitan",
                "pk": "LY__zlitan"
            },
            {
                "_id": "al-aziziyah",
                "code": "al-aziziyah",
                "name": "al-Aziziyah",
                "pk": "LY__al-aziziyah"
            },
            {
                "_id": "al-fatih",
                "code": "al-fatih",
                "name": "al-Fatih",
                "pk": "LY__al-fatih"
            },
            {
                "_id": "al-jabal_al_akhdar",
                "code": "al-jabal_al_akhdar",
                "name": "al-Jabal al Akhdar",
                "pk": "LY__al-jabal_al_akhdar"
            },
            {
                "_id": "al-jufrah",
                "code": "al-jufrah",
                "name": "al-Jufrah",
                "pk": "LY__al-jufrah"
            },
            {
                "_id": "al-khums",
                "code": "al-khums",
                "name": "al-Khums",
                "pk": "LY__al-khums"
            },
            {
                "_id": "al-kufrah",
                "code": "al-kufrah",
                "name": "al-Kufrah",
                "pk": "LY__al-kufrah"
            },
            {
                "_id": "an-nuqat_al-khams",
                "code": "an-nuqat_al-khams",
                "name": "an-Nuqat al-Khams",
                "pk": "LY__an-nuqat_al-khams"
            },
            {
                "_id": "ash-shati",
                "code": "ash-shati",
                "name": "ash-Shati",
                "pk": "LY__ash-shati"
            },
            {
                "_id": "az-zawiyah",
                "code": "az-zawiyah",
                "name": "az-Zawiyah",
                "pk": "LY__az-zawiyah"
            }
        ],
        "pk": "LY"
    },
    {
        "_id": "LI",
        "code": "LI",
        "name": "Liechtenstein",
        "states": [
            {
                "_id": "balzers",
                "code": "balzers",
                "name": "Balzers",
                "pk": "LI__balzers"
            },
            {
                "_id": "eschen",
                "code": "eschen",
                "name": "Eschen",
                "pk": "LI__eschen"
            },
            {
                "_id": "gamprin",
                "code": "gamprin",
                "name": "Gamprin",
                "pk": "LI__gamprin"
            },
            {
                "_id": "mauren",
                "code": "mauren",
                "name": "Mauren",
                "pk": "LI__mauren"
            },
            {
                "_id": "planken",
                "code": "planken",
                "name": "Planken",
                "pk": "LI__planken"
            },
            {
                "_id": "ruggell",
                "code": "ruggell",
                "name": "Ruggell",
                "pk": "LI__ruggell"
            },
            {
                "_id": "schaan",
                "code": "schaan",
                "name": "Schaan",
                "pk": "LI__schaan"
            },
            {
                "_id": "schellenberg",
                "code": "schellenberg",
                "name": "Schellenberg",
                "pk": "LI__schellenberg"
            },
            {
                "_id": "triesen",
                "code": "triesen",
                "name": "Triesen",
                "pk": "LI__triesen"
            },
            {
                "_id": "triesenberg",
                "code": "triesenberg",
                "name": "Triesenberg",
                "pk": "LI__triesenberg"
            },
            {
                "_id": "vaduz",
                "code": "vaduz",
                "name": "Vaduz",
                "pk": "LI__vaduz"
            }
        ],
        "pk": "LI"
    },
    {
        "_id": "LT",
        "code": "LT",
        "name": "Lituanie",
        "states": [
            {
                "_id": "alytaus",
                "code": "alytaus",
                "name": "Alytaus",
                "pk": "LT__alytaus"
            },
            {
                "_id": "anyksciai",
                "code": "anyksciai",
                "name": "Anyksciai",
                "pk": "LT__anyksciai"
            },
            {
                "_id": "kauno",
                "code": "kauno",
                "name": "Kauno",
                "pk": "LT__kauno"
            },
            {
                "_id": "klaipedos",
                "code": "klaipedos",
                "name": "Klaipedos",
                "pk": "LT__klaipedos"
            },
            {
                "_id": "marijampoles",
                "code": "marijampoles",
                "name": "Marijampoles",
                "pk": "LT__marijampoles"
            },
            {
                "_id": "panevezhio",
                "code": "panevezhio",
                "name": "Panevezhio",
                "pk": "LT__panevezhio"
            },
            {
                "_id": "panevezys",
                "code": "panevezys",
                "name": "Panevezys",
                "pk": "LT__panevezys"
            },
            {
                "_id": "shiauliu",
                "code": "shiauliu",
                "name": "Shiauliu",
                "pk": "LT__shiauliu"
            },
            {
                "_id": "taurages",
                "code": "taurages",
                "name": "Taurages",
                "pk": "LT__taurages"
            },
            {
                "_id": "telshiu",
                "code": "telshiu",
                "name": "Telshiu",
                "pk": "LT__telshiu"
            },
            {
                "_id": "telsiai",
                "code": "telsiai",
                "name": "Telsiai",
                "pk": "LT__telsiai"
            },
            {
                "_id": "utenos",
                "code": "utenos",
                "name": "Utenos",
                "pk": "LT__utenos"
            },
            {
                "_id": "vilniaus",
                "code": "vilniaus",
                "name": "Vilniaus",
                "pk": "LT__vilniaus"
            }
        ],
        "pk": "LT"
    },
    {
        "_id": "LU",
        "code": "LU",
        "name": "Luxembourg",
        "states": [
            {
                "_id": "capellen",
                "code": "capellen",
                "name": "Capellen",
                "pk": "LU__capellen"
            },
            {
                "_id": "clervaux",
                "code": "clervaux",
                "name": "Clervaux",
                "pk": "LU__clervaux"
            },
            {
                "_id": "diekirch",
                "code": "diekirch",
                "name": "Diekirch",
                "pk": "LU__diekirch"
            },
            {
                "_id": "echternach",
                "code": "echternach",
                "name": "Echternach",
                "pk": "LU__echternach"
            },
            {
                "_id": "esch-sur-alzette",
                "code": "esch-sur-alzette",
                "name": "Esch-sur-Alzette",
                "pk": "LU__esch-sur-alzette"
            },
            {
                "_id": "grevenmacher",
                "code": "grevenmacher",
                "name": "Grevenmacher",
                "pk": "LU__grevenmacher"
            },
            {
                "_id": "luxembourg",
                "code": "luxembourg",
                "name": "Luxembourg",
                "pk": "LU__luxembourg"
            },
            {
                "_id": "mersch",
                "code": "mersch",
                "name": "Mersch",
                "pk": "LU__mersch"
            },
            {
                "_id": "redange",
                "code": "redange",
                "name": "Redange",
                "pk": "LU__redange"
            },
            {
                "_id": "remich",
                "code": "remich",
                "name": "Remich",
                "pk": "LU__remich"
            },
            {
                "_id": "vianden",
                "code": "vianden",
                "name": "Vianden",
                "pk": "LU__vianden"
            },
            {
                "_id": "wiltz",
                "code": "wiltz",
                "name": "Wiltz",
                "pk": "LU__wiltz"
            }
        ],
        "pk": "LU"
    },
    {
        "_id": "MO",
        "code": "MO",
        "name": "R.A.S. de Macao",
        "states": [
            {
                "_id": "macau",
                "code": "macau",
                "name": "Macau",
                "pk": "MO__macau"
            }
        ],
        "pk": "MO"
    },
    {
        "_id": "MK",
        "code": "MK",
        "name": "Macédoine",
        "states": [
            {
                "_id": "berovo",
                "code": "berovo",
                "name": "Berovo",
                "pk": "MK__berovo"
            },
            {
                "_id": "bitola",
                "code": "bitola",
                "name": "Bitola",
                "pk": "MK__bitola"
            },
            {
                "_id": "brod",
                "code": "brod",
                "name": "Brod",
                "pk": "MK__brod"
            },
            {
                "_id": "debar",
                "code": "debar",
                "name": "Debar",
                "pk": "MK__debar"
            },
            {
                "_id": "delchevo",
                "code": "delchevo",
                "name": "Delchevo",
                "pk": "MK__delchevo"
            },
            {
                "_id": "demir_hisar",
                "code": "demir_hisar",
                "name": "Demir Hisar",
                "pk": "MK__demir_hisar"
            },
            {
                "_id": "gevgelija",
                "code": "gevgelija",
                "name": "Gevgelija",
                "pk": "MK__gevgelija"
            },
            {
                "_id": "gostivar",
                "code": "gostivar",
                "name": "Gostivar",
                "pk": "MK__gostivar"
            },
            {
                "_id": "kavadarci",
                "code": "kavadarci",
                "name": "Kavadarci",
                "pk": "MK__kavadarci"
            },
            {
                "_id": "kichevo",
                "code": "kichevo",
                "name": "Kichevo",
                "pk": "MK__kichevo"
            },
            {
                "_id": "kochani",
                "code": "kochani",
                "name": "Kochani",
                "pk": "MK__kochani"
            },
            {
                "_id": "kratovo",
                "code": "kratovo",
                "name": "Kratovo",
                "pk": "MK__kratovo"
            },
            {
                "_id": "kriva_palanka",
                "code": "kriva_palanka",
                "name": "Kriva Palanka",
                "pk": "MK__kriva_palanka"
            },
            {
                "_id": "krushevo",
                "code": "krushevo",
                "name": "Krushevo",
                "pk": "MK__krushevo"
            },
            {
                "_id": "kumanovo",
                "code": "kumanovo",
                "name": "Kumanovo",
                "pk": "MK__kumanovo"
            },
            {
                "_id": "negotino",
                "code": "negotino",
                "name": "Negotino",
                "pk": "MK__negotino"
            },
            {
                "_id": "ohrid",
                "code": "ohrid",
                "name": "Ohrid",
                "pk": "MK__ohrid"
            },
            {
                "_id": "prilep",
                "code": "prilep",
                "name": "Prilep",
                "pk": "MK__prilep"
            },
            {
                "_id": "probishtip",
                "code": "probishtip",
                "name": "Probishtip",
                "pk": "MK__probishtip"
            },
            {
                "_id": "radovish",
                "code": "radovish",
                "name": "Radovish",
                "pk": "MK__radovish"
            },
            {
                "_id": "resen",
                "code": "resen",
                "name": "Resen",
                "pk": "MK__resen"
            },
            {
                "_id": "shtip",
                "code": "shtip",
                "name": "Shtip",
                "pk": "MK__shtip"
            },
            {
                "_id": "skopje",
                "code": "skopje",
                "name": "Skopje",
                "pk": "MK__skopje"
            },
            {
                "_id": "struga",
                "code": "struga",
                "name": "Struga",
                "pk": "MK__struga"
            },
            {
                "_id": "strumica",
                "code": "strumica",
                "name": "Strumica",
                "pk": "MK__strumica"
            },
            {
                "_id": "sveti_nikole",
                "code": "sveti_nikole",
                "name": "Sveti Nikole",
                "pk": "MK__sveti_nikole"
            },
            {
                "_id": "tetovo",
                "code": "tetovo",
                "name": "Tetovo",
                "pk": "MK__tetovo"
            },
            {
                "_id": "valandovo",
                "code": "valandovo",
                "name": "Valandovo",
                "pk": "MK__valandovo"
            },
            {
                "_id": "veles",
                "code": "veles",
                "name": "Veles",
                "pk": "MK__veles"
            },
            {
                "_id": "vinica",
                "code": "vinica",
                "name": "Vinica",
                "pk": "MK__vinica"
            }
        ],
        "pk": "MK"
    },
    {
        "_id": "MG",
        "code": "MG",
        "name": "Madagascar",
        "states": [
            {
                "_id": "antananarivo",
                "code": "antananarivo",
                "name": "Antananarivo",
                "pk": "MG__antananarivo"
            },
            {
                "_id": "antsiranana",
                "code": "antsiranana",
                "name": "Antsiranana",
                "pk": "MG__antsiranana"
            },
            {
                "_id": "fianarantsoa",
                "code": "fianarantsoa",
                "name": "Fianarantsoa",
                "pk": "MG__fianarantsoa"
            },
            {
                "_id": "mahajanga",
                "code": "mahajanga",
                "name": "Mahajanga",
                "pk": "MG__mahajanga"
            },
            {
                "_id": "toamasina",
                "code": "toamasina",
                "name": "Toamasina",
                "pk": "MG__toamasina"
            },
            {
                "_id": "toliary",
                "code": "toliary",
                "name": "Toliary",
                "pk": "MG__toliary"
            }
        ],
        "pk": "MG"
    },
    {
        "_id": "MW",
        "code": "MW",
        "name": "Malawi",
        "states": [
            {
                "_id": "balaka",
                "code": "balaka",
                "name": "Balaka",
                "pk": "MW__balaka"
            },
            {
                "_id": "blantyre_city",
                "code": "blantyre_city",
                "name": "Blantyre City",
                "pk": "MW__blantyre_city"
            },
            {
                "_id": "chikwawa",
                "code": "chikwawa",
                "name": "Chikwawa",
                "pk": "MW__chikwawa"
            },
            {
                "_id": "chiradzulu",
                "code": "chiradzulu",
                "name": "Chiradzulu",
                "pk": "MW__chiradzulu"
            },
            {
                "_id": "chitipa",
                "code": "chitipa",
                "name": "Chitipa",
                "pk": "MW__chitipa"
            },
            {
                "_id": "dedza",
                "code": "dedza",
                "name": "Dedza",
                "pk": "MW__dedza"
            },
            {
                "_id": "dowa",
                "code": "dowa",
                "name": "Dowa",
                "pk": "MW__dowa"
            },
            {
                "_id": "karonga",
                "code": "karonga",
                "name": "Karonga",
                "pk": "MW__karonga"
            },
            {
                "_id": "kasungu",
                "code": "kasungu",
                "name": "Kasungu",
                "pk": "MW__kasungu"
            },
            {
                "_id": "lilongwe_city",
                "code": "lilongwe_city",
                "name": "Lilongwe City",
                "pk": "MW__lilongwe_city"
            },
            {
                "_id": "machinga",
                "code": "machinga",
                "name": "Machinga",
                "pk": "MW__machinga"
            },
            {
                "_id": "mangochi",
                "code": "mangochi",
                "name": "Mangochi",
                "pk": "MW__mangochi"
            },
            {
                "_id": "mchinji",
                "code": "mchinji",
                "name": "Mchinji",
                "pk": "MW__mchinji"
            },
            {
                "_id": "mulanje",
                "code": "mulanje",
                "name": "Mulanje",
                "pk": "MW__mulanje"
            },
            {
                "_id": "mwanza",
                "code": "mwanza",
                "name": "Mwanza",
                "pk": "MW__mwanza"
            },
            {
                "_id": "mzimba",
                "code": "mzimba",
                "name": "Mzimba",
                "pk": "MW__mzimba"
            },
            {
                "_id": "mzuzu_city",
                "code": "mzuzu_city",
                "name": "Mzuzu City",
                "pk": "MW__mzuzu_city"
            },
            {
                "_id": "nkhata_bay",
                "code": "nkhata_bay",
                "name": "Nkhata Bay",
                "pk": "MW__nkhata_bay"
            },
            {
                "_id": "nkhotakota",
                "code": "nkhotakota",
                "name": "Nkhotakota",
                "pk": "MW__nkhotakota"
            },
            {
                "_id": "nsanje",
                "code": "nsanje",
                "name": "Nsanje",
                "pk": "MW__nsanje"
            },
            {
                "_id": "ntcheu",
                "code": "ntcheu",
                "name": "Ntcheu",
                "pk": "MW__ntcheu"
            },
            {
                "_id": "ntchisi",
                "code": "ntchisi",
                "name": "Ntchisi",
                "pk": "MW__ntchisi"
            },
            {
                "_id": "phalombe",
                "code": "phalombe",
                "name": "Phalombe",
                "pk": "MW__phalombe"
            },
            {
                "_id": "rumphi",
                "code": "rumphi",
                "name": "Rumphi",
                "pk": "MW__rumphi"
            },
            {
                "_id": "salima",
                "code": "salima",
                "name": "Salima",
                "pk": "MW__salima"
            },
            {
                "_id": "thyolo",
                "code": "thyolo",
                "name": "Thyolo",
                "pk": "MW__thyolo"
            },
            {
                "_id": "zomba_municipality",
                "code": "zomba_municipality",
                "name": "Zomba Municipality",
                "pk": "MW__zomba_municipality"
            }
        ],
        "pk": "MW"
    },
    {
        "_id": "MY",
        "code": "MY",
        "name": "Malaisie",
        "states": [
            {
                "_id": "johor",
                "code": "johor",
                "name": "Johor",
                "pk": "MY__johor"
            },
            {
                "_id": "kedah",
                "code": "kedah",
                "name": "Kedah",
                "pk": "MY__kedah"
            },
            {
                "_id": "kelantan",
                "code": "kelantan",
                "name": "Kelantan",
                "pk": "MY__kelantan"
            },
            {
                "_id": "kuala_lumpur",
                "code": "kuala_lumpur",
                "name": "Kuala Lumpur",
                "pk": "MY__kuala_lumpur"
            },
            {
                "_id": "labuan",
                "code": "labuan",
                "name": "Labuan",
                "pk": "MY__labuan"
            },
            {
                "_id": "melaka",
                "code": "melaka",
                "name": "Melaka",
                "pk": "MY__melaka"
            },
            {
                "_id": "negeri_johor",
                "code": "negeri_johor",
                "name": "Negeri Johor",
                "pk": "MY__negeri_johor"
            },
            {
                "_id": "negeri_sembilan",
                "code": "negeri_sembilan",
                "name": "Negeri Sembilan",
                "pk": "MY__negeri_sembilan"
            },
            {
                "_id": "pahang",
                "code": "pahang",
                "name": "Pahang",
                "pk": "MY__pahang"
            },
            {
                "_id": "penang",
                "code": "penang",
                "name": "Penang",
                "pk": "MY__penang"
            },
            {
                "_id": "perak",
                "code": "perak",
                "name": "Perak",
                "pk": "MY__perak"
            },
            {
                "_id": "perlis",
                "code": "perlis",
                "name": "Perlis",
                "pk": "MY__perlis"
            },
            {
                "_id": "pulau_pinang",
                "code": "pulau_pinang",
                "name": "Pulau Pinang",
                "pk": "MY__pulau_pinang"
            },
            {
                "_id": "sabah",
                "code": "sabah",
                "name": "Sabah",
                "pk": "MY__sabah"
            },
            {
                "_id": "sarawak",
                "code": "sarawak",
                "name": "Sarawak",
                "pk": "MY__sarawak"
            },
            {
                "_id": "selangor",
                "code": "selangor",
                "name": "Selangor",
                "pk": "MY__selangor"
            },
            {
                "_id": "sembilan",
                "code": "sembilan",
                "name": "Sembilan",
                "pk": "MY__sembilan"
            },
            {
                "_id": "terengganu",
                "code": "terengganu",
                "name": "Terengganu",
                "pk": "MY__terengganu"
            }
        ],
        "pk": "MY"
    },
    {
        "_id": "MV",
        "code": "MV",
        "name": "Maldives",
        "states": [
            {
                "_id": "alif_alif",
                "code": "alif_alif",
                "name": "Alif Alif",
                "pk": "MV__alif_alif"
            },
            {
                "_id": "alif_dhaal",
                "code": "alif_dhaal",
                "name": "Alif Dhaal",
                "pk": "MV__alif_dhaal"
            },
            {
                "_id": "baa",
                "code": "baa",
                "name": "Baa",
                "pk": "MV__baa"
            },
            {
                "_id": "dhaal",
                "code": "dhaal",
                "name": "Dhaal",
                "pk": "MV__dhaal"
            },
            {
                "_id": "faaf",
                "code": "faaf",
                "name": "Faaf",
                "pk": "MV__faaf"
            },
            {
                "_id": "gaaf_alif",
                "code": "gaaf_alif",
                "name": "Gaaf Alif",
                "pk": "MV__gaaf_alif"
            },
            {
                "_id": "gaaf_dhaal",
                "code": "gaaf_dhaal",
                "name": "Gaaf Dhaal",
                "pk": "MV__gaaf_dhaal"
            },
            {
                "_id": "ghaviyani",
                "code": "ghaviyani",
                "name": "Ghaviyani",
                "pk": "MV__ghaviyani"
            },
            {
                "_id": "haa_alif",
                "code": "haa_alif",
                "name": "Haa Alif",
                "pk": "MV__haa_alif"
            },
            {
                "_id": "haa_dhaal",
                "code": "haa_dhaal",
                "name": "Haa Dhaal",
                "pk": "MV__haa_dhaal"
            },
            {
                "_id": "kaaf",
                "code": "kaaf",
                "name": "Kaaf",
                "pk": "MV__kaaf"
            },
            {
                "_id": "laam",
                "code": "laam",
                "name": "Laam",
                "pk": "MV__laam"
            },
            {
                "_id": "lhaviyani",
                "code": "lhaviyani",
                "name": "Lhaviyani",
                "pk": "MV__lhaviyani"
            },
            {
                "_id": "male",
                "code": "male",
                "name": "Male",
                "pk": "MV__male"
            },
            {
                "_id": "miim",
                "code": "miim",
                "name": "Miim",
                "pk": "MV__miim"
            },
            {
                "_id": "nuun",
                "code": "nuun",
                "name": "Nuun",
                "pk": "MV__nuun"
            },
            {
                "_id": "raa",
                "code": "raa",
                "name": "Raa",
                "pk": "MV__raa"
            },
            {
                "_id": "shaviyani",
                "code": "shaviyani",
                "name": "Shaviyani",
                "pk": "MV__shaviyani"
            },
            {
                "_id": "siin",
                "code": "siin",
                "name": "Siin",
                "pk": "MV__siin"
            },
            {
                "_id": "thaa",
                "code": "thaa",
                "name": "Thaa",
                "pk": "MV__thaa"
            },
            {
                "_id": "vaav",
                "code": "vaav",
                "name": "Vaav",
                "pk": "MV__vaav"
            }
        ],
        "pk": "MV"
    },
    {
        "_id": "ML",
        "code": "ML",
        "name": "Mali",
        "states": [
            {
                "_id": "bamako",
                "code": "bamako",
                "name": "Bamako",
                "pk": "ML__bamako"
            },
            {
                "_id": "gao",
                "code": "gao",
                "name": "Gao",
                "pk": "ML__gao"
            },
            {
                "_id": "kayes",
                "code": "kayes",
                "name": "Kayes",
                "pk": "ML__kayes"
            },
            {
                "_id": "kidal",
                "code": "kidal",
                "name": "Kidal",
                "pk": "ML__kidal"
            },
            {
                "_id": "koulikoro",
                "code": "koulikoro",
                "name": "Koulikoro",
                "pk": "ML__koulikoro"
            },
            {
                "_id": "mopti",
                "code": "mopti",
                "name": "Mopti",
                "pk": "ML__mopti"
            },
            {
                "_id": "segou",
                "code": "segou",
                "name": "Segou",
                "pk": "ML__segou"
            },
            {
                "_id": "sikasso",
                "code": "sikasso",
                "name": "Sikasso",
                "pk": "ML__sikasso"
            },
            {
                "_id": "tombouctou",
                "code": "tombouctou",
                "name": "Tombouctou",
                "pk": "ML__tombouctou"
            }
        ],
        "pk": "ML"
    },
    {
        "_id": "MT",
        "code": "MT",
        "name": "Malte",
        "states": [
            {
                "_id": "gozo_and_comino",
                "code": "gozo_and_comino",
                "name": "Gozo and Comino",
                "pk": "MT__gozo_and_comino"
            },
            {
                "_id": "inner_harbour",
                "code": "inner_harbour",
                "name": "Inner Harbour",
                "pk": "MT__inner_harbour"
            },
            {
                "_id": "northern",
                "code": "northern",
                "name": "Northern",
                "pk": "MT__northern"
            },
            {
                "_id": "outer_harbour",
                "code": "outer_harbour",
                "name": "Outer Harbour",
                "pk": "MT__outer_harbour"
            },
            {
                "_id": "south_eastern",
                "code": "south_eastern",
                "name": "South Eastern",
                "pk": "MT__south_eastern"
            },
            {
                "_id": "valletta",
                "code": "valletta",
                "name": "Valletta",
                "pk": "MT__valletta"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "MT__western"
            }
        ],
        "pk": "MT"
    },
    {
        "_id": "XM",
        "code": "XM",
        "name": "Île de Man",
        "states": [
            {
                "_id": "castletown",
                "code": "castletown",
                "name": "Castletown",
                "pk": "XM__castletown"
            },
            {
                "_id": "douglas",
                "code": "douglas",
                "name": "Douglas",
                "pk": "XM__douglas"
            },
            {
                "_id": "laxey",
                "code": "laxey",
                "name": "Laxey",
                "pk": "XM__laxey"
            },
            {
                "_id": "onchan",
                "code": "onchan",
                "name": "Onchan",
                "pk": "XM__onchan"
            },
            {
                "_id": "peel",
                "code": "peel",
                "name": "Peel",
                "pk": "XM__peel"
            },
            {
                "_id": "port_erin",
                "code": "port_erin",
                "name": "Port Erin",
                "pk": "XM__port_erin"
            },
            {
                "_id": "port_saint_mary",
                "code": "port_saint_mary",
                "name": "Port Saint Mary",
                "pk": "XM__port_saint_mary"
            },
            {
                "_id": "ramsey",
                "code": "ramsey",
                "name": "Ramsey",
                "pk": "XM__ramsey"
            }
        ],
        "pk": "XM"
    },
    {
        "_id": "MH",
        "code": "MH",
        "name": "Îles Marshall",
        "states": [
            {
                "_id": "ailinlaplap",
                "code": "ailinlaplap",
                "name": "Ailinlaplap",
                "pk": "MH__ailinlaplap"
            },
            {
                "_id": "ailuk",
                "code": "ailuk",
                "name": "Ailuk",
                "pk": "MH__ailuk"
            },
            {
                "_id": "arno",
                "code": "arno",
                "name": "Arno",
                "pk": "MH__arno"
            },
            {
                "_id": "aur",
                "code": "aur",
                "name": "Aur",
                "pk": "MH__aur"
            },
            {
                "_id": "bikini",
                "code": "bikini",
                "name": "Bikini",
                "pk": "MH__bikini"
            },
            {
                "_id": "ebon",
                "code": "ebon",
                "name": "Ebon",
                "pk": "MH__ebon"
            },
            {
                "_id": "enewetak",
                "code": "enewetak",
                "name": "Enewetak",
                "pk": "MH__enewetak"
            },
            {
                "_id": "jabat",
                "code": "jabat",
                "name": "Jabat",
                "pk": "MH__jabat"
            },
            {
                "_id": "jaluit",
                "code": "jaluit",
                "name": "Jaluit",
                "pk": "MH__jaluit"
            },
            {
                "_id": "kili",
                "code": "kili",
                "name": "Kili",
                "pk": "MH__kili"
            },
            {
                "_id": "kwajalein",
                "code": "kwajalein",
                "name": "Kwajalein",
                "pk": "MH__kwajalein"
            },
            {
                "_id": "lae",
                "code": "lae",
                "name": "Lae",
                "pk": "MH__lae"
            },
            {
                "_id": "lib",
                "code": "lib",
                "name": "Lib",
                "pk": "MH__lib"
            },
            {
                "_id": "likiep",
                "code": "likiep",
                "name": "Likiep",
                "pk": "MH__likiep"
            },
            {
                "_id": "majuro",
                "code": "majuro",
                "name": "Majuro",
                "pk": "MH__majuro"
            },
            {
                "_id": "maloelap",
                "code": "maloelap",
                "name": "Maloelap",
                "pk": "MH__maloelap"
            },
            {
                "_id": "mejit",
                "code": "mejit",
                "name": "Mejit",
                "pk": "MH__mejit"
            },
            {
                "_id": "mili",
                "code": "mili",
                "name": "Mili",
                "pk": "MH__mili"
            },
            {
                "_id": "namorik",
                "code": "namorik",
                "name": "Namorik",
                "pk": "MH__namorik"
            },
            {
                "_id": "namu",
                "code": "namu",
                "name": "Namu",
                "pk": "MH__namu"
            },
            {
                "_id": "rongelap",
                "code": "rongelap",
                "name": "Rongelap",
                "pk": "MH__rongelap"
            },
            {
                "_id": "ujae",
                "code": "ujae",
                "name": "Ujae",
                "pk": "MH__ujae"
            },
            {
                "_id": "utrik",
                "code": "utrik",
                "name": "Utrik",
                "pk": "MH__utrik"
            },
            {
                "_id": "wotho",
                "code": "wotho",
                "name": "Wotho",
                "pk": "MH__wotho"
            },
            {
                "_id": "wotje",
                "code": "wotje",
                "name": "Wotje",
                "pk": "MH__wotje"
            }
        ],
        "pk": "MH"
    },
    {
        "_id": "MQ",
        "code": "MQ",
        "name": "Martinique",
        "states": [
            {
                "_id": "fort-de-france",
                "code": "fort-de-france",
                "name": "Fort-de-France",
                "pk": "MQ__fort-de-france"
            },
            {
                "_id": "la_trinite",
                "code": "la_trinite",
                "name": "La Trinite",
                "pk": "MQ__la_trinite"
            },
            {
                "_id": "le_marin",
                "code": "le_marin",
                "name": "Le Marin",
                "pk": "MQ__le_marin"
            },
            {
                "_id": "saint-pierre",
                "code": "saint-pierre",
                "name": "Saint-Pierre",
                "pk": "MQ__saint-pierre"
            }
        ],
        "pk": "MQ"
    },
    {
        "_id": "MR",
        "code": "MR",
        "name": "Mauritanie",
        "states": [
            {
                "_id": "adrar",
                "code": "adrar",
                "name": "Adrar",
                "pk": "MR__adrar"
            },
            {
                "_id": "assaba",
                "code": "assaba",
                "name": "Assaba",
                "pk": "MR__assaba"
            },
            {
                "_id": "brakna",
                "code": "brakna",
                "name": "Brakna",
                "pk": "MR__brakna"
            },
            {
                "_id": "dhakhlat_nawadibu",
                "code": "dhakhlat_nawadibu",
                "name": "Dhakhlat Nawadibu",
                "pk": "MR__dhakhlat_nawadibu"
            },
            {
                "_id": "hudh-al-gharbi",
                "code": "hudh-al-gharbi",
                "name": "Hudh-al-Gharbi",
                "pk": "MR__hudh-al-gharbi"
            },
            {
                "_id": "hudh-ash-sharqi",
                "code": "hudh-ash-sharqi",
                "name": "Hudh-ash-Sharqi",
                "pk": "MR__hudh-ash-sharqi"
            },
            {
                "_id": "inshiri",
                "code": "inshiri",
                "name": "Inshiri",
                "pk": "MR__inshiri"
            },
            {
                "_id": "nawakshut",
                "code": "nawakshut",
                "name": "Nawakshut",
                "pk": "MR__nawakshut"
            },
            {
                "_id": "qidimagha",
                "code": "qidimagha",
                "name": "Qidimagha",
                "pk": "MR__qidimagha"
            },
            {
                "_id": "qurqul",
                "code": "qurqul",
                "name": "Qurqul",
                "pk": "MR__qurqul"
            },
            {
                "_id": "taqant",
                "code": "taqant",
                "name": "Taqant",
                "pk": "MR__taqant"
            },
            {
                "_id": "tiris_zammur",
                "code": "tiris_zammur",
                "name": "Tiris Zammur",
                "pk": "MR__tiris_zammur"
            },
            {
                "_id": "trarza",
                "code": "trarza",
                "name": "Trarza",
                "pk": "MR__trarza"
            }
        ],
        "pk": "MR"
    },
    {
        "_id": "MU",
        "code": "MU",
        "name": "Maurice",
        "states": [
            {
                "_id": "black_river",
                "code": "black_river",
                "name": "Black River",
                "pk": "MU__black_river"
            },
            {
                "_id": "eau_coulee",
                "code": "eau_coulee",
                "name": "Eau Coulee",
                "pk": "MU__eau_coulee"
            },
            {
                "_id": "flacq",
                "code": "flacq",
                "name": "Flacq",
                "pk": "MU__flacq"
            },
            {
                "_id": "floreal",
                "code": "floreal",
                "name": "Floreal",
                "pk": "MU__floreal"
            },
            {
                "_id": "grand_port",
                "code": "grand_port",
                "name": "Grand Port",
                "pk": "MU__grand_port"
            },
            {
                "_id": "moka",
                "code": "moka",
                "name": "Moka",
                "pk": "MU__moka"
            },
            {
                "_id": "pamplempousses",
                "code": "pamplempousses",
                "name": "Pamplempousses",
                "pk": "MU__pamplempousses"
            },
            {
                "_id": "plaines_wilhelm",
                "code": "plaines_wilhelm",
                "name": "Plaines Wilhelm",
                "pk": "MU__plaines_wilhelm"
            },
            {
                "_id": "port_louis",
                "code": "port_louis",
                "name": "Port Louis",
                "pk": "MU__port_louis"
            },
            {
                "_id": "riviere_du_rempart",
                "code": "riviere_du_rempart",
                "name": "Riviere du Rempart",
                "pk": "MU__riviere_du_rempart"
            },
            {
                "_id": "rodrigues",
                "code": "rodrigues",
                "name": "Rodrigues",
                "pk": "MU__rodrigues"
            },
            {
                "_id": "rose_hill",
                "code": "rose_hill",
                "name": "Rose Hill",
                "pk": "MU__rose_hill"
            },
            {
                "_id": "savanne",
                "code": "savanne",
                "name": "Savanne",
                "pk": "MU__savanne"
            }
        ],
        "pk": "MU"
    },
    {
        "_id": "YT",
        "code": "YT",
        "name": "Mayotte",
        "states": [
            {
                "_id": "mayotte",
                "code": "mayotte",
                "name": "Mayotte",
                "pk": "YT__mayotte"
            },
            {
                "_id": "pamanzi",
                "code": "pamanzi",
                "name": "Pamanzi",
                "pk": "YT__pamanzi"
            }
        ],
        "pk": "YT"
    },
    {
        "_id": "MX",
        "code": "MX",
        "name": "Mexique",
        "states": [
            {
                "_id": "aguascalientes",
                "code": "aguascalientes",
                "name": "Aguascalientes",
                "pk": "MX__aguascalientes"
            },
            {
                "_id": "baja_california",
                "code": "baja_california",
                "name": "Baja California",
                "pk": "MX__baja_california"
            },
            {
                "_id": "baja_california_sur",
                "code": "baja_california_sur",
                "name": "Baja California Sur",
                "pk": "MX__baja_california_sur"
            },
            {
                "_id": "campeche",
                "code": "campeche",
                "name": "Campeche",
                "pk": "MX__campeche"
            },
            {
                "_id": "chiapas",
                "code": "chiapas",
                "name": "Chiapas",
                "pk": "MX__chiapas"
            },
            {
                "_id": "chihuahua",
                "code": "chihuahua",
                "name": "Chihuahua",
                "pk": "MX__chihuahua"
            },
            {
                "_id": "coahuila",
                "code": "coahuila",
                "name": "Coahuila",
                "pk": "MX__coahuila"
            },
            {
                "_id": "colima",
                "code": "colima",
                "name": "Colima",
                "pk": "MX__colima"
            },
            {
                "_id": "distrito_federal",
                "code": "distrito_federal",
                "name": "Distrito Federal",
                "pk": "MX__distrito_federal"
            },
            {
                "_id": "durango",
                "code": "durango",
                "name": "Durango",
                "pk": "MX__durango"
            },
            {
                "_id": "estado_de_mexico",
                "code": "estado_de_mexico",
                "name": "Estado de Mexico",
                "pk": "MX__estado_de_mexico"
            },
            {
                "_id": "guanajuato",
                "code": "guanajuato",
                "name": "Guanajuato",
                "pk": "MX__guanajuato"
            },
            {
                "_id": "guerrero",
                "code": "guerrero",
                "name": "Guerrero",
                "pk": "MX__guerrero"
            },
            {
                "_id": "hidalgo",
                "code": "hidalgo",
                "name": "Hidalgo",
                "pk": "MX__hidalgo"
            },
            {
                "_id": "jalisco",
                "code": "jalisco",
                "name": "Jalisco",
                "pk": "MX__jalisco"
            },
            {
                "_id": "mexico",
                "code": "mexico",
                "name": "Mexico",
                "pk": "MX__mexico"
            },
            {
                "_id": "michoacan",
                "code": "michoacan",
                "name": "Michoacan",
                "pk": "MX__michoacan"
            },
            {
                "_id": "morelos",
                "code": "morelos",
                "name": "Morelos",
                "pk": "MX__morelos"
            },
            {
                "_id": "nayarit",
                "code": "nayarit",
                "name": "Nayarit",
                "pk": "MX__nayarit"
            },
            {
                "_id": "nuevo_leon",
                "code": "nuevo_leon",
                "name": "Nuevo Leon",
                "pk": "MX__nuevo_leon"
            },
            {
                "_id": "oaxaca",
                "code": "oaxaca",
                "name": "Oaxaca",
                "pk": "MX__oaxaca"
            },
            {
                "_id": "puebla",
                "code": "puebla",
                "name": "Puebla",
                "pk": "MX__puebla"
            },
            {
                "_id": "queretaro",
                "code": "queretaro",
                "name": "Queretaro",
                "pk": "MX__queretaro"
            },
            {
                "_id": "quintana_roo",
                "code": "quintana_roo",
                "name": "Quintana Roo",
                "pk": "MX__quintana_roo"
            },
            {
                "_id": "san_luis_potosi",
                "code": "san_luis_potosi",
                "name": "San Luis Potosi",
                "pk": "MX__san_luis_potosi"
            },
            {
                "_id": "sinaloa",
                "code": "sinaloa",
                "name": "Sinaloa",
                "pk": "MX__sinaloa"
            },
            {
                "_id": "sonora",
                "code": "sonora",
                "name": "Sonora",
                "pk": "MX__sonora"
            },
            {
                "_id": "tabasco",
                "code": "tabasco",
                "name": "Tabasco",
                "pk": "MX__tabasco"
            },
            {
                "_id": "tamaulipas",
                "code": "tamaulipas",
                "name": "Tamaulipas",
                "pk": "MX__tamaulipas"
            },
            {
                "_id": "tlaxcala",
                "code": "tlaxcala",
                "name": "Tlaxcala",
                "pk": "MX__tlaxcala"
            },
            {
                "_id": "veracruz",
                "code": "veracruz",
                "name": "Veracruz",
                "pk": "MX__veracruz"
            },
            {
                "_id": "yucatan",
                "code": "yucatan",
                "name": "Yucatan",
                "pk": "MX__yucatan"
            },
            {
                "_id": "zacatecas",
                "code": "zacatecas",
                "name": "Zacatecas",
                "pk": "MX__zacatecas"
            }
        ],
        "only_shipping": true,
        "pk": "MX"
    },
    {
        "_id": "FM",
        "code": "FM",
        "name": "Micronésie",
        "states": [
            {
                "_id": "chuuk",
                "code": "chuuk",
                "name": "Chuuk",
                "pk": "FM__chuuk"
            },
            {
                "_id": "kusaie",
                "code": "kusaie",
                "name": "Kusaie",
                "pk": "FM__kusaie"
            },
            {
                "_id": "pohnpei",
                "code": "pohnpei",
                "name": "Pohnpei",
                "pk": "FM__pohnpei"
            },
            {
                "_id": "yap",
                "code": "yap",
                "name": "Yap",
                "pk": "FM__yap"
            }
        ],
        "pk": "FM"
    },
    {
        "_id": "MD",
        "code": "MD",
        "name": "Moldavie",
        "states": [
            {
                "_id": "balti",
                "code": "balti",
                "name": "Balti",
                "pk": "MD__balti"
            },
            {
                "_id": "cahul",
                "code": "cahul",
                "name": "Cahul",
                "pk": "MD__cahul"
            },
            {
                "_id": "chisinau",
                "code": "chisinau",
                "name": "Chisinau",
                "pk": "MD__chisinau"
            },
            {
                "_id": "chisinau_oras",
                "code": "chisinau_oras",
                "name": "Chisinau Oras",
                "pk": "MD__chisinau_oras"
            },
            {
                "_id": "edinet",
                "code": "edinet",
                "name": "Edinet",
                "pk": "MD__edinet"
            },
            {
                "_id": "gagauzia",
                "code": "gagauzia",
                "name": "Gagauzia",
                "pk": "MD__gagauzia"
            },
            {
                "_id": "lapusna",
                "code": "lapusna",
                "name": "Lapusna",
                "pk": "MD__lapusna"
            },
            {
                "_id": "orhei",
                "code": "orhei",
                "name": "Orhei",
                "pk": "MD__orhei"
            },
            {
                "_id": "soroca",
                "code": "soroca",
                "name": "Soroca",
                "pk": "MD__soroca"
            },
            {
                "_id": "taraclia",
                "code": "taraclia",
                "name": "Taraclia",
                "pk": "MD__taraclia"
            },
            {
                "_id": "tighina",
                "code": "tighina",
                "name": "Tighina",
                "pk": "MD__tighina"
            },
            {
                "_id": "transnistria",
                "code": "transnistria",
                "name": "Transnistria",
                "pk": "MD__transnistria"
            },
            {
                "_id": "ungheni",
                "code": "ungheni",
                "name": "Ungheni",
                "pk": "MD__ungheni"
            }
        ],
        "pk": "MD"
    },
    {
        "_id": "MC",
        "code": "MC",
        "name": "Monaco",
        "states": [
            {
                "_id": "fontvieille",
                "code": "fontvieille",
                "name": "Fontvieille",
                "pk": "MC__fontvieille"
            },
            {
                "_id": "la_condamine",
                "code": "la_condamine",
                "name": "La Condamine",
                "pk": "MC__la_condamine"
            },
            {
                "_id": "monaco-ville",
                "code": "monaco-ville",
                "name": "Monaco-Ville",
                "pk": "MC__monaco-ville"
            },
            {
                "_id": "monte_carlo",
                "code": "monte_carlo",
                "name": "Monte Carlo",
                "pk": "MC__monte_carlo"
            }
        ],
        "pk": "MC"
    },
    {
        "_id": "MN",
        "code": "MN",
        "name": "Mongolie",
        "states": [
            {
                "_id": "arhangaj",
                "code": "arhangaj",
                "name": "Arhangaj",
                "pk": "MN__arhangaj"
            },
            {
                "_id": "bajan-olgij",
                "code": "bajan-olgij",
                "name": "Bajan-Olgij",
                "pk": "MN__bajan-olgij"
            },
            {
                "_id": "bajanhongor",
                "code": "bajanhongor",
                "name": "Bajanhongor",
                "pk": "MN__bajanhongor"
            },
            {
                "_id": "bulgan",
                "code": "bulgan",
                "name": "Bulgan",
                "pk": "MN__bulgan"
            },
            {
                "_id": "darhan-uul",
                "code": "darhan-uul",
                "name": "Darhan-Uul",
                "pk": "MN__darhan-uul"
            },
            {
                "_id": "dornod",
                "code": "dornod",
                "name": "Dornod",
                "pk": "MN__dornod"
            },
            {
                "_id": "dornogovi",
                "code": "dornogovi",
                "name": "Dornogovi",
                "pk": "MN__dornogovi"
            },
            {
                "_id": "dundgovi",
                "code": "dundgovi",
                "name": "Dundgovi",
                "pk": "MN__dundgovi"
            },
            {
                "_id": "govi-altaj",
                "code": "govi-altaj",
                "name": "Govi-Altaj",
                "pk": "MN__govi-altaj"
            },
            {
                "_id": "govisumber",
                "code": "govisumber",
                "name": "Govisumber",
                "pk": "MN__govisumber"
            },
            {
                "_id": "hentij",
                "code": "hentij",
                "name": "Hentij",
                "pk": "MN__hentij"
            },
            {
                "_id": "hovd",
                "code": "hovd",
                "name": "Hovd",
                "pk": "MN__hovd"
            },
            {
                "_id": "hovsgol",
                "code": "hovsgol",
                "name": "Hovsgol",
                "pk": "MN__hovsgol"
            },
            {
                "_id": "omnogovi",
                "code": "omnogovi",
                "name": "Omnogovi",
                "pk": "MN__omnogovi"
            },
            {
                "_id": "orhon",
                "code": "orhon",
                "name": "Orhon",
                "pk": "MN__orhon"
            },
            {
                "_id": "ovorhangaj",
                "code": "ovorhangaj",
                "name": "Ovorhangaj",
                "pk": "MN__ovorhangaj"
            },
            {
                "_id": "selenge",
                "code": "selenge",
                "name": "Selenge",
                "pk": "MN__selenge"
            },
            {
                "_id": "suhbaatar",
                "code": "suhbaatar",
                "name": "Suhbaatar",
                "pk": "MN__suhbaatar"
            },
            {
                "_id": "tov",
                "code": "tov",
                "name": "Tov",
                "pk": "MN__tov"
            },
            {
                "_id": "ulaanbaatar",
                "code": "ulaanbaatar",
                "name": "Ulaanbaatar",
                "pk": "MN__ulaanbaatar"
            },
            {
                "_id": "uvs",
                "code": "uvs",
                "name": "Uvs",
                "pk": "MN__uvs"
            },
            {
                "_id": "zavhan",
                "code": "zavhan",
                "name": "Zavhan",
                "pk": "MN__zavhan"
            }
        ],
        "pk": "MN"
    },
    {
        "_id": "MS",
        "code": "MS",
        "name": "Montserrat",
        "states": [
            {
                "_id": "montserrat",
                "code": "montserrat",
                "name": "Montserrat",
                "pk": "MS__montserrat"
            }
        ],
        "pk": "MS"
    },
    {
        "_id": "MA",
        "code": "MA",
        "name": "Maroc",
        "states": [
            {
                "_id": "agadir",
                "code": "agadir",
                "name": "Agadir",
                "pk": "MA__agadir"
            },
            {
                "_id": "casablanca",
                "code": "casablanca",
                "name": "Casablanca",
                "pk": "MA__casablanca"
            },
            {
                "_id": "chaouia-ouardigha",
                "code": "chaouia-ouardigha",
                "name": "Chaouia-Ouardigha",
                "pk": "MA__chaouia-ouardigha"
            },
            {
                "_id": "doukkala-abda",
                "code": "doukkala-abda",
                "name": "Doukkala-Abda",
                "pk": "MA__doukkala-abda"
            },
            {
                "_id": "fes-boulemane",
                "code": "fes-boulemane",
                "name": "Fes-Boulemane",
                "pk": "MA__fes-boulemane"
            },
            {
                "_id": "gharb-chrarda-beni_hssen",
                "code": "gharb-chrarda-beni_hssen",
                "name": "Gharb-Chrarda-Beni Hssen",
                "pk": "MA__gharb-chrarda-beni_hssen"
            },
            {
                "_id": "guelmim",
                "code": "guelmim",
                "name": "Guelmim",
                "pk": "MA__guelmim"
            },
            {
                "_id": "kenitra",
                "code": "kenitra",
                "name": "Kenitra",
                "pk": "MA__kenitra"
            },
            {
                "_id": "marrakech-tensift-al_haouz",
                "code": "marrakech-tensift-al_haouz",
                "name": "Marrakech-Tensift-Al Haouz",
                "pk": "MA__marrakech-tensift-al_haouz"
            },
            {
                "_id": "meknes-tafilalet",
                "code": "meknes-tafilalet",
                "name": "Meknes-Tafilalet",
                "pk": "MA__meknes-tafilalet"
            },
            {
                "_id": "oriental",
                "code": "oriental",
                "name": "Oriental",
                "pk": "MA__oriental"
            },
            {
                "_id": "oujda",
                "code": "oujda",
                "name": "Oujda",
                "pk": "MA__oujda"
            },
            {
                "_id": "province_de_tanger",
                "code": "province_de_tanger",
                "name": "Province de Tanger",
                "pk": "MA__province_de_tanger"
            },
            {
                "_id": "rabat-sale-zammour-zaer",
                "code": "rabat-sale-zammour-zaer",
                "name": "Rabat-Sale-Zammour-Zaer",
                "pk": "MA__rabat-sale-zammour-zaer"
            },
            {
                "_id": "sala_al_jadida",
                "code": "sala_al_jadida",
                "name": "Sala Al Jadida",
                "pk": "MA__sala_al_jadida"
            },
            {
                "_id": "settat",
                "code": "settat",
                "name": "Settat",
                "pk": "MA__settat"
            },
            {
                "_id": "souss_massa-draa",
                "code": "souss_massa-draa",
                "name": "Souss Massa-Draa",
                "pk": "MA__souss_massa-draa"
            },
            {
                "_id": "tadla-azilal",
                "code": "tadla-azilal",
                "name": "Tadla-Azilal",
                "pk": "MA__tadla-azilal"
            },
            {
                "_id": "tangier-tetouan",
                "code": "tangier-tetouan",
                "name": "Tangier-Tetouan",
                "pk": "MA__tangier-tetouan"
            },
            {
                "_id": "taza-al_hoceima-taounate",
                "code": "taza-al_hoceima-taounate",
                "name": "Taza-Al Hoceima-Taounate",
                "pk": "MA__taza-al_hoceima-taounate"
            },
            {
                "_id": "wilaya_de_casablanca",
                "code": "wilaya_de_casablanca",
                "name": "Wilaya de Casablanca",
                "pk": "MA__wilaya_de_casablanca"
            },
            {
                "_id": "wilaya_de_rabat-sale",
                "code": "wilaya_de_rabat-sale",
                "name": "Wilaya de Rabat-Sale",
                "pk": "MA__wilaya_de_rabat-sale"
            }
        ],
        "pk": "MA"
    },
    {
        "_id": "MZ",
        "code": "MZ",
        "name": "Mozambique",
        "states": [
            {
                "_id": "cabo_delgado",
                "code": "cabo_delgado",
                "name": "Cabo Delgado",
                "pk": "MZ__cabo_delgado"
            },
            {
                "_id": "gaza",
                "code": "gaza",
                "name": "Gaza",
                "pk": "MZ__gaza"
            },
            {
                "_id": "inhambane",
                "code": "inhambane",
                "name": "Inhambane",
                "pk": "MZ__inhambane"
            },
            {
                "_id": "manica",
                "code": "manica",
                "name": "Manica",
                "pk": "MZ__manica"
            },
            {
                "_id": "maputo",
                "code": "maputo",
                "name": "Maputo",
                "pk": "MZ__maputo"
            },
            {
                "_id": "maputo_provincia",
                "code": "maputo_provincia",
                "name": "Maputo Provincia",
                "pk": "MZ__maputo_provincia"
            },
            {
                "_id": "nampula",
                "code": "nampula",
                "name": "Nampula",
                "pk": "MZ__nampula"
            },
            {
                "_id": "niassa",
                "code": "niassa",
                "name": "Niassa",
                "pk": "MZ__niassa"
            },
            {
                "_id": "sofala",
                "code": "sofala",
                "name": "Sofala",
                "pk": "MZ__sofala"
            },
            {
                "_id": "tete",
                "code": "tete",
                "name": "Tete",
                "pk": "MZ__tete"
            },
            {
                "_id": "zambezia",
                "code": "zambezia",
                "name": "Zambezia",
                "pk": "MZ__zambezia"
            }
        ],
        "pk": "MZ"
    },
    {
        "_id": "MM",
        "code": "MM",
        "name": "Myanmar",
        "states": [
            {
                "_id": "ayeyarwady",
                "code": "ayeyarwady",
                "name": "Ayeyarwady",
                "pk": "MM__ayeyarwady"
            },
            {
                "_id": "bago",
                "code": "bago",
                "name": "Bago",
                "pk": "MM__bago"
            },
            {
                "_id": "chin",
                "code": "chin",
                "name": "Chin",
                "pk": "MM__chin"
            },
            {
                "_id": "kachin",
                "code": "kachin",
                "name": "Kachin",
                "pk": "MM__kachin"
            },
            {
                "_id": "kayah",
                "code": "kayah",
                "name": "Kayah",
                "pk": "MM__kayah"
            },
            {
                "_id": "kayin",
                "code": "kayin",
                "name": "Kayin",
                "pk": "MM__kayin"
            },
            {
                "_id": "magway",
                "code": "magway",
                "name": "Magway",
                "pk": "MM__magway"
            },
            {
                "_id": "mandalay",
                "code": "mandalay",
                "name": "Mandalay",
                "pk": "MM__mandalay"
            },
            {
                "_id": "mon",
                "code": "mon",
                "name": "Mon",
                "pk": "MM__mon"
            },
            {
                "_id": "nay_pyi_taw",
                "code": "nay_pyi_taw",
                "name": "Nay Pyi Taw",
                "pk": "MM__nay_pyi_taw"
            },
            {
                "_id": "rakhine",
                "code": "rakhine",
                "name": "Rakhine",
                "pk": "MM__rakhine"
            },
            {
                "_id": "sagaing",
                "code": "sagaing",
                "name": "Sagaing",
                "pk": "MM__sagaing"
            },
            {
                "_id": "shan",
                "code": "shan",
                "name": "Shan",
                "pk": "MM__shan"
            },
            {
                "_id": "tanintharyi",
                "code": "tanintharyi",
                "name": "Tanintharyi",
                "pk": "MM__tanintharyi"
            },
            {
                "_id": "yangon",
                "code": "yangon",
                "name": "Yangon",
                "pk": "MM__yangon"
            }
        ],
        "pk": "MM"
    },
    {
        "_id": "NA",
        "code": "NA",
        "name": "Namibie",
        "states": [
            {
                "_id": "caprivi",
                "code": "caprivi",
                "name": "Caprivi",
                "pk": "NA__caprivi"
            },
            {
                "_id": "erongo",
                "code": "erongo",
                "name": "Erongo",
                "pk": "NA__erongo"
            },
            {
                "_id": "hardap",
                "code": "hardap",
                "name": "Hardap",
                "pk": "NA__hardap"
            },
            {
                "_id": "karas",
                "code": "karas",
                "name": "Karas",
                "pk": "NA__karas"
            },
            {
                "_id": "kavango",
                "code": "kavango",
                "name": "Kavango",
                "pk": "NA__kavango"
            },
            {
                "_id": "khomas",
                "code": "khomas",
                "name": "Khomas",
                "pk": "NA__khomas"
            },
            {
                "_id": "kunene",
                "code": "kunene",
                "name": "Kunene",
                "pk": "NA__kunene"
            },
            {
                "_id": "ohangwena",
                "code": "ohangwena",
                "name": "Ohangwena",
                "pk": "NA__ohangwena"
            },
            {
                "_id": "omaheke",
                "code": "omaheke",
                "name": "Omaheke",
                "pk": "NA__omaheke"
            },
            {
                "_id": "omusati",
                "code": "omusati",
                "name": "Omusati",
                "pk": "NA__omusati"
            },
            {
                "_id": "oshana",
                "code": "oshana",
                "name": "Oshana",
                "pk": "NA__oshana"
            },
            {
                "_id": "oshikoto",
                "code": "oshikoto",
                "name": "Oshikoto",
                "pk": "NA__oshikoto"
            },
            {
                "_id": "otjozondjupa",
                "code": "otjozondjupa",
                "name": "Otjozondjupa",
                "pk": "NA__otjozondjupa"
            }
        ],
        "pk": "NA"
    },
    {
        "_id": "NR",
        "code": "NR",
        "name": "Nauru",
        "states": [
            {
                "_id": "yaren",
                "code": "yaren",
                "name": "Yaren",
                "pk": "NR__yaren"
            }
        ],
        "pk": "NR"
    },
    {
        "_id": "NP",
        "code": "NP",
        "name": "Népal",
        "states": [
            {
                "_id": "bagmati",
                "code": "bagmati",
                "name": "Bagmati",
                "pk": "NP__bagmati"
            },
            {
                "_id": "bheri",
                "code": "bheri",
                "name": "Bheri",
                "pk": "NP__bheri"
            },
            {
                "_id": "dhawalagiri",
                "code": "dhawalagiri",
                "name": "Dhawalagiri",
                "pk": "NP__dhawalagiri"
            },
            {
                "_id": "gandaki",
                "code": "gandaki",
                "name": "Gandaki",
                "pk": "NP__gandaki"
            },
            {
                "_id": "janakpur",
                "code": "janakpur",
                "name": "Janakpur",
                "pk": "NP__janakpur"
            },
            {
                "_id": "karnali",
                "code": "karnali",
                "name": "Karnali",
                "pk": "NP__karnali"
            },
            {
                "_id": "koshi",
                "code": "koshi",
                "name": "Koshi",
                "pk": "NP__koshi"
            },
            {
                "_id": "lumbini",
                "code": "lumbini",
                "name": "Lumbini",
                "pk": "NP__lumbini"
            },
            {
                "_id": "mahakali",
                "code": "mahakali",
                "name": "Mahakali",
                "pk": "NP__mahakali"
            },
            {
                "_id": "mechi",
                "code": "mechi",
                "name": "Mechi",
                "pk": "NP__mechi"
            },
            {
                "_id": "narayani",
                "code": "narayani",
                "name": "Narayani",
                "pk": "NP__narayani"
            },
            {
                "_id": "rapti",
                "code": "rapti",
                "name": "Rapti",
                "pk": "NP__rapti"
            },
            {
                "_id": "sagarmatha",
                "code": "sagarmatha",
                "name": "Sagarmatha",
                "pk": "NP__sagarmatha"
            },
            {
                "_id": "seti",
                "code": "seti",
                "name": "Seti",
                "pk": "NP__seti"
            }
        ],
        "pk": "NP"
    },
    {
        "_id": "AN",
        "code": "AN",
        "name": "Antilles néerlandaises",
        "states": [
            {
                "_id": "bonaire",
                "code": "bonaire",
                "name": "Bonaire",
                "pk": "AN__bonaire"
            },
            {
                "_id": "curacao",
                "code": "curacao",
                "name": "Curacao",
                "pk": "AN__curacao"
            },
            {
                "_id": "saba",
                "code": "saba",
                "name": "Saba",
                "pk": "AN__saba"
            },
            {
                "_id": "sint_eustatius",
                "code": "sint_eustatius",
                "name": "Sint Eustatius",
                "pk": "AN__sint_eustatius"
            },
            {
                "_id": "sint_maarten",
                "code": "sint_maarten",
                "name": "Sint Maarten",
                "pk": "AN__sint_maarten"
            }
        ],
        "pk": "AN"
    },
    {
        "_id": "NL",
        "code": "NL",
        "name": "Pays-Bas",
        "states": [
            {
                "_id": "amsterdam",
                "code": "amsterdam",
                "name": "Amsterdam",
                "pk": "NL__amsterdam"
            },
            {
                "_id": "benelux",
                "code": "benelux",
                "name": "Benelux",
                "pk": "NL__benelux"
            },
            {
                "_id": "drenthe",
                "code": "drenthe",
                "name": "Drenthe",
                "pk": "NL__drenthe"
            },
            {
                "_id": "flevoland",
                "code": "flevoland",
                "name": "Flevoland",
                "pk": "NL__flevoland"
            },
            {
                "_id": "friesland",
                "code": "friesland",
                "name": "Friesland",
                "pk": "NL__friesland"
            },
            {
                "_id": "gelderland",
                "code": "gelderland",
                "name": "Gelderland",
                "pk": "NL__gelderland"
            },
            {
                "_id": "groningen",
                "code": "groningen",
                "name": "Groningen",
                "pk": "NL__groningen"
            },
            {
                "_id": "limburg",
                "code": "limburg",
                "name": "Limburg",
                "pk": "NL__limburg"
            },
            {
                "_id": "noord-brabant",
                "code": "noord-brabant",
                "name": "Noord-Brabant",
                "pk": "NL__noord-brabant"
            },
            {
                "_id": "noord-holland",
                "code": "noord-holland",
                "name": "Noord-Holland",
                "pk": "NL__noord-holland"
            },
            {
                "_id": "overijssel",
                "code": "overijssel",
                "name": "Overijssel",
                "pk": "NL__overijssel"
            },
            {
                "_id": "south_holland",
                "code": "south_holland",
                "name": "South Holland",
                "pk": "NL__south_holland"
            },
            {
                "_id": "utrecht",
                "code": "utrecht",
                "name": "Utrecht",
                "pk": "NL__utrecht"
            },
            {
                "_id": "zeeland",
                "code": "zeeland",
                "name": "Zeeland",
                "pk": "NL__zeeland"
            },
            {
                "_id": "zuid-holland",
                "code": "zuid-holland",
                "name": "Zuid-Holland",
                "pk": "NL__zuid-holland"
            }
        ],
        "pk": "NL"
    },
    {
        "_id": "NC",
        "code": "NC",
        "name": "Nouvelle-Calédonie",
        "states": [
            {
                "_id": "iles",
                "code": "iles",
                "name": "Iles",
                "pk": "NC__iles"
            },
            {
                "_id": "nord",
                "code": "nord",
                "name": "Nord",
                "pk": "NC__nord"
            },
            {
                "_id": "sud",
                "code": "sud",
                "name": "Sud",
                "pk": "NC__sud"
            }
        ],
        "pk": "NC"
    },
    {
        "_id": "NZ",
        "code": "NZ",
        "name": "Nouvelle-Zélande",
        "states": [
            {
                "_id": "area_outside_region",
                "code": "area_outside_region",
                "name": "Area Outside Region",
                "pk": "NZ__area_outside_region"
            },
            {
                "_id": "auckland",
                "code": "auckland",
                "name": "Auckland",
                "pk": "NZ__auckland"
            },
            {
                "_id": "bay_of_plenty",
                "code": "bay_of_plenty",
                "name": "Bay of Plenty",
                "pk": "NZ__bay_of_plenty"
            },
            {
                "_id": "canterbury",
                "code": "canterbury",
                "name": "Canterbury",
                "pk": "NZ__canterbury"
            },
            {
                "_id": "christchurch",
                "code": "christchurch",
                "name": "Christchurch",
                "pk": "NZ__christchurch"
            },
            {
                "_id": "gisborne",
                "code": "gisborne",
                "name": "Gisborne",
                "pk": "NZ__gisborne"
            },
            {
                "_id": "hawkes_bay",
                "code": "hawkes_bay",
                "name": "Hawkes Bay",
                "pk": "NZ__hawkes_bay"
            },
            {
                "_id": "manawatu-wanganui",
                "code": "manawatu-wanganui",
                "name": "Manawatu-Wanganui",
                "pk": "NZ__manawatu-wanganui"
            },
            {
                "_id": "marlborough",
                "code": "marlborough",
                "name": "Marlborough",
                "pk": "NZ__marlborough"
            },
            {
                "_id": "nelson",
                "code": "nelson",
                "name": "Nelson",
                "pk": "NZ__nelson"
            },
            {
                "_id": "northland",
                "code": "northland",
                "name": "Northland",
                "pk": "NZ__northland"
            },
            {
                "_id": "otago",
                "code": "otago",
                "name": "Otago",
                "pk": "NZ__otago"
            },
            {
                "_id": "rodney",
                "code": "rodney",
                "name": "Rodney",
                "pk": "NZ__rodney"
            },
            {
                "_id": "southland",
                "code": "southland",
                "name": "Southland",
                "pk": "NZ__southland"
            },
            {
                "_id": "taranaki",
                "code": "taranaki",
                "name": "Taranaki",
                "pk": "NZ__taranaki"
            },
            {
                "_id": "tasman",
                "code": "tasman",
                "name": "Tasman",
                "pk": "NZ__tasman"
            },
            {
                "_id": "waikato",
                "code": "waikato",
                "name": "Waikato",
                "pk": "NZ__waikato"
            },
            {
                "_id": "wellington",
                "code": "wellington",
                "name": "Wellington",
                "pk": "NZ__wellington"
            },
            {
                "_id": "west_coast",
                "code": "west_coast",
                "name": "West Coast",
                "pk": "NZ__west_coast"
            }
        ],
        "pk": "NZ"
    },
    {
        "_id": "NI",
        "code": "NI",
        "name": "Nicaragua",
        "states": [
            {
                "_id": "atlantico_norte",
                "code": "atlantico_norte",
                "name": "Atlantico Norte",
                "pk": "NI__atlantico_norte"
            },
            {
                "_id": "atlantico_sur",
                "code": "atlantico_sur",
                "name": "Atlantico Sur",
                "pk": "NI__atlantico_sur"
            },
            {
                "_id": "boaco",
                "code": "boaco",
                "name": "Boaco",
                "pk": "NI__boaco"
            },
            {
                "_id": "carazo",
                "code": "carazo",
                "name": "Carazo",
                "pk": "NI__carazo"
            },
            {
                "_id": "chinandega",
                "code": "chinandega",
                "name": "Chinandega",
                "pk": "NI__chinandega"
            },
            {
                "_id": "chontales",
                "code": "chontales",
                "name": "Chontales",
                "pk": "NI__chontales"
            },
            {
                "_id": "esteli",
                "code": "esteli",
                "name": "Esteli",
                "pk": "NI__esteli"
            },
            {
                "_id": "granada",
                "code": "granada",
                "name": "Granada",
                "pk": "NI__granada"
            },
            {
                "_id": "jinotega",
                "code": "jinotega",
                "name": "Jinotega",
                "pk": "NI__jinotega"
            },
            {
                "_id": "leon",
                "code": "leon",
                "name": "Leon",
                "pk": "NI__leon"
            },
            {
                "_id": "madriz",
                "code": "madriz",
                "name": "Madriz",
                "pk": "NI__madriz"
            },
            {
                "_id": "managua",
                "code": "managua",
                "name": "Managua",
                "pk": "NI__managua"
            },
            {
                "_id": "masaya",
                "code": "masaya",
                "name": "Masaya",
                "pk": "NI__masaya"
            },
            {
                "_id": "matagalpa",
                "code": "matagalpa",
                "name": "Matagalpa",
                "pk": "NI__matagalpa"
            },
            {
                "_id": "nueva_segovia",
                "code": "nueva_segovia",
                "name": "Nueva Segovia",
                "pk": "NI__nueva_segovia"
            },
            {
                "_id": "rio_san_juan",
                "code": "rio_san_juan",
                "name": "Rio San Juan",
                "pk": "NI__rio_san_juan"
            },
            {
                "_id": "rivas",
                "code": "rivas",
                "name": "Rivas",
                "pk": "NI__rivas"
            }
        ],
        "pk": "NI"
    },
    {
        "_id": "NE",
        "code": "NE",
        "name": "Niger",
        "states": [
            {
                "_id": "agadez",
                "code": "agadez",
                "name": "Agadez",
                "pk": "NE__agadez"
            },
            {
                "_id": "diffa",
                "code": "diffa",
                "name": "Diffa",
                "pk": "NE__diffa"
            },
            {
                "_id": "dosso",
                "code": "dosso",
                "name": "Dosso",
                "pk": "NE__dosso"
            },
            {
                "_id": "maradi",
                "code": "maradi",
                "name": "Maradi",
                "pk": "NE__maradi"
            },
            {
                "_id": "niamey",
                "code": "niamey",
                "name": "Niamey",
                "pk": "NE__niamey"
            },
            {
                "_id": "tahoua",
                "code": "tahoua",
                "name": "Tahoua",
                "pk": "NE__tahoua"
            },
            {
                "_id": "tillabery",
                "code": "tillabery",
                "name": "Tillabery",
                "pk": "NE__tillabery"
            },
            {
                "_id": "zinder",
                "code": "zinder",
                "name": "Zinder",
                "pk": "NE__zinder"
            }
        ],
        "pk": "NE"
    },
    {
        "_id": "NG",
        "code": "NG",
        "name": "Nigéria",
        "states": [
            {
                "_id": "abia",
                "code": "abia",
                "name": "Abia",
                "pk": "NG__abia"
            },
            {
                "_id": "abuja_federal_capital_territor",
                "code": "abuja_federal_capital_territor",
                "name": "Abuja Federal Capital Territor",
                "pk": "NG__abuja_federal_capital_territor"
            },
            {
                "_id": "adamawa",
                "code": "adamawa",
                "name": "Adamawa",
                "pk": "NG__adamawa"
            },
            {
                "_id": "akwa_ibom",
                "code": "akwa_ibom",
                "name": "Akwa Ibom",
                "pk": "NG__akwa_ibom"
            },
            {
                "_id": "anambra",
                "code": "anambra",
                "name": "Anambra",
                "pk": "NG__anambra"
            },
            {
                "_id": "bauchi",
                "code": "bauchi",
                "name": "Bauchi",
                "pk": "NG__bauchi"
            },
            {
                "_id": "bayelsa",
                "code": "bayelsa",
                "name": "Bayelsa",
                "pk": "NG__bayelsa"
            },
            {
                "_id": "benue",
                "code": "benue",
                "name": "Benue",
                "pk": "NG__benue"
            },
            {
                "_id": "borno",
                "code": "borno",
                "name": "Borno",
                "pk": "NG__borno"
            },
            {
                "_id": "cross_river",
                "code": "cross_river",
                "name": "Cross River",
                "pk": "NG__cross_river"
            },
            {
                "_id": "delta",
                "code": "delta",
                "name": "Delta",
                "pk": "NG__delta"
            },
            {
                "_id": "ebonyi",
                "code": "ebonyi",
                "name": "Ebonyi",
                "pk": "NG__ebonyi"
            },
            {
                "_id": "edo",
                "code": "edo",
                "name": "Edo",
                "pk": "NG__edo"
            },
            {
                "_id": "ekiti",
                "code": "ekiti",
                "name": "Ekiti",
                "pk": "NG__ekiti"
            },
            {
                "_id": "enugu",
                "code": "enugu",
                "name": "Enugu",
                "pk": "NG__enugu"
            },
            {
                "_id": "gombe",
                "code": "gombe",
                "name": "Gombe",
                "pk": "NG__gombe"
            },
            {
                "_id": "imo",
                "code": "imo",
                "name": "Imo",
                "pk": "NG__imo"
            },
            {
                "_id": "jigawa",
                "code": "jigawa",
                "name": "Jigawa",
                "pk": "NG__jigawa"
            },
            {
                "_id": "kaduna",
                "code": "kaduna",
                "name": "Kaduna",
                "pk": "NG__kaduna"
            },
            {
                "_id": "kano",
                "code": "kano",
                "name": "Kano",
                "pk": "NG__kano"
            },
            {
                "_id": "katsina",
                "code": "katsina",
                "name": "Katsina",
                "pk": "NG__katsina"
            },
            {
                "_id": "kebbi",
                "code": "kebbi",
                "name": "Kebbi",
                "pk": "NG__kebbi"
            },
            {
                "_id": "kogi",
                "code": "kogi",
                "name": "Kogi",
                "pk": "NG__kogi"
            },
            {
                "_id": "kwara",
                "code": "kwara",
                "name": "Kwara",
                "pk": "NG__kwara"
            },
            {
                "_id": "lagos",
                "code": "lagos",
                "name": "Lagos",
                "pk": "NG__lagos"
            },
            {
                "_id": "nassarawa",
                "code": "nassarawa",
                "name": "Nassarawa",
                "pk": "NG__nassarawa"
            },
            {
                "_id": "niger",
                "code": "niger",
                "name": "Niger",
                "pk": "NG__niger"
            },
            {
                "_id": "ogun",
                "code": "ogun",
                "name": "Ogun",
                "pk": "NG__ogun"
            },
            {
                "_id": "ondo",
                "code": "ondo",
                "name": "Ondo",
                "pk": "NG__ondo"
            },
            {
                "_id": "osun",
                "code": "osun",
                "name": "Osun",
                "pk": "NG__osun"
            },
            {
                "_id": "oyo",
                "code": "oyo",
                "name": "Oyo",
                "pk": "NG__oyo"
            },
            {
                "_id": "plateau",
                "code": "plateau",
                "name": "Plateau",
                "pk": "NG__plateau"
            },
            {
                "_id": "rivers",
                "code": "rivers",
                "name": "Rivers",
                "pk": "NG__rivers"
            },
            {
                "_id": "sokoto",
                "code": "sokoto",
                "name": "Sokoto",
                "pk": "NG__sokoto"
            },
            {
                "_id": "taraba",
                "code": "taraba",
                "name": "Taraba",
                "pk": "NG__taraba"
            },
            {
                "_id": "yobe",
                "code": "yobe",
                "name": "Yobe",
                "pk": "NG__yobe"
            },
            {
                "_id": "zamfara",
                "code": "zamfara",
                "name": "Zamfara",
                "pk": "NG__zamfara"
            }
        ],
        "pk": "NG"
    },
    {
        "_id": "NU",
        "code": "NU",
        "name": "Niue",
        "states": [
            {
                "_id": "niue",
                "code": "niue",
                "name": "Niue",
                "pk": "NU__niue"
            }
        ],
        "pk": "NU"
    },
    {
        "_id": "NF",
        "code": "NF",
        "name": "Île Norfolk",
        "states": [
            {
                "_id": "norfolk_island",
                "code": "norfolk_island",
                "name": "Norfolk Island",
                "pk": "NF__norfolk_island"
            }
        ],
        "pk": "NF"
    },
    {
        "_id": "MP",
        "code": "MP",
        "name": "Îles Mariannes du Nord",
        "states": [
            {
                "_id": "northern_islands",
                "code": "northern_islands",
                "name": "Northern Islands",
                "pk": "MP__northern_islands"
            },
            {
                "_id": "rota",
                "code": "rota",
                "name": "Rota",
                "pk": "MP__rota"
            },
            {
                "_id": "saipan",
                "code": "saipan",
                "name": "Saipan",
                "pk": "MP__saipan"
            },
            {
                "_id": "tinian",
                "code": "tinian",
                "name": "Tinian",
                "pk": "MP__tinian"
            }
        ],
        "pk": "MP"
    },
    {
        "_id": "NO",
        "code": "NO",
        "name": "Norvège",
        "states": [
            {
                "_id": "akershus",
                "code": "akershus",
                "name": "Akershus",
                "pk": "NO__akershus"
            },
            {
                "_id": "aust_agder",
                "code": "aust_agder",
                "name": "Aust Agder",
                "pk": "NO__aust_agder"
            },
            {
                "_id": "bergen",
                "code": "bergen",
                "name": "Bergen",
                "pk": "NO__bergen"
            },
            {
                "_id": "buskerud",
                "code": "buskerud",
                "name": "Buskerud",
                "pk": "NO__buskerud"
            },
            {
                "_id": "finnmark",
                "code": "finnmark",
                "name": "Finnmark",
                "pk": "NO__finnmark"
            },
            {
                "_id": "hedmark",
                "code": "hedmark",
                "name": "Hedmark",
                "pk": "NO__hedmark"
            },
            {
                "_id": "hordaland",
                "code": "hordaland",
                "name": "Hordaland",
                "pk": "NO__hordaland"
            },
            {
                "_id": "moere_og_romsdal",
                "code": "moere_og_romsdal",
                "name": "Moere og Romsdal",
                "pk": "NO__moere_og_romsdal"
            },
            {
                "_id": "nord_trondelag",
                "code": "nord_trondelag",
                "name": "Nord Trondelag",
                "pk": "NO__nord_trondelag"
            },
            {
                "_id": "nordland",
                "code": "nordland",
                "name": "Nordland",
                "pk": "NO__nordland"
            },
            {
                "_id": "oestfold",
                "code": "oestfold",
                "name": "Oestfold",
                "pk": "NO__oestfold"
            },
            {
                "_id": "oppland",
                "code": "oppland",
                "name": "Oppland",
                "pk": "NO__oppland"
            },
            {
                "_id": "oslo",
                "code": "oslo",
                "name": "Oslo",
                "pk": "NO__oslo"
            },
            {
                "_id": "rogaland",
                "code": "rogaland",
                "name": "Rogaland",
                "pk": "NO__rogaland"
            },
            {
                "_id": "soer_troendelag",
                "code": "soer_troendelag",
                "name": "Soer Troendelag",
                "pk": "NO__soer_troendelag"
            },
            {
                "_id": "sogn_og_fjordane",
                "code": "sogn_og_fjordane",
                "name": "Sogn og Fjordane",
                "pk": "NO__sogn_og_fjordane"
            },
            {
                "_id": "stavern",
                "code": "stavern",
                "name": "Stavern",
                "pk": "NO__stavern"
            },
            {
                "_id": "sykkylven",
                "code": "sykkylven",
                "name": "Sykkylven",
                "pk": "NO__sykkylven"
            },
            {
                "_id": "telemark",
                "code": "telemark",
                "name": "Telemark",
                "pk": "NO__telemark"
            },
            {
                "_id": "troms",
                "code": "troms",
                "name": "Troms",
                "pk": "NO__troms"
            },
            {
                "_id": "vest_agder",
                "code": "vest_agder",
                "name": "Vest Agder",
                "pk": "NO__vest_agder"
            },
            {
                "_id": "vestfold",
                "code": "vestfold",
                "name": "Vestfold",
                "pk": "NO__vestfold"
            },
            {
                "_id": "ãƒâ˜stfold",
                "code": "ãƒâ˜stfold",
                "name": "ÃƒÂ˜stfold",
                "pk": "NO__ãƒâ˜stfold"
            }
        ],
        "pk": "NO"
    },
    {
        "_id": "OM",
        "code": "OM",
        "name": "Oman",
        "states": [
            {
                "_id": "al_buraimi",
                "code": "al_buraimi",
                "name": "Al Buraimi",
                "pk": "OM__al_buraimi"
            },
            {
                "_id": "dhufar",
                "code": "dhufar",
                "name": "Dhufar",
                "pk": "OM__dhufar"
            },
            {
                "_id": "masqat",
                "code": "masqat",
                "name": "Masqat",
                "pk": "OM__masqat"
            },
            {
                "_id": "musandam",
                "code": "musandam",
                "name": "Musandam",
                "pk": "OM__musandam"
            },
            {
                "_id": "rusayl",
                "code": "rusayl",
                "name": "Rusayl",
                "pk": "OM__rusayl"
            },
            {
                "_id": "wadi_kabir",
                "code": "wadi_kabir",
                "name": "Wadi Kabir",
                "pk": "OM__wadi_kabir"
            },
            {
                "_id": "ad-dakhiliyah",
                "code": "ad-dakhiliyah",
                "name": "ad-Dakhiliyah",
                "pk": "OM__ad-dakhiliyah"
            },
            {
                "_id": "adh-dhahirah",
                "code": "adh-dhahirah",
                "name": "adh-Dhahirah",
                "pk": "OM__adh-dhahirah"
            },
            {
                "_id": "al-batinah",
                "code": "al-batinah",
                "name": "al-Batinah",
                "pk": "OM__al-batinah"
            },
            {
                "_id": "ash-sharqiyah",
                "code": "ash-sharqiyah",
                "name": "ash-Sharqiyah",
                "pk": "OM__ash-sharqiyah"
            }
        ],
        "pk": "OM"
    },
    {
        "_id": "PK",
        "code": "PK",
        "name": "Pakistan",
        "states": [
            {
                "_id": "baluchistan",
                "code": "baluchistan",
                "name": "Baluchistan",
                "pk": "PK__baluchistan"
            },
            {
                "_id": "federal_capital_area",
                "code": "federal_capital_area",
                "name": "Federal Capital Area",
                "pk": "PK__federal_capital_area"
            },
            {
                "_id": "federally_administered_tribal_",
                "code": "federally_administered_tribal_",
                "name": "Federally administered Tribal ",
                "pk": "PK__federally_administered_tribal_"
            },
            {
                "_id": "north-west_frontier",
                "code": "north-west_frontier",
                "name": "North-West Frontier",
                "pk": "PK__north-west_frontier"
            },
            {
                "_id": "northern_areas",
                "code": "northern_areas",
                "name": "Northern Areas",
                "pk": "PK__northern_areas"
            },
            {
                "_id": "punjab",
                "code": "punjab",
                "name": "Punjab",
                "pk": "PK__punjab"
            },
            {
                "_id": "sind",
                "code": "sind",
                "name": "Sind",
                "pk": "PK__sind"
            }
        ],
        "pk": "PK"
    },
    {
        "_id": "PW",
        "code": "PW",
        "name": "Palau",
        "states": [
            {
                "_id": "aimeliik",
                "code": "aimeliik",
                "name": "Aimeliik",
                "pk": "PW__aimeliik"
            },
            {
                "_id": "airai",
                "code": "airai",
                "name": "Airai",
                "pk": "PW__airai"
            },
            {
                "_id": "angaur",
                "code": "angaur",
                "name": "Angaur",
                "pk": "PW__angaur"
            },
            {
                "_id": "hatobohei",
                "code": "hatobohei",
                "name": "Hatobohei",
                "pk": "PW__hatobohei"
            },
            {
                "_id": "kayangel",
                "code": "kayangel",
                "name": "Kayangel",
                "pk": "PW__kayangel"
            },
            {
                "_id": "koror",
                "code": "koror",
                "name": "Koror",
                "pk": "PW__koror"
            },
            {
                "_id": "melekeok",
                "code": "melekeok",
                "name": "Melekeok",
                "pk": "PW__melekeok"
            },
            {
                "_id": "ngaraard",
                "code": "ngaraard",
                "name": "Ngaraard",
                "pk": "PW__ngaraard"
            },
            {
                "_id": "ngardmau",
                "code": "ngardmau",
                "name": "Ngardmau",
                "pk": "PW__ngardmau"
            },
            {
                "_id": "ngaremlengui",
                "code": "ngaremlengui",
                "name": "Ngaremlengui",
                "pk": "PW__ngaremlengui"
            },
            {
                "_id": "ngatpang",
                "code": "ngatpang",
                "name": "Ngatpang",
                "pk": "PW__ngatpang"
            },
            {
                "_id": "ngchesar",
                "code": "ngchesar",
                "name": "Ngchesar",
                "pk": "PW__ngchesar"
            },
            {
                "_id": "ngerchelong",
                "code": "ngerchelong",
                "name": "Ngerchelong",
                "pk": "PW__ngerchelong"
            },
            {
                "_id": "ngiwal",
                "code": "ngiwal",
                "name": "Ngiwal",
                "pk": "PW__ngiwal"
            },
            {
                "_id": "peleliu",
                "code": "peleliu",
                "name": "Peleliu",
                "pk": "PW__peleliu"
            },
            {
                "_id": "sonsorol",
                "code": "sonsorol",
                "name": "Sonsorol",
                "pk": "PW__sonsorol"
            }
        ],
        "pk": "PW"
    },
    {
        "_id": "PS",
        "code": "PS",
        "name": "Territoire palestinien occupé",
        "states": [
            {
                "_id": "ariha",
                "code": "ariha",
                "name": "Ariha",
                "pk": "PS__ariha"
            },
            {
                "_id": "bayt_lahm",
                "code": "bayt_lahm",
                "name": "Bayt Lahm",
                "pk": "PS__bayt_lahm"
            },
            {
                "_id": "bethlehem",
                "code": "bethlehem",
                "name": "Bethlehem",
                "pk": "PS__bethlehem"
            },
            {
                "_id": "dayr-al-balah",
                "code": "dayr-al-balah",
                "name": "Dayr-al-Balah",
                "pk": "PS__dayr-al-balah"
            },
            {
                "_id": "ghazzah",
                "code": "ghazzah",
                "name": "Ghazzah",
                "pk": "PS__ghazzah"
            },
            {
                "_id": "ghazzah_ash-shamaliyah",
                "code": "ghazzah_ash-shamaliyah",
                "name": "Ghazzah ash-Shamaliyah",
                "pk": "PS__ghazzah_ash-shamaliyah"
            },
            {
                "_id": "janin",
                "code": "janin",
                "name": "Janin",
                "pk": "PS__janin"
            },
            {
                "_id": "khan_yunis",
                "code": "khan_yunis",
                "name": "Khan Yunis",
                "pk": "PS__khan_yunis"
            },
            {
                "_id": "nabulus",
                "code": "nabulus",
                "name": "Nabulus",
                "pk": "PS__nabulus"
            },
            {
                "_id": "qalqilyah",
                "code": "qalqilyah",
                "name": "Qalqilyah",
                "pk": "PS__qalqilyah"
            },
            {
                "_id": "rafah",
                "code": "rafah",
                "name": "Rafah",
                "pk": "PS__rafah"
            },
            {
                "_id": "ram_allah_wal-birah",
                "code": "ram_allah_wal-birah",
                "name": "Ram Allah wal-Birah",
                "pk": "PS__ram_allah_wal-birah"
            },
            {
                "_id": "salfit",
                "code": "salfit",
                "name": "Salfit",
                "pk": "PS__salfit"
            },
            {
                "_id": "tubas",
                "code": "tubas",
                "name": "Tubas",
                "pk": "PS__tubas"
            },
            {
                "_id": "tulkarm",
                "code": "tulkarm",
                "name": "Tulkarm",
                "pk": "PS__tulkarm"
            },
            {
                "_id": "al-khalil",
                "code": "al-khalil",
                "name": "al-Khalil",
                "pk": "PS__al-khalil"
            },
            {
                "_id": "al-quds",
                "code": "al-quds",
                "name": "al-Quds",
                "pk": "PS__al-quds"
            }
        ],
        "pk": "PS"
    },
    {
        "_id": "PA",
        "code": "PA",
        "name": "Panama",
        "states": [
            {
                "_id": "bocas_del_toro",
                "code": "bocas_del_toro",
                "name": "Bocas del Toro",
                "pk": "PA__bocas_del_toro"
            },
            {
                "_id": "chiriqui",
                "code": "chiriqui",
                "name": "Chiriqui",
                "pk": "PA__chiriqui"
            },
            {
                "_id": "cocle",
                "code": "cocle",
                "name": "Cocle",
                "pk": "PA__cocle"
            },
            {
                "_id": "colon",
                "code": "colon",
                "name": "Colon",
                "pk": "PA__colon"
            },
            {
                "_id": "darien",
                "code": "darien",
                "name": "Darien",
                "pk": "PA__darien"
            },
            {
                "_id": "embera",
                "code": "embera",
                "name": "Embera",
                "pk": "PA__embera"
            },
            {
                "_id": "herrera",
                "code": "herrera",
                "name": "Herrera",
                "pk": "PA__herrera"
            },
            {
                "_id": "kuna_yala",
                "code": "kuna_yala",
                "name": "Kuna Yala",
                "pk": "PA__kuna_yala"
            },
            {
                "_id": "los_santos",
                "code": "los_santos",
                "name": "Los Santos",
                "pk": "PA__los_santos"
            },
            {
                "_id": "ngobe_bugle",
                "code": "ngobe_bugle",
                "name": "Ngobe Bugle",
                "pk": "PA__ngobe_bugle"
            },
            {
                "_id": "panama",
                "code": "panama",
                "name": "Panama",
                "pk": "PA__panama"
            },
            {
                "_id": "veraguas",
                "code": "veraguas",
                "name": "Veraguas",
                "pk": "PA__veraguas"
            }
        ],
        "pk": "PA"
    },
    {
        "_id": "PG",
        "code": "PG",
        "name": "Papouasie-Nouvelle-Guinée",
        "states": [
            {
                "_id": "east_new_britain",
                "code": "east_new_britain",
                "name": "East New Britain",
                "pk": "PG__east_new_britain"
            },
            {
                "_id": "east_sepik",
                "code": "east_sepik",
                "name": "East Sepik",
                "pk": "PG__east_sepik"
            },
            {
                "_id": "eastern_highlands",
                "code": "eastern_highlands",
                "name": "Eastern Highlands",
                "pk": "PG__eastern_highlands"
            },
            {
                "_id": "enga",
                "code": "enga",
                "name": "Enga",
                "pk": "PG__enga"
            },
            {
                "_id": "fly_river",
                "code": "fly_river",
                "name": "Fly River",
                "pk": "PG__fly_river"
            },
            {
                "_id": "gulf",
                "code": "gulf",
                "name": "Gulf",
                "pk": "PG__gulf"
            },
            {
                "_id": "madang",
                "code": "madang",
                "name": "Madang",
                "pk": "PG__madang"
            },
            {
                "_id": "manus",
                "code": "manus",
                "name": "Manus",
                "pk": "PG__manus"
            },
            {
                "_id": "milne_bay",
                "code": "milne_bay",
                "name": "Milne Bay",
                "pk": "PG__milne_bay"
            },
            {
                "_id": "morobe",
                "code": "morobe",
                "name": "Morobe",
                "pk": "PG__morobe"
            },
            {
                "_id": "national_capital_district",
                "code": "national_capital_district",
                "name": "National Capital District",
                "pk": "PG__national_capital_district"
            },
            {
                "_id": "new_ireland",
                "code": "new_ireland",
                "name": "New Ireland",
                "pk": "PG__new_ireland"
            },
            {
                "_id": "north_solomons",
                "code": "north_solomons",
                "name": "North Solomons",
                "pk": "PG__north_solomons"
            },
            {
                "_id": "oro",
                "code": "oro",
                "name": "Oro",
                "pk": "PG__oro"
            },
            {
                "_id": "sandaun",
                "code": "sandaun",
                "name": "Sandaun",
                "pk": "PG__sandaun"
            },
            {
                "_id": "simbu",
                "code": "simbu",
                "name": "Simbu",
                "pk": "PG__simbu"
            },
            {
                "_id": "southern_highlands",
                "code": "southern_highlands",
                "name": "Southern Highlands",
                "pk": "PG__southern_highlands"
            },
            {
                "_id": "west_new_britain",
                "code": "west_new_britain",
                "name": "West New Britain",
                "pk": "PG__west_new_britain"
            },
            {
                "_id": "western_highlands",
                "code": "western_highlands",
                "name": "Western Highlands",
                "pk": "PG__western_highlands"
            }
        ],
        "pk": "PG"
    },
    {
        "_id": "PY",
        "code": "PY",
        "name": "Paraguay",
        "states": [
            {
                "_id": "alto_paraguay",
                "code": "alto_paraguay",
                "name": "Alto Paraguay",
                "pk": "PY__alto_paraguay"
            },
            {
                "_id": "alto_parana",
                "code": "alto_parana",
                "name": "Alto Parana",
                "pk": "PY__alto_parana"
            },
            {
                "_id": "amambay",
                "code": "amambay",
                "name": "Amambay",
                "pk": "PY__amambay"
            },
            {
                "_id": "asuncion",
                "code": "asuncion",
                "name": "Asuncion",
                "pk": "PY__asuncion"
            },
            {
                "_id": "boqueron",
                "code": "boqueron",
                "name": "Boqueron",
                "pk": "PY__boqueron"
            },
            {
                "_id": "caaguazu",
                "code": "caaguazu",
                "name": "Caaguazu",
                "pk": "PY__caaguazu"
            },
            {
                "_id": "caazapa",
                "code": "caazapa",
                "name": "Caazapa",
                "pk": "PY__caazapa"
            },
            {
                "_id": "canendiyu",
                "code": "canendiyu",
                "name": "Canendiyu",
                "pk": "PY__canendiyu"
            },
            {
                "_id": "central",
                "code": "central",
                "name": "Central",
                "pk": "PY__central"
            },
            {
                "_id": "concepcion",
                "code": "concepcion",
                "name": "Concepcion",
                "pk": "PY__concepcion"
            },
            {
                "_id": "cordillera",
                "code": "cordillera",
                "name": "Cordillera",
                "pk": "PY__cordillera"
            },
            {
                "_id": "guaira",
                "code": "guaira",
                "name": "Guaira",
                "pk": "PY__guaira"
            },
            {
                "_id": "itapua",
                "code": "itapua",
                "name": "Itapua",
                "pk": "PY__itapua"
            },
            {
                "_id": "misiones",
                "code": "misiones",
                "name": "Misiones",
                "pk": "PY__misiones"
            },
            {
                "_id": "neembucu",
                "code": "neembucu",
                "name": "Neembucu",
                "pk": "PY__neembucu"
            },
            {
                "_id": "paraguari",
                "code": "paraguari",
                "name": "Paraguari",
                "pk": "PY__paraguari"
            },
            {
                "_id": "presidente_hayes",
                "code": "presidente_hayes",
                "name": "Presidente Hayes",
                "pk": "PY__presidente_hayes"
            },
            {
                "_id": "san_pedro",
                "code": "san_pedro",
                "name": "San Pedro",
                "pk": "PY__san_pedro"
            }
        ],
        "pk": "PY"
    },
    {
        "_id": "PE",
        "code": "PE",
        "name": "Pérou",
        "states": [
            {
                "_id": "amazonas",
                "code": "amazonas",
                "name": "Amazonas",
                "pk": "PE__amazonas"
            },
            {
                "_id": "ancash",
                "code": "ancash",
                "name": "Ancash",
                "pk": "PE__ancash"
            },
            {
                "_id": "apurimac",
                "code": "apurimac",
                "name": "Apurimac",
                "pk": "PE__apurimac"
            },
            {
                "_id": "arequipa",
                "code": "arequipa",
                "name": "Arequipa",
                "pk": "PE__arequipa"
            },
            {
                "_id": "ayacucho",
                "code": "ayacucho",
                "name": "Ayacucho",
                "pk": "PE__ayacucho"
            },
            {
                "_id": "cajamarca",
                "code": "cajamarca",
                "name": "Cajamarca",
                "pk": "PE__cajamarca"
            },
            {
                "_id": "cusco",
                "code": "cusco",
                "name": "Cusco",
                "pk": "PE__cusco"
            },
            {
                "_id": "huancavelica",
                "code": "huancavelica",
                "name": "Huancavelica",
                "pk": "PE__huancavelica"
            },
            {
                "_id": "huanuco",
                "code": "huanuco",
                "name": "Huanuco",
                "pk": "PE__huanuco"
            },
            {
                "_id": "ica",
                "code": "ica",
                "name": "Ica",
                "pk": "PE__ica"
            },
            {
                "_id": "junin",
                "code": "junin",
                "name": "Junin",
                "pk": "PE__junin"
            },
            {
                "_id": "la_libertad",
                "code": "la_libertad",
                "name": "La Libertad",
                "pk": "PE__la_libertad"
            },
            {
                "_id": "lambayeque",
                "code": "lambayeque",
                "name": "Lambayeque",
                "pk": "PE__lambayeque"
            },
            {
                "_id": "lima_y_callao",
                "code": "lima_y_callao",
                "name": "Lima y Callao",
                "pk": "PE__lima_y_callao"
            },
            {
                "_id": "loreto",
                "code": "loreto",
                "name": "Loreto",
                "pk": "PE__loreto"
            },
            {
                "_id": "madre_de_dios",
                "code": "madre_de_dios",
                "name": "Madre de Dios",
                "pk": "PE__madre_de_dios"
            },
            {
                "_id": "moquegua",
                "code": "moquegua",
                "name": "Moquegua",
                "pk": "PE__moquegua"
            },
            {
                "_id": "pasco",
                "code": "pasco",
                "name": "Pasco",
                "pk": "PE__pasco"
            },
            {
                "_id": "piura",
                "code": "piura",
                "name": "Piura",
                "pk": "PE__piura"
            },
            {
                "_id": "puno",
                "code": "puno",
                "name": "Puno",
                "pk": "PE__puno"
            },
            {
                "_id": "san_martin",
                "code": "san_martin",
                "name": "San Martin",
                "pk": "PE__san_martin"
            },
            {
                "_id": "tacna",
                "code": "tacna",
                "name": "Tacna",
                "pk": "PE__tacna"
            },
            {
                "_id": "tumbes",
                "code": "tumbes",
                "name": "Tumbes",
                "pk": "PE__tumbes"
            },
            {
                "_id": "ucayali",
                "code": "ucayali",
                "name": "Ucayali",
                "pk": "PE__ucayali"
            }
        ],
        "pk": "PE"
    },
    {
        "_id": "PH",
        "code": "PH",
        "name": "Philippines",
        "states": [
            {
                "_id": "batangas",
                "code": "batangas",
                "name": "Batangas",
                "pk": "PH__batangas"
            },
            {
                "_id": "bicol",
                "code": "bicol",
                "name": "Bicol",
                "pk": "PH__bicol"
            },
            {
                "_id": "bulacan",
                "code": "bulacan",
                "name": "Bulacan",
                "pk": "PH__bulacan"
            },
            {
                "_id": "cagayan",
                "code": "cagayan",
                "name": "Cagayan",
                "pk": "PH__cagayan"
            },
            {
                "_id": "caraga",
                "code": "caraga",
                "name": "Caraga",
                "pk": "PH__caraga"
            },
            {
                "_id": "central_luzon",
                "code": "central_luzon",
                "name": "Central Luzon",
                "pk": "PH__central_luzon"
            },
            {
                "_id": "central_mindanao",
                "code": "central_mindanao",
                "name": "Central Mindanao",
                "pk": "PH__central_mindanao"
            },
            {
                "_id": "central_visayas",
                "code": "central_visayas",
                "name": "Central Visayas",
                "pk": "PH__central_visayas"
            },
            {
                "_id": "cordillera",
                "code": "cordillera",
                "name": "Cordillera",
                "pk": "PH__cordillera"
            },
            {
                "_id": "davao",
                "code": "davao",
                "name": "Davao",
                "pk": "PH__davao"
            },
            {
                "_id": "eastern_visayas",
                "code": "eastern_visayas",
                "name": "Eastern Visayas",
                "pk": "PH__eastern_visayas"
            },
            {
                "_id": "greater_metropolitan_area",
                "code": "greater_metropolitan_area",
                "name": "Greater Metropolitan Area",
                "pk": "PH__greater_metropolitan_area"
            },
            {
                "_id": "ilocos",
                "code": "ilocos",
                "name": "Ilocos",
                "pk": "PH__ilocos"
            },
            {
                "_id": "laguna",
                "code": "laguna",
                "name": "Laguna",
                "pk": "PH__laguna"
            },
            {
                "_id": "luzon",
                "code": "luzon",
                "name": "Luzon",
                "pk": "PH__luzon"
            },
            {
                "_id": "mactan",
                "code": "mactan",
                "name": "Mactan",
                "pk": "PH__mactan"
            },
            {
                "_id": "metropolitan_manila_area",
                "code": "metropolitan_manila_area",
                "name": "Metropolitan Manila Area",
                "pk": "PH__metropolitan_manila_area"
            },
            {
                "_id": "muslim_mindanao",
                "code": "muslim_mindanao",
                "name": "Muslim Mindanao",
                "pk": "PH__muslim_mindanao"
            },
            {
                "_id": "northern_mindanao",
                "code": "northern_mindanao",
                "name": "Northern Mindanao",
                "pk": "PH__northern_mindanao"
            },
            {
                "_id": "southern_mindanao",
                "code": "southern_mindanao",
                "name": "Southern Mindanao",
                "pk": "PH__southern_mindanao"
            },
            {
                "_id": "southern_tagalog",
                "code": "southern_tagalog",
                "name": "Southern Tagalog",
                "pk": "PH__southern_tagalog"
            },
            {
                "_id": "western_mindanao",
                "code": "western_mindanao",
                "name": "Western Mindanao",
                "pk": "PH__western_mindanao"
            },
            {
                "_id": "western_visayas",
                "code": "western_visayas",
                "name": "Western Visayas",
                "pk": "PH__western_visayas"
            }
        ],
        "pk": "PH"
    },
    {
        "_id": "PN",
        "code": "PN",
        "name": "Îles Pitcairn",
        "states": [
            {
                "_id": "pitcairn_island",
                "code": "pitcairn_island",
                "name": "Pitcairn Island",
                "pk": "PN__pitcairn_island"
            }
        ],
        "pk": "PN"
    },
    {
        "_id": "PL",
        "code": "PL",
        "name": "Pologne",
        "states": [
            {
                "_id": "biale_blota",
                "code": "biale_blota",
                "name": "Biale Blota",
                "pk": "PL__biale_blota"
            },
            {
                "_id": "dobroszyce",
                "code": "dobroszyce",
                "name": "Dobroszyce",
                "pk": "PL__dobroszyce"
            },
            {
                "_id": "dolnoslaskie",
                "code": "dolnoslaskie",
                "name": "Dolnoslaskie",
                "pk": "PL__dolnoslaskie"
            },
            {
                "_id": "dziekanow_lesny",
                "code": "dziekanow_lesny",
                "name": "Dziekanow Lesny",
                "pk": "PL__dziekanow_lesny"
            },
            {
                "_id": "hopowo",
                "code": "hopowo",
                "name": "Hopowo",
                "pk": "PL__hopowo"
            },
            {
                "_id": "kartuzy",
                "code": "kartuzy",
                "name": "Kartuzy",
                "pk": "PL__kartuzy"
            },
            {
                "_id": "koscian",
                "code": "koscian",
                "name": "Koscian",
                "pk": "PL__koscian"
            },
            {
                "_id": "krakow",
                "code": "krakow",
                "name": "Krakow",
                "pk": "PL__krakow"
            },
            {
                "_id": "kujawsko-pomorskie",
                "code": "kujawsko-pomorskie",
                "name": "Kujawsko-Pomorskie",
                "pk": "PL__kujawsko-pomorskie"
            },
            {
                "_id": "lodzkie",
                "code": "lodzkie",
                "name": "Lodzkie",
                "pk": "PL__lodzkie"
            },
            {
                "_id": "lubelskie",
                "code": "lubelskie",
                "name": "Lubelskie",
                "pk": "PL__lubelskie"
            },
            {
                "_id": "lubuskie",
                "code": "lubuskie",
                "name": "Lubuskie",
                "pk": "PL__lubuskie"
            },
            {
                "_id": "malomice",
                "code": "malomice",
                "name": "Malomice",
                "pk": "PL__malomice"
            },
            {
                "_id": "malopolskie",
                "code": "malopolskie",
                "name": "Malopolskie",
                "pk": "PL__malopolskie"
            },
            {
                "_id": "mazowieckie",
                "code": "mazowieckie",
                "name": "Mazowieckie",
                "pk": "PL__mazowieckie"
            },
            {
                "_id": "mirkow",
                "code": "mirkow",
                "name": "Mirkow",
                "pk": "PL__mirkow"
            },
            {
                "_id": "opolskie",
                "code": "opolskie",
                "name": "Opolskie",
                "pk": "PL__opolskie"
            },
            {
                "_id": "ostrowiec",
                "code": "ostrowiec",
                "name": "Ostrowiec",
                "pk": "PL__ostrowiec"
            },
            {
                "_id": "podkarpackie",
                "code": "podkarpackie",
                "name": "Podkarpackie",
                "pk": "PL__podkarpackie"
            },
            {
                "_id": "podlaskie",
                "code": "podlaskie",
                "name": "Podlaskie",
                "pk": "PL__podlaskie"
            },
            {
                "_id": "polska",
                "code": "polska",
                "name": "Polska",
                "pk": "PL__polska"
            },
            {
                "_id": "pomorskie",
                "code": "pomorskie",
                "name": "Pomorskie",
                "pk": "PL__pomorskie"
            },
            {
                "_id": "poznan",
                "code": "poznan",
                "name": "Poznan",
                "pk": "PL__poznan"
            },
            {
                "_id": "pruszkow",
                "code": "pruszkow",
                "name": "Pruszkow",
                "pk": "PL__pruszkow"
            },
            {
                "_id": "rymanowska",
                "code": "rymanowska",
                "name": "Rymanowska",
                "pk": "PL__rymanowska"
            },
            {
                "_id": "rzeszow",
                "code": "rzeszow",
                "name": "Rzeszow",
                "pk": "PL__rzeszow"
            },
            {
                "_id": "slaskie",
                "code": "slaskie",
                "name": "Slaskie",
                "pk": "PL__slaskie"
            },
            {
                "_id": "stare_pole",
                "code": "stare_pole",
                "name": "Stare Pole",
                "pk": "PL__stare_pole"
            },
            {
                "_id": "swietokrzyskie",
                "code": "swietokrzyskie",
                "name": "Swietokrzyskie",
                "pk": "PL__swietokrzyskie"
            },
            {
                "_id": "warminsko-mazurskie",
                "code": "warminsko-mazurskie",
                "name": "Warminsko-Mazurskie",
                "pk": "PL__warminsko-mazurskie"
            },
            {
                "_id": "warsaw",
                "code": "warsaw",
                "name": "Warsaw",
                "pk": "PL__warsaw"
            },
            {
                "_id": "wejherowo",
                "code": "wejherowo",
                "name": "Wejherowo",
                "pk": "PL__wejherowo"
            },
            {
                "_id": "wielkopolskie",
                "code": "wielkopolskie",
                "name": "Wielkopolskie",
                "pk": "PL__wielkopolskie"
            },
            {
                "_id": "wroclaw",
                "code": "wroclaw",
                "name": "Wroclaw",
                "pk": "PL__wroclaw"
            },
            {
                "_id": "zachodnio-pomorskie",
                "code": "zachodnio-pomorskie",
                "name": "Zachodnio-Pomorskie",
                "pk": "PL__zachodnio-pomorskie"
            },
            {
                "_id": "zukowo",
                "code": "zukowo",
                "name": "Zukowo",
                "pk": "PL__zukowo"
            }
        ],
        "pk": "PL"
    },
    {
        "_id": "PT",
        "code": "PT",
        "name": "Portugal",
        "states": [
            {
                "_id": "abrantes",
                "code": "abrantes",
                "name": "Abrantes",
                "pk": "PT__abrantes"
            },
            {
                "_id": "acores",
                "code": "acores",
                "name": "Acores",
                "pk": "PT__acores"
            },
            {
                "_id": "alentejo",
                "code": "alentejo",
                "name": "Alentejo",
                "pk": "PT__alentejo"
            },
            {
                "_id": "algarve",
                "code": "algarve",
                "name": "Algarve",
                "pk": "PT__algarve"
            },
            {
                "_id": "braga",
                "code": "braga",
                "name": "Braga",
                "pk": "PT__braga"
            },
            {
                "_id": "centro",
                "code": "centro",
                "name": "Centro",
                "pk": "PT__centro"
            },
            {
                "_id": "distrito_de_leiria",
                "code": "distrito_de_leiria",
                "name": "Distrito de Leiria",
                "pk": "PT__distrito_de_leiria"
            },
            {
                "_id": "distrito_de_viana_do_castelo",
                "code": "distrito_de_viana_do_castelo",
                "name": "Distrito de Viana do Castelo",
                "pk": "PT__distrito_de_viana_do_castelo"
            },
            {
                "_id": "distrito_de_vila_real",
                "code": "distrito_de_vila_real",
                "name": "Distrito de Vila Real",
                "pk": "PT__distrito_de_vila_real"
            },
            {
                "_id": "distrito_do_porto",
                "code": "distrito_do_porto",
                "name": "Distrito do Porto",
                "pk": "PT__distrito_do_porto"
            },
            {
                "_id": "lisboa_e_vale_do_tejo",
                "code": "lisboa_e_vale_do_tejo",
                "name": "Lisboa e Vale do Tejo",
                "pk": "PT__lisboa_e_vale_do_tejo"
            },
            {
                "_id": "madeira",
                "code": "madeira",
                "name": "Madeira",
                "pk": "PT__madeira"
            },
            {
                "_id": "norte",
                "code": "norte",
                "name": "Norte",
                "pk": "PT__norte"
            },
            {
                "_id": "paivas",
                "code": "paivas",
                "name": "Paivas",
                "pk": "PT__paivas"
            }
        ],
        "pk": "PT"
    },
    {
        "_id": "PR",
        "code": "PR",
        "name": "Porto Rico",
        "states": [
            {
                "_id": "arecibo",
                "code": "arecibo",
                "name": "Arecibo",
                "pk": "PR__arecibo"
            },
            {
                "_id": "bayamon",
                "code": "bayamon",
                "name": "Bayamon",
                "pk": "PR__bayamon"
            },
            {
                "_id": "carolina",
                "code": "carolina",
                "name": "Carolina",
                "pk": "PR__carolina"
            },
            {
                "_id": "florida",
                "code": "florida",
                "name": "Florida",
                "pk": "PR__florida"
            },
            {
                "_id": "guayama",
                "code": "guayama",
                "name": "Guayama",
                "pk": "PR__guayama"
            },
            {
                "_id": "humacao",
                "code": "humacao",
                "name": "Humacao",
                "pk": "PR__humacao"
            },
            {
                "_id": "mayaguez-aguadilla",
                "code": "mayaguez-aguadilla",
                "name": "Mayaguez-Aguadilla",
                "pk": "PR__mayaguez-aguadilla"
            },
            {
                "_id": "ponce",
                "code": "ponce",
                "name": "Ponce",
                "pk": "PR__ponce"
            },
            {
                "_id": "salinas",
                "code": "salinas",
                "name": "Salinas",
                "pk": "PR__salinas"
            },
            {
                "_id": "san_juan",
                "code": "san_juan",
                "name": "San Juan",
                "pk": "PR__san_juan"
            }
        ],
        "pk": "PR"
    },
    {
        "_id": "QA",
        "code": "QA",
        "name": "Qatar",
        "states": [
            {
                "_id": "doha",
                "code": "doha",
                "name": "Doha",
                "pk": "QA__doha"
            },
            {
                "_id": "jarian-al-batnah",
                "code": "jarian-al-batnah",
                "name": "Jarian-al-Batnah",
                "pk": "QA__jarian-al-batnah"
            },
            {
                "_id": "umm_salal",
                "code": "umm_salal",
                "name": "Umm Salal",
                "pk": "QA__umm_salal"
            },
            {
                "_id": "ad-dawhah",
                "code": "ad-dawhah",
                "name": "ad-Dawhah",
                "pk": "QA__ad-dawhah"
            },
            {
                "_id": "al-ghuwayriyah",
                "code": "al-ghuwayriyah",
                "name": "al-Ghuwayriyah",
                "pk": "QA__al-ghuwayriyah"
            },
            {
                "_id": "al-jumayliyah",
                "code": "al-jumayliyah",
                "name": "al-Jumayliyah",
                "pk": "QA__al-jumayliyah"
            },
            {
                "_id": "al-khawr",
                "code": "al-khawr",
                "name": "al-Khawr",
                "pk": "QA__al-khawr"
            },
            {
                "_id": "al-wakrah",
                "code": "al-wakrah",
                "name": "al-Wakrah",
                "pk": "QA__al-wakrah"
            },
            {
                "_id": "ar-rayyan",
                "code": "ar-rayyan",
                "name": "ar-Rayyan",
                "pk": "QA__ar-rayyan"
            },
            {
                "_id": "ash-shamal",
                "code": "ash-shamal",
                "name": "ash-Shamal",
                "pk": "QA__ash-shamal"
            }
        ],
        "pk": "QA"
    },
    {
        "_id": "CG",
        "code": "CG",
        "name": "République du Congo",
        "states": [
            {
                "_id": "bouenza",
                "code": "bouenza",
                "name": "Bouenza",
                "pk": "CG__bouenza"
            },
            {
                "_id": "brazzaville",
                "code": "brazzaville",
                "name": "Brazzaville",
                "pk": "CG__brazzaville"
            },
            {
                "_id": "cuvette",
                "code": "cuvette",
                "name": "Cuvette",
                "pk": "CG__cuvette"
            },
            {
                "_id": "kouilou",
                "code": "kouilou",
                "name": "Kouilou",
                "pk": "CG__kouilou"
            },
            {
                "_id": "lekoumou",
                "code": "lekoumou",
                "name": "Lekoumou",
                "pk": "CG__lekoumou"
            },
            {
                "_id": "likouala",
                "code": "likouala",
                "name": "Likouala",
                "pk": "CG__likouala"
            },
            {
                "_id": "niari",
                "code": "niari",
                "name": "Niari",
                "pk": "CG__niari"
            },
            {
                "_id": "plateaux",
                "code": "plateaux",
                "name": "Plateaux",
                "pk": "CG__plateaux"
            },
            {
                "_id": "pool",
                "code": "pool",
                "name": "Pool",
                "pk": "CG__pool"
            },
            {
                "_id": "sangha",
                "code": "sangha",
                "name": "Sangha",
                "pk": "CG__sangha"
            }
        ],
        "pk": "CG"
    },
    {
        "_id": "RE",
        "code": "RE",
        "name": "Réunion",
        "states": [
            {
                "_id": "saint-benoit",
                "code": "saint-benoit",
                "name": "Saint-Benoit",
                "pk": "RE__saint-benoit"
            },
            {
                "_id": "saint-denis",
                "code": "saint-denis",
                "name": "Saint-Denis",
                "pk": "RE__saint-denis"
            },
            {
                "_id": "saint-paul",
                "code": "saint-paul",
                "name": "Saint-Paul",
                "pk": "RE__saint-paul"
            },
            {
                "_id": "saint-pierre",
                "code": "saint-pierre",
                "name": "Saint-Pierre",
                "pk": "RE__saint-pierre"
            }
        ],
        "pk": "RE"
    },
    {
        "_id": "RO",
        "code": "RO",
        "name": "Roumanie",
        "states": [
            {
                "_id": "alba",
                "code": "alba",
                "name": "Alba",
                "pk": "RO__alba"
            },
            {
                "_id": "arad",
                "code": "arad",
                "name": "Arad",
                "pk": "RO__arad"
            },
            {
                "_id": "arges",
                "code": "arges",
                "name": "Arges",
                "pk": "RO__arges"
            },
            {
                "_id": "bacau",
                "code": "bacau",
                "name": "Bacau",
                "pk": "RO__bacau"
            },
            {
                "_id": "bihor",
                "code": "bihor",
                "name": "Bihor",
                "pk": "RO__bihor"
            },
            {
                "_id": "bistrita-nasaud",
                "code": "bistrita-nasaud",
                "name": "Bistrita-Nasaud",
                "pk": "RO__bistrita-nasaud"
            },
            {
                "_id": "botosani",
                "code": "botosani",
                "name": "Botosani",
                "pk": "RO__botosani"
            },
            {
                "_id": "braila",
                "code": "braila",
                "name": "Braila",
                "pk": "RO__braila"
            },
            {
                "_id": "brasov",
                "code": "brasov",
                "name": "Brasov",
                "pk": "RO__brasov"
            },
            {
                "_id": "bucuresti",
                "code": "bucuresti",
                "name": "Bucuresti",
                "pk": "RO__bucuresti"
            },
            {
                "_id": "buzau",
                "code": "buzau",
                "name": "Buzau",
                "pk": "RO__buzau"
            },
            {
                "_id": "calarasi",
                "code": "calarasi",
                "name": "Calarasi",
                "pk": "RO__calarasi"
            },
            {
                "_id": "caras-severin",
                "code": "caras-severin",
                "name": "Caras-Severin",
                "pk": "RO__caras-severin"
            },
            {
                "_id": "cluj",
                "code": "cluj",
                "name": "Cluj",
                "pk": "RO__cluj"
            },
            {
                "_id": "constanta",
                "code": "constanta",
                "name": "Constanta",
                "pk": "RO__constanta"
            },
            {
                "_id": "covasna",
                "code": "covasna",
                "name": "Covasna",
                "pk": "RO__covasna"
            },
            {
                "_id": "dambovita",
                "code": "dambovita",
                "name": "Dambovita",
                "pk": "RO__dambovita"
            },
            {
                "_id": "dolj",
                "code": "dolj",
                "name": "Dolj",
                "pk": "RO__dolj"
            },
            {
                "_id": "galati",
                "code": "galati",
                "name": "Galati",
                "pk": "RO__galati"
            },
            {
                "_id": "giurgiu",
                "code": "giurgiu",
                "name": "Giurgiu",
                "pk": "RO__giurgiu"
            },
            {
                "_id": "gorj",
                "code": "gorj",
                "name": "Gorj",
                "pk": "RO__gorj"
            },
            {
                "_id": "harghita",
                "code": "harghita",
                "name": "Harghita",
                "pk": "RO__harghita"
            },
            {
                "_id": "hunedoara",
                "code": "hunedoara",
                "name": "Hunedoara",
                "pk": "RO__hunedoara"
            },
            {
                "_id": "ialomita",
                "code": "ialomita",
                "name": "Ialomita",
                "pk": "RO__ialomita"
            },
            {
                "_id": "iasi",
                "code": "iasi",
                "name": "Iasi",
                "pk": "RO__iasi"
            },
            {
                "_id": "ilfov",
                "code": "ilfov",
                "name": "Ilfov",
                "pk": "RO__ilfov"
            },
            {
                "_id": "maramures",
                "code": "maramures",
                "name": "Maramures",
                "pk": "RO__maramures"
            },
            {
                "_id": "mehedinti",
                "code": "mehedinti",
                "name": "Mehedinti",
                "pk": "RO__mehedinti"
            },
            {
                "_id": "mures",
                "code": "mures",
                "name": "Mures",
                "pk": "RO__mures"
            },
            {
                "_id": "neamt",
                "code": "neamt",
                "name": "Neamt",
                "pk": "RO__neamt"
            },
            {
                "_id": "olt",
                "code": "olt",
                "name": "Olt",
                "pk": "RO__olt"
            },
            {
                "_id": "prahova",
                "code": "prahova",
                "name": "Prahova",
                "pk": "RO__prahova"
            },
            {
                "_id": "salaj",
                "code": "salaj",
                "name": "Salaj",
                "pk": "RO__salaj"
            },
            {
                "_id": "satu_mare",
                "code": "satu_mare",
                "name": "Satu Mare",
                "pk": "RO__satu_mare"
            },
            {
                "_id": "sibiu",
                "code": "sibiu",
                "name": "Sibiu",
                "pk": "RO__sibiu"
            },
            {
                "_id": "sondelor",
                "code": "sondelor",
                "name": "Sondelor",
                "pk": "RO__sondelor"
            },
            {
                "_id": "suceava",
                "code": "suceava",
                "name": "Suceava",
                "pk": "RO__suceava"
            },
            {
                "_id": "teleorman",
                "code": "teleorman",
                "name": "Teleorman",
                "pk": "RO__teleorman"
            },
            {
                "_id": "timis",
                "code": "timis",
                "name": "Timis",
                "pk": "RO__timis"
            },
            {
                "_id": "tulcea",
                "code": "tulcea",
                "name": "Tulcea",
                "pk": "RO__tulcea"
            },
            {
                "_id": "valcea",
                "code": "valcea",
                "name": "Valcea",
                "pk": "RO__valcea"
            },
            {
                "_id": "vaslui",
                "code": "vaslui",
                "name": "Vaslui",
                "pk": "RO__vaslui"
            },
            {
                "_id": "vrancea",
                "code": "vrancea",
                "name": "Vrancea",
                "pk": "RO__vrancea"
            }
        ],
        "pk": "RO"
    },
    {
        "_id": "RU",
        "code": "RU",
        "name": "Russie",
        "states": [
            {
                "_id": "adygeja",
                "code": "adygeja",
                "name": "Adygeja",
                "pk": "RU__adygeja"
            },
            {
                "_id": "aga",
                "code": "aga",
                "name": "Aga",
                "pk": "RU__aga"
            },
            {
                "_id": "alanija",
                "code": "alanija",
                "name": "Alanija",
                "pk": "RU__alanija"
            },
            {
                "_id": "altaj",
                "code": "altaj",
                "name": "Altaj",
                "pk": "RU__altaj"
            },
            {
                "_id": "amur",
                "code": "amur",
                "name": "Amur",
                "pk": "RU__amur"
            },
            {
                "_id": "arhangelsk",
                "code": "arhangelsk",
                "name": "Arhangelsk",
                "pk": "RU__arhangelsk"
            },
            {
                "_id": "astrahan",
                "code": "astrahan",
                "name": "Astrahan",
                "pk": "RU__astrahan"
            },
            {
                "_id": "bashkortostan",
                "code": "bashkortostan",
                "name": "Bashkortostan",
                "pk": "RU__bashkortostan"
            },
            {
                "_id": "belgorod",
                "code": "belgorod",
                "name": "Belgorod",
                "pk": "RU__belgorod"
            },
            {
                "_id": "brjansk",
                "code": "brjansk",
                "name": "Brjansk",
                "pk": "RU__brjansk"
            },
            {
                "_id": "burjatija",
                "code": "burjatija",
                "name": "Burjatija",
                "pk": "RU__burjatija"
            },
            {
                "_id": "chechenija",
                "code": "chechenija",
                "name": "Chechenija",
                "pk": "RU__chechenija"
            },
            {
                "_id": "cheljabinsk",
                "code": "cheljabinsk",
                "name": "Cheljabinsk",
                "pk": "RU__cheljabinsk"
            },
            {
                "_id": "chita",
                "code": "chita",
                "name": "Chita",
                "pk": "RU__chita"
            },
            {
                "_id": "chukotka",
                "code": "chukotka",
                "name": "Chukotka",
                "pk": "RU__chukotka"
            },
            {
                "_id": "chuvashija",
                "code": "chuvashija",
                "name": "Chuvashija",
                "pk": "RU__chuvashija"
            },
            {
                "_id": "dagestan",
                "code": "dagestan",
                "name": "Dagestan",
                "pk": "RU__dagestan"
            },
            {
                "_id": "evenkija",
                "code": "evenkija",
                "name": "Evenkija",
                "pk": "RU__evenkija"
            },
            {
                "_id": "gorno-altaj",
                "code": "gorno-altaj",
                "name": "Gorno-Altaj",
                "pk": "RU__gorno-altaj"
            },
            {
                "_id": "habarovsk",
                "code": "habarovsk",
                "name": "Habarovsk",
                "pk": "RU__habarovsk"
            },
            {
                "_id": "hakasija",
                "code": "hakasija",
                "name": "Hakasija",
                "pk": "RU__hakasija"
            },
            {
                "_id": "hanty-mansija",
                "code": "hanty-mansija",
                "name": "Hanty-Mansija",
                "pk": "RU__hanty-mansija"
            },
            {
                "_id": "ingusetija",
                "code": "ingusetija",
                "name": "Ingusetija",
                "pk": "RU__ingusetija"
            },
            {
                "_id": "irkutsk",
                "code": "irkutsk",
                "name": "Irkutsk",
                "pk": "RU__irkutsk"
            },
            {
                "_id": "ivanovo",
                "code": "ivanovo",
                "name": "Ivanovo",
                "pk": "RU__ivanovo"
            },
            {
                "_id": "jamalo-nenets",
                "code": "jamalo-nenets",
                "name": "Jamalo-Nenets",
                "pk": "RU__jamalo-nenets"
            },
            {
                "_id": "jaroslavl",
                "code": "jaroslavl",
                "name": "Jaroslavl",
                "pk": "RU__jaroslavl"
            },
            {
                "_id": "jevrej",
                "code": "jevrej",
                "name": "Jevrej",
                "pk": "RU__jevrej"
            },
            {
                "_id": "kabardino-balkarija",
                "code": "kabardino-balkarija",
                "name": "Kabardino-Balkarija",
                "pk": "RU__kabardino-balkarija"
            },
            {
                "_id": "kaliningrad",
                "code": "kaliningrad",
                "name": "Kaliningrad",
                "pk": "RU__kaliningrad"
            },
            {
                "_id": "kalmykija",
                "code": "kalmykija",
                "name": "Kalmykija",
                "pk": "RU__kalmykija"
            },
            {
                "_id": "kaluga",
                "code": "kaluga",
                "name": "Kaluga",
                "pk": "RU__kaluga"
            },
            {
                "_id": "kamchatka",
                "code": "kamchatka",
                "name": "Kamchatka",
                "pk": "RU__kamchatka"
            },
            {
                "_id": "karachaj-cherkessija",
                "code": "karachaj-cherkessija",
                "name": "Karachaj-Cherkessija",
                "pk": "RU__karachaj-cherkessija"
            },
            {
                "_id": "karelija",
                "code": "karelija",
                "name": "Karelija",
                "pk": "RU__karelija"
            },
            {
                "_id": "kemerovo",
                "code": "kemerovo",
                "name": "Kemerovo",
                "pk": "RU__kemerovo"
            },
            {
                "_id": "khabarovskiy_kray",
                "code": "khabarovskiy_kray",
                "name": "Khabarovskiy Kray",
                "pk": "RU__khabarovskiy_kray"
            },
            {
                "_id": "kirov",
                "code": "kirov",
                "name": "Kirov",
                "pk": "RU__kirov"
            },
            {
                "_id": "komi",
                "code": "komi",
                "name": "Komi",
                "pk": "RU__komi"
            },
            {
                "_id": "komi-permjakija",
                "code": "komi-permjakija",
                "name": "Komi-Permjakija",
                "pk": "RU__komi-permjakija"
            },
            {
                "_id": "korjakija",
                "code": "korjakija",
                "name": "Korjakija",
                "pk": "RU__korjakija"
            },
            {
                "_id": "kostroma",
                "code": "kostroma",
                "name": "Kostroma",
                "pk": "RU__kostroma"
            },
            {
                "_id": "krasnodar",
                "code": "krasnodar",
                "name": "Krasnodar",
                "pk": "RU__krasnodar"
            },
            {
                "_id": "krasnojarsk",
                "code": "krasnojarsk",
                "name": "Krasnojarsk",
                "pk": "RU__krasnojarsk"
            },
            {
                "_id": "krasnoyarskiy_kray",
                "code": "krasnoyarskiy_kray",
                "name": "Krasnoyarskiy Kray",
                "pk": "RU__krasnoyarskiy_kray"
            },
            {
                "_id": "kurgan",
                "code": "kurgan",
                "name": "Kurgan",
                "pk": "RU__kurgan"
            },
            {
                "_id": "kursk",
                "code": "kursk",
                "name": "Kursk",
                "pk": "RU__kursk"
            },
            {
                "_id": "leningrad",
                "code": "leningrad",
                "name": "Leningrad",
                "pk": "RU__leningrad"
            },
            {
                "_id": "lipeck",
                "code": "lipeck",
                "name": "Lipeck",
                "pk": "RU__lipeck"
            },
            {
                "_id": "magadan",
                "code": "magadan",
                "name": "Magadan",
                "pk": "RU__magadan"
            },
            {
                "_id": "marij_el",
                "code": "marij_el",
                "name": "Marij El",
                "pk": "RU__marij_el"
            },
            {
                "_id": "mordovija",
                "code": "mordovija",
                "name": "Mordovija",
                "pk": "RU__mordovija"
            },
            {
                "_id": "moscow",
                "code": "moscow",
                "name": "Moscow",
                "pk": "RU__moscow"
            },
            {
                "_id": "moskovskaja_oblast",
                "code": "moskovskaja_oblast",
                "name": "Moskovskaja Oblast",
                "pk": "RU__moskovskaja_oblast"
            },
            {
                "_id": "moskovskaya_oblast",
                "code": "moskovskaya_oblast",
                "name": "Moskovskaya Oblast",
                "pk": "RU__moskovskaya_oblast"
            },
            {
                "_id": "moskva",
                "code": "moskva",
                "name": "Moskva",
                "pk": "RU__moskva"
            },
            {
                "_id": "murmansk",
                "code": "murmansk",
                "name": "Murmansk",
                "pk": "RU__murmansk"
            },
            {
                "_id": "nenets",
                "code": "nenets",
                "name": "Nenets",
                "pk": "RU__nenets"
            },
            {
                "_id": "nizhnij_novgorod",
                "code": "nizhnij_novgorod",
                "name": "Nizhnij Novgorod",
                "pk": "RU__nizhnij_novgorod"
            },
            {
                "_id": "novgorod",
                "code": "novgorod",
                "name": "Novgorod",
                "pk": "RU__novgorod"
            },
            {
                "_id": "novokusnezk",
                "code": "novokusnezk",
                "name": "Novokusnezk",
                "pk": "RU__novokusnezk"
            },
            {
                "_id": "novosibirsk",
                "code": "novosibirsk",
                "name": "Novosibirsk",
                "pk": "RU__novosibirsk"
            },
            {
                "_id": "omsk",
                "code": "omsk",
                "name": "Omsk",
                "pk": "RU__omsk"
            },
            {
                "_id": "orenburg",
                "code": "orenburg",
                "name": "Orenburg",
                "pk": "RU__orenburg"
            },
            {
                "_id": "orjol",
                "code": "orjol",
                "name": "Orjol",
                "pk": "RU__orjol"
            },
            {
                "_id": "penza",
                "code": "penza",
                "name": "Penza",
                "pk": "RU__penza"
            },
            {
                "_id": "perm",
                "code": "perm",
                "name": "Perm",
                "pk": "RU__perm"
            },
            {
                "_id": "primorje",
                "code": "primorje",
                "name": "Primorje",
                "pk": "RU__primorje"
            },
            {
                "_id": "pskov",
                "code": "pskov",
                "name": "Pskov",
                "pk": "RU__pskov"
            },
            {
                "_id": "pskovskaya_oblast",
                "code": "pskovskaya_oblast",
                "name": "Pskovskaya Oblast",
                "pk": "RU__pskovskaya_oblast"
            },
            {
                "_id": "rjazan",
                "code": "rjazan",
                "name": "Rjazan",
                "pk": "RU__rjazan"
            },
            {
                "_id": "rostov",
                "code": "rostov",
                "name": "Rostov",
                "pk": "RU__rostov"
            },
            {
                "_id": "saha",
                "code": "saha",
                "name": "Saha",
                "pk": "RU__saha"
            },
            {
                "_id": "sahalin",
                "code": "sahalin",
                "name": "Sahalin",
                "pk": "RU__sahalin"
            },
            {
                "_id": "samara",
                "code": "samara",
                "name": "Samara",
                "pk": "RU__samara"
            },
            {
                "_id": "samarskaya",
                "code": "samarskaya",
                "name": "Samarskaya",
                "pk": "RU__samarskaya"
            },
            {
                "_id": "sankt-peterburg",
                "code": "sankt-peterburg",
                "name": "Sankt-Peterburg",
                "pk": "RU__sankt-peterburg"
            },
            {
                "_id": "saratov",
                "code": "saratov",
                "name": "Saratov",
                "pk": "RU__saratov"
            },
            {
                "_id": "smolensk",
                "code": "smolensk",
                "name": "Smolensk",
                "pk": "RU__smolensk"
            },
            {
                "_id": "stavropol",
                "code": "stavropol",
                "name": "Stavropol",
                "pk": "RU__stavropol"
            },
            {
                "_id": "sverdlovsk",
                "code": "sverdlovsk",
                "name": "Sverdlovsk",
                "pk": "RU__sverdlovsk"
            },
            {
                "_id": "tajmyrija",
                "code": "tajmyrija",
                "name": "Tajmyrija",
                "pk": "RU__tajmyrija"
            },
            {
                "_id": "tambov",
                "code": "tambov",
                "name": "Tambov",
                "pk": "RU__tambov"
            },
            {
                "_id": "tatarstan",
                "code": "tatarstan",
                "name": "Tatarstan",
                "pk": "RU__tatarstan"
            },
            {
                "_id": "tjumen",
                "code": "tjumen",
                "name": "Tjumen",
                "pk": "RU__tjumen"
            },
            {
                "_id": "tomsk",
                "code": "tomsk",
                "name": "Tomsk",
                "pk": "RU__tomsk"
            },
            {
                "_id": "tula",
                "code": "tula",
                "name": "Tula",
                "pk": "RU__tula"
            },
            {
                "_id": "tver",
                "code": "tver",
                "name": "Tver",
                "pk": "RU__tver"
            },
            {
                "_id": "tyva",
                "code": "tyva",
                "name": "Tyva",
                "pk": "RU__tyva"
            },
            {
                "_id": "udmurtija",
                "code": "udmurtija",
                "name": "Udmurtija",
                "pk": "RU__udmurtija"
            },
            {
                "_id": "uljanovsk",
                "code": "uljanovsk",
                "name": "Uljanovsk",
                "pk": "RU__uljanovsk"
            },
            {
                "_id": "ulyanovskaya_oblast",
                "code": "ulyanovskaya_oblast",
                "name": "Ulyanovskaya Oblast",
                "pk": "RU__ulyanovskaya_oblast"
            },
            {
                "_id": "ust-orda",
                "code": "ust-orda",
                "name": "Ust-Orda",
                "pk": "RU__ust-orda"
            },
            {
                "_id": "vladimir",
                "code": "vladimir",
                "name": "Vladimir",
                "pk": "RU__vladimir"
            },
            {
                "_id": "volgograd",
                "code": "volgograd",
                "name": "Volgograd",
                "pk": "RU__volgograd"
            },
            {
                "_id": "vologda",
                "code": "vologda",
                "name": "Vologda",
                "pk": "RU__vologda"
            },
            {
                "_id": "voronezh",
                "code": "voronezh",
                "name": "Voronezh",
                "pk": "RU__voronezh"
            }
        ],
        "pk": "RU"
    },
    {
        "_id": "RW",
        "code": "RW",
        "name": "Rwanda",
        "states": [
            {
                "_id": "butare",
                "code": "butare",
                "name": "Butare",
                "pk": "RW__butare"
            },
            {
                "_id": "byumba",
                "code": "byumba",
                "name": "Byumba",
                "pk": "RW__byumba"
            },
            {
                "_id": "cyangugu",
                "code": "cyangugu",
                "name": "Cyangugu",
                "pk": "RW__cyangugu"
            },
            {
                "_id": "gikongoro",
                "code": "gikongoro",
                "name": "Gikongoro",
                "pk": "RW__gikongoro"
            },
            {
                "_id": "gisenyi",
                "code": "gisenyi",
                "name": "Gisenyi",
                "pk": "RW__gisenyi"
            },
            {
                "_id": "gitarama",
                "code": "gitarama",
                "name": "Gitarama",
                "pk": "RW__gitarama"
            },
            {
                "_id": "kibungo",
                "code": "kibungo",
                "name": "Kibungo",
                "pk": "RW__kibungo"
            },
            {
                "_id": "kibuye",
                "code": "kibuye",
                "name": "Kibuye",
                "pk": "RW__kibuye"
            },
            {
                "_id": "kigali-ngali",
                "code": "kigali-ngali",
                "name": "Kigali-ngali",
                "pk": "RW__kigali-ngali"
            },
            {
                "_id": "ruhengeri",
                "code": "ruhengeri",
                "name": "Ruhengeri",
                "pk": "RW__ruhengeri"
            }
        ],
        "pk": "RW"
    },
    {
        "_id": "SH",
        "code": "SH",
        "name": "Sainte-Hélène",
        "states": [
            {
                "_id": "ascension",
                "code": "ascension",
                "name": "Ascension",
                "pk": "SH__ascension"
            },
            {
                "_id": "gough_island",
                "code": "gough_island",
                "name": "Gough Island",
                "pk": "SH__gough_island"
            },
            {
                "_id": "saint_helena",
                "code": "saint_helena",
                "name": "Saint Helena",
                "pk": "SH__saint_helena"
            },
            {
                "_id": "tristan_da_cunha",
                "code": "tristan_da_cunha",
                "name": "Tristan da Cunha",
                "pk": "SH__tristan_da_cunha"
            }
        ],
        "pk": "SH"
    },
    {
        "_id": "KN",
        "code": "KN",
        "name": "Saint-Christophe-et-Niévès",
        "states": [
            {
                "_id": "christ_church_nichola_town",
                "code": "christ_church_nichola_town",
                "name": "Christ Church Nichola Town",
                "pk": "KN__christ_church_nichola_town"
            },
            {
                "_id": "saint_anne_sandy_point",
                "code": "saint_anne_sandy_point",
                "name": "Saint Anne Sandy Point",
                "pk": "KN__saint_anne_sandy_point"
            },
            {
                "_id": "saint_george_basseterre",
                "code": "saint_george_basseterre",
                "name": "Saint George Basseterre",
                "pk": "KN__saint_george_basseterre"
            },
            {
                "_id": "saint_george_gingerland",
                "code": "saint_george_gingerland",
                "name": "Saint George Gingerland",
                "pk": "KN__saint_george_gingerland"
            },
            {
                "_id": "saint_james_windward",
                "code": "saint_james_windward",
                "name": "Saint James Windward",
                "pk": "KN__saint_james_windward"
            },
            {
                "_id": "saint_john_capesterre",
                "code": "saint_john_capesterre",
                "name": "Saint John Capesterre",
                "pk": "KN__saint_john_capesterre"
            },
            {
                "_id": "saint_john_figtree",
                "code": "saint_john_figtree",
                "name": "Saint John Figtree",
                "pk": "KN__saint_john_figtree"
            },
            {
                "_id": "saint_mary_cayon",
                "code": "saint_mary_cayon",
                "name": "Saint Mary Cayon",
                "pk": "KN__saint_mary_cayon"
            },
            {
                "_id": "saint_paul_capesterre",
                "code": "saint_paul_capesterre",
                "name": "Saint Paul Capesterre",
                "pk": "KN__saint_paul_capesterre"
            },
            {
                "_id": "saint_paul_charlestown",
                "code": "saint_paul_charlestown",
                "name": "Saint Paul Charlestown",
                "pk": "KN__saint_paul_charlestown"
            },
            {
                "_id": "saint_peter_basseterre",
                "code": "saint_peter_basseterre",
                "name": "Saint Peter Basseterre",
                "pk": "KN__saint_peter_basseterre"
            },
            {
                "_id": "saint_thomas_lowland",
                "code": "saint_thomas_lowland",
                "name": "Saint Thomas Lowland",
                "pk": "KN__saint_thomas_lowland"
            },
            {
                "_id": "saint_thomas_middle_island",
                "code": "saint_thomas_middle_island",
                "name": "Saint Thomas Middle Island",
                "pk": "KN__saint_thomas_middle_island"
            },
            {
                "_id": "trinity_palmetto_point",
                "code": "trinity_palmetto_point",
                "name": "Trinity Palmetto Point",
                "pk": "KN__trinity_palmetto_point"
            }
        ],
        "pk": "KN"
    },
    {
        "_id": "LC",
        "code": "LC",
        "name": "Sainte-Lucie",
        "states": [
            {
                "_id": "anse-la-raye",
                "code": "anse-la-raye",
                "name": "Anse-la-Raye",
                "pk": "LC__anse-la-raye"
            },
            {
                "_id": "canaries",
                "code": "canaries",
                "name": "Canaries",
                "pk": "LC__canaries"
            },
            {
                "_id": "castries",
                "code": "castries",
                "name": "Castries",
                "pk": "LC__castries"
            },
            {
                "_id": "choiseul",
                "code": "choiseul",
                "name": "Choiseul",
                "pk": "LC__choiseul"
            },
            {
                "_id": "dennery",
                "code": "dennery",
                "name": "Dennery",
                "pk": "LC__dennery"
            },
            {
                "_id": "gros_inlet",
                "code": "gros_inlet",
                "name": "Gros Inlet",
                "pk": "LC__gros_inlet"
            },
            {
                "_id": "laborie",
                "code": "laborie",
                "name": "Laborie",
                "pk": "LC__laborie"
            },
            {
                "_id": "micoud",
                "code": "micoud",
                "name": "Micoud",
                "pk": "LC__micoud"
            },
            {
                "_id": "soufriere",
                "code": "soufriere",
                "name": "Soufriere",
                "pk": "LC__soufriere"
            },
            {
                "_id": "vieux_fort",
                "code": "vieux_fort",
                "name": "Vieux Fort",
                "pk": "LC__vieux_fort"
            }
        ],
        "pk": "LC"
    },
    {
        "_id": "PM",
        "code": "PM",
        "name": "Saint-Pierre-et-Miquelon",
        "states": [
            {
                "_id": "miquelon-langlade",
                "code": "miquelon-langlade",
                "name": "Miquelon-Langlade",
                "pk": "PM__miquelon-langlade"
            },
            {
                "_id": "saint-pierre",
                "code": "saint-pierre",
                "name": "Saint-Pierre",
                "pk": "PM__saint-pierre"
            }
        ],
        "pk": "PM"
    },
    {
        "_id": "VC",
        "code": "VC",
        "name": "Saint-Vincent-et-les-Grenadines",
        "states": [
            {
                "_id": "charlotte",
                "code": "charlotte",
                "name": "Charlotte",
                "pk": "VC__charlotte"
            },
            {
                "_id": "grenadines",
                "code": "grenadines",
                "name": "Grenadines",
                "pk": "VC__grenadines"
            },
            {
                "_id": "saint_andrew",
                "code": "saint_andrew",
                "name": "Saint Andrew",
                "pk": "VC__saint_andrew"
            },
            {
                "_id": "saint_david",
                "code": "saint_david",
                "name": "Saint David",
                "pk": "VC__saint_david"
            },
            {
                "_id": "saint_george",
                "code": "saint_george",
                "name": "Saint George",
                "pk": "VC__saint_george"
            },
            {
                "_id": "saint_patrick",
                "code": "saint_patrick",
                "name": "Saint Patrick",
                "pk": "VC__saint_patrick"
            }
        ],
        "pk": "VC"
    },
    {
        "_id": "WS",
        "code": "WS",
        "name": "Samoa",
        "states": [
            {
                "_id": "aana",
                "code": "aana",
                "name": "Aana",
                "pk": "WS__aana"
            },
            {
                "_id": "aiga-i-le-tai",
                "code": "aiga-i-le-tai",
                "name": "Aiga-i-le-Tai",
                "pk": "WS__aiga-i-le-tai"
            },
            {
                "_id": "atua",
                "code": "atua",
                "name": "Atua",
                "pk": "WS__atua"
            },
            {
                "_id": "faasaleleaga",
                "code": "faasaleleaga",
                "name": "Faasaleleaga",
                "pk": "WS__faasaleleaga"
            },
            {
                "_id": "gagaemauga",
                "code": "gagaemauga",
                "name": "Gagaemauga",
                "pk": "WS__gagaemauga"
            },
            {
                "_id": "gagaifomauga",
                "code": "gagaifomauga",
                "name": "Gagaifomauga",
                "pk": "WS__gagaifomauga"
            },
            {
                "_id": "palauli",
                "code": "palauli",
                "name": "Palauli",
                "pk": "WS__palauli"
            },
            {
                "_id": "satupaitea",
                "code": "satupaitea",
                "name": "Satupaitea",
                "pk": "WS__satupaitea"
            },
            {
                "_id": "tuamasaga",
                "code": "tuamasaga",
                "name": "Tuamasaga",
                "pk": "WS__tuamasaga"
            },
            {
                "_id": "vaa-o-fonoti",
                "code": "vaa-o-fonoti",
                "name": "Vaa-o-Fonoti",
                "pk": "WS__vaa-o-fonoti"
            },
            {
                "_id": "vaisigano",
                "code": "vaisigano",
                "name": "Vaisigano",
                "pk": "WS__vaisigano"
            }
        ],
        "pk": "WS"
    },
    {
        "_id": "SM",
        "code": "SM",
        "name": "Saint-Marin",
        "states": [
            {
                "_id": "acquaviva",
                "code": "acquaviva",
                "name": "Acquaviva",
                "pk": "SM__acquaviva"
            },
            {
                "_id": "borgo_maggiore",
                "code": "borgo_maggiore",
                "name": "Borgo Maggiore",
                "pk": "SM__borgo_maggiore"
            },
            {
                "_id": "chiesanuova",
                "code": "chiesanuova",
                "name": "Chiesanuova",
                "pk": "SM__chiesanuova"
            },
            {
                "_id": "domagnano",
                "code": "domagnano",
                "name": "Domagnano",
                "pk": "SM__domagnano"
            },
            {
                "_id": "faetano",
                "code": "faetano",
                "name": "Faetano",
                "pk": "SM__faetano"
            },
            {
                "_id": "fiorentino",
                "code": "fiorentino",
                "name": "Fiorentino",
                "pk": "SM__fiorentino"
            },
            {
                "_id": "montegiardino",
                "code": "montegiardino",
                "name": "Montegiardino",
                "pk": "SM__montegiardino"
            },
            {
                "_id": "san_marino",
                "code": "san_marino",
                "name": "San Marino",
                "pk": "SM__san_marino"
            },
            {
                "_id": "serravalle",
                "code": "serravalle",
                "name": "Serravalle",
                "pk": "SM__serravalle"
            }
        ],
        "pk": "SM"
    },
    {
        "_id": "ST",
        "code": "ST",
        "name": "Sao Tomé-et-Principe",
        "states": [
            {
                "_id": "agua_grande",
                "code": "agua_grande",
                "name": "Agua Grande",
                "pk": "ST__agua_grande"
            },
            {
                "_id": "cantagalo",
                "code": "cantagalo",
                "name": "Cantagalo",
                "pk": "ST__cantagalo"
            },
            {
                "_id": "lemba",
                "code": "lemba",
                "name": "Lemba",
                "pk": "ST__lemba"
            },
            {
                "_id": "lobata",
                "code": "lobata",
                "name": "Lobata",
                "pk": "ST__lobata"
            },
            {
                "_id": "me-zochi",
                "code": "me-zochi",
                "name": "Me-Zochi",
                "pk": "ST__me-zochi"
            },
            {
                "_id": "pague",
                "code": "pague",
                "name": "Pague",
                "pk": "ST__pague"
            }
        ],
        "pk": "ST"
    },
    {
        "_id": "SA",
        "code": "SA",
        "name": "Arabie saoudite",
        "states": [
            {
                "_id": "al_khobar",
                "code": "al_khobar",
                "name": "Al Khobar",
                "pk": "SA__al_khobar"
            },
            {
                "_id": "aseer",
                "code": "aseer",
                "name": "Aseer",
                "pk": "SA__aseer"
            },
            {
                "_id": "ash_sharqiyah",
                "code": "ash_sharqiyah",
                "name": "Ash Sharqiyah",
                "pk": "SA__ash_sharqiyah"
            },
            {
                "_id": "asir",
                "code": "asir",
                "name": "Asir",
                "pk": "SA__asir"
            },
            {
                "_id": "central_province",
                "code": "central_province",
                "name": "Central Province",
                "pk": "SA__central_province"
            },
            {
                "_id": "eastern_province",
                "code": "eastern_province",
                "name": "Eastern Province",
                "pk": "SA__eastern_province"
            },
            {
                "_id": "hail",
                "code": "hail",
                "name": "Hail",
                "pk": "SA__hail"
            },
            {
                "_id": "jawf",
                "code": "jawf",
                "name": "Jawf",
                "pk": "SA__jawf"
            },
            {
                "_id": "jizan",
                "code": "jizan",
                "name": "Jizan",
                "pk": "SA__jizan"
            },
            {
                "_id": "makkah",
                "code": "makkah",
                "name": "Makkah",
                "pk": "SA__makkah"
            },
            {
                "_id": "najran",
                "code": "najran",
                "name": "Najran",
                "pk": "SA__najran"
            },
            {
                "_id": "qasim",
                "code": "qasim",
                "name": "Qasim",
                "pk": "SA__qasim"
            },
            {
                "_id": "tabuk",
                "code": "tabuk",
                "name": "Tabuk",
                "pk": "SA__tabuk"
            },
            {
                "_id": "western_province",
                "code": "western_province",
                "name": "Western Province",
                "pk": "SA__western_province"
            },
            {
                "_id": "al-bahah",
                "code": "al-bahah",
                "name": "al-Bahah",
                "pk": "SA__al-bahah"
            },
            {
                "_id": "al-hudud-ash-shamaliyah",
                "code": "al-hudud-ash-shamaliyah",
                "name": "al-Hudud-ash-Shamaliyah",
                "pk": "SA__al-hudud-ash-shamaliyah"
            },
            {
                "_id": "al-madinah",
                "code": "al-madinah",
                "name": "al-Madinah",
                "pk": "SA__al-madinah"
            },
            {
                "_id": "ar-riyad",
                "code": "ar-riyad",
                "name": "ar-Riyad",
                "pk": "SA__ar-riyad"
            }
        ],
        "pk": "SA"
    },
    {
        "_id": "SN",
        "code": "SN",
        "name": "Sénégal",
        "states": [
            {
                "_id": "dakar",
                "code": "dakar",
                "name": "Dakar",
                "pk": "SN__dakar"
            },
            {
                "_id": "diourbel",
                "code": "diourbel",
                "name": "Diourbel",
                "pk": "SN__diourbel"
            },
            {
                "_id": "fatick",
                "code": "fatick",
                "name": "Fatick",
                "pk": "SN__fatick"
            },
            {
                "_id": "kaolack",
                "code": "kaolack",
                "name": "Kaolack",
                "pk": "SN__kaolack"
            },
            {
                "_id": "kolda",
                "code": "kolda",
                "name": "Kolda",
                "pk": "SN__kolda"
            },
            {
                "_id": "louga",
                "code": "louga",
                "name": "Louga",
                "pk": "SN__louga"
            },
            {
                "_id": "saint-louis",
                "code": "saint-louis",
                "name": "Saint-Louis",
                "pk": "SN__saint-louis"
            },
            {
                "_id": "tambacounda",
                "code": "tambacounda",
                "name": "Tambacounda",
                "pk": "SN__tambacounda"
            },
            {
                "_id": "thies",
                "code": "thies",
                "name": "Thies",
                "pk": "SN__thies"
            },
            {
                "_id": "ziguinchor",
                "code": "ziguinchor",
                "name": "Ziguinchor",
                "pk": "SN__ziguinchor"
            }
        ],
        "pk": "SN"
    },
    {
        "_id": "RS",
        "code": "RS",
        "name": "Serbie",
        "states": [
            {
                "_id": "central_serbia",
                "code": "central_serbia",
                "name": "Central Serbia",
                "pk": "RS__central_serbia"
            },
            {
                "_id": "kosovo_and_metohija",
                "code": "kosovo_and_metohija",
                "name": "Kosovo and Metohija",
                "pk": "RS__kosovo_and_metohija"
            },
            {
                "_id": "vojvodina",
                "code": "vojvodina",
                "name": "Vojvodina",
                "pk": "RS__vojvodina"
            }
        ],
        "pk": "RS"
    },
    {
        "_id": "SC",
        "code": "SC",
        "name": "Seychelles",
        "states": [
            {
                "_id": "anse_boileau",
                "code": "anse_boileau",
                "name": "Anse Boileau",
                "pk": "SC__anse_boileau"
            },
            {
                "_id": "anse_royale",
                "code": "anse_royale",
                "name": "Anse Royale",
                "pk": "SC__anse_royale"
            },
            {
                "_id": "cascade",
                "code": "cascade",
                "name": "Cascade",
                "pk": "SC__cascade"
            },
            {
                "_id": "takamaka",
                "code": "takamaka",
                "name": "Takamaka",
                "pk": "SC__takamaka"
            },
            {
                "_id": "victoria",
                "code": "victoria",
                "name": "Victoria",
                "pk": "SC__victoria"
            }
        ],
        "pk": "SC"
    },
    {
        "_id": "SL",
        "code": "SL",
        "name": "Sierra Leone",
        "states": [
            {
                "_id": "eastern",
                "code": "eastern",
                "name": "Eastern",
                "pk": "SL__eastern"
            },
            {
                "_id": "northern",
                "code": "northern",
                "name": "Northern",
                "pk": "SL__northern"
            },
            {
                "_id": "southern",
                "code": "southern",
                "name": "Southern",
                "pk": "SL__southern"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "SL__western"
            }
        ],
        "pk": "SL"
    },
    {
        "_id": "SG",
        "code": "SG",
        "name": "Singapour",
        "states": [
            {
                "_id": "singapore",
                "code": "singapore",
                "name": "Singapore",
                "pk": "SG__singapore"
            }
        ],
        "pk": "SG"
    },
    {
        "_id": "SK",
        "code": "SK",
        "name": "Slovaquie",
        "states": [
            {
                "_id": "banskobystricky",
                "code": "banskobystricky",
                "name": "Banskobystricky",
                "pk": "SK__banskobystricky"
            },
            {
                "_id": "bratislavsky",
                "code": "bratislavsky",
                "name": "Bratislavsky",
                "pk": "SK__bratislavsky"
            },
            {
                "_id": "kosicky",
                "code": "kosicky",
                "name": "Kosicky",
                "pk": "SK__kosicky"
            },
            {
                "_id": "nitriansky",
                "code": "nitriansky",
                "name": "Nitriansky",
                "pk": "SK__nitriansky"
            },
            {
                "_id": "presovsky",
                "code": "presovsky",
                "name": "Presovsky",
                "pk": "SK__presovsky"
            },
            {
                "_id": "trenciansky",
                "code": "trenciansky",
                "name": "Trenciansky",
                "pk": "SK__trenciansky"
            },
            {
                "_id": "trnavsky",
                "code": "trnavsky",
                "name": "Trnavsky",
                "pk": "SK__trnavsky"
            },
            {
                "_id": "zilinsky",
                "code": "zilinsky",
                "name": "Zilinsky",
                "pk": "SK__zilinsky"
            }
        ],
        "pk": "SK"
    },
    {
        "_id": "SI",
        "code": "SI",
        "name": "Slovénie",
        "states": [
            {
                "_id": "benedikt",
                "code": "benedikt",
                "name": "Benedikt",
                "pk": "SI__benedikt"
            },
            {
                "_id": "gorenjska",
                "code": "gorenjska",
                "name": "Gorenjska",
                "pk": "SI__gorenjska"
            },
            {
                "_id": "gorishka",
                "code": "gorishka",
                "name": "Gorishka",
                "pk": "SI__gorishka"
            },
            {
                "_id": "jugovzhodna_slovenija",
                "code": "jugovzhodna_slovenija",
                "name": "Jugovzhodna Slovenija",
                "pk": "SI__jugovzhodna_slovenija"
            },
            {
                "_id": "koroshka",
                "code": "koroshka",
                "name": "Koroshka",
                "pk": "SI__koroshka"
            },
            {
                "_id": "notranjsko-krashka",
                "code": "notranjsko-krashka",
                "name": "Notranjsko-krashka",
                "pk": "SI__notranjsko-krashka"
            },
            {
                "_id": "obalno-krashka",
                "code": "obalno-krashka",
                "name": "Obalno-krashka",
                "pk": "SI__obalno-krashka"
            },
            {
                "_id": "obcina_domzale",
                "code": "obcina_domzale",
                "name": "Obcina Domzale",
                "pk": "SI__obcina_domzale"
            },
            {
                "_id": "obcina_vitanje",
                "code": "obcina_vitanje",
                "name": "Obcina Vitanje",
                "pk": "SI__obcina_vitanje"
            },
            {
                "_id": "osrednjeslovenska",
                "code": "osrednjeslovenska",
                "name": "Osrednjeslovenska",
                "pk": "SI__osrednjeslovenska"
            },
            {
                "_id": "podravska",
                "code": "podravska",
                "name": "Podravska",
                "pk": "SI__podravska"
            },
            {
                "_id": "pomurska",
                "code": "pomurska",
                "name": "Pomurska",
                "pk": "SI__pomurska"
            },
            {
                "_id": "savinjska",
                "code": "savinjska",
                "name": "Savinjska",
                "pk": "SI__savinjska"
            },
            {
                "_id": "slovenian_littoral",
                "code": "slovenian_littoral",
                "name": "Slovenian Littoral",
                "pk": "SI__slovenian_littoral"
            },
            {
                "_id": "spodnjeposavska",
                "code": "spodnjeposavska",
                "name": "Spodnjeposavska",
                "pk": "SI__spodnjeposavska"
            },
            {
                "_id": "zasavska",
                "code": "zasavska",
                "name": "Zasavska",
                "pk": "SI__zasavska"
            }
        ],
        "pk": "SI"
    },
    {
        "_id": "XG",
        "code": "XG",
        "name": "Îles mineures éloignées du Royaume-Uni",
        "states": [
            {
                "_id": "pitcairn",
                "code": "pitcairn",
                "name": "Pitcairn",
                "pk": "XG__pitcairn"
            }
        ],
        "pk": "XG"
    },
    {
        "_id": "SB",
        "code": "SB",
        "name": "Îles Salomon",
        "states": [
            {
                "_id": "central",
                "code": "central",
                "name": "Central",
                "pk": "SB__central"
            },
            {
                "_id": "choiseul",
                "code": "choiseul",
                "name": "Choiseul",
                "pk": "SB__choiseul"
            },
            {
                "_id": "guadalcanal",
                "code": "guadalcanal",
                "name": "Guadalcanal",
                "pk": "SB__guadalcanal"
            },
            {
                "_id": "isabel",
                "code": "isabel",
                "name": "Isabel",
                "pk": "SB__isabel"
            },
            {
                "_id": "makira_and_ulawa",
                "code": "makira_and_ulawa",
                "name": "Makira and Ulawa",
                "pk": "SB__makira_and_ulawa"
            },
            {
                "_id": "malaita",
                "code": "malaita",
                "name": "Malaita",
                "pk": "SB__malaita"
            },
            {
                "_id": "rennell_and_bellona",
                "code": "rennell_and_bellona",
                "name": "Rennell and Bellona",
                "pk": "SB__rennell_and_bellona"
            },
            {
                "_id": "temotu",
                "code": "temotu",
                "name": "Temotu",
                "pk": "SB__temotu"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "SB__western"
            }
        ],
        "pk": "SB"
    },
    {
        "_id": "SO",
        "code": "SO",
        "name": "Somalie",
        "states": [
            {
                "_id": "awdal",
                "code": "awdal",
                "name": "Awdal",
                "pk": "SO__awdal"
            },
            {
                "_id": "bakol",
                "code": "bakol",
                "name": "Bakol",
                "pk": "SO__bakol"
            },
            {
                "_id": "banadir",
                "code": "banadir",
                "name": "Banadir",
                "pk": "SO__banadir"
            },
            {
                "_id": "bari",
                "code": "bari",
                "name": "Bari",
                "pk": "SO__bari"
            },
            {
                "_id": "bay",
                "code": "bay",
                "name": "Bay",
                "pk": "SO__bay"
            },
            {
                "_id": "galgudug",
                "code": "galgudug",
                "name": "Galgudug",
                "pk": "SO__galgudug"
            },
            {
                "_id": "gedo",
                "code": "gedo",
                "name": "Gedo",
                "pk": "SO__gedo"
            },
            {
                "_id": "hiran",
                "code": "hiran",
                "name": "Hiran",
                "pk": "SO__hiran"
            },
            {
                "_id": "jubbada_hose",
                "code": "jubbada_hose",
                "name": "Jubbada Hose",
                "pk": "SO__jubbada_hose"
            },
            {
                "_id": "jubbadha_dexe",
                "code": "jubbadha_dexe",
                "name": "Jubbadha Dexe",
                "pk": "SO__jubbadha_dexe"
            },
            {
                "_id": "mudug",
                "code": "mudug",
                "name": "Mudug",
                "pk": "SO__mudug"
            },
            {
                "_id": "nugal",
                "code": "nugal",
                "name": "Nugal",
                "pk": "SO__nugal"
            },
            {
                "_id": "sanag",
                "code": "sanag",
                "name": "Sanag",
                "pk": "SO__sanag"
            },
            {
                "_id": "shabellaha_dhexe",
                "code": "shabellaha_dhexe",
                "name": "Shabellaha Dhexe",
                "pk": "SO__shabellaha_dhexe"
            },
            {
                "_id": "shabellaha_hose",
                "code": "shabellaha_hose",
                "name": "Shabellaha Hose",
                "pk": "SO__shabellaha_hose"
            },
            {
                "_id": "togdher",
                "code": "togdher",
                "name": "Togdher",
                "pk": "SO__togdher"
            },
            {
                "_id": "woqoyi_galbed",
                "code": "woqoyi_galbed",
                "name": "Woqoyi Galbed",
                "pk": "SO__woqoyi_galbed"
            }
        ],
        "pk": "SO"
    },
    {
        "_id": "ZA",
        "code": "ZA",
        "name": "Afrique du Sud",
        "states": [
            {
                "_id": "eastern_cape",
                "code": "eastern_cape",
                "name": "Eastern Cape",
                "pk": "ZA__eastern_cape"
            },
            {
                "_id": "free_state",
                "code": "free_state",
                "name": "Free State",
                "pk": "ZA__free_state"
            },
            {
                "_id": "gauteng",
                "code": "gauteng",
                "name": "Gauteng",
                "pk": "ZA__gauteng"
            },
            {
                "_id": "kempton_park",
                "code": "kempton_park",
                "name": "Kempton Park",
                "pk": "ZA__kempton_park"
            },
            {
                "_id": "kramerville",
                "code": "kramerville",
                "name": "Kramerville",
                "pk": "ZA__kramerville"
            },
            {
                "_id": "kwazulu_natal",
                "code": "kwazulu_natal",
                "name": "KwaZulu Natal",
                "pk": "ZA__kwazulu_natal"
            },
            {
                "_id": "limpopo",
                "code": "limpopo",
                "name": "Limpopo",
                "pk": "ZA__limpopo"
            },
            {
                "_id": "mpumalanga",
                "code": "mpumalanga",
                "name": "Mpumalanga",
                "pk": "ZA__mpumalanga"
            },
            {
                "_id": "north_west",
                "code": "north_west",
                "name": "North West",
                "pk": "ZA__north_west"
            },
            {
                "_id": "northern_cape",
                "code": "northern_cape",
                "name": "Northern Cape",
                "pk": "ZA__northern_cape"
            },
            {
                "_id": "parow",
                "code": "parow",
                "name": "Parow",
                "pk": "ZA__parow"
            },
            {
                "_id": "table_view",
                "code": "table_view",
                "name": "Table View",
                "pk": "ZA__table_view"
            },
            {
                "_id": "umtentweni",
                "code": "umtentweni",
                "name": "Umtentweni",
                "pk": "ZA__umtentweni"
            },
            {
                "_id": "western_cape",
                "code": "western_cape",
                "name": "Western Cape",
                "pk": "ZA__western_cape"
            }
        ],
        "pk": "ZA"
    },
    {
        "_id": "GS",
        "code": "GS",
        "name": "Géorgie du Sud et îles Sandwich du Sud",
        "states": [
            {
                "_id": "south_georgia",
                "code": "south_georgia",
                "name": "South Georgia",
                "pk": "GS__south_georgia"
            }
        ],
        "pk": "GS"
    },
    {
        "_id": "SS",
        "code": "SS",
        "name": "Soudan du Sud",
        "states": [
            {
                "_id": "central_equatoria",
                "code": "central_equatoria",
                "name": "Central Equatoria",
                "pk": "SS__central_equatoria"
            }
        ],
        "pk": "SS"
    },
    {
        "_id": "ES",
        "code": "ES",
        "name": "Espagne",
        "states": [
            {
                "_id": "a_coruna",
                "code": "a_coruna",
                "name": "A Coruna",
                "pk": "ES__a_coruna"
            },
            {
                "_id": "alacant",
                "code": "alacant",
                "name": "Alacant",
                "pk": "ES__alacant"
            },
            {
                "_id": "alava",
                "code": "alava",
                "name": "Alava",
                "pk": "ES__alava"
            },
            {
                "_id": "albacete",
                "code": "albacete",
                "name": "Albacete",
                "pk": "ES__albacete"
            },
            {
                "_id": "almeria",
                "code": "almeria",
                "name": "Almeria",
                "pk": "ES__almeria"
            },
            {
                "_id": "andalucia",
                "code": "andalucia",
                "name": "Andalucia",
                "pk": "ES__andalucia"
            },
            {
                "_id": "asturias",
                "code": "asturias",
                "name": "Asturias",
                "pk": "ES__asturias"
            },
            {
                "_id": "avila",
                "code": "avila",
                "name": "Avila",
                "pk": "ES__avila"
            },
            {
                "_id": "badajoz",
                "code": "badajoz",
                "name": "Badajoz",
                "pk": "ES__badajoz"
            },
            {
                "_id": "balears",
                "code": "balears",
                "name": "Balears",
                "pk": "ES__balears"
            },
            {
                "_id": "barcelona",
                "code": "barcelona",
                "name": "Barcelona",
                "pk": "ES__barcelona"
            },
            {
                "_id": "bertamirans",
                "code": "bertamirans",
                "name": "Bertamirans",
                "pk": "ES__bertamirans"
            },
            {
                "_id": "biscay",
                "code": "biscay",
                "name": "Biscay",
                "pk": "ES__biscay"
            },
            {
                "_id": "burgos",
                "code": "burgos",
                "name": "Burgos",
                "pk": "ES__burgos"
            },
            {
                "_id": "caceres",
                "code": "caceres",
                "name": "Caceres",
                "pk": "ES__caceres"
            },
            {
                "_id": "cadiz",
                "code": "cadiz",
                "name": "Cadiz",
                "pk": "ES__cadiz"
            },
            {
                "_id": "cantabria",
                "code": "cantabria",
                "name": "Cantabria",
                "pk": "ES__cantabria"
            },
            {
                "_id": "castello",
                "code": "castello",
                "name": "Castello",
                "pk": "ES__castello"
            },
            {
                "_id": "catalunya",
                "code": "catalunya",
                "name": "Catalunya",
                "pk": "ES__catalunya"
            },
            {
                "_id": "ceuta",
                "code": "ceuta",
                "name": "Ceuta",
                "pk": "ES__ceuta"
            },
            {
                "_id": "ciudad_real",
                "code": "ciudad_real",
                "name": "Ciudad Real",
                "pk": "ES__ciudad_real"
            },
            {
                "_id": "comunidad_autonoma_de_canarias",
                "code": "comunidad_autonoma_de_canarias",
                "name": "Comunidad Autonoma de Canarias",
                "pk": "ES__comunidad_autonoma_de_canarias"
            },
            {
                "_id": "comunidad_autonoma_de_cataluna",
                "code": "comunidad_autonoma_de_cataluna",
                "name": "Comunidad Autonoma de Cataluna",
                "pk": "ES__comunidad_autonoma_de_cataluna"
            },
            {
                "_id": "comunidad_autonoma_de_galicia",
                "code": "comunidad_autonoma_de_galicia",
                "name": "Comunidad Autonoma de Galicia",
                "pk": "ES__comunidad_autonoma_de_galicia"
            },
            {
                "_id": "comunidad_autonoma_de_las_isla",
                "code": "comunidad_autonoma_de_las_isla",
                "name": "Comunidad Autonoma de las Isla",
                "pk": "ES__comunidad_autonoma_de_las_isla"
            },
            {
                "_id": "comunidad_autonoma_del_princip",
                "code": "comunidad_autonoma_del_princip",
                "name": "Comunidad Autonoma del Princip",
                "pk": "ES__comunidad_autonoma_del_princip"
            },
            {
                "_id": "comunidad_valenciana",
                "code": "comunidad_valenciana",
                "name": "Comunidad Valenciana",
                "pk": "ES__comunidad_valenciana"
            },
            {
                "_id": "cordoba",
                "code": "cordoba",
                "name": "Cordoba",
                "pk": "ES__cordoba"
            },
            {
                "_id": "cuenca",
                "code": "cuenca",
                "name": "Cuenca",
                "pk": "ES__cuenca"
            },
            {
                "_id": "gipuzkoa",
                "code": "gipuzkoa",
                "name": "Gipuzkoa",
                "pk": "ES__gipuzkoa"
            },
            {
                "_id": "girona",
                "code": "girona",
                "name": "Girona",
                "pk": "ES__girona"
            },
            {
                "_id": "granada",
                "code": "granada",
                "name": "Granada",
                "pk": "ES__granada"
            },
            {
                "_id": "guadalajara",
                "code": "guadalajara",
                "name": "Guadalajara",
                "pk": "ES__guadalajara"
            },
            {
                "_id": "guipuzcoa",
                "code": "guipuzcoa",
                "name": "Guipuzcoa",
                "pk": "ES__guipuzcoa"
            },
            {
                "_id": "huelva",
                "code": "huelva",
                "name": "Huelva",
                "pk": "ES__huelva"
            },
            {
                "_id": "huesca",
                "code": "huesca",
                "name": "Huesca",
                "pk": "ES__huesca"
            },
            {
                "_id": "jaen",
                "code": "jaen",
                "name": "Jaen",
                "pk": "ES__jaen"
            },
            {
                "_id": "la_rioja",
                "code": "la_rioja",
                "name": "La Rioja",
                "pk": "ES__la_rioja"
            },
            {
                "_id": "las_palmas",
                "code": "las_palmas",
                "name": "Las Palmas",
                "pk": "ES__las_palmas"
            },
            {
                "_id": "leon",
                "code": "leon",
                "name": "Leon",
                "pk": "ES__leon"
            },
            {
                "_id": "lerida",
                "code": "lerida",
                "name": "Lerida",
                "pk": "ES__lerida"
            },
            {
                "_id": "lleida",
                "code": "lleida",
                "name": "Lleida",
                "pk": "ES__lleida"
            },
            {
                "_id": "lugo",
                "code": "lugo",
                "name": "Lugo",
                "pk": "ES__lugo"
            },
            {
                "_id": "madrid",
                "code": "madrid",
                "name": "Madrid",
                "pk": "ES__madrid"
            },
            {
                "_id": "malaga",
                "code": "malaga",
                "name": "Malaga",
                "pk": "ES__malaga"
            },
            {
                "_id": "melilla",
                "code": "melilla",
                "name": "Melilla",
                "pk": "ES__melilla"
            },
            {
                "_id": "murcia",
                "code": "murcia",
                "name": "Murcia",
                "pk": "ES__murcia"
            },
            {
                "_id": "navarra",
                "code": "navarra",
                "name": "Navarra",
                "pk": "ES__navarra"
            },
            {
                "_id": "ourense",
                "code": "ourense",
                "name": "Ourense",
                "pk": "ES__ourense"
            },
            {
                "_id": "pais_vasco",
                "code": "pais_vasco",
                "name": "Pais Vasco",
                "pk": "ES__pais_vasco"
            },
            {
                "_id": "palencia",
                "code": "palencia",
                "name": "Palencia",
                "pk": "ES__palencia"
            },
            {
                "_id": "pontevedra",
                "code": "pontevedra",
                "name": "Pontevedra",
                "pk": "ES__pontevedra"
            },
            {
                "_id": "salamanca",
                "code": "salamanca",
                "name": "Salamanca",
                "pk": "ES__salamanca"
            },
            {
                "_id": "santa_cruz_de_tenerife",
                "code": "santa_cruz_de_tenerife",
                "name": "Santa Cruz de Tenerife",
                "pk": "ES__santa_cruz_de_tenerife"
            },
            {
                "_id": "segovia",
                "code": "segovia",
                "name": "Segovia",
                "pk": "ES__segovia"
            },
            {
                "_id": "sevilla",
                "code": "sevilla",
                "name": "Sevilla",
                "pk": "ES__sevilla"
            },
            {
                "_id": "soria",
                "code": "soria",
                "name": "Soria",
                "pk": "ES__soria"
            },
            {
                "_id": "tarragona",
                "code": "tarragona",
                "name": "Tarragona",
                "pk": "ES__tarragona"
            },
            {
                "_id": "tenerife",
                "code": "tenerife",
                "name": "Tenerife",
                "pk": "ES__tenerife"
            },
            {
                "_id": "teruel",
                "code": "teruel",
                "name": "Teruel",
                "pk": "ES__teruel"
            },
            {
                "_id": "toledo",
                "code": "toledo",
                "name": "Toledo",
                "pk": "ES__toledo"
            },
            {
                "_id": "valencia",
                "code": "valencia",
                "name": "Valencia",
                "pk": "ES__valencia"
            },
            {
                "_id": "valladolid",
                "code": "valladolid",
                "name": "Valladolid",
                "pk": "ES__valladolid"
            },
            {
                "_id": "vizcaya",
                "code": "vizcaya",
                "name": "Vizcaya",
                "pk": "ES__vizcaya"
            },
            {
                "_id": "zamora",
                "code": "zamora",
                "name": "Zamora",
                "pk": "ES__zamora"
            },
            {
                "_id": "zaragoza",
                "code": "zaragoza",
                "name": "Zaragoza",
                "pk": "ES__zaragoza"
            }
        ],
        "only_shipping": true,
        "pk": "ES"
    },
    {
        "_id": "LK",
        "code": "LK",
        "name": "Sri Lanka",
        "states": [
            {
                "_id": "amparai",
                "code": "amparai",
                "name": "Amparai",
                "pk": "LK__amparai"
            },
            {
                "_id": "anuradhapuraya",
                "code": "anuradhapuraya",
                "name": "Anuradhapuraya",
                "pk": "LK__anuradhapuraya"
            },
            {
                "_id": "badulla",
                "code": "badulla",
                "name": "Badulla",
                "pk": "LK__badulla"
            },
            {
                "_id": "boralesgamuwa",
                "code": "boralesgamuwa",
                "name": "Boralesgamuwa",
                "pk": "LK__boralesgamuwa"
            },
            {
                "_id": "colombo",
                "code": "colombo",
                "name": "Colombo",
                "pk": "LK__colombo"
            },
            {
                "_id": "galla",
                "code": "galla",
                "name": "Galla",
                "pk": "LK__galla"
            },
            {
                "_id": "gampaha",
                "code": "gampaha",
                "name": "Gampaha",
                "pk": "LK__gampaha"
            },
            {
                "_id": "hambantota",
                "code": "hambantota",
                "name": "Hambantota",
                "pk": "LK__hambantota"
            },
            {
                "_id": "kalatura",
                "code": "kalatura",
                "name": "Kalatura",
                "pk": "LK__kalatura"
            },
            {
                "_id": "kegalla",
                "code": "kegalla",
                "name": "Kegalla",
                "pk": "LK__kegalla"
            },
            {
                "_id": "kilinochchi",
                "code": "kilinochchi",
                "name": "Kilinochchi",
                "pk": "LK__kilinochchi"
            },
            {
                "_id": "kurunegala",
                "code": "kurunegala",
                "name": "Kurunegala",
                "pk": "LK__kurunegala"
            },
            {
                "_id": "madakalpuwa",
                "code": "madakalpuwa",
                "name": "Madakalpuwa",
                "pk": "LK__madakalpuwa"
            },
            {
                "_id": "maha_nuwara",
                "code": "maha_nuwara",
                "name": "Maha Nuwara",
                "pk": "LK__maha_nuwara"
            },
            {
                "_id": "malwana",
                "code": "malwana",
                "name": "Malwana",
                "pk": "LK__malwana"
            },
            {
                "_id": "mannarama",
                "code": "mannarama",
                "name": "Mannarama",
                "pk": "LK__mannarama"
            },
            {
                "_id": "matale",
                "code": "matale",
                "name": "Matale",
                "pk": "LK__matale"
            },
            {
                "_id": "matara",
                "code": "matara",
                "name": "Matara",
                "pk": "LK__matara"
            },
            {
                "_id": "monaragala",
                "code": "monaragala",
                "name": "Monaragala",
                "pk": "LK__monaragala"
            },
            {
                "_id": "mullaitivu",
                "code": "mullaitivu",
                "name": "Mullaitivu",
                "pk": "LK__mullaitivu"
            },
            {
                "_id": "north_eastern_province",
                "code": "north_eastern_province",
                "name": "North Eastern Province",
                "pk": "LK__north_eastern_province"
            },
            {
                "_id": "north_western_province",
                "code": "north_western_province",
                "name": "North Western Province",
                "pk": "LK__north_western_province"
            },
            {
                "_id": "nuwara_eliya",
                "code": "nuwara_eliya",
                "name": "Nuwara Eliya",
                "pk": "LK__nuwara_eliya"
            },
            {
                "_id": "polonnaruwa",
                "code": "polonnaruwa",
                "name": "Polonnaruwa",
                "pk": "LK__polonnaruwa"
            },
            {
                "_id": "puttalama",
                "code": "puttalama",
                "name": "Puttalama",
                "pk": "LK__puttalama"
            },
            {
                "_id": "ratnapuraya",
                "code": "ratnapuraya",
                "name": "Ratnapuraya",
                "pk": "LK__ratnapuraya"
            },
            {
                "_id": "southern_province",
                "code": "southern_province",
                "name": "Southern Province",
                "pk": "LK__southern_province"
            },
            {
                "_id": "tirikunamalaya",
                "code": "tirikunamalaya",
                "name": "Tirikunamalaya",
                "pk": "LK__tirikunamalaya"
            },
            {
                "_id": "tuscany",
                "code": "tuscany",
                "name": "Tuscany",
                "pk": "LK__tuscany"
            },
            {
                "_id": "vavuniyawa",
                "code": "vavuniyawa",
                "name": "Vavuniyawa",
                "pk": "LK__vavuniyawa"
            },
            {
                "_id": "western_province",
                "code": "western_province",
                "name": "Western Province",
                "pk": "LK__western_province"
            },
            {
                "_id": "yapanaya",
                "code": "yapanaya",
                "name": "Yapanaya",
                "pk": "LK__yapanaya"
            },
            {
                "_id": "kadawatha",
                "code": "kadawatha",
                "name": "kadawatha",
                "pk": "LK__kadawatha"
            }
        ],
        "pk": "LK"
    },
    {
        "_id": "SD",
        "code": "SD",
        "name": "Soudan",
        "states": [
            {
                "_id": "aali-an-nil",
                "code": "aali-an-nil",
                "name": "Aali-an-Nil",
                "pk": "SD__aali-an-nil"
            },
            {
                "_id": "bahr-al-jabal",
                "code": "bahr-al-jabal",
                "name": "Bahr-al-Jabal",
                "pk": "SD__bahr-al-jabal"
            },
            {
                "_id": "central_equatoria",
                "code": "central_equatoria",
                "name": "Central Equatoria",
                "pk": "SD__central_equatoria"
            },
            {
                "_id": "gharb_bahr-al-ghazal",
                "code": "gharb_bahr-al-ghazal",
                "name": "Gharb Bahr-al-Ghazal",
                "pk": "SD__gharb_bahr-al-ghazal"
            },
            {
                "_id": "gharb_darfur",
                "code": "gharb_darfur",
                "name": "Gharb Darfur",
                "pk": "SD__gharb_darfur"
            },
            {
                "_id": "gharb_kurdufan",
                "code": "gharb_kurdufan",
                "name": "Gharb Kurdufan",
                "pk": "SD__gharb_kurdufan"
            },
            {
                "_id": "gharb-al-istiwaiyah",
                "code": "gharb-al-istiwaiyah",
                "name": "Gharb-al-Istiwaiyah",
                "pk": "SD__gharb-al-istiwaiyah"
            },
            {
                "_id": "janub_darfur",
                "code": "janub_darfur",
                "name": "Janub Darfur",
                "pk": "SD__janub_darfur"
            },
            {
                "_id": "janub_kurdufan",
                "code": "janub_kurdufan",
                "name": "Janub Kurdufan",
                "pk": "SD__janub_kurdufan"
            },
            {
                "_id": "junqali",
                "code": "junqali",
                "name": "Junqali",
                "pk": "SD__junqali"
            },
            {
                "_id": "kassala",
                "code": "kassala",
                "name": "Kassala",
                "pk": "SD__kassala"
            },
            {
                "_id": "nahr-an-nil",
                "code": "nahr-an-nil",
                "name": "Nahr-an-Nil",
                "pk": "SD__nahr-an-nil"
            },
            {
                "_id": "shamal_bahr-al-ghazal",
                "code": "shamal_bahr-al-ghazal",
                "name": "Shamal Bahr-al-Ghazal",
                "pk": "SD__shamal_bahr-al-ghazal"
            },
            {
                "_id": "shamal_darfur",
                "code": "shamal_darfur",
                "name": "Shamal Darfur",
                "pk": "SD__shamal_darfur"
            },
            {
                "_id": "shamal_kurdufan",
                "code": "shamal_kurdufan",
                "name": "Shamal Kurdufan",
                "pk": "SD__shamal_kurdufan"
            },
            {
                "_id": "sharq-al-istiwaiyah",
                "code": "sharq-al-istiwaiyah",
                "name": "Sharq-al-Istiwaiyah",
                "pk": "SD__sharq-al-istiwaiyah"
            },
            {
                "_id": "sinnar",
                "code": "sinnar",
                "name": "Sinnar",
                "pk": "SD__sinnar"
            },
            {
                "_id": "warab",
                "code": "warab",
                "name": "Warab",
                "pk": "SD__warab"
            },
            {
                "_id": "wilayat_al_khartum",
                "code": "wilayat_al_khartum",
                "name": "Wilayat al Khartum",
                "pk": "SD__wilayat_al_khartum"
            },
            {
                "_id": "al-bahr-al-ahmar",
                "code": "al-bahr-al-ahmar",
                "name": "al-Bahr-al-Ahmar",
                "pk": "SD__al-bahr-al-ahmar"
            },
            {
                "_id": "al-buhayrat",
                "code": "al-buhayrat",
                "name": "al-Buhayrat",
                "pk": "SD__al-buhayrat"
            },
            {
                "_id": "al-jazirah",
                "code": "al-jazirah",
                "name": "al-Jazirah",
                "pk": "SD__al-jazirah"
            },
            {
                "_id": "al-khartum",
                "code": "al-khartum",
                "name": "al-Khartum",
                "pk": "SD__al-khartum"
            },
            {
                "_id": "al-qadarif",
                "code": "al-qadarif",
                "name": "al-Qadarif",
                "pk": "SD__al-qadarif"
            },
            {
                "_id": "al-wahdah",
                "code": "al-wahdah",
                "name": "al-Wahdah",
                "pk": "SD__al-wahdah"
            },
            {
                "_id": "an-nil-al-abyad",
                "code": "an-nil-al-abyad",
                "name": "an-Nil-al-Abyad",
                "pk": "SD__an-nil-al-abyad"
            },
            {
                "_id": "an-nil-al-azraq",
                "code": "an-nil-al-azraq",
                "name": "an-Nil-al-Azraq",
                "pk": "SD__an-nil-al-azraq"
            },
            {
                "_id": "ash-shamaliyah",
                "code": "ash-shamaliyah",
                "name": "ash-Shamaliyah",
                "pk": "SD__ash-shamaliyah"
            }
        ],
        "pk": "SD"
    },
    {
        "_id": "SR",
        "code": "SR",
        "name": "Suriname",
        "states": [
            {
                "_id": "brokopondo",
                "code": "brokopondo",
                "name": "Brokopondo",
                "pk": "SR__brokopondo"
            },
            {
                "_id": "commewijne",
                "code": "commewijne",
                "name": "Commewijne",
                "pk": "SR__commewijne"
            },
            {
                "_id": "coronie",
                "code": "coronie",
                "name": "Coronie",
                "pk": "SR__coronie"
            },
            {
                "_id": "marowijne",
                "code": "marowijne",
                "name": "Marowijne",
                "pk": "SR__marowijne"
            },
            {
                "_id": "nickerie",
                "code": "nickerie",
                "name": "Nickerie",
                "pk": "SR__nickerie"
            },
            {
                "_id": "para",
                "code": "para",
                "name": "Para",
                "pk": "SR__para"
            },
            {
                "_id": "paramaribo",
                "code": "paramaribo",
                "name": "Paramaribo",
                "pk": "SR__paramaribo"
            },
            {
                "_id": "saramacca",
                "code": "saramacca",
                "name": "Saramacca",
                "pk": "SR__saramacca"
            },
            {
                "_id": "wanica",
                "code": "wanica",
                "name": "Wanica",
                "pk": "SR__wanica"
            }
        ],
        "pk": "SR"
    },
    {
        "_id": "SJ",
        "code": "SJ",
        "name": "Svalbard et Île Jan Mayen",
        "states": [
            {
                "_id": "svalbard",
                "code": "svalbard",
                "name": "Svalbard",
                "pk": "SJ__svalbard"
            }
        ],
        "pk": "SJ"
    },
    {
        "_id": "SZ",
        "code": "SZ",
        "name": "Swaziland",
        "states": [
            {
                "_id": "hhohho",
                "code": "hhohho",
                "name": "Hhohho",
                "pk": "SZ__hhohho"
            },
            {
                "_id": "lubombo",
                "code": "lubombo",
                "name": "Lubombo",
                "pk": "SZ__lubombo"
            },
            {
                "_id": "manzini",
                "code": "manzini",
                "name": "Manzini",
                "pk": "SZ__manzini"
            },
            {
                "_id": "shiselweni",
                "code": "shiselweni",
                "name": "Shiselweni",
                "pk": "SZ__shiselweni"
            }
        ],
        "pk": "SZ"
    },
    {
        "_id": "SE",
        "code": "SE",
        "name": "Suède",
        "states": [
            {
                "_id": "alvsborgs_lan",
                "code": "alvsborgs_lan",
                "name": "Alvsborgs Lan",
                "pk": "SE__alvsborgs_lan"
            },
            {
                "_id": "angermanland",
                "code": "angermanland",
                "name": "Angermanland",
                "pk": "SE__angermanland"
            },
            {
                "_id": "blekinge",
                "code": "blekinge",
                "name": "Blekinge",
                "pk": "SE__blekinge"
            },
            {
                "_id": "bohuslan",
                "code": "bohuslan",
                "name": "Bohuslan",
                "pk": "SE__bohuslan"
            },
            {
                "_id": "dalarna",
                "code": "dalarna",
                "name": "Dalarna",
                "pk": "SE__dalarna"
            },
            {
                "_id": "gavleborg",
                "code": "gavleborg",
                "name": "Gavleborg",
                "pk": "SE__gavleborg"
            },
            {
                "_id": "gaza",
                "code": "gaza",
                "name": "Gaza",
                "pk": "SE__gaza"
            },
            {
                "_id": "gotland",
                "code": "gotland",
                "name": "Gotland",
                "pk": "SE__gotland"
            },
            {
                "_id": "halland",
                "code": "halland",
                "name": "Halland",
                "pk": "SE__halland"
            },
            {
                "_id": "jamtland",
                "code": "jamtland",
                "name": "Jamtland",
                "pk": "SE__jamtland"
            },
            {
                "_id": "jonkoping",
                "code": "jonkoping",
                "name": "Jonkoping",
                "pk": "SE__jonkoping"
            },
            {
                "_id": "kalmar",
                "code": "kalmar",
                "name": "Kalmar",
                "pk": "SE__kalmar"
            },
            {
                "_id": "kristianstads",
                "code": "kristianstads",
                "name": "Kristianstads",
                "pk": "SE__kristianstads"
            },
            {
                "_id": "kronoberg",
                "code": "kronoberg",
                "name": "Kronoberg",
                "pk": "SE__kronoberg"
            },
            {
                "_id": "norrbotten",
                "code": "norrbotten",
                "name": "Norrbotten",
                "pk": "SE__norrbotten"
            },
            {
                "_id": "orebro",
                "code": "orebro",
                "name": "Orebro",
                "pk": "SE__orebro"
            },
            {
                "_id": "ostergotland",
                "code": "ostergotland",
                "name": "Ostergotland",
                "pk": "SE__ostergotland"
            },
            {
                "_id": "saltsjo-boo",
                "code": "saltsjo-boo",
                "name": "Saltsjo-Boo",
                "pk": "SE__saltsjo-boo"
            },
            {
                "_id": "skane",
                "code": "skane",
                "name": "Skane",
                "pk": "SE__skane"
            },
            {
                "_id": "smaland",
                "code": "smaland",
                "name": "Smaland",
                "pk": "SE__smaland"
            },
            {
                "_id": "sodermanland",
                "code": "sodermanland",
                "name": "Sodermanland",
                "pk": "SE__sodermanland"
            },
            {
                "_id": "stockholm",
                "code": "stockholm",
                "name": "Stockholm",
                "pk": "SE__stockholm"
            },
            {
                "_id": "uppsala",
                "code": "uppsala",
                "name": "Uppsala",
                "pk": "SE__uppsala"
            },
            {
                "_id": "varmland",
                "code": "varmland",
                "name": "Varmland",
                "pk": "SE__varmland"
            },
            {
                "_id": "vasterbotten",
                "code": "vasterbotten",
                "name": "Vasterbotten",
                "pk": "SE__vasterbotten"
            },
            {
                "_id": "vastergotland",
                "code": "vastergotland",
                "name": "Vastergotland",
                "pk": "SE__vastergotland"
            },
            {
                "_id": "vasternorrland",
                "code": "vasternorrland",
                "name": "Vasternorrland",
                "pk": "SE__vasternorrland"
            },
            {
                "_id": "vastmanland",
                "code": "vastmanland",
                "name": "Vastmanland",
                "pk": "SE__vastmanland"
            },
            {
                "_id": "vastra_gotaland",
                "code": "vastra_gotaland",
                "name": "Vastra Gotaland",
                "pk": "SE__vastra_gotaland"
            }
        ],
        "pk": "SE"
    },
    {
        "_id": "CH",
        "code": "CH",
        "name": "Suisse",
        "states": [
            {
                "_id": "aargau",
                "code": "aargau",
                "name": "Aargau",
                "pk": "CH__aargau"
            },
            {
                "_id": "appenzell_inner-rhoden",
                "code": "appenzell_inner-rhoden",
                "name": "Appenzell Inner-Rhoden",
                "pk": "CH__appenzell_inner-rhoden"
            },
            {
                "_id": "appenzell-ausser_rhoden",
                "code": "appenzell-ausser_rhoden",
                "name": "Appenzell-Ausser Rhoden",
                "pk": "CH__appenzell-ausser_rhoden"
            },
            {
                "_id": "basel-landschaft",
                "code": "basel-landschaft",
                "name": "Basel-Landschaft",
                "pk": "CH__basel-landschaft"
            },
            {
                "_id": "basel-stadt",
                "code": "basel-stadt",
                "name": "Basel-Stadt",
                "pk": "CH__basel-stadt"
            },
            {
                "_id": "bern",
                "code": "bern",
                "name": "Bern",
                "pk": "CH__bern"
            },
            {
                "_id": "canton_ticino",
                "code": "canton_ticino",
                "name": "Canton Ticino",
                "pk": "CH__canton_ticino"
            },
            {
                "_id": "fribourg",
                "code": "fribourg",
                "name": "Fribourg",
                "pk": "CH__fribourg"
            },
            {
                "_id": "geneve",
                "code": "geneve",
                "name": "Geneve",
                "pk": "CH__geneve"
            },
            {
                "_id": "glarus",
                "code": "glarus",
                "name": "Glarus",
                "pk": "CH__glarus"
            },
            {
                "_id": "graubunden",
                "code": "graubunden",
                "name": "Graubunden",
                "pk": "CH__graubunden"
            },
            {
                "_id": "heerbrugg",
                "code": "heerbrugg",
                "name": "Heerbrugg",
                "pk": "CH__heerbrugg"
            },
            {
                "_id": "jura",
                "code": "jura",
                "name": "Jura",
                "pk": "CH__jura"
            },
            {
                "_id": "kanton_aargau",
                "code": "kanton_aargau",
                "name": "Kanton Aargau",
                "pk": "CH__kanton_aargau"
            },
            {
                "_id": "luzern",
                "code": "luzern",
                "name": "Luzern",
                "pk": "CH__luzern"
            },
            {
                "_id": "morbio_inferiore",
                "code": "morbio_inferiore",
                "name": "Morbio Inferiore",
                "pk": "CH__morbio_inferiore"
            },
            {
                "_id": "muhen",
                "code": "muhen",
                "name": "Muhen",
                "pk": "CH__muhen"
            },
            {
                "_id": "neuchatel",
                "code": "neuchatel",
                "name": "Neuchatel",
                "pk": "CH__neuchatel"
            },
            {
                "_id": "nidwalden",
                "code": "nidwalden",
                "name": "Nidwalden",
                "pk": "CH__nidwalden"
            },
            {
                "_id": "obwalden",
                "code": "obwalden",
                "name": "Obwalden",
                "pk": "CH__obwalden"
            },
            {
                "_id": "sankt_gallen",
                "code": "sankt_gallen",
                "name": "Sankt Gallen",
                "pk": "CH__sankt_gallen"
            },
            {
                "_id": "schaffhausen",
                "code": "schaffhausen",
                "name": "Schaffhausen",
                "pk": "CH__schaffhausen"
            },
            {
                "_id": "schwyz",
                "code": "schwyz",
                "name": "Schwyz",
                "pk": "CH__schwyz"
            },
            {
                "_id": "solothurn",
                "code": "solothurn",
                "name": "Solothurn",
                "pk": "CH__solothurn"
            },
            {
                "_id": "thurgau",
                "code": "thurgau",
                "name": "Thurgau",
                "pk": "CH__thurgau"
            },
            {
                "_id": "ticino",
                "code": "ticino",
                "name": "Ticino",
                "pk": "CH__ticino"
            },
            {
                "_id": "uri",
                "code": "uri",
                "name": "Uri",
                "pk": "CH__uri"
            },
            {
                "_id": "valais",
                "code": "valais",
                "name": "Valais",
                "pk": "CH__valais"
            },
            {
                "_id": "vaud",
                "code": "vaud",
                "name": "Vaud",
                "pk": "CH__vaud"
            },
            {
                "_id": "vauffelin",
                "code": "vauffelin",
                "name": "Vauffelin",
                "pk": "CH__vauffelin"
            },
            {
                "_id": "zug",
                "code": "zug",
                "name": "Zug",
                "pk": "CH__zug"
            },
            {
                "_id": "zurich",
                "code": "zurich",
                "name": "Zurich",
                "pk": "CH__zurich"
            }
        ],
        "only_shipping": true,
        "pk": "CH"
    },
    {
        "_id": "SY",
        "code": "SY",
        "name": "Syrie",
        "states": [
            {
                "_id": "aleppo",
                "code": "aleppo",
                "name": "Aleppo",
                "pk": "SY__aleppo"
            },
            {
                "_id": "dara",
                "code": "dara",
                "name": "Dara",
                "pk": "SY__dara"
            },
            {
                "_id": "dayr-az-zawr",
                "code": "dayr-az-zawr",
                "name": "Dayr-az-Zawr",
                "pk": "SY__dayr-az-zawr"
            },
            {
                "_id": "dimashq",
                "code": "dimashq",
                "name": "Dimashq",
                "pk": "SY__dimashq"
            },
            {
                "_id": "halab",
                "code": "halab",
                "name": "Halab",
                "pk": "SY__halab"
            },
            {
                "_id": "hamah",
                "code": "hamah",
                "name": "Hamah",
                "pk": "SY__hamah"
            },
            {
                "_id": "hims",
                "code": "hims",
                "name": "Hims",
                "pk": "SY__hims"
            },
            {
                "_id": "idlib",
                "code": "idlib",
                "name": "Idlib",
                "pk": "SY__idlib"
            },
            {
                "_id": "madinat_dimashq",
                "code": "madinat_dimashq",
                "name": "Madinat Dimashq",
                "pk": "SY__madinat_dimashq"
            },
            {
                "_id": "tartus",
                "code": "tartus",
                "name": "Tartus",
                "pk": "SY__tartus"
            },
            {
                "_id": "al-hasakah",
                "code": "al-hasakah",
                "name": "al-Hasakah",
                "pk": "SY__al-hasakah"
            },
            {
                "_id": "al-ladhiqiyah",
                "code": "al-ladhiqiyah",
                "name": "al-Ladhiqiyah",
                "pk": "SY__al-ladhiqiyah"
            },
            {
                "_id": "al-qunaytirah",
                "code": "al-qunaytirah",
                "name": "al-Qunaytirah",
                "pk": "SY__al-qunaytirah"
            },
            {
                "_id": "ar-raqqah",
                "code": "ar-raqqah",
                "name": "ar-Raqqah",
                "pk": "SY__ar-raqqah"
            },
            {
                "_id": "as-suwayda",
                "code": "as-suwayda",
                "name": "as-Suwayda",
                "pk": "SY__as-suwayda"
            }
        ],
        "pk": "SY"
    },
    {
        "_id": "TW",
        "code": "TW",
        "name": "Taïwan",
        "states": [
            {
                "_id": "changhwa",
                "code": "changhwa",
                "name": "Changhwa",
                "pk": "TW__changhwa"
            },
            {
                "_id": "chiayi_hsien",
                "code": "chiayi_hsien",
                "name": "Chiayi Hsien",
                "pk": "TW__chiayi_hsien"
            },
            {
                "_id": "chiayi_shih",
                "code": "chiayi_shih",
                "name": "Chiayi Shih",
                "pk": "TW__chiayi_shih"
            },
            {
                "_id": "eastern_taipei",
                "code": "eastern_taipei",
                "name": "Eastern Taipei",
                "pk": "TW__eastern_taipei"
            },
            {
                "_id": "hsinchu_hsien",
                "code": "hsinchu_hsien",
                "name": "Hsinchu Hsien",
                "pk": "TW__hsinchu_hsien"
            },
            {
                "_id": "hsinchu_shih",
                "code": "hsinchu_shih",
                "name": "Hsinchu Shih",
                "pk": "TW__hsinchu_shih"
            },
            {
                "_id": "hualien",
                "code": "hualien",
                "name": "Hualien",
                "pk": "TW__hualien"
            },
            {
                "_id": "ilan",
                "code": "ilan",
                "name": "Ilan",
                "pk": "TW__ilan"
            },
            {
                "_id": "kaohsiung_hsien",
                "code": "kaohsiung_hsien",
                "name": "Kaohsiung Hsien",
                "pk": "TW__kaohsiung_hsien"
            },
            {
                "_id": "kaohsiung_shih",
                "code": "kaohsiung_shih",
                "name": "Kaohsiung Shih",
                "pk": "TW__kaohsiung_shih"
            },
            {
                "_id": "keelung_shih",
                "code": "keelung_shih",
                "name": "Keelung Shih",
                "pk": "TW__keelung_shih"
            },
            {
                "_id": "kinmen",
                "code": "kinmen",
                "name": "Kinmen",
                "pk": "TW__kinmen"
            },
            {
                "_id": "miaoli",
                "code": "miaoli",
                "name": "Miaoli",
                "pk": "TW__miaoli"
            },
            {
                "_id": "nantou",
                "code": "nantou",
                "name": "Nantou",
                "pk": "TW__nantou"
            },
            {
                "_id": "northern_taiwan",
                "code": "northern_taiwan",
                "name": "Northern Taiwan",
                "pk": "TW__northern_taiwan"
            },
            {
                "_id": "penghu",
                "code": "penghu",
                "name": "Penghu",
                "pk": "TW__penghu"
            },
            {
                "_id": "pingtung",
                "code": "pingtung",
                "name": "Pingtung",
                "pk": "TW__pingtung"
            },
            {
                "_id": "taichung",
                "code": "taichung",
                "name": "Taichung",
                "pk": "TW__taichung"
            },
            {
                "_id": "taichung_hsien",
                "code": "taichung_hsien",
                "name": "Taichung Hsien",
                "pk": "TW__taichung_hsien"
            },
            {
                "_id": "taichung_shih",
                "code": "taichung_shih",
                "name": "Taichung Shih",
                "pk": "TW__taichung_shih"
            },
            {
                "_id": "tainan_hsien",
                "code": "tainan_hsien",
                "name": "Tainan Hsien",
                "pk": "TW__tainan_hsien"
            },
            {
                "_id": "tainan_shih",
                "code": "tainan_shih",
                "name": "Tainan Shih",
                "pk": "TW__tainan_shih"
            },
            {
                "_id": "taipei_hsien",
                "code": "taipei_hsien",
                "name": "Taipei Hsien",
                "pk": "TW__taipei_hsien"
            },
            {
                "_id": "taipei_shih_/_taipei_hsien",
                "code": "taipei_shih_/_taipei_hsien",
                "name": "Taipei Shih / Taipei Hsien",
                "pk": "TW__taipei_shih_/_taipei_hsien"
            },
            {
                "_id": "taitung",
                "code": "taitung",
                "name": "Taitung",
                "pk": "TW__taitung"
            },
            {
                "_id": "taoyuan",
                "code": "taoyuan",
                "name": "Taoyuan",
                "pk": "TW__taoyuan"
            },
            {
                "_id": "yilan",
                "code": "yilan",
                "name": "Yilan",
                "pk": "TW__yilan"
            },
            {
                "_id": "yun-lin_hsien",
                "code": "yun-lin_hsien",
                "name": "Yun-Lin Hsien",
                "pk": "TW__yun-lin_hsien"
            },
            {
                "_id": "yunlin",
                "code": "yunlin",
                "name": "Yunlin",
                "pk": "TW__yunlin"
            }
        ],
        "pk": "TW"
    },
    {
        "_id": "TJ",
        "code": "TJ",
        "name": "Tadjikistan",
        "states": [
            {
                "_id": "dushanbe",
                "code": "dushanbe",
                "name": "Dushanbe",
                "pk": "TJ__dushanbe"
            },
            {
                "_id": "gorno-badakhshan",
                "code": "gorno-badakhshan",
                "name": "Gorno-Badakhshan",
                "pk": "TJ__gorno-badakhshan"
            },
            {
                "_id": "karotegin",
                "code": "karotegin",
                "name": "Karotegin",
                "pk": "TJ__karotegin"
            },
            {
                "_id": "khatlon",
                "code": "khatlon",
                "name": "Khatlon",
                "pk": "TJ__khatlon"
            },
            {
                "_id": "sughd",
                "code": "sughd",
                "name": "Sughd",
                "pk": "TJ__sughd"
            }
        ],
        "pk": "TJ"
    },
    {
        "_id": "TZ",
        "code": "TZ",
        "name": "Tanzanie",
        "states": [
            {
                "_id": "arusha",
                "code": "arusha",
                "name": "Arusha",
                "pk": "TZ__arusha"
            },
            {
                "_id": "dar_es_salaam",
                "code": "dar_es_salaam",
                "name": "Dar es Salaam",
                "pk": "TZ__dar_es_salaam"
            },
            {
                "_id": "dodoma",
                "code": "dodoma",
                "name": "Dodoma",
                "pk": "TZ__dodoma"
            },
            {
                "_id": "iringa",
                "code": "iringa",
                "name": "Iringa",
                "pk": "TZ__iringa"
            },
            {
                "_id": "kagera",
                "code": "kagera",
                "name": "Kagera",
                "pk": "TZ__kagera"
            },
            {
                "_id": "kigoma",
                "code": "kigoma",
                "name": "Kigoma",
                "pk": "TZ__kigoma"
            },
            {
                "_id": "kilimanjaro",
                "code": "kilimanjaro",
                "name": "Kilimanjaro",
                "pk": "TZ__kilimanjaro"
            },
            {
                "_id": "lindi",
                "code": "lindi",
                "name": "Lindi",
                "pk": "TZ__lindi"
            },
            {
                "_id": "mara",
                "code": "mara",
                "name": "Mara",
                "pk": "TZ__mara"
            },
            {
                "_id": "mbeya",
                "code": "mbeya",
                "name": "Mbeya",
                "pk": "TZ__mbeya"
            },
            {
                "_id": "morogoro",
                "code": "morogoro",
                "name": "Morogoro",
                "pk": "TZ__morogoro"
            },
            {
                "_id": "mtwara",
                "code": "mtwara",
                "name": "Mtwara",
                "pk": "TZ__mtwara"
            },
            {
                "_id": "mwanza",
                "code": "mwanza",
                "name": "Mwanza",
                "pk": "TZ__mwanza"
            },
            {
                "_id": "pwani",
                "code": "pwani",
                "name": "Pwani",
                "pk": "TZ__pwani"
            },
            {
                "_id": "rukwa",
                "code": "rukwa",
                "name": "Rukwa",
                "pk": "TZ__rukwa"
            },
            {
                "_id": "ruvuma",
                "code": "ruvuma",
                "name": "Ruvuma",
                "pk": "TZ__ruvuma"
            },
            {
                "_id": "shinyanga",
                "code": "shinyanga",
                "name": "Shinyanga",
                "pk": "TZ__shinyanga"
            },
            {
                "_id": "singida",
                "code": "singida",
                "name": "Singida",
                "pk": "TZ__singida"
            },
            {
                "_id": "tabora",
                "code": "tabora",
                "name": "Tabora",
                "pk": "TZ__tabora"
            },
            {
                "_id": "tanga",
                "code": "tanga",
                "name": "Tanga",
                "pk": "TZ__tanga"
            },
            {
                "_id": "zanzibar_and_pemba",
                "code": "zanzibar_and_pemba",
                "name": "Zanzibar and Pemba",
                "pk": "TZ__zanzibar_and_pemba"
            }
        ],
        "pk": "TZ"
    },
    {
        "_id": "TH",
        "code": "TH",
        "name": "Thaïlande",
        "states": [
            {
                "_id": "amnat_charoen",
                "code": "amnat_charoen",
                "name": "Amnat Charoen",
                "pk": "TH__amnat_charoen"
            },
            {
                "_id": "ang_thong",
                "code": "ang_thong",
                "name": "Ang Thong",
                "pk": "TH__ang_thong"
            },
            {
                "_id": "bangkok",
                "code": "bangkok",
                "name": "Bangkok",
                "pk": "TH__bangkok"
            },
            {
                "_id": "buri_ram",
                "code": "buri_ram",
                "name": "Buri Ram",
                "pk": "TH__buri_ram"
            },
            {
                "_id": "chachoengsao",
                "code": "chachoengsao",
                "name": "Chachoengsao",
                "pk": "TH__chachoengsao"
            },
            {
                "_id": "chai_nat",
                "code": "chai_nat",
                "name": "Chai Nat",
                "pk": "TH__chai_nat"
            },
            {
                "_id": "chaiyaphum",
                "code": "chaiyaphum",
                "name": "Chaiyaphum",
                "pk": "TH__chaiyaphum"
            },
            {
                "_id": "changwat_chaiyaphum",
                "code": "changwat_chaiyaphum",
                "name": "Changwat Chaiyaphum",
                "pk": "TH__changwat_chaiyaphum"
            },
            {
                "_id": "chanthaburi",
                "code": "chanthaburi",
                "name": "Chanthaburi",
                "pk": "TH__chanthaburi"
            },
            {
                "_id": "chiang_mai",
                "code": "chiang_mai",
                "name": "Chiang Mai",
                "pk": "TH__chiang_mai"
            },
            {
                "_id": "chiang_rai",
                "code": "chiang_rai",
                "name": "Chiang Rai",
                "pk": "TH__chiang_rai"
            },
            {
                "_id": "chon_buri",
                "code": "chon_buri",
                "name": "Chon Buri",
                "pk": "TH__chon_buri"
            },
            {
                "_id": "chumphon",
                "code": "chumphon",
                "name": "Chumphon",
                "pk": "TH__chumphon"
            },
            {
                "_id": "kalasin",
                "code": "kalasin",
                "name": "Kalasin",
                "pk": "TH__kalasin"
            },
            {
                "_id": "kamphaeng_phet",
                "code": "kamphaeng_phet",
                "name": "Kamphaeng Phet",
                "pk": "TH__kamphaeng_phet"
            },
            {
                "_id": "kanchanaburi",
                "code": "kanchanaburi",
                "name": "Kanchanaburi",
                "pk": "TH__kanchanaburi"
            },
            {
                "_id": "khon_kaen",
                "code": "khon_kaen",
                "name": "Khon Kaen",
                "pk": "TH__khon_kaen"
            },
            {
                "_id": "krabi",
                "code": "krabi",
                "name": "Krabi",
                "pk": "TH__krabi"
            },
            {
                "_id": "krung_thep",
                "code": "krung_thep",
                "name": "Krung Thep",
                "pk": "TH__krung_thep"
            },
            {
                "_id": "lampang",
                "code": "lampang",
                "name": "Lampang",
                "pk": "TH__lampang"
            },
            {
                "_id": "lamphun",
                "code": "lamphun",
                "name": "Lamphun",
                "pk": "TH__lamphun"
            },
            {
                "_id": "loei",
                "code": "loei",
                "name": "Loei",
                "pk": "TH__loei"
            },
            {
                "_id": "lop_buri",
                "code": "lop_buri",
                "name": "Lop Buri",
                "pk": "TH__lop_buri"
            },
            {
                "_id": "mae_hong_son",
                "code": "mae_hong_son",
                "name": "Mae Hong Son",
                "pk": "TH__mae_hong_son"
            },
            {
                "_id": "maha_sarakham",
                "code": "maha_sarakham",
                "name": "Maha Sarakham",
                "pk": "TH__maha_sarakham"
            },
            {
                "_id": "mukdahan",
                "code": "mukdahan",
                "name": "Mukdahan",
                "pk": "TH__mukdahan"
            },
            {
                "_id": "nakhon_nayok",
                "code": "nakhon_nayok",
                "name": "Nakhon Nayok",
                "pk": "TH__nakhon_nayok"
            },
            {
                "_id": "nakhon_pathom",
                "code": "nakhon_pathom",
                "name": "Nakhon Pathom",
                "pk": "TH__nakhon_pathom"
            },
            {
                "_id": "nakhon_phanom",
                "code": "nakhon_phanom",
                "name": "Nakhon Phanom",
                "pk": "TH__nakhon_phanom"
            },
            {
                "_id": "nakhon_ratchasima",
                "code": "nakhon_ratchasima",
                "name": "Nakhon Ratchasima",
                "pk": "TH__nakhon_ratchasima"
            },
            {
                "_id": "nakhon_sawan",
                "code": "nakhon_sawan",
                "name": "Nakhon Sawan",
                "pk": "TH__nakhon_sawan"
            },
            {
                "_id": "nakhon_si_thammarat",
                "code": "nakhon_si_thammarat",
                "name": "Nakhon Si Thammarat",
                "pk": "TH__nakhon_si_thammarat"
            },
            {
                "_id": "nan",
                "code": "nan",
                "name": "Nan",
                "pk": "TH__nan"
            },
            {
                "_id": "narathiwat",
                "code": "narathiwat",
                "name": "Narathiwat",
                "pk": "TH__narathiwat"
            },
            {
                "_id": "nong_bua_lam_phu",
                "code": "nong_bua_lam_phu",
                "name": "Nong Bua Lam Phu",
                "pk": "TH__nong_bua_lam_phu"
            },
            {
                "_id": "nong_khai",
                "code": "nong_khai",
                "name": "Nong Khai",
                "pk": "TH__nong_khai"
            },
            {
                "_id": "nonthaburi",
                "code": "nonthaburi",
                "name": "Nonthaburi",
                "pk": "TH__nonthaburi"
            },
            {
                "_id": "pathum_thani",
                "code": "pathum_thani",
                "name": "Pathum Thani",
                "pk": "TH__pathum_thani"
            },
            {
                "_id": "pattani",
                "code": "pattani",
                "name": "Pattani",
                "pk": "TH__pattani"
            },
            {
                "_id": "phangnga",
                "code": "phangnga",
                "name": "Phangnga",
                "pk": "TH__phangnga"
            },
            {
                "_id": "phatthalung",
                "code": "phatthalung",
                "name": "Phatthalung",
                "pk": "TH__phatthalung"
            },
            {
                "_id": "phayao",
                "code": "phayao",
                "name": "Phayao",
                "pk": "TH__phayao"
            },
            {
                "_id": "phetchabun",
                "code": "phetchabun",
                "name": "Phetchabun",
                "pk": "TH__phetchabun"
            },
            {
                "_id": "phetchaburi",
                "code": "phetchaburi",
                "name": "Phetchaburi",
                "pk": "TH__phetchaburi"
            },
            {
                "_id": "phichit",
                "code": "phichit",
                "name": "Phichit",
                "pk": "TH__phichit"
            },
            {
                "_id": "phitsanulok",
                "code": "phitsanulok",
                "name": "Phitsanulok",
                "pk": "TH__phitsanulok"
            },
            {
                "_id": "phra_nakhon_si_ayutthaya",
                "code": "phra_nakhon_si_ayutthaya",
                "name": "Phra Nakhon Si Ayutthaya",
                "pk": "TH__phra_nakhon_si_ayutthaya"
            },
            {
                "_id": "phrae",
                "code": "phrae",
                "name": "Phrae",
                "pk": "TH__phrae"
            },
            {
                "_id": "phuket",
                "code": "phuket",
                "name": "Phuket",
                "pk": "TH__phuket"
            },
            {
                "_id": "prachin_buri",
                "code": "prachin_buri",
                "name": "Prachin Buri",
                "pk": "TH__prachin_buri"
            },
            {
                "_id": "prachuap_khiri_khan",
                "code": "prachuap_khiri_khan",
                "name": "Prachuap Khiri Khan",
                "pk": "TH__prachuap_khiri_khan"
            },
            {
                "_id": "ranong",
                "code": "ranong",
                "name": "Ranong",
                "pk": "TH__ranong"
            },
            {
                "_id": "ratchaburi",
                "code": "ratchaburi",
                "name": "Ratchaburi",
                "pk": "TH__ratchaburi"
            },
            {
                "_id": "rayong",
                "code": "rayong",
                "name": "Rayong",
                "pk": "TH__rayong"
            },
            {
                "_id": "roi_et",
                "code": "roi_et",
                "name": "Roi Et",
                "pk": "TH__roi_et"
            },
            {
                "_id": "sa_kaeo",
                "code": "sa_kaeo",
                "name": "Sa Kaeo",
                "pk": "TH__sa_kaeo"
            },
            {
                "_id": "sakon_nakhon",
                "code": "sakon_nakhon",
                "name": "Sakon Nakhon",
                "pk": "TH__sakon_nakhon"
            },
            {
                "_id": "samut_prakan",
                "code": "samut_prakan",
                "name": "Samut Prakan",
                "pk": "TH__samut_prakan"
            },
            {
                "_id": "samut_sakhon",
                "code": "samut_sakhon",
                "name": "Samut Sakhon",
                "pk": "TH__samut_sakhon"
            },
            {
                "_id": "samut_songkhran",
                "code": "samut_songkhran",
                "name": "Samut Songkhran",
                "pk": "TH__samut_songkhran"
            },
            {
                "_id": "saraburi",
                "code": "saraburi",
                "name": "Saraburi",
                "pk": "TH__saraburi"
            },
            {
                "_id": "satun",
                "code": "satun",
                "name": "Satun",
                "pk": "TH__satun"
            },
            {
                "_id": "si_sa_ket",
                "code": "si_sa_ket",
                "name": "Si Sa Ket",
                "pk": "TH__si_sa_ket"
            },
            {
                "_id": "sing_buri",
                "code": "sing_buri",
                "name": "Sing Buri",
                "pk": "TH__sing_buri"
            },
            {
                "_id": "songkhla",
                "code": "songkhla",
                "name": "Songkhla",
                "pk": "TH__songkhla"
            },
            {
                "_id": "sukhothai",
                "code": "sukhothai",
                "name": "Sukhothai",
                "pk": "TH__sukhothai"
            },
            {
                "_id": "suphan_buri",
                "code": "suphan_buri",
                "name": "Suphan Buri",
                "pk": "TH__suphan_buri"
            },
            {
                "_id": "surat_thani",
                "code": "surat_thani",
                "name": "Surat Thani",
                "pk": "TH__surat_thani"
            },
            {
                "_id": "surin",
                "code": "surin",
                "name": "Surin",
                "pk": "TH__surin"
            },
            {
                "_id": "tak",
                "code": "tak",
                "name": "Tak",
                "pk": "TH__tak"
            },
            {
                "_id": "trang",
                "code": "trang",
                "name": "Trang",
                "pk": "TH__trang"
            },
            {
                "_id": "trat",
                "code": "trat",
                "name": "Trat",
                "pk": "TH__trat"
            },
            {
                "_id": "ubon_ratchathani",
                "code": "ubon_ratchathani",
                "name": "Ubon Ratchathani",
                "pk": "TH__ubon_ratchathani"
            },
            {
                "_id": "udon_thani",
                "code": "udon_thani",
                "name": "Udon Thani",
                "pk": "TH__udon_thani"
            },
            {
                "_id": "uthai_thani",
                "code": "uthai_thani",
                "name": "Uthai Thani",
                "pk": "TH__uthai_thani"
            },
            {
                "_id": "uttaradit",
                "code": "uttaradit",
                "name": "Uttaradit",
                "pk": "TH__uttaradit"
            },
            {
                "_id": "yala",
                "code": "yala",
                "name": "Yala",
                "pk": "TH__yala"
            },
            {
                "_id": "yasothon",
                "code": "yasothon",
                "name": "Yasothon",
                "pk": "TH__yasothon"
            }
        ],
        "pk": "TH"
    },
    {
        "_id": "TG",
        "code": "TG",
        "name": "Togo",
        "states": [
            {
                "_id": "centre",
                "code": "centre",
                "name": "Centre",
                "pk": "TG__centre"
            },
            {
                "_id": "kara",
                "code": "kara",
                "name": "Kara",
                "pk": "TG__kara"
            },
            {
                "_id": "maritime",
                "code": "maritime",
                "name": "Maritime",
                "pk": "TG__maritime"
            },
            {
                "_id": "plateaux",
                "code": "plateaux",
                "name": "Plateaux",
                "pk": "TG__plateaux"
            },
            {
                "_id": "savanes",
                "code": "savanes",
                "name": "Savanes",
                "pk": "TG__savanes"
            }
        ],
        "pk": "TG"
    },
    {
        "_id": "TK",
        "code": "TK",
        "name": "Tokelau",
        "states": [
            {
                "_id": "atafu",
                "code": "atafu",
                "name": "Atafu",
                "pk": "TK__atafu"
            },
            {
                "_id": "fakaofo",
                "code": "fakaofo",
                "name": "Fakaofo",
                "pk": "TK__fakaofo"
            },
            {
                "_id": "nukunonu",
                "code": "nukunonu",
                "name": "Nukunonu",
                "pk": "TK__nukunonu"
            }
        ],
        "pk": "TK"
    },
    {
        "_id": "TO",
        "code": "TO",
        "name": "Tonga",
        "states": [
            {
                "_id": "eua",
                "code": "eua",
                "name": "Eua",
                "pk": "TO__eua"
            },
            {
                "_id": "haapai",
                "code": "haapai",
                "name": "Haapai",
                "pk": "TO__haapai"
            },
            {
                "_id": "niuas",
                "code": "niuas",
                "name": "Niuas",
                "pk": "TO__niuas"
            },
            {
                "_id": "tongatapu",
                "code": "tongatapu",
                "name": "Tongatapu",
                "pk": "TO__tongatapu"
            },
            {
                "_id": "vavau",
                "code": "vavau",
                "name": "Vavau",
                "pk": "TO__vavau"
            }
        ],
        "pk": "TO"
    },
    {
        "_id": "TT",
        "code": "TT",
        "name": "Trinité-et-Tobago",
        "states": [
            {
                "_id": "arima-tunapuna-piarco",
                "code": "arima-tunapuna-piarco",
                "name": "Arima-Tunapuna-Piarco",
                "pk": "TT__arima-tunapuna-piarco"
            },
            {
                "_id": "caroni",
                "code": "caroni",
                "name": "Caroni",
                "pk": "TT__caroni"
            },
            {
                "_id": "chaguanas",
                "code": "chaguanas",
                "name": "Chaguanas",
                "pk": "TT__chaguanas"
            },
            {
                "_id": "couva-tabaquite-talparo",
                "code": "couva-tabaquite-talparo",
                "name": "Couva-Tabaquite-Talparo",
                "pk": "TT__couva-tabaquite-talparo"
            },
            {
                "_id": "diego_martin",
                "code": "diego_martin",
                "name": "Diego Martin",
                "pk": "TT__diego_martin"
            },
            {
                "_id": "glencoe",
                "code": "glencoe",
                "name": "Glencoe",
                "pk": "TT__glencoe"
            },
            {
                "_id": "penal_debe",
                "code": "penal_debe",
                "name": "Penal Debe",
                "pk": "TT__penal_debe"
            },
            {
                "_id": "point_fortin",
                "code": "point_fortin",
                "name": "Point Fortin",
                "pk": "TT__point_fortin"
            },
            {
                "_id": "port_of_spain",
                "code": "port_of_spain",
                "name": "Port of Spain",
                "pk": "TT__port_of_spain"
            },
            {
                "_id": "princes_town",
                "code": "princes_town",
                "name": "Princes Town",
                "pk": "TT__princes_town"
            },
            {
                "_id": "saint_george",
                "code": "saint_george",
                "name": "Saint George",
                "pk": "TT__saint_george"
            },
            {
                "_id": "san_fernando",
                "code": "san_fernando",
                "name": "San Fernando",
                "pk": "TT__san_fernando"
            },
            {
                "_id": "san_juan",
                "code": "san_juan",
                "name": "San Juan",
                "pk": "TT__san_juan"
            },
            {
                "_id": "sangre_grande",
                "code": "sangre_grande",
                "name": "Sangre Grande",
                "pk": "TT__sangre_grande"
            },
            {
                "_id": "siparia",
                "code": "siparia",
                "name": "Siparia",
                "pk": "TT__siparia"
            },
            {
                "_id": "tobago",
                "code": "tobago",
                "name": "Tobago",
                "pk": "TT__tobago"
            }
        ],
        "pk": "TT"
    },
    {
        "_id": "TN",
        "code": "TN",
        "name": "Tunisie",
        "states": [
            {
                "_id": "aryanah",
                "code": "aryanah",
                "name": "Aryanah",
                "pk": "TN__aryanah"
            },
            {
                "_id": "bajah",
                "code": "bajah",
                "name": "Bajah",
                "pk": "TN__bajah"
            },
            {
                "_id": "bin_arus",
                "code": "bin_arus",
                "name": "Bin Arus",
                "pk": "TN__bin_arus"
            },
            {
                "_id": "binzart",
                "code": "binzart",
                "name": "Binzart",
                "pk": "TN__binzart"
            },
            {
                "_id": "gouvernorat_de_ariana",
                "code": "gouvernorat_de_ariana",
                "name": "Gouvernorat de Ariana",
                "pk": "TN__gouvernorat_de_ariana"
            },
            {
                "_id": "gouvernorat_de_nabeul",
                "code": "gouvernorat_de_nabeul",
                "name": "Gouvernorat de Nabeul",
                "pk": "TN__gouvernorat_de_nabeul"
            },
            {
                "_id": "gouvernorat_de_sousse",
                "code": "gouvernorat_de_sousse",
                "name": "Gouvernorat de Sousse",
                "pk": "TN__gouvernorat_de_sousse"
            },
            {
                "_id": "hammamet_yasmine",
                "code": "hammamet_yasmine",
                "name": "Hammamet Yasmine",
                "pk": "TN__hammamet_yasmine"
            },
            {
                "_id": "jundubah",
                "code": "jundubah",
                "name": "Jundubah",
                "pk": "TN__jundubah"
            },
            {
                "_id": "madaniyin",
                "code": "madaniyin",
                "name": "Madaniyin",
                "pk": "TN__madaniyin"
            },
            {
                "_id": "manubah",
                "code": "manubah",
                "name": "Manubah",
                "pk": "TN__manubah"
            },
            {
                "_id": "monastir",
                "code": "monastir",
                "name": "Monastir",
                "pk": "TN__monastir"
            },
            {
                "_id": "nabul",
                "code": "nabul",
                "name": "Nabul",
                "pk": "TN__nabul"
            },
            {
                "_id": "qabis",
                "code": "qabis",
                "name": "Qabis",
                "pk": "TN__qabis"
            },
            {
                "_id": "qafsah",
                "code": "qafsah",
                "name": "Qafsah",
                "pk": "TN__qafsah"
            },
            {
                "_id": "qibili",
                "code": "qibili",
                "name": "Qibili",
                "pk": "TN__qibili"
            },
            {
                "_id": "safaqis",
                "code": "safaqis",
                "name": "Safaqis",
                "pk": "TN__safaqis"
            },
            {
                "_id": "sfax",
                "code": "sfax",
                "name": "Sfax",
                "pk": "TN__sfax"
            },
            {
                "_id": "sidi_bu_zayd",
                "code": "sidi_bu_zayd",
                "name": "Sidi Bu Zayd",
                "pk": "TN__sidi_bu_zayd"
            },
            {
                "_id": "silyanah",
                "code": "silyanah",
                "name": "Silyanah",
                "pk": "TN__silyanah"
            },
            {
                "_id": "susah",
                "code": "susah",
                "name": "Susah",
                "pk": "TN__susah"
            },
            {
                "_id": "tatawin",
                "code": "tatawin",
                "name": "Tatawin",
                "pk": "TN__tatawin"
            },
            {
                "_id": "tawzar",
                "code": "tawzar",
                "name": "Tawzar",
                "pk": "TN__tawzar"
            },
            {
                "_id": "tunis",
                "code": "tunis",
                "name": "Tunis",
                "pk": "TN__tunis"
            },
            {
                "_id": "zaghwan",
                "code": "zaghwan",
                "name": "Zaghwan",
                "pk": "TN__zaghwan"
            },
            {
                "_id": "al-kaf",
                "code": "al-kaf",
                "name": "al-Kaf",
                "pk": "TN__al-kaf"
            },
            {
                "_id": "al-mahdiyah",
                "code": "al-mahdiyah",
                "name": "al-Mahdiyah",
                "pk": "TN__al-mahdiyah"
            },
            {
                "_id": "al-munastir",
                "code": "al-munastir",
                "name": "al-Munastir",
                "pk": "TN__al-munastir"
            },
            {
                "_id": "al-qasrayn",
                "code": "al-qasrayn",
                "name": "al-Qasrayn",
                "pk": "TN__al-qasrayn"
            },
            {
                "_id": "al-qayrawan",
                "code": "al-qayrawan",
                "name": "al-Qayrawan",
                "pk": "TN__al-qayrawan"
            }
        ],
        "pk": "TN"
    },
    {
        "_id": "TR",
        "code": "TR",
        "name": "Turquie",
        "states": [
            {
                "_id": "adana",
                "code": "adana",
                "name": "Adana",
                "pk": "TR__adana"
            },
            {
                "_id": "adiyaman",
                "code": "adiyaman",
                "name": "Adiyaman",
                "pk": "TR__adiyaman"
            },
            {
                "_id": "afyon",
                "code": "afyon",
                "name": "Afyon",
                "pk": "TR__afyon"
            },
            {
                "_id": "agri",
                "code": "agri",
                "name": "Agri",
                "pk": "TR__agri"
            },
            {
                "_id": "aksaray",
                "code": "aksaray",
                "name": "Aksaray",
                "pk": "TR__aksaray"
            },
            {
                "_id": "amasya",
                "code": "amasya",
                "name": "Amasya",
                "pk": "TR__amasya"
            },
            {
                "_id": "ankara",
                "code": "ankara",
                "name": "Ankara",
                "pk": "TR__ankara"
            },
            {
                "_id": "antalya",
                "code": "antalya",
                "name": "Antalya",
                "pk": "TR__antalya"
            },
            {
                "_id": "ardahan",
                "code": "ardahan",
                "name": "Ardahan",
                "pk": "TR__ardahan"
            },
            {
                "_id": "artvin",
                "code": "artvin",
                "name": "Artvin",
                "pk": "TR__artvin"
            },
            {
                "_id": "aydin",
                "code": "aydin",
                "name": "Aydin",
                "pk": "TR__aydin"
            },
            {
                "_id": "balikesir",
                "code": "balikesir",
                "name": "Balikesir",
                "pk": "TR__balikesir"
            },
            {
                "_id": "bartin",
                "code": "bartin",
                "name": "Bartin",
                "pk": "TR__bartin"
            },
            {
                "_id": "batman",
                "code": "batman",
                "name": "Batman",
                "pk": "TR__batman"
            },
            {
                "_id": "bayburt",
                "code": "bayburt",
                "name": "Bayburt",
                "pk": "TR__bayburt"
            },
            {
                "_id": "bilecik",
                "code": "bilecik",
                "name": "Bilecik",
                "pk": "TR__bilecik"
            },
            {
                "_id": "bingol",
                "code": "bingol",
                "name": "Bingol",
                "pk": "TR__bingol"
            },
            {
                "_id": "bitlis",
                "code": "bitlis",
                "name": "Bitlis",
                "pk": "TR__bitlis"
            },
            {
                "_id": "bolu",
                "code": "bolu",
                "name": "Bolu",
                "pk": "TR__bolu"
            },
            {
                "_id": "burdur",
                "code": "burdur",
                "name": "Burdur",
                "pk": "TR__burdur"
            },
            {
                "_id": "bursa",
                "code": "bursa",
                "name": "Bursa",
                "pk": "TR__bursa"
            },
            {
                "_id": "canakkale",
                "code": "canakkale",
                "name": "Canakkale",
                "pk": "TR__canakkale"
            },
            {
                "_id": "cankiri",
                "code": "cankiri",
                "name": "Cankiri",
                "pk": "TR__cankiri"
            },
            {
                "_id": "corum",
                "code": "corum",
                "name": "Corum",
                "pk": "TR__corum"
            },
            {
                "_id": "denizli",
                "code": "denizli",
                "name": "Denizli",
                "pk": "TR__denizli"
            },
            {
                "_id": "diyarbakir",
                "code": "diyarbakir",
                "name": "Diyarbakir",
                "pk": "TR__diyarbakir"
            },
            {
                "_id": "duzce",
                "code": "duzce",
                "name": "Duzce",
                "pk": "TR__duzce"
            },
            {
                "_id": "edirne",
                "code": "edirne",
                "name": "Edirne",
                "pk": "TR__edirne"
            },
            {
                "_id": "elazig",
                "code": "elazig",
                "name": "Elazig",
                "pk": "TR__elazig"
            },
            {
                "_id": "erzincan",
                "code": "erzincan",
                "name": "Erzincan",
                "pk": "TR__erzincan"
            },
            {
                "_id": "erzurum",
                "code": "erzurum",
                "name": "Erzurum",
                "pk": "TR__erzurum"
            },
            {
                "_id": "eskisehir",
                "code": "eskisehir",
                "name": "Eskisehir",
                "pk": "TR__eskisehir"
            },
            {
                "_id": "gaziantep",
                "code": "gaziantep",
                "name": "Gaziantep",
                "pk": "TR__gaziantep"
            },
            {
                "_id": "giresun",
                "code": "giresun",
                "name": "Giresun",
                "pk": "TR__giresun"
            },
            {
                "_id": "gumushane",
                "code": "gumushane",
                "name": "Gumushane",
                "pk": "TR__gumushane"
            },
            {
                "_id": "hakkari",
                "code": "hakkari",
                "name": "Hakkari",
                "pk": "TR__hakkari"
            },
            {
                "_id": "hatay",
                "code": "hatay",
                "name": "Hatay",
                "pk": "TR__hatay"
            },
            {
                "_id": "icel",
                "code": "icel",
                "name": "Icel",
                "pk": "TR__icel"
            },
            {
                "_id": "igdir",
                "code": "igdir",
                "name": "Igdir",
                "pk": "TR__igdir"
            },
            {
                "_id": "isparta",
                "code": "isparta",
                "name": "Isparta",
                "pk": "TR__isparta"
            },
            {
                "_id": "istanbul",
                "code": "istanbul",
                "name": "Istanbul",
                "pk": "TR__istanbul"
            },
            {
                "_id": "izmir",
                "code": "izmir",
                "name": "Izmir",
                "pk": "TR__izmir"
            },
            {
                "_id": "kahramanmaras",
                "code": "kahramanmaras",
                "name": "Kahramanmaras",
                "pk": "TR__kahramanmaras"
            },
            {
                "_id": "karabuk",
                "code": "karabuk",
                "name": "Karabuk",
                "pk": "TR__karabuk"
            },
            {
                "_id": "karaman",
                "code": "karaman",
                "name": "Karaman",
                "pk": "TR__karaman"
            },
            {
                "_id": "kars",
                "code": "kars",
                "name": "Kars",
                "pk": "TR__kars"
            },
            {
                "_id": "karsiyaka",
                "code": "karsiyaka",
                "name": "Karsiyaka",
                "pk": "TR__karsiyaka"
            },
            {
                "_id": "kastamonu",
                "code": "kastamonu",
                "name": "Kastamonu",
                "pk": "TR__kastamonu"
            },
            {
                "_id": "kayseri",
                "code": "kayseri",
                "name": "Kayseri",
                "pk": "TR__kayseri"
            },
            {
                "_id": "kilis",
                "code": "kilis",
                "name": "Kilis",
                "pk": "TR__kilis"
            },
            {
                "_id": "kirikkale",
                "code": "kirikkale",
                "name": "Kirikkale",
                "pk": "TR__kirikkale"
            },
            {
                "_id": "kirklareli",
                "code": "kirklareli",
                "name": "Kirklareli",
                "pk": "TR__kirklareli"
            },
            {
                "_id": "kirsehir",
                "code": "kirsehir",
                "name": "Kirsehir",
                "pk": "TR__kirsehir"
            },
            {
                "_id": "kocaeli",
                "code": "kocaeli",
                "name": "Kocaeli",
                "pk": "TR__kocaeli"
            },
            {
                "_id": "konya",
                "code": "konya",
                "name": "Konya",
                "pk": "TR__konya"
            },
            {
                "_id": "kutahya",
                "code": "kutahya",
                "name": "Kutahya",
                "pk": "TR__kutahya"
            },
            {
                "_id": "lefkosa",
                "code": "lefkosa",
                "name": "Lefkosa",
                "pk": "TR__lefkosa"
            },
            {
                "_id": "malatya",
                "code": "malatya",
                "name": "Malatya",
                "pk": "TR__malatya"
            },
            {
                "_id": "manisa",
                "code": "manisa",
                "name": "Manisa",
                "pk": "TR__manisa"
            },
            {
                "_id": "mardin",
                "code": "mardin",
                "name": "Mardin",
                "pk": "TR__mardin"
            },
            {
                "_id": "mugla",
                "code": "mugla",
                "name": "Mugla",
                "pk": "TR__mugla"
            },
            {
                "_id": "mus",
                "code": "mus",
                "name": "Mus",
                "pk": "TR__mus"
            },
            {
                "_id": "nevsehir",
                "code": "nevsehir",
                "name": "Nevsehir",
                "pk": "TR__nevsehir"
            },
            {
                "_id": "nigde",
                "code": "nigde",
                "name": "Nigde",
                "pk": "TR__nigde"
            },
            {
                "_id": "ordu",
                "code": "ordu",
                "name": "Ordu",
                "pk": "TR__ordu"
            },
            {
                "_id": "osmaniye",
                "code": "osmaniye",
                "name": "Osmaniye",
                "pk": "TR__osmaniye"
            },
            {
                "_id": "rize",
                "code": "rize",
                "name": "Rize",
                "pk": "TR__rize"
            },
            {
                "_id": "sakarya",
                "code": "sakarya",
                "name": "Sakarya",
                "pk": "TR__sakarya"
            },
            {
                "_id": "samsun",
                "code": "samsun",
                "name": "Samsun",
                "pk": "TR__samsun"
            },
            {
                "_id": "sanliurfa",
                "code": "sanliurfa",
                "name": "Sanliurfa",
                "pk": "TR__sanliurfa"
            },
            {
                "_id": "siirt",
                "code": "siirt",
                "name": "Siirt",
                "pk": "TR__siirt"
            },
            {
                "_id": "sinop",
                "code": "sinop",
                "name": "Sinop",
                "pk": "TR__sinop"
            },
            {
                "_id": "sirnak",
                "code": "sirnak",
                "name": "Sirnak",
                "pk": "TR__sirnak"
            },
            {
                "_id": "sivas",
                "code": "sivas",
                "name": "Sivas",
                "pk": "TR__sivas"
            },
            {
                "_id": "tekirdag",
                "code": "tekirdag",
                "name": "Tekirdag",
                "pk": "TR__tekirdag"
            },
            {
                "_id": "tokat",
                "code": "tokat",
                "name": "Tokat",
                "pk": "TR__tokat"
            },
            {
                "_id": "trabzon",
                "code": "trabzon",
                "name": "Trabzon",
                "pk": "TR__trabzon"
            },
            {
                "_id": "tunceli",
                "code": "tunceli",
                "name": "Tunceli",
                "pk": "TR__tunceli"
            },
            {
                "_id": "usak",
                "code": "usak",
                "name": "Usak",
                "pk": "TR__usak"
            },
            {
                "_id": "van",
                "code": "van",
                "name": "Van",
                "pk": "TR__van"
            },
            {
                "_id": "yalova",
                "code": "yalova",
                "name": "Yalova",
                "pk": "TR__yalova"
            },
            {
                "_id": "yozgat",
                "code": "yozgat",
                "name": "Yozgat",
                "pk": "TR__yozgat"
            },
            {
                "_id": "zonguldak",
                "code": "zonguldak",
                "name": "Zonguldak",
                "pk": "TR__zonguldak"
            }
        ],
        "pk": "TR"
    },
    {
        "_id": "TM",
        "code": "TM",
        "name": "Turkménistan",
        "states": [
            {
                "_id": "ahal",
                "code": "ahal",
                "name": "Ahal",
                "pk": "TM__ahal"
            },
            {
                "_id": "asgabat",
                "code": "asgabat",
                "name": "Asgabat",
                "pk": "TM__asgabat"
            },
            {
                "_id": "balkan",
                "code": "balkan",
                "name": "Balkan",
                "pk": "TM__balkan"
            },
            {
                "_id": "dasoguz",
                "code": "dasoguz",
                "name": "Dasoguz",
                "pk": "TM__dasoguz"
            },
            {
                "_id": "lebap",
                "code": "lebap",
                "name": "Lebap",
                "pk": "TM__lebap"
            },
            {
                "_id": "mari",
                "code": "mari",
                "name": "Mari",
                "pk": "TM__mari"
            }
        ],
        "pk": "TM"
    },
    {
        "_id": "TC",
        "code": "TC",
        "name": "Îles Turques-et-Caïques",
        "states": [
            {
                "_id": "grand_turk",
                "code": "grand_turk",
                "name": "Grand Turk",
                "pk": "TC__grand_turk"
            },
            {
                "_id": "south_caicos_and_east_caicos",
                "code": "south_caicos_and_east_caicos",
                "name": "South Caicos and East Caicos",
                "pk": "TC__south_caicos_and_east_caicos"
            }
        ],
        "pk": "TC"
    },
    {
        "_id": "TV",
        "code": "TV",
        "name": "Tuvalu",
        "states": [
            {
                "_id": "funafuti",
                "code": "funafuti",
                "name": "Funafuti",
                "pk": "TV__funafuti"
            },
            {
                "_id": "nanumanga",
                "code": "nanumanga",
                "name": "Nanumanga",
                "pk": "TV__nanumanga"
            },
            {
                "_id": "nanumea",
                "code": "nanumea",
                "name": "Nanumea",
                "pk": "TV__nanumea"
            },
            {
                "_id": "niutao",
                "code": "niutao",
                "name": "Niutao",
                "pk": "TV__niutao"
            },
            {
                "_id": "nui",
                "code": "nui",
                "name": "Nui",
                "pk": "TV__nui"
            },
            {
                "_id": "nukufetau",
                "code": "nukufetau",
                "name": "Nukufetau",
                "pk": "TV__nukufetau"
            },
            {
                "_id": "nukulaelae",
                "code": "nukulaelae",
                "name": "Nukulaelae",
                "pk": "TV__nukulaelae"
            },
            {
                "_id": "vaitupu",
                "code": "vaitupu",
                "name": "Vaitupu",
                "pk": "TV__vaitupu"
            }
        ],
        "pk": "TV"
    },
    {
        "_id": "UG",
        "code": "UG",
        "name": "Ouganda",
        "states": [
            {
                "_id": "central",
                "code": "central",
                "name": "Central",
                "pk": "UG__central"
            },
            {
                "_id": "eastern",
                "code": "eastern",
                "name": "Eastern",
                "pk": "UG__eastern"
            },
            {
                "_id": "northern",
                "code": "northern",
                "name": "Northern",
                "pk": "UG__northern"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "UG__western"
            }
        ],
        "pk": "UG"
    },
    {
        "_id": "UA",
        "code": "UA",
        "name": "Ukraine",
        "states": [
            {
                "_id": "cherkaska",
                "code": "cherkaska",
                "name": "Cherkaska",
                "pk": "UA__cherkaska"
            },
            {
                "_id": "chernihivska",
                "code": "chernihivska",
                "name": "Chernihivska",
                "pk": "UA__chernihivska"
            },
            {
                "_id": "chernivetska",
                "code": "chernivetska",
                "name": "Chernivetska",
                "pk": "UA__chernivetska"
            },
            {
                "_id": "crimea",
                "code": "crimea",
                "name": "Crimea",
                "pk": "UA__crimea"
            },
            {
                "_id": "dnipropetrovska",
                "code": "dnipropetrovska",
                "name": "Dnipropetrovska",
                "pk": "UA__dnipropetrovska"
            },
            {
                "_id": "donetska",
                "code": "donetska",
                "name": "Donetska",
                "pk": "UA__donetska"
            },
            {
                "_id": "ivano-frankivska",
                "code": "ivano-frankivska",
                "name": "Ivano-Frankivska",
                "pk": "UA__ivano-frankivska"
            },
            {
                "_id": "kharkiv",
                "code": "kharkiv",
                "name": "Kharkiv",
                "pk": "UA__kharkiv"
            },
            {
                "_id": "kharkov",
                "code": "kharkov",
                "name": "Kharkov",
                "pk": "UA__kharkov"
            },
            {
                "_id": "khersonska",
                "code": "khersonska",
                "name": "Khersonska",
                "pk": "UA__khersonska"
            },
            {
                "_id": "khmelnytska",
                "code": "khmelnytska",
                "name": "Khmelnytska",
                "pk": "UA__khmelnytska"
            },
            {
                "_id": "kirovohrad",
                "code": "kirovohrad",
                "name": "Kirovohrad",
                "pk": "UA__kirovohrad"
            },
            {
                "_id": "krym",
                "code": "krym",
                "name": "Krym",
                "pk": "UA__krym"
            },
            {
                "_id": "kyyiv",
                "code": "kyyiv",
                "name": "Kyyiv",
                "pk": "UA__kyyiv"
            },
            {
                "_id": "kyyivska",
                "code": "kyyivska",
                "name": "Kyyivska",
                "pk": "UA__kyyivska"
            },
            {
                "_id": "lvivska",
                "code": "lvivska",
                "name": "Lvivska",
                "pk": "UA__lvivska"
            },
            {
                "_id": "luhanska",
                "code": "luhanska",
                "name": "Luhanska",
                "pk": "UA__luhanska"
            },
            {
                "_id": "mykolayivska",
                "code": "mykolayivska",
                "name": "Mykolayivska",
                "pk": "UA__mykolayivska"
            },
            {
                "_id": "odeska",
                "code": "odeska",
                "name": "Odeska",
                "pk": "UA__odeska"
            },
            {
                "_id": "odessa",
                "code": "odessa",
                "name": "Odessa",
                "pk": "UA__odessa"
            },
            {
                "_id": "poltavska",
                "code": "poltavska",
                "name": "Poltavska",
                "pk": "UA__poltavska"
            },
            {
                "_id": "rivnenska",
                "code": "rivnenska",
                "name": "Rivnenska",
                "pk": "UA__rivnenska"
            },
            {
                "_id": "sevastopol",
                "code": "sevastopol",
                "name": "Sevastopol",
                "pk": "UA__sevastopol"
            },
            {
                "_id": "sumska",
                "code": "sumska",
                "name": "Sumska",
                "pk": "UA__sumska"
            },
            {
                "_id": "ternopilska",
                "code": "ternopilska",
                "name": "Ternopilska",
                "pk": "UA__ternopilska"
            },
            {
                "_id": "volynska",
                "code": "volynska",
                "name": "Volynska",
                "pk": "UA__volynska"
            },
            {
                "_id": "vynnytska",
                "code": "vynnytska",
                "name": "Vynnytska",
                "pk": "UA__vynnytska"
            },
            {
                "_id": "zakarpatska",
                "code": "zakarpatska",
                "name": "Zakarpatska",
                "pk": "UA__zakarpatska"
            },
            {
                "_id": "zaporizhia",
                "code": "zaporizhia",
                "name": "Zaporizhia",
                "pk": "UA__zaporizhia"
            },
            {
                "_id": "zhytomyrska",
                "code": "zhytomyrska",
                "name": "Zhytomyrska",
                "pk": "UA__zhytomyrska"
            }
        ],
        "pk": "UA"
    },
    {
        "_id": "AE",
        "code": "AE",
        "name": "Émirats arabes unis",
        "states": [
            {
                "_id": "abu_zabi",
                "code": "abu_zabi",
                "name": "Abu Zabi",
                "pk": "AE__abu_zabi"
            },
            {
                "_id": "ajman",
                "code": "ajman",
                "name": "Ajman",
                "pk": "AE__ajman"
            },
            {
                "_id": "dubai",
                "code": "dubai",
                "name": "Dubai",
                "pk": "AE__dubai"
            },
            {
                "_id": "ras_al-khaymah",
                "code": "ras_al-khaymah",
                "name": "Ras al-Khaymah",
                "pk": "AE__ras_al-khaymah"
            },
            {
                "_id": "sharjah",
                "code": "sharjah",
                "name": "Sharjah",
                "pk": "AE__sharjah"
            },
            {
                "_id": "sharjha",
                "code": "sharjha",
                "name": "Sharjha",
                "pk": "AE__sharjha"
            },
            {
                "_id": "umm_al_qaywayn",
                "code": "umm_al_qaywayn",
                "name": "Umm al Qaywayn",
                "pk": "AE__umm_al_qaywayn"
            },
            {
                "_id": "al-fujayrah",
                "code": "al-fujayrah",
                "name": "al-Fujayrah",
                "pk": "AE__al-fujayrah"
            },
            {
                "_id": "ash-shariqah",
                "code": "ash-shariqah",
                "name": "ash-Shariqah",
                "pk": "AE__ash-shariqah"
            }
        ],
        "pk": "AE"
    },
    {
        "_id": "GB",
        "code": "GB",
        "name": "Angleterre",
        "states": [
            {
                "_id": "aberdeen",
                "code": "aberdeen",
                "name": "Aberdeen",
                "pk": "GB__aberdeen"
            },
            {
                "_id": "aberdeenshire",
                "code": "aberdeenshire",
                "name": "Aberdeenshire",
                "pk": "GB__aberdeenshire"
            },
            {
                "_id": "argyll",
                "code": "argyll",
                "name": "Argyll",
                "pk": "GB__argyll"
            },
            {
                "_id": "armagh",
                "code": "armagh",
                "name": "Armagh",
                "pk": "GB__armagh"
            },
            {
                "_id": "bedfordshire",
                "code": "bedfordshire",
                "name": "Bedfordshire",
                "pk": "GB__bedfordshire"
            },
            {
                "_id": "belfast",
                "code": "belfast",
                "name": "Belfast",
                "pk": "GB__belfast"
            },
            {
                "_id": "berkshire",
                "code": "berkshire",
                "name": "Berkshire",
                "pk": "GB__berkshire"
            },
            {
                "_id": "birmingham",
                "code": "birmingham",
                "name": "Birmingham",
                "pk": "GB__birmingham"
            },
            {
                "_id": "brechin",
                "code": "brechin",
                "name": "Brechin",
                "pk": "GB__brechin"
            },
            {
                "_id": "bridgnorth",
                "code": "bridgnorth",
                "name": "Bridgnorth",
                "pk": "GB__bridgnorth"
            },
            {
                "_id": "bristol",
                "code": "bristol",
                "name": "Bristol",
                "pk": "GB__bristol"
            },
            {
                "_id": "buckinghamshire",
                "code": "buckinghamshire",
                "name": "Buckinghamshire",
                "pk": "GB__buckinghamshire"
            },
            {
                "_id": "cambridge",
                "code": "cambridge",
                "name": "Cambridge",
                "pk": "GB__cambridge"
            },
            {
                "_id": "cambridgeshire",
                "code": "cambridgeshire",
                "name": "Cambridgeshire",
                "pk": "GB__cambridgeshire"
            },
            {
                "_id": "channel_islands",
                "code": "channel_islands",
                "name": "Channel Islands",
                "pk": "GB__channel_islands"
            },
            {
                "_id": "cheshire",
                "code": "cheshire",
                "name": "Cheshire",
                "pk": "GB__cheshire"
            },
            {
                "_id": "cleveland",
                "code": "cleveland",
                "name": "Cleveland",
                "pk": "GB__cleveland"
            },
            {
                "_id": "co_fermanagh",
                "code": "co_fermanagh",
                "name": "Co Fermanagh",
                "pk": "GB__co_fermanagh"
            },
            {
                "_id": "conwy",
                "code": "conwy",
                "name": "Conwy",
                "pk": "GB__conwy"
            },
            {
                "_id": "cornwall",
                "code": "cornwall",
                "name": "Cornwall",
                "pk": "GB__cornwall"
            },
            {
                "_id": "coventry",
                "code": "coventry",
                "name": "Coventry",
                "pk": "GB__coventry"
            },
            {
                "_id": "craven_arms",
                "code": "craven_arms",
                "name": "Craven Arms",
                "pk": "GB__craven_arms"
            },
            {
                "_id": "cumbria",
                "code": "cumbria",
                "name": "Cumbria",
                "pk": "GB__cumbria"
            },
            {
                "_id": "denbighshire",
                "code": "denbighshire",
                "name": "Denbighshire",
                "pk": "GB__denbighshire"
            },
            {
                "_id": "derby",
                "code": "derby",
                "name": "Derby",
                "pk": "GB__derby"
            },
            {
                "_id": "derbyshire",
                "code": "derbyshire",
                "name": "Derbyshire",
                "pk": "GB__derbyshire"
            },
            {
                "_id": "devon",
                "code": "devon",
                "name": "Devon",
                "pk": "GB__devon"
            },
            {
                "_id": "dial_code_dungannon",
                "code": "dial_code_dungannon",
                "name": "Dial Code Dungannon",
                "pk": "GB__dial_code_dungannon"
            },
            {
                "_id": "didcot",
                "code": "didcot",
                "name": "Didcot",
                "pk": "GB__didcot"
            },
            {
                "_id": "dorset",
                "code": "dorset",
                "name": "Dorset",
                "pk": "GB__dorset"
            },
            {
                "_id": "dunbartonshire",
                "code": "dunbartonshire",
                "name": "Dunbartonshire",
                "pk": "GB__dunbartonshire"
            },
            {
                "_id": "durham",
                "code": "durham",
                "name": "Durham",
                "pk": "GB__durham"
            },
            {
                "_id": "east_dunbartonshire",
                "code": "east_dunbartonshire",
                "name": "East Dunbartonshire",
                "pk": "GB__east_dunbartonshire"
            },
            {
                "_id": "east_lothian",
                "code": "east_lothian",
                "name": "East Lothian",
                "pk": "GB__east_lothian"
            },
            {
                "_id": "east_midlands",
                "code": "east_midlands",
                "name": "East Midlands",
                "pk": "GB__east_midlands"
            },
            {
                "_id": "east_sussex",
                "code": "east_sussex",
                "name": "East Sussex",
                "pk": "GB__east_sussex"
            },
            {
                "_id": "east_yorkshire",
                "code": "east_yorkshire",
                "name": "East Yorkshire",
                "pk": "GB__east_yorkshire"
            },
            {
                "_id": "england",
                "code": "england",
                "name": "England",
                "pk": "GB__england"
            },
            {
                "_id": "essex",
                "code": "essex",
                "name": "Essex",
                "pk": "GB__essex"
            },
            {
                "_id": "fermanagh",
                "code": "fermanagh",
                "name": "Fermanagh",
                "pk": "GB__fermanagh"
            },
            {
                "_id": "fife",
                "code": "fife",
                "name": "Fife",
                "pk": "GB__fife"
            },
            {
                "_id": "flintshire",
                "code": "flintshire",
                "name": "Flintshire",
                "pk": "GB__flintshire"
            },
            {
                "_id": "fulham",
                "code": "fulham",
                "name": "Fulham",
                "pk": "GB__fulham"
            },
            {
                "_id": "gainsborough",
                "code": "gainsborough",
                "name": "Gainsborough",
                "pk": "GB__gainsborough"
            },
            {
                "_id": "glocestershire",
                "code": "glocestershire",
                "name": "Glocestershire",
                "pk": "GB__glocestershire"
            },
            {
                "_id": "gwent",
                "code": "gwent",
                "name": "Gwent",
                "pk": "GB__gwent"
            },
            {
                "_id": "hampshire",
                "code": "hampshire",
                "name": "Hampshire",
                "pk": "GB__hampshire"
            },
            {
                "_id": "hants",
                "code": "hants",
                "name": "Hants",
                "pk": "GB__hants"
            },
            {
                "_id": "herefordshire",
                "code": "herefordshire",
                "name": "Herefordshire",
                "pk": "GB__herefordshire"
            },
            {
                "_id": "hertfordshire",
                "code": "hertfordshire",
                "name": "Hertfordshire",
                "pk": "GB__hertfordshire"
            },
            {
                "_id": "ireland",
                "code": "ireland",
                "name": "Ireland",
                "pk": "GB__ireland"
            },
            {
                "_id": "isle_of_man",
                "code": "isle_of_man",
                "name": "Isle Of Man",
                "pk": "GB__isle_of_man"
            },
            {
                "_id": "isle_of_wight",
                "code": "isle_of_wight",
                "name": "Isle of Wight",
                "pk": "GB__isle_of_wight"
            },
            {
                "_id": "kenford",
                "code": "kenford",
                "name": "Kenford",
                "pk": "GB__kenford"
            },
            {
                "_id": "kent",
                "code": "kent",
                "name": "Kent",
                "pk": "GB__kent"
            },
            {
                "_id": "kilmarnock",
                "code": "kilmarnock",
                "name": "Kilmarnock",
                "pk": "GB__kilmarnock"
            },
            {
                "_id": "lanarkshire",
                "code": "lanarkshire",
                "name": "Lanarkshire",
                "pk": "GB__lanarkshire"
            },
            {
                "_id": "lancashire",
                "code": "lancashire",
                "name": "Lancashire",
                "pk": "GB__lancashire"
            },
            {
                "_id": "leicestershire",
                "code": "leicestershire",
                "name": "Leicestershire",
                "pk": "GB__leicestershire"
            },
            {
                "_id": "lincolnshire",
                "code": "lincolnshire",
                "name": "Lincolnshire",
                "pk": "GB__lincolnshire"
            },
            {
                "_id": "llanymynech",
                "code": "llanymynech",
                "name": "Llanymynech",
                "pk": "GB__llanymynech"
            },
            {
                "_id": "london",
                "code": "london",
                "name": "London",
                "pk": "GB__london"
            },
            {
                "_id": "ludlow",
                "code": "ludlow",
                "name": "Ludlow",
                "pk": "GB__ludlow"
            },
            {
                "_id": "manchester",
                "code": "manchester",
                "name": "Manchester",
                "pk": "GB__manchester"
            },
            {
                "_id": "mayfair",
                "code": "mayfair",
                "name": "Mayfair",
                "pk": "GB__mayfair"
            },
            {
                "_id": "merseyside",
                "code": "merseyside",
                "name": "Merseyside",
                "pk": "GB__merseyside"
            },
            {
                "_id": "mid_glamorgan",
                "code": "mid_glamorgan",
                "name": "Mid Glamorgan",
                "pk": "GB__mid_glamorgan"
            },
            {
                "_id": "middlesex",
                "code": "middlesex",
                "name": "Middlesex",
                "pk": "GB__middlesex"
            },
            {
                "_id": "mildenhall",
                "code": "mildenhall",
                "name": "Mildenhall",
                "pk": "GB__mildenhall"
            },
            {
                "_id": "monmouthshire",
                "code": "monmouthshire",
                "name": "Monmouthshire",
                "pk": "GB__monmouthshire"
            },
            {
                "_id": "newton_stewart",
                "code": "newton_stewart",
                "name": "Newton Stewart",
                "pk": "GB__newton_stewart"
            },
            {
                "_id": "norfolk",
                "code": "norfolk",
                "name": "Norfolk",
                "pk": "GB__norfolk"
            },
            {
                "_id": "north_humberside",
                "code": "north_humberside",
                "name": "North Humberside",
                "pk": "GB__north_humberside"
            },
            {
                "_id": "north_yorkshire",
                "code": "north_yorkshire",
                "name": "North Yorkshire",
                "pk": "GB__north_yorkshire"
            },
            {
                "_id": "northamptonshire",
                "code": "northamptonshire",
                "name": "Northamptonshire",
                "pk": "GB__northamptonshire"
            },
            {
                "_id": "northants",
                "code": "northants",
                "name": "Northants",
                "pk": "GB__northants"
            },
            {
                "_id": "northern_ireland",
                "code": "northern_ireland",
                "name": "Northern Ireland",
                "pk": "GB__northern_ireland"
            },
            {
                "_id": "northumberland",
                "code": "northumberland",
                "name": "Northumberland",
                "pk": "GB__northumberland"
            },
            {
                "_id": "nottinghamshire",
                "code": "nottinghamshire",
                "name": "Nottinghamshire",
                "pk": "GB__nottinghamshire"
            },
            {
                "_id": "oxford",
                "code": "oxford",
                "name": "Oxford",
                "pk": "GB__oxford"
            },
            {
                "_id": "powys",
                "code": "powys",
                "name": "Powys",
                "pk": "GB__powys"
            },
            {
                "_id": "roos-shire",
                "code": "roos-shire",
                "name": "Roos-shire",
                "pk": "GB__roos-shire"
            },
            {
                "_id": "sussex",
                "code": "sussex",
                "name": "SUSSEX",
                "pk": "GB__sussex"
            },
            {
                "_id": "sark",
                "code": "sark",
                "name": "Sark",
                "pk": "GB__sark"
            },
            {
                "_id": "scotland",
                "code": "scotland",
                "name": "Scotland",
                "pk": "GB__scotland"
            },
            {
                "_id": "scottish_borders",
                "code": "scottish_borders",
                "name": "Scottish Borders",
                "pk": "GB__scottish_borders"
            },
            {
                "_id": "shropshire",
                "code": "shropshire",
                "name": "Shropshire",
                "pk": "GB__shropshire"
            },
            {
                "_id": "somerset",
                "code": "somerset",
                "name": "Somerset",
                "pk": "GB__somerset"
            },
            {
                "_id": "south_glamorgan",
                "code": "south_glamorgan",
                "name": "South Glamorgan",
                "pk": "GB__south_glamorgan"
            },
            {
                "_id": "south_wales",
                "code": "south_wales",
                "name": "South Wales",
                "pk": "GB__south_wales"
            },
            {
                "_id": "south_yorkshire",
                "code": "south_yorkshire",
                "name": "South Yorkshire",
                "pk": "GB__south_yorkshire"
            },
            {
                "_id": "southwell",
                "code": "southwell",
                "name": "Southwell",
                "pk": "GB__southwell"
            },
            {
                "_id": "staffordshire",
                "code": "staffordshire",
                "name": "Staffordshire",
                "pk": "GB__staffordshire"
            },
            {
                "_id": "strabane",
                "code": "strabane",
                "name": "Strabane",
                "pk": "GB__strabane"
            },
            {
                "_id": "suffolk",
                "code": "suffolk",
                "name": "Suffolk",
                "pk": "GB__suffolk"
            },
            {
                "_id": "surrey",
                "code": "surrey",
                "name": "Surrey",
                "pk": "GB__surrey"
            },
            {
                "_id": "sussex",
                "code": "sussex",
                "name": "Sussex",
                "pk": "GB__sussex"
            },
            {
                "_id": "twickenham",
                "code": "twickenham",
                "name": "Twickenham",
                "pk": "GB__twickenham"
            },
            {
                "_id": "tyne_and_wear",
                "code": "tyne_and_wear",
                "name": "Tyne and Wear",
                "pk": "GB__tyne_and_wear"
            },
            {
                "_id": "tyrone",
                "code": "tyrone",
                "name": "Tyrone",
                "pk": "GB__tyrone"
            },
            {
                "_id": "utah",
                "code": "utah",
                "name": "Utah",
                "pk": "GB__utah"
            },
            {
                "_id": "wales",
                "code": "wales",
                "name": "Wales",
                "pk": "GB__wales"
            },
            {
                "_id": "warwickshire",
                "code": "warwickshire",
                "name": "Warwickshire",
                "pk": "GB__warwickshire"
            },
            {
                "_id": "west_lothian",
                "code": "west_lothian",
                "name": "West Lothian",
                "pk": "GB__west_lothian"
            },
            {
                "_id": "west_midlands",
                "code": "west_midlands",
                "name": "West Midlands",
                "pk": "GB__west_midlands"
            },
            {
                "_id": "west_sussex",
                "code": "west_sussex",
                "name": "West Sussex",
                "pk": "GB__west_sussex"
            },
            {
                "_id": "west_yorkshire",
                "code": "west_yorkshire",
                "name": "West Yorkshire",
                "pk": "GB__west_yorkshire"
            },
            {
                "_id": "whissendine",
                "code": "whissendine",
                "name": "Whissendine",
                "pk": "GB__whissendine"
            },
            {
                "_id": "wiltshire",
                "code": "wiltshire",
                "name": "Wiltshire",
                "pk": "GB__wiltshire"
            },
            {
                "_id": "wokingham",
                "code": "wokingham",
                "name": "Wokingham",
                "pk": "GB__wokingham"
            },
            {
                "_id": "worcestershire",
                "code": "worcestershire",
                "name": "Worcestershire",
                "pk": "GB__worcestershire"
            },
            {
                "_id": "wrexham",
                "code": "wrexham",
                "name": "Wrexham",
                "pk": "GB__wrexham"
            },
            {
                "_id": "wurttemberg",
                "code": "wurttemberg",
                "name": "Wurttemberg",
                "pk": "GB__wurttemberg"
            },
            {
                "_id": "yorkshire",
                "code": "yorkshire",
                "name": "Yorkshire",
                "pk": "GB__yorkshire"
            }
        ],
        "only_shipping": true,
        "pk": "GB"
    },
    {
        "_id": "UM",
        "code": "UM",
        "name": "Îles mineures éloignées des États-Unis",
        "pk": "UM"
    },
    {
        "_id": "UY",
        "code": "UY",
        "name": "Uruguay",
        "states": [
            {
                "_id": "artigas",
                "code": "artigas",
                "name": "Artigas",
                "pk": "UY__artigas"
            },
            {
                "_id": "canelones",
                "code": "canelones",
                "name": "Canelones",
                "pk": "UY__canelones"
            },
            {
                "_id": "cerro_largo",
                "code": "cerro_largo",
                "name": "Cerro Largo",
                "pk": "UY__cerro_largo"
            },
            {
                "_id": "colonia",
                "code": "colonia",
                "name": "Colonia",
                "pk": "UY__colonia"
            },
            {
                "_id": "durazno",
                "code": "durazno",
                "name": "Durazno",
                "pk": "UY__durazno"
            },
            {
                "_id": "florida",
                "code": "florida",
                "name": "FLorida",
                "pk": "UY__florida"
            },
            {
                "_id": "flores",
                "code": "flores",
                "name": "Flores",
                "pk": "UY__flores"
            },
            {
                "_id": "lavalleja",
                "code": "lavalleja",
                "name": "Lavalleja",
                "pk": "UY__lavalleja"
            },
            {
                "_id": "maldonado",
                "code": "maldonado",
                "name": "Maldonado",
                "pk": "UY__maldonado"
            },
            {
                "_id": "montevideo",
                "code": "montevideo",
                "name": "Montevideo",
                "pk": "UY__montevideo"
            },
            {
                "_id": "paysandu",
                "code": "paysandu",
                "name": "Paysandu",
                "pk": "UY__paysandu"
            },
            {
                "_id": "rio_negro",
                "code": "rio_negro",
                "name": "Rio Negro",
                "pk": "UY__rio_negro"
            },
            {
                "_id": "rivera",
                "code": "rivera",
                "name": "Rivera",
                "pk": "UY__rivera"
            },
            {
                "_id": "rocha",
                "code": "rocha",
                "name": "Rocha",
                "pk": "UY__rocha"
            },
            {
                "_id": "salto",
                "code": "salto",
                "name": "Salto",
                "pk": "UY__salto"
            },
            {
                "_id": "san_jose",
                "code": "san_jose",
                "name": "San Jose",
                "pk": "UY__san_jose"
            },
            {
                "_id": "soriano",
                "code": "soriano",
                "name": "Soriano",
                "pk": "UY__soriano"
            },
            {
                "_id": "tacuarembo",
                "code": "tacuarembo",
                "name": "Tacuarembo",
                "pk": "UY__tacuarembo"
            },
            {
                "_id": "treinta_y_tres",
                "code": "treinta_y_tres",
                "name": "Treinta y Tres",
                "pk": "UY__treinta_y_tres"
            }
        ],
        "pk": "UY"
    },
    {
        "_id": "UZ",
        "code": "UZ",
        "name": "Ouzbékistan",
        "states": [
            {
                "_id": "andijon",
                "code": "andijon",
                "name": "Andijon",
                "pk": "UZ__andijon"
            },
            {
                "_id": "buhoro",
                "code": "buhoro",
                "name": "Buhoro",
                "pk": "UZ__buhoro"
            },
            {
                "_id": "buxoro_viloyati",
                "code": "buxoro_viloyati",
                "name": "Buxoro Viloyati",
                "pk": "UZ__buxoro_viloyati"
            },
            {
                "_id": "cizah",
                "code": "cizah",
                "name": "Cizah",
                "pk": "UZ__cizah"
            },
            {
                "_id": "fargona",
                "code": "fargona",
                "name": "Fargona",
                "pk": "UZ__fargona"
            },
            {
                "_id": "horazm",
                "code": "horazm",
                "name": "Horazm",
                "pk": "UZ__horazm"
            },
            {
                "_id": "kaskadar",
                "code": "kaskadar",
                "name": "Kaskadar",
                "pk": "UZ__kaskadar"
            },
            {
                "_id": "korakalpogiston",
                "code": "korakalpogiston",
                "name": "Korakalpogiston",
                "pk": "UZ__korakalpogiston"
            },
            {
                "_id": "namangan",
                "code": "namangan",
                "name": "Namangan",
                "pk": "UZ__namangan"
            },
            {
                "_id": "navoi",
                "code": "navoi",
                "name": "Navoi",
                "pk": "UZ__navoi"
            },
            {
                "_id": "samarkand",
                "code": "samarkand",
                "name": "Samarkand",
                "pk": "UZ__samarkand"
            },
            {
                "_id": "sirdare",
                "code": "sirdare",
                "name": "Sirdare",
                "pk": "UZ__sirdare"
            },
            {
                "_id": "surhondar",
                "code": "surhondar",
                "name": "Surhondar",
                "pk": "UZ__surhondar"
            },
            {
                "_id": "toskent",
                "code": "toskent",
                "name": "Toskent",
                "pk": "UZ__toskent"
            }
        ],
        "pk": "UZ"
    },
    {
        "_id": "VU",
        "code": "VU",
        "name": "Vanuatu",
        "states": [
            {
                "_id": "malampa",
                "code": "malampa",
                "name": "Malampa",
                "pk": "VU__malampa"
            },
            {
                "_id": "penama",
                "code": "penama",
                "name": "Penama",
                "pk": "VU__penama"
            },
            {
                "_id": "sanma",
                "code": "sanma",
                "name": "Sanma",
                "pk": "VU__sanma"
            },
            {
                "_id": "shefa",
                "code": "shefa",
                "name": "Shefa",
                "pk": "VU__shefa"
            },
            {
                "_id": "tafea",
                "code": "tafea",
                "name": "Tafea",
                "pk": "VU__tafea"
            },
            {
                "_id": "torba",
                "code": "torba",
                "name": "Torba",
                "pk": "VU__torba"
            }
        ],
        "pk": "VU"
    },
    {
        "_id": "VA",
        "code": "VA",
        "name": "Cité du Vatican",
        "states": [
            {
                "_id": "vatican_city_state_(holy_see",
                "code": "vatican_city_state_(holy_see",
                "name": "Vatican City State (Holy See",
                "pk": "VA__vatican_city_state_(holy_see"
            }
        ],
        "pk": "VA"
    },
    {
        "_id": "VE",
        "code": "VE",
        "name": "Venezuela",
        "states": [
            {
                "_id": "amazonas",
                "code": "amazonas",
                "name": "Amazonas",
                "pk": "VE__amazonas"
            },
            {
                "_id": "anzoategui",
                "code": "anzoategui",
                "name": "Anzoategui",
                "pk": "VE__anzoategui"
            },
            {
                "_id": "apure",
                "code": "apure",
                "name": "Apure",
                "pk": "VE__apure"
            },
            {
                "_id": "aragua",
                "code": "aragua",
                "name": "Aragua",
                "pk": "VE__aragua"
            },
            {
                "_id": "barinas",
                "code": "barinas",
                "name": "Barinas",
                "pk": "VE__barinas"
            },
            {
                "_id": "bolivar",
                "code": "bolivar",
                "name": "Bolivar",
                "pk": "VE__bolivar"
            },
            {
                "_id": "carabobo",
                "code": "carabobo",
                "name": "Carabobo",
                "pk": "VE__carabobo"
            },
            {
                "_id": "cojedes",
                "code": "cojedes",
                "name": "Cojedes",
                "pk": "VE__cojedes"
            },
            {
                "_id": "delta_amacuro",
                "code": "delta_amacuro",
                "name": "Delta Amacuro",
                "pk": "VE__delta_amacuro"
            },
            {
                "_id": "distrito_federal",
                "code": "distrito_federal",
                "name": "Distrito Federal",
                "pk": "VE__distrito_federal"
            },
            {
                "_id": "falcon",
                "code": "falcon",
                "name": "Falcon",
                "pk": "VE__falcon"
            },
            {
                "_id": "guarico",
                "code": "guarico",
                "name": "Guarico",
                "pk": "VE__guarico"
            },
            {
                "_id": "lara",
                "code": "lara",
                "name": "Lara",
                "pk": "VE__lara"
            },
            {
                "_id": "merida",
                "code": "merida",
                "name": "Merida",
                "pk": "VE__merida"
            },
            {
                "_id": "miranda",
                "code": "miranda",
                "name": "Miranda",
                "pk": "VE__miranda"
            },
            {
                "_id": "monagas",
                "code": "monagas",
                "name": "Monagas",
                "pk": "VE__monagas"
            },
            {
                "_id": "nueva_esparta",
                "code": "nueva_esparta",
                "name": "Nueva Esparta",
                "pk": "VE__nueva_esparta"
            },
            {
                "_id": "portuguesa",
                "code": "portuguesa",
                "name": "Portuguesa",
                "pk": "VE__portuguesa"
            },
            {
                "_id": "sucre",
                "code": "sucre",
                "name": "Sucre",
                "pk": "VE__sucre"
            },
            {
                "_id": "tachira",
                "code": "tachira",
                "name": "Tachira",
                "pk": "VE__tachira"
            },
            {
                "_id": "trujillo",
                "code": "trujillo",
                "name": "Trujillo",
                "pk": "VE__trujillo"
            },
            {
                "_id": "vargas",
                "code": "vargas",
                "name": "Vargas",
                "pk": "VE__vargas"
            },
            {
                "_id": "yaracuy",
                "code": "yaracuy",
                "name": "Yaracuy",
                "pk": "VE__yaracuy"
            },
            {
                "_id": "zulia",
                "code": "zulia",
                "name": "Zulia",
                "pk": "VE__zulia"
            }
        ],
        "pk": "VE"
    },
    {
        "_id": "VN",
        "code": "VN",
        "name": "Viêt Nam",
        "states": [
            {
                "_id": "bac_giang",
                "code": "bac_giang",
                "name": "Bac Giang",
                "pk": "VN__bac_giang"
            },
            {
                "_id": "binh_dinh",
                "code": "binh_dinh",
                "name": "Binh Dinh",
                "pk": "VN__binh_dinh"
            },
            {
                "_id": "binh_duong",
                "code": "binh_duong",
                "name": "Binh Duong",
                "pk": "VN__binh_duong"
            },
            {
                "_id": "da_nang",
                "code": "da_nang",
                "name": "Da Nang",
                "pk": "VN__da_nang"
            },
            {
                "_id": "dong_bang_song_cuu_long",
                "code": "dong_bang_song_cuu_long",
                "name": "Dong Bang Song Cuu Long",
                "pk": "VN__dong_bang_song_cuu_long"
            },
            {
                "_id": "dong_bang_song_hong",
                "code": "dong_bang_song_hong",
                "name": "Dong Bang Song Hong",
                "pk": "VN__dong_bang_song_hong"
            },
            {
                "_id": "dong_nai",
                "code": "dong_nai",
                "name": "Dong Nai",
                "pk": "VN__dong_nai"
            },
            {
                "_id": "dong_nam_bo",
                "code": "dong_nam_bo",
                "name": "Dong Nam Bo",
                "pk": "VN__dong_nam_bo"
            },
            {
                "_id": "duyen_hai_mien_trung",
                "code": "duyen_hai_mien_trung",
                "name": "Duyen Hai Mien Trung",
                "pk": "VN__duyen_hai_mien_trung"
            },
            {
                "_id": "hanoi",
                "code": "hanoi",
                "name": "Hanoi",
                "pk": "VN__hanoi"
            },
            {
                "_id": "hung_yen",
                "code": "hung_yen",
                "name": "Hung Yen",
                "pk": "VN__hung_yen"
            },
            {
                "_id": "khu_bon_cu",
                "code": "khu_bon_cu",
                "name": "Khu Bon Cu",
                "pk": "VN__khu_bon_cu"
            },
            {
                "_id": "long_an",
                "code": "long_an",
                "name": "Long An",
                "pk": "VN__long_an"
            },
            {
                "_id": "mien_nui_va_trung_du",
                "code": "mien_nui_va_trung_du",
                "name": "Mien Nui Va Trung Du",
                "pk": "VN__mien_nui_va_trung_du"
            },
            {
                "_id": "thai_nguyen",
                "code": "thai_nguyen",
                "name": "Thai Nguyen",
                "pk": "VN__thai_nguyen"
            },
            {
                "_id": "thanh_pho_ho_chi_minh",
                "code": "thanh_pho_ho_chi_minh",
                "name": "Thanh Pho Ho Chi Minh",
                "pk": "VN__thanh_pho_ho_chi_minh"
            },
            {
                "_id": "thu_do_ha_noi",
                "code": "thu_do_ha_noi",
                "name": "Thu Do Ha Noi",
                "pk": "VN__thu_do_ha_noi"
            },
            {
                "_id": "tinh_can_tho",
                "code": "tinh_can_tho",
                "name": "Tinh Can Tho",
                "pk": "VN__tinh_can_tho"
            },
            {
                "_id": "tinh_da_nang",
                "code": "tinh_da_nang",
                "name": "Tinh Da Nang",
                "pk": "VN__tinh_da_nang"
            },
            {
                "_id": "tinh_gia_lai",
                "code": "tinh_gia_lai",
                "name": "Tinh Gia Lai",
                "pk": "VN__tinh_gia_lai"
            }
        ],
        "pk": "VN"
    },
    {
        "_id": "VG",
        "code": "VG",
        "name": "Îles Vierges britanniques",
        "states": [
            {
                "_id": "anegada",
                "code": "anegada",
                "name": "Anegada",
                "pk": "VG__anegada"
            },
            {
                "_id": "jost_van_dyke",
                "code": "jost_van_dyke",
                "name": "Jost van Dyke",
                "pk": "VG__jost_van_dyke"
            },
            {
                "_id": "tortola",
                "code": "tortola",
                "name": "Tortola",
                "pk": "VG__tortola"
            }
        ],
        "pk": "VG"
    },
    {
        "_id": "VI",
        "code": "VI",
        "name": "Îles Vierges des États-Unis",
        "states": [
            {
                "_id": "saint_croix",
                "code": "saint_croix",
                "name": "Saint Croix",
                "pk": "VI__saint_croix"
            },
            {
                "_id": "saint_john",
                "code": "saint_john",
                "name": "Saint John",
                "pk": "VI__saint_john"
            },
            {
                "_id": "saint_thomas",
                "code": "saint_thomas",
                "name": "Saint Thomas",
                "pk": "VI__saint_thomas"
            }
        ],
        "pk": "VI"
    },
    {
        "_id": "WF",
        "code": "WF",
        "name": "Wallis-et-Futuna",
        "states": [
            {
                "_id": "alo",
                "code": "alo",
                "name": "Alo",
                "pk": "WF__alo"
            },
            {
                "_id": "singave",
                "code": "singave",
                "name": "Singave",
                "pk": "WF__singave"
            },
            {
                "_id": "wallis",
                "code": "wallis",
                "name": "Wallis",
                "pk": "WF__wallis"
            }
        ],
        "pk": "WF"
    },
    {
        "_id": "EH",
        "code": "EH",
        "name": "Sahara occidental",
        "states": [
            {
                "_id": "bu_jaydur",
                "code": "bu_jaydur",
                "name": "Bu Jaydur",
                "pk": "EH__bu_jaydur"
            },
            {
                "_id": "wad-adh-dhahab",
                "code": "wad-adh-dhahab",
                "name": "Wad-adh-Dhahab",
                "pk": "EH__wad-adh-dhahab"
            },
            {
                "_id": "al-ayun",
                "code": "al-ayun",
                "name": "al-Ayun",
                "pk": "EH__al-ayun"
            },
            {
                "_id": "as-samarah",
                "code": "as-samarah",
                "name": "as-Samarah",
                "pk": "EH__as-samarah"
            }
        ],
        "pk": "EH"
    },
    {
        "_id": "YE",
        "code": "YE",
        "name": "Yémen",
        "states": [
            {
                "_id": "adan",
                "code": "adan",
                "name": "Adan",
                "pk": "YE__adan"
            },
            {
                "_id": "abyan",
                "code": "abyan",
                "name": "Abyan",
                "pk": "YE__abyan"
            },
            {
                "_id": "dhamar",
                "code": "dhamar",
                "name": "Dhamar",
                "pk": "YE__dhamar"
            },
            {
                "_id": "hadramaut",
                "code": "hadramaut",
                "name": "Hadramaut",
                "pk": "YE__hadramaut"
            },
            {
                "_id": "hajjah",
                "code": "hajjah",
                "name": "Hajjah",
                "pk": "YE__hajjah"
            },
            {
                "_id": "hudaydah",
                "code": "hudaydah",
                "name": "Hudaydah",
                "pk": "YE__hudaydah"
            },
            {
                "_id": "ibb",
                "code": "ibb",
                "name": "Ibb",
                "pk": "YE__ibb"
            },
            {
                "_id": "lahij",
                "code": "lahij",
                "name": "Lahij",
                "pk": "YE__lahij"
            },
            {
                "_id": "marib",
                "code": "marib",
                "name": "Marib",
                "pk": "YE__marib"
            },
            {
                "_id": "madinat_sana",
                "code": "madinat_sana",
                "name": "Madinat Sana",
                "pk": "YE__madinat_sana"
            },
            {
                "_id": "sadah",
                "code": "sadah",
                "name": "Sadah",
                "pk": "YE__sadah"
            },
            {
                "_id": "sana",
                "code": "sana",
                "name": "Sana",
                "pk": "YE__sana"
            },
            {
                "_id": "shabwah",
                "code": "shabwah",
                "name": "Shabwah",
                "pk": "YE__shabwah"
            },
            {
                "_id": "taizz",
                "code": "taizz",
                "name": "Taizz",
                "pk": "YE__taizz"
            },
            {
                "_id": "al-bayda",
                "code": "al-bayda",
                "name": "al-Bayda",
                "pk": "YE__al-bayda"
            },
            {
                "_id": "al-hudaydah",
                "code": "al-hudaydah",
                "name": "al-Hudaydah",
                "pk": "YE__al-hudaydah"
            },
            {
                "_id": "al-jawf",
                "code": "al-jawf",
                "name": "al-Jawf",
                "pk": "YE__al-jawf"
            },
            {
                "_id": "al-mahrah",
                "code": "al-mahrah",
                "name": "al-Mahrah",
                "pk": "YE__al-mahrah"
            },
            {
                "_id": "al-mahwit",
                "code": "al-mahwit",
                "name": "al-Mahwit",
                "pk": "YE__al-mahwit"
            }
        ],
        "pk": "YE"
    },
    {
        "_id": "YU",
        "code": "YU",
        "name": "Yougoslavie",
        "states": [
            {
                "_id": "central_serbia",
                "code": "central_serbia",
                "name": "Central Serbia",
                "pk": "YU__central_serbia"
            },
            {
                "_id": "kosovo_and_metohija",
                "code": "kosovo_and_metohija",
                "name": "Kosovo and Metohija",
                "pk": "YU__kosovo_and_metohija"
            },
            {
                "_id": "montenegro",
                "code": "montenegro",
                "name": "Montenegro",
                "pk": "YU__montenegro"
            },
            {
                "_id": "republic_of_serbia",
                "code": "republic_of_serbia",
                "name": "Republic of Serbia",
                "pk": "YU__republic_of_serbia"
            },
            {
                "_id": "serbia",
                "code": "serbia",
                "name": "Serbia",
                "pk": "YU__serbia"
            },
            {
                "_id": "vojvodina",
                "code": "vojvodina",
                "name": "Vojvodina",
                "pk": "YU__vojvodina"
            }
        ],
        "pk": "YU"
    },
    {
        "_id": "ZM",
        "code": "ZM",
        "name": "Zambie",
        "states": [
            {
                "_id": "central",
                "code": "central",
                "name": "Central",
                "pk": "ZM__central"
            },
            {
                "_id": "copperbelt",
                "code": "copperbelt",
                "name": "Copperbelt",
                "pk": "ZM__copperbelt"
            },
            {
                "_id": "eastern",
                "code": "eastern",
                "name": "Eastern",
                "pk": "ZM__eastern"
            },
            {
                "_id": "luapala",
                "code": "luapala",
                "name": "Luapala",
                "pk": "ZM__luapala"
            },
            {
                "_id": "lusaka",
                "code": "lusaka",
                "name": "Lusaka",
                "pk": "ZM__lusaka"
            },
            {
                "_id": "north-western",
                "code": "north-western",
                "name": "North-Western",
                "pk": "ZM__north-western"
            },
            {
                "_id": "northern",
                "code": "northern",
                "name": "Northern",
                "pk": "ZM__northern"
            },
            {
                "_id": "southern",
                "code": "southern",
                "name": "Southern",
                "pk": "ZM__southern"
            },
            {
                "_id": "western",
                "code": "western",
                "name": "Western",
                "pk": "ZM__western"
            }
        ],
        "pk": "ZM"
    },
    {
        "_id": "ZW",
        "code": "ZW",
        "name": "Zimbabwe",
        "states": [
            {
                "_id": "bulawayo",
                "code": "bulawayo",
                "name": "Bulawayo",
                "pk": "ZW__bulawayo"
            },
            {
                "_id": "harare",
                "code": "harare",
                "name": "Harare",
                "pk": "ZW__harare"
            },
            {
                "_id": "manicaland",
                "code": "manicaland",
                "name": "Manicaland",
                "pk": "ZW__manicaland"
            },
            {
                "_id": "mashonaland_central",
                "code": "mashonaland_central",
                "name": "Mashonaland Central",
                "pk": "ZW__mashonaland_central"
            },
            {
                "_id": "mashonaland_east",
                "code": "mashonaland_east",
                "name": "Mashonaland East",
                "pk": "ZW__mashonaland_east"
            },
            {
                "_id": "mashonaland_west",
                "code": "mashonaland_west",
                "name": "Mashonaland West",
                "pk": "ZW__mashonaland_west"
            },
            {
                "_id": "masvingo",
                "code": "masvingo",
                "name": "Masvingo",
                "pk": "ZW__masvingo"
            },
            {
                "_id": "matabeleland_north",
                "code": "matabeleland_north",
                "name": "Matabeleland North",
                "pk": "ZW__matabeleland_north"
            },
            {
                "_id": "matabeleland_south",
                "code": "matabeleland_south",
                "name": "Matabeleland South",
                "pk": "ZW__matabeleland_south"
            },
            {
                "_id": "midlands",
                "code": "midlands",
                "name": "Midlands",
                "pk": "ZW__midlands"
            }
        ],
        "pk": "ZW"
    }
]
