<template>
  <v-container class="overflow-hidden">
    <v-btn v-if="is_button_active"  class="buttons " depressed tile x-large @click="$emit('onClick')">
      <span class="title">{{ $t("buttons.addCart").toUpperCase() }}</span>
      <v-spacer></v-spacer>
      <span class="title" style="color: #077d60">$ {{ total }}</span>
    </v-btn>
  </v-container>

</template>

<script>
export default {
  name: "AddCartButton",
  props: {
    total: {
      type: String,
    },
    is_button_active: {
      type: Boolean,
    }
  }
}
</script>

<style scoped>
.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  position: fixed;
  left: 10px;
  right: 10px;
  bottom:80px;
  width: 95%;
  background-color: #FFFFFF;
  border-radius: 3px;
  max-width: 500px;
}


.title {
  font-family: var(--font-family--alethia)!important;
  font-size: 22px;
  font-weight: bolder;
  text-transform: capitalize;
}
</style>
