<template>
  <v-container class="auth">
    <form @keydown.enter="validateAndSubmit" class="mx-auto">
      <h1 class="small-title mb-4">{{ $t('buttons.forgot') }}</h1>

      <div class="mb-4">
        <span>{{ $t('buttons.please') }}.<br>{{ $t('buttons.receive') }}</span>
      </div>

      <template
        v-for="field in formFields"
      >
        <component
          :loading="loading"
          :is="field.comp"
          :key="field.name"
          :ref="field.name"
          :label="field.label"
          v-model="userData[field.name]"
          :append-icon="field.icon"
          :error-messages="errors.collect(field.name)"
          v-validate="field.validation"
          :data-vv-name="field.name"
          :data-vv-as="field.label"
        />
      </template>
      <AppButton
        block
        color="primary"
        :loading="loading"
        @click="validateAndSubmit"
        large
      >
        {{ $t('buttons.reset') }}
      </AppButton>

      <div class="documents">
        <router-link :to="{name:'SignIn'}">
          <p class="page-subtitle">{{ $t('buttons.sign-in-title') }}</p>
        </router-link>
        <router-link :to="{name:'SignUp'}">
          <p class="page-subtitle">{{ $t('buttons.create') }}</p>
        </router-link>
      </div>
    </form>
  </v-container>
</template>

<script>

import {authPagesMixins} from '@/mixins/authPagesMixins'

export default {
  name: 'ForgotPassword',
  mixins: [authPagesMixins],
  metaInfo() {
    return {
      title: this.$t('titles.lost_password'),
      titleTemplate: '%s - Blankspace',
       htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
  data() {
    return {
      userData: {
        email: null
      },
    }
  },
  methods: {
    onSubmit() {
      this.loading = true
      this.$store.dispatch('lostPassword', this.userData)
      .then(data => {
        this.createSuccessNotification(this.$t('notify.update_email_sent'))
        this.$router.push({name: 'Home'})
      })
        .catch(error => {
          console.log(error)
        })
      .then(() => {
        this.loading = false
      })
    },
  },
  computed: {
    formFields() {
      return [
        {
          comp: 'AppFormInput',
          icon: '',
          label: this.$t('placeholder.email'),
          name: 'email',
          validation: 'required|email'
        }
      ]
    }
  }
}
</script>
<style lang="postcss" scoped>

.auth {
  min-height: calc(100vh - 100px - 52px);
  display: flex;
  align-items: center;

  .alert {
    max-width: 410px;
    background-color: #FFF5F5;
    border: 1px solid #FFD6D6;
    color: #FF3838;
  }

  form {
    @media (--t) {
      max-width: 410px;
    }

    .documents {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 15px;
      @media (--t) {
        max-width: 410px;
      }

      p {
        text-decoration: underline;
        cursor: pointer;
      }


    }
  }

  .form-black-button {
    .footer-link {
      font-family: Sk-Modernist, 'sans-serif' !important;
      font-style: normal;
      font-weight: bold !important;
      font-size: 16px !important;
      line-height: 150% !important;
      text-align: center;
      letter-spacing: 0.02em;
      color: #FFFFFF;
    }
  }
}


</style>
