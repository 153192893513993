<template>
  <div>
    <div :class="smAndDown ? 'shopping-item' : 'shopping-item-container'">
      <v-row class="my-0 pb-2" v-if="!smAndDown" style="border-bottom: 1px solid #E8E8E8;">
        <v-col style="max-width: 120px" class="pb-0 pr-0 px-0">
          <FrameThumb :max-width="100" v-if="_material_name !== 'gift_card'" :layout="layout" :imageUrl="src"
                      :width="item.width" :height="item.height"/>
          <v-img v-else width="max-width:120px;" :aspect-ratio="(170/225)" :src="'/gift-card.png'"/>
        </v-col>
        <v-col class="shopping-item-summary" cols="3" lg="5" md="3" sm="3">
          <div v-if="_material_name  === 'gift_card'" class="row-one">
            <div class="section">
              <div style="width: 150px">
                <span class="title">{{ $t('cart.items.gift') }}</span>
              </div>
            </div>
            <div class="section mt-2">
              <div style="width: 150px">
                <span class="title">{{ $t('cart.items.card') }}</span>
              </div>
              <span class="dot title">:</span>
              <span class="value ml-lg-3">$ {{ gift_card_value }}</span>
            </div>
          </div>
          <div v-if="_material_name && _material_name !== 'gift_card'" class="row-one">
            <div class="section">
              <div style="width: 110px">
                <span class="title">{{ $t('cart.items.material') }}</span>
              </div>
              <span class="dot title">:</span>
              <span class="value ml-lg-3">{{ _material_name }}</span>
            </div>
            <div v-if="this.size_height && this.size_width" class="section">
              <div style="width: 110px">
                <span class="title">{{ $t('cart.items.size') }}</span>
              </div>
              <span class="dot title">:</span>
              <span class="value ml-lg-3">{{ size_width }}" x {{ size_height }}"</span>
            </div>
          </div>
          <div v-if="this.edge || this.frame" class="row-one" style="width: 90px">
            <div v-if="this.frame" class="section">
              <div style="width: 110px">
                <span class="title">{{ $t('cart.items.frame') }}</span>
              </div>
              <span class="dot title">:</span>
              <span class="value ml-lg-3">{{ frame }}</span>
            </div>
            <div v-if="this.edge" class="section">
              <div style="width: 110px">
                <span class="title">{{ $t('cart.items.edge') }}</span>
              </div>
              <span class="dot title">:</span>
              <span class="value ml-lg-3">{{ edge }}</span>
            </div>
          </div>
          <div v-if="this.border" class="row-one">
            <div class="section">
              <div style="width: 110px">
                <span class="title">{{ $t('cart.items.border') }}</span>
              </div>
              <span class="dot title">:</span>
              <span class="value ml-lg-3">{{ border }}</span>
            </div>
          </div>
          <div class="row-one ">
            <div v-if="this.rails" class="section">
              <div style="width: 110px">
                <span class="title">{{ $t('cart.items.finish') }}</span>
              </div>
              <span class="dot title">:</span>
              <span class="value ml-lg-3">{{ rails }}</span>
            </div>
            <div v-if="this.layout" class="section">
              <div style="width: 110px">
                <span class="title">{{ $t('cart.items.layout') }}</span>
              </div>
              <span class="dot title">:</span>
              <span class="value ml-lg-3">{{ _layout_name }}</span>
            </div>
          </div>
        </v-col>
        <v-col class="shopping-item-spinner pl-5 pt-5" cols="3" lg="3" align-self="end" md="3" sm="3">
          <v-row class="spinner-component">
            <v-col class="spinner-button" lg="4" sm="4" @click="decrease">
              <IconMinus/>
            </v-col>
            <v-col class="spinner-content" lg="4" sm="4">
              <span>{{ quantity }}</span>
            </v-col>
            <v-col class="spinner-button" lg="4" sm="4" @click="increase">
              <IconPlus/>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="shopping-item-total" align-self="end" cols="3" lg="2" md="3" sm="3">
          <span class="cost-text d-block text-right">$ {{ price.toFixed(2) }}</span>
        </v-col>
        <v-col cols="12" class="pa-0">
          <AppButton
              text
              @click="remove"
              class="my-2 text-capitalize font-weight-medium remove-btn"
              color="passive-grey"

          >
            {{ $t('cart.remove') }}
          </AppButton>
        </v-col>
      </v-row>
      <v-row class="my-0" v-else>
        <v-col class="pb-0" cols="4">
          <v-img :aspect-ratio="(170/225)" width="95" :src="src || '/gift-card.png'"/>
        </v-col>
        <v-col class="item-info-section" cols="8" lg="8" sm="8">


          <v-row v-if="_material_name !== 'gift_card'">
            <v-col class="first-info-column" cols="6" sm="6">
              <span class="title">{{ $t('cart.items.material') }}</span>
              <span class="value">{{ _material_name }}</span>
            </v-col>
            <v-col class="second-info-column" cols="6" sm="6">
              <span class="title">{{ $t('cart.items.size') }}</span>
              <span class="value">{{ size_width }}" x {{ size_height }}"</span>
            </v-col>
          </v-row>

          <v-row v-if="_material_name === 'gift_card'">
            <v-col class="first-info-column" cols="6" sm="6">
              <span class="title">{{ $t('cart.items.gift') }}</span>
            </v-col>
            <v-col class="second-info-column" cols="6" sm="6">
              <span class="title">Card Amount</span>
              <span class="value">$ {{ gift_card_value }}</span>
            </v-col>
          </v-row>


          <v-row>
            <v-col v-if="layout" class="first-info-column" cols="6" sm="6">
              <span class="title">{{ $t('cart.items.layout') }}</span>
              <span class="value">{{ _layout_name }}</span>
            </v-col>
            <v-col v-if="this.edge" class="second-info-column" cols="6" sm="6">
              <span class="title">{{ $t('cart.items.edge') }}</span>
              <span class="value">{{ edge }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="first-info-column" cols="6" sm="6">
              <span class="price">${{ price.toFixed(2) }}</span>
            </v-col>
            <v-col class="second-info-column d-flex align-center" cols="6" sm="6">
              <v-row class="spinner">
                <v-col class="decrement-button" cols="4" sm="4" @click="decrease">-</v-col>
                <v-col class="spinner-value" cols="4" sm="4">{{ quantity }}</v-col>
                <v-col class="increment-button" cols="4" sm="4" @click="increase">+</v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="pa-0">
          <AppButton
              text
              :loading="loading"
              @click="remove"
              class="my-2 text-capitalize font-weight-medium remove-btn"
              color="passive-grey"

          >
            {{ $t('cart.remove') }}
          </AppButton>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {IconMinus, IconPlus} from "@/assets/svg-icons";

export default {
  name: "ShoppingItem",
  data: () => ({loading: false}),
  components: {
    IconMinus,
    IconPlus,
    RectangleFrame: () => import("@/components/Preview/Frames/Rectangle"),
    VerticalFrame: () => import("@/components/Preview/Frames/Vertical"),
    HorizontalFrame: () => import("@/components/Preview/Frames/Horizontal"),
    WavyFrame: () => import("@/components/Preview/Frames/Wavy"),
    FrameThumb: () => import("@/components/Preview/Frames/FrameThumb"),
  },
  props: (['item', 'material', 'border', 'rails', 'layout', 'size_height', 'size_width', 'src', 'quantity', 'price', "quantity", "frame", "edge", "gift_card_value"]),
  methods: {
    remove(e) {
      this.loading = true;
      this.$emit('remove');
    },
    increase(e) {
      this.$emit('increase');
    },
    decrease(e) {
      this.$emit('decrease', e);
    }
  },
  computed: {
    orientation(width, height) {
      const _aspectRatio = this.size_height / this.size_width;
      if (_aspectRatio >= 0 && _aspectRatio < 1) return "horizontal";
      else if (_aspectRatio === 1) return "square";
      else if (_aspectRatio > 1) return "vertical";
    },
    _material_name() {
      if (localStorage.getItem("lang") === "en") {
        if (this.material === 'gift_card') {
          return this.material
        } else {
          return this.$store.getters.get_material_en.filter(e => e.value === this.material)[0].title;
        }
      } else {
        if (this.material === 'gift_card') {
          return this.material
        } else {
          return this.$store.getters.get_material_fr.filter(e => e.value === this.material)[0].title;
        }
      }
    },
    _layout_name() {
      if (localStorage.getItem('lang') === 'fr') {
        if (this.layout === '3 Horizontal Panels') {
          return '3 Panneaux Horizontaux';
        } else if (this.layout === '1 Panel') {
          return '1 Panneau';
        } else if (this.layout === '3 Vertical Panels') {
          return '3 Panneaux Verticaux';
        } else if (this.layout === '4 Wavy Panels') {
          return '4 Panneaux Ondules';
        }
      } else return this.layout;

    },
    layoutComponent() {
      if (this.layout === "1 Panel") return "RectangleFrame";
      if (this.layout === "3 Horizontal Panels") return "HorizontalFrame";
      if (this.layout === "3 Vertical Panels") return "VerticalFrame";
      if (this.layout === "4 Wavy Panels") return "WavyFrame";
    },
    imageContainerSizes() {
      //hor => yat, ver => dik
      if (this.layout === "4 Wavy Panels") {
        return {
          width: "110px",
          height: `${parseFloat(this.size_height) * 110 / parseFloat(this.size_width)}px`
        }
      } else if (this.orientation === "horizontal") {
        return {
          width: "135px",
          height: `${parseFloat(this.size_height) * 135 / parseFloat(this.size_width)}px`
        }

      } else if (this.orientation === "vertical") {
        return {
          height: "174px",
          width: `${parseFloat(this.size_width) * 174 / parseFloat(this.size_height)}px`
        }
      } else {
        return {
          height: "174px",
          width: "174px",
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.image-item::v-deep .v-image__image--cover {
  background-size: contain !important;
}


.shopping-item-container {
  @media (--t) {
    .remove-btn {
      padding-left: 10px;
    }
  }
  display: flex;
  @media (--t) {
    max-height: 336px;
    padding-left: 0;
  }

  .shopping-item-summary {

    .row-one {
      display: flex;
      flex-direction: row;
      @media (--t) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .shopping-item-spinner {
    margin-top: 10px;

    .spinner-component {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 105px;
      max-height: 35px;
      @media (--t) {
        display: flex;
        max-height: 45px;
        max-width: 135px;
      }

      .spinner-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #E8E8E8;
        height: 43px;
        width: 43px;
        cursor: pointer;

        @media (--t) {
        }

      }

      .spinner-content {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #E8E8E8;
        height: 43px;
        width: 43px;

        span {
          font-family: Sk-Modernist, 'sans-serif';
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.02em;
          color: #000000;
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none
        }

      }

    }

  }

  .shopping-item-total {
    padding-top: 55px;

    .cost-text {
      font-family: Sk-Modernist, 'sans-serif';
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.02em;
      color: #000000;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none
    }
  }

  .remove-text {
    display: block;
    font-family: Sk-Modernist, 'sans-serif';
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #919191;
    margin-top: 15px;
    cursor: pointer !important;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    max-width: 80px;
  }


  .section {
    display: flex;
    flex-direction: column;
    width: 83px;
    @media (--t) {
      display: flex;
      flex-direction: row;
      width: 340px;
    }


    .dot {
      display: none;
      @media (--t) {
        display: flex;
      }
    }
  }


  .value {
    font-family: Sk-Modernist, "sans-serif";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.02em;
    text-align: left;
    text-transform: capitalize;

    @media (--t) {
      font-size: 18px;
      letter-spacing: 0.02em;
      color: #000000;
      line-height: 28px !important;
    }
  }

  .title {
    font-family: Sk-Modernist;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.02em;
    text-align: left;
    @media (--t) {
      font-family: Sk-Modernist;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #000000;
    }

  }


  .info {
    padding-top: 0;
    @media (--t) {
      padding-top: 20px;

    }

  }

  @media (--t) {
    display: flex;
    flex-direction: column;

  }

  .title {
    font-family: Sk-Modernist;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #000000;
  }

  .value {
    font-family: Sk-Modernist;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #000000;
  }
}


.shopping-item {
  display: grid;
  @media (--t) {
    display: none;
  }

  hr {
    margin-top: 10px;
    margin-bottom: 0;
  }

  span {
    display: block;
    font-family: Sk-Modernist, 'sans-serif';
    line-height: 17px;
    color: #000000;
    letter-spacing: 0.02em;
    font-size: 14px;
  }

  .remove-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.02em;
    cursor: pointer;
    color: #919191;
  }

  .image-section {
    display: flex;
    justify-content: center;
    align-items: center;


    img {
      width: inherit;
      object-fit: cover;
      max-height: 185px;
      max-width: 185px;
      border: 1px solid #cdcdcd;
    }
  }

  .item-info-section {

    .title {
      font-weight: bold;
    }

    .value {
    }

    .price {
      font-weight: bold;
      font-size: 18px;
      line-height: 150%;
    }

    .first-info-column {
    }

    .second-info-column {

      .spinner {
        max-width: 105px;
        border: 1px solid #E8E8E8;

        .decrement-button {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #E8E8E8;
          box-sizing: border-box;
          max-height: 33px;
          font-weight: bold;
          cursor: pointer;
          user-select: none;
        }

        .spinner-value {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ffffff;
          max-height: 33px;
          font-size: 16px;
          user-select: none;
          font-weight: bold;
        }

        .increment-button {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #E8E8E8;
          max-height: 33px;
          font-weight: bold;
          user-select: none;
          cursor: pointer;
        }
      }


    }


  }
}


</style>
