<template>
  <div class="product-review-card">
    <div class="product-review-card-avatar">
      <v-avatar>
        Ö A
      </v-avatar>
      <Avatar :size="116" :src="src"/>
      <IconStar v-for="(star,index) in stars" :key="index"/>
    </div>
    <div class="product-review-card-review text-center">
      <span>
        {{ review }}
      </span>
    </div>
    <footer class="d-flex align-center flex-column text-center">
      <span class="d-block">- {{ author }}</span>
      <span class="d-block">{{ social }}</span>
    </footer>
  </div>
</template>

<script>
import Avatar from "../shared/Avatar";
import IconStar from "@/assets/svg-icons/icons/star.svg";

export default {
  name: "ProductReviewCard",
  components: {Avatar, IconStar},
  props: ["stars", "review", "author", "social", "src"]
}
</script>

<style lang="scss" scoped>
.product-review-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 400px;
  min-width: 270px;
  max-width: 270px;
  margin-left: 5px;
  margin-right: 5px;

  .avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .review {
    span {
      font-family: Sk-Modernist, 'sans-serif';
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #000000;
    }

    footer {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
