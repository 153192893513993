<template>
  <div class="swiper-container" ref="swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in items" :key="index">
        <slot name="item" v-bind="item">

        </slot>
      </div>
    </div>
    <div class="swiper-pagination" ref="pagination"></div>
  </div>
</template>

<script>
import {swiperMixins} from '@/mixins/swiperMixins'

export default {
  name: 'AppSwiper',
  mixins: [swiperMixins],
  props: {
    items: {
      type: Array,
      default: () => []
    },
  },

  computed: {
    options() {
      return {
        ...{
          pagination: {
            el: this.$refs.pagination,
          }
        },
        ...this.$attrs
      }
    }
  }
}
</script>

<style scoped>

</style>
