<template>
  <div class="inspire-section">
    <v-img :src="background" width="100%" :aspect-ratio="aspectRatio">
      <div class="section-content pt-10 pt-md-0">
        <div class="inspire-header">
          <div class="px-7 px-md-0">
            <h3>{{ $t('about.environment.title') }}</h3>
            <h4>{{ $t('about.environment.subtitle') }}</h4>
          </div>
          <div class="px-7 px-md-0" v-html="$t('about.environment.description')"></div>
        </div>
        <div class="container mt-16" v-if="smAndDown">
          <InspiringStories
            :list="inspired_data"
          />
        </div>
      </div>
    </v-img>
    <InspiringStories
      v-if="!smAndDown"
      :style="cardStyle"
      :list="inspired_data"
      ref="inspireCard"
    />

  </div>
</template>

<script>

export default {
  name: 'Inspired',
  data() {
    return {
      inspired_data: [],
      nudgeCard: 0,
    }
  },
  methods: {
    inspireData() {
      if (localStorage.getItem('lang') === 'en') {
        this.inspired_data = this.$store.getters.get_inspire_en
      } else if (localStorage.getItem('lang') === 'fr') {
        this.inspired_data = this.$store.getters.get_inspire_fr
      }
    },
    alignCardNudge() {
      this.nudgeCard = (this.$refs.inspireCard.$el.clientHeight || 0) * (2/3)
    }

  },

  created() {
    this.inspireData()
  },
  mounted() {
    setTimeout(() => {
      this.alignCardNudge()
    }, 350)
  },
  computed: {
    background() {
      return this.smAndDown ? '/img/cover-1.webp' : '/img/eco.jpg'
    },
    aspectRatio() {
      return this.smAndDown ? (360/1235) : (1920 / 1000)
    },
    cardStyle() {
      return {
        ...this.$vuetify.breakpoint.lgAndUp && {marginTop: `-${this.nudgeCard}px`}
      }
    }
  }
}
</script>

<style lang="scss">

.inspire-section {
  .nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  .navigation-next {
    right: 16px;
  }
  .navigation-prev {
    left: 16px;
  }
  .swiper-pagination {
    visibility: hidden;
  }
  padding: 0;
  border: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  //d-flex flex-wrap align-center flex-column
  > * {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .section-content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(100.16deg, rgba(0, 0, 0, 0.2) 31.49%, rgba(0, 0, 0, 0) 86.76%);
    }
    .inspire-header {
      max-width: 970px;
      z-index: 1;
      @media(min-width: 1400px) {
        margin-top: -40px;
      }
      @media(min-width: 1600px) {
        margin-top: -140px;
      }
      > * {
        margin-bottom: 16px;
      }
      h3 {
        font-family: var(--font-family--alethia) !important;
        font-size: 36px !important;
        font-weight: normal;
        text-align: center !important;
        color: #FFFFFF !important;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
        margin-bottom: 16px;

        @media (--t) {
          font-size: 60px !important;
        }
      }

      h4 {
        font-family: Sk-Modernist, 'sans-serif' !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 19px !important;
        text-align: center !important;
        letter-spacing: 0.15em !important;
        color: #FFFFFF !important;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
      }

      p {
        font-family: Sk-Modernist, 'sans-serif' !important;
        font-size: 16px !important;
        line-height: 130% !important;
        text-align: center !important;
        letter-spacing: 0.02em !important;
        color: #FFFFFF !important;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
        @media (--t) {
          font-family: Sk-Modernist, 'sans-serif' !important;
          font-size: 18px !important;
          line-height: 150% !important;
          letter-spacing: 0.02em !important;
        }
      }
    }
  }
  .arrows {
    display: none;
    @media (--t) {
      display: block;
    }
  }

  .pagination {
    background-color: #000000 !important;
  }

  .pagination[aria-current] {
    background-color: #009771 !important;
  }

  .inspire-pagination-container {
    position: relative;
  }

  .head {
    padding: 10px 20px 0 25px;
    @media (--t) {
      padding: unset;
    }
  }

  .inspire-slide-card {
    .swiper-pagination {
      position: relative;
      margin: 16px 0 0;
      bottom: 0;
    }
    .swiper-slide {
      height: 100%;
    }
    padding: 65px 0 0;
    @media (--t) {
      padding: 8px 86px 0;
    }

    @media only screen and (min-width: 1400px) and (max-width: 1600px) {
      margin-top: -250px!important;
    }

    @media only screen and (min-width: 1260px) and (max-width: 1400px)   {
      margin-top: -180px!important;
    }


    h3 {
      font-family: var(--font-family--alethia) !important;
      font-size: 45px;
      line-height: 84.6%;
      text-align: center;
      color: #000000;
      font-weight: normal;
      @media (--t) {
        line-height: 109px;
        font-size: 60px;

      }
    }

    p {
      font-family: Sk-Modernist, sans-serif !important;
      font-size: 16px !important;
      line-height: 19px !important;
      text-align: center !important;
      letter-spacing: 0.15em !important;
      color: #000000 !important;


    }


  }

}
</style>
