<template>
  <v-select
      :items="items"
      v-model="innerValue"
      outlined
      v-bind="$attrs"
      class="app-select rounded-0"
      :dense="dense"
      height="45px"
      :menu-props="mProps"
      append-icon="mdi-chevron-down"
      ref="select"
      :immediate="true"
      @click:append="openMenu"
  >
    <template v-slot:label v-if="!!$slots.item">
      <slot name="label"></slot>
    </template>
    <template v-slot:item="attrs" v-if="!!$slots.item">
      <slot name="item" v-bind="attrs"></slot>
    </template>
    <template v-slot:selection="attrs" v-if="!!$slots.selection">
      <slot name="selection" v-bind="attrs"></slot>
    </template>

  </v-select>
</template>

<script>
export default {
  name: 'AppSelect',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number, Object],
      default: () => null
    },
    menuProps: {
      type: Object,
      default: () => ({})
    },
    dense: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    openMenu() {
      this.$refs.select.focus()
      this.$refs.select.isMenuActive = true
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    mProps() {
      return {
        ...{
          nudgeBottom: '44px',
          closeOnClick: true,
          contentClass: 'app-select-list',
          maxHeight: 200
        },
        ...this.menuProps
      }
    }
  },
  watch : {
    value(value){
      // if(value) this.$emit("input", value)
    }
  }
}
</script>

<style lang="scss">
.app-select {
  &.v-text-field--outlined {
    fieldset {
      border: 1px solid #000000;
    }

    &.v-input--is-dirty {
      fieldset {
        border: 2px solid #077D60;
      }

    }

    &.v-input--is-focused {
      fieldset {

      }
    }

    &.v-input--dense:not(.v-text-field--solo) {
      .v-label {
        top: 12px;
      }

      .v-input__append-inner {
        margin-top: 13px !important;
      }
    }
  }

  /*.v-select__selection {
    font-size: 18px;
  }*/
}

.app-select-list {
  overflow-y: auto;

  .v-list {
    margin: 0;
    padding: 0;

    .v-list-item {
      border: 1px solid #000000;

      &:hover {
        color: #000000 !important;

        &:before {
          opacity: .5;
          background-color: #B9DCCB;
          color: #000000 !important
        }
      }
    }
  }
}

</style>
