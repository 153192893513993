<template>
  <div class="gift-certificate ">
    <v-container class="">
      <Heading :subtitle="$t('shop.gift.subtitle')" :title="$t('shop.gift.title')"/>
      <div class="gift-cards">
        <v-row class="mt-0">
          <v-col class="image-container px-0 px-md-3 pr-lg-10" cols="12" lg="6">
            <v-img alt="gift_cards" class="img-fluid" src="/gift-card.png" :aspect-ratio="smAndDown ? (360/240) : 1"
                   width="100%"/>
          </v-col>
          <v-col class="gift-card-info pt-lg-12 pl-lg-10" cols="12" lg="6">
            <div class="card-body px-4 px-md-0">
              <h3 v-if="lang === 'en'" class="mid-titles">{{ $t("shop.gift.tertiary") }}</h3>
              <h3 v-if="lang === 'fr'" class="mid-titles">{{ $t("shop.gift.tertiary") }}<span
                  class="french-letter">É</span></h3>
              <p class="page-subtitle text-left mb-lg-7 font-weight-bold">{{ $t("shop.gift.offer") }}</p>
              <p class="page-subtitle text-left">{{ $t("shop.gift.choose") }}</p>
              <div class="quantity-section">
                <AppSelect
                    :items="gift_card_options"
                    item-value="gift_card_value"
                    item-text="name"
                    v-model="gift_data"
                    return-object
                    label="Select Value"
                    class="mr-2 mr-md-0"
                    style="max-width: 140px;"

                />
                <AppButton height="50" min-width="180" class="ml-auto ml-md-2" @click="addToCart">
                  {{ $t("buttons.addCart") }}
                </AppButton>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>


    <div class="sections">
      <v-container class="pa-0 pa-md-3">
        <AppSwiper ref="swiper" v-bind="options" class="slide-section" :items="sliders">
          <template v-slot:item="slide">
            <div class="slide-section-item-container">
              <span class="slide-section-item-container-title">“{{ slide.text }}”</span>
              <span v-if="slide.writer" class="slide-section-item-container-writer">-{{ slide.writer }} </span>
            </div>
          </template>
        </AppSwiper>
        <template
            v-for="direction in ['next', 'prev']"
        >
          <v-btn
              depressed
              @click="$refs.swiper.swiper[`slide${direction.capitalize()}`]()"
              icon
              absolute
              color="primary"
              ref="navBtn"
              :key="`${direction}Btn`"
              :class="`navigation-${direction} nav-btn`"
              height="60"
              width="60"
          >
            <v-icon :size="smAndDown ? 35 : 50">mdi-chevron-{{ direction === 'next' ? 'right' : 'left' }}</v-icon>
          </v-btn>
        </template>
      </v-container>
    </div>

    <JoinCommunitySection/>
  </div>

</template>

<script>
import CustomText from "@/components/shared/CustomText";
import BlogItem from "@/components/cards/shared-card/blog-item-card";
import {axiosInstance} from "@/utils/setAxios";
import {GIFT_CARD_KARAVAN_PRODUCT_ID} from "@/utils/config";

export default {
  name: "GiftCertificate",
  metaInfo() {
    return {
      title: this.$t('titles.gift'),
      titleTemplate: '%s - Blankspace',
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.$t('titles.gift-content')},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  components: {
    BlogItem,
    CustomText
  },
  data() {
    return {
      gift_card_options: [],
      gift_data: {
        type: "",
        quantity: 1,
        size: null,
        thumbnail_id: null,
        svg_file_id: null,
        svg_file_base64: null,
        price: null,
        inventory_product_id: "",
        inventory_product_var_id: "",
        product_type: '',
        gift_card_value: null,
      },
      sliders: [
        {id: 1, text: "Make space for the life you want to have", writer: "Shawna Scafe"},
        {id: 2, text: "Never underestimate the power of coming HOME", writer: ""},
        {
          id: 3,
          text: "Whenever you creating beauty around you, you are restoring your own soul",
          writer: "Alice Walker"
        },
        {
          id: 4,
          text: "Your home should allow you to slow down and appreciate the good things in life",
          writer: ""
        },
      ]
    }
  },
  methods: {
    addToCart() {
      this.$store.dispatch("SET_NEW_ITEM", this.gift_data);
    },
    fetchGiftCards() {
      axiosInstance
          .get(`/products/${GIFT_CARD_KARAVAN_PRODUCT_ID}`)
          .then(({data: {product: {inventory_product}}, status}) => {
            this.gift_card_options = inventory_product?.vars?.filter(p => p?.price && p.price > 0)?.map(item => {
              return {
                name: `$${item.price}`,
                type: "gift_card",
                quantity: 1,
                size: null,
                thumbnail_id: null,
                svg_file_id: null,
                svg_file_base64: null,
                price: item.price,
                inventory_product: inventory_product.id,
                inventory_product_var_id: item.id,
                product_type: 'gift_card',
                gift_card_value: item.price,
              }
            }) || [];
            this.gift_card_options?.sort((a, b) => a.price - b.price)
            this.gift_data = this.gift_card_options?.[0];
          })
    }
  },
  computed: {
    lang() {
      return localStorage.getItem('lang');
    },
    options() {
      return {
        loop: true,

        slidesPerView: 1,
        spaceBetween: 16,
        height: '300px',
        autoplay: true,
        breakpoints: {
          980: {
            slidesPerView: 1,
            spaceBetween: 0,
            height: '270px',
          },
        },
      }
    }
  },
  mounted() {
    this.fetchGiftCards();
  }
}
</script>

<style lang="scss">


.gift-certificate {
  .container {
    @media(min-width: 1500px) {
      max-width: 1400px
    }
  }

  overflow: hidden;
  padding-top: 40px;
  @media(--t) {
    padding-top: 54px;
  }

  .french-letter {
    font-family: Albra, 'sans-serif' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 24px !important;
    line-height: 63px !important;
    color: #000000 !important;
    @media (--t) {
      font-size: 28px !important;
      line-height: 63px !important;

    }
  }


  h2 {
    display: none;
    letter-spacing: 0.15em;
    @media (--t) {
      display: flex !important;
      justify-content: center;
    }
  }

  .community-section {
    margin-top: 90px;
    padding-top: 42px;
    padding-bottom: 20px;
    @media (--t) {
      padding-bottom: 40px;
      margin-top: 0 !important;
      padding-top: 32px !important;
    }

    .communities {
      margin-top: 63px;

      img {
        width: inherit;
        margin-top: 20px;
      }
    }
  }

  .card-body {
    h3 {
      font-family: var(--font-family--alethia) !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 28px !important;
      /*margin-bottom: 16px;*/
      line-height: 1 !important;
      color: #000000 !important;
      @media (--t) {
        max-width: unset !important;
        font-size: 35px !important;
        line-height: 63px !important;
      }
    }
  }

  p {
    font-family: Sk-Modernist, 'sans-serif' !important;
    font-size: 16px !important;
    line-height: 150% !important;
    letter-spacing: 0.02em !important;
    color: #393939 !important;
    max-width: 550px !important;
    text-align: justify !important;
  }

  .green-select {
    max-width: 100px !important;
    height: 50px !important;
    @media (--t) {
      max-width: 90px !important;
    }
  }

  .button {
    padding: 10px 24px !important;
    background-color: #000000 !important;
    font-family: Sk-Modernist, 'sans-serif' !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 150% !important;
    letter-spacing: 0.02em !important;
    color: #FFFFFF !important;
    margin-left: 20px !important;
    width: 70%;
    height: 50px;
    @media (--t) {
      width: unset;
      font-size: 20px !important;

    }
  }

}

.quantity-section {
  display: flex;
  flex-direction: row;
  margin-top: 30px !important;
  @media (--t) {
    margin-top: 68px !important;
  }
}

.gift-cards {
  display: flex;
  margin-bottom: 30px;
  border-radius: 0;
  border: 0;
  margin-top: 15px;
  @media (--t) {
    margin-top: 46px;
  }

  .gift-card-info {
    @media (--t) {
      padding-right: 0 !important;
    }
  }

}

.sections {
  background-color: #F4F4F4;
  height: 270px;

  .container {
    position: relative;

    .nav-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      @media(max-width: 1024px) {
        &.v-btn.theme--light:hover {
          &:before {
            opacity: 0;
          }
        }
      }
    }

    .navigation-next {
      right: 0;
    }

    .navigation-prev {
      left: 0;
    }
  }

  @media (--t) {
    height: 400px;
    margin-top: 60px;
    padding-bottom: 10px;
  }

  .slide-section {
    .swiper-pagination {
      bottom: 30px;
    }

    .swiper-slide {
      height: unset;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 40px 0;
      min-height: 270px;
      @media (--t) {
        min-height: 400px;
        align-items: center;
        padding: 0;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (--t) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 40px;
        min-height: 400px;
      }

      &-container {
        max-width: 742px;
        padding: 0 16px;

        &-title {
          display: block !important;
          font-family: var(--font-family--alethia) !important;
          font-size: 27px;
          line-height: 104.6%;
          text-align: center !important;
          color: #000000 !important;
          max-width: 280px;
          @media (--t) {
            font-size: 50px;
            margin-bottom: 50px;
            max-width: unset;
          }
        }

        &-writer {
          display: block;
          font-family: Sk-Modernist, 'sans-serif';
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 130%;
          letter-spacing: 0.01em;
          color: #000000;
          text-align: center;
          margin-top: 12px;
          @media (--t) {
            margin-top: unset;
            margin-bottom: 47px;
          }
        }

      }
    }

  }


  //.slogan-title {
  //  font-size: 40px !important;
  //  @media (--t) {
  //    font-size: 60px !important;
  //    max-width: unset;
  //  }
  //}
}

.gift-card {
  margin-top: 91px;
}


</style>
