<template>
  <v-btn
    v-bind="$attrs"
    tile
    :color="color"
    class="app-btn"
    depressed
    @click="$emit('click')"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    color: {
      type: String,
      default: () => 'primary'
    }
  }
}
</script>

<style scoped lang="scss">
  .v-btn.app-btn {
    font-family: var(--font-family-modernist);
    font-weight: normal;
    letter-spacing: 0.02em;
  }
</style>
