import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import localforage from "localforage";

import user from "./modules/user";
import address from "./modules/address";
import country from "./modules/country";
import order from "./modules/order";
import data from "./modules/data";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    key: "user_data",
    storage: localforage,
    asyncStorage: true,
    restoreState: (key) => localforage.getItem(key),
    reducer: (state) => {
        return {
            order: {
                items_count: state.order.items_count,
                items_quantity_count: state.order.items_quantity_count,
                cart: state.order.cart,
                gift_cards: state.order.gift_cards,
                coupon: state.order.coupon,
                order_summary: state.order.order_summary,
                shipment_rates: state.order.shipment_rates,
                shipping_price: state.order.shipping_price,
                applied_taxes: state.order.applied_taxes,
                discount_code_applied_status: state.order.discount_code_applied_status,
                discountAmount: state.order.discountAmount,
            },
        };
    },
});

export default new Vuex.Store({
    state: () => ({
        mobileMenu: false,
    }),
    getters: {
        mobileMenu: (state) => state.mobileMenu,
    },
    mutations: {
        SET_MOBILE_MENU(state, value) {
            state.mobileMenu = value === true;
        },
    },
    modules: {
        user,
        address,
        country,
        data,
        order,
    },

    plugins: [vuexLocal.plugin],
});
