<template>
  <v-navigation-drawer v-model="isMenuOpened" width="80vw" app class="app-drawer" disable-resize-watcher>
    <v-toolbar flat dense>
      <v-btn icon @click="isMenuOpened = false">
        <IconX/>
      </v-btn>
    </v-toolbar>
    <template v-for="(list, lIndex) in menuLists">
      <v-list :class="list.classes" :key="`mobileMenuList-${lIndex}`">
        <template v-for="item in list.nodes">
          <v-list-item v-if="typeof item.condition !== 'undefined' ? item.condition : true" :key="item.title" :to="item.to">
            <v-list-item-content>
              {{ item.title }}
            </v-list-item-content>
            <v-list-item-action v-if="item.active">
<!--              <IconPlus/>-->
              <v-icon>mdi-chevron-left</v-icon>
            </v-list-item-action>
          </v-list-item>
        </template>
        <v-menu v-if="list.classes === 'sub-menu'" nudge-bottom="40">
          <template v-slot:activator="{ on }">
            <v-list-item v-on="on">
              {{ activeLocale }} <v-icon right size="18" class="pt-1">mdi-chevron-down</v-icon>
            </v-list-item>
          </template>
          <v-list>
            <template v-for="locale in availableLocales">
              <v-list-item
                :key="locale.code"
                @click="changeLanguage(locale.code)"
              >
                {{ locale.title}}
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import IconArrowDown from '@/assets/svg-icons/icons/down-black-mini.svg'
import { IconX, IconPlus } from '@/assets/svg-icons'
import { mapGetters } from 'vuex'
import {languageSelctorMixins} from '@/mixins/languageSelctorMixins'

export default {
  name: 'MobileMenu',
  mixins: [languageSelctorMixins],
  computed: {
    ...mapGetters(['getAuthStatus']),
    isMenuOpened: {
      get() {
        return this.$store.getters.mobileMenu
      },
      set(val) {
        this.$store.commit('SET_MOBILE_MENU', val)
      }
    },
    menuLists() {
      return [
        {
          classes: 'top-menu',
          nodes: [
            {
              title: this.$t('navbar.shop'),
              to: {name: 'Shop'},
              active: ['Shop'].includes(this.$route.name),
            },
            {
              title: this.$t('navbar.blog'),
              to: {name: 'Blog'},
              active: ['Blog'].includes(this.$route.name),
            },
            {
              title: 'FAQ',
              to: {name: 'Faq'},
              active: ['Faq'].includes(this.$route.name),
            },
            {
              title: this.$t('navbar.about'),
              to: {name: 'About'},
              active: ['About'].includes(this.$route.name),
            }
          ]
        },
        {
          classes: 'sub-menu',
          nodes: [
            {
              title: this.$t('navbar.login'),
              to: {name: 'SignIn'},
              active: ['SignIn'].includes(this.$route.name),
              condition: !this.getAuthStatus
            },
            {
              title: this.$t('navbar.account'),
              to: {name: 'Account Details'},
              active: ['Login'].includes(this.$route.name),
              condition: this.getAuthStatus
            },
            {
              title: this.$t('navbar.return'),
              to: {path: '/faq#returns'},
              active: ['Programe'].includes(this.$route.name), // ? What is this?
            },
            {
              title: this.$t('navbar.shipping'),
              to: {path: '/faq#shipment'},
              active: ['Shipping'].includes(this.$route.name),
            },
            {
              title: this.$t('navbar.volume'),
              to: {path:'/faq#volume'},
              active: ['Volume'].includes(this.$route.name),
            },
          ]
        }
      ]
    }
  },

  components: {
    IconArrowDown,
    IconX,
    IconPlus
  }
}
</script>

<style lang="scss">

.app-drawer {
  .v-list {
    .theme--light.v-list-item {
      font-family: Sk-Modernist, 'sans-serif' !important;
      font-style: normal !important;
      padding-left: 40px;
    }
    &.top-menu {
      .theme--light.v-list-item {
        font-weight: bold;
        font-size: 21px !important;
        line-height: 25px;
        color: #000000;
        text-transform: capitalize;
      }
    }

    &.sub-menu {
      .theme--light.v-list-item {
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 19px !important;
        color: #535353 !important;
      }
    }
  }
}
</style>
