<template>
  <v-col class="order-summary-section">
    <hr class="short mx-auto"/>
    <CustomText class="summary-title">{{ $t("cart.summary") }}</CustomText>
    <div style="margin-top: 18px">
      <div class="order-info">
        <CustomText>{{ $t("cart.subtotal") }}</CustomText>
        <CustomText v-if="cartTotalAmount > 0">$ {{ cartTotalAmount.toFixed(2) }}</CustomText>
      </div>
    </div>

    <div v-if="hasSampleKit">
      <div class="order-info">
        <CustomText>{{ $t("cart.sample_pack_kit") }}</CustomText>
        <CustomText v-if="cartTotalAmount > 0">$ {{ getSampleKit.price.toFixed(2) }}</CustomText>
      </div>
    </div>

    <DiscountCard/>
    <hr/>
    <GiftCardSummary />
    <div class="order-info mb-6">
      <CustomText>{{ $t("cart.estimated") }}</CustomText>
      <CustomText v-if="estimatedTotalAmount >= 0">
        $ {{ estimatedTotalAmount.toFixed(2) }}
      </CustomText>
    </div>
    <AppButton block large @click="onCheckout">
      {{ $t("buttons.checkout") }}
    </AppButton>
  </v-col>
</template>

<script>
import {IconPaypal} from "@/assets/svg-icons";
import {mapGetters} from "vuex";

export default {
  name: "OrderSummary",
  components: {
    IconPaypal,
    CustomText: () => import("@/components/shared/CustomText"),
    DiscountCard: () => import("@/components/cards/shared-card/DiscountCard"),
    GiftCardSummary: () => import("@/components/cards/shared-card/GiftCardSummary"),
  },
  props: {
    tax: {
      type: String,
    },
    ship: {
      type: String,
    },
  },
  methods: {
    onCheckout(e) {
      this.$emit("onCheckout", e);
    },
    onPaypal(e) {
      this.$emit("onPaypal", e);
    },
  },
  computed: {
    ...mapGetters({
      cartTotalAmount: "cart_total_amount",
      discountAvailable : "discountAvailable",
      estimatedDiscountAvailable: "estimatedDiscountAvailable",
      "estimatedDiscountAmount" : "estimatedDiscountAmount",
      discountAmount: "discountAmount",
      giftCardAvailable: "giftCardAvailable",
      appliedGiftCardsAmount: "appliedGiftCardsAmount",
      hasSampleKit : "hasSampleKit",
      getSampleKit : "getSampleKit",
    }),
    estimatedTotalAmount() {
      let discountAmount = this.discountAvailable ? this.discountAmount : 0;
      if(this.estimatedDiscountAvailable) discountAmount = this.estimatedDiscountAmount;
      const giftCardAmount = this.giftCardAvailable ? this.appliedGiftCardsAmount : 0;
      const samplePackKitAmount = this.hasSampleKit ? 10 : 0;
      const total = this.cartTotalAmount + samplePackKitAmount - discountAmount - giftCardAmount;
      return total > 0 ? total : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-title {
  font-size: 20px;
  font-weight: bold;
}

.short {
  padding: 0;
  margin-top: 0;
  margin-bottom: 15px;
  height: 5px;
  width: 100px !important;
  background-color: #d2d2d2;
  border-radius: 100px;
  @media (--t) {
    display: none;
  }
}

.cad {
  font-family: Sk-Modernist;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: right;
  letter-spacing: 0.02em;
  color: #000000;
  margin-right: 10px;
}

hr {
  height: 1px;
  background-color: #9f9f9f;
  margin: 12px 0;
}

.order-summary-section {
  position: relative;

  &:before,
  &:after {
    top: 0;
    bottom: 0;
    content: "";
    background-color: #e8e8e8;
    width: 100%;
    position: absolute;
  }

  &:before {
    right: -100%;
  }

  &:after {
    left: -100%;
  }

  background-color: #e8e8e8;
  max-height: 497px;
  max-width: 100%;
  padding: 12px 30px 30px;
  @media (--t) {
    &:before,
    &:after {
      content: unset;
    }
    margin-top: 0;
    padding: 30px;
    max-width: 400px;
  }

  .order-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .checkout-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border: 0;
    width: 100%;
    background: #000000;
    font-family: Sk-Modernist;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ffffff;
    margin-top: 20px;
  }
}
</style>
