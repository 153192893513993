<template>
  <div v-if="order && isLoad" class="order-detail">
    <v-container class="order-detail-container">
      <slot name="prepend"></slot>
      <v-row class="grid-container">
        <v-col class="pb-0 pt-0 pr-lg-8" lg="7" md="6" cols="12">
          <slot name="header"></slot>
          <hr class="mt-0" v-if="!$slots.header">
          <div class="order-contact">
            <h3>{{ $t('purchase.customer') }}</h3>
            <v-row justify="space-between" class="mt-0">
              <v-col class="contact-left " md="6" cols="12">
                <h4>{{ $t('purchase.contact') }}</h4>
                <span>{{ order.contact_person }}</span>
              </v-col>
                <v-col v-if="" class="contact-right " md="6" cols="12">
                  <h4>{{ $t('purchase.payment') }}</h4>
                  <v-row class="my-0 py-0">
                    <v-col v-if="giftCardAvailable" class="my-0 py-0 d-flex justify-space-between align-center" cols="12">
                      <span class="medium">{{ $t("cart.gift") }}</span>
                      <span class="medium">${{ appliedGiftCardsAmount.toFixed(2) }}</span>
                    </v-col>
                    <v-col v-if="!onlyGiftCardPayment" class="my-0 py-0 d-flex justify-space-between align-center" cols="12">
                      <span>{{ $t('ending_with') }} {{ _card_last_digit }}</span>
                      <span>${{ cartPayableTotalAmount.toFixed(2) }}</span>
                    </v-col>
                    <v-col v-if="onlyGiftCardPayment" class="my-0 py-0 d-flex justify-space-between align-center" cols="12">
                      {{ $t('cart.gift') }}
                    </v-col>
                  </v-row>
                </v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col class="contact-left" md="6" cols="12">
                <h4>{{ $t('purchase.address') }}</h4>
                <div v-if="shipmentAddressAvailable">
                  <span>{{
                      shippingAddress.first_name || ''
                    }} {{ shippingAddress.last_name }}</span>
                  <span>{{ shippingAddress.street1 }}</span>
                  <span>{{ shippingAddress.country }} / {{
                      shippingAddress.state
                    }}</span>
                  <span>
                  <span v-if="shippingAddress.phone_ext" class="d-inline">{{ shippingAddress.phone_ext }} </span>
                  {{ shippingAddress.phone }}
                </span>
                </div>
                <span v-else>None</span>
              </v-col>
              <v-col class="contact-right" md="6" cols="12">
                <h4>{{ $t('purchase.bill') }}</h4>
                <span>{{ order.billing_address.first_name || '' }} {{ order.billing_address.last_name }}</span>
                <span>{{ order.billing_address.street1 }}</span>
                <span>{{ order.billing_address.country }} /{{ order.billing_address.state }} </span>
                <span>
                  <span v-if="order.billing_address.phone_ext" class="d-inline">{{
                      order.billing_address.phone_ext
                    }}</span>
                  {{ order.billing_address.phone }}
                </span>
              </v-col>
            </v-row>
            <div class="mt-3">
              <h4>{{ $t('purchase.ship') }}</h4>
              <span>{{ shipmentMethod }}</span>
              <hr />
            </div>
          </div>

        </v-col>

        <v-col class="pl-lg-8 py-0" style="max-width: 400px;">
          <div class="order-summary-section fill-height">
            <div class="order-list">

              <PurchaseShopItem
                  v-for="(item, index) in get_cart_without_sample_kit"
                  :key="index"
                  :product="item.product_type"
                  :layout="item.layout"
                  :width="item.width"
                  :height="item.height"
                  :gift_card_value="item.name"
                  :quantity="item.quantity"
                  :src="item.thumbnail_base64"
                  :total="item.quantity * item.price"
              />
            </div>
            <div class="order-footer">
              <div>
                <v-row class="my-0 py-0">
                  <v-col class="order-title-section my-0 py-0">
                    <span class="medium">{{ $t("cart.subtotal") }}</span>
                    <span v-if="hasSampleKit" class="medium">{{ $t('cart.sample_pack_kit') }}</span>
                  </v-col>

                  <v-col class="order-value-section my-0 py-0">
                    <span class="medium">$ {{ cartTotalAmount.toFixed(2) }}</span>
                    <span v-if="hasSampleKit" class="medium">$ {{ getSampleKit.price.toFixed(2) }}</span>
                  </v-col>
                </v-row>

                <template v-if="discountAvailable">
                  <v-row class="my-0 py-0">
                    <v-col class="order-title-section my-0 py-0">
                      <span class="medium">{{ $t("cart.discount") }}</span>
                    </v-col>
                    <v-col class="order-value-section my-0 py-0">
                      <span class="medium error--text">- $ {{ discountAmount.toFixed(2) }}</span>
                    </v-col>
                  </v-row>
                </template>

                <v-row class="my-0 py-0">
                  <v-col class="order-title-section my-0 py-0">
                    <span class="medium" v-if="shippingPrice">{{ $t("cart.shipment") }}</span>
                    <span v-for="(taxItem, index) in _taxes" :key="taxItem.id" class="medium"> {{ `${taxItem.rate_name} (${taxItem.rate_value}%)` }}</span>
                  </v-col>

                  <v-col class="order-value-section my-0 py-0">
                    <span class="medium" v-if="shippingPrice">$ {{ shippingPrice.toFixed(2) }}</span>
                    <span v-for="(taxItem, index) in _taxes" :key="taxItem.id" class="medium"> {{ `$ ${taxItem.amount.toFixed(2)}` }}</span>
                  </v-col>
                </v-row>

                <hr class="total" />
                <v-row class="my-0 py-0">
                  <v-col class="order-title-section my-0 py-0">
                    <span class="medium">{{ $t("cart.total") }}</span>
                  </v-col>
                  <v-col class="order-value-section my-0 py-0">
                    <span class="medium">$ {{ totalAmount.toFixed(2) }}</span>
                  </v-col>
                </v-row>
                <hr class="total" />

                <template v-if="giftCardAvailable">
                  <v-row class="my-0 py-0">
                    <v-col class="order-title-section py-0">
                      <span class="medium">{{ $t("cart.gift") }}</span>
                    </v-col>
                    <v-col class="order-value-section py-0">
                      <span class="medium error--text">-$ {{ appliedGiftCardsAmount }}</span>
                    </v-col>
                  </v-row>

                  <v-row class="my-0">
                    <v-col class="order-title-section py-0">
                      <span class="medium">{{ $t("cart.grand_total") }}</span>
                    </v-col>
                    <v-col class="order-value-section py-0">
                      <span class="medium">$ {{ cartPayableTotalAmount.toFixed(2) }}</span>
                    </v-col>
                  </v-row>
                </template>
              </div>

            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="grid-container">
        <v-col class="py-0 pr-lg-8" lg="7" cols="12">
          <div class="d-flex flex-wrap justify-lg-space-between">
            <div class="order-contact">
              <span>{{ $t('purchase.need') }}</span>
              <span>{{ $t('purchase.support') }}
                <a class="font-weight-bold"
                   href="mailto:support@blankspace.ink?subject=I have questions."
                   style="color: black!important;">support@blankspace.ink
          </a></span>
            </div>
            <div class="mt-4 mt-md-0">
              <slot name="continue-btn">
                <AppButton
                    :to="{name: 'Configurator'}"
                    color="primary"
                    :large="!smAndDown"
                >
                  {{ $t('buttons.continue') }}
                </AppButton>
              </slot>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {cloneDeep, isArray, isObject} from "lodash";

export default {
  name: 'OrderPreview',
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      get_cart_without_sample_kit: [],
      cartTotalAmount: 0,
      totalAmount: 0,
      hasSampleKit: false,
      getSampleKit: null,
      discountAvailable: false,
      giftCardAvailable: false,
      discountAmount: 0,
      shippingPrice: 0,
      shipment_method: null,
      _applied_taxes: [],
      isLoad: false,
    }
  },
  components: {
    DiscountCard: () => import('@/components/cards/shared-card/DiscountCard'),
    OrderPaymentCard: () => import("@/components/cards/order-payment-card")
  },
  computed: {
    _taxes() {
      return this._applied_taxes?.filter((taxItem) => taxItem?.total_amount > 0);
    },
    _card_last_digit() {
      return (this.getProp(this.order, 'card_number.check_out.cc_number') || '').substr(-4)
    },
    shippingAddress() {
      return this.order.shipping_address || this.order.shipment_address || {}
    },
    shipmentMethod() {
      if (this._only_gift_card) {
        return "None"
      } else {
        const key = this.shipment_method === "pickup" ? "pickup" : "ground_shipping";
        return this.$t(key)
      }
    },
    cartPayableTotalAmount() {
      let giftCardAmount = this.giftCardAvailable ? this.appliedGiftCardsAmount : 0;
      if (giftCardAmount > this.totalAmount) giftCardAmount = this.totalAmount;
      return this.totalAmount - giftCardAmount
    },
    _only_gift_card() {
      return this.get_cart_without_sample_kit.every((item) => item.product_type === "gift_card")
    },
    onlyGiftCardPayment(){
      return this._card_last_digit?.length <= 0
    },
    shipmentAddressAvailable() {
      return !this._only_gift_card && this.shipment_method !== "pickup"
    }
  },
  methods: {
    prepareSummary() {
      const fieldList = [
        "get_cart_without_sample_kit",
        "cart_total_amount",
        "get_total_amount",
        "hasSampleKit",
        "getSampleKit",
        "discountAvailable",
        "giftCardAvailable",
        "discountAmount",
        "appliedGiftCardsAmount",
        "get_shipping_price",
        "_applied_taxes",
        "shipment_method"
      ];
      fieldList?.forEach(field => {
        const fieldValue = this.$store.getters[field]
        if (field === "get_shipping_price") field = "shippingPrice"
        else if (field === "cart_total_amount") field = "cartTotalAmount"
        else if (field === "get_total_amount") field = "totalAmount"
        if (isObject(fieldValue) || isArray(fieldValue)) {
          this[field] = cloneDeep(fieldValue) || this[field]
        } else {
          this[field] = fieldValue || this[field]
        }
      })

      setTimeout(() => this.isLoad = true, 100)
      this.$store.commit("CLEAR_CART_LIST");
    }
  },
  created() {
    this.prepareSummary();
  },
}
</script>

<style scoped lang="scss">
.order-detail {
  p {
    line-height: 150%;
    margin-bottom: 20px;
    letter-spacing: 0.02em;
    font-size: 14px;
    @media (--t) {
      font-size: 20px;
    }
  }

  hr {
    height: 1px;
    background: #E8E8E8;
    border: 0;
    margin: 20px 0;

    &:last-child {
      margin: 44px 0 0;
    }
  }

  h1, h2 {
    font-family: var(--font-family--alethia);
    line-height: 1;
    color: #000000;
    margin-bottom: 8px;
  }

  h1 {
    font-size: 30px;
    @media (--t) {
      font-size: 48px;
    }
  }

  h2 {
    font-size: 24px;
    @media (--t) {
      font-size: 36px;
    }
  }


  //overflow-y: hidden !important;
  //overflow-x: scroll !important;
  .order-detail-container {
    padding-top: 35px;
    padding-bottom: 35px;

    @media (--t) {
      max-width: 1260px;
      width: 100vw;
      padding-bottom: 112px;
    }

    .grid-container {
      display: flex;
      //justify-content: space-between;
      margin-top: 65px;

      .order-header {

        span {
          font-size: 12px;
          letter-spacing: 0.02em;
          @media (--t) {
            font-size: 18px;
          }

        }

      }

      .order-message {

        span {
          font-size: 18px;
          line-height: 105%;
        }


      }

      .order-contact {
        h3 {
          font-family: Sk-Modernist, 'san-serif';
          font-weight: bold;
          font-size: 16px;
          margin: 10px 0;
          letter-spacing: 0.02em;
          color: #000000;
          @media (--t) {
            font-size: 20px;
            margin: 50px 0 10px;
          }
        }

        h4 {
          font-family: Sk-Modernist, 'sans-serif';
          font-weight: bold;
          font-size: 14px;
          @media (--t) {
            font-size: 18px;
          }
          line-height: 150%;
          letter-spacing: 0.02em;
          color: #000000;
          margin-bottom: 4px;
        }

        span {
          display: block;
          line-height: 1.5;
          font-family: Sk-Modernist, 'sans-serif';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          @media (--t) {
            font-size: 18px;
          }
          letter-spacing: 0.02em;
          color: #393939;
        }
      }

      .order-summary-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #E8E8E8;
        padding: 30px;
        margin-top: 50px;
        @media (--t) {
          margin-top: unset;
        }

        .order-list {


        }


        .order-footer {

          .order-title-section {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
          }

          .order-value-section {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }

        }


      }


    }
  }
}
hr.total {
  margin: 12px 0;
  background-color: #9f9f9f;
}
</style>
