<template>
  <div class="story-section">
    <v-container class="pb-0 pb-md-3">
      <Heading :subtitle=" $t('story.subtitle')" :title="$t('story.title')"/>
      <StoryCardSwiper
        v-if="smAndDown"
        :items="story_data"
        class="mt-5"
      />
      <v-row v-else class="desktop story-items" cols="1" cols-lg="3" cols-md="2" g="4">
        <div
          class="col-12 col-md-6 col-lg-4"
          v-for="(item,index) in story_data"
          :key="index"
        >
          <StoryCard
            :item="item"
          />
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "WorthSection",
  data() {
    return {
      story_data: [],
    }
  },
  methods: {
    storyData() {
      if (localStorage.getItem('lang') === 'en') {
        this.story_data = this.$store.getters.get_story_en.slice(0, 3)
      } else if (localStorage.getItem('lang') === 'fr') {
        this.story_data = this.$store.getters.get_story_fr.slice(0, 3)
      }
    },
  },
  created() {
    this.storyData();
  },
}
</script>

<style lang="scss">

.story-section {
  > .container {
    @media (min-width: 1650px) {
      max-width: 1200px;
    }
  }
  background-color: #F9F9F9;
  padding-top: 38px !important;
  padding-bottom: 20px;
  @media (--t) {
    padding-bottom: 8px !important;
    padding-top: 48px !important;

  }

  .row.story-items {
    margin: 51px -25px 0;
    .col-12 {
      padding: 25px;
    }
  }

}

</style>
