import { render, staticRenderFns } from "./forgot-password.vue?vue&type=template&id=47fa4401&scoped=true&"
import script from "./forgot-password.vue?vue&type=script&lang=js&"
export * from "./forgot-password.vue?vue&type=script&lang=js&"
import style0 from "./forgot-password.vue?vue&type=style&index=0&id=47fa4401&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47fa4401",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
