<template>
  <div class="configurator-page-containers">
    <v-row class="configurator-layout ma-0 fill-height">
      <v-col class="configurator-preview" :class="{ layoutMode: openLayoutMode }" lg="7" md="6"
             style="position: relative">
        <v-btn v-if="openLayoutMode" class="close-btn" color="primary" depressed @click="openLayoutMode = false">
          <v-icon large class="close-button">mdi-arrow-left</v-icon>
        </v-btn>

        <transition name="slide" mode="out-in">
          <Alert v-if="qualityWarning"/>
        </transition>
        <div v-if="!image" class="cropper-placeholder mx-auto"
             @click="$refs.file.click()">
          <p class="frame-title">{{ $t("configurator.upload.upload") }}</p>
          <div style="border-top: 4px solid black; padding: 20px">
            <IconUpload/>
          </div>
          <input ref="file" accept="image/*" type="file" @change="uploadImage($event)"/>
        </div>
        <div class="image-engine-container" v-else>
          <image-engine
              v-if="image"
              :image="image"
              :customImageSize="customImageSize"
              :imageUrl="image.thumbnail.url"
              :originalImageHeight="image.original.height"
              :originalImageWidth="image.original.width"
              :snapshot="prepareSnapShot"
              :snapshotForAddToCart="prepareSnapShotForAddToCart"
              :thumbnailImageHeight="imageSize.height"
              :thumbnailImageWidth="imageSize.width"
              :layoutMode="openLayoutMode"
              :layout="order.layout"
              :selectedDimension="selectedDimension"
              :dimensionList="dimensionList"
              :customWidthList="customWidthList"
              :customHeightList="customHeightList"
              @snapshot-event="addToCart"
              @coords-event="coords = $event"
              @image-zoom-event="checkQuality"
              @ready-event="imageIsReady"
              @layoutModeEvent="openLayoutMode = $event"
          />
        </div>
      </v-col>
      <v-col class="configurator-editor pt-0" lg="5">
        <v-tabs v-model="tab" class="configurator-tabs" grow icons-and-text>
          <v-tab class="configurator-tab">
            <div class="tab-title-wrapper">
              <IconUploadMini class="float-left" height="39" width="29"/>
              <span class="configurator-tab_title" v-html="$t('configurator.tabs.image_upload')"></span>
            </div>
          </v-tab>
          <v-tab :disabled="!image" class="configurator-tab">
            <div class="tab-title-wrapper">
              <IconMaterial/>
              <span class="configurator-tab_title" v-html="$t('configurator.tabs.print_medium')"></span>
            </div>
          </v-tab>
          <v-tab :disabled="_is_layout_active" class="configurator-tab">
            <div class="tab-title-wrapper">
              <IconSizeMini height="31" width="31"/>
              <span class="configurator-tab_title" v-html="$t('configurator.tabs.size_layout')"></span>
            </div>
          </v-tab>
          <v-tab :disabled="!_is_edge_active" class="configurator-tab">
            <div class="tab-title-wrapper">
              <IconEdgeMini height="35" width="29"/>
              <span class="configurator-tab_title" v-html="$t('configurator.tabs.edge_frame')"></span>
            </div>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" active-class="" class="configurator-contents">
          <v-tab-item class="configurator-content">
            <p class="configurator-content-titles">
              {{ $t("configurator.upload.title") }}
            </p>
            <p class="configurator-content-texts">
              {{ $t("configurator.upload.description") }}
            </p>
            <span class="image-uploader-button" @click="$refs.file_input.click()">
              <input ref="file_input" accept="image/*" type="file" @change="uploadImage($event)"/>
              {{ $t("configurator.upload.upload") }}
              <a class="image-uploader-svg"><IconArrowWhite/></a>
            </span>
            <TipCard/>
            <!-- USER IMAGE LIBRARY -->
            <UserImageLibrary v-if="imageReady || image === null" :refreshData="imageLibraryUpdated"
                              @user-image-select-event="prepareOrUploadImage"/>
            <!-- USER IMAGE LIBRARY -->
          </v-tab-item>
          <v-tab-item class="configurator-content">
            <p class="configurator-content-titles">
              {{ $t("configurator.material.title") }}
            </p>
            <v-row>
              <v-col v-for="(material, index) in materialData" :key="index" class="d-flex child-flex flex-column"
                     cols="4" xl="3">
                <ProductOption :option="material" @click="SET_MATERIAL_VALUE(material)"
                               :img-props="{ 'aspect-ratio': '1.8' }" :value="material.id === selected_material"/>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="configurator-content">
            <p class="configurator-content-titles">
              {{ $t("configurator.resize.title") }}
            </p>
            <p class="configurator-content-texts">
              {{ $t("configurator.resize.description") }}
            </p>
            <div class="d-flex flex-wrap flex-row justify-space-between" style="max-width: 564px">
              <SizeSelect :customSize="customSize" :dimensions="dimensionList" @customSize="customSizeSelected"
                          @onSelect="checkDimension"/>
            </div>
            <div v-if="customSize">
              <div class="size-selection my-5">
                <AppSelect dense color="primary" :label="$t('buttons.select-width')" tile :items="customWidthList"
                           item-value="value.inch" hide-details v-model="customWidth" @input="customWidthChanged"
                           class="custom-size-selector" return-object/>
                <IconX/>
                <AppSelect
                    color="primary"
                    :label="$t('buttons.select-height')"
                    tile
                    :items="customHeightList"
                    item-value="value.inch"
                    hide-details
                    dense
                    v-model="customHeight"
                    @input="customHeightChanged"
                    class="custom-size-selector"
                    return-object
                />
              </div>
            </div>

            <p class="configurator-content-titles mt-5">
              {{ $t("configurator.layout") }}
            </p>

            <!-- LAYOUT -->
            <div class="configurator-layout-selection d-flex flex-row justify-space-between pr-2"
                 style="max-width: 564px">
              <label class="layout-type" @click="SET_LAYOUT('1 Panel')">
                <IconGridDefault :class="order.layout === '1 Panel' ? 'svg-active' : ''"/>
              </label>
              <v-tooltip bottom color="#e03d3e" :disabled="multiPanelAvailable">
                <template v-slot:activator="{ on }">
                  <label class="layout-type" @click="SET_LAYOUT('3 Vertical Panels')"
                         :class="{ disabled: !multiPanelAvailable }" v-on="on">
                    <IconGridVertical :class="order.layout === '3 Vertical Panels' ? 'svg-active' : ''"/>
                  </label>
                </template>
                <span>{{ $t("notAvailable") }}</span>
              </v-tooltip>
              <v-tooltip bottom color="#e03d3e" :disabled="multiPanelAvailable">
                <template v-slot:activator="{ on }">
                  <label class="layout-type" @click="SET_LAYOUT('3 Horizontal Panels')"
                         :class="{ disabled: !multiPanelAvailable }" v-on="on">
                    <IconGridHorizontal :class="order.layout === '3 Horizontal Panels' ? 'svg-active' : ''"/>
                  </label>
                </template>
                <span>{{ $t("notAvailable") }}</span>
              </v-tooltip>
              <v-tooltip bottom color="#e03d3e" :disabled="multiPanelAvailable">
                <template v-slot:activator="{ on }">
                  <label class="layout-type" @click="SET_LAYOUT('4 Wavy Panels')"
                         :class="{ disabled: !multiPanelAvailable }" v-on="on">
                    <IconGridWave :class="order.layout === '4 Wavy Panels' ? 'svg-active' : ''"/>
                  </label>
                </template>
                <span>{{ $t("notAvailable") }}</span>
              </v-tooltip>
            </div>
            <!-- LAYOUT -->
          </v-tab-item>
          <v-tab-item class="configurator-content">
            <div v-if="selectedMaterial">
              <template v-for="addon in addons">
                <template v-if="selectedMaterial.addons.includes(addon.id)">
                  <div :key="addon.id">
                    <p class="configurator-content-titles">
                      {{ addon.title }}
                    </p>
                    <v-row class="mb-4">
                      <v-col v-for="(item, index) in addon.items" :key="index" class="d-flex child-flex flex-column"
                             cols="4" xl="3">
                        <ProductOption :option="item" :value="item.title === order[addon.value]"
                                       @click="callAction(addon.action, item)" item-text="title"/>
                      </v-col>
                    </v-row>
                  </div>
                </template>
              </template>
            </div>
          </v-tab-item>
        </v-tabs-items>

        <CostCard
            :disabled="!showAddToCartBtn"
            :nextBtnStatus="nextBtnStatus"
            :edge="order.product_edge"
            :frame="order.product_frame"
            :border="order.product_border"
            :hardware="order.product_hanging_hardware"
            :height="order.selected_size_height"
            :layout="order.layout"
            :material="_material_name"
            :total="order.total_amount"
            :width="order.selected_size_width"
            @onClick="prepareCart"
            @next-action="nextAction"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {configuratorMixins} from "@/mixins/configuratorMixins";
import imageLoading from "@/components/imageEngine/imageLoading.vue";
import {mapGetters} from "vuex";

export default {
  name: "ConfiguratorDesktop",
  mixins: [configuratorMixins],
  components: {
    imageLoading,
    SizeSelect: () => import("@/components/buttons/size-select"),
    CostCard: () => import("@/components/cards/unique-card/cost-card"),
  },
};
</script>

<style lang="scss">
.configurator-page-containers {
  height: calc(100vh - 100px);
  overflow: hidden;
  display: none;
  @media (--t) {
    display: block;
  }

  .style-chooser .vs__dropdown-menu {
    position: absolute !important;
    top: -250px !important;
  }

  .vs__selected {
    color: #000000;
    font-size: 20px !important;
    margin-left: 10px;
    margin-top: 41px;
  }

  .vs__dropdown-toggle {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 18px !important;
    height: 40px !important;
    background-color: #ffffff !important;
    border-radius: 0 !important;
    border: 1px solid #009771;
    color: #000000 !important;
    cursor: pointer;
    font-family: var(--font-family--alethia) !important;
    width: 100px;
  }

  .v-tabs-slider-wrapper {
    width: 100px !important;
    margin-left: 50px !important;
  }

  .v-tabs--icons-and-text > .v-tabs-bar {
    height: 120px !important;
  }

  .v-tab--active {
    background-color: #ffffff !important;
  }

  .configurator-layout {
    .configurator-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      overflow: hidden;
      position: relative;
      /* background-color: #f1efec; */
      background-color: #f1f2f3;

      .cropper-placeholder {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
        height: 348px;
        outline: 3px solid black;
        cursor: pointer;
        @media (--t) {
          width: 302px;
        }

        p {
          font-size: 30px;
        }
      }
    }

    .configurator-editor {
      height: 100%;
      overflow: hidden;
      padding-left: 0 !important;

      .configurator-tabs {
        border-radius: 0 !important;

        .configurator-tab {
          display: flex;
          flex-direction: column;
          background-color: #f1efec;
          color: #000000 !important;

          .tab-title-wrapper {
            display: flex;
            flex-direction: column;
            gap: 5px;
          }

        }

        .configurator-tab_title {
          font-family: "Sk-Modernist", sans-serif !important;
          font-size: 17px !important;
          text-align: left !important;
          font-weight: normal !important;
          line-height: 21px !important;
          text-transform: lowercase !important;
        }
      }

      .configurator-contents {
        border-radius: 0 !important;
        overflow: hidden !important;

        .configurator-content {
          height: calc(100vh - (100px + 120px + 180px + 2px)) !important;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            display: none;
          }

          overflow-x: auto !important;
          padding: 20px 50px 40px 50px !important;

          .configurator-content-titles {
            font-size: 35px !important;
            color: #000000 !important;
            font-family: var(--font-family--alethia) !important;
            font-weight: normal;
          }

          .image-uploader-button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            background-color: #ffffff;
            border: 1px solid #009771;
            font-family: "Sk-Modernist", sans-serif;
            padding-left: 10px;
            padding-right: 5px;
            height: 40px;
            width: 360px;
            margin-bottom: 20px;
            cursor: pointer;
            letter-spacing: 0.17em;

            input {
              display: none;
            }

            .image-uploader-svg {
              display: flex;
              align-items: flex-end;
              justify-content: center;
              height: 30px;
              width: 30px;
              background-color: #009771;
            }
          }

          .size-selection {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;

            .app-select {
              max-width: 125px;
            }

            svg {
              margin-left: 20px;
              margin-right: 20px;
            }
          }

          .configurator-layout-selection {
            display: flex;
            flex-direction: row;
            margin-top: 20px;

            label {
              svg {
                fill: black;
              }

              .svg-active {
                fill: #009071;
                transition-duration: 700ms;
              }

              input {
                display: none;
              }
            }
          }
        }
      }
    }

    .layoutMode {
      background-color: rgba(0, 0, 0, 0.5) !important;
    }

    .close-btn {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 9999;
    }
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: .5s;
  opacity: 1;

}

.slide-enter {
  transform: translate(10%, 0);
  opacity: 0;

}

.slide-leave-to {
  transform: translateX(10%);
  opacity: 0;
}
</style>
