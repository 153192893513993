<template>
  <div class="container justify-center">
    <img v-if="english" alt="" class="img-fluid" src="@/assets/images/table.png"/>
    <img v-if="french" alt="" class="img-fluid" src="@/assets/images/table-french.png"/>
  </div>
</template>

<script>
export default {
  name: 'FaqImageQuality',
  props: {
    english: {
      type: Boolean,
      default: () => false
    },
    french: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style scoped>

</style>
