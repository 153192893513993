<template>
  <v-card v-bind="$attrs" flat color="transparent" tile>
    <v-responsive class="mb-3" :aspect-ratio="(300/200)" >
      <img :src="src" alt="">
    </v-responsive>
    <v-card-text class="pl-0">
      <h4 class="card-title ">{{ frame }}</h4>
      <p class="card-text">{{ about }}</p>
    </v-card-text>
  </v-card>

</template>

<script>
export default {
  name: "FrameCard",
  props: {
    src: {
      type: String,
      default: '/img/placeholders/edge.png'
    },
    frame: {
      type: String
    },
    about: {
      type: String,
    }
  }
}
</script>

<style scoped lang="scss">


.v-card {
  img {
    object-fit: cover;
    height: 100%;
  }
  .card-title {
    font-family: Sk-Modernist,'sans-serif' !important;
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 16px;
    letter-spacing: 0.02em;
    color: #000000;
    text-transform: uppercase !important;
  }

  .card-text {
    font-family: Sk-Modernist,'sans-serif' !important;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #393939;
    margin-top: -7px;
  }
}


</style>
