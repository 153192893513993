<template>
  <div class="edge-frame-section" :class="{'bg-grey-1': hasBg}">
    <IconLogo class="background-logo" v-if="hasBg"/>
    <v-container>
      <Heading :subtitle="subTitle" class="mb-7 mb-md-15" :title="title" style="position: relative"/>
      <div class="row">
        <div class="col-12">
          <AppSwiper :items="items" v-if="smAndDown" v-bind="options">
            <template #item="item">
              <FrameCard
                width="300"
                :about="item.about"
                :frame="item.name"
                :src="item.path"
              />
            </template>
          </AppSwiper>
          <div
            v-else
            class="d-flex flex-wrap justify-center"
            :style="{
              margin: `0 -${spaceBetween / 2 }px`
            }"
          >
            <div
              :style="{padding: `0 ${spaceBetween / 2 }px`}"
              v-for="item in items"
            >
              <FrameCard
                width="300"
                :about="item.about"
                :frame="item.name"
                :src="item.path"
              />
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import FrameCard from '@/components/cards/shared-card/frame-card'
import IconLogo from '@/assets/svg-icons/logo/logo-6.svg'
import {merge} from 'lodash'
import {swiperMixins} from '@/mixins/swiperMixins'

export default {
  name: 'EdgeFrameSection',
  mixins: [swiperMixins],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: () => null
    },
    subTitle: {
      type: String,
      default: () => null
    },
    swiperOptions: {
      type: Object,
      default: () => ({})
    },
    spaceBetween: {
      type: Number,
      default: () => 50
    },
    hasBg: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    defaultSwiperOptions() {
      return {
        loop: this.smAndDown,
        autoplay: this.smAndDown,
        ...this.smAndDown  && {pagination: {
          el: this.$refs.pagination,
          //dynamicBullets: true, //this.items.length > 10
        }},
        pagination: false,
        spaceBetween: 16,
        slidesPerView: 'auto',
        centeredSlides: this.smAndDown,
      }
    },
    options() {
      return merge(this.defaultSwiperOptions, this.swiperOptions)
    }
  },

  components: {
    FrameCard,
    IconLogo
  }
}
</script>

<style lang="scss">
.edge-frame-section {
  .container {
    @media (min-width: 1400px ) {
      max-width: 1395px;
    }
  }
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  @media (--t) {
    padding: 60px 0;
  }

  h2 {
    letter-spacing: 0.15em;
    margin-top: -4px;
  }

  .slide-contain {
    margin-top: 10px;
    @media (--t) {
      margin-top: 10px;
    }
  }

  .background-logo {
    display: none;
    @media (--t) {
      position: absolute;
      z-index: 0;
      right: 12%;
      margin-top: -56px;
      display: block;
    }
  }

  .swiper-container {
    display: flex;
    justify-content: center;
    .swiper-wrapper {
      margin: 0 auto;
      width: unset;
      max-width: 100%;
    }
    .swiper-slide {
      box-sizing: border-box;
      z-index: 0;
      width: 300px!important;
    }
    .swiper-pagination {
      bottom: 0;
    }

    position: relative;
  }
}
</style>
