<template>
  <div class="d-flex justify-center mb-4">
    <v-rating
      v-model="innerValue"
      background-color="warning"
      color="warning"
      hover
      class="app-rating"
      length="5"
      large
    />
  </div>
</template>

<script>
import { formInputMixins } from '@/mixins/formInputMixins'

export default {
  name: 'AppRatingInput',
  mixins: [formInputMixins],
  props: {
    value: {
      type: Number,
      default: () => 0
    }
  }
}
</script>

<style scoped lang="scss">
.app-rating.v-rating {
  width: 80%;
  display: flex;
  justify-content: space-between;
}
</style>
