<template>
  <v-container class="terms-page-container">
    <Heading :title="$t('terms.title')" title-tag="h1"/>
    <div class="row justify-center">
      <div class="col-12 col-lg-10">
        <h3 class="layout-title ">1.0 {{ $t('terms.definition.title') }}</h3>
        <p class="medium-gray">1.1 {{ $t('terms.definition.1') }}</p>
        <p class="medium-gray">1.2 {{ $t('terms.definition.2') }}</p>
        <p class="medium-gray">1.3 {{ $t('terms.definition.3') }}</p>
        <p class="medium-gray">1.4 {{ $t('terms.definition.4') }}</p>
        <p class="medium-gray">1.5 {{ $t('terms.definition.5') }}</p>
        <p class="medium-gray">1.6 {{ $t('terms.definition.6') }}</p>
        <p class="medium-gray">1.7 {{ $t('terms.definition.7') }}</p>
        <p class="medium-gray">1.8 {{ $t('terms.definition.8') }}</p>
        <p class="medium-gray">1.9 {{ $t('terms.definition.9') }}</p>
        <p class="medium-gray">1.10 {{ $t('terms.definition.10') }}</p>
        <p class="medium-gray">1.11 {{ $t('terms.definition.11') }}</p>
        <h3 class="layout-title ">2.0 {{ $t('terms.provisions.title') }}</h3>
        <p class="medium-gray">2.1 {{ $t('terms.provisions.1') }}</p>
        <p class="medium-gray">2.2 {{ $t('terms.provisions.2') }}</p>
        <p class="medium-gray">2.3 {{ $t('terms.provisions.3') }}</p>
        <p class="medium-gray">2.4 {{ $t('terms.provisions.4') }}</p>
        <p class="medium-gray">2.5 {{ $t('terms.provisions.5') }}</p>
        <h3 class="layout-title ">3.0 {{ $t('terms.compliance.title') }}</h3>
        <p class="medium-gray">3.1 {{ $t('terms.compliance.1') }}</p>
        <p class="medium-gray">3.2 {{ $t('terms.compliance.2') }}</p>
        <h3 class="layout-title ">4.0 {{ $t('terms.compliance.title') }}</h3>
        <p class="medium-gray">4.1 {{ $t('terms.compliance.1') }}</p>
        <p class="medium-gray">4.2 {{ $t('terms.compliance.2') }}</p>

        <h3 class="layout-title ">4.0 {{ $t('terms.minimum.title') }}</h3>
        <p class="medium-gray">4.1 {{ $t('terms.minimum.1') }}</p>

        <h3 class="layout-title ">5.0 {{ $t('terms.modification.title') }}</h3>
        <p class="medium-gray">5.1 {{ $t('terms.modification.1') }}</p>
        <p class="medium-gray">5.2 {{ $t('terms.modification.2') }}</p>

        <h3 class="layout-title ">6.0 {{ $t('terms.eligibility.title') }}</h3>
        <p class="medium-gray">6.1 {{ $t('terms.eligibility.1') }}</p>

        <h4 class="layout-title ">7.0 {{ $t('terms.service.title') }}</h4>
        <p class="medium-gray">7.1 {{ $t('terms.service.1') }}</p>

        <h4 class="frame-title text-left mt-5">{{ $t('terms.service.account') }}</h4>
        <p class="medium-gray">7.1 {{ $t('terms.service.2') }}</p>

        <h4 class="frame-title text-left">{{ $t('terms.service.purchase') }}</h4>
        <p class="medium-gray">7.2 {{ $t('terms.service.3') }}</p>

        <h4 class="frame-title text-left">{{ $t('terms.service.commercial') }}</h4>
        <p class="medium-gray">7.3 {{ $t('terms.service.4') }}</p>

        <h4 class="frame-title text-left">{{ $t('terms.service.delivery') }}</h4>
        <p class="medium-gray">7.4 {{ $t('terms.service.5') }}</p>
        <p class="medium-gray">7.5 {{ $t('terms.service.6') }}</p>
        <p class="medium-gray">7.6 {{ $t('terms.service.7') }}</p>
        <p class="medium-gray">7.7 {{ $t('terms.service.8') }}</p>

        <h4 class="frame-title text-left">{{ $t('terms.service.return') }}</h4>
        <p class="medium-gray">7.8 {{ $t('terms.service.9') }}</p>
        <p class="medium-gray">7.9 {{ $t('terms.service.10') }}</p>

        <h4 class="frame-title text-left">{{ $t('terms.service.payment') }}</h4>
        <p class="medium-gray">7.10 {{ $t('terms.service.11') }}</p>
        <p class="medium-gray">7.11 {{ $t('terms.service.12') }}</p>

        <h4 class="frame-title text-left">{{ $t('terms.service.damaged') }}</h4>
        <p class="medium-gray">7.12 {{ $t('terms.service.13') }}</p>
        <p class="medium-gray">7.13 {{ $t('terms.service.14') }}</p>

        <h4 class="frame-title text-left">{{ $t('terms.service.contact') }}</h4>
        <p class="medium-gray">7.14 {{ $t('terms.service.15') }}</p>


        <h3 class="layout-title text-left">8.0 {{ $t('terms.licence.title') }}</h3>
        <p class="medium-gray">8.1 {{ $t('terms.licence.1') }}</p>
        <p class="medium-gray">8.2 {{ $t('terms.licence.2') }}</p>
        <p class="medium-gray">8.3 {{ $t('terms.licence.3') }}</p>
        <p class="medium-gray">8.4 {{ $t('terms.licence.4') }}</p>

        <h3 class="layout-title text-left">9.0 {{ $t('terms.rule.title') }}</h3>
        <p class="medium-gray">(i) {{ $t('terms.rule.1') }}</p>
        <p class="medium-gray">(ii) {{ $t('terms.rule.2') }}</p>
        <p class="medium-gray">(ii) {{ $t('terms.rule.3') }}</p>
        <p class="medium-gray">(iii) {{ $t('terms.rule.4') }}</p>
        <p class="medium-gray">(iv) {{ $t('terms.rule.5') }}</p>
        <p class="medium-gray">(v) {{ $t('terms.rule.6') }}</p>
        <p class="medium-gray">(vi) {{ $t('terms.rule.7') }}</p>
        <p class="medium-gray">(vii) {{ $t('terms.rule.8') }}</p>
        <p class="medium-gray">(ix) {{ $t('terms.rule.9') }}</p>
        <p class="medium-gray">(x) {{ $t('terms.rule.10') }}</p>
        <p class="medium-gray">(xi) {{ $t('terms.rule.11') }}</p>
        <p class="medium-gray">(xii) {{ $t('terms.rule.12') }}</p>
        <p class="medium-gray">(xiii) {{ $t('terms.rule.13') }}</p>
        <h3 class="layout-title text-left">10.0 {{ $t('terms.content.title') }}</h3>
        <p class="medium-gray">10.1 {{ $t('terms.content.1') }}</p>
        <h3 class="layout-title text-left">11.0 {{ $t('terms.privacy.title') }}</h3>
        <p class="medium-gray">11.1 {{ $t('terms.privacy.1') }}</p>

        <h3 class="layout-title text-left">12.0 {{ $t('terms.indemnity.title') }}</h3>
        <p class="medium-gray">12.1 {{ $t('terms.indemnity.1') }}</p>
        <p class="medium-gray">12.2 {{ $t('terms.indemnity.2') }}</p>

        <h3 class="layout-title text-left">13.0 {{ $t('terms.disclaimer.title') }}</h3>
        <p class="medium-gray">13.1 {{ $t('terms.disclaimer.1') }}</p>
        <p class="medium-gray">14.2 {{ $t('terms.disclaimer.2') }}</p>

        <h3 class="layout-title text-left">14.0 {{ $t('terms.laws.title') }}</h3>
        <p class="medium-gray">14.1 {{ $t('terms.laws.1') }}</p>

        <h3 class="layout-title text-left">15.0 {{ $t('terms.final.title') }}</h3>
        <p class="medium-gray">15.1 {{ $t('terms.final.1') }}</p>

        <h3 class="layout-title text-left">16.0 {{ $t('terms.contact.title') }}</h3>
        <p class="medium-gray">16.1 {{ $t('terms.contact.1') }}</p>
      </div>
    </div>


  </v-container>
</template>

<script>
export default {
  name: "Terms",
  metaInfo() {
    return {
      title: this.$t('titles.terms'),
      titleTemplate: '%s - Blankspace',
       htmlAttrs: {
        lang: this.$i18n.locale,
      },
    }
  },
}
</script>

<style lang="postcss" scoped>
.terms-page-container {
  padding-top: 80px;
  padding-bottom: 80px;

  p {
    text-align: justify;
    margin-top: 20px;
  }

  h3 {
    margin-top: 30px;
  }
}
</style>
