<template>
  <v-card
    class="inspire-slide-card"
    tile
    flat
    ref="inspireCard"
    :max-width="$vuetify.breakpoint.lgAndUp ? 1200 : ''"
  >
    <div class="head">
      <h3>{{ $t('about.inspire.title') }}</h3>
      <p>{{ $t('about.inspire.subtitle').toUpperCase() }}</p>
    </div>

    <div class="py-4" style="position: relative;">
      <AppSwiper v-if="list.length > 0" :items="list" v-bind="options" ref="swiper">
        <template v-slot:item="inspire">
          <div class="d-flex flex-wrap fill-height justify-center">
            <InspiredCustomerCard
              :comment_one="inspire.comment_one"
              :comment_two="inspire.comment_two"
              :name="inspire.name"
              :social="inspire.social"
              :src="inspireImage(inspire.path)"
              :title="inspire.title"
            />
          </div>
        </template>
      </AppSwiper>
      <template
        v-for="direction in ['next', 'prev']"
        v-if="!smAndDown"
      >
        <v-btn
          depressed
          @click="$refs.swiper.swiper[`slide${direction.capitalize()}`]()"
          icon
          absolute
          color="primary"
          ref="navBtn"
          :key="`${direction}Btn`"
          :class="`navigation-${direction} nav-btn`"
          height="60"
          width="60"

        >
          <v-icon size="50">mdi-chevron-{{ direction === 'next' ? 'right' : 'left' }}</v-icon>
        </v-btn>
      </template>
    </div>
  </v-card>
</template>

<script>
import InspiredCustomerCard from '@/components/cards/shared-card/ispired-card'
export default {
  name: 'InspiringStories',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    inspireImage(path) {
      return require('@/' + path)
    },
  },
  computed: {
    options() {
      return {
        loop: true,
        slidesPerView: 1,
        autoHeight: !this.smAndDown,
        autoplay: true,
      }
    }
  },
  components: {
    InspiredCustomerCard
  }
}
</script>

<style scoped>

</style>
