<template>
  <div class="loader">
<!--    <h2 data-text="Blankspace">Blankspace</h2>-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Loader",
};
</script>

<style lang="postcss">
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  z-index: 999;
  width: 100%;
  height: 150vh;
  opacity: 0.3;
  overflow: hidden !important;

  h2 {
    font-family: var(--font-family--alethia);
    position: relative;
    font-size: 10vw;
    margin-bottom: 50vh;
    color: #ffffff;
    -webkit-text-stroke: 0.18vw #000000;

    &:before {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      color: #000000;
      -webkit-text-stroke: 0 #000000;
      border-right: 2px solid #000000;
      overflow: hidden;
      transition: width .5s linear;
      animation: animate 6s linear infinite;
    }

    @keyframes animate {
      0%,
      10%,
      100% {
        width: 0;
      }
      70%,
      90% {
        width: 100%;
      }
    }
  }
}
</style>
