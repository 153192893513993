<template>
  <div class="container">
    <div>
      <span class="soft-text">{{ $t("faq.products.print-text.one") }}</span>
      <br/><br/>
      <span class="soft-text">{{ $t("faq.products.print-text.two") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqProductsPrint'
}
</script>

<style scoped>

</style>
