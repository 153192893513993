<template>
  <div class="profile pb-5" style="min-height: 100vh">
    <v-container v-if="currentUserData">
      <h2 class="page-title text-left ml-3">{{ routeTitle }}</h2>

      <v-row class="mt-5">

        <v-col class="desktop-profile-menu menu border-right" lg="3">
          <v-list>
            <template v-for="item in navigationMenu">
              <v-list-item v-on="item.on" :to="item.to" class="py-2">{{ item.title }}</v-list-item>
            </template>
          </v-list>
        </v-col>

        <v-container class="mobile-profile-menu">
          <v-col cols="12">
            <AppSelect
              outlined
              :items="navigationMenu"
              v-model="activeRoute"
              :menu-props="{maxHeight: 'unset'}"
              append-icon="mdi-chevron-down"
              item-value="name"
              item-text="title"
              hide-details
              return-object
              v-if="$vuetify.breakpoint.mdAndDown"
              class="mb-6"
            >
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item
                  :to="item.to"
                  v-on="item.on"
                  @click.native="item.on ? item.on.click : $router.push(item.to)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item.title"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{item}">
                <span>{{ item.title }}</span>
              </template>

            </AppSelect>
          </v-col>
        </v-container>

        <v-col lg="8" cols="12">
          <router-view/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Profile',
  metaInfo() {
    return {
      title: this.$t('titles.account'),
      titleTemplate: '%s - Blankspace',
      htmlAttrs: {
        lang: 'en',
      }
    }
  },
  data() {
    return {
      english: true,
      french: false
    }
  },
  methods: {
    attemptLogout() {
      this.$store.commit('logoutUser')
      this.$store.commit("CLEAR_CART_LIST");
      setTimeout(() => {
        this.$router.push({name: 'Home'})
      }, 300)
    }
  },
  computed: {
    ...mapGetters(['currentUserData']),

    routes() {
      return [
/*        {
          name: 'Dashboard',
          title: this.$t('orders.route.dashboard')
        },*/
        {
          name: 'Orders',
          title: this.$t('orders.route.order')
        },
        {
          name: 'Change Password',
          title: this.$t('orders.route.change')
        },
        {
          name: 'Address',
          title: this.$t('orders.route.address')
        },
        {
          name: 'Billing Address',
          title: this.$t('orders.route.add')
        },
        {
          name: 'Account Details',
          title: this.$t('orders.route.detail')
        },
        {
          name: 'Update Address',
          title: this.$t('orders.route.edit')
        },
        {
          name: 'UpdatePassword',
          title: this.$t('orders.route.update')
        },
      ]
    },
    menuRoutes() {
      return [
        'Dashboard',
        'Orders',
        'Change Password',
        'Address',
        'Account Details',
        ...(this.$route.name === 'Billing Address' ? ['Billing Address'] : []),
        ...(this.$route.name === 'Update Address' ? ['Update Address'] : []),
      ]
    },
    navigationMenu() {
      return [
        ...this.routes.filter(item => this.menuRoutes.includes(item.name)).map(item => ({
          ...item,
          to: {name: item.name}
        })),
        {
          title: this.$t('profile.menu.logout'),
          on: {
            click: this.attemptLogout
          }
        }
      ]
    },
    activeRoute: {
      get() {
        return this.routes.find(item => item.name === this.$route.name)
      },
      set(val) {
        if(val.to)
          this.$router.push(val.to)
        if(this.getProp(val, 'on.click'))
          val.on.click()
      }
    },
    routeTitle() {
      return this.activeRoute?.title
    }
  }

}
</script>

<style scoped lang="scss">
.profile {
  .page-title {
    @media (max-width: 600px) {
      font-size: 36px;
    }
  }
  padding-top: 65px;

  .container {
    @media(min-width: 1400px) {
      max-width: 1320px;
    }
  }

  .border-right {
    border-right: 1px solid #dee2e6 !important;
  }

  .desktop-profile-menu {
    display: none;
    @media (--t) {
      display: flex;
      flex-direction: column;
      position: -webkit-sticky !important;
      position: sticky !important;
      top: 5% !important;
    }
  }

  .mobile-profile-menu {
    display: flex;
    @media (--t) {
      display: none;
    }
  }
}


</style>
