export {default as LogoMobile} from '@/assets/svg-icons/logo/logo-3.svg';
export {default as LogoDesktop} from '@/assets/svg-icons/logo/logo-1.svg';
export {default as LogoDesktopBig} from '@/assets/svg-icons/logo/logo-2.svg';
export {default as LogoWhite} from "@/assets/svg-icons/logo/logo-4.svg";
export {default as SymbolBig} from "@/assets/svg-icons/logo/logo-0.svg";
export {default as SymbolMedium} from "@/assets/svg-icons/logo/logo-0.svg";
export {default as SymbolSmall} from "../../../public/favicon.svg";
export {default as IconPaypal} from "@/assets/svg-icons/icons/paypal.svg";


export {default as IconInstagram} from "@/assets/svg-icons/icons/instagram.svg"
export {default as IconYoutube} from "@/assets/svg-icons/icons/youtube.svg"
export {default as IconFacebook} from "@/assets/svg-icons/icons/facebook.svg"
export {default as IconPinterest} from "@/assets/svg-icons/icons/pintrest.svg"
export {default as IconCart} from "@/assets/svg-icons/icons/cart.svg";
export {default as IconRightArrow} from "@/assets/svg-icons/icons/right.svg";
export {default as IconDownArrow} from "@/assets/svg-icons/icons/down.svg";
export {default as IconDownMini} from "@/assets/svg-icons/icons/down-mini.svg";
export {default as IconUpArrow} from "@/assets/svg-icons/icons/up-arrow.svg";
export {default as IconPlus} from "@/assets/svg-icons/icons/plus.svg";
export {default as IconMinus} from "@/assets/svg-icons/icons/minus.svg";
export {default as IconX} from "@/assets/svg-icons/icons/x-icon.svg";


export {default as IconGridDefault} from "@/assets/svg-icons/grids/rectangle.svg";
export {default as IconGridVertical} from "@/assets/svg-icons/grids/vertical.svg";
export {default as IconGridHorizontal} from "@/assets/svg-icons/grids/horizontal.svg";
export {default as IconGridWave} from "@/assets/svg-icons/grids/wawe.svg";


//  payment method icons
export {default as IconPaypals} from "@/assets/svg-icons/icons/payment/paypal.svg";
export {default as IconVisaDebit} from "@/assets/svg-icons/icons/payment/visa-debit.svg";
export {default as IconMasterCard} from "@/assets/svg-icons/icons/payment/mastercard.svg";
export {default as IconVisa} from "@/assets/svg-icons/icons/payment/visa.svg";

//  configurator method icons
export {default as IconUploadMini} from "@/assets/svg-icons/configuration/upload.svg";
export {default as IconSizeMini} from "@/assets/svg-icons/configuration/layout.svg";
export {default as IconPrintMini} from "@/assets/svg-icons/configuration/print.svg";
export {default as IconEdgeMini} from "@/assets/svg-icons/configuration/edge.svg";

export {default as IconFlipHorizontal} from "@/assets/svg-icons/configuration/icon-0.svg";
export {default as IconFlipVertical} from "@/assets/svg-icons/configuration/icon-3.svg";
export {default as IconRotateLeft} from "@/assets/svg-icons/configuration/icon-2.svg";
export {default as IconRotateRight} from "@/assets/svg-icons/configuration/icon-1.svg";
export {default as IconZoomIn} from "@/assets/svg-icons/configuration/zoom-in.svg";
export {default as IconZoomOut} from "@/assets/svg-icons/configuration/zoom-out.svg";
export {default as IconCartNew} from "@/assets/svg-icons/configuration/cart-new.svg";
export {default as IconSave} from "@/assets/svg-icons/icons/save-icon.svg";
export {default as IconDelete} from "@/assets/svg-icons/icons/delete.svg";

