<template>
  <div class="about">
    <Heading title-tag="h1" sub-title-tag="h2" :subtitle="$t('about.subtitle')" :title="$t('about.title')"/>
    <div class="title-section">
      <h3 class="page-subtitle head">{{ $t("about.thirdTitle") }}</h3>
    </div>
    <v-container>
      <v-row class="founder-info">
        <v-col class="align-center d-flex flex-column" cols="12" lg="5">
          <img alt="founder_picture" class="img-fluid" src="../../../assets/images/laurence-lefebvre-founder-of-blankspace.jpg">
          <div class="note-from-founder">
            <span>{{ $t('home.about.note') }}</span>
          </div>

        </v-col>
        <v-col class="about-info pl-lg-16" lg="6" cols="12">
          <p class="soft-text font-weight-bold primary--text">{{ $t("about.first") }}</p>
          <p class="soft-text">{{ $t("about.second") }}</p>
          <p class="soft-text">{{ $t("about.third") }}</p>
          <p class="soft-text">{{ $t("about.fourth") }}</p>
          <p class="soft-text">{{ $t("about.fifth") }}</p>
          <p class="soft-text">{{ $t("about.thanks") }}</p>
          <p class="soft-text primary--text">- {{ $t("about.founder") }}</p>
        </v-col>
      </v-row>
    </v-container>


    <div class="quality-section">
      <v-container class="my-2 my-lg-8" :style="{maxWidth: max_cont + 'px'}">
        <Heading :subtitle="$t('about.quality.subtitle').toUpperCase()" class="mb-4" :title="$t('about.quality.title')"/>
        <p class="text-center">
          {{ $t("about.quality.description-one") }}
          <br>
          {{ $t("about.quality.description-two") }}

        </p>
      </v-container>

      <div class="d-flex flex-wrap" style="position: relative">
        <AppSwiper ref="qualitySwiper" :items="slider" v-bind="primaryOptions">
          <template v-slot:item="slide">
            <img :src="slide.image" class="img-fluid"/>
          </template>
        </AppSwiper>
        <template
          v-for="direction in ['next', 'prev']"
        >
          <v-btn
            depressed
            @click="$refs.qualitySwiper.swiper[`slide${direction.capitalize()}`]()"
            icon
            absolute
            color="primary"
            ref="navBtn"
            :key="`${direction}Btn`"
            :class="`navigation-${direction} nav-btn`"
            height="60"
            width="60"
          >
            <v-icon color="white" size="35">mdi-chevron-{{ direction === 'next' ? 'right' : 'left' }}</v-icon>
          </v-btn>
        </template>
      </div>
    </div>
    <Inspired/>
    <v-container>
      <div class="quotes-section">
        <template v-for="quote in quotes">
          <div>
            <AphoristicCard
              height="100%"
              :key="quote.id"
              :quotes="quote.text"
              width="350"
              :writer="quote.writer"
            />
          </div>
        </template>
      </div>
    </v-container>
    <v-container v-if="smAndDown">
      <div class="mobile-quotes">
        <AppSwiper :items="quotes" v-bind="tertiaryOptions">
          <template #item="quote">
            <AphoristicCard
              :quotes="quote.text"
              :writer="quote.writer"
            />
          </template>
        </AppSwiper>

      </div>
    </v-container>
    <JoinCommunitySection/>
  </div>
</template>

<script>
import {mapState} from "vuex";

import IconSymbol from "../../../../public/favicon.svg";
import AphoristicCard from "@/components/cards/shared-card/quote-card";
import IconLogo from "@/assets/svg-icons/logo/logo-8.svg";
import Inspired from "@/components/section/inspire-section";

export default {
  name: "About",
  metaInfo() {
    return {
      title: this.$t('titles.about'),
      titleTemplate: '%s - Blankspace',
       htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.$t('titles.about-content')},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  components: {
    Inspired,
    AphoristicCard,
    IconSymbol,
    IconLogo
  },
  computed: {
    ...mapState(["blogList"]),
    max_cont() {
      if (localStorage.getItem("lang") === 'fr') {
        return 1100;
      } else return 880;
    }
  },
  data() {
    return {
      slider: [
        {id: 1, image: require("../../../assets/images/slide-image/slide-2.png")},
        {id: 2, image: require("../../../assets/images/slide-image/slide-0.png")},
        {id: 3, image: require("../../../assets/images/slide-image/slide-3.png")},
        {id: 4, image: require("../../../assets/images/slide-image/slide-1.png")},
        {id: 5, image: require("../../../assets/images/slide-image/slide-4.jpg")},
        {id: 6, image: require("../../../assets/images/slide-image/slide-5.jpg")},
        {id: 7, image: require("../../../assets/images/slide-image/slide-6.jpg")},
      ],
      quotes: [
        {
          id: 1,
          writer: "Natte Berkus",
          text: "Your home should tell the story of who you are, and be a collection of what you love"
        },
        {
          id: 2,
          writer: "Marie Kondo",
          text: "It is essential to create a quiet space in which to evaluate the things in your life"
        },
        {
          id: 3,
          writer: "Alice Walker",
          text: "Whenever you are creating beauty around you, you are restoring your own soul"
        }
      ],
      primaryOptions: {
        loop: true,
        autoplay: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 16,
        breakpoints: {
          1280: {
            slidesPerView: 4,
            centeredSlides: false,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 2,
          },
          770: {
            slidesPerView: 2,

            spaceBetween: 16,
          },
          740: {
            slidesPerView: 1,
            spaceBetween: 16,
          },

        }
      },
      tertiaryOptions: {
        loop: true,
        autoplay: true,
        slidesPerView: 1,
        spaceBetween: 16,
      }
    }
  },
  created() {
    this.$store.dispatch('GET_BLOG_POSTS')
  }
}
</script>


<style lang="scss">


.about {
  padding-top: 40px;
  @media(min-width: 1280px) {
    padding-top: 65px;
  }
  .img-fluid {
    width: 100%;
    height: auto;
  }
  .container {
    @media (min-width: 1280px) {
      max-width: 1170px;

    }
  }

  .pagination-container {
    top: 100% !important;
    &.mobile-quotes-container {
      position: relative;
      top: unset;
      margin-top: 30px;
    }
  }

  .title-section {
    max-width: 360px;
    padding: 0 20px;
    @media (--t) {
      max-width: unset;
      margin-top: 8px;
    }

    .page-subtitle {
      letter-spacing: 0.1em;
    }

  }

  .note-from-founder {
    margin-top: 40px;
    display: inline-block;
    max-width: 119px;
    padding-bottom: 15px;
    border-bottom: 1px solid #000000;
    font-family: Sk-Modernist;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.15em;
    color: #000000;
    @media (--t) {
      display: none;
    }
  }

  .founder-info {
    margin-top: 16px;
    padding-bottom: 30px;
    padding-left: 7px;
    padding-right: 7px;
    @media (--t) {
      padding-left: unset;
      padding-right: unset;
    }


    img {
      width: 253px;
      @media (--t) {
        width: inherit;
        margin-bottom: 20px;
        height: 610px;
        margin-top:5px;
      }
    }

    .about-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .soft-text {
        font-size: 17px !important;
        line-height: 140% !important;
        letter-spacing: 0.02em !important;
        text-align: center !important;
        margin-top: 12px;
        @media (--t) {
          text-align: left !important;
          margin-top: unset;
        }
      }


    }


  }

  .quality-section {
    background-color: #F9F9F9;
    padding-top: 10px;
    margin-top: -10px;
    .swiper-slide {
      width: 276px;
    }
    .nav-btn {
      position: absolute;
      top: 50%;
      transform: translateY(calc(-50% - 20px));
      z-index: 1;
    }
    .navigation-next {
      right: 0;
    }
    .navigation-prew {
      left: 0;
    }
    .swiper-container {
     padding-bottom: 40px;
      @media(--t) {
        padding-bottom: 92px;
      }

    }
    .swiper-pagination {
      bottom: 5px;
    }
    margin-bottom: 40px;
    @media (--t) {
      margin-top: 70px;
      margin-bottom: 0;
      .swiper-pagination {
        display: none;
      }
    }

    span {
      font-family: Sk-Modernist, 'sans-serif';
      font-size: 18px !important;
      line-height: 150% !important;
      letter-spacing: 0.02em !important;
      color: #000000 !important;
    }


    .container {
      //max-width: 815px !important;
      padding-right: 20px;
      padding-left: 20px;
      text-align: left;

      :nth-child(4) {
        display: block;
        @media(--t) {
          display: unset;
          margin-top: unset;
        }
      }

      :nth-child(3) {
        display: block;
        @media(--t) {
          display: unset;
          margin-top: unset;
        }
      }

      @media (--t) {
        text-align: center;
      }


      h2 {
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;
        @media (--t) {
          max-width: unset;
        }
      }


      h1, h2, h3 {
        text-align: center !important;

      }

      .slide {
        margin-top: 80px !important;
        margin-bottom: 40px !important;


      }
    }


  }

  .image-gallery {
    margin-bottom: 20px;
    @media (--t) {
      margin-bottom: unset;
    }

  }

  .head {
    letter-spacing: 0.15em;
    margin-bottom: 15px !important;
  }

  .mobile-quotes {
    margin: 20px 0;
    .swiper-pagination {
      bottom: 0;
      position: relative;
      margin: 16px 0 0;
    }
  }

  .quotes-section {
    display: none;
    > * {
      height: 100%;
      padding: 0 30px;
      &:first-child {
        padding: 0 30px 0 0;
      }
      &:last-child {
        padding: 0 0 0 30px;
      }
    }
    @media (--t) {
      display: flex;
      margin-top: 95px;
    }
  }

  .community-section {
    background-color: #F4F4F4;
    /*margin-top: 90px;
    padding-top: 42px;*/
    @media (--t) {
      //padding-bottom: 93px;
    }

    .communities {
      margin-top: 63px;

      img {
        width: inherit;
        margin-top: 20px;
      }
    }
  }
}


</style>


