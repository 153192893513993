<template>
  <section class="instagram-section">
    <slot name="title">
      <div class="d-flex justify-center flex-wrap">
        <h3>
          <a href="https://www.instagram.com/blankspace.ink/" target="_blank">{{ $t('social') }}</a>
        </h3>
      </div>
    </slot>
    <div class="social-section">
      <v-container class="pb-0">
        <div class="row justify-center">
          <div class="col-12 col-lg-10 instagram-swiper-wrapper py-0">
            <div class="swiper-container pb-10" ref="swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in posts" :key="index">
                  <InstagramCard
                    :customer="item.name"
                    :message="item.message"
                    :src="item.path"
                  />
                </div>
              </div>
              <div class="swiper-pagination" ref="pagination"></div>
            </div>
            <template
              v-for="direction in ['next', 'prev']"
            >
              <v-btn
                v-if="!smAndDown"
                depressed
                icon
                absolute
                color="primary"
                ref="navBtn"
                :key="`${direction}Btn`"
                :class="`navigation-${direction} nav-btn`"
                height="60"
                width="60"
              >
                <v-icon :size="smAndDown ? 35 : 50">mdi-chevron-{{ direction === 'next' ? 'right' : 'left' }}</v-icon>
              </v-btn>
            </template>
          </div>
        </div>
      </v-container>
    </div>
  </section>
</template>

<script>
import IconSymbol from '../../../public/favicon.svg'
import InstagramCard from '@/components/cards/shared-card/instagram-card'
import { swiperMixins } from '@/mixins/swiperMixins'

export default {
  name: 'InstagramSection',
  mixins: [swiperMixins],
  data: () => ({
    posts: [
      {
        id: 1,
        name: 'Alice Walker',
        message: '“Whenever you are creating beauty around you, you are restoring your own soul.”',
        path: '/img/instagram/1.webp'
      },
      {
        id: 2,
        name: 'Melanie Berliet',
        message: '“In the play of life, your home is a character. You give it a meaning.”',
        path: '/img/instagram/2.webp'
      },
      {
        id: 3,
        name: 'Nate Berkus',
        message: '“Your hom should tell the story of who you are, and be a collection of what you love”',
        path: '/img/instagram/3.jpg'
      },
      {
        id: 4,
        name: 'Shawna Scafe',
        message: '“Make space for the life you want to have”',
        path: '/img/instagram/4.jpg'
      },
      {
        id: 5,
        name: 'Alice Walker',
        message: '“Your home should be the antidote to stress, not the cause of it”',
        path: '/img/instagram/5.jpg'
      },
      {
        id: 6,
        name: 'Alice Walker',
        message: '“Your home should be the antidote to stress, not the cause of it”',
        path: '/img/instagram/6.jpg'
      }
    ],
  }),
  computed: {
    options() {
      return {
        slidesPerView: 1,
        spaceBetween: 32,
        autoplay: true,
        loop: true,
        ...this.smAndDown && {
          pagination: {
            el: this.$refs.pagination,
            //dynamicBullets: true
          }
        },
        ...!this.smAndDown && {
          navigation: {
            nextEl: this.$refs.navBtn[0].$el,
            prevEl: this.$refs.navBtn[1].$el,
          }
        },
        breakpoints: {
          980: {
            slidesPerView: 2,
            spaceBetween: 32,
          }
        }
      }
    }
  },
  components: {
    IconSymbol,
    InstagramCard
  },
}
</script>

<style lang="scss">

.instagram-section {
  .instagram-swiper-wrapper {
    position: relative;

    .nav-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;

      &.navigation-prev {
        left: -55px;
      }

      &.navigation-next {
        right: -55px;
      }
    }
  }

  .swiper-pagination {
    bottom: 0 !important;
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.03em;
    margin-top: 100px;
    font-family: var(--font-family--alethia);

    color: #000000;

    @media (max-width: 980px) {
      font-size: 35px;
      line-height: 35px;
      margin-top: 40px;
    }
  }

  .social-section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 45px;

    hr {
      background-color: #E5E5E5;
      margin: 16px 10px 0;
      @media (--t) {
        margin-top: 100px
      }
    }

    .social-slider {
      margin-top: 5px;
      @media (--t) {
        margin-top: 70px;
      }
    }
  }
}
</style>
