<template>
  <div class="paper-type ">


    <Heading :title="$t('paper.hemp.title')" :subtitle="$t('paper.hemp.subtitle')"/>

<!--    <h1 class="page-title">{{$t('paper.hemp.title')}}</h1>-->
<!--    <h2 class="page-subtitle">{{$t('paper.hemp.subtitle')}}</h2>-->

    <PhotoPaperSection
        :details="details"
        :feature_five="$t('paper.hemp.specified.five')"
        :feature_four="$t('paper.hemp.specified.four')"
        :feature_one="$t('paper.hemp.specified.one')"
        :feature_six="$t('paper.hemp.specified.six')"
        :feature_three="$t('paper.hemp.specified.three')"
        :feature_two="$t('paper.hemp.specified.two')"
        :images="images"
        :subtitle="$t('paper.hemp.description')"
        :title="$t('paper.hemp.about')"
    />


    <PricingNew :vertical-sizes="[5, 94]" :horizontal-sizes="[7, 35]"/>

    <PaperTab/>

    <UsagePlaces
        :vibrant_title="$t('paper.hemp.usage.vibrant.title')"
        :vibrant_footer="$t('paper.hemp.usage.vibrant.footer')"
        :vibrant_description="$t('paper.hemp.usage.vibrant.description')"
        :handcrafted_title="$t('paper.hemp.usage.handcrafted.title')"
        :handcrafted_description="$t('paper.hemp.usage.handcrafted.description')"
        :handcrafted_team="$t('paper.hemp.usage.handcrafted.team')"
        :handcrafted_moments="$t('paper.hemp.usage.handcrafted.moments')"
        :feature_one="$t('paper.hemp.usage.features.feature_one')"
        :feature_two="$t('paper.hemp.usage.features.feature_two')"
        :feature_three="$t('paper.hemp.usage.features.feature_three')"
        :feature_four="$t('paper.hemp.usage.features.feature_four')"
        :feature_five="$t('paper.hemp.usage.features.feature_five')"
        :feature_six="$t('paper.hemp.usage.features.feature_six')"
        :left_image="hemp_image_one"
        :right_image="hemp_image_two"
    />

    <ProductReviewSection/>

    <WorthSection/>

  </div>
</template>

<script>
import {mapState} from "vuex";
import PaperTab from "@/components/tab/paper-tab/PaperTab";

import PhotoPaperSection from "@/components/section/paper-info-section";
import UsagePlaces from "@/components/section/example-usage-section";
import WorthSection from "@/components/section/stories-worth-section";
import PricingNew from "@/components/cards/pricing-card-new";
import ProductReviewSection from "../../components/product-review/product-review-section";


export default {
  name: "Hemp",
  metaInfo() {
    return {
      title: this.$t('titles.hemp'),
      titleTemplate: '%s - Blankspace',
       htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.$t('titles.hemp-content') },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' }
      ]
    }
  },
  components: {
    ProductReviewSection,
    PricingNew,
    WorthSection,
    UsagePlaces,
    PhotoPaperSection,
    PaperTab
  },
  computed: {
    ...mapState(["review", "worth","stories"])
  },
  data() {
    return {
      hemp_image_one:require('../../assets/images/usage-new/7.png'),
      hemp_image_two:require('../../assets/images/usage-new/6.png'),
      images: [
        {id: 1, src: '/img/papers/opt/hemp-1-opt.jpg'},
        {id: 2, src: '/img/papers/opt/hemp-0-opt.jpg'}
      ],
      details: [
        {id: 1, title: "Recyclable sustainable material"},
        {id: 2, title: "Fit to frame"},
        {id: 3, title: "Vibrant pigment inks with extended color gamut"},
        {id: 4, title: "Incredible print longevity of over 200 years"},
        {id: 5, title: "Scratch resistant technology "},
        {id: 6, title: "True giclée print using the highest quality inkjet technology"},
      ]
    }
  }
}
</script>

<style scoped>

h2 {
  letter-spacing: 0.15em;
}
.paper-type {
  padding-top: 35px;
  overflow-x: hidden !important;
  @media (--t) {
    padding-top: 65px;
  }

  .story-section {
    background-color: #F9F9F9;
    padding-top: 85px;

    .story-items {
      margin-top: 51px;
    }
  }
}

</style>
