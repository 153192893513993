  <template>
  <div class="community-section">
    <v-container class="community-section-container">
      <IconLogoBig class="community-section-logo"/>
      <Heading :subtitle="$t('join.subtitle')" :title="$t('join.title')" class="mb-lg-8"/>
      <v-row class="justify-center">
        <v-col cols="12" lg="4" md="12">
          <h6 class="area-title">{{ $t("join.blog") }}</h6>
          <div class="d-flex flex-column" >
            <template v-for="blog in getBlogList">
              <BlogItem
                :key="blog.id"
                :blog="blog"
                class="mb-4"
              />
            </template>
          </div>
        </v-col>
        <div class="cols-12 col-md-12 col-sm-12 col-lg-3">
          <v-card href="https://www.instagram.com/blankspace.ink/" target="_blank" flat color="transparent">
            <h6 class="area-title">{{ $t("join.social") }}</h6>
            <v-img src="../../assets/images/new-images/social.png" width="100%"></v-img>
          </v-card>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import CustomText from "@/components/shared/CustomText";
import IconLogoBig from "@/assets/svg-icons/logo/logo-8.svg";
import BlogItem from "@/components/cards/shared-card/blog-item-card";
import {mapGetters} from "vuex";
import Heading from "../Heading";

export default {
  name: "JoinCommunitySection",
  components: {
    Heading,
    CustomText,
    IconLogoBig,
    BlogItem
  },
  created() {
    this.$store.dispatch("GET_BLOG_POSTS")
  },
  computed: {
    ...mapGetters(["getBlogList"])
  }
}
</script>

<style lang="scss" scoped>

.community-section {
  .v-card--link:focus:before {
    opacity: 0!important;
  }

  .area-title {
    font-family: var(--font-family--alethia)!important;
    font-style: normal !important;
    font-weight: normal !important;
    color: #000000 !important;
    font-size: 40px !important;
    line-height: 102.7%;
    text-align: left;
    margin: 20px 0 16px;
    @media(--t) {
      margin: 25px 0;
    }

  }
  position: relative;
  overflow: hidden;
  margin-top: 0;
  padding-top: 0;
  z-index: 0;
  @media (--t) {
    margin-top: 90px;
    padding-top: 42px;
  }

  &-logo {
    display: none;
    @media (--t) {
      display: inline-block;
      position: absolute;
      top: 60px;
      right: -10%;
      z-index: -1;
    }
  }

  &-container {
    padding-top: 40px;

    z-index: 2;
    position: relative;
    @media(--t) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    @media(min-width: 1500px) {
      &.container {
        max-width: 1400px;
      }
    }
  }
}


</style>
