<template>
  <v-col class="order-payment-card" lg="4" cols="12">
    <div class="order-item-list">
      <slot name="products" />
    </div>

    <div>
      <v-row>
        <v-col class="order-title-section">
          <span class="medium" v-if="subtotal">{{ $t("cart.subtotal") }}</span>
          <span class="medium" v-if="shipment">{{ $t("cart.shipment") }}</span>
          <span v-for="(taxItem, index) in _taxes" :key="taxItem.id" class="medium" :class="{ 'mt-4': index === 0 }"> {{ `${taxItem.rate_name} (${taxItem.rate_value}%)` }}</span>
        </v-col>

        <v-col class="order-value-section">
          <span class="medium" v-if="subtotal">$ {{ subtotal.toFixed(2) }}</span>
          <span class="medium" v-if="shipment">$ {{ shipment }}</span>
          <span class="medium" v-if="gift">- $ {{ gift }}</span>
          <span v-for="(taxItem, index) in _taxes" :key="taxItem.id" class="medium" :class="{ 'mt-4': index === 0 }"> {{ `$ ${taxItem.total_amount}` }}</span>
        </v-col>
      </v-row>
      <template v-if="discountAvailable">
        <hr />
        <v-row>
          <v-col class="order-title-section">
            <span class="medium" v-if="discountAvailable">{{ $t("cart.discount") }}</span>
            <span class="medium" v-if="gift_card">Gift Card</span>
          </v-col>
          <v-col class="order-value-section">
            <span class="medium" v-if="discountAvailable">- $ {{ discountAmount.toFixed(2) }}</span>
            <span class="medium" v-if="gift_card">- $ {{ gift_card }}</span>
          </v-col>
        </v-row>
      </template>
      <hr />
      <v-row>
        <v-col class="order-title-section">
          <span class="medium">{{ $t("cart.total") }}</span>
        </v-col>
        <v-col class="order-value-section">
          <span class="medium">$ {{ total.toFixed(2) }}</span>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "OrderPaymentCard",
  props: {
    subtotal: {
      type: Number,
    },
    shipment: {
      type: Number,
    },
    tax: {
      type: String,
    },
    total: {
      type: Number,
    },
    discount: {
      type: [Number, String],
    },
    gift: {
      type: Number,
    },
    gift_card: {
      type: Number,
    },
    taxes: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      discountAvailable : "discountAvailable",
      discountAmount : "discountAmount"
    }),
    _taxes() {
      return this.taxes?.filter((taxItem) => taxItem?.total_amount > 0);
    },
  },
};
</script>

<style scoped lang="scss">
.order-payment-card {
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 30px;
  background-color: #e8e8e8 !important;
  margin-top: 50px;
  @media (--t) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    margin-top: 0;
  }

  .order-item-list {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 50vh);
    max-height: 40vh;
  }

  .order-title-section {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .order-value-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

hr {
  margin: 12px 0;
  background-color: #9f9f9f;
}
</style>
