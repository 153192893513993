<template>
  <v-row class="justify-space-between " no-gutters>
    <v-col
        v-for="(dimension, index) in sizeList"
        :key="index"
        :class="{ selected: dimension.selected }"
        class="size-button mt-3"
        cols="6"
        depressed
        style="max-width: 45%"
        @click="$emit('onSelect',dimension)"
    >
      {{ dimension.label }}
    </v-col>
    <span></span>
    <v-col :class="customClass" class="mt-3"  @click="customSizeSelected">
      {{ $t("price.custom") }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SizeSelectMobile",
  data() {
    return {
      custom: false
    }
  },
  model: {
    event: "change",
  },
  props: {
    sizeList: {
      type: Array
    },
    customSize: {
      type: Boolean,
    }

  },
  computed:{
    customClass(){
      return this.customSize ? 'active' :'not-active';
    }
  },
  methods: {
    customSizeSelected() {
      this.$emit('onCustomSize');
    }
  }
}
</script>

<style scoped>
.active {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  height: 50px !important;
  background-color: #009771;
  border-radius: 0;
  border: 2px solid #009771;
  cursor: pointer;
  color: #FFFFFF;
}


.not-active{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  height: 50px !important;
  background-color: #ffffff ;
  border-radius: 0;
  border: 2px solid #009771;
  color: #000000;
  cursor: pointer;

}


.size-button {
  /*display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 300!important;
  height: 50px !important;
  background-color: #ffffff ;
  border-radius: 0;
  border: 2px solid #009771;
  color: #000000;
  cursor: pointer;*/
}
</style>
