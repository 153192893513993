<template>
  <OrderPreview
      v-if="order.order_id"
      class="order-detail"
      :order="order"
  >
    <template v-slot:header>
      <div class="order-header">
        <h1>{{ $t('purchase.thanks') }} {{ order.shipment_address.first_name }}</h1>
        <span class="passive-grey--text">{{ $t('purchase.order') }} #{{ order.order_id }}</span>
      </div>
      <hr>
      <div class="order-message">
        <h2>{{ $t('purchase.confirmed') }}</h2>
        <p>{{ $t('purchase.message') }}</p>
      </div>
      <hr>
    </template>
    <template v-slot:continue-btn>
      <AppButton
          @click="continueShopping"
          color="primary"
          large
      >
        {{ $t('buttons.continue') }}
      </AppButton>
    </template>
  </OrderPreview>
</template>

<script>
import {LogoDesktopBig} from '@/assets/svg-icons'
import localforage from 'localforage'
import OrderPreview from '@/components/ui/OrderPreview'

export default {
  name: 'OrderDetail',
  components: {OrderPreview, LogoDesktopBig},
  data() {
    return {
      shipment_address: '',
      billing_address: '',
      order_items: [],
      contact_person: '',
      order: {
        order_id: null,
        billing_address: {},
        shipment_address: {},
        order_items: [],
        contact_person: {},
        name: '',
        card_number: '',
      },
    }
  },
  methods: {
    continueShopping() {
      this.$store.dispatch('CLEAR_SUMMARY')
      this.$router.push({name: 'Home'})
    }
  },
  created() {
    // this.$store.commit("SET_LOADER", true)
  },
  mounted() {
    if (!localStorage.order_id) {
      this.$store.dispatch('CLEAR_SUMMARY')
      this.$router.push({ name: 'Home' })
      // this.$store.commit("SET_LOADER", false);
    } else {
      localforage.getItem('user_data')
          .then(({order: {order_summary, gift_cards}}) => {
            this.order = {
              order_id: order_summary?.order_id,
              billing_address: order_summary?.order_info?.karavan_order?.contact_billing_address || "",
              shipment_address: order_summary?.order_info?.karavan_order?.contact_shipping_address || '',
              contact_person: order_summary?.order_info?.karavan_order?.contact_person.email || '',
              card_number: order_summary?.order_card || 0,
              gift_cards : gift_cards || []
            }
            // setTimeout(() => {
            //   this.$store.commit("SET_LOADER", false)
            // }, 1500)
          })

    }
  },
}
</script>
