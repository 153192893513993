<script>
import {IconEdgeMini, IconPrintMini, IconSizeMini, IconUploadMini} from "@/assets/svg-icons";

export default {
  name: "BottomNavigation",
  props: {
    image: {
      type: Boolean,
      default: true,
    },
    edgeTab: {
      type: Boolean,
      default: true,
    },
    sizeTab:{
      type:Boolean,
      default:true,
    }
  },
  components: {IconUploadMini, IconEdgeMini, IconPrintMini, IconSizeMini},
}
</script>

<template>
  <v-bottom-navigation app color="teal" grow>
    <v-btn @click="$emit('on-upload-tab')">
      <IconUploadMini height="35" width="29"/>
    </v-btn>
    <v-btn :disabled="!image" @click="$emit('on-material-tab')">
      <IconSizeMini height="35" width="29"/>
    </v-btn>
    <v-btn :disabled="!sizeTab" @click="$emit('on-size-tab')">
      <IconPrintMini height="35" width="29"/>
    </v-btn>
    <v-btn :disabled="!edgeTab" @click="$emit('on-edge-tab')">
      <IconEdgeMini height="35" width="29"/>
    </v-btn>
  </v-bottom-navigation>
</template>
