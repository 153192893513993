<template>
  <v-container>
    <template v-if="loading">
      <v-skeleton-loader v-for="i in 5" :key="i" class="mb-4" type="list-item-two-line" :elevation="2"/>
    </template>
    <template v-else-if="get_order_list">
      <OrderListCard
          v-for="(item,index) in get_order_list"
          :key="index"
          class="mb-4"
          :accordion_id="index"
          :date="item.created_at"
          :status="lang === 'en' ? item.status : 'COMPLÉTÉ' "
          :to="`/prev-order-detail/${item.id}`"
          :total="item.total_amount"
      />
    </template>
    <div v-else class="d-flex align-center justify-center flex-column mt-5">
      <h1 class="font-alethia">{{ $t('profile.no_order') }}</h1>
      <AppButton
          color="primary"
          :to="{name:'Configurator'}"
          large
      >
        {{ $t('buttons.start') }}
      </AppButton>
    </div>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import OrderListCard from '../../../../components/cards/order-card'

export default {
  name: "Orders",
  components: {OrderListCard},
  data: () => ({
    loading: true
  }),
  beforeCreate() {
    this.$store.dispatch('GET_ORDERS').then(() => this.loading = false)
  },
  computed: {
    ...mapGetters(['get_order_list']),
    lang() {
      return localStorage.getItem('lang')
    }

  },

}
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  font-size: 18px;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 2rem;
  @media (--t) {
    font-size: 36px;
  }
}
</style>
