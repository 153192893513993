<template>
  <div class="footer">
    <div class="footer-top">
      <v-container class="px-8 px-md-0 footer-container-top">
        <div class="row justify-space-between">
          <v-col class="footer-top-mail" lg="3" md="12" cols="12">
            <router-link :to="{name:'Home'}">
              <LogoWhite/>
            </router-link>
            <form v-if="subscriber_text_active" class="subscriber-form" @submit.prevent="subscribe">
              <input required :placeholder="$t('placeholder.email')" v-model="subscriber.karavan_subscriber.email" type="email" >
              <button type="submit">{{$t('buttons.send')}}</button>
            </form>
            <span v-if="subscriber_text_active">{{$t('placeholder.subscriber')}}</span>
            <span v-if="is_subscribed" class="animate__animated  animate__bounceInLeft mt-5">{{$t('subscribed')}}</span>
          </v-col>
          <v-col class="footer-top-routes" lg="9" md="12" cols="12">
            <v-row>
              <v-col class="nav-container" offset-lg="2" lg="3" cols="12">
                <h6 class="footer-route-title">{{ $t("footer.company.title") }}</h6>
                <router-link class="footer-route-name" :to="{name:'About'}"><span>{{ $t("footer.company.about") }}</span></router-link>
                <router-link class="footer-route-name" :to="{name:'Blog'}"><span>{{ $t("footer.company.blog") }}</span></router-link>
              </v-col>
              <v-col class="nav-container" lg="3" cols="12">
                <h6 class="footer-route-title">{{ $t("footer.help.title") }}</h6>
                <a class="footer-route-name" @click="openContactForm"><span>{{ $t("footer.help.contact") }}</span></a>
                <router-link class="footer-route-name" to="/faq"><span>{{ $t("footer.help.faq") }}</span></router-link>
                <router-link :to="{path:'/faq#return'}" class="footer-route-name"><span>{{ $t("footer.help.return") }}</span></router-link>
                <router-link :to="{path:'/faq#shipment'}" class="footer-route-name"><span>{{ $t("footer.help.ship") }}</span></router-link>
                <router-link :to="{path:'/faq#volume'}" class="footer-route-name"><span>{{ $t("footer.help.volume") }}</span></router-link>
              </v-col>
              <v-col class="nav-container pr-0" lg="4" cols="12">
                <h6 class="footer-route-title">{{ $t("footer.shop.title") }}</h6>
                <router-link class="footer-route-name" :to="{name:'CanvasPaper'}"><span>{{ $t("footer.shop.canvas") }}</span></router-link>
                <router-link class="footer-route-name" :to="{name:'AcrylicPaper'}"><span>{{ $t("footer.shop.hd") }}</span></router-link>
                <router-link class="footer-route-name" :to="{name:'MetalPaper'}"><span>{{ $t("footer.shop.metal") }}</span></router-link>
                <router-link class="footer-route-name" :to="{name:'Matte'}"><span>{{ $t("footer.shop.matte") }}</span></router-link>
                <router-link class="footer-route-name" :to="{name:'Satin'}"><span>{{ $t("footer.shop.satin") }}</span></router-link>
                <router-link class="footer-route-name" :to="{name:'Metallic'}"><span>{{ $t("footer.shop.metallic") }}</span></router-link>
                <router-link class="footer-route-name" :to="{name:'Textured'}"><span>{{ $t("footer.shop.texture") }}</span></router-link>
                <router-link class="footer-route-name" :to="{name:'Hemp'}"><span>{{ $t("footer.shop.hemp") }}</span></router-link>
                <router-link class="footer-route-name" :to="{name:'GiftCertificate'}"><span>{{$t("footer.shop.gift")}}</span></router-link>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </v-container>
    </div>
    <div>
      <hr>
      <v-container class=" px-8 px-md-0 footer-container-bottom">
        <v-row class="justify-space-between">
          <v-col class="footer-bottom-terms" :lg="terms_layout" cols="12">
            <router-link class="footer-route-name" :to="{name:'Terms'}">
              <span class="term-title">{{$t('footer.terms')}}</span>
            </router-link>
            <router-link class="footer-route-name" :to="{name:'Privacy'}">
              <span class="term-title term-mobile">{{$t('footer.privacy')}}</span>
            </router-link>
          </v-col>
          <v-col class="footer-bottom-social-icons" lg="2">
            <div class="social">
              <a href="https://www.youtube.com/channel/UCmQGG_uNecmvAvNMFxgP8ug" target="_blank">
                <IconYoutube/>
              </a>
              <a href="https://www.facebook.com/blankspace.ink/" target="_blank">
                <IconFacebook/>
              </a>
              <a href="https://www.instagram.com/blankspace.ink/?hl=en" target="_blank">
                <IconInstagram/>
              </a>
              <a href="https://www.pinterest.ca/blankspaceink/boards/" target="_blank">
                <IconPinterest/>
              </a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {IconFacebook, IconInstagram, IconPinterest, IconYoutube, LogoWhite} from "@/assets/svg-icons"
import {axiosInstance} from "@/utils/setAxios";

export default {
  name: "Footer",
  components: {
    LogoWhite,
    IconYoutube,
    IconFacebook,
    IconPinterest,
    IconInstagram
  },
  data() {
    return {
      subscriber: {
        karavan_subscriber: {
          email: "",
          first_name: "",
          last_name: "",
          source: "",
          subscription_ip: ""
        }
      },
      subscriber_text_active:true,
      is_subscribed:false,
    }
  },
  methods: {
    subscribe() {
      axiosInstance.post('subscribers',this.subscriber).then((res)=> {
          this.subscriber_text_active = false;
          this.is_subscribed = true;
        this.subscriber.karavan_subscriber.email = "";
      });
    },
    openContactForm() {
      window.Intercom('show')
    }
  },
  watch:{
    $route (to, from){
      this.subscriber.karavan_subscriber.email = "";
    }
  },
  computed:{
    terms_layout(){
      if(localStorage.getItem("lang") === "fr"){
        return 4
      } else return 3
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  background-color: #000000 !important;
  //overflow: hidden !important;
  .footer-top {
    padding: 30px 0;
  }

  @media (--t) {
    //height: 41.62vh !important;
    position: static;
    bottom: 0;
    left: 0;
    right: 0;
  }

  hr {
    background: #393939;
    padding: 0;
    margin: 0;
    border: 1px solid #393939;
  }
  @media(min-width: 1280px) {
    .container {
      max-width: 1300px;
    }
  }
  .footer-route-name {
    display: block;
    float: none;
    font-family: Sk-Modernist, 'sans-serif' !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    position: relative;
    span {
      position: relative;
      &:before {
        position: absolute;
        content: '';
        background-color: currentColor;
        width: 0;
        height: 2px;
        left: 0;
        bottom: 0;
        transition: width linear .3s;
      }
    }
    &:hover {
      span:before {
        width: 100%;
      }
    }
  }
  .footer-container-top {


    .footer-top-mail {
      .subscriber-form {
        display: flex;
        align-items: center;
        margin-top: 50px;

        input {
          height: 41px;
          border: 1px solid #ffffff;
          width: 235px;
          padding-left: 12px;
          outline: none;
          color: #ffffff;

          &::placeholder {
            font-family: Sk-Modernist, 'sans-serif' !important;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
          }
        }

        button {
          background-color: #ffffff !important;
          font-family: Sk-Modernist, 'sans-serif' !important;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: 0.02em;
          color: #000000;
          padding: 8px 15px !important;
          height: 41px;
        }


      }

      span {
        font-family: Sk-Modernist, 'sans-serif' !important;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        display: block;
        margin-top: 10px;
      }
    }

    .footer-top-routes {

      .footer-route-title {
        font-family: Sk-Modernist, 'sans-serif' !important;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 5px;
        @media(--t) {
          margin-bottom: 15px;
        }
      }
      .under-line-link {

      }


    }
  }

  .footer-container-bottom {
    //max-width: 1300px !important;
    padding-right: unset;
    @media (--t) {
      padding-right: 80px;
    }

    .footer-bottom-terms {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 30px;

      @media (--t) {
        padding-right: unset;
      }
      .term-mobile {
        text-align: right;
        @media (--t) {
          text-align: unset;
        }
      }



      .term-title {
        font-family: Sk-Modernist, 'sans-serif' !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        display: inline-block;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFFFFF;
      }
    }

    .footer-bottom-social-icons {
      display: flex;
      justify-content: center;
      @media (--t) {
        display: unset;
        justify-content: unset;
      }

      .social {
        display: flex;
        flex-direction: row;
        justify-content: space-between !important;
        max-width: 190px;
        a {
          display: flex;
        }
        @media (--t) {
          max-width: unset;
        }

        svg {
          margin-right: 30px;
          @media (--t) {
            margin-right: unset;
          }
        }
      }
    }


  }

}
</style>
